// import i18next from "i18next";
export const cardsMapper = {
	total_lbs_harvested: "label.totalLbsHarvested",
	cases_required: "label.total_cases_required",
	total_cases_packed: "label.total_cases_packed",
	case_per_lab_hour: "label.cases_per_lab_hour",
	lbs_per_lab_hour: "label.lbs_per_lab_hour",
	harvest_rate: "label.total_harvest_rate",
	seed_rate: "label.total_seed_rate",
};
export const iconsMapper = {
	total_lbs_harvested: "icon-total-lbs-harvested text-xl",
	cases_required: "icon-total-cases-required text-xl",
	total_cases_packed: "icon-total-cases-packed text-xl",
	case_per_lab_hour: "icon-cases-per-lab-hour text-xl",
	lbs_per_lab_hour: "icon-lbs-per-lab-hour text-xl",
	harvest_rate: "icon-harvest-rate text-xl",
	seed_rate: "icon-seeding-rate text-xl",
};

export const cardsPermission = {
	total_lbs_harvested: "harvest",
	cases_required: "orders",
	total_cases_packed: "orders",
	case_per_lab_hour: "orders",
	lbs_per_lab_hour: "orders",
	harvest_rate: "harvest",
	seed_rate: "seed",
};
