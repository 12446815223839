import fetchHandler from "utils/fetchHandler";
import { TO_ADD_LIST } from "../types";

export const setLoaderToAddList = () => {
	return (dispatch) => {
		dispatch({
			type: TO_ADD_LIST.LOADER_ADD_LIST,
		});
	};
};

export const fetchDropdownDataToAddList = (successHandler, errorHandler) => {
	const fetchOptions = {
		url: `dosing-filter-data`,
		method: "GET",
		secure: true,
		actionType: TO_ADD_LIST.SET_DROPDOWN_ADD_LIST,
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const setRenderTypeToAddList = (value) => {
	return (dispatch) => {
		dispatch({
			type: TO_ADD_LIST.SET_RENDER_TYPE_ADD_LIST,
			payload: value,
		});
	};
};

export const fetchDataToAddList = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `list-to-add-fertiliser`,
		method: "POST",
		secure: true,
		actionType: TO_ADD_LIST.SET_DATA_ADD_LIST,
		body: JSON.stringify(values),
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const fetchToAddListNutrientList = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `view-nutients`,
		method: "POST",
		secure: true,
		actionType: TO_ADD_LIST.SET_NUTRIENTS_ADD_LIST,
		body: JSON.stringify(values),
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const setToAddListEdit = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `set-edit-to-add`,
		method: "POST",
		secure: true,
		body: JSON.stringify(values),
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const deleteToAddList = (slug, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `delete-to-add-fertiliser/${slug}`,
		method: "DELETE",
		secure: true,
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const deleteToAddListFromStore = (slug) => {
	return (dispatch) => {
		dispatch({
			type: TO_ADD_LIST.DELETE_DATA_TO_ADD_LIST,
			payload: slug,
		});
	};
};
