import { CUSTOMERS } from "../types";
const initialState = {
	renderDisplay: 0,
	prevRenderDisplay: 0,
	customerDetails: [],
	allSkuList: [],
	allLocationsList: [],
	dcPriority: [],
	allCustomerDetails: [],
};
export default (state = initialState, action) => {
	switch (action.type) {
		case CUSTOMERS.SET_CUSTOMER_VIEW_RENDER:
			return {
				...state,
				prevRenderDisplay: state.renderDisplay,
				renderDisplay: action.payload,
			};
		case CUSTOMERS.FETCH_CUSTOMER_DETAILS:
			return {
				...state,
				customerDetails: action.payload ? action.payload.data : [],
			};
		case CUSTOMERS.FETCH_CUSTOMERS_LOCATION_LIST:
			return {
				...state,
				allLocationsList: action.payload.data,
			};
		case CUSTOMERS.FETCH_CUSTOMERS_SKU_LIST:
			return {
				...state,
				allSkuList: action.payload.data,
			};
		case CUSTOMERS.FETCH_CUSTOMER_DC_PRIORITY:
			return {
				...state,
				dcPriority: action.payload.data,
			};
		case CUSTOMERS.FETCH_CUSTOMERS:
			return {
				...state,
				allCustomerDetails: action.payload.data,
			};
		default:
			return state;
	}
};
