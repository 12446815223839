import React from "react";
import { Modal } from "react-bootstrap";
import useModal from "../../../utils/useModal";
import DailyTasksForm from "./dailyTasksForm";
import {
	mediaUsageForm,
	mediaUsageEditForm,
	setUpTimeForm,
	setUpTimeEditForm,
	cleaningTimeForm,
	cleaningTimeEditForm,
} from "./dailyTasksFormHelper";
import Util from "utils/Util";
import { connect } from "react-redux";
import { useTranslation } from 'react-i18next';

const { Header, Title, Body } = Modal;

// Daily Tasks Modal
//     1 - Media
//     2 - Setup
//     3 - Cleaning
const DailyTasks = ({ userDetails, refetch, setRefetch }) => {
	const [isOpen, modalType, , closeModal, modalProps = {}] = useModal("dailyTasks");
	const { t } = useTranslation();
	// Formatted location array
	const locations = Util.mapOptions(userDetails && userDetails.location_slugs, "name", "slug") || [];

	const formFields =
		modalType === 1
			? setRefetch
				? mediaUsageEditForm()
				: mediaUsageForm(locations)
			: modalType === 2
			? setRefetch
				? setUpTimeEditForm()
				: setUpTimeForm(locations)
			: modalType === 3
			? setRefetch
				? cleaningTimeEditForm()
				: cleaningTimeForm(locations)
			: null;

	return (
		<Modal size="lg" show={isOpen} onHide={closeModal} className="modal-sidepanel">
			<Header closeButton>
				<Title className="modal-title">
					{modalType === 1 ? t("label.media_usage") : modalType === 2 ? t("label.set_up_time") : modalType === 3 ? t("label.cleaning_time") : ""}
				</Title>
			</Header>
			<Body>
				{isOpen && (
					<DailyTasksForm
						formFields={formFields}
						initialValues={modalProps}
						setRefetch={setRefetch}
						refetch={refetch}
					/>
				)}
			</Body>
		</Modal>
	);
};

const mapStateToProps = (state) => {
	return { userDetails: state.Auth.profileDetails };
};

export default connect(mapStateToProps, null)(DailyTasks);
