import i18next from "i18next";
export const required = (value) => {
	if (
		(typeof value === "number" && (value || value === 0)) ||
		(value && value instanceof Array && value.length) ||
		(value && typeof value === "object" && Object.keys(value).length) ||
		(value && typeof value === "string" && value && value !== "") ||
		(value && typeof value === "boolean")
	) {
		return undefined;
	}
	return i18next.t("label.required");
};

export const requiredDateObj = (value) => {
	if (value && typeof value === "object" && JSON.stringify(value) !== "{}") {
		return undefined;
	}
	return i18next.t("label.required");
};

export const requiredDateRange = (value) => {
	if (value && typeof value === "object" && JSON.stringify(value) !== "{}" && value[0] && value[1]) {
		return undefined;
	}
	return i18next.t("label.required");
};

export const isDateObj = (value) => {
	if (value) {
		if (typeof value === "object") {
			return undefined;
		} else {
			return i18next.t("label.invalid_format");
		}
	}
};
export const maxLength = (max) => (value) => value && value.length > max ? `Must be ${max} characters or less` : undefined;

export const max5 = maxLength(5);
export const max25 = maxLength(25);
export const max64 = maxLength(64);
export const max100 = maxLength(100);
export const max160 = maxLength(160);
export const max250 = maxLength(250);

export const minLength = (min) => (value) => value && value.length < min ? `Must be ${min} characters or more` : undefined;

export const number = (value) => (value && isNaN(Number(value)) ? i18next.t("label.must_be_a_number") : undefined);
export const zeroValidation = (value) => (value && value <= 0 ? i18next.t("label.must_be_greater") : undefined);

export const minValue = (min) => (value) =>
	value && value < min ? i18next.t("label.must_be_at_least", { minimum: `${min}` }) : undefined;
export const maxValue = (max) => (value) =>
	value && value > max ? i18next.t("label.cannot_greater_than", { maximum: `${max}` }) : undefined;

export const email = (value) =>
	value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ? "Invalid email address" : undefined;

export const tooYoung = (value) => (value && value < 13 ? "You do not meet the minimum age requirement!" : undefined);

export const aol = (value) => (value && /.+@aol\.com/.test(value) ? "Really? You still use AOL for your email?" : undefined);

export const alphaNumeric = (value) => (value && /[^a-zA-Z0-9 ]/i.test(value) ? "Only alphanumeric characters" : undefined);

export const phoneNumber = (value) => (value && !/^(0|[1-9][0-9]{5,9})$/i.test(value) ? "Must be 6 to 10 digits" : undefined);

export const USPhoneNumber = (value) => {
	const pattern = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
	return value && !pattern.test(value) ? "Not a valid format" : undefined;
};
export const USZipCode = (value) => {
	const pattern = /^\d{5}(-\d{4})?$/;
	return value && !pattern.test(value) ? "Not a valid format" : undefined;
};

export const passwordMatch = (pass1, pass2) => (pass1 && pass2 && pass1 !== pass2 ? "Password does not match" : undefined);

export const passwordStrength = (value) => {
	const pattern = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\\W_])(?=.{8,})");
	return value && !pattern.test(value) ? "Invalid Password format" : undefined;
};

export const time = (value) =>
	value && !/^(0[0-9]|1[0-9]|2[0-3]|[0-9]):[0-5][0-9]$/.test(value) ? "Invalid time format" : undefined;

export const max = (max) => (value) =>
	value && typeof value !== "number" && value > max ? i18next.t("label.must_be_at_least", { max: `${max}` }) : undefined;

export const max100p = (value) => (value && typeof value !== "number" && value > 100 ? `Must be 100 or less` : undefined);

export const min0p = (value) => (value && typeof value !== "number" && value < 0 ? `Must not be less than 0` : undefined);

export const maxInt = max(2147483647);

export const min = (min) => (value) => {
	const val = value ? (typeof value === "object" ? value.value : value) : undefined;
	return val && val < min ? `Must be ${min} or more` : undefined;
};

export const min0 = min(0);
export const min1 = min(1);

export const isInt = (value) => (value && !/^\d+$/.test(value) ? i18next.t("label.provide_an_integer") : undefined);

export const array = (value) => (value && value.length > 0 ? undefined : "Required");

export const url = (value) =>
	value && !/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/i.test(value)
		? "Invalid url"
		: undefined;

export const youtube_url = (value) =>
	value && !/^(?:https?:\/\/)?(?:youtu\.be\/|(?:www\.)?youtube\.com\/watch(?:\.php)?\?.*v=)([a-zA-Z0-9\-_]+)/gm.test(value)
		? "Not a YouTube Video Url"
		: undefined;

export const normalizePhone = (value, previousValue) => {
	if (!value) {
		return value;
	}
	const onlyNums = value.replace(/[^\d]/g, "");
	if (!previousValue || value.length > previousValue.length) {
		// typing forward
		if (onlyNums.length === 3) {
			return "(" + onlyNums + ") ";
		}
		if (onlyNums.length === 6) {
			return "(" + onlyNums.slice(0, 3) + ") " + onlyNums.slice(3) + "-";
		}
	}
	if (onlyNums.length <= 3) {
		return onlyNums;
	}
	if (onlyNums.length <= 6) {
		return "(" + onlyNums.slice(0, 3) + ") " + onlyNums.slice(3);
	}
	return "(" + onlyNums.slice(0, 3) + ") " + onlyNums.slice(3, 6) + "-" + onlyNums.slice(6, 10);
};
export const names = (value) => {
	const pattern = /^[a-z ,.'-]+$/i;
	return value && !pattern.test(value) ? "Not a valid name" : undefined;
};

export const selectLimit = (length) => (value) => {
	return value && value.length > length ? `Not be more than ${length}` : undefined;
};

export const localeStringNumber = (value) =>
	value && isNaN(Number(value.replace(/[^0-9-.]/g, ""))) ? "Must be a number" : undefined;

export const isValidAddressInternalIdObj = (value) => {
	if (value && typeof value === "object") {
		return undefined;
	}
	return "Required";
};

export const minValueBoard = (value) =>
	value && typeof value !== "number" && value < 1 ? i18next.t("label.must_be_greater_than_zero") : undefined;

export const checkPackDate = (value, values) => {
	const dd = (values && values.delivery_date) || null;
	const bbd = (values && values.best_buy_date) || null;
	const shd = (values && values.ship_date) || null;
	const pd = (values && values.pack_date) || null;
	if (dd && pd && dd < pd) {
		return "Date invalid";
	}
	if (bbd && pd && bbd < pd) {
		return "Date invalid";
	}
	if (shd && pd && shd < pd) {
		return "Date invalid";
	}
	return undefined;
};
export const checkBestBuyDate = (value, values) => {
	const dd = (values && values.delivery_date) || null;
	const bbd = (values && values.best_buy_date) || null;
	const pd = (values && values.pack_date) || null;
	const shd = (values && values.ship_date) || null;

	if (dd && bbd && dd < bbd) {
		return "Date invalid";
	}
	if (shd && bbd && shd > bbd) {
		return "Date invalid";
	}
	if (pd && bbd && pd > bbd) {
		return "Date invalid";
	}
	return undefined;
};
export const checkShipDate = (value, values) => {
	const dd = (values && values.delivery_date) || null;
	const bbd = (values && values.best_buy_date) || null;
	const pd = (values && values.pack_date) || null;
	const shd = (values && values.ship_date) || null;

	if (dd && shd && dd < shd) {
		return "Date invalid";
	}
	if (bbd && shd && bbd < shd) {
		return "Date invalid";
	}
	if (pd && shd && pd > shd) {
		return "Date invalid";
	}
	return undefined;
};
