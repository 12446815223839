import React, { useState } from "react";
import { connect } from "react-redux";
import { Container, Button, Row, Col, Form } from "react-bootstrap";
import { reduxForm, Field, reset, change, getFormValues } from "redux-form";
import Util from "../../../utils/Util";
import FormGroups from "../../../utils/formGroup";
import { formCreationFields } from "./formHelper";
import useModal from "../../../utils/useModal";
import { successMessage } from "../../../utils/successMessage";
import { errorMessage } from "../../../utils/errorMessage";
import { createSeedingGerminationTask, updateBasicDetailsOfSGTask } from "../../../store/actions";
import TableLoader from "../../../utils/table/tableLoader";

const SeedingGerminationTaskForm = ({
	formValues,
	handleSubmit,
	change,
	reset,
	form,
	doRefetch,

	createSeedingGerminationTask,
	updateBasicDetailsOfSGTask,
	fetchDropdownArray,

	userDetails,
	dropdownValuesObject,
}) => {
	const [, modalType, , closeModal] = useModal("seedingGerminationTask");

	const [isLoading, setIsLoading] = useState(false);

	// Formatted location array
	const locations = Util.mapOptions(userDetails && userDetails.location_slugs, "name", "slug") || [];

	// On change event
	const onChangeFormFields = (name, e) => {
		if (name === "location_slug") {
			change("product_variety_slug", null);
			change("assignee_id", null);
			fetchDropdownArray(e.value);
		}
	};

	// Submit form
	const submitDetails = (values) => {
		setIsLoading(true);
		const successHandler = (event) => {
			setIsLoading(false);
			doRefetch && doRefetch(event);
			reset(form);
			successMessage(event);
			closeModal();
		};
		const errorHandler = (event) => {
			setIsLoading(false);
			errorMessage(event);
		};
		const body = {
			...values,
			board_count: values.board_count || null,
			description: values.description || null,
			seed_density: values.seed_density || null,
			location_slug: values.location_slug.value,
			product_variety_slug: values.product_variety_slug.value,
			flag_color: values.flag_color && values.flag_color.value,
			top_coat: values.top_coat.value,
			drum_size: values.drum_size && values.drum_size.value,
			assignee_id: (values.assignee_id && values.assignee_id.value) || null,
			task_type: (values.task_type && values.task_type.value) || null,
			expected_germination_date: Util.getYMDFormat(values.expected_germination_date),
			expected_seeding_date: Util.getYMDFormat(values.expected_seeding_date),
			expected_transplant_date: Util.getYMDFormat(values.expected_transplant_date),
		};
		modalType === 1
			? updateBasicDetailsOfSGTask(body.slug, body, successHandler, errorHandler)
			: createSeedingGerminationTask(body, successHandler, errorHandler);
	};

	const isEdit = modalType === 1;
	const minDateForGermination = (formValues && formValues.expected_seeding_date) || new Date();
	const minDateForTransplant =
		(formValues && formValues.expected_germination_date) || (formValues && formValues.expected_seeding_date) || new Date();

	const formFields = formCreationFields(
		locations,
		(dropdownValuesObject && dropdownValuesObject.products) || [],
		(dropdownValuesObject && dropdownValuesObject.usersList) || [],
		minDateForGermination,
		minDateForTransplant,
		isEdit
	);

	// Conditional Form rendering
	const getFormField = (field) => {
		return field.type === "date-picker" ? (
			<Field
				name={field.name}
				type={field.type}
				label={field.label}
				placeholder={field.placeholder}
				component={FormGroups}
				minDate={field.minDate}
				validate={field.validate}
			/>
		) : field.type === "select-react" ? (
			<Field
				name={field.name}
				type={field.type}
				label={field.label}
				placeholder={field.placeholder}
				component={FormGroups}
				validate={field.validate}
				options={field.options || []}
				disable={field.disabled || false}
				multiple={false}
				onChange={(e) => onChangeFormFields(field.name, e)}
				selectMenuHeight={field.maxMenuHeight}
			/>
		) : (
			<Field
				name={field.name}
				type={field.type}
				label={field.label}
				placeholder={field.placeholder}
				component={FormGroups}
				validate={field.validate}
				disable={field.disabled || false}
			/>
		);
	};

	return (
		<Form onSubmit={handleSubmit(submitDetails)} className="board-details-form">
			<Container className="position-relative">
				<Row>
					{formFields.map((field, idx) => (
						<Col xs={field.type === "textarea" ? 12 : 6} key={idx}>
							<Form.Group controlId={`formControl ${idx}`}>{getFormField(field)}</Form.Group>
						</Col>
					))}
					{isEdit && (
						<Col xs={12}>
							<Form.Group controlId="formControl">
								<Field
									name="notify_assignee"
									type="checkbox"
									label="Notify assignee"
									placeholder="Notify"
									component={FormGroups}
									disable={false}
								/>
							</Form.Group>
						</Col>
					)}
				</Row>
				{/* Loader */}
				{isLoading && (
					<div className="table-loader-wrapper">
						<TableLoader />
					</div>
				)}
			</Container>
			<div className="modal-footer save-footer">
				<Button variant="secondary" onClick={closeModal}>
					Cancel
				</Button>
				<Button variant="primary" type="submit" disabled={isLoading}>
					{isLoading ? "Saving..." : "Save"}
				</Button>
			</div>
		</Form>
	);
};

const mapStateToProps = (state) => ({
	userDetails: state.Auth.profileDetails,
	formValues: getFormValues("seeding-germination-task-form")(state),
});

const mapDispatchToProps = {
	reset,
	change,
	createSeedingGerminationTask,
	updateBasicDetailsOfSGTask,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(
	reduxForm({
		form: "seeding-germination-task-form",
		enableReinitialize: true,
	})(SeedingGerminationTaskForm)
);
