import { createSelectorCreator, defaultMemoize } from "reselect";
import { isEmpty, isEqual, values } from "lodash";
import Util from "utils/Util";

const getFertilizers = (state) => state.Fertilizers;

const getForm = (state) => state.form;

const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);

const getDropdownData = createDeepEqualSelector([getFertilizers], (crop) => crop.dropdownData);

export const fertilizerForm = createDeepEqualSelector([getForm], (form) => form.fertilizerForm);

export const getLoading = createDeepEqualSelector([getFertilizers], (fertilizer) => fertilizer && fertilizer.loading);

export const getLocationList = createDeepEqualSelector([getDropdownData], (data) =>
	data && data.location && !isEmpty(data.location) ? Util.mapOptions(values(data.location), "location_name", "slug") : []
);

export const getNutrientList = createDeepEqualSelector([getDropdownData], (data) => data && data.nutrients);

export const getSelectedLocation = createDeepEqualSelector([getFertilizers], (fertilizer) => fertilizer.location);

export const getRenderType = createDeepEqualSelector([getFertilizers], (fertilizer) => fertilizer.renderType);

const getAddData = (location, nutrients) => {
	const data = [
		{
			name: "",
			slug: 0,
			unit: "",
			unit_weight: "",
			data: nutrients.map((item) => ({
				nutrient: item.nutrient,
				nutrient_slug: item.slug,
				nutrient_value: "",
			})),
		},
	];

	return data;
};

export const getTableData = createDeepEqualSelector(
	[getSelectedLocation, getRenderType, getNutrientList, getFertilizers],
	(location, render, nutrients, fertilizer) =>
		location && nutrients
			? render === 1
				? getAddData(location, nutrients)
				: fertilizer.data
				? fertilizer.data
				: null
			: null
);

const getNutrientsColumn = (nutrients) => {
	const data = nutrients.map((item) => ({
		name: item.nutrient,
		slug: item.slug,
	}));
	return data;
};

export const getColumns = createDeepEqualSelector([getNutrientList], (nutrients) =>
	nutrients ? getNutrientsColumn(nutrients) : null
);

export const getData = createDeepEqualSelector([getFertilizers], (fertilizer) => fertilizer.data);

const fertilizersPermit = (state) => state.Auth.permissionDetails.fertilizers;

export const getAddPermission = createDeepEqualSelector(
	[fertilizersPermit],
	(access) => access && access.permissions.add_fertilisers
);

export const getEditPermission = createDeepEqualSelector(
	[fertilizersPermit],
	(access) => access && access.permissions.edit_fertilisers
);

export const getDeletePermission = createDeepEqualSelector(
	[fertilizersPermit],
	(access) => access && access.permissions.delete_fertilisers
);

export const getListPermission = createDeepEqualSelector(
	[fertilizersPermit],
	(access) => access && access.permissions.list_fertilisers
);
