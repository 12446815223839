import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { setLoaderWSR, resetRefreshWSR, setDataWSR, editDataWSR, deleteDataWSR } from "store/actions";
import {
	getColumnsWSR,
	getDataWSR,
	getDateSlugMapping,
	getEditPermission,
	getDeletePermission,
} from "store/selectors/waterSourceSelectors";
import ActionTable from "utils/actionTable";
import ConfirmModal from "utils/confirmModal";
import { errorMessage } from "utils/errorMessage";
import { successMessage } from "utils/successMessage";
import WaterSourceForm from "../manage";

const Listing = ({
	columns,
	data,
	resetRefreshWSR,
	setLoaderWSR,
	dateMapping,
	setDataWSR,
	editDataWSR,
	deleteDataWSR,
	editPermission,
	deletePermission,
}) => {
	const [deleteItem, setdeleteItem] = useState(null);

	useEffect(() => {
		return () => {
			resetRefreshWSR();
		};
	}, [resetRefreshWSR]);

	const onSave = (values, slug, editableChange) => {
		setLoaderWSR();
		const tempData = [];
		for (const key in values) {
			tempData.push({
				nutrient_slug: key,
				nutrient_value: values[key],
			});
		}
		const submitData = {
			water_source_results: [
				{
					water_source_result_slug: dateMapping[slug],
					nutrients: tempData,
				},
			],
		};

		const successHandler = (e) => {
			successMessage(e);
			const arr = [];

			for (const key in values) {
				arr.push({
					slug: key,
					value: values[key],
				});
			}

			setDataWSR({ ...data, [slug]: arr });
			setLoaderWSR();
			editableChange();
		};
		const errorHandler = (e) => {
			errorMessage(e);
			setLoaderWSR();
		};
		editDataWSR(submitData, successHandler, errorHandler);
	};

	const onDelete = () => {
		setLoaderWSR();

		const successHandler = (e) => {
			successMessage(e);

			// const tempData = data;
			// delete tempData[deleteItem.date];
			const tempData = {};
			for (const key in data) {
				if (key !== deleteItem.date) {
					tempData[key] = data[key];
				}
			}

			setDataWSR(tempData);

			setdeleteItem(null);
			setLoaderWSR();
		};
		const errorHandler = (e) => {
			errorMessage(e);
			setLoaderWSR();
			setdeleteItem(null);
		};
		deleteDataWSR(deleteItem.slug, successHandler, errorHandler);
	};
	return (
		<div className="listing-wrapper-croptimals">
			<WaterSourceForm />

			{data &&
				columns &&
				(!isEmpty(data) ? (
					<ActionTable
						editable={true}
						columns={columns}
						fixedColumnHeading="Week"
						initialData={data}
						onSave={onSave}
						dateFormat="MM-DD-YYYY"
						onDelete={(slug) => setdeleteItem({ date: slug, slug: dateMapping[slug] })}
						editPermission={editPermission}
						deletePermission={deletePermission}
					/>
				) : (
					<div className="no-results-found text-center">No Results Found</div>
				))}

			<ConfirmModal
				show={deleteItem ? true : false}
				onNo={() => setdeleteItem(null)}
				onYes={onDelete}
				message="Are you sure want to delete this data ?"
			/>
		</div>
	);
};

const mapStateToProps = createStructuredSelector({
	data: getDataWSR,
	columns: getColumnsWSR,
	dateMapping: getDateSlugMapping,
	editPermission: getEditPermission,
	deletePermission: getDeletePermission,
});

export default connect(mapStateToProps, {
	setLoaderWSR,
	resetRefreshWSR,
	setDataWSR,
	editDataWSR,
	deleteDataWSR,
})(Listing);
