import React, { useEffect } from "react";
import { Tabs, Tab } from "react-bootstrap";
import { compact, join, map } from "lodash";
import ToggleButton from "../../../utils/toggleButton";
import ProductImageSlider from "./productImageSlider";

const ProductView = ({ productDetails, clearProductDetails, changeProductVarityStatus, currentLocation }) => {
	useEffect(() => {
		return () => {
			clearProductDetails();
		};
	}, [clearProductDetails]);
	const getImagesArray = (a, b, c) => compact([a, b, c]);

	const labelledValue = (label, value) => (
		<div className="mw-pv-data mb-5">
			<p className="d-flex align-items-center text-muted mb-1">
				<span className="light-badge-pill"></span>
				{label}
			</p>
			<h6 className="ml-4">{value ? value : "-"}</h6>
		</div>
	);

	const plural = (val) => (+val !== 1 ? "s" : "");
	const renderSeasonTabContent = (season = {}, locations = []) => {
		const {
			germination_period,
			grow_cycle,
			harvesting_period,
			shelflife_period,
			seed_density,
			board,
			germination_temperature,
			germination_humidity,
			germination_time,
			growing_temperature,
			growing_humidity,
			growing_light,
			ph,
			ec,
			pond_temperature,
			price_per_pound,
		} = season;
		return (
			<div>
				<h2 className="text-md font-weight-bold mb-4">Product Information</h2>
				<div className="d-flex mb-3 flex-wrap">
					{labelledValue("Germination Period", `${germination_period} Day${plural(germination_period)}`)}
					{labelledValue("Grow Cycle Period", `${grow_cycle} Day${plural(grow_cycle)}`)}
					{labelledValue("Harvesting Period", `${harvesting_period} Day${plural(harvesting_period)}`)}
					{labelledValue("Shelf life Period", `${shelflife_period} Day${plural(shelflife_period)}`)}
				</div>
				<h2 className="text-md font-weight-bold mb-4">Germination Conditions</h2>
				<div className="d-flex mb-3 flex-wrap">
					{labelledValue("Temperature", `${germination_temperature} C`)}
					{labelledValue("Humidity", `${germination_humidity} %`)}
					{labelledValue("Germination Time", germination_time)}
				</div>
				<h2 className="text-md font-weight-bold mb-4">Growing Conditions</h2>
				<div className="d-flex mb-3 flex-wrap">
					{labelledValue("Temperature", `${growing_temperature} C`)}
					{labelledValue("Humidity", `${growing_humidity} %`)}
					{labelledValue("Light (Avg. Par/day)", growing_light)}
				</div>
				<h2 className="text-md font-weight-bold mb-4">Pond Metrics</h2>
				<div className="d-flex mb-3 flex-wrap">
					{labelledValue("PH", ph)}
					{labelledValue("DO", season.do)}
					{labelledValue("EC", ec)}
					{labelledValue("Pond Temperature (C)", `${pond_temperature} C`)}
				</div>
				<div className="d-flex flex-wrap">
					<div className="d-block">
						<h2 className="text-md font-weight-bold mb-4">Price</h2>
						{labelledValue("Price", `$ ${price_per_pound}`)}
					</div>
					<div className="d-block">
						<h2 className="text-md font-weight-bold mb-4">Seed Density</h2>
						{labelledValue("Seed Density", seed_density)}
					</div>
					<div className="d-block">
						<h2 className="text-md font-weight-bold mb-4">Styrofoam Boards</h2>
						{labelledValue("Styrofoam Boards", board && board.board_name)}
					</div>
				</div>
			</div>
		);
	};

	return (
		<>
			<h1 className="pl-2 mb-4">{productDetails.green.green_name || "Green"}</h1>
			<Tabs className="product-view-tab" id="pv-view-seeds-tab">
				{productDetails &&
					productDetails.product_variety_list &&
					productDetails.product_variety_list.map((pv, i) => (
						<Tab key={`PV_VIEW_SEEDS_${pv.slug}`} eventKey={`PV_VIEW_SEEDS_${pv.slug}`} title={pv.seed.seed_name}>
							<div className="product-view-sub-tab-wrapper">
								<div className="product-view-sub-tab-image show-md">
									<ProductImageSlider
										imagesArr={getImagesArray(pv.first_image, pv.second_image, pv.third_image)}
									/>
								</div>
								<div className="product-view-sub-tab-content">
									<div className="d-block ml-4">
										<div className="product-view-sub-tab-content-header">
											<h2 className="text-md font-weight-bold mb-4">Locations</h2>
											<ToggleButton
												value={pv.active}
												onChange={(e) =>
													changeProductVarityStatus(
														currentLocation.value,
														pv.slug,
														!pv.active,
														productDetails.slug
													)
												}
											/>
										</div>
										{labelledValue("Locations", join(map(pv.locations, "location_name"), ", "))}
									</div>
									<Tabs className="view-tabs" id="pv-view-seeds-seasons-tab">
										{pv.seasons.map((season, i) => (
											<Tab
												title={season.season_name || `SEASON ${season.season_id}`}
												key={`PV_VIEW_SEASON_${season.season_id}_${i}`}
												eventKey={`PV_VIEW_SEASON_${season.season_id}_${i}`}>
												{renderSeasonTabContent(season)}
											</Tab>
										))}
									</Tabs>
								</div>
								<div className="product-view-sub-tab-image show-lg">
									<ProductImageSlider
										imagesArr={getImagesArray(pv.first_image, pv.second_image, pv.third_image)}
									/>
								</div>
							</div>
						</Tab>
					))}
			</Tabs>
		</>
	);
};

export default ProductView;
