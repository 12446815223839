import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import {
	setLoaderLR,
	resetRefreshLR,
	setDataLR,
	editDataLR,
	deleteDataLR,
	fetchCroptimalValue,
	setCroptimalSelection,
	setDateMapping,
	setActiveTabPondLR,
	fetchDataLR,
	fetchEditDataLR
} from "store/actions";
import {
	getColumnsLR,
	getDataLR,
	getDateSlugMapping,
	getFormFromDate,
	getFormLocation,
	getFormToDate,
	getLocationData,
	getSelectedLocationProductVariety,
	getCroptimalData,
	getRenderType,
	getEditPermission,
	getDeletePermission,
	getProductVarietySelected,
	getActivePondTab,
	getTabsPonds,
} from "store/selectors/labResultSelectors";
import ActionTable from "utils/actionTable";
import ConfirmModal from "utils/confirmModal";
import { errorMessage } from "utils/errorMessage";
import { successMessage } from "utils/successMessage";
import LabResultForm from "../manage";
import LabResultTable from "utils/labResultTable";
import { Tab, Tabs } from "react-bootstrap";
import { isEmpty } from "lodash";
const Listing = ({
	columns,
	data,
	resetRefreshLR,
	setLoaderLR,
	dateMapping,
	setDataLR,
	editDataLR,
	deleteDataLR,
	location,
	fromDate,
	toDate,
	fetchCroptimalValue,
	locationData,
	selectedLocationProductVariety,
	croptimalData,
	renderType,
	editPermission,
	deletePermission,
	setCroptimalSelection,
	productVarietySelected,
	setDateMapping,
	activePondTab,
	pondTabs,
	setActiveTabPondLR,
	fetchDataLR,
	fetchEditDataLR
}) => {
	const [deleteItem, setdeleteItem] = useState(null);

	useEffect(() => {
		return () => {
			resetRefreshLR();
		};
	}, [resetRefreshLR]);

	const onSave = (values, slug, editableChange) => {
		setLoaderLR();
		const tempData = [];
		for (const key in values) {
			tempData.push({
				nutrient_slug: key,
				nutrient_value: values[key],
			});
		}
		const submitData = {
			lab_results: [
				{
					lab_result_slug: slug,
					nutrients: tempData,
				},
			],
		};

		const successHandler = (e) => {
			successMessage(e);
			const arr = [];

			for (const key in values) {
				arr.push({
					slug: key,
					value: values[key],
				});
			}
			const submitData = {
				location_slug: location,
				pond: pondTabs.map((item) => item.key),
				start_date: fromDate,
				end_date: toDate,
			};
			fetchEditDataLR(submitData);
			setDataLR({ ...data, [slug]: arr });
			setLoaderLR();
			editableChange();
		};
		const errorHandler = (e) => {
			errorMessage(e);
			setLoaderLR();
		};
		editDataLR(submitData, successHandler, errorHandler);
	};

	const onDelete = () => {
		setLoaderLR();

		const successHandler = (e) => {
			successMessage(e);
			const submitData = {
				location_slug: location,
				pond: pondTabs.map((item) => item.key),
				start_date: fromDate,
				end_date: toDate,
			};
			fetchDataLR(submitData);
			const tempData = data;
			delete tempData[deleteItem.date];
			setDataLR(tempData);
			const keyMap = dateMapping.filter((item) => item.slug !== deleteItem.date);
			setDateMapping(keyMap);
			setdeleteItem(null);
			setLoaderLR();
		};
		const errorHandler = (e) => {
			errorMessage(e);
			setLoaderLR();
			setdeleteItem(null);
			
		};
		deleteDataLR(deleteItem.slug, successHandler, errorHandler);
	};

	const getNutrientCroptimalsValues = (value) => {
		const submitData = {
			location_slug: location,
			product_variety_slug: value ? value.value : null,
			start_date: fromDate,
			end_date: toDate,
		};
		const successHandler = (data) => {
			setCroptimalSelection(value);
		};
		const errorHandler = (e) => {
			errorMessage(e);
		};

		fetchCroptimalValue(submitData, successHandler, errorHandler);
	};

	return (
		<div className="listing-wrapper-croptimals">
			<LabResultForm />

			{!isEmpty(pondTabs) ? (
				<div className="tab-wrapper">
					<Tabs
						className="customer-tab"
						id="uncontrolled-tab-inner"
						activeKey={activePondTab}
						defaultActiveKey={activePondTab}
						mountOnEnter={true}
						onSelect={(k) => {
							setActiveTabPondLR(k);
						}}>
						{pondTabs &&
							pondTabs.map((item, index) => (
								<Tab
									className="dc-tab-cell"
									eventKey={item.label}
									key={index}
									title={
										<span>
											<i className="icon icon-market mr-3" />
											{item.label}
										</span>
									}>
									{activePondTab === item.label && data && Object.keys(data).length !== 0 && columns ? (
										croptimalData && data ? (
											<>
												{renderType === 0 && (
													<div className="croptimal-data-table-height">
														<LabResultTable
															getNutrientCroptimalsValues={getNutrientCroptimalsValues}
															editable={false}
															columns={columns}
															fixedColumnHeading=""
															initialData={croptimalData}
															productVarietyData={selectedLocationProductVariety}
															productVarietySelected={productVarietySelected}
														/>
													</div>
												)}
												<ActionTable
													editable={true}
													columns={columns}
													fixedColumnHeading="Week"
													keyMapping={dateMapping}
													initialData={data}
													onSave={onSave}
													dateFormat="MM-DD-YYYY"
													editPermission={editPermission}
													deletePermission={deletePermission}
													onDelete={(slug) => setdeleteItem({ date: slug, slug: slug })}
												/>
											</>
										) : (
											columns && <div className="no-results-found text-center">No Results Found</div>
										)
									) : null}
								</Tab>
							))}
					</Tabs>
				</div>
			):columns && (
				<div className="no-results-found text-center">No Results Found</div>
			)}
			<ConfirmModal
				show={deleteItem ? true : false}
				onNo={() => setdeleteItem(null)}
				onYes={onDelete}
				message="Are you sure want to delete this result ?"
			/>
		</div>
	);
};

const mapStateToProps = createStructuredSelector({
	data: getDataLR,
	columns: getColumnsLR,
	dateMapping: getDateSlugMapping,
	fromDate: getFormFromDate,
	toDate: getFormToDate,
	location: getFormLocation,
	locationData: getLocationData,
	selectedLocationProductVariety: getSelectedLocationProductVariety,
	croptimalData: getCroptimalData,
	renderType: getRenderType,
	editPermission: getEditPermission,
	deletePermission: getDeletePermission,
	productVarietySelected: getProductVarietySelected,
	activePondTab: getActivePondTab,
	pondTabs: getTabsPonds,
});

export default connect(mapStateToProps, {
	setLoaderLR,
	resetRefreshLR,
	setDataLR,
	editDataLR,
	deleteDataLR,
	fetchCroptimalValue,
	setCroptimalSelection,
	setDateMapping,
	setActiveTabPondLR,
	fetchDataLR,
	fetchEditDataLR
})(Listing);
