import React, { useState, useEffect } from "react";
import { get } from "lodash";
import { Row, Col } from "react-bootstrap";
import { ContentWrap, Breadcrumbs, BackButton } from "elements";
import {
	changeRenderType,
	fetchHarvestDetails,
	createStartTask,
	completeTask,
	resetAddEdit,
	getHarvestAuditLog,
} from "store/actions";
import { connect } from "react-redux";
import TableLoader from "utils/table/tableLoader";
import moment from "moment";
import { viewBoardForm, harvestedTableForm } from "./helper";
import RenderViewTable from "utils/table/viewTable";
import ViewModal from "./viewModal";
import useModal from "utils/useModal";
import { errorMessage } from "utils/errorMessage";
import { successMessage } from "utils/successMessage";
import AuditLog from "components/auditLog";
import Util from "utils/Util";
import { useTranslation } from 'react-i18next';

const HarvestView = ({
	task_id,
	status,
	expected_harvesting_date,
	harvesting_board_details,
	product_variety,
	forecast_oz_per_board,
	forecast_no_of_boards,
	forecast_harvest_weight,
	harvesting_task_description,
	season_details,
	harvested_board_details,
	wastage_pound,
	actual_grow_cycle,
	down_time,
	harvested_comments,
	harvested_blade_height,
	boards_broken,
	blades_replaced,
	changeRenderType,
	fetchHarvestDetails,
	createStartTask,
	completeTask,
	resetAddEdit,
	wastage_percent,
	selected_location,
	activityLog,
	getHarvestAuditLog,
	season_name,
	permissionDetails,
	harvesting_start_date,
	harvesting_end_date,
	harvestActive,
	harvested_blade_height_start_range,
	harvested_blade_height_end_range,
}) => {
	const { t } = useTranslation();
	const [viewLoading, setviewLoading] = useState(false);
	const [, , showModal] = useModal("harvest-view");
	const [startTitle, setstartTitle] = useState(t("label.startButton"));
	const [completeTitle, setcompleteTitle] = useState(t("label.complete_task"));
	const [auditLoading, setauditLoading] = useState(false);
	const auditLogPermission =
		permissionDetails &&
		permissionDetails.harvesting &&
		permissionDetails.harvesting.permissions.view_harvesting_task_audit_log
			? permissionDetails.harvesting.permissions.view_harvesting_task_audit_log
			: "";
	const incompletedTaskEditPermission =
		permissionDetails && permissionDetails.harvesting && permissionDetails.harvesting.permissions.harvesting_log_details
			? permissionDetails.harvesting.permissions.harvesting_log_details
			: "";
	const completedTaskEditPermission =
		permissionDetails &&
		permissionDetails.harvesting &&
		permissionDetails.harvesting.permissions.update_completed_log_details
			? permissionDetails.harvesting.permissions.update_completed_log_details
			: "";
	const logPermission = status === "Completed" ? completedTaskEditPermission : incompletedTaskEditPermission;

	useEffect(() => {
		return async () => {
			await resetAddEdit();
		};
	}, [task_id, resetAddEdit]);

	const onStartTask = () => {
		if (!viewLoading) {
			setviewLoading(true);
			setstartTitle(t("label.startingButton"));
			const onSuccess = (e) => {
				setviewLoading(false);
			};
			const successHandler = (event) => {
				successMessage(event);
				fetchHarvestDetails(task_id, onSuccess, errorHandler);
			};
			const errorHandler = (event) => {
				errorMessage(event);
				setviewLoading(false);
			};

			createStartTask(task_id, successHandler, errorHandler);
		}
	};

	const onCompleteTask = () => {
		if (!viewLoading) {
			setviewLoading(true);
			setcompleteTitle(t("label.completing"));
			const onSuccess = (e) => {
				setviewLoading(false);
			};
			const successHandler = (event) => {
				successMessage(event);
				fetchHarvestDetails(task_id, onSuccess, errorHandler);
			};
			const errorHandler = (event) => {
				errorMessage(event);
				setviewLoading(false);
			};
			completeTask(task_id, successHandler, errorHandler);
		}
	};

	const fetchAuditLog = () => {
		setauditLoading(true);
		const successHandler = () => {
			setauditLoading(false);
		};
		const errorHandler = (err) => {
			errorMessage(err);
			setauditLoading(false);
		};
		getHarvestAuditLog(task_id.toLowerCase(), successHandler, errorHandler);
	};

	const labelledValue = (label, value, unit = null, colSize = 4, colLGSize = 3) => (
		<Col className={`col-lg-${colLGSize} col-md-${colSize} col-12 mb-3`} key={label}>
			{(label || value) && (
				<div key={label}>
					<p className="d-flex align-items-center text-muted mb-2">
						<span className="light-badge-pill"></span>
						<span className="view-labels">{label}</span>
					</p>
					<h6 className="ml-4 view-values">{value ? `${value} ${unit ? unit : ""}` : "-"}</h6>
				</div>
			)}
		</Col>
	);

	const renderView = () => {
		return (
			<>
				<div className="position-relative">
					<div className="outer-box-view">
						<Row>
							<h2 className="form-headers">{t("label.task_details")}</h2>
						</Row>
						<Row>
							{labelledValue(t("label.task_id"), task_id)}
							{labelledValue(t("label.location"), selected_location.label)}
							{labelledValue(t("label.expected_harvesting_date"), expected_harvesting_date)}
							{labelledValue(t("label.product_variety"), product_variety)}
							{labelledValue(t("label.season"), season_name)}
							{labelledValue(t("label.forcast_oz_per_board"), forecast_oz_per_board)}
							{labelledValue(t("label.forcast_no_of_boards"), forecast_no_of_boards)}
							{labelledValue(t("label.forcast_harvest_lbs"), forecast_harvest_weight, "Lbs")}
						</Row>
						<Row className="mb-4">{labelledValue(t("label.description"), harvesting_task_description, "", 12, 6)}</Row>
						{viewBoardForm &&
							viewBoardForm.length > 0 &&
							harvesting_board_details &&
							harvesting_board_details.length > 0 && (
								<div className="mb-5 mt-4">
									<RenderViewTable form={viewBoardForm} data={harvesting_board_details} />
								</div>
							)}
					</div>

					{harvested_board_details && harvested_board_details.length > 0 && (
						<div className="outer-box-view">
							<div className="d-flex justify-content-between">
								<Row>
									<h2 className="form-headers">{t("label.harvesting_details")}</h2>
								</Row>
								{logPermission && harvestActive && (
									<button
										onClick={() => showModal(2)}
										className="btn btn-cell btn-light btn-sm ml-2 light-border">
										<i className="icon icon-edit" />
									</button>
								)}
							</div>
							<div className="mb-5 mb-1">
								{logPermission ? (
									<RenderViewTable
										form={harvestedTableForm}
										data={harvested_board_details}
										addItem={() => {
											const pondDetails = harvested_board_details.map((item) => ({
												pond: item.pond,
												lane: item.lane,
											}));
											showModal(3, pondDetails);
										}}
									/>
								) : (
									<RenderViewTable form={harvestedTableForm} data={harvested_board_details} />
								)}
							</div>
							<Row>
								{labelledValue(t("label.actual_harvesting_start_Date"), harvesting_start_date)}
								{labelledValue(t("label.total_boards_harvested"), getSum("total_boards_harvested"))}
								{labelledValue(t("label.total_lbs_harvested"), getSum("total_lbs_harvested"))}
								{labelledValue(t("label.actual_lbs_harvested"), getSum("actual_lbs_harvested"))}
								{labelledValue(t("label.actual_grow_cycle"), actual_grow_cycle)}
								{labelledValue(t("label.down_time"), down_time)}
								{labelledValue(t("label.wastage_lbs"), wastage_pound)}
								{labelledValue(
									t("label.blade_height_range"),
									`${harvested_blade_height_start_range ? harvested_blade_height_start_range : ""} - ${
										harvested_blade_height_end_range ? harvested_blade_height_end_range : ""
									} `
								)}
								{labelledValue(t("label.boards_broken"), boards_broken)}
								{labelledValue(t("label.blades_replaced"), blades_replaced === 1 ? "Yes" : "No")}
								{labelledValue(t("label.actual_harvesting_end_date_and_time"), harvesting_end_date)}
								{labelledValue(t("label.comments"), harvested_comments, "", 6, 6)}
							</Row>
						</div>
					)}

					{/* Product Information */}
					{season_details && (
						<div className="outer-box-view">
							<Row>
								<h2 className="form-headers">{t("label.product_information")}</h2>
							</Row>
							<Row className="mb-4">
								{labelledValue(
									t("label.germination_period"),
									season_details.germination_period,
									season_details.germination_period === "1" ? "day" : "days"
								)}
								{labelledValue(
									t("label.gorw_cycle_period"),
									season_details.grow_cycle,
									season_details.grow_cycle === "1" ? "day" : "days"
								)}
								{labelledValue(
									t("label.harvesting_period"),
									season_details.harvesting_period,
									season_details.harvesting_period === "1" ? "day" : "days"
								)}
								{labelledValue(
									t("label.shelf_life_period"),
									season_details.shelflife_period,
									season_details.shelflife_period === "1" ? "day" : "days"
								)}
							</Row>
							<Row>
								<h2 className="form-headers">{t("label.growing_condition")}</h2>
							</Row>
							<Row className="mb-4">
								{labelledValue(t("label.temperature"), season_details.growing_temperature, "C")}
								{labelledValue(t("label.humidity"), season_details.growing_humidity, "%")}
								{labelledValue(t("label.light"), season_details.growing_light)}
								{labelledValue("", "")}
							</Row>
							<Row>
								<h2 className="form-headers">{t("label.germination_condition")}</h2>
							</Row>
							<Row>
								{labelledValue(t("label.temperature"), season_details.germination_temperature, "C")}
								{labelledValue(t("label.humidity"), season_details.germination_humidity, "%")}
								{labelledValue(
									t("label.germination_time"),
									season_details.germination_time,
									season_details.germination_time === "1" ? "day" : "days"
								)}
								{labelledValue(t("label.seed_density"), season_details.seed_density, "C")}
							</Row>
						</div>
					)}

					{viewLoading && (
						<div className="table-loader-wrapper">
							<TableLoader />
						</div>
					)}
				</div>

				{/* Status Bar */}
				{status === "New" ? (
					<div className="mb-2 mt-2 start-task-row text-right">
						{logPermission && harvestActive && (
							<button
								style={{ width: 110 }}
								onClick={onStartTask}
								varient="btn-primary"
								type="button"
								className="btn btn-primary ">
								{startTitle}
							</button>
						)}
					</div>
				) : status === "In progress" && harvestActive ? (
					<div className="outer-box-view view-bottom-box mt-2 mb-2 pt-3 pb-3 d-xl-flex d-lg-flex d-md-flex justify-content-between">
						<h2>
							<span className="view-labels" style={{ paddingRight: 2 }}>
							{t("label.status")}
							</span>
							{t("label.harvesting_in_progress")}
							{harvesting_start_date && (
								<p className="status-bar-subtitle">
									<span className="view-labels">{t("label.actual_harvesting_start_date_and_time")}</span>
									<span className="font-weight-bold">{harvesting_start_date}</span>
								</p>
							)}
						</h2>
						{harvested_board_details.length > 0 && logPermission && harvestActive ? (
							<button onClick={onCompleteTask} type="button" className="btn btn-primary ">
								{completeTitle}
							</button>
						) : (
							logPermission &&
							harvestActive && (
								<button
									onClick={() => showModal(1)}
									varient="btn-primary"
									type="button"
									className="btn btn-primary ">
									{t("label.enter_harvesting_details")}
								</button>
							)
						)}
					</div>
				) : null}

				{/* Log */}
				{auditLogPermission && (
					<AuditLog log={activityLog} loading={auditLoading} onClickStatusAction={fetchAuditLog} />
				)}
			</>
		);
	};

	const getSum = (field) => {
		if (field === "total_boards_harvested") {
			let sum = 0;

			harvested_board_details.forEach((item) => {
				sum += Number(item.board_count);
			});
			return sum;
		} else if (field === "total_lbs_harvested") {
			let sum = 0;

			harvested_board_details.forEach((item) => {
				sum += item.weight.reduce((a, b) => Number(a) + Number(b), 0);
			});

			return Number.isInteger(sum) ? sum : sum.toFixed(2);
		} else if (field === "actual_lbs_harvested") {
			let sum = 0;

			harvested_board_details.forEach((item) => {
				sum += item.weight.reduce((a, b) => Number(a) + Number(b), 0);
			});
			const actual_sum = sum - sum * (Number(wastage_percent) / 100);
			return Number.isInteger(actual_sum) ? actual_sum : actual_sum.toFixed(2);
		}
	};

	return (
		<ContentWrap>
			<div className="d-flex justify-content-between">
				<div className="d-flex justify-content-between mb-3">
					<BackButton onClick={() => changeRenderType(0)} />
					<Breadcrumbs keyValue="harvesting" showLabel={true} label={t("label.view")} />
				</div>
			</div>
			{renderView()}
			<ViewModal />
		</ContentWrap>
	);
};

const mapStateToProps = (state) => {
	const {
		Harvest,
		Harvest: { harvestDetails },
	} = state;

	const season_details = get(harvestDetails, "product_variety.season_details", null);

	const selectedLocation = get(Harvest, "selectedLocation", null);

	return {
		activityLog: get(Harvest, "activityLog", ""),
		status: get(harvestDetails, "status", ""),
		startTask: Harvest.startTask,
		task_id: get(harvestDetails, "task_id", ""),
		expected_harvesting_date: get(harvestDetails, "expected_harvesting_date", "")
			? moment(harvestDetails.expected_harvesting_date).format("MM/DD/YYYY")
			: "",
		harvesting_start_date: get(harvestDetails, "harvesting_start_date", "")
			? Util.utcToLocal(harvestDetails.harvesting_start_date)
			: "",
		harvesting_end_date: get(harvestDetails, "harvesting_end_date", "")
			? Util.utcToLocal(harvestDetails.harvesting_end_date)
			: "",
		product_variety: get(harvestDetails, "product_variety.name", ""),
		forecast_oz_per_board: get(harvestDetails, "forecast_oz_per_board", ""),
		forecast_no_of_boards: get(harvestDetails, "forecast_no_of_boards", ""),
		forecast_harvest_weight: get(harvestDetails, "forecast_harvest_weight", ""),
		harvesting_task_description: get(harvestDetails, "harvesting_task_description", ""),
		wastage_pound: get(harvestDetails, "wastage_pound", ""),
		actual_grow_cycle: get(harvestDetails, "actual_grow_cycle", ""),
		down_time: get(harvestDetails, "down_time", ""),
		boards_broken: get(harvestDetails, "boards_broken", ""),
		blades_replaced: get(harvestDetails, "blades_replaced", 0),
		harvested_blade_height_start_range: get(harvestDetails, "harvested_blade_height_start_range", ""),
		harvested_blade_height_end_range: get(harvestDetails, "harvested_blade_height", ""),
		harvested_comments: get(harvestDetails, "harvested_comments", ""),
		selected_location: selectedLocation,
		season_details: season_details,
		harvesting_board_details: get(harvestDetails, "harvesting_board_details", []),
		harvested_board_details: get(harvestDetails, "harvested_board_details", []),
		wastage_percent: selectedLocation && get(Harvest, `taskUser[${selectedLocation.value}].wastage_percentage`, null),
		season_name: get(harvestDetails, "season", null),
		permissionDetails: state.Auth.permissionDetails,
		harvestActive: state?.Harvest?.harvestDetails?.product_variety?.active,
	};
};
export default connect(mapStateToProps, {
	changeRenderType,
	fetchHarvestDetails,
	createStartTask,
	completeTask,
	resetAddEdit,
	getHarvestAuditLog,
})(HarvestView);
