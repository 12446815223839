import React, { useEffect } from "react";
import { Breadcrumbs, ContentWrap } from "elements";
import TableLoader from "utils/table/tableLoader";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { getLoading } from "store/selectors/laneCalculatorSelectors";
import { getLocationList, setLoaderLc } from "store/actions";
import { errorMessage } from "utils/errorMessage";
import Listing from "./listing";
const LaneCalculator = ({ loading, getLocationList, setLoaderLc }) => {
	useEffect(() => {
		setLoaderLc();
		const successHandler = () => {
			setLoaderLc();
		};
		const errorHandler = (e) => {
			errorMessage(e);
		};
		getLocationList(successHandler, errorHandler);
	}, [getLocationList, setLoaderLc]);
	return (
		<ContentWrap>
			<div className="d-flex align-items-center mb-3">
			<Breadcrumbs
					className="forecast-breadcrumb"
					keyValue="laneCalculator"
					showLabel={true}
					label={"Actual Production Vs Sales"}
				/>
			</div>
			<div className="position-relative">
				<Listing />
				{loading && (
					<div className="table-loader-wrapper">
						<TableLoader />
					</div>
				)}
			</div>
		</ContentWrap>
	);
};
const mapStateToProps = createStructuredSelector({
	loading: getLoading,
});

export default connect(mapStateToProps, { getLocationList, setLoaderLc })(LaneCalculator);
