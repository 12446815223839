import { SKU } from "../types";

const initialState = {
	allLocationsList: [],
	skuDetails: {},
	productList: [],
	allSkuDetails: [],
};

export default function (state = initialState, { type, payload = {} }) {
	switch (type) {
		case SKU.FETCH_SKU_LOCATION_LIST:
			return {
				...state,
				allLocationsList: payload.data,
			};
		case SKU.FETCH_SKU_DETAILS:
			state = {
				...state,
				skuDetails: payload.data || {},
			};
			return state;
		case SKU.FETCH_SKU_PRODUCT_LIST:
			return {
				...state,
				productList: payload.data,
			};
		case SKU.FETCH_SKU:
			return {
				...state,
				allSkuDetails: payload.data,
			};
		default:
			return state;
	}
}

