import { required } from "../../constant/formValidators";

export const formCreationFields = [
	{
		label: "Location *",
		name: "location",
		type: "select-react",
		placeholder: "Select Location",
	},
	{
		label: "Compartment *",
		name: "compartment",
		type: "select-react",
		placeholder: "Select Compartment",
	},

	{
		label: "Parameters *",
		name: "parameters",
		type: "select-react-expand-all",
		placeholder: "Select Parameters",
		multiple: true,
	},
	{
		label: "Date (From - To) *",
		name: "date",
		type: "date-range-picker",
		placeholder: "Enter date range",
	},
];

export const getValidations = (field) => {
	switch (field) {
		default:
			return [required];
	}
};
