import React, { useState, useEffect } from "react";
import { get, isEmpty, find, reject, map, includes, filter } from "lodash";
import { connect } from "react-redux";
import { DropdownButton, Dropdown, Button } from "react-bootstrap";
import { ContentWrap, ButtonPrimary, Breadcrumbs, BackButton } from "elements";
import HarvestManage from "../manage/";
import HarvestView from "../detail";
import {
	fetchHarvests,
	fetchHarvestDetails,
	changeRenderType,
	deleteHarvest,
	allstatusList,
	fetchTaskUsers,
	fetchLocationUsers,
	changeLocation,
	fetchHarvestingTaskOrder,
	fetchSetUpTime,
	deleteSetUpTime,
	fetchCleaningTime,
	deleteCleaningTime,
} from "store/actions";
import Listing from "partials/listings";
import ConfirmModal from "utils/confirmModal";
import { errorMessage } from "utils/errorMessage";
import { successMessage } from "utils/successMessage";
import { renderColumns, renderSetupTimeColumns, renderCleaningTimeColumns } from "./helper";
import useModal from "utils/useModal";
import Util from "utils/Util";
import {
	LocationDropdown,
	AssigneeButton,
	CommonDatePicker,
	LocationOrderDropdown,
} from "components/bulkAssignee/bulkAssigneeComponents";
import BulkAssignee from "components/bulkAssignee";
import RepeatAllTasks from "components/repeatAllTasks";
import "../harvesting.scss";
import Checkbox from "utils/checkbox";
import TableLoader from "utils/table/tableLoader";
import ViewHarvestOrderListing from "../listing/viewHarvestOrderListing";
import { useTranslation } from "react-i18next";

const HarvestListing = ({
	userDetails,
	fetchLocationUsers,
	fetchHarvestList,
	fetchHarvestDetails,
	fetchHarvests,
	changeRenderType,
	renderType,
	deleteHarvest,
	allstatusList,
	statusList,
	fetchTaskUsers,
	permissionDetails,
	changeLocation,
	fetchHarvestingTaskOrder,
	harvestTaskOrderList,
	locationOrder,
	fetchSetUpTime,
	deleteSetUpTime,
	fetchCleaningTime,
	deleteCleaningTime,
}) => {
	const { t } = useTranslation();
	const [, , showModal] = useModal("harvest");
	const [refetch, setRefetch] = useState(3);
	const getTodaysDate = Util.getYMDFormat(new Date());
	const [listFilter, setListFilter] = useState([{ column_name: "expected_harvesting_date", search: getTodaysDate }]);
	const [dailyFilter, setDailyFilter] = useState([]);
	const [deleteItem, setdeleteItem] = useState(false);
	const [bulkAssigneeList, setBulkAssigneeList] = useState([]);
	const [filterLocation, setFilterLocation] = useState(null);
	const [, , openBulkModal] = useModal("bulkAssignee");
	const [, , openRepeatAllModal] = useModal("repeatAllTasks");
	const [loading, setloading] = useState(false);
	const [showHarvestView, setShowHarvestView] = useState(false);
	const [disableRepeatTasks, setDisableRepeatTasks] = useState(true);
	const locations = Util.mapOptions(userDetails && userDetails.location_slugs, "name", "slug") || [];
	const [date, setDate] = useState(null);
	const [filterHarvestOrderLocation, setFilterHarvestOrderLocation] = useState(null);
	const [exportFlag, setExportFlag] = useState(null);

	const handleHarvestOrderView = () => {
		setShowHarvestView(true);
		setloading(true);
		const successHandler = (e) => {
			setloading(false);
		};
		const onError = (e) => {
			errorMessage(e);
		};
		const body = {
			location_slug: filterHarvestOrderLocation ? filterHarvestOrderLocation.value : locationOrder.slug,
			date: date ? Util.getYMDFormat(date) : Util.getYMDFormat(new Date()),
		};

		fetchHarvestingTaskOrder(body, successHandler, onError);
	};
	useEffect(() => {
		if (isEmpty(bulkAssigneeList)) {
			setDisableRepeatTasks(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [bulkAssigneeList]);
	useEffect(() => {
		if (filterHarvestOrderLocation && date) {
			setloading(true);
			handleHarvestOrderView();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filterHarvestOrderLocation, date]);
	// Formatted location array

	const handleAddPanel = () => {
		changeLocation(filterLocation);
		showModal(0);
	};
	const handleEditPanel = (slug, status) => {
		if (status !== "Completed") {
			setloading(true);
			const successhandler = () => onSuccess(1);
			fetchHarvestDetails(slug, successhandler, onError);
		} else {
			errorMessage("HARVEST_NOT_ALLOWED_TO_EDIT");
		}
	};
	const handleRepeatPanel = (slug) => {
		setloading(true);
		const successhandler = () => onSuccess(2);
		fetchHarvestDetails(slug, successhandler, onError);
	};
	const handleViewPanel = async (slug) => {
		setloading(true);
		const successHandler = () => {
			const onSuccess = () => {
				changeRenderType(1, slug);
				setloading(false);
			};
			fetchTaskUsers(onSuccess, onError);
		};
		fetchHarvestDetails(slug, successHandler, onError);
		// await changeRenderType(1, slug);
	};

	const handleCleaningTime = async () => {
		filterLocation && changeLocation(filterLocation);
		showModal(3);
	};

	const handleSetUpTime = async () => {
		filterLocation && changeLocation(filterLocation);
		showModal(4);
	};

	useEffect(() => {
		allstatusList();
	}, [allstatusList]);

	const onSuccess = (modalType) => {
		setloading(false);
		showModal(modalType);
	};
	const onError = (e) => {
		setloading(false);
		errorMessage(e);
	};

	const handleDeletePanel = (data) => {
		setdeleteItem(data);
	};

	const addToFilter = (e) => {
		let obj = [];
		e.forEach((item) => {
			return (obj =
				item && item.column
					? [
							...obj,
							{
								column_name: item.column.accessor,
								search: item.value,
							},
					  ]
					: obj);
		});
        const locationFilter = find(listFilter, ["column_name", "location_slug"]);
		locationFilter ? setListFilter([...obj, locationFilter]) : setListFilter(obj);
		const locationDailyFilter = find(dailyFilter, ["column_name", "location_slug"]);
		locationDailyFilter && setDailyFilter([locationDailyFilter]);

		// setListFilter(obj);
	};

	const onDeleteCustomer = () => {
		const successHandler = (e) => {
			setdeleteItem(null);
			successMessage(e);
			setRefetch(refetch + 1);
		};
		const errorHandler = (e) => {
			setdeleteItem(null);
			errorMessage(e);
		};
		deleteHarvest(deleteItem, successHandler, errorHandler);
	};

	// ----------------- BULK ASSIGNEE ----------------- //
	const onSelectFilterLocation = (location) => {
		if (location) {
			const obj = {
				column_name: "location_slug",
				search: location.value || "",
			};
			setDailyFilter([obj]);
			const rejectListArr = reject(listFilter, ["column_name", "location_slug"]);
			setListFilter([...rejectListArr, obj]);
		} 
		else {
			const rejectArr = reject(listFilter, ["column_name", "location_slug"]);
			setListFilter(rejectArr);
			const rejectDailyArr = reject(dailyFilter, ["column_name", "location_slug"]);
			setDailyFilter(rejectDailyArr);
		}
		// Clear assignee list, since choosing assignee from a single location is permitted
		setBulkAssigneeList([]);

		// Set location filter
		setFilterLocation(location);
	};

	// Handle bulk assignee checkbox
	const onChangeCheckbox = (e, task_id) => {
		if (e.target.checked) {
			setBulkAssigneeList([...bulkAssigneeList, task_id]);
			setDisableRepeatTasks(false);
		} else {
			const filteredArr = filter(bulkAssigneeList, (o) => o !== task_id);
			setBulkAssigneeList(filteredArr);
			if (isEmpty(filteredArr)) {
				setDisableRepeatTasks(true);
			}
		}
	};

	// Click - Each checkbox
	const onClickAssignee = () => {
		if (filterLocation && filterLocation.value) {
			const handleSuccess = () => {
				const body = {
					location_slug: filterLocation.value,
					task_key: "harvesting",
					slugs: bulkAssigneeList,
				};
				openBulkModal(0, body);
			};
			fetchLocationUsers(filterLocation.value, handleSuccess, errorHandler);
		}
	};

	// Click - Select All checkbox
	const onChangeAllCheckbox = (e) => {
		if (e.target.checked) {
			const valSlugs = map(fetchHarvestList, (o) => o.task_id);
			setBulkAssigneeList(valSlugs);
			setDisableRepeatTasks(false);
		} else {
			setBulkAssigneeList([]);
			setDisableRepeatTasks(true);
		}
	};

	const isLocationSelected = filterLocation && filterLocation.value ? true : false;
	const showAllCheckbox = !isEmpty(fetchHarvestList);
	const isAllCheckboxSelected = find(fetchHarvestList, (o) => !includes(bulkAssigneeList, o.task_id)) ? false : true;

	// Success handling after assignee adding
	const doSuccessHandling = () => {
		setRefetch(refetch + 1);
		setBulkAssigneeList([]);
	};
	const errorHandler = (e) => {
		errorMessage(e);
	};

	const onSelectOrderLocation = (location = null) => {
		setFilterHarvestOrderLocation(location);
		//handleHarvestOrderView();
	};
	const onSelectOrderDate = (event) => {
		setDate(event);
		//handleHarvestOrderView();
	};
	const handleHarvestListView = () => {
		setShowHarvestView(false);
	};

	const handleHarvestView = () => {
		if (!filterHarvestOrderLocation && locationOrder) {
			setFilterHarvestOrderLocation({ label: locationOrder?.name || null, value: locationOrder?.slug || null });
		}
		if (!date) {
			setDate(new Date());
		}
		handleHarvestOrderView();
	};
	const handleRepeatTask = () => {
		const body = {
			task_type: "harvesting",
			task_ids: bulkAssigneeList,
		};
		openRepeatAllModal(0, body);
	};

	const columns = renderColumns({
		addToFilter,
		handleEditPanel,
		handleViewPanel,
		handleDeletePanel,
		handleRepeatPanel,
		statusList,
		isLocationSelected,
		onChangeCheckbox,
		permissionDetails,
		checkedAssignees: bulkAssigneeList,
	});

	const onDeleteSetUpAndCleaningTime = () => {
		const successHandler = (e) => {
			setdeleteItem(null);
			successMessage(e);
			setRefetch(refetch + 1);
		};
		const errorHandler = (e) => {
			setdeleteItem(null);
			errorMessage(e);
		};

		renderType === 3
			? deleteCleaningTime(deleteItem, successHandler, errorHandler)
			: deleteSetUpTime(deleteItem, successHandler, errorHandler);
	};

	const handleEditSetUpTimePanel = (data) => {
		showModal(4, data);
	};

	const setUpTimeColumns = renderSetupTimeColumns({
		addToFilter,
		handleEditSetUpTimePanel,
		handleDeletePanel,
	});
	const handleEditCleaningTimePanel = (data) => {
		showModal(3, data);
	};
	const cleaningTimeColumns = renderCleaningTimeColumns({
		addToFilter,
		handleEditCleaningTimePanel,
		handleDeletePanel,
	});
	const addPermission =
		permissionDetails && permissionDetails.harvesting && permissionDetails.harvesting.permissions.create_harvesting_task
			? permissionDetails.harvesting.permissions.create_harvesting_task
			: "";
	const listPermission =
		permissionDetails && permissionDetails.harvesting && permissionDetails.harvesting.permissions.list_harvesting_task
			? permissionDetails.harvesting.permissions.list_harvesting_task
			: "";
	const dailyTaskPermission =
		permissionDetails &&
		permissionDetails.harvesting &&
		permissionDetails.harvesting.permissions.harvesting_daily_task_details
			? permissionDetails.harvesting.permissions.harvesting_daily_task_details
			: "";
	const assigneePermission =
		permissionDetails &&
		permissionDetails.harvesting &&
		permissionDetails.harvesting.permissions.harvesting_bulk_assign_tasks
			? permissionDetails.harvesting.permissions.harvesting_bulk_assign_tasks
			: "";
	const viewOrderPermission =
		permissionDetails && permissionDetails.harvesting && permissionDetails.harvesting.permissions.view_harvest_order
			? permissionDetails.harvesting.permissions.view_harvest_order
			: "";
	const updateOrderPermission =
		permissionDetails && permissionDetails.harvesting && permissionDetails.harvesting.permissions.update_harvest_order
			? permissionDetails.harvesting.permissions.update_harvest_order
			: "";
	const exportHandler = () => {
		setExportFlag(true);
	};
	const exportHandlerFlag = () => {
		setExportFlag(false);
	};

	return (
		<>
			{" "}
			{!showHarvestView ? (
				renderType === 0 ? (
					<ContentWrap>
						<div className="d-flex align-items-center mb-3">
							<Breadcrumbs keyValue="harvesting" showLabel={listPermission} label={t("label.list")} />
							<div className="d-flex align-items-center">
								{viewOrderPermission && (
									<Button className="header-order-btn position-btn" variant="" onClick={handleHarvestView}>
										{t("label.view_harvest_order")}
									</Button>
								)}
								{viewOrderPermission && (
									<Button
										className="header-order-btn position-btn"
										variant=""
										onClick={handleRepeatTask}
										disabled={disableRepeatTasks}>
										{t("label.repeat_all_task")}
									</Button>
								)}
								{assigneePermission && !isEmpty(locations) && (
									<LocationDropdown
										value={filterLocation}
										options={locations}
										onSelect={onSelectFilterLocation}
									/>
								)}
								{assigneePermission && filterLocation && filterLocation.value && !isEmpty(bulkAssigneeList) && (
									<AssigneeButton onClick={onClickAssignee} />
								)}
								{dailyTaskPermission ? (
									<DropdownButton variant="" className="btn-dropdown" title={t("label.daily_task")}>
										<Dropdown.Item as="button" onClick={handleSetUpTime}>
											{t("label.log_setup_time")}
										</Dropdown.Item>
										<Dropdown.Item as="button" onClick={handleCleaningTime}>
											{t("label.log_cleaning_time")}
										</Dropdown.Item>
										<Dropdown.Item as="button" onClick={() => changeRenderType(2)}>
											{t("label.view_setup_time")}
										</Dropdown.Item>
										<Dropdown.Item as="button" onClick={() => changeRenderType(3)}>
											{t("label.view_cleaning_time")}
										</Dropdown.Item>
									</DropdownButton>
								) : (
									""
								)}
							</div>
							{!isEmpty(fetchHarvestList) && (
								<ButtonPrimary className="export_btn btn-fixed-height" onClick={exportHandler}>
									<span>
									{t("label.export")} <i className="icon icon-export text-sm" style={{ fontWeight: "bold" }} />
									</span>
								</ButtonPrimary>
							)}
							{addPermission ? (
								<div className="add-button">
									<ButtonPrimary className="btn-fixed-height" onClick={handleAddPanel}>
										<i className="icon icon-plus mr-2" />
										{t("label.add_task")}
									</ButtonPrimary>
								</div>
							) : (
								""
							)}
						</div>
						<div className="position-relative">
							{showAllCheckbox && (
								<Checkbox
									wrapperClass="listing-checkbox top-harvesting-checkbox"
									checked={isAllCheckboxSelected}
									onChange={onChangeAllCheckbox}
								/>
							)}
							{listPermission ? (
								<Listing
									sort_name={"task_id"}
									fetchAction={fetchHarvests}
									columns={columns}
									refetch={refetch}
									filter={listFilter}
									exportFlag={exportFlag}
									exportHandlerFlag={exportHandlerFlag}
								/>
							) : (
								""
							)}
							{loading && !permissionDetails && (
								<div className="table-loader-wrapper">
									<TableLoader />
								</div>
							)}
						</div>

						<HarvestManage
							onAddOrEdit={() => setRefetch(refetch + 1)}
							doRefetch={() => setRefetch(refetch + 1)}
							handleEditPanel={handleEditPanel}
						/>

						<ConfirmModal
							show={deleteItem ? true : false}
							onNo={() => setdeleteItem(null)}
							onYes={onDeleteCustomer}
							message={t("harvesting.confirm.delete_task")}
						/>
						{/* Bulk Assignee Modal */}
						<BulkAssignee doSuccessHandling={doSuccessHandling} />
						{/* Repeat All Tasks modal*/}
						<RepeatAllTasks doSuccessHandling={doSuccessHandling} />
					</ContentWrap>
				) : renderType === 2 || renderType === 3 ? (
					// VIEW SET UP AND CLEANING TIME
					<ContentWrap>
						<div className="position-relative">
							<div className="d-flex justify-content-between mb-3">
								<BackButton onClick={() => changeRenderType(0)} />
								<Breadcrumbs
									keyValue="harvesting"
									showLabel={true}
									label={renderType === 3 ? t("label.view_cleaning_time") : t("label.view_setup_time")}
								/>
								<LocationDropdown
									value={filterLocation}
									options={locations}
									onSelect={onSelectFilterLocation}
								/>
							</div>
							<Listing
								sort_name={"date"}
								fetchAction={renderType === 3 ? fetchCleaningTime : fetchSetUpTime}
								columns={renderType === 3 ? cleaningTimeColumns : setUpTimeColumns}
								refetch={refetch}
								filter={dailyFilter}
								exportFlag={exportFlag}
								exportHandlerFlag={exportHandlerFlag}
							/>
							<HarvestManage
								onAddOrEdit={() => setRefetch(refetch + 1)}
								doRefetch={() => setRefetch(refetch + 1)}
								dailyTaskEdit
							/>
							<ConfirmModal
								show={deleteItem ? true : false}
								onNo={() => setdeleteItem(null)}
								onYes={onDeleteSetUpAndCleaningTime}
								message={t("harvesting.confirm.delete_task")}
							/>
						</div>
					</ContentWrap>
				) : (
					<HarvestView />
				)
			) : (
				<ContentWrap>
					<div className="d-flex justify-content-between mb-3">
						<div className="d-flex justify-content-between mb-3">
							<BackButton onClick={handleHarvestListView} />
							<Breadcrumbs keyValue="harvesting" showLabel={listPermission} label={t("label.harvest_order")} />
						</div>
						<div className="d-flex align-items-center">
							{!isEmpty(locations) && (
								<LocationOrderDropdown
									value={filterHarvestOrderLocation}
									options={locations}
									onSelect={onSelectOrderLocation}
									isClearable={false}
									label={t("label.location")}
								/>
							)}
							<CommonDatePicker
								label={t("label.exp_harvested_date")}
								placeholder={t("label.select_date")}
								selectedDate={date ? date : new Date()}
								onSelect={onSelectOrderDate}
							/>
						</div>
					</div>
					<div className="position-relative">
						<div className="italic-text mb-3">{t("harvesting.drag_and_drop_task")}</div>
						{harvestTaskOrderList && (
							<ViewHarvestOrderListing
								handleHarvestListView={handleHarvestListView}
								doRefetch={fetchHarvestingTaskOrder}
								details={harvestTaskOrderList}
								updateOrderPermission={updateOrderPermission}
								viewOrderPermission={viewOrderPermission}
							/>
						)}

						{loading && !permissionDetails && (
							<div className="table-loader-wrapper">
								<TableLoader />
							</div>
						)}
					</div>
				</ContentWrap>
			)}
		</>
	);
};
const mapStateToProps = (state) => {
	const { UI, Auth, Harvest } = state;
	return {
		...Harvest,
		fetchHarvestList: state.Harvest.fetchHarvestList,
		accessToken: get(Auth, "accessToken", null),
		mainPreloader: get(UI, "mainPreloader", null),
		renderType: get(Harvest, "renderType", 0),
		statusList: get(Harvest, "statusList", []),
		harvestTaskOrderList: get(Harvest, "harvestTaskOrderList", []),
		userDetails: state.Auth.profileDetails,
		permissionDetails: state.Auth.permissionDetails,
		locationOrder: state?.Auth?.userLocations[0],
	};
};

const mapDispatchToProps = {
	fetchLocationUsers,
	fetchHarvests,
	changeRenderType,
	deleteHarvest,
	allstatusList,
	fetchTaskUsers,
	fetchHarvestDetails,
	changeLocation,
	fetchHarvestingTaskOrder,
	fetchSetUpTime,
	deleteSetUpTime,
	fetchCleaningTime,
	deleteCleaningTime,
};
export default connect(mapStateToProps, mapDispatchToProps)(HarvestListing);
