import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import useModal from "../../../../utils/useModal";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import FormGroups from "../../../../utils/formGroup";
import { Field, reduxForm, reset } from "redux-form";
import { TaskFormDetails } from "./taskDetailsFormHelper";
import { updateTaskDetails } from "../../../../store/actions";
import { successMessage } from "../../../../utils/successMessage";
import { errorMessage } from "../../../../utils/errorMessage";
import Util from "../../../../utils/Util";
import TableLoader from "../../../../utils/table/tableLoader";

const { Header, Title, Body } = Modal;

const TaskDetailsModal = ({
	slug,
	handleSubmit,
	statusDropDown,
	editable,
	viewPageLoader,
	doFetchTaskDetails,
	updateTaskDetails,
}) => {
	const [isOpen, , , closeModal] = useModal("taskDetails");
	const [isLoading, setIsLoading] = useState(false);

	const submitDetails = (values) => {
		setIsLoading(true);
		const successHandler = (e) => {
			successMessage(e.code);
			setIsLoading(false);
			closeModal();
			doFetchTaskDetails();
		};
		const errorHandler = (e) => {
			errorMessage(e.code);
			setIsLoading(false);
		};
		let statusValue = "";
		if (values.status.value) {
			statusValue = values.status.value;
		} else {
			statusValue = values.status;
		}
		const body = {
			slug: slug,
			status: statusValue,
			comments: values.comment,
		};
		updateTaskDetails(body, successHandler, errorHandler);
	};

	const getOptions = (field) => {
		switch (field.name) {
			case "status":
				return statusDropDown ? Util.mapOptions(statusDropDown, "status", "key") : [];
			default:
				return [];
		}
	};

	const getFormFields = (fields) => {
		let formFieldHtml = [];
		formFieldHtml = fields.map((field, idx) => {
			return (
				<Col xs={field.view === "full" ? 12 : 6} key={idx}>
					<Form.Group controlId={`formControl ${idx}`}>
						{field.type === "select-react" ? (
							<Field
								name={field.name}
								type={field.type}
								label={field.label}
								placeholder={field.placeholder}
								component={FormGroups}
								validate={field.validate}
								options={getOptions(field)}
								disable={editable}
							/>
						) : field.name === "status" ? (
							<Field
								name={field.name}
								type={field.type}
								label={field.label}
								placeholder={field.placeholder}
								component={FormGroups}
								validate={field.validate}
								disable={!field.editFlag}
							/>
						) : (
							<Field
								name={field.name}
								type={field.type}
								label={field.label}
								placeholder={field.placeholder}
								component={FormGroups}
								validate={field.validate}
								// disable={!field.editFlag}
							/>
						)}
					</Form.Group>
				</Col>
			);
		});
		return <>{formFieldHtml}</>;
	};
	const formField = TaskFormDetails(editable);
	return (
		<Modal size="lg" show={isOpen} onHide={closeModal} className="modal-sidepanel">
			<Header closeButton>
				<Title className="modal-title">Add Comment</Title>
			</Header>
			<Body>
				{/* <TaskDetailsEdit initialValues={initialValues}/> */}
				<Form onSubmit={handleSubmit(submitDetails)} className="board-details-form">
					<Container className="position-relative">
						<Row>{getFormFields(formField)}</Row>
					</Container>
					{isLoading && (
						<div className="table-loader-wrapper">
							<TableLoader />
						</div>
					)}
					<div className="modal-footer">
						<Button variant="secondary" onClick={closeModal}>
							Cancel
						</Button>
						<Button variant="primary" type="submit">
							Submit
						</Button>
					</div>
				</Form>
			</Body>
		</Modal>
	);
};
const mapDispatchToProps = {
	reset,
	updateTaskDetails,
};

export default connect(
	null,
	mapDispatchToProps
)(
	reduxForm({
		form: "task-details",
		enableReinitialize: true,
	})(TaskDetailsModal)
);
