import { ListingBtn } from "elements";
import React from "react";
import { Col, Row } from "react-bootstrap";
import ActionForm from "./actionForm";
import Util from "../Util";

export const getActionColumns = (
	data,
	actionColumnWidth,
	posThreeRef,
	editableRows,
	setEditableRows,
	onDelete,
	dispatch,
	submit,
	reset,
	cancelEdit,
	customEdit,
	editPermission,
	addPermission,
	keyMapping,
	onClickFullFillmentDetails
) => {
	return (
		<div className="action-fixed-column right-fixed" style={{ width: actionColumnWidth, position: "relative" }}>
			<div ref={posThreeRef} className="sticky-head sticky-head-right">
				<Row className={"right-head action-table-head "} style={{ width: `${actionColumnWidth}px` }}>
					<div className="show-shadow-row-right shadow-head" />

					<Col className="action-table-col">
						<span>Actions</span>
					</Col>
				</Row>
			</div>
			<div className="action-table-body">
				{data.map((dataItem, d_ind) => (
					<Row key={d_ind} className={"action-table-body-row right-row position-relative"}>
						<div className="show-shadow-row-right" />
						<Col className="action-table-col">
							{dataItem.is_edittable === 0 ? (
								<>
									<ListingBtn
										tooltipFontSize={11}
										tooltipText="Edit"
										disabled={true}
										type="btn"
										iconClass="icon icon-edit"
										btnClass="btn btn-cell btn-light btn-sm "
									/>
									{addPermission && dataItem.cases_remaining !== 0 && (
										<ListingBtn
											tooltipFontSize={11}
											tooltipText="Add"
											iconClass={"icon icon-check modal-table-icon"}
											// tooltipLeft={true}
											type="span"
											onClick={() => {
												onClickFullFillmentDetails(dataItem, 2);
											}}
											btnClass="btn btn-cell btn-light btn-sm ml-2"
										/>
									)}
								</>
							) : (
								<>
									{editableRows.indexOf(d_ind) === -1 ? (
										<>
											{editPermission && (
												<ListingBtn
													tooltipFontSize={11}
													tooltipText="Edit"
													// tooltipLeft={true}
													type="span"
													iconClass="icon icon-edit"
													onClick={() =>
														customEdit
															? customEdit(dataItem)
															: setEditableRows([...editableRows, d_ind])
													}
													btnClass="btn btn-cell btn-light btn-sm "
												/>
											)}
											{addPermission && (
												<ListingBtn
													tooltipFontSize={11}
													tooltipText="Add"
													iconClass={"icon icon-check modal-table-icon"}
													// tooltipLeft={true}
													type="span"
													onClick={() => {
														onClickFullFillmentDetails(dataItem, 2);
													}}
													btnClass="btn btn-cell btn-light btn-sm ml-2"
												/>
											)}
										</>
									) : (
										<>
											<ListingBtn
												tooltipFontSize={11}
												// tooltipLeft={true}
												tooltipText="Save"
												type="span"
												iconClass={"icon icon-check modal-table-icon"}
												onClick={(e) => dispatch(submit(`requestedDetailsForm${d_ind}`))}
												btnClass="btn btn-cell btn-light btn-sm"
											/>

											<ListingBtn
												tooltipFontSize={11}
												// tooltipLeft={true}
												tooltipText="Cancel"
												type="span"
												iconClass={"icon icon-close modal-table-icon"}
												onClick={(e) => {
													dispatch(reset(`requestedDetailsForm${d_ind}`));
													cancelEdit(d_ind);
												}}
												btnClass="btn btn-cell btn-light btn-sm ml-2"
											/>
										</>
									)}
								</>
							)}
						</Col>
					</Row>
				))}
			</div>
		</div>
	);
};
export const getData = (
	data,
	initialData,
	posTwoRef,
	columns,
	slugKey,
	renderKey,
	render,
	editableRows,
	onSave,
	cancelEdit,
	validation,
	keyMapping,
	option
) => {
	return (
		<>
			<div ref={posTwoRef} className="sticky-head">
				<Row className="action-table-head position-relative">
					{columns.map((item, idx) => (
						<Col key={idx} className="action-table-col">
							<span>{item.label && item.label}</span>
						</Col>
					))}
				</Row>
			</div>
			<div className="action-table-body">
				{data.map((a_item, a_ind) => {
					const initialValues = {};
					a_item &&
						columns &&
						columns.forEach((columnItem) => {
							if (columnItem.type === "date-picker") {
								initialValues[columnItem.key] =
									a_item[columnItem.key] && Util.getDateObjectFormat(a_item[columnItem.key]);
							} else {
								initialValues[columnItem.key] = a_item[columnItem.key] && a_item[columnItem.key];
							}
						});
					return (
						data && (
							<ActionForm
								key={a_ind.toString()}
								form={`requestedDetailsForm${a_ind}`}
								columns={columns}
								renderKey={renderKey}
								render={render}
								a_ind={a_ind}
								editable={editableRows.indexOf(a_ind) !== -1 ? true : false}
								a_item={data}
								slugKey={slugKey}
								initialValues={initialValues}
								slug={a_item}
								onSave={onSave}
								validation={validation}
								cancelEdit={cancelEdit}
								option={option}
							/>
						)
					);
				})}
			</div>
		</>
	);
};

export const fixedColumnTable = (
	data,
	fixedColumnWidth,
	posOneRef,
	fixedColumnHeading,
	renderKeyItem,
	keyMapping,
	initialData
) => {

	return (
		<div className="action-fixed-column" style={{ width: fixedColumnWidth, position: "relative" }}>
			<div ref={posOneRef} className="sticky-head">
				<Row className={"action-table-head "} style={{ width: `${fixedColumnWidth}px` }}>
					<div className="show-shadow-row shadow-head" />

					<Col className="action-table-col">
						<span>{fixedColumnHeading}</span>
					</Col>
				</Row>
			</div>
			<div className="action-table-body">
				{initialData.map((dataItem, d_ind) => (
					<Row key={d_ind} className={"action-table-body-row position-relative"}>
						<div className="show-shadow-row " />
						<Col className="action-table-col">{dataItem.sku_name}</Col>

						{/* <Col className="action-table-col">{renderKeyItem(dataItem)}</Col> */}
					</Row>
				))}
			</div>
		</div>
	);
};
