import React, { useEffect, useState } from "react";

import { errorMessage } from "utils/errorMessage";
import { connect } from "react-redux";
import { debounce, get, round } from "lodash";

import "./index.scss";
import { successMessage } from "../../utils/successMessage";

import { Col, Container, Row } from "react-bootstrap";
import moment from "moment";
import { updateHarvestForecast } from "store/actions";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";

const CustomTextField = styled(TextField)({
	"label + &": { borderRadius: 8 },
	"& .MuiInputBase-input": {
		borderRadius: 8,
	},
	"& label": {
		color: "black",
	},
	"& label.Mui-focused": {
		color: "#006241",
	},
	"& .MuiInput-underline:after": {
		borderBottomColor: "#006241",
		borderRadius: 8,
	},
	"& .MuiOutlinedInput-root": {
		borderRadius: 8,
		width: "100%",
		"& fieldset": {
			borderColor: "#cdcdcd",
			borderRadius: 8,
			fontWeight: "bold",
		},
		"&:hover fieldset": {
			borderColor: "#000",
			borderRadius: 14,
			fontWeight: "bold",
		},
		"&.Mui-focused fieldset": {
			borderColor: "#006241",
			borderRadius: 8,
		},
	},
});

const ForecastDay = ({
	updateHarvestForecast,
	greenhouse,
	variety,
	date,
	year,
	currentLane,
	currentPond,
	currentValue = {},
	setShouldRefresh,
	loading,
	stats = {},
}) => {
	const [ozPerBoard, setOzPerBoard] = useState({
		forecast_unit: "oz_per_board",
		forecast_type: "daily",
		growing_lane_number: currentLane,
		growing_location: currentPond,
		location_id: greenhouse.slug,
		date: date.toISOString(),
		product_variety_id: variety.slug,
		forecast_value: currentValue?.oz_per_board?.forecast_value ? currentValue?.oz_per_board?.forecast_value : 0,
		year: year,
		change_reason: null,
		change_notes: null,
	});
	const [ozPerBoardActual, setOzPerBoardActual] = useState({
		forecast_unit: "oz_per_board_actual",
		forecast_type: "daily",
		growing_lane_number: currentLane,
		growing_location: currentPond,
		location_id: greenhouse.slug,
		date: date.toISOString(),
		product_variety_id: variety.slug,
		forecast_value: currentValue?.oz_per_board_actual?.forecast_value
			? currentValue?.oz_per_board_actual?.forecast_value
			: 0,
		year: year,
		change_reason: null,
		change_notes: null,
	});

	const [numOfBoards, setNumOfBoards] = useState({
		forecast_unit: "number_of_boards",
		forecast_type: "daily",
		growing_lane_number: currentLane,
		growing_location: currentPond,
		location_id: greenhouse.slug,
		date: date.toISOString(),
		product_variety_id: variety.slug,
		forecast_value: currentValue?.number_of_boards?.forecast_value ? currentValue?.number_of_boards?.forecast_value : 0,
		year: year,
		change_reason: null,
		change_notes: null,
	});
	const [numOfBoardsActual, setNumOfBoardsActual] = useState({
		forecast_unit: "number_of_boards_actual",
		forecast_type: "daily",
		growing_lane_number: currentLane,
		growing_location: currentPond,
		location_id: greenhouse.slug,
		date: date.toISOString(),
		product_variety_id: variety.slug,
		forecast_value: currentValue?.number_of_boards_actual?.forecast_value
			? currentValue?.number_of_boards_actual?.forecast_value
			: 0,
		year: year,
		change_reason: null,
		change_notes: null,
	});
	const [growCycle, setGrowCycle] = useState({
		forecast_unit: "grow_cycle",
		forecast_type: "daily",
		growing_lane_number: currentLane,
		growing_location: currentPond,
		location_id: greenhouse.slug,
		date: date.toISOString(),
		product_variety_id: variety.slug,
		forecast_value: currentValue?.grow_cycle?.forecast_value ? currentValue?.grow_cycle?.forecast_value : 0,
		year: year,
		change_reason: null,
		change_notes: null,
	});

	const [shouldSave, setShouldSave] = useState(false);

	const updateOzActual = () => {
		console.log("Saving OZ Actual");
		saveForecast(ozPerBoardActual);
	};

	const updateOz = () => {
		console.log("Saving OZ");
		saveForecast(ozPerBoard);
	};

	// useAutosave({ data: ozPerBoard, onSave: updateOz });

	const updateGrow = () => {
		console.log("Saving Grow");
		saveForecast(growCycle);
	};

	// useAutosave({ data: growCycle, onSave: updateGrow });

	const updateBoards = () => {
		console.log("Saving Boards");
		saveForecast(numOfBoards);
	};

	const updateBoardsActual = () => {
		console.log("Saving Boards Actual");
		saveForecast(numOfBoardsActual);
	};

	// useAutosave({ data: numOfBoards, onSave: updateBoards });

	const saveForecast = (value) => {
		if (shouldSave) {
			setShouldSave(false);
			updateHarvestForecast(
				[value],
				(e) => {
					successMessage(e);
					delayedRefresh(true);
				},
				errorHandler
			);
		}
	};

	const delayedRefresh = React.useCallback(
		debounce((val) => setShouldRefresh(val), 500),
		[]
	);

	const errorHandler = (e) => {
		errorMessage(e);
	};

	useEffect(() => {
		setOzPerBoard({
			forecast_unit: "oz_per_board",
			forecast_type: "daily",
			growing_lane_number: currentLane,
			growing_location: currentPond,
			location_id: greenhouse.slug,
			date: date.toISOString(),
			product_variety_id: variety.slug,
			forecast_value: currentValue?.oz_per_board?.forecast_value ? currentValue?.oz_per_board?.forecast_value : 0,
			year: year,
			change_reason: null,
			change_notes: null,
		});

		setOzPerBoardActual({
			forecast_unit: "oz_per_board_actual",
			forecast_type: "daily",
			growing_lane_number: currentLane,
			growing_location: currentPond,
			location_id: greenhouse.slug,
			date: date.toISOString(),
			product_variety_id: variety.slug,
			forecast_value: currentValue?.oz_per_board_actual?.forecast_value
				? currentValue?.oz_per_board_actual?.forecast_value
				: 0,
			year: year,
			change_reason: null,
			change_notes: null,
		});

		setNumOfBoards({
			forecast_unit: "number_of_boards",
			forecast_type: "daily",
			growing_lane_number: currentLane,
			growing_location: currentPond,
			location_id: greenhouse.slug,
			date: date.toISOString(),
			product_variety_id: variety.slug,
			forecast_value: currentValue?.number_of_boards?.forecast_value ? currentValue?.number_of_boards?.forecast_value : 0,
			year: year,
			change_reason: null,
			change_notes: null,
		});
		setNumOfBoardsActual({
			forecast_unit: "number_of_boards_actual",
			forecast_type: "daily",
			growing_lane_number: currentLane,
			growing_location: currentPond,
			location_id: greenhouse.slug,
			date: date.toISOString(),
			product_variety_id: variety.slug,
			forecast_value: currentValue?.number_of_boards_actual?.forecast_value
				? currentValue?.number_of_boards_actual?.forecast_value
				: 0,
			year: year,
			change_reason: null,
			change_notes: null,
		});

		setGrowCycle({
			forecast_unit: "grow_cycle",
			forecast_type: "daily",
			growing_lane_number: currentLane,
			growing_location: currentPond,
			location_id: greenhouse.slug,
			date: date.toISOString(),
			product_variety_id: variety.slug,
			forecast_value: currentValue?.grow_cycle?.forecast_value ? currentValue?.grow_cycle?.forecast_value : 0,
			year: year,
			change_reason: null,
			change_notes: null,
		});
	}, [currentValue, currentLane, currentPond, date, greenhouse, variety, year]);

	return (
		<div style={{ paddingBottom: 24 }}>
			<div style={{ textAlign: "center" }}>
				<div
					style={{
						position: "absolute",
						right: 4,
						top: 4,
						fontWeight: "bold",
						border: "1px solid rgb(0, 98, 65)",
						backgroundColor: date === moment() ? "rgb(0, 98, 65)" : "transparent",
						color: "rgb(0, 98, 65)",
						borderRadius: 50,
						padding: 2,
						width: 25,
						height: 25,
						lineHeight: "20px",
						fontSize: 12,
					}}>
					{date.date()}
				</div>
				{date.day() != 0 && stats?.rolling_7_day_oz_per_board > 0 && (
					<div
						style={{
							maxWidth: 140,
							borderRadius: 20,
							backgroundColor:
								ozPerBoard.forecast_value > stats?.rolling_7_day_oz_per_board
									? "green"
									: ozPerBoard.forecast_value < stats?.rolling_7_day_oz_per_board
									? "red"
									: "darkgray",
							color: "white",
							margin: "8px 4px",
							paddingLeft: 4,
							paddingRight: 4,
							fontWeight: "bold",
							fontSize: 10,
							letterSpacing: 2,
						}}>
						7d: {parseFloat(stats?.rolling_7_day_oz_per_board).toFixed(2)}
						<span style={{ fontWeight: "bold", paddingLeft: 12 }}>
							{ozPerBoard.forecast_value > stats?.rolling_7_day_oz_per_board ? "⬆" : ""}
							{ozPerBoard.forecast_value < stats?.rolling_7_day_oz_per_board ? "⬇" : ""}
							{round((ozPerBoard.forecast_value / stats?.rolling_7_day_oz_per_board - 1) * 100)}%
						</span>
					</div>
				)}

				<Container style={{ paddingTop: 40 }}>
					<Row style={{ textAlign: "left", justifyContent: "center", paddingBottom: 12 }}>
						<Col style={{}}>
							<CustomTextField
								onChange={(e) => {
									setShouldSave(true);
									setOzPerBoard({ ...ozPerBoard, forecast_value: e.currentTarget.value });
								}}
								onBlur={(e) => {
									updateOz();
								}}
								value={ozPerBoard.forecast_value}
								placeholder="oz/board"
								type="number"
								label="Oz / board"
								disabled={loading}
							/>
						</Col>
					</Row>
					<Row style={{ textAlign: "left", justifyContent: "center", paddingBottom: 12 }}>
						<Col style={{}}>
							<CustomTextField
								onChange={(e) => {
									setShouldSave(true);
									setNumOfBoards({ ...numOfBoards, forecast_value: e.currentTarget.value });
								}}
								onBlur={(e) => {
									updateBoards();
								}}
								value={numOfBoards.forecast_value}
								placeholder="# of boards"
								type="number"
								label="# of boards"
								disabled={loading}
							/>
						</Col>
					</Row>
					<Row style={{ textAlign: "left", justifyContent: "center", paddingBottom: 12 }}>
						<Col style={{}}>
							<CustomTextField
								value={growCycle.forecast_value}
								onChange={(e) => {
									setShouldSave(true);
									setGrowCycle({ ...growCycle, forecast_value: e.currentTarget.value });
								}}
								onBlur={(e) => {
									updateGrow();
								}}
								placeholder="Grow Cycle"
								label="Grow Cycle"
								disabled={loading}
							/>
						</Col>
					</Row>
					<div
						style={{
							marginTop: 0,
							marginLeft: 0,
							justifyContent: "center",
							alignItems: "center",
							padding: 0,
							fontWeight: "bold",
							color: "white",
							textAlign: "right",
						}}>
						<div
							style={{
								justifyContent: "center",
								alignItems: "flex-end",
								fontWeight: "bold",
								color: "black",
								fontSize: 20,
								textAlign: "right",
							}}>
							Total <br />
							<span style={{ fontSize: 24 }}>
								{(ozPerBoard.forecast_value / 16) * numOfBoards.forecast_value} Lbs
							</span>
						</div>
					</div>
					<div style={{ textAlign: "left", paddingLeft: 0, color: "#aaa" }}>
						<div style={{ marginTop: 8, fontSize: 10, fontWeight: "bold" }}>
							Oz: <span>{moment(currentValue?.oz_per_board?.updated_at).fromNow()}</span>
						</div>
						<div style={{ marginTop: 8, fontSize: 10, fontWeight: "bold" }}>
							#: <span>{moment(currentValue?.number_of_boards?.updated_at).fromNow()}</span>
						</div>
						<div style={{ marginTop: 8, fontSize: 10, fontWeight: "bold" }}>
							Cycle: <span>{moment(currentValue?.grow_cycle?.updated_at).fromNow()}</span>
						</div>
					</div>
				</Container>
				{/* <Container style={{ paddingTop: 40 }}>
					<Row style={{ textAlign: "left", justifyContent: "center", paddingBottom: 12 }}>
						<Col style={{}}>
							<CustomTextField
								onChange={(e) => {
									setShouldSave(true);
									setOzPerBoardActual({ ...ozPerBoardActual, forecast_value: e.currentTarget.value });
								}}
								onBlur={(e) => {
									updateOzActual();
								}}
								value={ozPerBoardActual.forecast_value}
								placeholder="oz/board"
								type="number"
								label="Actual Oz/board"
								disabled={loading}
							/>
						</Col>
					</Row>
					<Row style={{ textAlign: "left", justifyContent: "center", paddingBottom: 12 }}>
						<Col style={{}}>
							<CustomTextField
								onChange={(e) => {
									setShouldSave(true);
									setNumOfBoardsActual({ ...numOfBoardsActual, forecast_value: e.currentTarget.value });
								}}
								onBlur={(e) => {
									updateBoardsActual();
								}}
								value={numOfBoardsActual.forecast_value}
								placeholder="# of boards"
								type="number"
								label="Actual # of boards"
								disabled={loading}
							/>
						</Col>
					</Row>

					<div
						style={{
							marginTop: 0,
							marginLeft: 0,
							justifyContent: "center",
							alignItems: "center",
							padding: 0,
							fontWeight: "bold",
							color: "white",
							textAlign: "right",
						}}>
						<div
							style={{
								justifyContent: "center",
								alignItems: "flex-end",
								fontWeight: "bold",
								color: "black",
								fontSize: 20,
								textAlign: "right",
							}}>
							Total <br />
							<span style={{ fontSize: 24 }}>
								{(ozPerBoardActual.forecast_value / 16) * numOfBoardsActual.forecast_value} Lbs
							</span>
						</div>
					</div>
					<div style={{ textAlign: "left", paddingLeft: 0, color: "#aaa" }}>
						<div style={{ marginTop: 8, fontSize: 10, fontWeight: "bold" }}>
							Oz: <span>{moment(currentValue?.oz_per_board_actual?.updated_at).fromNow()}</span>
						</div>
						<div style={{ marginTop: 8, fontSize: 10, fontWeight: "bold" }}>
							Boards: <span>{moment(currentValue?.number_of_boards_actual?.updated_at).fromNow()}</span>
						</div>
					</div> 
				</Container>*/}
			</div>
		</div>
	);
};

const mapStateToProps = (state) => {
	const { UI, Auth } = state;
	return {
		accessToken: get(Auth, "accessToken", null),
		mainPreloader: get(UI, "mainPreloader", null),
		permissionDetails: get(Auth, "permissionDetails", null),
	};
};

const mapDispatchToProps = {
	updateHarvestForecast,
};
export default connect(mapStateToProps, mapDispatchToProps)(ForecastDay);
