import React from "react";
import { Modal } from "react-bootstrap";
import SkuView from "./skuView";
import SkuEdit from "./skuAddEdit";
import useModal from "../../../utils/useModal";

const { Header, Title, Body } = Modal;
const classPrefix = "bright-farms";

const SkuManage = ({ handleEditPanel, onAddOrEdit, doRefetch, updatePermission, changeStatus, fetchSkuDetails }) => {
	const [isOpen, modalType, , closeModal, modalProps] = useModal("sku");

	return (
		<Modal size="lg" show={isOpen} onHide={closeModal} className="modal-sidepanel">
			<Header closeButton>
				<Title className="modal-title">
					{modalType === 2 ? "View" : modalType === 1 ? "Edit" : "Add"} Stock Keeping Unit
				</Title>
				{modalType === 2 && (
					<button type="button" className={`${classPrefix}-modal-close-button`} onClick={closeModal}>
						<i className="icon icon-close" />
					</button>
				)}
			</Header>
			<Body>
				{modalType === 2 ? (
					<SkuView
						details={modalProps}
						doRefetch={doRefetch}
						handleEditPanel={handleEditPanel}
						updatePermission={updatePermission}
						changeStatus={changeStatus}
						fetchSkuDetails={fetchSkuDetails}
					/>
				) : (
					<SkuEdit onAddOrEdit={onAddOrEdit} mode={modalType} />
				)}
			</Body>
		</Modal>
	);
};

export default SkuManage;
