import React from "react";
import { Modal } from "react-bootstrap";
import useModal from "utils/useModal";
import RepeatAllForm from "./repeatAllForm";
import { useTranslation } from "react-i18next";

const { Header, Title, Body } = Modal;

const RepeatAllTasks = ({ doSuccessHandling }) => {
	const { t } = useTranslation();
	const [isOpen, , , closeModal, modalProps] = useModal("repeatAllTasks");
	return (
		<Modal size="lg" show={isOpen} onHide={closeModal} className="modal-sidepanel">
			<Header closeButton>
				<Title className="modal-title">{t("label.repeat_all_task")}</Title>
			</Header>
			<Body>
				<RepeatAllForm initialBody={modalProps} doSuccessHandling={doSuccessHandling} closeModal={closeModal} />
			</Body>
		</Modal>
	);
};

export default RepeatAllTasks;
