import { createSelectorCreator, defaultMemoize } from "reselect";
import { isEmpty, isEqual } from "lodash";
import Util from "utils/Util";
import moment from "moment";

const getToAddList = (state) => state.ToAddList;

const getForm = (state) => state.form;

const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);

const getDropdownData = createDeepEqualSelector([getToAddList], (data) => data.dropdownData);

export const toAddListForm = createDeepEqualSelector([getForm], (form) => form.toAddListForm);

export const locationSelected = createDeepEqualSelector([toAddListForm], (form) =>
	form && form.values && form.values.location ? form.values.location : null
);

export const getLoading = createDeepEqualSelector([getToAddList], (wsr) => wsr && wsr.loading);
export const getRenderType = createDeepEqualSelector([getToAddList], (data) => data && data.renderType);

export const getLocationList = createDeepEqualSelector([getDropdownData], (data) =>
	data && !isEmpty(data) ? Util.mapOptions(data, "location", "slug") : []
);

const formatPondList = (data, location) => {
	const ind = data.findIndex((item) => item.slug === location.value);
	if (ind !== -1) {
		const tempData = data[ind].pond_details;
		const pondOptions = !isEmpty(tempData) ? [{label:"All",value:""}] : [];

		tempData && tempData.map((item)=>{
			pondOptions.push({
				label:item.value,
				value:item.id
			});
		}) 

		return pondOptions;
	} else {
		return [];
	}
};
export const getPondList = createDeepEqualSelector([getDropdownData, locationSelected], (data, location) =>
	data && !isEmpty(data) && location ? formatPondList(data, location) : null
);

export const getFormFromDate = createDeepEqualSelector([toAddListForm], (form) =>
	form && form.values && form.values.week && form.values.week[0] ? form.values.week[0] : null
);
export const getFormToDate = createDeepEqualSelector([toAddListForm], (form) =>
	form && form.values && form.values.week && form.values.week[1] ? form.values.week[1] : null
);

const fromDateFormat = createDeepEqualSelector([getFormFromDate], (date) => (date ? new Date(date) : null));

export const getThirtyWeeks = createDeepEqualSelector([fromDateFormat], (date) =>
	date
		? new Date(
				moment(date.setDate(date.getDate() + 7 * 29))
					.endOf("week")
					.format("MM-DD-YYYY")
		  )
		: null
);

export const getNutrientsTableData = createDeepEqualSelector([getToAddList], (data) =>
	data.nutrientsData ? (!isEmpty(data.nutrientsData) ? data.nutrientsData : []) : null
);

const formatNutrientsColumn = (array) => {
	const data = array.map((item) => ({
		slug: item.slug,
		name: item.nutrient,
	}));
	return data;
};

export const getNutrientsTableColumns = createDeepEqualSelector([getToAddList], (data) =>
	data.nutrientsColumn && !isEmpty(data.nutrientsColumn) ? formatNutrientsColumn(data.nutrientsColumn) : null
);

export const getToAddListData = createDeepEqualSelector([getToAddList], (data) =>
	data.addListData ? (!isEmpty(data.addListData) ? data.addListData : []) : null
);

const formatAddListColumn = (array) => {
	const data = array.map((item) => ({
		slug: item.slug,
		name: item.fertiliser_name,
	}));
	return data;
};

export const getToAddListColumns = createDeepEqualSelector([getToAddList], (data) =>
	data.addListColumn && !isEmpty(data.addListColumn) ? formatAddListColumn(data.addListColumn) : null
);

export const getPondSelected = createDeepEqualSelector([toAddListForm], (form) =>
	form && form.values && form.values.pond ? form.values.pond : null
);

const toAddListPermit = (state) => state.Auth.permissionDetails.to_add_list;

export const getNutrientsAddedPermission = createDeepEqualSelector(
	[toAddListPermit],
	(access) => access && access.permissions.view_nutrients_added
);

export const getEditPermission = createDeepEqualSelector(
	[toAddListPermit],
	(access) => access && access.permissions.edit_to_add_list
);

export const getDeletePermission = createDeepEqualSelector(
	[toAddListPermit],
	(access) => access && access.permissions.delete_to_add_list
);

export const getListPermission = createDeepEqualSelector(
	[toAddListPermit],
	(access) => access && access.permissions.list_to_add_list
);
