import { required} from "../../../../constant/formValidators";
export const TaskFormDetails = (editable) => [
	{
		label: "Status *",
		name: "status",
		type: editable === false ? "select-react" : "text",
		view: "full",
		placeholder: "Enter Status",
		validate: [required],
		editFlag: editable === false ? true : false,
	},
	{
		label: "Comment",
		name: "comment",
		type: "textarea",
		view: "full",
		placeholder: "Enter Comment",
		editFlag:false
	},
];
