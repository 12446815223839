import React from "react";
import { Breadcrumbs, ContentWrap } from "elements";
import TableLoader from "utils/table/tableLoader";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { getLoading } from "store/selectors/laneCalculatorSelectors";
import { getLocationList, setLoader } from "store/actions";
import Listing from "./listing";
const CaseToLane = ({ loading }) => {
	return (
		<ContentWrap>
			<div className="d-flex align-items-center mb-3">
				<Breadcrumbs
					className="forecast-breadcrumb"
					keyValue="laneCalculator"
					showLabel={true}
					label={"Case To Lane"}
				/>
			</div>
			<div className="position-relative">
				<Listing />
				{loading && (
					<div className="table-loader-wrapper">
						<TableLoader />
					</div>
				)}
			</div>
		</ContentWrap>
	);
};
const mapStateToProps = createStructuredSelector({
	loading: getLoading,
});

export default connect(mapStateToProps, { getLocationList, setLoader })(CaseToLane);
