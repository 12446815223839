import { required, number, requiredDateObj, minValueBoard } from "../../../constant/formValidators";
import { find } from "lodash";
import { flagColors } from "../../../constant/global";
export const dropdownArrays = {
	flagColors,
};

export const formCreationFieldsTop = [
	{
		label: "label.location_required",
		name: "location",
		type: "select-react",
		placeholder: "label.select_location",
	},
	{
		label: "label.product_variety_required",
		name: "product_variety",
		type: "select-react",
		placeholder: "label.select_product_variety",
	},
];
export const formCreationFieldsMiddle = [
	{
		label: "label.pond_required",
		name: "pond_number",
		type: "select-react",
		placeholder: "label.select_pond",
	},
	{
		label: "label.lane_required",
		name: "lane_number",
		type: "select-react",
		placeholder: "label.select_lane_number",
	},
	{
		label: "label.no_of_boards_required",
		name: "number_of_boards",
		type: "text",
		placeholder: "label.enter_boards",
	},
];

export const formCreationFieldsBottom = [
	{
		label: "label.flag_color_required",
		name: "flag_color",
		type: "select-react",
		placeholder: "label.enter_flag_color",
	},
	{
		label: "label.expected_transplantd_date_required",
		name: "expected_transplant_date",
		type: "react-date-picker",
		placeholder: "label.select_transplant_date",
		validate: [requiredDateObj],
		minDate: new Date(),
	},
	{
		label: "label.comments",
		name: "task_description",
		type: "textarea",
		placeholder: "label.enter_description",
	},
	{
		label: "label.assignee",
		name: "assignee_id",
		type: "select-react",
		placeholder: "label.select_employee",
		maxMenuHeight: 210,
	},
];

export const getValidations = (field) => {
	switch (field) {
		case "lane_number":
			return required;
		case "pond_number":
			return required;
		case "number_of_boards":
			return [required, number, minValueBoard];
		case "location":
			return [required];
		case "product_variety":
			return [required];
		case "flag_color":
			return [required];
		case "expected_date":
			return [requiredDateObj];
		default:
			return [];
	}
};

export const enumerateOptions = (locationPondLaneMappedArray, field) => {
	const newList =
		locationPondLaneMappedArray &&
		locationPondLaneMappedArray[field] > 0 &&
		Array(locationPondLaneMappedArray[field])
			.fill()
			.map((_, i) => ({
				label: i + 1,
				value: (i + 1).toString(),
			}));

	return newList ? newList : [];
};

export const getDropdownObj = (arrKey, value) => find(dropdownArrays[arrKey], (o) => o.value === value);
