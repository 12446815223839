import React from "react";
import { DropdownButton, Dropdown } from "react-bootstrap";
import i18next from "i18next";

export const DailyUsageDropdown = ({ handleDailyTask }) => (
	<DropdownButton variant="" className="btn-dropdown" title={i18next.t("label.daily_task")}>
		<Dropdown.Item as="button" onClick={() => handleDailyTask(1)}>
			{i18next.t("label.add_media_usage")}
		</Dropdown.Item>
		<Dropdown.Item as="button" onClick={() => handleDailyTask(2)}>
			{i18next.t("label.log_setup_time")}
		</Dropdown.Item>
		<Dropdown.Item as="button" onClick={() => handleDailyTask(3)}>
			{i18next.t("label.log_cleaning_time")}
		</Dropdown.Item>
		<Dropdown.Item as="button" onClick={() => handleDailyTask(4)}>
			{i18next.t("label.view_media_usage")}
		</Dropdown.Item>

		<Dropdown.Item as="button" onClick={() => handleDailyTask(5)}>
			{i18next.t("label.view_setup_time")}
		</Dropdown.Item>
		<Dropdown.Item as="button" onClick={() => handleDailyTask(6)}>
			{i18next.t("label.view_cleaning_time")}
		</Dropdown.Item>
	</DropdownButton>
);
