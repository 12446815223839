import React, { useEffect, useState } from "react";
import { Form, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { reduxForm, FieldArray, change, getFormValues } from "redux-form";
import { last, filter, isEmpty, size, map } from "lodash";
import Util from "../../../utils/Util";
import { ButtonSecondary, ButtonPrimary } from "../../../elements";
import useProductViews from "../useProductViews";
import TableLoader from "../../../utils/table/tableLoader";
import { SeedsField, GreensField, constructSeedDetailsFormArr, seedDetailsForEdit, seedDetailsForAdd } from "./formHelper";
import { RenderControlField, RenderSeedTabs } from "./renderFormFields";
import { fetchMiniGreensList } from "../../../store/actions/greensActions";
import {
	getSeedsListForGreens,
	createProductVariety,
	updateProductVariety,
	productFormSubmitFailed,
	accessProductState,
} from "../../../store/actions/productActions";
import { fetchSeedDetails } from "../../../store/actions/seedsActions";
import { fetchLocations } from "../../../store/actions/userActions";
import { fetchMiniBoardsList } from "../../../store/actions/boardsActions";
import { errorMessage } from "../../../utils/errorMessage";
import { successMessage } from "../../../utils/successMessage";
import { NotificationManager } from "react-notifications";

const ProductVarietyManage = ({
	isEdit,
	handleSubmit,
	fetchMiniGreensList,
	getSeedsListForGreens,
	fetchLocations,
	fetchMiniBoardsList,
	change,
	createProductVariety,
	updateProductVariety,
	accessProductState,
	productVarietyFormValues,
	fetchSeedDetails,
	greensDropdownList,
	seedsListForGreens,
	userLocations,
	boardsDropdownList,
	formErrObj,
	seedsErrorTab,
	seasonsErrorTab,
	isProductFormError,
	initialValues,
	editPanel,
	listFilter,
}) => {
	// const scrollRef = useRef(null);
	const [, modalType, , closeModal] = useProductViews("product_variety");
	const [pageLoading, setPageLoading] = useState(false);
	const hideLoader = () => setPageLoading(false);
	const [btnTitle, setbtnTitle] = useState("Save");
	const [selectSeed, setSelectSeed] = useState("");
	const greensList = Util.mapOptions(greensDropdownList, "green_name", "slug");
	const seedsList = Util.mapOptions(seedsListForGreens, "name", "slug") || [];
	const locationsList = Util.mapOptions(userLocations, "location_name", "slug") || [];
	const boardsList = Util.mapOptions(boardsDropdownList, "name", "slug") || [];

	useEffect(() => {
		setPageLoading(true);
		fetchMiniGreensList(hideLoader, hideLoader);
		fetchMiniBoardsList();
		fetchLocations();
	}, [fetchLocations, fetchMiniBoardsList, fetchMiniGreensList]);

	// Select Greens
	const onSelectGreens = (e) => {
		setPageLoading(true);
		change("seeds", []);
		change("seed_details", []);
		accessProductState("seedsErrorTab", 0);
		accessProductState("seasonsErrorTab", `0_0`);
		getSeedsListForGreens(e.value, hideLoader, hideLoader);
		setSelectSeed(e.value);
	};

	// If an array contains an object
	// Compared using slug
	const ifArrayContainObj = (arr, obj) => {
		const found = filter(arr, (o) => o.value === obj.slug);
		return isEmpty(found) ? false : true;
	};
	// Select Seeds
	const onSelectSeeds = (seedsArr) => {
		const initialBoardvalue = boardsList.filter((item) => item.label === "New");
		if (seedsArr) {
			setPageLoading(true);
			const onSuccess = (resp) => {
				// Checking existing tabs - to filter out removing tabs
				const filterOut = filter(productVarietyFormValues.seed_details, (o) => ifArrayContainObj(seedsArr, o));

				// Check if action is remove/add
				// If remove, Keep the data
				// Else add API response data to arr
				let arr = [];
				if (size(filterOut) < size(productVarietyFormValues.seed_details)) {
					arr = filterOut;
					accessProductState("seedsErrorTab", 0);
					accessProductState("seasonsErrorTab", `0_0`);
				} else {
					arr = [...filterOut, constructSeedDetailsFormArr(resp.data, initialBoardvalue)];
					// arr = [...filterOut, constructSeedDetailsFormArr(resp.data)];
				}

				change("seed_details", arr);
				setPageLoading(false);
			};
			const onError = (e) => {
				hideLoader();
				errorMessage(e);
			};
			// Get last added seed - slug
			const slug = last(seedsArr) && last(seedsArr).value;
			let locationMode = true;
			modalType === 2 && (locationMode = false);

			fetchSeedDetails(slug, onSuccess, onError, locationMode, selectSeed);
		}
		// No values inside input - all removed
		else {
			change("seed_details", []);
		}
	};

	const getActiveTab = () => {
		const totalTabs = size(productVarietyFormValues && productVarietyFormValues.seed_details);
		return +seedsErrorTab >= totalTabs ? 0 : seedsErrorTab;
	};
	const onClickSeedsTab = (key) => {
		if (+key >= size(productVarietyFormValues && productVarietyFormValues.seed_details)) {
			accessProductState("seedsErrorTab", 0);
			accessProductState("seasonsErrorTab", `0_0`);
		} else {
			accessProductState("seedsErrorTab", key);
			accessProductState("seasonsErrorTab", `${key}_0`);
		}
	};

	const onClickSeasonsTab = (key) => accessProductState("seasonsErrorTab", key);

	// Submit Form
	const submitProductVarietyDetails = (values) => {
		setPageLoading(true);
		setbtnTitle("Saving...");
		const onSuccess = (e) => {
			successMessage(e);
			setbtnTitle("Save");
			hideLoader();
			closeModal();
		};
		const onError = (e) => {
			hideLoader();
			errorMessage(e);
			setbtnTitle("Save");
		};
		const filterSlug = listFilter[0]?.search;

		if (modalType === 2) {
			const body = {
				...values,
				green: values.green.value,
				seeds: map(values.seeds, "value"),
				seed_details: map(values.seed_details, (s) => seedDetailsForEdit(s)),
			};
			updateProductVariety(body.prSlug, body, filterSlug, onSuccess, onError);
		} else {
			const body = {
				...values,
				green: values.green.value,
				seeds: map(values.seeds, "value"),
				seed_details: map(values.seed_details, (s) => seedDetailsForAdd(s)),
			};
			createProductVariety(body, onSuccess, onError);
		}
	};

	// Form Error - Tab control
	useEffect(() => {
		if (isProductFormError) {
			const err = formErrObj || "Please check all mandatory fields";
			NotificationManager.error(err);
		}
	}, [formErrObj, isProductFormError]);

	useEffect(() => {
		if (initialValues && modalType === 2) {
			getSeedsListForGreens(initialValues && initialValues.green.value, hideLoader, hideLoader);
		}
	}, [getSeedsListForGreens, initialValues, modalType]);

	return (
		<Form onSubmit={handleSubmit(submitProductVarietyDetails)} className="mt-5 position-relative">
			<div className="position-relative">
				<Row>
					<RenderControlField formData={GreensField(greensList)} onChange={onSelectGreens} />
					<RenderControlField formData={SeedsField(seedsList)} onChange={onSelectSeeds} />
				</Row>

				{/* <div ref={scrollRef}>j</div> */}
				{/* Seed Tabs */}
				<FieldArray
					name="seed_details"
					formFieldValues={productVarietyFormValues}
					locationsList={locationsList}
					boardsDropdownList={boardsList}
					component={RenderSeedTabs}
					isEdit={isEdit}
					getActiveTab={getActiveTab}
					onClickSeedsTab={onClickSeedsTab}
					seasonActiveTab={seasonsErrorTab}
					onClickSeasonsTab={onClickSeasonsTab}
					initialValues={initialValues}
					editPanel={editPanel}
				/>
				{/* Loader */}
				{pageLoading && (
					<div className="table-loader-wrapper">
						<TableLoader />
					</div>
				)}
			</div>
			{/* Footer */}
			<div className="modal-footer d-flex justify-content-end save-footer">
				<ButtonSecondary onClick={closeModal}>Cancel</ButtonSecondary>
				<ButtonPrimary type="submit" disabled={pageLoading}>
					{btnTitle}
				</ButtonPrimary>
			</div>
		</Form>
	);
};

const mapStateToProps = (state) => {
	const { Greens, Product, User, Boards } = state;
	return {
		greensDropdownList: Greens.greensDropdownList,
		seedsListForGreens: Product.seedsListForGreens,
		boardsDropdownList: Boards.boardsDropdownList,
		initialValues: Product.editDetailsFormInitialValues,
		productVarietyFormValues: getFormValues("productVarietyForm")(state),
		userLocations: User.userLocations,
		formErrObj: Product.formErrObj,
		seedsErrorTab: Product.seedsErrorTab,
		seasonsErrorTab: Product.seasonsErrorTab,
		isProductFormError: Product.isProductFormError,
	};
};
const mapDispatchToProps = {
	fetchMiniGreensList,
	getSeedsListForGreens,
	fetchSeedDetails,
	fetchLocations,
	fetchMiniBoardsList,
	change,
	createProductVariety,
	updateProductVariety,
	accessProductState,
	onSubmitFail: (errors, dispatch) => dispatch(productFormSubmitFailed(errors)),
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(
	reduxForm({
		form: "productVarietyForm",
		enableReinitialize: true,
		keepDirtyOnReinitialize: true,
	})(ProductVarietyManage)
);
