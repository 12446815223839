import { number, required } from "constant/formValidators";
import React, { useEffect } from "react";
import { Button, Form } from "react-bootstrap";
import { connect, useDispatch,useSelector } from "react-redux";
import { Field, reduxForm, submit } from "redux-form";
import { createStructuredSelector } from "reselect";
import FormGroups from "utils/formGroup";
import { getGallonsInitial } from "store/selectors/dosingCalcSelectors";

const CalculateForm = ({ handleSubmit, submitting, valueChanged, pristine, isDisabled, initialValues }) => {
	const dispatch = useDispatch(null);
	const renderType = useSelector((state) => state.DosingCalculator.pondId);
	const onSubmit = (values) => {
		dispatch(submit(`dosingCalculatorTable_fertilizer_${renderType}`));
	};

	useEffect(() => {
		valueChanged && valueChanged(!pristine);
	}, [pristine, valueChanged]);

	return (
		<div className="dosing-calculator-form-wrapper">
			<Form onSubmit={handleSubmit(onSubmit)} className="form-cal">
				<div className="gallons-col">
					<Field
						name={`gallons_${renderType}`}
						type="text"
						label="Gallons in Pond"
						placeholder={"Enter Gallons"}
						component={FormGroups}
						validate={[required, number]}
					/>
				</div>
				<div className="calculate-btn-col">
					<Button type="submit" disabled={submitting || isDisabled} className="calculate-btn">
						Calculate
					</Button>
				</div>
			</Form>
		</div>
	);
};

const areEqual = (prevProps, nextProps) => {
	return (
		prevProps.submitting === nextProps.submitting &&
		prevProps.valueChanged === nextProps.valueChanged &&
		prevProps.pristine === nextProps.pristine &&
		prevProps.isDisabled === nextProps.isDisabled
	);
};

const mapStateToProps = createStructuredSelector({
	initialValues: getGallonsInitial,
});

export default connect(mapStateToProps)(
	reduxForm({
		form: "calculateForm",
		destroyOnUnmount: false,
		// enableReinitialize: true,
	})(React.memo(CalculateForm, areEqual))
);
