import { TRANSFER_REQUEST } from "../types";
const initialState = {
	taskDetails: {},
	auditLogTransferRequest:{},
	loading: false,
};
export default function (state = initialState, { type, payload }) {
	switch (type) {
		case TRANSFER_REQUEST.GET_TASK_DETAILS:
			return {
				...state,
				taskDetails: payload.data || {},
			};
		case TRANSFER_REQUEST.AUDITLOG_DETAILS:
			return{
				...state,
				auditLogTransferRequest:payload.data || {},
			}
		case TRANSFER_REQUEST.LOCATION_SKU_LIST:
			return{
				...state,
				locationSkuList:payload || {},
			}
		case TRANSFER_REQUEST.LOADER_FULFILLMENT:
			return{
				...state,
				loading: !state.loading,
			}
		default:
			return state;
	}
}
