import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import Util from "../Util";

const TableFilter = ({ column, filter, setFilter, classPrefix, filterArr, defaultDate }) => {
	const [timeout, settimeout] = useState(null);

	useEffect(() => {
		return () => {
			clearTimeout(timeout);
		};
	});

	const findCondition = (item) => JSON.stringify(item.column) === JSON.stringify(column);

	const addToFilter = () => {
		if (filter && !filter.find(findCondition)) {
			setFilter([...filter, { column, value: "" }]);
		} else {
			setFilter(filter.filter((item) => JSON.stringify(item.column) !== JSON.stringify(column)));
		}
	};

	const removeFromFilter = () => {
		const newFilter = filter.filter((item) => JSON.stringify(item.column) !== JSON.stringify(column));
		setFilter(newFilter);
		column.filter.onChange && column.filter.onChange(newFilter);
	};

	const setFilterValue = (e, column) => {
		clearTimeout(timeout);
		const newFilter = filter.map((item) => {
			return JSON.stringify(item.column) === JSON.stringify(column) ? { ...item, value: e.target.value } : item;
		});
		setFilter(newFilter);
		settimeout(
			setTimeout(() => {
				column.filter.onChange && column.filter.onChange(newFilter);
			}, 300)
		);
	};

	// Date handling
	const onHandleDate = (e, column) => {
		const dateStr = Util.dateObjToString(e);
		const newFilter = filter.map((item) => {
			return JSON.stringify(item.column) === JSON.stringify(column) ? { ...item, value: dateStr } : item;
		});
		setFilter(newFilter);
		column.filter.onChange && column.filter.onChange(newFilter);
	};

	// Selected date value
	const getSelectedDateValue = () => {
		const val = filter.find(findCondition) && filter.find(findCondition).value ? filter.find(findCondition).value : "";
		return val ? Util.getDateObjectFormat(val) : "";
	};

	const renderInputType = () => {
		switch (column.filter.type) {
			case "search":
				return (
					<input
						className="form-control"
						type="text"
						value={filter.find(findCondition).value}
						onChange={(e) => setFilterValue(e, column)}
					/>
				);
			case "calendar":
				return (
					<div className="position-relative">
						<DatePicker
							className="form-control form-control-date"
							selected={getSelectedDateValue()}
							onChange={(e) => onHandleDate(e, column)}
							calendarClassName="bright-farms-calendar"
							autoComplete="off"
							dateFormat="MM/dd/yyyy"
							placeholderText="Choose date"
						/>
					</div>
				);
			case "select":
				return (
					<select className="form-control" onChange={(e) => setFilterValue(e, column)}>
						{column && !column.germination && <option value="">Select</option>}
						{column.options &&
							column.options.map((option, index) => (
								<option key={index} value={option.value}>
									{option.label}
								</option>
							))}
					</select>
				);
			default:
				return null;
		}
	};

	const renderFilterIcon = () => {
		switch (column.filter.type) {
			case "search":
				return <i className="icon icon-search" />;
			case "select":
				return <i className="icon icon-angle-down" />;
			case "calendar":
				return <i className="icon icon-calendar" />;
			default:
				return null;
		}
	};

	// Finding if filter array has a key
	const findIfFilterHasKey = (key, type = "obj") => {
		const findObj = filterArr && filterArr.find(({ column_name }) => column_name === key);
		if (type === "obj") {
			return findObj || null;
		} else {
			return findObj ? true : false;
		}
	};

	const shouldShowDateByDefault =
		(column.accessor === "expected_seeding_date" && findIfFilterHasKey("expected_seeding_date")) ||
		(column.accessor === "expected_germination_date" && findIfFilterHasKey("expected_germination_date")) ||
		(column.accessor === "expected_transplant_date" && findIfFilterHasKey("expected_transplant_date")) ||
		(column.accessor === "expected_harvesting_date" && findIfFilterHasKey("expected_harvesting_date")) ||
		(column.accessor === "ship_date" && findIfFilterHasKey("ship_date")) ||
		(column.accessor === "date_of_delivery" && findIfFilterHasKey("date_of_delivery"))
			? true
			: false;
	const shouldShowStatusByDefault =
		column.accessor === "status" && column.germination && findIfFilterHasKey("status") ? true : false;

	const setDefaultValueToListing = () => {
		// Set todays date only if no date object is present in array
		const ifNoDateObjectFound = () => {
			const findDateObj =
				filter &&
				filter.find(
					(o) =>
						(o.column && o.column.accessor && o.column.accessor === "expected_seeding_date") ||
						(o.column && o.column.accessor && o.column.accessor === "expected_germination_date") ||
						(o.column && o.column.accessor && o.column.accessor === "expected_transplant_date") ||
						(o.column && o.column.accessor && o.column.accessor === "ship_date") ||
						(o.column && o.column.accessor && o.column.accessor === "expected_harvesting_date")
				);
			return findDateObj ? false : true;
		};

		if (ifNoDateObjectFound() && shouldShowDateByDefault) {
			const getTodaysDate = Util.getYMDFormat(new Date());
			setFilter([...filter, { column, value: defaultDate ? defaultDate : getTodaysDate }]);
		}

		// if (shouldShowStatusByDefault) {
		// 	setFilter([...filter, { column, value: "Germination" }]);
		// }
	};

	useEffect(setDefaultValueToListing, [shouldShowDateByDefault]);

	return (filter && filter.find(findCondition)) || shouldShowDateByDefault ? (
		<div className={`${classPrefix}-table-column-filter-wrap`}>
			{renderInputType()}
			<button type="button" className={`${classPrefix}-table-column-filter-button`} onClick={removeFromFilter}>
				<i className="icon icon-close text-xs" />
			</button>
		</div>
	) : (filter && filter.find(findCondition)) || shouldShowStatusByDefault ? (
		<div className={`${classPrefix}-table-column-filter-wrap`}>
			{renderInputType()}
			<button type="button" className={`${classPrefix}-table-column-filter-button`} onClick={removeFromFilter}>
				<i className="icon icon-close text-xs" />
			</button>
		</div>
	) : (
		<div className={`${classPrefix}-table-column-filter-icon`} onClick={addToFilter}>
			{renderFilterIcon()}
		</div>
	);
};

export default TableFilter;
