import React from "react";
import Table, { AutoResizer } from "react-base-table";
//import TableLoader from "../../../utils/table/tableLoader";
import Util from "../../../utils/Util";

import { getFixedColumns, getColumnKeys, getFixedColumnsSinglePlot } from "./tableHelper";

const ChartTable = ({ data, timeFrame, type }) => {
	const tablePlotData = data.data;
	const columns =
		(tablePlotData && tablePlotData.product_varieties) || (tablePlotData && tablePlotData.skus)
			? getFixedColumns(
					tablePlotData && tablePlotData.product_varieties
						? tablePlotData.product_varieties
						: tablePlotData && tablePlotData.skus
						? tablePlotData.skus
						: tablePlotData.parameters,
					timeFrame,
					type
			  )
			: getFixedColumnsSinglePlot(tablePlotData.parameters ? tablePlotData.parameters : [], timeFrame);
	const columnKeys = getColumnKeys(columns);

	const enumerateColumns = (value) => {
		const mappedColumnData = [];
		const keys = tablePlotData.product_varieties || tablePlotData.skus ? value : Object.keys(value[0] ? value[0] : value);

		keys.forEach(function (val, i) {
			mappedColumnData[tablePlotData.product_varieties || tablePlotData.skus ? val.slug : val] =
				tablePlotData.product_varieties || tablePlotData.skus ? val.value : value[0] ? value[0][val] : "";
		});

		const newList = [];
		columnKeys &&
			columnKeys.forEach(function (columnKey, i) {
				newList[columnKey] = String(mappedColumnData[columnKey]) ? mappedColumnData[columnKey] : "";
			});
		return newList;
	};

	const graphTableData = [];
	tablePlotData.tableData.forEach(function (graphTable, i) {
		const formattedDateValue = Util.checkValidDateString(graphTable.date);
		graphTableData[i] = {
			id: i === 0 ? "frozen-row-0" : "row-" + i,
			parentId: null,
			"column-0": formattedDateValue !== "" ? formattedDateValue : graphTable.date,
			...enumerateColumns(
				graphTable.product_varieties
					? graphTable.product_varieties
					: graphTable.skus
					? graphTable.skus
					: graphTable.parameters
			),
			total: graphTable.total || graphTable.total === 0 ? graphTable.total : "",
			average: graphTable.avg || graphTable.avg === 0 ? graphTable.avg : "",
		};
	});

	return (
		<div className="accordion-table position-relative table-container">
			<AutoResizer>
				{({ width, height }) => (
					<Table
						classPrefix="AdvanceTable"
						width={width}
						height={height}
						maxHeight={500}
						fixed
						headerHeight={60}
						columns={columns}
						data={graphTableData}
					/>
				)}
			</AutoResizer>
		</div>
	);
};

export default ChartTable;
