import { number, required } from "constant/formValidators";
import { ListingBtn } from "elements";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { autofill, Field, FormSection, unregisterField } from "redux-form";
import ActionForm from "./actionForm";
import { componentGroup } from "./componentGroup";
import RenderActionTableColumn from "./renderActionTableColumn";

export const getUnitOptions = [
	{
		label: "Kg",
		value: "Kg",
	},
	{
		label: "Oz",
		value: "Oz",
	},
];

export const getActionColumns = (
	data,
	actionColumnWidth,
	posThreeRef,
	editableRows,
	setEditableRows,
	onDelete,
	dispatch,
	submit,
	reset,
	cancelEdit,
	setData,
	type,
	editPermission,
	deletePermission
) => {
	return (
		<div className="fertilizer-fixed-column right-fixed" style={{ width: actionColumnWidth, position: "relative" }}>
			<div ref={posThreeRef} className="sticky-head sticky-head-right">
				<Row className={"right-head fertilizer-table-head "} style={{ width: `${actionColumnWidth}px` }}>
					<div className="show-shadow-row-right shadow-head" />
					<Col className="fertilizer-table-col">{type !== "add" && <span>Actions</span>}</Col>
				</Row>
			</div>
			<div className="fertilizer-table-body">
				{data.map((dataItem, d_ind) => (
					<Row
						key={d_ind}
						style={{ width: actionColumnWidth }}
						className={"fertilizer-table-body-row right-row position-relative"}>
						<div className="show-shadow-row-right" />
						<Col className="fertilizer-table-col justify-content-center">
							{type === "add" ? (
								data.length > 1 ? (
									<ListingBtn
										tooltipFontSize={11}
										tooltipText="Delete"
										iconClass="icon icon-trash"
										tooltipLeft={true}
										type="span"
										onClick={() => {
											const temp = data.filter((item) => item.slug !== dataItem.slug);
											setData(temp);
											dispatch(unregisterField("fertilizerTable", `row${dataItem.slug}`));
											dispatch(autofill("fertilizerTable", `row${dataItem.slug}`, undefined));
										}}
										btnClass="btn btn-cell btn-light btn-sm"
									/>
								) : null
							) : editableRows.indexOf(d_ind) === -1 ? (
								<>
									{editPermission && (
										<ListingBtn
											tooltipFontSize={11}
											tooltipText="Edit"
											tooltipLeft={true}
											type="span"
											iconClass="icon icon-edit"
											onClick={() => setEditableRows([...editableRows, d_ind])}
											btnClass="btn btn-cell btn-light btn-sm "
										/>
									)}
									{deletePermission && (
										<ListingBtn
											tooltipFontSize={11}
											tooltipText="Delete"
											iconClass="icon icon-trash"
											tooltipLeft={true}
											type="span"
											onClick={() => onDelete(dataItem.slug)}
											btnClass="btn btn-cell btn-light btn-sm ml-2"
										/>
									)}
								</>
							) : (
								<>
									<ListingBtn
										tooltipFontSize={11}
										tooltipLeft={true}
										tooltipText="Save"
										type="span"
										iconClass={"icon icon-check modal-table-icon"}
										onClick={(e) => dispatch(submit(`fertilizerTable${d_ind}`))}
										btnClass="btn btn-cell btn-light btn-sm"
									/>

									<ListingBtn
										tooltipFontSize={11}
										tooltipLeft={true}
										tooltipText="Cancel"
										type="span"
										iconClass={"icon icon-close modal-table-icon"}
										onClick={(e) => {
											dispatch(reset(`fertilizerTable${d_ind}`));
											cancelEdit(d_ind);
										}}
										btnClass="btn btn-cell btn-light btn-sm ml-2"
									/>
								</>
							)}
						</Col>
					</Row>
				))}
			</div>
		</div>
	);
};
export const getData = (data, posTwoRef, columns, slugKey, renderKey, render, editableRows, type, onSave, cancelEdit) => {
	// const arr = keys(data).sort();

	return (
		<>
			<div ref={posTwoRef} className="sticky-head">
				<Row className="fertilizer-table-head position-relative">
					{columns.map((item, idx) => (
						<Col key={idx} className="fertilizer-table-col">
							<span>{item.name ? item.name : item}</span>
						</Col>
					))}
				</Row>
			</div>
			<div className="fertilizer-table-body">
				{data.map((a_item, a_ind) => {
					const initialValues = {};
					// eslint-disable-next-line array-callback-return
					if (type === "add") {
						return (
							<FormSection
								key={a_ind.toString()}
								name={`row${a_item.slug}.data`}
								component={dataColumns}
								a_item={a_item.data}
								columns={columns}
								a_ind={a_ind}
								editable={true}
								slugKey={slugKey}
								type={type}
							/>
						);
						// dataColumns(a_item.data, columns, a_ind, editable, c_item, slugKey);
					} else {
						// eslint-disable-next-line array-callback-return
						a_item.data.map((item) => {
							if (slugKey) {
								initialValues[item[slugKey]] = renderKey ? item[renderKey] : item.value;
							} else {
								initialValues[item.slug] = renderKey ? item[renderKey] : item.value;
							}
						});

						return (
							<ActionForm
								key={a_ind.toString()}
								form={`fertilizerTable${a_ind}`}
								columns={columns}
								renderKey={renderKey}
								render={render}
								a_ind={a_ind}
								editable={type === "add" ? true : editableRows.indexOf(a_ind) !== -1 ? true : false}
								a_item={a_item}
								slugKey={slugKey}
								onSave={onSave}
								cancelEdit={cancelEdit}
								type={type}
								// initialValues={{
								// 	name: a_item.name,
								// 	unit:
								// 		editableRows.indexOf(a_ind) !== -1
								// 			? { label: a_item.unit, value: a_item.unit }
								// 			: a_item.unit,
								// 	unit_weight: a_item.unit_weight,
								// 	data: initialValues,
								// }}
								slug={a_item}
							/>
						);
					}
				})}
			</div>
		</>
	);
};

const dataColumns = ({ a_item, columns, a_ind, editable, slugKey, type }) => {
	return (
		<Row key={a_ind} className="fertilizer-table-body-row">
			{columns.map((b_item, b_ind) => {
				const c_ind = a_item.findIndex((key) =>
					slugKey ? key[slugKey] === b_item.slug : b_item.slug ? key.slug === b_item.slug : key.slug === b_item
				);

				if (c_ind !== -1) {
					const c_item = a_item[c_ind];
					return (
						<RenderActionTableColumn
							key={b_ind}
							b_ind={b_ind}
							a_ind={a_ind}
							editable={editable}
							c_item={c_item}
							slugKey={slugKey}
							type={type}
						/>
					);
				} else {
					return (
						<Col key={b_ind} className="fertilizer-table-col">
							<span>-</span>
						</Col>
					);
				}
			})}
		</Row>
	);
};

export const fixedColumnTable = (
	data,
	fixedColumnWidth,
	posOneRef,
	fixedColumnHeading,
	type,
	editable,
	editableRows,
	renderKey,
	slugKey,
	dispatch,
	onSave,
	cancelEdit
) => {
	return (
		<div className="fertilizer-fixed-column" style={{ width: fixedColumnWidth, position: "relative" }}>
			<div ref={posOneRef} className="sticky-head">
				<div className="position-relative">
					<Row className={"fertilizer-table-head "}>
						<div className="show-shadow-row shadow-head" />
						<Col className="fertilizer-table-col name-col">
							<span>Name</span>
						</Col>
						<Col className="fertilizer-table-col unit-col">
							<span>Unit</span>
						</Col>
						<Col className="fertilizer-table-col unit-weight-col">
							<span>Unit Weight(Oz/Kg)</span>
						</Col>
					</Row>
				</div>
			</div>
			<div className="fertilizer-table-body">
				{data.map((dataItem, d_ind) => {
					const initialValues = {};
					if (type === "add") {
						return (
							<FormSection
								key={d_ind.toString()}
								name={`row${dataItem.slug}`}
								component={fixedColumnRow}
								d_ind={d_ind}
								slug={dataItem.slug}
								editable={editable}
								type={type}
								fixedColumnWidth={fixedColumnWidth}
							/>
						);
					} else {
						// eslint-disable-next-line array-callback-return
						dataItem.data.map((item) => {
							if (slugKey) {
								initialValues[item[slugKey]] = renderKey ? item[renderKey] : item.value;
							} else {
								initialValues[item.slug] = renderKey ? item[renderKey] : item.value;
							}
						});
						return (
							<ActionForm
								key={d_ind.toString()}
								form={`fertilizerTable${d_ind}`}
								// columns={columns}
								// renderKey={renderKey}
								// render={render}
								a_ind={d_ind}
								editable={editableRows.indexOf(d_ind) !== -1 ? true : false}
								a_item={dataItem}
								// slugKey={slugKey}
								initialValues={{
									name: dataItem.name,
									unit:
										editableRows.indexOf(d_ind) !== -1
											? { label: dataItem.unit, value: dataItem.unit }
											: dataItem.unit,
									unit_weight: dataItem.unit_weight,
									data: initialValues,
								}}
								slug={dataItem.slug}
								slugKey={slugKey}
								renderKey={renderKey}
								type={type}
								columnType="fixed"
								dispatch={dispatch}
								onSave={onSave}
								cancelEdit={cancelEdit}
							/>
						);
					}
				})}
			</div>
		</div>
	);
};

const fixedColumnRow = ({ d_ind, editable, type, slug, fixedColumnWidth }) => {
	return (
		<div className="position-relative">
			<Row key={d_ind} className={"fertilizer-table-body-row position-relative"}>
				<div className="show-shadow-row" />
				<Col className="fertilizer-table-col name-col">
					{/* <span>{dataItem.name}</span> */}
					<Field
						id={`name${slug}`}
						name={"name"}
						type={type === "add" ? "input" : editable ? "input" : "div"}
						component={componentGroup}
						validate={[required]}
						selectMenuHeight={200}
						// {...(!editable && {defaultValue: {this.props.editableOpts}} : {})}
					/>
				</Col>
				<Col className="fertilizer-table-col unit-col">
					{/* <span>{dataItem.unit}</span> */}
					<Field
						id={`unit${slug}`}
						name={"unit"}
						type={type === "add" ? "select-react" : editable ? "select-react" : "div"}
						component={componentGroup}
						validate={[required]}
						selectMenuHeight={200}
						options={getUnitOptions}
						// {...(!editable && {defaultValue: {this.props.editableOpts}} : {})}
					/>
				</Col>
				<Col className="fertilizer-table-col unit-weight-col">
					{/* <span>{dataItem.unit_weight}</span> */}
					<Field
						id={`unit_weight${slug}`}
						name={"unit_weight"}
						type={type === "add" ? "input" : editable ? "input" : "div"}
						component={componentGroup}
						validate={[required, number]}
						selectMenuHeight={200}
						// {...(!editable && {defaultValue: {this.props.editableOpts}} : {})}
					/>
				</Col>
			</Row>
		</div>
	);
};
