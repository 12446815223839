import { USERS } from "../types";
import fetchHandler from "../../utils/fetchHandler";

export function updateField(field, val) {
	return {
		type: USERS.UPDATE_FIELD,
		field,
		val,
	};
}

export const deleteUser = (id, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `user/${id}`,
		method: "DELETE",
		actionType: USERS.DELETE_USER,
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const changeUserStatus = (payload) => {
	const fetchOptions = {
		url: "user/status",
		method: "PUT",
		body: JSON.stringify(payload),
		actionType: USERS.CHANGE_STATUS,
	};

	return fetchHandler(fetchOptions);
};

export const getUserRole = () => {
	const fetchOptions = {
		url: "roles",
		method: "GET",
		actionType: USERS.GET_ROLE,
	};

	return fetchHandler(fetchOptions);
};

export const fetchUsers = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: "users",
		method: "POST",
		actionType: USERS.FETCH_USERS,
		body: JSON.stringify(values),
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const fetchUserDetails = (id, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `user/${id}`,
		method: "GET",
		actionType: USERS.FETCH_USER_DETAILS,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const fetchLocations = () => {
	const fetchOptions = {
		url: `locations`,
		method: "GET",
		actionType: USERS.FETCH_LOCATION_LIST,
	};

	return fetchHandler(fetchOptions);
};

export const fetchUserRoles = () => {
	const fetchOptions = {
		url: `roles`,
		method: "GET",
		actionType: USERS.FETCH_USER_ROLES,
	};

	return fetchHandler(fetchOptions);
};

export const createUser = (values, method, successHandler, errorHandler) => {
	const fetchOptions = {
		url: method ? `user/${method}` : `user`,
		method: "POST",
		secure: true,
		actionType: USERS.CREATE_USER,
		body: JSON.stringify(values),
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const updateProfile = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: "profile",
		method: "POST",
		actionType: USERS.UPDATE_PROFILE,
		body: JSON.stringify(values),
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};
