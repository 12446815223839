import React from "react";
import LaneCalculatorForm from "../manage/index";
import { Col, Row } from "react-bootstrap";
import Select from "react-select";
import { isEmpty } from "lodash";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import { laneUsageDropDown, tableViewDropDown, laneUtilizedColumns } from "./helper";
import { errorMessage } from "utils/errorMessage";
import Util from "../../../../utils/Util";
import {
	addForecast,
	setLoaderLc,
	setActiveTabCustomerLaneCalculator,
	setLaneUsageDropDown,
	setTableViewDropDown,
	fetchLaneCalculatorData,
	fetchLaneCalculatorForecastData,
	setActiveTabDCLaneCalculator,
} from "store/actions";
import {
	getCustomerLaneUsageData,
	getLaneCalculatorColumns,
	customerFixedHeader,
	getTabsCustomer,
	getActiveTabCustomer,
	getActiveTabDC,
	getTableDataLC,
	getTabsDC,
	getLaneUtilizedColumns,
	getTableView,
	getTableViewHeader,
	salesVsProdTypeSelect,
	getFormValues,
	getTableViewDataProduction,
	getLaneUtilized,
	customerKeyLabel,
	forcastDropdownSelect,
	getTableDataUSTotal
} from "store/selectors/laneCalculatorSelectors";
import { Tab, TabContent, Tabs } from "react-bootstrap";
import { getCustomTotal } from "./getCustumTotal";
import LaneCalculatorTable from "utils/laneCalculatorTable";
const Listing = ({
	laneUsageData,
	columns,
	header,
	tableData,
	customerTabs,
	activeTabCustomer,
	setActiveTabCustomerLaneCalculator,
	activeTabDC,
	dcTabs,
	laneUtilizedColumn,
	laneUtilizedData,
	tableViewData,
	tableViewHeader,
	setLaneUsageDropDown,
	setTableViewDropDown,
	selectType,
	formValue,
	fetchLaneCalculatorData,
	tableViewDataProduction,
	fetchLaneCalculatorForecastData,
	selectTypeForcast,
	customerKeyLabel,
	setActiveTabDCLaneCalculator,
	tableDataTotal
}) => {
	const handleChangeLaneUsage = (item) => {
		setLaneUsageDropDown(item.value);
		setLoaderLc();
		const submitData = {
			location_slug: formValue.location_slug.value,
			customer_slug: formValue.customer.map((item) => item.value),
			dc_slug: formValue.dc_name.map((item) => item.value),
			sku_slug: formValue.skus.map((item) => item.value),
			view_by: item.value,
			tz: Util.getLocalTimeZone(),
		};

		const successHandler = () => {
			// setLoaderLc();
		};
		const errorHandler = (e) => {
			// setLoaderLc();
			errorMessage(e);
		};
		fetchLaneCalculatorData(submitData, successHandler, errorHandler);
	};
	const handleChangeTableDropDown = (item) => {
		setLoaderLc();
		setTableViewDropDown(item.value);
		const submitData = {
			location_slug: formValue.location_slug.value,
			customer_slug: formValue.customer.map((item) => item.value),
			dc_slug: formValue.dc_name.map((item) => item.value),
			sku_slug: formValue.skus.map((item) => item.value),
			view_by: item.value,
			tz: Util.getLocalTimeZone(),
		};

		const successHandler = () => {
			// setLoaderLc();
		};
		const errorHandler = (e) => {
			// setLoaderLc();
			errorMessage(e);
		};
		fetchLaneCalculatorForecastData(submitData, successHandler, errorHandler);
	};
	return (
		<div className="listing-wrapper">
			<LaneCalculatorForm />

			{laneUtilizedData ? (
				!isEmpty(laneUtilizedData) ? (
					<LaneCalculatorTable
						columns={laneUtilizedColumn}
						fixedColumnHeading=""
						headers={laneUtilizedColumns}
						initialData={laneUtilizedData}
						dateFormat={"MM-DD-YYYY"}
						localeString={true}
						laneUtilization
					/>
				) : null
			) : null}

			{customerTabs ? (
				!isEmpty(customerTabs) ? (
					<div>
						<Row className="justify-content-end sales-vs-prod-type-row">
							<Col className="col-md-4 col-lg-3 d-md-flex flex-row">
								<div className="d-flex justify-content-md-end align-items-center mr-3">
									<span className="form-label">View By: </span>
								</div>
								<div className="form-group">
									<Select
										placeholder={"Select Type"}
										classNamePrefix="react-select"
										onChange={handleChangeTableDropDown}
										options={tableViewDropDown}
										className={`custom-select-box sales-vs-prod}`}
										isMulti={false}
										name="sales vs production type"
										value={selectType}
									/>
								</div>
							</Col>
						</Row>
						{selectType.value === "sales" ||
						selectType.value === "production_vs_sales" ||
						selectType.value === "production_vs_sales_difference" ? (
							customerTabs && (
								<div className="tab-wrapper">
									<Tabs
										activeKey={activeTabCustomer}
										defaultActiveKey={activeTabCustomer}
										mountOnEnter={true}
										onSelect={(k) => {
											setActiveTabCustomerLaneCalculator(customerKeyLabel[k]);
										}}
										className="customer-tab"
										id="uncontrolled-tab-outer">
										{customerTabs &&
											customerTabs.map((tab, index) => (
												<Tab eventKey={tab.key} key={index} title={tab.label}>
													<TabContent bsPrefix="customer-tab-content">
														<Tabs
															activeKey={activeTabDC}
															defaultActiveKey={activeTabDC}
															onSelect={(k) => setActiveTabDCLaneCalculator(k)}
															className="dc-tab"
															mountOnEnter={true}
															id="uncontrolled-tab-inner">
															{dcTabs &&
																dcTabs.map((tab, index) => (
																	<Tab
																		className="dc-tab-cell"
																		eventKey={tab.key}
																		key={index}
																		title={
																			<span>
																				<i className="icon icon-market mr-3" />
																				{tab.label}
																			</span>
																		}>
																		{activeTabDC === tab.key && tableData ? (
																			!isEmpty(tableData) ? (
																				<>
																					<LaneCalculatorTable
																						columns={laneUtilizedColumn}
																						fixedColumnHeading="Sku"
																						initialData={tableViewData}
																						headers={tableViewHeader}
																						render={(item) => (
																							<>
																								{selectType.value ===
																								"production_vs_sales" ? (
																									<>
																										<span className="production-value">
																											{item &&
																											item.production &&
																											item.production
																												? Number.isInteger(
																														item.production
																												  )
																													? item.production.toLocaleString(
																															"en-IN"
																													  )
																													: item.production.toLocaleString(
																															"en-IN"
																													  )
																												: "0"}
																										</span>
																										{" / "}
																										<span className="sales-value">
																											{item &&
																											item.sales &&
																											item.sales
																												? Number.isInteger(
																														item.sales
																												  )
																													? item.sales.toLocaleString(
																															"en-IN"
																													  )
																													: item.sales.toLocaleString(
																															"en-IN"
																													  )
																												: "0"}
																										</span>
																									</>
																								) : selectType.value ===
																										"sales" ||
																								  selectType.value ===
																										"production_vs_sales_difference" ? (
																									<span className="production-value">
																										{item &&
																										item.lane_usage &&
																										item.lane_usage
																											? Number.isInteger(
																													item.lane_usage
																											  )
																												? item.lane_usage.toLocaleString(
																														"en-IN"
																												  )
																												: item.lane_usage.toLocaleString(
																														"en-IN"
																												  )
																											: "0"}
																									</span>
																								) : null}
																							</>
																						)}
																					/>
																				</>
																			) : (
																				<div className="no-results-found text-center">
																					No Results Found
																				</div>
																			)
																		) : null}
																	</Tab>
																))}
														</Tabs>
													</TabContent>
												</Tab>
											))}
									</Tabs>
								</div>
							)
						) : selectType.value === "production" ? (
							<div>
								{tableViewDataProduction ? (
									<LaneCalculatorTable
										type={selectType.value}
										columns={laneUtilizedColumn}
										fixedColumnHeading="Sku"
										initialData={tableViewDataProduction}
										headers={tableViewHeader}
										render={(item) => (
											// console.log("item",item),
											<>
												<span className="production-value">
													{item !== 0 ? (
														<>
															{item.value && item.value
																? Number.isInteger(item.value)
																	? item.value.toLocaleString("en-IN")
																	: item.value.toLocaleString("en-IN")
																: "0"}
														</>
													) : (
														"0"
													)}
												</span>
											</>
										)}
									/>
								) : (
									<div className="no-results-found text-center">No Results Found</div>
								)}
							</div>
						) : null}
					</div>
				) : null
			) : null}
		

			{laneUsageData ? (
				!isEmpty(laneUsageData) ? (
					<div>
						<div className="d-flex justify-content-between">
							<h1 className="form-headers pl-0" style={{paddingTop:"30px"}}>GH Lane Usage</h1>
						</div>
						<Row className="justify-content-end sales-vs-prod-type-row">
							<Col className="col-md-4 col-lg-3 d-md-flex flex-row">
								<div className="d-flex justify-content-md-end align-items-center mr-3">
									<span className="form-label">View By: </span>
								</div>
								<div className="form-group">
									<Select
										placeholder={"Select Type"}
										classNamePrefix="react-select"
										onChange={handleChangeLaneUsage}
										options={laneUsageDropDown}
										className={`custom-select-box sales-vs-prod}`}
										isMulti={false}
										name="sales vs production type"
										value={selectTypeForcast}
									/>
								</div>
							</Col>
						</Row>

						<LaneCalculatorTable
							columns={columns}
							fixedColumnHeading="Product Varieties"
							headers={header}
							initialData={laneUsageData}
							dateFormat={"MM-DD-YYYY"}
							localeString={true}
							total
							// tableDataTotal
							customTotal={() =>
								tableDataTotal &&
								getCustomTotal(tableDataTotal)
							}
						/>
					</div>
				) : null
			) : null}

			{laneUtilizedData &&
				isEmpty(laneUtilizedData) &&
				tableViewData &&
				isEmpty(tableViewData) &&
				laneUsageData &&
				isEmpty(laneUsageData) && <div className="no-results-found text-center">No Results Found</div>}
		</div>
	);
};

const mapStateToProps = createStructuredSelector({
	columns: getLaneCalculatorColumns,
	laneUsageData: getCustomerLaneUsageData,
	header: customerFixedHeader,
	tableData: getTableDataLC,
	customerTabs: getTabsCustomer,
	activeTabCustomer: getActiveTabCustomer,
	activeTabDC: getActiveTabDC,
	dcTabs: getTabsDC,
	laneUtilizedColumn: getLaneUtilizedColumns,
	laneUtilizedData: getLaneUtilized,
	tableViewData: getTableView,
	tableViewHeader: getTableViewHeader,
	selectType: salesVsProdTypeSelect(tableViewDropDown),
	selectTypeForcast: forcastDropdownSelect(laneUsageDropDown),
	formValue: getFormValues,
	tableViewDataProduction: getTableViewDataProduction,
	customerKeyLabel: customerKeyLabel,
	tableDataTotal: getTableDataUSTotal,
});
export default connect(mapStateToProps, {
	setLoaderLc,
	addForecast,
	setActiveTabCustomerLaneCalculator,
	setLaneUsageDropDown,
	setTableViewDropDown,
	fetchLaneCalculatorData,
	fetchLaneCalculatorForecastData,
	setActiveTabDCLaneCalculator,
})(Listing);
