import { PRODUCTION_FORECAST } from "store/types";
import fetchHandler from "utils/fetchHandler";

export const setLoader = () => {
	return (dispatch) => {
		dispatch({
			type: PRODUCTION_FORECAST.LOADER,
		});
	};
};

export const fetchLocationProductVaritiesPF = (successHandler, errorHandler) => {
	const fetchOptions = {
		url: `location-product-varieties`,
		method: "GET",
		secure: true,
		actionType: PRODUCTION_FORECAST.LOCATION_PRODUCT_VARIETIES,
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const setForecastColumns = (values, successHandler, errorHandler) => {
	return (dispatch) => {
		dispatch({
			type: PRODUCTION_FORECAST.FORECAST_COLUMNS,
			payload: values,
		});
	};
};

export const fetchForecastData = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `production-forecasts`,
		method: "POST",
		secure: true,
		body: JSON.stringify(values),
		actionType: PRODUCTION_FORECAST.SET_DATA,
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};
export const fetchForecastDataExport = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `production-forecasts`,
		method: "POST",
		secure: true,
		body: JSON.stringify(values),
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const fetchHarvestForecastPoundsData = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `production-forecast/harvest-forecast`,
		method: "POST",
		secure: true,
		body: JSON.stringify(values),
		actionType: PRODUCTION_FORECAST.SET_DATA,
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};
export const fetchHarvestForecastPoundsDataExport = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `production-forecast/harvest-forecast`,
		method: "POST",
		secure: true,
		body: JSON.stringify(values),
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const setForecastData = (values, successHandler, errorHandler) => {
	return (dispatch) => {
		dispatch({
			type: PRODUCTION_FORECAST.SET_DATA,
			payload: { data: values },
		});
	};
};

export const setForecastEditable = (value) => {
	return (dispatch) => {
		dispatch({
			type: PRODUCTION_FORECAST.FORECAST_EDITABLE,
			payload: value,
		});
	};
};

export const setForecastRenderType = (value) => {
	return (dispatch) => {
		dispatch({
			type: PRODUCTION_FORECAST.FORECAST_RENDER_TYPE,
			payload: value,
		});
	};
};

export const setForecastAddInitialData = (value) => {
	return (dispatch) => {
		dispatch({
			type: PRODUCTION_FORECAST.FORECAST_ADD_DATA,
			payload: value,
		});
	};
};

export const syncLuna = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `luna/today?startDate=${values.startDate}&endDate=${values.endDate}&facilityId=${values.facilityId}`,
		method: "GET",
		secure: true,
		// body: JSON.stringify(values),
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const fetchHarvestForecastV2 = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `harvest-forecast-retrieve`,
		method: "POST",
		secure: true,
		body: JSON.stringify(values),
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const fetchHarvestActualV2 = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `harvest-actual-retrieve-v2`,
		method: "POST",
		secure: true,
		body: JSON.stringify(values),
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const updateHarvestForecast = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `harvest-forecast-update`,
		method: "POST",
		secure: true,
		body: JSON.stringify(values),
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const updateHarvestActualV2 = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `harvest-actual-update-v2`,
		method: "POST",
		secure: true,
		body: JSON.stringify(values),
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const deleteHarvestActualV2 = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `harvest-actual-delete-v2`,
		method: "DELETE",
		secure: true,
		body: JSON.stringify(values),
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const addForecast = (values, successHandler, errorHandler, type = "add") => {
	const fetchOptions = {
		url: `production-forecast`,
		method: type === "add" ? "POST" : "PUT",
		secure: true,
		body: JSON.stringify(values),
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const updateForecast = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `production-forecast-update`,
		method: "POST",
		secure: true,
		body: JSON.stringify(values),
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const forecastResetRefresh = () => {
	return (dispatch) => {
		dispatch({
			type: PRODUCTION_FORECAST.RESET_REFRESH,
		});
	};
};

export const setForecastFormData = (values) => {
	return (dispatch) => {
		dispatch({
			type: PRODUCTION_FORECAST.FORECAST_FORM_DATA,
			payload: values,
		});
	};
};

export const copyForecast = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `production-forecast/copy`,
		method: "POST",
		secure: true,
		body: JSON.stringify(values),
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const fetchLocationSkusPF = (successHandler, errorHandler) => {
	const fetchOptions = {
		url: `location-skus`,
		method: "GET",
		secure: true,
		actionType: PRODUCTION_FORECAST.LOCATION_SKUS,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const fetchProductionForecastCasesData = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `production-forecast/forecast-cases`,
		method: "POST",
		secure: true,
		body: JSON.stringify(values),
		actionType: PRODUCTION_FORECAST.SET_DATA,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const fetchProductionForecastCasesDataExport = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `production-forecast/forecast-cases`,
		method: "POST",
		secure: true,
		body: JSON.stringify(values),
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

//sales vs prod

export const getLocationCustomersPF = (successHandler, errorHandler) => {
	const fetchOptions = {
		url: `location-customers`,
		method: "GET",
		secure: true,
		actionType: PRODUCTION_FORECAST.LOCATION_CUSTOMERS,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const fetchSalesVsProductionData = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `production-forecast/production-sales`,
		method: "POST",
		secure: true,
		body: JSON.stringify(values),
		actionType: PRODUCTION_FORECAST.SET_DATA_SP,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const fetchSalesVsProductionDataExport = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `production-forecast/production-sales`,
		method: "POST",
		secure: true,
		body: JSON.stringify(values),
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const setActiveTabCustomer = (value, type) => {
	return (dispatch) => {
		dispatch({
			type:
				type && type === "sf"
					? PRODUCTION_FORECAST.SET_ACTIVE_TAB_CUSTOMER_SF
					: PRODUCTION_FORECAST.SET_ACTIVE_TAB_CUSTOMER,
			payload: value,
		});
	};
};

export const setActiveTabDC = (value) => {
	return (dispatch) => {
		dispatch({
			type: PRODUCTION_FORECAST.SET_ACTIVE_TAB_DC,
			payload: value,
		});
	};
};

// ==== SALES FORECAST======

export const fetchDailyForecastSF = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `sales-forecasts/daily`,
		method: "POST",
		secure: true,
		body: JSON.stringify(values),
		actionType: PRODUCTION_FORECAST.SET_SALES_FORECAST_DATA_SF,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const fetchDailyForecastSFExport = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `sales-forecasts/daily`,
		method: "POST",
		secure: true,
		body: JSON.stringify(values),
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const fetchWeeklyForecastSF = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `sales-forecasts/weekly`,
		method: "POST",
		secure: true,
		body: JSON.stringify(values),
		actionType: PRODUCTION_FORECAST.SET_SALES_FORECAST_DATA_SF,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const fetchWeeklyForecastSFExport = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `sales-forecasts/weekly`,
		method: "POST",
		secure: true,
		body: JSON.stringify(values),
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const setDcKeyMapping = (value) => {
	return (dispatch) => {
		dispatch({
			type: PRODUCTION_FORECAST.SET_DC_KEY_MAPPING,
			payload: value,
		});
	};
};

export const addWeeklyForecast = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `sales-forecast/weekly`,
		method: "POST",
		secure: true,
		body: JSON.stringify(values),
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const updateWeeklyForecast = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `sales-forecast/weekly`,
		method: "PUT",
		secure: true,
		body: JSON.stringify(values),
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const updateSalesForecastData = (values) => {
	return (dispatch) => {
		dispatch({
			type: PRODUCTION_FORECAST.UPDATE_SALES_FORECAST_DATA,
			payload: values,
		});
	};
};

export const updateDailyForecast = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `sales-forecast/daily`,
		method: "PUT",
		secure: true,
		body: JSON.stringify(values),
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const setSalesVsProductionType = (value) => {
	return (dispatch) => {
		dispatch({
			type: PRODUCTION_FORECAST.TYPE_SP,
			payload: value,
		});
	};
};

export const uploadExcelFile = (value, successHandler, errorHandler, options) => {
	const fetchOptions = {
		url: `sales-forecast/upload-weekly-forecast`,
		method: "POST",
		secure: true,
		fileUpload: true,
		// body: JSON.stringify(value),
		body: value,
	};
	const type = "axios";
	return fetchHandler(fetchOptions, successHandler, errorHandler, type, options);
};

export const uploadBackfillFile = (value, successHandler, errorHandler, options) => {
	const fetchOptions = {
		url: `harvest-forecast/upload-backfill`,
		method: "POST",
		secure: true,
		fileUpload: true,
		// body: JSON.stringify(value),
		body: value,
	};
	const type = "axios";
	return fetchHandler(fetchOptions, successHandler, errorHandler, type, options);
};

export const setCombinedDifferenceSP = () => {
	return (dispatch) => {
		dispatch({
			type: PRODUCTION_FORECAST.COMBINED_DIFFERENCE_SP,
		});
	};
};

export const fetchSalesVsProductionDataVariety = (values, successHandler, errorHandler, viewBy) => {
	const fetchOptions = {
		url: `production-forecasts-pv`,
		method: "POST",
		secure: true,
		body: JSON.stringify(values),
		actionType:
			viewBy === "production_vs_sales_combined"
				? PRODUCTION_FORECAST.SET_DATA_SP_VARIETY_COMBINED
				: PRODUCTION_FORECAST.SET_DATA_SP_VARIETY,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const fetchSalesVsProductionDataVarietyExport = (values, successHandler, errorHandler, viewBy) => {
	const fetchOptions = {
		url: `production-forecasts-pv`,
		method: "POST",
		secure: true,
		body: JSON.stringify(values),
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const setVarietyTypeSP = (value) => {
	return (dispatch) => {
		dispatch({
			type: PRODUCTION_FORECAST.SET_VARIETY_TYPE_SP,
			payload: value,
		});
	};
};

export const fetchProductionVsActualSalesBySku = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `production-forecast/actual-sales-sku`,
		method: "POST",
		secure: true,
		body: JSON.stringify(values),
		actionType: PRODUCTION_FORECAST.SET_DATA_SP,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const fetchProductionVsActualSalesBySkuExport = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `production-forecast/actual-sales-sku`,
		method: "POST",
		secure: true,
		body: JSON.stringify(values),
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const fetchProductionVsActualSalesByVariety = (values, successHandler, errorHandler, viewBy) => {
	const fetchOptions = {
		url: `production-forecast/actual-sales-pv`,
		method: "POST",
		secure: true,
		body: JSON.stringify(values),
		actionType:
			viewBy === "production_vs_sales_combined"
				? PRODUCTION_FORECAST.SET_DATA_SP_VARIETY_COMBINED
				: PRODUCTION_FORECAST.SET_DATA_SP_VARIETY,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const fetchProductionVsActualSalesByVarietyExport = (values, successHandler, errorHandler, viewBy) => {
	const fetchOptions = {
		url: `production-forecast/actual-sales-pv`,
		method: "POST",
		secure: true,
		body: JSON.stringify(values),
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};
