import React from "react";
import { ListingBtn } from "elements";
import moment from "moment";
import Util from "utils/Util";
import { PackagingWasteDropdown, WastageReasonsList } from "../tasks/dailyTasks/formHelper";
import { inventoryList } from "../tasks/inventory/formHelper";
import i18next from "i18next";

export const renderSetupTimeColumns = ({ addToFilter, handleDeletePanel, handleEditSetUpTimePanel }) => {
	return [
		{
			name: i18next.t("label.date"),
			accessor: "date",
			className: "harvest-listing-col-dc table-col3",
			headerClassName: "harvest-listing-col-dc-header  table-col3",
			// filter: { type: "calendar", onChange: addToFilter },
			render: (row) => moment(row.set_up_date).format("MM/DD/YYYY"),
			sortable: true,
		},
		{
			name: i18next.t("label.set_up_time"),
			accessor: "set_up_time_in_minutes",
			className: "harvest-listing-col-name table-col2",
			headerClassName: "harvest-listing-col-name-header table-col2",
			// render: (row) => row.set_up_time_in_minutes,
			sortable: false,
		},
		{
			name: i18next.t("label.location"),
			accessor: "location",
			className: "harvest-listing-col-name table-col2",
			headerClassName: "harvest-listing-col-name-header table-col2",
			render: (row) => row.location.location_name,
			sortable: false,
		},
		{
			name: i18next.t("label.user"),
			accessor: "user",
			className: "harvest-listing-col-dc",
			headerClassName: "harvest-listing-col-dc-header",
			render: (row) => row.user.name,
			sortable: true,
		},
		{
			name: i18next.t("label.actions"),
			className: "harvest-listing-col-actions",
			headerClassName: "harvest-listing-col-actions-header",
			// hide: updatePermission || deletePermission ? false : true,
			render: (row) => {
				return (
					<>
						<ListingBtn
							parentClass={"justify-content-end"}
							tooltipText={i18next.t("tooltip.edit")}
							iconClass="icon icon-edit"
							onClick={() =>
								handleEditSetUpTimePanel({
									set_up_date: new Date(row.set_up_date),
									location_slug: { label: row.location.location_name, value: row.location.slug },
									set_up_time_in_minutes: row.set_up_time_in_minutes,
									user: { name: row.user.name, value: row.set_up_time_in_minutes },
								})
							}
						/>

						<ListingBtn
							parentClass={"justify-content-end"}
							tooltipText={i18next.t("tooltip.delete")}
							iconClass="icon icon-trash"
							onClick={() =>
								handleDeletePanel({
									set_up_date: Util.getYMDFormat(row.set_up_date),
									set_up_type: row.set_up_type,
									location_slug: row.location.slug,
								})
							}
						/>
					</>
				);
			},
			sortable: false,
		},
	];
};

export const renderCleaningTimeColumns = ({ addToFilter, handleDeletePanel, handleEditCleaningTimePanel }) => {
	return [
		{
			name: i18next.t("label.date"),
			accessor: "date",
			className: "harvest-listing-col-dc table-col3",
			headerClassName: "harvest-listing-col-dc-header  table-col3",
			// filter: { type: "calendar", onChange: addToFilter },
			render: (row) => moment(row.cleaning_date).format("MM/DD/YYYY"),
			sortable: true,
		},
		{
			name: i18next.t("label.cleaning_time"),
			accessor: "cleaning_time_in_minutes",
			className: "harvest-listing-col-name table-col2",
			headerClassName: "harvest-listing-col-name-header table-col2",
			// render: (row) => row.set_up_time_in_minutes,
			sortable: false,
		},
		{
			name: i18next.t("label.location"),
			// accessor: "lane",
			className: "harvest-listing-col-name table-col2",
			headerClassName: "harvest-listing-col-name-header table-col2",
			render: (row) => row.location.location_name,
			sortable: false,
		},
		{
			name: i18next.t("label.user"),
			accessor: "user",
			className: "harvest-listing-col-dc",
			headerClassName: "harvest-listing-col-dc-header",
			render: (row) => row.user.name,
			sortable: true,
		},
		{
			name: i18next.t("label.actions"),
			className: "harvest-listing-col-actions",
			headerClassName: "harvest-listing-col-actions-header",
			// hide: updatePermission || deletePermission ? false : true,
			render: (row) => {
				return (
					<>
						<ListingBtn
							parentClass={"justify-content-end"}
							tooltipText={i18next.t("tooltip.edit")}
							iconClass="icon icon-edit"
							onClick={() =>
								handleEditCleaningTimePanel({
									cleaning_date: new Date(row.cleaning_date),
									location_slug: { label: row.location.location_name, value: row.location.slug },
									cleaning_time_in_minutes: row.cleaning_time_in_minutes,
									user: { name: row.user.name, value: row.cleaning_time_in_minutes },
								})
							}
						/>

						<ListingBtn
							parentClass={"justify-content-end"}
							tooltipText={i18next.t("tooltip.delete")}
							iconClass="icon icon-trash"
							onClick={() =>
								handleDeletePanel({
									cleaning_date: Util.getYMDFormat(row.cleaning_date),
									cleaning_type: row.cleaning_type,
									location_slug: row.location.slug,
								})
							}
						/>
					</>
				);
			},
			sortable: false,
		},
	];
};

export const renderPackagingWasteSKUColumns = ({ addToFilter, handleDeletePanel, handleEditPackagingWasteSKUPanel }) => {
	return [
		{
			name: i18next.t("label.date"),
			accessor: "date",
			className: "harvest-listing-col-dc table-col3",
			headerClassName: "harvest-listing-col-dc-header  table-col3",
			// filter: { type: "calendar", onChange: addToFilter },
			render: (row) => moment(row.date).format("MM/DD/YYYY"),
			sortable: true,
		},
		{
			name: i18next.t("label.location"),
			accessor: "location",
			className: "harvest-listing-col-name table-col2",
			headerClassName: "harvest-listing-col-name-header table-col2",
			render: (row) => row.location_name,
			sortable: false,
		},
		{
			name: i18next.t("label.sku"),
			accessor: "sku_name",
			className: "harvest-listing-col-name table-col2",
			headerClassName: "harvest-listing-col-name-header table-col2",
			render: (row) => row.sku_name,
			sortable: false,
		},
		{
			name: i18next.t("label.cases_wasted"),
			accessor: "wastage_cases",
			className: "harvest-listing-col-name table-col2",
			headerClassName: "harvest-listing-col-name-header table-col2",
			render: (row) => row.wastage_cases,
			sortable: false,
		},
		{
			name: i18next.t("label.reason_for_wastage"),
			accessor: "reason",
			className: "harvest-listing-col-name table-col2",
			headerClassName: "harvest-listing-col-name-header table-col2",
			render: (row) => row.reason,
			sortable: false,
		},
		{
			name: i18next.t("label.created_user"),
			accessor: "user",
			className: "harvest-listing-col-dc",
			headerClassName: "harvest-listing-col-dc-header",
			render: (row) => row.created_by_user,
			sortable: true,
		},
		{
			name: i18next.t("label.actions"),
			className: "harvest-listing-col-actions",
			headerClassName: "harvest-listing-col-actions-header",
			// hide: updatePermission || deletePermission ? false : true,
			render: (row) => {
				return (
					<>
						<ListingBtn
							parentClass={"justify-content-end"}
							tooltipText={i18next.t("tooltip.edit")}
							iconClass="icon icon-edit"
							onClick={() => {
								handleEditPackagingWasteSKUPanel({
									date: new Date(row.date),
									location_slug: { label: row.location_name, value: row.location_slug },
									type: PackagingWasteDropdown.find((item) => item.value === row.type),
									sku: [{ label: row.sku_name, value: row.sku_slug }],
									user: row.created_by_user,
									items: {
										[row.sku_slug]: row.wastage_cases,
										[`${row.sku_slug}_reason`]:
											WastageReasonsList.find((item) => item.value === row.reason) || {},
									},
									package_waste_composition_id: row.package_waste_composition_id,
								});
							}}
						/>

						<ListingBtn
							parentClass={"justify-content-end"}
							tooltipText={i18next.t("tooltip.delete")}
							iconClass="icon icon-trash"
							onClick={() =>
								handleDeletePanel({
									package_waste_composition_id: row.package_waste_composition_id,
								})
							}
						/>
					</>
				);
			},
			sortable: false,
		},
	];
};

export const renderPackagingWasteVarietyColumns = ({ addToFilter, handleDeletePanel, handleEditPackagingWasteSKUPanel }) => {
	return [
		{
			name: i18next.t("label.date"),
			accessor: "date",
			className: "harvest-listing-col-dc table-col3",
			headerClassName: "harvest-listing-col-dc-header  table-col3",
			// filter: { type: "calendar", onChange: addToFilter },
			render: (row) => moment(row.date).format("MM/DD/YYYY"),
			sortable: true,
		},
		{
			name: i18next.t("label.location"),
			accessor: "location",
			className: "harvest-listing-col-name table-col2",
			headerClassName: "harvest-listing-col-name-header table-col2",
			render: (row) => row.location_name,
			sortable: false,
		},
		{
			name: i18next.t("label.product_variety"),
			accessor: "product_variety_name",
			className: "harvest-listing-col-name table-col2",
			headerClassName: "harvest-listing-col-name-header table-col2",
			render: (row) => row.product_variety_name,
			sortable: false,
		},
		{
			name: i18next.t("label.lbs_wasted"),
			accessor: "wastage_pounds",
			className: "harvest-listing-col-name table-col2",
			headerClassName: "harvest-listing-col-name-header table-col2",
			render: (row) => row.wastage_pounds,
			sortable: false,
		},
		{
			name: i18next.t("label.reason_for_wastage"),
			accessor: "reason",
			className: "harvest-listing-col-name table-col2",
			headerClassName: "harvest-listing-col-name-header table-col2",
			render: (row) => row.reason,
			sortable: false,
		},
		{
			name: i18next.t("label.created_user"),
			accessor: "user",
			className: "harvest-listing-col-dc",
			headerClassName: "harvest-listing-col-dc-header",
			render: (row) => row.created_by_user,
			sortable: true,
		},
		{
			name: i18next.t("label.actions"),
			className: "harvest-listing-col-actions",
			headerClassName: "harvest-listing-col-actions-header",
			// hide: updatePermission || deletePermission ? false : true,
			render: (row) => {
				return (
					<>
						<ListingBtn
							parentClass={"justify-content-end"}
							tooltipText={i18next.t("tooltip.edit")}
							iconClass="icon icon-edit"
							onClick={() => {
								handleEditPackagingWasteSKUPanel({
									date: new Date(row.date),
									location_slug: { label: row.location_name, value: row.location_slug },
									type: PackagingWasteDropdown.find((item) => item.value === row.type),
									product_variety: [{ label: row.product_variety_name, value: row.pv_slug }],
									user: row.created_by_user,
									items: {
										[row.pv_slug]: row.wastage_pounds,
										[`${row.pv_slug}_reason`]:
											WastageReasonsList.find((item) => item.value === row.reason) || {},
									},
									package_waste_composition_id: row.package_waste_composition_id,
								});
							}}
						/>

						<ListingBtn
							parentClass={"justify-content-end"}
							tooltipText={i18next.t("tooltip.delete")}
							iconClass="icon icon-trash"
							onClick={() =>
								handleDeletePanel({
									package_waste_composition_id: row.package_waste_composition_id,
								})
							}
						/>
					</>
				);
			},
			sortable: false,
		},
	];
};

export const renderPackedCasesColumns = ({ addToFilter, handleDeletePanel, handleEditInventory }) => {
	return [
		{
			name: i18next.t("label.date"),
			accessor: "date",
			className: "harvest-listing-col-dc table-col3",
			headerClassName: "harvest-listing-col-dc-header  table-col3",
			// filter: { type: "calendar", onChange: addToFilter },
			render: (row) => moment(row.date).format("MM/DD/YYYY"),
			sortable: true,
		},
		{
			name: i18next.t("label.location"),
			accessor: "location",
			className: "harvest-listing-col-name table-col2",
			headerClassName: "harvest-listing-col-name-header table-col2",
			render: (row) => row.location_name,
			sortable: false,
		},
		{
			name: i18next.t("label.sku"),
			accessor: "sku_name",
			className: "harvest-listing-col-name table-col2",
			headerClassName: "harvest-listing-col-name-header table-col2",
			render: (row) => row.sku_name,
			sortable: false,
		},
		{
			name: i18next.t("label.packed_cases"),
			accessor: "unsold_cases",
			className: "harvest-listing-col-name table-col2",
			headerClassName: "harvest-listing-col-name-header table-col2",
			render: (row) => row.unsold_cases,
			sortable: false,
		},

		{
			name: i18next.t("label.created_user"),
			accessor: "user",
			className: "harvest-listing-col-dc",
			headerClassName: "harvest-listing-col-dc-header",
			render: (row) => row.created_by_user,
			sortable: true,
		},
		{
			name: i18next.t("label.actions"),
			className: "harvest-listing-col-actions",
			headerClassName: "harvest-listing-col-actions-header",
			// hide: updatePermission || deletePermission ? false : true,
			render: (row) => {
				return (
					<>
						<ListingBtn
							parentClass={"justify-content-end"}
							tooltipText={i18next.t("tooltip.edit")}
							iconClass="icon icon-edit"
							onClick={() => {
								handleEditInventory({
									date: new Date(row.date),
									location_slug: { label: row.location_name, value: row.location_slug },
									inventory: inventoryList.find((item) => item.value === row.type),
									sku: [{ label: row.sku_name, value: row.sku_slug }],
									user: row.created_by_user,
									itemSelected: [
										{
											label: row.sku_name,
											value: row.sku_slug,
											count: row.unsold_cases,
											addToPackage: row.added_to_package,
										},
									],
									inventory_id: row.inventory_id,
								});
							}}
						/>

						<ListingBtn
							parentClass={"justify-content-end"}
							tooltipText={i18next.t("tooltip.delete")}
							iconClass="icon icon-trash"
							onClick={() =>
								handleDeletePanel({
									inventory_id: row.inventory_id,
								})
							}
						/>
					</>
				);
			},
			sortable: false,
		},
	];
};

export const renderUnpackedLbsColumns = ({ addToFilter, handleDeletePanel, handleEditInventory }) => {
	return [
		{
			name: i18next.t("label.date"),
			accessor: "date",
			className: "harvest-listing-col-dc table-col3",
			headerClassName: "harvest-listing-col-dc-header  table-col3",
			// filter: { type: "calendar", onChange: addToFilter },
			render: (row) => moment(row.date).format("MM/DD/YYYY"),
			sortable: true,
		},
		{
			name: i18next.t("label.location"),
			accessor: "location",
			className: "harvest-listing-col-name table-col2",
			headerClassName: "harvest-listing-col-name-header table-col2",
			render: (row) => row.location_name,
			sortable: false,
		},
		{
			name: i18next.t("label.product_variety"),
			accessor: "product_variety_name",
			className: "harvest-listing-col-name table-col2",
			headerClassName: "harvest-listing-col-name-header table-col2",
			render: (row) => row.product_variety_name,
			sortable: false,
		},
		{
			name: i18next.t("label.unpacked_lbs"),
			accessor: "unpacked_lbs",
			className: "harvest-listing-col-name table-col2",
			headerClassName: "harvest-listing-col-name-header table-col2",
			render: (row) => row.unpacked_lbs,
			sortable: false,
		},

		{
			name: i18next.t("label.created_user"),
			accessor: "user",
			className: "harvest-listing-col-dc",
			headerClassName: "harvest-listing-col-dc-header",
			render: (row) => row.created_by_user,
			sortable: true,
		},
		{
			name: i18next.t("label.actions"),
			className: "harvest-listing-col-actions",
			headerClassName: "harvest-listing-col-actions-header",
			render: (row) => {
				return (
					<>
						<ListingBtn
							parentClass={"justify-content-end"}
							tooltipText={i18next.t("tooltip.edit")}
							iconClass="icon icon-edit"
							onClick={() => {
								handleEditInventory({
									date: new Date(row.date),
									location_slug: { label: row.location_name, value: row.location_slug },
									inventory: inventoryList.find((item) => item.value === row.type),
									product_variety: [{ label: row.product_variety_name, value: row.pv_slug }],
									user: row.created_by_user,
									itemSelected: [
										{
											label: row.product_variety_name,
											value: row.pv_slug,
											count: row.unpacked_lbs,
											addToPackage: row.added_to_package,
										},
									],
									inventory_id: row.inventory_id,
								});
							}}
						/>

						<ListingBtn
							parentClass={"justify-content-end"}
							tooltipText={i18next.t("tooltip.delete")}
							iconClass="icon icon-trash"
							onClick={() =>
								handleDeletePanel({
									inventory_id: row.inventory_id,
								})
							}
						/>
					</>
				);
			},
			sortable: false,
		},
	];
};

export const renderPackRateColumns = ({ handleDeletePanel, handleEditPackRatePanel }) => {
	return [
		{
			name: i18next.t("label.packed_date"),
			accessor: "date",
			className: "harvest-listing-col-dc table-col3",
			headerClassName: "harvest-listing-col-dc-header  table-col3",
			render: (row) => moment(row.packed_date).format("MM/DD/YYYY"),
			sortable: true,
		},
		{
			name: i18next.t("label.no_of_cases_packed"),
			accessor: "total_cases_packed",
			className: "harvest-listing-col-name table-col2",
			headerClassName: "harvest-listing-col-name-header table-col2",
			sortable: false,
		},
		{
			name: i18next.t("label.total_pounds_packed"),
			accessor: "total_pounds_packed",
			className: "harvest-listing-col-name table-col2",
			headerClassName: "harvest-listing-col-name-header table-col2",
			sortable: false,
		},
		{
			name: i18next.t("label.no_of_employee"),
			accessor: "number_of_employees",
			className: "harvest-listing-col-name table-col2",
			headerClassName: "harvest-listing-col-name-header table-col2",
			sortable: false,
		},
		{
			name: i18next.t("label.start_time"),
			accessor: "start_time",
			className: "harvest-listing-col-dc",
			headerClassName: "harvest-listing-col-dc-header",
			sortable: false,
		},
		{
			name: i18next.t("label.end_time"),
			accessor: "end_time",
			className: "harvest-listing-col-dc",
			headerClassName: "harvest-listing-col-dc-header",
			sortable: false,
		},
		{
			name: i18next.t("label.cases_per_labour_hour"),
			accessor: "cases_per_lab_hour",
			className: "harvest-listing-col-name table-col2",
			headerClassName: "harvest-listing-col-name-header table-col2",
			render: (row) => (row.cases_per_lab_hour ? row.cases_per_lab_hour : "-"),
			sortable: false,
		},
		{
			name: i18next.t("label.lbs_per_labour_hour"),
			accessor: "lbs_per_lab_hour",
			className: "harvest-listing-col-name table-col2",
			headerClassName: "harvest-listing-col-name-header table-col2",
			render: (row) => (row.lbs_per_lab_hour ? row.lbs_per_lab_hour : "-"),
			sortable: false,
		},
		{
			name: i18next.t("label.actions"),
			className: "harvest-listing-col-actions",
			headerClassName: "harvest-listing-col-actions-header",
			render: (row) => {
				return (
					<>
						<ListingBtn
							parentClass={"justify-content-end"}
							tooltipText={i18next.t("tooltip.edit")}
							iconClass="icon icon-edit"
							onClick={() =>
								handleEditPackRatePanel({
									date: new Date(row.packed_date.split("-")),
									location_slug: { label: row.location_name, value: row.location_slug },
									total_cases_packed: row.total_cases_packed,
									total_pounds_packed: row.total_pounds_packed,
									number_of_employees: row.number_of_employees,
									start_time: row.start_time,
									end_time: row.end_time,
									cases_per_lab_hour: row.cases_per_lab_hour,
									lbs_per_lab_hour: row.lbs_per_lab_hour,
								})
							}
						/>

						<ListingBtn
							parentClass={"justify-content-end"}
							tooltipText={i18next.t("tooltip.delete")}
							iconClass="icon icon-trash"
							onClick={() =>
								handleDeletePanel({
									packed_date: row.packed_date,
									location_slug: row.location_slug,
								})
							}
						/>
					</>
				);
			},
			sortable: false,
		},
	];
};

export const renderPackRoomPreCool = ({ handleDeletePanel, handleEditPackRoomPreCool }) => {
	return [
		{
			name: i18next.t("label.packed_date"),
			accessor: "date",
			className: "harvest-listing-col-dc table-col3",
			headerClassName: "harvest-listing-col-dc-header  table-col3",
			render: (row) => moment(row.date).format("MM/DD/YYYY"),
			sortable: true,
		},
		{
			name: i18next.t("label.room"),
			accessor: "room",
			className: "harvest-listing-col-name table-col2",
			headerClassName: "harvest-listing-col-name-header table-col2",
			sortable: false,
		},
		{
			name: i18next.t("label.temperature"),
			accessor: "temperature",
			className: "harvest-listing-col-name table-col2",
			headerClassName: "harvest-listing-col-name-header table-col2",
			sortable: false,
		},
		{
			name: i18next.t("label.humidity"),
			accessor: "humidity",
			className: "harvest-listing-col-name table-col2",
			headerClassName: "harvest-listing-col-name-header table-col2",
			sortable: false,
		},
		{
			name: i18next.t("label.created_user"),
			accessor: "created_by",
			className: "harvest-listing-col-dc",
			headerClassName: "harvest-listing-col-dc-header",
			sortable: false,
		},

		{
			name: i18next.t("label.actions"),
			className: "harvest-listing-col-actions",
			headerClassName: "harvest-listing-col-actions-header",
			render: (row) => {
				return (
					<>
						<ListingBtn
							parentClass={"justify-content-end"}
							tooltipText={i18next.t("tooltip.edit")}
							iconClass="icon icon-edit"
							onClick={() =>
								handleEditPackRoomPreCool({
									date: new Date(row.date.split("-")),
									location_slug: { label: row.location_name, value: row.location_slug },
									room: { label: row.room, value: row.room.replace(/\s+/g, "") },
									temperature: row.temperature,
									humidity: row.humidity,
								})
							}
						/>

						<ListingBtn
							parentClass={"justify-content-end"}
							tooltipText={i18next.t("tooltip.delete")}
							iconClass="icon icon-trash"
							onClick={() =>
								handleDeletePanel({
									date: row.date,
									location_slug: row.location_slug,
								})
							}
						/>
					</>
				);
			},
			sortable: false,
		},
	];
};

export const renderProductTemperatureDetails = ({ handleDeletePanel, handleEditProductTemperatureDetails }) => {
	return [
		{
			name: i18next.t("label.packed_date"),
			accessor: "date",
			className: "harvest-listing-col-dc table-col3",
			headerClassName: "harvest-listing-col-dc-header  table-col3",
			render: (row) => moment(row.date).format("MM/DD/YYYY"),
			sortable: true,
		},
		{
			name: i18next.t("label.product_varieties"),
			accessor: "product_variety_name",
			className: "harvest-listing-col-name table-col2",
			headerClassName: "harvest-listing-col-name-header table-col2",
			sortable: false,
		},
		{
			name: i18next.t("label.room"),
			accessor: "room_type",
			className: "harvest-listing-col-name table-col2",
			headerClassName: "harvest-listing-col-name-header table-col2",
			sortable: true,
		},
		{
			name: i18next.t("label.temperature"),
			accessor: "temperature",
			className: "harvest-listing-col-name table-col2",
			headerClassName: "harvest-listing-col-name-header table-col2",
			sortable: false,
		},
		{
			name: i18next.t("label.humidity"),
			accessor: "humidity",
			className: "harvest-listing-col-name table-col2",
			headerClassName: "harvest-listing-col-name-header table-col2",
			sortable: false,
		},
		{
			name: i18next.t("label.created_user"),
			accessor: "user_name",
			className: "harvest-listing-col-dc",
			headerClassName: "harvest-listing-col-dc-header",
			sortable: false,
		},

		{
			name: i18next.t("label.actions"),
			className: "harvest-listing-col-actions",
			headerClassName: "harvest-listing-col-actions-header",
			render: (row) => {
				return (
					<>
						<ListingBtn
							parentClass={"justify-content-end"}
							tooltipText={i18next.t("tooltip.edit")}
							iconClass="icon icon-edit"
							onClick={() =>
								handleEditProductTemperatureDetails({
									edit:true,
									date: new Date(row.date.split("-")),
									location_slug: { label: row.location_name, value: row.location_slug },
									temperature_details: [
										{
										  room_type: { label: "Pack Room", value: "Pack Room" },
										  product_variety_slug: { label: row.product_variety_name, value: row.product_variety_slug},
										  temperature: row.temperature,
										  humidity: row.humidity
										}
									]
								})
							}
						/>

						<ListingBtn
							parentClass={"justify-content-end"}
							tooltipText={i18next.t("tooltip.delete")}
							iconClass="icon icon-trash"
							onClick={() =>
								handleDeletePanel({
									date: row.date,
									location_slug: row.location_slug,
									product_variety_slug:row.product_variety_slug,
									room_type:row.room_type
								})
							}
						/>
					</>
				);
			},
			sortable: false,
		},
	];
};
