import { WATCH_WIRE } from "store/types";
import fetchHandler from "utils/fetchHandler";


export const setLoaderWW = () => {
	return (dispatch) => {
		dispatch({
			type: WATCH_WIRE.LOADER_WW,
		});
	};
};

export const getDropDownOptions = (successHandler, errorHandler) => {
	const fetchOptions = {
		url: `get-watchwire-dropdown`,
		method: "GET",
		secure: true,
		actionType: WATCH_WIRE.DROPDOWN_LIST,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const fetchWatchWireInvoiceData = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `get-watchwire-invoice-data`,
		method: "POST",
		secure: true,
		actionType: WATCH_WIRE.FETCH_WATCH_WIRE_INVOICE,
		body: JSON.stringify(values),
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};


export const setActiveTabCommodity = (value, type) => {
	return (dispatch) => {
		dispatch({
			type: WATCH_WIRE.SET_ACTIVE_TAB_COMMODITY,
			payload: value,
		});
	};
};

export const setActiveTabAccountId = (value) => {
	return (dispatch) => {
		dispatch({
			type: WATCH_WIRE.SET_ACTIVE_TAB_ACCOUNT_ID,
			payload: value,
		});
	};
};

export const resetFieldsWatchWire = (value) => {
	return (dispatch) => {
		dispatch({
			type: WATCH_WIRE.RESET_WATCH_WIRE_FIELD,
			payload: value,
		});
	};
};

export const fetchExportFunction = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `export-watchwire-invoice-data`,
		method: "POST",
		secure: true,
		actionType: WATCH_WIRE.EXPORT_FILE,
		body: JSON.stringify(values),
		download:true
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};



export const showChartHandler = (value) => {
	return (dispatch) => {
		dispatch({
			type: WATCH_WIRE.SHOW_CHART,
			payload: value,
		});
	};
};

export const setColumns = (value) => {
	return (dispatch) => {
		dispatch({
			type: WATCH_WIRE.SET_COLUMNS,
			payload: value,
		});
	};
};





