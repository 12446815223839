import { required,number,isInt } from "constant/formValidators";

export const forecastFormFields = [
	{
		label: "Location *",
		name: "location_slug",
		type: "select-react",
		placeholder: "Select Location",
		validate: [required],
	},
	{
		label: "Sku *",
		name: "skus",
		type: "select-react",
		placeholder: "Select Sku",
		validate: [required],
	},
	{
		label: "Cases Required *",
		name: "cases_required",
		type: "text",
		placeholder: "Enter Cases Required",
		validate: [required,number,isInt],
	},
];

