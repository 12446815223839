import React from "react";
import { Modal, Alert } from "react-bootstrap";
import moment from "moment";
import useModal from "../../../utils/useModal";
import TaskDetailsForm from "./taskDetailsForm";
import { SeedingTaskFormDetails, GerminationTaskFormDetails } from "./taskDetailsFormHelper";
import { getDropdownObj } from "../manage/formHelper";
import Util from "../../../utils/Util";
import { useTranslation } from "react-i18next";

const { Header, Title, Body } = Modal;

// 1 - Seeding Add
// 2 - Seeding Edit
// 3 - Germination Add
// 4 - Germination Edit
const TaskDetailsModal = ({ showCompleteButton, taskDetails, doFetchTaskDetails }) => {
	const [isOpen, modalType, , closeModal] = useModal("seeding_details");
	const { t } = useTranslation();

	const isSeeding = modalType === 1 || modalType === 2;
	const isGermination = modalType === 3 || modalType === 4;
	const isEditing = modalType === 2 || modalType === 4;

	// Expected germination date- More or equal to expected seeding date
	// Actual Germination Date - More or equal to actual seeding date which is automatically logged by backend
	const minDateForExpGermination = taskDetails.expected_seeding_date
		? Util.getDateObjectFormat(taskDetails.expected_seeding_date)
		: new Date();
	const minDateForActGermination =
		taskDetails && taskDetails.seeding_end_date
			? moment.utc(taskDetails.seeding_end_date).seconds(0).local().toDate()
			: new Date();
	const maxDateForActGermination =
		taskDetails && taskDetails.germination_end_date
			? moment.utc(taskDetails.germination_end_date).seconds(0).local().toDate()
			: null;

	const formFields = isSeeding
		? SeedingTaskFormDetails
		: isGermination
		? GerminationTaskFormDetails(minDateForExpGermination, minDateForActGermination, maxDateForActGermination)
		: [];

	// Initial Values
	const getFormInitialValues = () => {
		if (isEditing) {
			// SEEDING
			if (isSeeding) {
				return {
					seeded_board_count: taskDetails.seeded_board_count,
					seeding_flag_color: getDropdownObj("flagColors", taskDetails.seeding_flag_color),
					seeding_seed_density: taskDetails.seeding_seed_density,
					seeding_container_filled: getDropdownObj("containerFilled", taskDetails.seeding_container_filled),
					seeding_accuracy_in_furrows: taskDetails.seeding_accuracy_in_furrows,
					comments: taskDetails.comments,
				};
			}
			// GERMINATION
			else if (isGermination) {
				return {
					germination_start_date:
						moment.utc(taskDetails.germination_start_date) &&
						moment.utc(taskDetails.germination_start_date).local().toDate(),
					expected_germination_date: Util.getDateObjectFormat(taskDetails.expected_germination_date),
				};
			}
		}
		// Add germination details : Prepopulate expected date
		// NOTE : Below case is not excecuted now [CR]
		// Germination now have only edit / No add
		else if (isGermination) {
			return {
				germination_start_date: taskDetails.germination_start_date
					? taskDetails && moment.utc(taskDetails.germination_start_date).local().toDate()
					: taskDetails.seeding_end_date
					? taskDetails && moment.utc(taskDetails.seeding_end_date).local().toDate()
					: null,
				expected_germination_date: Util.getDateObjectFormat(taskDetails.expected_germination_date),
			};
		}
	};

	return (
		<Modal size="lg" show={isOpen} onHide={closeModal} className="modal-sidepanel">
			<Header closeButton>
				<Title className="modal-title">
					{isSeeding ? t("label.seeding_details") : isGermination ? t("label.germination_details") : ""}
				</Title>
			</Header>
			<Body>
				{isGermination && taskDetails.seeding_end_date && (
					<Alert className="daily-tasks-alert" variant="light">
						{t("label.end_date_and_time")}{" "}
						{taskDetails && moment.utc(taskDetails.seeding_end_date).local().format("MM/DD/YYYY LT")}
					</Alert>
				)}
				<TaskDetailsForm
					showCompleteButton={showCompleteButton}
					formFields={formFields}
					initialValues={getFormInitialValues()}
					slug={(taskDetails && taskDetails.slug) || ""}
					isAssigneeSelected={taskDetails && taskDetails.assignee && taskDetails.assignee.id ? true : false}
					isSeeding={isSeeding}
					isGermination={isGermination}
					doFetchTaskDetails={doFetchTaskDetails}
				/>
			</Body>
		</Modal>
	);
};

export default TaskDetailsModal;
