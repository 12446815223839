import React from "react";
import { Modal } from "react-bootstrap";
import HarvestAddEdit from "./harvestAddEdit";
import useModal from "utils/useModal";
import LogTime from "./logTime";
import { useTranslation } from 'react-i18next';

const { Header, Title, Body } = Modal;

const HarvestManage = ({ slug, handleEditPanel, onAddOrEdit, dailyTaskEdit, doRefetch }) => {
	const { t } = useTranslation();
	const [isOpen, modalType, , closeModal, modalProps] = useModal("harvest");
	return (
		<Modal size="lg" show={isOpen} onHide={closeModal} className="modal-sidepanel">
			<Header closeButton>
				<Title className="modal-title">
					{modalType === 2
						? t("label.repeat_task")
						: modalType === 1
						? t("label.edit_task")
						: modalType === 0
						? t("label.add_task")
						: modalType === 3
						? t("label.cleaning_time")
						: t("label.set_up_time")}
				</Title>
			</Header>
			<Body style={{ overflow: "hidden" }}>
				{modalType === 3 || modalType === 4 ? (
					// <LogTime initialValues={{ cleaning_date: new Date(), set_up_date: new Date() }} editData={modalProps} />
					<LogTime editData={modalProps} doRefetch={doRefetch} />
				) : (
					!dailyTaskEdit && <HarvestAddEdit slug={slug} onAddOrEdit={onAddOrEdit} />
				)}
			</Body>
		</Modal>
	);
};

export default HarvestManage;
