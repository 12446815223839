import { values } from "lodash";
import React, { useEffect, useState } from "react";
import { Button, Form, Row } from "react-bootstrap";
import ScrollContainer from "react-indiana-drag-scroll";
// import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { fixedColumnTable, getData, getActionColumns } from "./columns";

const AddForm = ({
	handleSubmit,
	fixedRef,
	fixedColumnWidth,
	draggableRef,
	onScrollTrack,
	onClick,
	scrollRef,
	editable,
	tableErrors,
	dispatch,
	reset,
	onCancel,
	data: initialData,
	posOneRef,
	fixedColumnHeading,
	type,
	posTwoRef,
	columns,
	slugKey,
	renderKey,
	render,
	editableRows,
	onSave,
	actionColumnWidth,
	actionRef,
	setEditableRows,
	onDelete,
	submit,
	cancelEdit,
	posThreeRef,
}) => {
	const [data, setData] = useState(initialData);

	useEffect(() => {
		onScrollTrack();
	}, [data, onScrollTrack]);

	return (
		<>
			<Form onSubmit={handleSubmit} className="fertilizerTable">
				<div className="fertilizer-table-wrapper">
					<div ref={fixedRef} className="forecast-fixed-column-wrapper" style={{ width: fixedColumnWidth }}>
						{fixedColumnTable(data, fixedColumnWidth, posOneRef, fixedColumnHeading, type, editable)}
					</div>
					<ScrollContainer
						ref={draggableRef}
						hideScrollbars={false}
						className="fertilizer-data-column-wrapper"
						style={{ width: `calc(100% - ${fixedColumnWidth + actionColumnWidth}px)` }}
						onScroll={(e) => onScrollTrack(e)}
						onClick={onClick}>
						<div ref={scrollRef} className="fertilizer-data-column">
							{getData(
								data,
								posTwoRef,
								columns,
								slugKey,
								renderKey,
								render,
								editableRows,
								// onSave,
								// cancelEdit,
								type
							)}
						</div>
					</ScrollContainer>
					<div style={{ width: actionColumnWidth }} ref={actionRef} className="fertilizer-table-column">
						{getActionColumns(
							data,
							actionColumnWidth,
							posThreeRef,
							editableRows,
							setEditableRows,
							onDelete,
							dispatch,
							submit,
							reset,
							cancelEdit,
							setData,
							type
						)}
					</div>
				</div>
				<div
					onClick={() =>
						setData([
							...data,
							{
								...initialData[0],
								slug: data[data.length - 1].slug + 1,
							},
						])
					}
					className="fertilizer-table-add-btn">
					Add
				</div>
				{editable && (
					<Row className="justify-content-end ml-0 mr-0 mt-4 position-relative fertilizer-table-btn-row">
						{tableErrors && <p className="error-string">{tableErrors}</p>}
						<Button
							variant="secondary"
							className="mr-3"
							onClick={() => {
								dispatch(reset("fertilizerTable"));
								onCancel && onCancel();
							}}>
							Cancel
						</Button>
						<Button variant="primary" className="save-btn" type="submit">
							Save
						</Button>
					</Row>
				)}
			</Form>
		</>
	);
};

const scrollToFirstError = (fieldList, props) => {
	const { data, slugKey } = props;

	// eslint-disable-next-line no-unused-vars
	const elem = "";
	// eslint-disable-next-line array-callback-return
	data.some((item) => {
		// for(const key in values(fieldList)[0]){
		// 	if(item)
		// }
		if (`row${item.slug}` in fieldList) {
			if ("name" in fieldList[`row${item.slug}`]) {
				const elem = document.querySelector(`#name${item.slug}`);

				if (elem) {
					// setTimeout(() => {
					elem.scrollIntoView({ behavior: "smooth", block: "center" });
					// }, 800);
					return elem;
				}
			} else if ("unit" in fieldList[`row${item.slug}`]) {
				const elem = document.querySelector(`#unit${item.slug}`);

				if (elem) {
					// setTimeout(() => {
					elem.scrollIntoView({ behavior: "smooth", block: "center" });
					// }, 800);
					return elem;
				}
			} else if ("unit_weight" in fieldList[`row${item.slug}`]) {
				const elem = document.querySelector(`#unit_weight${item.slug}`);

				if (elem) {
					// setTimeout(() => {
					elem.scrollIntoView({ behavior: "smooth", block: "center" });
					// }, 800);
					return elem;
				}
			} else {
				// eslint-disable-next-line array-callback-return
				item.data.some((obj) => {
					if (slugKey) {
						if (obj[slugKey] in fieldList[`row${item.slug}`].data) {
							const elem = document.querySelector(`#${obj[slugKey]}${item.slug}`);

							if (elem) {
								// setTimeout(() => {
								elem.scrollIntoView({ behavior: "smooth", block: "center" });
								// }, 800);
								return elem;
							}
						}
					} else {
						if (obj.slug in fieldList[`row${item.slug}`].data) {
							const elem = document.querySelector(`#${obj.slug}${item.slug}`);

							if (elem) {
								// setTimeout(() => {
								elem.scrollIntoView({ behavior: "smooth", block: "center" });
								// }, 800);
								return elem;
							}
						}
					}
				});
			}
		}

		// if (item.name in values(fieldList)[0]) {
		// 	const elem = document.querySelector(`#${slugKey ? item : item.slug}_${keys(fieldList)[0]}`);

		// 	if (elem) {
		// 		setTimeout(() => {
		// 			elem.scrollIntoView({ behavior: "smooth", block: "center" });
		// 		}, 800);
		// 		return elem;
		// 	}
		// }
	});
};

const onSubmit = (val, dispatch, props) => {
	const { slugKey, renderKey } = props;
	// props.onSave(values, props.slug, editableChange);
	const filterVal = values(val).filter((item) => item !== undefined);

	// eslint-disable-next-line array-callback-return
	const tempData = filterVal.map((item) => {
		if (item) {
			const data = [];

			for (const key in item.data) {
				if (slugKey) {
					if (renderKey) {
						data.push({
							[slugKey]: key,
							[renderKey]: item.data[key],
						});
					} else {
						data.push({
							[slugKey]: key,
							value: item.data[key],
						});
					}
				} else {
					if (renderKey) {
						data.push({
							slug: key,
							[renderKey]: item.data[key],
						});
					} else {
						data.push({
							slug: key,
							value: item.data[key],
						});
					}
				}
			}
			return {
				...item,
				unit: item.unit.value,
				data: data,
			};
		}
	});

	props.onSave(tempData);
};

export default reduxForm({
	form: "fertilizerTable",
	enableReinitialize: true,
	keepDirtyOnReinitialize: true,
	onSubmitFail: (errors, dispatch, submitError, props) => {
		scrollToFirstError(errors, props);
	},
	onSubmit: onSubmit,
})(AddForm);
