import React from "react";

const RenderInputs = () => {
	return (
		<div className="ui-inputs">
			<h2 className="mb-4">Inputs</h2>
			<div className="d-flex">
				<div className="form-group flex-fill mr-3">
					<input type="text" className="form-control" />
				</div>
				<div className="form-group flex-fill error mr-3">
					<input type="text" className="form-control" />
				</div>
				<div className="form-group flex-fill focus">
					<input type="text" className="form-control" />
				</div>
			</div>
		</div>
	);
};

export default RenderInputs;
