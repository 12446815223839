import React from "react";
import { Col, Row } from "react-bootstrap";
import {
	TaskDetailsView,
	ProductInfoView,
	GrowingCondView,
	GerminationCondView,
	SeedingDetailsView,
	GerminationDetailsView,
} from "./viewHelper";
import i18next from "i18next";

import { ButtonPrimary } from "../../../elements";

export const labelledValue = (item, details = {}) => (
	<Col className={`${item.classes} mb-3`} key={item.label}>
		<p className="d-flex align-items-center view-labels mb-1">
			<span className="light-badge-pill"></span>
			{i18next.t(item.label)}
		</p>
		<p className="ml-4 view-values">{item.render ? item.render(details) : details ? details[item.accessor] || "-" : "-"}</p>
	</Col>
);

export const BasicDetails = ({ taskDetails }) => (
	<div className="sg-view-content">
		<h2 className="form-headers mt-4 mb-4 pl-0">{i18next.t('label.task_details')}</h2>
		{taskDetails && <Row>{TaskDetailsView.map((item) => labelledValue(item, taskDetails))}</Row>}
	</div>
);

const EditButton = ({ onClick }) => (
	<button className="btn btn-cell btn-light btn-sm ml-2 float-right" onClick={() => onClick()}>
		<i className="icon icon-edit" />
	</button>
);

export const SeedingDetails = ({ taskDetails, onClickEditAction, logPermission, germinationActive }) => (
	<div className="sg-view-content">
		{logPermission && germinationActive && <EditButton onClick={() => onClickEditAction(2)} />}
		<h2 className="form-headers mt-4 mb-4 pl-0">{i18next.t('label.seeding_details')}</h2>
		<Row>{SeedingDetailsView.map((item) => labelledValue(item, taskDetails))}</Row>
	</div>
);

export const GerminationDetails = ({ taskDetails, onClickEditAction, logPermission, germinationActive }) => (
	<div className="sg-view-content">
		{logPermission && germinationActive && <EditButton onClick={() => onClickEditAction(4)} />}
		<h2 className="form-headers mt-4 mb-4 pl-0">{i18next.t('label.germination_details')}</h2>
		<Row>{GerminationDetailsView.map((item) => labelledValue(item, taskDetails))}</Row>
	</div>
);

export const ProductInformation = ({ taskDetails, seasonDetails }) =>
	taskDetails &&
	seasonDetails && (
		<div className="sg-view-content pt-no-head">
			<Row>
				<Col className="col-12 mb-2 mt-1">
					<h2 className="form-headers mb-4 pl-0">{i18next.t('label.product_information')}</h2>
					<Row>{ProductInfoView.map((item) => labelledValue(item, seasonDetails))}</Row>
				</Col>
				<Col className="col-12 mb-2">
					<h2 className="form-headers mb-4 pl-0">{i18next.t('label.growing_condition')}</h2>
					<Row>{GrowingCondView.map((item) => labelledValue(item, seasonDetails))}</Row>
				</Col>
				<Col className="col-12">
					<h2 className="form-headers mb-4 pl-0">{i18next.t('label.germination_condition')}</h2>
					<Row>{GerminationCondView.map((item) => labelledValue(item, seasonDetails))}</Row>
				</Col>
			</Row>
		</div>
	);

export const TaskStatusBar = ({
	type = 0,
	onClickStatusAction,
	startTaskBtn,
	logPermission,
	tastStartTime,
	germinationActive,
}) =>
	type === 1 ? (
		// STATUS : NEW
		<div className="sg-view-footer">
			{logPermission && germinationActive && (
				<ButtonPrimary className="start-task-btn" onClick={() => onClickStatusAction(type)}>
					{i18next.t(startTaskBtn)}
				</ButtonPrimary>
			)}
		</div>
	) : type === 2 && germinationActive ? (
		<div className="sg-view-content status-bar">
			<h2 className="status-bar-title">
				<span className="view-labels">{i18next.t('label.status')} </span>{i18next.t('label.seeding_in_progress')}
				{tastStartTime && (
					<p className="status-bar-subtitle">
						<span className="view-labels">{i18next.t('label.actul_seeding_start_date')}</span>
						<span className="font-weight-bold">{tastStartTime}</span>
					</p>
				)}
			</h2>
			{logPermission && <ButtonPrimary onClick={() => onClickStatusAction(type)}>{i18next.t('label.enter_seeding_details')}</ButtonPrimary>}
		</div>
	) : type === 3 && germinationActive ? (
		<div className="sg-view-content status-bar">
			<h2 className="status-bar-title">
				<span className="view-labels">{i18next.t('label.status')} </span>{i18next.t('label.germination_in_progress')}
				{tastStartTime && (
					<p className="status-bar-subtitle">
						<span className="view-labels">{i18next.t('label.actual_germination_start_date')}</span>
						<span className="font-weight-bold">{tastStartTime}</span>
					</p>
				)}
			</h2>
			{/* {logPermission && (
				<ButtonPrimary className="complete-task-btn" onClick={() => onClickStatusAction(type)}>
					Complete Task
				</ButtonPrimary>
			)} */}
		</div>
	) : null;
