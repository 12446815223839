import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import useModal from "../../../utils/useModal";
import { reduxForm, Field, reset } from "redux-form";
import { connect } from "react-redux";
import { Container, Button, Row, Col, Form } from "react-bootstrap";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import arrayMove from "array-move";
import FormGroups from "../../../utils/formGroup";
import { errorMessage } from "../../../utils/errorMessage";
import { successMessage } from "../../../utils/successMessage";
import { required } from "../../../constant/formValidators";
import TableLoader from "utils/table/tableLoader";
import Util from "../../../utils/Util";
import { updatePriority, toggleMainLoader } from "../../../store/actions";

const { Header, Title, Body } = Modal;

const SortableItem = SortableElement(({ value, priority }) => (
	<Row>
		<Col className="col-12 sort-priority-container-child d-flex justify-content-between align-items-center">
			<span>{value}</span>
			<span>Priority {priority}</span>
		</Col>
	</Row>
));
const SortableList = SortableContainer(({ priorityList }) => {
	return (
		<>
			<div className="sort-priority-container">
				{priorityList.map((value, index) => (
					<SortableItem key={value.dc_slug} index={index} priority={index + 1} value={value.dc_name} />
				))}
			</div>
		</>
	);
});

const ReorderDc = ({
	handleDcReorder,
	handleSubmit,
	details,
	doRefetch,
	form,
	toggleMainLoader,
	initialValues,
	updatePriority,
	reset,
	onAddOrEdit,
}) => {
	const [isOpen, modalType, , closeModal, modalProp] = useModal("customer");
	const [isLoading, setisLoading] = useState(false);
	const [titleBtn, settitleBtn] = useState("Save");
	const [location, setLocation] = useState("");
	const locationDropDownData = details.locations ? details.locations : [];
	const dcListDataArray = details.dc_priority ? details.dc_priority : [];
	const [priorityList, setPriorityList] = useState([]);

	const onSortEnd = ({ oldIndex, newIndex }) => {
		setPriorityList(arrayMove(priorityList, oldIndex, newIndex));
	};

	const submitDetails = (values) => {
		setisLoading(true);
		settitleBtn("Saving...");
		toggleMainLoader(true);

		const successHandler = (event) => {
			doRefetch && doRefetch(event);
			reset(form);
			setisLoading(false);
			settitleBtn("Save");
			successMessage(event);
			setPriorityList([]);
			setLocation("");
			closeModal();
		};
		const errorHandler = (event) => {
			errorMessage(event);
			setisLoading(false);
			settitleBtn("Save");
		};
		const body = priorityList.map((value, index) => {
			return {
				dc_slug: value.dc_slug,
				dc_priority: index + 1,
			};
		});

		updatePriority({ customer_dcs: body }, `dc-priority/${modalProp}`, successHandler, errorHandler);
	};

	const remapPriorityList = (event) => {
		setLocation(event.value);
		setPriorityList(dcListDataArray[event.value]);
	};

	return (
		<Modal size="lg" show={isOpen} onHide={closeModal} className="modal-sidepanel">
			<Header closeButton>
				<Title className="modal-title">Manage DC Priority</Title>
			</Header>
			<Body>
				{modalType === 4 ? (
					<Form onSubmit={handleSubmit(submitDetails)} className="reorderForm">
						<Container>
							<Row>
								<Col className="col-12">
									<Form.Group controlId="customerName">
										<Field
											name="location-select"
											type="select-react"
											label="Location *"
											placeholder="Select Location"
											component={FormGroups}
											validate={[required]}
											options={Util.mapOptions(locationDropDownData, "location_name", "slug")}
											onChange={(event) => remapPriorityList(event)}
										/>
									</Form.Group>
								</Col>
							</Row>
							{location && (
								<>
									<Row className="mb-4">
										<Col className="col-12 d-flex justify-content-start italic-text">
											<div className="italic-text">
												Drag and drop the rows to change customer dc priority
											</div>
										</Col>
									</Row>
									<Row className="mb-4">
										<Col className="col-12 d-flex justify-content-between text-center">
											<h6>DC Name</h6>
											<h6>Priority </h6>
										</Col>
									</Row>
									<SortableList
										helperClass="sortableHelper"
										onSortEnd={onSortEnd}
										priorityList={priorityList}
									/>
								</>
							)}
							{isLoading && (
								<div className="table-loader-wrapper">
									<TableLoader />
								</div>
							)}
						</Container>
						<div className="modal-footer save-footer">
							<Button variant="secondary" onClick={closeModal}>
								Cancel
							</Button>
							<Button variant="primary" type="submit" disabled={isLoading}>
								{titleBtn}
							</Button>
						</div>
					</Form>
				) : (
					""
				)}
			</Body>
		</Modal>
	);
};

const mapDispatchToProps = {
	toggleMainLoader,
	updatePriority,
	reset,
};
const mapStateToProps = (state) => {
	return { initialValues: {} };
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(
	reduxForm({
		form: "reorderForm",
		enableReinitialize: true,
	})(ReorderDc)
);
