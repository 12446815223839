import React from "react";
import Logo from "../../assets/logo.svg";

const TableLoader = () => {
	return (
		<div className="table-loader">
			<div className="loading-logo">
				<img src={Logo} alt="LOADING" />
			</div>
			<div className="loading-anim" />
		</div>
	);
};

export default TableLoader;
