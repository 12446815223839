import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import { update } from "lodash";

import UI from "./uiReducer";
import Auth from "./authReducer";
import User from "./userReducer";
import Privileges from "./privilegesReducer";
import Boards from "./boardsReducer";
import Modals from "./modalReducer";
import Location from "./locationReducer";
import Product from "./productReducer";
import ProductView from "./productViewReducer";
import Greens from "./greensReducer";
import Seeds from "./seedsReducer";
import Sku from "./skuReducer";
import SeedingGermination from "./seedingGerminationReducer";
import CrossSupply from "./crossSupplyReducer";
import Customers from "./customerReducer";
import Transplant from "./transplantReducer";
import Harvest from "./harvestReducer";
import OrdersPackaging from "./ordersPackagingReducer";
import Reports from "./reportReducer";
import Climate from "./climateReducer";
import Count from "./countReducer";
import SeedHarvestGraph from "./seedHarvestRateGraphReducer";
import DailyGraphs from "./dailyGraphsReducer";
import packRateGraph from "./packRateGraphsReducer";
import Forecast from "./forecastReducer";
import Croptimals from "./croptimalsReducer";
import WaterSource from "./waterSourceReducer";
import Fertilizers from "./fertilizerReducer";
import ToAddList from "./toAddListReducer";
import DosingCalculator from "./dosingCalcReducer";
import DashBoard from "./pondVisualizationReducer";

import TransferRequest from "./transferRequestReducer";
import LaneCalculator from "./laneCalculatorReducer";
import WatchWire from "./watchWireReducer";

import LabResult from "./labResultsReducer";
import Kpi from "./kpiReducer";
import { COMMON } from "../types";

const allReducers = combineReducers({
	form: formReducer,
	UI,
	Auth,
	User,
	Privileges,
	Boards,
	Modals,
	Location,
	Product,
	ProductView,
	Greens,
	Seeds,
	Sku,
	SeedingGermination,
	CrossSupply,
	Customers,
	Transplant,
	Harvest,
	OrdersPackaging,
	Reports,
	Climate,
	Count,
	SeedHarvestGraph,
	DailyGraphs,
	packRateGraph,
	Forecast,
	Croptimals,
	WaterSource,
	Fertilizers,
	ToAddList,
	DosingCalculator,
	LabResult,
	Kpi,
	TransferRequest,
	LaneCalculator,
	WatchWire,
	DashBoard
});

const rootReducer = (state, action) => {
	let newState = { ...state };
	switch (action.type) {
		case COMMON.RESET_FIELD:
			if (action.key) {
				newState = update(state, action.key, () => action.value);
			}
			return {
				...newState,
			};
		default:
		// do nothing;
	}
	return allReducers(action.type === COMMON.LOGOUT_APP ? { UI: { mainPreloader: false } } : newState, action);
};

export default rootReducer;
