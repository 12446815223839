import React from "react";
import Tooltip from "./tooltip";
const ToggleButton = ({
	value = true,
	onChange,
	isDisabled = false,
	tooltip = "",
}) => {
	const component = (
		<div
			className={`custom-toggle-btn  ${value ? "active" : ""} `}
			onClick={isDisabled ? null : () => onChange(!value)}
		>
			<span className="toggle-btn" />
		</div>
	);

	return tooltip !== "" ? (
		<Tooltip message={tooltip} component={component} />
	) : (
		component
	);
};

export default ToggleButton;
