import { ORDERS_PACKAGING } from "../types";
import fetchHandler from "utils/fetchHandler";

export const fetchOrdersList = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `order-packaging-tasks`,
		method: "POST",
		secure: true,
		body: JSON.stringify(values),
		actionType: ORDERS_PACKAGING.GET_ORDERS_PACKAGING_TASKS_LIST,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const getPackageDetails = (id, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `order-packaging-task/${id}`,
		method: "GET",
		secure: true,
		actionType: ORDERS_PACKAGING.GET_PACKAGE_DETAILS,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const logEmployeeCount = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `orders_packaging/employee_count`,
		method: "POST",
		secure: true,
		body: JSON.stringify(values),
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const logPackRoomPreCool = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `orders_packaging/room_precool_details`,
		method: "POST",
		secure: true,
		body: JSON.stringify(values),
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const getCleaningTimeOrders = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `get_cleaning_time`,
		method: "POST",
		secure: true,
		body: JSON.stringify(values),
		actionType: ORDERS_PACKAGING.GET_CLEANING_TIME,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const insertCleaningTimeOrders = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `cleaning_time`,
		method: "POST",
		secure: true,
		body: JSON.stringify(values),
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const getSetUpTimeOrders = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `get_set_up_time`,
		method: "POST",
		secure: true,
		body: JSON.stringify(values),
		// actionType: ORDERS_PACKAGING.GET_SET_UP_TIME,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const insertSetUpTimeOrders = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `set_up_time`,
		method: "POST",
		secure: true,
		body: JSON.stringify(values),
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const loadLocationSkus = (successHandler, errorHandler) => {
	const fetchOptions = {
		url: `location-skus`,
		method: "GET",
		secure: true,
		actionType: ORDERS_PACKAGING.LOAD_LOCATION_SKUS,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const selectLocation = (values) => {
	return (dispatch) => {
		dispatch({
			type: ORDERS_PACKAGING.SELECT_LOCATION,
			payload: values,
		});
	};
};

export const logPackagingWaste = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `orders_packaging/add_package_waste_details`,
		method: "POST",
		secure: true,
		body: JSON.stringify(values),
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const getLocationCustomers = (successHandler, errorHandler) => {
	const fetchOptions = {
		url: `location-customers`,
		method: "GET",
		secure: true,
		actionType: ORDERS_PACKAGING.LOCATION_CUSTOMERS,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const selectOrder = (value, successHandler, errorHandler) => {
	return (dispatch) => {
		dispatch({
			type: ORDERS_PACKAGING.SELECTED_ORDER,
			payload: value,
		});
	};
};

export const selectLocationCustomer = (value) => {
	return (dispatch) => {
		dispatch({
			type: ORDERS_PACKAGING.SELECTED_LOCATION_CUSTOMERS,
			payload: value,
		});
	};
};

export const selectLocationCustomerDC = (value) => {
	return (dispatch) => {
		dispatch({
			type: ORDERS_PACKAGING.SELECTED_LOCATION_CUSTOMERS_DC,
			payload: value,
		});
	};
};

export const addOrder = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `order`,
		method: "POST",
		secure: true,
		body: JSON.stringify(values),
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const updateOrder = (task_id, values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `order-packaging-task/${task_id}`,
		method: "PUT",
		secure: true,
		body: JSON.stringify(values),
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const fetchLocationProductVarities = (successHandler, errorHandler) => {
	const fetchOptions = {
		url: `location-product-varieties`,
		method: "GET",
		secure: true,
		actionType: ORDERS_PACKAGING.FETCH_LOCATION_PRODUCT_VARITIES,
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

// Start Task
export const startOPTask = (slug, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `order-packaging-task/start-packaging/${slug}`,
		method: "GET",
		secure: true,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

// Complete Task
export const completeOPTask = (task_id, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `order-packaging-task/complete-packaging/${task_id}`,
		method: "GET",
		secure: true,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

// Add Packaging Details
export const addPackagingDetails = (slug, values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `order-packaging-task/packaging-details/${slug}`,
		method: "POST",
		secure: true,
		body: JSON.stringify(values),
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

// Update Packaging Details
export const updatePackagingDetails = (slug, values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `order-packaging-task/packaging-details/${slug}`,
		method: "PUT",
		secure: true,
		body: JSON.stringify(values),
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

// Get Audit Log
export const getAuditLogOfOPTask = (slug, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `order-packaging-task/audit-log/${slug}`,
		method: "GET",
		secure: true,
		actionType: ORDERS_PACKAGING.GET_ORDERS_PACKAGING_TASK_AUDIT_LOG,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

// Change Render Type
export const changeOrdersRenderType = (payload) => {
	return (dispatch) => {
		dispatch({
			type: ORDERS_PACKAGING.CHANGE_RENDER_TYPE,
			payload,
		});
	};
};

// View Page Loader
export const toggleOPViewLoader = (payload) => {
	return (dispatch) => {
		dispatch({
			type: ORDERS_PACKAGING.TOGGLE_OP_VIEW_LOADER,
			payload,
		});
	};
};

// Fetch SKU Recipe details
export const getSKURecipeDetails = (body, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `sku/composition`,
		method: "POST",
		secure: true,
		body: JSON.stringify(body),
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

// Change SKU Composition
export const changeSKUComposition = (body, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `orders_packaging/change_sku_composition`,
		method: "POST",
		secure: true,
		body: JSON.stringify(body),
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

// Change SKU Composition of an Order
export const changeSKURecipeOfOrder = (slug, body, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `order-packaging-task/change-sku-recipe/${slug}`,
		method: "POST",
		secure: true,
		body: JSON.stringify(body),
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const resetListing = () => {
	return (dispatch) => {
		dispatch({
			type: ORDERS_PACKAGING.RESET_LISTING,
		});
	};
};

export const addInventoryDetails = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `orders-packaging/create-inventory`,
		method: "POST",
		secure: true,
		body: JSON.stringify(values),
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const fetchInventoryDetails = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `orders-packaging/inventory-list`,
		method: "POST",
		secure: true,
		body: JSON.stringify(values),
		actionType: ORDERS_PACKAGING.FETCH_INVENTORY_DETAILS,
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const setInventoryNull = () => {
	return (dispatch) => {
		dispatch({
			type: ORDERS_PACKAGING.SET_INVENTORY_NULL,
		});
	};
};

export const getDailyTasksDetails = (values, type, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `orders_packaging/get_task_info/${type}`,
		method: "POST",
		secure: true,
		body: JSON.stringify(values),
		// actionType:
		// 	type === "employeeCount"
		// 		? ORDERS_PACKAGING.EMPLOYEE_COUNT
		// 		: type === "packroomPrecool"
		// 		? ORDERS_PACKAGING.PACKROOM_PRECOOL
		// 		: ORDERS_PACKAGING.PACKAGE_WASTE,
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const deleteOrder = (slug, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `order-packaging-task/${slug}`,
		method: "DELETE",
		secure: true,
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const fetchCasesPerSku = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `order-packaging-task/cases-per-sku`,
		method: "POST",
		secure: true,
		body: JSON.stringify(values),
		actionType: ORDERS_PACKAGING.CASES_PER_SKU,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const fetchCasesByCustomerDetails = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `orders-packaging/cases-by-customer`,
		method: "POST",
		secure: true,
		body: JSON.stringify(values),
		actionType: ORDERS_PACKAGING.CASES_BY_CUSTOMER,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const ordersLoading = () => {
	return (dispatch) => {
		dispatch({
			type: ORDERS_PACKAGING.LOADING,
		});
	};
};

export const setShouldUpdateCases = (value) => {
	return (dispatch) => {
		dispatch({
			type: ORDERS_PACKAGING.SHOULD_UPDATE_CASES,
			payload: value,
		});
	};
};

export const submitCasesByCustomer = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `orders-packaging/cases-by-customer`,
		method: "PUT",
		secure: true,
		body: JSON.stringify(values),
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const updateCasesByCustomer = (values) => {
	return (dispatch) => {
		dispatch({
			type: ORDERS_PACKAGING.UPDATE_CASES_BY_CUSTOMER,
			payload: values,
		});
	};
};

export const locationSelect = (value) => {
	return (dispatch) => {
		dispatch({
			type: ORDERS_PACKAGING.LOCATION_SELECT,
			payload: value,
		});
	};
};

export const setPackagingOrderLocation = (value) => {
	return (dispatch) => {
		dispatch({
			type: ORDERS_PACKAGING.PACKAGING_ORDER_LOCATION,
			payload: value,
		});
	};
};

export const setPackagingOrderExpDate = (value) => {
	return (dispatch) => {
		dispatch({
			type: ORDERS_PACKAGING.PACKAGING_ORDER_EXP_DATE,
			payload: value,
		});
	};
};

export const fetchPackagingOrders = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `orders-packaging/packaging-tasks-order`,
		method: "POST",
		secure: true,
		body: JSON.stringify(values),
		actionType: ORDERS_PACKAGING.PACKAGING_ORDER_DATA,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const updatePackagingOrders = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `orders-packaging/update-packaging-tasks-order`,
		method: "POST",
		secure: true,
		body: JSON.stringify(values),
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const fetchOrdersSetUpTimeList = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `list_orders_set_up_time`,
		method: "POST",
		secure: true,
		body: JSON.stringify(values),
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const deleteOrdersSetUpTime = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `delete_orders_set_up_time`,
		method: "DELETE",
		secure: true,
		body: JSON.stringify(values),
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const fetchOrdersCleaningTimeList = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `list_orders_cleaning_time`,
		method: "POST",
		secure: true,
		body: JSON.stringify(values),
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const deleteOrdersCleaningTime = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `delete_orders_cleaning_time`,
		method: "DELETE",
		secure: true,
		body: JSON.stringify(values),
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const fetchOrdersPackagingWasteSKUList = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `list-packaging-waste-by-cases`,
		method: "POST",
		secure: true,
		body: JSON.stringify(values),
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const deleteOrdersPackagingWasteSKU = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `delete-packaging-waste`,
		method: "DELETE",
		secure: true,
		body: JSON.stringify(values),
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const fetchOrdersPackagingWasteVarietyList = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `list-packaging-waste-by-varieties`,
		method: "POST",
		secure: true,
		body: JSON.stringify(values),
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const editPackagingWaste = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `update-packaging-waste`,
		method: "POST",
		secure: true,
		body: JSON.stringify(values),
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const fetchOrdersPackedCasesList = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `list-packed-cases-by-sku`,
		method: "POST",
		secure: true,
		body: JSON.stringify(values),
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const fetchOrdersUnPackedCasesList = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `list-unpacked-cases-by-variety`,
		method: "POST",
		secure: true,
		body: JSON.stringify(values),
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};
export const editInventory = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `update-inventory`,
		method: "PUT",
		secure: true,
		body: JSON.stringify(values),
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const deleteInventory = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `delete-inventory`,
		method: "DELETE",
		secure: true,
		body: JSON.stringify(values),
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const addPackRate = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `add-pack-rate-data`,
		method: "POST",
		secure: true,
		body: JSON.stringify(values),
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const fetchOrdersPackRateList = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `list-pack-rate-data`,
		method: "POST",
		secure: true,
		body: JSON.stringify(values),
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const deletePackRate = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `delete-pack-rate-data`,
		method: "DELETE",
		secure: true,
		body: JSON.stringify(values),
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const deletePackageDetails = (slug, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `order-packaging-task/packaging-details/${slug}`,
		method: "DELETE",
		secure: true,
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const currentOrderSlug = (value) => {
	return (dispatch) => {
		dispatch({
			type: ORDERS_PACKAGING.CURRENT_ORDER_PACKAGE_SLUG,
			payload: value,
		});
	};
};

export const addAction = (value) => {
	return (dispatch) => {
		dispatch({
			type: ORDERS_PACKAGING.ADD_STATUS,
			payload: value,
		});
	};
};


export const getPackRate = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `get-order-pack-rate-data`,
		method: "POST",
		secure: true,
		body: JSON.stringify(values),
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const fetchPackRoomPreCool = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `list-packroom-precool`,
		method: "POST",
		secure: true,
		body: JSON.stringify(values),
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const deletePackRoomPreCool = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `delete-packroom-precool`,
		method: "DELETE",
		secure: true,
		body: JSON.stringify(values),
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const fetchProductVarieties = (body, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `location/product_varieties`,
		method: "POST",
		actionType: ORDERS_PACKAGING.FETCH_LOCATION_PRODUCTS_OP,
		body: JSON.stringify(body),
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const addProductTemperature = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `orders-packaging/product-temperature`,
		method: "POST",
		secure: true,
		body: JSON.stringify(values),
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const fetchProductTemperatureDetails = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `orders-packaging/product-temperatures`,
		method: "POST",
		secure: true,
		body: JSON.stringify(values),
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const deleteProductTemperatureDetails = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `orders-packaging/product-temperature`,
		method: "DELETE",
		secure: true,
		body: JSON.stringify(values),
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const updateProductTemperatureDetails = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `orders-packaging/product-temperature`,
		method: "PUT",
		secure: true,
		body: JSON.stringify(values),
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const getProductTemperatureDetails = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `orders-packaging/product-temperature?location_slug=${values.location_slug}&date=${values.date}`,
		method: "GET",
		secure: true,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const getInventoryTaskDetails = (id, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `order-packaging-task/inventory/${id}`,
		method: "GET",
		secure: true,
		actionType: ORDERS_PACKAGING.GET_INVENTORY_DETAILS,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const updateInventoryPackedCases = (id,values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `order-packaging-task/inventory/${id}`,
		method: "POST",
		secure: true,
		body: JSON.stringify(values),
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};
