import React, { useState, useEffect } from "react";
import { get, without, split, includes, map } from "lodash";
import { connect } from "react-redux";
import { Row, Col, Form, Tabs, Tab } from "react-bootstrap";
import { formCreationFields, seasonsArray } from "./formHelper";
import { reduxForm, Field } from "redux-form";
import FormGroups from "../../../utils/formGroup";
import { required, number } from "../../../constant/formValidators";
import { createSeed, setSeedsViewRender } from "../../../store/actions";
import { errorMessage } from "../../../utils/errorMessage";
import { successMessage } from "../../../utils/successMessage";
import "./manage.scss";
import { ButtonPrimary, ButtonSecondary } from "../../../elements";
import TableLoader from "utils/table/tableLoader";

const SeedsForm = ({ handleSubmit, setSeedsViewRender, createSeed, renderType, formValues }) => {
	const [tabsArray, setTabsArray] = useState([]);
	const [tabsActive, setTabsActive] = useState("");
	const tabClick = 1;
	const [tabPaneClick, setTabPaneClick] = useState(1);
	const [btnTitle, setbtnTitle] = useState("Save");
	const [isLoading, setisLoading] = useState(false);

	const getValidations = (field) => {
		switch (field) {
			case "seed_name":
				return required;
			case "harvesting_period":
				return [number];

			case "do":
				return [number];
			case "ph":
				return [number];
			case "ec":
				return [number];

			default:
				return [required, number];
		}
	};

	useEffect(() => {
		if (tabsArray && Object.keys(tabsArray).length > 0) {
			if (includes(map(tabsArray, "value"), tabsActive)) {
				setTabsActive(tabsActive);
			} else {
				setTabsActive(tabsArray[0].value);
			}
		} else {
			setTabsActive("");
		}
	}, [formValues, tabsActive, tabsArray]);

	useEffect(() => {
		if (
			formValues &&
			Object.keys(formValues).length &&
			formValues.seedManagementForm &&
			Object.keys(formValues.seedManagementForm).length > 0 &&
			formValues.seedManagementForm.syncErrors &&
			Object.keys(formValues.seedManagementForm.syncErrors).length > 0 &&
			formValues.seedManagementForm.values &&
			Object.keys(formValues.seedManagementForm.values).length > 0 &&
			formValues.seedManagementForm.submitFailed
		) {
			const syncErrors = Object.keys(formValues.seedManagementForm.syncErrors);
			const errorTabs = without(syncErrors, "seed_name", "season");

			if (Object.keys(errorTabs).length > 0 && tabPaneClick && Object.keys(errorTabs).length > 0) {
				if (tabsArray && Object.keys(tabsArray).length === 1) {
					setTabsActive(tabsArray[0].value);
				} else {
					setTabsActive(split(errorTabs[0], "_", 2)[1]);
				}
			}
			setTabPaneClick(0);
		}

		renderType === 2 &&
			formValues &&
			formValues.seedManagementForm &&
			formValues.seedManagementForm.values.season &&
			Object.keys(formValues.seedManagementForm.values.season).length > 0 &&
			tabClick &&
			setTabsArray(formValues.seedManagementForm.values.season);
	}, [renderType, formValues, tabClick, tabPaneClick, tabsArray]);

	const submitSeedDetails = (values) => {
		if (!isLoading) {
			setisLoading(true);
			setbtnTitle("Saving...");
			const seasons =
				values && values.season
					? values.season.map(function (value, index) {
							return value.value;
					  })
					: [];
			const seasonDetails =
				seasons && seasons
					? seasons.map(function (value, index) {
							return { ...values["season-id_" + value], season_id: value };
					  })
					: [];
			const method = renderType === 1 ? null : values.slug;
			const submissionData = {
				season: seasons,
				seed_name: values.seed_name,
				season_details: seasonDetails,
			};
			const successHandler = (event) => {
				setisLoading(false);
				setbtnTitle("Save");
				successMessage(event);
				setSeedsViewRender(0);
			};
			const errorHandler = (event) => {
				setisLoading(false);
				setbtnTitle("Save");
				errorMessage(event);
			};
			createSeed(submissionData, method, successHandler, errorHandler);
		}
	};

	const getFormFieldHtml = (fields, tabValue) => {
		let formFieldHtml = [];
		formFieldHtml = fields.fields.map((field, idx) => {
			return (
				<React.Fragment key={idx}>
					<Col className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
						<Form.Group controlId={`formControl ${idx}${tabValue}`}>
							<Field
								name={`season-id_${tabValue}.${field.name}`}
								type={field.type}
								label={field.label}
								placeholder={field.placeholder}
								component={FormGroups}
								validate={getValidations(field.name)}
							/>
						</Form.Group>
					</Col>
				</React.Fragment>
			);
		});
		return <>{formFieldHtml}</>;
	};

	const seedsFormFields = (tabValue) => {
		let columns = [];
		columns = formCreationFields.map((fields, idx) => {
			return (
				<React.Fragment key={idx}>
					<Row>
						<div className="form-headers">{fields.heading}</div>
					</Row>
					<Row>{getFormFieldHtml(fields, tabValue)}</Row>
				</React.Fragment>
			);
		});

		return <>{columns}</>;
	};
	const setTabsClick = (k) => {
		setTabsActive(k);
		setTabPaneClick(0);
	};

	return (
		<Form onSubmit={handleSubmit(submitSeedDetails)} className="seed-management-form">
			<div className="position-relative">
				<Row>
					<Col className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
						<Form.Group controlId="seedName">
							<Field
								name="seed_name"
								type="text"
								label="Seed Name *"
								validate={[required]}
								placeholder="Enter Seed Name"
								component={FormGroups}
							/>
						</Form.Group>
					</Col>
					<Col className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
						<Form.Group controlId="seasons">
							<Field
								label="Select Seasons *"
								name="season"
								type="select-react"
								multiple={true}
								validate={[required]}
								component={FormGroups}
								onChange={(event) => setTabsArray(event)}
								options={seasonsArray}></Field>
						</Form.Group>
					</Col>
				</Row>

				<div className="tab-headers">
					{tabsActive && tabsArray ? (
						<Tabs
							onSelect={(k) => setTabsClick(k)}
							activeKey={tabsActive}
							defaultActiveKey={tabsActive}
							id="uncontrolled-tab-example">
							{tabsArray.map((tab, index) => (
								<Tab eventKey={tab.value} key={index} title={tab.label}>
									{seedsFormFields(tab.value)}
								</Tab>
							))}
						</Tabs>
					) : (
						""
					)}
				</div>
				{isLoading && (
					<div className="table-loader-wrapper">
						<TableLoader />
					</div>
				)}
			</div>
			<div className="modal-footer d-flex justify-content-end save-footer">
				<>
					<ButtonSecondary onClick={() => setSeedsViewRender(0)}>Cancel</ButtonSecondary>
					<ButtonPrimary onClick={() => setTabPaneClick(1)} type="submit" disabled={isLoading}>
						{btnTitle}
					</ButtonPrimary>
				</>
			</div>
		</Form>
	);
};
const mapStateToProps = (state) => {
	const { UI, Auth, Seeds } = state;

	const initialArray = () => {
		const seedsDataArray = state.Seeds && state.Seeds.seedDetails ? state.Seeds && state.Seeds.seedDetails : [];
		const selectedSeasons =
			seedsDataArray && seedsDataArray.season_seed_details
				? seedsDataArray.season_seed_details.map(function (value, index) {
						return value["season_id"].toString();
				  })
				: [];

		const filteredSeasonNames = seasonsArray.filter((seasons) => selectedSeasons.includes(seasons.value));
		const seedName = seedsDataArray && seedsDataArray.seed_name ? seedsDataArray.seed_name : "";
		const slug = seedsDataArray && seedsDataArray.slug ? seedsDataArray.slug : "";
		const formatedInitialValueArray = {
			...seedsDataArray.season_details,
			slug,
			seed_name: seedName,
			season: filteredSeasonNames,
		};
		return formatedInitialValueArray;
	};

	return {
		accessToken: get(Auth, "accessToken", null),
		mainPreloader: get(UI, "mainPreloader", null),
		renderType: get(Seeds, "renderDisplay", null),
		initialValues: state.Seeds.renderDisplay === 1 ? {} : initialArray(),
		formValues: state.form,
	};
};

const mapDispatchToProps = { createSeed, setSeedsViewRender };

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(
	reduxForm({
		form: "seedManagementForm",
		enableReinitialize: true,
	})(SeedsForm)
);
