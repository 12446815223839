import React from "react";
import { connect } from "react-redux";
import Listing from "../../../partials/listings";
import useModal from "../../../utils/useModal";
import { successMessage } from "../../../utils/successMessage";
import { errorMessage } from "../../../utils/errorMessage";
import ConfirmModal from "../../../utils/confirmModal";
import { renderColumnsMU, renderColumnsST, renderColumnsCT } from "./helper";
import {
	getSGMediaUsageList,
	deleteSGMediaUsage,
	getSGSetUpTimeList,
	deleteSGSetUpTime,
	getSGCleaningTimeList,
	deleteSGCleaningTime,
	toggleListLoader,
} from "../../../store/actions";
import TableLoader from "../../../utils/table/tableLoader";


import i18next from "i18next";

// Task types
//     media_usage
//    set_up_time
//     cleaning_time

const SGDailyTaskListing = ({
	filter,
	refetch,
	task,
	setRefetch,
	handleEditPanel,
	listingPageLoader,
	getSGMediaUsageList,
	deleteSGMediaUsage,
	getSGSetUpTimeList,
	deleteSGSetUpTime,
	getSGCleaningTimeList,
	deleteSGCleaningTime,
	permissionDetails,
}) => {
	const [isDeleteModalOpenMU, , showDeleteModalMU, closeDeleteModalMU, deleteModalPropsMU] = useModal("deleteMediaUsage");
	const [isDeleteModalOpenST, , showDeleteModalST, closeDeleteModalST, deleteModalPropsST] = useModal("deleteSetUpTime");
	const [isDeleteModalOpenCT, , showDeleteModalCT, closeDeleteModalCT, deleteModalPropsCT] = useModal("deleteCleaingTime");

	const handleDeletePanelMU = (data) => showDeleteModalMU(0, data);
	const handleDeletePanelST = (data) => showDeleteModalST(0, data);
	const handleDeletePanelCT = (data) => showDeleteModalCT(0, data);
	// on delete media usage
	const onDeleteTaskMU = () => {
		toggleListLoader(true);
		const successHandler = (e) => {
			closeDeleteModalMU();
			successMessage(e);
			toggleListLoader(false);
			setRefetch(refetch + 1);
		};
		const errorHandler = (e) => {
			closeDeleteModalMU();
			errorMessage(e);
			toggleListLoader(false);
		};

		deleteSGMediaUsage(deleteModalPropsMU, successHandler, errorHandler);
	};
	//on delete set up time
	const onDeleteTaskST = () => {
		toggleListLoader(true);
		const successHandler = (e) => {
			closeDeleteModalST();
			successMessage(e);
			toggleListLoader(false);
			setRefetch(refetch + 1);
		};
		const errorHandler = (e) => {
			closeDeleteModalST();
			errorMessage(e);
			toggleListLoader(false);
		};
		deleteSGSetUpTime(deleteModalPropsST, successHandler, errorHandler);
	};
	//on delete cleaning time
	const onDeleteTaskCT = () => {
		toggleListLoader(true);
		const successHandler = (e) => {
			closeDeleteModalCT();
			successMessage(e);
			toggleListLoader(false);
			setRefetch(refetch + 1);
		};
		const errorHandler = (e) => {
			closeDeleteModalCT();
			errorMessage(e);
			toggleListLoader(false);
		};

		deleteSGCleaningTime(deleteModalPropsCT, successHandler, errorHandler);
	};

	const listPermission =
		permissionDetails && permissionDetails.seeding && permissionDetails.seeding.permissions.list_seeding_task
			? permissionDetails.seeding.permissions.list_seeding_task
			: "";

	const columnsMU = renderColumnsMU({
		handleEditPanel,
		handleDeletePanelMU,
		permissionDetails,
	});
	const columnsST = renderColumnsST({
		handleEditPanel,
		handleDeletePanelST,
		permissionDetails,
	});
	const columnsCT = renderColumnsCT({
		handleEditPanel,
		handleDeletePanelCT,
		permissionDetails,
	});

	return (
		<div className="position-relative">
			{listPermission && task === "media_usage" ? (
				<Listing
					fetchAction={getSGMediaUsageList}
					columns={columnsMU}
					refetch={refetch}
					filter={filter}
					sort_name="date"
					sort_dir="desc"
				/>
			) : listPermission && task === "set_up_time" ? (
				<Listing
					fetchAction={getSGSetUpTimeList}
					columns={columnsST}
					refetch={refetch}
					filter={filter}
					sort_name="date"
					sort_dir="desc"
				/>
			) : listPermission && task === "cleaning_time" ? (
				<Listing
					fetchAction={getSGCleaningTimeList}
					columns={columnsCT}
					refetch={refetch}
					filter={filter}
					sort_name="date"
					sort_dir="desc"
				/>
			) : (
				""
			)}
			<ConfirmModal
				show={isDeleteModalOpenMU}
				onNo={closeDeleteModalMU}
				onYes={onDeleteTaskMU}
				message={i18next.t('seeding.confirm.media_usage')}
			/>
			<ConfirmModal
				show={isDeleteModalOpenST}
				onNo={closeDeleteModalST}
				onYes={onDeleteTaskST}
				message={i18next.t('seeding.confirm.set_up_time')}
			/>
			<ConfirmModal
				show={isDeleteModalOpenCT}
				onNo={closeDeleteModalCT}
				onYes={onDeleteTaskCT}
				message={i18next.t('seeding.confirm.cleaning_time')}
			/>

			{/* Loader */}
			{listingPageLoader && (
				<div className="table-loader-wrapper">
					<TableLoader />
				</div>
			)}
		</div>
	);
};

const mapStateToProps = (state) => ({
	permissionDetails: state.Auth.permissionDetails,
});
const mapDispatchToProps = {
	getSGMediaUsageList,
	deleteSGMediaUsage,
	getSGSetUpTimeList,
	deleteSGSetUpTime,
	getSGCleaningTimeList,
	deleteSGCleaningTime,
};

export default connect(mapStateToProps, mapDispatchToProps)(SGDailyTaskListing);
