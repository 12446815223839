import React from "react";
import { map, join, includes, find } from "lodash";
import { ButtonLink, ListingBtn } from "../../../elements";
import { transplantTaskStatus } from "../../../constant/global";
import Util from "../../../utils/Util";
import Checkbox from "utils/checkbox";
import { errorMessage } from "../../../utils/errorMessage";
import Select from "react-select";
import i18next from "i18next";

export const renderColumns = ({
	handleViewPanel,
	handleEditPanel,
	handleRepeatPanel,
	confirmDelete,
	addToFilter,
	isLocationSelected,
	onChangeCheckbox,
	checkedAssignees,
	permissionDetails,
}) => {
	const updatePermission =
		permissionDetails &&
		permissionDetails.transplant &&
		permissionDetails.transplant.permissions.update_transpant_and_growing_task
			? permissionDetails.transplant.permissions.update_transpant_and_growing_task
			: "";
	const viewPermission =
		permissionDetails &&
		permissionDetails.transplant &&
		permissionDetails.transplant.permissions.view_transpant_and_growing_task
			? permissionDetails.transplant.permissions.view_transpant_and_growing_task
			: "";
	const deletePermission =
		permissionDetails &&
		permissionDetails.transplant &&
		permissionDetails.transplant.permissions.delete_transpant_and_growing_task
			? permissionDetails.transplant.permissions.delete_transpant_and_growing_task
			: "";
	return [
		{
			name: "",
			className: "transplant-listing-col-checkbox",
			headerClassName: "transplant-listing-col-checkbox-header",
			render: (row) => (
				<Checkbox
					checked={includes(checkedAssignees, row.task_id)}
					onChange={(e) => onChangeCheckbox(e, row.task_id)}
				/>
			),
			sortable: false,
		},
		{
			name: i18next.t("label.task_id"),
			accessor: "task_id",
			className: "transplant-listing-col-taskId",
			headerClassName: "transplant-listing-col-taskId-header seedingCentreHeading",
			filter: {
				type: "search",
				onChange: addToFilter,
			},
			render: (row) => {
				return viewPermission ? (
					<ButtonLink className="px-0" onClick={() => handleViewPanel(row.task_id)}>
						{row.task_id}
					</ButtonLink>
				) : (
					row.task_id
				);
			},
		},
		{
			name: i18next.t("label.product_variety"),
			accessor: "product_variety",
			className: "transplant-listing-col-pv",
			headerClassName: "transplant-listing-col-pv-header seedingCentreHeading",
			render: (row) => {
				return row.product_variety_name;
			},
			filter: {
				type: "search",
				onChange: addToFilter,
			},
			sortable: true,
		},
		{
			name: i18next.t("label.exp_transplant_date"),
			accessor: "expected_transplant_date",
			className: "transplant-listing-col-date",
			headerClassName: "transplant-listing-col-date-header",
			filter: { type: "calendar", onChange: addToFilter },
			render: (row) => Util.getDateFormat(row.expected_transplant_date),
			sortable: false,
		},
		{
			name: i18next.t("label.pond"),
			accessor: "transplant_and_growing_task_details",
			className: "transplant-listing-col-pond",
			headerClassName: "transplant-listing-col-pond-header",
			render: (row) => join(map(row.ponds), ", "),
			sortable: false,
		},
		{
			name: i18next.t("label.lane"),
			accessor: "transplant_and_growing_task_details",
			className: "transplant-listing-col-lane",
			headerClassName: "transplant-listing-col-lane-header",
			render: (row) => join(map(row.lanes), ", "),
			sortable: false,
		},
		{
			name: i18next.t("label.assignee"),
			accessor: "assignee",
			className: "transplant-listing-col-assignee",
			headerClassName: "transplant-listing-col-assignee-header",
			filter: { type: "search", onChange: addToFilter },
			render: (row) => (row.assignee && row.assignee) || "-",
			sortable: false,
		},
		{
			name: i18next.t("label.status_col"),
			accessor: "status",
			className: "transplant-listing-col-status",
			headerClassName: "transplant-listing-col-status-header",
			render: (row) => row.status,
			sortable: false,
			options: transplantTaskStatus,
			filter: {
				type: "select",
				options: transplantTaskStatus,
				onChange: addToFilter,
			},
		},
		{
			name: i18next.t("label.actions"),
			className: "transplant-listing-col-actions",
			headerClassName: "transplant-listing-col-actions-header",
			hide: updatePermission || deletePermission ? false : true,
			render: (row) => {
				return (
					<>
						{updatePermission ? (
							<>
								<ListingBtn
									parentClass={
										updatePermission && deletePermission ? "justify-content-end" : "justify-content-around"
									}
									tooltipText={i18next.t("tooltip.repeat")}
									btnClass="btn btn-cell btn-light btn-sm ml-2 spinner-button"
									iconClass="icon icon-repeat"
									onClick={() => handleRepeatPanel(`${row.task_id}`)}
								/>
								<ListingBtn
									parentClass={
										updatePermission && deletePermission ? "justify-content-end" : "justify-content-around"
									}
									tooltipText={i18next.t("tooltip.edit")}
									btnClass="btn btn-cell btn-light btn-sm ml-2 "
									iconClass="icon icon-edit"
									//onClick={() => handleEditPanel(`${row.task_id}`)}
									onClick={() =>
										row.status === "Completed"
											? errorMessage("TRANSPLANT_DELETE_DISABLE")
											: handleEditPanel(`${row.task_id}`)
									}
								/>
							</>
						) : (
							""
						)}
						{deletePermission ? (
							<ListingBtn
								parentClass={
									updatePermission && deletePermission ? "justify-content-end" : "justify-content-around"
								}
								tooltipText={i18next.t("tooltip.delete")}
								btnClass="btn btn-cell btn-light btn-sm ml-2"
								iconClass="icon icon-trash"
								onClick={() => confirmDelete(`${row.task_id}`)}
							/>
						) : (
							""
						)}
					</>
				);
			},
			sortable: false,
		},
	];
};
export const PondParametersDropdown = ({ onSelect, isClearable = false }) => {
	const options = [
		{
			value: "view-pond-parameters",
			label: i18next.t("label.view_pond_parameter"),
		},
		{
			value: "add-pond-parameters",
			label: i18next.t("label.add_pond_parameter"),
		},
	];
	return (
		<Select
			controlShouldRenderValue={false}
			onChange={onSelect}
			options={options}
			isClearable={isClearable}
			isSearchable={false}
			classNamePrefix="header-react-select"
			className="pond-parameter-select"
			placeholder={i18next.t("label.manage_pond_parameters")}
		/>
	);
};

export const renderPondParameterColumns = ({
	handleDeletePanel,
	addToFilter,
	handlePondEditPanel,
	permissionDetails,
	locationMappings,
}) => {
	const updatePermission =
		permissionDetails &&
		permissionDetails.transplant &&
		permissionDetails.transplant.permissions.transpant_log_pond_parameter
			? permissionDetails.transplant.permissions.transpant_log_pond_parameter
			: "";
	const deletePermission =
		permissionDetails &&
		permissionDetails.transplant &&
		permissionDetails.transplant.permissions.transpant_log_pond_parameter
			? permissionDetails.transplant.permissions.transpant_log_pond_parameter
			: "";
	return [
		{
			name: i18next.t("label.location"),
			accessor: "location_slug",
			className: "ponds-listing-col-name table-col2",
			headerClassName: "ponds-listing-col-name-header table-col2 overflow-unset",
			sortable: false,
			render: (row) => {
				if (row.location_slug) {
					const pondLocation = find(locationMappings, { value: row.location_slug });
					if (pondLocation) {
						return pondLocation.label;
					} else {
						return row.location_slug;
					}
				} else {
					return "-";
				}
			},
		},
		{
			name: i18next.t("label.date"),
			accessor: "date",
			className: "ponds-listing-col-name table-col2 datewidth",
			headerClassName: "ponds-listing-col-name-header table-col2 overflow-unset",
			sortable: true,
			render: (row) => (row.date ? Util.getFormattedDateAndTime(row.date) : "-"),
		},
		{
			name: i18next.t("label.pond"),
			accessor: "pond",
			className: "ponds-listing-col-name table-col2",
			headerClassName: "ponds-listing-col-name-header table-col2",
			sortable: false,
			// render: (row) => (row.pond_name ? row.pond_name : "-"),
			render: (row) => {
				return row.pond_name ? row.pond_name : "-"
			},
			filter: {
				type: "search",
				onChange: addToFilter,
			},
		},
		{
			name: i18next.t("label.pond_temperature"),
			accessor: "pond_temperature",
			className: "ponds-listing-col-density table-col3",
			headerClassName: "ponds-listing-col-density-header table-col3",
			render: (row) => (row.pond_temperature ? row.pond_temperature : "-"),
			sortable: false,
		},
		{
			name: i18next.t("label.ph"),
			accessor: "ph",
			className: "ponds-listing-col-price table-col2",
			headerClassName: "ponds-listing-col-price-header table-col2",

			render: (row) => (row.ph ? row.ph : "-"),
			sortable: false,
		},
		{
			name: i18next.t("label.ec"),
			accessor: "ec",
			className: "ponds-listing-col-density table-col2",
			headerClassName: "ponds-listing-col-density-header table-col2",
			render: (row) => (row.ec ? row.ec : "-"),
			sortable: false,
		},
		{
			name: i18next.t("label.do"),
			accessor: "do",
			className: "ponds-listing-col-density table-col2",
			headerClassName: "ponds-listing-col-density-header table-col2",
			render: (row) => (row.do ? row.do : "-"),
			sortable: false,
		},
		{
			name: i18next.t("label.orp"),
			accessor: "orp",
			className: "ponds-listing-col-density table-col2",
			headerClassName: "ponds-listing-col-density-header table-col2",
			render: (row) => (row.orp ? row.orp : "-"),
			sortable: false,
		},
		{
			name: i18next.t("label.created_by"),
			accessor: "created_by_user",
			className: "ponds-listing-col-density table-col2",
			headerClassName: "ponds-listing-col-density-header table-col2",
			render: (row) => (row.created_by_user ? row.created_by_user : "-"),
			sortable: false,
		},
		{
			name: i18next.t("label.last_updated_by"),
			accessor: "updated_by_user",
			className: "ponds-listing-col-density table-col2",
			headerClassName: "ponds-listing-col-density-header table-col2",
			render: (row) => (row.updated_by_user ? row.updated_by_user : "-"),
			sortable: false,
		},
		{
			name: i18next.t("label.actions"),
			className: "ponds-listing-col-actions table-col1 transplantcol",
			headerClassName: "ponds-listing-col-actions-header table-col1",
			hide: updatePermission || deletePermission ? false : true,
			render: (row) => {
				return (
					<>
						{updatePermission ? (
							<ListingBtn
								parentClass={
									updatePermission && deletePermission ? "justify-content-end" : "justify-content-around"
								}
								tooltipText={i18next.t("tooltip.edit")}
								btnClass="btn btn-cell btn-light btn-sm ml-2"
								iconClass="icon icon-edit"
								onClick={() => handlePondEditPanel(row.transplant_pond_parameter_values_id)}
							/>
						) : (
							""
						)}
						{deletePermission ? (
							<ListingBtn
								parentClass={
									updatePermission && deletePermission ? "justify-content-end" : "justify-content-around"
								}
								tooltipText={i18next.t("tooltip.delete")}
								btnClass="btn btn-cell btn-light btn-sm ml-2"
								iconClass="icon icon-trash"
								onClick={() =>
									handleDeletePanel({
										transplant_pond_parameter_values_id: row.transplant_pond_parameter_values_id,
									})
								}
							/>
						) : (
							""
						)}
					</>
				);
			},
			sortable: false,
		},
	];
};
