import React from "react";
import { useSelector } from "react-redux";
import { ContentWrap, button, Breadcrumbs, BackButton } from "../../../elements";
import { connect } from "react-redux";
import SeedsForm from "./seedsForm";
import SeedsView from "./seedsView";
import { setSeedsViewRender } from "../../../store/actions";
import { get } from "lodash";

const SeedsManage = ({ handleSubmit, setSeedsViewRender, createSeed, handleEdit, permissionDetails }) => {
	const details = useSelector((state) => state.Seeds);
	const renderType = details && details.renderDisplay && details.renderDisplay;
	const slugValue = details && details.seedDetails && details.seedDetails.slug;
	const prevRenderType = details && details.prevRenderDisplay && details.prevRenderDisplay;
	const updatePermission =
		permissionDetails && permissionDetails.seeds && permissionDetails.seeds.permissions.update_seed
			? permissionDetails.seeds.permissions.update_seed
			: "";

	return (
		<ContentWrap>
			<div className="d-flex justify-content-between">
				<div className="d-flex justify-content-between mb-3">
					<BackButton onClick={() => setSeedsViewRender(prevRenderType)} />
					<Breadcrumbs
						keyValue="seeds"
						showLabel={true}
						label={renderType === 3 ? "View" : renderType === 2 ? "Edit" : "Add"}
					/>
				</div>
				{renderType === 3 && updatePermission ? (
					<div>
						<button onClick={() => handleEdit(slugValue)} className="btn btn-cell btn-light btn-sm ml-2">
							<i className="icon icon-edit" />
						</button>
					</div>
				) : (
					""
				)}
			</div>
			<div>{renderType === 3 ? <SeedsView /> : <SeedsForm />}</div>
		</ContentWrap>
	);
};
const mapStateToProps = (state) => {
	const { Auth } = state;

	return {
		permissionDetails: get(Auth, "permissionDetails", null),
	};
};
const mapDispatchToProps = { setSeedsViewRender };
export default connect(mapStateToProps, mapDispatchToProps)(SeedsManage);
