import React, { useState } from "react";
import Listing from "partials/listings";
import { createStructuredSelector } from "reselect";
import { getRenderType } from "store/selectors/ordersPackagingSelectors";
import { connect } from "react-redux";
import {
	deleteOrdersCleaningTime,
	deleteOrdersSetUpTime,
	fetchOrdersCleaningTimeList,
	fetchOrdersSetUpTimeList,
	fetchOrdersPackagingWasteSKUList,
	deleteOrdersPackagingWasteSKU,
	fetchOrdersPackagingWasteVarietyList,
	fetchOrdersPackedCasesList,
	fetchOrdersUnPackedCasesList,
	deleteInventory,
	fetchOrdersPackRateList,
	deletePackRate,
	fetchPackRoomPreCool,
	deletePackRoomPreCool,
	fetchProductTemperatureDetails,
	deleteProductTemperatureDetails,
} from "store/actions";
import {
	renderCleaningTimeColumns,
	renderPackagingWasteSKUColumns,
	renderPackagingWasteVarietyColumns,
	renderSetupTimeColumns,
	renderPackedCasesColumns,
	renderUnpackedLbsColumns,
	renderPackRateColumns,
	renderPackRoomPreCool,
	renderProductTemperatureDetails,
} from "./helper";
import { successMessage } from "utils/successMessage";
import { errorMessage } from "utils/errorMessage";
import ConfirmModal from "utils/confirmModal";
import { useTranslation } from "react-i18next";

const TaskView = ({
	renderType,
	showTaskModal,
	addToFilter,
	dailyFilter,
	deleteOrdersCleaningTime,
	deleteOrdersSetUpTime,
	fetchOrdersSetUpTimeList,
	fetchOrdersCleaningTimeList,
	fetchOrdersPackagingWasteSKUList,
	deleteOrdersPackagingWasteSKU,
	fetchOrdersPackagingWasteVarietyList,
	fetchOrdersPackedCasesList,
	fetchOrdersUnPackedCasesList,
	deleteInventory,
	fetchOrdersPackRateList,
	deletePackRate,
	fetchPackRoomPreCool,
	deletePackRoomPreCool,
	fetchProductTemperatureDetails,
	deleteProductTemperatureDetails,
}) => {
	const { t } = useTranslation();
	const [refetch, setRefetch] = useState(3);
	const [deleteItem, setdeleteItem] = useState(false);

	const getColumns = () => {
		switch (renderType) {
			case 4:
				return renderSetupTimeColumns({ addToFilter, handleDeletePanel, handleEditSetUpTimePanel });
			case 5:
				return renderCleaningTimeColumns({ addToFilter, handleDeletePanel, handleEditCleaningTimePanel });
			case 6:
				return renderPackagingWasteSKUColumns({ addToFilter, handleDeletePanel, handleEditPackagingWasteSKUPanel });
			case 7:
				return renderPackagingWasteVarietyColumns({ addToFilter, handleDeletePanel, handleEditPackagingWasteSKUPanel });
			case 8:
				return renderPackedCasesColumns({ addToFilter, handleDeletePanel, handleEditInventory });
			case 9:
				return renderUnpackedLbsColumns({ addToFilter, handleDeletePanel, handleEditInventory });
			case 10:
				return renderPackRateColumns({ handleDeletePanel, handleEditPackRatePanel });
			case 11:
				return renderPackRoomPreCool({ handleDeletePanel, handleEditPackRoomPreCool });
			case 12:
				return renderProductTemperatureDetails({ handleDeletePanel, handleEditProductTemperatureDetails });
			default:
				return;
		}
	};

	const handleEditInventory = (data) => {
		showTaskModal(6, { editData: data, setRefetch: setRefetch });
	};

	const handleEditPackagingWasteSKUPanel = (data) => {
		showTaskModal(5, { editData: data, setRefetch: setRefetch });
	};

	const handleEditSetUpTimePanel = (data) => {
		showTaskModal(3, { editData: data, setRefetch: setRefetch });
	};

	const handleEditPackRatePanel = (data) => {
		showTaskModal(9, { editData: data, setRefetch: setRefetch });
	};
	const handleEditPackRoomPreCool = (data) => {
		showTaskModal(2, { editData: data, setRefetch: setRefetch });
	};

	const handleEditProductTemperatureDetails = (data) => {
		showTaskModal(10, { editData: data, setRefetch: setRefetch });
	};
	
	const onDeleteDailyTask = () => {
		const successHandler = (e) => {
			setdeleteItem(null);
			successMessage(e);
			setRefetch(refetch + 1);
		};
		const errorHandler = (e) => {
			setdeleteItem(null);
			errorMessage(e);
		};

		switch (renderType) {
			case 4:
				deleteOrdersSetUpTime(deleteItem, successHandler, errorHandler);
				break;
			case 5:
				deleteOrdersCleaningTime(deleteItem, successHandler, errorHandler);
				break;
			case 6:
			case 7:
				deleteOrdersPackagingWasteSKU(deleteItem, successHandler, errorHandler);
				break;
			case 8:
			case 9:
				deleteInventory(deleteItem, successHandler, errorHandler);
				break;
			case 10:
				deletePackRate(deleteItem, successHandler, errorHandler);
				break;
			case 11:
				deletePackRoomPreCool(deleteItem, successHandler, errorHandler);
				break;
			case 12:
				deleteProductTemperatureDetails(deleteItem, successHandler, errorHandler);
				break;
			default:
				return;
		}
	};

	const handleEditCleaningTimePanel = (data) => {
		showTaskModal(4, { editData: data, setRefetch: setRefetch });
	};
	const handleDeletePanel = (data) => {
		setdeleteItem(data);
	};
	return (
		<div>
			<Listing
				sort_name={"date"}
				fetchAction={
					renderType === 4
						? fetchOrdersSetUpTimeList
						: renderType === 5
						? fetchOrdersCleaningTimeList
						: renderType === 6
						? fetchOrdersPackagingWasteSKUList
						: renderType === 7
						? fetchOrdersPackagingWasteVarietyList
						: renderType === 8
						? fetchOrdersPackedCasesList
						: renderType === 9
						? fetchOrdersUnPackedCasesList
						: renderType === 10
						? fetchOrdersPackRateList
						: renderType === 11
						? fetchPackRoomPreCool
						: renderType === 12
						? fetchProductTemperatureDetails
						: null
				}
				columns={getColumns()}
				refetch={refetch}
				filter={dailyFilter}
			/>

			<ConfirmModal
				show={deleteItem ? true : false}
				onNo={() => setdeleteItem(null)}
				onYes={onDeleteDailyTask}
				message={t("confirm.delete_task")}
			/>
		</div>
	);
};

const mapStateToProps = createStructuredSelector({
	renderType: getRenderType,
});

const areEqual = (prevProps, nextProps) => {
	return (
		prevProps.renderType === nextProps.renderType &&
		prevProps.refetch === nextProps.refetch &&
		prevProps.dailyFilter === nextProps.dailyFilter
	);
};

export default connect(mapStateToProps, {
	fetchOrdersSetUpTimeList,
	deleteOrdersCleaningTime,
	deleteOrdersSetUpTime,
	fetchOrdersCleaningTimeList,
	fetchOrdersPackagingWasteSKUList,
	deleteOrdersPackagingWasteSKU,
	fetchOrdersPackagingWasteVarietyList,
	fetchOrdersPackedCasesList,
	fetchOrdersUnPackedCasesList,
	deleteInventory,
	fetchOrdersPackRateList,
	deletePackRate,
	fetchPackRoomPreCool,
	deletePackRoomPreCool,
	fetchProductTemperatureDetails,
	deleteProductTemperatureDetails,
})(React.memo(TaskView, areEqual));
