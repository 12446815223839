import React from "react";
import Scrollbars from "react-custom-scrollbars";
import { Modal } from "react-bootstrap";
import "./index.scss";

const { Header, Title, Body } = Modal;

const CustomModal = ({ show, onHide, className, title, children }) => {
	return (
		<Modal
			size="lg"
			show={show}
			onHide={onHide}
			className={`${className} modal-sidepanel modal-right`}
			style={{ overflow: "hidden" }}>
			<Scrollbars autoHide autoHeight autoHeightMin="100vh">
				<div
					style={{
						minHeight: "100vh",
						background: "#fff",
						borderTopLeftRadius: "40px",
						borderBottomLeftRadius: "40px",
					}}>
					<Header closeButton>
						<Title className="modal-title">{title}</Title>
					</Header>
					<Body>{children}</Body>
				</div>
			</Scrollbars>
		</Modal>
	);
};

export default CustomModal;
