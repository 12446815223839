import { required, names, number, USZipCode, max100p, min0p } from "../../../constant/formValidators";
import { join, map, isEmpty, includes, forEach } from "lodash";

export const formCreationFields = [
	{
		label: "Location *",
		name: "location",
		type: "select-react",
		placeholder: "Select Location",
	},
	{
		label: "Address Internal Id",
		name: "address_internal_id",
		type: "selectWithTagsNumbers",
		placeholder: "Enter Address Internal Id",
	},
	{
		label: "DC Name *",
		name: "name",
		type: "text",
		placeholder: "Enter DC Name",
	},
	{
		label: "DC Address *",
		name: "address",
		type: "text",
		placeholder: "Enter DC Address",
	},
	{
		label: "DC City *",
		name: "city",
		type: "text",
		placeholder: "Enter DC City",
	},
	{
		label: "DC State *",
		name: "state",
		type: "text",
		placeholder: "Enter DC State",
	},
	{
		label: "DC Zipcode *",
		name: "zip_code",
		type: "text",
		placeholder: "Enter DC Zipcode",
	},
	{
		label: "Delivery Days *",
		name: "delivery_days",
		type: "select-react",
		placeholder: "Select Delivery Days",
	},
	{
		label: "SKU *",
		name: "sku",
		type: "select-react",
		placeholder: "Select SKU",
	},
];

export const viewCreationFields = [
	{
		label: "Location",
		name: "location",
		render: (item) => (item && item.location && item.location.location_name) || "-",
	},
	{
		label: "Address Internal Id",
		name: "address_internal_id",
		render: (item) => (item && item.address_internal_id && join(item.address_internal_id, ", ")) || "-",
	},
	{
		label: "DC Name",
		name: "name",
	},
	{
		label: "DC Address",
		name: "address",
	},
	{
		label: "DC City",
		name: "city",
	},
	{
		label: "DC State",
		name: "state",
	},
	{
		label: "DC Zipcode",
		name: "zip_code",
	},
	{
		label: "Delivery Days",
		name: "delivery_days",
		render: (item) =>
			item && !isEmpty(item.delivery_days)
				? allDaysPresent(item.delivery_days)
					? "All"
					: join(item.delivery_days, ", ")
				: "-",
	},
	{
		label: "SKU",
		name: "sku",
		render: (item) => (item && !isEmpty(item.skus) && join(map(item.skus, "sku"), ", ")) || "-",
	},
	{
		label: "Status",
		name: "status",
		render: (item) => item.status ? 'Active' : 'Inactive'
	}
];

export const daysArray = [
	{
		value: "Monday",
		label: "Monday",
		index: 0,
	},
	{
		value: "Tuesday",
		label: "Tuesday",
		index: 1,
	},
	{
		value: "Wednesday",
		label: "Wednesday",
		index: 2,
	},
	{
		value: "Thursday",
		label: "Thursday",
		index: 3,
	},
	{
		value: "Friday",
		label: "Friday",
		index: 4,
	},
	{
		value: "Saturday",
		label: "Saturday",
		index: 5,
	},
	{
		value: "Sunday",
		label: "Sunday",
		index: 6,
	},
];

export const getValidations = (field) => {
	switch (field) {
		case "customer_name":
			return [required, names];
		case "zip_code":
			return [required, number, USZipCode];
		case "location":
			return [required];
		case "state":
			return [required];
		case "address":
			return [required];
		case "city":
			return [required];
		case "delivery_days":
			return [required];
		case "sku":
			return [required];
		case "name":
			return [required];
		case "address_internal_id":
			return [];
		case "percentage":
			return [required, number, max100p, min0p];
		default:
			return [required, number];
	}
};

export const mapOptions = (list, label, value) => {
	const newList = list.map((field) => ({
		label: field[label],
		value: field[value].toString(),
	}));

	return newList;
};

export const allDaysPresent = (list) =>
	includes(list, "Monday") &&
	includes(list, "Tuesday") &&
	includes(list, "Wednesday") &&
	includes(list, "Thursday") &&
	includes(list, "Friday") &&
	includes(list, "Saturday") &&
	includes(list, "Sunday")
		? true
		: false;

// Map : Initial values / If all days are present / Show All
export const daysMap = (list) => {
	if (allDaysPresent(list)) {
		return [
			{
				label: "All",
				value: "All",
			},
		];
	} else {
		return list.map((field) => ({
			label: field.replace(/\b[a-z]/g, (x) => x.toUpperCase()),
			value: field.toString(),
		}));
	}
};

export const skuMap = (list) => {
	const newList = [];
	list.map((field) => (newList[field.slug] = field.price));
	return newList ? newList : [];
};

export const createSKUPercentageObj = (skuPerc = {}, days, skus) => {
	if (isEmpty(skuPerc) && !isEmpty(days) && !isEmpty(skus)) {
		let resultSkuPerc = {};
		let skusObj = {};

		// Create SKU Object => skuSlug: {skuDetails}
		forEach(skus, (o) => {
			skusObj = {
				...skusObj,
				[o.slug]: {
					percentage: "",
					sku_name: o.sku,
					sku_slug: o.slug,
				},
			};
		});

		// Create Days Object => day: {skuObject}
		forEach(days, (o) => {
			resultSkuPerc = {
				...resultSkuPerc,
				[o]: skusObj,
			};
		});
		return resultSkuPerc;
	} else {
		return skuPerc;
	}
};
