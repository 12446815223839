import { createSelectorCreator, defaultMemoize } from "reselect";
import Util from "utils/Util";
import { isEqual, omit } from "lodash";

const getAuth = (state) => state.Auth;

const getClimateGraphParams = (state) => state.Climate;

const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);

export const getUserLocation = createDeepEqualSelector([getAuth], (auth) => auth.userLocations[0] || []);

export const getGraphTypeArray = createDeepEqualSelector(
	[getClimateGraphParams],
	(data) => data.dropDownDetails.graph_types || []
);

export const getLocationList = createDeepEqualSelector(
	[getAuth],
	(auth) => Util.mapOptions(auth.climateLocations, "name", "slug") || []
);

export const getUserAllocatedLocation = createDeepEqualSelector(
	[getAuth],
	(auth) => Util.mapOptions(auth.userLocations, "name", "slug") || []
);

export const getLocationDetailsArray = createDeepEqualSelector(
	[getClimateGraphParams],
	(data) => omit(data.dropDownDetails, ["graph_types"]) || []
);

const getReportsPermission = (state) => state.Auth.permissionDetails["climate-reports"];

export const getClimateAllAccess = createDeepEqualSelector(
	[getReportsPermission],
	(reports) => reports && reports.permissions.climate_all
);
export const getDailyAverageAccess = createDeepEqualSelector(
	[getReportsPermission],
	(reports) => reports && reports.permissions.daily_averages
);
export const getLinearPlotAccess = createDeepEqualSelector(
	[getReportsPermission],
	(reports) => reports && reports.permissions.linear_plots
);
export const getAllLocationAccess = createDeepEqualSelector(
	[getReportsPermission],
	(reports) => reports && reports.permissions.climate_report_all_locations
);

export const getClimateAccess = createDeepEqualSelector([getReportsPermission], (reports) => reports && reports.permissions);

export const getClimateReportFullPermission = createDeepEqualSelector(
	[getReportsPermission],
	(reports) => reports && reports.full_permission
);

const getTablePermission = (state) => state.Auth.permissionDetails["climate-table"];

export const getClimateTableFullPermission = createDeepEqualSelector(
	[getTablePermission],
	(reports) => reports && reports.permissions.data_table
);
export const getTableAllLocationAccess = createDeepEqualSelector(
	[getTablePermission],
	(reports) => reports && reports.permissions.all_locations
);
