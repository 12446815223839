import { CUSTOMERS } from "../types";
import fetchHandler from "../../utils/fetchHandler";

export const setCustomerViewRender = (value) => {
	return {
		type: CUSTOMERS.SET_CUSTOMER_VIEW_RENDER,
		payload: value,
	};
};
export const createCustomer = (values, slug, successHandler, errorHandler) => {
	const fetchOptions = {
		url: slug ? `customer/${slug}` : `customer`,
		method: slug ? "PUT" : "POST",
		secure: true,
		actionType: CUSTOMERS.CREATE_CUSTOMER,
		body: JSON.stringify(values),
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};
export const deleteCustomer = (slug, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `customer/${slug}`,
		method: "DELETE",
		actionType: CUSTOMERS.DELETE_CUSTOMER,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const fetchCustomerDetails = (slug, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `customer/${slug}`,
		method: "GET",
		actionType: CUSTOMERS.FETCH_CUSTOMER_DETAILS,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const getCustomersList = (successHandler, errorHandler) => {
	const fetchOptions = {
		url: "customers",
		method: "GET",
		actionType: CUSTOMERS.GET_CUSTOMER_MINI_LIST,
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const fetchCustomers = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: "customers",
		method: "POST",
		actionType: CUSTOMERS.FETCH_CUSTOMERS,
		body: JSON.stringify(values),
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};
export const fetchCustomerLocations = () => {
	const fetchOptions = {
		url: `locations`,
		method: "GET",
		actionType: CUSTOMERS.FETCH_CUSTOMERS_LOCATION_LIST,
	};

	return fetchHandler(fetchOptions);
};
export const fetchCustomerSkus = () => {
	const fetchOptions = {
		url: `location-skus`,
		method: "GET",
		actionType: CUSTOMERS.FETCH_CUSTOMERS_SKU_LIST,
	};

	return fetchHandler(fetchOptions);
};

export const fetchCustomerDcPriority = (slug, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `dc-priority/${slug}`,
		method: "GET",
		actionType: CUSTOMERS.FETCH_CUSTOMER_DC_PRIORITY,
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};
