import React from "react";

const RenderIcons = () => {
	const icons = [
		"icon-angle-left",
		"icon-angle-right",
		"icon-angle-up",
		"icon-caret-down",
		"icon-caret-left",
		"icon-caret-right",
		"icon-caret-up",
		"icon-check",
		"icon-close",
		"icon-edit",
		"icon-minus",
		"icon-notification",
		"icon-plus",
		"icon-search",
		"icon-trash",
		"icon-upload",
		"icon-user",
		"icon-angle-down",
		"icon-shipping-icon",
		"icon-shipped-icon",
		"icon-export"
	];
	return (
		<div className="ui-cards">
			<h2 className="mb-4">Icons</h2>
			<div className="row">
				{icons.sort().map((icon) => (
					<div key={icon} className="col-3">
						<div className="card card-primary p-3 mb-3">
							<div className="text-center p-2">
								<i className={`icon ${icon}`} />
							</div>
							<div className="text-center">{icon}</div>
						</div>
					</div>
				))}
			</div>
		</div>
	);
};

export default RenderIcons;
