import React from "react";
import { connect } from "react-redux";
import {
	//PieChart,
	//Pie,
	//Legend,
	Cell,
	ResponsiveContainer,
	Tooltip,
	BarChart,
	Bar,
	CartesianGrid,
	XAxis,
	YAxis,
} from "recharts";
import { pieChartColorArray } from "../../../utils/helper";
import {
	singleToolTipProps,
	//legendProps,
	barChartProps,
	cartesianGridProps,
	xAxisProps,
	yAxisProps,
} from "../chartComponentHelper";
import { createStructuredSelector } from "reselect";
import { getOzPerBoardPiArray } from "store/selectors/dashboardSelectors";
import ContentLoader from "../../contentLoader/contentLoader";
import { useTranslation } from "react-i18next";

const OzPerBoardGraphBlock = ({ getOzPerBoardPiArray, loadingFlag }) => {
	const { t } = useTranslation();
	return (
		<div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6 mb-4 ">
			<div className="card h-100">
				<div className="card-body">
					<div className="graph-headers"> {t("label.oz_per_board")}</div>
					{loadingFlag ? (
						<ContentLoader type="pie" />
					) : Object.keys(getOzPerBoardPiArray).length !== 0 ? (
						<ResponsiveContainer aspect={2}>
							{/* <PieChart>
								<Pie data={getOzPerBoardPiArray} outerRadius={100} fill="#8884d8" dataKey="value">
									{getOzPerBoardPiArray.map((entry, index) => (
										<Cell
											key={`cell-${index}`}
											fill={pieChartColorArray[index % pieChartColorArray.length]}
										/>
									))}
								</Pie>
								<Legend {...legendProps} />
								<Tooltip {...singleToolTipProps} />
							</PieChart> */}
							<BarChart {...barChartProps} data={getOzPerBoardPiArray}>
								<CartesianGrid {...cartesianGridProps} />
								<XAxis {...xAxisProps} />
								<YAxis {...yAxisProps} />
								<Tooltip {...singleToolTipProps} />
								<Bar dataKey="value">
									{getOzPerBoardPiArray.map((entry, index) => (
										<Cell
											key={`cell-${index}`}
											fill={pieChartColorArray[index % pieChartColorArray.length]}
										/>
									))}
								</Bar>
							</BarChart>
						</ResponsiveContainer>
					) : (
						<p className="card-text-empty">{t("no_data_available")}</p>
					)}
				</div>
			</div>
		</div>
		// </div>
	);
};

const mapStateToProps = createStructuredSelector({
	getOzPerBoardPiArray,
});
export default connect(mapStateToProps, {})(OzPerBoardGraphBlock);
