import React from "react";
import CustomModal from "utils/modal";

import useModal from "utils/useModal";
import AddEditOrders from "./addEditOrders";
import { useTranslation } from 'react-i18next';

const OrdersManage = ({ onAddOrEdit }) => {
	const { t } = useTranslation();
	const [isOpen, modalType, , closeModal] = useModal("orders-add-edit");

	return (
		<CustomModal
			show={isOpen}
			onHide={closeModal}
			title={modalType === 1 ? t("label.add_order") : modalType === 2 ? t("label.edit_order") : ""}>
			<AddEditOrders onAddOrEdit={onAddOrEdit} />
		</CustomModal>
	);
};

export default OrdersManage;
