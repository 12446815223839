import { DAILY_DATA_GRAPH } from "../types";
const initialState = {
	dailyGraphDetails: [],
};
export default (state = initialState, action) => {
	switch (action.type) {
		case DAILY_DATA_GRAPH.FETCH_DAILY_DATA_GRAPH:
			return {
				...state,
				dailyGraphDetails: action.payload ? action.payload.data : [],
			};

		default:
			return state;
	}
};
