import React from "react";
import { filter, map, without } from "lodash";
import { AccordionTableSummary, AccordionTablePermissionRow } from "./views";

export const getFixedColumns = (roles) => {
	const fixedColumns = [
		{
			key: "column-0",
			dataKey: "column-0",
			title: "",
			width: 216,
			frozen: "left",
			resizable: true,
			maxWidth: 216,
		},
	];
	roles &&
		roles.forEach(function (data, key) {
			fixedColumns[key + 1] = {
				key: "column-" + (key + 1),
				dataKey: "column-" + (key + 1),
				title: data.name,
				width: "column-" + (key + 1) === "column-12" ? 121 : "column-" + (key + 1) === "column-6" ? 115 : 98,
				align: "center",
				frozen: false,
				cellRenderer: ({ cellData, rowData }) =>
					cellData.flag === "child" ? (
						<AccordionTablePermissionRow
							permission={cellData.permission}
							roles={cellData.role}
							permissionRoles={cellData.permissionRoles}
							permissionIds={cellData.permissionId}
							disabled={cellData.disabled}
							onChangeCheckbox={cellData.changeFunction}
							details={cellData.details}
						/>
					) : (
						<AccordionTableSummary
							key={cellData.role.id}
							role={cellData.role}
							disabled={cellData.disabled}
							permission={cellData.permission}
							onChangeSummary={cellData.changeFunction}
						/>
					),
			};
		});
	return fixedColumns;
};

export const getColumnKeys = (fixedColumns) => {
	return fixedColumns ? without(map(fixedColumns, "key"), "column-0") : [];
};

// Update/create API request body
// With new permission object
export const updateRequestArray = (prState, clickedPermissionId, updatedRolesInPermission) => {
	// Filter-out the existing API request body arr
	// with arr to inject
	const filteredExistingArr = filter(prState, (o) => o.permission_id !== clickedPermissionId);

	// Array to pass into API
	prState = [
		...filteredExistingArr,
		{
			permission_id: clickedPermissionId,
			role_ids: updatedRolesInPermission,
		},
	];

	return prState;
};

// Update list that is rendered in the list
export const updateRenderArray = (permState, clickedPermissionId, updatedRolesInPermission) => {
	const firstArr = map(permState, (o) => {
		const secArr = map(o.permissions, (p) => {
			if (p.id === clickedPermissionId) {
				p = {
					...p,
					permission_roles: updatedRolesInPermission,
				};
			}
			return p;
		});
		return {
			...o,
			permissions: secArr,
		};
	});
	return firstArr;
};
