import React, { useState, useEffect } from "react";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import { Field, reduxForm, reset, getFormValues, change } from "redux-form";
import FormGroups from "../../../utils/formGroup";
import { ButtonLink } from "../../../elements";
import { connect, useSelector, useDispatch } from "react-redux";
import useModal from "../../../utils/useModal";
import { successMessage } from "../../../utils/successMessage";
import { errorMessage } from "../../../utils/errorMessage";
import Util from "../../../utils/Util";
import TableLoader from "../../../utils/table/tableLoader";
import { OPPackageDetailsFormFields, bestBuyDays } from "./packageDetailsFormHelper";
import { addPackagingDetails, updatePackagingDetails } from "store/actions";
import { findKey } from "lodash";
import { useTranslation } from "react-i18next";

const PackageDetailsForm = ({
	isEdit,
	form,
	handleSubmit,
	addPackagingDetails,
	updatePackagingDetails,
	doFetchTaskDetails,
}) => {
	const dispatch = useDispatch(null);
	const { t } = useTranslation();
	const [, , , closeModal] = useModal("seeding_details");
	const { packageDetails = {} } = useSelector((state) => state.OrdersPackaging);
	const currentPackageOrder = useSelector((state) => state.OrdersPackaging.current_order_and_package_slug);
	const formValues = useSelector((state) => state.form["package-details-form"]);
	const [isLoading, setIsLoading] = useState(false);
	const [disableBestBuyDate, setDisableBestBuyDate] = useState({ 0: false });
	const EditData = useSelector((state) => state.OrdersPackaging.packageDetails.order_packaging_task_details);
	const current_order_and_package_slug = useSelector((state) => state.OrdersPackaging.current_order_and_package_slug);
	const addStatus = useSelector((state) => state.OrdersPackaging.addStatus);

	const [packageMetricsList, setPackageMetricsList] = useState(
		// initialValues && initialValues.transplantMapArray ? initialValues.transplantMapArray :
		[{ key: 0, flag: "add" }]
	);

	useEffect(() => {
		addStatus
			? setDisableBestBuyDate({ 0: false })
			: EditData &&
			  EditData.map((item) => {
					if (item.slug === current_order_and_package_slug) {
						item.best_buy_days ? setDisableBestBuyDate({ 0: true }) : setDisableBestBuyDate({ 0: false });
					}
			  });
	}, [EditData, addStatus, current_order_and_package_slug]);

	// Submit Form
	const submitDetails = (values) => {
		const metrics = packageMetricsList.map(function (metric) {
			return {
				packaging_start_date: Util.getMDYFormatUtcDateAndTime(values["lm_" + metric.key].packaging_start_date),
				cases_packed: values["lm_" + metric.key].actual_cases_packed,
				packaging_end_date: Util.getMDYFormatUtcDateAndTime(values["lm_" + metric.key].packaging_end_date),
				best_buy_date: Util.getMDYFormatUtcDate(values["lm_" + metric.key].best_buy_date),
				change_overtime: values["lm_" + metric.key].change_over_time,
				down_time: values["lm_" + metric.key].down_time,
				lot_no: values["lm_" + metric.key].lot_no,
				best_buy_days: values["lm_" + metric.key].best_buy_days ? values["lm_" + metric.key].best_buy_days.value : "",
				no_of_employees: values["lm_" + metric.key].no_of_employees ? values["lm_" + metric.key].no_of_employees : "",
			};
		});
		if (packageDetails.slug) {
			setIsLoading(true);
			const successHandler = (data) => {
				successMessage(data);
				setIsLoading(false);
				closeModal();
				reset(form);
				doFetchTaskDetails();
			};
			const errorHandler = (err) => {
				errorMessage(err);
				setIsLoading(false);
			};

			// const { change_over_time, down_time, cases_packed, no_of_employees } = values || {};
			const body = {
				packaging_details: metrics,
			};

			isEdit
				? updatePackagingDetails(currentPackageOrder, metrics[0], successHandler, errorHandler)
				: addPackagingDetails(packageDetails.slug, body, successHandler, errorHandler);
		}
	};

	const getOptions = (name) => {
		switch (name) {
			case "best_buy_days":
				return bestBuyDays;
			default:
				return [];
		}
	};

	const onSelect = (name, value, id) => {
		let package_date;
		let bestbuydate;
		switch (name) {
			case "packaging_start_date":
				const selectedBestBuyDate =
					formValues &&
					formValues.values &&
					formValues.values[`lm_${id}`] &&
					formValues.values[`lm_${id}`].best_buy_days
						? formValues.values[`lm_${id}`].best_buy_days
						: null;
				package_date = value;
				bestbuydate = Util.addDateToPackageStartDate(package_date, selectedBestBuyDate);
				if(formValues && formValues.values && formValues.values[`lm_${id}`] && formValues.values[`lm_${id}`].best_buy_days){
					dispatch(change("package-details-form", `lm_${id}.best_buy_date`, bestbuydate))
					dispatch(change("package-details-form", `lm_${id}.packaging_end_date`, ""))
				}else{
					dispatch(change("package-details-form", `lm_${id}.best_buy_date`, ""))
					dispatch(change("package-details-form", `lm_${id}.packaging_end_date`, ""))
				}

				break;
			case "best_buy_days":
				package_date = formValues && formValues.values && formValues.values[`lm_${id}`].packaging_start_date;
				bestbuydate = Util.addDateToPackageStartDate(package_date, value);
				dispatch(change("package-details-form", `lm_${id}.best_buy_date`, bestbuydate));
				setDisableBestBuyDate({ ...disableBestBuyDate, [id]: true });
				break;
			default:
				return;
		}
	};

	const DateValidation = (name, id) => {
		let package_date;
		switch (name) {
			case "best_buy_date":
				package_date =
					formValues &&
					formValues.values &&
					formValues.values[`lm_${id}`] &&
					formValues.values[`lm_${id}`].packaging_start_date;
				return new Date(package_date);
			case "packaging_end_date":
				package_date =
					formValues &&
					formValues.values &&
					formValues.values[`lm_${id}`] &&
					formValues.values[`lm_${id}`].packaging_start_date;
				return new Date(package_date);
			default:
				return null;
		}
	};
	const metricsFormFields = (count, flag = "add", i) => {
		let columns = [];

		columns = OPPackageDetailsFormFields.map((field, idx) => {
			return (
				<Col className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12" key={idx}>
					<Form.Group controlId={`formControl ${idx}`}>
						{field.name === "best_buy_days" ? (
							<Field
								name={`lm_${count}.${field.name}`}
								type={field.type}
								label={t(field.label)}
								placeholder={t(field.placeholder)}
								component={FormGroups}
								validate={field.validate}
								options={getOptions(field.name)}
								onChange={(event) => onSelect(field.name, event, i)}
							/>
						):field.name === "packaging_start_date" ? (
							<Field
								name={`lm_${count}.${field.name}`}
								type={field.type}
								label={t(field.label)}
								placeholder={t(field.placeholder)}
								component={FormGroups}
								validate={field.validate}
								options={getOptions(field.name)}
								onChange={(event) => onSelect(field.name, event, i)}
							/>
						) : (
							<Field
								name={`lm_${count}.${field.name}`}
								type={field.type}
								label={t(field.label)}
								placeholder={t(field.placeholder)}
								component={FormGroups}
								validate={field.validate}
								onChange={(event) => onSelect(field.name, event, i)}
								minDate={DateValidation(field.name, i)}
								disable={field.name === "best_buy_date" ? disableBestBuyDate[i] : false}
							/>
						)}
					</Form.Group>
				</Col>
			);
		});

		return (
			<React.Fragment key={i}>
				<div className="outer-box">
					<Row>{columns}</Row>
					{isEdit ? null : (
						<>
							<Row>
								{packageMetricsList.length - 1 === i ? (
									<Col
										className={`d-flex justify-content-center text-center ${
											packageMetricsList.length === 1 ? "col-xl-12 col-lg-12  " : "col-xl-6 col-lg-6"
										} col-md-12 col-sm-12 col-12`}>
										<ButtonLink onClick={() => setMetricsArray(count + 1)}>
											<i className="icon icon-plus mr-2" />
											{t("label.Add")}
										</ButtonLink>
									</Col>
								) : (
									""
								)}
								{packageMetricsList.length !== 1 ? (
									<Col
										className={`d-flex justify-content-center text-center ${
											packageMetricsList.length - 1 !== i ? "col-xl-12 col-lg-12  " : "col-xl-6 col-lg-6"
										} col-md-12 col-sm-12 col-12`}>
										<ButtonLink className="remove-link-btn" onClick={() => removeMetricsArray(count)}>
											<i className="icon icon-minus mr-2" />
											{t("label.remove")}
										</ButtonLink>
									</Col>
								) : (
									""
								)}
							</Row>
						</>
					)}
				</div>
			</React.Fragment>
		);
	};

	const setMetricsArray = (count) => {
		setPackageMetricsList([
			...packageMetricsList,
			{
				key: count,
				flag: "add",
			},
		]);
	};
	const removeMetricsArray = (index) => {
		const locationMetricsListKeyToRemove = findKey(packageMetricsList, function (o) {
			return o.key === index;
		});
		dispatch(change("package-details-form", `lm_${index}`, ""));
		setDisableBestBuyDate({ ...disableBestBuyDate, [index]: false });
		const list = [...packageMetricsList];
		list.splice(locationMetricsListKeyToRemove, 1);
		setPackageMetricsList(list);
	};

	return (
		<Form onSubmit={handleSubmit(submitDetails)} className="board-details-form">
			<Container className="position-relative">
				{packageMetricsList &&
					packageMetricsList.map((x, i) => {
						return metricsFormFields(x.key, x.flag, i);
					})}

				{/* Loader */}
				{isLoading && (
					<div className="table-loader-wrapper">
						<TableLoader />
					</div>
				)}
			</Container>
			<div className="modal-footer">
				<Button variant="secondary" onClick={closeModal}>
					{t("label.cancel")}
				</Button>
				<Button variant="primary" type="submit" disabled={isLoading}>
					{isLoading ? t("label.saving") : t("label.save")}
				</Button>
			</div>
		</Form>
	);
};

const mapStateToProps = (state) => {
	const { OrdersPackaging, Modals } = state;
	const ordersAndPackageDetailsArray = OrdersPackaging.packageDetails.order_packaging_task_details;
	const current_order_and_package_slug = OrdersPackaging.current_order_and_package_slug;
	const initialArray = () => {
		const packageMetrics = ordersAndPackageDetailsArray ? ordersAndPackageDetailsArray : [];
		const packageMetricsArray = [];
		packageMetrics.forEach(function (data, key) {
			let no_of_days = null;
			if (current_order_and_package_slug === data.slug) {
				if (data) {
					if (data["best_buy_days"] === 1) {
						no_of_days = { label: "12 Days", value: "1" };
					} else if (data["best_buy_days"] === 2) {
						no_of_days = { label: "15 Days", value: "2" };
					} else if (data["best_buy_days"] === 3) {
						no_of_days = { label: "16 Days", value: "3" };
					}
				}
				packageMetricsArray["lm_" + 0] = {
					best_buy_date: Util.getDateObjectFormat(data["best_buy_date"]),
					actual_cases_packed: data["cases_packed"],
					change_over_time: data["change_overtime"],
					down_time: data["down_time"],
					lot_no: data["lot_no"],
					no_of_employees: data["no_of_employees"],
					best_buy_days: no_of_days,
					packaging_end_date: Util.getDateObjectFormat(data["packaging_end_date"]),
					packaging_start_date: Util.getDateObjectFormat(data["packaging_start_date"]),
				};
			}
		});
		const formatedInitialValueArray = {
			...packageMetricsArray,
		};
		return ordersAndPackageDetailsArray ? formatedInitialValueArray : [];
	};
	return {
		formValues: getFormValues("package-details-form")(state),
		initialValues: Modals.modalType === 2 ? initialArray() : {},
	};
};

const mapDispatchToProps = {
	reset,
	addPackagingDetails,
	updatePackagingDetails,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(
	reduxForm({
		form: "package-details-form",
		enableReinitialize: true,
	})(PackageDetailsForm)
);
