import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Row, Col, Form, Container } from "react-bootstrap";
import FormGroups from "utils/formGroup";
import { reduxForm, Field, change } from "redux-form";

import { createStructuredSelector } from "reselect";
import TableLoader from "utils/table/tableLoader";
import { casesByCustomerForm } from "./formHelper";
import {
	getLocationInitial,
	getLocationList,
	getLocationSkus,
	getCasesByCustomerLocation,
	getCasesByCustomerSku,
	getCasesByCustomerDate,
	getCasesByCustomer,
	getCasesByCustomerFormData,
	getLoading,
	getCasesByCustomerFormValidate,
	getShouldUpdateCases,
	getLocationSelected,
} from "store/selectors/ordersPackagingSelectors";
import { errorMessage } from "utils/errorMessage";
import {
	fetchCasesByCustomerDetails,
	loadLocationSkus,
	resetListing,
	selectLocation,
	ordersLoading,
	submitCasesByCustomer,
	setShouldUpdateCases,
	updateCasesByCustomer,
} from "store/actions";
import moment from "moment";
import CasesByCustomerSection from "./casesByCustomerSection";
import { successMessage } from "utils/successMessage";
import { isEmpty } from "lodash";
import { useTranslation } from 'react-i18next';

const CasesByCustomer = ({
	locationList,
	initialValues,
	skuList,
	location,
	date,
	sku,
	fetchCasesByCustomerDetails,
	selectLocation,
	loadLocationSkus,
	casesByCustomer,
	casesByCustomerFormData,
	resetListing,
	ordersLoading,
	loading,
	handleSubmit,
	submitCasesByCustomer,
	setShouldUpdateCases,
	formValidate,
	shouldUpdateCases,
	updateCasesByCustomer,
	globalLocation,
}) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const [startDate, setStartDate] = useState(null);
	const [endDate, setEndDate] = useState(null);

	const onSelect = (field, event) => {
		switch (field) {
			case "location_slug":
				selectLocation(event);
				sku &&
					dispatch(change("casesByCustomer", "sku_slug", "")) &&
					dispatch(change("casesByCustomer", "casesByCustomer", null));

				break;
			default:
				break;
		}
	};

	const onDateChange = (dates) => {
		const [start, end] = dates;
		if (dates) {
			setStartDate(start);
			setEndDate(end);
		} else {
			return;
		}
	};

	const getFormFieldHtml = (formfields) => {
		let formFieldHtml = [];

		formFieldHtml = formfields.map((field, idx) => {
			return (
				<React.Fragment key={idx}>
					<Col className={field.name === "date" || field.name === "location_slug" ? "col-md-6" : "col-12"}>
						<Form.Group controlId={`formControl ${field.name}`}>
							{field.name === "date" ? (
								<Field
									name={field.name}
									type={field.type}
									label={t(field.label)}
									placeholder={t(field.placeholder)}
									component={FormGroups}
									startDate={startDate}
									endDate={endDate}
									onChange={onDateChange}
									validate={field.validate}
									dateFormat={"MM-DD-YYYY"}
									pickerTypeSelector={0}
									// maxDate={fromDate && !toDate && maxDate}
									onBlur={(event) => event.preventDefault()}
								/>
							) : (
								<Field
									name={field.name}
									type={field.type}
									label={t(field.label)}
									placeholder={t(field.placeholder)}
									component={FormGroups}
									validate={field.validate}
									multiple={field.name === "sku_slug" ? true : false}
									options={getOptions(field.name)}
									onChange={(event) => onSelect(field.name, event)}
								/>
							)}
						</Form.Group>
					</Col>
				</React.Fragment>
			);
		});
		return <Row>{formFieldHtml}</Row>;
	};

	useEffect(() => {
		loadLocationSkus();
	}, [loadLocationSkus]);

	useEffect(() => {
		if (globalLocation) {
			selectLocation(globalLocation);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [globalLocation]);

	useEffect(() => {
		if (location && date[0] && date[1] && sku && !isEmpty(sku)) {
			ordersLoading();
			shouldUpdateCases && setShouldUpdateCases(false);

			formValidate && dispatch(change("casesByCustomer", "validate", ""));
			const values = {
				// date: moment(date).format("YYYY-MM-DD"),
				location_slug: location,
				skus: sku.map((item) => item.value),
				order_date_from: moment(date[0]).format("YYYY-MM-DD"),
				order_date_to: moment(date[1]).format("YYYY-MM-DD"),
			};
			const successHandler = () => {
				ordersLoading();
			};
			const errorHandler = (e) => {
				ordersLoading();
				errorMessage(e);
			};
			fetchCasesByCustomerDetails(values, successHandler, errorHandler);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [date, fetchCasesByCustomerDetails, location, ordersLoading, sku]);

	useEffect(() => {
		if (casesByCustomer) {
			dispatch(change("casesByCustomer", "casesByCustomer", casesByCustomer));
		}
	}, [casesByCustomer, dispatch]);

	useEffect(() => {
		return () => {
			resetListing();
		};
	}, [resetListing]);

	const getOptions = (field) => {
		switch (field) {
			case "location_slug":
				return locationList;

			case "sku_slug":
				return skuList;

			default:
				return;
		}
	};

	const onSubmit = (values) => {
		let checkValidation = "";
		let count = 0;
		// eslint-disable-next-line array-callback-return
		values.casesByCustomer.customer_cases.map((item) => {
			if (item.updated_cases && isNaN(Number(item.updated_cases)) && item.updated_cases === "") {
				checkValidation = "notBoth";
				return true;
			} else if (item.updated_cases && isNaN(Number(item.updated_cases))) {
				if (checkValidation === "required") {
					checkValidation = "notBoth";
					return true;
				} else {
					checkValidation = "notNumber";
				}
			} else if (!item.updated_cases && item.updated_cases !== 0) {
				if (checkValidation === "notNumber") {
					checkValidation = "notBoth";
					return true;
				} else {
					checkValidation = "required";
				}
			}
			count = Number(item.updated_cases) + count;
		});
		if (!checkValidation) {
			if (count !== values.casesByCustomer.total_possible_cases) {
				checkValidation = "notEqual";
			}
		}
		if (checkValidation) {
			dispatch(change("casesByCustomer", "validate", checkValidation));
		} else {
			ordersLoading();
			const cases = values.casesByCustomer.customer_cases.map((item) => ({
				task_slug: item.task_slug,
				updated_cases: item.updated_cases,
			}));

			const submitData = {
				// location_slug: values.location_slug.value,
				// sku_slug: values.sku_slug.value,
				// order_date: moment(values.date).format("YYYY-MM-DD"),
				customer_cases: cases,
			};

			const successHandler = (e) => {
				ordersLoading();
				setShouldUpdateCases(false);
				updateCasesByCustomer(values.casesByCustomer);
				successMessage(e);
			};
			const errorHandler = (e) => {
				ordersLoading();
				errorMessage(e);
			};

			submitCasesByCustomer(submitData, successHandler, errorHandler);
		}
	};

	return (
		<Form onSubmit={handleSubmit(onSubmit)} className="casesByCustomer">
			<Container className="postion-relative">
				{getFormFieldHtml(casesByCustomerForm)}
				{casesByCustomerFormData && <CasesByCustomerSection />}
				{loading && (
					<div className="table-loader-wrapper">
						<TableLoader />
					</div>
				)}
			</Container>
		</Form>
	);
};

const mapStateToProps = createStructuredSelector({
	locationList: getLocationList,
	initialValues: getLocationInitial,
	skuList: getLocationSkus,
	location: getCasesByCustomerLocation,
	date: getCasesByCustomerDate,
	sku: getCasesByCustomerSku,
	casesByCustomer: getCasesByCustomer,
	casesByCustomerFormData: getCasesByCustomerFormData,
	loading: getLoading,
	formValidate: getCasesByCustomerFormValidate,
	shouldUpdateCases: getShouldUpdateCases,
	globalLocation: getLocationSelected,
});

export default connect(mapStateToProps, {
	fetchCasesByCustomerDetails,
	selectLocation,
	loadLocationSkus,
	resetListing,
	ordersLoading,
	submitCasesByCustomer,
	setShouldUpdateCases,
	updateCasesByCustomer,
})(
	reduxForm({
		form: "casesByCustomer",
		enableReinitialize: false,
	})(CasesByCustomer)
);
