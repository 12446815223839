import { required } from "constant/formValidators";

export const forecastFormFields = [
	{
		label: "Location *",
		name: "location_slug",
		type: "select-react",
		placeholder: "Select Location",
		validate: [required],
	},
	{
		label: "Customer *",
		name: "customer",
		type: "select-react-expand-all",
		placeholder: "Select Customer",
		validate: [required],
	},
	{
		label: "DC Name *",
		name: "dc_name",
		type: "select-react-expand-all",
		placeholder: "Enter DC Name",
		validate: [required],
	},

	{
		label: "Sku *",
		name: "skus",
		type: "select-react-expand-all",
		placeholder: "Select Sku",
		validate: [required],
	},
];

