import { number, required, requiredDateObj, minValue } from "../../../../constant/formValidators";
// const isPastDate = (value) => (value && value < new Date().setHours(0, 0, 0, 0) ? "Date invalid" : undefined);
const checkBestBuyDate = (value, values) => {
	const dd = (values && values.deliverydate) || null;
	const bbd = value || null;
	const pd = (values && values.packdate) || null;
	const shd = (values && values.shipdate) || null;

	if (dd && bbd && dd < bbd) {
		return "Date invalid";
	}
	if (shd && bbd && shd > bbd) {
		return "Date invalid";
	}
	if (pd && bbd && pd > bbd) {
		return "Date invalid";
	}
	return undefined;
};
const checkShipDate = (value, values) => {
	const dd = (values && values.deliverydate) || null;
	const bbd = (values && values.bestbuydate) || null;
	const pd = (values && values.packdate) || null;
	const shd = value || null;

	if (dd && shd && dd < shd) {
		return "Date invalid";
	}
	if (bbd && shd && bbd < shd) {
		return "Date invalid";
	}
	if (pd && shd && pd > shd) {
		return "Date invalid";
	}
	return undefined;
};

const checkPackDate = (value, values) => {
	const dd = (values && values.deliverydate) || null;
	const bbd = (values && values.bestbuydate) || null;
	const shd = (values && values.shipdate) || null;
	const pd = value || null;
	if (dd && pd && dd < pd) {
		return "Date invalid";
	}
	if (bbd && pd && bbd < pd) {
		return "Date invalid";
	}
	if (shd && pd && shd < pd) {
		return "Date invalid";
	}
	return undefined;
};

export const FullFillmentDetails = (initialValue) => [
	{
		label: "Number of cases *",
		name: "numberofcases",
		type: "number",
		placeholder: "Enter Cases",
		validate: [required, number, minValue(1)],
	},
	{
		label: "Pack Date *",
		name: "packdate",
		type: "date-picker",
		placeholder: "Select Date",
		validate: [requiredDateObj, checkPackDate],
	},
	{
		label: "Ship Date *",
		name: "shipdate",
		type: "date-picker",
		placeholder: "Select Date",
		validate: [requiredDateObj, checkShipDate],
	},
	{
		label: "Best Buy Date *",
		name: "bestbuydate",
		type: "date-picker",
		placeholder: "Select Date",
		validate: [requiredDateObj, checkBestBuyDate],
	},

	{
		label: "Sender *",
		name: "sender",
		type: "select-react",
		view: "full",
		placeholder: "Select Sender",
		validate: [required],
	},
];
