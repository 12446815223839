import React from "react";
import { connect } from "react-redux";
import { get } from "lodash";
import useModal from "../../utils/useModal";
import "./kpi.scss";
import KpiEdit from "./kpiEdit";

const Kpi = ({ locations, permissionDetails }) => {
	const [, modalType] = useModal("kpi");
	const addPermission =
		permissionDetails && permissionDetails.kpi && permissionDetails.kpi.permissions.add_kpi_target
			? permissionDetails.kpi.permissions.add_kpi_target
			: "";
	const viewPermission =
		permissionDetails && permissionDetails.kpi && permissionDetails.kpi.permissions.view_kpi_target
			? permissionDetails.kpi.permissions.view_kpi_target
			: "";

	return (
		<KpiEdit addPermission={addPermission} viewPermission={viewPermission} modalType={modalType} locations={locations} />
	);
};

const mapStateToProps = (state) => {
	const { Auth } = state;
	return {
		locations: get(Auth, "userLocations", null),
		permissionDetails: get(Auth, "permissionDetails", null),
	};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Kpi);
