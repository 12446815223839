import React from "react";
import { map, join } from "lodash";
//import { userRoles } from "../../../constant/global";
import ToggleButton from "../../../utils/toggleButton";
import { ButtonLink, ListingBtn } from "../../../elements";
import { errorMessage } from "utils/errorMessage";

export const renderColumns = ({
	handleViewPanel,
	handleEditPanel,
	confirmDelete,
	addToFilter,
	allLocationsList = [],
	changeUserStatus,
	permissionDetails,
	roleList = [],
}) => {
	const locationMap = map(allLocationsList, (o) => ({
		label: o.location_name,
		value: o.slug,
	}));
	const roleMap = map(roleList, (o) => ({
		label: o.name,
		value: o.id,
	}));
	const updatePermission =
		permissionDetails && permissionDetails.user && permissionDetails.user.permissions.update_user
			? permissionDetails.user.permissions.update_user
			: "";
	const deletePermission =
		permissionDetails && permissionDetails.user && permissionDetails.user.permissions.delete_user
			? permissionDetails.user.permissions.delete_user
			: "";

	return [
		{
			name: "User ID",
			accessor: "uid",
			className: "user-listing-col-userId",
			headerClassName: "user-listing-col-userId-header",
			filter: {
				type: "search",
				onChange: addToFilter,
			},
		},
		{
			name: "Name",
			accessor: "name",
			className: "user-listing-col-name",
			headerClassName: "user-listing-col-name-header",
			filter: {
				type: "search",
				onChange: addToFilter,
			},
			render: (row) => {
				return permissionDetails && permissionDetails.user && permissionDetails.user.permissions.view_user ? (
					<ButtonLink
						className="p-0"
						onClick={() =>
							permissionDetails && permissionDetails.user && permissionDetails.user.permissions.view_user
								? handleViewPanel(`${row.id}`)
								: errorMessage("INSUFFICIENT_PERMISSION")
						}>
						{row.name}
					</ButtonLink>
				) : (
					row.name
				);
			},
		},
		{
			name: "Role",
			accessor: "role",
			className: "user-listing-col-role",
			headerClassName: "user-listing-col-role-header",
			options: roleMap,
			filter: {
				type: "select",
				options: roleMap,
				onChange: addToFilter,
			},
			render: (row) => row.role_name,
		},
		{
			name: "Location",
			accessor: "location",
			className: "user-listing-col-location",
			headerClassName: "user-listing-col-location-header",
			options: locationMap,
			filter: {
				type: "select",
				options: locationMap,
				onChange: addToFilter,
			},
			render: (row) => join(row.location, ", "),
			sortable: false,
		},
		{
			name: "Email",
			accessor: "email",
			className: "user-listing-col-email",
			headerClassName: "user-listing-col-email-header",
			filter: {
				type: "search",
				onChange: addToFilter,
			},
		},
		{
			name: "Actions",
			className: "user-listing-col-actions",
			headerClassName: "user-listing-col-actions-header",
			hide: updatePermission || deletePermission ? false : true,
			render: (row) => {
				return (
					<>
						{updatePermission ? (
							<ToggleButton
								value={row.status ? true : false}
								onChange={(e) =>
									permissionDetails &&
									permissionDetails.user &&
									permissionDetails.user.permissions.update_user
										? changeUserStatus(e, row)
										: errorMessage("INSUFFICIENT_PERMISSION")
								}
							/>
						) : (
							""
						)}
						{updatePermission ? (
							<ListingBtn
								parentClass={
									updatePermission && deletePermission ? "justify-content-end" : "justify-content-around"
								}
								tooltipText="Edit"
								btnClass="btn btn-cell btn-light btn-sm ml-2"
								iconClass="icon icon-edit"
								onClick={() =>
									permissionDetails &&
									permissionDetails.user &&
									permissionDetails.user.permissions.update_user
										? handleEditPanel(`${row.id}`)
										: errorMessage("INSUFFICIENT_PERMISSION")
								}
							/>
						) : (
							""
						)}
						{deletePermission ? (
							<ListingBtn
								parentClass={
									updatePermission && deletePermission ? "justify-content-end" : "justify-content-between"
								}
								tooltipText="Delete"
								btnClass="btn btn-cell btn-light btn-sm ml-2"
								iconClass="icon icon-trash"
								onClick={() =>
									permissionDetails &&
									permissionDetails.user &&
									permissionDetails.user.permissions.delete_user
										? confirmDelete(row.id)
										: errorMessage("INSUFFICIENT_PERMISSION")
								}
							/>
						) : (
							""
						)}
					</>
				);
			},
			sortable: false,
		},
	];
};
