import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Tabs, Tab, Row } from "react-bootstrap";
import { seasonsArray, viewCreationFields as formCreationFields } from "./formHelper";
import "./manage.scss";

const SeedsView = ({ setSeedsViewRender }) => {
	const seedDetails = useSelector((state) => state.Seeds.seedDetails);
	const selectedSeasons =
		seedDetails && seedDetails.season_seed_details
			? seedDetails.season_seed_details.map(function (value, index) {
					return value["season_id"].toString();
			  })
			: [];
	selectedSeasons.sort();
	const [tabsActive, setTabsActive] = useState(selectedSeasons[0]);
	const filteredSeasonNames = seasonsArray.filter((seasons) => selectedSeasons.includes(seasons.value));
	const getFormFieldHtml = (fields, tabValue) => {
		let formFieldHtml = [];
		formFieldHtml = fields.fields.map((field, idx) => {
			return (
				<React.Fragment key={idx}>
					<div className="col-xl-3 col-lg-4 col-md-3 col-sm-12 col-12">
						<p className="d-flex align-items-center text-muted ">
							<span className="light-badge-pill"></span>
							{field.label}
						</p>
						<h6 className="ml-4">
							{field.name && field.name === "price_per_pound" ? field.unit : ""}{" "}
							{seedDetails.season_details["season-id_" + tabValue][field.name]}
							{field.name && field.name !== "price_per_pound" ? field.unit : ""}
						</h6>
					</div>
				</React.Fragment>
			);
		});
		return <>{formFieldHtml}</>;
	};
	const seedsFormFields = (tabValue) => {
		let columns = [];
		columns = formCreationFields.map((fields, idx) => {
			return (
				<React.Fragment key={idx}>
					<Row>
						<div className="form-headers">{fields.heading}</div>
					</Row>

					<Row>{getFormFieldHtml(fields, tabValue)}</Row>
				</React.Fragment>
			);
		});

		return <>{columns}</>;
	};

	return (
		<>
			<h2 className="view-h2">{seedDetails && seedDetails.seed_name}</h2>
			{seedDetails ? (
				<div className="tab-headers">
					<Tabs
						activeKey={tabsActive}
						defaultActiveKey={tabsActive}
						onSelect={(k) => setTabsActive(k)}
						id="uncontrolled-tab-example">
						{filteredSeasonNames.map((tab, index) => (
							<Tab eventKey={tab.value} key={index} title={tab.label}>
								{seedsFormFields(tab.value)}
							</Tab>
						))}
					</Tabs>
				</div>
			) : (
				""
			)}
		</>
	);
};

export default SeedsView;
