import React from "react";
import { connect } from "react-redux";
import { AreaChart, Area, Legend, CartesianGrid, ResponsiveContainer, XAxis, YAxis, Tooltip } from "recharts";
import { areaChartColorArray } from "../../../utils/helper";
import { cartesianGridProps, xAxisProps, yAxisProps, legendPropsArea, toolTipProps } from "../chartComponentHelper";
import { createStructuredSelector } from "reselect";
import { getPackRateGraphArray, getPackRateKpi } from "store/selectors/dashboardSelectors";
import ContentLoader from "../../contentLoader/contentLoader";
import { useTranslation } from "react-i18next";

const OrdersPackRateGraphBlock = ({ getPackRateGraphArray, loadingFlag, getPackRateKpi }) => {
	const { t } = useTranslation();
	return (
		<div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6 mb-4">
			<div className="card h-100">
				<div className="card-body ">
					<div className="d-flex justify-content-between">
						<div className="graph-headers">{t("label.orders_pack_rate")}</div>
						<div className="graph-headers">
							{t("label.kpi_target")} - {getPackRateKpi}{" "}
						</div>
					</div>
					{loadingFlag ? (
						<ContentLoader type="area" />
					) : Object.keys(getPackRateGraphArray).length !== 0 ? (
						<ResponsiveContainer aspect={2}>
							<AreaChart data={getPackRateGraphArray}>
								<CartesianGrid {...cartesianGridProps} />
								<XAxis {...xAxisProps} />
								<YAxis {...yAxisProps} />
								<Tooltip {...toolTipProps} />
								{["Cases Per labor Hour", "Lbs Per Labor Hour"].map((entry, index) => (
									<Area
										key={index}
										type="monotone"
										dataKey={entry}
										stroke={areaChartColorArray[index % areaChartColorArray.length]}
										fill={areaChartColorArray[index % areaChartColorArray.length]}
									/>
								))}

								<Legend {...legendPropsArea} />
							</AreaChart>
						</ResponsiveContainer>
					) : (
						<p className="card-text-empty">{t("no_data_available")}</p>
					)}
				</div>
			</div>
		</div>
		// </div>
	);
};

const mapStateToProps = createStructuredSelector({
	getPackRateGraphArray,
	getPackRateKpi,
});
export default connect(mapStateToProps, {})(OrdersPackRateGraphBlock);
