import React from "react";
import { Row, Col } from "react-bootstrap";

const BoardsView = ({ details = {}, handleEditPanel, updatePermission }) => {
	const { board_name, length, width, height, furrow_count } = details;
	const labelledValue = (label, value) => (
		<Col className="col-lg-4 col-md-6 col-sm-12 col-12 mb-3">
			<p className="d-flex align-items-center text-muted mb-1">
				<span className="light-badge-pill"></span>
				{label}
			</p>
			<h6 className="ml-4">{value ? `${value} Inch` : "-"}</h6>
		</Col>
	);
	return (
		<div className="px-4 pt-3">
			<div className="d-flex align-items-center justify-content-between mb-4">
				<h2>{board_name}</h2>
				{updatePermission ? (
					<button className="btn btn-cell btn-light btn-sm ml-2" onClick={() => handleEditPanel(details)}>
						<i className="icon icon-edit" />
					</button>
				) : (
					""
				)}
			</div>
			<Row className="mb-4">
				{labelledValue("Length", length)}
				{labelledValue("Width", width)}
				{labelledValue("Height", height)}
			</Row>
			<Row>{labelledValue("Furrows", furrow_count)}</Row>
		</div>
	);
};

export default BoardsView;
