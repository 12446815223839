import React from "react";
import { Table, Pagination } from "../../utils/table/table";

const RenderPrimayTable = () => {
	const columns = [
		{
			name: "Name",
			accessor: "name",
			className: "col-table-name",
			headerClassName: "col-table-name-header",
		},
		{
			name: "Office Name",
			accessor: "officeName",
			className: "col-table-office-name",
			headerClassName: "col-table-office-name-header",
		},
		{
			name: "Actions",
			className: "col-table-actions",
			headerClassName: "col-table-actions-header",
			render: (row) => {
				return (
					<button
						className="btn btn-cell btn-primary btn-sm"
						onClick={() => alert(`Clicked on row id: ${row.id}`)}
					>
						<i className="icon icon-edit" />
					</button>
				);
			},
			sortable: false,
		},
	];
	const data = [
		{
			id: 1,
			name: "Name 1",
			officeName: "Office 1",
		},
		{
			id: 2,
			name: "Name 2",
			officeName: "Office 2",
		},
		{
			id: 3,
			name: "Name 3",
			officeName: "Office 3",
		},
	];
	return (
		<div>
			<Table
				columns={columns}
				data={data}
				onSort={(e) => console.log(e)}
				sortBy={"name"}
				sortDir={"asc"}
			/>
			<Pagination
				total={data.length}
				count={10}
				page={1}
				onChange={(e) => console.log(e)}
			/>
		</div>
	);
};

export default RenderPrimayTable;
