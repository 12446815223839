import { PRIVILEGES } from "../types";
import fetchHandler from "../../utils/fetchHandler";

export function getAllPrivilegesList(successHandler, errorHandler) {
	const fetchOptions = {
		url: "privilages",
		method: "GET",
		actionType: PRIVILEGES.GET_ALL_PRIVILEGES_LIST,
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
}

export function updatePrivileges(body, successHandler, errorHandler) {
	const fetchOptions = {
		url: "privilages",
		method: "POST",
		body: JSON.stringify(body),
		actionType: PRIVILEGES.UPDATE_PRIVILEGES_LIST,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
}
