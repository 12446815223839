import React from "react";
import { Modal } from "react-bootstrap";
import TaskAddEdit from "./taskAddEdit";
import TransplantPondAddEdit from "./taskPondAddEdit";
import useModal from "../../../utils/useModal";
import { useTranslation } from 'react-i18next';
const { Header, Title, Body } = Modal;

const TaskManage = ({ taskDetails, doFetchTaskDetails }) => {
	const { t } = useTranslation();
	const [isOpen, modalType, , closeModal, modalProps] = useModal("transplant_details");

	return (
		<Modal size="lg" show={isOpen} onHide={closeModal} className="modal-sidepanel">
			<Header closeButton>
				<Title className="modal-title">
					{modalType === 4
						? t("label.transplant_pond_details")
						: modalType === 1
						? t("label.add_transplant_details")
						: t("label.edit_transplant_details")}
				</Title>
			</Header>
			<Body>
				{modalType === 4 ? (
					<TransplantPondAddEdit
						taskDetails={taskDetails}
						mode={modalType}
						doFetchTaskDetails={doFetchTaskDetails}
						pondsAdded={modalProps}
					/>
				) : (
					<TaskAddEdit taskDetails={taskDetails} mode={modalType} doFetchTaskDetails={doFetchTaskDetails} />
				)}
			</Body>
		</Modal>
	);
};

export default TaskManage;
