import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { getCountDetailsArray } from "store/selectors/dashboardSelectors";
import { cardsMapper, iconsMapper, cardsPermission } from "./countHelper";
import ContentLoader from "../../contentLoader/contentLoader";
import { useTranslation } from "react-i18next";

const CountBlock = ({ getCountDetailsArray, loadingFlag, orderAcess, seedAcess, harvestingAcess }) => {
	const { t } = useTranslation();
	const getDisplayPermission = (card) => {
		switch (card) {
			case "orders":
				return orderAcess;
			case "harvest":
				return harvestingAcess;
			case "seed":
				return seedAcess;

			default:
				return false;
		}
	};
	const cardKeys = Object.keys(cardsMapper);
	return getCountDetailsArray ? (
		<div className="row count-blocks mb-4">
			<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
				<div className="card-group h-100">
					{cardKeys.map(
						(card, i) =>
							getDisplayPermission(cardsPermission[card]) && (
								<div key={i} className="card h-100">
									<div className="card-body">
										<div className="row">
											<div className="card-title col-8">
												{String(getCountDetailsArray[card]) || <ContentLoader type="line" />}
											</div>
											<div className="col-4">
												<i className={iconsMapper[card]}></i>
											</div>
										</div>

										<p className="card-text">{t(cardsMapper[card])}</p>
									</div>
								</div>
							)
					)}
				</div>
			</div>
		</div>
	) : (
		""
	);
};

const mapStateToProps = createStructuredSelector({
	getCountDetailsArray: getCountDetailsArray,
});
export default connect(mapStateToProps, {})(CountBlock);
