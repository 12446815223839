import { CLIMATE } from "../types";
const initialState = {
	graphDetails: [],
	tableDetails: [],
	dropDownDetails: [],
	parameters: [],
	ponds: [],
};
export default (state = initialState, action) => {
	switch (action.type) {
		case CLIMATE.FETCH_CLIMATE_GRAPH_DETAILS:
			return {
				...state,
				graphDetails: action.payload ? action.payload.data : [],
			};
		case CLIMATE.FETCH_CLIMATE_GRAPH_DROPDOWN_DETAILS:
			return {
				...state,
				dropDownDetails: action.payload ? action.payload.data : [],
			};
		case CLIMATE.FETCH_CLIMATE_TABLE_DETAILS:
			return {
				...state,
				tableDetails: action.payload ? action.payload.data : [],
			};
		case CLIMATE.FETCH_CLIMATE_PARAMETERS:
			return {
				...state,
				parameters: action.payload ? action.payload.data : [],
			};
		case CLIMATE.FETCH_POND_PARAMETERS:
			return {
				...state,
				ponds: action.payload ? action.payload.data : [],
			};
		default:
			return state;
	}
};
