import React from "react";
import { Modal } from "react-bootstrap";
import { join } from "lodash";
import useModal from "../../utils/useModal";
import Person from "../../assets/images/profile-image.png";
const { Body } = Modal;

const ProfileView = ({ data = {} }) => {
	const [isOpen, , showModal, closeModal] = useModal("profile");

	const { avatar, email, location, name, phone, role_name, uid } = data || {};

	const labelledValue = (label, value, wrapperClasses) => (
		<div className={wrapperClasses}>
			<p className="d-flex align-items-center text-muted mb-1 text-profile-label">
				<span className="light-badge-pill"></span>
				{label}
			</p>
			<h6 className="text-profile-value ml-4">{value ? value : "-"}</h6>
		</div>
	);

	return (
		<Modal
			size="md"
			show={isOpen}
			onHide={closeModal}
			backdropClassName="profile-view-modal-backdrop"
			className="profile-view-modal">
			<Body>
				{data ? (
					<div>
						<button className="btn btn-cell btn-light d-block btn-sm ml-auto" onClick={() => showModal(1, data)}>
							<i className="icon icon-edit" />
						</button>
						<img className="d-block mx-auto profile-view-modal-image" src={avatar || Person} alt={name} />
						<h3 className="text-center">
							<span className="text-sm">User ID : </span>
							<span className="font-weight-bold">{uid}</span>
						</h3>
						<h2 className="text-center text-md font-weight-bold">{name}</h2>
						<h3 className="text-center">{email}</h3>
						<h3 className="text-center">{phone}</h3>
						<hr />

						{labelledValue("Role", role_name, "mt-4 mb-4")}
						{labelledValue("Location", join(location, ", "), "mb-3")}
					</div>
				) : (
					<div>No data found. Please wait or try refreshing the page.</div>
				)}
			</Body>
		</Modal>
	);
};

export default ProfileView;
