import React, { useState } from "react";
import { connect } from "react-redux";
import { isEmpty, reject, filter, map, includes, find } from "lodash";
import Util from "../../utils/Util";
import { ContentWrap, Breadcrumbs, BackButton ,ButtonPrimary } from "../../elements";
import SeedingGerminationListing from "./listing";
import SeedingGerminationManage from "./manage";
import { useTranslation } from 'react-i18next';
import {
	getSeedingGerminationTaskDetails,
	getRepeatSeedingGerminationTaskDetails,
	toggleListLoader,
	toggleViewLoader,
	fetchLocationUsers,
	changeSeedingGerminationLocation,
} from "../../store/actions";
import "./seedingGermination.scss";
import SeedingGerminationView from "./view";
import { errorMessage } from "../../utils/errorMessage";
import { LocationDropdown } from "components/bulkAssignee/bulkAssigneeComponents";

const Germination = ({
	getSeedingGerminationTaskDetails,
	locationBasedDetails,
	selectedTaskDetails,
	userDetails,
	seedingGerminationList,
	viewPageLoader,
	toggleViewLoader,
	listingPageLoader,
	toggleListLoader,
	permissionDetails,
	changeSeedingGerminationLocation,
}) => {
	const { t } = useTranslation();
	const getTodaysDate = Util.getYMDFormat(new Date());
	const [listFilter, setListFilter] = useState([
		{ column_name: "expected_transplant_date", search: getTodaysDate },
		{ column_name: "status", search: "Germination" },
	]);
	const [bulkAssigneeList, setBulkAssigneeList] = useState([]);
	const [filterLocation, setFilterLocation] = useState(null);
	const [isShowViewPage, setIsShowViewPage] = useState(false);
	const [dropdownValuesObject, setDropdownValuesObject] = useState({});
	const [refetch, setRefetch] = useState(0);
	const [exportFlag,setExportFlag] = useState(null);

	// Formatted location array
	const locations = Util.mapOptions(userDetails && userDetails.location_slugs, "name", "slug") || [];

	// Common error handler
	const errorHandler = (event) => {
		errorMessage(event);
		toggleListLoader(false);
		toggleViewLoader(false);
	};

	// View
	const handleViewPanel = (data) => {
		toggleListLoader(true);
		const openModal = () => {
			toggleListLoader(false);
			setIsShowViewPage(true);
		};
		getSeedingGerminationTaskDetails(data.slug, openModal, errorHandler);
	};

	// ADD/EDIT/REPEAT : Update the dropdown array - corresponding to location selected
	const fetchDropdownArray = (location) => {
		const locObj = locationBasedDetails[location];
		const { product_varieties, users } = locObj || {};
		const products = Util.mapOptions(product_varieties, "product_variety_name", "slug") || [];
		const usersList = Util.mapOptions(users, "name", "id") || [];
		setDropdownValuesObject({ products, usersList });
	};

	const assignPermission =
		permissionDetails && permissionDetails.seeding && permissionDetails.seeding.permissions.seeding_bulk_assign_tasks
			? permissionDetails.seeding.permissions.seeding_bulk_assign_tasks
			: "";

	// ----------------- DAILY TASKS ----------------- //

	// View
	const doFetchTaskDetails = (slug) => {
		toggleViewLoader(true);
		const disableLoader = () => toggleViewLoader(false);
		getSeedingGerminationTaskDetails(slug, disableLoader, disableLoader);
	};

	// ----------------- BULK ASSIGNEE ----------------- //
	const onSelectFilterLocation = (location = null) => {
		if (location) {
			const obj = {
				column_name: "location_slug",
				search: location.value || "",
			};
			const rejectArr = reject(listFilter, ["column_name", "location_slug"]);
			setListFilter([...rejectArr, obj]);
		} else {
			const rejectArr = reject(listFilter, ["column_name", "location_slug"]);
			setListFilter(rejectArr);
		}
		// Clear assignee list, since choosing assignee from a single location is permitted
		setBulkAssigneeList([]);

		// Set location filter
		setFilterLocation(location);
		changeSeedingGerminationLocation(location);
	};

	// Handle bulk assignee checkbox
	const onChangeCheckbox = (e, slug) => {
		if (filterLocation && filterLocation.value) {
			if (e.target.checked) {
				setBulkAssigneeList([...bulkAssigneeList, slug]);
			} else {
				const filteredArr = filter(bulkAssigneeList, (o) => o !== slug);
				setBulkAssigneeList(filteredArr);
			}
		} else {
			errorMessage("NO_LOCATION");
		}
	};

	// Click - Select All checkbox
	const onChangeAllCheckbox = (e) => {
		if (filterLocation && filterLocation.value) {
			if (e.target.checked) {
				const valSlugs = map(seedingGerminationList, (o) => o.slug);
				setBulkAssigneeList(valSlugs);
			} else {
				setBulkAssigneeList([]);
			}
		} else {
			errorMessage("NO_LOCATION");
		}
	};

	const isAllCheckboxSelected = find(seedingGerminationList, (o) => !includes(bulkAssigneeList, o.slug)) ? false : true;

	const isLocationSelected = filterLocation && filterLocation.value ? true : false;
	const exportHandler = () => {
		setExportFlag(true);
	};
	const exportHandlerFlag = () => {
		setExportFlag(false);
	};
	return (
		<ContentWrap>
			<div className="d-flex mb-3">
				{/* -------------- HEADER -------------- */}
				{/* For View page */}
				{isShowViewPage ? <BackButton onClick={() => setIsShowViewPage(false)} /> : null}

				<Breadcrumbs keyValue="germination" showLabel={true} label={isShowViewPage ? t("label.view") : t("label.list")} />

				
				{/* For listing page */}
				{!isShowViewPage && (
					<div className="d-flex align-items-center">
						{assignPermission && !isEmpty(locations) && isEmpty(bulkAssigneeList) && (
							<LocationDropdown value={filterLocation} options={locations} onSelect={onSelectFilterLocation} />
						)}
						{!isEmpty(seedingGerminationList) && <ButtonPrimary className="export_btn btn-fixed-height" onClick={exportHandler}>
							<span>
								{t("label.export")}{" "}
								<i className="icon icon-export text-sm" style={{fontWeight:"bold"}}/>
							</span>
						</ButtonPrimary>}
					</div>
				)}
			</div>

			{/* -------------- BODY -------------- */}
			{isShowViewPage ? (
				// View
				<SeedingGerminationView taskDetails={selectedTaskDetails} viewPageLoader={viewPageLoader} />
			) : (
				// Listing
				<SeedingGerminationListing
					filter={listFilter}
					setFilter={setListFilter}
					assigneeList={bulkAssigneeList}
					onChangeCheckbox={onChangeCheckbox}
					showAllCheckbox={!isEmpty(seedingGerminationList)}
					isLocationSelected={isLocationSelected}
					isAllCheckboxSelected={isAllCheckboxSelected}
					onChangeAllCheckbox={onChangeAllCheckbox}
					listingPageLoader={listingPageLoader}
					refetch={refetch}
					setRefetch={setRefetch}
					handleViewPanel={handleViewPanel}
					exportFlag={exportFlag}
					exportHandlerFlag={()=>{exportHandlerFlag()}}
				/>
			)}

			{/* Add/Edit/Repeat Tasks Modal */}
			<SeedingGerminationManage
				dropdownValuesObject={dropdownValuesObject}
				fetchDropdownArray={fetchDropdownArray}
				doRefetch={() => setRefetch(refetch + 1)}
				doTaskRefetch={doFetchTaskDetails}
			/>
		</ContentWrap>
	);
};

const mapStateToProps = (state) => ({
	userDetails: state.Auth.profileDetails,
	listingPageLoader: state.SeedingGermination.listingPageLoader,
	viewPageLoader: state.SeedingGermination.viewPageLoader,
	locationBasedDetails: state.SeedingGermination.locationBasedDetails,
	selectedTaskDetails: state.SeedingGermination.sgTaskDetails,
	seedingGerminationList: state.SeedingGermination.seedingGerminationList,
	permissionDetails: state.Auth.permissionDetails,
});
const mapDispatchToProps = {
	getSeedingGerminationTaskDetails,
	getRepeatSeedingGerminationTaskDetails,
	toggleListLoader,
	toggleViewLoader,
	fetchLocationUsers,
	changeSeedingGerminationLocation,
};

export default connect(mapStateToProps, mapDispatchToProps)(Germination);
