const pondLayout = {
	"chi-1": ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19"],
	pen: ["1E", "2E", "3E", "4E", "5E", "6E", "7E", "8E", "9E", "1W", "2W", "3W", "4W", "5W", "6W", "7W", "8W", "9W"],
	car: ["1E", "2E", "3E", "4E", "5E", "6E", "7E", "8E", "9E", "1W", "2W", "3W", "4W", "5W", "6W", "7W", "8W", "9W"],
	ohi: ["1", "2", "3", "4", "5", "6", "7", "8", "9"],
	wdc: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
	ele: [],
	gag: ["system1", "system2", "system3", "system4", "system5", "system6"],
	tex: ["system1", "system2", "system3", "system4", "system5", "system6"],
	ilg: ["system1", "system2", "system3", "system4", "system5", "system6"],
	neh: ["system1", "system2", "system3", "system4"],
};
export default pondLayout;
