import { PRODUCT } from "../types";

const initialState = {
	modalName: null,
	modalType: 0,
	modalProps: null
};

export default function (state = initialState, { type, payload }) {
	switch (type) {
		case PRODUCT.SHOW_PRODUCT_VIEW:
			return {
				modalName: payload.modalName,
				modalType: payload.modalType,
				modalProps: payload.modalProps,
			};
		case PRODUCT.HIDE_PRODUCT_VIEW:
			return initialState;
		default:
			return state;
	}
}
