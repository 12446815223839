import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { getCasesByCustomerFormData } from "store/selectors/ordersPackagingSelectors";
import CasesByCustomerTable from "./casesByCustomerTable";
import i18next from "i18next";

const labelledValue = (label, value, classname) => (
	<div className={classname}>
		{(label || value) && (
			<div key={label}>
				<p className="d-flex align-items-center text-muted mb-2">
					<span className="light-badge-pill"></span>
					<span className="view-labels">{label}</span>
				</p>
				<h6 className="ml-4 view-values">{value}</h6>
			</div>
		)}
	</div>
);

const CasesByCustomerSection = ({ casesByCustomer }) => {
	return (
		<div className="cases-by-customer-section">
			<div className="d-flex">
				{labelledValue(i18next.t("label.total_required_cases"), casesByCustomer.total_required_cases)}
				{labelledValue(i18next.t("label.total_possible_cases"), casesByCustomer.total_possible_cases, "ml-4")}
			</div>
			{casesByCustomer.customer_cases && <CasesByCustomerTable />}
		</div>
	);
};

const mapStateToProps = createStructuredSelector({
	casesByCustomer: getCasesByCustomerFormData,
});

export default connect(mapStateToProps)(CasesByCustomerSection);
