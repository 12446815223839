import React from "react";
import { map, join } from "lodash";
import { ButtonLink, ListingBtn } from "../../../elements";

export const renderColumns = ({
	handleViewPanel,
	handleEditPanel,
	confirmDelete,
	addToFilter,
	allLocationsList = [],
	permissionDetails,
}) => {
	const locationMap = map(allLocationsList, (o) => ({
		label: o.location_name,
		value: o.slug,
	}));
	const updatePermission =
		permissionDetails && permissionDetails.stock && permissionDetails.stock.permissions.update_stock_keeping_unit
			? permissionDetails.stock.permissions.update_stock_keeping_unit
			: "";
	const viewPermission =
		permissionDetails && permissionDetails.stock && permissionDetails.stock.permissions.view_stock_keeping_unit
			? permissionDetails.stock.permissions.view_stock_keeping_unit
			: "";
	const deletePermission =
		permissionDetails && permissionDetails.stock && permissionDetails.stock.permissions.delete_stock_keeping_unit
			? permissionDetails.stock.permissions.delete_stock_keeping_unit
			: "";
	return [
		{
			name: "SKU Name",
			accessor: "sku_name",
			className: "sku-listing-col-name",
			headerClassName: "sku-listing-col-name-header",
			filter: {
				type: "search",
				onChange: addToFilter,
			},
			render: (row) => {
				return viewPermission ? (
					<ButtonLink className="px-0" onClick={() => handleViewPanel(row.slug)}>
						{row.sku_name}
					</ButtonLink>
				) : (
					row.sku_name
				);
			},
		},
		{
			name: "Oz Per Clamshell",
			accessor: "oz_per_clamshell",
			className: "sku-listing-col-number",
			headerClassName: "sku-listing-col-number",
			render: (row) => {
				return row.oz_per_clamshell + " Oz";
			},
			sortable: false,
		},
		{
			name: "Clamshells Per Case",
			accessor: "clamshells_per_case",
			className: "sku-listing-col-number",
			headerClassName: "",
			render: (row) => row.clamshells_per_case,
			sortable: false,
		},
		{
			name: "Location",
			accessor: "location_name",
			className: "sku-listing-col-location",
			headerClassName: "sku-listing-col-location-header",
			options: locationMap,
			filter: {
				type: "select",
				options: locationMap,
				onChange: addToFilter,
			},
			render: (row) => join(row.locations, ", "),
			sortable: false,
		},
		{
			name: "Actions",
			className: "location-listing-col-actions",
			headerClassName: "location-listing-col-actions-header",
			hide: updatePermission || deletePermission ? false : true,
			render: (row) => {
				return (
					<>
						{updatePermission ? (
							<ListingBtn
								parentClass={
									updatePermission && deletePermission ? "justify-content-end" : "justify-content-around"
								}
								tooltipText="Edit"
								btnClass="btn btn-cell btn-light btn-sm ml-2"
								iconClass="icon icon-edit"
								onClick={() => handleEditPanel(`${row.slug}`)}
							/>
						) : (
							""
						)}
						{deletePermission ? (
							<ListingBtn
								parentClass={
									updatePermission && deletePermission ? "justify-content-end" : "justify-content-around"
								}
								tooltipText="Delete"
								btnClass="btn btn-cell btn-light btn-sm ml-2"
								iconClass="icon icon-trash"
								onClick={() => confirmDelete(`${row.slug}`)}
							/>
						) : (
							""
						)}
					</>
				);
			},
			sortable: false,
		},
	];
};
