import { required, number, requiredDateObj, minValue } from "../../constant/formValidators";

export const formCreationFieldsTop = [
	{
		label: "Location *",
		name: "location",
		type: "select-react",
		placeholder: "Select Location",
	},
];
export const formCreationFieldsBottom = [
	{
		label: "Pack Rate Target",
		name: "pack_rate_target",
		type: "text",
		placeholder: "Enter pack rate target",
	},
	{
		label: "Seed Rate Target",
		name: "seed_rate_target",
		type: "text",
		placeholder: "Enter seed rate target",
	},
	{
		label: "Harvest Rate Target",
		name: "harvest_rate_target",
		type: "text",
		placeholder: "Enter harvest rate target",
	},
];

export const getValidations = (field) => {
	switch (field) {
		case "lane_number":
			return required;
		case "pond_number":
			return required;
		case "number_of_boards":
			return [required, number, minValue(1)];
		case "location":
			return [required];
		case "product_variety":
			return [required];
		case "flag_color":
			return [required];
		case "expected_date":
			return [requiredDateObj];
		default:
			return [number];
	}
};
