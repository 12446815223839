import { requiredDateObj, required } from "constant/formValidators";

export const AddEditForm = {
	details: [
		// {
		// 	label: "Order ID *",
		// 	name: "order_id",
		// 	type: "text",
		// 	placeholder: "Enter Order ID",
		// 	validate: [required],
		// },
		{
			label: "label.location_required",
			name: "location_slug",
			type: "select-react",
			placeholder: "label.select_location",
			validate: [required],
		},
		{
			label: "label.customer_name_req",
			name: "customer_slug",
			type: "select-react",
			placeholder: "label.select_customer",
			validate: [required],
		},
		{
			label: "label.dc_name_req",
			name: "dc_slug",
			type: "select-react",
			placeholder: "label.select_dc",
			validate: [required],
		},
		{
			label: "label.sku_req",
			name: "skus",
			type: "select-react",
			placeholder: "label.select_sku",
			validate: [required],
		},
	],
	dates: [
		{
			label: "label.order_request_date_req",
			name: "order_request_date",
			type: "date-picker",
			placeholder: "label.enter_date",
			validate: [requiredDateObj],
		},
		{
			label: "label.date_of_delivery_req",
			name: "date_of_delivery",
			type: "date-picker",
			placeholder: "label.enter_date",
			validate: [requiredDateObj],
		},
	],
};
