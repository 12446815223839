import React, { useEffect } from "react";
import { Breadcrumbs, ContentWrap } from "elements";
import Listing from "./listing";
import "../../index.scss";
import TableLoader from "utils/table/tableLoader";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { getLoading } from "store/selectors/forecastSelectors";
import { getLocationCustomersPF, setLoader } from "store/actions";
import { errorMessage } from "utils/errorMessage";

const DailyForecast = ({ loading, getLocationCustomersPF, setLoader }) => {
	useEffect(() => {
		setLoader();
		const successHandler = () => {
			setLoader();
		};
		const errorHandler = (e) => {
			errorMessage(e);
		};
		getLocationCustomersPF(successHandler, errorHandler);
	}, [getLocationCustomersPF, setLoader]);

	return (
		<ContentWrap>
			<div className="d-flex align-items-center mb-3">
				<Breadcrumbs
					className="forecast-breadcrumb"
					keyValue="salesForecast"
					showLabel={true}
					label={"Daily Forecast"}
				/>
			</div>

			<div className="position-relative">
				<Listing />
				{loading && (
					<div className="table-loader-wrapper">
						<TableLoader />
					</div>
				)}
			</div>
		</ContentWrap>
	);
};

const mapStateToProps = createStructuredSelector({
	loading: getLoading,
});

export default connect(mapStateToProps, { getLocationCustomersPF, setLoader })(DailyForecast);
