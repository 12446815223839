import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { fetchLocationDetails } from "store/actions";
import { errorMessage } from "utils/errorMessage";
import { connect } from "react-redux";
import { get } from "lodash";

import "./index.scss";
import { ContentWrap } from "elements";
import { getProductVarietyList } from "store/actions/productActions";

import { uploadBackfillFile } from "store/actions/forecastActions";
import date from "moment";

const HarvestForecastV2 = ({ fetchLocationDetails, getProductVarietyList, uploadBackfillFile }) => {
	const [greenhouse, setGreenhouse] = useState([]);
	const [varieties, setVarieties] = useState([]);
	const inpRef = useRef(null);
	const [percentage, setPercentage] = useState("");
	const [uploadBtnText, setuploadBtnText] = useState("Upload Backfill");
	let params = useParams();
	useEffect(() => {
		fetchLocationDetails(params.slug, successHandler, errorHandler);

		getProductVarietyList(
			{ filter: [{ column_name: "location_slug", search: params.slug }] },
			(e) => {
				setVarieties(e.data);
				console.log(e.data);
			},
			errorHandler
		);
	}, [fetchLocationDetails, getProductVarietyList, params]);
	const successHandler = (e) => {
		console.log(e.data);
		setGreenhouse(e.data);
	};
	const errorHandler = (e) => {
		errorMessage(e);
	};

	const onChangeFile = async (event) => {
		inpRef.current = event.target;
		setuploadBtnText("Uploading...");

		const successHandler = (e) => {
			setuploadBtnText("Upload Backfill");
			console.log(e);

			inpRef.current.value = "";
		};

		const errorHandler = (e) => {
			setuploadBtnText("Upload Backfill");

			errorMessage(e);
			inpRef.current.value = "";
		};

		const formD = new FormData();

		formD.append("forecast_file", event.target.files[0]);
		formD.append("location_id", greenhouse.slug);
		console.log(formD);

		const options = {
			onUploadProgress: (progressEvent) => {
				const { loaded, total } = progressEvent;
				const percent = Math.floor((loaded * 100) / total);
				// console.log(`${loaded}kb of ${total}kb | ${percent}%`);

				setPercentage(percent);
			},
		};

		uploadBackfillFile(formD, successHandler, errorHandler, options);
	};

	return (
		<ContentWrap>
			<div className="d-flex">
				<div style={{ flex: 1 }}>
					<div style={{ fontSize: 32, fontWeight: "bold" }}>{greenhouse.location_name}</div>
					<div style={{ fontSize: 16 }}>
						{greenhouse.city}, {greenhouse.state}
					</div>
				</div>
				<div style={{ flex: 1, paddingTop: 12 }}>
					<div style={{ fontSize: 16, fontWeight: "bold" }}>
						Green House Type: <span style={{ paddingLeft: 12 }}>Ponds</span>
					</div>
					<div style={{ fontSize: 16, fontWeight: "bold" }}>
						Ponds: <span style={{ paddingLeft: 12 }}>{greenhouse?.pond?.length}</span>
					</div>
					<div style={{ fontSize: 16, fontWeight: "bold" }}>
						Lanes: <span style={{ paddingLeft: 12 }}>{greenhouse?.lanes_per_pond}</span>
					</div>
				</div>
			</div>
			<h2 style={{ fontSize: 24, paddingTop: 48, textAlign: "center" }}>Select a Tool</h2>
			<div className="ui-cards" style={{ paddingTop: 24 }}>
				<div className="d-flex" style={{ justifyContent: "center", alignItems: "center", flexWrap: "wrap" }}>
					{/* <div
						key={"forecast-tool"}
						className="flex mr-3"
						style={{ boxShadow: "0px 0px 8px rgba(0,0,0,.2)", width: "250px", margin: 16 }}>
						<a href={`/gh/grower/${greenhouse.slug}`}>
							<div
								className="card card-primary p-4"
								style={{
									textAlign: "center",
									justifyContent: "center",
									backgroundSize: "cover",
									minHeight: "200px",
									backgroundImage: `url(${require("../../assets/images/placeholder_leaf.png")}`,
								}}>
								<div
									style={{
										textShadow: "2px 2px rgba(0,0,0,.5)",
										fontWeight: "bold",
										fontSize: "32px",
										color: "white",
									}}>
									Harvest Forecast Tool
								</div>
							</div>
						</a>
					</div> */}
					<div
						key={"forecast-tool"}
						className="flex mr-3"
						style={{ boxShadow: "0px 0px 8px rgba(0,0,0,.2)", width: "250px", margin: 16 }}>
						<a href={`/gh/grower-table/${greenhouse.slug}`}>
							<div
								className="card card-primary p-4"
								style={{
									textAlign: "center",
									justifyContent: "center",
									backgroundSize: "cover",
									minHeight: "200px",
									backgroundImage: `url(${require("../../assets/images/placeholder_leaf.png")}`,
								}}>
								<div
									style={{
										textShadow: "2px 2px rgba(0,0,0,.5)",
										fontWeight: "bold",
										fontSize: "32px",
										color: "white",
									}}>
									Harvest Forecast EXCEL
								</div>
							</div>
						</a>
					</div>
					<div
						key={"harvest-tool"}
						className="flex mr-3"
						style={{ boxShadow: "0px 0px 8px rgba(0,0,0,.2)", width: "250px", margin: 16 }}>
						<a href={`/gh/grower-harvest/${greenhouse.slug}/${date().format("MMDDYYYY")}`}>
							<div
								className="card card-primary p-4"
								style={{
									textAlign: "center",
									justifyContent: "center",
									backgroundSize: "cover",
									minHeight: "200px",
									backgroundImage: `url(${require("../../assets/images/placeholder_leaf.png")}`,
								}}>
								<div
									style={{
										textShadow: "2px 2px rgba(0,0,0,.5)",
										fontWeight: "bold",
										fontSize: "32px",
										color: "white",
									}}>
									Harvest Actual
								</div>
							</div>
						</a>
					</div>
				</div>
			</div>
		</ContentWrap>
	);
};

const mapStateToProps = (state) => {
	const { UI, Auth } = state;
	return {
		accessToken: get(Auth, "accessToken", null),
		mainPreloader: get(UI, "mainPreloader", null),
		permissionDetails: get(Auth, "permissionDetails", null),
	};
};

const mapDispatchToProps = {
	fetchLocationDetails,
	getProductVarietyList,
	uploadBackfillFile,
};
export default connect(mapStateToProps, mapDispatchToProps)(HarvestForecastV2);
