import React from "react";

const RenderColors = () => {
	const colors = [
		{ className: "bg-theme", code: "#006241" },
		{ className: "bg-primary", code: "#E46B2D" },
		{ className: "bg-yellow", code: "#C9DD74" },
		{ className: "bg-black", code: "#000000" },
		{ className: "bg-gray", code: "#6D6E71" },
		{ className: "bg-light-gray", code: "#ECF1F0" },
		{ className: "bg-white", code: "#FFFFFF" },
	];
	return (
		<div className="ui-colors">
			<h2 className="mb-4">Colors</h2>
			<div className="d-flex">
				{colors.map(({ className, code }) => (
					<div key={code} className="flex-fill color-container">
						<div className={`color-box ${className}`}></div>
						<div className="color-code">{code}</div>
					</div>
				))}
			</div>
		</div>
	);
};

export default RenderColors;
