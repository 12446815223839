/* eslint-disable array-callback-return */
import React from "react";
import CaseToLaneForm from "../manage/index";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import { laneToCaseColumns, laneUsageHeader } from "../../productVsSales/listing/helper";
import CaseToLaneTable from "utils/caseToLaneTable";
import CaseToLaneProductTable from "utils/caseToLaneProductTable";
import { laneUsage, setLoaderLc } from "store/actions";
import { errorMessage } from "utils/errorMessage";
import { successMessage } from "utils/successMessage";
import Util from "../../../../utils/Util";
import {
	getCaseToLaneData,
	getLaneCalculatorForm,
	getLaneUsageData,
	getLaneData,
	getSubmitData,
	getProduct,
} from "../../../../store/selectors/laneCalculatorSelectors";
import { isEmpty } from "lodash";
const Listing = ({ data, productVariety, formData, laneUsage, laneData, setLoaderLc }) => {
	const onSave = (values) => {
		setLoaderLc();
		const product_varieties = [];
		productVariety &&
			productVariety.map((item, ind) => {
				let temp = {};
				let oz = "";
				let grow = "";
				values[item.pv_name] &&
					values[item.pv_name].map((item, ind_i) => {
						if (item.slug === "oz_board") {
							oz = item.value;
						} else if (item.slug === "grow_cycle") {
							grow = item.value;
						}
					});
				temp = {
					oz_per_board: oz,
					grow_cycle: grow,
					slug: item.pv_slug,
				};
				product_varieties.push(temp);
			});

		const submitData = {
			location_slug: formData.values.location_slug.value,
			sku_slug: formData.values.skus.value,
			cases_required: formData.values.cases_required,
			product_varieties: product_varieties,
			tz: Util.getLocalTimeZone(),
		};
		const successHandler = (e) => {
			successMessage(e);
			setLoaderLc();
		};
		const errorHandler = (e) => {
			errorMessage(e);
			setLoaderLc();
		};
		laneUsage(submitData, successHandler, errorHandler);
	};
	return (
		<div className="listing-wrapper">
			<CaseToLaneForm />
			{productVariety ? (
				!isEmpty(productVariety) ? (
					<>
						<CaseToLaneTable
							editable={true}
							columns={laneToCaseColumns}
							fixedColumnHeading="Product Varieties"
							initialData={data}
							onSave={onSave}
						/>
					</>
				) : (
					<div className="no-results-found text-center">No Results Found</div>
				)
			) : null}
			{laneData ? (
				!isEmpty(laneData) ? (
					<>
						<h1>Lane usage</h1>
						<CaseToLaneProductTable
							columns={laneUsageHeader}
							fixedColumnHeading="Product Varieties"
							initialData={laneData}
							onSave={onSave}
						/>
					</>
				) : (
					<div className="no-results-found text-center">No Results Found</div>
				)
			) : null}
		</div>
	);
};

const mapStateToProps = createStructuredSelector({
	data: getCaseToLaneData,
	formData: getLaneCalculatorForm,
	laneUsageData: getLaneUsageData,
	laneData: getLaneData,
	submitData: getSubmitData,
	productVariety: getProduct,
});
export default connect(mapStateToProps, { laneUsage, setLoaderLc })(Listing);
