/* eslint-disable array-callback-return */
import { keys, values } from "lodash";
import React, { useEffect, useState } from "react";
import TableForm from "./tableForm";

const LaneCalculatorTable = (props) => {
	const { initialData, slugKey, renderKey } = props;
	const [initialValues, setinitialValues] = useState(null);

	useEffect(() => {
		if (initialData) {
			const dataObj = {};
			// eslint-disable-next-line array-callback-return
			if (props.laneUtilization) {
				values(initialData).map((item, i) => {
					dataObj[keys(initialData)[i]] = {};
					props.headers &&
						props.headers.map((item_h) => {
							if (item_h === "Lane Utilized") {
								dataObj[keys(initialData)[i]][item_h] = item && item.gh_utilized_lanes;
							} else if (item_h === "GH Max") {
								dataObj[keys(initialData)[i]][item_h] = item && item.gh_max_lanes;
							}
						});
				});
				setinitialValues(dataObj);
			} else {
				values(initialData).map((item, i) => {
					dataObj[keys(initialData)[i]] = {};
					props.headers &&
						props.headers.map((item_h) => {
							dataObj[keys(initialData)[i]][item_h] =
								item && item[item_h] && item[item_h].lane_usage && item[item_h].lane_usage;
						});
				});
				setinitialValues(dataObj);
			}
		}
	}, [initialData, props, props.localeString, renderKey, slugKey]);
	return initialValues ? (
		<TableForm
			initialValues={initialValues}
			initialData={initialData}
			headers={props.headers}
			type={props.type}
			columns={props.columns}
			{...props}
		/>
	) : null;
};

export default LaneCalculatorTable;
