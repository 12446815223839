import { useDispatch, useSelector } from "react-redux";
import { hideView, showView } from "../../store/actions/productViewActions";

export default function useProductViews(modalName) {
	const dispatch = useDispatch();
	const isOpen = useSelector((state) => state.ProductView.modalName === modalName);
	const modalProps = useSelector((state) => state.ProductView.modalProps);
	const modalType = useSelector((state) => state.ProductView.modalType);
	const closeModal = () => dispatch(hideView());
	const showModal = (modalType, modalProps) => dispatch(showView(modalName, modalType, modalProps));

	return [isOpen, modalType, showModal, closeModal, modalProps];
}
