import { NotificationManager } from "react-notifications";
import i18next from "i18next";
import { successCodes } from "../constant/successCodes";

export const successMessage = (event) => {
	let code = null;

	switch (typeof event) {
		case "number":
		case "string":
			code = event;
			break;
		default:
			code = event && event.code ? event.code : "";
	}

	const delay = 5000;
	if (code || code === 0) {
		const message = successCodes.filter((i) => i.code === code);
		return NotificationManager.success(message[0] ? i18next.t(message[0].message) : i18next.t("sucess.success"), "", delay);
	}
	NotificationManager.success(i18next.t("sucess.success"), "", delay);
};
