import { HARVEST } from "../types";
import fetchHandler from "../../utils/fetchHandler";

export const fetchHarvests = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `harvesting_tasks`,
		method: "POST",
		secure: true,
		body: JSON.stringify(values),
		actionType: HARVEST.FETCH_HARVEST_LIST,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};
export const updatePriorityHarvest = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `update_harvesting_tasks_order`,
		method: "POST",
		secure: true,
		body: JSON.stringify(values),
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const fetchHarvestDetails = (slug, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `harvesting_task/${slug}`,
		method: "GET",
		secure: true,
		actionType: HARVEST.FETCH_HARVEST_DETAILS,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const fetchHarvestForecast = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `harvest-production-forecast`,
		method: "POST",
		secure: true,
		body: JSON.stringify(values),
		actionType: HARVEST.FETCH_HARVEST_FORECAST,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const fetchTaskUsers = (successHandler, errorHandler) => {
	const fetchOptions = {
		url: `task-users/harvesting`,
		method: "GET",
		secure: true,
		actionType: HARVEST.FETCH_TASK_USERS,
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const createHarvest = (values, slug, type, successHandler, errorHandler) => {
	const fetchOptions = {
		url: type === "edit" ? `harvesting_task/${slug}` : "harvesting_task",
		method: "POST",
		secure: true,
		body: JSON.stringify(values),
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const changeLocation = (location) => {
	return (dispatch) => {
		dispatch({
			type: HARVEST.CHANGE_LOCATION,
			payload: location,
		});
	};
};

export const resetAddEdit = () => {
	return (dispatch) => {
		dispatch({
			type: HARVEST.RESET_ADD_EDIT,
		});
	};
};

export const changeRenderType = (value, slug) => {
	return (dispatch) => {
		dispatch({
			type: HARVEST.CHANGE_RENDER_TYPE,
			payload: { value, slug },
		});
	};
};

export const createHarvestingDetails = (values, slug, type, successHandler, errorHandler) => {
	const fetchOptions = {
		url:
			type === 1
				? `harvesting_task/add_details/${slug}`
				: type === 2
				? `harvesting_task/update_details/${slug}`
				: `harvesting_task/add_batch/${slug}`,
		method: "POST",
		secure: true,
		body: JSON.stringify(values),
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const createStartTask = (slug, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `start_harvesting_task/${slug}`,
		method: "GET",
		secure: true,
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const updateHarvestedDetails = (values) => {
	return (dispatch) => {
		dispatch({
			type: HARVEST.UPDATE_HARVESTED_DETAILS,
			payload: values,
		});
	};
};

export const updateHarvestedBoardDetails = (values) => {
	return (dispatch) => {
		dispatch({
			type: HARVEST.UPDATE_HARVESTED_BOARD_DETAILS,
			payload: values,
		});
	};
};

export const updateStatus = (value) => {
	return (dispatch) => {
		dispatch({
			type: HARVEST.UPDATE_STATUS,
			payload: value,
		});
	};
};

export const deleteHarvest = (slug, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `harvesting_task/${slug}`,
		method: "DELETE",
		secure: true,
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const completeTask = (slug, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `complete_harvesting_task/${slug}`,
		method: "GET",
		secure: true,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const allstatusList = () => {
	const fetchOptions = {
		url: `constants`,
		method: "GET",
		secure: true,
		actionType: HARVEST.SET_STATUS_LIST,
	};
	return fetchHandler(fetchOptions);
};

export const getCleaningTime = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `get_cleaning_time`,
		method: "POST",
		secure: true,
		body: JSON.stringify(values),
		actionType: HARVEST.GET_CLEANING_TIME,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const insertCleaningTime = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `cleaning_time`,
		method: "POST",
		secure: true,
		body: JSON.stringify(values),
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const getSetUpTime = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `get_set_up_time`,
		method: "POST",
		secure: true,
		body: JSON.stringify(values),
		actionType: HARVEST.GET_SET_UP_TIME,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const insertSetUpTime = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `set_up_time`,
		method: "POST",
		secure: true,
		body: JSON.stringify(values),
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const getHarvestAuditLog = (slug, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `harvesting_task/auditlog/${slug}`,
		method: "GET",
		actionType: HARVEST.GET_HARVEST_AUDIT_LOG,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const fetchHarvestingTaskOrder = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `harvesting_tasks_order`,
		method: "POST",
		secure: true,
		body: JSON.stringify(values),
		actionType: HARVEST.FETCH_HARVEST_TASK_ORDER_LIST,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const fetchSetUpTime = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `list_harvesting_set_up_time`,
		method: "POST",
		secure: true,
		body: JSON.stringify(values),
		// actionType: HARVEST.FETCH_HARVEST_LIST,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const fetchCleaningTime = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `list_harvesting_cleaning_time`,
		method: "POST",
		secure: true,
		body: JSON.stringify(values),
		// actionType: HARVEST.FETCH_HARVEST_LIST,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const deleteSetUpTime = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `delete_harvesting_set_up_time`,
		method: "DELETE",
		secure: true,
		body: JSON.stringify(values),
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const deleteCleaningTime = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `delete_harvesting_cleaning_time`,
		method: "DELETE",
		secure: true,
		body: JSON.stringify(values),
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const resetHarvestLogTime = () => {
	return (dispatch) => {
		dispatch({
			type: HARVEST.RESET_HARVEST_LOG_TIME,
		});
	};
};
