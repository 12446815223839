import React, { useState, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Col } from "react-bootstrap";
import ToggleButton from "utils/toggleButton";
import TableLoader from "utils/table/tableLoader";
import {
	fetchProductChangeStatus,
	fetchSkuChangeStatus,
	fetchProductBasedList,
	fetchSkuBasedList,
} from "store/actions/locationActions";
import ConfirmModal from "utils/confirmModal";
import { successMessage } from "utils/successMessage";
import { errorMessage } from "utils/errorMessage";

const ManageStatusTable = ({ modalType, modalProp }) => {
	const [loader, setLoader] = useState(false);
	const [confirmModal, setConfirmModal] = useState(false);
	const [confirmData, setConfirmData] = useState(null);
	const [modelStatus, setModelStatus] = useState("");
	const status = useSelector((state) => state?.Location?.manageStatus);
	const dispatch = useDispatch();

	const changeUserStatus = (e, data) => {
		setConfirmData([
			{
				e: e,
				data: data,
			},
		]);
		modalType === 5 &&
			setModelStatus(`Are you sure you want to make the SKU status ${e !== true ? "Inactive" : "active"}?`);
		modalType === 6 &&
			setModelStatus(`Are you sure you want to make the Product Variety status ${e !== true ? "Inactive" : "active"}?`);
		setConfirmModal(true);
	};

	const handleClose = () => setConfirmModal(false);
	const handleSubmit = () => {
		const { e, data } = confirmData[0];
		setLoader(true);
		let isSubmit = true;
		const successHandler = (res) => {
			successMessage(res);
			const body = {
				location_slug: modalProp,
			};
			if (res.status === "success" && isSubmit === true) {
				const successHandler = (res) => {
					res && setLoader(false);
				};
				if (modalType === 5) {
					dispatch(fetchSkuBasedList(body, successHandler, onError));
				} else {
					dispatch(fetchProductBasedList(body, successHandler, onError));
				}
			}
			isSubmit = false;
		};

		const onError = (err) => {
			errorMessage(err);
			setLoader(false);
		};

		const postBody = {
			location_slug: modalProp,
			...(modalType === 5 ? { sku_slug: data?.slug } : { pv_slug: data?.slug }),
			active: e,
		};
		modalType === 5
			? dispatch(fetchSkuChangeStatus(postBody, successHandler, onError))
			: dispatch(fetchProductChangeStatus(postBody, successHandler, onError));
		setConfirmModal(false);
	};

	return (
		<Fragment>
			<ConfirmModal show={confirmModal} onNo={handleClose} onYes={handleSubmit} message={modelStatus} />
			<div className="mt-3">
				<div className="modal-table-head d-flex ml-1 mr-1">
					<Col className="modal-table-head-col cont-wrap">
						<span> {modalType === 5 ? "SKU" : modalType === 6 && "Product Variety"}</span>
					</Col>
					<Col className="modal-table-head-col cont-wrap justify-content-center">
						<span>Status</span>
					</Col>
				</div>
				<div className="table-body-wrapper">
					{!loader &&
						status?.map((data, index) => (
							<div className="modal-table-body-row d-flex" key={index}>
								<Col className="modal-table-col cont-wrap">
									<span>{data.sku_name || data.product_variety_name}</span>
								</Col>
								<Col className="modal-table-col cont-wrap justify-content-center">
									<ToggleButton value={data.active} onChange={(e) => changeUserStatus(e, data)} />
								</Col>
							</div>
						))}
					{status?.length === 0 && <p className="d-flex justify-content-center">No Results Found</p>}
					{loader && (
						<div className="table-loader-wrapper">
							<TableLoader />
						</div>
					)}
				</div>
			</div>
		</Fragment>
	);
};

export default ManageStatusTable;
