import { SEEDS } from "../types";
import fetchHandler from "../../utils/fetchHandler";

export const setSeedsViewRender = (value) => {
	return {
		type: SEEDS.SET_SEEDS_VIEW_RENDER,
		payload: value,
	};
};
export const createSeed = (values, method, successHandler, errorHandler) => {
	const fetchOptions = {
		url: method ? `seed/${method}` : `seed`,
		method: method ? "PUT" : "POST",
		secure: true,
		actionType: SEEDS.CREATE_SEED,
		body: JSON.stringify(values),
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};
export const deleteSeed = (slug, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `seed/${slug}`,
		method: "DELETE",
		actionType: SEEDS.DELETE_SEED,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};
export const fetchSeeds = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: "seeds",
		method: "POST",
		actionType: SEEDS.FETCH_SEEDS,
		body: JSON.stringify(values),
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};
export const fetchSeedDetails = (slug, successHandler, errorHandler, mode = false, greenSlug) => {
	const fetchOptions = {
		url: mode ? `green-seed/${greenSlug}/${slug}` : `seed/${slug}`,
		method: "GET",
		actionType: SEEDS.FETCH_SEED_DETAILS,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const getSeedsList = (successHandler, errorHandler) => {
	const fetchOptions = {
		url: "seed",
		method: "GET",
		actionType: SEEDS.GET_SEEDS_MINI_LIST,
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};
export const fetchSeedChangeStatus = (body, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `seed/change-status`,
		method: "POST",
		actionType: SEEDS.FETCH_SEED_CHANGE_STATUS,
		body: JSON.stringify(body),
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};
