import React, { useEffect, useState } from "react";
import { Button, Row, Col, Form } from "react-bootstrap";
import { reduxForm, Field, change } from "redux-form";
import { forecastFormFields } from "./formHelper";
import { connect, useDispatch } from "react-redux";
import { createStructuredSelector } from "reselect";
import Util from "../../../../utils/Util";
import { errorMessage } from "../../../../utils/errorMessage";
import {
	fetchLaneCalculatorData,
	setTableViewColumns,
	setTableViewDropDown,
	setLaneUsageDropDown,
	fetchLaneCalculatorForecastData,
	laneCalculatorRefresh,
	setLoaderLc,
} from "store/actions";
import {
	getLocationList,
	getCustomers,
	getDcSku,
	getCustomerDCSample,
	getSalesVsProductionType,
	getSalesVsProductionTypeLu,
} from "../../../../store/selectors/laneCalculatorSelectors";
import FormGroups from "utils/formGroup";
const LaneCalculatorForm = ({
	locationList,
	customerList,
	dcList,
	skusList,
	fetchLaneCalculatorData,
	handleSubmit,
	setLoaderLc,
	setTableViewColumns,
	setTableViewDropDown,
	setLaneUsageDropDown,
	viewByTv,
	viewByLu,
	fetchLaneCalculatorForecastData,
	reset,
	laneCalculatorRefresh,
	submitting,
}) => {
	const dispatch = useDispatch();
	const [submitStatus, setSubmitStatus] = useState(false);
	const onSubmit = (values) => {
		setLoaderLc();
		setTableViewDropDown(viewByTv);//table view of product vs sales
		setLaneUsageDropDown(viewByLu);//lane usage and lane utilization 
		const submitData = {
			location_slug: values.location_slug.value,
			customer_slug: values.customer.map((item) => item.value),
			dc_slug: values.dc_name.map((item) => item.value),
			sku_slug: values.skus.map((item) => item.value),
			view_by: viewByLu,
			tz: Util.getLocalTimeZone(),
		};

		const submitDataForcast = {
			location_slug: values.location_slug.value,
			customer_slug: values.customer.map((item) => item.value),
			dc_slug: values.dc_name.map((item) => item.value),
			sku_slug: values.skus.map((item) => item.value),
			view_by: viewByTv,
			tz: Util.getLocalTimeZone(),
		};
		const successHandler = () => {
			setTableViewColumns(values.skus);
			setLoaderLc();
		};
		const errorHandler = (e) => {
			setLoaderLc();
			errorMessage(e);
		};
		!submitStatus && setSubmitStatus(true);
		
		fetchLaneCalculatorForecastData(submitDataForcast);
		fetchLaneCalculatorData(submitData, successHandler, errorHandler);
	};
	const getOptions = (name) => {
		switch (name) {
			case "location_slug":
				return locationList;
			case "customer":
				// return [
				// 	{
				// 		value:"All",
				// 		label:"All"
				// 	},
				// 	...customerList,
				// ]
				return customerList;
			case "dc_name":
				// return [
				// 	{
				// 		value:"All",
				// 		label:"All"
				// 	},
				// 	...dcList,
				// ]
				return dcList;
			case "skus":
				// return [
				// 	{
				// 		value:"All",
				// 		label:"All"
				// 	},
				// 	...skusList,
				// ]
				return skusList;
			default:
				return [];
		}
	};
	const onSelect = (event, newValue, previousValue, field) => {
		if (field === "location_slug") {
			submitStatus && setSubmitStatus(false);
			dispatch(change("laneCalculatorForm", "customer", []));
			dispatch(change("laneCalculatorForm", "dc_name", []));
			dispatch(change("laneCalculatorForm", "skus", []));
		} else if (field === "customer") {
			dispatch(change("laneCalculatorForm", "dc_name", []));
			dispatch(change("laneCalculatorForm", "skus", []));
		} else if (field === "dc_name") {
			dispatch(change("laneCalculatorForm", "skus", []));
		}
	};
	const getFormFieldHtml = (formfields) => {
		let formFieldHtml = [];
		formFieldHtml = formfields.map((field, idx) => {
			return (
				<React.Fragment key={idx}>
					<Col className="forecast-form-column">
						<Form.Group
							className={field.name === "exclude_wastage" && "mt-5"}
							controlId={`formControl ${field.name}`}>
							{field.name === "location_slug" ? (
								<Field
									name={field.name}
									type={field.type}
									label={field.label}
									placeholder={field.placeholder}
									component={FormGroups}
									validate={field.validate}
									onBlur={(event) => event.preventDefault()}
									options={getOptions(field.name)}
									onChange={(event, newValue, previousValue, name) =>
										onSelect(event, newValue, previousValue, name)
									}
								/>
							) : (
								<Field
									name={field.name}
									type={field.type}
									label={field.label}
									placeholder={field.placeholder}
									component={FormGroups}
									validate={field.validate}
									// onBlur={(event) => event.preventDefault()}
									options={getOptions(field.name)}
									multiple={true}
									onChange={(event, newValue, previousValue, name) =>
										onSelect(event, newValue, previousValue, name)
									}
									selectMenuHeight={200}
									checked={true}
								/>
							)}
						</Form.Group>
					</Col>
				</React.Fragment>
			);
		});
		return (
			<Row className="forecast-form-row">
				{formFieldHtml}
				<Col className="form-submit-col">
					<Button type="submit" disabled={submitting} className="apply-btn">
						Apply
					</Button>
					<Button
						className="reset-btn"
						disabled={submitting}
						onClick={() => {
							reset();
							laneCalculatorRefresh();
						}}>
						<i className="icon icon-reset mr-2" />
					</Button>
				</Col>
			</Row>
		);
	};
	useEffect(() => {
		setTableViewDropDown(viewByTv);//table view of product vs sales
		setLaneUsageDropDown(viewByLu);//lane usage and lane utilization 
	}, [setTableViewDropDown, setLaneUsageDropDown, setLoaderLc, viewByTv, viewByLu]);
	
	return (
		<div className="forecast-form-wrapper">
			<Form onSubmit={handleSubmit(onSubmit)} className="laneCalculatorForm">
				{getFormFieldHtml(forecastFormFields)}
			</Form>
		</div>
	);
};

const mapStateToProps = createStructuredSelector({
	locationList: getLocationList,
	customerList: getCustomers,
	dcList: getCustomerDCSample,
	skusList: getDcSku,
	viewByTv: getSalesVsProductionType,
	viewByLu: getSalesVsProductionTypeLu,
});

export default connect(mapStateToProps, {
	fetchLaneCalculatorData,
	setTableViewColumns,
	setTableViewDropDown,
	setLaneUsageDropDown,
	fetchLaneCalculatorForecastData,
	laneCalculatorRefresh,
	setLoaderLc,
})(reduxForm({ form: "laneCalculatorForm", enableReinitialize: true })(LaneCalculatorForm));
