import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { change } from "redux-form";
import { createStructuredSelector } from "reselect";
import { Row, Col } from "react-bootstrap";

import {
	getInventoryItems,
	checkInventoryDateLessThanCurrent,
	getInventoryType,
} from "store/selectors/ordersPackagingSelectors";
import { ToolTipCheckBox } from "utils/checkbox";
import InventoryTableBody from "./inventoryTableBody";
import { useTranslation } from 'react-i18next';

const InventoryTable = ({ data, checkInventoryDateLessThanCurrent, inventoryType, validate, editMode }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const [addAllPackage, setaddAllPackage] = useState(false);
	useEffect(() => {
		if (editMode) {
			dispatch(change("inventoryForm", `itemSelected[${0}].editable`, true));
		}
	}, [dispatch, editMode]);

	const tableHead = () => {
		return (
			<Row className="modal-table-head">
				{checkInventoryDateLessThanCurrent && (
					<Col className="modal-table-col" style={{ width: 35, flex: "inherit" }}>
						<ToolTipCheckBox
							tooltipText={t("tooltip.add_Add_to_packaging")}
							tooltipFontSize={11}
							tooltipWidth={140}
							checked={addAllPackage}
							bottomArrowLeftPosition={25}
							tooltipLeftPostion={43}
							onChange={(e) => {
								const arr = data.map((item) => {
									return {
										...item,
										addToPackage: addAllPackage ? false : true,
									};
								});
								dispatch(change("inventoryForm", `itemSelected`, arr));
								setaddAllPackage(addAllPackage ? false : true);
							}}
						/>
					</Col>
				)}
				<Col className="modal-table-head-col cont-wrap" style={{ flex: 1.2 }}>
					<span>{inventoryType === "UnSoldCases" ? t("label.sku") : t("label.product_variety")}</span>
				</Col>
				<Col className="modal-table-head-col cont-wrap" style={{ flex: checkInventoryDateLessThanCurrent ? 1.8 : 1.7 }}>
					<span>{inventoryType === "UnSoldCases" ? t("label.packed_cases") : t("label.unpacked_lbs")}</span>
				</Col>
			</Row>
		);
	};

	const tableBody = () => {
		return (
			<div className="modal-table-body">
				{data.map((item, index) => {
					return (
						<InventoryTableBody
							key={index}
							setaddAllPackage={setaddAllPackage}
							value={item.value}
							index={index}
							count={item.count}
							label={item.label}
							addToPackage={item.addToPackage}
							editable={item.editable}
							editMode={editMode}
						/>
					);
				})}
			</div>
		);
	};

	return (
		<div className="modal-table-wrapper">
			{tableHead()}
			{tableBody()}
			{validate && (
				<span className="error-text">{`${inventoryType === "UnSoldCases" ? t("label.packed_cases") : t("label.unpacked_lbss")} ${
					validate === "notBoth"
						? t("validation.is_required_is_number")
						: validate === "notNumber"
						? t("validation.should_be_number")
						: t("validation.is_required")
				}`}</span>
			)}
		</div>
	);
};

const mapStateToProps = createStructuredSelector({
	data: getInventoryItems,
	checkInventoryDateLessThanCurrent: checkInventoryDateLessThanCurrent,
	inventoryType: getInventoryType,
});

const areEqual = (prevProps, nextProps) => {
	return (
		prevProps.data === nextProps.data &&
		prevProps.checkInventoryDateLessThanCurrent === nextProps.checkInventoryDateLessThanCurrent &&
		prevProps.inventoryType === nextProps.inventoryType &&
		prevProps.validate === nextProps.validate
	);
};

export default connect(mapStateToProps)(React.memo(InventoryTable, areEqual));
