import { get } from "lodash";
import axios from "axios";

const rootURL = process.env.REACT_APP_ROOT_URL;
const LSVariable = process.env.REACT_APP_LS_VAR;

export default function fetchHandler(
	{ url, method = "GET", actionType, headers, body, secure = true, fileUpload = false },
	successHandler = null,
	errorHandler = null,
	type = "fetch",
	options = {}
) {
	return (dispatch, getState) => {
		const triggerSuccessHandler = (response) => {
			if (actionType) {
				dispatch({
					type: actionType,
					payload: response,
				});
			}
			return successHandler ? successHandler(response) : null;
		};

		const LSString = localStorage.getItem(LSVariable);
		const LSToken = LSString ? JSON.parse(LSString) : null;
		const stateToken = get(getState(), "Auth.accessToken", null);
		const accessToken = stateToken ? stateToken.access_token : LSToken ? LSToken.access_token : null;

		let headersData = {
			...headers,
			Accept: fileUpload ? "multipart/form-data" : "application/json",
			"Content-Type": fileUpload ? "multipart/form-data" : "application/json",
		};

		if (secure) {
			headersData = {
				...headersData,
				Authorization: `Bearer ${accessToken}`,
			};
		}

		if (type === "axios") {
			axios
				.post(`${rootURL}${url}`, body, {
					...options,
					headers: {
						...headersData,
					},
				})
				.then((res) => {
					return triggerSuccessHandler(res.data);
				})
				.catch((err) => {
					return errorHandler ? errorHandler(err.response.data) : null;
				});
		} else {
			const request = fetch(`${rootURL}${url}`, {
				method,
				headers: {
					...headersData,
				},
				body,
			});

			let status = null;
			request
				.then((data) => {
					status = data.status;
					return data.json();
				})
				.then((response) => {
					if (status && status > 399) {
						return errorHandler ? errorHandler(response) : null;
					} else {
						return triggerSuccessHandler(response);
					}
				})
				.catch((err) => {
					const errorObj = {
						error: {
							url: `${rootURL}${url}`,
							code: "FETCH_FAILED",
							message: err,
						},
					};
					return errorHandler ? errorHandler(errorObj) : null;
				});
			return {
				type: actionType,
				payload: request,
			};
		}
	};
}
