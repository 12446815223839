import React, { useEffect } from "react";
import { Breadcrumbs, ContentWrap } from "elements";
import Listing from "./listing";
import "../../index.scss";
import TableLoader from "utils/table/tableLoader";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { getLoading } from "store/selectors/forecastSelectors";
import { getLocationCustomersPF, setLoader, fetchLocationProductVaritiesPF } from "store/actions";
import { errorMessage } from "utils/errorMessage";

const SalesVsProductionByVariety = ({ loading, getLocationCustomersPF, setLoader, fetchLocationProductVaritiesPF, match }) => {
	const actualSales = match.path === "/production-vs-actual-sales-by-variety" ? true : false;

	useEffect(() => {
		setLoader();
		const successHandler = () => {
			fetchLocationProductVaritiesPF(setLoader(), errorHandler);
		};
		const errorHandler = (e) => {
			errorMessage(e);
		};
		getLocationCustomersPF(successHandler, errorHandler);
	}, [getLocationCustomersPF, setLoader, fetchLocationProductVaritiesPF]);

	return (
		<ContentWrap>
			<div className="d-flex align-items-center mb-3">
				<Breadcrumbs
					keyValue={actualSales ? "productionVsActualSales" : "productionVsSales"}
					showLabel={true}
					label={`Production Vs ${actualSales ? "Actual Sales" : "Sales"} By Product Variety`}
				/>
			</div>

			<div className="position-relative">
				<Listing actualSales={actualSales} />
				{loading && (
					<div className="table-loader-wrapper">
						<TableLoader />
					</div>
				)}
			</div>
		</ContentWrap>
	);
};

const mapStateToProps = createStructuredSelector({
	loading: getLoading,
});

export default connect(mapStateToProps, { getLocationCustomersPF, setLoader, fetchLocationProductVaritiesPF })(
	SalesVsProductionByVariety
);
