export const laneToCaseColumns = [
	{ name: "No. Of Lanes", slug: "no_of_lanes" },
	{ name: "Oz/board", slug: "oz_per_board" },
	{ name: "Grow Cycle", slug: "grow_cycle" },
];

export const pvColumns = [
	{ name: "Production Lbs", slug: "production_lbs" },
	{ name: "Sales Forecast lbs", slug: "sales_lbs" },
	{ name: "Production vs Sales Lbs", slug: "production_vs_sales" },
];
