import { COUNT } from "../types";
const initialState = {
	countDetails: [],
};
export default (state = initialState, action) => {
	switch (action.type) {
		case COUNT.FETCH_COUNT_DETAILS:
			return {
				...state,
				countDetails: action.payload ? action.payload.data : [],
			};

		default:
			return state;
	}
};
