import React from "react";
import { useSelector } from "react-redux";
import { ContentWrap, Breadcrumbs, BackButton } from "../../../elements";
import { connect } from "react-redux";
import CustomerForm from "./customerForm";
import CustomerView from "./customerView";
import { setCustomerViewRender } from "../../../store/actions";

const CustomerManage = ({ setCustomerViewRender, handleEdit, updatePermission, viewPageLoading }) => {
	const details = useSelector((state) => state.Customers);
	const renderType = details && details.renderDisplay && details.renderDisplay;
	const slugValue = details && details.customerDetails && details.customerDetails.slug;
	const prevRenderType = details && details.prevRenderDisplay && details.prevRenderDisplay;
	return (
		<ContentWrap>
			<div className="d-flex justify-content-between">
				<div className="d-flex justify-content-between mb-3">
					<BackButton onClick={() => setCustomerViewRender(prevRenderType)} />
					<Breadcrumbs
						keyValue="customer"
						showLabel={true}
						label={renderType === 3 ? "View" : renderType === 1 ? "Add" : "Edit"}
					/>
				</div>
				{renderType === 3 && updatePermission ? (
					<div>
						<button onClick={() => handleEdit(slugValue)} className="btn btn-cell btn-light btn-sm ml-2">
							<i className="icon icon-edit" />
						</button>
					</div>
				) : null}
			</div>

			{/* Render Body */}
			{renderType === 3 ? <CustomerView viewPageLoading={viewPageLoading} /> : <CustomerForm />}
		</ContentWrap>
	);
};
const mapDispatchToProps = { setCustomerViewRender };
export default connect(null, mapDispatchToProps)(CustomerManage);
