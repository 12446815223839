import Util from "../../../../utils/Util";
export const TaskDetailsView = [
	{
		label: "Trasnfer ID",
		accessor: "task_id",
		classes: "col-lg-3 col-md-4 col-sm-6 col-12",
		render: (task) => (task && task.transfer_request_id && task.transfer_request_id) || "-",
	},
	{
		label: "Recipient",
		accessor: "task_id",
		classes: "col-lg-3 col-md-4 col-sm-6 col-12",
		render: (task) => (task && task.location && task.location) || "-",
	},
	{
		label: "Data Of Request",
		accessor: "task_id",
		classes: "col-lg-3 col-md-4 col-sm-6 col-12",
		render: (task) => Util.formatMDY(task.request_date),
	},
	{
		label: "Status",
		accessor: "task_id",
		classes: "col-lg-3 col-md-4 col-sm-6 col-12",
		render: (task) => (task && task.status && task.status) || "-",
	},
	{
		label: "Comment",
		accessor: "task_id",
		classes: "col-lg-3 col-md-4 col-sm-6 col-12",
		render: (task) => (task && task.comments && task.comments) || "-",
	},
];


export const tableHeadersFullfillmentDetails = [
	{
		label: "Delivery Date",
		key: "delivery_date",
		type: "date-picker",
		editable: false,
	},
	{
		label: "Cases",
		key: "cases",
		type: "input",
		editable: true,
	},
	{
		label: "Pack date",
		key: "pack_date",
		type: "date-picker",
		editable: true,
	},
	{
		label: "Ship date",
		key: "ship_date",
		type: "date-picker",
		editable: true,
	},
	{
		label: "Best Buy date",
		key: "best_buy_date",
		type: "date-picker",
		editable: true,
	},
	{
		label: "Sender",
		key: "sender_location",
		type: "select-react",
		editable: true,
	},
];

export const tableHeadersRequestedDetails = [
	{
		label: "Total Required Cases",
		key: "cases_required",
		type: "input",
		editable: true,
	},
	{
		label: "Cases Remaining",
		key: "cases_remaining",
		type: "div",
		editable: true,
	},
	{
		label: "Delivery Date",
		key: "delivery_date",
		type: "date-picker",
		editable: true,
	},
];
