import React, { useState } from "react";
import { get } from "lodash";
import { connect, useSelector, useDispatch } from "react-redux";
import { ContentWrap, ButtonPrimary, Breadcrumbs } from "../../../elements";
import SeedsManage from "../manage/";
import { setSeedsViewRender, deleteSeed, fetchSeeds, fetchSeedDetails, fetchSeedChangeStatus } from "../../../store/actions";
import Listing from "../../../partials/listings";
import ConfirmModal from "../../../utils/confirmModal";
import { errorMessage } from "../../../utils/errorMessage";
import { successMessage } from "../../../utils/successMessage";
import { renderColumns } from "./helper";
import useModal from "../../../utils/useModal";
import "../manage/manage.scss";
import TableLoader from "utils/table/tableLoader";
import { useTranslation } from "react-i18next";
import { isEmpty } from "lodash";

const SeedsListing = ({ setSeedsViewRender, deleteSeed, fetchSeeds, doFetchSeedDetails, permissionDetails, allSeedDetails}) => {
	const { t } = useTranslation();
	const renderType = useSelector((state) => state.Seeds.renderDisplay);
	const [refetch, setRefetch] = useState(0);
	const [filter, setFilter] = useState([]);
	const [loading, setloading] = useState(false);
	const [exportFlag,setExportFlag] = useState(null);
	const [isDeletModalOpen, , showDeleteModal, closeDeleteModal, deleteModalProps] = useModal("deleteSeed");
	const dispatch = useDispatch();
	const handleEdit = (slug) => {
		setloading(true);
		const successHandler = () => onSuccess(2);
		doFetchSeedDetails(slug, successHandler, onError);
	};
	const handleView = (slug) => {
		setloading(true);
		const successHandler = () => onSuccess(3);
		doFetchSeedDetails(slug, successHandler, onError);
	};

	const onSuccess = (renderType) => {
		setloading(false);
		setSeedsViewRender(renderType);
	};
	const onError = (e) => {
		setloading(false);
		errorMessage(e);
	};
	const handleSeedStatus = (e, row = {}) => {
		const { slug } = row;
		setloading(true);
		const successHandler = (event) => {
			setloading(false);
			successMessage(event);
			setRefetch(refetch + 1);
		};
		const errorHandler = (event) => {
			setloading(false);
			errorMessage(event);
		};
		dispatch(fetchSeedChangeStatus({ seed_slug: slug, status: e }, successHandler, errorHandler));
	};
	const handleDeletePanel = (data) => showDeleteModal(0, data);
	const addToFilter = (e) => {
		let obj = [];
		e.forEach((item) => {
			return (obj =
				item && item.column
					? [
							...obj,
							{
								column_name: item.column.accessor,
								search: item.value,
							},
					  ]
					: obj);
		});
		setFilter(obj);
	};
	const columns = renderColumns({
		setSeedsViewRender,
		addToFilter,
		handleEdit,
		handleView,
		handleDeletePanel,
		permissionDetails,
		handleSeedStatus,
	});
	const onDeleteSeed = (id) => {
		const successHandler = (e) => {
			closeDeleteModal();
			successMessage(e);
			setRefetch(refetch + 1);
		};
		const errorHandler = (e) => {
			closeDeleteModal();
			errorMessage(e);
		};
		deleteSeed(deleteModalProps, successHandler, errorHandler);
	};
	const exportHandler = () =>{
		setExportFlag(true);
	}

	const exportHandlerFlag = () => {
		setExportFlag(false);
	};

	const addPermission =
		permissionDetails && permissionDetails.seeds && permissionDetails.seeds.permissions.create_seed
			? permissionDetails.seeds.permissions.create_seed
			: "";
	const listPermission =
		permissionDetails && permissionDetails.seeds && permissionDetails.seeds.permissions.list_seeds
			? permissionDetails.seeds.permissions.list_seeds
			: "";

	return (
		<>
			{renderType === 0 ? (
				<ContentWrap>
					<div className="d-flex align-items-center mb-3">
						<Breadcrumbs keyValue="seeds" showLabel={listPermission} label="List" />
						{!isEmpty(allSeedDetails) &&
							<ButtonPrimary className="export_btn" onClick={exportHandler}>
								<span>
								{t("label.export")}{" "}
									<i className="icon icon-export text-sm" style={{fontWeight:"bold"}}/>
								</span>
						    </ButtonPrimary>
			          	}
						<div>
							{addPermission ? (
								<ButtonPrimary onClick={() => setSeedsViewRender(1)}>
									<i className="icon icon-plus mr-2" />
									Add Seed
								</ButtonPrimary>
							) : (
								""
							)}
						</div>
					</div>
					<div className="position-relative">
						{listPermission ? (
							<Listing
								sort_name={"seed_name"}
								fetchAction={fetchSeeds}
								columns={columns}
								refetch={refetch}
								filter={filter}
								exportFlag={exportFlag} 
							    exportHandlerFlag={()=>{exportHandlerFlag()}}
							/>
						) : (
							""
						)}
						{listPermission && loading && (
							<div className="table-loader-wrapper">
								<TableLoader />
							</div>
						)}
					</div>

					<ConfirmModal
						show={isDeletModalOpen}
						onNo={closeDeleteModal}
						onYes={onDeleteSeed}
						message="Are you sure want to delete this seed?"
					/>
				</ContentWrap>
			) : (
				<SeedsManage handleEdit={handleEdit} />
			)}
		</>
	);
};
const mapStateToProps = (state) => {
	const { UI, Auth, Seed } = state;
	return {
		accessToken: get(Auth, "accessToken", null),
		mainPreloader: get(UI, "mainPreloader", null),
		renderDisplay: get(Seed, "renderDisplay", null),
		prevRenderDisplay: get(Seed, "prevRenderDisplay", null),
		editingUserDetails: get(Seed, "seedDetails", null),
		permissionDetails: get(Auth, "permissionDetails", null),
		allSeedDetails: state.Seeds.allSeedDetails,
	};
};

const mapDispatchToProps = { setSeedsViewRender, deleteSeed, fetchSeeds, doFetchSeedDetails: fetchSeedDetails };
export default connect(mapStateToProps, mapDispatchToProps)(SeedsListing);
