import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import ForecastForm from "../../../manage";
import {
	checkDaysCountEqual,
	getForecastColumns,
	getForecastData,
	getForecastEditable,
	getForecastFormForecast,
	getForecastFormLocation,
	getHarvestForecastPoundsViewAccess,
	getForecastForm,
} from "store/selectors/forecastSelectors";
import ForecastTable from "utils/forecastTable";
import { addForecast, setLoader, fetchHarvestForecastPoundsDataExport } from "store/actions";
import { isEmpty } from "lodash";
import moment from "moment";
import { errorMessage } from "utils/errorMessage";
import { ButtonPrimary } from "elements";

const Listing = ({
	data,
	fixedColumnData,
	columns,
	forecastEditable,

	location,
	forecast,
	setLoader,
	addForecast,

	checkDaysCountEqual,
	viewAccess,
	formValues,
	fetchHarvestForecastPoundsDataExport,
}) => {
	const exportHandler = () => {
		const values = formValues && formValues.values;
		const submitData = {
			location_slug: values.location_slug.value,
			exclude_wastage: values.exclude_wastage ? 1 : 0,
			date_from: moment(values.date[0]).format("YYYY-MM-DD"),
			date_to: moment(values.date[1]).format("YYYY-MM-DD"),
			pv_slugs: values.product_variety.map((item) => item.value),
			export: 1,
		};
		const successHandler = (e) => {
			e.data.export_url && window.open(e.data.export_url);
		};
		const errorHandler = (e) => {
			errorMessage(e);
		};
		fetchHarvestForecastPoundsDataExport(submitData, successHandler, errorHandler);
	};
	return (
		<div className="listing-wrapper" style={{ paddingBottom: !viewAccess && 50 }}>
			{viewAccess && (
				<>
					<ForecastForm
						initialValues={{ exclude_wastage: true }}
						type={2}
						exportHandler={exportHandler}
						getForcastData={data}
					/>

					{data ? (
						!isEmpty(data) ? (
							<>
								<div className="d-flex justify-content-end">
									<div className="export-btn">
										<ButtonPrimary type="submit" onClick={exportHandler}>
											<span>
												Export <i className="icon icon-export text-sm" style={{ fontWeight: "bold" }} />
											</span>
										</ButtonPrimary>
									</div>
								</div>
								{!checkDaysCountEqual && (
									<div className="d-flex justify-content-end">
										<p className="dates-missing-text">Data is not available for a few dates</p>
									</div>
								)}
								<div className="forecast-margin-style">
									<ForecastTable
										editable={forecastEditable}
										columns={columns}
										fixedColumnHeading="Date"
										initialData={data}
										dateFormat={"MM-DD-YYYY"}
										localeString={true}
										total
									/>
								</div>
							</>
						) : (
							<div className="no-results-found text-center">No Results Found</div>
						)
					) : null}
				</>
			)}
		</div>
	);
};

const mapStateToProps = createStructuredSelector({
	columns: getForecastColumns,
	data: getForecastData,
	forecastEditable: getForecastEditable,
	forecast: getForecastFormForecast,
	location: getForecastFormLocation,
	checkDaysCountEqual: checkDaysCountEqual,
	viewAccess: getHarvestForecastPoundsViewAccess,
	formValues: getForecastForm,
});
export default connect(mapStateToProps, { setLoader, addForecast, fetchHarvestForecastPoundsDataExport })(Listing);
