/* eslint-disable array-callback-return */
import { createSelectorCreator, defaultMemoize } from "reselect";
import { isEqual, isEmpty, values, keys } from "lodash";
import Util from "utils/Util";

const getWatchWire = (state) => state.WatchWire;

const getForm = (state) => state.form;

const getAuth = (state) => state.Auth;

const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);

export const getWatchWireForm = createDeepEqualSelector([getForm], (form) => (form.watchWireForm ? form.watchWireForm : null));

export const getWatchWireFormLocation = createDeepEqualSelector([getWatchWireForm], (form) =>
	form && form.values && form.values.location_slug ? form.values.location_slug : null
);
// export const getForecastColumns = createDeepEqualSelector([getWatchWire], (WatchWire) => WatchWire.forecastColumns);

export const getWatchWireCommodity = createDeepEqualSelector([getWatchWireForm], (form) =>
	form && form.values && form.values.commodity ? form.values.commodity : null
);
export const getTableData = createDeepEqualSelector([getWatchWire], (watchWire) =>
	watchWire && watchWire.invoiceDataTableData ? watchWire.invoiceDataTableData : null
);

export const getStartDate = createDeepEqualSelector([getWatchWireForm], (form) =>
	form && form.values && form.values.date && form.values.date[0] ? form.values.date[0] : null
);

export const getEndDate = createDeepEqualSelector([getWatchWireForm], (form) =>
	form && form.values && form.values.date && form.values.date[1] ? form.values.date[1] : null
);
// export const getColumns = createDeepEqualSelector([getWatchWireForm], (form) =>
// 	form && form.values && form.values.metric ? form.values.metric : null
// );

const format = (list, label, value, isDisabled) =>
		(!isEmpty(list) &&
			list.map((field) => ({
				name: field[label],
				slug: field[value],
				isdisabled: field[isDisabled],
			}))) ||
		[];

export const getColumns = createDeepEqualSelector(
	[getWatchWire],
	(watchWire) => watchWire && watchWire.columns && format(watchWire.columns, "label", "value") || []
);

export const getActiveTabCommodity = createDeepEqualSelector([getWatchWire], (WatchWire) => WatchWire.activeTabCommodity);

export const getActiveTabAccountIds = createDeepEqualSelector([getWatchWire], (WatchWire) => WatchWire.activeTabAccountsIds);

export const getDownload = createDeepEqualSelector([getWatchWire], (WatchWire) => WatchWire.downloadUrl);
export const getSubmittedObj = createDeepEqualSelector([getWatchWire], (WatchWire) => WatchWire.submitted);

export const getChartData = createDeepEqualSelector([getWatchWire], (WatchWire) => WatchWire.invoiceDataChartData);

export const getShowChartObj = createDeepEqualSelector([getWatchWire], (WatchWire) => WatchWire.showChart);

export const CommodityKeyLabel = createDeepEqualSelector([getTableData], (data) =>
	data && !isEmpty(data) ? tabKeys(keys(data)) : null
);

export const getTabsCommodity = createDeepEqualSelector([CommodityKeyLabel], (data) =>
	data && !isEmpty(data) ? values(data) : null
);

export const dcKeyLabel = createDeepEqualSelector(
	[getTableData, getActiveTabCommodity, CommodityKeyLabel],
	(data, customerKey, customerObj) =>
		data && customerKey && customerObj && !isEmpty(data) ? tabKeys(keys(data[customerObj[customerKey].label])) : null
);

export const getTabsDC = createDeepEqualSelector([dcKeyLabel], (data) => (data && !isEmpty(data) ? values(data) : null));

export const getLoading = createDeepEqualSelector([getWatchWire], (WatchWire) => WatchWire && WatchWire.loading);
export const getLocationList = createDeepEqualSelector(
	[getAuth],
	(auth) => Util.mapOptions(auth.userLocations, "name", "slug") || []
);

//get the list of commidity
export const getCommodityList = createDeepEqualSelector([getWatchWire, getWatchWireFormLocation], (watchWire, location) =>
	watchWire && watchWire.dropDownList && location
		? watchWire.dropDownList.filter((item) => item.location_slug === location.value) &&
		  watchWire.dropDownList.filter((item) => item.location_slug === location.value)[0] &&
		  watchWire.dropDownList.filter((item) => item.location_slug === location.value)[0].commodities &&
		  Util.mapOptions(
				watchWire.dropDownList.filter((item) => item.location_slug === location.value)[0].commodities,
				"commodity_display_name",
				"commodity_name"
		  )
		: []
);

const formatArray = (watchWire, commodity, location) => {
	const commodities =
		watchWire.dropDownList && location
			? watchWire.dropDownList.filter((item) => item.location_slug === location.value)[0].commodities
			: [];
	const accountsArray = [];
	commodity.map((commodityItem, index) => {
		const accountsIds =
			commodities && commodity ? commodities.filter((item) => item.commodity_name === commodityItem.value) : [];
		accountsIds &&
			accountsIds[0] &&
			accountsIds[0].account_details &&
			accountsIds[0].account_details.map((val) => accountsArray.push(val));
	});

	return accountsArray ? accountsArray : [];
};

export const getAccountsIds = createDeepEqualSelector(
	[getWatchWire, getCommodityList, getWatchWireCommodity, getWatchWireFormLocation],
	(watchWire, commoditylist, commodity, location) =>
		watchWire && commoditylist && commodity && location
			? Util.mapOptions(formatArray(watchWire, commodity, location), "account_code", "account_code")
			: []
);

const tabKeys = (data) => {
	const keys = {};
	// eslint-disable-next-line array-callback-return
	data.map((item) => {
		keys[item.replace(/\s/g, "")] = {
			key: item.replace(/\s/g, ""),
			label: item,
		};
	});

	return keys;
};

export const getTableDataSP = createDeepEqualSelector(
	[getTableData, getActiveTabCommodity, getActiveTabAccountIds, CommodityKeyLabel, dcKeyLabel],
	(data, customerKey, dcKey, customerObj, dcObj) =>
		data && customerKey && dcKey && customerObj && dcObj
			? data[customerObj[customerKey].label] &&
			  data[customerObj[customerKey].label][dcObj[dcKey].label] &&
			  data[customerObj[customerKey].label][dcObj[dcKey].label]
			: null
);

const TableViewdata = (data, key) => {
	const columns = [
		{
			name: "Usage",
			slug: "usage",
		},
		{
			name: "Cost",
			slug: "cost",
		},
	];

	const UlData = {};
	key &&
		key.map((item_i) => {
			// const obj = [];
			let obj1 = [];
			let obj2 = [];
			const tempObj = [];
			const lanes = data && data[item_i] && data[item_i];
			lanes &&
				lanes.map((item_lane, index) => {
					columns.map((col) => {
						if (col.name === "Usage") {
							obj1 = {
								name: "Usage",
								usage: item_lane.usage,
								usage_unit: item_lane.usage_unit,
								slug: col.slug,
							};
							tempObj.push(obj1);
						} else if (col.name === "Cost") {
							obj2 = {
								cost: item_lane.cost,
								name: "Cost",
								usage_unit: item_lane.usage_unit,
								slug: col.slug,
							};
							tempObj.push(obj2);
						}
					});
				});
			UlData[item_i] = tempObj;
		});

	return UlData;
};

export const TableViewkeyLabel = createDeepEqualSelector([getTableDataSP], (data) =>
	data && data && !isEmpty(data) ? TableViewdata(data, keys(data)) : null
);

export const getTableView = createDeepEqualSelector([TableViewkeyLabel], (data) => (data && !isEmpty(data) ? data : null));
