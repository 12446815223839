import { isEmpty, map, isArray, isNull, isString } from "lodash";
import { required, number } from "../../../constant/formValidators";

export const GreensField = (greensList = []) => ({
	label: "Greens Name *",
	name: "green",
	type: "select-react",
	placeholder: "Select Greens Name",
	isMultiple: false,
	options: greensList,
	disabled: isEmpty(greensList),
	validationsArr: required,
});
export const SeedsField = (seedsList = []) => ({
	label: "Seed Name *",
	name: "seeds",
	type: "select-react",
	placeholder: "Select Seed Name",
	isMultiple: true,
	options: seedsList,
	disabled: isEmpty(seedsList),
	validationsArr: required,
});

export const LocationField = (locationsList = []) => [
	{
		heading: "Location *",
		fields: [
			{
				name: "location",
				type: "select-react",
				placeholder: "Select Location",
				isMultiple: true,
				options: locationsList,
				disabled: isEmpty(locationsList),
				validationsArr: required,
			},
		],
	},
];

export const LunaField = () => [
	{
		heading: "Luna Crop Id",
		fields: [
			{
				label: "Luna Crop ID",
				name: "luna_crop_id",
				type: "text",
				placeholder: "Enter Luna Crop ID",
				validationsArr: [number],
			},
		],
	},
];

export const SeasonFields = (boardsList = []) => [
	{
		heading: "Product Information",
		fields: [
			{
				label: "Germination Period *",
				name: "germination_period",
				type: "text",
				placeholder: "Enter Germination Period",
				validationsArr: [required, number],
			},
			{
				label: "Grow Cycle *",
				name: "grow_cycle",
				type: "text",
				placeholder: "Enter Grow Cycle",
				validationsArr: [required, number],
			},
			{
				label: "Harvesting Period",
				name: "harvesting_period",
				type: "text",
				placeholder: "Enter Harvesting Period",
				validationsArr: number,
			},
			{
				label: "Shelflife Period",
				name: "shelflife_period",
				type: "text",
				placeholder: "Enter Shelflife Period",
				validationsArr: number,
			},
			{
				label: "Styrofoam Board *",
				name: "board",
				type: "select-react",
				placeholder: "Select Styrofoam Board",
				options: boardsList,
				disabled: isEmpty(boardsList),
				isMultiple: false,
				validationsArr: required,
			},
		],
	},
	{
		heading: "Germination Conditions",
		fields: [
			{
				label: "Germination Time *",
				name: "germination_time",
				type: "text",
				placeholder: "Enter Germination Time",
				validationsArr: [required, number],
			},
			{
				label: "Temperature (C) *",
				name: "germination_temperature",
				type: "text",
				placeholder: "Enter Temperature",
				validationsArr: [required, number],
			},
			{
				label: "Humidity (%) *",
				name: "germination_humidity",
				type: "text",
				placeholder: "Enter Humidity",
				validationsArr: [required, number],
			},
		],
	},
	{
		heading: "Growing Conditions",
		fields: [
			{
				label: "Temperature (C) *",
				name: "growing_temperature",
				type: "text",
				placeholder: "Enter Temperature",
				validationsArr: [required, number],
			},
			{
				label: "Humidity (%) *",
				name: "growing_humidity",
				type: "text",
				placeholder: "Enter Humidity",
				validationsArr: [required, number],
			},
			{
				label: "Light(Avg. Per/day) *",
				name: "growing_light",
				type: "text",
				placeholder: "Enter Avg. per/Day",
				validationsArr: [required, number],
			},
		],
	},
	{
		heading: "Pond Metrics",
		fields: [
			{
				label: "pH",
				name: "ph",
				type: "text",
				placeholder: "Enter pH",
				validationsArr: number,
			},
			{
				label: "EC",
				name: "ec",
				type: "text",
				placeholder: "Enter EC",
				validationsArr: number,
			},
			{
				label: "DO",
				name: "do",
				type: "text",
				placeholder: "Enter DO",
				validationsArr: number,
			},
			{
				label: "Pond Temperature (C) *",
				name: "pond_temperature",
				type: "text",
				placeholder: "Enter Pond Temperature",
				validationsArr: required,
			},
		],
	},
	{
		heading: "Price & Seed Density",
		fields: [
			{
				label: "Price Per Pound *",
				name: "price_per_pound",
				type: "text",
				placeholder: "Enter Price",
				validationsArr: [required, number],
			},
			{
				label: "Average Seed Density *",
				name: "seed_density",
				type: "text",
				placeholder: "Enter Seed Density",
				validationsArr: [required, number],
			},
		],
	},
];

export const constructSeedDetailsFormArr = (data, initialBoardvalue) => ({
	seed_name: data.seed_name,
	seed: data.slug,
	slug: data.slug,
	first_image: "",
	second_image: "",
	third_image: "",
	luna_crop_id: "",
	location: [],
	getActiveLocation: data?.locations,
	seasons: map(data.season_seed_details, (s) => constructSeasonsFormArr(s, initialBoardvalue)),
});

const constructSeasonsFormArr = (data, initialBoardvalue) => ({
	...data,
	germination_period: data.germination_period || "",
	grow_cycle: data.grow_cycle || "",
	harvesting_period: data.harvesting_period || "",
	shelflife_period: data.shelflife_period || "",
	seed_density: data.seed_density || "",
	board: isEmpty(initialBoardvalue) ? null : initialBoardvalue[0],
	germination_temperature: data.germination_temperature || "",
	germination_humidity: data.germination_humidity || "",
	germination_time: data.germination_time || "",
	growing_temperature: data.growing_temperature || "",
	growing_humidity: data.growing_humidity || "",
	growing_light: data.growing_light || "",
	ph: data.ph || "",
	ec: data.ec || "",
	do: data.do || "",
	pond_temperature: data.pond_temperature || "",
	price_per_pound: data.price_per_pound || "",
});

// Seed Details Constructor
export const seedDetailsForAdd = (s) => {
	const getBaseVal = (img = null) => (img ? img.base64 : null);
	return {
		...s,
		first_image: getBaseVal(s.first_image && s.first_image[0]) || null,
		second_image: getBaseVal(s.second_image && s.second_image[0]) || null,
		third_image: getBaseVal(s.third_image && s.third_image[0]) || null,
		luna_crop_id: (s.luna_crop_id && s.luna_crop_id["luna_crop_id"]) || null,
		location: map(s.location, "value"),
		seasons: map(s.seasons, (o) => ({
			...o,
			board: o.board.value || "",
		})),
	};
};

export const seedDetailsForEdit = (s) => {
	// arr = take base64
	// string =  remove key - do not pass
	// null = pass null
	const getBaseVal = (img = null) => (img ? img.base64 : null);
	let mappedObj = {
		...s,
		location: map(s.location, "value"),
		seasons: map(s.seasons, (o) => ({
			...o,
			board: o.board.value || "",
		})),
	};

	if (isArray(s.luna_crop_id) || isNull(s.luna_crop_id)) {
		mappedObj = {
			...mappedObj,
			luna_crop_id: getBaseVal(s.luna_crop_id && s.luna_crop_id[0]) || null,
		};
	} else if (isString(s.luna_crop_id)) {
		delete mappedObj.luna_crop_id;
	}

	// First Image
	if (isArray(s.first_image) || isNull(s.first_image)) {
		mappedObj = {
			...mappedObj,
			first_image: getBaseVal(s.first_image && s.first_image[0]) || null,
		};
	} else if (isString(s.first_image)) {
		delete mappedObj.first_image;
	}

	// Second Image
	if (isArray(s.second_image) || isNull(s.second_image)) {
		mappedObj = {
			...mappedObj,
			second_image: getBaseVal(s.second_image && s.second_image[0]) || null,
		};
	} else if (isString(s.second_image)) {
		delete mappedObj.second_image;
	}

	// Third Image
	if (isArray(s.third_image) || isNull(s.third_image)) {
		mappedObj = {
			...mappedObj,
			third_image: getBaseVal(s.third_image && s.third_image[0]) || null,
		};
	} else if (isString(s.third_image)) {
		delete mappedObj.third_image;
	}
	return mappedObj;
};
