import { required, number, requiredDateObj, isDateObj } from "../../../constant/formValidators";
export const formCreationFieldsTop = [
	{
		label: "label.location_required",
		name: "location",
		type: "select-react",
		placeholder: "label.select_location",
		doGetCall: true,
	},
	{
		label: "label.date_and_time_req",
		name: "date",
		type: "date-time-picker",
		placeholder: "label.select_date_and_time",
		doGetCall: true,
	},
	{
		label: "label.pond_required",
		name: "pond_number",
		type: "select-react-expand-all",
		placeholder: "label.select_pond",
	},
];
export const formCreationFieldsMiddle = [
	{
		label: "label.ph_required",
		name: "ph",
		type: "text",
		placeholder: "label.enter_ph",
	},
	{
		label: "label.ec_required",
		name: "ec",
		type: "text",
		placeholder: "label.enter_ec",
	},
	{
		label: "label.do_required",
		name: "do",
		type: "text",
		placeholder: "label.enter_do",
	},
	{
		label: "label.pond_temperature_required",
		name: "pond_temperature",
		type: "text",
		placeholder: "label.enter_temperature",
	},
	{
		label: "label.orp",
		name: "orp",
		type: "text",
		placeholder: "label.enter_orp",
	},
	{
		label: "sample",
		name: "sample",
		type: "hidden",
		value:10,
		placeholder: "Enter sample",
	},
];

export const getValidations = (field) => {
	switch (field) {
		case "location":
			return [required];
		case "pond_number":
			return [required];
		case "date":
			return [requiredDateObj, isDateObj];
		case "number":
			return [number];
		default:
			return [required, number];
	}
};

export const enumerateOptions = (locationPondLaneMappedArray, field) => {
	const newList =
		locationPondLaneMappedArray &&
		locationPondLaneMappedArray[field] > 0 &&
		Array(locationPondLaneMappedArray[field])
			.fill()
			.map((_, i) => ({
				label: "Pond " + (i + 1),
				value: (i + 1).toString(),
			}));

	return newList ? newList : [];
};
