import { isEmpty } from "lodash";
import { ORDERS_PACKAGING } from "../types";

const initialState = {
	renderType: 0,
	opViewLoader: false,
	packageDetails: {},
	cleaningTime: null,
	setUpTime: null,
	employeeCount: null,
	packroomPrecool: null,
	packageWaste: null,
	skus: null,
	locationSkus: null,
	locationProductVarieties: null,
	selectedLocation: null,
	locationCustomers: null,
	selectedLocationCustomer: null,
	selectedCustomerDC: null,
	selectedOrder: null,
	inventoryDetails: null,
	casesPerSku: null,
	activityLog: [],
	casesByCustomer: null,
	loading: false,
	shouldUpdateCases: false,
	locationSelected: null,
	packagingOrderLocation: null,
	packagingOrderExpDate: new Date(),
	packagingOrderData: null,
	current_order_and_package_slug:null,
	productVarieties:null,
	addStatus:false,
	inventoryTaskDetails:{},
	ordersAndPackagingList:[]
};

export default (state = initialState, action) => {
	switch (action.type) {
		case ORDERS_PACKAGING.GET_PACKAGE_DETAILS:
			return {
				...state,
				packageDetails: action.payload.data || {},
			};
		case ORDERS_PACKAGING.GET_CLEANING_TIME:
			return {
				...state,
				cleaningTime: action.payload.data ? action.payload.data : null,
			};
		case ORDERS_PACKAGING.GET_SET_UP_TIME:
			return {
				...state,
				setUpTime: action.payload.data ? action.payload.data : null,
			};
		case ORDERS_PACKAGING.LOAD_LOCATION_SKUS:
			return {
				...state,
				locationSkus: action.payload.data,
			};
		case ORDERS_PACKAGING.SELECT_LOCATION:
			return {
				...state,
				selectedLocation: action.payload,
			};
		case ORDERS_PACKAGING.LOCATION_CUSTOMERS:
			return {
				...state,
				locationCustomers: action.payload.data,
			};
		case ORDERS_PACKAGING.SELECTED_LOCATION_CUSTOMERS:
			return {
				...state,
				selectedLocationCustomer: action.payload,
			};
		case ORDERS_PACKAGING.SELECTED_LOCATION_CUSTOMERS_DC:
			return {
				...state,
				selectedCustomerDC: action.payload,
			};
		case ORDERS_PACKAGING.SELECTED_ORDER:
			const { payload } = action;
			const selLoc = payload.order_location
				? { label: payload.order_location.location_name, value: payload.order_location.slug }
				: null;
			const selCustomer = payload.customer_slug ? { label: payload.customer_name, value: payload.customer_slug } : null;
			const selDC = payload.dc_slug ? { label: payload.dc_name, value: payload.dc_slug } : null;
			return {
				...state,
				selectedOrder: payload,
				selectedLocation: selLoc,
				selectedLocationCustomer: selCustomer,
				selectedCustomerDC: selDC,
			};
		case ORDERS_PACKAGING.FETCH_LOCATION_PRODUCT_VARITIES:
			return {
				...state,
				locationProductVarieties: action.payload.data,
			};

		case ORDERS_PACKAGING.CHANGE_RENDER_TYPE:
			return {
				...state,
				renderType: action.payload,
			};

		case ORDERS_PACKAGING.TOGGLE_OP_VIEW_LOADER:
			return {
				...state,
				opViewLoader: action.payload,
			};
		case ORDERS_PACKAGING.FETCH_INVENTORY_DETAILS:
			const { data } = action.payload;
			if (data.length > 0) {
				const selFields = data.map((item) =>
					item.inventory === "UnSoldCases"
						? {
								label: item.sku_name,
								value: item.sku_slug,
						  }
						: {
								label: item.pv_name,
								value: item.pv_slug,
						  }
				);
				const selItems = data.map((item) => ({
					label: item.inventory === "UnSoldCases" ? item.sku_name : item.pv_name,
					value: item.inventory === "UnSoldCases" ? item.sku_slug : item.pv_slug,
					addToPackage: false,
					count: item.inventory === "UnSoldCases" ? item.unsold_cases : item.unpacked_lbs,
					editable: false,
					isdisabled: undefined,
				}));

				return {
					...state,
					inventoryDetails: { selectedFields: selFields, selectedItems: selItems },
				};
			} else {
				return state;
			}
		case ORDERS_PACKAGING.SET_INVENTORY_NULL:
			return {
				...state,
				inventoryDetails: null,
			};

		case ORDERS_PACKAGING.EMPLOYEE_COUNT:
			return {
				...state,
				employeeCount: !isEmpty(action.payload.data) ? action.payload.data : null,
			};

		case ORDERS_PACKAGING.PACKROOM_PRECOOL:
			return {
				...state,
				packroomPrecool: !isEmpty(action.payload.data) ? action.payload.data : null,
			};
		case ORDERS_PACKAGING.PACKAGE_WASTE:
			return {
				...state,
				packageWaste: !isEmpty(action.payload.data) ? action.payload.data : null,
			};

		case ORDERS_PACKAGING.CASES_PER_SKU:
			return {
				...state,
				casesPerSku: !isEmpty(action.payload.data) ? action.payload.data : null,
			};

		case ORDERS_PACKAGING.GET_ORDERS_PACKAGING_TASK_AUDIT_LOG:
			return {
				...state,
				activityLog: (action.payload && action.payload.data) || [],
			};

		case ORDERS_PACKAGING.CASES_BY_CUSTOMER:
			const cases =
				action.payload.data && action.payload.data.customer_cases
					? action.payload.data.customer_cases.map((item) => ({
							...item,
							updated_cases: item.updated_cases ? item.updated_cases : item.possible_cases,
					  }))
					: null;

			return {
				...state,
				casesByCustomer: action.payload.data ? { ...action.payload.data, customer_cases: cases } : null,
			};

		case ORDERS_PACKAGING.UPDATE_CASES_BY_CUSTOMER:
			return {
				...state,
				casesByCustomer: action.payload,
			};

		case ORDERS_PACKAGING.LOADING:
			return {
				...state,
				loading: !state.loading,
			};

		case ORDERS_PACKAGING.SHOULD_UPDATE_CASES:
			return {
				...state,
				shouldUpdateCases: !state.shouldUpdateCases,
			};

		case ORDERS_PACKAGING.RESET_LISTING:
			return {
				...initialState,
				locationSelected: state.locationSelected,
			};

		case ORDERS_PACKAGING.LOCATION_SELECT:
			return {
				...state,
				locationSelected: action.payload,
			};

		case ORDERS_PACKAGING.PACKAGING_ORDER_LOCATION:
			return {
				...state,
				packagingOrderLocation: action.payload,
			};
		case ORDERS_PACKAGING.PACKAGING_ORDER_EXP_DATE:
			return {
				...state,
				packagingOrderExpDate: action.payload,
			};
		case ORDERS_PACKAGING.PACKAGING_ORDER_DATA:
			return {
				...state,
				packagingOrderData: action.payload.data,
			};
		case ORDERS_PACKAGING.CURRENT_ORDER_PACKAGE_SLUG:
			return{
				...state,
				current_order_and_package_slug:action.payload
			}
		case ORDERS_PACKAGING.FETCH_LOCATION_PRODUCTS_OP:
			return{
				...state,
				productVarieties:action.payload
			};
			case ORDERS_PACKAGING.ADD_STATUS:
				return{
					...state,
					addStatus:action.payload
				};
		case ORDERS_PACKAGING.GET_INVENTORY_DETAILS:
			return{
				...state,
				inventoryTaskDetails:action.payload.data || {},
			}
		case ORDERS_PACKAGING.GET_ORDERS_PACKAGING_TASKS_LIST:
			return {
				...state,
				ordersAndPackagingList: action.payload.data || [],
			}

		default: {
			return state;
		}
	}
};
