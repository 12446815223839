import fetchHandler from "utils/fetchHandler";
import { DOSING_CALC } from "../types";

export const setLoaderDosingCalc = () => {
	return (dispatch) => {
		dispatch({
			type: DOSING_CALC.LOADER_DOSING,
		});
	};
};

export const fetchDropdownDataDosingCalc = (successHandler, errorHandler) => {
	const fetchOptions = {
		url: `dosing-filter-data`,
		method: "GET",
		secure: true,
		actionType: DOSING_CALC.SET_DROPDOWN_DOSING,
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const setRenderTypeDosingCalc = (value) => {
	return (dispatch) => {
		dispatch({
			type: DOSING_CALC.SET_RENDER_TYPE_DOSING,
			payload: value,
		});
	};
};

export const fetchDataDosingCalc = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `get-dosing-calculator`,
		method: "POST",
		secure: true,
		actionType: DOSING_CALC.SET_DATA_DOSING,
		body: JSON.stringify(values),
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const calculateData = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `dosing-calculate`,
		method: "POST",
		secure: true,
		body: JSON.stringify(values),
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const changePv = (value) => {
	return (dispatch) => {
		dispatch({
			type: DOSING_CALC.CHANGE_PV,
			payload: value,
		});
	};
};

export const onResetData = () => {
	return (dispatch) => {
		dispatch({
			type: DOSING_CALC.RESET_DATA_DOSING,
		});
	};
};

export const saveData = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `save-dosing-calculator`,
		method: "POST",
		secure: true,
		actionType: DOSING_CALC.SAVE_DATA_DOSING,
		body: JSON.stringify(values),
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const updateCalculateData = (value,active) => {
	return (dispatch) => {
		dispatch({
			type: DOSING_CALC.PPM_UPDATE,
			payload: value,
			statusSaving:active
		});
	};
};

export const setDosingEditData = (value) => {
	return (dispatch) => {
		dispatch({
			type: DOSING_CALC.EDIT_DATA_DOSING,
			payload: value,
		});
	};
};

export const editDosingData = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `edit-dosing-calculator`,
		method: "PUT",
		secure: true,
		actionType: DOSING_CALC.DONE_EDIT_DATA_DOSING,
		body: JSON.stringify(values),
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const cancelDosingEdit = () => {
	return (dispatch) => {
		dispatch({
			type: DOSING_CALC.DONE_EDIT_DATA_DOSING,
		});
	};
};

export const setActiveTabPond = (value, type) => {
	return (dispatch) => {
		dispatch({
			type: DOSING_CALC.SET_ACTIVE_TAB_POND,
			payload: value,
		});
	};
};


export const resetAll = (value, type) => {
	return (dispatch) => {
		dispatch({
			type: DOSING_CALC.RESET_ALL,
			payload: value,
		});
	};
};

