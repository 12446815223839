import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import UIDocumentation from "../components/uiDocs";
import HarvestForecastV2 from "../components/harvestForecastV2";
import Dashboard from "../components/dashboard";
import UserListing from "../components/users/listing";
import ViewUser from "../components/users/view";
import BoardsListing from "../components/boards/listing";
import SeedsListing from "../components/seeds/listing";
import Locations from "../components/locations/listing";
import GreensListing from "../components/greens/listing";
import ProductVarietyListing from "../components/productVariety/listing";
import StockKeeping from "../components/sku/listing";
import SeedingGermination from "../components/seedingGermination";
import CustomerListing from "../components/customer/listing";
import Transplant from "../components/transplant/listing";
import HarvestListing from "../components/harvesting/listing";
import AcessTable from "../components/acessTable";
import OrdersPackaging from "components/ordersPackaging";
import Reports from "components/reports";
import Climate from "components/climateReports";
import ProductionForecast from "components/forecast/productionForecast/forecast";
import HarvestForecastPounds from "components/forecast/productionForecast/harvestForecastPounds";
import ProductionForecastCases from "components/forecast/productionForecast/productionForecastCases";
import SalesVsProduction from "components/forecast/salesVsProduction/bySku";
import DailyForecast from "components/forecast/salesForecast/dailyForecast";
import WeeklyForecast from "components/forecast/salesForecast/weeklyForecast";
import ClimateTable from "components/climateTable";
import SalesVsProductionByVariety from "components/forecast/salesVsProduction/byProductVariety";
import Germination from "../components/germination";
import Croptimals from "components/croptimals";
import WaterSource from "components/water-source-results";
import Fertilizers from "components/fertilizers";
import ToAddList from "components/toAddList";
import DosingCalculator from "components/dosingCalculator";
import LabResults from "components/lab-results";
import crossSupply from "../components/crossSupply";

import LaneToCase from "components/laneCalculator/laneToCase";
import LaneCalculator from "components/laneCalculator/productVsSales";
import CaseToLane from "components/laneCalculator/caseToLane";
import watchWire from "components/watchWire";
import allGreenHouses from "components/allGreenHouses";
import greenForecastV2 from "components/greenForecastV2";
import productVarietyForecastV2 from "components/productVarietyForecastV2";
import growerTools from "components/growerTools";
import growerToolsTable from "components/growerToolsTable";
import growerToolsHarvest from "components/growerToolsHarvest";
//import PrivaReports from "components/privaReports";

//import Util from "../utils/Util";
//import { routingArray } from "./helper";

export const MainRouter = ({ userData, location, permissionData }) => {
	return userData && userData.id ? (
		<Switch location={location}>
			{/* {routingArray.map((item, i) => (
				<Route key={i} exact path={item.url} component={item.name} />
			))} */}

			<Route exact path="/styleguide" component={UIDocumentation} />
			<Route exact path="/gh/grower/:slug" component={growerTools} />
			<Route exact path="/gh/grower-table/:slug" component={growerToolsTable} />
			<Route exact path="/gh/grower-harvest/:slug" component={growerToolsHarvest} />
			<Route exact path="/gh/grower-harvest/:slug/:date" component={growerToolsHarvest} />
			<Route exact path="/gh/all" component={allGreenHouses} />
			<Route exact path="/gh/:slug" component={HarvestForecastV2} />
			{/* <Route exact path="/gh/:slug/:green_slug" component={greenForecastV2} /> */}
			{/* <Route exact path="/gh/:slug/:green_slug/:product_variety_slug" component={productVarietyForecastV2} /> */}
			<Route exact path="/dashboard" component={Dashboard} />
			<Route exact path="/users" component={UserListing} />
			<Route exact path="/users/:id/details" component={ViewUser} />
			<Route exact path="/styrofoams" component={BoardsListing} />
			<Route exact path="/access-privileges" component={AcessTable} />
			<Route exact path="/seeds" component={SeedsListing} />
			<Route exact path="/greens" component={GreensListing} />
			<Route exact path="/locations" component={Locations} />
			<Route exact path="/product-varieties" component={ProductVarietyListing} />
			<Route exact path="/sku" component={StockKeeping} />
			<Route exact path="/seeding-and-germination" component={SeedingGermination} />
			<Route exact path="/customers" component={CustomerListing} />
			<Route exact path="/transplant" component={Transplant} />
			<Route exact path="/harvesting" component={HarvestListing} />
			<Route exact path="/reports" component={Reports} />
			<Route exact path="/orders-and-packaging" component={OrdersPackaging} />
			<Route exact path="/climate" component={Climate} />
			<Route exact path="/forecast" component={ProductionForecast} />
			<Route exact path="/harvest-forecast-pounds" component={HarvestForecastPounds} />
			<Route exact path="/production-forecast-cases" component={ProductionForecastCases} />
			<Route exact path="/production-vs-sales-by-sku" component={SalesVsProduction} />
			<Route exact path="/daily-forecast" component={DailyForecast} />
			<Route exact path="/weekly-forecast" component={WeeklyForecast} />
			<Route exact path="/climate-table" component={ClimateTable} />
			<Route exact path="/production-vs-sales-by-variety" component={SalesVsProductionByVariety} />
			<Route exact path="/germination" component={Germination} />
			<Route exact path="/croptimals" component={Croptimals} />
			<Route exact path="/water-source-results" component={WaterSource} />
			<Route exact path="/fertilizers" component={Fertilizers} />
			<Route exact path="/to-add-list" component={ToAddList} />
			<Route exact path="/dosing-calculator" component={DosingCalculator} />
			<Route exact path="/lab-results" component={LabResults} />
			<Route exact path="/production-vs-actual-sales-by-sku" component={SalesVsProduction} />
			<Route exact path="/production-vs-actual-sales-by-variety" component={SalesVsProductionByVariety} />
			<Route exact path="/cross-supply" component={crossSupply} />
			<Route exact path="/cross-supply-shipping" component={crossSupply} />
			<Route exact path="/lane-calculator" component={LaneCalculator} />
			<Route exact path="/lane-to-case" component={LaneToCase} />
			<Route exact path="/case-to-lane" component={CaseToLane} />
			<Route exact path="/watch_wire" component={watchWire} />
			{/* <Route exact path="/priva" component={PrivaReports} /> */}

			<Redirect to="/gh/all" />
		</Switch>
	) : null;
};
