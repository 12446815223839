import React, { useState } from "react";
import { ContentWrap, ButtonPrimary, ListingBtn } from "elements";
import { connect } from "react-redux";
import { DropdownButton, Dropdown, Button } from "react-bootstrap";
import {
	fetchOrdersList,
	deleteOrder,
	changeOrdersRenderType,
	getPackageDetails,
	selectOrder,
	locationSelect,
	setPackagingOrderLocation,
} from "store/actions";
import useModal from "utils/useModal";
import Listing from "partials/listings";
import { renderColumns } from "./helper";
import DailyTasks from "../tasks";
import OrdersManage from "../manage";
import { successMessage } from "utils/successMessage";
import { errorMessage } from "utils/errorMessage";
import TableLoader from "utils/table/tableLoader";
import ConfirmModal from "utils/confirmModal";
import { OrdersPackagingBreadcrumb } from "../ordersPackagingComponents";
import {
	getAddAccess,
	getCasesByCustomerAccess,
	getCasesPerSkuAccess,
	getCreateInventoryAccess,
	getDailyTaskAccess,
	getDeleteAccess,
	getListAccess,
	getLocationList,
	getLocationSelected,
	getPackagingOrderViewAccess,
	getRenderType,
	getSkuRecipeAccess,
	getUpdateAccess,
	getViewAccess,
	getPackageList
} from "store/selectors/ordersPackagingSelectors";
import { createStructuredSelector } from "reselect";
import Util from "utils/Util";
import moment from "moment";
import { reject,isEmpty,find } from "lodash";
import { LocationDropdown } from "components/bulkAssignee/bulkAssigneeComponents";
import TaskView from "../taskView";
import { useTranslation } from "react-i18next";

const OrdersPackingListing = ({
	fetchOrdersList,
	deleteOrder,
	changeOrdersRenderType,
	getPackageDetails,
	selectOrder,
	permissionDetails,
	listAccess,
	addAccess,
	updateAccess,
	deleteAccess,
	viewAccess,
	createInventoryAccess,
	casesPerSkuAccess,
	dailyTaskAccess,
	skuRecipeAccess,
	casesByCustomerAccess,
	locations,
	locationSelect,
	// filterLocation,
	setPackagingOrderLocation,
	viewPackagingOrderPermission,
	renderType,
	ordersAndPackagingList
}) => {
	const { t } = useTranslation();
	const [refetch, setRefetch] = useState(3);
	const [loading, setloading] = useState(false);
	const [filterLocation, setFilterLocation] = useState(null);

	const [isTaskOpen, , showTasksModal] = useModal("task");
	const [isAddEditOpen, , showAddEditModal] = useModal("orders-add-edit");
	const tomorrow = Util.getYMDFormat(moment().add(1, "days"));

	const [filter, setFilter] = useState([{ column_name: "date_of_delivery", search: tomorrow }]);
	const [dailyFilter, setDailyFilter] = useState([]);
	const [deleteItem, setdeleteItem] = useState(false);
	const [exportFlag,setExportFlag] = useState(null);

	// Filter
	const addToFilter = (e) => {
		let obj = [];
		e.forEach((item) => {
			return (obj =
				item && item.column
					? [
							...obj,
							{
								column_name: item.column.accessor,
								search: item.value,
							},
					  ]
					: obj);
		});
		const locationFilter = find(filter, ["column_name", "location_slug"]);
		locationFilter ? setFilter([...obj, locationFilter]) : setFilter(obj);
		const locationDailyFilter = find(dailyFilter, ["column_name", "location_slug"]);
		locationDailyFilter && setDailyFilter([locationDailyFilter]);
	};

	const handleEditPanel = (item) => {
		selectOrder(item);
		showAddEditModal(2);
	};

	const handleViewPanel = (row) => {
		setloading(true);
		const onSuccess = () => {
			setloading(false);
			changeOrdersRenderType(1);
		};
		const onError = (e) => {
			setloading(false);
			errorMessage(e);
		};
		getPackageDetails(row.slug, onSuccess, onError);
	};
	const handleDeletePanel = (data) => {
		setdeleteItem(data);
	};

	const onDeleteOrder = () => {
		const successHandler = (e) => {
			setdeleteItem(null);
			successMessage(e);
			setRefetch(refetch + 1);
		};
		const errorHandler = (e) => {
			setdeleteItem(null);
			errorMessage(e);
		};
		deleteOrder(deleteItem, successHandler, errorHandler);
	};

	const columns = renderColumns({
		addToFilter,
		handleEditPanel,
		handleViewPanel,
		handleDeletePanel,
		deleteAccess,
		updateAccess,
		viewAccess,
	});
	const onSelectFilterLocation = (location = null) => {
		if (location) {
			const obj = {
				column_name: "location_slug",
				search: location.value || "",
			};
			setDailyFilter([obj]);
			const rejectListArr = reject(filter, ["column_name", "location_slug"]);
			setFilter([...rejectListArr, obj]);
		} else {
			const rejectArr = reject(filter, ["column_name", "location_slug"]);
			setFilter(rejectArr);
			const rejectDailyArr = reject(dailyFilter, ["column_name", "location_slug"]);
			setDailyFilter(rejectDailyArr);
		}
        setFilterLocation(location);
		locationSelect(location);
	};

	const handleHarvestView = () => {
		if (filterLocation) {
			setPackagingOrderLocation(filterLocation);
		}
		changeOrdersRenderType(3);
	};

	const exportHandler = () => {
		setExportFlag(true);
	};
	const exportHandlerFlag = () => {
		setExportFlag(false);
	};

	return (
		<ContentWrap>
			<div className="position-relative orders-page">
				<div className="d-lg-flex align-items-center mb-3">
					<OrdersPackagingBreadcrumb view={renderType} goBackToListing={() => changeOrdersRenderType(0)} />
					<div className="d-md-flex my-md-3 my-lg-0">
						<div className="d-sm-flex my-3 my-md-0">
							{!isEmpty(locations) && (
							<LocationDropdown value={filterLocation} options={locations} onSelect={onSelectFilterLocation} />
							)}
							{renderType === 0 && casesByCustomerAccess && (
								<ListingBtn
									tooltipText={t("tooltip.case_by_customer")}
									iconClass="icon icon-cases-by-customer"
									btnClass="btn btn-cell btn-light inventory-btn"
									tooltipWidth={150}
									onClick={() => showTasksModal(8)}
								/>
							)}
							{renderType === 0 && createInventoryAccess && (
								<ListingBtn
									tooltipText={t("tooltip.inventory")}
									iconClass="icon icon-Inventory"
									btnClass="btn btn-cell btn-light inventory-btn"
									onClick={() => showTasksModal(6)}
								/>
							)}
							{renderType === 0 && createInventoryAccess && (
								<ListingBtn
									tooltipText={t("tooltip.cases_per_sku")}
									iconClass="icon icon-cases-per-sku"
									btnClass="btn btn-cell btn-light cases-btn"
									tooltipWidth={120}
									onClick={() => showTasksModal(7)}
								/>
							)}
						</div>
						{renderType === 0 && (
							<DropdownButton
								variant=""
								className="btn-dropdown my-3 my-md-0 orders-daily-task-dropdown"
								title={t("label.daily_task")}>
								{dailyTaskAccess && (
									<>
										<Dropdown.Item as="button" onClick={() => showTasksModal(1)}>
											{t("label.no_of_employees")}
										</Dropdown.Item>

										<Dropdown.Item as="button" onClick={() => showTasksModal(2)}>
											{t("label.pack_room_or_pre_cool_details")}
										</Dropdown.Item>
										<Dropdown.Item as="button" onClick={() => showTasksModal(3)}>
											{t("label.log_set_up_time")}
										</Dropdown.Item>
										<Dropdown.Item as="button" onClick={() => showTasksModal(4)}>
											{t("label.log_cleaning_time")}
										</Dropdown.Item>
									</>
								)}
								{skuRecipeAccess && (
									<Dropdown.Item as="button" onClick={() => changeOrdersRenderType(2)}>
										{t("label.change_sku_recipe")}
									</Dropdown.Item>
								)}
								{dailyTaskAccess && (
									<>
										<Dropdown.Item as="button" onClick={() => showTasksModal(5)}>
											{t("label.packaging_waste")}
										</Dropdown.Item>
										<Dropdown.Item as="button" onClick={() => showTasksModal(9)}>
											{t("label.pack_rate_details")}
										</Dropdown.Item>
										<Dropdown.Item as="button" onClick={() => showTasksModal(10)}>
											{t("label.product_temperature_details")}
										</Dropdown.Item>
									</>
								)}
								{dailyTaskAccess && (
									<>
										<Dropdown.Item as="button" onClick={() => changeOrdersRenderType(4)}>
											{t("label.view_set_up_time")}
										</Dropdown.Item>
										<Dropdown.Item as="button" onClick={() => changeOrdersRenderType(5)}>
											{t("label.view_cleaning_time")}
										</Dropdown.Item>
										<Dropdown.Item as="button" onClick={() => changeOrdersRenderType(6)}>
											{t("label.view_packaging_waste_by_sku")}
										</Dropdown.Item>
										<Dropdown.Item as="button" onClick={() => changeOrdersRenderType(7)}>
											{t("label.view_packaging_waste_by_variety")}
										</Dropdown.Item>
									</>
								)}
								<Dropdown.Item as="button" onClick={() => changeOrdersRenderType(8)}>
									{t("label.view_packed_cases")}
								</Dropdown.Item>
								<Dropdown.Item as="button" onClick={() => changeOrdersRenderType(9)}>
									{t("label.view_unpacked_lbs")}
								</Dropdown.Item>
								<Dropdown.Item as="button" onClick={() => changeOrdersRenderType(10)}>
									{t("label.view_pack_rate_details")}
								</Dropdown.Item>
								<Dropdown.Item as="button" onClick={() => changeOrdersRenderType(11)}>
									{t("label.view_pack_room_or_pre_cool_details")}
								</Dropdown.Item>
								<Dropdown.Item as="button" onClick={() => changeOrdersRenderType(12)}>
									{t("label.view_product_temperature_details")}
								</Dropdown.Item>
							</DropdownButton>
						)}
						{renderType === 0 && viewPackagingOrderPermission && (
							<Button className="header-order-btn" variant="" onClick={handleHarvestView}>
								{t("label.packaging_order")}
							</Button>
						)}
						{!isEmpty(ordersAndPackagingList) && !(renderType === 4) && 
						   !(renderType === 5) && !(renderType === 6) && !(renderType === 7) && 
						   !(renderType === 8) && !(renderType === 9) && !(renderType === 10) &&
						   !(renderType === 11) && !(renderType === 12) &&
							<ButtonPrimary className="export_btn btn-fixed-height" onClick={exportHandler}>
								<span>
								{t("label.export")} <i className="icon icon-export text-sm" style={{ fontWeight: "bold" }} />
								</span>
							</ButtonPrimary>
						}
						{renderType === 0 && addAccess && (
							<div>
								<ButtonPrimary className="order-btn btn-fixed-height" onClick={() => showAddEditModal(1)}>
									<i className="icon icon-plus mr-2" />
									{t("label.new_order")}
								</ButtonPrimary>
							</div>
						)}
					</div>
				</div>
				<div className="position-relative">
					{renderType === 0 ? (
						listAccess && (
							<Listing
								sort_name={"order_id"}
								fetchAction={fetchOrdersList}
								columns={columns}
								refetch={refetch}
								filter={filter}
								defaultDate={tomorrow}
								exportFlag={exportFlag}
								exportHandlerFlag={exportHandlerFlag}
							/>
						)
					) : (
						<TaskView showTaskModal={showTasksModal} addToFilter={addToFilter} dailyFilter={dailyFilter} />
					)}
				</div>
				{loading && (
					<div className="table-loader-wrapper">
						<TableLoader />
					</div>
				)}
				<ConfirmModal
					show={deleteItem ? true : false}
					onNo={() => setdeleteItem(null)}
					onYes={onDeleteOrder}
					message={t("confirm.delete_task")}
				/>
				{isTaskOpen && <DailyTasks />}
				{isAddEditOpen && <OrdersManage onAddOrEdit={() => setRefetch(refetch + 1)} />}
			</div>
		</ContentWrap>
	);
};

const mapStateToProps = createStructuredSelector({
	listAccess: getListAccess,
	viewAccess: getViewAccess,
	addAccess: getAddAccess,
	updateAccess: getUpdateAccess,
	deleteAccess: getDeleteAccess,
	createInventoryAccess: getCreateInventoryAccess,
	casesPerSkuAccess: getCasesPerSkuAccess,
	dailyTaskAccess: getDailyTaskAccess,
	skuRecipeAccess: getSkuRecipeAccess,
	casesByCustomerAccess: getCasesByCustomerAccess,
	locations: getLocationList,
	filterLocation: getLocationSelected,
	viewPackagingOrderPermission: getPackagingOrderViewAccess,
	renderType: getRenderType,
	ordersAndPackagingList:getPackageList
});

export default connect(mapStateToProps, {
	fetchOrdersList,
	deleteOrder,
	changeOrdersRenderType,
	getPackageDetails,
	selectOrder,
	locationSelect,
	setPackagingOrderLocation,
})(OrdersPackingListing);
