import React, { useEffect, useState } from "react";
import { Button, Row, Col, Form } from "react-bootstrap";
import { connect, useDispatch } from "react-redux";
import { reduxForm, Field, change, destroy, initialize } from "redux-form";
import { createStructuredSelector } from "reselect";
import {
	getFormToDate,
	getLocationList,
	getFormFromDate,
	getThirtyWeeks,
	getPondList,
	// getDosingCalcEditFormInitials,
	getDosingCalcEditMode,
	getCurrentPondId,
	getPondTabData,
} from "store/selectors/dosingCalcSelectors";

import FormGroups from "utils/formGroup";
import { dosingCalcFields } from "./formHelper";

import moment from "moment";
import { errorMessage } from "utils/errorMessage";
import Util from "utils/Util";
import { setLoaderDosingCalc, fetchDataDosingCalc, resetAll } from "store/actions";
import { keys } from "lodash";

const DosingCalcForm = ({
	handleSubmit,
	reset,
	submitting,
	pristine,
	locationList,
	fromDate,
	toDate,
	maxDate,
	pondList,
	setLoaderDosingCalc,
	fetchDataDosingCalc,
	editMode,
	initialValues,
	currentPondId,
	ponds,
	resetAll,
}) => {
	const dispatch = useDispatch();
	// const [updated, setUpdated] = useState(false);
	const [startDate, setStartDate] = useState(editMode && initialValues.week ? initialValues.week[0] : null);

	const [endDate, setEndDate] = useState(editMode && initialValues.week ? initialValues.week[1] : null);

	const onSubmit = (values) => {
		// clear previousely selected form values
		const key = keys(ponds);
		key.forEach((item) => {
			dispatch(initialize(`dosingCalculatorTable_fertilizer_${ponds[item].pond_id}`));
			dispatch(initialize(`dosingCalculatorTable_results${ponds[item].pond_id}`));
		});
		dispatch(destroy(`calculateForm`));
		resetAll();
		setLoaderDosingCalc();
		const submitData = {
			location_slug: values.location.value,
			pond: values.pond.map((item) => item.value),
			date: Util.getMDYFormatUtcDate(values.week[0]),
		};
		const successHandler = (e) => {
			setLoaderDosingCalc();
		};
		const errorHandler = (e) => {
			errorMessage(e);
			setLoaderDosingCalc();
		};
		fetchDataDosingCalc(submitData, successHandler, errorHandler);
	};

	const getOptions = (name) => {
		switch (name) {
			case "location":
				return locationList;
			case "pond":
				return pondList;
			default:
				return [];
		}
	};

	const onDateChange = (dates) => {
		const [start] = dates;

		if (dates) {
			const first = new Date(moment(start).startOf("week"));
			const last = new Date(moment(start).endOf("week"));

			setStartDate(first);
			setEndDate(last);
		} else {
			setStartDate(null);
			setEndDate(null);
		}
	};

	useEffect(() => {
		if (fromDate) {
			const first = new Date(moment(fromDate).startOf("week"));
			const last = new Date(moment(fromDate).endOf("week"));
			dispatch(change("dosingCalcForm", "week", [first, last]));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [fromDate]);

	const onSelect = (event, newValue, previousValue, name) => {
		if (name === "location") {
			if (previousValue !== undefined) {
				dispatch(change("dosingCalcForm", "pond", []));
			}
		}
	};

	const resetHandler = () => {
		const key = keys(ponds);
		key.forEach((item) => {
			dispatch(destroy(`dosingCalculatorTable_fertilizer_${ponds[item].pond_id}`));
			dispatch(destroy(`dosingCalculatorTable_results${ponds[item].pond_id}`));
		});
		dispatch(destroy(`calculateForm`));
		resetAll();
		reset();
		setStartDate(null);
		setEndDate(null);
	};

	const getFormFieldHtml = (formfields) => {
		let formFieldHtml = [];

		formFieldHtml = formfields.map((field, idx) => {
			return (
				<React.Fragment key={idx}>
					<Col className="croptimals-form-column">
						<Form.Group
							// className={field.name === "exclude_wastage" && "mt-5"}
							controlId={`formControl ${field.name}`}>
							{field.name === "week" ? (
								<Field
									name={field.name}
									type={field.type}
									label={field.label}
									placeholder={field.placeholder}
									component={FormGroups}
									startDate={startDate}
									endDate={endDate}
									onChange={onDateChange}
									validate={field.validate}
									dateFormat={"MM-DD-YYYY"}
									pickerTypeSelector={0}
									disabled={editMode}
									// maxDate={fromDate && !toDate && maxDate}
									onBlur={(event) => event.preventDefault()}
								/>
							) : (
								<Field
									name={field.name}
									type={field.type}
									label={field.label}
									placeholder={field.placeholder}
									component={FormGroups}
									validate={field.validate}
									options={getOptions(field.name)}
									startDate={startDate}
									endDate={endDate}
									disable={editMode}
									selectMenuHeight={200}
									onChange={(event, newValue, previousValue, name) =>
										onSelect(event, newValue, previousValue, name)
									}
								/>
							)}
						</Form.Group>
					</Col>
				</React.Fragment>
			);
		});

		return (
			<Row className="dosing-form-row">
				{formFieldHtml}
				<Col className="form-submit-col-wsr">
					<Button type="submit" disabled={submitting || editMode} className="apply-btn">
						Apply
					</Button>
					<Button className="reset-btn" disabled={pristine || submitting || editMode} onClick={resetHandler}>
						<i className="icon icon-reset mr-2" />
					</Button>
				</Col>
			</Row>
		);
	};

	return (
		<div className="dosing-form-wrapper">
			<Form onSubmit={handleSubmit(onSubmit)} className="dosingCalcForm">
				{getFormFieldHtml(dosingCalcFields)}
			</Form>
		</div>
	);
};

const mapStateToProps = createStructuredSelector({
	locationList: getLocationList,
	fromDate: getFormFromDate,
	toDate: getFormToDate,
	maxDate: getThirtyWeeks,
	pondList: getPondList,
	// initialValues: getDosingCalcEditFormInitials,
	editMode: getDosingCalcEditMode,
	currentPondId: getCurrentPondId,
	ponds: getPondTabData,
});

export default connect(mapStateToProps, {
	setLoaderDosingCalc,
	fetchDataDosingCalc,
	resetAll,
})(reduxForm({ form: "dosingCalcForm", enableReinitialize: true })(DosingCalcForm));
