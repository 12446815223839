import React, { useState } from "react";
import { Field, reduxForm, reset, change } from "redux-form";
import { useDispatch } from "react-redux";
import { Form, Container, Row, Col, Modal, Button } from "react-bootstrap";
import useModal from "../../utils/useModal";
import FormGroups from "../../utils/formGroup";
import { successMessage } from "../../utils/successMessage";
import { errorMessage } from "../../utils/errorMessage";
import { connect } from "react-redux";
import { fetchLocationKpiDetails, addLocationKpiDetails } from "../../store/actions/kpiActions";
import { formCreationFieldsTop, formCreationFieldsBottom, getValidations } from "./formHelper";
import Util from "../../utils/Util";
import TableLoader from "../../utils/table/tableLoader";

const KpiEdit = ({
	locations,
	addPermission,
	viewPermission,
	handleSubmit,
	form,
	fetchLocationKpiDetails,
	addLocationKpiDetails,
}) => {
	const [isOpen, , , closeModal] = useModal("kpi");
	const [isLoading, setIsLoading] = useState(false);
	const [viewType, setViewType] = useState(true);
	const [location, setLocation] = useState("");
	const [data, setData] = useState([]);
	//const [submitAction, setSubmitAction] = useState(false);

	const dispatch = useDispatch();

	const getLocationKpiTargets = (value) => {
		setIsLoading(true);
		setData([]);
		const body = {
			slug: value,
		};

		const successHandler = (e) => {
			setIsLoading(false);
			setData(e);
			dispatch(change("kpi-form", "pack_rate_target", e && e.pack_rate_target ? e.pack_rate_target : ""));
			dispatch(change("kpi-form", "seed_rate_target", e && e.seed_rate_target ? e.seed_rate_target : ""));
			dispatch(change("kpi-form", "harvest_rate_target", e && e.harvest_rate_target ? e.harvest_rate_target : ""));
			dispatch(change("transplantForm", "product_variety", []));
		};
		const errorHandler = (e) => setIsLoading(false);
		fetchLocationKpiDetails(body, successHandler, errorHandler);
	};

	const remapLocations = (value) => {
		setLocation(value);
		getLocationKpiTargets(value);
	};
	const submitKpiDetails = (values) => {
		const body = {
			pack_rate_target: values.pack_rate_target,
			seed_rate_target: values.seed_rate_target,
			harvest_rate_target: values.harvest_rate_target,
			slug: values.location.value,
		};
		const successHandler = (e) => {
			setIsLoading(false);
			//setSubmitAction(true);
			successMessage(e);
			setViewType(true);
			getLocationKpiTargets(location);
		};
		const errorHandler = (e) => {
			errorMessage(e);
			setIsLoading(false);
		};
		addLocationKpiDetails(body, successHandler, errorHandler);
	};

	const getFormFieldHtml = (fields) => {
		let formFieldHtml = [];
		formFieldHtml = fields.map((field, idx) => {
			return (
				<React.Fragment key={idx}>
					<Col className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
						<Form.Group controlId={`formControl ${field.name}`}>
							{field.type === "select-react" ? (
								<Field
									name={field.name}
									type={field.type}
									label={field.label}
									placeholder={field.placeholder}
									component={FormGroups}
									validate={getValidations(field.name)}
									options={Util.mapOptions(locations, "name", "slug")}
									selectMenuHeight={field.maxMenuHeight}
									onChange={(event) => field.name === "location" && remapLocations(event.value)}
								/>
							) : (
								<Field
									name={field.name}
									type={field.type}
									label={field.label}
									placeholder={field.placeholder}
									component={FormGroups}
									validate={getValidations(field.name)}
								/>
							)}
						</Form.Group>
					</Col>
				</React.Fragment>
			);
		});
		return <>{formFieldHtml}</>;
	};
	const labelledValue = (label, value, unit = null, colSize = 6, colLGSize = 6) => (
		<Col className={`col-lg-${colLGSize} col-md-${colSize} col-12 mb-3`} key={label}>
			{(label || value) && (
				<div key={label}>
					<p className="d-flex align-items-center text-muted mb-2">
						<span className="light-badge-pill"></span>
						<span className="view-labels">{label}</span>
					</p>
					<h6 className="ml-4 view-values">{value ? `${value} ${unit ? unit : ""}` : "-"}</h6>
				</div>
			)}
		</Col>
	);
	const cancelModal = () => {
		closeModal();
		setViewType(true);
		setLocation("");
		setData([]);
		dispatch(reset("kpi-form"));
	};
	return (
		<Modal size="lg" show={isOpen} onHide={closeModal} className="modal-sidepanel kpi-modal">
			<Modal.Header closeButton>
				<Modal.Title className="modal-title">KPI Target</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form onSubmit={handleSubmit(submitKpiDetails)} className="kpi-form">
					<Container>
						<Row className="mb-4">{getFormFieldHtml(formCreationFieldsTop)}</Row>
						<Row className="mb-4">
							{addPermission && location && viewType && (
								<button
									className="btn btn-light d-block btn-lg ml-auto btn-link"
									onClick={() => setViewType(!viewType)}>
									<i className={`icon ${viewType ? "icon-edit" : "icon-view"}`} />
									{viewType ? " Edit" : " View"}
								</button>
							)}
						</Row>
						{viewType && data && location && (
							// {viewType && data && Object.keys(data).length > 0 && (
							<Row className="mb-4">
								{labelledValue("Pack Rate Target", data && data.pack_rate_target)}
								{labelledValue("Seed Rate Target", data && data.seed_rate_target)}
								{labelledValue("Harvest Rate Target", data && data.harvest_rate_target)}
							</Row>
						)}
						{!viewType && (
							<div className="outer-box">
								<Row>
									<div className="form-headers">{getFormFieldHtml(formCreationFieldsBottom)}</div>
								</Row>
							</div>
						)}
					</Container>

					<Modal.Footer>
						<Button variant="secondary" onClick={() => cancelModal()}>
							Cancel
						</Button>
						{addPermission && !viewType && (
							<Button variant="primary" type="submit" disabled={isLoading}>
								{isLoading ? "Saving..." : "Save"}
							</Button>
						)}
					</Modal.Footer>
				</Form>
				{/* Loader */}
				{isLoading && (
					<div className="table-loader-wrapper">
						<TableLoader />
					</div>
				)}
			</Modal.Body>
		</Modal>
	);
};

const mapDispatchToProps = {
	reset,
	fetchLocationKpiDetails,
	addLocationKpiDetails,
};

export default connect(
	null,
	mapDispatchToProps
)(
	reduxForm({
		form: "kpi-form",
		enableReinitialize: true,
	})(KpiEdit)
);
