import { Breadcrumbs, ButtonPrimary, ContentWrap } from "elements";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { cancelDosingEdit, fetchDropdownDataToAddList, setLoaderToAddList, setRenderTypeToAddList } from "store/actions";
import { getLoading, getNutrientsAddedPermission, getRenderType, getListPermission } from "store/selectors/toAddListSelectors";
import { errorMessage } from "utils/errorMessage";
import TableLoader from "utils/table/tableLoader";
import Listing from "./listing";
import "./index.scss";
import NutrientListing from "./nutrient-listing";
import { getDosingCalcEditMode } from "store/selectors/dosingCalcSelectors";

const ToAddList = ({
	loading,
	renderType,
	setRenderTypeToAddList,
	fetchDropdownDataToAddList,
	setLoaderToAddList,
	editMode,
	cancelDosingEdit,
	nutrientsPermission,
	listPermission,
}) => {
	useEffect(() => {
		setLoaderToAddList();
		const successHandler = () => {
			setLoaderToAddList();
		};
		const errorHandler = (e) => {
			errorMessage(e);
			setLoaderToAddList();
		};
		fetchDropdownDataToAddList(successHandler, errorHandler);
	}, [fetchDropdownDataToAddList, setLoaderToAddList]);

	return (
		<ContentWrap>
			<div className="d-flex align-items-center mb-3 to-add-list-wrapper">
			<Breadcrumbs
					className="forecast-breadcrumb"
					keyValue={renderType === 0 ? "nutrientTracking" : "nutrientTrackingToAdd"}
					showLabel={renderType === 0 ? true : false}
					label={renderType === 0 ? "To Add List" : ""}
				/>
				{renderType === 0 && nutrientsPermission && (
					<div className="d-md-flex position-relative">
						<ButtonPrimary className="view-nutrients-btn" onClick={() => setRenderTypeToAddList(1)}>
							View Nutrients Added
						</ButtonPrimary>
					</div>
				)}
			</div>

			<div className="position-relative water-source-wrapper" style={{ paddingBottom: !listPermission ? 20 : 0 }}>
				{renderType === 0 ? listPermission && <Listing /> : nutrientsPermission && <NutrientListing />}
				{loading && (
					<div className="table-loader-wrapper">
						<TableLoader />
					</div>
				)}
			</div>
		</ContentWrap>
	);
};

const mapStateToProps = createStructuredSelector({
	loading: getLoading,
	renderType: getRenderType,
	editMode: getDosingCalcEditMode,
	nutrientsPermission: getNutrientsAddedPermission,
	listPermission: getListPermission,
});

export default connect(mapStateToProps, {
	setRenderTypeToAddList,
	fetchDropdownDataToAddList,
	setLoaderToAddList,
	cancelDosingEdit,
})(ToAddList);
