import React, { useState } from "react";
//import { get } from "lodash";
import { connect } from "react-redux";
import { Row, Col, Form, Button } from "react-bootstrap";
import { formCreationFields, getValidations } from "./formHelper";
import { reduxForm, Field, change } from "redux-form";
import FormGroups from "../../utils/formGroup";
import { fetchGraphDetails } from "../../store/actions";
import { ButtonPrimary } from "../../elements";
//import TableLoader from "utils/table/tableLoader";
import { useDispatch } from "react-redux";
import { createStructuredSelector } from "reselect";
import {
	getLocationList,
	getLocationDetailsArray,
	getTableAllLocationAccess,
	getUserAllocatedLocation,
	getClimateTableFullPermission,
} from "store/selectors/climateSelectors";

const ReportForm = ({
	permissionDetails,
	handleSubmit,
	locationDetails,
	locationList,
	btnTitle,
	submitClimateDetails,
	isLoading,
	reset,
	pristine,
	submitting,
	allLocationAcess,
	userLocations,
	fullPermission,
}) => {
	const [startDate, setStartDate] = useState(null);
	const [endDate, setEndDate] = useState(null);
	const [selectedLocation, setSelectedLocation] = useState("");
	const [selectedCompartment, setSelectedCompartment] = useState("");
	const dispatch = useDispatch();

	const parameterArray = (paramsArray) => {
		const newList = [];
		paramsArray
			.filter((val) => val !== "yield")
			.forEach(function (val, i) {
				newList[i] = {
					label: val,
					value: val,
				};
			});

		return newList;
	};

	const getOptions = (field) => {
		switch (field) {
			case "compartment":
				return selectedLocation && locationDetails[selectedLocation]
					? parameterArray(locationDetails[selectedLocation]["comp"])
					: [];
			case "location":
				return allLocationAcess ? locationList : userLocations;
			case "parameters":
				return selectedLocation &&
					selectedCompartment &&
					locationDetails[selectedLocation] &&
					locationDetails[selectedLocation]["comp_data"] &&
					locationDetails[selectedLocation]["comp_data"][selectedCompartment]
					? parameterArray(locationDetails[selectedLocation]["comp_data"][selectedCompartment])
					: [];
			default:
				return [];
		}
	};
	const onChange = (dates) => {
		const [start, end] = dates;
		setStartDate(start);
		setEndDate(end);
	};

	const onChangeSelect = (e, name) => {
		if (name === "location" && e && e.value) {
			setSelectedLocation([e.value]);
			dispatch(change("climateManagementForm", "compartment", []));
			dispatch(change("climateManagementForm", "parameters", []));
		} else if (name === "compartment" && e && e.value) {
			setSelectedCompartment([e.value]);
			dispatch(change("climateManagementForm", "parameters", []));
		}
	};

	const customerFormFields = () => {
		let columns = [];
		columns = formCreationFields.map((field, idx) => {
			return (
				<Col className="forecast-form-column" key={idx}>
					<Form.Group controlId={`formControl ${idx}`}>
						{field.type === "select-react" || field.type === "select-react-expand-all" ? (
							<Field
								name={field.name}
								type={field.type}
								label={field.label}
								placeholder={field.placeholder}
								component={FormGroups}
								validate={getValidations(field.name)}
								options={getOptions(field.name)}
								onChange={(event) => {
									onChangeSelect(event, field.name);
								}}
								multiple={field.multiple ? field.multiple : false}
							/>
						) : (
							<Field
								name={field.name}
								type={field.type}
								label={field.label}
								placeholder={field.placeholder}
								component={FormGroups}
								startDate={startDate}
								selectsEnd={Boolean(startDate)}
								endDate={endDate}
								onChange={onChange}
								validate={getValidations(field.name)}
								dateFormat="MM/dd/yyyy"
								pickerTypeSelector={0}
							/>
						)}
					</Form.Group>
				</Col>
			);
		});

		return (
			<React.Fragment>
				{
					<Row className="forecast-form-row">
						{columns}
						<Col className="form-submit-col">
							<ButtonPrimary type="submit" disabled={pristine || submitting}>
								{btnTitle}
							</ButtonPrimary>
							<Button
								className="reset-btn"
								onClick={() => {
									reset();
									setStartDate(null);
									setEndDate(null);
									setSelectedCompartment("");
								}}
								disabled={pristine || submitting}>
								<i className="icon icon-reset mr-2" />
							</Button>
						</Col>
					</Row>
				}
			</React.Fragment>
		);
	};

	return fullPermission ? (
		<Form
			name="climateManagementForm"
			autoComplete="off"
			onSubmit={handleSubmit(submitClimateDetails)}
			className="climate-management-form">
			{customerFormFields()}
			{isLoading && <div className="table-loader-wrapper">{/* <TableLoader /> */}</div>}
		</Form>
	) : (
		""
	);
};
const mapStateToProps = createStructuredSelector({
	locationList: getLocationList,
	locationDetails: getLocationDetailsArray,
	allLocationAcess: getTableAllLocationAccess,
	userLocations: getUserAllocatedLocation,
	fullPermission: getClimateTableFullPermission,
});

const mapDispatchToProps = { fetchGraphDetails };

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(
	reduxForm({
		form: "climateManagementForm",
		enableReinitialize: true,
	})(ReportForm)
);
