import { TRANSPLANT } from "../types";

const initialState = {
	fetchTransplantList: [],
	transplantTaskDetails: {},
	locationMapping: [],
	renderDisplay: 0,
	prevRenderDisplay: 0,
	selectedLocation: null,
	transplantTaskOrderList: [],
	fetchAllPondParameters: [],
	editPondParameters: [],
};

export default function (state = initialState, { type, payload = {} }) {
	switch (type) {
		case TRANSPLANT.FETCH_TRANSPLANT:
			return {
				...state,
				fetchTransplantList: payload.data || [],
			};
		case TRANSPLANT.FETCH_TRANSPLANT_TASK_ORDER_LIST:
			return {
				...state,
				transplantTaskOrderList: payload.data || [],
			};
		case TRANSPLANT.SET_TRANSPLANT_VIEW_RENDER:
			return {
				...state,
				prevRenderDisplay: state.renderDisplay,
				renderDisplay: payload,
			};
		case TRANSPLANT.FETCH_TRANSPLANT_DETAILS:
			state = {
				...state,
				transplantTaskDetails: payload.data || {},
			};
			return state;
		case TRANSPLANT.GET_TRANSPLANT_LOCATION_DETAILS:
			return {
				...state,
				locationMapping: payload.data,
			};
		case TRANSPLANT.GET_TRANSPLANT_TASK_AUDIT_LOG:
			return {
				...state,
				acitivityLog: payload.data || [],
			};
		case TRANSPLANT.SELECTED_LOCATION:
			return {
				...state,
				selectedLocation: payload,
			};
		case TRANSPLANT.FETCH_ALL_POND_PARAMETERS:
			return {
				...state,
				fetchAllPondParameters: payload,
			};
		default:
			return state;
	}
}
