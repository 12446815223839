import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Container, Button, Row, Col, Form } from "react-bootstrap";
import { reduxForm, Field, reset, change } from "redux-form";
import { useDispatch } from "react-redux";
import FormGroups from "../../../utils/formGroup";
import { required, number } from "../../../constant/formValidators";
import { formCreationFields } from "./formHelper";
import { createSku } from "../../../store/actions";
import useModal from "../../../utils/useModal";
import { toggleMainLoader } from "../../../store/actions";
import { errorMessage } from "../../../utils/errorMessage";
import { successMessage } from "../../../utils/successMessage";
import { fetchSkuProducts } from "../../../store/actions";
import { map } from "lodash";
import TableLoader from "utils/table/tableLoader";

const SkuAddEdit = ({
	handleSubmit,
	createSku,
	handleEditPanel,
	toggleMainLoader,
	reset,
	form,
	onAddOrEdit,
	initialValues,
	locationList,
	productList,
	fetchSkuProducts,
}) => {
	const [, modalType, , closeModal] = useModal("sku");
	const [productArray, setProductArray] = useState(initialValues ? initialValues.product_varieties : []);
	const [locationArray, setLocationArray] = useState(
		initialValues ? { locations: map(initialValues.locations, "value") } : []
	);
	const [firstRender, setFirstRender] = useState(0);
	const dispatch = useDispatch();
	const [isLoading, setisLoading] = useState(false);
	const [titleBtn, settitleBtn] = useState("Save");

	useEffect(() => {
		locationArray && Object.keys(locationArray.locations).length > 0 && fetchSkuProducts(locationArray);
		firstRender && setProductArray([]);
		firstRender && dispatch(change("skuForm", "product_varieties", []));
	}, [locationArray, fetchSkuProducts, firstRender, dispatch]);

	const submitBoardDetails = (values) => {
		setisLoading(true);
		settitleBtn("Saving...");
		toggleMainLoader(true);
		const locations = values.locations
			? values.locations.map(function (value, index) {
					return value.value;
			  })
			: [];

		const products =
			values.product_varieties && values.product_variety
				? values.product_varieties.map(function (value, index) {
						return { product_variety: value.value, percentage: values.product_variety[value.value] };
				  })
				: [];

		const submissionData = {
			sku_name: values.sku_name,
			oz_per_clamshell: values.oz_per_clamshell,
			clamshells_per_case: values.clamshells_per_case,
			locations: locations,
			product_varieties: products,
		};

		const successHandler = (event) => {
			onAddOrEdit && onAddOrEdit(event);
			reset(form);
			setisLoading(false);
			settitleBtn("Save");
			successMessage(event);
			closeModal();
		};
		const errorHandler = (event) => {
			errorMessage(event);
			setisLoading(false);
			settitleBtn("Save");
		};
		const method = modalType ? "PUT" : "POST";
		const payload = modalType ? initialValues.slug : "";

		createSku(submissionData, method, payload, successHandler, errorHandler);
	};
	const getValidations = (field) => {
		switch (field) {
			case "sku_name":
				return required;
			case "product_varieties":
				return [required];
			case "locations":
				return [required];
			default:
				return [required, number];
		}
	};

	const mapOptions = (list, label, value) => {
		const newList = list.map((field) => ({
			label: field[label],
			value: field[value].toString(),
			isdisabled: label === "product_variety" ? !field.combined : false,
		}));

		return newList;
	};

	const getOptions = (field) => {
		switch (field) {
			case "product_varieties":
				return mapOptions(productList, "product_variety", "slug");
			case "locations":
				return mapOptions(locationList, "location_name", "slug");

			default:
				return [];
		}
	};

	const remapLocations = (event) => {
		setLocationArray(event);
		setFirstRender(1);
		setProductArray([]);
		dispatch(change("skuForm", "product_varieties", []));
	};

	const getFormFieldHtml = (fields, tabValue) => {
		let formFieldHtml = [];
		formFieldHtml = fields.fields.map((field, idx) => {
			return (
				<React.Fragment key={idx}>
					<Col
						className={
							field.name === "product_varieties"
								? "col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
								: "col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12"
						}>
						<Form.Group controlId={`formControl ${field.name}`}>
							{field.name === "product_varieties" ? (
								<Field
									name={field.name}
									type={field.type}
									label={field.label}
									placeholder={field.placeholder}
									component={FormGroups}
									validate={getValidations(field.name)}
									multiple={true}
									options={getOptions(field.name)}
									onChange={(event) => setProductArray(event)}
								/>
							) : field.name === "locations" ? (
								<Field
									name={field.name}
									type={field.type}
									label={field.label}
									placeholder={field.placeholder}
									component={FormGroups}
									validate={getValidations(field.name)}
									multiple={true}
									options={getOptions(field.name)}
									onChange={(event) => remapLocations({ locations: map(event, "value") })}
								/>
							) : (
								<Field
									name={field.name}
									type={field.type}
									label={field.label}
									placeholder={field.placeholder}
									component={FormGroups}
									validate={getValidations(field.name)}
								/>
							)}
						</Form.Group>
					</Col>
				</React.Fragment>
			);
		});
		return <>{formFieldHtml}</>;
	};

	const getProductHtml = () => {
		let formFieldHtml = [];
		formFieldHtml = productArray.map((productField, idx) => {
			return (
				<React.Fragment key={idx}>
					<Col className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
						<Form.Group controlId={`formControl ${productField.value}`}>
							<Field
								name={"product_variety." + productField.value}
								type="text"
								label={productField.label + " (%) *"}
								placeholder={productField.placeholder}
								component={FormGroups}
								validate={getValidations("products")}
							/>
						</Form.Group>
					</Col>
				</React.Fragment>
			);
		});

		return <>{formFieldHtml}</>;
	};

	const skuFormFields = () => {
		let columns = [];
		columns = formCreationFields.map((fields, idx) => {
			return (
				<React.Fragment key={idx}>
					<Row>
						<div className="form-headers">{fields.heading}</div>
					</Row>
					<Row>{getFormFieldHtml(fields)}</Row>
				</React.Fragment>
			);
		});

		return <>{columns}</>;
	};

	return (
		<>
			<Form onSubmit={handleSubmit(submitBoardDetails)} className="skuForm">
				<Container className="position-relative">
					{skuFormFields()}
					<Row>{productArray ? getProductHtml() : ""}</Row>
					{isLoading && (
						<div className="table-loader-wrapper">
							<TableLoader />
						</div>
					)}
				</Container>
				<div className="modal-footer save-footer">
					<Button variant="secondary" onClick={closeModal}>
						Cancel
					</Button>
					<Button variant="primary" type="submit" disabled={isLoading}>
						{titleBtn}
					</Button>
				</div>
			</Form>
		</>
	);
};

const mapStateToProps = (state) => {
	const { Sku, Modals } = state;
	const initialArray = () => {
		const locationsList = Sku.skuDetails.location_list
			? Sku.skuDetails.location_list.map(function (value, index) {
					return { value: value["slug"], label: value["name"] };
			  })
			: [];
		const products = Sku.skuDetails.recipe
			? Sku.skuDetails.recipe.map(function (value, index) {
					return { value: value["slug"], label: value["product_variety"] };
			  })
			: [];

		const productSingleList = Sku.skuDetails.recipe
			? Sku.skuDetails.recipe.reduce((accumulator, current) => {
					accumulator[current.slug] = current.percentage;
					return accumulator;
			  }, {})
			: [];

		const formatedInitialValueArray = {
			...Sku.skuDetails,
			locations: locationsList,
			product_varieties: products,
			product_variety: productSingleList,
		};
		return formatedInitialValueArray;
	};
	return {
		locationList: Sku.allLocationsList,
		productList: Sku.productList,
		initialValues: Modals.modalType ? initialArray() : {},
	};
};

const mapDispatchToProps = {
	toggleMainLoader,
	createSku,
	reset,
	fetchSkuProducts,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(
	reduxForm({
		form: "skuForm",
		enableReinitialize: true,
	})(SkuAddEdit)
);
