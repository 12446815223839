import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import {
	deleteCroptimal,
	editCroptimals,
	resetRefreshCroptimals,
	setCroptimalsData,
	setLoaderCroptimals,
	setProductVarietyMapping,
} from "store/actions";
import {
	getCroptimalsData,
	getCroptimalsTableColumn,
	getDeletePermission,
	getEditPermission,
	getProductKeyMapping,
} from "store/selectors/croptimalsSelectors";
import ActionTable from "utils/actionTable";
import ConfirmModal from "utils/confirmModal";
import { errorMessage } from "utils/errorMessage";
import { successMessage } from "utils/successMessage";
import CroptimalsForm from "../manage";

const Listing = ({
	columns,
	data,
	productKeyMapping,
	setLoaderCroptimals,
	editCroptimals,
	setCroptimalsData,
	deleteCroptimal,
	resetRefreshCroptimals,
	editPermission,
	deletePermission,
	setProductVarietyMapping,
}) => {
	const [deleteItem, setdeleteItem] = useState(null);
	useEffect(() => {
		return () => {
			resetRefreshCroptimals();
		};
	}, [resetRefreshCroptimals]);

	const onSave = (values, slug, editableChange) => {
		setLoaderCroptimals();
		const tempData = [];
		for (const key in values) {
			tempData.push({
				nutrient_slug: key,
				nutrient_value: values[key],
			});
		}
		const submitData = {
			croptimals: [
				{
					croptimal_slug: slug,
					nutrients: tempData,
				},
			],
		};

		const successHandler = (e) => {
			successMessage(e);
			const arr = [];

			for (const key in values) {
				arr.push({
					nutrientSlug: key,
					croptimal_value: values[key],
				});
			}

			setCroptimalsData({ ...data, [slug]: arr });
			setLoaderCroptimals();
			editableChange();
		};
		const errorHandler = (e) => {
			errorMessage(e);
			setLoaderCroptimals();
		};
		editCroptimals(submitData, successHandler, errorHandler);
	};

	const onDelete = () => {
		setLoaderCroptimals();

		const successHandler = (e) => {
			successMessage(e);

			// const tempData = data;
			// delete tempData[deleteItem];
			const tempData = {};
			for (const key in data) {
				if (key !== deleteItem) {
					tempData[key] = data[key];
				}
			}
			const keyMap = productKeyMapping.filter((item) => item.slug !== deleteItem);
			setCroptimalsData(tempData);
			setProductVarietyMapping(keyMap);
			setdeleteItem(null);
			setLoaderCroptimals();
		};
		const errorHandler = (e) => {
			errorMessage(e);
			setLoaderCroptimals();
			setdeleteItem(null);
		};
		deleteCroptimal(deleteItem, successHandler, errorHandler);
	};

	return (
		<div className="listing-wrapper-croptimals">
			<CroptimalsForm />

			{data &&
				columns &&
				(!isEmpty(data) ? (
					<ActionTable
						editable={true}
						columns={columns}
						fixedColumnHeading="Product Variety"
						initialData={data}
						slugKey="nutrientSlug"
						renderKey="croptimal_value"
						onSave={onSave}
						render={(item) => (item.croptimal_value ? item.croptimal_value : "-")}
						keyMapping={productKeyMapping}
						onDelete={(slug) => setdeleteItem(slug)}
						editPermission={editPermission}
						deletePermission={deletePermission}
					/>
				) : (
					<div className="no-results-found text-center">No Results Found</div>
				))}

			<ConfirmModal
				show={deleteItem ? true : false}
				onNo={() => setdeleteItem(null)}
				onYes={onDelete}
				message="Are you sure want to delete this data ?"
			/>
		</div>
	);
};

const mapStateToProps = createStructuredSelector({
	data: getCroptimalsData,
	columns: getCroptimalsTableColumn,
	productKeyMapping: getProductKeyMapping,
	editPermission: getEditPermission,
	deletePermission: getDeletePermission,
});

export default connect(mapStateToProps, {
	setLoaderCroptimals,
	editCroptimals,
	setCroptimalsData,
	deleteCroptimal,
	resetRefreshCroptimals,
	setProductVarietyMapping,
})(Listing);
