import React from "react";
import { Row, Col } from "react-bootstrap";
import { flagColors_2 } from "constant/global";
import i18next from "i18next";

const RenderViewTable = ({ form, data, flagsArray, addItem, number }) => {
	const tableHead = () => {
		return (
			<Row className="view-table-head-row">
				{form.map((column, index) => {
					return (
						<Col key={index} className="view-table-head-col" style={column.type ==="avg" ? {overflow:"visible"}:null}>
							<span>{i18next.t(column.label)}</span>
						</Col>
					);
				})}
			</Row>
		);
	};

	const tableBody = () => {
		return (
			<div className="view-table-body">
				{data.map((dataItem, d_ind) => (
					<Row key={d_ind} className="view-table-body-row">
						{form.map((formItem, f_ind) => (
							<Col key={f_ind} className="view-table-body-col">
								{/* <span>{dataItem[formItem.name]}</span> */}
								{getColumnItem(formItem, dataItem)}
							</Col>
						))}
					</Row>
				))}
			</div>
		);
	};

	const getColumnItem = (formItem, dataItem) => {
		if (formItem.type === "join") {
			const joinArr = dataItem[formItem.name];
			return <span>{joinArr.join(", ")}</span>;
		} else if (formItem.type === "sum") {
			const joinArr = dataItem[formItem.name];
			let result = 0;
			joinArr.map((item) => (result += parseFloat(item)));
			return <span>{Number.isInteger(result) ? result : result.toFixed(2)}</span>;
		}else if (formItem.type === "avg") {
			const joinArr = dataItem[formItem.name];
			let lbs = 0;
			const board = dataItem.board_count;
			joinArr.map((item) => (lbs += parseFloat(item)));
			const avgValue = lbs && board && ((lbs * 16)/board);
			return <span>{Number.isInteger(avgValue) ? avgValue : avgValue.toFixed(2)}</span>;
		} else {
			return (
				<span>
					{formItem.name === "flag_color"
						? flagColors_2[dataItem[formItem.name]].label
						: formItem.name === "pond"
						? dataItem[formItem.name]
							? dataItem[formItem.name].value
							: "-"
						: dataItem[formItem.name]}
				</span>
			);
		}
	};

	const addButton = () => (
		<div className="view-table-btn-row">
			<button className="view-table-btn" type="button" onClick={addItem}>
				+ {i18next.t("label.Add")}
			</button>
		</div>
	);
	return (
		<div className="view-table">
			{tableHead()}
			{tableBody()}
			{addItem && addButton()}
		</div>
	);
};
export default RenderViewTable;
