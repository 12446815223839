import { LocationDropdown } from "components/bulkAssignee/bulkAssigneeComponents";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import {
	getColumns,
	getLocationList,
	getSelectedLocation,
	getTableData,
	getRenderType,
	getEditPermission,
	getDeletePermission,
} from "store/selectors/fertilizerSelectors";
import {
	addFertilizer,
	locationSelectFertilizer,
	fetchFertilizers,
	editFertilizer,
	updateFertilizerData,
	setLoaderFertilizers,
	deleteFertilizer,
	resetRefreshFertilizers,
	setRenderTypeFertilizers,
} from "store/actions";
import FertilizerTable from "utils/fertilizerTable";
import { errorMessage } from "utils/errorMessage";
import { successMessage } from "utils/successMessage";
import ConfirmModal from "utils/confirmModal";
import { isEmpty } from "lodash";

const Listing = ({
	locationList,
	locationSelected,
	locationSelectFertilizer,
	tableData,
	columns,
	addFertilizer,
	renderType,
	fetchFertilizers,
	editFertilizer,
	updateFertilizerData,
	setLoaderFertilizers,
	deleteFertilizer,
	resetRefreshFertilizers,
	setRenderTypeFertilizers,
	editPermission,
	deletePermission,
}) => {
	const [deleteItem, setdeleteItem] = useState(null);

	const onSave = (values, slug, editableChange) => {
		setLoaderFertilizers();
		if (renderType === 1) {
			const submitData = {
				location_slug: locationSelected.value,
				fertilisers: values,
			};

			const successHandler = (e) => {
				successMessage(e);
				setLoaderFertilizers();
				resetRefreshFertilizers();
				setRenderTypeFertilizers(0);
			};
			const errorHandler = (e) => {
				errorMessage(e);
				setLoaderFertilizers();
			};

			addFertilizer(submitData, successHandler, errorHandler);
		} else {
			const submitData = {
				location_slug: locationSelected.value,
				fertilisers: [{ ...values, fertiliser_slug: slug }],
			};

			const successHandler = (e) => {
				successMessage(e);
				const finalValues = columns.map((col_item) => {
					let count = -1;
					values.data.forEach((val_item, i) => {
						if (col_item.slug === val_item.nutrient_slug) {
							count = i;
						}
					});
					if (count > -1) {
						return values.data[count];
					} else {
						return {
							nutrient_slug: col_item.slug,
							nutrient_value: "",
						};
					}
				});

				const data = tableData.map((item) => {
					if (item.slug === slug) {
						return { slug:slug,...values, data: finalValues };
					} else {
						return item;
					}
				});
				
				updateFertilizerData(data);

				editableChange();
				setLoaderFertilizers();
			};
			const errorHandler = (e) => {
				errorMessage(e);
				setLoaderFertilizers();
			};
			editFertilizer(submitData, successHandler, errorHandler);
		}
	};

	useEffect(() => {
		return () => {
			resetRefreshFertilizers();
		};
	}, [renderType, resetRefreshFertilizers]);

	useEffect(() => {
		if (renderType === 0 && locationSelected) {
			setLoaderFertilizers();

			const submitData = {
				location_slug: locationSelected.value,
				// per_page: "100",
				// page: "1",
			};

			const successHandler = (e) => {
				// successMessage(e);
				setLoaderFertilizers();
			};
			const errorHandler = (e) => {
				errorMessage(e);
				setLoaderFertilizers();
			};

			fetchFertilizers(submitData, successHandler, errorHandler);
		}
	}, [renderType, locationSelected, fetchFertilizers, setLoaderFertilizers]);

	const onDelete = () => {
		setLoaderFertilizers();

		const successHandler = (e) => {
			successMessage(e);

			const tempData = tableData.filter((item) => item.slug !== deleteItem);
			updateFertilizerData(tempData);
			setdeleteItem(null);
			setLoaderFertilizers();
		};
		const errorHandler = (e) => {
			errorMessage(e);
			setLoaderFertilizers();
			setdeleteItem(null);
		};
		deleteFertilizer(deleteItem, successHandler, errorHandler);
	};

	return (
		<div className="listing-wrapper-croptimals">
			<div className="fertilizers-form-row form-group">
				<LocationDropdown
					value={locationSelected}
					options={locationList}
					onSelect={locationSelectFertilizer}
					isClearable={false}
				/>
				<span className="form-label">Location * </span>
			</div>

			{tableData &&
				(!isEmpty(tableData) ? (
					<div className="fertilizer-table-container">
						<div className="table-head">
							<div className="table-head-left">
								<span>Fertilizers</span>
							</div>
							<div className="table-head-right">
								<span>Percentage Active</span>
							</div>
						</div>
						<div className="table-body">
							<FertilizerTable
								editable={true}
								columns={columns}
								fixedColumnHeading="Product Variety"
								initialData={tableData}
								onSave={onSave}
								onDelete={(slug) => setdeleteItem(slug)}
								type={renderType === 1 && "add"}
								fixedColumnWidth={500}
								actionColumnWidth={renderType === 1 ? 50 : 100}
								onCancel={() => {
									setRenderTypeFertilizers(0);
									resetRefreshFertilizers();
								}}
								slugKey="nutrient_slug"
								renderKey="nutrient_value"
								editPermission={editPermission}
								deletePermission={deletePermission}
							/>
						</div>
					</div>
				) : (
					<div className="no-results-found text-center">No Results Found</div>
				))}

			<ConfirmModal
				show={deleteItem ? true : false}
				onNo={() => setdeleteItem(null)}
				onYes={onDelete}
				message="Are you sure want to delete this fertilizer ?"
			/>
		</div>
	);
};

const mapStateToProps = createStructuredSelector({
	locationList: getLocationList,
	locationSelected: getSelectedLocation,
	tableData: getTableData,
	columns: getColumns,
	renderType: getRenderType,
	editPermission: getEditPermission,
	deletePermission: getDeletePermission,
});
export default connect(mapStateToProps, {
	locationSelectFertilizer,
	addFertilizer,
	fetchFertilizers,
	editFertilizer,
	updateFertilizerData,
	setLoaderFertilizers,
	deleteFertilizer,
	resetRefreshFertilizers,
	setRenderTypeFertilizers,
})(Listing);
