import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Row, Col, Form, Button } from "react-bootstrap";
import {
	formCreationFields,
	formCreationWithoutTimeFrameFields,
	getValidations,
	timeFrameArray,
	reportTypeArray,
	pickerFormatArray,
} from "./formHelper";
import { reduxForm, Field, change } from "redux-form";
import FormGroups from "../../utils/formGroup";
import Util from "../../utils/Util";
import { fetchGraphDetails, fetchClimateGraphParameters, fetchClimatePondParameters } from "../../store/actions";
import { ButtonPrimary } from "../../elements";
import TableLoader from "utils/table/tableLoader";
import { useDispatch } from "react-redux";
import { createStructuredSelector } from "reselect";
import { errorMessage } from "../../utils/errorMessage";
import {
	getLocationList,
	getGraphTypeArray,
	getLocationDetailsArray,
	getClimateAccess,
	getAllLocationAccess,
	getUserAllocatedLocation,
	getClimateReportFullPermission,
} from "store/selectors/climateSelectors";

const ReportForm = ({
	handleSubmit,
	graphType,
	locationList,
	btnTitle,
	submitCustomerDetails,
	reset,
	pristine,
	submitting,
	locationDetails,
	fetchClimateGraphParameters,
	fetchClimatePondParameters,
	reportsPermissions,
	userLocations,
	fullPermission,
	allLocationAcess,
}) => {
	const [startDate, setStartDate] = useState(null);
	const [endDate, setEndDate] = useState(null);
	const [selectedReport, setSelectedReport] = useState("");
	const [pickerTypeSelector, setPickerTypeSelector] = useState(0);
	const [selectedLocation, setSelectedLocation] = useState([]);
	const [selectedCompartments, setSelectedCompartments] = useState([]);
	const [compartments, setCompartments] = useState([]);
	const [ponds, setPonds] = useState([]);
	const [selectedPonds, setSelectedPonds] = useState([]);
	const [pageLoading, setPageLoading] = useState(false);
	const [showPonds, setShowPonds] = useState(false);

	useEffect(() => {
		// if (
		// 	selectedReport === "climate_all" &&
		// 	typeof selectedLocation !== "undefined" &&
		// 	selectedLocation.length > 0 &&
		// 	typeof setSelectedCompartments !== "undefined" &&
		// 	setSelectedCompartments.length > 0
		// ) {
		// 	setPageLoading(true);
		// 	getParameters(false);
		// }
		if (
			selectedReport === "climate_all" &&
			typeof selectedLocation !== "undefined" &&
			selectedLocation.length > 0 &&
			typeof setSelectedCompartments !== "undefined" &&
			setSelectedCompartments.length > 0
		) {
			if (selectedPonds.length > 0) {
				setPageLoading(true);
				getParameters(true);
			} else {
				setPageLoading(true);
				getParameters(false);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedReport, selectedLocation, selectedCompartments, selectedPonds]);

	useEffect(() => {
		if (selectedCompartments.length === 1 && selectedCompartments[0] === "Ponds") {
			setPageLoading(true);
			getPonds();
			setShowPonds(true);
			getParameters(true);
		} else {
			setShowPonds(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedCompartments]);
	const dispatch = useDispatch();
	const getParameters = (getPonds) => {
		let obj = {
			location_slug: selectedLocation,
			graph_types: "climate_all",
			compartment: selectedCompartments,
		};
		if (getPonds) {
			obj = { ...obj, ponds: selectedPonds };
		}
		const successHandler = (e) => {
			setPageLoading(false);
			setCompartments(e.data);
		};
		const errorHandler = (event) => {
			setPageLoading(false);
			errorMessage(event);
		};
		fetchClimateGraphParameters(obj, successHandler, errorHandler);
	};
	const getPonds = () => {
		const obj = {
			location_slug: selectedLocation,
		};
		const successHandler = (e) => {
			setPageLoading(false);
			setPonds(e.data);
		};

		const errorHandler = (event) => {
			setPageLoading(false);
			errorMessage(event);
		};
		fetchClimatePondParameters(obj, successHandler, errorHandler);
	};

	const permissionArray = reportsPermissions ? reportsPermissions : "";

	const getReportWithPermissions = () => {
		const newList = [];
		reportTypeArray.forEach(function (singleReportType, i) {
			if (permissionArray[singleReportType.permission_key]) {
				newList[i] = {
					...singleReportType,
				};
			}
		});

		return newList;
	};
	const parameterArray = () => {
		const newList = [];

		if (selectedReport === "climate_all") {
			const paramsArray = compartments || [];
			const keys = Object.keys(compartments) || [];
			keys.forEach(function (val, i) {
				newList[i] = {
					label: paramsArray[val],
					value: val,
				};
			});
		} else {
			const paramsArray = graphType[selectedReport]["params"] || [];
			const keys = Object.keys(paramsArray) || [];
			keys.forEach(function (val, i) {
				newList[i] = {
					label: paramsArray[val],
					value: val,
				};
			});
		}

		return newList;
	};
	const getCompartmentArray = () => {
		const newList = [];
		if (selectedReport === "climate_all") {
			const compartments = graphType[selectedReport]["compartments"] || [];
			compartments.forEach(function (val, i) {
				newList[i] = {
					label: [val],
					value: val,
				};
			});
		} else if (selectedReport === "daily_avg" || selectedReport === "linear_plot") {
			if (selectedLocation && locationDetails[selectedLocation[0]]) {
				const paramsArray = locationDetails[selectedLocation[0]]["comp"] || [];
				paramsArray.forEach(function (val, i) {
					newList[i] = {
						label: val,
						value: val,
					};
				});
			}
		}
		return newList;
	};

	const getOptions = (field) => {
		switch (field) {
			case "compartment":
				return getCompartmentArray();
			case "ponds":
				return ponds ? Util.mapOptions(ponds, "pond_name", "id") : [];
			case "location":
				return allLocationAcess ? locationList : userLocations;
			case "graph_types":
				return getReportWithPermissions();
			case "timeframe":
				return timeFrameArray;
			case "parameters":
				return selectedReport ? parameterArray(selectedReport) : [];

			default:
				return [];
		}
	};
	const onChange = (dates) => {
		const [start, end] = dates;
		setStartDate(start);
		setEndDate(end);
	};

	const onChangeSelect = (e, name) => {
		if (name === "graph_types" && e && e.value) {
			setSelectedReport(e.value);
			dispatch(change("climateManagementForm", "location", []));
			dispatch(change("climateManagementForm", "compartment", []));
			dispatch(change("climateManagementForm", "parameters", []));
		} else if (name === "timeframe") {
			e.value && e.value === "monthly"
				? setPickerTypeSelector(1)
				: e.value && e.value === "yearly"
				? setPickerTypeSelector(2)
				: setPickerTypeSelector(0);
			dispatch(change("climateManagementForm", "date", []));
			setStartDate(null);
			setEndDate(null);
		} else if (name === "compartment") {
			setSelectedPonds([]);
			setSelectedCompartments(e.map((item) => item.value));
			dispatch(change("climateManagementForm", "parameters", []));
			dispatch(change("climateManagementForm", "ponds", []));
		} else if (name === "ponds") {
			setSelectedPonds(e.map((item) => item.value));
			dispatch(change("climateManagementForm", "ponds", []));
			dispatch(change("climateManagementForm", "parameters", []));
		} else if (name === "location" && selectedReport && e && Object.keys(e).length !== 0) {
			setSelectedPonds([]);
			selectedReport === "climate_all"
				? setSelectedLocation(e.map((item) => item.value))
				: setSelectedLocation([e.value]);
			dispatch(change("climateManagementForm", "compartment", []));
			dispatch(change("climateManagementForm", "parameters", []));
			dispatch(change("climateManagementForm", "ponds", []));
		}
	};

	const customerFormFields = () => {
		let columns = [];
		const fields = selectedReport !== "linear_plot" ? formCreationFields : formCreationWithoutTimeFrameFields;
		columns = fields.map((field, idx) => {
			return field.name === "ponds" ? (
				showPonds && (
					<Col className="forecast-form-column" key={idx}>
						<Form.Group controlId={`formControl ${idx}`}>
							<Field
								name={field.name}
								type={field.type}
								label={field.label}
								multiple={field.multiple}
								placeholder={field.placeholder}
								component={FormGroups}
								validate={getValidations(field.name)}
								options={getOptions(field.name)}
								onChange={(event) => {
									onChangeSelect(event, field.name);
								}}
							/>
						</Form.Group>
					</Col>
				)
			) : (
				<Col className="forecast-form-column" key={idx}>
					<Form.Group controlId={`formControl ${idx}`}>
						{field.type === "select-react" || field.type === "select-react-expand-all" ? (
							<Field
								name={field.name}
								type={
									field.name === "location"
										? selectedReport === "climate_all"
											? "select-react-expand-all"
											: "select-react"
										: field.type
								}
								label={field.label}
								multiple={
									field.name === "location"
										? selectedReport === "climate_all"
											? true
											: false
										: field.multiple
								}
								placeholder={field.placeholder}
								component={FormGroups}
								validate={getValidations(field.name)}
								options={getOptions(field.name)}
								onChange={(event) => {
									onChangeSelect(event, field.name);
								}}
							/>
						) : (
							<Field
								name={field.name}
								type={field.type}
								label={field.label}
								placeholder={field.placeholder}
								component={FormGroups}
								startDate={startDate}
								selectsEnd={Boolean(startDate)}
								endDate={endDate}
								onChange={onChange}
								validate={getValidations(field.name)}
								dateFormat={pickerFormatArray[pickerTypeSelector]}
								pickerTypeSelector={pickerTypeSelector}
							/>
						)}
					</Form.Group>
				</Col>
			);
		});

		return (
			<React.Fragment>
				{
					<Row className="forecast-form-row">
						{columns}
						<Col className="form-submit-col">
							<ButtonPrimary type="submit" disabled={pristine || submitting}>
								{btnTitle}
							</ButtonPrimary>
							<Button
								className="reset-btn"
								onClick={() => {
									reset();
									setStartDate(null);
									setEndDate(null);
								}}
								disabled={pristine || submitting}>
								<i className="icon icon-reset mr-2" />
							</Button>
						</Col>
					</Row>
				}
			</React.Fragment>
		);
	};

	return fullPermission ? (
		<Form autoComplete="off" onSubmit={handleSubmit(submitCustomerDetails, 1)} className="climate-management-form">
			{customerFormFields()}
			{pageLoading && (
				<div className="table-loader-wrapper">
					<TableLoader />
				</div>
			)}
		</Form>
	) : (
		""
	);
};
const mapStateToProps = createStructuredSelector({
	locationList: getLocationList,
	graphType: getGraphTypeArray,
	locationDetails: getLocationDetailsArray,
	reportsPermissions: getClimateAccess,
	allLocationAcess: getAllLocationAccess,
	userLocations: getUserAllocatedLocation,
	fullPermission: getClimateReportFullPermission,
});

const mapDispatchToProps = { fetchGraphDetails, fetchClimateGraphParameters, fetchClimatePondParameters };

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(
	reduxForm({
		form: "climateManagementForm",
		enableReinitialize: true,
	})(ReportForm)
);
