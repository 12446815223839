import fetchHandler from "../../utils/fetchHandler";
import { LOCATIONS } from "../types";

export const fetchAllLocations = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `locations`,
		method: "POST",
		actionType: LOCATIONS.FETCH_LOCATIONS,
		body: JSON.stringify(values),
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

// List product varieties according to location for dropdown
export const fetchLocationProducts = (body, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `location/product_varieties`,
		method: "POST",
		actionType: LOCATIONS.FETCH_LOCATION_PRODUCTS,
		body: JSON.stringify(body),
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

// List users according to location for dropdown
export const fetchLocationUsers = (slug, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `location-users/${slug}`,
		method: "GET",
		actionType: LOCATIONS.FETCH_LOCATION_USERS,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const fetchLocationDetails = (slug, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `location/${slug}`,
		method: "GET",
		actionType: LOCATIONS.FETCH_LOCATION_DETAILS,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const createLocation = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `location`,
		method: "POST",
		actionType: LOCATIONS.CREATE_LOCATION,
		body: JSON.stringify(values),
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const updateLocation = (slug, values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `location/${slug}`,
		method: "PUT",
		actionType: LOCATIONS.UPDATE_LOCATION,
		body: JSON.stringify(values),
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const deleteLocation = (id, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `location/${id}`,
		method: "DELETE",
		actionType: LOCATIONS.DELETE_LOCATION,
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const clearLocationDetails = () => {
	return (dispatch, getState) => {
		dispatch({
			type: LOCATIONS.CLEAR_LOCATION_DETAILS,
		});
	};
};

export const fetchLocationSkuPriority = (slug, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `location-sku-priority/${slug}`,
		method: "GET",
		actionType: LOCATIONS.FETCH_LOCATION_SKU_PRIORITY,
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const updatePriority = (values, url, successHandler, errorHandler) => {
	const fetchOptions = {
		url: url,
		method: "PUT",
		secure: true,
		actionType: LOCATIONS.UPDATE_PRIORITY,
		body: JSON.stringify(values),
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const fetchLocationCustomerPriority = (slug, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `location-customer-priority/${slug}`,
		method: "GET",
		actionType: LOCATIONS.FETCH_LOCATION_CUSTOMER_PRIORITY,
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};
export const fetchProductBasedList = (body, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `product_varieties/location_based_list`,
		method: "POST",
		actionType: LOCATIONS.FETCH_PRODUCT_BASED_LIST,
		body: JSON.stringify(body),
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};
export const fetchSkuBasedList = (body, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `sku/location_based_list`,
		method: "POST",
		actionType: LOCATIONS.FETCH_SKU_BASED_LIST,
		body: JSON.stringify(body),
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const fetchProductChangeStatus = (body, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `product_varieties/change_status`,
		method: "POST",
		actionType: LOCATIONS.FETCH_PRODUCT_CHANGE_STATUS,
		body: JSON.stringify(body),
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};
export const fetchSkuChangeStatus = (body, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `sku/change_status`,
		method: "POST",
		actionType: LOCATIONS.FETCH_SKU_CHANGE_STATUS,
		body: JSON.stringify(body),
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};
