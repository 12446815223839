export const formCreationFields = [
	{
		label: "Styrofoam Board Name *",
		name: "board_name",
		type: "text",
		placeholder: "Enter Styrofoam Board Name",
	},
	{
		label: "Length *",
		name: "length",
		type: "text",
		placeholder: "Enter length in inches",
	},
	{
		label: "Width *",
		name: "width",
		type: "text",
		placeholder: "Enter width in inches",
	},
	{
		label: "Height *",
		name: "height",
		type: "text",
		placeholder: "Enter height in inches",
	},
	{
		label: "Number of Furrows *",
		name: "furrow_count",
		type: "text",
		placeholder: "Enter Furrows",
	},
];
