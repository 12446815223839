import React, { useEffect, useState } from "react";
import { Button, Row, Col, Form } from "react-bootstrap";
import { connect, useDispatch } from "react-redux";
import { reduxForm, Field, change } from "redux-form";
import { createStructuredSelector } from "reselect";
import {
	getCustomerDC,
	getCustomerDCAdd,
	getCustomers,
	getCustomerSkuAdd,
	getCustomers_variety,
	getDcSku,
	getForecastFormFromDate,
	getForecastFormLocation,
	getForecastFormToDate,
	getForecastLocationProducts,
	getForecastRenderType,
	getLocationList,
	getSkusList,
	getViewBy,
	thirtyDaysDate,
	getCustomerDC_variety,
	getLocationProducts_variety,
} from "store/selectors/forecastSelectors";
import FormGroups from "utils/formGroup";
import {
	forecastCasesFormFields,
	forecastFormFields,
	forecastList,
	harvestForecastFormFields,
	salesVsProdByVarietyFormFields,
	salesVsProdFormFields,
	viewByOptions,
	viewByOptionsActualSales,
	weeklyAddFields,
} from "./formHelper";
import {
	setForecastColumns,
	fetchForecastData,
	setForecastAddInitialData,
	setLoader,
	forecastResetRefresh,
	setForecastFormData,
	fetchHarvestForecastPoundsData,
	fetchProductionForecastCasesData,
	fetchSalesVsProductionData,
	fetchDailyForecastSF,
	fetchWeeklyForecastSF,
	setDcKeyMapping,
	fetchSalesVsProductionDataVariety,
	setVarietyTypeSP,
	fetchProductionVsActualSalesBySku,
	fetchProductionVsActualSalesByVariety,
} from "store/actions";
import moment from "moment";
import Util from "utils/Util";
import { errorMessage } from "utils/errorMessage";

const ForecastForm = ({
	type,
	fromDate,
	toDate,
	locationList,
	maxDate,
	location,
	productVarietyList,
	skusList,
	handleSubmit,
	setForecastColumns,
	fetchForecastData,
	renderType,
	setForecastAddInitialData,
	setLoader,
	forecastResetRefresh,
	setForecastFormData,
	fetchHarvestForecastPoundsData,
	fetchProductionForecastCasesData,
	fetchSalesVsProductionData,
	customerList,
	dcList,
	skuListSP,
	fetchDailyForecastSF,
	fetchWeeklyForecastSF,
	setDcKeyMapping,
	dcListAdd,
	skuListAdd,
	viewBy,
	customerList_variety,
	dcList_variety,
	productVarietyList_variety,
	reset,
	submitting,
	pristine,
	fetchSalesVsProductionDataVariety,
	setVarietyTypeSP,
	fetchProductionVsActualSalesBySku,
	actualSales,
	fetchProductionVsActualSalesByVariety,
}) => {
	const dispatch = useDispatch();
	const [startDate, setStartDate] = useState(null);
	const [endDate, setEndDate] = useState(null);
	const [submitStatus, setSubmitStatus] = useState(false);
	const [locationSelected, setLocationSelected] = useState(false);
	const onSubmit = (values) => {
		//forecast (production)
		if (type === 1) {
			if (renderType === 0) {
				setLoader();

				const submitData = {
					location_slug: values.location_slug.value,
					forecast: values.forecast_slug.value,
					date_from: moment(values.date[0]).format("YYYY-MM-DD"),
					date_to: moment(values.date[1]).format("YYYY-MM-DD"),
					pv_slugs: values.product_variety.map((item) => item.value),
				};

				const successHandler = () => {
					setLoader();
					setForecastColumns(values.product_variety);
					setForecastFormData({
						location: values.location_slug,
						forecast: values.forecast_slug,
						fromDate: values.date[0],
						toDate: values.date[1],
						productVarieties: values.product_variety,
					});
				};
				const errorHandler = (e) => {
					errorMessage(e);
					setLoader();
				};
				fetchForecastData(submitData, successHandler, errorHandler);
			} else {
				const dates = Util.getDates(values.date[0], values.date[1]);

				const data = {};
				// eslint-disable-next-line array-callback-return
				dates.map((item) => {
					data[item] = values.product_variety.map((p_item) => ({
						name: p_item.label,
						slug: p_item.value,
						value: "",
					}));
				});
				setForecastColumns(values.product_variety);
				setForecastAddInitialData(data);
			}
		}
		//harvest forecast pounds
		else if (type === 2) {
			setLoader();

			const submitData = {
				location_slug: values.location_slug.value,
				exclude_wastage: values.exclude_wastage ? 1 : 0,
				date_from: moment(values.date[0]).format("YYYY-MM-DD"),
				date_to: moment(values.date[1]).format("YYYY-MM-DD"),
				pv_slugs: values.product_variety.map((item) => item.value),
			};

			const successHandler = () => {
				setForecastColumns(values.product_variety);
				setLoader();
			};
			const errorHandler = (e) => {
				errorMessage(e);
				setLoader();
			};
			fetchHarvestForecastPoundsData(submitData, successHandler, errorHandler);
		}
		//production forecast cases
		else if (type === 3) {
			setLoader();
			const submitData = {
				location_slug: values.location_slug.value,
				exclude_wastage: 1,
				date_from: moment(values.date[0]).format("YYYY-MM-DD"),
				date_to: moment(values.date[1]).format("YYYY-MM-DD"),
				sku_slugs: values.sku.map((item) => item.value),
			};

			const successHandler = () => {
				setForecastColumns(values.sku);
				setLoader();
			};
			const errorHandler = (e) => {
				errorMessage(e);
				setLoader();
			};

			fetchProductionForecastCasesData(submitData, successHandler, errorHandler);
		}
		// sales vs prod
		else if (type === 4) {
			setLoader();
			const submitData = {
				location_slug: values.location_slug.value,
				customer_slug: values.customer.map((item) => item.value),
				dc_slug: values.dc.map((item) => item.value),
				date_from: moment(values.date[0]).format("YYYY-MM-DD"),
				date_to: moment(values.date[1]).format("YYYY-MM-DD"),
				sku_slugs: values.sku_sp.map((item) => item.value),
			};

			const successHandler = () => {
				setLoader();
				setForecastColumns(values.sku_sp);
			};
			const errorHandler = (e) => {
				errorMessage(e);
				setLoader();
			};

			if (!actualSales) {
				fetchSalesVsProductionData(submitData, successHandler, errorHandler);
			} else {
				fetchProductionVsActualSalesBySku(submitData, successHandler, errorHandler);
			}
		}
		// daily forecast (sales)
		else if (type === 5) {
			setLoader();
			const submitData = {
				location_slug: values.location_slug.value,
				customer_slug: values.customer.map((item) => item.value),
				dc_slugs: values.dc.map((item) => item.value),
				from: moment(values.date[0]).format("YYYY-MM-DD"),
				to: moment(values.date[1]).format("YYYY-MM-DD"),
				skus: values.sku_sp.map((item) => item.value),
			};

			const successHandler = () => {
				setLoader();
				setForecastColumns(values.sku_sp);
			};
			const errorHandler = (e) => {
				errorMessage(e);
				setLoader();
			};

			fetchDailyForecastSF(submitData, successHandler, errorHandler);
		}
		// weekly forecast (sales)
		else if (type === 6) {
			//  /api/sales-forecasts/weekly
			if (renderType === 0) {
				setLoader();
				const submitData = {
					location_slug: values.location_slug.value,
					customer_slug: values.customer.map((item) => item.value),
					dc_slugs: values.dc.map((item) => item.value),
					from: moment(values.date[0]).format("YYYY-MM-DD"),
					to: moment(values.date[1]).format("YYYY-MM-DD"),
					skus: values.sku_sp.map((item) => item.value),
				};

				const successHandler = () => {
					setLoader();
					setForecastColumns(values.sku_sp);
				};
				const errorHandler = (e) => {
					errorMessage(e);
					setLoader();
				};

				fetchWeeklyForecastSF(submitData, successHandler, errorHandler);
			} else {
				const data = {};
				// eslint-disable-next-line array-callback-return
				values.dc_add.map((item) => {
					data[item.value] = values.sku_add.map((p_item) => ({
						name: p_item.label,
						slug: p_item.value,
						forecast_value: "",
					}));
				});
				const dcKeyMapping = values.dc_add.map((item) => ({
					name: item.label,
					slug: item.value,
				}));
				setForecastColumns(values.sku_add);
				setForecastAddInitialData(data);
				setDcKeyMapping(dcKeyMapping);
			}
		}
		// sales vs production by variety
		else if (type === 7) {
			setLoader();
			const submitData = {
				location_slug:
					values.view_by.value === "production_vs_sales_combined"
						? values.location_variety.map((item) => item.value)
						: values.location_variety.value,
				customer_slug: values.customer_variety.map((item) => item.value),
				dc_slug: values.dc_variety.map((item) => item.value),
				date_from: moment(values.date[0]).format("YYYY-MM-DD"),
				date_to: moment(values.date[1]).format("YYYY-MM-DD"),
				pv_slugs: values.product_variety_sp.map((item) => item.value),
			};

			const successHandler = () => {
				setLoader();
				setForecastColumns(values.product_variety_sp);
				setVarietyTypeSP(values.view_by.value);
			};
			const errorHandler = (e) => {
				errorMessage(e);
				setLoader();
			};
			if (!actualSales) {
				fetchSalesVsProductionDataVariety(submitData, successHandler, errorHandler, values.view_by.value);
			} else {
				fetchProductionVsActualSalesByVariety(submitData, successHandler, errorHandler, values.view_by.value);
			}
		}
		!submitStatus && setSubmitStatus(true);
	};

	const onChange = (dates) => {
		const [start, end] = dates;
		if (dates) {
			if (type === 6) {
				if (renderType === 0) {
					const first = new Date(moment(start).startOf("week"));
					const last = end ? new Date(moment(end).endOf("week")) : null;

					if (startDate && endDate) {
						setStartDate(first);
						setEndDate(null);
					} else {
						setStartDate(first);
						last && setEndDate(last);
					}
				} else {
					const first = new Date(moment(start).startOf("week"));
					const last = new Date(moment(start).endOf("week"));
					setStartDate(first);
					setEndDate(last);
				}
			} else {
				setStartDate(start);
				setEndDate(end);
			}
		} else {
			return;
		}
	};

	useEffect(() => {
		if (type === 6 && renderType === 1 && fromDate) {
			const first = new Date(moment(fromDate).startOf("week"));
			const last = new Date(moment(fromDate).endOf("week"));
			dispatch(change("forecastForm", "date", [first, last]));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [fromDate]);

	useEffect(() => {
		locationSelected && setLocationSelected(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [renderType]);

	useEffect(() => {
		locationSelected &&
			!submitStatus &&
			customerList.length > 0 &&
			dispatch(change("forecastForm", "customer", customerList));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [customerList]);
	useEffect(() => {
		locationSelected &&
			!submitStatus &&
			customerList_variety.length > 0 &&
			dispatch(change("forecastForm", "customer_variety", customerList_variety));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [customerList_variety]);

	useEffect(() => {
		locationSelected && !submitStatus && skusList.length > 0 && dispatch(change("forecastForm", "sku", skusList));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [skusList]);

	useEffect(() => {
		locationSelected && !submitStatus && skuListAdd.length > 0 && dispatch(change("forecastForm", "sku_add", skuListAdd));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [skuListAdd]);

	useEffect(() => {
		locationSelected && !submitStatus && skuListSP.length > 0 && dispatch(change("forecastForm", "sku_sp", skuListSP));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [skuListSP]);

	useEffect(() => {
		locationSelected && !submitStatus && dcList.length > 0 && dispatch(change("forecastForm", "dc", dcList));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dcList]);

	useEffect(() => {
		locationSelected && !submitStatus && dcListAdd.length > 0 && dispatch(change("forecastForm", "dc_add", dcListAdd));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dcListAdd]);

	useEffect(() => {
		locationSelected &&
			!submitStatus &&
			dcList_variety.length > 0 &&
			dispatch(change("forecastForm", "dc_variety", dcList_variety));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dcList_variety]);

	const getOptions = (name) => {
		switch (name) {
			case "location_slug":
				return locationList;
			case "forecast_slug":
				return forecastList;
			case "product_variety":
				return productVarietyList;
			case "sku":
				return skusList;
			case "customer":
				return customerList;
			case "dc":
				return dcList;
			case "sku_sp":
				return skuListSP;
			case "customer_add":
				return customerList;
			case "dc_add":
				return dcListAdd;
			case "sku_add":
				return skuListAdd;

			// prod vs sales by variety
			case "view_by":
				return actualSales ? viewByOptionsActualSales : viewByOptions;
			case "location_variety":
				return locationList;
			case "customer_variety":
				return customerList_variety;
			case "dc_variety":
				return dcList_variety;
			case "product_variety_sp":
				return productVarietyList_variety;
			default:
				return [];
		}
	};

	const onSelect = (event, newValue, previousValue, field) => {
		if (field === "location_slug") {
			submitStatus && setSubmitStatus(false);
			setLocationSelected(true);
			if (type === 1 || type === 2) {
				dispatch(change("forecastForm", "product_variety", []));
			} else if (type === 3) {
				dispatch(change("forecastForm", "sku", []));
			} else if (type === 6 && renderType === 1 && previousValue !== undefined) {
				dispatch(change("forecastForm", "customer_add", null));
				dispatch(change("forecastForm", "dc_add", null));
				dispatch(change("forecastForm", "sku_add", null));
			} else if (type === 4 || type === 5 || type === 6) {
				dispatch(change("forecastForm", "customer", []));
				dispatch(change("forecastForm", "dc", []));
				dispatch(change("forecastForm", "sku_sp", []));
			}
		} else if (field === "customer") {
			dispatch(change("forecastForm", "dc", []));
			dispatch(change("forecastForm", "sku_sp", []));
		} else if (field === "dc") {
			dispatch(change("forecastForm", "sku_sp", []));
		} else if (field === "view_by") {
			// if (previousValue !== undefined) {
			if (newValue.value === "production_vs_sales_combined") {
				dispatch(change("forecastForm", "location_variety", []));
			} else {
				dispatch(change("forecastForm", "location_variety", ""));
			}
			dispatch(change("forecastForm", "customer_variety", []));
			dispatch(change("forecastForm", "dc_variety", []));
			dispatch(change("forecastForm", "product_variety_sp", []));
		} else if (field === "location_variety") {
			submitStatus && setSubmitStatus(false);
			setLocationSelected(true);
			if (previousValue !== undefined) {
				// if (previousValue.length > newValue.length) {
				dispatch(change("forecastForm", "customer_variety", []));
				dispatch(change("forecastForm", "dc_variety", []));
				dispatch(change("forecastForm", "product_variety_sp", []));

				// dispatch(change("forecastForm", "product_variety_sp", []));
				// }
			}
		} else if (field === "customer_variety") {
			if (previousValue !== undefined) {
				if (previousValue.length > newValue.length) {
					dispatch(change("forecastForm", "dc_variety", []));
					dispatch(change("forecastForm", "product_variety_sp", []));

					// dispatch(change("forecastForm", "product_variety_sp", []));
				}
			}
		} else if (field === "customer_add" && previousValue !== undefined) {
			dispatch(change("forecastForm", "dc_add", []));
			dispatch(change("forecastForm", "sku_add", []));
		} else if (field === "dc_add" && previousValue !== undefined) {
			dispatch(change("forecastForm", "sku_add", []));
		} else if (field === "dc_variety") {
			if (previousValue !== undefined) {
				if (previousValue.length > newValue.length) {
					dispatch(change("forecastForm", "product_variety_sp", []));

					// dispatch(change("forecastForm", "product_variety_sp", []));
				}
			}
		}
	};

	const getFormFieldHtml = (formfields) => {
		let formFieldHtml = [];

		formFieldHtml = formfields.map((field, idx) => {
			return (
				<React.Fragment key={idx}>
					<Col className="forecast-form-column">
						<Form.Group
							className={field.name === "exclude_wastage" && "mt-5"}
							controlId={`formControl ${field.name}`}>
							{field.name === "date" ? (
								<Field
									name={field.name}
									type={field.type}
									label={field.label}
									placeholder={field.placeholder}
									component={FormGroups}
									startDate={startDate}
									endDate={endDate}
									onChange={onChange}
									validate={field.validate}
									dateFormat={"MM-DD-YYYY"}
									pickerTypeSelector={0}
									//maxDate={fromDate && !toDate && maxDate}
									onBlur={(event) => event.preventDefault()}
								/>
							) : (
								<Field
									name={field.name}
									type={
										type === 6 && renderType === 1 && field.name === "customer"
											? "select-react"
											: type === 7 &&
											  field.name === "location_variety" &&
											  viewBy &&
											  viewBy.value === "production_vs_sales_combined"
											? "select-react-expand-all"
											: field.type
									}
									label={field.label}
									placeholder={field.placeholder}
									component={FormGroups}
									validate={field.validate}
									disable={
										field.name === "product_variety" || field.name === "sku"
											? location
												? false
												: true
											: false
									}
									multiple={
										type === 6 && renderType === 1 && field.name === "customer"
											? false
											: field.name === "product_variety" ||
											  field.name === "sku" ||
											  field.name === "customer" ||
											  field.name === "dc" ||
											  field.name === "sku_sp"
											? true
											: false
									}
									options={getOptions(field.name)}
									onChange={(event, newValue, previousValue, name) =>
										onSelect(event, newValue, previousValue, name)
									}
									selectMenuHeight={200}
									checked={true}
								/>
							)}
						</Form.Group>
					</Col>
				</React.Fragment>
			);
		});
		return (
			<Row className="forecast-form-row">
				{formFieldHtml}
				<Col className="form-submit-col">
					<Button type="submit" disabled={submitting} className="apply-btn">
						Apply
					</Button>
					<Button
						className="reset-btn"
						disabled={pristine || submitting}
						onClick={() => {
							reset();
							forecastResetRefresh();
							setStartDate(null);
							setEndDate(null);
						}}>
						<i className="icon icon-reset mr-2" />
					</Button>
				</Col>
			</Row>
		);
	};

	return (
		<div className="forecast-form-wrapper">
			<Form onSubmit={handleSubmit(onSubmit)} className="forecastForm">
				{type === 1
					? getFormFieldHtml(forecastFormFields)
					: type === 2
					? getFormFieldHtml(harvestForecastFormFields)
					: type === 3
					? getFormFieldHtml(forecastCasesFormFields)
					: type === 4 || type === 5 || (type === 6 && renderType === 0)
					? getFormFieldHtml(salesVsProdFormFields)
					: type === 6 && renderType === 1
					? getFormFieldHtml(weeklyAddFields)
					: type === 7
					? getFormFieldHtml(salesVsProdByVarietyFormFields)
					: null}
			</Form>
		</div>
	);
};

const mapStateToProps = createStructuredSelector({
	fromDate: getForecastFormFromDate,
	locationList: getLocationList,
	skusList: getSkusList,
	productVarietyList: getForecastLocationProducts,
	maxDate: thirtyDaysDate,
	location: getForecastFormLocation,
	renderType: getForecastRenderType,
	//sales vs prod
	toDate: getForecastFormToDate,
	customerList: getCustomers,
	dcList: getCustomerDC,
	skuListSP: getDcSku,

	//weekly add
	dcListAdd: getCustomerDCAdd,
	skuListAdd: getCustomerSkuAdd,

	// prod vs sales variety
	viewBy: getViewBy,
	customerList_variety: getCustomers_variety,
	dcList_variety: getCustomerDC_variety,
	productVarietyList_variety: getLocationProducts_variety,
});

export default connect(mapStateToProps, {
	setForecastColumns,
	fetchForecastData,
	setForecastAddInitialData,
	setLoader,
	forecastResetRefresh,
	setForecastFormData,
	fetchHarvestForecastPoundsData,
	fetchProductionForecastCasesData,
	fetchSalesVsProductionData,
	fetchDailyForecastSF,
	fetchWeeklyForecastSF,
	setDcKeyMapping,
	fetchSalesVsProductionDataVariety,
	setVarietyTypeSP,
	fetchProductionVsActualSalesBySku,
	fetchProductionVsActualSalesByVariety,
})(reduxForm({ form: "forecastForm", enableReinitialize: true })(ForecastForm));

// TYPES

// 1 = forecast (Prodcution forecast)
// 2 = harvest forecast pounds (Prodcution forecast)
// 3 = production forecast cases (Prodcution forecast)
// 4 = prod vs sales forecast by Sku (Prod vs Sales)
// 5 = daily forecast (Sales forecast)
// 6 = Weekly forecast (Sales forecast)
// 7 = production vs sales forecast by Variety (Prod vs Sales)
// 8 = production vs actual sales forecast by Sku (Prod vs Actual Sales)
// 9 = production vs actual sales forecast by Variety (Prod vs Actual Sales)
