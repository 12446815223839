/* eslint-disable prefer-const */
/* eslint-disable no-useless-concat */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { ContentWrap } from "../../elements";
import Chart from "./chart";
import ReportForm from "./form";
import { fetchClimateGraphDetails, fetchClimateGraphDropDownDetails } from "../../store/actions";
import Util from "../../utils/Util";
import { errorMessage } from "../../utils/errorMessage";
import { isEmpty, capitalize } from "lodash";
import "./reports.scss";
import TableLoader from "../../utils/table/tableLoader";

const Climate = ({ fetchClimateGraphDetails, fetchClimateGraphDropDownDetails }) => {
	const pageTitle = "Climate";
	const [isLoading, setisLoading] = useState(false);
	const [btnTitle, setbtnTitle] = useState("Apply");
	const [timeFrame, setTimeFrame] = useState("");
	const [data, setData] = useState({});
	const [isData, setIsData] = useState(false);

	useEffect(() => {
		fetchClimateGraphDropDownDetails();
	}, [fetchClimateGraphDropDownDetails]);

	//To fill missing months in x-axis with value 0
	const fillMonths = (chartData, startMonth, endMonth, startYear, endYear) => {
		const allMonths = [
			"January",
			"February",
			"March",
			"April",
			"May",
			"June",
			"July",
			"August",
			"September",
			"October",
			"November",
			"December",
		];
		const result = [];
		let months = [];
		if (startYear === endYear) {
			// fillmonths in a year
			months = allMonths.slice(startMonth - 1, endMonth);
			months.forEach((month, index) => (months[index] = month + "-" + endYear));
		} else if (endYear - startYear > 1) {
			months = allMonths.slice(startMonth - 1);
			months.forEach((month, index) => {
				months[index] = month + "-" + startYear;
			});

			for (let index = 1; index < endYear - startYear; index++) {
				const interMonths = allMonths.map((month) => month + "-" + Number(startYear + index));
				months.push(...interMonths);
			}
			const lastMonths = allMonths.slice(0, endMonth).map((month) => month + "-" + endYear);
			months.push(...lastMonths);
		} else {
			//for adjacent start and endyear
			months = allMonths.slice(startMonth - 1).map((month) => month + "-" + startYear);
			months.push(...allMonths.slice(0, endMonth).map((month) => month + "-" + endYear));
		}

		//check for months in x-axis range
		months.forEach((month) => {
			const item = chartData.find((dataItem) => dataItem.date === month);
			if (item) {
				result.push(item);
			} else {
				result.push({ date: month, value: 0 });
			}
		});

		return result;
	};

	const submitCustomerDetails = (values) => {
		setData([]);
		setisLoading(true);
		setTimeFrame("");

		if (!isLoading) {
			setbtnTitle("Fetching...");
			const fromDate =
				values.date && values.graph_types.value !== "linear_plot" && values.timeframe.value === "monthly"
					? Util.getFirstDay(new Date(values.date[0]), "month")
					: values.date && values.graph_types.value !== "linear_plot" && values.timeframe.value === "yearly"
					? Util.getFirstDay(new Date(values.date[0]), "year")
					: Util.getMDYFormatUtcDate(new Date(values.date[0]));

			const toDate =
				values.date && values.graph_types.value !== "linear_plot" && values.timeframe.value === "monthly"
					? Util.getLastDay(new Date(values.date[1] ? values.date[1] : values.date[0]), "month")
					: values.date && values.graph_types.value !== "linear_plot" && values.timeframe.value === "yearly"
					? Util.getLastDay(new Date(values.date[1] ? values.date[1] : values.date[0]), "year")
					: Util.getMDYFormatUtcDate(new Date(values.date[1] ? values.date[1] : values.date[0]));
			const parameters =
				values && values.parameters
					? values.parameters.map(function (value, index) {
							return value.value;
					  })
					: [];
			const locations =
				values && values.location && values.graph_types.value === "climate_all"
					? values.location.map(function (value, index) {
							return value.value;
					  })
					: values.location
					? [values.location.value]
					: "";
			const compartments =
				values && values.compartment
					? values.compartment.map(function (value, index) {
							return value.value;
					  })
					: [];
			const submissionData = {
				location_slugs: locations,
				graph_types: values.graph_types ? values.graph_types.value : "",
				report_start_date: fromDate ? fromDate : "",
				report_end_date: toDate ? toDate : "",
				timeframe: values.timeframe ? values.timeframe.value : "",
				timezone: Util.getLocalTimeZone(),
				parameters: parameters,
				compartment: compartments,
			};

			const successHandler = (event) => {
				setbtnTitle("Apply");
				setisLoading(false);
				isEmpty(event.data) ? setIsData(true) : setIsData(false);
				let dataChart = event.data;
				if (submissionData.timeframe && submissionData.timeframe === "monthly") {
					const startDate = submissionData.report_start_date.split("-");
					const endDate = submissionData.report_end_date.split("-");

					for (let key in dataChart) {
						dataChart[key].data = fillMonths(
							dataChart[key].data,
							Number(startDate[1]),
							Number(endDate[1]),
							Number(startDate[0].substr(-2)),
							Number(endDate[0].substr(-2))
						);
					}
				}
				setData(dataChart);
				setTimeFrame(submissionData.timeframe);
			};
			const errorHandler = (event) => {
				setbtnTitle("Apply");
				setisLoading(false);
				errorMessage(event);
			};
			fetchClimateGraphDetails(submissionData, successHandler, errorHandler);
		}
	};

	return (
		<ContentWrap>
			<div className="reports-container-climate h-100">
				<div className="d-flex align-items-center mb-4">
					<div className="flex-fill">
						<h1>{pageTitle}</h1>
						<span className="page-bread-crumbs">
							<a href="/dashboard">Home</a> /Climate Reports{" "}
						</span>
					</div>
				</div>
				<ReportForm submitCustomerDetails={submitCustomerDetails} btnTitle={btnTitle} isLoading={isLoading} />
				{isLoading ? (
					<TableLoader />
				) : !isEmpty(data) ? (
					<Chart data={data} isLoading={isLoading} timeFrame={capitalize(timeFrame)} />
				) : (
					<p className="card-text-empty text-center">{!isLoading && isData ? "No Data Available" : ""}</p>
				)}
			</div>
		</ContentWrap>
	);
};

const mapDispatchToProps = { fetchClimateGraphDetails, fetchClimateGraphDropDownDetails };
export default connect(null, mapDispatchToProps)(Climate);
