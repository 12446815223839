import React from "react";
import Select from "react-select";
import DatePicker from "react-datepicker";
import i18next from "i18next";

export const SKUDateDropdown = ({ value, onSelect }) => (
	<>
		<p className="form-label mb-2">{i18next.t("label.date")}</p>
		<DatePicker
			className="form-control form-control-date"
			selected={value}
			onChange={(e) => onSelect("DATE", e)}
			placeholderText={i18next.t("label.choose_date")}
			dateFormat="MM/dd/yyyy"
			calendarClassName="bright-farms-calendar"
			autoComplete="off"
		/>
	</>
);

export const SKULocationDropdown = ({ value, options, onSelect }) => (
	<>
		<p className="form-label mb-2">{i18next.t("label.location")}</p>
		<Select
			value={value}
			onChange={(e) => onSelect("LOCATION", e)}
			options={options}
			isClearable={true}
			className="custom-select-box"
			placeholder={i18next.t("label.select_location")}
		/>
	</>
);

export const SKUListDropdown = ({ value, options, onSelect }) => (
	<>
		<p className="form-label mb-2">{i18next.t("label.sku")}</p>
		<Select
			value={value}
			onChange={(e) => onSelect("SKU", e)}
			options={options}
			isClearable={true}
			className="custom-select-box"
			placeholder={i18next.t("label.select_sku")}
		/>
	</>
);
