import { CLIMATE } from "../types";
import fetchHandler from "../../utils/fetchHandler";

export const fetchClimateGraphDetails = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `get_priva_chart_data`,
		method: "POST",
		secure: true,
		actionType: CLIMATE.FETCH_CLIMATE_GRAPH_DETAILS,
		body: JSON.stringify(values),
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const fetchClimateGraphDropDownDetails = (successHandler, errorHandler) => {
	const fetchOptions = {
		url: `get_priva_filter_data`,
		method: "GET",
		secure: true,
		actionType: CLIMATE.FETCH_CLIMATE_GRAPH_DROPDOWN_DETAILS,
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};
export const fetchClimateTableDetails = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `get_priva_table_data`,
		method: "POST",
		secure: true,
		actionType: CLIMATE.FETCH_CLIMATE_TABLE_DETAILS,
		body: JSON.stringify(values),
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const fetchClimateGraphParameters = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `get-climate-all-filter`,
		method: "POST",
		secure: true,
		actionType: CLIMATE.FETCH_CLIMATE_PARAMETERS,
		body: JSON.stringify(values),
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};
export const fetchClimatePondParameters = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `get-ponds`,
		method: "POST",
		secure: true,
		actionType: CLIMATE.FETCH_POND_PARAMETERS,
		body: JSON.stringify(values),
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};
