import React, { useState } from "react";
import { map, join } from "lodash";
import LocationUsers from "./locationUsers";
import { ButtonLink } from "../../../elements";
import { Row, Col } from "react-bootstrap";

const LocationView = ({ details = {}, handleEditPanel, closeModal, updatePermission }) => {
	const [showUsers, setShowUsers] = useState(false);

	const {
		city,
		column_board_count,
		column_per_lane,
		lanes_per_pond,
		location_name,
		pond,
		drum_size,
		germination_chambers,
		//product_varieties,
		slug,
		state,
		user_list,
		wastage_percentage,
		luna_facility_id,
	} = details;
	const labelledValueFullRow = (label, value, unit = null) => (
		<Col className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-3" key={label}>
			<div key={label}>
				<p className="d-flex align-items-center text-muted mb-2">
					<span className="light-badge-pill"></span>
					<span className="view-labels">{label}</span>
				</p>
				<h6 className="ml-4 view-values">{value ? `${value}` : "-"}</h6>
			</div>
		</Col>
	);
	const labelledValue = (label, value, flex) => (
		<div className={`flex-${flex}`}>
			<p className="d-flex align-items-center text-muted text-13px mb-1">
				<span className="light-badge-pill"></span>
				{label}
			</p>
			<h6 className="ml-4">{value || "-"}</h6>
		</div>
	);

	return (
		<div className="px-4 pt-3 pb-3">
			{/* Title */}
			<div className="d-flex align-items-center justify-content-between mb-4">
				<h2 className="mb-0">Location Information</h2>
				{updatePermission ? (
					<button className="btn btn-cell btn-light btn-sm ml-2" onClick={() => handleEditPanel(slug)}>
						<span className="edit-icon-background">
							<i className="icon icon-edit" />
						</span>
					</button>
				) : (
					""
				)}
			</div>

			{/* Details */}
			<div className="d-flex mb-4">
				{labelledValue("Location Name", location_name, 2)}
				{labelledValue("City", city, 2)}
			</div>
			<div className="d-flex mb-4">
				{labelledValue("State", state, 2)}
				{labelledValue("Lanes per Pond", lanes_per_pond, 2)}
			</div>
			<div className="d-flex mb-4">
				{labelledValue("Columns per Lane", column_per_lane, 2)}
				{labelledValue("Styrofoam Boards per Column", column_board_count, 2)}
			</div>
			<div className="d-flex mb-4">{labelledValue("Wastage (%)", wastage_percentage, 1)}</div>
			<Row>{labelledValueFullRow("Pond Naming", join(map(pond, "value"), ", "))}</Row>
			<Row>{labelledValueFullRow("Drum Size", join(map(drum_size, "value"), ", "))}</Row>
			<Row>{labelledValueFullRow("Germination Chambers", join(map(germination_chambers, "value"), ", "))}</Row>

			{/* {labelledValue("Product Varieties", join(map(product_varieties, "product_variety"), ", "))} */}

			{/* Users */}
			<ButtonLink className="mt-4 mt-2 text-green" onClick={() => setShowUsers(!showUsers)}>
				View Users
			</ButtonLink>
			{showUsers && <LocationUsers data={user_list} />}
		</div>
	);
};

export default LocationView;
