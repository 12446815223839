import React from "react";
import { Col } from "react-bootstrap";
import { Field } from "redux-form";
import { ButtonLink } from "elements";
import FormGroups from "../../../../utils/formGroup";
import { crossSupplyBoardForm } from "../formHelper";
import { renderColumns } from "./helper";

const CrossSupplyBoard = ({
	fields,
	skuOptions,
	resetSku,
	skuList,
	setSkuList,
	deliveryDate,
	meta: { error, submitFailed },
}) => {
	const handleDeletePanel = (index) => {
		if (fields.get(index).slug === undefined) {
			fields.remove(index);
		} else {
			resetSku(fields.get(index).slug.value);
			fields.remove(index);
		}
	};

	const onChangeSku = (event) => {
		const newArr = skuList.map((item) => {
			return item.slug === event.value
				? { ...item, isdisabled: true }
				: item.isdisabled
				? item
				: { ...item, isdisabled: false };
		});
		setSkuList(newArr);
	};

	const columns = renderColumns({ handleDeletePanel });

	const renderTableColumnGroup = () => {
		return (
			<div className={`bright-farms-table-col-group`}>
				{columns.map((column, columnIndex) => (
					<div key={columnIndex} className={`bright-farms-table-col ${column.className ? column.className : ""}`} />
				))}
			</div>
		);
	};

	const renderTableHead = () => {
		return (
			<div className={`bright-farms-table-thead`}>
				<div className={`bright-farms-table-tr`}>
					{columns.map((column, columnIndex) => {
						return (
							<div
								key={columnIndex}
								className={`bright-farms-table-th ${column.className ? column.className : ""} ${
									column.headerClassName ? column.headerClassName : ""
								} `}>
								<span>{column.name}</span>
							</div>
						);
					})}
				</div>
			</div>
		);
	};

	const renderTableBody = () => {
		const sku = crossSupplyBoardForm.sku;
		const cases = crossSupplyBoardForm.cases;
		const date = crossSupplyBoardForm.date;
		return (
			<div className={`bright-farms-table-tbody`}>
				{fields.map((item, index) => (
					<>
						<div key={index} className={`bright-farms-table-tr`}>
							{columns.map((column, columnIndex) => (
								<div
									key={columnIndex}
									className={`bright-farms-table-td ${column.className ? column.className : ""}`}>
									{column.render ? (
										fields.length > 1 ? (
											column.render(index)
										) : (
											""
										)
									) : column.accessor === "skus" ? (
										<Col className="cs-board-sku product-variety-listing">
											<Field
												name={`${item}.${sku.name}`}
												type={sku.type}
												component={FormGroups}
												validate={sku.validate}
												placeholder={sku.placeholder}
												multiple={false}
												options={skuOptions}
												onFocus={(event) => {
													resetSku(event.value);
												}}
												onChange={(event) => {
													onChangeSku(event);
												}}
											/>
										</Col>
									) : column.accessor === "delivery_date" ? (
										<Col className="cs-board-date">
											<Field
												name={`${item}.${date.name}`}
												type={date.type}
												placeholder={date.placeholder}
												component={FormGroups}
												minDate={date.minDate}
												selectedDate={deliveryDate}
												validate={date.validate}
												popperPlacement={date.popperPlacement}
											/>
										</Col>
									) : (
										<Col className="cs-board-cases">
											<Field
												name={`${item}.${cases.name}`}
												type={cases.type}
												component={FormGroups}
												validate={cases.validate}
												placeholder={cases.placeholder}
											/>
										</Col>
									)}
								</div>
							))}
						</div>

						{index + 1 === fields.length && (
							<Col className="col-12 d-flex justify-content-center text-center">
								<ButtonLink onClick={() => fields.push({})}>
									<i className="icon icon-plus mr-2" />
									Add
								</ButtonLink>
							</Col>
						)}
					</>
				))}
			</div>
		);
	};

	return (
		<div className={`bright-farms-table`}>
			{columns && columns.length ? renderTableColumnGroup() : null}
			{columns && columns.length ? renderTableHead() : null}
			{renderTableBody()}
			{submitFailed && error && <span>{error}</span>}
		</div>
	);
};

export default CrossSupplyBoard;
