import fetchHandler from "../../utils/fetchHandler";
import {DASHBOARD} from "../types";
export const fetchPondVisualizationData = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `dashboard/pond-visualisation`,
		method: "POST",
		secure: true,
		body: JSON.stringify(values),
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const fetchPondList = (slug, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `dashboard/get-pond-dropdown/${slug}`,
		method: "GET",
		secure: true,
		actionType: DASHBOARD.POND_LIST,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};
