import React, { useEffect, useState } from "react";
import TableForm from "./tableForm";
import { useSelector } from "react-redux";

const DosingCalculatorTable = (props) => {
	const { initialData, headings } = props;
	const [initialValues, setinitialValues] = useState(null);
	const renderType = useSelector((state) => state.DosingCalculator.pondId);


	useEffect(() => {
		if (initialData) {
			const dataObj = {};
			// eslint-disable-next-line array-callback-return
			initialData.map((item, i) => {
				const temp = item.slug.replace(".","~_~")
				dataObj[temp] = {};
				// eslint-disable-next-line array-callback-return
				headings.map((item_i, j) => {
					if (item_i.custom_accessor) {
						dataObj[temp][item_i.slug] = item[item_i.custom_accessor];
						dataObj[temp][item_i.custom_accessor] = item[item_i.slug];
					} else {
						dataObj[temp][item_i.slug] = item[item_i.slug];
					}
				});
			});
			setinitialValues(dataObj);
		}
	}, [headings, initialData]);

	return initialValues ? (
		<TableForm
			form={props.tableId ? `dosingCalculatorTable_${props.tableId}_${renderType}` : `dosingCalculatorTable`}
			initialValues={initialValues}
			{...props}
		/>
	) : null;
};

export default DosingCalculatorTable;
