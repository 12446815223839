import { LOCATIONS } from "../types";

const initialState = {
	locationsDropdownList: [],
	locationProductsList: [],
	locationUsersList: [],
	allLocationsList: [],
	locationDetails: {},
	skuPriority: {},
	customerPriority: {},
	manageStatus: [],
};

export default function (state = initialState, { type, payload = {} }) {
	switch (type) {
		case LOCATIONS.FETCH_LOCATIONS:
			return {
				...state,
				locationsDropdownList: payload.data || [],
			};
		case LOCATIONS.FETCH_LOCATION_PRODUCTS:
			return {
				...state,
				locationProductsList: payload.data || [],
			};
		case LOCATIONS.FETCH_LOCATION_USERS:
			return {
				...state,
				locationUsersList: payload.data || [],
			};
		case LOCATIONS.FETCH_ALL_LOCATIONS:
			return {
				...state,
				allLocationsList: payload.data,
			};
		case LOCATIONS.FETCH_LOCATION_DETAILS:
			state = {
				...state,
				locationDetails: payload.data || {},
			};
			return state;
		case LOCATIONS.CLEAR_LOCATION_DETAILS:
			return {
				...state,
				locationDetails: {},
			};
		case LOCATIONS.FETCH_LOCATION_SKU_PRIORITY:
			return {
				...state,
				skuPriority: payload.data,
			};
		case LOCATIONS.FETCH_LOCATION_CUSTOMER_PRIORITY:
			return {
				...state,
				customerPriority: payload.data,
			};
		case LOCATIONS.FETCH_SKU_BASED_LIST:
			return {
				...state,
				manageStatus: payload.data,
			};
		case LOCATIONS.FETCH_PRODUCT_BASED_LIST:
			return {
				...state,
				manageStatus: payload.data,
			};
		default:
			return state;
	}
}
