import React from "react";
import { connect, useDispatch } from "react-redux";
import { Field, change } from "redux-form";
import { createStructuredSelector } from "reselect";
import { Row, Col } from "react-bootstrap";
import { ToolTipCheckBox } from "utils/checkbox";
import { ListingBtn } from "elements";
import {
	getInventoryItems,
	checkInventoryDateLessThanCurrent,
	getInventoryType,
	getProductVarietyFields,
	getSkuFields,
} from "store/selectors/ordersPackagingSelectors";
import { filter } from "lodash";

const componentGroup = (field) => {
	switch (field.type) {
		case "div":
			return <div>{field.defaultValue}</div>;
		case "text":
			return (
				<input
					type="text"
					className="form-control"
					value={field.defaultValue}
					autoFocus={field.autoFocus}
					onKeyDown={field.onKeyDown}
					onBlur={(e) => field.input.onBlur(e)}
					onFocus={(e) => field.input.onFocus(e)}
					onChange={(e) => field.input.onChange(e)}
				/>
			);
		default:
			return;
	}
};

const InventoryTableBody = ({
	checkInventoryDateLessThanCurrent,
	setaddAllPackage,
	inventoryType,
	value,
	index,
	count,
	label,
	addToPackage,
	editable,
	data,
	skuFields,
	productVarietyFields,
	editMode,
}) => {
	const dispatch = useDispatch();
	return (
		<Row key={index} className="modal-table-body-row">
			{checkInventoryDateLessThanCurrent && (
				<Col className="modal-table-col" style={{ width: 35, flex: "inherit" }}>
					<ToolTipCheckBox
						tooltipWidth={140}
						tooltipFontSize={11}
						tooltipText="Add to packaging"
						bottomArrowLeftPosition={25}
						tooltipLeftPostion={43}
						checked={addToPackage}
						onChange={(e) => {
							addToPackage && setaddAllPackage(false);
							dispatch(
								change("inventoryForm", `itemSelected[${index}].addToPackage`, addToPackage ? false : true)
							);
						}}
					/>
				</Col>
			)}
			<Col className={"modal-table-col cont-wrap"} style={{ flex: 1.1 }}>
				<Field name={`${value}-item`} type={"div"} component={componentGroup} value="qwe" defaultValue={label} />
			</Col>
			<Col className={"modal-table-col cont-wrap"} style={{ flex: 1, paddingRight: 0 }}>
				<Field
					name={`${value}-value`}
					defaultValue={count}
					type={editable ? "text" : "div"}
					component={editable ? componentGroup : componentGroup}
					placeholder={inventoryType === "UnSoldCases" ? "Enter Cases" : "Enter Lbs"}
					autoFocus
					onKeyDown={(event) => {
						if (event.key === "Enter") {
							event.preventDefault();
							document.getElementById(`${value}-edit-id`).click();
						}
					}}
					onChange={(e) => dispatch(change("inventoryForm", `itemSelected[${index}].count`, e.target.value))}
				/>
			</Col>
			<Col className="modal-table-col" style={{ width: 95, flex: "inherit" }}>
				<ListingBtn
					id={`${value}-edit-id`}
					tooltipFontSize={11}
					tooltipText={editable ? "Save" : "Edit"}
					type="span"
					iconClass={editable ? "icon icon-check modal-table-icon " : "icon icon-edit modal-table-icon "}
					onClick={(e) => {
						if (editable) {
							dispatch(change("inventoryForm", `itemSelected[${index}].editable`, false));
						} else {
							dispatch(change("inventoryForm", `itemSelected[${index}].editable`, true));
						}
					}}
				/>
				{!editMode && (
					<ListingBtn
						tooltipFontSize={11}
						tooltipText="Delete"
						type="span"
						iconClass="icon icon-trash modal-table-icon "
						onClick={(e) => {
							const arr = filter(data, (obj) => obj.value !== value);
							const arr_2 =
								inventoryType === "UnSoldCases"
									? filter(skuFields, (obj) => obj.value !== value)
									: filter(productVarietyFields, (obj) => obj.value !== value);
							dispatch(change("inventoryForm", `itemSelected`, arr));
							dispatch(
								change("inventoryForm", inventoryType === "UnSoldCases" ? "sku" : "product_variety", arr_2)
							);
						}}
					/>
				)}
			</Col>
		</Row>
	);
};

const mapStateToProps = createStructuredSelector({
	data: getInventoryItems,
	checkInventoryDateLessThanCurrent: checkInventoryDateLessThanCurrent,
	inventoryType: getInventoryType,
	productVarietyFields: getProductVarietyFields,
	skuFields: getSkuFields,
});

const areEqual = (prevProps, nextProps) => {
	return (
		prevProps.checkInventoryDateLessThanCurrent === nextProps.checkInventoryDateLessThanCurrent &&
		prevProps.inventoryType === nextProps.inventoryType &&
		prevProps.value === nextProps.value &&
		prevProps.index === nextProps.index &&
		prevProps.count === nextProps.count &&
		prevProps.label === nextProps.label &&
		prevProps.addToPackage === nextProps.addToPackage &&
		prevProps.editable === nextProps.editable &&
		prevProps.data === nextProps.data
	);
};

export default connect(mapStateToProps)(React.memo(InventoryTableBody, areEqual));
