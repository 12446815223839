import React, { useEffect } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import {
	getCroptimalsData,
	getCroptimalsTableColumn,
	getSelectedSeason,
	getSelectedLocation,
	getProductKeyMapping,
} from "store/selectors/croptimalsSelectors";
import CroptimalsForm from "../manage";
import ForecastTable from "utils/forecastTable";
import { isEmpty, keys, values } from "lodash";
import { successMessage } from "utils/successMessage";
import { errorMessage } from "utils/errorMessage";
import { addCroptimals, setLoaderCroptimals, resetRefreshCroptimals, setRenderTypeCroptimals } from "store/actions";
import { number } from "constant/formValidators";

const AddCroptimals = ({
	data,
	columns,
	location,
	season,
	productKeyMapping,
	setLoaderCroptimals,
	addCroptimals,
	resetRefreshCroptimals,
	setRenderTypeCroptimals,
}) => {
	const onSave = (tableData) => {
		setLoaderCroptimals();
		const submitData = {
			location_slug: location.value,
			season_name: season.label,
			croptimals: values(tableData).map((item, i) => ({
				pv_slug: keys(tableData)[i].replace("croptimals_",""),
				nutrients: item.map((item_o, j) => ({
					nutrient_slug: item_o.slug,
					nutrient_value: item_o.value,
				})),
			})),
		};

		const successHandler = (e) => {
			successMessage(e);
			setLoaderCroptimals();
			resetRefreshCroptimals();
			setRenderTypeCroptimals(0);
		};

		const errorHandler = (e) => {
			errorMessage(e);
			setLoaderCroptimals();
		};

		addCroptimals(submitData, successHandler, errorHandler);
	};

	useEffect(() => {
		return () => {
			resetRefreshCroptimals();
		};
	}, [resetRefreshCroptimals]);

	return (
		<div className="listing-wrapper-croptimals">
			<CroptimalsForm />
			{data &&
				(!isEmpty(columns) ? (
					<>
						<ForecastTable
							editable={true}
							columns={columns}
							fixedColumnHeading="Product Variety"
							initialData={data}
							onSave={onSave}
							keyMapping={productKeyMapping}
							onCancel={() => resetRefreshCroptimals()}
							validation={[number]}
							croptimals = {true}
						/>
					</>
				) : (
					<div className="no-results-found text-center">No Nutrients Added</div>
				))}
		</div>
	);
};

const mapStateToProps = createStructuredSelector({
	data: getCroptimalsData,
	columns: getCroptimalsTableColumn,
	location: getSelectedLocation,
	season: getSelectedSeason,
	productKeyMapping: getProductKeyMapping,
});

export default connect(mapStateToProps, {
	addCroptimals,
	setLoaderCroptimals,
	resetRefreshCroptimals,
	setRenderTypeCroptimals,
})(AddCroptimals);
