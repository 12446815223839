import { requiredDateObj, required } from "constant/formValidators";

export const inventoryList = [
	{
		value: "UnSoldCases",
		label: "Packed Cases",
	},
	{
		value: "UnPackedLbs",
		label: "Unpacked Lbs",
	},
];

const today = new Date();

export const threeDaysBack = today.setDate(today.getDate() - 3);

export const inventoryForm = {
	details: [
		{
			label: "label.location_required",
			name: "location_slug",
			type: "select-react",
			placeholder: "label.select_location",
			validate: [required],
		},
		{
			label: "label.pack_date_req",
			name: "date",
			type: "date-picker",
			placeholder: "label.enter_date",
			validate: [requiredDateObj],
			minDate: threeDaysBack,
		},
		{
			label: "label.inventory_req",
			name: "inventory",
			type: "select-react",
			placeholder: "label.inventory_type",
			validate: [required],
		},
	],
	bySKU: [
		{
			label: "label.sku_req",
			name: "sku",
			type: "select-react",
			placeholder: "label.select_sku",
			validate: [required],
		},
	],
	byProductVariety: [
		{
			label: "label.product_variety_required",
			name: "product_variety",
			type: "select-react",
			placeholder: "label.select_product_variety",
			validate: [required],
		},
	],
};
