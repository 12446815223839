import { requiredDateObj, required, number, minValue } from "constant/formValidators";
export const EmployeeForm = [
	{
		label: "label.location_required",
		name: "location_slug",
		type: "select-react",
		placeholder: "label.select_location",
		validate: [required],
	},
	{
		label: "label.date_required",
		name: "date",
		type: "date-picker",
		placeholder: "label.enter_date",
		validate: [requiredDateObj],
		minDate: new Date(),
	},
	{
		label: "label.no_of_employees_req",
		name: "employee_count",
		type: "text",
		placeholder: "label.enter_the_no_of_employees_working_today",
		validate: [required,number,minValue(0)],
	},
];

export const PackRoomForm = [
	{
		label: "label.location_required",
		name: "location_slug",
		type: "select-react",
		placeholder: "label.select_location",
		validate: [required],
	},
	{
		label: "label.date_required",
		name: "date",
		type: "date-picker",
		placeholder: "label.enter_date",
		validate: [requiredDateObj],
		minDate: new Date(),
	},
	{
		label: "label.room_required",
		name: "room",
		type: "select-react",
		placeholder: "label.select_location",
		validate: [required],
	},
	{
		label: "label.temperature_req",
		name: "temperature",
		type: "text",
		placeholder: "label.enter_temparature_in_celsius",
		validate: [required, number],
	},
	{
		label: "label.humidity",
		name: "humidity",
		type: "text",
		placeholder: "label.enter_humidity",
		validate: [number],
	},
];

export const CleaningTimeForm = [
	{
		label: "label.location_required",
		name: "location_slug",
		type: "select-react",
		placeholder: "label.select_location",
		validate: [required],
	},
	{
		label: "label.date_required",
		name: "cleaning_date",
		type: "date-picker",
		placeholder: "label.enter_date",
		validate: [requiredDateObj],
		minDate: new Date(),
	},
	{
		label: "label.cleaning_time_required",
		name: "cleaning_time_in_minutes",
		type: "text",
		placeholder: "label.enter_cleaning_time_in_minutes",
		validate: [required, number, minValue(1)],
	},
];

export const SetUpTimeForm = [
	{
		label: "label.location_required",
		name: "location_slug",
		type: "select-react",
		placeholder: "label.select_location",
		validate: [required],
	},
	{
		label: "label.date_required",
		name: "set_up_date",
		type: "date-picker",
		placeholder: "label.enter_date",
		validate: [requiredDateObj],
		minDate: new Date(),
	},
	{
		label:"label.set_up_time_required",
		name: "set_up_time_in_minutes",
		type: "text",
		placeholder: "label.enter_setup_time_in_minutes",
		validate: [required, number, minValue(1)],
	},
];

export const PackagingWaste = {
	details: [
		{
			label: "label.location_required",
			name: "location_slug",
			type: "select-react",
			placeholder: "label.select_location",
			validate: [required],
		},
		{
			label: "label.date_required",
			name: "date",
			type: "date-picker",
			placeholder: "label.enter_date",
			validate: [requiredDateObj],
			minDate: new Date(),
		},
		{
			label: "label.packaging_waste_req",
			name: "type",
			type: "select-react",
			placeholder: "label.select_packaging_waste_type",
			validate: [required],
		},
	],
	byCase: [
		{
			label: "label.sku_req",
			name: "sku",
			type: "select-react",
			placeholder: "label.select_sku",
			validate: [required],
		},
	],
	byProductVariety: [
		{
			label: "label.product_variety_required",
			name: "product_variety",
			type: "select-react",
			placeholder: "label.select_product_variety",
			validate: [required],
		},
	],
	reason: [
		{
			label: "label.reason_for_wastage_req",
			name: "reason",
			type: "select-react",
			placeholder: "label.select_packaging_waste_type",
			validate: [required],
		},
	],
};

export const Rooms = [
	{
		label: "Pack Room",
		value: "PackRoom",
	},
	{
		label: "Pre Cool",
		value: "PreCool",
	},
];

export const RoomSelect = [
	{
		label: "Pack Room",
		value: "Pack Room",
	},
	{
		label: "Pre Cool",
		value: "Precool",
	},
]
export const PackagingWasteDropdown = [
	{
		label: "Packaging Waste by Cases",
		value: "ByCase",
	},
	{
		label: "Packaging Waste by Product Variety",
		value: "ByProductVariety",
	},
];

export const WastageReasonsList = [
	{
		label: "Quality",
		value: "Quality",
	},
	{
		label: "Unsold",
		value: "Unsold",
	},
	{
		label: "Food Safety",
		value: "Food Safety",
	},
];

export const PackRateForm = [
	{
		label: "label.location_required",
		name: "location_slug",
		type: "select-react",
		placeholder: "label.select_location",
		validate: [required],
	},
	{
		label: "label.date_required",
		name: "date",
		type: "date-picker",
		placeholder: "label.enter_date",
		validate: [requiredDateObj],
		minDate: new Date(),
	},
	{
		label: "label.total_no_of_cases_packed_req",
		name: "total_cases_packed",
		type: "text",
		placeholder: "label.enter_total_no_of_cases_packed",
		validate: [required, number, minValue(1)],
	},
	{
		label: "label.total_pounds_packed_req",
		name: "total_pounds_packed",
		type: "text",
		placeholder: "label.enter_total_pounds_packed",
		validate: [required, number, minValue(1)],
	},
	{
		label: "label.no_of_employees_req",
		name: "number_of_employees",
		type: "text",
		placeholder: "label.enter_no_of_employees",
		validate: [required, number, minValue(1)],
	},
	{
		label: "label.start_time_req",
		name: "start_time",
		type: "time-picker",
		placeholder: "label.select_start_time",
	},
	{
		label: "label.end_time_req",
		name: "end_time",
		type: "time-picker",
		placeholder: "label.select_end_time",
	},
	{
		label: "label.cases_per_labour_hour_req",
		name: "cases_per_lab_hour",
		type: "text",
	},
	{
		label: "label.lbs_per_labour_hour_req",
		name: "lbs_per_lab_hour",
		type: "text",
	},
];

export const productTemperatureForm = [
	{
		label: "label.location_required",
		name: "location_slug",
		type: "select-react",
		placeholder: "label.select_location",
		validate: [required],
	},
	{
		label: "label.pack_date_req",
		name: "date",
		type: "date-picker",
		placeholder: "label.enter_date",
		validate: [requiredDateObj]
	},
];

export const productTemperatureBottom = [
	{
		label: "label.room_required",
		name: "rooms",
		type: "select-react",
		placeholder: "label.select_room",
		validate: [required],
	},
	{
		label: "label.product_variety_required",
		name: "product_variety_slug",
		type: "select-react",
		placeholder: "label.select_product_variety",
		validate: [required]
	},
	{
		label: "label.temperature_req",
		name: "temperature",
		type: "text",
		placeholder: "label.enter_temparature_in_celsius",
		validate: [required, number],
	},
	{
		label: "label.humidity_req",
		name: "humidity",
		type: "text",
		placeholder: "label.enter_humidity",
		validate: [required,number],
	},
];