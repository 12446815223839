import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import { reduxForm } from "redux-form";
import { errorMessage } from "utils/errorMessage";
import RenderActionTableColumn from "./renderActionTableColumn";

const ActionForm = ({
	a_ind,
	editable,
	columns,
	a_item,
	render,
	renderKey,
	handleSubmit,
	slugKey,
	initialValues,
	validation,
	dateValidation,
	option
}) => {
	return (
		<div>
			<Form onSubmit={handleSubmit} className="actionForm">
				<Row key={a_ind} className="action-table-body-row">
					{columns.map((b_item, b_ind) => {
						const c_ind = b_ind;
						if (c_ind !== -1) {
							const c_item = a_item[a_ind];

							return (
								<RenderActionTableColumn
									key={b_ind}
									b_item={b_item.key}
									name={b_item.key}
									type={b_item.type}
									editFlag={b_item.editable}
									b_ind={b_ind}
									a_ind={a_ind}
									c_ind={c_ind}
									editable={editable}
									renderKey={b_item.key}
									render={render}
									c_item={c_item}
									slugKey={slugKey}
									validation={validation}
									dateValidation={dateValidation}
									option={option}
								/>
							);
						} else {
							return (
								<Col key={b_ind} className="action-table-col">
									<span>-</span>
								</Col>
							);
						}
					})}
				</Row>
			</Form>
		</div>
	);
};

export function scrollToFirstError(fieldList, columns, a_ind) {
	// eslint-disable-next-line no-unused-vars
	const elem = "";
	// eslint-disable-next-line array-callback-return
	columns.some((item) => {
		if (item.slug in fieldList) {
			const elem = document.querySelector(`#${item.slug}${a_ind}`);

			if (elem) {
				elem.scrollIntoView({ behavior: "smooth", block: "center" });
				return elem;
			}
		}
	});
}

const onSubmit = (values, dispatch, props) => {
	const editableChange = () => {
		props.cancelEdit(props.a_ind);
	};
	const result = {};
	let count = 0;
	for (const key in values) {
		if (values[key] && values[key] !== "") {
			count += 1;
		}
	}


	if (count > 0) {
		for (const key in values) {
			if (values[key] && values[key] !== "") {
				if(key === "sender_location"){
					result[key] = values[key] && values[key].value && values[key].value;
				}else{
					result[key] = values[key] && values[key];
				}
			}
		}
		props.onSave && props.onSave(result, props.slug, editableChange);
	} else {
		errorMessage("REQUIRED_ONE_FIELD");
	}
};

const areEqual = (prevProps, nextProps) => {
	return (
		prevProps.editable === nextProps.editable &&
		prevProps.a_item === nextProps.a_item &&
		prevProps.columns === nextProps.columns
	);
};

export default reduxForm({
	enableReinitialize: true,
	onSubmitFail: (errors, dispatch, submitError, props) => {
		scrollToFirstError(errors, props.columns, props.a_ind);
	},
	onSubmit: onSubmit,
})(React.memo(ActionForm, areEqual));
