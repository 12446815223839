import React, { useState } from "react";
import { size, isEmpty } from "lodash";
import { ButtonSecondary } from "../../../elements";

const ProductImageSlider = ({ imagesArr = [] }) => {
	const [currentIndex, setCurrentIndex] = useState(0);

	const length = size(imagesArr);

	const shuffleImage = (to = "next") => {
		if (to === "next") {
			if (currentIndex === length - 1) {
				setCurrentIndex(0);
			} else {
				const nextImg = imagesArr[currentIndex + 1];
				return nextImg ? setCurrentIndex(currentIndex + 1) : setCurrentIndex(0);
			}
		} else if (to === "prev") {
			if (currentIndex === 0) {
				setCurrentIndex(length - 1);
			} else {
				const prevImg = imagesArr[currentIndex - 1];
				return prevImg ? setCurrentIndex(currentIndex - 1) : setCurrentIndex(0);
			}
		}
	};

	return (
		<div className="product-seeds-image-slider">
			<div className="product-seeds-image-wrapper">
				{!isEmpty(imagesArr) ? (
					<img className="d-block w-100" src={imagesArr[currentIndex]} alt="Seeds_image" />
				) : (
					"No images"
				)}
			</div>
			{!isEmpty(imagesArr) && (
				<div className="product-seeds-image-indicator">
					<ButtonSecondary onClick={() => shuffleImage("next")}>{`<`}</ButtonSecondary>
					<ButtonSecondary onClick={() => shuffleImage("prev")}>{`>`}</ButtonSecondary>
				</div>
			)}
		</div>
	);
};

export default ProductImageSlider;
