import React from "react";
import { connect } from "react-redux";
import { get } from "lodash";
import useModal from "../../utils/useModal";
import ProfileView from "./profileView";
import "./profile.scss";
import ProfileEdit from "./profileEdit";

// 0 View
// 1 Edit
const Profile = ({ userDetails }) => {
	const [, modalType] = useModal("profile");
	return modalType ? <ProfileEdit initialValues={userDetails || {}} /> : <ProfileView data={userDetails} />;
};

const mapStateToProps = (state) => {
	const { Auth } = state;
	return {
		userDetails: get(Auth, "userDetails", null),
	};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
