import { BOARDS } from "../types";

const initialState = {
	boardsDropdownList: [],
	boardsList: [],
};

export default function (state = initialState, { type, payload }) {
	switch (type) {
		case BOARDS.GET_BOARDS_MINI_LIST:
			return {
				...state,
				boardsDropdownList: payload.data,
			};

		case BOARDS.GET_BOARDS_LIST:
			return {
				...state,
				boardsList: payload.data,
			};
		default:
			return state;
	}
}
