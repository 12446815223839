import { required, requiredDateObj } from "constant/formValidators";

export const copyForecastFormFields = [
	{
		label: "Location *",
		name: "location_slug",
		type: "select-react",
		placeholder: "Select Location",
		validate: [required],
	},
	{
		label: "Forecast *",
		name: "forecast_slug",
		type: "select-react",
		placeholder: "Select Forecast",
		validate: [required],
	},
	{
		label: "Date From *",
		name: "date_from",
		type: "date-picker",
		placeholder: "Enter Date",
		validate: [requiredDateObj],
	},
	{
		label: "Date To *",
		name: "date_to",
		type: "date-picker",
		placeholder: "Enter Date",
		validate: [requiredDateObj],
	},
];
