import { required, requiredDateRange } from "constant/formValidators";

export const dosingCalcFields = [
	{
		label: "Location *",
		name: "location",
		type: "select-react",
		placeholder: "Select Location",
		validate: [required],
	},
	{
		label: "Pond *",
		name: "pond",
		type: "select-react-expand-all",
		placeholder: "Select Pond",
		validate: [required],
	},
	{
		label: "Week *",
		name: "week",
		type: "date-range-picker",
		placeholder: "Enter Week",
		validate: [requiredDateRange],
	},
	// {
	// 	label: "Gallons in Ponds *",
	// 	name: "gallons",
	// 	type: "text",
	// 	placeholder: "Enter Gallons in Pond",
	// 	validate: [required],
	// },
];
