import React from "react";
import ContentLoader from "react-content-loader";

const MyLoader = (type) => {
	const loaderProps = { backgroundColor: "#bbbfbf", foregroundColor: "#ecebeb", viewBox: "0 0 420 150", speed: 2 };
	const barProps = { opacity: 2, width: 36 };
	const smallBarProps = { opacity: 2, width: 20 };

	switch (type.type) {
		case "bar":
			return (
				<ContentLoader {...loaderProps}>
					<rect x="50" y="0" height="268" {...barProps}></rect>
					<rect x="100" y="84" height="255" {...barProps}></rect>
					<rect x="150" y="108" height="231" {...barProps}></rect>
					<rect x="200" y="112" height="227" {...barProps}></rect>
					<rect x="250" y="116" height="223" {...barProps}></rect>
					<rect x="300" y="116" height="221" {...barProps}></rect>
				</ContentLoader>
			);
		case "smallbar":
			return (
				<ContentLoader viewBox="0 0 20 15" {...loaderProps}>
					<rect x="0" y="0" height="168" {...smallBarProps}></rect>
					<rect x="100" y="84" height="155" {...smallBarProps}></rect>
					<rect x="150" y="108" height="131" {...smallBarProps}></rect>
					<rect x="200" y="112" height="127" {...smallBarProps}></rect>
					<rect x="250" y="116" height="123" {...smallBarProps}></rect>
					<rect x="300" y="116" height="121" {...smallBarProps}></rect>
				</ContentLoader>
			);
		case "pie":
			return (
				<ContentLoader {...loaderProps} style={{ padding: "10%", align: "center" }}>
					<rect x="250" y="8" rx="3" ry="3" width="88" height="6" />
					<rect x="250" y="26" rx="3" ry="3" width="88" height="6" />
					<rect x="250" y="46" rx="3" ry="3" width="88" height="6" />

					<circle r="70" cx="70" cy="70" />
				</ContentLoader>
			);
		case "line":
			return (
				<ContentLoader {...loaderProps}>
					<rect x="80" y="17" rx="4" ry="4" width="500" height="70" />
				</ContentLoader>
			);
		case "area":
			return (
				<ContentLoader rtl {...loaderProps} style={{ padding: "10%", align: "center" }}>
					<path d="M 60 131.6 c 22.778 8.016 45.556 16.032 68.333 16.032 c 22.778 0 45.556 -134.432 68.334 -134.432 c 22.777 0 45.555 94.272 68.333 94.272 S 310.556 93.2 333.333 93.2 c 22.778 0 45.556 16 68.334 16 c 22.777 0 45.555 -4 68.333 -8 V 170 H 60 z" />
				</ContentLoader>
			);
		default:
			return (
				<ContentLoader {...loaderProps}>
					<rect x="0" y="0" rx="5" ry="5" width="70" height="70" />
					<rect x="80" y="17" rx="4" ry="4" width="300" height="13" />
					<rect x="80" y="40" rx="3" ry="3" width="250" height="10" />
				</ContentLoader>
			);
	}
};

const ContentLoaders = ({ type }) => (
	<>
		<MyLoader type={type} />
	</>
);

export default ContentLoaders;
