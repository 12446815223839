import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Container, Button, Row, Col, Form } from "react-bootstrap";
import { reduxForm, Field, reset, change, FieldArray, formValueSelector, FormSection } from "redux-form";
import FormGroups from "utils/formGroup";
import { topFormFields, harvestingBoardForm, bottomFormFields } from "./formHelper";
import { flagColors_2 as flagColors } from "constant/global";
import useModal from "utils/useModal";
import {
	toggleMainLoader,
	createHarvest,
	fetchTaskUsers,
	changeLocation,
	resetAddEdit,
	fetchHarvestDetails,
	fetchHarvestForecast,
} from "store/actions";
import { errorMessage } from "utils/errorMessage";
import { successMessage } from "utils/successMessage";
import Util from "utils/Util";
import moment from "moment";
import { ButtonLink } from "elements";
import TableLoader from "utils/table/tableLoader";
import { isEmpty } from "lodash";
import { useTranslation } from 'react-i18next';
import i18next from "i18next";


const renderHarvestingBoard = ({ fields, getOptions, onPondChange, meta: { error, submitFailed } }) => {
	const pond = harvestingBoardForm.pond;
	const lane = harvestingBoardForm.lane;
	const flag_color = harvestingBoardForm.flag_color;
	const board_count = harvestingBoardForm.board_count;
	const className = "col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12";

	return (
		<div className="harvest-board-outer">
			{fields.map((item, index) => (
				<React.Fragment key={index}>
					<Row className="harvest-board-row">
						<Col className={className}>
							<Field
								name={`${item}.${pond.name}`}
								type={pond.type}
								component={FormGroups}
								label={i18next.t(pond.label)}
								validate={pond.validate}
								placeholder={i18next.t(pond.placeholder)}
								multiple={false}
								options={getOptions(pond.name)}
								onChange={() => onPondChange(index)}
							/>
						</Col>
						<Col className={className}>
							<Field
								name={`${item}.${lane.name}`}
								type={lane.type}
								component={FormGroups}
								label={i18next.t(lane.label)}
								validate={lane.validate}
								placeholder={i18next.t(lane.placeholder)}
								multiple={false}
								options={getOptions(lane.name, index)}
							/>
						</Col>
						<Col className={className}>
							<Field
								name={`${item}.${flag_color.name}`}
								type={flag_color.type}
								component={FormGroups}
								label={i18next.t(flag_color.label)}
								validate={flag_color.validate}
								placeholder={i18next.t(flag_color.placeholder)}
								multiple={false}
								options={getOptions(flag_color.name)}
							/>
						</Col>
						<Col className={className}>
							<Field
								name={`${item}.${board_count.name}`}
								type={board_count.type}
								component={FormGroups}
								label={i18next.t(board_count.label)}
								validate={board_count.validate}
								placeholder={i18next.t(board_count.placeholder)}
							/>
						</Col>
						{index + 1 === fields.length && (
							<Col className={`${index === 0 ? "col-12" : "col-6"} d-flex justify-content-center text-center`}>
								<ButtonLink onClick={() => fields.push({})}>
									<i className="icon icon-plus mr-2" />
									{i18next.t("label.Add")}
								</ButtonLink>
							</Col>
						)}
						{fields.length > 1 && (
							<Col
								className={`${
									index + 1 !== fields.length ? "col-12" : "col-6"
								} col-6 d-flex justify-content-center text-center`}>
								<ButtonLink className="remove-link-btn" onClick={() => fields.remove(index)}>
									<i className="icon icon-minus mr-2" />
									{i18next.t("label.remove")}
								</ButtonLink>
							</Col>
						)}
					</Row>
				</React.Fragment>
			))}
			{submitFailed && error && <span>{error}</span>}
		</div>
	);
};

const HarvesAddEdit = ({
	slug,
	harvestingBoardDetails,
	fetchTaskUsers,
	createHarvest,
	changeLocation,
	handleSubmit,
	productList,
	assigneeList,
	toggleMainLoader,
	reset,
	form,
	onAddOrEdit,
	initialValues,
	locationList,
	pondList,
	laneList,
	resetAddEdit,
	boardValues,
	fetchHarvestDetails,
	fetchHarvestForecast,
	lanesPerPond,
}) => {
	const { t } = useTranslation();
	const [, modalType, , closeModal] = useModal("harvest");
	const [loading, setloading] = useState(true);
	const [btnTitle, setbtnTitle] = useState(t("label.save"));
	const [forecastOz, setforecastOz] = useState(
		initialValues && initialValues.forecast_oz_per_board ? initialValues.forecast_oz_per_board : ""
	);
	const [forecastNo, setforecastNo] = useState(
		initialValues && initialValues.forecast_no_of_boards ? initialValues.forecast_no_of_boards : ""
	);
	const [date, setDate] = useState(
		initialValues && initialValues.expected_harvesting_date ? initialValues.expected_harvesting_date : ""
	);

	const setInitialValues = {
		location_slug: (initialValues.locations && initialValues.locations.value) || null,
		pv_slug: (initialValues.product_varieties && initialValues.product_varieties.value) || null,
		date: initialValues.expected_harvesting_date || null,
	};
	const [initialForecastFetcher, setInitialForecastFetcher] = useState(setInitialValues);
	const [forecastDataFetcher, setForecastDataFetcher] = useState(null);

	const dispatch = useDispatch();

	// Detect change in form location/product variety/date fields
	// Change form forecast values
	useEffect(() => {
		if (
			!initialForecastFetcher &&
			forecastDataFetcher &&
			forecastDataFetcher.location_slug &&
			forecastDataFetcher.pv_slug &&
			forecastDataFetcher.date
		) {
			setloading(true);
			const body = {
				...forecastDataFetcher,
				date: Util.getYMDFormat(forecastDataFetcher.date),
			};

			const successHandler = (e) => {
				setloading(false);
				if (e && e.data && !isEmpty(e.data)) {
					setforecastNo(e.data.no_of_boards);
					setforecastOz(e.data.oz_per_board);
					dispatch(change("harvestForm", "forecast_no_of_boards", e.data.no_of_boards || null));
					dispatch(change("harvestForm", "forecast_oz_per_board", e.data.oz_per_board || null));
					if (e.data.forecast_value) {
						dispatch(change("harvestForm", "forecast_harvest_weight", e.data.forecast_value || null));
					} else {
						if (
							(e.data.no_of_boards || e.data.no_of_boards === 0) &&
							(e.data.oz_per_board || e.data.oz_per_board === 0)
						) {
							dispatch(
								change(
									"harvestForm",
									"forecast_harvest_weight",
									(e.data.oz_per_board / 16) * e.data.no_of_boards
								)
							);
						}
					}
				} else {
					setforecastNo(null);
					setforecastOz(null);
					dispatch(change("harvestForm", "forecast_no_of_boards", null));
					dispatch(change("harvestForm", "forecast_oz_per_board", null));
					dispatch(change("harvestForm", "forecast_harvest_weight", null));
				}
			};
			const errorHandler = (e) => setloading(false);
			fetchHarvestForecast(body, successHandler, errorHandler);
		}
	}, [dispatch, fetchHarvestForecast, forecastDataFetcher, initialForecastFetcher]);

	// Detect furthur changes in forecast fields and recalculate forecast value
	useEffect(() => {
		if ((forecastOz || forecastOz === 0) && (forecastNo || forecastNo === 0)) {
			dispatch(change("harvestForm", "forecast_harvest_weight", (forecastOz / 16) * forecastNo));
		}
	}, [dispatch, forecastNo, forecastOz]);

	useEffect(() => {
		const successhandler = () => {
			setloading(false);
		};
		fetchTaskUsers(successhandler);
		return () => {
			resetAddEdit();
		};
	}, [fetchTaskUsers, resetAddEdit, fetchHarvestDetails, slug, modalType]);

	// Form Submission
	const submitBoardDetails = (values) => {
		if (!loading) {
			setloading(true);
			setbtnTitle(t("label.saving"));
			toggleMainLoader(true);
			const boardDetails = values.harvestingBoard.map((item) => {
				return {
					pond: item.pond.value,
					lane: item.lane.value,
					flag_color: item.flag_color.value,
					board_count: parseInt(item.board_count),
				};
			});

			const submissionData = {
				product_variety_slug: values.product_varieties.value,
				location_slug: values.locations.value,
				assignee_id: values.assignee_id ? values.assignee_id.value.toString() : "",
				expected_harvesting_date: moment(values.expected_harvesting_date).format("YYYY-MM-DD  hh:mm:ss"),
				harvesting_blade_height: values.harvesting_blade_height
					? values.harvesting_blade_height.height2.toString()
					: "",
				harvesting_blade_height_start_range: values.harvesting_blade_height
					? values.harvesting_blade_height.height1.toString()
					: "",
				root_puller_height: values.root_puller_height ? values.root_puller_height.toString() : "",
				forecast_oz_per_board: values.forecast_oz_per_board.toString(),
				forecast_no_of_boards: values.forecast_no_of_boards.toString(),
				forecast_harvest_weight: values.forecast_harvest_weight.toString(),
				harvesting_task_description: values.harvesting_task_description
					? values.harvesting_task_description.toString()
					: "",
				board_details: boardDetails,
			};

			const successHandler = (event) => {
				onAddOrEdit && onAddOrEdit(event);
				reset(form);
				setloading(false);
				setbtnTitle(t("label.save"));
				successMessage(event);
				closeModal();
			};
			const errorHandler = (event) => {
				errorMessage(event);
				setloading(false);
				setbtnTitle(t("label.save"));
			};
			const type = modalType === 1 ? "edit" : "add";
			const slug = modalType === 1 ? values.task_id : "";

			createHarvest(submissionData, slug, type, successHandler, errorHandler);
		}
	};

	const getLaneList = (pond, id,boardValues) => {
		const lanesSelected = [];
		boardValues.forEach((item, idx) => {
			if (
				idx !== id &&
				// boardValues &&
				// boardValues[idx] &&
				item["pond"] &&
				item["pond"]["value"] &&
				item["pond"]["value"].toString() === pond.toString() &&
				item["lane"] &&
				item["lane"]["value"]
				// formValues[`lm_${idx}`]["lane_number"]["value"] !== formValues[`lm_${id}`]["lane_number"]["value"]
			) {
				lanesSelected.push(item["lane"]["value"].toString());
			}
		});
		const arr = Array(lanesPerPond)
			.fill()
			.map((_, i) => ({
				label: i + 1,
				value: (i + 1).toString(),
				...(!isEmpty(lanesSelected)
					? { isdisabled: lanesSelected.includes((i + 1).toString()) ? true : false }
					: { isdisabled: false }),
			}));
		return arr;
	};

	const getOptions = (field, id) => {
		switch (field) {
			case "assignee_id":
				return assigneeList ? Util.mapOptions(assigneeList, "name", "id") : [];
			case "product_varieties":
				return productList ? Util.mapOptions(productList, "product_variety_name", "slug") : [];
			case "locations":
				return locationList ? Util.mapOptions(locationList, "label", "value") : [];
			case "flag_color":
				return flagColors ? Object.values(flagColors) : [];
			case "pond":
				return pondList ? Util.mapOptions(pondList, "value", "id") : [];
			case "lane":
				// return laneList ? laneList : [];
				return (
					(boardValues &&
						boardValues[id] &&
						boardValues[id].pond &&
						boardValues[id].pond.value &&
						getLaneList(boardValues[id].pond.value, id,boardValues)) ||
					[]
				);
			default:
				return [];
		}
	};

	const onPondChange = (id) => {
		boardValues &&
			boardValues[id] &&
			boardValues[id]["lane"] &&
			boardValues[id]["lane"]["value"] &&
			dispatch(change("harvestForm", `harvestingBoard[${id}].lane`, null));
	};

	const onChangeFormEvents = (name, event) => {
		if (name === "locations") {
			changeLocation(event);
			dispatch(change("harvestForm", "product_varieties", ""));
			dispatch(change("harvestForm", "assignee_id", ""));
			boardValues &&
				boardValues.length > 0 &&
				// eslint-disable-next-line array-callback-return
				boardValues.map((item, index) => {
					dispatch(change("harvestForm", `harvestingBoard[${index}].pond`, ""));
					dispatch(change("harvestForm", `harvestingBoard[${index}].lane`, ""));
				});

			if (initialForecastFetcher) {
				setForecastDataFetcher({
					location_slug: event.value,
				});
				setInitialForecastFetcher(null);
			} else {
				setForecastDataFetcher({
					location_slug: event.value,
				});
			}
		}
		if (name === "forecast_no_of_boards") {
			setforecastNo(Number(event.target.value));
		}
		if (name === "forecast_oz_per_board") {
			setforecastOz(Number(event.target.value));
		}
		if (name === "expected_harvesting_date") {
			setDate(event);

			if (initialForecastFetcher) {
				setForecastDataFetcher({
					...initialForecastFetcher,
					date: event,
				});
				setInitialForecastFetcher(null);
			} else {
				setForecastDataFetcher({
					...forecastDataFetcher,
					date: event,
				});
			}
		}

		if (name === "product_varieties") {
			if (initialForecastFetcher) {
				setForecastDataFetcher({
					...initialForecastFetcher,
					pv_slug: event.value,
				});
				setInitialForecastFetcher(null);
			} else {
				setForecastDataFetcher({
					...forecastDataFetcher,
					pv_slug: event.value,
				});
			}
		}
	};

	const getFormFieldHtml = (formfields, getOptions, dispatch, change, date) =>
		formfields.fields.map((field, idx) => (
			<React.Fragment key={idx}>
				<Col
					className={
						field.name === "product_varieties" || field.name === "harvesting_task_description"
							? "col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
							: "col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12"
					}>
					<Form.Group controlId={`formControl ${field.name}`}>
						{field.name === "product_varieties" || field.name === "assignee_id" || field.name === "locations" ? (
							<Field
								name={field.name}
								type={field.type}
								label={t(field.label)}
								placeholder={t(field.placeholder)}
								component={FormGroups}
								validate={field.validate}
								multiple={false}
								options={getOptions(field.name)}
								onChange={(event) => onChangeFormEvents(field.name, event)}
							/>
						) : field.name === "expected_harvesting_date" ? (
							<Field
								name={field.name}
								type={field.type}
								label={t(field.label)}
								placeholder={t(field.placeholder)}
								component={FormGroups}
								minDate={field.minDate}
								validate={field.validate}
								selectedDate={date}
								onChange={(event) => onChangeFormEvents(field.name, event)}
							/>
						) : field.name === "harvesting_blade_height" ? (
							<>
								<FormSection name="harvesting_blade_height">
									<Row>
										<Col>
											<Field
												name={"height1"}
												type={field.type}
												// label={field.label}
												placeholder={t("label.from")}
												component={FormGroups}
												disable={field.name === "forecast_harvest_weight" ? true : false}
												validate={field.validate}
												minDate={field.minDate}
												onChange={(event) => onChangeFormEvents(field.name, event)}
											/>
										</Col>
										<Col>
											<Field
												name={"height2"}
												type={field.type}
												// label={field.label}
												placeholder={t("label.to")}
												component={FormGroups}
												disable={field.name === "forecast_harvest_weight" ? true : false}
												validate={field.validate}
												minDate={field.minDate}
												onChange={(event) => onChangeFormEvents(field.name, event)}
											/>
										</Col>
									</Row>
								</FormSection>
								<span className="form-label">{t(field.label)}</span>
							</>
						) : (
							<Field
								name={field.name}
								type={field.type}
								label={t(field.label)}
								placeholder={t(field.placeholder)}
								component={FormGroups}
								disable={field.name === "forecast_harvest_weight" ? true : false}
								validate={field.validate}
								minDate={field.minDate}
								onChange={(event) => onChangeFormEvents(field.name, event)}
							/>
						)}
					</Form.Group>
				</Col>
			</React.Fragment>
		));

	const renderFormFields = (fields) =>
		fields.map((fields, idx) => (
			<React.Fragment key={idx}>
				<Row>
					<div className="form-headers">{fields.heading}</div>
				</Row>
				<Row>{getFormFieldHtml(fields, getOptions, dispatch, change, date)}</Row>
			</React.Fragment>
		));

	return (
		<Form onSubmit={handleSubmit(submitBoardDetails)} className="harvestForm">
			<Container style={{ position: "relative" }}>
				{renderFormFields(topFormFields)}
				<FieldArray
					name="harvestingBoard"
					component={renderHarvestingBoard}
					onPondChange={onPondChange}
					getOptions={getOptions}
				/>
				{renderFormFields(bottomFormFields)}
				{loading && (
					<div className="table-loader-wrapper">
						<TableLoader />
					</div>
				)}
			</Container>
			<div className="modal-footer save-footer">
				<Button variant="secondary" onClick={closeModal}>
					{t("label.cancel")}
				</Button>
				<Button variant="primary" type="submit" disabled={loading}>
					{btnTitle}
				</Button>
			</div>
		</Form>
	);
};

const mapStateToProps = (state) => {
	const { Modals, Harvest } = state;
	const selector = formValueSelector("harvestForm");
	const initialArray = () => {
		const harvestingBoard = Harvest.harvestDetails.harvesting_board_details
			? Harvest.harvestDetails.harvesting_board_details.map((item, index) => {
					return {
						pond: { value: item.pond.id, label: item.pond.value },
						lane: { value: item.lane, label: item.lane.toString(), isdisabled: false },
						flag_color: flagColors[item.flag_color],
						board_count: item.board_count,
					};
			  })
			: [];

		const varieties = Harvest.harvestDetails.product_variety;
		const products = varieties ? { value: varieties["slug"], label: varieties["name"] } : {};
		const locationCheck =
			Harvest.harvestDetails.location && Harvest.harvestDetails.location.deleted_at === null ? true : false;

		const topFields = {
			task_id: Harvest.harvestDetails.task_id,
			expected_harvesting_date: Harvest.harvestDetails.expected_harvesting_date
				? moment(Harvest.harvestDetails.expected_harvesting_date).toDate()
				: null,
			product_varieties: products,
			locations:
				Harvest.harvestDetails.location && locationCheck
					? {
							value: Harvest.harvestDetails.location["slug"],
							label: Harvest.harvestDetails.location["location_name"],
					  }
					: "",
		};

		const assignee = Harvest.harvestDetails.assignee;
		const bottomFields = {
			assignee_id: locationCheck && assignee ? { value: assignee["id"], label: assignee["name"] } : "",
			harvesting_blade_height: {
				height1: Harvest.harvestDetails.harvesting_blade_height_start_range,
				height2: Harvest.harvestDetails.harvesting_blade_height,
			},
			forecast_oz_per_board: Harvest.harvestDetails.forecast_oz_per_board,
			forecast_no_of_boards: Harvest.harvestDetails.forecast_no_of_boards,
			forecast_harvest_weight: Harvest.harvestDetails.forecast_harvest_weight,
			root_puller_height: Harvest.harvestDetails.root_puller_height,
			harvesting_task_description: Harvest.harvestDetails.harvesting_task_description,
		};

		const formatedInitialValueArray = {
			...topFields,
			harvestingBoard,
			...bottomFields,
		};
		return formatedInitialValueArray;
	};

	const addInitial = {
		locations: !isEmpty(Harvest.selectedLocation) ? Harvest.selectedLocation : "",
		harvestingBoard: [
			{
				pond: "",
				lane: "",
				flag_color: "",
				board_count: "",
			},
		],
	};
	return {
		boardValues: selector(state, "harvestingBoard"),
		selectedLocation: Harvest.selectedLocation,
		locationList: Harvest.locationList,
		productList: Harvest.productList,
		assigneeList: Harvest.assigneeList,
		laneList: Harvest.laneList,
		pondList: Harvest.pondList,
		lanesPerPond: Harvest.lanesPerPond,
		harvestingBoardDetails: Harvest.harvestDetails.harvesting_board_details,
		initialValues: Modals.modalType === 0 ? addInitial : initialArray(),
	};
};

const mapDispatchToProps = {
	fetchTaskUsers,
	createHarvest,
	changeLocation,
	toggleMainLoader,
	reset,
	resetAddEdit,
	fetchHarvestDetails,
	fetchHarvestForecast,
};

const areEqual = (prevProps, nextProps) => {
	return (
		prevProps.selectedLocation === nextProps.selectedLocation &&
		prevProps.locationList === nextProps.locationList &&
		prevProps.productList === nextProps.productList &&
		prevProps.boardValues === nextProps.boardValues
	);
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(
	reduxForm({
		form: "harvestForm",
		enableReinitialize: true,
	})(React.memo(HarvesAddEdit, areEqual))
);
