import React, { useState } from "react";
import { Row } from "react-bootstrap";
import { tableHeadersFullfillmentDetails } from "../view/viewHelper";
import ConfirmModal from "utils/confirmModal";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import FulFillmentTable from "../../../../utils/fulFillmentDetailsTable";
import { getfulFillementTableColumns } from "../../../../store/selectors/fulFillmentTableSelectors";
import { errorMessage } from "utils/errorMessage";
import { successMessage } from "utils/successMessage";
import {
	setLoaderFulFillmentData,
	deleteFulFillmentData,
	editFulFillmentData,
	changeFulfillmentStatus,
} from "../../../../store/actions";
import Util from "../../../../utils/Util";

const FulFillmentDetails = ({
	taskDetails,
	columns,
	locationSkuList,
	setLoaderFulFillmentData,
	doFetchTaskDetails,
	deleteFulFillmentData,
	editFulFillmentData,
	changeFulfillmentStatus,
	permissionDetails,
}) => {
	const [deleteItem, setdeleteItem] = useState(null);
	const onSave = (values, slug, editableChange) => {
		const submitData = {
			slug: slug.slug,
			cases: values.cases,
			pack_date: Util.getYMDFormat(values.pack_date),
			best_buy_date: Util.getYMDFormat(values.best_buy_date),
			ship_date: Util.getYMDFormat(values.ship_date),
			location_slug: values.sender_location,
		};

		const successHandler = (e) => {
			successMessage(e.code);
			doFetchTaskDetails();
			editableChange();
		};
		const errorHandler = (e) => {
			errorMessage(e);
			doFetchTaskDetails();
			// setLoaderCroptimals();
		};

		editFulFillmentData(submitData, successHandler, errorHandler);
	};
	const onDelete = () => {
		const successHandler = (e) => {
			successMessage(e.code);
			doFetchTaskDetails();
			setdeleteItem(null);
		};
		const errorHandler = (e) => {
			errorMessage(e);
			doFetchTaskDetails();
			setdeleteItem(null);
		};
		deleteFulFillmentData(deleteItem.slug, successHandler, errorHandler);
	};
	const onClickOrderShip = (item) => {
		const body = {
			slug: item.slug,
		};
		const successHandler = (e) => {
			successMessage(e.code);
			doFetchTaskDetails();
			setdeleteItem(null);
		};
		const errorHandler = (e) => {
			errorMessage(e);
			doFetchTaskDetails();
			setdeleteItem(null);
		};
		changeFulfillmentStatus(body, successHandler, errorHandler);
	};

	const editPermission =
		permissionDetails && permissionDetails.cross_supply && permissionDetails.cross_supply.permissions.edit_fulfilment
			? permissionDetails.cross_supply.permissions.edit_fulfilment
			: "";
	const deletePermission =
		permissionDetails && permissionDetails.cross_supply && permissionDetails.cross_supply.permissions.delete_fulfilment
			? permissionDetails.cross_supply.permissions.delete_fulfilment
			: "";

	return (
		<>
			<Row>
				<div className="form-headers">Fullfillment Details</div>
			</Row>
			<FulFillmentTable
				editable={true}
				columns={tableHeadersFullfillmentDetails}
				fixedColumnHeading="SKU"
				initialData={taskDetails}
				slugKey="sku_slug"
				renderKey="sku_name"
				option={locationSkuList}
				onSave={onSave}
				render={(item) => (item.sku_name ? item.sku_name : "-")}
				keyMapping={taskDetails}
				onDelete={(slug) => setdeleteItem(slug)}
				onClickOrderShip={onClickOrderShip}
				editPermission={editPermission}
				deletePermission={deletePermission}
			/>
			<ConfirmModal
				show={deleteItem ? true : false}
				onNo={() => setdeleteItem(null)}
				onYes={onDelete}
				message="Are you sure want to delete this data ?"
			/>
		</>
	);
};

const mapStateToProps = createStructuredSelector({
	columns: getfulFillementTableColumns,
});

export default connect(mapStateToProps, {
	setLoaderFulFillmentData,
	deleteFulFillmentData,
	editFulFillmentData,
	changeFulfillmentStatus,
})(FulFillmentDetails);
