import { REPORTS } from "../types";
import fetchHandler from "../../utils/fetchHandler";

export const fetchGraphDetails = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `reports`,
		method: "POST",
		secure: true,
		actionType: REPORTS.FETCH_GRAPH_DETAILS,
		body: JSON.stringify(values),
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};
export const fetchLocationSkus = (successHandler, errorHandler) => {
	const fetchOptions = {
		url: `location-skus`,
		method: "GET",
		secure: true,
		actionType: REPORTS.FETCH_LOCATION_SKUS,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const getLocationCustomersReport = (successHandler, errorHandler) => {
	const fetchOptions = {
		url: `location-customers`,
		method: "GET",
		secure: true,
		actionType: REPORTS.LOCATION_CUSTOMERS,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const fetchLocationProductVaritiesReport = (successHandler, errorHandler) => {
	const fetchOptions = {
		url: `location-product-varieties`,
		method: "GET",
		secure: true,
		actionType: REPORTS.FETCH_LOCATION_PRODUCT_VARITIES_REPORT,
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const downloadExcel = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `reports`,
		method: "POST",
		secure: true,
		actionType: REPORTS.EXPORT_TABLE,
		body: JSON.stringify(values),
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};