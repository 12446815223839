import React from "react";
import { size } from "lodash";
import { ButtonLink, ListingBtn } from "../../../elements";
import ToggleButton from "../../../utils/toggleButton";
import { errorMessage } from "../../../utils/errorMessage";

export const renderColumns = ({
	handleDeletePanel,
	addToFilter,
	handleEdit,
	handleView,
	permissionDetails,
	handleSeedStatus,
}) => {
	const updatePermission =
		permissionDetails && permissionDetails.seeds && permissionDetails.seeds.permissions.update_seed
			? permissionDetails.seeds.permissions.update_seed
			: "";
	const viewPermission =
		permissionDetails && permissionDetails.seeds && permissionDetails.seeds.permissions.view_seed
			? permissionDetails.seeds.permissions.view_seed
			: "";
	const deletePermission =
		permissionDetails && permissionDetails.seeds && permissionDetails.seeds.permissions.delete_seed
			? permissionDetails.seeds.permissions.delete_seed
			: "";

	return [
		{
			name: "Seed Name",
			accessor: "seed_name",
			className: "seeds-listing-col-name",
			headerClassName: "seeds-listing-col-name-header",
			filter: {
				type: "search",
				onChange: addToFilter,
			},
			render: (row) => {
				return viewPermission ? (
					<ButtonLink className="p-0" onClick={() => handleView(`${row.slug}`)}>
						{row.seed_name}
					</ButtonLink>
				) : (
					row.seed_name
				);
				// return (
				// 	<ButtonLink className="p-0" onClick={() => handleView(`${row.slug}`)}>
				// 		{row.seed_name}
				// 	</ButtonLink>
				// );
			},
		},
		{
			name: "Seed Pricing",
			accessor: "price_per_pound",
			className: "seeds-listing-col-price",
			headerClassName: "seeds-listing-col-price-header",

			render: (row) => row.price_per_pound && `$ ` + row.price_per_pound,
			sortable: false,
		},
		{
			name: "Seed Density",
			accessor: "pond_temperature",
			className: "seeds-listing-col-density",
			headerClassName: "seeds-listing-col-density-header",
			render: (row) => row.seed_density,
			sortable: false,
		},
		{
			name: "Status",
			accessor: "active",
			className: "seeds-listing-col-status",
			headerClassName: "seeds-listing-col-status-header",
			render: (row) => {
				return <ToggleButton value={row.active} onChange={(e) => handleSeedStatus(e, row)} />;
			},
		},
		{
			name: "Actions",
			className: "seeds-listing-col-actions justify-content-end",
			headerClassName: "seeds-listing-col-actions-header justify-content-end",
			hide: updatePermission || deletePermission ? false : true,
			render: (row) => {
				return (
					<>
						{updatePermission ? (
							<ListingBtn
								parentClass={
									updatePermission && deletePermission ? "justify-content-end" : "justify-content-around"
								}
								tooltipText="Edit"
								btnClass="btn btn-cell btn-light btn-sm ml-2"
								iconClass="icon icon-edit"
								onClick={() => handleEdit(`${row.slug}`)}
							/>
						) : (
							""
						)}
						{deletePermission ? (
							<ListingBtn
								parentClass={
									updatePermission && deletePermission ? "justify-content-end" : "justify-content-around"
								}
								tooltipText="Delete"
								btnClass="btn btn-cell btn-light btn-sm ml-2"
								iconClass="icon icon-trash"
								onClick={() =>
									size(row.greens) || size(row.product_varieties) > 0
										? errorMessage("SEEDS_DELETE_DISABLE")
										: handleDeletePanel(`${row.slug}`)
								}
							/>
						) : (
							""
						)}
					</>
				);
			},
			sortable: false,
		},
	];
};
