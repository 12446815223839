import React, { useState } from "react";
import { CalculateForm } from "../manage";
import DosingCalculatorTable from "utils/dosingCalculatorTable";
import DosingCalculator from "utils/dosingCalculator";
import { fertilizerTableHeadings, resultTableHeadings } from "./helper";
import { createStructuredSelector } from "reselect";
import {
	getFertilizerTableData,
	getFormFromDate,
	getGallonsValue,
	getPvDropdownList,
	getPvSelected,
	getResultTableData,
	getSaveEnable,
	getSeasonName,
	getSelectedPond,
	locationSelected,
	getData,
	getDosingCalcEditMode,
	getDosingCalcSlug,
	getActivePondTab,
	getTabsPonds,
	getPondTabData,
	getCurrentPondId,
	getInitials
} from "store/selectors/dosingCalcSelectors";
import { connect, useDispatch } from "react-redux";
import { isEmpty, keys } from "lodash";
import { successMessage } from "utils/successMessage";
import { errorMessage } from "utils/errorMessage";
import {
	calculateData,
	changePv,
	onResetData,
	saveData,
	updateCalculateData,
	editDosingData,
	setLoaderDosingCalc,
	setLoaderToAddList,
	cancelDosingEdit,
	setActiveTabPond,
} from "store/actions";
import { LocationDropdown } from "components/bulkAssignee/bulkAssigneeComponents";
import { Button } from "react-bootstrap";
import { reset, submit } from "redux-form";
import Util from "utils/Util";
import { Tab, Tabs } from "react-bootstrap";

const Listing = ({
	fertilizerData,
	gallonsSelected,
	calculateData,
	pvList,
	pvSelected,
	changePv,
	resultData,
	season,
	onResetData,
	saveEnable,
	selectedPond,
	selectedLocation,
	fromDate,
	saveData,
	data,
	updateCalculateData,
	editMode,
	editDosingData,
	dosingCalcSlug,
	setLoaderDosingCalc,
	setLoaderToAddList,
	cancelDosingEdit,
	setRefetch,
	activePondTab,
	pondTabs,
	setActiveTabPond,
	ponds,
	currentPondId,
	initialData
}) => {
	const dispatch = useDispatch(null);
	const [noData, setnoData] = useState(false);
	const [fertilizerValueChanged, setfertilizerValueChanged] = useState(false);
	const [gallonsValueChanged, setgallonsValueChanged] = useState(false);
	const [calculatedValue, setcalculatedValue] = useState(null);
	const onCalculate = (values) => {
		//Field name cannot be a number or it cannot have "."
		//redux store cannot store these filed's values // drawback of reduxstore
		const data = values.filter((item) => item.input_quantity && item.input_quantity !== "");
		data.forEach((item)=>{
			item.slug = item.slug.replace("~_~",".")
		})

		if (isEmpty(data)) {
			setnoData(true);
		} else {
			setnoData(false);
			if (editMode) {
				setLoaderToAddList();
			} else {
				setLoaderDosingCalc();
			}
			setcalculatedValue(data);
			const submitData = {
				data: data,
				galloons: gallonsSelected,
			};
			const successHandler = (e) => {
				if (editMode) {
					setLoaderToAddList();
				} else {
					setLoaderDosingCalc();
				}

				updateData(e.data, values);
			};
			const errorHandler = (e) => {
				if (editMode) {
					setLoaderToAddList();
				} else {
					setLoaderDosingCalc();
				}

				errorMessage(e);
			};
			calculateData(submitData, successHandler, errorHandler);
		}
	};

	const updateData = (payload, fertilizer_data) => {
		const tempData = {};
		const tempKeys = keys(initialData.resultTable.result_table);

		tempKeys.forEach((item_i) => {
			initialData.resultTable.result_table[item_i].forEach((item_j, ind) => {
				if (!tempData[item_i]) {
					tempData[item_i] = [];
				}
				if (item_j.slug in payload) {
					const obj = {
						...item_j,
						to_add_value: payload[item_j.slug],
						total: parseFloat((payload[item_j.slug] * 1 + item_j.lab_result_value * 1).toFixed(2)),
					};
					tempData[item_i].push(obj);
				} else {
					const obj = {
						...item_j,
						to_add_value: 0,
						total: parseFloat((item_j.lab_result_value *1).toFixed(2)),
					};
					tempData[item_i].push(obj);
				}
			});
		});

		const tempObj = {
			...initialData,
			fertiliser_data: fertilizer_data,
			resultTable: {
				...initialData.resultTable,
				result_table: tempData,
			},
		};
		const active = `saving_${activePondTab}`
		updateCalculateData(tempObj,active);
	};

	const onResultsSave = (values) => {
		const data = values
		// .filter((item) => item.to_add_value && item.to_add_value !== "");
		const formatData = data.map((item) => ({
			...item,
			value: item.to_add_value,
		}));

		if (isEmpty(data)) {
			setnoData(true);
		} else {
			if (editMode) {
				setLoaderToAddList();

				const submitData = {
					dosing_calculator_slug: dosingCalcSlug,
					galloons: gallonsSelected,
					fertiliser: calculatedValue,
					nutrients: formatData,
				};
				const successHandler = (e) => {
					setLoaderToAddList();
					successMessage(e);
					setRefetch(true);
				};
				const errorHandler = (e) => {
					setLoaderToAddList();
					errorMessage(e);
				};

				editDosingData(submitData, successHandler, errorHandler);
			} else {
				setLoaderDosingCalc();

				const submitData ={
					location_slug: selectedLocation.value,
					date: Util.getMDYFormatUtcDate(fromDate),
					dosing_details: [
					  {
						pond: currentPondId,
						galloons: gallonsSelected,
						fertiliser:calculatedValue,
						nutrients: formatData
					  }
					]
				  }

				const successHandler = (e) => {
					setLoaderDosingCalc();

					successMessage(e);
				};
				const errorHandler = (e) => {
					setLoaderDosingCalc();

					errorMessage(e);
				};
				saveData(submitData, successHandler, errorHandler);
			}
		}
	};

	return (
		<div className="dosing-listing-wrapper">
			{pondTabs && (
				<div className="tab-wrapper">
					<Tabs
						className="customer-tab"
						id="uncontrolled-tab-inner"
						activeKey={activePondTab}
						defaultActiveKey={activePondTab}
						mountOnEnter={true}
						onSelect={(k) => {
							setActiveTabPond(k);
						}}>
						{pondTabs.map((item, index) => (
							<Tab
								className="dc-tab-cell"
								eventKey={item.key}
								key={index}
								title={
									<span>
										<i className="icon icon-market mr-3" />
										{item.label}
									</span>
								}>
								{activePondTab === item.key && fertilizerData && resultData ? (
									!isEmpty(fertilizerData) || !isEmpty(resultData) ? (
										<>
											<DosingCalculatorTable
												headings={fertilizerTableHeadings}
												initialData={fertilizerData}
												tableId="fertilizer"
												onSave={onCalculate}
												valueChanged={(value) => {
													value !== fertilizerValueChanged && setfertilizerValueChanged(value);
												}}
											/>
											{noData && (
												<div className="position-relative">
													<span className="dosing-no-data ">No data entered in table</span>
												</div>
											)}

											<CalculateForm
												valueChanged={(value) =>
													value !== gallonsValueChanged && setgallonsValueChanged(value)
												}
												isDisabled={fertilizerValueChanged || gallonsValueChanged ? false : true}
											/>
											<div className="d-md-flex justify-content-between mb-2">
												<div className="dropdown-row d-flex">
													<p className="results-head"> Results for</p>
													<LocationDropdown
														value={pvSelected}
														options={pvList}
														onSelect={changePv}
														isClearable={false}
													/>
												</div>
												<p className="season-head"> Season : {season}</p>
											</div>
											<DosingCalculator
												headings={resultTableHeadings}
												initialData={resultData}
												tableId="results"
												onSave={onResultsSave}
											/>
											{saveEnable && (
												<div className="d-flex justify-content-end ml-0 mr-0 mt-4 position-relative forecast-table-btn-row">
													<Button
														variant="secondary"
														className="mr-3"
														onClick={() => {
															dispatch(reset(`dosingCalculatorTable_fertilizer_${currentPondId}`));
															dispatch(reset(`calculateForm`));
															onResetData();
														}}>
														Cancel
													</Button>
													<Button
														variant="primary"
														className="save-btn"
														disabled={noData}
														onClick={() => dispatch(submit(`dosingCalculatorTable_results_${currentPondId}`))}>
														Save
													</Button>
												</div>
											)}
											{!saveEnable && editMode && (
												<div className="d-flex justify-content-end ml-0 mr-0 mt-4 position-relative forecast-table-btn-row">
													<Button
														variant="secondary"
														onClick={() => {
															cancelDosingEdit();
														}}>
														Cancel Editing
													</Button>
												</div>
											)}
										</>
									) : (
										<div className="no-results-found text-center">No Results Found</div>
									)
								) : null}
							</Tab>
						))}
					</Tabs>
				</div>
			)}
		</div>
	);
};

const mapStateToProps = createStructuredSelector({
	fertilizerData: getFertilizerTableData,
	gallonsSelected: getGallonsValue,
	pvList: getPvDropdownList,
	pvSelected: getPvSelected,
	resultData: getResultTableData,
	season: getSeasonName,
	saveEnable: getSaveEnable,
	selectedPond: getSelectedPond,
	selectedLocation: locationSelected,
	fromDate: getFormFromDate,
	data: getData,
	editMode: getDosingCalcEditMode,
	dosingCalcSlug: getDosingCalcSlug,
	activePondTab: getActivePondTab,
	pondTabs: getTabsPonds,
	ponds: getPondTabData,
	currentPondId:getCurrentPondId,
	initialData:getInitials
});
export default connect(mapStateToProps, {
	calculateData,
	changePv,
	onResetData,
	saveData,
	updateCalculateData,
	editDosingData,
	setLoaderDosingCalc,
	setLoaderToAddList,
	cancelDosingEdit,
	setActiveTabPond,
})(Listing);
