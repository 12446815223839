import Util from "utils/Util";

export const OrderDetailsView = [
	{
		label: "label.order_id",
		accessor: "task_id",
		classes: "col-lg-3 col-md-4 col-sm-6 col-12",
		render: (task) => (task && task.order && task.order.order_id) || "-",
	},
	{
		label: "label.customer",
		accessor: "",
		classes: "col-lg-3 col-md-4 col-sm-6 col-12",
		render: (task) => (task && task.order && task.order.customer && task.order.customer.customer_name) || "-",
	},
	{
		label: "label.location",
		accessor: "",
		classes: "col-lg-3 col-md-4 col-sm-6 col-12",
		render: (task) => (task && task.order && task.order.location && task.order.location.location_name) || "-",
	},
	{
		label: "label.dc_name",
		accessor: "",
		classes: "col-lg-3 col-md-4 col-sm-6 col-12",
		render: (task) => (task && task.order && task.order.distribution_center && task.order.distribution_center.name) || "-",
	},
	{
		label: "label.sku_name",
		accessor: "",
		classes: "col-lg-3 col-md-4 col-sm-6 col-12",
		render: (task) => (task && task.stock_keeping_unit && task.stock_keeping_unit.sku_name) || "-",
	},
	{
		label: "label.order_request_date",
		accessor: "",
		classes: "col-lg-3 col-md-4 col-sm-6 col-12",
		render: (task) =>
			(task && task.order && task.order.order_request_date && Util.formatMDY(task.order.order_request_date)) || "-",
	},
	{
		label: "label.order_delivery_date",
		accessor: "",
		classes: "col-lg-3 col-md-4 col-sm-6 col-12",
		render: (task) =>
			(task && task.order && task.order.date_of_delivery && Util.formatMDY(task.order.date_of_delivery)) || "-",
	},
	{
		label: "label.required_cases",
		accessor: "required_cases",
		classes: "col-lg-3 col-md-4 col-sm-6 col-12",
		unit: " Cases",
	},
	{
		label: "label.lbs_per_case",
		accessor: "lbs_per_case",
		classes: "col-lg-3 col-md-4 col-sm-6 col-12",
		unit: " Lbs",
	},
	{
		label: "label.total_clamshells_required",
		accessor: "total_clamshells",
		classes: "col-lg-3 col-md-4 col-sm-6 col-12",
	},
	{
		label: "label.total_lbs_required",
		accessor: "total_lbs",
		classes: "col-lg-3 col-md-4 col-sm-6 col-12",
		unit: " Lbs",
	},
];

export const PackagingDetailsView = [
	{
		label: "label.actual_packaging_start_date_and_time",
		accessor: "packaging_start_time",
		classes: "col-lg-3 col-md-4 col-sm-6 col-12",
		render: (task, packagingDetails) =>
			packagingDetails.packaging_start_time ? Util.utcToLocal(packagingDetails.packaging_start_time) : "-",
	},
	{
		label: "label.changeover_time",
		accessor: "change_over_time",
		classes: "col-lg-3 col-md-4 col-sm-6 col-12",
		unit: "m",
	},
	{
		label: "label.downtime",
		accessor: "down_time",
		classes: "col-lg-3 col-md-4 col-sm-6 col-12",
		unit: "m",
	},
	{
		label: "label.actual_cases_packed",
		accessor: "cases_packed",
		classes: "col-lg-3 col-md-4 col-sm-6 col-12",
	},
	{
		label: "label.actual_no_of_employee",
		accessor: "no_of_employees",
		classes: "col-lg-3 col-md-4 col-sm-6 col-12",
	},
	{
		label: "label.actual_packaging_end_date_and_time",
		accessor: "packaging_complete_time",
		classes: "col-lg-3 col-md-4 col-sm-6 col-12",
		render: (task, packagingDetails) =>
			packagingDetails.packaging_complete_time ? Util.utcToLocal(packagingDetails.packaging_complete_time) : "-",
	},
];

export const packageDetailsTableForm = [
	{
		label: "label.packaging_start_date",
		name: "packaging_start_date",
	},
	{
		label: "label.actual_cases_packed",
		name: "cases_packed",
	},
	{
		label: "label.packaging_end_date_and_time",
		name: "packaging_end_date",
	},
	{
		label: "label.best_buy_days",
		name: "best_buy_days",
	},
	{
		label: "label.best_buy_date",
		name: "best_buy_date",
	},
	{
		label: "label.change_over_time",
		name: "change_overtime",
	},
	{
		label: "label.down_time",
		name: "down_time",
	},
	{
		label: "label.Lot_no",
		name: "lot_no",
	},
	{
		label: "label.actual_no_of_employee",
		name: "no_of_employees",
	},
	{
		label: "label.actions",
		name: "action",
	},
];
