import React from "react";
import { ListingBtn } from "../../../elements";
import i18next from "i18next";
import Util from "../../../utils/Util";

export const renderColumnsMU = ({ handleEditPanel, handleDeletePanelMU, permissionDetails }) => {
	const updatePermission =
		permissionDetails && permissionDetails.seeding && permissionDetails.seeding.permissions.update_seeding_task
			? permissionDetails.seeding.permissions.update_seeding_task
			: "";

	const deletePermission =
		permissionDetails && permissionDetails.seeding && permissionDetails.seeding.permissions.delete_seeding_task
			? permissionDetails.seeding.permissions.delete_seeding_task
			: "";
	return [
		{
			name: i18next.t('label.date'),
			accessor: "date",
			className: "sg-listing-col-date",
			headerClassName: "sg-listing-col-date-header",
			sortable: true,
			render: (row) => {
				return Util.formatMDY(row.media_usage_date);
			},
		},

		{
			name: i18next.t('label.media_usage'),
			accessor: "media_usage",
			className: "sg-listing-col-number",
			headerClassName: "sg-listing-col-number-header",
			sortable: false,
		},
		{
			name: i18next.t('label.lot_no'),
			accessor: "lot_number",
			className: "sg-listing-col-number",
			headerClassName: "sg-listing-col-number-header",
			render: (row) => (row.lot_number ? row.lot_number : "-"),
			sortable: false,
		},
		{
			name: i18next.t('label.user'),
			accessor: "user",
			className: "sg-listing-col-assignee",
			headerClassName: "sg-listing-col-assignee-header",
			render: (row) => (row.user && row.user.name) || "-",
			sortable: true,
		},

		{
			name: i18next.t('label.actions'),
			className: "sg-listing-col-actions justify-content-end",
			headerClassName: "sg-listing-col-actions-header justify-content-end",
			hide: updatePermission || deletePermission ? false : true,
			render: (row) => {
				return (
					<>
						{updatePermission ? (
							<>
								<ListingBtn
									parentClass={
										updatePermission && deletePermission ? "justify-content-end" : "justify-content-around"
									}
									tooltipText={i18next.t('tooltip.edit')}
									btnClass="btn btn-cell btn-light btn-sm ml-2 spinner-button"
									iconClass="icon icon-edit"
									onClick={() =>
										handleEditPanel({
											media_usage_date: new Date(row.media_usage_date),
											media_usage: row.media_usage,
											location_slug: { label: row.location.location_name, value: row.location.slug },
											lot_number: row.lot_number,
											user: row.user.name,
										})
									}
								/>
							</>
						) : (
							""
						)}

						{deletePermission ? (
							<ListingBtn
								tooltipText={i18next.t('tooltip.delete')}
								btnClass="btn btn-cell btn-light btn-sm ml-2"
								iconClass="icon icon-trash"
								onClick={() =>
									handleDeletePanelMU({
										location_slug: row.location.slug,
										media_usage_date: row.media_usage_date.split(" ")[0],
									})
								}
							/>
						) : (
							""
						)}
					</>
				);
			},
			sortable: false,
		},
	];
};
export const renderColumnsST = ({ handleEditPanel, handleDeletePanelST, permissionDetails }) => {
	const updatePermission =
		permissionDetails && permissionDetails.seeding && permissionDetails.seeding.permissions.update_seeding_task
			? permissionDetails.seeding.permissions.update_seeding_task
			: "";

	const deletePermission =
		permissionDetails && permissionDetails.seeding && permissionDetails.seeding.permissions.delete_seeding_task
			? permissionDetails.seeding.permissions.delete_seeding_task
			: "";
	return [
		{
			name: i18next.t('label.date'),
			accessor: "date",
			className: "sg-listing-col-date",
			headerClassName: "sg-listing-col-date-header",
			sortable: true,
			render: (row) => {
				return Util.formatMDY(row.set_up_date);
			},
		},

		{
			name: i18next.t('label.room'),
			accessor: "room",
			className: "sg-listing-col-number",
			headerClassName: "sg-listing-col-number-header",
			sortable: false,
		},
		{
			name: i18next.t('label.set_up_time'),
			accessor: "set_up_time_in_minutes",
			className: "sg-listing-col-number",
			headerClassName: "sg-listing-col-number-header",
			sortable: false,
		},
		{
			name: i18next.t('label.user'),
			accessor: "user",
			className: "sg-listing-col-assignee",
			headerClassName: "sg-listing-col-assignee-header",
			render: (row) => (row.user && row.user.name) || "-",
			sortable: true,
		},

		{
			name: i18next.t('label.actions'),
			className: "sg-listing-col-actions justify-content-end",
			headerClassName: "sg-listing-col-actions-header justify-content-end",
			hide: updatePermission || deletePermission ? false : true,
			render: (row) => {
				return (
					<>
						{updatePermission ? (
							<>
								<ListingBtn
									parentClass={
										updatePermission && deletePermission ? "justify-content-end" : "justify-content-around"
									}
									tooltipText={i18next.t('tooltip.edit')}
									btnClass="btn btn-cell btn-light btn-sm ml-2 spinner-button"
									iconClass="icon icon-edit"
									onClick={() =>
										handleEditPanel({
											location_slug: { label: row.location.location_name, value: row.location.slug },
											room: { value: row.room },
											set_up_date: new Date(row.set_up_date),
											set_up_type: row.set_up_type,
											set_up_time_in_minutes: row.set_up_time_in_minutes,
											user: row.user.name,
										})
									}
								/>
							</>
						) : (
							""
						)}
						{deletePermission ? (
							<ListingBtn
								tooltipText={i18next.t('tooltip.delete')}
								btnClass="btn btn-cell btn-light btn-sm ml-2"
								iconClass="icon icon-trash"
								onClick={() =>
									handleDeletePanelST({
										location_slug: row.location.slug,
										set_up_date: row.set_up_date.split(" ")[0],
										set_up_type: row.set_up_type,
										room: row.room,
									})
								}
							/>
						) : (
							""
						)}
					</>
				);
			},
			sortable: false,
		},
	];
};
export const renderColumnsCT = ({ handleEditPanel, handleDeletePanelCT, permissionDetails }) => {
	const updatePermission =
		permissionDetails && permissionDetails.seeding && permissionDetails.seeding.permissions.update_seeding_task
			? permissionDetails.seeding.permissions.update_seeding_task
			: "";

	const deletePermission =
		permissionDetails && permissionDetails.seeding && permissionDetails.seeding.permissions.delete_seeding_task
			? permissionDetails.seeding.permissions.delete_seeding_task
			: "";
	return [
		{
			name: i18next.t('label.date'),
			accessor: "date",
			className: "sg-listing-col-date",
			headerClassName: "sg-listing-col-date-header",
			sortable: true,
			render: (row) => {
				return Util.formatMDY(row.cleaning_date);
			},
		},

		{
			name: i18next.t('label.room'),
			accessor: "room",
			className: "sg-listing-col-number",
			headerClassName: "sg-listing-col-number-header",
			sortable: false,
		},
		{
			name: i18next.t('label.cleaning_time'),
			accessor: "cleaning_time_in_minutes",
			className: "sg-listing-col-number",
			headerClassName: "sg-listing-col-number-header",
			sortable: false,
		},
		{
			name: i18next.t('label.user'),
			accessor: "user",
			className: "sg-listing-col-assignee",
			headerClassName: "sg-listing-col-assignee-header",
			render: (row) => (row.user && row.user.name) || "-",
			sortable: true,
		},

		{
			name: i18next.t('label.actions'),
			className: "sg-listing-col-actions justify-content-end",
			headerClassName: "sg-listing-col-actions-header justify-content-end",
			hide: updatePermission || deletePermission ? false : true,
			render: (row) => {
				return (
					<>
						{updatePermission ? (
							<>
								<ListingBtn
									parentClass={
										updatePermission && deletePermission ? "justify-content-end" : "justify-content-around"
									}
									tooltipText={i18next.t('tooltip.edit')}
									btnClass="btn btn-cell btn-light btn-sm ml-2 spinner-button"
									iconClass="icon icon-edit"
									onClick={() =>
										handleEditPanel({
											location_slug: { label: row.location.location_name, value: row.location.slug },
											cleaning_date: new Date(row.cleaning_date),
											cleaning_time_in_minutes: row.cleaning_time_in_minutes,
											cleaning_type: row.cleaning_type,
											room: { value: row.room },
											user: row.user.name,
										})
									}
								/>
							</>
						) : (
							""
						)}
						{deletePermission ? (
							<ListingBtn
								tooltipText={i18next.t('tooltip.delete')}
								btnClass="btn btn-cell btn-light btn-sm ml-2"
								iconClass="icon icon-trash"
								onClick={() =>
									handleDeletePanelCT({
										location_slug: row.location.slug,
										cleaning_type: row.cleaning_type,
										room: row.room,
										cleaning_date: row.cleaning_date.split(" ")[0],
									})
								}
							/>
						) : (
							""
						)}
					</>
				);
			},
			sortable: false,
		},
	];
};
