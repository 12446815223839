import { createSelectorCreator, defaultMemoize } from "reselect";
import { isEmpty, isEqual, values, keys } from "lodash";
import Util from "utils/Util";
import moment from "moment";

const getLabResult = (state) => state.LabResult;

const getForm = (state) => state.form;

const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);

const getDropdownData = createDeepEqualSelector([getLabResult], (lr) => lr.dropdownData);

export const LabResultForm = createDeepEqualSelector([getForm], (form) => form.LabResultForm);

export const getLoading = createDeepEqualSelector([getLabResult], (lr) => lr && lr.loading);

export const getLocationList = createDeepEqualSelector([getDropdownData], (data) =>
	data && data.locations && !isEmpty(data.locations) ? Util.mapOptions(values(data.locations), "location_name", "slug") : []
);

export const labResultForm = createDeepEqualSelector([getForm], (form) => form.labResultForm);
const getlocationSlugKeyMappedArray = (values) => {
	const data = [];
	values.forEach(function (x) {
		data[x.slug] = x;
	});
	return data;
};

export const getLocationData = createDeepEqualSelector([getDropdownData], (data) =>
	data && data.locations && !isEmpty(data.locations) ? getlocationSlugKeyMappedArray(data.locations) : []
);

const columnFormat = (values) => {
	const data = values.map((item) => ({
		name: item.nutrient,
		slug: item.slug,
	}));
	return data;
};

export const getNutrientList = createDeepEqualSelector([getDropdownData], (data) =>
	data && data.nutrients && !isEmpty(data.nutrients) ? columnFormat(data.nutrients) : []
);
export const getFormDataArray = createDeepEqualSelector([getLabResult], (lr) => lr.formDataArray || []);

export const getAddFormFromDate = createDeepEqualSelector([labResultForm], (form) =>
	form && form.values && form.values.week && form.values.week[0] ? form.values.week[0] : null
);
export const getAddFormToDate = createDeepEqualSelector([labResultForm], (form) =>
	form && form.values && form.values.week && form.values.week[1] ? form.values.week[1] : null
);

export const getFormFromDate = createDeepEqualSelector([getFormDataArray], (data) =>
	data && data.start_date ? data.start_date : null
);
export const getFormToDate = createDeepEqualSelector([getFormDataArray], (data) =>
	data && data.end_date ? data.end_date : null
);

export const getFormLocation = createDeepEqualSelector([getFormDataArray], (data) =>
	data && data.location_slug ? data.location_slug : null
);

export const getFormPond = createDeepEqualSelector([getFormDataArray], (data) => (data && data.pond ? data.pond : null));

const fromDateFormat = createDeepEqualSelector([getFormFromDate], (date) => (date ? new Date(date) : null));

export const getThirtyWeeks = createDeepEqualSelector([fromDateFormat], (date) =>
	date
		? new Date(
				moment(date.setDate(date.getDate() + 7 * 29))
					.endOf("week")
					.format("MM-DD-YYYY")
		  )
		: null
);

// const getWeeks = (from, to) => {
// 	const dateArray = [];
// 	let curDate = moment(from);
// 	const stDate = moment(to);
// 	while (curDate <= stDate) {
// 		dateArray.push(moment(curDate).format("MM-DD-YYYY"));
// 		curDate = moment(curDate).add(7, "days");
// 	}

// 	return dateArray;
// };

export const getSelectedWeekDates = createDeepEqualSelector(
	[getAddFormFromDate, getAddFormToDate],
	(from, to) => (from && to && getWeeks(from, to)) || null
);

export const getColumnsLR = createDeepEqualSelector([getLabResult], (lr) => lr.columns);

export const getDataLR = createDeepEqualSelector([getLabResult], (lr) => lr.data);

export const getRenderType = createDeepEqualSelector([getLabResult], (lr) => lr.renderType);

export const getDateSlugMapping = createDeepEqualSelector([getLabResult], (lr) => lr.dateSlugMapping);

export const getSelectedLocationProductVariety = createDeepEqualSelector(
	[getLocationData, getFormLocation],
	(data, location) =>
		(data && location && Util.mapOptions(data[location]["product_varieties"], "product_variety_name", "slug")) || null
);

export const getCroptimalData = createDeepEqualSelector([getLabResult], (lr) => lr.croptimalData);

const labPermit = (state) => state.Auth.permissionDetails.lab_results;

export const getAddPermission = createDeepEqualSelector([labPermit], (access) => access && access.permissions.add_lab_results);

export const getEditPermission = createDeepEqualSelector(
	[labPermit],
	(access) => access && access.permissions.edit_lab_results
);

export const getDeletePermission = createDeepEqualSelector(
	[labPermit],
	(access) => access && access.permissions.delete_lab_results
);

export const getListPermission = createDeepEqualSelector(
	[labPermit],
	(access) => access && access.permissions.list_lab_results
);

export const getProductVarietySelected = createDeepEqualSelector([getLabResult], (lr) => lr.productVarietySelected);

const getWeeks = (from, to) => {
	const dateArray = [];
	let curDate = moment(from);
	const stDate = moment(to);
	while (curDate <= stDate) {
		dateArray.push(moment(curDate).format("MM-DD-YYYY"));
		curDate = moment(curDate).add(7, "days");
	}

	return dateArray;
};


const tabKeys = (data,id) => {
	const keys = {};
	// eslint-disable-next-line array-callback-return
	data.map((item, index) => {
		keys[item.replace(/\s/g, "")] = {
			key: id[item] && id[item][0] && id[item][0].pond_number,
			label: item,
		};
	});
	return keys;
};

export const pondKeyLabels = createDeepEqualSelector([getLabResult], (data) =>
	data && !isEmpty(data.pondTabs) ? tabKeys(keys(data.pondTabs),data.pondTabs) : null
);

export const getTabsPonds = createDeepEqualSelector([pondKeyLabels], (data) => (data && !isEmpty(data) ? values(data) : null));

export const getActivePondTabLr = createDeepEqualSelector([getLabResult], (data) => data && data.pondTabsLR);
export const getActivePondTab = createDeepEqualSelector([getLabResult], (data) => data && data.activePond);
