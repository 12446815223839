import React from "react";
import { join } from "lodash";
import { ButtonLink, ListingBtn } from "../../../elements";

export const renderColumns = ({
	handleDeletePanel,
	addToFilter,
	handleEdit,
	handleView,
	handleDcReorder,
	permissionDetails,
}) => {
	const updatePermission =
		permissionDetails && permissionDetails.customer && permissionDetails.customer.permissions.update_customer
			? permissionDetails.customer.permissions.update_customer
			: "";
	const viewPermission =
		permissionDetails && permissionDetails.customer && permissionDetails.customer.permissions.view_customer
			? permissionDetails.customer.permissions.view_customer
			: "";
	const deletePermission =
		permissionDetails && permissionDetails.customer && permissionDetails.customer.permissions.delete_customer
			? permissionDetails.customer.permissions.delete_customer
			: "";
	return [
		{
			name: "Customer Name",
			accessor: "customer_name",
			className: "customer-listing-col-name",
			headerClassName: "customer-listing-col-name-header",
			filter: {
				type: "search",
				onChange: addToFilter,
			},
			render: (row) => {
				return viewPermission ? (
					<ButtonLink className="p-0" onClick={() => handleView(`${row.slug}`)}>
						{row.customer_name}
					</ButtonLink>
				) : (
					row.customer_name
				);
			},
		},
		{
			name: "DC City",
			accessor: "dc_city",
			className: "customer-listing-col-name",
			headerClassName: "customer-listing-col-name-header",

			render: (row) => join(row.dc_city, ", "),
			sortable: false,
		},
		{
			name: "DC Name",
			accessor: "dc_name",
			className: "customer-listing-col-dc",
			headerClassName: "customer-listing-col-dc-header",
			render: (row) => join(row.dc_name, ", "),
			sortable: false,
		},

		{
			name: "Actions",
			className: "customer-listing-col-actions",
			headerClassName: "customer-listing-col-actions-header",
			hide: updatePermission || deletePermission ? false : true,
			render: (row) => {
				return (
					<>
						{updatePermission ? (
							<>
								<ListingBtn
									parentClass={
										updatePermission && deletePermission ? "justify-content-end" : "justify-content-around"
									}
									tooltipText="Manage DC Priority"
									iconClass="icon icon-reorder"
									onClick={() => handleDcReorder(`${row.slug}`)}
								/>
								<ListingBtn
									tooltipText="Edit"
									iconClass="icon icon-edit"
									onClick={() => handleEdit(`${row.slug}`)}
								/>
							</>
						) : (
							""
						)}
						{deletePermission ? (
							<ListingBtn
								parentClass={
									updatePermission && deletePermission ? "justify-content-end" : "justify-content-around"
								}
								tooltipText="Delete"
								iconClass="icon icon-trash"
								onClick={() => handleDeletePanel(`${row.slug}`)}
							/>
						) : (
							""
						)}
					</>
				);
			},
			sortable: false,
		},
	];
};
