import { LANE_CALCULATOR } from "store/types";
import fetchHandler from "utils/fetchHandler";

export const setLoaderLc = () => {
	return (dispatch) => {
		dispatch({
			type: LANE_CALCULATOR.LOADER_LC,
		});
	};
};

export const getLocationList = (successHandler, errorHandler) => {
	const fetchOptions = {
		url: `location-customers`,
		method: "GET",
		secure: true,
		actionType: LANE_CALCULATOR.LOCATION_LIST,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};
export const fetchLaneCalculatorData = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `lane-calculator/customer-lane-usage`,
		method: "POST",
		secure: true,
		body: JSON.stringify(values),
		actionType: LANE_CALCULATOR.SET_DATA,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const fetchLaneCalculatorForecastData = (values, successHandler, errorHandler) => {
	let action;
	if (
		values.view_by === "sales" ||
		values.view_by === "production_vs_sales_difference" ||
		values.view_by === "production_vs_sales"
	) {
		action = LANE_CALCULATOR.SET_DATA_FORCAST;
	} else if (values.view_by === "production") {
		action = LANE_CALCULATOR.SET_PRODUCTION_DATA_FORCAST;
	}
	const fetchOptions = {
		url: `lane-calculator/forecast-data`,
		method: "POST",
		secure: true,
		body: JSON.stringify(values),
		actionType: action,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const setActiveTabCustomerLaneCalculator = (value, type) => {
	return (dispatch) => {
		dispatch({
			type: LANE_CALCULATOR.SET_ACTIVE_TAB_CUSTOMER_LC,
			payload: value,
		});
	};
};

export const setActiveTabDCLaneCalculator = (value) => {
	return (dispatch) => {
		dispatch({
			type: LANE_CALCULATOR.SET_ACTIVE_TAB_DC_LC,
			payload: value,
		});
	};
};

export const setTableViewColumns = (value, type) => {
	return (dispatch) => {
		dispatch({
			type: LANE_CALCULATOR.SET_TABLE_VIEW_COLUMNS,
			payload: value,
		});
	};
};

export const setLaneUsageDropDown = (value) => {
	return (dispatch) => {
		dispatch({
			type: LANE_CALCULATOR.TYPE_LU,
			payload: value,
		});
	};
};

export const setTableViewDropDown = (value) => {
	return (dispatch) => {
		dispatch({
			type: LANE_CALCULATOR.TYPE_TV,
			payload: value,
		});
	};
};

export const laneCalculatorRefresh = () => {
	return (dispatch) => {
		dispatch({
			type: LANE_CALCULATOR.RESET_REFRESH_LC,
		});
	};
};

export const fetchSkusPvList = (slug, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `location-sku-pv/${slug}`,
		method: "GET",
		secure: true,
		actionType: LANE_CALCULATOR.SKU_AND_PV,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const laneUsage = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `lane-calculator`,
		method: "POST",
		secure: true,
		body: JSON.stringify(values),
		actionType: LANE_CALCULATOR.LANE_USAGE_DATA,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const setSubmitTrue = (value) => {
	return (dispatch) => {
		dispatch({
			type: LANE_CALCULATOR.SET_SUBMIT_TRUE,
			payload: value,
		});
	};
};

export const resetFieldLaneUsage = () => {
	return (dispatch) => {
		dispatch({
			type: LANE_CALCULATOR.RESET_FIELD_LU,
		});
	};
};

export const fetchLocationProductVaritiesLc = (successHandler, errorHandler) => {
	const fetchOptions = {
		url: `location-product-varieties`,
		method: "GET",
		secure: true,
		actionType: LANE_CALCULATOR.LOCATION_PRODUCT_VARIETIES,
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const fetchGrowLanesLc = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `lane-calculator/advanced-values`,
		method: "POST",
		secure: true,
		body: JSON.stringify(values),
		actionType: LANE_CALCULATOR.SET_GROW_LANES,
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const addGrowLanesLc = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `lane-calculator/advanced`,
		method: "POST",
		secure: true,
		body: JSON.stringify(values),
		actionType: LANE_CALCULATOR.SET_PRODUCT_VARIETIES,
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const setLaneToCaseFormData = (values) => {
	return (dispatch) => {
		dispatch({
			type: LANE_CALCULATOR.LANE_TO_CASE_FORM_DATA,
			payload: values,
		});
	};
};

export const resetProductVarities = () => {
	return (dispatch) => {
		dispatch({
			type: LANE_CALCULATOR.RESET_PRODUCT_VARIETIES,
		});
	};
};
export const resetLaneToCase = () => {
	return (dispatch) => {
		dispatch({
			type: LANE_CALCULATOR.RESET_LANE_TO_CASE,
		});
	};
};

export const setProductVarieties = (values) => {
	return (dispatch) => {
		dispatch({
			payload: values,
			type: LANE_CALCULATOR.SET_PRODUCT_VARIETIES_CL,
		});
	};
};
export const resetLaneUsageLC = () => {
	return (dispatch) => {
		dispatch({
			type: LANE_CALCULATOR.RESET_LANE_USAGE_LC,
		});
	};
};

export const resetGrowLanes = () => {
	return (dispatch) => {
		dispatch({
			type: LANE_CALCULATOR.RESET_GROW_LANES,
		});
	};
};

