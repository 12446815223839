import React, { useEffect, useState, useRef, useContext, createContext, useMemo } from "react";
import { useParams, useHistory } from "react-router-dom";
import { fetchLocationDetails, fetchHarvestActualV2, updateHarvestActualV2, deleteHarvestActualV2 } from "store/actions";
import { errorMessage } from "utils/errorMessage";
import { connect } from "react-redux";
import _, { get } from "lodash";

import "./index.scss";
import { ContentWrap, ListingBtn } from "elements";
import date from "moment";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { Button, Col, Row } from "react-bootstrap";

import { successMessage } from "../../utils/successMessage";

import growerVarieties from "../harvestForecastV2/farmVarieties";
import pondLayout from "components/harvestForecastV2/pondLayouts";
import { MenuItem, Select, TextField } from "@mui/material";
import { CommonDatePicker } from "components/bulkAssignee/bulkAssigneeComponents";

const GrowerToolsHarvest = ({ fetchLocationDetails, fetchHarvestActualV2, updateHarvestActualV2, deleteHarvestActualV2 }) => {
	let location = useLocation();
	let history = useHistory();
	const urlParams = new URLSearchParams(location.search);
	const [visible, setVisible] = useState([]);
	const [greenhouse, setGreenhouse] = useState();
	const [harvestData, setHarvestData] = useState({});
	const [save, setSave] = useState({});
	const [refresh, setRefresh] = useState(false);
	const [clearEntries, setClearEntries] = useState(false);
	const saveRef = useRef({});
	const updateRef = useRef({});
	const [loading, setLoading] = useState(false);
	const [printer, setPrinter] = useState({ status: "connecting" });
	const [calcs, setCalcs] = useState({});

	let params = useParams();

	const setHarvestSaveData = (varietyCode, e, field, value, dbId = null) => {
		let saver = { ...saveRef.current };
		let updater = { ...updateRef.current };
		if (dbId) {
			setNestedKey(updater, [varietyCode, dbId, field], value);
			setNestedKey(updater, [varietyCode, dbId, "date"], params.date);
			setNestedKey(updater, [varietyCode, dbId, "farm"], params.slug);
			setNestedKey(updater, [varietyCode, dbId, "id"], dbId);
			updateRef.current = updater;
		} else {
			setNestedKey(saver, [varietyCode, e, field], value);
			setNestedKey(saver, [varietyCode, e, "date"], params.date);
			setNestedKey(saver, [varietyCode, e, "farm"], params.slug);
			saveRef.current = saver;
		}
	};

	const setNestedKey = (obj, path, value) => {
		// Start with the root object
		let current = obj;

		// Iterate over the path array, except for the last element
		for (let i = 0; i < path.length - 1; i++) {
			// If the key doesn't exist or it's not an object, create it
			if (typeof current[path[i]] !== "object" || current[path[i]] === null) {
				current[path[i]] = {};
			}

			// Move to the next level
			current = current[path[i]];
		}

		// Set the value at the final key
		current[path[path.length - 1]] = value;
	};

	useEffect(() => {
		console.log(save);
		const handleBeforeUnload = (e) => {
			// You can check if the user has taken some action here
			// For example, check if a form is dirty or if specific data has been modified
			const isDirty = save.length > 0; // Replace this with your condition

			if (isDirty) {
				e.preventDefault();
				e.returnValue = "Are you sure you want to leave without saving?"; // This is the message that will be displayed in the confirmation dialog
			}
		};

		window.addEventListener("beforeunload", handleBeforeUnload);

		return () => {
			// Cleanup the event listener when the component unmounts
			window.removeEventListener("beforeunload", handleBeforeUnload);
		};
	}, [save]);

	useEffect(() => {
		var newCalcs = {};
		var total = 0;
		var total_boards = 0;
		growerVarieties[params.slug].map((v, ix) => {
			if (harvestData[v.varietyCode]) {
				newCalcs[v.varietyCode] = { total_pounds: null, oz_per_board: null, boards: null };
				newCalcs[v.varietyCode]["total_pounds"] = _.sum(
					harvestData[v.varietyCode].map((e) => {
						if (e.weights == null) {
							return 0;
						}
						return Object.values(e.weights)
							.map(Number)
							.reduce((acc, num) => acc + num, 0);
					})
				).toFixed(2);

				newCalcs[v.varietyCode]["boards"] = _.sum(
					harvestData[v.varietyCode].map((e) => {
						return parseFloat(e.boards);
					})
				).toFixed(2);

				total = parseFloat(total) + parseFloat(newCalcs[v.varietyCode]["total_pounds"]);
				total_boards = parseFloat(total_boards) + parseFloat(newCalcs[v.varietyCode]["boards"]);

				newCalcs[v.varietyCode]["oz_per_board"] = (
					_.sum(
						harvestData[v.varietyCode].map((e) => {
							if (e.weights == null) {
								return 0;
							}
							return (
								Object.values(e.weights)
									.map(Number)
									.reduce((acc, num) => acc + num, 0) * 16
							);
						})
					).toFixed(2) /
					_.sum(
						harvestData[v.varietyCode].map((e) => {
							return parseFloat(e.boards);
						})
					).toFixed(2)
				).toFixed(2);
			}
		});
		newCalcs["total"] = total;
		newCalcs["total_boards"] = total_boards;
		setCalcs(newCalcs);
	}, [harvestData]);

	useEffect(() => {
		fetchLocationDetails(params.slug, successHandler, errorHandler);
		setVisible([growerVarieties[params.slug][0].varietyCode]);
	}, []);

	const successHandler = (e) => {
		if (!params.date) {
			history.push(`/gh/grower-harvest/${params.slug}/${date().format("MMDDYYYY")}`);
		}
		console.log(e.data.slug);
		if (e.data?.slug && params.date) {
			pullActuals(e.data);
		}
		var data = e.data;
		setGreenhouse(data);
	};

	function formatDate(d) {
		return date(d, "MMDDYYYY").format("ddd, MMM Do YYYY");
	}

	const errorHandler = (e) => {
		errorMessage(e);
	};

	const saveActual = (e) => {
		const target = e;
		target.disabled = true;
		setClearEntries(true);
		updateHarvestActualV2(
			{ location_slug: params.slug, data: saveRef.current, update: updateRef.current },
			(e2) => {
				target.disabled = false;
				successMessage(e2);
				saveRef.current = {};
				updateRef.current = {};
				setRefresh(!refresh);
				setClearEntries(false);
				target.disabled = false;
			},
			(e3) => {
				target.disabled = false;
				errorHandler(e3);
			}
		);
	};

	const deleteRow = async (id) => {
		deleteHarvestActualV2(
			{ id: id },
			(e) => {
				successMessage({ status: "success", message: "Deleted Harvest", code: 359 });
				pullActuals();
			},
			errorHandler
		);
	};

	const findPrinter = async () => {
		window.BrowserPrint.getLocalDevices(function (deviceList) {
			if (deviceList.deviceList.length > 0) {
				setPrinter({ status: "found", device: deviceList.deviceList[0] });
			} else {
				setPrinter({ status: "not-found", error: "" });
			}
		});
	};

	const connectPrinter = async () => {
		// window.BrowserPrint.getLocalDevices(function (deviceList) {
		// 	if (deviceList.deviceList.length > 0) {
		// 		setPrinter({ status: "found", device: deviceList.deviceList[0] });
		// 	} else {
		// 		setPrinter({ status: "not-found", error: "" });
		// 	}
		// });
		window.BrowserPrint.getDefaultDevice(
			"printer",
			function (device) {
				setPrinter({ status: "found", device: device });
				console.log("found printer", device);
			},
			function (error) {
				setPrinter({ status: "not-found", error: error });
			}
		);
	};

	const printLabel = async (data, weightIndex = null) => {
		console.log(data);
		const ip = { pen: "10.22.30.30" };
		var printerIpAddress = ip[params.slug]; // Replace with your printer's IP address

		// window.BrowserPrint.getLocalDevices(function (deviceList) {
		// 	if (deviceList.deviceList.length > 0) {
		// 		let device = deviceList.deviceList[0];
		// 		console.log("found printer", device);
		// 		device.send(
		// 			"^XA^XFE:HWC.ZPL^FS^FN1^FD" +
		// 				"P:" +
		// 				data.data.pond +
		// 				"L:" +
		// 				data.data.lane +
		// 				"^FS^FN2^FD+^FS^FN3^FD" +
		// 				params.date +
		// 				"^FS^FN4^FD" +
		// 				data.name +
		// 				"^FS^FN5^FD" +
		// 				data.data.boards +
		// 				" boards^FS^FN6^FD" +
		// 				new Date().toLocaleString() +
		// 				"^FS^FN7^FD" +
		// 				data.data.weights[
		// 					weightIndex != null ? weightIndex : data.data.weights.length > 0 ? data.data.weights.length - 1 : 0
		// 				] +
		// 				"lbs^FS^FN8^FD^FS^FN9^FD" +
		// 				greenhouse.location_name +
		// 				"^FS^PQ1^XZ",
		// 			function (success) {
		// 				successMessage({ status: "success", message: "Zebra Print Sucessfull", code: 358 });
		// 				console.log(success);
		// 			},
		// 			function (error) {
		// 				alert("Error:" + error);
		// 			}
		// 		);
		// 	} else {
		// 		alert("did not find printer");
		// 	}
		// });

		window.BrowserPrint.getDefaultDevice(
			"printer",
			function (device) {
				console.log("found printer", device);
				device.send(
					"^XA^XFE:HWC.ZPL^FS^FN1^FD" +
						"P:" +
						data.data.pond +
						"L:" +
						data.data.lane +
						"^FS^FN2^FD+^FS^FN3^FD" +
						params.date +
						"^FS^FN4^FD" +
						data.name +
						"^FS^FN5^FD" +
						data.data.boards +
						" boards^FS^FN6^FD" +
						new Date().toLocaleString() +
						"^FS^FN7^FD" +
						data.data.weights[
							weightIndex != null ? weightIndex : data.data.weights.length > 0 ? data.data.weights.length - 1 : 0
						] +
						"lbs^FS^FN8^FD^FS^FN9^FD" +
						greenhouse.location_name +
						"^FS^PQ1^XZ",
					function (success) {
						successMessage({ status: "success", message: "Zebra Print Sucessfull", code: 358 });
						console.log(success);
					},
					function (error) {
						alert("Error:" + error);
					}
				);
			},
			function (error) {
				alert("did not find printer", error);
			}
		);
	};

	const pullActuals = () => {
		setLoading(true);
		fetchHarvestActualV2(
			{
				date: params.date,
				location_id: params.slug,
			},
			(e) => {
				console.log(e);
				var harvest = {};
				e.forEach((hv) => {
					if (harvest[hv.variety_code_from_grower]) {
						harvest[hv.variety_code_from_grower].push(hv);
					} else {
						harvest[hv.variety_code_from_grower] = [];
						harvest[hv.variety_code_from_grower].push(hv);
					}
				});
				console.log(harvest);
				console.log(Object.keys(harvest).length);
				setHarvestData(harvest);
				setLoading(false);
			},
			errorHandler
		);
	};

	useEffect(() => {
		console.log("greenhouse:", greenhouse);
		console.log("params:", params);
		connectPrinter();

		if (greenhouse?.slug && params.date) {
			pullActuals();
		}
	}, [refresh]);

	const copyFromDate = (e, date) => {
		const target = e.currentTarget;
		console.log(date);
		fetchHarvestActualV2(
			{
				date: date,
				location_id: params.slug,
			},
			(ee) => {
				console.log(ee);
				var harvest = {};
				ee.forEach((hv, index) => {
					setHarvestSaveData(hv.variety_code_from_grower, index, "pond", hv.pond);
					setHarvestSaveData(hv.variety_code_from_grower, index, "lane", hv.lane);
					setHarvestSaveData(hv.variety_code_from_grower, index, "cycle", hv.cycle);
					setHarvestSaveData(hv.variety_code_from_grower, index, "seeds", hv.seeds);
					setHarvestSaveData(hv.variety_code_from_grower, index, "boards", hv.boards);
				});
				// console.log(harvest);
				// console.log(saveRef.current);
				saveActual(target);
				// setHarvestSaveData(
				//     v.varietyCode,
				//     e.id,
				//     "pond",
				//     event.target.value,
				//     e.id
				// );
			},
			errorHandler
		);
	};

	return (
		<ContentWrap>
			<div className="d-flex" style={{ paddingBottom: 10 }}>
				<div style={{ flex: 1 }}>
					<div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
						<div style={{ fontSize: 32, fontWeight: "bold" }}>{greenhouse?.location_name}</div>
						<div style={{ fontSize: 16 }}>
							{greenhouse?.city}, {greenhouse?.state}
						</div>

						<div>
							<button onClick={findPrinter}>Find Printer</button>
							<div>{printer && printer.status == "connecting" && <h2>FINDING PRINTER</h2>}</div>
							<div>
								{printer && printer.status == "found" && (
									<>
										<h2>Printer: Connected</h2>
										<p>{printer.device.name}</p>
										<p>{printer.device.uid}</p>
									</>
								)}
							</div>
							<div>
								{printer && printer.status == "not-found" && (
									<div>
										<h2>Printer: NO PRINTER</h2>
										<p>Error: {JSON.stringify(printer.error)}</p>
									</div>
								)}
							</div>
						</div>
					</div>
					<div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
						<div style={{ fontSize: 32, fontWeight: "bold" }}>
							{formatDate(params.date)}
							<div style={{ fontSize: 18 }}>All lbs: {calcs.total} lbs</div>
							<div style={{ fontSize: 18 }}>All boards: {calcs.total_boards} lbs</div>
						</div>
						{params.date && (
							<CommonDatePicker
								label={"Harvest Date"}
								placeholder={"Select Date"}
								selectedDate={date(params.date, "MMDDYYYY").toDate()}
								onSelect={(event) => {
									history.push(`/gh/grower-harvest/${params.slug}/${date(event).format("MMDDYYYY")}`);
								}}
							/>
						)}
					</div>
					{Object.keys(harvestData).length == 0 && (
						<div style={{ paddingBottom: 24 }}>
							<Button
								style={{ marginLeft: 4, marginRight: 4, backgroundColor: "green" }}
								onClick={(e) => {
									copyFromDate(e, date(params.date, "MMDDYYYY").subtract(1, "day").format("MMDDYYYY"));
								}}>
								Copy from Yesterday
							</Button>
							<Button
								style={{ marginLeft: 4, marginRight: 4, backgroundColor: "green" }}
								onClick={(e) => {
									copyFromDate(e, date(params.date, "MMDDYYYY").subtract(2, "day").format("MMDDYYYY"));
								}}>
								Copy from {date(params.date, "MMDDYYYY").subtract(2, "day").format("MM/DD/YYYY")}
							</Button>
							<Button
								style={{ marginLeft: 4, marginRight: 4, backgroundColor: "green" }}
								onClick={(e) => {
									copyFromDate(e, date(params.date, "MMDDYYYY").subtract(3, "day").format("MMDDYYYY"));
								}}>
								Copy from {date(params.date, "MMDDYYYY").subtract(3, "day").format("MM/DD/YYYY")}
							</Button>
						</div>
					)}
					{params.slug &&
						growerVarieties[params.slug].map((v, ix) => {
							return (
								<Button
									style={{ marginLeft: 4, marginRight: 4, marginBottom: 4, height: 75 }}
									key={v.varietyCode}
									onClick={(e) => {
										// if (visible.includes(v.varietyCode)) {
										// 	let vis = [...visible];
										// 	let index = vis.indexOf(v.varietyCode);
										// 	if (index > -1) {
										// 		vis.splice(index, 1);
										// 	}
										// 	setVisible(vis);
										// } else {
										let vis = [];
										vis.push(v.varietyCode);
										setVisible(vis);
										// }
									}}>
									{v.growerName} {visible.includes(v.varietyCode) ? "👁" : ""}
									{calcs[v.varietyCode] && (
										<div>
											<div>{harvestData[v.varietyCode] && calcs[v.varietyCode]?.total_pounds} lbs</div>
											<div>
												{harvestData[v.varietyCode] && calcs[v.varietyCode]?.oz_per_board} oz/board
											</div>
										</div>
									)}
								</Button>
							);
						})}
				</div>
			</div>

			{params.slug &&
				growerVarieties[params.slug].map((v, ix) => {
					if (!visible.includes(v.varietyCode)) {
						return;
					}
					return (
						<div
							key={`${v.perfName} (${v.growerName} - ${v.varietyCode})`}
							style={{ paddingBottom: 24, marginBottom: 24 }}>
							<Row
								style={{
									width: "100%",
									justifyContent: "space-evenly",
									alignItems: "center",
									paddingBottom: 24,
								}}>
								<Col>
									<h1 style={{ fontSize: 28, fontWeight: "bold", paddingTop: 0 }}>
										{v.perfName} ({v.growerName} - {v.varietyCode}){" "}
									</h1>
									<h2>{harvestData[v.varietyCode] && calcs[v.varietyCode]?.total_pounds} lbs</h2>
									<h2>{harvestData[v.varietyCode] && calcs[v.varietyCode]?.boards} boards</h2>
									<h2>{harvestData[v.varietyCode] && calcs[v.varietyCode]?.oz_per_board} oz/board</h2>
								</Col>
								<Col style={{ textAlign: "right" }}>
									<Button
										onClick={(e) => {
											saveActual(e.currentTarget);
										}}>
										Save
									</Button>
								</Col>
							</Row>
							<Row
								style={{
									width: "100%",
									alignItems: "center",
								}}>
								<Col style={{ maxWidth: 60, border: "1px solid gray", padding: 4 }}>
									<span style={{ fontSize: 12 }}>Pond</span>
								</Col>
								<Col style={{ maxWidth: 60, border: "1px solid gray", padding: 4 }}>
									<span style={{ fontSize: 12 }}>Lane</span>
								</Col>
								<Col style={{ maxWidth: 60, border: "1px solid gray", padding: 4 }}>
									<span style={{ fontSize: 12 }}>Cycle</span>
								</Col>
								<Col style={{ maxWidth: 60, border: "1px solid gray", padding: 4 }}>
									<span style={{ fontSize: 12 }}>Seeds</span>
								</Col>
								<Col style={{ maxWidth: 60, border: "1px solid gray", padding: 4 }}>
									<span style={{ fontSize: 12 }}>Boards</span>
								</Col>
								<Col style={{ maxWidth: 60, border: "1px solid gray", padding: 4 }}>
									<span style={{ fontSize: 12 }}>Blade</span>
								</Col>
								<Col style={{ maxWidth: 600, border: "1px solid gray", padding: 4 }}>
									<span style={{ fontSize: 12 }}>Weights (lbs)</span>
								</Col>
								<Col style={{ maxWidth: 60, border: "1px solid gray", padding: 4 }}>
									<span style={{ fontSize: 12 }}>Lbs</span>
								</Col>
								<Col style={{ maxWidth: 60, border: "1px solid gray", padding: 4 }}>
									<span style={{ fontSize: 12 }}>Oz/Boa</span>
								</Col>
								<Col style={{ maxWidth: 60, border: "1px solid gray", padding: 4 }}>
									<span style={{ fontSize: 12 }}>Actions</span>
								</Col>
							</Row>
							{!clearEntries &&
								harvestData[v.varietyCode] &&
								harvestData[v.varietyCode].map((e) => {
									return (
										<Row
											key={`${e.id}-${v.perfName} (${v.growerName} - ${v.varietyCode}) - ${e.updated_at}`}
											style={{ borderBottom: "1px solid gray" }}>
											<Col style={{ maxWidth: 60, padding: 4 }}>
												<Select
													name={`row-${e.id}-pond`}
													defaultValue={e.pond}
													onChange={(event) => {
														setHarvestSaveData(
															v.varietyCode,
															e.id,
															"pond",
															event.target.value,
															e.id
														);
													}}>
													{pondLayout[params.slug].map((v, ix) => {
														return (
															<MenuItem key={v} value={v}>
																{v}
															</MenuItem>
														);
													})}
													<MenuItem key={"undefined"} value={undefined}></MenuItem>
												</Select>
											</Col>
											<Col style={{ maxWidth: 60, padding: 4 }}>
												<Select
													name={`row-${e.id}-lane-id-${e.id}`}
													defaultValue={e.lane}
													onChange={(event) => {
														setHarvestSaveData(v.varietyCode, e, "lane", event.target.value, e.id);
													}}>
													<MenuItem value={1}>1</MenuItem>
													<MenuItem value={2}>2</MenuItem>
													<MenuItem value={3}>3</MenuItem>
													<MenuItem key={"undefined"} value={undefined}></MenuItem>
												</Select>
											</Col>
											<Col style={{ maxWidth: 60, padding: 4 }}>
												<TextField
													onChange={(event) => {
														setHarvestSaveData(v.varietyCode, e, "cycle", event.target.value, e.id);
													}}
													defaultValue={e.cycle}
													name={`row-${e}-cycle`}></TextField>
											</Col>
											<Col style={{ maxWidth: 60, padding: 4 }}>
												<TextField
													onChange={(event) => {
														setHarvestSaveData(v.varietyCode, e, "seeds", event.target.value, e.id);
													}}
													defaultValue={e.seeds}
													name={`row-${e}-seeds`}></TextField>
											</Col>
											<Col style={{ maxWidth: 60, padding: 4 }}>
												<TextField
													onChange={(event) => {
														setHarvestSaveData(
															v.varietyCode,
															e,
															"boards",
															event.target.value,
															e.id
														);
													}}
													defaultValue={e.boards}
													name={`row-${e}-boards`}></TextField>
											</Col>
											<Col style={{ maxWidth: 60, padding: 4 }}>
												<TextField
													onChange={(event) => {
														setHarvestSaveData(v.varietyCode, e, "blade", event.target.value, e.id);
													}}
													defaultValue={e.blade}
													name={`row-${e}-blade`}></TextField>
											</Col>

											<Col style={{ maxWidth: 600, minWidth: 380, paddingTop: 4 }}>
												<Row style={{ borderBottom: "1px solid gray" }}>
													<Col style={{ width: 100, minWidth: 100, minHeight: 50 }}>
														<TextField
															defaultValue={e.weights && e.weights[0] ? e.weights[0] : null}
															onChange={(event) => {
																let updater = { ...updateRef.current };
																setNestedKey(
																	updater,
																	[v.varietyCode, e.id, "weight", 0],
																	event.target.value
																);
																setNestedKey(
																	updater,
																	[v.varietyCode, e.id, "date"],
																	params.date
																);
																setNestedKey(updater, [v.varietyCode, e.id, "id"], e.id);
																setNestedKey(
																	updater,
																	[v.varietyCode, e.id, "farm"],
																	params.slug
																);
																updateRef.current = updater;
															}}
															name={`row-${e}-weight-0`}></TextField>
														{e.weights && e.weights[0] && (
															<Button
																onClick={() => {
																	console.log({ data: e, name: v.growerName });
																	printLabel({ data: e, name: v.growerName }, 0);
																}}>
																PRINT
															</Button>
														)}
													</Col>
													<Col style={{ width: 100, minWidth: 100, minHeight: 50 }}>
														<TextField
															defaultValue={e.weights && e.weights[1] ? e.weights[1] : null}
															onChange={(event) => {
																let updater = { ...updateRef.current };
																setNestedKey(
																	updater,
																	[v.varietyCode, e.id, "weight", 1],
																	event.target.value
																);
																setNestedKey(updater, [v.varietyCode, e.id, "id"], e.id);
																setNestedKey(
																	updater,
																	[v.varietyCode, e.id, "date"],
																	params.date
																);
																setNestedKey(
																	updater,
																	[v.varietyCode, e.id, "farm"],
																	params.slug
																);
																updateRef.current = updater;
															}}
															name={`row-${e}-weight-1`}></TextField>
														{e.weights && e.weights[1] && (
															<Button
																onClick={() => {
																	console.log({ data: e, name: v.growerName });
																	printLabel({ data: e, name: v.growerName }, 1);
																}}>
																PRINT
															</Button>
														)}
													</Col>
													<Col style={{ width: 100, minWidth: 100, minHeight: 50 }}>
														<TextField
															defaultValue={e.weights && e.weights[2] ? e.weights[2] : null}
															onChange={(event) => {
																let updater = { ...updateRef.current };
																setNestedKey(
																	updater,
																	[v.varietyCode, e.id, "weight", 2],
																	event.target.value
																);
																setNestedKey(updater, [v.varietyCode, e.id, "id"], e.id);
																setNestedKey(
																	updater,
																	[v.varietyCode, e.id, "date"],
																	params.date
																);
																setNestedKey(
																	updater,
																	[v.varietyCode, e.id, "farm"],
																	params.slug
																);
																updateRef.current = updater;
															}}
															name={`row-${e}-weight-2`}></TextField>
														{e.weights && e.weights[2] && (
															<Button
																onClick={() => {
																	console.log({ data: e, name: v.growerName });
																	printLabel({ data: e, name: v.growerName }, 2);
																}}>
																PRINT
															</Button>
														)}
													</Col>
													<Col style={{ width: 100, minWidth: 100, minHeight: 50 }}>
														<TextField
															defaultValue={e.weights && e.weights[3] ? e.weights[3] : null}
															onChange={(event) => {
																let updater = { ...updateRef.current };
																setNestedKey(
																	updater,
																	[v.varietyCode, e.id, "weight", 3],
																	event.target.value
																);
																setNestedKey(updater, [v.varietyCode, e.id, "id"], e.id);
																setNestedKey(
																	updater,
																	[v.varietyCode, e.id, "date"],
																	params.date
																);
																setNestedKey(
																	updater,
																	[v.varietyCode, e.id, "farm"],
																	params.slug
																);
																updateRef.current = updater;
															}}
															name={`row-${e}-weight-3`}></TextField>
														{e.weights && e.weights[3] && (
															<Button
																onClick={() => {
																	console.log({ data: e, name: v.growerName });
																	printLabel({ data: e, name: v.growerName }, 3);
																}}>
																PRINT
															</Button>
														)}
													</Col>
													<Col style={{ width: 100, minWidth: 100, minHeight: 50 }}>
														<TextField
															defaultValue={e.weights && e.weights[4] ? e.weights[4] : null}
															onChange={(event) => {
																let updater = { ...updateRef.current };
																setNestedKey(
																	updater,
																	[v.varietyCode, e.id, "weight", 4],
																	event.target.value
																);
																setNestedKey(updater, [v.varietyCode, e.id, "id"], e.id);
																setNestedKey(
																	updater,
																	[v.varietyCode, e.id, "date"],
																	params.date
																);
																setNestedKey(
																	updater,
																	[v.varietyCode, e.id, "farm"],
																	params.slug
																);
																updateRef.current = updater;
															}}
															name={`row-${e}-weight-4`}></TextField>
														{e.weights && e.weights[4] && (
															<Button
																onClick={() => {
																	console.log({ data: e, name: v.growerName });
																	printLabel({ data: e, name: v.growerName }, 4);
																}}>
																PRINT
															</Button>
														)}
													</Col>
													<Col style={{ width: 100, minWidth: 100, minHeight: 50 }}>
														<TextField
															defaultValue={e.weights && e.weights[5] ? e.weights[5] : null}
															onChange={(event) => {
																let updater = { ...updateRef.current };
																setNestedKey(
																	updater,
																	[v.varietyCode, e.id, "weight", 5],
																	event.target.value
																);
																setNestedKey(updater, [v.varietyCode, e.id, "id"], e.id);
																setNestedKey(
																	updater,
																	[v.varietyCode, e.id, "date"],
																	params.date
																);
																setNestedKey(
																	updater,
																	[v.varietyCode, e.id, "farm"],
																	params.slug
																);
																updateRef.current = updater;
															}}
															name={`row-${e}-weight-5`}></TextField>
														{e.weights && e.weights[5] && (
															<Button
																onClick={() => {
																	console.log({ data: e, name: v.growerName });
																	printLabel({ data: e, name: v.growerName }, 5);
																}}>
																PRINT
															</Button>
														)}
													</Col>
												</Row>

												<Row>
													<Col style={{ width: 100, minWidth: 100, minHeight: 50 }}>
														<TextField
															defaultValue={e.weights && e.weights[6] ? e.weights[6] : null}
															onChange={(event) => {
																let updater = { ...updateRef.current };
																setNestedKey(
																	updater,
																	[v.varietyCode, e.id, "weight", 6],
																	event.target.value
																);
																setNestedKey(updater, [v.varietyCode, e.id, "id"], e.id);
																setNestedKey(
																	updater,
																	[v.varietyCode, e.id, "date"],
																	params.date
																);
																setNestedKey(
																	updater,
																	[v.varietyCode, e.id, "farm"],
																	params.slug
																);
																updateRef.current = updater;
															}}
															name={`row-${e}-weight-6`}></TextField>
														{e.weights && e.weights[6] && (
															<Button
																onClick={() => {
																	console.log({ data: e, name: v.growerName });
																	printLabel({ data: e, name: v.growerName }, 6);
																}}>
																PRINT
															</Button>
														)}
													</Col>
													<Col style={{ width: 100, minWidth: 100, minHeight: 50 }}>
														<TextField
															defaultValue={e.weights && e.weights[7] ? e.weights[7] : null}
															onChange={(event) => {
																let updater = { ...updateRef.current };
																setNestedKey(
																	updater,
																	[v.varietyCode, e.id, "weight", 7],
																	event.target.value
																);
																setNestedKey(updater, [v.varietyCode, e.id, "id"], e.id);
																setNestedKey(
																	updater,
																	[v.varietyCode, e.id, "date"],
																	params.date
																);
																setNestedKey(
																	updater,
																	[v.varietyCode, e.id, "farm"],
																	params.slug
																);
																updateRef.current = updater;
															}}
															name={`row-${e}-weight-7`}></TextField>
														{e.weights && e.weights[7] && (
															<Button
																onClick={() => {
																	console.log({ data: e, name: v.growerName });
																	printLabel({ data: e, name: v.growerName }, 7);
																}}>
																PRINT
															</Button>
														)}
													</Col>
													<Col style={{ width: 100, minWidth: 100, minHeight: 50 }}>
														<TextField
															defaultValue={e.weights && e.weights[8] ? e.weights[8] : null}
															onChange={(event) => {
																let updater = { ...updateRef.current };
																setNestedKey(
																	updater,
																	[v.varietyCode, e.id, "weight", 8],
																	event.target.value
																);
																setNestedKey(updater, [v.varietyCode, e.id, "id"], e.id);
																setNestedKey(
																	updater,
																	[v.varietyCode, e.id, "date"],
																	params.date
																);
																setNestedKey(
																	updater,
																	[v.varietyCode, e.id, "farm"],
																	params.slug
																);
																updateRef.current = updater;
															}}
															name={`row-${e}-weight-8`}></TextField>
														{e.weights && e.weights[8] && (
															<Button
																onClick={() => {
																	console.log({ data: e, name: v.growerName });
																	printLabel({ data: e, name: v.growerName }, 8);
																}}>
																PRINT
															</Button>
														)}
													</Col>
													<Col style={{ width: 100, minWidth: 100, minHeight: 50 }}>
														<TextField
															defaultValue={e.weights && e.weights[9] ? e.weights[9] : null}
															onChange={(event) => {
																let updater = { ...updateRef.current };
																setNestedKey(
																	updater,
																	[v.varietyCode, e.id, "weight", 9],
																	event.target.value
																);
																setNestedKey(updater, [v.varietyCode, e.id, "id"], e.id);
																setNestedKey(
																	updater,
																	[v.varietyCode, e.id, "date"],
																	params.date
																);
																setNestedKey(
																	updater,
																	[v.varietyCode, e.id, "farm"],
																	params.slug
																);
																updateRef.current = updater;
															}}
															name={`row-${e}-weight-9`}></TextField>
														{e.weights && e.weights[9] && (
															<Button
																onClick={() => {
																	console.log({ data: e, name: v.growerName });
																	printLabel({ data: e, name: v.growerName }, 9);
																}}>
																PRINT
															</Button>
														)}
													</Col>
													<Col style={{ width: 100, minWidth: 100, minHeight: 50 }}>
														<TextField
															defaultValue={e.weights && e.weights[10] ? e.weights[10] : null}
															onChange={(event) => {
																let updater = { ...updateRef.current };
																setNestedKey(
																	updater,
																	[v.varietyCode, e.id, "weight", 10],
																	event.target.value
																);
																setNestedKey(updater, [v.varietyCode, e.id, "id"], e.id);
																setNestedKey(
																	updater,
																	[v.varietyCode, e.id, "date"],
																	params.date
																);
																setNestedKey(
																	updater,
																	[v.varietyCode, e.id, "farm"],
																	params.slug
																);
																updateRef.current = updater;
															}}
															name={`row-${e}-weight-10`}></TextField>
														{e.weights && e.weights[10] && (
															<Button
																onClick={() => {
																	console.log({ data: e, name: v.growerName });
																	printLabel({ data: e, name: v.growerName }, 10);
																}}>
																PRINT
															</Button>
														)}
													</Col>
													<Col style={{ minWidth: 60, minHeight: 50 }}>
														<TextField
															defaultValue={e.weights && e.weights[11] ? e.weights[11] : null}
															onChange={(event) => {
																let updater = { ...updateRef.current };
																setNestedKey(
																	updater,
																	[v.varietyCode, e.id, "weight", 11],
																	event.target.value
																);
																setNestedKey(updater, [v.varietyCode, e.id, "id"], e.id);
																setNestedKey(
																	updater,
																	[v.varietyCode, e.id, "date"],
																	params.date
																);
																setNestedKey(
																	updater,
																	[v.varietyCode, e.id, "farm"],
																	params.slug
																);
																updateRef.current = updater;
															}}
															name={`row-${e}-weight-11`}></TextField>
														{e.weights && e.weights[11] && (
															<Button
																onClick={() => {
																	console.log({ data: e, name: v.growerName });
																	printLabel({ data: e, name: v.growerName }, 11);
																}}>
																PRINT
															</Button>
														)}
													</Col>
												</Row>
											</Col>
											<Col style={{ maxWidth: 60, padding: 4 }}>
												{e.weights != null &&
													Object.values(e.weights)
														.map(Number)
														.reduce((acc, num) => acc + num, 0)
														.toFixed(2)}
											</Col>
											<Col style={{ maxWidth: 60, padding: 4 }}>
												{e.weights != null &&
													(
														(Object.values(e.weights)
															.map(Number)
															.reduce((acc, num) => acc + num, 0) *
															16) /
														e.boards
													).toFixed(2)}
											</Col>
											<Col style={{ maxWidth: 60, padding: 4 }}>
												<ListingBtn
													parentClass={"h-100"}
													tooltipText="Delete"
													btnClass="btn btn-cell btn-light btn-sm ml-2"
													iconClass="icon icon-trash"
													onClick={() => deleteRow(e.id)}
												/>
												<Button
													onClick={(e) => {
														saveActual(e.currentTarget);
													}}>
													Save
												</Button>
											</Col>
										</Row>
									);
								})}
							{!clearEntries &&
								[
									...Array(
										v.weightRows - (harvestData[v.varietyCode] ? harvestData[v.varietyCode]?.length : 0) + 1
									).keys(),
								].map((e) => {
									return (
										<Row
											key={`${v.perfName} (${v.growerName} - ${v.varietyCode}) - ${e} - add`}
											style={{ borderBottom: "1px solid gray" }}>
											<Col style={{ maxWidth: 60, padding: 4 }}>
												<Select
													name={`row-${e}-pond`}
													onChange={(event) => {
														setHarvestSaveData(v.varietyCode, e, "pond", event.target.value);
													}}>
													{pondLayout[params.slug].map((v, ix) => {
														return (
															<MenuItem key={v} value={v}>
																{v}
															</MenuItem>
														);
													})}
													<MenuItem key={"undefined"} value={undefined}></MenuItem>
												</Select>
											</Col>
											<Col style={{ maxWidth: 60, padding: 4 }}>
												<Select
													name={`row-${e}-lane`}
													onChange={(event) => {
														setHarvestSaveData(v.varietyCode, e, "lane", event.target.value);
													}}>
													<MenuItem value={1}>1</MenuItem>
													<MenuItem value={2}>2</MenuItem>
													<MenuItem value={3}>3</MenuItem>
													<MenuItem key={"undefined"} value={undefined}></MenuItem>
												</Select>
											</Col>
											<Col style={{ maxWidth: 60, padding: 4 }}>
												<TextField
													onChange={(event) => {
														setHarvestSaveData(v.varietyCode, e, "cycle", event.target.value);
													}}
													name={`row-${e}-cycle`}></TextField>
											</Col>
											<Col style={{ maxWidth: 60, padding: 4 }}>
												<TextField
													onChange={(event) => {
														setHarvestSaveData(v.varietyCode, e, "seeds", event.target.value);
													}}
													name={`row-${e}-seeds`}></TextField>
											</Col>
											<Col style={{ maxWidth: 60, padding: 4 }}>
												<TextField
													onChange={(event) => {
														setHarvestSaveData(v.varietyCode, e, "boards", event.target.value);
													}}
													name={`row-${e}-boards`}></TextField>
											</Col>
											<Col style={{ maxWidth: 60, padding: 4 }}>
												<TextField
													onChange={(event) => {
														setHarvestSaveData(v.varietyCode, e, "blade", event.target.value);
													}}
													name={`row-${e}-blade`}></TextField>
											</Col>
											<Col style={{ maxWidth: 600, paddingTop: 4 }}>
												<Row style={{ borderBottom: "1px solid gray" }}>
													<Col style={{ width: 100, minWidth: 100, minHeight: 50 }}>
														<TextField
															onChange={(event) => {
																let saver = { ...saveRef.current };
																setNestedKey(
																	saver,
																	[v.varietyCode, e, "weight", 0],
																	event.target.value
																);
																setNestedKey(saver, [v.varietyCode, e, "date"], params.date);
																setNestedKey(saver, [v.varietyCode, e, "farm"], params.slug);
																saveRef.current = saver;
															}}
															name={`row-${e}-weight-0`}></TextField>
													</Col>
													<Col style={{ width: 100, minWidth: 100, minHeight: 50 }}>
														<TextField
															onChange={(event) => {
																let saver = { ...saveRef.current };
																setNestedKey(
																	saver,
																	[v.varietyCode, e, "weight", 1],
																	event.target.value
																);
																setNestedKey(saver, [v.varietyCode, e, "date"], params.date);
																setNestedKey(saver, [v.varietyCode, e, "farm"], params.slug);
																saveRef.current = saver;
															}}
															name={`row-${e}-weight-1`}></TextField>
													</Col>
													<Col style={{ width: 100, minWidth: 100, minHeight: 50 }}>
														<TextField
															onChange={(event) => {
																let saver = { ...saveRef.current };
																setNestedKey(
																	saver,
																	[v.varietyCode, e, "weight", 2],
																	event.target.value
																);
																setNestedKey(saver, [v.varietyCode, e, "date"], params.date);
																setNestedKey(saver, [v.varietyCode, e, "farm"], params.slug);
																saveRef.current = saver;
															}}
															name={`row-${e}-weight-2`}></TextField>
													</Col>
													<Col style={{ width: 100, minWidth: 100, minHeight: 50 }}>
														<TextField
															onChange={(event) => {
																let saver = { ...saveRef.current };
																setNestedKey(
																	saver,
																	[v.varietyCode, e, "weight", 3],
																	event.target.value
																);
																setNestedKey(saver, [v.varietyCode, e, "date"], params.date);
																setNestedKey(saver, [v.varietyCode, e, "farm"], params.slug);
																saveRef.current = saver;
															}}
															name={`row-${e}-weight-3`}></TextField>
													</Col>
													<Col style={{ width: 100, minWidth: 100, minHeight: 50 }}>
														<TextField
															onChange={(event) => {
																let saver = { ...saveRef.current };
																setNestedKey(
																	saver,
																	[v.varietyCode, e, "weight", 4],
																	event.target.value
																);
																setNestedKey(saver, [v.varietyCode, e, "date"], params.date);
																setNestedKey(saver, [v.varietyCode, e, "farm"], params.slug);
																saveRef.current = saver;
															}}
															name={`row-${e}-weight-4`}></TextField>
													</Col>
													<Col style={{ width: 100, minWidth: 100, minHeight: 50 }}>
														<TextField
															onChange={(event) => {
																let saver = { ...saveRef.current };
																setNestedKey(
																	saver,
																	[v.varietyCode, e, "weight", 5],
																	event.target.value
																);
																setNestedKey(saver, [v.varietyCode, e, "date"], params.date);
																setNestedKey(saver, [v.varietyCode, e, "farm"], params.slug);
																saveRef.current = saver;
															}}
															name={`row-${e}-weight-5`}></TextField>
													</Col>
												</Row>
												<Row>
													<Col style={{ width: 100, minWidth: 100, minHeight: 50 }}>
														<TextField
															onChange={(event) => {
																let saver = { ...saveRef.current };
																setNestedKey(
																	saver,
																	[v.varietyCode, e, "weight", 6],
																	event.target.value
																);
																setNestedKey(saver, [v.varietyCode, e, "date"], params.date);
																setNestedKey(saver, [v.varietyCode, e, "farm"], params.slug);
																saveRef.current = saver;
															}}
															name={`row-${e}-weight-6`}></TextField>
													</Col>
													<Col style={{ width: 100, minWidth: 100, minHeight: 50 }}>
														<TextField
															onChange={(event) => {
																let saver = { ...saveRef.current };
																setNestedKey(
																	saver,
																	[v.varietyCode, e, "weight", 7],
																	event.target.value
																);
																setNestedKey(saver, [v.varietyCode, e, "date"], params.date);
																setNestedKey(saver, [v.varietyCode, e, "farm"], params.slug);
																saveRef.current = saver;
															}}
															name={`row-${e}-weight-7`}></TextField>
													</Col>
													<Col style={{ width: 100, minWidth: 100, minHeight: 50 }}>
														<TextField
															onChange={(event) => {
																let saver = { ...saveRef.current };
																setNestedKey(
																	saver,
																	[v.varietyCode, e, "weight", 8],
																	event.target.value
																);
																setNestedKey(saver, [v.varietyCode, e, "date"], params.date);
																setNestedKey(saver, [v.varietyCode, e, "farm"], params.slug);
																saveRef.current = saver;
															}}
															name={`row-${e}-weight-8`}></TextField>
													</Col>
													<Col style={{ width: 100, minWidth: 100, minHeight: 50 }}>
														<TextField
															onChange={(event) => {
																let saver = { ...saveRef.current };
																setNestedKey(
																	saver,
																	[v.varietyCode, e, "weight", 9],
																	event.target.value
																);
																setNestedKey(saver, [v.varietyCode, e, "date"], params.date);
																setNestedKey(saver, [v.varietyCode, e, "farm"], params.slug);
																saveRef.current = saver;
															}}
															name={`row-${e}-weight-9`}></TextField>
													</Col>
													<Col style={{ width: 100, minWidth: 100, minHeight: 50 }}>
														<TextField
															onChange={(event) => {
																let saver = { ...saveRef.current };
																setNestedKey(
																	saver,
																	[v.varietyCode, e, "weight", 10],
																	event.target.value
																);
																setNestedKey(saver, [v.varietyCode, e, "date"], params.date);
																setNestedKey(saver, [v.varietyCode, e, "farm"], params.slug);
																saveRef.current = saver;
															}}
															name={`row-${e}-weight-10`}></TextField>
													</Col>
													<Col style={{ width: 100, minWidth: 100, minHeight: 50 }}>
														<TextField
															onChange={(event) => {
																let saver = { ...saveRef.current };
																setNestedKey(
																	saver,
																	[v.varietyCode, e, "weight", 11],
																	event.target.value
																);
																setNestedKey(saver, [v.varietyCode, e, "date"], params.date);
																setNestedKey(saver, [v.varietyCode, e, "farm"], params.slug);
																saveRef.current = saver;
															}}
															name={`row-${e}-weight-11`}></TextField>
													</Col>
												</Row>
											</Col>
											<Col>
												<Button
													onClick={(e) => {
														saveActual(e.currentTarget);
													}}>
													Save
												</Button>
											</Col>
										</Row>
									);
								})}
						</div>
					);
				})}
		</ContentWrap>
	);
};

const mapStateToProps = (state) => {
	const { UI, Auth } = state;
	return {
		accessToken: get(Auth, "accessToken", null),
		mainPreloader: get(UI, "mainPreloader", null),
		permissionDetails: get(Auth, "permissionDetails", null),
	};
};

const mapDispatchToProps = {
	fetchLocationDetails,
	fetchHarvestActualV2,
	updateHarvestActualV2,
	deleteHarvestActualV2,
};
export default connect(mapStateToProps, mapDispatchToProps)(GrowerToolsHarvest);
