import { isEmpty, isDate, padStart, some } from "lodash";
import moment from "moment-timezone";

class Util {
	static mapOptions = (list, label, value, isDisabled) =>
		(!isEmpty(list) &&
			list.map((field) => ({
				label: field[label],
				value: field[value],
				isdisabled: field[isDisabled],
			}))) ||
		[];

	static mapObject = (label, value) => ({
		label,
		value,
	});

	/** Convert Date Object to string
	 * @param date in new Date format
	 * @param separator By default hyphen -
	 * @returns "YYYY-MM-DD"
	 */
	static dateObjToString = (date = null, separator = "-") => {
		const sep = separator ? separator : "-";
		if (isDate(date)) {
			const dateNo = date.getDate();
			const padDate = padStart(dateNo, 2, 0);
			const month = date.getMonth() + 1;
			const padMonth = padStart(month, 2, 0);
			const year = date.getFullYear();
			return `${year}${sep}${padMonth}${sep}${padDate}`;
		}
		return null;
	};

	/**
	 * UTC to Local - Moment
	 * @returns MM/DD/YYYY LT
	 * Eg: 09/15/2020 02:00 PM
	 */
	static utcToLocal = (date = null) => {
		if (date) {
			const convertedValue = moment.utc(date).local().format("MM/DD/YYYY LT");
			return convertedValue;
		} else {
			return null;
		}
	};

	/**
	 * Only Formats Date
	 * @returns MM/DD/YYYY LT
	 * Eg: 09/15/2020
	 */
	static formatMDY = (date = null) => {
		return date ? moment(date).format("MM/DD/YYYY") : "";
	};

	static DateFormat = (date = null) => {
		return date ? moment(date).format("YYYY-MM-DD HH:mm") : "";
	};

	static getYMDFormat = (date = null) => {
		return date ? moment(date).format("YYYY-MM-DD") : "";
	};
	static getDateFormat = (date = null) => {
		return moment(date) ? moment(date).format("MM/DD/YYYY") : "";
	};
	static getMDYFormat = (date = null) => {
		const utcTime = moment.utc(date).toDate();
		const localTime = moment(utcTime).local().format("MM/DD/YYYY");
		return date ? localTime : "";
	};
	static getMDYLTFormat = (date = null) => {
		const utcTime = moment.utc(date).toDate();
		const localTime = moment(utcTime).local().format("MM/DD/YYYY  LT");
		return date ? localTime : "";
	};
	static getMDYFormatUtcDate = (date = null) => {
		const utcTime = moment.utc(date).toDate();
		const localTime = moment(utcTime).local().format("YYYY-MM-DD ");
		return date ? localTime : "";
	};
	static getTimeFormat = (date = null) => {
		const utcTime = moment.utc(date).toDate();
		const localTime = moment(utcTime).local().format("HH:mm");
		return date ? localTime : "";
	};
	static getDateObjectFormat = (date = null) => {
		return date ? moment(date).toDate() : "";
	};

	// Seeding & Germination
	static checkDateIsPast = (date) => {
		const germinationEnd = moment.utc(date).seconds(0).local().toDate();
		const now = moment().seconds(0);
		return germinationEnd < now;
	};

	static formatPermissionDataArray = (permissionData) => {
		const permissionArray = [];
		permissionData.forEach((item, i) => {
			const data = Util.getSinglePermissionArray(item.name);
			if (data) {
				return (permissionArray[data] = { full_permission: some(item.permissions), permissions: item.permissions });
			}
		});
		return permissionArray;
	};

	static getDates = (startDate, stopDate) => {
		const dateArray = [];
		let curDate = moment(startDate);
		const stDate = moment(stopDate);
		while (curDate <= stDate) {
			dateArray.push(moment(curDate).format("YYYY-MM-DD"));
			curDate = moment(curDate).add(1, "days");
		}
		return dateArray;
	};

	static getLastDay = (date, type) => {
		return moment(`${date}`).endOf(type).format("YYYY-MM-DD");
	};
	static getFirstDay = (date, type) => {
		return moment(`${date}`).startOf(type).format("YYYY-MM-DD");
	};
	static getLocalTimeZone = () => {
		return moment.tz.guess();
	};

	static getNextDate = (date, days) => {
		return moment(date) ? Util.getDateObjectFormat(moment(date).add("days", days).format("YYYY-MM-DD")) : "";
	};

	static getSinglePermissionArray = (item) => {
		switch (item) {
			case "User Management":
				return "user";
			case "Product Management":
				return "product";
			case "Task Management":
				return "task";
			case "Stock Keeping Unit":
				return "stock";
			case "Customer Management":
				return "customer";
			case "Seeding":
				return "seeding";
			case "Germination":
				return "germination";
			case "Harvesting":
				return "harvesting";
			case "Seeds":
				return "seeds";
			case "Product Variety":
				return "product_variety";
			case "Access Privilages":
				return "access";
			case "Locations":
				return "locations";
			case "Greens":
				return "greens";
			case "Styrofoams":
				return "styrofoams";
			case "Transplant & Growing":
				return "transplant";
			case "Orders":
				return "orders";
			case "Climate Reports":
				return "climate-reports";
			case "Climate Data Table":
				return "climate-table";
			case "Orders & Packaging":
				return "orders_and_packaging";
			case "Production Forecast":
				return "production_forecast";
			case "Dashboard & Reports":
				return "reports";
			case "Weekly Forecast":
				return "weekly_forecast";
			case "Daily Forecast":
				return "daily_forecast";
			case "Harvest Forecast Pounds":
				return "harvest_forecast_pounds";
			case "Production Forecast Cases":
				return "production_forecast_cases";
			case "Production Vs Sales By SKU":
				return "production_vs_sales_sku";
			case "Production Vs Sales By Product Variety":
				return "production_vs_sales_variety";
			case "Croptimals":
				return "croptimals";
			case "Water Source Results":
				return "water_source_results";
			case "Fertilisers":
				return "fertilizers";
			case "Dosing Calculator":
				return "dosing_calculator";
			case "To Add List":
				return "to_add_list";
			case "Lab Results":
				return "lab_results";
			case "KPI Target":
				return "kpi";
			case "Cross Supply":
				return "cross_supply";
			case "Lane Calculator":
				return "lane_calculator";
			case "Watchwire":
				return "watchwire";
			default:
				return "";
		}
	};

	static checkValidDateString = (date = null, format) => {
		return moment(date, "YYYY-MM-DD", true).isValid() ? moment(date).format("MM/DD/YYYY") : "";
	};
	static checkValidDateTimeString = (date = null, format) => {
		return moment(date, "YYYY-MM-DD", true).isValid()
			? moment(date).format("MM/DD/YYYY")
			: moment(date, "YYYY-MM-DD HH:mm:ss", true).isValid()
			? moment(date).format("MM/DD/YYYY HH:mm:ss")
			: "";
	};
	static getMDYFormatUtcDateAndTime = (date = null) => {
		return date ? moment(date).format("YYYY-MM-DD HH:mm:ss") : "";
	};

	static getFormattedDateAndTime = (date = null, format) => {
		return date ? moment(date).format("MM/DD/YYYY LT") : "";
	};

	static getTime =(time)=>{
		return moment(time, "hh.mm").format('LT');
		// let dt = moment();
		// dt.format("HH:mm") // 24 hour time
		// dt.format("hh:mm a") // 12 hour time (with am/pm)
	}
	
	static addDateToPackageStartDate = (date, days) => {
		if(date && days){
			let no_of_days = 0;
			if (days.value === "1") {
				no_of_days = 12;
			} else if (days.value === "2") {
				no_of_days = 15;
			} else if (days.value === "3") {
				no_of_days = 16;
			}
			const new_date = moment(date, "DD-MM-YYYY").add(no_of_days, "days");
			return new_date["_d"];
		}
		else {return null;}
		
	};

	static getTimeDifference = (d) => {
		d = Number(d);
		const h = Math.floor(d / 3600);
		const m = Math.floor((d % 3600) / 60);
		const s = Math.floor((d % 3600) % 60);

		const hDisplay =
			h > 0
				? h === 1 && m === 0
					? h + "hr"
					: h === 1 && m > 0
					? h + "hr, "
					: h > 1 && m === 0
					? h + "hrs"
					: h + "hrs, "
				: "";

		const mDisplay =
			m > 0
				? m === 1 && s === 0
					? m + "min"
					: m === 1 && s > 0
					? m + "min, "
					: m > 1 && s === 0
					? m + "mins"
					: m + "mins, "
				: "";
		const sDisplay = s > 0 ? s + (s === 1 ? " sec" : " secs") : "";
		return hDisplay + mDisplay + sDisplay;
	};
	// moment().format("dddd, MMMM Do YYYY, h:mm:ss a");
}

export default Util;
