import React from "react";
import { connect } from "react-redux";
import { find } from "lodash";
import Listing from "../../../partials/listings";
import useModal from "../../../utils/useModal";
import { successMessage } from "../../../utils/successMessage";
import { errorMessage } from "../../../utils/errorMessage";
import ConfirmModal from "../../../utils/confirmModal";
import { renderColumns } from "./helper";
import { getSeedingGerminationTasks, deleteSeedingGerminationTask, toggleListLoader } from "../../../store/actions";
import TableLoader from "../../../utils/table/tableLoader";
import Checkbox from "utils/checkbox";
import { useTranslation } from "react-i18next";

const SeedingGerminationListing = ({
	filter,
	setFilter,
	refetch,
	setRefetch,
	assigneeList,
	showAllCheckbox,
	onChangeCheckbox,
	isLocationSelected,
	isAllCheckboxSelected,
	onChangeAllCheckbox,
	handleEditPanel,
	handleViewPanel,
	listingPageLoader,
	getSeedingGerminationTasks,
	deleteSeedingGerminationTask,
	permissionDetails,
	exportFlag,
	exportHandlerFlag
}) => {
	const { t } = useTranslation();
	const [isDeletModalOpen, , showDeleteModal, closeDeleteModal, deleteModalProps] = useModal("deleteBoard");

	const handleDeletePanel = (data) => showDeleteModal(0, data);

	const onDeleteTask = () => {
		toggleListLoader(true);
		const successHandler = (e) => {
			closeDeleteModal();
			successMessage(e);
			toggleListLoader(false);
			setRefetch(refetch + 1);
		};
		const errorHandler = (e) => {
			closeDeleteModal();
			errorMessage(e);
			toggleListLoader(false);
		};
		deleteSeedingGerminationTask(deleteModalProps.slug, successHandler, errorHandler);
	};

	const listPermission =
		permissionDetails && permissionDetails.seeding && permissionDetails.seeding.permissions.list_seeding_task
			? permissionDetails.seeding.permissions.list_seeding_task
			: "";
	// const updatePermission =
	// 	permissionDetails && permissionDetails.seeding && permissionDetails.seeding.permissions.update_seeding_task
	// 		? permissionDetails.seeding.permissions.update_seeding_task
	// 		: "";

	// Filter
	const addToFilter = (e) => {
		let obj = [];
		e.forEach((item) => {
			return (obj =
				item && item.column
					? [
							...obj,
							{
								column_name: item.column.accessor,
								search: item.value,
							},
					  ]
					: obj);
		});
		const locationFilter = find(filter, ["column_name", "location_slug"]);
		locationFilter ? setFilter([...obj, locationFilter]) : setFilter(obj);
	};

	const columns = renderColumns({
		handleEditPanel,
		handleViewPanel,
		handleDeletePanel,
		addToFilter,
		isLocationSelected,
		onChangeCheckbox,
		checkedAssignees: assigneeList,
		permissionDetails,
	});

	return (
		<div className="position-relative">
			{showAllCheckbox && (
				<Checkbox wrapperClass="listing-checkbox top-seeding-checkbox"  checked={isAllCheckboxSelected} onChange={onChangeAllCheckbox} />
			)}
			{listPermission ? (
				<Listing
					fetchAction={getSeedingGerminationTasks}
					columns={columns}
					refetch={refetch}
					filter={filter}
					exportFlag={exportFlag}
					sort_name="task_id"
					exportHandlerFlag={()=>{exportHandlerFlag()}}
				/>
			) : (
				""
			)}
			<ConfirmModal
				show={isDeletModalOpen}
				onNo={closeDeleteModal}
				onYes={onDeleteTask}
				message={t("seeding.confirm.delete_task")}
			/>

			{/* Loader */}
			{listingPageLoader && (
				<div className="table-loader-wrapper">
					<TableLoader />
				</div>
			)}
		</div>
	);
};

const mapStateToProps = (state) => ({
	permissionDetails: state.Auth.permissionDetails,
});
const mapDispatchToProps = {
	getSeedingGerminationTasks,
	deleteSeedingGerminationTask,
};

export default connect(mapStateToProps, mapDispatchToProps)(SeedingGerminationListing);
