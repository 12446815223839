import React, { useState } from "react";

import { connect } from "react-redux";
import { Modal, Button, Container, Row, Col, Form } from "react-bootstrap";
import { reduxForm, Field, reset } from "redux-form";
import FormGroups from "../../../utils/formGroup";
import { errorMessage } from "../../../utils/errorMessage";
import { successMessage } from "../../../utils/successMessage";
import { required, USPhoneNumber, email, names, normalizePhone } from "../../../constant/formValidators";
import { createUser, getUserRole } from "../../../store/actions";
import { toggleMainLoader } from "../../../store/actions";
import useModal from "../../../utils/useModal";
import TableLoader from "utils/table/tableLoader";

export const UserDetailsForm = ({
	initialValues,
	handleSubmit,
	toggleMainLoader,
	createUser,
	rolesList,
	editingUserDetails,
	handleEditUser,
	onAddOrEdit,
	form,
	reset,
	locationList,
	updatePermission,
}) => {
	const [isOpen, modalType, , closeModal] = useModal("users");

	const [isLoading, setisLoading] = useState(false);
	const [btnTitle, setbtnTitle] = useState("Save");
	const [isActiveToggler, setIsActiveToggler] = useState(true);
	const classPrefix = "bright-farms";

	const getUpdatedBody = (body, img) => {
		if (img === undefined) {
			return body;
		} else {
			return {
				...body,
				photo: img && img[0] ? img[0]["base64"] : null,
			};
		}
	};
	const mapOptions = (list, label, value) => {
		const newList = list.map((field) => ({
			label: field[label],
			value: field[value].toString(),
		}));
		return newList;
	};
	const updateUserStatus = (e, id) => {
		const successHandler = (event) => {
			successMessage(event);
			onAddOrEdit && onAddOrEdit(event);
		};
		const errorHandler = (event) => errorMessage(event);
		createUser({ status: e }, id, successHandler, errorHandler);
	};

	const getLocationsText = () => {
		let locationName = "";
		initialValues.location.map((singleLocation) => {
			return (locationName += singleLocation.label + ", ");
		});
		return locationName.replace(/,(\s+)?$/, "");
	};

	const getOptions = (field) => {
		switch (field) {
			case "role":
				return mapOptions(rolesList, "name", "id");
			case "location":
				return mapOptions(locationList, "location_name", "slug");
			default:
				return [];
		}
	};

	const submitUserDetails = (values) => {
		if (!isLoading) {
			setisLoading(true);
			setbtnTitle("Saving...");
			const locations = values.location
				? values.location.map(function (value, index) {
						return value.value;
				  })
				: [];

			toggleMainLoader(true);
			const successHandler = (event) => {
				setbtnTitle("Save");
				onAddOrEdit && onAddOrEdit(event);
				setisLoading(false);
				successMessage(event);
				reset(form);
				closeModal();
			};
			const errorHandler = (event) => {
				setbtnTitle("Save");
				setisLoading(false);
				errorMessage(event);
			};
			const method = modalType ? editingUserDetails.id : null;
			const submissionData = modalType
				? {
						first_name: values.first_name,
						last_name: values.last_name,
						phone: values.phone ? values.phone : "",
						email: values.email ? values.email : "",
						status: values.status ? values.status : false,
						id: editingUserDetails.id,
						role: values.role.value ? values.role.value : null,
						location: locations,
				  }
				: {
						...values,
						role: values.role.value ? values.role.value : null,
						location: locations,
				  };
			const bodyEdit = getUpdatedBody(submissionData, values.photo);
			createUser(bodyEdit, method, successHandler, errorHandler);
		}
	};

	const labelledValue = (label, value, isLarge = false) => (
		<Col className={isLarge ? "col-md-7 col-12 mb-3" : "col-md-5 col-12 mb-3"}>
			<div>
				<p className="d-flex align-items-center view-labels mb-1">
					<span className="light-badge-pill"></span>
					{label}
				</p>

				<h6 className="ml-4 view-values break-word">{value ? value : "-"}</h6>
			</div>
		</Col>
	);

	const onToggleStatus = (val) => setIsActiveToggler(val);

	const renderManage = () => (
		<Form onSubmit={handleSubmit(submitUserDetails)} className="user-details-form">
			<Container className="position-relative">
				<Row>
					<Col xs={6} md={6}>
						<Form.Group controlId="userFirstName">
							<Field
								name="first_name"
								type="text"
								label="First Name *"
								placeholder="Enter First Name"
								component={FormGroups}
								validate={[required, names]}
							/>
						</Form.Group>
					</Col>
					<Col xs={6} md={6}>
						<Form.Group controlId="userLastName">
							<Field
								name="last_name"
								type="text"
								label="Last Name *"
								placeholder="Enter Last Name"
								component={FormGroups}
								validate={[required, names]}
							/>
						</Form.Group>
					</Col>
				</Row>
				<Row>
					<Col xs={6} md={6}>
						<Form.Group controlId="userEmail">
							<Field
								name="email"
								type="text"
								label="Email *"
								placeholder="Enter Email"
								component={FormGroups}
								validate={[required, email]}
							/>
						</Form.Group>
					</Col>
					<Col xs={6} md={6}>
						<Form.Group controlId="userPhone">
							<Field
								label="Phone Number"
								type="text"
								name="phone"
								placeholder="Enter Phone Number"
								component={FormGroups}
								validate={[USPhoneNumber]}
								normalize={normalizePhone}
							/>
						</Form.Group>
					</Col>
				</Row>
				<Row>
					<Col xs={6} md={6}>
						<Form.Group controlId="userLocation">
							<Field
								label="Location *"
								name="location"
								type="select-react"
								multiple={true}
								validate={[required]}
								component={FormGroups}
								options={getOptions("location")}></Field>
						</Form.Group>
					</Col>
					<Col xs={6} md={6}>
						<Form.Group controlId="userRole">
							<Field
								label="Role *"
								name="role"
								type="select-react"
								validate={[required]}
								component={FormGroups}
								options={getOptions("role")}></Field>
						</Form.Group>
					</Col>
				</Row>
				<Row>
					<Col className="pt-2 pb-1" xs={6} md={3}>
						<div className="form-label">Status</div>
						<div className="d-flex">
							<Field
								label=""
								name="status"
								type="boolean"
								component={FormGroups}
								onChange={onToggleStatus}></Field>
							<span className="toggler-status-text">{isActiveToggler ? "Active" : "Inactive"}</span>
						</div>
					</Col>
				</Row>
				<Row>
					<Col xs={12} md={12} className="d-flex justify-content-center text-center">
						<Field
							name="photo"
							type="image-upload"
							className="profile-round-upload"
							defaultValue={editingUserDetails && editingUserDetails.avatar ? editingUserDetails.avatar : ""}
							placeholder="Drop Profile Image here"
							component={FormGroups}></Field>
					</Col>
				</Row>
				{isLoading && (
					<div className="table-loader-wrapper">
						<TableLoader />
					</div>
				)}
			</Container>
			<Modal.Footer className="save-footer">
				<Button variant="secondary" onClick={closeModal}>
					Cancel
				</Button>
				<Button variant="primary" type="submit" disabled={isLoading}>
					{btnTitle}
				</Button>
			</Modal.Footer>
		</Form>
	);

	const renderView = () => (
		<>
			<Container className="pt-2">
				<Row>
					<Col className="d-flex align-items-center">
						{editingUserDetails && editingUserDetails.avatar ? (
							<img className="thumbnail-image" src={editingUserDetails.avatar} alt="user pic" />
						) : (
							<span className="vector-image">
								<i className="icon-user" />
							</span>
						)}
						<Col className="ml-3">
							<Row className="user-id-class">
								User ID :
								<label className="mb-0">
									{editingUserDetails && editingUserDetails.id ? ` ${editingUserDetails.uid}` : ""}
								</label>
							</Row>
							<Row className="user-name-class">
								{editingUserDetails && editingUserDetails.first_name ? editingUserDetails.first_name : ""}{" "}
								{editingUserDetails && editingUserDetails.last_name ? editingUserDetails.last_name : ""}
							</Row>
						</Col>
					</Col>

					{updatePermission ? (
						<Col xs="2">
							<div className="d-flex justify-content-end">
								<span className="edit-icon-background">
									<i onClick={() => handleEditUser(`${editingUserDetails.id}`)} className="icon-edit" />
								</span>
							</div>
							<div className="d-flex justify-content-end">
								<Field
									label=""
									name="status"
									type="boolean"
									component={FormGroups}
									onChange={(e) => updateUserStatus(e, editingUserDetails.id)}></Field>
							</div>
						</Col>
					) : null}
				</Row>
				{isLoading && (
					<div className="table-loader-wrapper">
						<TableLoader />
					</div>
				)}
			</Container>
			<Container>
				<hr className="mt-4 mb-4" />
			</Container>
			<Container>
				<Row>
					{labelledValue(
						"Email",
						editingUserDetails && editingUserDetails.email ? editingUserDetails.email : "",
						true
					)}
					{labelledValue(
						"Phone Number",
						editingUserDetails && editingUserDetails.phone ? editingUserDetails.phone : ""
					)}
					{labelledValue("Location", initialValues && initialValues.location ? getLocationsText() : "", true)}
					{labelledValue(
						"Role",
						editingUserDetails && editingUserDetails.role_name ? editingUserDetails.role_name : ""
					)}
				</Row>
			</Container>
		</>
	);

	return (
		<Modal size="lg" show={isOpen} onHide={closeModal} className="modal-sidepanel">
			<Modal.Header closeButton>
				<Modal.Title className="modal-title">
					{modalType === 1 ? "Edit User" : modalType === 2 ? "User Details" : "Add User"}
				</Modal.Title>
				{modalType === 2 && (
					<button type="button" className={`${classPrefix}-modal-close-button`} onClick={closeModal}>
						<i className="icon icon-close" />
					</button>
				)}
			</Modal.Header>
			<Modal.Body>{modalType === 1 || modalType === 0 ? renderManage() : renderView()}</Modal.Body>
		</Modal>
	);
};

const mapStateToProps = (state) => ({
	rolesList: state.User.userRoles,
	userDetails: state.User.userDetails,
	locationList: state.User.userLocations,
});
const mapDispatchToProps = {
	createUser,
	toggleMainLoader,
	getUserRole,
	reset,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(
	reduxForm({
		form: "user-details-form",
		enableReinitialize: true,
	})(UserDetailsForm)
);
