export const errorCodes = [
	// custom messages
	{
		code: "SESSION_EXPIRED",
		reason: "SESSION_EXPIRED",
		message: "Your Session will expire in 1 minute",
	},
	{
		code: "PASSWORD_NOT_MATCH",
		reason: "Password not match",
		message: "Your passwords doesn't match.",
	},
	{
		code: "GREENS_DELETE_DISABLE",
		reason: "GREENS_DELETE_DISABLE",
		message: "Cannot delete as the selected item is already linked",
	},
	{
		code: "SEEDS_DELETE_DISABLE",
		reason: "SEEDS_DELETE_DISABLE",
		message: "Cannot delete as the selected item is already linked",
	},
	{
		code: "SG_NOT_ALLOWED_TO_EDIT",
		reason: "SG_NOT_ALLOWED_TO_EDIT",
		message: "error.cannot_edit_completed_task",
	},
	{
		code: "HARVEST_NOT_ALLOWED_TO_EDIT",
		reason: "SG_NOT_ALLOWED_TO_EDIT",
		message: "error.cannot_edit_completed_task",
	},
	{
		code: "TRANSPLANT_DELETE_DISABLE",
		reason: "TRANSPLANT_DELETE_DISABLE",
		message: "error.cannot_edit_completed_task",
	},
	{
		code: "SKU_NOT_MAPPED",
		reason: "SKU_NOT_MAPPED",
		message: "SKUs not mapped for this location",
	},
	{
		code: "CUSTOMERS_NOT_MAPPED",
		reason: "CUSTOMERS_NOT_MAPPED",
		message: "Customers not mapped for this location",
	},
	{
		code: "REQUIRED_ONE_FIELD",
		reason: "Atleast one field required",
		message: "Atleast one field required",
	},
	{ code: "NO_LOCATION", reason: "NO_LOCATION", message: "Please choose location" },
	{ code: "NO_ASSIGNEE_SELECTED", reason: "NO_ASSIGNEE_SELECTED", message: "error.please_assign_the_task_to_proceed" },
	{
		code: "GERMINATION_END_DATE_CURRENT_DATE",
		reason: "GERMINATION_END_DATE_CURRENT_DATE",
		message: "Actual Germination Start Date and Time should not exceed Germination End Date and Time.",
	},
	{
		code: "RECIPE_SUM_MAX_100",
		reason: "RECIPE_SUM_MAX_100",
		message: "error.total_percentage_should_be_100",
	},
	{
		code: "INSUFFICIENT_PERMISSION",
		reason: "InsufficientPermission",
		message: "Insufficient permission!",
	},

	{
		code: 1,
		reason: "DefaultException",
		message: "Default exception!",
	},
	{
		code: 2,
		reason: "InsufficientPermission",
		message: "Insufficient permission!",
	},
	{
		code: 3,
		reason: "LoginFailed",
		message: "Login failed!",
	},
	{
		code: 4,
		reason: "InvalidToken",
		message: "Link not valid anymore. Request another one!",
	},
	{
		code: 5,
		reason: "UserLockedOut",
		message: "User Locked out! Please try after two minutes.",
	},
	{
		code: 6,
		reason: "InvalidPassword",
		message: "Invalid Password",
	},
	{
		code: 7,
		reason: "InvalidEmailAddress",
		message: "Invalid Email Address",
	},
	{
		code: 101,
		reason: "Failed to create user",
		message: "Failed to create user",
	},
	{
		code: 103,
		reason: "Failed to update user",
		message: "Failed to update user",
	},
	{
		code: 106,
		reason: "Failed to fetch user details",
		message: "Failed to fetch user details",
	},

	{
		code: 108,
		reason: "Failed to delete user",
		message: "Failed to delete user",
	},

	{
		code: 110,
		reason: "Invalid username or password",
		message: "Invalid username or password",
	},

	{
		code: 113,
		reason: "Failed to update user profile",
		message: "Failed to update user profile",
	},

	{
		code: 115,
		reason: "Failed to fetch profile details",
		message: "Failed to fetch profile details",
	},
	{
		code: 116,
		reason: "Input validation failed",
		// message: "Input validation failed",
		message: "error.date_must_be_after_equal",
	},
	{
		code: 117,
		reason: "This user does not exist",
		message: "This user does not exist",
	},
	{
		code: 118,
		reason: "Role does not exist",
		message: "Role does not exist",
	},
	{
		code: 119,
		reason: "User does not have a profile",
		message: "User does not have a profile",
	},
	{
		code: 120,
		reason: "Invalid token",
		message: "Invalid token",
	},
	{
		code: 121,
		reason: "This email id is invalid",
		message: "This email id is invalid",
	},
	{
		code: 122,
		reason: "This token has been expired",
		message: "This token has been expired",
	},
	{
		code: 125,
		reason: "This email has not been verified yet",
		message: "This email has not been verified yet",
	},
	{
		code: 130,
		reason: "Location do not exist",
		message: "Location do not exist",
	},
	{
		code: 131,
		reason: "Unauthenticated",
		message: "Unauthenticated. Please sign in again.",
	},
	{
		code: 132,
		reason: "You do not have the required authorization",
		message: "You do not have the required authorization",
	},
	{
		code: 133,
		reason: "Your email id is not verified",
		message: "Your email id is not verified",
	},
	{
		code: 134,
		reason: "Your account is not active",
		message: "Your account is not active",
	},
	{
		code: 138,
		reason: "Stock keeping unit does not exist",
		message: "Stock keeping unit does not exist",
	},
	{
		code: 142,
		reason: "Customer do not exist",
		message: "Customer do not exist",
	},
	{
		code: 146,
		reason: "Invalid Growing and Transplant task",
		message: "Invalid Growing and Transplant task",
	},
	{
		code: 148,
		reason: "The task has already been started",
		message: "The task has already been started",
	},
	{
		code: 149,
		reason: "The task has not yet started",
		message: "The task has not yet started",
	},

	{
		code: 150,
		reason: "Image is invalid",
		message: "Image is invalid",
	},
	{
		code: 152,
		reason: "Board doesnot exist",
		message: "Board doesnot exist",
	},
	{
		code: 158,
		reason: "Seed doesnot exist",
		message: "Seed doesnot exist",
	},
	{
		code: 161,
		reason: "Season details missing",
		message: "Season details missing",
	},
	{
		code: 163,
		reason: "Green doesnot exist",
		message: "Green doesnot exist",
	},
	{
		code: 167,
		reason: "Seed details missing",
		message: "Seed details missing",
	},
	{
		code: 168,
		reason: "Product variety doesnot exist",
		message: "Product variety doesnot exist",
	},
	{
		code: 174,
		reason: "Seed mapped to green and product variety. Please unlink and try again",
		message: "Seed mapped to green and product variety. Please unlink and try again",
	},
	{
		code: 175,
		reason: "Seed mapped to product variety. Please unlink and try again",
		message: "Seed mapped to product variety. Please unlink and try again",
	},
	{
		code: 176,
		reason: "Seed mapped to green. Please unlink and try again",
		message: "Seed mapped to green. Please unlink and try again",
	},
	{
		code: 177,
		reason: "Green mapped to product variety",
		message: "Green mapped to product variety",
	},
	{
		code: 180,
		reason: "Seeding task not found",
		message: "Seeding task not found",
	},
	{
		code: 182,
		reason: "Task already started",
		message: "Task already started",
	},
	{
		code: 183,
		reason: "Task not started yet",
		message: "Task not started yet",
	},
	{
		code: 186,
		reason: "You do not have required permission",
		message: "You do not have required permission",
	},
	{
		code: 188,
		reason: "Task already completed",
		message: "Task already completed",
	},
	{
		code: 190,
		reason: "Seeding not completed",
		message: "Seeding not completed",
	},
	{
		code: 191,
		reason: "Location mapped to some modules",
		message: "Location mapped to some modules",
	},
	{
		code: 192,
		reason: "Some works are still pending",
		message: "Some works are still pending",
	},
	{
		code: 193,
		reason: "Task doesnot exist",
		message: "Task doesnot exist",
	},
	{
		code: 195,
		reason: "Actual germination date greater than task complete date",
		message: "Actual germination date greater than task complete date",
	},
	{
		code: 203,
		reason: "Transplant details already exist",
		message: "Transplant details already exist",
	},
	{
		code: 204,
		reason: "Invalid pond number",
		message: "Invalid pond number",
	},
	{
		code: 206,
		reason: "Task already completed",
		message: "Task already completed",
	},
	{
		code: 207,
		reason: "Invalid task",
		message: "Invalid task",
	},
	{
		code: 208,
		reason: "Transplant details not added yet",
		message: "Transplant details not added yet",
	},
	{
		code: 212,
		reason: "Invalid order",
		message: "Invalid order",
	},
	{
		code: 213,
		reason: "Invalid packaging task",
		message: "Invalid packaging task",
	},
	{
		code: 216,
		reason: "Packaging already started",
		message: "Packaging already started",
	},
	{
		code: 217,
		reason: "Packaging already completed",
		message: "Packaging already completed",
	},
	{
		code: 218,
		reason: "Packaging not started yet",
		message: "Packaging not started yet",
	},
	{
		code: 232,
		reason: "Past delivery date",
		message: "Order Delivery Date is a past date. Kindly update the same to proceed with packaging",
	},
	{
		code: 251,
		reason: "Failed to create Harvesting task, please try again",
		message: "Failed to create Harvesting task, please try again",
	},
	{
		code: 253,
		reason: "Failed to update Harvesting task, please try again",
		message: "Failed to update Harvesting task, please try again",
	},
	{
		code: 254,
		reason: "Harvesting task not found",
		message: "Harvesting task not found",
	},
	{
		code: 256,
		reason: "Task not started yet",
		message: "Task not started yet",
	},
	{
		code: 258,
		reason: "Harvesting task already started",
		message: "Harvesting task already started",
	},
	{
		code: 259,
		reason: "Harvesting task already completed",
		message: "Harvesting task already completed",
	},
	{
		code: 261,
		reason: "Failed to update harvesting task, please try again",
		message: "Failed to update harvesting task, please try again",
	},
	{
		code: 263,
		reason: "User does not belong to the task location",
		message: "User does not belong to the task location",
	},
	{
		code: 264,
		reason: "Invalid Harvesting task",
		message: "Invalid Harvesting task",
	},
	{
		code: 212,
		reason: "Invalid order",
		message: "Invalid order",
	},
	{
		code: 213,
		reason: "Invalid packaging task",
		message: "Invalid packaging task",
	},
	{
		code: 216,
		reason: "Packaging already started",
		message: "Packaging already started",
	},
	{
		code: 217,
		reason: "Packaging already completed",
		message: "Packaging already completed",
	},
	{
		code: 218,
		reason: "Packaging not started yet",
		message: "Packaging not started yet",
	},
	{
		code: 230,
		reason: "Assign the task to a user to proceed",
		message: "error.assign_the_task_to_a_user_to_proceed",
	},
	{
		code: 231,
		reason: "Started/Completed task cannot be edited",
		message: "error.started_completed_task_cannot_be_edited",
	},
	{
		code: 232,
		reason: "Order Delivery Date is a past date. Kindly update the same to proceed with packaging",
		message: "Order Delivery Date is a past date. Kindly update the same to proceed with packaging",
	},
	{
		code: 277,
		reason: "Product variety is in use",
		message: "Product variety is in use",
	},
	{
		code: 278,
		reason: "Product variety is Inactive, Cannot proceed",
		message: "Product variety is Inactive, Cannot proceed",
	},
	{
		code: 279,
		reason: "Stock keeping unit is Inactive, Cannot proceed",
		message: "Stock keeping unit is Inactive, Cannot proceed",
	},
	{
		code: 280,
		reason: "Stock keeping unit is used by customers",
		message: "Stock keeping unit is used by customers",
	},
	{
		code: 281,
		reason: "Cannot remove location, since SKU is in use by customers",
		message: "Cannot remove location, since SKU is in use by customers",
	},
	{
		code: 233,
		reason: "Forecast data is empty",
		message: "Forecast data is empty",
	},
	{
		code: 234,
		reason: "Failed to process forecast file, missing entities",
		message: "Failed to process forecast file, missing entities",
	},
	{
		code: 274,
		reason: "Forecast Creation Failed",
		message: "Forecast Creation Failed",
	},
	{
		code: 282,
		reason: "Forecast data already exists for some dates",
		message: "Forecast data already exists for some dates",
	},
	{
		code: 283,
		reason: "Product Variety is used by some Stock Keeping Units",
		message: "Product Variety is used by some Stock Keeping Units",
	},
	{
		code: 284,
		reason: "Croptimals already added for the applied filter",
		message: "Croptimals already added for the applied filter",
	},
	{
		code: 287,
		reason: "Added fertilizer already exists",
		message: "Added fertilizer already exists",
	},
	{
		code: 288,
		reason: "Lab Results already exists",
		message: "Lab Results already exists",
	},
	{
		code: 289,
		reason: "Lab Results already exists",
		message: "Lab Results already exists",
	},
	{
		code: 291,
		reason: "Water source results already exists",
		message: "Water source results already exists",
	},
	{
		code: 293,
		reason: "Location does not exist",
		message: "Location does not exist",
	},
	{
		code: 299,
		reason: "Location does not exists",
		message: "Location does not exists",
	},
	{
		code: 300,
		reason: "Location does not exists",
		message: "Location does not exists",
	},
	{
		code: 302,
		reason: "Please upload file in correct format",
		message: "Please upload file in correct format",
	},
	{
		code: 303,
		reason: "Uploaded file does not contain any water source results",
		message: "Uploaded file does not contain any water source results",
	},
	{
		code: 319,
		reason: "Updation failed",
		message: "Updation failed",
	},
	{
		code: 322,
		reason: "Only able to edit current date and future date values",
		// message: "Only able to edit current date and future date values",
		message: "error.edit_current_date",
	},
	{
		code: 323,
		reason: "Inventory cannot be updated before the last 3 days",
		message: "error.edit_last_3_days",
	},
];
