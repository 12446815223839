import { CROPTIMALS } from "../types";

const initialState = {
	loading: false,
	dropdownData: null,
	data: null,
	renderType: 0,
	columns: null,
	productKeyMapping: null,
};

export default function (state = initialState, { type, payload }) {
	switch (type) {
		case CROPTIMALS.LOADER_CROPTIMALS:
			return {
				...state,
				loading: !state.loading,
			};

		case CROPTIMALS.SET_DROPDOWN_DATA_CROPTIMALS:
			return {
				...state,
				dropdownData: payload.data,
			};
		case CROPTIMALS.SET_DATA_CROPTIMALS:
			return {
				...state,
				data: payload.data,
			};

		case CROPTIMALS.SET_DATA_LIST_CROPTIMALS:
			const temp = {};
			// eslint-disable-next-line array-callback-return
			payload.data.map((item) => {
				if (item.croptimalData) {
					temp[item.croptimal_slug] = item.croptimalData;
				}
			});

			const tempMap = [];
			payload.data.forEach((item) => {
				if (item.croptimalData) {
					tempMap.push({
						name: item.product_variety_name,
						slug: item.croptimal_slug,
					});
				}
			});

			return {
				...state,
				data: temp,
				productKeyMapping: tempMap,
			};
		case CROPTIMALS.SET_RENDER_TYPE_CROPTIMALS:
			return {
				...state,
				renderType: payload,
			};
		case CROPTIMALS.CROPTIMALS_COLUMNS_CROPTIMALS:
			return {
				...state,
				columns: payload,
			};
		case CROPTIMALS.SET_KEY_MAPPING_CROPTIMALS:
			return {
				...state,
				productKeyMapping: payload,
			};
		case CROPTIMALS.REFRESH_LISTING_CROPTIMALS:
			return {
				...initialState,
				dropdownData: state.dropdownData,
				renderType: state.renderType,
			};
		default:
			return state;
	}
}
