import React, { useState } from "react";
import { connect, useSelector } from "react-redux";
import { ContentWrap } from "elements";
import { isEmpty } from "lodash";
import { Row } from "react-bootstrap";
import ConfirmModal from "utils/confirmModal";
import { OrdersPackagingBreadcrumb, CaseSummary, PackagingSummary } from "../ordersPackagingComponents";
import {
	changeOrdersRenderType,
	toggleOPViewLoader,
	getPackageDetails,
	startOPTask,
	completeOPTask,
	changeSKURecipeOfOrder,
	getAuditLogOfOPTask,
	deletePackageDetails,
	currentOrderSlug,
	addAction,
	getInventoryTaskDetails,
} from "store/actions";
import { packageDetailsTableForm } from "./viewHelper";
import { OrderDetails, TaskStatusBar, BtnSKUChange, EditSKU } from "./viewComponents";
import Util from "utils/Util";
import { errorMessage } from "utils/errorMessage";
import { successMessage } from "utils/successMessage";
import TableLoader from "utils/table/tableLoader";
import useModal from "utils/useModal";
import PackageDetailsModal from "../packageDetails";
import AuditLog from "components/auditLog";
import { createStructuredSelector } from "reselect";
import RenderViewTable from "utils/ordersAndPackageTable/viewTable";
import {
	getAuditLogAccess,
	getCompleteTaskAccess,
	getCreatePackagingTaskAccess,
	getStartTaskAccess,
	getUpdatePackagingTaskAccess,
	getUpdateTaskSkuRecipeAccess,
} from "store/selectors/ordersPackagingSelectors";
import InventoryDetailsModal from "../inventoryDetails";
import { useTranslation } from "react-i18next";

const OrdersPackingView = ({
	changeOrdersRenderType,
	toggleOPViewLoader,
	getPackageDetails,
	startOPTask,
	completeOPTask,
	changeSKURecipeOfOrder,
	getAuditLogOfOPTask,
	completeTaskAccess,
	startTaskAccess,
	createPackagingTaskAccess,
	updatePackagingTaskAccess,
	auditLogAccess,
	updateTaskSkuRecipeAccess,
	deletePackageDetails,
	currentOrderSlug,
	addAction,
	getInventoryTaskDetails,
}) => {
	const { t } = useTranslation();
	const [deleteItem, setdeleteItem] = useState(null);

	const { packageDetails = {}, opViewLoader = false, activityLog = [] } = useSelector((state) => state.OrdersPackaging);
	const packagingActivate = useSelector((state) => state?.OrdersPackaging?.packageDetails?.sku_active);
	const [, , showModal] = useModal("op_package_details");
	const [, , inventoryShowModal] = useModal("inventory_show_modal");
	const [isRecipeEditing, setIsRecipeEditing] = useState(false);

	const [showActivityLog, setShowActivityLog] = useState(true);
	const [fetchingLog, setFetchingLog] = useState(false);

	const isPackageDetailsEntered = packageDetails && !isEmpty(packageDetails.order_packaging_task_details) ? true : false;

	const getType =
		packageDetails.status === "New"
			? 1
			: packageDetails.status === "In Progress" && !isPackageDetailsEntered
			? 2
			: packageDetails.status === "In Progress" && isPackageDetailsEntered
			? 3
			: packageDetails.status === "Completed"
			? 4
			: 0;

	// Task Start Time's to show
	const getCorrespondingTaskTime =
		packageDetails.status === "New"
			? null
			: packageDetails.status === "In Progress" && !isPackageDetailsEntered
			? packageDetails && Util.utcToLocal(packageDetails.packaging_start_time)
			: packageDetails.status === "In Progress" && isPackageDetailsEntered
			? null
			: packageDetails.status === "Completed"
			? null
			: null;

	// Common error handler
	const errorHandler = (err) => {
		errorMessage(err);
		toggleOPViewLoader(false);
	};

	// Fetch Package Details
	const fetchTaskDetails = () => {
		setIsRecipeEditing(false);
		toggleOPViewLoader(true);
		const onSuccess = () => toggleOPViewLoader(false);
		getPackageDetails(packageDetails.slug, onSuccess, errorHandler);
	};

	// Fetch inventory task Details
	const fetchInventoryTaskDetails = () => {
		setIsRecipeEditing(false);
		toggleOPViewLoader(true);
		const onSuccess = () => toggleOPViewLoader(false);
		getInventoryTaskDetails(packageDetails.slug, onSuccess, errorHandler);
	};

	// If delivery date is expired / Past date - Start task is not allowed
	const isPastDate = () => {
		const deliveryDate = (packageDetails && packageDetails.order && packageDetails.order.date_of_delivery) || null;
		return deliveryDate ? new Date(deliveryDate).setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0) : false;
	};
	// Action : Footer button clicks
	const onClickStatusAction = (currentStatus) => {
		if (currentStatus === 1) {
			if (!isPastDate()) {
				const successHandler = (data) => {
					successMessage(data);
					fetchTaskDetails();
				};
				toggleOPViewLoader(true);
				startOPTask(packageDetails.slug, successHandler, errorHandler);
			}
		}
		// Add packaging Details
		if (currentStatus === 2) {
			showModal(1);
		}
		if (currentStatus === 3) {
			const successHandler = (data) => {
				successMessage(data);
				fetchTaskDetails();
			};
			toggleOPViewLoader(true);
			completeOPTask(packageDetails.slug, successHandler, errorHandler);
		}
		// Show activity log
		if (currentStatus === 5) {
			showActivityLog && fetchAuditLog();
			setShowActivityLog(!showActivityLog);
		}
		if (currentStatus === 6) {
			inventoryShowModal(1);
			fetchInventoryTaskDetails();
		}
	};

	// Action : Edit Button Click
	const onClickEdit = (type) => {
		if (type === "PACKAGE") {
			const packageDetailsForModal = (packageDetails && packageDetails.order_packaging_task_details) || {};
			showModal(2, packageDetailsForModal);
		}
		if (type === "RECIPE") {
			setIsRecipeEditing(true);
		}
		if (type === "CLOSE_RECIPE") {
			setIsRecipeEditing(false);
		}
	};

	// Fetch Log
	const fetchAuditLog = () => {
		setFetchingLog(true);
		const onAuditSuccess = () => {
			setFetchingLog(false);
		};
		const onAuditError = (err) => {
			errorMessage(err);
			setFetchingLog(false);
		};
		getAuditLogOfOPTask(packageDetails.slug, onAuditSuccess, onAuditError);
	};
	const onClickEditHandler = (item) => {
		addAction(false);
		currentOrderSlug(item.slug);
		showModal(2);
	};

	const onDelete = () => {
		setFetchingLog(true);
		const successHandler = (e) => {
			successMessage(e);
			fetchTaskDetails();
			setdeleteItem(null);
			setFetchingLog(false);
		};
		const errorHandler = (e) => {
			errorMessage(e);
			setFetchingLog(false);
			setdeleteItem(null);
		};
		deletePackageDetails(deleteItem.slug, successHandler, errorHandler);
	};

	return (
		<ContentWrap>
			<div className="d-flex align-items-center mb-3">
				<OrdersPackagingBreadcrumb view={1} goBackToListing={() => changeOrdersRenderType(0)} />
			</div>
			<div className="position-relative">
				{/* Order Details */}
				{!isEmpty(packageDetails) && <OrderDetails data={packageDetails} />}

				{/* Summary Boxes */}
				<CaseSummary
					isThreeBoxes
					required={packageDetails.required_cases}
					possible={packageDetails.possible_cases}
					updated={packageDetails.updated_cases}
					totalPacked={packageDetails.inventory_data ? packageDetails.inventory_data.unsold_cases : 0}
					unPackedCases={packageDetails.inventory_data ? packageDetails.inventory_data.by_date : []}
				/>

				{/* Harvest LBS */}
				{(!isEmpty(packageDetails.actual_harvested) || !isEmpty(packageDetails.available_harvest)) && (
					<div className="op-view-content">
						<PackagingSummary
							title={t("label.actual_harvest_lbs")}
							data={Util.mapOptions(packageDetails.actual_harvested, "product_variety", "total_lbs_harvested")}
							unit=" Lbs"
						/>
						<PackagingSummary
							title={t("label.available_harvest_lbs")}
							data={Util.mapOptions(packageDetails.available_harvest, "product_variety", "total_lbs_harvested")}
							unit=" Lbs"
						/>
						<PackagingSummary
							title={t("label.inventory_unpacked_lbs")}
							data={Util.mapOptions(packageDetails.inventory_data.unpacked_lbs, "product_variety", "lbs")}
							unit=" Lbs"
						/>
					</div>
				)}

				{/* Recipe */}
				{!isEmpty(packageDetails.sku_recipe) &&
					(isRecipeEditing ? (
						<EditSKU
							slug={packageDetails.slug || null}
							initialValues={packageDetails.sku_recipe || []}
							callFetchOrder={fetchTaskDetails}
							onCloseEditRecipe={onClickEdit}
							changeSKURecipeOfOrder={changeSKURecipeOfOrder}
						/>
					) : (
						<div className="op-view-content">
							<PackagingSummary
								title={t("label.recipe")}
								data={Util.mapOptions(packageDetails.sku_recipe, "product_variety_name", "percentage")}
								unit="%"
							/>
							<PackagingSummary
								title={t("label.lbs_needed_for_the_order")}
								data={Util.mapOptions(packageDetails.sku_recipe, "product_variety_name", "required_lbs")}
								unit=" Lbs"
							/>
							{getType === 1 && packagingActivate && updateTaskSkuRecipeAccess && (
								<BtnSKUChange onClickEdit={onClickEdit} />
							)}
						</div>
					))}

				{!isEmpty(packageDetails.order_packaging_task_details) && (
					<div className="outer-box-view">
						<div className="d-flex justify-content-between">
							<Row>
								<h2 className="form-headers">{t("label.packaging_details")}</h2>
							</Row>
						</div>
						<div className="mb-5 mb-1">
							<RenderViewTable
								form={packageDetailsTableForm}
								data={packageDetails.order_packaging_task_details}
								addItem={() => {
									showModal(3);
									addAction(true);
								}}
								onClickEditHandler={onClickEditHandler}
								onClickDeleteHandle={(item) => setdeleteItem(item)}
								status={packageDetails?.status}
							/>
						</div>
					</div>
				)}

				<ConfirmModal
					show={deleteItem ? true : false}
					onNo={() => setdeleteItem(null)}
					onYes={onDelete}
					message={t("confirm.delete_task")}
				/>

				{/* Status Bar */}
				<TaskStatusBar
					type={getType}
					onClickStatusAction={onClickStatusAction}
					loading={opViewLoader && (getType === 1 || getType === 3)}
					completeTaskAccess={completeTaskAccess}
					startTaskAccess={startTaskAccess}
					createPackagingTaskAccess={createPackagingTaskAccess}
					tastStartTime={getCorrespondingTaskTime}
					packagingActivate={packagingActivate}
					addInventory={packageDetails?.inventory_data?.by_date}
				/>

				{/* Loader */}
				{opViewLoader && (
					<div className="table-loader-wrapper">
						<TableLoader />
					</div>
				)}
			</div>

			{/* Activity Log */}
			{auditLogAccess && <AuditLog log={activityLog} loading={fetchingLog} onClickStatusAction={onClickStatusAction} />}

			{/* Packaging Details Modal */}
			<PackageDetailsModal doFetchTaskDetails={fetchTaskDetails} />
			<InventoryDetailsModal doFetchTaskDetails={fetchInventoryTaskDetails} fetchTaskDetails={fetchTaskDetails} />
		</ContentWrap>
	);
};

const mapStateToProps = createStructuredSelector({
	completeTaskAccess: getCompleteTaskAccess,
	startTaskAccess: getStartTaskAccess,
	createPackagingTaskAccess: getCreatePackagingTaskAccess,
	updatePackagingTaskAccess: getUpdatePackagingTaskAccess,
	auditLogAccess: getAuditLogAccess,
	updateTaskSkuRecipeAccess: getUpdateTaskSkuRecipeAccess,
	// status:getTaskStatus
});

export default connect(mapStateToProps, {
	changeOrdersRenderType,
	toggleOPViewLoader,
	getPackageDetails,
	startOPTask,
	completeOPTask,
	changeSKURecipeOfOrder,
	getAuditLogOfOPTask,
	deletePackageDetails,
	currentOrderSlug,
	addAction,
	getInventoryTaskDetails,
})(OrdersPackingView);
