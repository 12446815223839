import { capitalize, sumBy } from "lodash";
import Util from "../../../utils/Util";
import { flagColors } from "../../../constant/global";
const getFlagLabel = (flag_color) => {
	const found = flagColors.find(({ value }) => {
		return String(value).match(flag_color);
	});
	return found.label;
};
export const TaskDetailsView = [
	{
		label: "label.task_id",
		accessor: "task_id",
		classes: "col-lg-3 col-md-4 col-sm-6 col-12",
	},
	{
		label: "label.location",
		accessor: "location",
		classes: "col-lg-3 col-md-4 col-sm-6 col-12",
		render: (task) => (task.location ? task.location.label : "-"),
	},
	{
		label: "label.expected_transplant_date",
		accessor: "expected_transplant_date",
		classes: "col-lg-3 col-md-4 col-sm-6 col-12",
		render: (task) => Util.formatMDY(task.expected_transplant_date),
	},
	{
		label: "label.product_variety",
		accessor: "expected_germination_date",
		classes: "col-lg-3 col-md-4 col-sm-6 col-12",
		render: (task) => task.product_variety && task.product_variety.name,
	},

	{
		label: "label.season",
		accessor: "season_name",
		classes: "col-lg-3 col-md-4 col-sm-6 col-12",
	},
	{
		label: "label.flag_color",
		accessor: "flag_color",
		classes: "col-lg-3 col-md-4 col-sm-6 col-12",
		render: (task) => getFlagLabel(task.flag_color),
	},

	{
		label: "label.description",
		accessor: "task_description",
		classes: "",
		render: (task) => (task.task_description ? task.task_description : "-"),
	},
];

export const ProductInfoView = [
	{
		label: "label.germination_period",
		accessor: "germination_period",
		classes: "col-lg-3 col-md-4 col-sm-6 col-12",
		render: (task) => (task.germination_period ? `${task.germination_period} Days` : "-"),
	},
	{
		label: "label.gorw_cycle_period",
		accessor: "grow_cycle",
		classes: "col-lg-3 col-md-4 col-sm-6 col-12",
		render: (task) => (task.grow_cycle ? `${task.grow_cycle} Days` : "-"),
	},
	{
		label: "label.harvesting_period",
		accessor: "harvesting_period",
		classes: "col-lg-3 col-md-4 col-sm-6 col-12",
		render: (task) => (task.harvesting_period ? `${task.harvesting_period} Days` : "-"),
	},
	{
		label: "label.shelf_life_period",
		accessor: "shelflife_period",
		classes: "col-lg-3 col-md-4 col-sm-6 col-12",
		render: (task) => (task.shelflife_period ? `${task.shelflife_period} Days` : "-"),
	},
];

export const GrowingCondView = [
	{
		label: "label.temperature",
		accessor: "growing_temperature",
		classes: "col-lg-3 col-md-4 col-sm-6 col-12",
		render: (task) => (task.growing_temperature ? `${task.growing_temperature} C` : "-"),
	},
	{
		label: "label.humidity",
		accessor: "growing_humidity",
		classes: "col-lg-3 col-md-4 col-sm-6 col-12",
		render: (task) => (task.growing_humidity ? `${task.growing_humidity} %` : "-"),
	},
	{
		label: "label.light",
		accessor: "growing_light",
		classes: "col-lg-3 col-md-4 col-sm-6 col-12",
		render: (task) => (task.growing_light ? task.growing_light : "-"),
	},
];

export const GerminationCondView = [
	{
		label: "label.temperature",
		accessor: "germination_temperature",
		classes: "col-lg-3 col-md-4 col-sm-6 col-12",
		render: (task) => (task.germination_temperature ? `${task.germination_temperature} C` : "-"),
	},
	{
		label: "label.humidity",
		accessor: "germination_humidity",
		classes: "col-lg-3 col-md-4 col-sm-6 col-12",
		render: (task) => (task.germination_humidity ? `${task.germination_humidity} %` : "-"),
	},
	{
		label: "label.germination_time",
		accessor: "germination_time",
		classes: "col-lg-3 col-md-4 col-sm-6 col-12",
		render: (task) => (task.germination_time ? `${task.germination_time} Days` : "-"),
	},
	{
		label: "label.seed_density",
		accessor: "seed_density",
		classes: "col-lg-3 col-md-4 col-sm-6 col-12",
	},
];

export const PondMetricsView = [
	{
		label: "label.ph",
		accessor: "ph",
		classes: "col-lg-3 col-md-4 col-sm-6 col-12",
	},
	{
		label: "label.ec",
		accessor: "ec",
		classes: "col-lg-3 col-md-4 col-sm-6 col-12",
	},
	{
		label: "label.do",
		accessor: "do",
		classes: "col-lg-3 col-md-4 col-sm-6 col-12",
	},
	{
		label: "label.pond_temperature",
		accessor: "pond_temperature",
		classes: "col-lg-3 col-md-4 col-sm-6 col-12",
		render: (task) => (task.pond_temperature ? `${task.pond_temperature} C` : "-"),
	},
];

export const climateParametersView = [
	{
		label: "label.temperature",
		accessor: "germination_temperature",
		classes: "col-lg-3 col-md-4 col-sm-6 col-12",
		render: (task) => (task.germination_temperature ? `${task.germination_temperature} C` : "-"),
	},
	{
		label: "label.humidity",
		accessor: "germination_humidity",
		classes: "col-lg-3 col-md-4 col-sm-6 col-12",
		render: (task) => (task.germination_humidity ? `${task.germination_humidity} %` : "-"),
	},
];

export const TransplantDetailsViewBottom = [
	{
		label: "label.actual_transplant_start_date_time",
		accessor: "transplant_start_date",
		classes: "col-lg-3 col-md-4 col-sm-6 col-12",
		render: (task) => (task.transplant_start_date ? Util.utcToLocal(task.transplant_start_date) : "-"),
	},
	{
		label: "label.flag_color",
		accessor: "seeding_flag_color",
		classes: "col-lg-3 col-md-4 col-sm-6 col-12",
		render: (task) =>
			task.transplant_and_growing_task_transplant_details &&
			task.transplant_and_growing_task_transplant_details.flag_color
				? capitalize(task.transplant_and_growing_task_transplant_details.flag_color)
				: "",
	},
	{
		label: "label.total_boards_transplanted",
		accessor: "comments",
		classes: "col-lg-3 col-md-4 col-sm-6 col-12",
		render: (task) =>
			task.transplant_and_growing_task_transplant_details &&
			task.transplant_and_growing_task_transplant_details.transplant_details
				? sumBy(task.transplant_and_growing_task_transplant_details.transplant_details, function (o) {
						return parseInt(o.number_of_boards);
				  })
				: "-",
	},
	{
		label: "label.actual_transplant_end_date",
		accessor: "transplant_end_date",
		classes: "col-lg-3 col-md-4 col-sm-6 col-12",
		render: (task) => (task.transplant_end_date ? Util.utcToLocal(task.transplant_end_date) : "-"),
	},
	{
		label: "label.comments",
		accessor: "comments",
		classes: "col-lg-3 col-md-4 col-sm-6 col-12",
		render: (task) =>
			task.transplant_and_growing_task_transplant_details &&
			task.transplant_and_growing_task_transplant_details.task_description
				? task.transplant_and_growing_task_transplant_details.task_description
				: "-",
	},
];

export const tableHeadersBasicDetails = ["label.pond", "label.lane_number", "label.no_of_boards"];
export const tableHeadersTransplantDetails = [
	"label.pond",
	"label.lane_number",
	"label.no_of_boards",
	"label.germination_date",
];
export const tableHeadersPondMetrics = [
	{
		label: "label.pond",
		value: "ph",
	},
	{
		label: "label.ph",
		value: "ph",
	},
	{
		label: "label.ec",
		value: "ec",
	},
	{
		label: "label.do",
		value: "do",
	},
	{
		label: "label.pont_temperature",
		value: "pond_temperature",
	},
	{
		label: "label.pond_time",
		value: "time",
	}
];