import React, { useEffect, useState } from "react";
import { get, includes } from "lodash";
import { connect } from "react-redux";
import { Row, Col, Form, Button } from "react-bootstrap";
import {
	formCreationFields,
	getValidations,
	mapOptions,
	timeFrameArray,
	reportTypeArray,
	calculationTypeArray,
	pickerFormatArray,
} from "./formHelper";
import { reduxForm, Field, change } from "redux-form";
import FormGroups from "../../utils/formGroup";
import {
	fetchGraphDetails,
	fetchLocationSkus,
	getLocationCustomersReport,
	fetchLocationProductVaritiesReport,
} from "../../store/actions";
import { ButtonPrimary } from "../../elements";
import { useDispatch } from "react-redux";

const ReportForm = ({
	permissionDetails,
	handleSubmit,
	fetchGraphDetails,
	fetchLocationSkus,
	getLocationCustomersReport,
	fetchLocationProductVaritiesReport,
	locationList,
	locationSkus,
	btnTitle,
	submitCustomerDetails,
	isLoading,
	locationCustomers,
	productVariety,
	reset,
	pristine,
	submitting,
}) => {
	const [startDate, setStartDate] = useState(null);
	const [endDate, setEndDate] = useState(null);
	const [selectedReport, setSelectedReport] = useState([]);
	const [selectedLocation, setSelectedLocation] = useState(null);
	const [selectedCustomer, setSelectedCustomer] = useState(null);
	const [selectedSku, setSelectedSku] = useState([]);
	const [pickerTypeSelector, setPickerTypeSelector] = useState(0);
	const [isSku, setIsSKu] = useState(false);
	const [isAdditionalFields, setIsAdditionalFields] = useState(false);
	const [displayProductVariety, setDisplayProductVariety] = useState(false);
	const dispatch = useDispatch();
	const permissionArray =
		permissionDetails && permissionDetails.reports && permissionDetails.reports.permissions
			? permissionDetails.reports.permissions
			: "";

	useEffect(() => {
		fetchLocationSkus();
		getLocationCustomersReport();
		fetchLocationProductVaritiesReport();
	}, [fetchLocationSkus, getLocationCustomersReport, fetchLocationProductVaritiesReport]);

	const getReportWithPermissions = () => {
		const newList = [];
		reportTypeArray.forEach(function (singleReportType, i) {
			if (permissionArray[singleReportType.permission_key]) {
				newList[i] = {
					...singleReportType,
				};
			}
		});

		return newList;
	};

	const dcFormating = (formItem) => {
		const data = [];
		// eslint-disable-next-line array-callback-return
		formItem.map((item_1) => {
			if (selectedCustomer === item_1.customer_slug) {
				data.push(...mapOptions(item_1.dc_list, "dc_name", "dc_slug"));
			}
		});
		return data;
	};

	const getOptions = (field) => {
		switch (field) {
			case "report":
				return getReportWithPermissions();
			case "location":
				return locationList ? mapOptions(locationList, "name", "slug") : [];
			case "type":
				return selectedReport;
			case "sku":
				return isSku ? mapOptions(selectedSku, "name", "value") : [];
			case "timeframe":
				return timeFrameArray;
			case "customer_slug":
				return selectedLocation && locationCustomers
					? mapOptions(locationCustomers[selectedLocation], "customer_name", "customer_slug")
					: [];
			case "pv_slug":
				return selectedLocation && locationCustomers
					? mapOptions(productVariety[selectedLocation], "product_variety_name", "product_variety_slug")
					: [];
			case "dc_slug":
				return selectedLocation &&
					selectedCustomer &&
					locationCustomers &&
					locationCustomers[selectedLocation] &&
					locationCustomers[selectedLocation]
					? dcFormating(locationCustomers[selectedLocation])
					: [];
			default:
				return [];
		}
	};
	const onChange = (dates) => {
		const [start, end] = dates;
		setStartDate(start);
		setEndDate(end);
	};

	const onChangeSelect = (e, name) => {
		if (name === "report" && e && e.value) {
			if (e.value !== "orders_and_packaging") {
				setIsSKu(false);
			}
			dispatch(change("customerManagementForm", "type", []));
			setSelectedReport(calculationTypeArray[e.value]);
		} else if (name === "timeframe") {
			e.value && e.value === "monthly"
				? setPickerTypeSelector(1)
				: e.value && e.value === "yearly"
				? setPickerTypeSelector(2)
				: setPickerTypeSelector(0);
			dispatch(change("customerManagementForm", "date", []));
			setStartDate(null);
			setEndDate(null);
		} else if (name === "location" && e && e.value) {
			setSelectedSku(locationSkus[e.value]);
			setSelectedLocation(e.value);
			isAdditionalFields && dispatch(change("customerManagementForm", "customer_slug", []));
			isAdditionalFields && dispatch(change("customerManagementForm", "dc_slug", []));
			displayProductVariety && dispatch(change("customerManagementForm", "pv_slug", []));
		} else if (name === "customer_slug" && e && e.value) {
			setSelectedCustomer(e.value);
			isAdditionalFields && dispatch(change("customerManagementForm", "dc_slug", []));
		} else if (name === "type") {
			if (e.value === "orders_created" || e.value === "shortage_cases" || e.value === "excess_cases") {
				setIsSKu(true);
				setIsAdditionalFields(false);
				setDisplayProductVariety(false);
			} else if (e.value === "production_forecast_revenue_by_cases" || e.value === "sales_forecast_revenue_by_cases") {
				setIsSKu(false);
				setIsAdditionalFields(true);
				setDisplayProductVariety(false);
				dispatch(change("customerManagementForm", "customer_slug", []));
				dispatch(change("customerManagementForm", "dc_slug", []));
			} else if (
				e.value === "production_forecast_revenue_by_variety" ||
				e.value === "sales_forecast_revenue_by_variety"
			) {
				setIsSKu(false);
				setIsAdditionalFields(false);
				setDisplayProductVariety(true);
				dispatch(change("customerManagementForm", "pv_slug", []));
			} else {
				setIsSKu(false);
				setIsAdditionalFields(false);
				setDisplayProductVariety(false);
			}
		}
	};

	const customerFormFields = () => {
		let columns = [];
		columns = formCreationFields.map((field, idx) => {
			return (
				!(
					(field.name === "sku" && !isSku) ||
					(includes(["customer_slug", "dc_slug"], field.name) && !isAdditionalFields) ||
					(includes(["pv_slug"], field.name) && !displayProductVariety)
				) && (
					<Col
						className={
							isSku || displayProductVariety || isAdditionalFields
								? "forecast-form-column"
								: "col-xl-2 col-lg-3 col-md-4 col-sm-4 col-4"
						}
						key={idx}>
						<Form.Group controlId={`formControl ${idx}`}>
							{field.type === "select-react" ? (
								<Field
									name={field.name}
									type={field.type}
									label={field.label}
									placeholder={field.placeholder}
									component={FormGroups}
									validate={getValidations(field.name)}
									options={getOptions(field.name)}
									onChange={(event) => {
										onChangeSelect(event, field.name);
									}}
								/>
							) : (
								<Field
									name={field.name}
									type={field.type}
									label={field.label}
									placeholder={field.placeholder}
									component={FormGroups}
									startDate={startDate}
									selectsEnd={Boolean(startDate)}
									endDate={endDate}
									onChange={onChange}
									validate={getValidations(field.name)}
									dateFormat={pickerFormatArray[pickerTypeSelector]}
									pickerTypeSelector={pickerTypeSelector}
								/>
							)}
						</Form.Group>
					</Col>
				)
			);
		});

		return (
			<React.Fragment>
				{permissionArray ? (
					<Row className="forecast-form-row">
						{columns}
						{/* <Col className="col-xl-1 col-lg-3 col-md-4 col-sm-4 col-4 ">
							<span>
								<ButtonPrimary type="submit " disabled={isLoading} className="margin-top-10">
									{btnTitle}
								</ButtonPrimary>{" "}
							</span>
						</Col> */}

						{displayProductVariety && (
							<Col className="forecast-form-column">
								<Form.Group className="mt-5" controlId={`formControl exclude_wastage`}>
									<Field
										name="exclude_wastage"
										type="checkbox"
										label="Exclude wastage"
										component={FormGroups}
										onBlur={(event) => event.preventDefault()}
									/>
								</Form.Group>
							</Col>
						)}
						<Col className="form-submit-col">
							<ButtonPrimary type="submit" disabled={pristine || submitting}>
								{btnTitle}
							</ButtonPrimary>
							<Button
								className="reset-btn"
								onClick={() => {
									reset();
									setStartDate(null);
									setEndDate(null);
								}}
								disabled={pristine || submitting}>
								<i className="icon icon-reset mr-2" />
							</Button>
						</Col>
					</Row>
				) : (
					""
				)}
			</React.Fragment>
		);
	};

	return (
		<Form autoComplete="off" onSubmit={handleSubmit(submitCustomerDetails, 1)} className="customer-management-form">
			{selectedSku && customerFormFields()}
			{isLoading && <div className="table-loader-wrapper">{/* <TableLoader /> */}</div>}
		</Form>
	);
};
const mapStateToProps = (state) => {
	const { Auth, Reports } = state;
	return {
		locationList: get(Auth, "reportLocations", []),
		permissionDetails: get(Auth, "permissionDetails", null),
		locationSkus: get(Reports, "locationSkus", []),
		locationCustomers: get(Reports, "locationCustomers", []),
		productVariety: get(Reports, "locationProductVariety", []),
	};
};

const mapDispatchToProps = {
	fetchGraphDetails,
	fetchLocationSkus,
	getLocationCustomersReport,
	fetchLocationProductVaritiesReport,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(
	reduxForm({
		form: "customerManagementForm",
		enableReinitialize: true,
	})(ReportForm)
);
