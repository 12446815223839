import React, { useState, useEffect } from "react";
import { get } from "lodash";
import { connect } from "react-redux";
import { ContentWrap, ButtonPrimary, Breadcrumbs } from "../../../elements";
import "../manage/modal.scss";
import "./index.scss";
import Listing from "../../../partials/listings";
import UserForm from "../manage/userDetailsForm";
import ConfirmModal from "../../../utils/confirmModal";
import useModal from "../../../utils/useModal";
import { renderColumns } from "./helper";
import { fetchUserDetails, fetchUserRoles, fetchUsers, fetchLocations, deleteUser, createUser } from "../../../store/actions";
import { errorMessage } from "../../../utils/errorMessage";
import { successMessage } from "../../../utils/successMessage";
import TableLoader from "../../../utils/table/tableLoader";
import { useTranslation } from "react-i18next";
import { isEmpty } from "lodash";

const UserListing = ({
	doFetchUserDetails,
	editingUserDetails,
	fetchUsers,
	fetchUserRoles,
	fetchLocations,
	deleteUser,
	allLocationsList,
	createUser,
	permissionDetails,
	roleList,
	userList
}) => {
	const { t } = useTranslation();
	const [userToDelete, setUserToDelete] = useState(null);
	const [load, setLoad] = useState(false);

	const [, modalType, showModal] = useModal("users");
	const [refetch, setRefetch] = useState(0);
	const [filter, setFilter] = useState([]);
	const [clickLoader, setclickLoader] = useState(false);
	const [exportFlag,setExportFlag] = useState(null);

	useEffect(() => {
		fetchUserRoles();
		fetchLocations();
	}, [fetchUserRoles, fetchLocations]);

	const handleAddUser = () => showModal(0);
	const handleEditPanel = (id) => {
		setclickLoader(true);
		const successHandler = () => onSuccess(1);
		doFetchUserDetails(id, successHandler, onError);
	};
	const handleViewPanel = (id) => {
		setclickLoader(true);
		const successHandler = () => onSuccess(2);
		doFetchUserDetails(id, successHandler, onError);
	};
	const onSuccess = (modalType) => {
		setclickLoader(false);
		showModal(modalType);
	};
	const onError = (e) => {
		setclickLoader(false);
		errorMessage(e);
	};
	const confirmDelete = (id) => setUserToDelete(id);

	// Delete User
	const onDeleteUser = (id) => {
		const successHandler = (e) => {
			setUserToDelete(null);
			setRefetch(refetch + 1);
			successMessage(e);
		};
		const errorHandler = (e) => {
			setUserToDelete(null);
			errorMessage(e);
		};
		deleteUser(id, successHandler, errorHandler);
	};

	// Filter
	const addToFilter = (e) => {
		let obj = [];
		e.forEach((item) => {
			return (obj =
				item && item.column
					? [
							...obj,
							{
								column_name: item.column.accessor,
								search: item.value,
							},
					  ]
					: obj);
		});
		setFilter(obj);
	};

	// Edit user status
	const changeUserStatus = (e, row = {}) => {
		const { id } = row;
		setLoad(true);
		const successHandler = (event) => {
			setLoad(false);
			successMessage(event);
			setRefetch(refetch + 1);
		};
		const errorHandler = (event) => {
			setLoad(false);
			errorMessage(event);
		};
		createUser({ status: e }, id, successHandler, errorHandler);
	};

	const columns = renderColumns({
		handleEditPanel,
		handleViewPanel,
		confirmDelete,
		addToFilter,
		allLocationsList,
		changeUserStatus,
		permissionDetails,
		roleList,
	});
	const addPermission =
		permissionDetails && permissionDetails.user && permissionDetails.user.permissions.create_user
			? permissionDetails.user.permissions.create_user
			: "";
	const listPermission =
		permissionDetails && permissionDetails.user && permissionDetails.user.permissions.list_users
			? permissionDetails.user.permissions.list_users
			: "";
	const updatePermission =
		permissionDetails && permissionDetails.user && permissionDetails.user.permissions.update_user
			? permissionDetails.user.permissions.update_user
			: "";
	const setLocations = (userDetails) => {
		const newLocationList = userDetails.location.map((field) => ({
			label: field["name"],
			value: field["slug"].toString(),
		}));

		return newLocationList;
	};

	const exportHandler = () =>{
		setExportFlag(true);
	}

	const exportHandlerFlag = () => {
		setExportFlag(false);
	};

	return (
		<ContentWrap>
			<div className="d-flex align-items-center mb-3">
				<Breadcrumbs keyValue="users" showLabel={listPermission} label="List" />
				{!isEmpty(userList) &&
				<div>
					<ButtonPrimary className="export_btn" onClick={exportHandler}>
						<span>
						{t("label.export")}{" "}
							<i className="icon icon-export text-sm" style={{fontWeight:"bold"}}/>
						</span>
					</ButtonPrimary>
				</div>
                }
				<div>
					{addPermission ? (
						<ButtonPrimary onClick={handleAddUser}>
							<i className="icon icon-plus mr-2" /> Add User
						</ButtonPrimary>
					) : (
						""
					)}
				</div>
			</div>
			<div className={load ? "d-none position-relative" : "position-relative"}>
				{listPermission ? (
					<Listing fetchAction={fetchUsers} sort_name={"uid"} columns={columns} refetch={refetch} filter={filter} exportFlag={exportFlag} exportHandlerFlag={()=>{exportHandlerFlag()}} />
				) : (
					""
				)}
				{clickLoader && (
					<div className="table-loader-wrapper">
						<TableLoader />
					</div>
				)}
				<UserForm
					updatePermission={updatePermission}
					handleEditUser={handleEditPanel}
					editingUserDetails={modalType ? (editingUserDetails ? editingUserDetails[0] : null) : null}
					initialValues={
						modalType
							? editingUserDetails
								? {
										...editingUserDetails[0],
										role: {
											label: editingUserDetails[0].role_name,
											value: editingUserDetails[0].role_id,
										},
										location: setLocations(editingUserDetails[0]),
								  }
								: null
							: { status: true }
					}
					onAddOrEdit={() => setRefetch(refetch + 1)}
				/>
			</div>
			{load && permissionDetails && <TableLoader />}
			<ConfirmModal
				show={userToDelete ? true : false}
				onNo={() => setUserToDelete(null)}
				onYes={() => onDeleteUser(userToDelete)}
				message="Are you sure want to delete this user?"
			/>
		</ContentWrap>
	);
};
const mapStateToProps = (state) => {
	const { UI, Auth, User } = state;
	return {
		accessToken: get(Auth, "accessToken", null),
		permissionDetails: get(Auth, "permissionDetails", null),
		editingUserDetails: get(User, "userDetails", null),
		mainPreloader: get(UI, "mainPreloader", null),
		allLocationsList: get(User, "userLocations", null),
		roleList: get(User, "userRoles", null),
		userList: state.User.userList.data 
	};
};

const mapDispatchToProps = {
	doFetchUserDetails: fetchUserDetails,
	fetchUserRoles: fetchUserRoles,
	fetchLocations: fetchLocations,
	fetchUsers,
	deleteUser,
	createUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserListing);
