import { PACK_RATE_GRAPH } from "../types";
const initialState = {
	packRateGraphDetails: [],
};
export default (state = initialState, action) => {
	switch (action.type) {
		case PACK_RATE_GRAPH.FETCH_PACK_RATE_GRAPH_DETAILS:
			return {
				...state,
				packRateGraphDetails: action.payload ? action.payload.data : [],
			};

		default:
			return state;
	}
};
