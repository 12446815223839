import { capitalize } from "lodash";
import Util from "../../../utils/Util";
import { flagColors } from "../../../constant/global";
const getFlagLabel = (flag_color) => {
	const found = flagColors.find(({ value }) => {
		return String(value).match(flag_color);
	});
	return found.label;
};
export const TaskDetailsView = [
	{
		label: "label.task_id",
		accessor: "task_id",
		classes: "col-lg-3 col-md-4 col-sm-6 col-12",
	},
	{
		label: "label.location",
		accessor: "",
		classes: "col-lg-3 col-md-4 col-sm-6 col-12",
		render: (task) => (task && task.location && task.location.location_name) || "-",
	},
	{
		label: "label.expected_seeding_date",
		accessor: "expected_seeding_date",
		classes: "col-lg-3 col-md-4 col-sm-6 col-12",
		render: (task) => Util.formatMDY(task.expected_seeding_date),
	},
	{
		label: "label.expected_germination_date",
		accessor: "expected_germination_date",
		classes: "col-lg-3 col-md-4 col-sm-6 col-12",
		render: (task) => (task.expected_germination_date && Util.formatMDY(task.expected_germination_date)) || "-",
	},
	{
		label: "label.expected_transplant_date",
		accessor: "expected_transplant_date",
		classes: "col-lg-3 col-md-4 col-sm-6 col-12",
		render: (task) => (task.expected_transplant_date && Util.formatMDY(task.expected_transplant_date)) || "-",
	},
	{
		label: "label.batch_type",
		accessor: "task_type",
		classes: "col-lg-3 col-md-4 col-sm-6 col-12",
		render: (task) => (task.task_type && capitalize(task.task_type)) || "-",
	},
	{
		label: "label.season",
		accessor: "season",
		classes: "col-lg-3 col-md-4 col-sm-6 col-12",
	},
	{
		label: "label.drum_size",
		accessor: "drum_size",
		classes: "col-lg-3 col-md-4 col-sm-6 col-12",
		render: (task) => (task.drum_size && task.drum_size.value) || "-",
	},
	{
		label: "label.germination_chamber",
		accessor: "germination_chamber",
		classes: "col-lg-3 col-md-4 col-sm-6 col-12",
		render: (task) => (task.germination_chamber && task.germination_chamber.value) || "-",
	},
	{
		label: "label.top_coat",
		accessor: "top_coat",
		classes: "col-lg-3 col-md-4 col-sm-6 col-12",
		render: (task) => (task.top_coat ? "Yes" : "No"),
	},
	{
		label: "label.product_variety",
		accessor: "",
		classes: "col-lg-3 col-md-4 col-sm-6 col-12",
		render: (task) => task.product_variety && task.product_variety.name,
	},
	{
		label: "label.seed_density",
		accessor: "seed_density",
		classes: "col-lg-3 col-md-4 col-sm-6 col-12",
	},
	{
		label: "label.no_of_boards",
		accessor: "board_count",
		classes: "col-lg-3 col-md-4 col-sm-6 col-12",
	},
	{
		label: "label.flag_color",
		accessor: "flag_color",
		classes: "col-lg-3 col-md-4 col-sm-6 col-12",
		render: (task) => getFlagLabel(task.flag_color),
	},
	{
		label: "label.description",
		accessor: "description",
		classes: "",
		render: (task) => (task.description ? task.description : "-"),
	},
];

export const ProductInfoView = [
	{
		label: "label.germination_period",
		accessor: "germination_period",
		classes: "col-lg-3 col-md-4 col-sm-6 col-12",
		render: (task) => (task.germination_period ? `${task.germination_period} Days` : "-"),
	},
	{
		label: "label.gorw_cycle_period",
		accessor: "grow_cycle",
		classes: "col-lg-3 col-md-4 col-sm-6 col-12",
		render: (task) => (task.grow_cycle ? `${task.grow_cycle} Days` : "-"),
	},
	{
		label: "label.harvesting_period",
		accessor: "harvesting_period",
		classes: "col-lg-3 col-md-4 col-sm-6 col-12",
		render: (task) => (task.harvesting_period ? `${task.harvesting_period} Days` : "-"),
	},
	{
		label: "label.shelf_life_period",
		accessor: "shelflife_period",
		classes: "col-lg-3 col-md-4 col-sm-6 col-12",
		render: (task) => (task.shelflife_period ? `${task.shelflife_period} Days` : "-"),
	},
];

export const GrowingCondView = [
	{
		label: "label.temperature",
		accessor: "growing_temperature",
		classes: "col-lg-3 col-md-4 col-sm-6 col-12",
		render: (task) => (task.growing_temperature ? `${task.growing_temperature} C` : "-"),
	},
	{
		label: "label.humidity",
		accessor: "growing_humidity",
		classes: "col-lg-3 col-md-4 col-sm-6 col-12",
		render: (task) => (task.growing_humidity ? `${task.growing_humidity} %` : "-"),
	},
	{
		label: "label.light",
		accessor: "growing_light",
		classes: "col-lg-3 col-md-4 col-sm-6 col-12",
		render: (task) => (task.growing_light ? task.growing_light : "-"),
	},
];

export const GerminationCondView = [
	{
		label: "label.temperature",
		accessor: "germination_temperature",
		classes: "col-lg-3 col-md-4 col-sm-6 col-12",
		render: (task) => (task.germination_temperature ? `${task.germination_temperature} C` : "-"),
	},
	{
		label: "label.humidity",
		accessor: "germination_humidity",
		classes: "col-lg-3 col-md-4 col-sm-6 col-12",
		render: (task) => (task.germination_humidity ? `${task.germination_humidity} %` : "-"),
	},
	{
		label: "label.germination_time",
		accessor: "germination_time",
		classes: "col-lg-3 col-md-4 col-sm-6 col-12",
		render: (task) => (task.germination_time ? `${task.germination_time} Days` : "-"),
	},
	{
		label: "label.seed_density",
		accessor: "seed_density",
		classes: "col-lg-3 col-md-4 col-sm-6 col-12",
	},
];

export const SeedingDetailsView = [
	{
		label: "label.actual_seeding_start_date",
		accessor: "seeding_start_date",
		classes: "col-lg-3 col-md-4 col-sm-6 col-12",
		render: (task) => (task.seeding_start_date ? Util.utcToLocal(task.seeding_start_date) : "-"),
	},
	{
		label: "label.actual_boards_seeded",
		accessor: "seeded_board_count",
		classes: "col-lg-3 col-md-4 col-sm-6 col-12",
	},
	{
		label: "label.flag_color",
		accessor: "seeding_flag_color",
		classes: "col-lg-3 col-md-4 col-sm-6 col-12",
		render: (task) => capitalize(task.seeding_flag_color),
	},
	{
		label: "label.seed_accuracy_in_furrow",
		accessor: "seeding_accuracy_in_furrows",
		classes: "col-lg-3 col-md-4 col-sm-6 col-12",
		render: (task) => (task.seeding_accuracy_in_furrows ? task.seeding_accuracy_in_furrows + "%" : "-"),
	},
	{
		label: "label.batch_number",
		accessor: "seed_batch_number",
		classes: "col-lg-3 col-md-4 col-sm-6 col-12",
	},
	{
		label: "label.seed_density",
		accessor: "seeding_seed_density",
		classes: "col-lg-3 col-md-4 col-sm-6 col-12",
	},
	{
		label: "label.seed_lot_no",
		accessor: "seed_lot_number",
		classes: "col-lg-3 col-md-4 col-sm-6 col-12",
		render: (task) => (task.seed_lot_number ? task.seed_lot_number : task.prev_seed_lot_no ? task.prev_seed_lot_no : "-"),
	},
	{
		label: "label.seed_container_filled",
		accessor: "seeding_container_filled",
		classes: "col-lg-3 col-md-4 col-sm-6 col-12",
		render: (task) => (task.seeding_container_filled ? "Yes" : "No"),
	},
	{
		label: "label.actual_seeding_end_date",
		accessor: "seeding_end_date",
		classes: "col-lg-3 col-md-4 col-sm-6 col-12",
		render: (task) => (task.seeding_end_date ? Util.utcToLocal(task.seeding_end_date) : "-"),
	},
	{
		label: "label.comments",
		accessor: "comments",
		classes: "col-sm-6 col-12",
		render: (task) => (task.comments ? task.comments : "-"),
	},
];

export const GerminationDetailsView = [
	{
		label: "label.expected_germination_date",
		accessor: "expected_germination_date",
		classes: "col-lg-3 col-md-4 col-sm-6 col-12",
		render: (task) => Util.formatMDY(task.expected_germination_date),
	},
	{
		label: "label.germination_start_date",
		accessor: "germination_start_date",
		classes: "col-lg-3 col-md-4 col-sm-6 col-12",
		render: (task) => (task.germination_start_date ? Util.utcToLocal(task.germination_start_date) : "-"),
	},
	{
		label: "label.actual_germination_end_date",
		accessor: "germination_end_date",
		classes: "col-lg-3 col-md-4 col-sm-6 col-12",
		render: (task) => (task.germination_end_date ? Util.utcToLocal(task.germination_end_date) : "-"),
	},
];
