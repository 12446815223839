import React, { useState } from "react";
import { Button, Row, Col, Form } from "react-bootstrap";
import { connect, useDispatch } from "react-redux";
import { reduxForm, Field, change } from "redux-form";
import { createStructuredSelector } from "reselect";

import {
	getLocationList,
	getLaneToCaseFormFromDate,
	getLaneToCaseFormToDate,
	getLaneToCaseFormLocation,
	getLaneToCaseLocationProducts,
} from "store/selectors/laneCalculatorSelectors";
import FormGroups from "utils/formGroup";
import { laneToCaseFormFields } from "./formHelper";
import {
	fetchGrowLanesLc,
	setLoaderLc,
	setLaneToCaseFormData,
	resetLaneToCase,
	resetProductVarities,
	resetGrowLanes,
} from "store/actions";
import moment from "moment";
import { errorMessage } from "utils/errorMessage";

const LaneToCaseForm = ({
	locationList,
	location,
	productVarietyList,
	handleSubmit,
	fetchGrowLanesLc,
	setLoaderLc,
	resetLaneToCase,
	resetProductVarities,
	resetGrowLanes,
	setLaneToCaseFormData,
	reset,
	submitting,
}) => {
	const dispatch = useDispatch();
	const [startDate, setStartDate] = useState(null);
	const [endDate, setEndDate] = useState(null);
	const [submitStatus, setSubmitStatus] = useState(false);

	const onSubmit = (values) => {
		setLoaderLc();
		resetGrowLanes();
		const submitData = {
			location_slug: values.location_slug.value,
			from: moment(values.date[0]).format("YYYY-MM-DD"),
			to: moment(values.date[1]).format("YYYY-MM-DD"),
			product_varieties: values.product_variety.map((item) => item.value),
		};
		const successHandler = () => {
			setLoaderLc();
			resetProductVarities();
		};
		const errorHandler = (e) => {
			errorMessage(e);
			setLoaderLc();
		};
		fetchGrowLanesLc(submitData, successHandler, errorHandler);

		setLaneToCaseFormData({
			location: values.location_slug,
			fromDate: values.date[0],
			toDate: values.date[1],
			productVarieties: values.product_variety,
		});
	};

	const getOptions = (name) => {
		switch (name) {
			case "location_slug":
				return locationList;

			case "product_variety":
				return productVarietyList;

			default:
				return [];
		}
	};

	const onChange = (dates) => {
		const [start, end] = dates;
		if (dates) {
			setStartDate(start);
			setEndDate(end);
		} else {
			return;
		}
	};
	const onSelect = (field) => {
		if (field === "location_slug") {
			submitStatus && setSubmitStatus(false);
			dispatch(change("laneToCaseForm", "product_variety", []));
		}
	};

	const getFormFieldHtml = (formfields) => {
		let formFieldHtml = [];

		formFieldHtml = formfields.map((field, idx) => {
			return (
				<React.Fragment key={idx}>
					<Col className="forecast-form-column">
						<Form.Group controlId={`formControl ${field.name}`}>
							{field.name === "date" ? (
								<Field
									name={field.name}
									type={field.type}
									label={field.label}
									placeholder={field.placeholder}
									component={FormGroups}
									startDate={startDate}
									endDate={endDate}
									onChange={onChange}
									validate={field.validate}
									dateFormat={"MM-DD-YYYY"}
									pickerTypeSelector={0}
									onBlur={(event) => event.preventDefault()}
								/>
							) : (
								<Field
									name={field.name}
									type={field.type}
									label={field.label}
									placeholder={field.placeholder}
									component={FormGroups}
									validate={field.validate}
									disable={field.name === "product_variety" ? (location ? false : true) : false}
									multiple={field.name === "product_variety" ? true : false}
									options={getOptions(field.name)}
									onChange={(event, newValue, previousValue, name) =>
										onSelect(event, newValue, previousValue, name)
									}
									selectMenuHeight={200}
									checked={true}
								/>
							)}
						</Form.Group>
					</Col>
				</React.Fragment>
			);
		});
		return (
			<Row className="forecast-form-row">
				{formFieldHtml}
				<Col className="form-submit-col">
					<Button type="submit" disabled={submitting} className="apply-btn">
						Apply
					</Button>
					<Button
						className="reset-btn"
						disabled={submitting}
						onClick={() => {
							reset();
							resetLaneToCase();
							setStartDate(null);
							setEndDate(null);
						}}>
						<i className="icon icon-reset mr-2" />
					</Button>
				</Col>
			</Row>
		);
	};

	return (
		<div className="forecast-form-wrapper">
			<Form onSubmit={handleSubmit(onSubmit)} className="forecastForm">
				{getFormFieldHtml(laneToCaseFormFields)}
			</Form>
		</div>
	);
};

const mapStateToProps = createStructuredSelector({
	locationList: getLocationList,
	productVarietyList: getLaneToCaseLocationProducts,
	fromDate: getLaneToCaseFormFromDate,
	toDate: getLaneToCaseFormToDate,
	location: getLaneToCaseFormLocation,
});

export default connect(mapStateToProps, {
	fetchGrowLanesLc,
	setLoaderLc,
	setLaneToCaseFormData,
	resetLaneToCase,
	resetProductVarities,
	resetGrowLanes,
})(reduxForm({ form: "laneToCaseForm", enableReinitialize: true })(LaneToCaseForm));
