import React, { useState } from "react";
import { connect } from "react-redux";

import { createStructuredSelector } from "reselect";
import { Row, Col } from "react-bootstrap";

import { getCasesDate, getCasesLocation, getCasesPerSku } from "store/selectors/ordersPackagingSelectors";
import { errorMessage } from "utils/errorMessage";
import moment from "moment";
import { fetchCasesPerSku } from "store/actions";
import TableLoader from "utils/table/tableLoader";
import { useTranslation } from 'react-i18next';

const CasesPerSkuTable = ({ data, date, location, fetchCasesPerSku }) => {
	const { t } = useTranslation();
	const [searchWord, setsearchWord] = useState("");
	const [enableSearch, setenableSearch] = useState(false);
	const [loading, setloading] = useState(false);
	const [timeout, settimeout] = useState(null);
	const searchSku = (name) => {
		clearTimeout(timeout);
		setloading(true);
		setsearchWord(name);
		settimeout(
			setTimeout(() => {
				const submitData = {
					location_slug: location,
					date: moment(date).format("YYYY-MM-DD"),
					search: name,
				};
				const successHandler = (event) => {
					setloading(false);
				};
				const errorHandler = (event) => {
					setloading(false);
					errorMessage(event);
				};
				fetchCasesPerSku(submitData, successHandler, errorHandler);
			}, 500)
		);
	};
	const totalCases = () => {
		let totalRequired = 0;
			let totalPossible = 0;
		data &&
			data.forEach((item, index) => {
				if (item.required_cases) {
					totalRequired += item.required_cases;
				}
				if (item.possible_cases) {
					totalPossible += item.possible_cases;
				}
			});
		return (
			<Row>
				<Col md={6} className="total_required_cases">
					<div>
						<p className="total_required_cases_title">{t("label.total_required_cases")}</p>
						<p className="total_required_cases_value">{totalRequired}</p>
					</div>
				</Col>
				<Col md={6} className="total_possible_cases">
					<div>
						<p className="total_possible_cases_title">{t("label.total_possible_cases")}</p>
						<p className="total_possible_cases_value">{totalPossible}</p>
					</div>
				</Col>
			</Row>
		);
	};
	const tableHead = () => {
		return (
			<Row className="modal-table-head">
				<Col className="modal-sku-table-col1 cont-wrap ">
					{enableSearch ? (
						<div className="d-flex position-relative">
							<input
								className="form-control sku-input"
								type="text"
								autoFocus
								value={searchWord}
								onChange={(e) => searchSku(e.target.value)}></input>
							<span
								className="search-cancel-btn"
								onClick={() => {
									setenableSearch(false);
									searchSku("");
								}}>
								<i className="icon icon-close text-xs" />
							</span>
						</div>
					) : (
						<div className="d-flex">
							<span>{t("label.sku")}</span>
							<span className="cursor-pointer search-btn" onClick={() => setenableSearch(true)}>
								<i className="icon icon-search" />
							</span>
						</div>
					)}
				</Col>
				<Col className="modal-sku-table-col2 cont-wrap">
					<span>{t("label.required_cases")}</span>
				</Col>
				<Col className="modal-sku-table-col2 cont-wrap">
					<span>{t("label.possible_cases")}</span>
				</Col>
			</Row>
		);
	};

	const tableBody = () => {
		return (
			<div className="modal-table-body position-relative">
				{!loading ? (
					data ? (
						data.map((item, index) => {
							return (
								<Row key={index} className="modal-table-body-row">
									<Col className="modal-sku-table-col1">
										<span>{item.sku_name}</span>
									</Col>

									<Col className={"modal-sku-table-col2 cont-wrap"}>
										<span>{item.required_cases}</span>
									</Col>
									<Col className={"modal-sku-table-col3 cont-wrap"}>
										<span>{item.possible_cases}</span>
									</Col>
								</Row>
							);
						})
					) : (
						<div className="no-results-found text-center">{t("no_result_found")}</div>
					)
				) : (
					<div className="table-loader-wrapper position-relative">
						<TableLoader />
					</div>
				)}
			</div>
		);
	};

	return (
		<div className="modal-table-wrapper">
			{data && data.length > 0 && totalCases()}
			{tableHead()}
			{tableBody()}
		</div>
	);
};

const mapStateToProps = createStructuredSelector({
	data: getCasesPerSku,
	date: getCasesDate,
	location: getCasesLocation,
});

export default connect(mapStateToProps, { fetchCasesPerSku })(CasesPerSkuTable);
