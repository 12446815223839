import React, { useState } from "react";
import { Container, Button, Row, Col, Form } from "react-bootstrap";
import { reduxForm, Field } from "redux-form";
import { connect } from "react-redux";
import FormGroups from "../../utils/formGroup";
import TableLoader from "../../utils/table/tableLoader";
import { required, requiredDateObj } from "constant/formValidators";
import Util from "utils/Util";
import { successMessage } from "utils/successMessage";
import { errorMessage } from "utils/errorMessage";
import { repeatAllTasks } from "store/actions";
import { useTranslation } from "react-i18next";

const RepeatAllForm = ({ reset, form, handleSubmit, initialBody, repeatAllTasks, closeModal, doSuccessHandling }) => {
	const { t } = useTranslation();
	const [isLoading, setIsLoading] = useState(false);
	const submitDetails = (values) => {
		setIsLoading(true);
		const body = {
			...initialBody,
			flag_color: (values && values.flag_color && values.flag_color.value) || "",
			task_date: Util.getYMDFormat(values.task_date),
		};
		const successHandler = (resp) => {
			setIsLoading(false);
			successMessage(resp);
			reset(form);
			closeModal();
			doSuccessHandling();
		};
		const errorHandler = (err) => {
			setIsLoading(false);
			errorMessage(err);
		};
		repeatAllTasks(body, successHandler, errorHandler);
	};
	const options = [
		{ label: "Red-Monday", value: "red" },
		{ label: "Blue - Tuesday", value: "blue" },
		{ label: "Purple - Wednesday", value: "purple" },
		{ label: "White - Thursday", value: "white" },
		{ label: "Green - Friday", value: "green" },
		{ label: "Yellow - Saturday", value: "yellow" },
	];
	return (
		<Form onSubmit={handleSubmit(submitDetails)} className="board-details-form">
			<Container className="position-relative">
				<Row>
					<Col xs={12}>
						<Form.Group controlId="formControl">
							<Field
								name="task_date"
								type="date-picker"
								label={t("label.repeat_on")}
								placeholder={t("label.enter_date")}
								component={FormGroups}
								validate={requiredDateObj}
								minDate={new Date()}
							/>
							<Field
								name="flag_color"
								type="select-react"
								label={t("label.flag_color")}
								placeholder={t("label.enter_flag_color")}
								component={FormGroups}
								validate={required}
								options={options || []}
								multiple={false}
							/>
						</Form.Group>
					</Col>
				</Row>
				{/* Loader */}
				{isLoading && (
					<div className="table-loader-wrapper">
						<TableLoader />
					</div>
				)}
			</Container>
			<div className="modal-footer">
				<Button variant="secondary" onClick={closeModal}>
					{t("label.cancel")}
				</Button>
				<Button variant="primary" type="submit" disabled={isLoading}>
					{isLoading ? t("label.saving") : t("label.save")}
				</Button>
			</div>
		</Form>
	);
};
const mapStateToProps = (state) => ({});
const mapDispatchToProps = { repeatAllTasks };
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(
	reduxForm({
		form: "repeat-all-form",
		enableReinitialize: true,
	})(RepeatAllForm)
);
