import React from "react";
import CustomModal from "utils/modal";
import useModal from "utils/useModal";
import CopyForecastForm from "./copyForecastForm";

const CopyForecast = () => {
	const [isOpen, modalType, , closeModal] = useModal("task");

	return (
		<CustomModal
			show={isOpen}
			onHide={closeModal}
			className={modalType === 8 && "cases-by-customer-modal"}
			title={"Copy Forecast"}>
			<CopyForecastForm />
		</CustomModal>
	);
};

export default CopyForecast;
