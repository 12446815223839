import React, { useState, useCallback } from "react";
import { isEmpty, find, debounce, includes, map, every } from "lodash";
import Checkbox from "../../../utils/checkbox";
import { DropdownButton, Dropdown } from "react-bootstrap";
import { Pagination } from "../../../utils/table/table";
import TableLoader from "../../../utils/table/tableLoader";

const LocationUsers = ({
	isEditable = false,
	data = [],

	// Edit/Add
	query = {},
	rolesArr = [],
	roleKey,
	onChangeQuery,
	onRemoveFilter,
	onChangeCheckbox,
	checkedArr = [],
	onChangeAllCheckbox,
	loadingUsers = false,
}) => {
	const [isNameSearchShow, setIsNameSearchShow] = useState(false);
	const [userQuery, setUserQuery] = useState("");

	// On Handle Search
	const delayedQuery = useCallback(
		debounce((q) => onChangeQuery("name", q, true), 500),
		[]
	);
	const onChangeSearchUser = (e) => {
		setUserQuery(e.target.value);
		delayedQuery(e.target.value);
	};

	// Toggle search open/close | Remove filter on close
	const onHandleSearchClose = () => {
		setIsNameSearchShow(!isNameSearchShow);
		if (isNameSearchShow) {
			setUserQuery("");
			onRemoveFilter("name");
		}
	};

	// Search Name | Input toggle
	const NameSearch = () => {
		return (
			<div className="d-flex align-items-center">
				{isNameSearchShow ? (
					<input
						className="location-users-table-input"
						type="text"
						autoFocus
						placeholder="Search..."
						onChange={onChangeSearchUser}
						value={userQuery}
					/>
				) : (
					"Name"
				)}
				<i
					className={`icon icon-${
						isNameSearchShow ? "close text-sm" : "search ml-3"
					}`}
					onClick={onHandleSearchClose}
				/>
			</div>
		);
	};

	// Role | Dropdown
	const isRoleActive = (roleId) => {
		const found = find(
			query.filter,
			(o) => o.column_name === "role" && o.search === roleId
		);
		return found ? true : false;
	};

	const RoleDropdown = () => (
		<DropdownButton
			variant="none"
			className="location-users-table-drop"
			title="Role"
		>
			<Dropdown.Item
				onClick={() => onRemoveFilter("role")}
				active={isEmpty(query.filter)}
			>
				All
			</Dropdown.Item>
			{!isEmpty(rolesArr) &&
				rolesArr.map((role) => (
					<Dropdown.Item
						key={role.id}
						onClick={() => onChangeQuery("role", role.id, true)}
						active={isRoleActive(role.id)}
					>
						{role.name}
					</Dropdown.Item>
				))}
		</DropdownButton>
	);

	const checkIfAllExistInCheckedArr = () => {
		const dataArr = map(data, "id");
		return (
			(!isEmpty(dataArr) &&
				every(dataArr, (o) => includes(checkedArr, o))) ||
			false
		);
	};

	return (
		<div className="location-users-table">
			<div className="location-users-table-head">
				{isEditable && (
					<div className="location-users-table-cell cell-checkbox">
						<Checkbox
							checked={checkIfAllExistInCheckedArr()}
							onChange={(e) => onChangeAllCheckbox(e, data)}
						/>
					</div>
				)}
				<div className="location-users-table-cell cell-name">
					{isEditable ? <NameSearch /> : "Name"}
				</div>
				<div className="location-users-table-cell cell-role">
					{isEditable ? <RoleDropdown /> : "Role"}
				</div>
			</div>

			{loadingUsers ? (
				<TableLoader />
			) : !isEmpty(data) ? (
				data.map((item) => (
					<div className="location-users-table-body" key={item.id}>
						{isEditable && (
							<div className="location-users-table-cell cell-checkbox">
								<Checkbox
									checked={includes(checkedArr, item.id)}
									onChange={(e) =>
										onChangeCheckbox(e, item.id)
									}
								/>
							</div>
						)}
						<div className="location-users-table-cell cell-name">
							{item.name}
						</div>
						<div className="location-users-table-cell cell-role">
							{item[roleKey] || item.role}
						</div>
					</div>
				))
			) : (
				<div className="location-users-table-body">No results</div>
			)}

			<br />
			<Pagination
				total={query.total || 0}
				count={query.per_page || 1}
				page={query.page}
				hidePaginationOne
				hidePaginationTwo
				onChange={(currentPage) => onChangeQuery("page", currentPage)}
			/>
			<br />
		</div>
	);
};

export default LocationUsers;
