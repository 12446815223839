import { WSR } from "store/types";
import fetchHandler from "utils/fetchHandler";

export const setLoaderWSR = () => {
	return (dispatch) => {
		dispatch({
			type: WSR.LOADER_WSR,
		});
	};
};

export const fetchDropdownDataWSR = (successHandler, errorHandler) => {
	const fetchOptions = {
		url: `get-watersource-dropdown`,
		method: "GET",
		secure: true,
		actionType: WSR.SET_DROPDOWN_DATA_WSR,
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const setRenderTypeWSR = (value) => {
	return (dispatch) => {
		dispatch({
			type: WSR.SET_RENDER_TYPE_WSR,
			payload: value,
		});
	};
};

export const setColumnsWSR = (values, successHandler, errorHandler) => {
	return (dispatch) => {
		dispatch({
			type: WSR.SET_COLUMNS_WSR,
			payload: values,
		});
	};
};

export const setDataWSR = (values, successHandler, errorHandler) => {
	return (dispatch) => {
		dispatch({
			type: WSR.SET_DATA_WSR,
			payload: values,
		});
	};
};

export const resetRefreshWSR = () => {
	return (dispatch) => {
		dispatch({
			type: WSR.REFRESH_LISTING_WSR,
		});
	};
};

export const addDataWSR = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `add-water-source-result`,
		method: "POST",
		secure: true,
		body: JSON.stringify(values),
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const fetchDataWSR = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `list-water-source-results`,
		method: "POST",
		secure: true,
		body: JSON.stringify(values),
		actionType: WSR.SET_LIST_DATA_WSR,
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const editDataWSR = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `edit-water-source-results`,
		method: "PUT",
		secure: true,
		body: JSON.stringify(values),
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const deleteDataWSR = (slug, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `delete-water-source-results/${slug}`,
		method: "DELETE",
		secure: true,
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const uploadWSR = (value, successHandler, errorHandler, options) => {
	const fetchOptions = {
		url: `upload-water-source-result`,
		method: "POST",
		secure: true,
		fileUpload: true,
		body: value,
	};
	const type = "axios";
	return fetchHandler(fetchOptions, successHandler, errorHandler, type, options);
};
