import { SEEDING_GERMINATION } from "../types";

const initialState = {
	sgTaskDetails: {},
	locationBasedDetails: {},
	listingPageLoader: false,
	viewPageLoader: false,
	seedingGerminationList: [],
	sgMediaUsageList: [],
	sgCleaningTimeList: [],
	sgSetUpTimeList: [],
	selectedLocation: null,
};

export default function (state = initialState, { type, payload }) {
	switch (type) {
		case SEEDING_GERMINATION.GET_SEEDING_GERMINATION_TASKS_LIST:
			return {
				...state,
				seedingGerminationList: payload.data || [],
			};
		case SEEDING_GERMINATION.GET_TASK_DROPDOWN_DETAILS:
			return {
				...state,
				locationBasedDetails: payload.data || {},
			};
		case SEEDING_GERMINATION.GET_SEEDING_GERMINATION_TASK_DETAILS:
			return {
				...state,
				sgTaskDetails: payload.data || {},
			};

		case SEEDING_GERMINATION.CLEAR_SEEDING_GERMINATION_STATES:
			return {
				...state,
				sgTaskDetails: {},
			};
		case SEEDING_GERMINATION.TOGGLE_LIST_LOADER:
			return {
				...state,
				listingPageLoader: payload,
			};
		case SEEDING_GERMINATION.TOGGLE_VIEW_LOADER:
			return {
				...state,
				viewPageLoader: payload,
			};
		case SEEDING_GERMINATION.GET_SEEDING_GERMINATION_TASK_AUDIT_LOG:
			return {
				...state,
				acitivityLog: payload.data || [],
			};
		case SEEDING_GERMINATION.SELECTED_LOCATION:
			return {
				...state,
				selectedLocation: payload,
			};
		case SEEDING_GERMINATION.GET_SEEDING_GERMINATION_MEDIA_USAGE_LIST:
			return {
				...state,
				sgMediaUsageList: payload.data || [],
			};
		case SEEDING_GERMINATION.GET_SEEDING_GERMINATION_CLEANING_TIME_LIST:
			return {
				...state,
				sgCleaningTimeList: payload.data || [],
			};
		case SEEDING_GERMINATION.GET_SEEDING_GERMINATION_SETUP_TIME_LIST:
			return {
				...state,
				sgSetUpTimeList: payload.data || [],
			};
		default:
			return state;
	}
}
