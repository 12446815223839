export const routingArrayMapper = [
	{
		"User Management": "user",
		"Product Management": "product",
		"Task Management": "task",
		"Stock Keeping Unit": "stock",
		"Customer Management": "customer",
		Seeding: "seeding",
		Harvesting: "harvesting",
		Seeds: "seeds",
		"Product Variety": "product_variety",
		"Access Privilages": "access_previlages",
		Locations: "locations",
		Greens: "greens",
		Styrofoams: "styrofoams",
		"Transplant and Growing task Management": "transplant",
		"Dashboard & Report": "reports",
	},
];

export const colorArray = [
	"#00aeef",
	"#6bcff6",
	"#cedc28",
	"#fff101",
	"#f89b6c",
	"#16c172",
	"#b5e4b2",
	"#9ccc78",
	"#aefca9",
	"#a1e8cc",
	"#16c172",
	"#b5e4b2",
	"#9ccc78",
	"#aefca9",
	"#a1e8cc",
	"#ed4a6a",
	"#feea37",
	"#05b0d1",
	"#ef558b",
	"#1a685c",
	"#63274a",
	"#cedc29",
	"#864a96",
	"#f8a938",
	"#ed3e37",
	"#5cbb4e",
	"#dd6832",
	"#b6477f",
	"#b991c2",
	"#3fa550",
	"#9d57a3",
	"#9b57a3",
	"00A3DA",
	"#D8127D",
	"#FFF101",
	"#1A1919",
	"#00A6BE",
	"#71C9E6",
	"#D91A62",
	"#005E51",
	"#55283F",
	"#CAD832",
	"#B34C37",
	"#EC956A",
	"#A4276E",
	"#EDA22D",
	"#5CB24D",
	"#DA3736",
	"#00A2BF",
	"#007A40",
	"#AF88B2",
	"#ACDCEC",
	"#8FD1E9",
	"#00B8E0",
	"#EC965F",
	"#783882",
	"#915290",
	"#DF552C",
	"#CB8589",
	"#454ADE",
	"#1B1F3B",
	"#2EC4B6",
	"#CBF3F0",
	"#DB6C79",
	"#4E3822",
	"#2F1B25",
	"#519872",
	"#721121",
	"#A5402D",
	"#F15156",
	"#23395B",
	"#F8F32B",
	"#F9E900",
	"#ED33B9",
	"#29E7CD",
	"#AF3B6E",
	"#424651",
	"#99D19C",
	"#F4989C",
	"#FAA613",
	"#5BC0BE",
	"#F3DFC1",
	"#BFD7B5",
	"#006DAA",
	"#C9B79C",
	"#8AEA92",
	"#94778B",
	"#F24236",
	"#B9314F",
	"#C7D6D5",
	"#1C1F33",
	"#E6F9AF",
	"#F9D4BB",
	"#C6C013",
	"#C6AC8F",
	"#998FC7",
	"#285d6f",
	"#b80063",
	"#5f5a02",
	"#3d0202",
	"#46976a",
	"#fa9563",
	"#e942f7",
	"#47b1a2",
	"#801f50",
	"#3b4103",
	"#325bd8",
	"#ec6ace",
	"#ff7075",
	"#0579a0",
	"#a56387",
	"#646011",
	"#4d4b4b",
	"#112629",
	"#96b2bb",
	"#8d3c5b",
	"#5b7e79",
	"#55283F",
	"#737f03",
	"#c93719",
	"#fd5400",
	"#f32b9d",
	"#3a6g86",
	"#46b333",
	"#d63973",
	"#000fbf",
	"#007a78",
	"#77377c",
	"#ffe350",
	"#00beae",
];

export const pieChartColorArray = [
	"#ffbd58",
	"#7b9d77",
	"#3c641c",
	"#45b8c2",
	"#6681ba",
	"#45b8c2",
	"#3c641c",
	"#f27a70",
	"#e698a6",
	"#c79dcb",
	"#00aeef",
	"#6bcff6",
	"#cedc28",
	"#fff101",
	"#f89b6c",
	"#16c172",
	"#b5e4b2",
	"#9ccc78",
	"#aefca9",
	"#a1e8cc",
	"#16c172",
	"#b5e4b2",
	"#9ccc78",
	"#aefca9",
	"#a1e8cc",
	"#ed4a6a",
	"#feea37",
	"#05b0d1",
	"#ef558b",
	"#1a685c",
	"#63274a",
	"#cedc29",
	"#864a96",
	"#f8a938",
	"#ed3e37",
	"#5cbb4e",
	"#dd6832",
	"#b6477f",
	"#b991c2",
	"#3fa550",
	"#9d57a3",
	"#9b57a3",
];
export const areaChartColorArray = ["#64bd56", "#cedc2f"];

export const fullChartColorsList = [
	"00A3DA",
	"#D8127D",
	"#FFF101",
	"#1A1919",
	"#00A6BE",
	"#71C9E6",
	"#D91A62",
	"#005E51",
	"#55283F",
	"#CAD832",
	"#B34C37",
	"#EC956A",
	"#A4276E",
	"#EDA22D",
	"#5CB24D",
	"#DA3736",
	"#00A2BF",
	"#007A40",
	"#AF88B2",
	"#ACDCEC",
	"#8FD1E9",
	"#00B8E0",
	"#EC965F",
	"#783882",
	"#915290",
	"#DF552C",
	"#CB8589",
	"#454ADE",
	"#1B1F3B",
	"#2EC4B6",
	"#CBF3F0",
	"#DB6C79",
	"#4E3822",
	"#2F1B25",
	"#519872",
	"#721121",
	"#A5402D",
	"#F15156",
	"#23395B",
	"#F8F32B",
	"#F9E900",
	"#ED33B9",
	"#29E7CD",
	"#AF3B6E",
	"#424651",
	"#99D19C",
	"#F4989C",
	"#FAA613",
	"#5BC0BE",
	"#F3DFC1",
	"#BFD7B5",
	"#006DAA",
	"#C9B79C",
	"#8AEA92",
	"#94778B",
	"#F24236",
	"#B9314F",
	"#C7D6D5",
	"#1C1F33",
	"#E6F9AF",
	"#F9D4BB",
	"#C6C013",
	"#C6AC8F",
	"#998FC7",
	"#285d6f",
	"#b80063",
	"#5f5a02",
	"#3d0202",
	"#46976a",
	"#fa9563",
	"#e942f7",
	"#47b1a2",
	"#801f50",
	"#3b4103",
	"#325bd8",
	"#ec6ace",
	"#ff7075",
	"#0579a0",
	"#a56387",
	"#646011",
	"#4d4b4b",
	"#112629",
	"#96b2bb",
	"#8d3c5b",
	"#5b7e79",
	"#55283F",
	"#737f03",
	"#c93719",
	"#fd5400",
	"#f32b9d",
	"#3a6g86",
	"#46b333",
	"#d63973",
	"#000fbf",
	"#007a78",
	"#77377c",
	"#ffe350",
	"#00beae",
];
