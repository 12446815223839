export const dateFormat = "MM/DD/YYYY";
export const datePickerDateFormat = "MM/dd/yyyy";

export const userRoles = [
	{
		label: "Admin",
		value: 1,
	},
	{
		label: "Corporate Managers",
		value: 2,
	},
	{
		label: "General Manager",
		value: 3,
	},
	{
		label: "Production Manager",
		value: 4,
	},
	{
		label: "Head Grower",
		value: 5,
	},
	{
		label: "Admin Manager",
		value: 6,
	},
	{
		label: "Maintenance Manager",
		value: 7,
	},
	{
		label: "Seeding Employees  ",
		value: 8,
	},
	{
		label: "Transplant Employees",
		value: 9,
	},
	{
		label: "Harvesting Lead",
		value: 10,
	},
	{
		label: "Harvesting Employees",
		value: 11,
	},
	{
		label: "Packaging Lead",
		value: 12,
	},
	{
		label: "Packaging Employee",
		value: 13,
	},
	{
		label: "Sales Account Manager",
		value: 14,
	},
];

export const flagColors = [
	{ value: "red", label: "Red - Monday" },
	{ value: "blue", label: "Blue - Tuesday" },
	{ value: "purple", label: "Purple - Wednesday" },
	{ value: "white", label: "White - Thursday" },
	{ value: "green", label: "Green - Friday" },
	{ value: "yellow", label: "Yellow - Saturday" },
];

export const topCoatOptions = [
	{ value: true, label: "Yes" },
	{ value: false, label: "No" },
];

export const drumSizes = [
	{ value: "7.8.7", label: "7.8.7" },
	{ value: "12", label: "12" },
	{ value: "16", label: "16" },
];

export const batchTypes = [
	{ value: "trial", label: "Trial" },
	{ value: "production", label: "Production" },
];

export const containerFilled = [
	{ value: true, label: "Yes" },
	{ value: false, label: "No" },
];

export const rooms = [
	{ value: "Seeding room", label: "Seeding room" },
	{ value: "Germination room", label: "Germination room" },
];

export const taskStatus = [
	{ value: "New", label: "New" },
	{ value: "Seeding", label: "Seeding" },
	{ value: "Germination", label: "Germination" },
	{ value: "Completed", label: "Completed" },
];

export const transplantTaskStatus = [
	{ value: "New", label: "New" },
	{ value: "In Progress", label: "In Progress" },
	{ value: "Completed", label: "Completed" },
];
export const crossSupplyTaskStatus = [
	{ value: "Requested", label: "Requested" },
	{ value: "Partially Accepted", label: "Partially Accepted" },
	{ value: "Accepted", label: "Accepted" },
	{ value: "Shipped", label: "Shipped" },
	{ value: "Delivered", label: "Delivered" },
];
export const loggedInUserRole = (value) => {
	return userRoles.find((item) => item.value === value);
};

export const flagColors_2 = {
	red: { value: "red", label: "Red - Monday" },
	blue: { value: "blue", label: "Blue - Tuesday" },
	purple: { value: "purple", label: "Purple - Wednesday" },
	white: { value: "white", label: "White - Thursday" },
	green: { value: "green", label: "Green - Friday" },
	yellow: { value: "yellow", label: "Yellow - Saturday" },
};

export const ordersPackagingStatus = [
	{
		label: "New",
		value: "New",
	},
	{
		label: "In Progress",
		value: "In Progress",
	},
	{
		label: "Completed",
		value: "Completed",
	},
	{
		label: "Pending",
		value: "Pending",
	},
];

export const taskStatusGermination = [
	{ value: "Germination", label: "Germination" },
	{ value: "Completed", label: "Completed" },
];
