import { isEmpty, find } from "lodash";
import { number, required, requiredDateObj, isDateObj, minValue } from "../../../constant/formValidators";
import { flagColors, topCoatOptions, drumSizes, batchTypes, containerFilled } from "../../../constant/global";
import i18next from "i18next";

const dropdownArrays = {
	flagColors,
	topCoatOptions,
	drumSizes,
	batchTypes,
	containerFilled,
};

export const getDropdownObj = (arrKey, value) => find(dropdownArrays[arrKey], (o) => o.value === value);

// Transplant Date validation
const checkTransplantDate = (value, values) => {
	const s = (values && values.expected_seeding_date) || null;
	const g = (values && values.expected_germination_date) || null;
	const t = value || null;
	if (s && t && s > t) {
		return "Date invalid";
	}
	if (g && t && g > t) {
		return "Date invalid";
	}
	return undefined;
};

// Germination date validation
const checkGerminationDate = (value, values) => {
	const s = (values && values.expected_seeding_date) || null;
	const g = value || null;
	if (s && g && s > g) {
		return "Date invalid";
	}
	return undefined;
};

const isPastDate = (value) => (value && value < new Date().setHours(0, 0, 0, 0) ? "Date invalid" : undefined);

export const formCreationFields = (
	locationsList = [],
	locationProductsList = [],
	assigneeList = [],
	minDateForGermination,
	minDateForTransplant,
	isEdit
) => [
	{
		label: i18next.t("label.location_required"),
		name: "location_slug",
		type: "select-react",
		placeholder: i18next.t("label.select_location"),
		options: locationsList,
		validate: [required],
	},
	{
		label: i18next.t("label.product_variety_required"),
		name: "product_variety_slug",
		type: "select-react",
		placeholder: i18next.t("label.select_product_variety"),
		options: locationProductsList,
		disabled: isEmpty(locationProductsList),
		validate: [required],
	},
	{
		label: i18next.t("label.no_of_boards_required"),
		name: "board_count",
		type: "text",
		placeholder: i18next.t("label.enter_number_of_boards"),
		validate: [required, number, minValue(1)],
	},
	{
		label: i18next.t("label.flag_color_required"),
		name: "flag_color",
		type: "select-react",
		placeholder: i18next.t("label.enter_flag_color"),
		options: flagColors,
		validate: [required],
	},
	{
		label: i18next.t("label.top_coat_required"),
		name: "top_coat",
		type: "select-react",
		placeholder: i18next.t("label.select_coat"),
		options: topCoatOptions,
		validate: [required],
	},
	{
		label: i18next.t("label.drum_size_required"),
		name: "drum_size",
		type: "select-react",
		placeholder: i18next.t("label.select_drum_size"),
		options: drumSizes,
		validate: [required],
	},
	{
		label: i18next.t("label.seed_density_required"),
		name: "seed_density",
		type: "text",
		placeholder: i18next.t("label.seeding_enter_seed_density"),
		validate: [required, number],
	},
	{
		label: i18next.t("label.expected_seeding_date_required"),
		name: "expected_seeding_date",
		type: "date-picker",
		placeholder: i18next.t("label.enter_seeding_date"),
		validate: isEdit ? [requiredDateObj] : [requiredDateObj, isPastDate],
		minDate: new Date(),
	},
	{
		label: i18next.t("label.expected_germination_date"),
		name: "expected_germination_date",
		type: "date-picker",
		placeholder: i18next.t("label.enter_germination_date"),
		validate: isEdit ? [isDateObj, checkGerminationDate] : [isDateObj, checkGerminationDate, isPastDate],
		minDate: minDateForGermination,
	},
	{
		label: i18next.t("label.expected_transplant_date"),
		name: "expected_transplant_date",
		type: "date-picker",
		placeholder: i18next.t("label.enter_transplant_date"),
		validate: isEdit ? [isDateObj, checkTransplantDate] : [isDateObj, checkTransplantDate, isPastDate],
		minDate: minDateForTransplant,
	},
	{
		label: i18next.t("label.assignee"),
		name: "assignee_id",
		type: "select-react",
		placeholder: i18next.t("label.select_assignee"),
		options: assigneeList,
		validate: [],
		disabled: isEmpty(assigneeList),
		maxMenuHeight: 210,
	},
	{
		label: i18next.t("label.batch_type_required"),
		name: "task_type",
		type: "select-react",
		placeholder: i18next.t("label.select_batch_type"),
		options: batchTypes,
		validate: [required],
	},
	{
		label: i18next.t("label.task_description"),
		name: "description",
		type: "textarea",
		placeholder: i18next.t("label.enter_description"),
		validate: [],
	},
];
