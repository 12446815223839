import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { Button, Row, Col, Form, Container } from "react-bootstrap";
import FormGroups from "utils/formGroup";
import { reduxForm, Field, reset, FormSection, change } from "redux-form";
import useModal from "utils/useModal";
import { ButtonLink } from "../../../../elements";
import { findKey } from "lodash";
import {
	EmployeeForm,
	Rooms,
	PackRoomForm,
	CleaningTimeForm,
	SetUpTimeForm,
	PackagingWaste,
	PackagingWasteDropdown,
	WastageReasonsList,
	PackRateForm,
	productTemperatureForm,
	productTemperatureBottom,
	RoomSelect,
} from "./formHelper";
import TableLoader from "utils/table/tableLoader";
import { errorMessage } from "utils/errorMessage";
import { successMessage } from "utils/successMessage";
import {
	logEmployeeCount,
	logPackRoomPreCool,
	insertCleaningTime,
	insertSetUpTime,
	getCleaningTime,
	getSetUpTimeOrders,
	loadLocationSkus,
	selectLocation,
	logPackagingWaste,
	fetchLocationProductVarities,
	getDailyTasksDetails,
	resetListing,
	editPackagingWaste,
	addPackRate,
	getPackRate,
	fetchProductVarieties,
	addProductTemperature,
	getProductTemperatureDetails,
	updateProductTemperatureDetails,
} from "store/actions";
import moment from "moment";
import {
	getLocationSkus,
	getLocationList,
	getLocationProducts,
	getDailyTaskFormDate,
	getDailyTaskFormRoom,
	getDailyTaskFormPackagingType,
	getDailyTaskFormLocation,
	getDailyTaskInitials,
	getLocationSelected,
	getDailyTaskFormUser,
	getLocationProductVarieties,
	getProductVariety,
} from "store/selectors/ordersPackagingSelectors";
import { required, number, minValueBoard } from "constant/formValidators";
import { createStructuredSelector } from "reselect";
import { isEmpty, isEqual } from "lodash";
import Util from "utils/Util";

import { useTranslation } from "react-i18next";

const DailyTasksForm = ({
	handleSubmit,
	locationList,
	form,
	logEmployeeCount,
	initialValues,
	logPackRoomPreCool,
	insertCleaningTime,
	insertSetUpTime,
	getCleaningTime,
	getSetUpTimeOrders,
	loadLocationSkus,
	selectLocation,
	skuList,
	productVarietyList,
	logPackagingWaste,
	fetchLocationProductVarities,
	formDate,
	formPackagingType,
	formLocation,
	formRoom,
	getDailyTasksDetails,
	resetListing,
	globalLocation,
	userDetails,
	editData,
	setRefetch,
	editPackagingWaste,
	addPackRate,
	getPackRate,
	fetchProductVarieties,
	productVariety,
	addProductTemperature,
	getProductTemperatureDetails,
	updateProductTemperatureDetails,
	productDropDown,
}) => {
	const { t } = useTranslation();
	const [btnTitle, setbtnTitle] = useState(t("label.save"));
	const [loading, setloading] = useState(false);
	const [, modalType, , closeModal] = useModal("task");
	const [date, setdate] = useState(moment().format("YYYY-MM-DD"));
	const [startTime, setStartTime] = useState(new Date());
	const [endTime, setEndTime] = useState(new Date(startTime.getTime() + 15 * 60000));
	const [poundsPacked, setPoundsPacked] = useState(0);
	const [casesPacked, setCasesPacked] = useState(0);
	const [noOfEmps, setNoOfEmps] = useState(0);

	const [temperatureDetailsList, settemperatureDetailsList] = useState(
		// initialValues && initialValues.transplantMapArray ? initialValues.transplantMapArray :
		[{ key: 0, flag: "add" }]
	);
	// const [location, setlocation] = useState("");
	const [itemSelected, setitemSelected] = useState([]);
	const [packagingType, setpackagingType] = useState(initialValues.type ? initialValues.type : null);
	const dispatch = useDispatch();

	useEffect(() => {
		if (modalType === 3 || modalType === 4 || modalType === 9) {
			if (formLocation && date) {
				setloading(true);
				checkTime();
			}
		}
		let submitData = {};
		if (modalType === 10) {
			if (!editData) {
				if (formDate && formLocation) {
					setloading(true);
					submitData = {
						date: moment(formDate).format("YYYY-MM-DD"),
						location_slug: formLocation,
					};
					const successHandler = (e) => {
						if (!isEmpty(e.data)) {
							const transplantMetrics = e.data && e.data.temperature_details ? e.data.temperature_details : [];
							const tempObj = [];
							transplantMetrics.forEach(function (item, index) {
								const obj = {
									key: index,
									flag: "add",
								};
								tempObj.push(obj);
							});
							settemperatureDetailsList(tempObj);
							transplantMetrics.forEach(function (data, keys) {
								let room = {};
								if (data.room_type === "Pack Room") {
									room = {
										label: "Pack Room",
										value: "Pack Room",
									};
								} else if (data.room_type === "Precool") {
									room = {
										label: "Pre Cool",
										value: "Precool",
									};
								}
								const product_variety = {
									label: data.product_variety.product_variety_name,
									value: data.product_variety.slug,
								};

								const temperatureDetails = {
									humidity: data.humidity,
									temperature: data.temperature,
									product_variety_slug: product_variety,
									rooms: room,
								};
								dispatch(change("dailyTaskOrders", "edit", true));
								dispatch(change("dailyTaskOrders", "user", e.data.created_by.name));
								dispatch(change("dailyTaskOrders", "lm_" + keys, temperatureDetails));
							});
						} else {
							dispatch(change("dailyTaskOrders", "edit", null));
							dispatch(change("dailyTaskOrders", "user", null));
							temperatureDetailsList &&
								temperatureDetailsList.forEach(function (item, index) {
									dispatch(change("dailyTaskOrders", "lm_" + index));
								});
							settemperatureDetailsList([{ key: 0, flag: "add" }]);
						}
						setloading(false);
					};
					getProductTemperatureDetails(submitData, successHandler, onError);
				}
			} else {
				setloading(true);
				submitData = {
					date: moment(editData.date).format("YYYY-MM-DD"),
					location_slug: editData.location_slug.value,
				};
				const successHandler = (e) => {
					if (!isEmpty(e.data)) {
						const transplantMetrics = e.data && e.data.temperature_details ? e.data.temperature_details : [];
						const tempObj = [];
						transplantMetrics &&
							transplantMetrics.forEach(function (item, index) {
								const obj = {
									key: index,
									flag: "add",
								};
								tempObj.push(obj);
							});
						settemperatureDetailsList(tempObj);
						transplantMetrics &&
							transplantMetrics.forEach(function (data, keys) {
								let room = {};
								if (data.room_type === "Pack Room") {
									room = {
										label: "Pack Room",
										value: "Pack Room",
									};
								} else if (data.room_type === "Precool") {
									room = {
										label: "Pre Cool",
										value: "Precool",
									};
								}
								const product_variety = {
									label: data.product_variety.product_variety_name,
									value: data.product_variety.slug,
								};
								const temperatureDetails = {
									humidity: data.humidity,
									temperature: data.temperature,
									product_variety_slug: product_variety,
									rooms: room,
								};
								dispatch(change("dailyTaskOrders", "user", e.data.created_by.name));
								dispatch(change("dailyTaskOrders", "lm_" + keys, temperatureDetails));
							});
					} else {
						dispatch(change("dailyTaskOrders", "user", null));
						temperatureDetailsList &&
							temperatureDetailsList.forEach(function (item, index) {
								dispatch(change("dailyTaskOrders", "lm_" + index));
							});
						settemperatureDetailsList([{ key: 0, flag: "add" }]);
					}
					setloading(false);
				};
				const submitDataProduct = {
					locations: [editData.location_slug.value],
				};
				fetchProductVarieties(submitDataProduct);
				getProductTemperatureDetails(submitData, successHandler, onError);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [formLocation, date, formDate]);

	// Detect furthur changes in time fields and recalculate cases/hr & lbs/hr value
	useEffect(() => {
		startTime.setSeconds(0);
		startTime.setMilliseconds(0);
		endTime.setSeconds(0);
		endTime.setMilliseconds(0);
		dispatch(change("dailyTaskOrders", "cases_per_lab_hour", "0"));
		dispatch(change("dailyTaskOrders", "lbs_per_lab_hour", "0"));
		const startTimeMm = startTime && startTime.getTime();
		const endTimeMm = endTime && endTime.getTime();

		if (endTimeMm < startTimeMm) {
			setEndTime(new Date(startTime.getTime() + 15 * 60000));
			dispatch(change("dailyTaskOrders", "end_time", ""));
		} else {
			const diff = endTimeMm - startTimeMm;
			const diffHours = diff / (1000 * 60 * 60);

			if (noOfEmps > 0 && diffHours > 0) {
				const lbsPerHour = poundsPacked / (diffHours * Number(noOfEmps));
				const casesPerHour = casesPacked / (diffHours * Number(noOfEmps));

				dispatch(change("dailyTaskOrders", "cases_per_lab_hour", casesPerHour.toFixed(4)));
				dispatch(change("dailyTaskOrders", "lbs_per_lab_hour", lbsPerHour.toFixed(4)));
			}
		}
	}, [dispatch, startTime, endTime, poundsPacked, casesPacked, noOfEmps]);

	const setPackRateTime = (time, key) => {
		const arr = time.split(":");
		const hour = parseInt(arr[0]);
		const min = parseInt(arr[1]);
		const sec = parseInt(arr[2]);

		if (key === "start") {
			setStartTime(new Date(startTime.setHours(hour, min, sec)));
		} else {
			setEndTime(new Date(startTime.setHours(hour, min, sec)));
		}
	};

	//set pack rate variables in edit
	useEffect(() => {
		if (editData && modalType === 9) {
			setCasesPacked(editData.total_cases_packed);
			setNoOfEmps(editData.number_of_employees);
			setPoundsPacked(editData.total_pounds_packed);
			setPackRateTime(editData.start_time, "start");
			setPackRateTime(editData.end_time, "end");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [modalType, editData]);

	useEffect(() => {
		let submitData = {};
		if (!editData) {
			switch (modalType) {
				case 1:
					if (formDate && formLocation) {
						setloading(true);
						submitData = {
							date: moment(formDate).format("YYYY-MM-DD"),
							location_slug: formLocation,
						};
						const successHandler = (e) => {
							if (!isEmpty(e.data)) {
								dispatch(change("dailyTaskOrders", "employee_count", e.data.employee_count));
								dispatch(change("dailyTaskOrders", "user", e.data.created_by.name));
							} else {
								dispatch(change("dailyTaskOrders", "employee_count", ""));
								dispatch(change("dailyTaskOrders", "user", null));
							}

							setloading(false);
						};
						getDailyTasksDetails(submitData, "employeeCount", successHandler, onError);
					}
					break;

				case 2:
					if (formDate && formLocation && formRoom) {
						setloading(true);
						submitData = {
							date: moment(formDate).format("YYYY-MM-DD"),
							location_slug: formLocation,
							room: formRoom,
						};
						const successHandler = (e) => {
							if (!isEmpty(e.data)) {
								dispatch(change("dailyTaskOrders", "humidity", e.data.humidity));
								dispatch(change("dailyTaskOrders", "temperature", e.data.temperature));
								dispatch(change("dailyTaskOrders", "user", e.data.created_by.name));
							} else {
								dispatch(change("dailyTaskOrders", "humidity", ""));
								dispatch(change("dailyTaskOrders", "temperature", ""));
								dispatch(change("dailyTaskOrders", "user", null));
							}
							setloading(false);
						};
						getDailyTasksDetails(submitData, "packroomPrecool", successHandler, onError);
					}
					break;

				case 5:
					if (formDate && formLocation && formPackagingType) {
						setloading(true);
						submitData = {
							date: moment(formDate).format("YYYY-MM-DD"),
							location_slug: formLocation,
							type: formPackagingType,
						};
						const successHandler = (e) => {
							if (!isEmpty(e.data)) {
								const items = {};
								const arr = e.data.wastage_details.map((item) => {
									items[item.slug] = item.quantity;
									items[`${item.slug}_reason`] = {
										label: item.reason,
										value: item.reason,
										isDisabled: undefined,
									};

									return {
										label: item.label,
										value: item.slug,
										isDisabled: undefined,
									};
								});
								dispatch(change("dailyTaskOrders", `items`, items));
								setitemSelected(arr);
								dispatch(
									change("dailyTaskOrders", formPackagingType === "ByCase" ? "sku" : "product_variety", arr)
								);
								dispatch(change("dailyTaskOrders", "user", e.data.created_by.name));
							} else {
								dispatch(
									change("dailyTaskOrders", formPackagingType === "ByCase" ? "sku" : "product_variety", "")
								);
								dispatch(change("dailyTaskOrders", "reason", ""));
								setitemSelected([]);
								dispatch(change("dailyTaskOrders", "user", null));
							}
							setloading(false);
						};
						getDailyTasksDetails(submitData, "packageWaste", successHandler, onError);
					}
					break;
				default:
					return;
			}
		} else {
			if (modalType === 2) {
				setloading(true);
				submitData = {
					room: editData.room.value,
					location_slug: editData.location_slug.value,
					date: moment(editData.date).format("YYYY-MM-DD"),
				};
				const successHandler = (e) => {
					if (!isEmpty(e.data)) {
						dispatch(change("dailyTaskOrders", "humidity", e.data.humidity));
						dispatch(change("dailyTaskOrders", "temperature", e.data.temperature));
						dispatch(change("dailyTaskOrders", "user", e.data.created_by.name));
					} else {
						dispatch(change("dailyTaskOrders", "humidity", ""));
						dispatch(change("dailyTaskOrders", "temperature", ""));
						dispatch(change("dailyTaskOrders", "user", null));
					}
					setloading(false);
				};
				getDailyTasksDetails(submitData, "packroomPrecool", successHandler, onError);
			}
			if (modalType === 5) {
				setitemSelected(editData.type.value === "ByCase" ? editData.sku : editData.product_variety);
			}
		}
	}, [
		formDate,
		formLocation,
		formRoom,
		formPackagingType,
		modalType,
		getDailyTasksDetails,
		dispatch,
		editData,
		getProductTemperatureDetails,
	]);

	const onSuccess = () => {
		setloading(false);
	};
	const onError = (e) => {
		setloading(false);
		errorMessage(e);
	};

	useEffect(() => {
		if (globalLocation) {
			selectLocation(globalLocation);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [globalLocation]);

	useEffect(() => {
		return () => {
			selectLocation(null);
		};
	}, [selectLocation]);

	useEffect(() => {
		if (modalType === 5) {
			setloading(true);
			const loadFn = async () => {
				await loadLocationSkus(() => fetchLocationProductVarities(onSuccess, onError));
			};
			loadFn();
		}
	}, [fetchLocationProductVarities, loadLocationSkus, modalType]);

	const checkTime = () => {
		const obj =
			modalType === 4
				? {
						cleaning_date: date,
						cleaning_type: "orders_packaging",
						room: "",
						location_slug: formLocation,
				  }
				: modalType === 3
				? {
						set_up_date: date,
						room: "",
						set_up_type: "orders_packaging",
						location_slug: formLocation,
				  }
				: editData
				? {
						location_slug: editData.location_slug.value,
						packed_date: moment(editData.date).format("YYYY-MM-DD"),
				  }
				: {
						location_slug: formLocation,
						packed_date: date,
				  };
		const successHandler = (e) => {
			if (!isEmpty(e.data)) {
				if (modalType === 4) {
					dispatch(change("dailyTaskOrders", "user", { name: e.data.user, value: e.data.cleaning_time_in_minutes }));
					dispatch(change("dailyTaskOrders", "cleaning_time_in_minutes", e.data.cleaning_time_in_minutes));
				} else if (modalType === 3) {
					dispatch(change("dailyTaskOrders", "user", { name: e.data.user, value: e.data.set_up_time_in_minutes }));
					dispatch(change("dailyTaskOrders", "set_up_time_in_minutes", e.data.set_up_time_in_minutes));
				} else {
					setCasesPacked(e.data[0].total_cases_packed);
					setNoOfEmps(e.data[0].number_of_employees);
					setPoundsPacked(e.data[0].total_pounds_packed);
					setPackRateTime(e.data[0].start_time, "start");
					setPackRateTime(e.data[0].end_time, "end");

					dispatch(change("dailyTaskOrders", "user", e.data[0].users.name));
					dispatch(change("dailyTaskOrders", "total_cases_packed", e.data[0].total_cases_packed));
					dispatch(change("dailyTaskOrders", "total_pounds_packed", e.data[0].total_pounds_packed));
					dispatch(change("dailyTaskOrders", "number_of_employees", e.data[0].number_of_employees));
					dispatch(change("dailyTaskOrders", "cases_per_lab_hour", e.data[0].cases_per_lab_hour));
					dispatch(change("dailyTaskOrders", "lbs_per_lab_hour", e.data[0].lbs_per_lab_hour));
				}
			} else {
				if (modalType === 4) {
					dispatch(change("dailyTaskOrders", "user", null));
					dispatch(change("dailyTaskOrders", "cleaning_time_in_minutes", ""));
				} else if (modalType === 3) {
					dispatch(change("dailyTaskOrders", "set_up_time_in_minutes", ""));
					dispatch(change("dailyTaskOrders", "user", null));
				} else {
					setStartTime(new Date());
					setNoOfEmps(0);
					setCasesPacked(0);
					setPoundsPacked(0);

					dispatch(change("dailyTaskOrders", "user", null));
					dispatch(change("dailyTaskOrders", "user", ""));
					dispatch(change("dailyTaskOrders", "total_cases_packed", ""));
					dispatch(change("dailyTaskOrders", "total_pounds_packed", ""));
					dispatch(change("dailyTaskOrders", "number_of_employees", ""));
					dispatch(change("dailyTaskOrders", "cases_per_lab_hour", ""));
					dispatch(change("dailyTaskOrders", "lbs_per_lab_hour", ""));
				}
			}
			setloading(false);
		};

		const errorHandler = (event) => {
			setloading(false);
			errorMessage(event);
		};
		modalType === 4
			? getCleaningTime(obj, successHandler, errorHandler)
			: modalType === 3
			? getSetUpTimeOrders(obj, successHandler, errorHandler)
			: getPackRate(obj, successHandler, errorHandler);
	};

	const submitForm = (values) => {
		setloading(true);
		setbtnTitle(t("label.saving"));
		const successHandler = (event) => {
			reset(form);
			setloading(false);
			setbtnTitle(t("label.save"));
			setRefetch && setRefetch((refetch) => refetch + 1);
			successMessage(event);
			closeModal();
		};
		const errorHandler = (event) => {
			setloading(false);
			setbtnTitle(t("label.save"));
			errorMessage(event);
		};
		let submitData = {};
		switch (modalType) {
			//Employee number
			case 1:
				submitData = {
					...values,
					date: moment(values.date).format("YYYY-MM-DD"),
					location_slug: values.location_slug.value,
				};
				logEmployeeCount(submitData, successHandler, errorHandler);

				break;
			// Pack room / Pre cool
			case 2:
				submitData = {
					date: moment(values.date).format("YYYY-MM-DD"),
					location_slug: values.location_slug.value,
					room: values.room.value,
					temperature: values.temperature ? values.temperature : "",
					humidity: values.humidity ? values.humidity : "",
				};
				logPackRoomPreCool(submitData, successHandler, errorHandler);
				break;
			//Set up Time
			case 3:
				submitData = {
					...values,
					set_up_date: moment(values.set_up_date).format("YYYY-MM-DD"),
					room: "",
					set_up_type: "orders_packaging",
					location_slug: values.location_slug.value,
					...(editData && { edit_flag: true }),
				};
				insertSetUpTime(submitData, successHandler, errorHandler);
				break;
			//Cleaning Time
			case 4:
				submitData = {
					...values,
					cleaning_date: moment(values.cleaning_date).format("YYYY-MM-DD"),
					cleaning_type: "orders_packaging",
					room: "",
					location_slug: values.location_slug.value,
				};
				insertCleaningTime(submitData, successHandler, errorHandler);
				break;
			case 5:
				const items =
					packagingType.value === "ByProductVariety"
						? values.product_variety.map((item) => {
								return {
									pv_slug: item.value,
									wastage_pounds: values.items[item.value],
									reason: values.items[item.value + "_reason"].value || "",
								};
						  })
						: values.sku.map((item) => {
								return {
									sku_slug: item.value,
									wastage_cases: values.items[item.value],
									reason: values.items[item.value + "_reason"].value || "",
								};
						  });
				submitData = editData
					? {
							package_waste_composition_id: editData.package_waste_composition_id,
							...(editData.type.value === "ByCase"
								? { wastage_cases: items[0].wastage_cases }
								: { wastage_pounds: items[0].wastage_pounds }),
							reason: items[0].reason,
							type: values.type.value,
							timezone: Util.getLocalTimeZone(),
							date: moment(values.date).format("YYYY-MM-DD"),
					  }
					: {
							date: moment(values.date).format("YYYY-MM-DD"),
							type: values.type.value,
							//reason: values.reason.value,
							location_slug: values.location_slug.value,
							wastage_details: items,
					  };

				editData
					? editPackagingWaste(submitData, successHandler, errorHandler)
					: logPackagingWaste(submitData, successHandler, errorHandler);
				break;
			//Pack rate details
			case 9:
				submitData = {
					location_slug: values.location_slug.value,
					total_cases_packed: values.total_cases_packed,
					total_pounds_packed: values.total_pounds_packed,
					cases_per_lab_hour: values.cases_per_lab_hour,
					lbs_per_lab_hour: values.lbs_per_lab_hour,
					number_of_employees: values.number_of_employees,
					start_time: moment(startTime).format("HH:mm:ss"),
					end_time: moment(endTime).format("HH:mm:ss"),
					packed_date: moment(values.date).format("YYYY-MM-DD"),
				};
				addPackRate(submitData, successHandler, errorHandler);
				break;
			case 10:
				const metrics = temperatureDetailsList.map(function (metric) {
					return {
						room_type: values["lm_" + metric.key].rooms.value,
						product_variety_slug: values["lm_" + metric.key].product_variety_slug.value,
						temperature: values["lm_" + metric.key].temperature,
						humidity: values["lm_" + metric.key].humidity,
					};
				});
				submitData = {
					location_slug: values.location_slug.value,
					date: moment(values.date).format("YYYY-MM-DD"),
					temperature_details: metrics.map((item) => item),
				};

				values && values.edit
					? updateProductTemperatureDetails(submitData, successHandler, errorHandler)
					: addProductTemperature(submitData, successHandler, errorHandler);
				break;
			default:
				return;
		}
	};

	const getProductVariety = (x) => {
		const disableProductSlug = [];
		const arr = [];
		const currentItem =
			productDropDown.values["lm_" + x] &&
			productDropDown.values["lm_" + x].rooms &&
			productDropDown.values["lm_" + x].rooms.value;
		temperatureDetailsList.map((item, index) => {
			const obj =
				productDropDown.values["lm_" + item.key] &&
				productDropDown.values["lm_" + item.key].rooms &&
				productDropDown.values["lm_" + item.key].rooms.value;
			if (obj === currentItem) {
				productDropDown.values["lm_" + item.key] &&
					productDropDown.values["lm_" + item.key].product_variety_slug &&
					disableProductSlug.push(productDropDown.values["lm_" + item.key].product_variety_slug);
			}
		});
		productVariety &&
			productVariety.map((item, index) => {
				let isdisable;
				disableProductSlug.map((slug) => {
					if (slug.value === item.value) {
						isdisable = true;
					}
				});
				const obj = {
					label: item.label,
					value: item.value,
					isdisabled: isdisable,
				};
				arr.push(obj);
			});
		return arr;
	};
	const getOptions = (name, x) => {
		switch (name) {
			case "location_slug":
				return locationList;
			case "room":
				return Rooms;
			case "type":
				return PackagingWasteDropdown;
			case "sku":
				return skuList;
			case "product_variety":
				return productVarietyList;
			case "reason":
				return WastageReasonsList;
			case "product_variety_slug":
				return getProductVariety(x);
			case "rooms":
				return RoomSelect;
			default:
				return [];
		}
	};

	const onSelect = (field, event, number) => {
		switch (modalType) {
			// case 3:
			// case 4:
			// 	field === "location_slug" && setlocation(event.value);
			// 	break;
			case 5:
				if (field === "type") {
					setpackagingType((prevState) => {
						if (!isEqual(prevState, event)) {
							setitemSelected([]);
							dispatch(change("dailyTaskOrders", event.value === "ByCase" ? "product_variety" : "sku", ""));
						}
						return {
							...event,
						};
					});
				} else if (field === "location_slug") {
					selectLocation({ value: event.value, label: event.label });
					if (itemSelected.length > 0) {
						setpackagingType((prevState) => {
							if (!isEqual(prevState, event)) {
								setitemSelected([]);
								dispatch(change("dailyTaskOrders", event.value === "ByCase" ? "product_variety" : "sku", ""));
							}
							return {
								...event,
							};
						});
					}
				} else if (field === "sku") {
					setitemSelected(event);
				} else if (field === "product_variety") {
					setitemSelected(event);
				} else {
					return;
				}
				break;
			case 10:
				if (field === "location_slug") {
					setloading(true);
					const successHandler = (event) => {
						setloading(false);
					};
					const errorHandler = (event) => {
						setloading(false);
						errorMessage(event);
					};
					const submitData = {
						locations: [event.value],
					};
					fetchProductVarieties(submitData, successHandler, errorHandler);
				}
				if (field === "rooms") {
					dispatch(change("dailyTaskOrders", "lm_" + number + ".product_variety_slug"));
				}
				break;
			default:
				return;
		}
	};

	const getSelectedItemsHtml = () => {
		let formFieldHtml = [];
		formFieldHtml = itemSelected.map((skuField, idx) => {
			return (
				<Row key={idx}>
					<Col className="col-6">
						<Form.Group controlId={`formControl ${skuField.value}`}>
							<Field
								name={skuField.value}
								type="text"
								label={`${skuField.label} *`}
								placeholder={
									packagingType.value === "ByCase"
										? t("label.enter_case_wasted")
										: t("label.enter_pounds_wasted")
								}
								component={FormGroups}
								validate={[required, number, minValueBoard]}
							/>
						</Form.Group>
					</Col>
					<Col className="col-6">
						<Form.Group controlId={`formControl ${skuField.value} reason`}>
							<Field
								name={`${skuField.value}_reason`}
								type="select-react"
								label={t("label.reason_for_wastage_req")}
								placeholder={t("label.select_waste_type")}
								component={FormGroups}
								validate={[required]}
								options={getOptions("reason")}
							/>
						</Form.Group>
					</Col>
				</Row>
			);
		});

		return <>{formFieldHtml}</>;
	};

	const getFormFieldHtml = (formfields) => {
		let formFieldHtml = [];

		formFieldHtml = formfields.map((field, idx) => {
			return (
				<React.Fragment key={idx}>
					<Col
						className={
							field.name === "start_time" ||
							field.name === "end_time" ||
							field.name === "cases_per_lab_hour" ||
							field.name === "lbs_per_lab_hour" ||
							field.name === "date" ||
							field.name === "location_slug"
								? "col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12"
								: "col-12"
						}>
						<Form.Group controlId={`formControl ${field.name}`}>
							{field.name === "number_of_employees" ? (
								<Field
									name={field.name}
									type={field.type}
									label={t(field.label)}
									placeholder={t(field.placeholder)}
									component={FormGroups}
									validate={field.validate}
									onChange={(event) => {
										setNoOfEmps(Number(event.target.value));
									}}
								/>
							) : field.name === "total_cases_packed" || field.name === "total_pounds_packed" ? (
								<Field
									name={field.name}
									type={field.type}
									label={t(field.label)}
									placeholder={t(field.placeholder)}
									component={FormGroups}
									validate={field.validate}
									onChange={(event) => {
										field.name === "total_cases_packed"
											? setCasesPacked(Number(event.target.value))
											: setPoundsPacked(Number(event.target.value));
									}}
								/>
							) : field.name === "start_time" || field.name === "end_time" ? (
								<Field
									name={field.name}
									type={field.type}
									label={t(field.label)}
									placeholder={t(field.placeholder)}
									component={FormGroups}
									selectedTime={field.name === "start_time" ? startTime : endTime}
									validate={field.validate}
									onChange={(value) => {
										field.name === "start_time" ? setStartTime(value) : setEndTime(value);
									}}
								/>
							) : field.name === "date" || field.name === "cleaning_date" || field.name === "set_up_date" ? (
								<Field
									name={field.name}
									type={field.type}
									label={t(field.label)}
									placeholder={t(field.placeholder)}
									component={FormGroups}
									disable={editData ? true : false}
									minDate={field.minDate}
									validate={field.validate}
									onChange={(value) =>
										(modalType === 3 || modalType === 4 || modalType === 9) &&
										setdate(moment(value).format("YYYY-MM-DD"))
									}
								/>
							) : field.name === "cases_per_lab_hour" || field.name === "lbs_per_lab_hour" ? (
								<Field
									name={field.name}
									type={field.type}
									label={t(field.label)}
									placeholder={t(field.placeholder)}
									component={FormGroups}
									disable={true}
									validate={field.validate}
								/>
							) : (
								<Field
									name={field.name}
									type={field.type}
									label={t(field.label)}
									placeholder={t(field.placeholder)}
									component={FormGroups}
									validate={field.validate}
									multiple={field.name === "product_variety" || field.name === "sku" ? true : false}
									options={getOptions(field.name)}
									disable={
										editData &&
										(field.name === "location_slug" ||
											field.name === "product_variety" ||
											field.name === "sku" ||
											field.name === "type")
											? true
											: false
									}
									onChange={(event) => onSelect(field.name, event)}
								/>
							)}
						</Form.Group>
					</Col>
				</React.Fragment>
			);
		});
		return <Row>{formFieldHtml}</Row>;
	};

	const renderPackRateForm = () => {
		return <>{getFormFieldHtml(PackRateForm)}</>;
	};

	const temperatureDetailsFormList = (count, flag = "add", i) => {
		let columns = [];

		columns = productTemperatureBottom.map((field, idx) => {
			return (
				<Col
					className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12"
					style={
						field.name === "temperature"
							? { maxWidth: "52%",flex: "0 0 52%"}
							: field.name === "humidity"
							? { maxWidth: "48%" ,flex: "0 0 48%"}
							: null
					}
					key={idx}>
					<Form.Group controlId={`formControl ${idx}`}>
						<Field
							name={`lm_${count}.${field.name}`}
							type={field.type}
							label={t(field.label)}
							placeholder={t(field.placeholder)}
							component={FormGroups}
							validate={field.validate}
							options={getOptions(field.name, count)}
							onChange={(event) => onSelect(field.name, event, count)}
						/>
					</Form.Group>
				</Col>
			);
		});

		return (
			<React.Fragment key={i}>
				<div className="outer-box">
					<Row>{columns}</Row>
					{
						// isEdit ? null : (
						<>
							<Row>
								{temperatureDetailsList.length - 1 === i ? (
									<Col
										className={`d-flex justify-content-center text-center ${
											temperatureDetailsList.length === 1 ? "col-xl-12 col-lg-12  " : "col-xl-6 col-lg-6"
										} col-md-12 col-sm-12 col-12`}>
										<ButtonLink onClick={() => setMetricsArray(count + 1)}>
											<i className="icon icon-plus mr-2" />
											{t("label.Add")}
										</ButtonLink>
									</Col>
								) : (
									""
								)}
								{temperatureDetailsList.length !== 1 ? (
									<Col
										className={`d-flex justify-content-center text-center ${
											temperatureDetailsList.length - 1 !== i
												? "col-xl-12 col-lg-12  "
												: "col-xl-6 col-lg-6"
										} col-md-12 col-sm-12 col-12`}>
										<ButtonLink className="remove-link-btn" onClick={() => removeMetricsArray(count)}>
											<i className="icon icon-minus mr-2" />
											{t("label.remove")}
										</ButtonLink>
									</Col>
								) : (
									""
								)}
							</Row>
						</>
						// )
					}
				</div>
			</React.Fragment>
		);
	};

	const setMetricsArray = (count) => {
		settemperatureDetailsList([
			...temperatureDetailsList,
			{
				key: count,
				flag: "add",
			},
		]);
	};

	const removeMetricsArray = (index) => {
		dispatch(change("dailyTaskOrders", "lm_" + index));
		const locationMetricsListKeyToRemove = findKey(temperatureDetailsList, function (o) {
			return o.key === index;
		});

		const list = [...temperatureDetailsList];
		list.splice(locationMetricsListKeyToRemove, 1);
		settemperatureDetailsList(list);
	};

	const productTemperatureDetails = () => {
		return (
			<>
				{getFormFieldHtml(productTemperatureForm)}
				{temperatureDetailsList &&
					temperatureDetailsList.map((x, i) => {
						return temperatureDetailsFormList(x.key, x.flag, i);
					})}
			</>
		);
	};

	return (
		<Form onSubmit={handleSubmit(submitForm)} className="dailyTaskOrders">
			<Container style={{ position: "relative" }}>
				{modalType === 1 && getFormFieldHtml(EmployeeForm)}
				{modalType === 2 && getFormFieldHtml(PackRoomForm)}
				{modalType === 3 && getFormFieldHtml(SetUpTimeForm)}
				{modalType === 4 && getFormFieldHtml(CleaningTimeForm)}
				{modalType === 5 && getFormFieldHtml(PackagingWaste.details)}
				{modalType === 5 &&
					packagingType &&
					(packagingType.value === "ByCase"
						? getFormFieldHtml(PackagingWaste.byCase)
						: packagingType.value === "ByProductVariety"
						? getFormFieldHtml(PackagingWaste.byProductVariety)
						: null)}
				{modalType === 9 && renderPackRateForm()}

				{modalType === 10 && productTemperatureDetails()}
				{modalType === 5 && itemSelected && itemSelected.length > 0 && (
					<FormSection name="items">{getSelectedItemsHtml()}</FormSection>
				)}

				{userDetails && (
					<div
						role="alert"
						style={{ position: "absolute" }}
						className="fade daily-tasks-alert alert alert-light show">
						{modalType === 3 || modalType === 4 ? (
							<>
								{t("label.cleaning_time_user", {
									cleaning_time_user: `${userDetails.name}`,
									cleaning_time: `${userDetails.value}`,
								})}
							</>
						) : (
							<>
								{t("label.has_already_logged_in", {
									user_details: `${userDetails}`,
								})}
							</>
						)}
					</div>
				)}

				{loading && (
					<div className="table-loader-wrapper">
						<TableLoader />
					</div>
				)}
			</Container>
			<div className="modal-footer save-footer" style={{ marginTop: 40 }}>
				<Button variant="secondary" onClick={closeModal}>
					{t("label.cancel")}
				</Button>
				<Button variant="primary" type="submit" disabled={loading}>
					{btnTitle}
				</Button>
			</div>
		</Form>
	);
};

const mapStateToProps = (state, { editData }) =>
	createStructuredSelector({
		locationList: getLocationList,
		skuList: getLocationSkus,
		productVarietyList: getLocationProducts,
		formDate: getDailyTaskFormDate,
		formLocation: getDailyTaskFormLocation,
		formRoom: getDailyTaskFormRoom,
		formPackagingType: getDailyTaskFormPackagingType,
		initialValues: getDailyTaskInitials(editData),
		globalLocation: getLocationSelected,
		userDetails: getDailyTaskFormUser,
		productVariety: getLocationProductVarieties,
		productDropDown: getProductVariety,
	});

export default connect(mapStateToProps, {
	logEmployeeCount,
	logPackRoomPreCool,
	insertCleaningTime,
	insertSetUpTime,
	getCleaningTime,
	getSetUpTimeOrders,
	loadLocationSkus,
	selectLocation,
	logPackagingWaste,
	fetchLocationProductVarities,
	getDailyTasksDetails,
	resetListing,
	editPackagingWaste,
	addPackRate,
	getPackRate,
	fetchProductVarieties,
	addProductTemperature,
	getProductTemperatureDetails,
	updateProductTemperatureDetails,
})(
	reduxForm({
		form: "dailyTaskOrders",
		enableReinitialize: true,
	})(DailyTasksForm)
);

// modalType =1 employee count
// modalType =2 packroom/precool
// modalType =3 Set up Time
// modalType =4 Cleaning Time
// modalType =5 Packaging Waste
//modalType =9 Pack rate details
