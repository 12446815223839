import { ListingBtn } from "elements";
import { keys, sortBy } from "lodash";
import React from "react";
import { Col, Row } from "react-bootstrap";
import ActionForm from "./actionForm";

export const getActionColumns = (
	data,
	actionColumnWidth,
	posThreeRef,
	editableRows,
	setEditableRows,
	onDelete,
	dispatch,
	submit,
	reset,
	cancelEdit,
	customEdit,
	editPermission,
	deletePermission,
	keyMapping,
	toAddList
) => {
	let arr = [];

	if (keyMapping) {
		if ("pondIndex" in keyMapping[0]) {
			arr = sortBy(keyMapping, "pondIndex").map((item) => item.slug);
		} else {
			arr = sortBy(keyMapping, "name").map((item) => item.slug);
		}
	} else {
		arr = keys(data).sort();
	}

	return (
		<div className="action-fixed-column right-fixed" style={{ width: actionColumnWidth, position: "relative" }}>
			<div ref={posThreeRef} className="sticky-head sticky-head-right" style={toAddList ? { height: "80px" } : null}>
				<Row className={"right-head action-table-head "} style={{ width: `${actionColumnWidth}px` }}>
					<div className="show-shadow-row-right shadow-head" />

					<Col className="action-table-col">
						<span>Actions</span>
					</Col>
				</Row>
			</div>
			<div className="action-table-body" style={toAddList ? { marginTop: "35px" } : null}>
				{arr.map((dataItem, d_ind) => (
					<Row key={d_ind} className={"action-table-body-row right-row position-relative"}>
						<div className="show-shadow-row-right" />
						{dataItem !== "total" && (
							<Col className="action-table-col">
								{editableRows.indexOf(d_ind) === -1 ? (
									<>
										{editPermission && (
											<ListingBtn
												tooltipFontSize={11}
												tooltipText="Edit"
												// tooltipLeft={true}
												type="span"
												iconClass="icon icon-edit"
												onClick={() =>
													customEdit
														? customEdit(dataItem)
														: setEditableRows([...editableRows, d_ind])
												}
												btnClass="btn btn-cell btn-light btn-sm "
											/>
										)}
										{deletePermission && (
											<ListingBtn
												tooltipFontSize={11}
												tooltipText="Delete"
												iconClass="icon icon-trash"
												// tooltipLeft={true}
												type="span"
												onClick={() => onDelete(dataItem)}
												btnClass="btn btn-cell btn-light btn-sm ml-2"
											/>
										)}
									</>
								) : (
									<>
										<ListingBtn
											tooltipFontSize={11}
											// tooltipLeft={true}
											tooltipText="Save"
											type="span"
											iconClass={"icon icon-check modal-table-icon"}
											onClick={(e) => dispatch(submit(`actionForm${d_ind}`))}
											btnClass="btn btn-cell btn-light btn-sm"
										/>

										<ListingBtn
											tooltipFontSize={11}
											// tooltipLeft={true}
											tooltipText="Cancel"
											type="span"
											iconClass={"icon icon-close modal-table-icon"}
											onClick={(e) => {
												dispatch(reset(`actionForm${d_ind}`));
												cancelEdit(d_ind);
											}}
											btnClass="btn btn-cell btn-light btn-sm ml-2"
										/>
									</>
								)}
							</Col>
						)}
					</Row>
				))}
			</div>
		</div>
	);
};
export const getData = (
	data,
	posTwoRef,
	columns,
	slugKey,
	renderKey,
	render,
	editableRows,
	onSave,
	cancelEdit,
	validation,
	keyMapping,
	formName,
	showActions,
	toAddList
) => {
	let arr = [];
	if (keyMapping) {
		if ("pondIndex" in keyMapping[0]) {
			arr = sortBy(keyMapping, "pondIndex").map((item) => item.slug);
		} else {
			arr = sortBy(keyMapping, "name").map((item) => item.slug);
		}
	} else {
		arr = keys(data).sort();
	}
	return (
		<>
			<div ref={posTwoRef} className="sticky-head" style={toAddList ? { height: "80px" } : null}>
				{/* <Row className="action-table-head position-relative"> */}
				<Row className={`action-table-head position-relative ${showActions ? "" : "right"}`}>
					{columns.map((item, idx) => (
						<Col key={idx} className="action-table-col">
							<span>{item.name ? item.name : item}</span>
						</Col>
					))}
				</Row>
			</div>
			<div className="action-table-body" style={toAddList ? { marginTop: "35px" } : null}>
				{arr.map((a_item, a_ind) => {
					const initialValues = {};
					data[a_item] &&
						data[a_item].forEach((item) => {
							let slugName;
							if (toAddList) {
								if (slugKey) {
									//Field name cannot be a number or it cannot have "."
									//redux store cannot store these filed's values // drawback of reduxstore
									slugName = slugKey.replace(".", "~_~");
									initialValues[item[slugName]] = renderKey ? item[renderKey] : item.value;
								} else {
									slugName = item.slug.replace(".", "~_~");
									initialValues[slugName] = renderKey ? item[renderKey] : item.value;
								}
							} else {
								if (slugKey) {
									initialValues[item[slugKey]] = renderKey ? item[renderKey] : item.value;
								} else {
									initialValues[item.slug] = renderKey ? item[renderKey] : item.value;
								}
							}
						});

					return (
						data[a_item] && (
							<ActionForm
								key={a_ind.toString()}
								form={formName + a_ind}
								columns={columns}
								renderKey={renderKey}
								render={render}
								a_ind={a_ind}
								editable={editableRows.indexOf(a_ind) !== -1 ? true : false}
								a_item={data[a_item]}
								slugKey={slugKey}
								initialValues={initialValues}
								slug={a_item}
								onSave={onSave}
								validation={validation}
								cancelEdit={cancelEdit}
								showActions={showActions}
								toAddList={toAddList}
							/>
						)
					);
				})}
			</div>
		</>
	);
};

export const fixedColumnTable = (
	data,
	fixedColumnWidth,
	posOneRef,
	fixedColumnHeading,
	renderKeyItem,
	keyMapping,
	toAddList
) => {
	let arr = [];

	if (keyMapping) {
		if ("pondIndex" in keyMapping[0]) {
			arr = sortBy(keyMapping, "pondIndex").map((item) => item.slug);
		} else {
			arr = sortBy(keyMapping, "name").map((item) => item.slug);
		}
	} else {
		arr = keys(data).sort();
	}

	return (
		<div className="action-fixed-column" style={{ width: fixedColumnWidth, position: "relative" }}>
			<div ref={posOneRef} className="sticky-head" style={toAddList ? { height: "80px" } : null}>
				<Row className={"action-table-head "} style={{ width: `${fixedColumnWidth}px` }}>
					<div className="show-shadow-row shadow-head" />

					<Col className="action-table-col">
						<span>{fixedColumnHeading}</span>
					</Col>
				</Row>
			</div>
			<div className="action-table-body" style={toAddList ? { marginTop: "35px" } : null}>
				{arr.map((dataItem, d_ind) => (
					<Row key={d_ind} className={"action-table-body-row position-relative"}>
						<div className="show-shadow-row " />

						<Col className="action-table-col">{renderKeyItem(dataItem)}</Col>
					</Row>
				))}
			</div>
		</div>
	);
};
