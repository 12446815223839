import React from "react";
import { Field } from "redux-form";
import { get, uniq, map, isEmpty, includes, forEach, find, compact, sum } from "lodash";
import { getValidations, daysArray } from "./formHelper";
import FormGroups from "../../../utils/formGroup";
import { Col } from "react-bootstrap";

// ------------------------------------ MANAGE ------------------------------------ //
// Function : to remove duplicate weeks
// To correct the order of showing weeks
const getWeekName = (days) => {
	const selectedDays = uniq(map(days, "label"));
	if (includes(selectedDays, "All")) {
		return daysArray;
	} else {
		const arr = [];
		forEach(selectedDays, (o) => {
			const weekDayObj = find(daysArray, ["label", o]) || null;
			const weekDayIndex = weekDayObj && weekDayObj.index;
			arr[weekDayIndex] = weekDayObj;
		});
		return arr && !isEmpty(arr) ? compact(arr) : !isEmpty(days) ? days : [];
	}
};

// Render : Week Table Headings
const renderWeekHeads = (details) =>
	details &&
	!isEmpty(details.delivery_days) &&
	map(getWeekName(details.delivery_days), (o, i) => (
		<div key={`DAYS_${o.value}_${i}`} className="cpt-head-week">
			{o.label}
		</div>
	));

// Render : Week Table Inputs (Each Week Input box)
const renderWeekInputs = (field, count, details) =>
	details &&
	!isEmpty(details.delivery_days) &&
	map(getWeekName(details.delivery_days), (o) => (
		<div className="cpt-body-week" key={`SKU_INPUTS_${o.value}`}>
			<Field
				name={`dc_${count}.sku_percentages.${o.value}.${field.value}.percentage`}
				type="text"
				autoComplete="####@"
				component={FormGroups}
				validate={getValidations("percentage")}
			/>
		</div>
	));

// Manage Table
export const CustomerSKUPricingTableManage = ({ formValues, skuFields, count }) => {
	const getCurrentDCDetails = formValues && get(formValues, `dc_${count}`);
	return (
		<div className="customer-pricing-table">
			<div className="customer-pricing-table-head">
				<div className="cpt-head-name">SKU</div>
				<div className="cpt-head-pricing">SKU Pricing</div>
				{renderWeekHeads(getCurrentDCDetails)}
			</div>
			<div className="customer-pricing-table-body">
				{skuFields.map((field, idx) => (
					<div className="customer-pricing-table-body-row" key={idx}>
						<div className="cpt-body-name">{field.label}</div>
						<div className="cpt-body-pricing">
							<Field
								name={`dc_${count}.${field.value}`}
								type="text"
								autoComplete="####@"
								component={FormGroups}
								validate={getValidations(field)}
							/>
						</div>
						{renderWeekInputs(field, count, getCurrentDCDetails)}
					</div>
				))}
			</div>
		</div>
	);
};

// ------------------------------------ VIEW ------------------------------------ //

// Function : to remove duplicate weeks
// To correct the order of showing weeks
const getViewWeekName = (days = []) => {
	const selectedDays = uniq(days);
	if (includes(selectedDays, "All")) {
		return map(daysArray, "label");
	} else {
		const arr = [];
		forEach(selectedDays, (o) => {
			const weekDayObj = find(daysArray, ["label", o]) || null;
			const weekDayIndex = weekDayObj && weekDayObj.index;
			arr[weekDayIndex] = o;
		});
		return arr && !isEmpty(arr) ? compact(arr) : !isEmpty(days) ? days : [];
	}
};

// Render : VIEW - Week Table Headings
const renderViewWeekHeads = (days = []) =>
	!isEmpty(days) &&
	map(getViewWeekName(days), (o, i) => (
		<div key={`VIEW_DAYS_${i}`} className="cpt-head-week view-mode">
			{o}
		</div>
	));

// Render : VIEW - Week Table Values
const renderViewWeekValues = (fields = {}, skuSlug = "", days = []) =>
	!isEmpty(days) &&
	map(getViewWeekName(days), (o, i) => {
		const value = get(fields, `sku_percentages.${o}.${skuSlug}.percentage`);
		return (
			<div key={`VIEW_DAYS_VALUE_${i}`} className="cpt-body-week view-mode">
				{value || +value === 0 ? `${value}%` : "-"}
			</div>
		);
	});

// View Table
export const CustomerSKUPricingTableView = ({ dcDetails }) => (
	<div className="customer-pricing-table mb-0">
		<div className="customer-pricing-table-head">
			<div className="cpt-head-name">SKU</div>
			<div className="cpt-head-pricing view-mode">SKU Pricing</div>
			{renderViewWeekHeads(dcDetails && dcDetails.delivery_days)}
		</div>
		<div className="customer-pricing-table-body">
			{dcDetails &&
				!isEmpty(dcDetails.skus) &&
				dcDetails.skus.map((dcSKU, idx) => (
					<div className="customer-pricing-table-body-row" key={idx}>
						<div className="cpt-body-name view-mode">{dcSKU.sku}</div>
						<div className="cpt-body-pricing view-mode">{dcSKU.price || "-"}</div>
						{renderViewWeekValues(dcDetails, dcSKU && dcSKU.slug, dcDetails && dcDetails.delivery_days)}
					</div>
				))}
		</div>
	</div>
);

// Customer SKU DC Details - View Page
export const customerSKULabelledValue = (item, details) => (
	<Col className="col-lg-3 col-md-4 col-sm-6 col-12 mb-3" key={item.label}>
		<p className="d-flex align-items-center view-labels mb-1">
			<span className="light-badge-pill"></span>
			{item.label}
		</p>
		<p className="ml-4 view-values">{item.render ? item.render(details) : details ? details[item.name] || "-" : "-"}</p>
	</Col>
);

const getAllSum = (slug, percArr) => {
	const percentages = map(percArr, (o) => (o[slug] && o[slug].percentage && +o[slug].percentage) || 0);
	return sum(percentages);
};

// Returns an array with sku [label, value, sum]
export const checkingIfSumIs100 = (skuArr = [], percArr = [], dcName = "") =>
	map(skuArr, (o) => ({
		...o,
		label: `${o.label} ${dcName ? `(${dcName})` : ""}`,
		sum: getAllSum(o.value, percArr),
	}));
