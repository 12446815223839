import React from "react";

const RenderHeadings = () => {
	return (
		<div className="ui-typography">
			<h2 className="mb-4">Typography - Poppins</h2>
			<div className="d-flex">
				<div className="flex-fill">
					<h1>Aa</h1>
					<div>Heading 1 - 20px Semibold</div>
				</div>
				<div className="flex-fill">
					<h2>Aa</h2>
					<div>Heading 2 - 16px Medium</div>
				</div>
				<div className="flex-fill">
					<h3>Aa</h3>
					<div>Heading 3 - 14px Medium</div>
				</div>
			</div>
		</div>
	);
};

export default RenderHeadings;
