import { requiredDateRange, required } from "constant/formValidators";

export const laneToCaseFormFields = [
	{
		label: "Location *",
		name: "location_slug",
		type: "select-react",
		placeholder: "Select Location",
		validate: [required],
	},
	{
		label: "Product Variety *",
		name: "product_variety",
		type: "select-react-expand-all",
		placeholder: "Select Product Variety",
		validate: [required],
	},
	{
		label: "Date *",
		name: "date",
		type: "date-range-picker",
		placeholder: "Enter Date",
		validate: [requiredDateRange],
	},
];
