export const laneUsageDropDown = [
	{
		label: "Sales Lane",
		value: "sales",
	},
	{
		label: "Production Lane",
		value: "production",
	},
	{
		label: "Production Vs Sales",
		value: "production_vs_sales",
	},
	{
		label: "Production Vs Sales Difference",
		value: "production_vs_sales_difference",
	},
];
export const tableViewDropDown = [
	{
		label: "Sales",
		value: "sales",
	},
	{
		label: "Production",
		value: "production",
	},

	{
		label: "Production Vs Sales",
		value: "production_vs_sales",
	},
	{
		label: "Production Vs Sales Difference",
		value: "production_vs_sales_difference",
	},
];

export const laneUtilizedColumns = ["Lane Utilized","GH Max"];
export const laneToCaseColumns = [
	{ name: "Oz/Board", slug: "oz_board" },
	{ name: "Grow Cycle", slug: "grow_cycle" },
];
export const laneUsageHeader = [{ name: "No. Of Lanes", slug: "-1" }];
