import React, { useEffect } from "react";
import { Breadcrumbs, ContentWrap } from "elements";
import TableLoader from "utils/table/tableLoader";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { getLoading } from "store/selectors/laneCalculatorSelectors";
import { fetchLocationProductVaritiesLc, setLoaderLc } from "store/actions";
import { errorMessage } from "utils/errorMessage";
import Listing from "./listing";
const LaneToCase = ({ loading, fetchLocationProductVaritiesLc, setLoaderLc }) => {
	useEffect(() => {
		setLoaderLc();
		const successHandler = () => {
			setLoaderLc();
		};
		const errorHandler = (e) => {
			setLoaderLc();
			errorMessage(e);
		};

		fetchLocationProductVaritiesLc(successHandler, errorHandler);
	}, [fetchLocationProductVaritiesLc, setLoaderLc]);
	return (
		<ContentWrap>
			<div className="d-flex align-items-center mb-3">
				<Breadcrumbs
					className="forecast-breadcrumb"
					keyValue="laneCalculator"
					showLabel={true}
					label={"Lane to Case"}
				/>
			</div>
			<div className="position-relative">
				<Listing />
				{loading && (
					<div className="table-loader-wrapper">
						<TableLoader />
					</div>
				)}
			</div>
		</ContentWrap>
	);
};
const mapStateToProps = createStructuredSelector({
	loading: getLoading,
});

export default connect(mapStateToProps, { fetchLocationProductVaritiesLc, setLoaderLc })(LaneToCase);
