import { number, required, requiredDateObj, isDateObj, minValueBoard } from "../../../constant/formValidators";

export const topFormFields = [
	{
		fields: [
			{
				label: "Location *",
				name: "location_slug",
				type: "select-react",
				placeholder: "Select Location",
				validate: [required],
			},
			{
				label: "Date of request *",
				name: "date_of_request",
				type: "date-picker",
				placeholder: "Enter Date",
				validate: [requiredDateObj, isDateObj],
				doGetCall: true,
				minDate: new Date(),
			},
		],
	},
];
export const crossSupplyBoardForm = {
	sku: {
		name: "slug",
		type: "select-react",
		placeholder: "Select SKU",
		validate: [required],
	},
	cases: {
		name: "cases_required",
		type: "text",
		placeholder: "Cases required",
		validate: [required, number, minValueBoard],
	},
	date: {
		name: "delivery_date",
		type: "date-picker",
		placeholder: "Enter Date",
		validate: [requiredDateObj, isDateObj],
		doGetCall: true,
		minDate: new Date(),
		popperPlacement: "bottom-end",
	},
};
