import React from "react";
import { Col } from "react-bootstrap";

export const renderRow = ({
	nameKey,
	columns,
	a_item,
	a_ind,
	editable,
	renderKey,
	render,
	slugKey,
	validation,
	localeString,
}) => {
	return (
		<>
			{columns.map((b_item, b_ind) => {

				const value = a_item && a_item[0].value ? a_item[0].value : 0

				if(value !== 0){
					return (
						<Col key={b_ind} className="forecast-table-col">
							<span>{value}</span>
						</Col>
					);
				}else{
					return (
						<Col key={b_ind} className="forecast-table-col">
							<span>-</span>
						</Col>
					);
				}
			})}
		</>
	);
};
