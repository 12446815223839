import React, { useEffect } from "react";
import { Breadcrumbs, ButtonPrimary, ContentWrap } from "elements";
import Listing from "./listing";
import "../../index.scss";
import TableLoader from "utils/table/tableLoader";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { getForecastRenderType, getLoading, getProductionForecastAddAccess } from "store/selectors/forecastSelectors";
import { fetchLocationProductVaritiesPF, setForecastRenderType, setLoader } from "store/actions";
import { errorMessage } from "utils/errorMessage";
import AddForecast from "./addForecast";

const ProductionForecast = ({
	loading,
	fetchLocationProductVaritiesPF,
	setLoader,
	setForecastRenderType,
	renderType,
	addAccess,
}) => {
	useEffect(() => {
		setLoader();
		const successHandler = () => {
			setLoader();
		};
		const errorHandler = (e) => {
			errorMessage(e);
		};
		fetchLocationProductVaritiesPF(successHandler, errorHandler);
	}, [fetchLocationProductVaritiesPF, setLoader]);

	return (
		<ContentWrap>
			<div className="d-flex align-items-center mb-3">
				<Breadcrumbs
					className="forecast-breadcrumb"
					keyValue={renderType === 0 ? "productionForecast" : "productionForecastAdd"}
					showLabel={renderType === 0 ? true : false}
					label={renderType === 0 ? "Forecast" : ""}
					// label={
					// 	renderType === 0 ? (
					// 		"Forecast"
					// 	) : (
					// 		<>
					// 			<span onClick={() => setForecastRenderType(0)}>Forecast</span> / Add
					// 		</>
					// 	)
					// }
				/>
				{renderType === 0 && addAccess && (
					<ButtonPrimary onClick={() => setForecastRenderType(1)}>
						<i className="icon icon-plus mr-2" />
						Add Forecast
					</ButtonPrimary>
				)}
			</div>

			<div className="position-relative">
				{renderType === 0 ? <Listing /> : renderType === 1 ? <AddForecast /> : null}
				{loading && (
					<div className="table-loader-wrapper">
						<TableLoader />
					</div>
				)}
			</div>
		</ContentWrap>
	);
};

const mapStateToProps = createStructuredSelector({
	loading: getLoading,
	renderType: getForecastRenderType,
	addAccess: getProductionForecastAddAccess,
});

export default connect(mapStateToProps, { fetchLocationProductVaritiesPF, setLoader, setForecastRenderType })(
	ProductionForecast
);
