import React from "react";
import { Modal } from "react-bootstrap";
import useModal from "../../../utils/useModal";
import InventoryDetailsForm from "./inventoryDetailsForm";
import { useTranslation } from "react-i18next";
const { Header, Title, Body } = Modal;

const InventoryDetailsModal = ({ doFetchTaskDetails, fetchTaskDetails }) => {
	const { t } = useTranslation();
	const [isOpen, modalType, , closeModal, modalProps] = useModal("inventory_show_modal");

	const isEdit = modalType === 2;
	return (
		<Modal size="lg" show={isOpen} onHide={closeModal} className="modal-sidepanel">
			<Header closeButton>
				<Title className="modal-title">{t("label.add_inventory")}</Title>
			</Header>
			<Body>
				<InventoryDetailsForm
					isEdit={isEdit}
					initialValues={modalProps}
					doFetchTaskDetails={doFetchTaskDetails}
					fetchTaskDetails={fetchTaskDetails}
				/>
			</Body>
		</Modal>
	);
};

export default InventoryDetailsModal;
