import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { fetchLocationDetails, fetchHarvestForecastV2, updateHarvestForecast } from "store/actions";
import { errorMessage } from "utils/errorMessage";
import { connect } from "react-redux";
import { get } from "lodash";
import Sticky from "react-sticky-el";

import "./index.scss";
import { ContentWrap } from "elements";
import moment from "moment";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { Button, Col, Container, Row } from "react-bootstrap";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import DayCell from "./dayCell";
import { successMessage } from "../../utils/successMessage";

const CustomTextField = styled(TextField)({
	"label + &": { borderRadius: 8 },
	"& .MuiInputBase-input": {
		borderRadius: 8,
	},
	"& label": {
		color: "black",
	},
	"& label.Mui-focused": {
		color: "#006241",
	},
	"& .MuiInput-underline:after": {
		borderBottomColor: "#006241",
		borderRadius: 8,
	},
	"& .MuiOutlinedInput-root": {
		borderRadius: 8,
		width: "100%",
		"& fieldset": {
			borderColor: "#cdcdcd",
			borderRadius: 8,
			fontWeight: "bold",
		},
		"&:hover fieldset": {
			borderColor: "#000",
			borderRadius: 14,
			fontWeight: "bold",
		},
		"&.Mui-focused fieldset": {
			borderColor: "#006241",
			borderRadius: 8,
		},
	},
});

// Names from PHP
// $greenNamingMap =[
//     'Newton Basil'=>'Newton-Basil', #basil
//     'Thai Basil'=>'Thai-Basil',      #basil
//     'Lemon Basil'=>'Lemon-Basil',    #basil
//     'Basil'=>'Basil', #basil
//     'Kale'=>'Kale',  #Retired Kale
//     'Arugula'=>'Arugula', #Arugula -> Astro
//     'Wild Arugula' => 'Wild Arugula',#Wild Arugula -> Bellezia -> Esmee
//     'Bellezia' => 'Wild Arugula',
//     'Paris Island'=>'Romaine', #Romaine -> Paris Choi
//     'Romaine'=>'Romaine',
//     'Pac Choi'=>'Pac Choi', #Pac Choi -> Pac Choi
//     'Spinach'=>'Spinach', #spinach -> Bellizia is an arugula
//     'Lola' => 'Red Leaf',
//     'Yukina' => 'Yukina',
//     'Crunch' => 'Crunch',
//     'Cristabel' => 'Crunch',
//     'Cristobel'=>'Crunch', #Crunch -> Cristobel, Finstar
//     'Finstar' => 'Crunch',
//     'Sunny' => 'Crunch',
//     'Annapolis' => 'Red Leaf',
//     'Blend'=> 'Blend', #Blend product -> Five Star
//     'Lettuce Blend' => 'Blend',
//     'Azyrka' => 'Red Leaf',
//     'Azirka'=>'Red Leaf', #Red Leaf -> Azirka, Litzka
//     'Red Giant'=>'Mustard', # Mustard -> Red Giant
//     'Mustard' => 'Mustard',
//     'Beet'=>'Beet', #Beet -> Multiple
//     'Bellezia Arugula'=>'Wild Arugula', #Wild Arugula -> Bellezia -> Esmee
//     'Butter'=>'Butter', #Butter -> budgee, esmari
//     'Butter '=>'Butter', #Butter -> budgee, esmari
//     'Butter Crunch' => 'Butter', #Butter -> budgee, esmari
//     'Green Reef'=>'Reef', #Reef -> Coastline, Green Reef
//     'Rutgers'=>'Nutrigreens', #Nutrigreens -> Rutgers- custom KF Plus -Custom Nar
//     'Coastline'=>'Reef-Coastline', #Reef -> Coastline, Green Reef
//     'Chard'=>'Chard', #Chard -> Peppermint Chard, Charbell
//     'Red Crunch '=>'Red Leaf', #Red Leaf -> Azirka, Litza
//     'Red Crunch'=>'Red Leaf', #Red Leaf -> Azirka, Litza,
//     'Litska' => 'Red Leaf',
//     "Scarlet Frill / Red Giant" => "Mustard",
//     "SFrill" => "Mustard",
//     "Oak Leaf" => "Oak Leaf",
//     "Red Leaf" => "Red Leaf",
//     "Mizuna" => "Mizuna",
//     "Spice" => "Spice",
//     "Blank" => "Blank",
//     "Mixed" => "Mixed"
// ];

const growerVarieties = {
	"chi-1": [
		{ perfName: "Crunch", growerName: "Cristabel", varietyCode: "LSG1-0001" },
		{ perfName: "Reef-Coastline", growerName: "Green Reef", varietyCode: "LSG2-0001" },
		{ perfName: "Romaine", growerName: "Paris Island", varietyCode: "LSG3-0002" },
		{ perfName: "Red Leaf", growerName: "Azirka", varietyCode: "LSR4-0001" },
		{ perfName: "Butter", growerName: "Budgee", varietyCode: "LSG6-0001" },
		{ perfName: "Nutrigreens", growerName: "Nutrigreens Mix", varietyCode: "LSX9-0001-LSX9-0002" },
		// { perfName: "Beet", growerName: "Vulture", varietyCode: "BVG1-0002" },
		{ perfName: "Newton-Basil", growerName: "Newton", varietyCode: "OBX1-0002" },
	],
	pen: [
		{ perfName: "Crunch", growerName: "Cristabel", varietyCode: "LSG1-0001" },
		{ perfName: "Reef-Coastline", growerName: "Green Reef", varietyCode: "LSG2-0001" },
		{ perfName: "Romaine", growerName: "Paris Island", varietyCode: "LSG3-0002" },
		{ perfName: "Red Leaf", growerName: "Litska", varietyCode: "LSR4-0002" },
		{ perfName: "Red Leaf", growerName: "Azirka", varietyCode: "LSR4-0001" },
		{ perfName: "Butter", growerName: "Budgee", varietyCode: "LSG6-0001" },
		{ perfName: "Beet", growerName: "Vulture", varietyCode: "BVG1-0002" },
		{ perfName: "Newton-Basil", growerName: "Newton", varietyCode: "OBX1-0002" },
	],
	car: [
		{ perfName: "Crunch", growerName: "Cristabel", varietyCode: "LSG1-0001" },
		// { perfName: "Crunch", growerName: "Crispenza", varietyCode: "LSG1-0010" },
		{ perfName: "Sunny24", growerName: "Sunny24", varietyCode: "LSG1-0024" },
		{ perfName: "Sunny7", growerName: "Sunny7", varietyCode: "LSG1-0007" },
		{ perfName: "Sunny3", growerName: "Sunny3", varietyCode: "LSG1-0003" },
		{ perfName: "Sunny10", growerName: "Sunny10", varietyCode: "LSG1-0010" },
		// { perfName: "Crunch", growerName: "Sunny7", varietyCode: "LSG1-0007" },
		{ perfName: "Reef-Coastline", growerName: "Green Reef", varietyCode: "LSG2-0001" },
		// { perfName: "Reef-Coastline", growerName: "Coastline", varietyCode: "LSG2-0002" },
		{ perfName: "Romaine", growerName: "Paris Island", varietyCode: "LSG3-0002" },
		{ perfName: "Red Leaf", growerName: "Azirka", varietyCode: "LSR4-0001" },
		// { perfName: "Butter", growerName: "Budgee", varietyCode: "LSG6-0001" },
		{ perfName: "Butter", growerName: "Ezmari", varietyCode: "LSG6-0002" },
		// { perfName: "Beet", growerName: "Vulture", varietyCode: "BVG1-0002" },
		// { perfName: "Beet", growerName: "Fresh Pak", varietyCode: "BVG1-0005" },
	],
	ohi: [
		{ perfName: "Crunch", growerName: "Cristabel", varietyCode: "LSG1-0001" },
		{ perfName: "Reef-Coastline", growerName: "Green Reef", varietyCode: "LSG2-0001" },
		{ perfName: "Red Leaf", growerName: "Azirka", varietyCode: "LSR4-0001" },
		{ perfName: "Spinach", growerName: "Sunangel", varietyCode: "SOX1-0007" },
		// { perfName: "Beet", growerName: "Fresh Start", varietyCode: "BVG1-0001" },
	],
	wdc: [
		{ perfName: "Crunch", growerName: "Cristabel", varietyCode: "LSG1-0001" },
		{ perfName: "Reef-Coastline", growerName: "Green Reef", varietyCode: "LSG2-0001" },
		{ perfName: "Romaine", growerName: "Paris Island", varietyCode: "LSG3-0002" },
		{ perfName: "Red Leaf", growerName: "Azirka", varietyCode: "LSR4-0001" },
		{ perfName: "Spinach", growerName: "Sunangel", varietyCode: "SOX1-0007" },
	],
	ele: [],
	ilg: [
		{ perfName: "Crunch", growerName: "Cristabel", varietyCode: "LSG1-0001" },
		{ perfName: "Red Leaf", growerName: "Azirka", varietyCode: "LSR4-0001" },
		{ perfName: "Butter", growerName: "Ezmari", varietyCode: "LSG6-0002" },
		{ perfName: "Romaine", growerName: "Paris Island", varietyCode: "LSG3-0002" },
		{ perfName: "Green Reef", growerName: "Green Reef", varietyCode: "LSG2-0001" },
		{ perfName: "Coastline", growerName: "Coastline", varietyCode: "LSG2-0002" },
	],
	neh: [
		{ perfName: "Crunch", growerName: "Cristabel", varietyCode: "LSG1-0001" },
		{ perfName: "Reef-Coastline", growerName: "Coastline", varietyCode: "LSG2-0002" },
		{ perfName: "Red Leaf", growerName: "Red Leaf 18602", varietyCode: "LSR4-0008" },
		{ perfName: "Mizuna", growerName: "Mizuna", varietyCode: "BRG2-0001" },
		{ perfName: "Spice", growerName: "Spice Mix", varietyCode: "LSR4-0033-EVX1-0001-BJG1-0001-MGG1-0001" },
		{ perfName: "Oak Leaf", growerName: "Oak Mix", varietyCode: "LSG7-0006-LSG7-0007-LSR7-0008" },
	],
};

const GrowerTools = ({ fetchLocationDetails, fetchHarvestForecastV2, updateHarvestForecast }) => {
	let location = useLocation();
	const urlParams = new URLSearchParams(location.search);
	const [greenhouse, setGreenhouse] = useState([]);
	const [currentYear, setCurrentYear] = useState(urlParams.get("year") ? urlParams.get("year") : moment().year());
	const [currentMonth, setCurrentMonth] = useState(urlParams.get("month") ? moment(urlParams.get("month")) : moment());
	const [currentWeek, setCurrentWeek] = useState(urlParams.get("week") ? moment(urlParams.get("week")) : moment().isoWeek());
	const [shouldRefresh, setShouldRefresh] = useState(true);
	const [forecastData, setForecastData] = useState(null);
	const [save, setSave] = useState([]);
	const [luna, setLuna] = useState([]);
	const [loading, setLoading] = useState(false);

	let params = useParams();

	useEffect(() => {
		console.log("forecastUpdated");
	}, [forecastData]);

	useEffect(() => {
		const handleBeforeUnload = (e) => {
			// You can check if the user has taken some action here
			// For example, check if a form is dirty or if specific data has been modified
			const isDirty = save.length > 0; // Replace this with your condition

			if (isDirty) {
				e.preventDefault();
				e.returnValue = "Are you sure you want to leave without saving?"; // This is the message that will be displayed in the confirmation dialog
			}
		};

		window.addEventListener("beforeunload", handleBeforeUnload);

		return () => {
			// Cleanup the event listener when the component unmounts
			window.removeEventListener("beforeunload", handleBeforeUnload);
		};
	}, [save]);

	useEffect(() => {
		const pullForecastData = async () => {
			setLoading(true);
			fetchHarvestForecastV2(
				{
					forecast_type: "daily",
					current_year: currentYear,
					current_month: currentMonth,
					current_week: currentWeek,
					location_id: greenhouse.slug,
					yearOnly: 0,
				},
				(e) => {
					const mappingTable = e.harvest.reduce((acc, obj) => {
						const varietyCode = obj.variety_code_from_grower;

						if (!acc[varietyCode]) {
							acc[varietyCode] = [];
						}

						acc[varietyCode].push({
							location_id: obj.location_id,
							forecast: obj.forecast,
							date: obj.date,
							product_variety_id: obj.product_variety_id,
							forecast_value: obj.forecast_value,
							created_at: obj.created_at,
							updated_at: obj.updated_at,
							forecast_version: obj.forecast_version,
							most_current: obj.most_current,
							growing_lane_number: obj.growing_lane_number,
							forecast_unit: obj.forecast_unit,
							forecast_type: obj.forecast_type,
							change_reason: obj.change_reason,
							change_notes: obj.change_notes,
							growing_location_id: obj.growing_location_id,
							created_by_user_id: obj.created_by_user_id,
							variety_name_from_perf: obj.variety_name_from_perf,
							variety_name_from_grower: obj.variety_name_from_grower,
							variety_code_from_grower: obj.variety_code_from_grower,
						});

						return acc;
					}, {});
					console.log(mappingTable);
					setForecastData(mappingTable);
					setLuna(e.luna);
					setShouldRefresh(false);
					setLoading(false);
				},
				errorHandler
			);
		};

		if (greenhouse?.slug && currentYear && shouldRefresh) {
			pullForecastData();
		}
	}, [shouldRefresh, greenhouse, currentYear, currentMonth, currentWeek, fetchHarvestForecastV2]);

	useEffect(() => {
		fetchLocationDetails(params.slug, successHandler, errorHandler);
	}, [fetchLocationDetails, params]);

	const forecastChange = (object) => {
		console.log("Changing Data:", object);
		var savable = [...save];
		savable.push(object);
		setSave(savable);
	};

	const successHandler = (e) => {
		console.log(e.data);
		setGreenhouse(e.data);
	};
	function formatDate(date) {
		return date.format("ddd, MMM Do YYYY");
	}

	function getTotalFor(code, date) {
		if (forecastData) {
			var oz = getValueFromForecast(code, date, "oz_per_board");
			var boards = getValueFromForecast(code, date, "number_of_boards");
			if (oz?.forecast_value && boards?.forecast_value) {
				return ((oz.forecast_value * boards.forecast_value) / 16).toFixed(2);
			}
		}
		return 0;
	}

	function getValueFromForecast(code, date, type, perfName, growerName) {
		if (forecastData) {
			const matchingObject = forecastData[code]?.find((obj) => {
				// console.log(moment(obj.date, "YYYY-MM-DD HH:mm:ss").format("MMMM DD, YYYY"));
				// console.log(date.format("MMMM DD, YYYY"));
				return (
					moment(obj.date, "YYYY-MM-DD HH:mm:ss").format("MMMM DD, YYYY") === date.format("MMMM DD, YYYY") &&
					type === obj.forecast
				);
			});
			if (matchingObject) {
				return matchingObject;
			}
			return {
				date: date.format("MMMM DD, YYYY"),
				variety_code_from_grower: code,
				forecast: type,
				forecast_unit: type,
				forecast_value: 0,
				variety_name_from_grower: growerName,
				variety_name_from_perf: perfName,
				location_slug: greenhouse.slug,
				forecast_type: "daily",
			};
		}
	}

	const getWeekDates = (year, weekNumber) => {
		const firstDayOfWeek = moment().year(year).week(weekNumber).startOf("week");
		const lastDayOfWeek = moment(firstDayOfWeek).add(5, "week").endOf("week");

		return [firstDayOfWeek, lastDayOfWeek];
	};

	const getCalendar = () => {
		const [start, end] = getWeekDates(currentYear, currentWeek);

		const startWeek = start.week();

		let endWeek = end.week();

		var w = startWeek;
		let calendar = [];
		let year = currentYear;
		if (startWeek > endWeek) {
			endWeek = 52 + endWeek;
		}
		for (w; w <= endWeek; w++) {
			let week = w;

			if (w > 52) {
				year = currentYear + 1;
				week = w - 52;
			}
			calendar.push({
				week: w,
				days: Array(7)
					.fill(0)
					.map((n, i) =>
						moment()
							.year(year)
							.week(week)
							.startOf("week")
							.clone()
							.add(n + i, "day")
					),
			});
		}

		return calendar;
	};

	const errorHandler = (e) => {
		errorMessage(e);
	};

	const saveForecast = () => {
		updateHarvestForecast(
			save,
			(e) => {
				successMessage(e);
				setShouldRefresh(true);
				setSave([]);
			},
			errorHandler
		);
	};

	return (
		<ContentWrap>
			<div className="d-flex">
				<div style={{ flex: 1 }}>
					<div style={{ fontSize: 32, fontWeight: "bold" }}>{greenhouse.location_name}</div>
					<div style={{ fontSize: 16 }}>
						{greenhouse.city}, {greenhouse.state}
					</div>
				</div>
			</div>

			{greenhouse?.slug &&
				growerVarieties[greenhouse?.slug].map((v) => {
					return (
						<div style={{ paddingBottom: 24, marginBottom: 24 }}>
							<Row style={{ paddingTop: 24, paddingBottom: 12 }}>
								<div
									className="d-flex"
									style={{
										padding: 12,
										fwidth: "100%",
										borderTop: "1px solid #00624155",
										borderBottom: "none",
										textAlign: "center",
										flex: 1,
										flexDirection: "row",
										fontSize: 24,
									}}>
									<div style={{ width: "25%", fontSize: 32, fontWeight: "bold" }}>
										<div
											style={{
												border: "2px solid #006241",
												paddingLeft: 24,
												paddingRight: 24,
												borderRadius: 24,
												color: "#006241",
												cursor: "pointer",
											}}
											onClick={() => {
												setForecastData(null);
												setCurrentWeek(moment(currentMonth).subtract(5, "week").isoWeek());
												setCurrentMonth(moment(currentMonth).subtract(5, "week"));
												setCurrentYear(moment(currentMonth).subtract(5, "week").year());
												setShouldRefresh(true);
											}}>
											{"<"}
										</div>
									</div>
									<div style={{ width: "50%" }}>
										<div style={{ fontSize: 32, fontWeight: "bold" }}>{`${formatDate(
											getWeekDates(currentYear, currentWeek)[0]
										)} -> ${formatDate(getWeekDates(currentYear, currentWeek)[1])}`}</div>
									</div>
									<div style={{ width: "25%", fontSize: 32, fontWeight: "bold" }}>
										<div
											style={{
												border: "2px solid #006241",
												paddingLeft: 24,
												paddingRight: 24,
												borderRadius: 24,
												color: "#006241",
												cursor: "pointer",
											}}
											onClick={() => {
												setForecastData(null);
												setCurrentWeek(moment(currentMonth).add(5, "week").isoWeek());
												setCurrentMonth(moment(currentMonth).add(5, "week"));
												setCurrentYear(moment(currentMonth).add(5, "week").year());
												console.log(moment(currentMonth).add(5, "week").year());
												setShouldRefresh(true);
											}}>
											{">"}
										</div>
									</div>
								</div>
							</Row>
							<Row
								style={{
									width: "100%",
									justifyContent: "space-evenly",
									alignItems: "center",
									paddingBottom: 24,
								}}>
								<Col>
									<h1 style={{ fontSize: 32, fontWeight: "bold", paddingTop: 0 }}>
										{v.perfName} ({v.growerName} - {v.varietyCode})
									</h1>
								</Col>
								<Col style={{ textAlign: "right" }}>
									<Button disabled={save.length == 0 ? true : false} onClick={saveForecast}>
										Save
									</Button>
								</Col>
							</Row>
							<Row>
								<Col
									style={{
										width: "100%",
										fontSize: 12,
										fontWeight: "bold",
										letterSpacing: 1,
										color: "#fff",
										backgroundColor: "#006241",
										padding: 8,
										textAlign: "center",
										borderRight: "1px solid white",
									}}>
									Sunday
								</Col>
								<Col
									style={{
										width: "100%",
										fontSize: 12,
										fontWeight: "bold",
										letterSpacing: 1,
										color: "#fff",
										backgroundColor: "#006241",
										padding: 8,
										textAlign: "center",
										borderRight: "1px solid white",
									}}>
									Monday
								</Col>
								<Col
									style={{
										width: "100%",
										fontSize: 12,
										fontWeight: "bold",
										letterSpacing: 1,
										color: "#fff",
										backgroundColor: "#006241",
										padding: 8,
										textAlign: "center",
										borderRight: "1px solid white",
									}}>
									Tuesday
								</Col>
								<Col
									style={{
										width: "100%",
										fontSize: 12,
										fontWeight: "bold",
										letterSpacing: 1,
										color: "#fff",
										backgroundColor: "#006241",
										padding: 8,
										textAlign: "center",
										borderRight: "1px solid white",
									}}>
									Wednesday
								</Col>
								<Col
									style={{
										width: "100%",
										fontSize: 12,
										fontWeight: "bold",
										letterSpacing: 1,
										color: "#fff",
										backgroundColor: "#006241",
										padding: 8,
										textAlign: "center",
										borderRight: "1px solid white",
									}}>
									Thursday
								</Col>
								<Col
									style={{
										width: "100%",
										fontSize: 12,
										fontWeight: "bold",
										letterSpacing: 1,
										color: "#fff",
										backgroundColor: "#006241",
										padding: 8,
										textAlign: "center",
										borderRight: "1px solid white",
									}}>
									Friday
								</Col>
								<Col
									style={{
										width: "100%",
										fontSize: 12,
										fontWeight: "bold",
										letterSpacing: 1,
										color: "#fff",
										backgroundColor: "#006241",
										padding: 8,
										textAlign: "center",
									}}>
									Saturday
								</Col>
							</Row>
							{getCalendar().map((week, windex) => {
								return (
									<div key={`week-${windex}${week.week}`}>
										<Row>
											{week.days.map((d, i) => (
												<Col
													key={`day-col-${i}-${d.month() + 1}${d.date()}`}
													style={{
														padding: 0,
														borderTop: "2px solid #00624155",
														width: "100%",

														borderRight: (i + 1) % 7 <= 0 ? "none" : "1px solid #00624155",
													}}>
													<div style={{ paddingBottom: 24 }}>
														<div style={{ textAlign: "center" }}>
															<div
																style={{
																	position: "absolute",
																	right: 8,
																	top: 4,
																	fontWeight: "bold",
																	// border: "1px solid rgb(0, 98, 65)",
																	backgroundColor:
																		d === moment() ? "rgb(0, 98, 65)" : "transparent",
																	color: "rgb(0, 98, 65)",
																	borderRadius: 50,
																	padding: 2,
																	width: 25,
																	height: 25,
																	lineHeight: "20px",
																	fontSize: 12,
																}}>
																{d.month() + 1}/{d.date()}
															</div>
															<Container style={{ paddingTop: 40 }}>
																<Row
																	style={{
																		textAlign: "left",
																		justifyContent: "center",
																		paddingBottom: 12,
																	}}>
																	<Col style={{}}>
																		{forecastData && (
																			<DayCell
																				onChange={forecastChange}
																				dayObject={getValueFromForecast(
																					v.varietyCode,
																					d,
																					"oz_per_board",
																					v.perfName,
																					v.growerName
																				)}
																				placeholder="oz/board"
																				type="number"
																				label="Oz / board"
																			/>
																		)}
																	</Col>
																</Row>
																<Row
																	style={{
																		textAlign: "left",
																		justifyContent: "center",
																		paddingBottom: 12,
																	}}>
																	<Col style={{}}>
																		{forecastData && (
																			<DayCell
																				onChange={forecastChange}
																				dayObject={getValueFromForecast(
																					v.varietyCode,
																					d,
																					"number_of_boards",
																					v.perfName,
																					v.growerName
																				)}
																				placeholder="# of boards"
																				type="number"
																				label="# of boards"
																			/>
																		)}
																	</Col>
																</Row>

																<div
																	style={{
																		marginTop: 0,
																		marginLeft: 0,
																		justifyContent: "center",
																		alignItems: "center",
																		padding: 0,
																		fontWeight: "bold",
																		color: "white",
																		textAlign: "right",
																	}}>
																	<div
																		style={{
																			justifyContent: "center",
																			alignItems: "flex-end",
																			fontWeight: "bold",
																			color: "black",
																			fontSize: 12,
																			textAlign: "right",
																		}}>
																		Total Lbs: {getTotalFor(v.varietyCode, d)}
																	</div>
																</div>
															</Container>
														</div>
													</div>
												</Col>
											))}
										</Row>
									</div>
								);
							})}
						</div>
					);
				})}
		</ContentWrap>
	);
};

const mapStateToProps = (state) => {
	const { UI, Auth } = state;
	return {
		accessToken: get(Auth, "accessToken", null),
		mainPreloader: get(UI, "mainPreloader", null),
		permissionDetails: get(Auth, "permissionDetails", null),
	};
};

const mapDispatchToProps = {
	fetchLocationDetails,
	fetchHarvestForecastV2,
	updateHarvestForecast,
};
export default connect(mapStateToProps, mapDispatchToProps)(GrowerTools);
