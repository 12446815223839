import React, { useEffect, useState } from "react";
import { ContentWrap } from "../../elements";
import CountBlock from "./countBlocks/countBlock";
import CasesGraphBlock from "./ordersCasesPacked/casesGraphBlock";
import OrdersPackRateGraphBlock from "./ordersPackRate/ordersPackRateGraphBlock";
import SeedRateGraphBlock from "./seedRate/seedRateGraphBlock";
import HarvestRateGraphBlock from "./harvestRate/harvestRateGraphBlock";
import OzPerBoardGraphBlock from "./ozPerBoard/ozPerBoardGraphBlock";
import LbsHarvestedGraphBlock from "./lbsHarvested/lbsHarvestedGraphBlock";
import PondVisualization from "./pondVisualization/PondVisualization";
import "./dashboard.scss";
import { connect } from "react-redux";
import {
	getUserLocation,
	getLocationList,
	getSeedingAccess,
	getHarvestAccess,
	getOrdersAccess,
	getPondVisualizationAccess,
} from "store/selectors/dashboardSelectors";
import { createStructuredSelector } from "reselect";
import {
	fetchCountDetails,
	fetchSeedHarvestRateDetails,
	fetchDailyGraphsDetails,
	fetchPackRateGraphsDetails,
	fetchPondVisualizationData,
	fetchPondList,
} from "store/actions";
import Util from "../../utils/Util";
import { LocationDropdown } from "components/bulkAssignee/bulkAssigneeComponents";
import { useTranslation } from "react-i18next";

const Dashboard = ({
	location,
	locationList,
	fetchCountDetails,
	fetchPackRateGraphsDetails,
	fetchSeedHarvestRateDetails,
	fetchDailyGraphsDetails,
	seedingAcess,
	harvestAcess,
	ordersAcess,
	fetchPondList,
	pondVisualizationAccess,
}) => {
	const { t } = useTranslation();
	const [loadingCount, setLoadingCount] = useState(false);
	const [loadingDaily, setLoadingDaily] = useState(false);
	const [loadingPackRate, setLoadingPackRate] = useState(false);
	const [loadingSeedHarvest, setLoadingSeedHarvest] = useState(false);
	const [locationFilterFlag, setLocationFilterFlag] = useState(false);
	const [locationSlug, setLocationSlug] = useState(location && location["slug"] ? location["slug"] : "");
	const [filterLocation, setFilterLocation] = useState(location);
	const [pondVisualization, setPondVisualization] = useState(false);
	const onSuccess = (type) => {
		type === "conuntBlock" && setLoadingCount(false);
		type === "dailyGraph" && setLoadingDaily(false);
		type === "seedHarvestRate" && setLoadingSeedHarvest(false);
		type === "packedRate" && setLoadingPackRate(false);
		type === "pondVisualization" && setPondVisualization(false);
	};

	const onError = (type, e) => {
		type === "conuntBlock" && setLoadingCount(false);
		type === "dailyGraph" && setLoadingDaily(false);
		type === "seedHarvestRate" && setLoadingSeedHarvest(false);
		type === "packedRate" && setLoadingPackRate(false);
		type === "pondVisualization" && setPondVisualization(false);
	};
	useEffect(() => {
		setLoadingCount(true);
		setLoadingDaily(true);
		setLoadingSeedHarvest(true);
		setLoadingPackRate(true);
		setPondVisualization(true);
		location && !locationFilterFlag && setLocationSlug(location["slug"]);
		location &&
			!locationFilterFlag &&
			setFilterLocation({ label: location["name"], value: location["slug"], isdisabled: undefined });

		locationSlug &&
			fetchCountDetails(
				{
					location_slug: locationSlug,
					timezone: Util.getLocalTimeZone(),
					date: Util.getYMDFormat(new Date()),
				},
				onSuccess("conuntBlock"),
				onError("conuntBlock")
			);
		locationSlug &&
			fetchSeedHarvestRateDetails(
				{
					location_slug: locationSlug,
					timezone: Util.getLocalTimeZone(),
					date: Util.getYMDFormat(new Date()),
				},
				onSuccess("seedHarvestRate"),
				onError("seedHarvestRate")
			);
		locationSlug &&
			fetchDailyGraphsDetails(
				{
					location_slug: locationSlug,
					timezone: Util.getLocalTimeZone(),
					date: Util.getYMDFormat(new Date()),
				},
				onSuccess("dailyGraph"),
				onError("dailyGraph")
			);
		locationSlug &&
			fetchPackRateGraphsDetails(
				{
					location_slug: locationSlug,
					timezone: Util.getLocalTimeZone(),
					date: Util.getYMDFormat(new Date()),
				},
				onSuccess("packedRate"),
				onError("packedRate")
			);

		locationSlug && fetchPondList(locationSlug, onSuccess("pondVisualization"), onError("pondVisualization"));
	}, [
		locationSlug,
		fetchCountDetails,
		location,
		fetchSeedHarvestRateDetails,
		fetchPackRateGraphsDetails,
		fetchDailyGraphsDetails,
		locationFilterFlag,
		fetchPondList,
	]);

	const onSelectFilterLocation = (e = null) => {
		if (e) {
			setLocationSlug(e.value);
			setFilterLocation(e);
			setLocationFilterFlag(true);
		} else {
			setLocationFilterFlag(false);
			location && setLocationSlug(location["slug"]);
		}
	};

	return (
		<div className="dashboard">
			<ContentWrap>
				<div className="d-flex justify-content-between mb-4">
					<h1 className="name-class">{t("label.dashboard")}</h1>
					{Object.keys(locationList).length > 1 ? (
						<LocationDropdown value={filterLocation} options={locationList} onSelect={onSelectFilterLocation} />
					) : (
						""
					)}
				</div>
				{locationSlug && (
					<div>
						<CountBlock
							orderAcess={ordersAcess}
							seedAcess={seedingAcess}
							harvestingAcess={harvestAcess}
							loadingFlag={loadingCount}
						/>
						<div className="row count-blocks">
							{harvestAcess && <OzPerBoardGraphBlock loadingFlag={loadingDaily} />}
							{harvestAcess && <LbsHarvestedGraphBlock loadingFlag={loadingDaily} />}
							{ordersAcess && <CasesGraphBlock loadingFlag={loadingDaily} />}
							{ordersAcess && <OrdersPackRateGraphBlock loadingFlag={loadingPackRate} />}
							{seedingAcess && <SeedRateGraphBlock loadingFlag={loadingSeedHarvest} />}
							{harvestAcess && <HarvestRateGraphBlock loadingFlag={loadingSeedHarvest} />}
							{pondVisualizationAccess && (
								<PondVisualization locationSlug={locationSlug} pondVisualizationFlag={pondVisualization} />
							)}
						</div>
					</div>
				)}
			</ContentWrap>
		</div>
	);
};

const mapStateToProps = createStructuredSelector({
	location: getUserLocation,
	locationList: getLocationList,
	seedingAcess: getSeedingAccess,
	harvestAcess: getHarvestAccess,
	ordersAcess: getOrdersAccess,
	pondVisualizationAccess: getPondVisualizationAccess,
});

export default connect(mapStateToProps, {
	fetchCountDetails,
	fetchPackRateGraphsDetails,
	fetchSeedHarvestRateDetails,
	fetchDailyGraphsDetails,
	fetchPondVisualizationData,
	fetchPondList,
})(Dashboard);
