import React from "react";
import { Modal } from "react-bootstrap";
import ViewHarvestAddEdit from "./viewHarvestAddEdit";
import useModal from "utils/useModal";
import { useTranslation } from 'react-i18next';
const { Header, Title, Body } = Modal;

const ViewModal = ({ handleEditPanel, onAddOrEdit }) => {
	const { t } = useTranslation();
	const [isOpen, modalType, , closeModal, modalProps] = useModal("harvest-view");

	return (
		<Modal size="lg" show={isOpen} onHide={closeModal} className="modal-sidepanel">
			<Header closeButton>
				<Title className="modal-title">{modalType === 3 ? t("label.harvest_board_details") : t("label.harvested_details")}</Title>
			</Header>
			<Body>
				<ViewHarvestAddEdit pondsAdded={modalProps} />
			</Body>
		</Modal>
	);
};

export default ViewModal;
