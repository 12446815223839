import React, { createRef, useState, useEffect, useRef, useCallback } from "react";
import { useDispatch } from "react-redux";
import "./index.scss";
import { reset, submit } from "redux-form";
import Util from "../../utils/Util";
import ScrollContainer from "react-indiana-drag-scroll";
import { fixedColumnTable, getActionColumns, getData } from "./columns";
import { number } from "constant/formValidators";
import { keys } from "lodash";

const ActionTable = ({
	initialData,
	columns,
	fixedColumnHeading,
	fixedColumnWidth = 200,
	editable,
	onSave,
	onDelete,
	keyMapping,
	dateFormat,
	tableErrors,
	errorStrings,
	render,
	renderKey,
	slugKey,
	showActions = true,
	actionColumnWidth = showActions === false ? 0 : 100,
	customEdit,
	validation = [number],
	editPermission = true,
	deletePermission = true,
	formName = "actionForm",
	toAddList = false,
}) => {
	const dispatch = useDispatch();
	const inputRef = useRef(null);
	const draggableRef = createRef(null);
	const scrollRef = createRef(null);
	const fixedRef = createRef(null);
	const posOneRef = createRef(null);
	const posTwoRef = createRef(null);
	const posThreeRef = createRef(null);
	const actionRef = createRef(null);
	const [editableRows, setEditableRows] = useState([]);
	const [data, setdata] = useState(null);
	useEffect(() => {
		if (initialData) {
			const array = keys(initialData).sort();
			const dataObj = {};
			array.forEach((arrItem) => {
				const temp = columns.map((col) => {
					const ind = initialData[arrItem].findIndex((item) =>
						slugKey ? col.slug === item[slugKey] : col.slug === item.slug
					);
					let obj = {};
					if (ind === -1) {
						obj = {
							...(slugKey ? { [slugKey]: col.slug } : { slug: col.slug }),
							...(renderKey ? { [renderKey]: "" } : { value: "" }),
						};
					} else {
						obj = initialData[arrItem][ind];
					}
					return obj;
				});
				dataObj[arrItem] = temp;
			});
			setdata(dataObj);
		}
	}, [columns, initialData, renderKey, slugKey]);

	useEffect(() => {
		onScrollTrack();
		window.addEventListener("scroll", bodyScroll, true);
		return () => {
			window.removeEventListener("scroll", bodyScroll);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	});

	const bodyScroll = () => {
		const elem = fixedRef.current;
		if (elem) {
			const { top, height } = elem.getBoundingClientRect();
			if (height > 0) {
				if (top <= 0) {
					if (height + top <= 120) {
						posOneRef.current.style.top = height - 120 + "px";
						posTwoRef.current.style.top = height - 120 + "px";
						posThreeRef.current.style.top = height - 120 + "px";
					} else {
						posOneRef.current.style.top = Math.abs(top) + "px";
						posTwoRef.current.style.top = Math.abs(top) + "px";
						posThreeRef.current.style.top = Math.abs(top) + "px";
					}
				} else if (top > 0) {
					posOneRef.current.style.top = 0;
					posTwoRef.current.style.top = 0;
					if (posThreeRef.current) {
						posThreeRef.current.style.top = 0;
					}
				}
			}
		}
	};

	const renderKeyItem = (key) => {
		if (keyMapping) {
			const elem = keyMapping.find((item) => item.slug === key);
			if (elem) {
				return <span>{elem.name}</span>;
			}
		} else {
			return <span>{dateFormat ? Util.checkValidDateString(key) : key}</span>;
		}
	};

	const onScrollTrack = (e) => {
		const shadowElem = [].slice.call(document.getElementsByClassName("show-shadow-row"));
		const rightShadowElem = [].slice.call(document.getElementsByClassName("show-shadow-row-right"));
		if (fixedRef.current && scrollRef.current && actionRef.current) {
			const fixedPos = fixedRef.current.getBoundingClientRect().left;
			const scrollingPos = scrollRef.current.getBoundingClientRect().left;
			const actionPos = actionRef.current.getBoundingClientRect().left;
			const scrollingWidth = scrollRef.current.scrollWidth;

			if (
				scrollingWidth + Math.abs(scrollingPos) === actionPos ||
				actionPos + Math.abs(scrollingPos) === scrollingWidth
			) {
				// eslint-disable-next-line array-callback-return
				rightShadowElem.map((node) => {
					node.classList.remove("shadow-block");
					node.parentNode.classList.remove("show-shadow-right");
				});
			} else {
				// eslint-disable-next-line array-callback-return
				rightShadowElem.map((node) => {
					node.classList.add("shadow-block");
					node.parentNode.classList.add("show-shadow-right");
				});
			}

			if (fixedPos + fixedColumnWidth > scrollingPos) {
				// eslint-disable-next-line array-callback-return
				shadowElem.map((node) => {
					node.style.display = "block";

					node.parentNode.classList.add("show-shadow");
				});
			} else {
				// eslint-disable-next-line array-callback-return
				shadowElem.map((node) => {
					node.style.display = "none";
					node.parentNode.classList.remove("show-shadow");
				});
			}
		}
	};

	const onClick = useCallback((event) => {
		if (event.target.tagName === "INPUT") {
			inputRef.current = event.target;
			event.target.focus();
		} else {
			if (inputRef.current) {
				inputRef.current.blur();
				inputRef.current = null;
			}
			event.target.click();
		}
	}, []);

	const cancelEdit = (index) => {
		setEditableRows(() => editableRows.filter((item) => item !== index));
	};

	return columns && data ? (
		<>
			<div className="action-table-wrapper">
				<div ref={fixedRef} className="action-fixed-column-wrapper" style={{ width: fixedColumnWidth }}>
					{fixedColumnTable(data, fixedColumnWidth, posOneRef, fixedColumnHeading, renderKeyItem, keyMapping,toAddList)}
				</div>
				<ScrollContainer
					ref={draggableRef}
					hideScrollbars={false}
					className="action-data-column-wrapper"
					style={{ width: `calc(100% - ${fixedColumnWidth + actionColumnWidth}px)` }}
					onScroll={(e) => onScrollTrack(e)}
					onClick={onClick}>
					<div ref={scrollRef} className="action-data-column">
						{getData(
							data,
							posTwoRef,
							columns,
							slugKey,
							renderKey,
							render,
							editableRows,
							onSave,
							cancelEdit,
							validation,
							keyMapping,
							formName,
							showActions,
							toAddList
						)}
					</div>
				</ScrollContainer>
				{showActions && (
					<div style={{ width: actionColumnWidth }} ref={actionRef} className="action-table-column">
						{getActionColumns(
							data,
							actionColumnWidth,
							posThreeRef,
							editableRows,
							setEditableRows,
							onDelete,
							dispatch,
							submit,
							reset,
							cancelEdit,
							customEdit,
							editPermission,
							deletePermission,
							keyMapping,
							toAddList
						)}
					</div>
				)}
			</div>
		</>
	) : null;
};

export default ActionTable;
