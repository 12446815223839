import React, { useState } from "react";
import { Container, Button, Form } from "react-bootstrap";
import { Row, Col } from "react-bootstrap";
import { reduxForm, Field, reset, change, FieldArray, FormSection, formValueSelector } from "redux-form";
import { connect, useDispatch } from "react-redux";
import useModal from "utils/useModal";
import { otherFields, harvestedBoardForm, yesOrNo } from "./helper";
import { flagColors_2 as flagColors } from "constant/global";
import Util from "../../../utils/Util";

import {
	fetchTaskUsers,
	resetAddEdit,
	createHarvestingDetails,
	updateHarvestedDetails,
	updateHarvestedBoardDetails,
	fetchHarvestDetails,
} from "store/actions";
import FormGroups from "utils/formGroup";
import { errorMessage } from "utils/errorMessage";
import { successMessage } from "utils/successMessage";
import { ButtonLink } from "elements";
import TableLoader from "utils/table/tableLoader";
import { isEmpty } from "lodash";
import i18next from "i18next";

const renderharvestedBoard = ({ getOptions, onPondChange, fields, meta: { error, submitFailed } }) => {
	const pond = harvestedBoardForm.pond;
	const lane = harvestedBoardForm.lane;
	const flag_color = harvestedBoardForm.flag_color;
	const board_count = harvestedBoardForm.board_count;
	const weights = harvestedBoardForm.weights;

	const className = "col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12";

	return (
		<div className="harvest-board-outer">
			{fields.map((item, index) => (
				<Row key={index} className="harvest-board-row">
					<Col className={className}>
						<Field
							name={`${item}.${pond.name}`}
							type={pond.type}
							component={FormGroups}
							label={i18next.t(pond.label)}
							validate={pond.validate}
							placeholder={i18next.t(pond.placeholder)}
							multiple={false}
							options={getOptions(pond.name)}
							onChange={() => onPondChange(index)}
						/>
					</Col>
					<Col className={className}>
						<Field
							name={`${item}.${lane.name}`}
							type={lane.type}
							component={FormGroups}
							label={i18next.t(lane.label)}
							validate={lane.validate}
							placeholder={i18next.t(lane.placeholder)}
							multiple={false}
							options={getOptions(lane.name, index)}
						/>
					</Col>
					<Col className={className}>
						<Field
							name={`${item}.${flag_color.name}`}
							type={flag_color.type}
							component={FormGroups}
							label={i18next.t(flag_color.label)}
							validate={flag_color.validate}
							placeholder={i18next.t(flag_color.placeholder)}
							multiple={false}
							options={getOptions(flag_color.name)}
						/>
					</Col>
					<Col className={className}>
						<Field
							name={`${item}.${board_count.name}`}
							type={board_count.type}
							component={FormGroups}
							placeholder={i18next.t(board_count.placeholder)}
							label={i18next.t(board_count.label)}
							validate={board_count.validate}
						/>
					</Col>
					<Col className={"col-12"}>
						<Field
							name={`${item}.${weights.name}`}
							type={weights.type}
							component={FormGroups}
							placeholder={i18next.t(weights.placeholder)}
							label={i18next.t(weights.label)}
							validate={weights.validate}
							formName="viewHarvestForm"
						/>
					</Col>

					{index + 1 === fields.length && (
						<Col className={`${index === 0 ? "col-12" : "col-6"} d-flex justify-content-center text-center`}>
							<ButtonLink onClick={() => fields.push({})}>
								<i className="icon icon-plus mr-2" />
								{i18next.t("label.Add")}
							</ButtonLink>
						</Col>
					)}
					{fields.length > 1 && (
						<Col
							className={`${
								index + 1 !== fields.length ? "col-12" : "col-6"
							} col-6 d-flex justify-content-center text-center`}>
							<ButtonLink className="remove-link-btn" onClick={() => fields.remove(index)}>
								<i className="icon icon-minus mr-2" />
								{i18next.t("label.remove")}
							</ButtonLink>
						</Col>
					)}
				</Row>
			))}

			{submitFailed && error && <span>{error}</span>}
		</div>
	);
};

const ViewHarvestAddEdit = ({
	slug,
	handleSubmit,
	fetchTaskUsers,
	form,
	resetAddEdit,
	pondList,
	laneList,
	createHarvestingDetails,
	updateHarvestedDetails,
	updateHarvestedBoardDetails,
	fetchHarvestDetails,
	boardValues,
	lanesPerPond,
	pondsAdded,
}) => {
	const [, modalType, , closeModal] = useModal("harvest-view");
	const footerMargin = modalType === 3 ? 15 : 0;
	const [btnTitle, setbtnTitle] = useState(i18next.t("label.save"));
	const [loading, setloading] = useState(false);
	const dispatch = useDispatch();

	const submitBoardDetails = (values) => {
		if (!loading) {
			setloading(true);
			setbtnTitle(i18next.t("label.saving"));
			const boardDetails = values.harvestedBoard.map((item) => {
				return {
					...item,
					flag_color: item.flag_color.value,
					lane: item.lane.value,
					pond: item.pond.value,
					weight: item.weight.map((elem) => Number(elem.label)),
				};
			});

			const submissionData =
				modalType === 3
					? { board_details: boardDetails }
					: {
							...values,
							...(values.harvested_blade_height && values.harvested_blade_height.height1
								? { harvested_blade_height_start_range: values.harvested_blade_height.height1 }
								: { harvested_blade_height_start_range: "" }),
							...(values.harvested_blade_height && values.harvested_blade_height.height2
								? { harvested_blade_height: values.harvested_blade_height.height2 }
								: { harvested_blade_height: "" }),
							blades_replaced: values.blades_replaced.value,
							board_details: boardDetails,
					  };

			const onSuccess = () => {
				reset(form);
				setloading(false);
				setbtnTitle(i18next.t("label.saving"));

				closeModal();
			};
			const successHandler = (event) => {
				// modalType === 3
				// 	? updateHarvestedBoardDetails(submissionData.board_details)
				// 	: updateHarvestedDetails(submissionData);
				successMessage(event);
				fetchHarvestDetails(slug, onSuccess, errorHandler);
			};

			const errorHandler = (event) => {
				errorMessage(event);
				setloading(false);
				setbtnTitle(i18next.t("label.saving"));
			};

			createHarvestingDetails(submissionData, slug, modalType, successHandler, errorHandler);
		}
	};

	const renderFormFields = () => {
		let columns = [];
		columns = otherFields.map((fields, idx) => {
			return (
				<React.Fragment key={idx}>
					<Row>
						<div className="form-headers">{fields.heading}</div>
					</Row>
					<Row>{getFormFieldHtml(fields)}</Row>
				</React.Fragment>
			);
		});

		return <>{columns}</>;
	};

	const getFormFieldHtml = (fields, tabValue) => {
		let formFieldHtml = [];

		formFieldHtml = fields.fields.map((field, idx) => {
			return (
				<React.Fragment key={idx}>
					<Col
						className={
							field.name === "harvested_comments"
								? "col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
								: "col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12"
						}>
						<Form.Group controlId={`formControl ${field.name}`}>
							{field.name === "blades_replaced" ? (
								<Field
									key={field.name}
									name={field.name}
									type={field.type}
									label={i18next.t(field.label)}
									placeholder={i18next.t(field.placeholder)}
									component={FormGroups}
									validate={field.validate}
									multiple={false}
									options={getOptions(field.name)}
								/>
							) : field.name === "harvested_blade_height" ? (
								<>
									<FormSection name="harvested_blade_height">
										<Row>
											<Col>
												<Field
													key={"height1"}
													name={"height1"}
													type={field.type}
													placeholder={i18next.t("label.from")}
													component={FormGroups}
													validate={field.validate}
													multiple={false}
												/>
											</Col>
											<Col>
												<Field
													key={"height2"}
													name={"height2"}
													type={field.type}
													placeholder={i18next.t("label.to")}
													component={FormGroups}
													validate={field.validate}
													multiple={false}
												/>
											</Col>
										</Row>
									</FormSection>
									<span className="form-label">{i18next.t(field.label)}</span>
								</>
							) : (
								<Field
									key={field.name}
									name={field.name}
									type={field.type}
									label={i18next.t(field.label)}
									placeholder={i18next.t(field.placeholder)}
									component={FormGroups}
									validate={field.validate}
								/>
							)}
						</Form.Group>
					</Col>
				</React.Fragment>
			);
		});
		return <>{formFieldHtml}</>;
	};

	const onPondChange = (id) => {
		boardValues &&
			boardValues[id] &&
			boardValues[id]["lane"] &&
			boardValues[id]["lane"]["value"] &&
			dispatch(change("viewHarvestForm", `harvestedBoard[${id}].lane`, null));
	};

	const getLaneList = (pond, id) => {
		const lanesSelected = [];
		boardValues.forEach((item, idx) => {
			if (
				idx !== id &&
				// boardValues &&
				// boardValues[idx] &&
				item["pond"] &&
				item["pond"]["value"] &&
				item["pond"]["value"].toString() === pond.toString() &&
				item["lane"] &&
				item["lane"]["value"]
				// formValues[`lm_${idx}`]["lane_number"]["value"] !== formValues[`lm_${id}`]["lane_number"]["value"]
			) {
				lanesSelected.push(item["lane"]["value"].toString());
			}
		});

		pondsAdded &&
			pondsAdded.forEach((item) => {
				if (item.pond.id.toString() === pond.toString()) {
					lanesSelected.push(item.lane.toString());
				}
			});

		const arr = Array(lanesPerPond)
			.fill()
			.map((_, i) => ({
				label: i + 1,
				value: (i + 1).toString(),
				...(!isEmpty(lanesSelected)
					? { isdisabled: lanesSelected.includes((i + 1).toString()) ? true : false }
					: { isdisabled: false }),
			}));

		return arr;
	};

	const getOptions = (field, id) => {
		switch (field) {
			case "blades_replaced":
				return yesOrNo;
			case "flag_color":
				return flagColors ? Object.values(flagColors) : [];
			case "pond":
				return pondList ? Util.mapOptions(pondList, "value", "id") : [];
			case "lane":
				// return laneList ? laneList : [];

				return (
					(boardValues &&
						boardValues[id] &&
						boardValues[id].pond &&
						boardValues[id].pond.value &&
						getLaneList(boardValues[id].pond.value, id)) ||
					[]
				);

			default:
				return [];
		}
	};

	return (
		<Form onSubmit={handleSubmit(submitBoardDetails)} className="viewHarvestForm">
			<Container style={{ postion: "relative" }}>
				<FieldArray
					name="harvestedBoard"
					component={renderharvestedBoard}
					onPondChange={onPondChange}
					getOptions={getOptions}
				/>
				{modalType !== 3 && renderFormFields()}
				{loading && (
					<div className="table-loader-wrapper">
						<TableLoader />
					</div>
				)}
			</Container>
			<div className="modal-footer save-footer" style={{ marginTop: footerMargin }}>
				<Button variant="secondary" onClick={closeModal}>
					{i18next.t("label.cancel")}
				</Button>
				<Button variant="primary" type="submit" disabled={loading}>
					{btnTitle}
				</Button>
			</div>
		</Form>
	);
};

const mapStateToProps = (state) => {
	const {
		Modals: { modalType },
		Harvest: { harvestDetails, pondList, laneList, lanesPerPond },
	} = state;
	const selector = formValueSelector("viewHarvestForm");
	const addInitial = {
		harvestedBoard: [
			{
				pond: "",
				lane: "",
				flag_color: "",
				board_count: "",
				weight_1: "",
				weight_2: "",
				weight_3: "",
			},
		],
	};

	const setInitialValues = () => {
		const harvestedBoard =
			harvestDetails.harvested_board_details &&
			harvestDetails.harvested_board_details.map((item) => {
				return {
					...item,
					pond: { value: item.pond.id, label: item.pond.value },
					lane: { value: item.lane, label: item.lane },
					flag_color: flagColors[item.flag_color],
					weight: item.weight.map((elem, i) => ({
						label: elem,
						value: `${elem}-${i}`,
					})),
				};
			});
		const formatedInitialValueArray = {
			harvestedBoard,
			actual_grow_cycle: harvestDetails.actual_grow_cycle,
			down_time: harvestDetails.down_time,
			wastage_pound: harvestDetails.wastage_pound,
			harvested_blade_height: {
				height1: harvestDetails.harvested_blade_height_start_range,
				height2: harvestDetails.harvested_blade_height,
			},
			boards_broken: harvestDetails.boards_broken,
			blades_replaced: harvestDetails.blades_replaced === 1 ? { label: "Yes", value: 1 } : { label: "No", value: 0 },

			harvested_comments: harvestDetails.harvested_comments,
		};
		return formatedInitialValueArray;
	};

	return {
		slug: harvestDetails.task_id,
		laneList: laneList,
		pondList: pondList,
		initialValues: modalType === 2 ? setInitialValues() : addInitial,
		boardValues: selector(state, "harvestedBoard"),
		lanesPerPond: lanesPerPond,
	};
};
export default connect(mapStateToProps, {
	fetchTaskUsers,
	resetAddEdit,
	createHarvestingDetails,
	updateHarvestedDetails,
	updateHarvestedBoardDetails,
	fetchHarvestDetails,
})(
	reduxForm({
		form: "viewHarvestForm",
		enableReinitialize: true,
	})(ViewHarvestAddEdit)
);
