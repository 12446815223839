import React, { useEffect, useState } from "react";
import { BasicDetails } from "./view/viewComponents";
import { connect } from "react-redux";
import AuditLog from "../../auditLog";
import { startSeedingofSGTask, toggleViewLoader, getAuditLogOfTransferRequest } from "../../../store/actions";
import { errorMessage } from "../../../utils/errorMessage";
import useModal from "../../../utils/useModal";
import TaskDetailsModal from "./taskDetails";
import FullFillmentDetailsModal from "./fullFillmentDetailsModal";
import { getTransferRequestDetails, getLocationSkuList } from "../../../store/actions";
import Util from "../../../utils/Util";
import FulFillmentDetails from "./fulFillmentDetails";
import RequestedDetailsModel from "./requestedDetailsModel";
import TableLoader from "../../../utils/table/tableLoader";

const TransferRequest = ({
	viewPageLoader,
	toggleViewLoader,
	transferSlug,
	getTransferRequestDetails,
	getLocationSkuList,
	taskDetails,
	requestDetails,
	fulFillmentDetails,
	getAuditLogOfTransferRequest,
	acitivityLog,
	statusDropDown,
	locationSkuList,
	permissionDetails,
}) => {
	const [listingPageLoader, setListingPageLoader] = useState(true);
	const [, , showFullFillment] = useModal("FullFillmentDetails");
	const [, setstartTaskBtn] = useState("Start Task");
	const [showActivityLog, setShowActivityLog] = useState(true);
	const [fetchingLog, setFetchingLog] = useState(false);
	const [, modalType, showModal] = useModal("taskDetails");

	const [initialValueArray, setInitialValueArray] = useState([]);

	const isEditing = modalType === 2 || modalType === 4;
	useEffect(() => {
		const body = {
			slug: transferSlug,
		};

		const onSuccess = () => {
			setListingPageLoader(false);
		};
		const onError = (err) => {};
		getTransferRequestDetails(body, onSuccess, onError);
		getLocationSkuList();
	}, [getLocationSkuList, getTransferRequestDetails, transferSlug]);

	const onClickEditDetails = (editType) => {
		if (editType === 2) {
			showModal(2);
		}
		if (editType === 4) {
			showModal(4);
		}
	};

	const onClickFullFillmentDetails = (details, editType) => {
		if (editType === 2) {
			showFullFillment(2);
		}
		if (editType === 4) {
			showFullFillment(4);
		}
		const getDate = Util.getDateObjectFormat(details.delivery_date);
		if (details && details.is_edittable === 0) {
			const recipeInitialValue = {
				sku: details.sku_name,
				deliverydate: getDate,
				slug: details.transfer_request_details_slug,
				cases: details.cases_remaining,
			};
			setInitialValueArray(recipeInitialValue);
		} else {
			const recipeInitialValue = {
				sku: details.sku_name,
				deliverydate: getDate,
				slug: details.transfer_request_details_slug,
			};
			setInitialValueArray(recipeInitialValue);
		}
	};

	// Action : Footer button clicks
	const onClickStatusAction = (currentStatus) => {
		if (currentStatus === 1) {
			doStartTask();
		}
		// Add seeding Details
		// if (currentStatus === 2) {
		// 	showModal(1);
		// }
		// Show activity log
		if (currentStatus === 5) {
			showActivityLog && fetchAuditLog();
			setShowActivityLog(!showActivityLog);
		}
	};
	// const isAssigneeSelected = taskDetails && taskDetails.assignee && taskDetails.assignee.id ? true : false;
	const isAssigneeSelected = false;

	const doStartTask = () => {
		if (isAssigneeSelected) {
			toggleViewLoader(true);
			setstartTaskBtn("Starting...");
		} else {
			errorMessage("NO_ASSIGNEE_SELECTED");
		}
	};

	const doFetchTaskDetails = () => {
		const body = {
			slug: transferSlug,
		};
		getTransferRequestDetails(body);
	};

	// Fetch Log
	const fetchAuditLog = () => {
		setFetchingLog(true);
		const onAuditSuccess = () => {
			setFetchingLog(false);
		};
		const onAuditError = (err) => {
			errorMessage(err);
			setFetchingLog(false);
		};
		const data = {
			slug: transferSlug,
		};
		getAuditLogOfTransferRequest(data, onAuditSuccess, onAuditError);
	};

	// Initial Values
	const getFormInitialValues = () => {
		if (taskDetails) {
			const statusArray = Util.mapOptions(statusDropDown, "status", "key");
			const getCurrentStatus = statusArray.find((o) => o.value === taskDetails.status);
			if (isEditing) {
				if (taskDetails && taskDetails.is_status_change === false) {
					return {
						status: taskDetails.status,
						comment: taskDetails.comments,
					};
				} else {
					return {
						status: getCurrentStatus,
						comment: taskDetails.comments,
					};
				}
			}
		}
	};

	const addPermission =
		permissionDetails && permissionDetails.cross_supply && permissionDetails.cross_supply.permissions.add_fulfilment
			? permissionDetails.cross_supply.permissions.add_fulfilment
			: "";

	return (
		<>
			<div className="sg-view-wrapper position-relative transplant-view-wrapper">
				<div className="position-relative">
					{listingPageLoader === true ? (
						<div className="table-loader-wrapper">
							<TableLoader />
						</div>
					) : (
						<div className="sg-view-content">
							<>
								<BasicDetails taskDetails={taskDetails} onClickEditDetails={onClickEditDetails} />

								{/* requested details table */}
								<RequestedDetailsModel
									taskDetails={requestDetails}
									editable={true}
									locationSkuList={locationSkuList && locationSkuList}
									doFetchTaskDetails={doFetchTaskDetails}
									onClickFullFillmentDetails={onClickFullFillmentDetails}
									permissionDetails={permissionDetails}
								/>

								{/* fulfillment table  */}
								{fulFillmentDetails.length > 0 && (
									<FulFillmentDetails
										taskDetails={fulFillmentDetails}
										editable={true}
										locationSkuList={locationSkuList && locationSkuList}
										doFetchTaskDetails={doFetchTaskDetails}
										onClickFullFillmentDetails={onClickFullFillmentDetails}
										permissionDetails={permissionDetails}
									/>
								)}

								{/* task basic details form */}
								<TaskDetailsModal
									editable={taskDetails && taskDetails.is_status_change === false ? true : false}
									initialValues={getFormInitialValues()}
									slug={(taskDetails && taskDetails.slug) || ""}
									statusDropDown={statusDropDown}
									doFetchTaskDetails={doFetchTaskDetails}
									viewPageLoader={viewPageLoader}
									permissionDetails={permissionDetails}
								/>
								{/* fullfillment details form */}
								{addPermission && (
									<FullFillmentDetailsModal
										formValues={fulFillmentDetails && fulFillmentDetails}
										initialValues={(initialValueArray && initialValueArray) || []}
										locationSkuList={locationSkuList && locationSkuList}
										doFetchTaskDetails={doFetchTaskDetails}
									/>
								)}

								{/* Activity Log */}
								<AuditLog log={acitivityLog} loading={fetchingLog} onClickStatusAction={onClickStatusAction} />
							</>
						</div>
					)}
				</div>
			</div>
		</>
	);
};

const mapStateToProps = (state) => ({
	permissionDetails: state.Auth.permissionDetails,
	viewPageLoader: state.SeedingGermination.viewPageLoader,
	statusDropDown:
		state.TransferRequest &&
		state.TransferRequest.taskDetails &&
		state.TransferRequest.taskDetails.statusDropdown &&
		state.TransferRequest.taskDetails.statusDropdown,
	taskDetails:
		state.TransferRequest &&
		state.TransferRequest.taskDetails &&
		state.TransferRequest.taskDetails.transfer_request_data &&
		state.TransferRequest.taskDetails.transfer_request_data[0] &&
		state.TransferRequest.taskDetails.transfer_request_data[0],
	requestDetails:
		state.TransferRequest &&
		state.TransferRequest.taskDetails &&
		state.TransferRequest.taskDetails.request_details &&
		state.TransferRequest.taskDetails.request_details,
	fulFillmentDetails:
		state.TransferRequest && state.TransferRequest.taskDetails && state.TransferRequest.taskDetails.fulfilment_details,
	acitivityLog:
		state.TransferRequest && state.TransferRequest.auditLogTransferRequest && state.TransferRequest.auditLogTransferRequest,
	locationSkuList: state.TransferRequest && state.TransferRequest.locationSkuList && state.TransferRequest.locationSkuList,
});
const mapDispatchToProps = {
	startSeedingofSGTask,
	toggleViewLoader,
	getTransferRequestDetails,
	getAuditLogOfTransferRequest,
	getLocationSkuList,
};

export default connect(mapStateToProps, mapDispatchToProps)(TransferRequest);
