import fetchHandler from "../../utils/fetchHandler";
import { KPI } from "../types";

export const fetchLocationKpiDetails = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `view-target-values`,
		method: "POST",
		actionType: KPI.GET_LOCATION_KPI_DETAILS,
		body: JSON.stringify(values),
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const addLocationKpiDetails = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `update-target-values`,
		method: "POST",
		actionType: KPI.UPDATE_KPI_DETAILS,
		body: JSON.stringify(values),
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};
