import { SEEDS } from "../types";
const initialState = {
	renderDisplay: 0,
	prevRenderDisplay: 0,
	seedDetails: [],
	seedDropdownList: [],
	allSeedDetails: [],
};
export default (state = initialState, action) => {
	switch (action.type) {
		case SEEDS.SET_SEEDS_VIEW_RENDER:
			return {
				...state,
				prevRenderDisplay: state.renderDisplay,
				renderDisplay: action.payload,
			};
		case SEEDS.FETCH_SEED_DETAILS:
			return {
				...state,
				seedDetails: action.payload ? action.payload.data : [],
			};
		case SEEDS.GET_SEEDS_MINI_LIST:
			return {
				...state,
				seedDropdownList: action.payload.data || [],
			};
		case SEEDS.FETCH_SEEDS:
			return {
				...state,
				allSeedDetails: action.payload ? action.payload.data : [],
			};
		default:
			return state;
	}
};
