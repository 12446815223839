import React, { useState } from "react";
import "./checkboxStyles.scss";
import styled, { css } from "styled-components";

/**
 *
 * @param {String} type Type of checkbox tick mark [single, normal]
 * @param {String} label Label for checkbox, not mandatory
 */
const Checkbox = ({ type = "normal", label = "",inputClass="", wrapperClass, ...props }) => (
	<label className={`checkboxWrapper ${wrapperClass} ${props.disabled && "disabled"}`}>
		{label}
		<input type="checkbox" {...props} />
		<span className={`checkboxMark ${type} ${inputClass}`}></span>
	</label>
);

const Span = styled.span`
	padding-left: 2px;
	padding-right: 2px;
	color: #111;

	${(props) =>
		props.tooltipWidth &&
		css`
			width: ${props.tooltipWidth}px;
		`}
	${(props) =>
		props.tooltipFontSize &&
		css`
			font-size: ${props.tooltipFontSize}px;
		`}

	${(props) =>
		props.tooltipLeftPostion &&
		css`
			left: ${props.tooltipLeftPostion}px;
		`}
	${(props) =>
		props.bottomArrowLeftPosition &&
		css`
			&:before {
				left: ${props.bottomArrowLeftPosition}%;
			}
		`}
`;

export const ToolTipCheckBox = ({
	type = "normal",
	tooltipFontSize = 12,
	tooltipText,
	tooltipWidth = "auto",
	label = "",
	wrapperClass,
	bottomArrowLeftPosition,
	tooltipLeftPostion,
	...props
}) => {
	const [tooltip, settooltip] = useState(false);

	return (
		<label className={`checkboxWrapper ${wrapperClass} ${props.disabled && "disabled"}`}>
			{label}
			<input type="checkbox" {...props} />
			<div
				onMouseEnter={() => settooltip(true)}
				onMouseLeave={() => settooltip(false)}
				className={`checkboxMark ${type}`}>
				{tooltipText && tooltip && (
					<Span
						className="tooltip-btn"
						tooltipWidth={tooltipWidth}
						tooltipFontSize={tooltipFontSize}
						tooltipLeftPostion={tooltipLeftPostion}
						bottomArrowLeftPosition={bottomArrowLeftPosition}>
						{tooltipText}
					</Span>
				)}
			</div>
		</label>
	);
};
export default Checkbox;
