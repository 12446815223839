import { WSR } from "../types";

const initialState = {
	loading: false,
	dropdownData: null,
	data: null,
	renderType: 0,
	columns: null,
	dateSlugMapping: null,
};

export default function (state = initialState, { type, payload }) {
	switch (type) {
		case WSR.LOADER_WSR:
			return {
				...state,
				loading: !state.loading,
			};

		case WSR.SET_DROPDOWN_DATA_WSR:
			return {
				...state,
				dropdownData: payload.data,
			};
		case WSR.SET_RENDER_TYPE_WSR:
			return {
				...state,
				renderType: payload,
			};
		case WSR.REFRESH_LISTING_WSR:
			return {
				...initialState,
				dropdownData: state.dropdownData,
				renderType: state.renderType,
			};
		case WSR.SET_DATA_WSR:
			return {
				...state,
				data: payload,
			};
		case WSR.SET_COLUMNS_WSR:
			return {
				...state,
				columns: payload,
			};
		case WSR.SET_LIST_DATA_WSR:
			const temp = {};
			const slugMap = {};
			// eslint-disable-next-line array-callback-return
			payload.data.map((item) => {
				if (item.result_data) {
					temp[item.date] = item.result_data;
					slugMap[item.date] = item.slug;
				}
			});

			return {
				...state,
				data: temp,
				dateSlugMapping: slugMap,
			};
		default:
			return state;
	}
}
