import React, { useState, useEffect } from "react";
import { Button, Row, Col, Form } from "react-bootstrap";
import { connect } from "react-redux";
import { reduxForm, Field, change,destroy } from "redux-form";
import { createStructuredSelector } from "reselect";
import {
	getFormToDate,
	getLocationList,
	getFormFromDate,
	getThirtyWeeks,
	getSelectedWeekDates,
	getNutrientList,
	getRenderType,
	getLocationData,
	getActivePondTabLr,
	// getNutrientList,
	// getProductVarietyList,
	// getRenderType,
	// getSeasonList,
} from "store/selectors/labResultSelectors";
import {
	fetchDataLR,
	setColumnsLR,
	setDataLR,
	setLoaderLR,
	setFormData,
	setDateMapping,
	setCroptimalData,
	setCroptimalSelection,
	setActivePonds,
} from "store/actions";

import FormGroups from "utils/formGroup";
import { wsrFormFields } from "./formHelper";

import moment from "moment";
import { errorMessage } from "utils/errorMessage";
import Util from "utils/Util";
import { useDispatch } from "react-redux";

const LabResultForm = ({
	handleSubmit,
	reset,
	submitting,
	pristine,
	locationList,
	fromDate,
	toDate,
	maxDate,
	selectedWeeks,
	renderType,
	nutrientList,
	setColumnsLR,
	setFormData,
	setDataLR,
	setLoaderLR,
	fetchDataLR,
	locationData,
	setDateMapping,
	setCroptimalData,
	setCroptimalSelection,
	setActivePonds,
	getActivePondTabLr,
	pondTabs,
}) => {
	const [startDate, setStartDate] = useState(null);
	const [locationPondMappedArray, setLocationPondMappedArray] = useState([]);
	const dispatch = useDispatch();
	const [endDate, setEndDate] = useState(null);
	const getDays = (from, to) => {
		const dateArray = [];
		let curDate = moment(from);
		const stDate = moment(to);
		while (curDate <= stDate) {
			dateArray.push(moment(curDate).format("MM/DD/YYYY"));
			curDate = moment(curDate).add(7, "days");
		}

		return dateArray;
	};
	const onSubmit = (values) => {
		const submitData = {
			location_slug: values.location_slug.value,
			pond: values.pond.map((item) => item.value),
			start_date: Util.getMDYFormatUtcDate(values.week[0]),
			end_date: Util.getMDYFormatUtcDate(values.week[1]),
		};
		setFormData(submitData);
		if (renderType === 0) {
			setLoaderLR();

			const successHandler = () => {
				setFormData(submitData);
				setLoaderLR();
				setColumnsLR(nutrientList);
				setCroptimalData([]);
				setCroptimalSelection(null);
			};
			const errorHandler = (e) => {
				errorMessage(e);
				setLoaderLR();
				setCroptimalData([]);
				setCroptimalSelection(null);
			};
			fetchDataLR(submitData, successHandler, errorHandler);
		} else {
			const data = {};
			setDataLR([]);

			// eslint-disable-next-line array-callback-return
			const selectedWeeks = getDays(values.week[0], values.week[1]);
			const dateKeyMapping = selectedWeeks.map((item) => ({
				name: item,
				slug: item,
			}));
			setDateMapping(dateKeyMapping);
			// eslint-disable-next-line array-callback-return
			getDays(values.week[0], values.week[1]).map((item) => {
				data[item] = nutrientList.map((p_item) => ({
					name: p_item.name,
					slug: p_item.slug,
					value: "",
				}));
			});

			setColumnsLR(nutrientList);
			setDataLR(data);
			setCroptimalData([]);
			setCroptimalSelection(null);
			setActivePonds(values.pond);
		}
	};

	const getOptions = (name) => {
		switch (name) {
			case "location_slug":
				return locationList;
			case "pond":
				return Util.mapOptions(locationPondMappedArray, "value", "id") || [];

			default:
				return [];
		}
	};

	const onDateChange = (dates) => {
		const [start, end] = dates;
		if (dates) {
			const first = new Date(moment(start).startOf("week"));
			const last = end ? new Date(moment(end).endOf("week")) : null;

			if (startDate && endDate) {
				setStartDate(first);
				setEndDate(null);
			} else {
				setStartDate(first);
				last && setEndDate(last);
			}
		}
	};

	useEffect(() => {
		if (startDate) {
			const first = new Date(moment(startDate).startOf("week"));
			const last = endDate ? new Date(moment(endDate).endOf("week")) : null;
			dispatch(change("labResultForm", "week", [first, last]));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [startDate, endDate]);

	const remapLocations = (value) => {
		value && setLocationPondMappedArray(locationData[value]["pond_details"]);
		dispatch(change("labResultForm", "pond", []));
		pondTabs && pondTabs.forEach((item) => {
			dispatch(destroy(`labResultTable_${item.label}`));
		});
	};

	const resetHandler = () => {
		pondTabs && pondTabs.forEach((item) => {
			dispatch(destroy(`labResultTable_${item.label}`));
		});
		reset();
		setStartDate(null);
		setEndDate(null);
	};

	const getFormFieldHtml = (formfields) => {
		let formFieldHtml = [];

		formFieldHtml = formfields.map((field, idx) => {
			return (
				<React.Fragment key={idx}>
					<Col className="croptimals-form-column z-index-class">
						<Form.Group
							// className={field.name === "exclude_wastage" && "mt-5"}
							controlId={`formControl ${field.name}`}>
							{field.name === "week" ? (
								<Field
									name={field.name}
									type={field.type}
									label={field.label}
									placeholder={field.placeholder}
									component={FormGroups}
									startDate={startDate}
									endDate={endDate}
									onChange={onDateChange}
									validate={field.validate}
									dateFormat={"MM-DD-YYYY"}
									pickerTypeSelector={0}
									maxDate={fromDate && !toDate && maxDate}
									onBlur={(event) => event.preventDefault()}
								/>
							) : (
								<Field
									name={field.name}
									type={field.type}
									label={field.label}
									placeholder={field.placeholder}
									component={FormGroups}
									validate={field.validate}
									options={getOptions(field.name)}
									startDate={startDate}
									endDate={endDate}
									selectMenuHeight={200}
									onChange={(event) => field.name === "location_slug" && remapLocations(event.value)}
								/>
							)}
						</Form.Group>
					</Col>
				</React.Fragment>
			);
		});

		return (
			<Row className="wsr-form-row">
				{formFieldHtml}
				<Col className="form-submit-col-wsr">
					<Button type="submit" disabled={submitting} className="apply-btn">
						Apply
					</Button>
					<Button className="reset-btn" onClick={resetHandler} disabled={pristine || submitting}>
						<i className="icon icon-reset mr-2" />
					</Button>
				</Col>
			</Row>
		);
	};

	return (
		<div className="wsr-form-wrapper">
			<Form onSubmit={handleSubmit(onSubmit)} className="labResultForm">
				{getFormFieldHtml(wsrFormFields)}
			</Form>
		</div>
	);
};

const mapStateToProps = createStructuredSelector({
	locationList: getLocationList,
	fromDate: getFormFromDate,
	toDate: getFormToDate,
	maxDate: getThirtyWeeks,
	selectedWeeks: getSelectedWeekDates,
	nutrientList: getNutrientList,
	// pvList: getProductVarietyList,
	// seasonList: getSeasonList,
	renderType: getRenderType,
	locationData: getLocationData,
	pondTabs: getActivePondTabLr,
});

export default connect(mapStateToProps, {
	setLoaderLR,
	setColumnsLR,
	setFormData,
	setDataLR,
	fetchDataLR,
	setDateMapping,
	setCroptimalData,
	setCroptimalSelection,
	setActivePonds,
})(reduxForm({ form: "labResultForm", enableReinitialize: true })(LabResultForm));
