import React, { useState } from "react";
import { connect } from "react-redux";
import { get } from "lodash";
import "../locations.scss";
import { ContentWrap, ButtonPrimary, Breadcrumbs } from "../../../elements";
import Listing from "../../../partials/listings";
import { renderColumns } from "./helper";
import { fetchAllLocations as fetchLocations, deleteLocation } from "../../../store/actions";
import ConfirmModal from "../../../utils/confirmModal";
import { errorMessage } from "../../../utils/errorMessage";
import { successMessage } from "../../../utils/successMessage";
import LocationForm from "../manage/locationDetailsForm";
import useModal from "../../../utils/useModal";
import {
	fetchLocationDetails,
	clearLocationDetails,
	fetchLocationSkuPriority,
	fetchLocationCustomerPriority,
	fetchProductBasedList,
	fetchSkuBasedList,
} from "../../../store/actions/locationActions";
import TableLoader from "utils/table/tableLoader";

const Locations = ({
	fetchLocations,
	deleteLocation,
	fetchLocationDetails,
	clearLocationDetails,
	fetchLocationSkuPriority,
	fetchLocationCustomerPriority,
	fetchProductBasedList,
	fetchSkuBasedList,
	permissionDetails,
}) => {
	const [refetch, setRefetch] = useState(0);
	const [filter, setFilter] = useState([]);
	const [locationToDelete, setLocationToDelete] = useState(null);
	const [loading, setloading] = useState(false);
	const [, , showModal] = useModal("locations");

	const handleAddPanel = () => {
		clearLocationDetails();
		showModal(0);
	};
	const handleEditPanel = (slug) => {
		setloading(true);
		const successHandler = () => onSuccess(slug ? 1 : 0, slug);
		fetchLocationDetails(slug, successHandler, onError);
	};
	const handleViewPanel = (slug) => {
		setloading(true);
		const successHandler = () => onSuccess(2);
		fetchLocationDetails(slug, successHandler, onError);
	};

	const confirmDelete = (slug) => setLocationToDelete(slug);

	const onSuccess = (modalType, slug) => {
		setloading(false);
		showModal(modalType, slug);
	};
	const onError = (e) => {
		setloading(false);
		errorMessage(e);
	};

	// Delete User
	const onDeleteLocation = (id) => {
		const successHandler = (e) => {
			setLocationToDelete(null);
			setRefetch(refetch + 1);
			successMessage(e);
		};
		const errorHandler = (e) => {
			setLocationToDelete(null);
			errorMessage(e);
		};
		deleteLocation(id, successHandler, errorHandler);
	};

	const handleSkuReorder = (slug) => {
		setloading(true);
		const successHandler = (e) => {
			setloading(false);
			e.data && e.data.length ? showModal(3, slug) : errorMessage("SKU_NOT_MAPPED");
		};
		fetchLocationSkuPriority(slug, successHandler, onError);
	};

	const handleCustomerReorder = (slug) => {
		setloading(true);
		//const successHandler = () => onSuccess(4, slug);
		const successHandler = (e) => {
			setloading(false);
			e.data && e.data.length ? showModal(4, slug) : errorMessage("CUSTOMERS_NOT_MAPPED");
		};
		fetchLocationCustomerPriority(slug, successHandler, onError);
	};

	const handelSkuStatus = (slug) => {
		setloading(true);
		const successHandler = (res) => {
			setloading(false);
			res?.status === "success" ? showModal(5, slug) : errorMessage("SKUSTATUS_NOT_MAPPED");
		};
		const body = {
			location_slug: slug,
		};
		fetchSkuBasedList(body, successHandler, onError);
	};

	const handelProductVarietyStatus = (slug) => {
		setloading(true);
		const successHandler = (res) => {
			setloading(false);
			res?.status === "success" ? showModal(6, slug) : errorMessage("PRODUCT_VARIETY_NOT_MAPPED");
		};
		const body = {
			location_slug: slug,
		};
		fetchProductBasedList(body, successHandler, onError);
	};

	// Filter
	const addToFilter = (e) => {
		let obj = [];
		e.forEach((item) => {
			return (obj =
				item && item.column
					? [
							...obj,
							{
								column_name: item.column.accessor,
								search: item.value,
							},
					  ]
					: obj);
		});
		setFilter(obj);
	};

	const columns = renderColumns({
		handleSkuReorder,
		handleCustomerReorder,
		handleEditPanel,
		handleViewPanel,
		confirmDelete,
		addToFilter,
		permissionDetails,
		handelSkuStatus,
		handelProductVarietyStatus,
	});
	const addPermission =
		permissionDetails && permissionDetails.locations && permissionDetails.locations.permissions.create_location
			? permissionDetails.locations.permissions.create_location
			: "";
	const listPermission =
		permissionDetails && permissionDetails.locations && permissionDetails.locations.permissions.list_location
			? permissionDetails.locations.permissions.list_location
			: "";

	const updatePermission =
		permissionDetails && permissionDetails.locations && permissionDetails.locations.permissions.update_location
			? permissionDetails.locations.permissions.update_location
			: "";

	console.log("listPermission", permissionDetails.locations);
	return (
		<ContentWrap>
			<div className="d-flex align-items-center mb-3">
				<Breadcrumbs keyValue="locations" showLabel={listPermission} label="List" />
				<div>
					{addPermission ? (
						<ButtonPrimary onClick={handleAddPanel}>
							<i className="icon icon-plus mr-2"></i> Add Location
						</ButtonPrimary>
					) : (
						""
					)}
				</div>
			</div>
			<div className="position-relative">
				{listPermission ? (
					<Listing fetchAction={fetchLocations} columns={columns} refetch={refetch} filter={filter} />
				) : (
					""
				)}
				{loading && permissionDetails && (
					<div className="table-loader-wrapper">
						<TableLoader />
					</div>
				)}
			</div>
			<LocationForm
				updatePermission={updatePermission}
				doRefetch={() => setRefetch(refetch + 1)}
				handleEditPanel={handleEditPanel}
			/>
			<ConfirmModal
				show={locationToDelete ? true : false}
				onNo={() => setLocationToDelete(null)}
				onYes={() => onDeleteLocation(locationToDelete)}
				message="Are you sure want to delete this location?"
			/>
		</ContentWrap>
	);
};

const mapStateToProps = (state) => {
	const { UI, Auth } = state;
	return {
		accessToken: get(Auth, "accessToken", null),
		mainPreloader: get(UI, "mainPreloader", null),
		permissionDetails: get(Auth, "permissionDetails", null),
	};
};

const mapDispatchToProps = {
	fetchLocations,
	fetchLocationDetails,
	deleteLocation,
	clearLocationDetails,
	fetchLocationSkuPriority,
	fetchLocationCustomerPriority,
	fetchProductBasedList,
	fetchSkuBasedList,
};
export default connect(mapStateToProps, mapDispatchToProps)(Locations);
