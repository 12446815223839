import { requiredDateObj, required } from "constant/formValidators";

export const casesPerSkuForm = [
	{
		label: "label.location_required",
		name: "location_slug",
		type: "select-react",
		placeholder: "label.select_location",
		validate: [required],
	},
	{
		label: "label.delivery_date_req",
		name: "date",
		type: "date-picker",
		placeholder: "label.enter_date",
		validate: [requiredDateObj],
	},
];
