import { createSelectorCreator, defaultMemoize } from "reselect";
import { isEmpty, isEqual, keys, uniqBy, values } from "lodash";
import Util from "utils/Util";

const getForecast = (state) => state.Forecast;

const getForm = (state) => state.form;

const getAuth = (state) => state.Auth;

const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);

export const getLocationList = createDeepEqualSelector(
	[getAuth],
	(auth) => Util.mapOptions(auth.userLocations, "name", "slug") || []
);

export const getLoading = createDeepEqualSelector([getForecast], (forecast) => forecast && forecast.loading);

// forecast form
export const getForecastForm = createDeepEqualSelector([getForm], (form) => (form.forecastForm ? form.forecastForm : null));

export const getForecastFormFromDate = createDeepEqualSelector([getForecastForm], (form) =>
	form && form.values && form.values.date && form.values.date[0] ? form.values.date[0] : null
);

const fromDateFormat = createDeepEqualSelector([getForecastFormFromDate], (form) => (form ? new Date(form) : null));

export const thirtyDaysDate = createDeepEqualSelector([fromDateFormat], (date) =>
	date ? date.setDate(date.getDate() + 29) : null
);

export const getForecastFormLocation = createDeepEqualSelector([getForecastForm], (form) =>
	form && form.values && form.values.location_slug ? form.values.location_slug : null
);

export const getForecastFormForecast = createDeepEqualSelector([getForecastForm], (form) =>
	form && form.values && form.values.forecast_slug ? form.values.forecast_slug : null
);

export const getForecastFormToDate = createDeepEqualSelector([getForecastForm], (form) =>
	form && form.values && form.values.date && form.values.date[1] ? form.values.date[1] : null
);

export const getForecastLocationProducts = createDeepEqualSelector([getForecastFormLocation, getForecast], (form, forecast) =>
	form && forecast.productVarieties
		? !isEmpty(forecast.productVarieties[form.value])
			? Util.mapOptions(forecast.productVarieties[form.value], "product_variety_name", "product_variety_slug")
			: []
		: []
);

// forecast form ends

export const getForecastFixedColumnData = createDeepEqualSelector(
	[getForecast],
	(forecast) => forecast.forecastFixedColumnData
);
export const getForecastData = createDeepEqualSelector([getForecast], (forecast) => forecast.forecastData);

export const getForecastColumns = createDeepEqualSelector([getForecast], (forecast) => forecast.forecastColumns);

export const getForecastEditable = createDeepEqualSelector([getForecast], (forecast) => forecast.forecastEditable);

export const getForecastRenderType = createDeepEqualSelector([getForecast], (forecast) => forecast.forecastRender);

export const getForecastAddData = createDeepEqualSelector([getForecast], (forecast) => forecast.forecastAddData);

export const getForecastFormData = createDeepEqualSelector([getForecast], (forecast) => forecast.forecastFormData);

// days calculation

const numberOfDays = (date1, date2) => {
	const arr = Util.getDates(date1, date2);
	return arr.length;
};

const numberOfDaysSelected = createDeepEqualSelector([getForecastFormFromDate, getForecastFormToDate], (from, to) =>
	from && to ? numberOfDays(from, to) : null
);

export const numberOfDaysInTable = createDeepEqualSelector([getForecastData], (data) => (data ? keys(data).length : null));

export const checkDaysCountEqual = createDeepEqualSelector(
	[numberOfDaysSelected, numberOfDaysInTable],
	(daysSelected, daysTable) => (daysSelected === daysTable ? true : false)
);

//days calculation ends

//copyforecast
export const copyForecastInitials = createDeepEqualSelector([getForecastFormData], (form) => ({
	location_slug: form.location,
	forecast_slug: form.forecast,
}));

const getCopyForecastForm = createDeepEqualSelector([getForm], (form) =>
	form.copyForecastForm ? form.copyForecastForm : null
);

export const getCopyForecastFromDate = createDeepEqualSelector([getCopyForecastForm], (form) =>
	form && form.values && form.values.date_from ? form.values.date_from : null
);

const copyForecastFromDateFormat = createDeepEqualSelector([getCopyForecastFromDate], (form) => (form ? new Date(form) : null));

export const getCopyForecastToDate = createDeepEqualSelector(
	[copyForecastFromDateFormat, numberOfDaysInTable],
	(fromDate, days) => (fromDate && days ? fromDate.setDate(fromDate.getDate() + (days - 1)) : "")
);

// production forecast cases

export const getSkusList = createDeepEqualSelector([getForecastFormLocation, getForecast], (form, forecast) =>
	form && forecast.skus
		? !isEmpty(forecast.skus[form.value])
			? Util.mapOptions(forecast.skus[form.value], "name", "value")
			: []
		: []
);

//===== Sales vs production ======

const getLocationCustomers = createDeepEqualSelector([getForecast], (forecast) => forecast.locationCustomers);

export const getCustomers = createDeepEqualSelector([getForecast, getForecastFormLocation], (forecast, location) =>
	forecast.locationCustomers && location
		? Util.mapOptions(forecast.locationCustomers[location.value], "customer_name", "customer_slug")
		: []
);

export const getForecastFormCustomer = createDeepEqualSelector([getForecastForm], (form) =>
	form && form.values && form.values.customer ? form.values.customer : null
);

// export const getForecastFormCustomerAdd = createDeepEqualSelector([getForecastForm], (form) =>
// 	form && form.values && form.values.customerAdd ? form.values.customerAdd : null
// );

export const getForecastFormDC = createDeepEqualSelector([getForecastForm], (form) =>
	form && form.values && form.values.dc ? form.values.dc : null
);

export const getForecastFormSkuSP = createDeepEqualSelector([getForecastForm], (form) =>
	form && form.values && form.values.sku_sp ? form.values.sku_sp : null
);

const arrayFormating = (formItem, selItem, type) => {
	const data = [];

	// eslint-disable-next-line array-callback-return
	formItem.map((item_1) => {
		const obj = selItem.find((item_2) =>
			type === "dc" ? item_1.value === item_2.customer_slug : item_1.value === item_2.dc_slug
		);
		if (obj) {
			if (type === "dc") {
				data.push(...obj.dc_list);
			} else {
				data.push(...obj.dc_skus);
			}
		}
	});

	if (type === "dc") {
		return data;
	} else {
		return uniqBy(data, "sku_slug");
	}
};

const selectedCustomers = createDeepEqualSelector(
	[getLocationCustomers, getForecastFormLocation, getForecastFormCustomer],
	(forecast, location, customers) =>
		forecast && location && customers && !isEmpty(forecast[location.value])
			? arrayFormating(customers, forecast[location.value], "dc")
			: null
);

// export const getCustomerDC = createDeepEqualSelector(
// 	[selectedCustomers, getForecastFormCustomerAdd, getLocationCustomers, getForecastFormLocation],
// 	(customer, customerAdd, locationCustomers, location) =>
// 		customer
// 			? Util.mapOptions(customer, "dc_name", "dc_slug")
// 			: customerAdd && !isEmpty(locationCustomers[location.value])
// 			? Util.mapOptions(
// 					locationCustomers[location.value].find((item) => item.customer_slug === customerAdd.value).dc_list,
// 					"dc_name",
// 					"dc_slug"
// 			  )
// 			: []
// );

// const selectedDCs = createDeepEqualSelector(
// 	[selectedCustomers, getForecastFormDC, getForecastFormCustomerAdd, getLocationCustomers, getForecastFormLocation],
// 	(customers, dc, customerAdd, locationCustomers, location) =>
// 		customers && dc
// 			? arrayFormating(dc, customers, "sku")
// 			: customerAdd && dc
// 			? arrayFormating(
// 					dc,
// 					locationCustomers[location.value].find((item) => item.customer_slug === customerAdd.value).dc_list
// 			  )
// 			: null
// );

export const getCustomerDC = createDeepEqualSelector([selectedCustomers], (customer) =>
	customer ? Util.mapOptions(customer, "dc_name", "dc_slug") : []
);

const selectedDCs = createDeepEqualSelector([selectedCustomers, getForecastFormDC], (customers, dc) =>
	customers && dc ? arrayFormating(dc, customers, "sku") : null
);

export const getDcSku = createDeepEqualSelector([selectedDCs], (dc) => (dc ? Util.mapOptions(dc, "sku_name", "sku_slug") : []));

//weekly add
export const getForecastFormCustomerAdd = createDeepEqualSelector([getForecastForm], (form) =>
	form && form.values && form.values.customer_add ? form.values.customer_add : null
);

export const getForecastFormDCAdd = createDeepEqualSelector([getForecastForm], (form) =>
	form && form.values && form.values.dc_add ? form.values.dc_add : null
);

export const getForecastFormSkuAdd = createDeepEqualSelector([getForecastForm], (form) =>
	form && form.values && form.values.sku_add ? form.values.sku_add : null
);

// export const getCustomerDCAdd = createDeepEqualSelector(
// 	[getForecastFormCustomerAdd, getLocationCustomers, getForecastFormLocation],
// 	(customer, locationCustomers, location) =>
// 		customer && locationCustomers && location
// 			? Util.mapOptions(
// 					locationCustomers[location.value].find((item) => item.customer_slug === customer.value).dc_list,
// 					"dc_name",
// 					"dc_slug"
// 			  )
// 			: []
// );

// const selectedCustomerAdd = createDeepEqualSelector(
// 	[getForecastFormCustomerAdd, getLocationCustomers, getForecastFormLocation],
// 	(customer, locationCustomers, location) =>
// 		customer && locationCustomers && location
// 			? locationCustomers[location.value].find((item) => item.customer_slug === customer.value)
// 			: null
// );

// export const getCustomerDCAdd = createDeepEqualSelector([selectedCustomerAdd], (customer) =>
// 	customer && !isEmpty(customer.dc_list) ? Util.mapOptions(customer.dc_list, "dc_name", "dc_slug") : []
// );

const selectedCustomerAdd = createDeepEqualSelector(
	[getForecastFormCustomerAdd, getLocationCustomers, getForecastFormLocation],
	(customer, locationCustomers, location) =>
		customer && locationCustomers && location
			? locationCustomers[location.value].find((item) => item.customer_slug === customer.value)
			: null
);

export const getCustomerDCAdd = createDeepEqualSelector(
	[getForecastFormCustomerAdd, getLocationCustomers, getForecastFormLocation],
	(customer, locationCustomers, location) =>
		customer && locationCustomers && location
			? Util.mapOptions(
					locationCustomers[location.value].find((item) => item.customer_slug === customer.value).dc_list,
					"dc_name",
					"dc_slug"
			  )
			: []
);

const selectedDCAdd = (formItem, selItem) => {
	const data = [];
	// eslint-disable-next-line array-callback-return
	formItem.map((item_o) => {
		const obj = selItem.find((item_i) => item_i.dc_slug === item_o.value);
		if (obj && !isEmpty(obj.dc_skus)) {
			data.push(...obj.dc_skus);
		}
		// }
	});

	return uniqBy(data, "sku_slug");
};

export const getCustomerSkuAdd = createDeepEqualSelector([getForecastFormDCAdd, selectedCustomerAdd], (dc, selectedCustomers) =>
	dc && selectedCustomers ? Util.mapOptions(selectedDCAdd(dc, selectedCustomers.dc_list), "sku_name", "sku_slug") : []
);

// MULTIPLE CUSTOMERS IN ADD WEEKLY ALSO
// export const getCustomerDC = createDeepEqualSelector([selectedCustomers], (customer) =>
// 	customer ? Util.mapOptions(customer, "dc_name", "dc_slug") : []
// );

// const selectedDCs = createDeepEqualSelector([selectedCustomers, getForecastFormDC], (customers, dc) =>
// 	customers && dc ? arrayFormating(dc, customers, "sku") : null
// );

// TABS
export const getSalesVsProductionCombinedData = createDeepEqualSelector(
	[getForecast],
	(forecast) => forecast.salesVsProductionCombinedData
);

export const getSalesVsProductionType = createDeepEqualSelector([getForecast], (forecast) => forecast.salesVsProductionType);

export const getSalesVsProductionData = createDeepEqualSelector([getForecast], (forecast) => forecast.salesVsProductionData);
export const getActiveTabCustomer = createDeepEqualSelector([getForecast], (forecast) => forecast.activeTabCustomer);
export const getActiveTabDC = createDeepEqualSelector([getForecast], (forecast) => forecast.activeTabDC);

const tabKeys = (data) => {
	const keys = {};
	// eslint-disable-next-line array-callback-return
	data.map((item) => {
		keys[item.replace(/\s/g, "")] = {
			key: item.replace(/\s/g, ""),
			label: item,
		};
	});

	return keys;
};

export const customerKeyLabel = createDeepEqualSelector([getSalesVsProductionData], (data) =>
	data && !isEmpty(data) ? tabKeys(keys(data)) : null
);

export const getTabsCustomer = createDeepEqualSelector([customerKeyLabel], (data) =>
	data && !isEmpty(data) ? values(data) : null
);

export const dcKeyLabel = createDeepEqualSelector(
	[getSalesVsProductionData, getActiveTabCustomer, customerKeyLabel],
	(data, customerKey, customerObj) =>
		data && customerKey && customerObj && !isEmpty(data) ? tabKeys(keys(data[customerObj[customerKey].label])) : null
);

export const getTabsDC = createDeepEqualSelector([dcKeyLabel], (data) => (data && !isEmpty(data) ? values(data) : null));

export const getTableDataSP = createDeepEqualSelector(
	[getSalesVsProductionData, getActiveTabCustomer, getActiveTabDC, customerKeyLabel, dcKeyLabel],
	(data, customerKey, dcKey, customerObj, dcObj) =>
		data && customerKey && dcKey && customerObj && dcObj
			? data[customerObj[customerKey].label] &&
			  data[customerObj[customerKey].label][dcObj[dcKey].label] &&
			  data[customerObj[customerKey].label][dcObj[dcKey].label]
			: null
);

const formatSPTotal = (data, columns) => {
	const arr = columns.map((col_item, col_ind) => {
		let sales = 0;
		let prod = 0;
		for (const key in data) {
			const ind = data[key].findIndex((item) => item.slug === col_item.slug);

			if (ind > -1 && Number(data[key][ind].sales) !== "NaN") {
				sales += Number(data[key][ind].sales);
				prod += Number(data[key][ind].production);
			}
		}

		return { sales: sales, production: prod };
	});
	return arr;
};

export const getTableDataSPTotal = createDeepEqualSelector(
	[getTableDataSP, getForecastColumns],
	(data, columns) => data && columns && formatSPTotal(data, columns)
);

export const getSPCombinedDataTotal = createDeepEqualSelector(
	[getSalesVsProductionCombinedData, getForecastColumns],
	(data, columns) => data && columns && formatSPTotal(data, columns)
);

const getTypeObjSP = (array, type) => {
	let obj = {};
	// eslint-disable-next-line array-callback-return
	array.map((item) => {
		if (item.value === type) {
			obj = item;
		}
	});
	return obj;
};

export const salesVsProdTypeSelect = (array) =>
	createDeepEqualSelector([getSalesVsProductionType], (forecast) => getTypeObjSP(array, forecast));

//===== Sales vs production ends======

//===== Sales Forecast ======

export const getSalesForecastData = createDeepEqualSelector([getForecast], (forecast) => forecast.salesForecastData);

const getTabKeysSF = (data) => {
	const keys = data.map((item) => ({
		key: item.slug,
		label: item.name,
	}));

	return keys;
};
export const getTabsCustomerSF = createDeepEqualSelector([getSalesForecastData], (data) =>
	data && !isEmpty(data) ? getTabKeysSF(values(data)) : null
);

export const getTabsDCSF = createDeepEqualSelector([getSalesForecastData, getActiveTabCustomer], (data, customer) =>
	data && customer && !isEmpty(data) ? getTabKeysSF(values(data[customer].customer_dcs)) : null
);

export const getTableDataSF = createDeepEqualSelector(
	[getSalesForecastData, getActiveTabCustomer, getActiveTabDC],
	(data, customerKey, dcKey) =>
		data && customerKey && dcKey && !isEmpty(data)
			? // console.log(data, customerKey, dcKey, customerObj, dcObj)
			  data[customerKey] &&
			  data[customerKey].customer_dcs &&
			  data[customerKey].customer_dcs[dcKey] &&
			  data[customerKey].customer_dcs[dcKey].forecast_values
				? data[customerKey].customer_dcs[dcKey].forecast_values
				: null
			: null
);

export const getDcKeyMapping = createDeepEqualSelector([getForecast], (forecast) => forecast.dcKeyMapping);

const checkColumnsSelected = (columns, columnsSelected) => {
	const tempData = [];
	// eslint-disable-next-line array-callback-return
	columns.map((item) => {
		// eslint-disable-next-line array-callback-return
		const obj = columnsSelected.some((item_i) => {
			if (item_i.slug === item.slug) {
				tempData.push(item);
				return item;
			}
		});

		if (obj) {
			return obj;
		}
	});

	return tempData;
};

export const getColumnsSF = createDeepEqualSelector(
	[getSalesForecastData, getActiveTabCustomer, getActiveTabDC, getForecastColumns],
	(data, customer, dc, columnsSelected) =>
		data && customer && dc && !isEmpty(data) && columnsSelected
			? data[customer] &&
			  data[customer].customer_dcs &&
			  data[customer].customer_dcs[dc] &&
			  data[customer].customer_dcs[dc].dc_skus
				? checkColumnsSelected(data[customer].customer_dcs[dc].dc_skus, columnsSelected)
				: null
			: null
);
//=====================PRODUCTION VS SALES BY VARIETY==============================

export const getViewBy = createDeepEqualSelector([getForecastForm], (form) =>
	form && form.values && form.values.view_by ? form.values.view_by : null
);

export const getForecastFormLocation_variety = createDeepEqualSelector([getForecastForm], (form) =>
	form && form.values && form.values.location_variety ? form.values.location_variety : null
);

const formatVariety = (location, locationCustomers) => {
	const data = [];

	// eslint-disable-next-line array-callback-return
	location.map((item) => {
		if (locationCustomers && locationCustomers[item.value] && locationCustomers[item.value].length > 0) {
			data.push(...locationCustomers[item.value]);
		}
	});
	return data;
};

//get array of multiple location customers
export const getMultipleLocationCustomers = createDeepEqualSelector(
	[getForecast, getForecastFormLocation_variety, getViewBy],
	(forecast, location, view) =>
		forecast.locationCustomers && location
			? view && view.value === "production_vs_sales_combined"
				? formatVariety(location, forecast.locationCustomers)
				: forecast.locationCustomers[location.value]
			: null
);

//gets customers for select field
export const getCustomers_variety = createDeepEqualSelector(
	[getForecast, getForecastFormLocation_variety, getViewBy, getMultipleLocationCustomers],
	(forecast, location, view, combinedCustomers) =>
		forecast.locationCustomers && location
			? view && view.value === "production_vs_sales_combined"
				? Util.mapOptions(combinedCustomers, "customer_name", "customer_slug")
				: Util.mapOptions(forecast.locationCustomers[location.value], "customer_name", "customer_slug")
			: []
);

export const getForecastFormCustomer_variety = createDeepEqualSelector([getForecastForm], (form) =>
	form && form.values && form.values.customer_variety ? form.values.customer_variety : null
);

export const getForecastFormDC_variety = createDeepEqualSelector([getForecastForm], (form) =>
	form && form.values && form.values.dc_variety ? form.values.dc_variety : null
);

const formatVarietyDC = (customers, selectedCustomers) => {
	const data = [];

	// eslint-disable-next-line array-callback-return
	customers.map((item_o) => {
		// eslint-disable-next-line array-callback-return
		selectedCustomers.map((item_i) => {
			if (item_o.value === item_i.customer_slug) {
				data.push(...item_i.dc_list);
			}
		});
	});
	return data;
};

export const getCustomerDC_variety = createDeepEqualSelector(
	[getMultipleLocationCustomers, getForecastFormCustomer_variety],
	(customerList, customers) =>
		customerList && customers ? Util.mapOptions(formatVarietyDC(customers, customerList), "dc_name", "dc_slug") : []
);

const formatProductVariety = (locations, products) => {
	const data = [];
	// eslint-disable-next-line array-callback-return
	locations.map((item) => {
		if (products[item.value].length > 0) {
			data.push(...products[item.value]);
		}
	});
	return data;
};

export const getLocationProducts_variety = createDeepEqualSelector(
	[getForecast, getForecastFormLocation_variety, getViewBy],
	(forecast, location, view) =>
		forecast.productVarieties && location
			? view && view.value === "production_vs_sales_combined"
				? Util.mapOptions(
						formatProductVariety(location, forecast.productVarieties),
						"product_variety_name",
						"product_variety_slug"
				  )
				: Util.mapOptions(forecast.productVarieties[location.value], "product_variety_name", "product_variety_slug")
			: []
);

export const getCombinedDifferenceSP = createDeepEqualSelector([getForecast], (forecast) => forecast.combinedDifferenceSP);

const numberOfDaysInTableSF = createDeepEqualSelector([getTableDataSF], (data) => (data ? keys(data).length : null));
const numberOfDaysInTableSP = createDeepEqualSelector([getTableDataSP], (data) => (data ? keys(data).length : null));
const numberOfDaysInTableSP_combined = createDeepEqualSelector([getSalesVsProductionCombinedData], (data) =>
	data ? keys(data).length : null
);

export const checkDaysCountEqualSF = createDeepEqualSelector(
	[numberOfDaysSelected, numberOfDaysInTableSF],
	(daysSelected, daysTable) => (daysSelected === daysTable ? true : false)
);

export const checkDaysCountEqualSP = createDeepEqualSelector(
	[numberOfDaysSelected, numberOfDaysInTableSP],
	(daysSelected, daysTable) => (daysSelected === daysTable ? true : false)
);

export const checkDaysCountEqualSP_combined = createDeepEqualSelector(
	[numberOfDaysSelected, numberOfDaysInTableSP_combined],
	(daysSelected, daysTable) => (daysSelected === daysTable ? true : false)
);

export const getSalesVsProductionDataVariety = createDeepEqualSelector(
	[getForecast],
	(forecast) => forecast.salesVsProductionDataVariety
);

export const getVarietyTypeSP = createDeepEqualSelector([getForecast], (forecast) => forecast.varietyTypeSP);

// access privileges

const getDailyPermit = (state) => state.Auth.permissionDetails.daily_forecast;
const getWeeklyPermit = (state) => state.Auth.permissionDetails.weekly_forecast;

const getProductionForecastPermit = (state) => state.Auth.permissionDetails.production_forecast;
const getHarvestForecastPoundsPermit = (state) => state.Auth.permissionDetails.harvest_forecast_pounds;
const getProductionForecastCasesPermit = (state) => state.Auth.permissionDetails.production_forecast_cases;
const getProductionVsSalesSKUPermit = (state) => state.Auth.permissionDetails.production_vs_sales_sku;
const getProductionVsSalesVarietyPermit = (state) => state.Auth.permissionDetails.production_vs_sales_variety;

export const getDailyViewAccess = createDeepEqualSelector(
	[getDailyPermit],
	(access) => access && access.permissions.sales_view_daily_forecast
);

export const getDailyEditAccess = createDeepEqualSelector(
	[getDailyPermit],
	(access) => access && access.permissions.sales_edit_daily_forecast
);

export const getWeeklyViewAccess = createDeepEqualSelector(
	[getWeeklyPermit],
	(access) => access && access.permissions.sales_view_weekly_forecast
);

export const getWeeklyEditAccess = createDeepEqualSelector(
	[getWeeklyPermit],
	(access) => access && access.permissions.sales_edit_weekly_forecast
);

export const getWeeklyAddAccess = createDeepEqualSelector(
	[getWeeklyPermit],
	(access) => access && access.permissions.sales_add_weekly_forecast
);

export const getProductionForecastAddAccess = createDeepEqualSelector(
	[getProductionForecastPermit],
	(access) => access && access.permissions.production_forecast_creation
);

export const getProductionForecastCopyAccess = createDeepEqualSelector(
	[getProductionForecastPermit],
	(access) => access && access.permissions.production_forecast_copy
);

export const getProductionForecastUpdateAccess = createDeepEqualSelector(
	[getProductionForecastPermit],
	(access) => access && access.permissions.production_forecast_updation
);
export const getProductionForecastViewAccess = createDeepEqualSelector(
	[getProductionForecastPermit],
	(access) => access && access.permissions.production_forecast_view
);

export const getProductionForecastCasesViewAccess = createDeepEqualSelector(
	[getProductionForecastCasesPermit],
	(access) => access && access.permissions.production_forecast_cases_view
);

export const getHarvestForecastPoundsViewAccess = createDeepEqualSelector(
	[getHarvestForecastPoundsPermit],
	(access) => access && access.permissions.harvest_forecast_view
);

export const getProdVsSalesSKUViewAccess = createDeepEqualSelector(
	[getProductionVsSalesSKUPermit],
	(access) => access && access.permissions.production_vs_sales_by_sku
);

export const getProdVsSalesVarietyViewAccess = createDeepEqualSelector(
	[getProductionVsSalesVarietyPermit],
	(access) => access && access.permissions.production_vs_sales_by_pv
);
