import React, { useEffect } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import {
	getDataLR,
	getColumnsLR,
	getFormLocation,
	getDateSlugMapping,
	getFormPond,
	getActivePondTabLr,
	getActivePondTab,
} from "store/selectors/labResultSelectors";
import CroptimalsForm from "../manage";
import LabResult from "utils/LabResult";
import { keys, values } from "lodash";
import { successMessage } from "utils/successMessage";
import { errorMessage } from "utils/errorMessage";
import { addDataLR, setLoaderLR, resetRefreshLR, setRenderTypeLR, setActivePond } from "store/actions";
import Util from "utils/Util";
import { number } from "constant/formValidators";
import { Tab, Tabs } from "react-bootstrap";
import { isEmpty } from "lodash";

const AddCroptimals = ({
	data,
	columns,
	location,
	pond,
	productKeyMapping,
	setLoaderLR,
	addDataLR,
	resetRefreshLR,
	setRenderTypeLR,
	pondTabs,
	activePondTab,
	setActivePond,
}) => {

	const onSave = (tableData) => {
		setLoaderLR();

		const pondItem = pondTabs.filter((item) => item.label === activePondTab ? item.value : null);
		const submitData = {
			location_slug: location,
			lab_result_data: [
				{
					pond: pondItem[0].value,
					lab_results: values(tableData).map((item, i) => ({
						date: Util.getYMDFormat(keys(tableData)[i]),
						nutrient_data: item.map((item_o, j) => ({
							nutrient_slug: item_o.slug,
							value: item_o.value,
						})),
					})),
				},
			],
		};

		const successHandler = (e) => {
			successMessage(e);
			setLoaderLR();
			// resetRefreshLR();
			// setRenderTypeLR(0);
		};

		const errorHandler = (e) => {
			errorMessage(e);
			setLoaderLR();
		};

		addDataLR(submitData, successHandler, errorHandler);
	};

	useEffect(() => {
		return () => {
			resetRefreshLR();
		};
	}, [resetRefreshLR]);

	return (
		<div className="listing-wrapper-croptimals">
			<CroptimalsForm />

			{!isEmpty(pondTabs) && (
				<div className="tab-wrapper">
					<Tabs
						className="customer-tab"
						id="uncontrolled-tab-inner"
						activeKey={activePondTab}
						defaultActiveKey={activePondTab}
						mountOnEnter={true}
						onSelect={(k) => {
							setActivePond(k);
						}}>
						{pondTabs ?
							pondTabs.map(
								(item, index) => (
									(
										<Tab
											className="dc-tab-cell"
											eventKey={item.label}
											key={index}
											title={
												<span>
													<i className="icon icon-market mr-3" />
													{item.label}
												</span>
											}>
											{activePondTab === item.label && data ? (
												data ? (
													<>
														<LabResult
															editable={true}
															columns={columns}
															fixedColumnHeading="Week"
															initialData={data}
															onSave={onSave}
															keyMapping={productKeyMapping}
															onCancel={() => resetRefreshLR()}
															validation={[number]}
															tableId="labResult"
														/>
													</>
												) : (
													columns && (
														<div className="no-results-found text-center">No Results Found</div>
													)
												)
											) : null}
										</Tab>
									)
								)
							):null}
					</Tabs>
				</div>
			)}
		</div>
	);
};

const mapStateToProps = createStructuredSelector({
	data: getDataLR,
	columns: getColumnsLR,
	location: getFormLocation,
	productKeyMapping: getDateSlugMapping,
	pond: getFormPond,
	activePondTab: getActivePondTab,
	pondTabs: getActivePondTabLr,
});

export default connect(mapStateToProps, {
	addDataLR,
	setLoaderLR,
	resetRefreshLR,
	setRenderTypeLR,
	setActivePond,
})(AddCroptimals);
