import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { map } from "lodash";
import useModal from "../../../utils/useModal";
import { connect } from "react-redux";
import { Container, Button, Col, Form } from "react-bootstrap";
import { reduxForm, Field, reset } from "redux-form";
import FormGroups from "../../../utils/formGroup";
import { required } from "../../../constant/formValidators";
import { toggleMainLoader } from "../../../store/actions";
import { getSeedsList } from "../../../store/actions/seedsActions";
import { errorMessage } from "../../../utils/errorMessage";
import { successMessage } from "../../../utils/successMessage";
import { updateGreen, createGreen } from "../../../store/actions/greensActions";
import TableLoader from "utils/table/tableLoader";

const { Header, Title, Body } = Modal;

const GreensManage = ({
	getSeedsList,
	seedDropdownList,
	doRefetch,
	createGreen,
	updateGreen,
	reset,
	form,
	initialValues,
	handleSubmit,
}) => {
	const [isOpen, modalType, , closeModal, modalProps] = useModal("greens");
	const [btnTitle, setbtnTitle] = useState("Save");
	const [isLoading, setisLoading] = useState(false);
	useEffect(() => {
		getSeedsList();
	}, [getSeedsList]);

	const submitBoardDetails = (values) => {
		if (!isLoading) {
			setisLoading(true);
			setbtnTitle("Saving...");
			toggleMainLoader(true);
			const successHandler = (event) => {
				setisLoading(false);
				setbtnTitle("Save");
				doRefetch();
				reset(form);
				successMessage(event);
				closeModal();
			};
			const errorHandler = (event) => {
				setisLoading(false);
				setbtnTitle("Save");
				errorMessage(event);
			};

			const request = {
				...values,
				seeds: map(values.seeds, "value"),
			};
			modalType
				? updateGreen(modalProps.slug, request, successHandler, errorHandler)
				: createGreen(request, successHandler, errorHandler);
		}
	};
	return (
		<Modal size="lg" show={isOpen} onHide={closeModal} className="modal-sidepanel">
			<Header closeButton>
				<Title className="modal-title">{modalType === 1 ? "Edit" : "Add"} Greens</Title>
			</Header>
			<Body>
				<Form onSubmit={handleSubmit(submitBoardDetails)} className="board-details-form">
					<Container className="position-relative">
						<Col>
							<Form.Group controlId="formControl">
								<Field
									name="green_name"
									type="text"
									label="Green Name"
									placeholder="Enter Greens Name"
									component={FormGroups}
									validate={required}
								/>
							</Form.Group>
						</Col>

						<Col>
							<Form.Group controlId="locationProductVarieties">
								<Field
									label="Seeds"
									name="seeds"
									type={modalType === 1 ? "selectWithTags" : "select-react"}
									placeholder="Select Seeds"
									multiple={true}
									validate={[required]}
									component={FormGroups}
									options={
										map(seedDropdownList, (o) => ({
											label: o.name,
											value: o.slug,
											can_delete: o.can_delete,
										})) || []
									}
								/>
							</Form.Group>
						</Col>
						{isLoading && (
							<div className="table-loader-wrapper">
								<TableLoader />
							</div>
						)}
					</Container>
					<div className="modal-footer save-footer">
						<Button variant="secondary" onClick={closeModal}>
							Cancel
						</Button>
						<Button variant="primary" type="submit" disabled={isLoading}>
							{btnTitle}
						</Button>
					</div>
				</Form>
			</Body>
		</Modal>
	);
};
const mapStateToProps = (state) => ({ seedDropdownList: state.Seeds.seedDropdownList });

const mapDispatchToProps = {
	getSeedsList,
	toggleMainLoader,
	reset,
	createGreen,
	updateGreen,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(
	reduxForm({
		form: "green-details-form",
		enableReinitialize: true,
	})(GreensManage)
);
