import { COUNT } from "../types";
import fetchHandler from "../../utils/fetchHandler";

export const fetchCountDetails = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `dashboard`,
		method: "POST",
		secure: true,
		actionType: COUNT.FETCH_COUNT_DETAILS,
		body: JSON.stringify(values),
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};
