import React, { useState } from "react";
import { connect } from "react-redux";
import { map, get } from "lodash";
import { ContentWrap, ButtonPrimary, Breadcrumbs } from "../../../elements";
import Listing from "../../../partials/listings";
import useModal from "../../../utils/useModal";
import ConfirmModal from "../../../utils/confirmModal";
import { renderColumns } from "./helper";
import { successMessage } from "../../../utils/successMessage";
import { errorMessage } from "../../../utils/errorMessage";
import GreensManage from "../manage";
import { getGreensList, deleteGreen } from "../../../store/actions/greensActions";
import "../index.scss";

const GreensListing = ({ getGreensList, deleteGreen, permissionDetails }) => {
	const [, , showModal, , modalProps] = useModal("greens");
	const [refetch, setRefetch] = useState(0);
	const [filter, setFilter] = useState([]);
	const [isDeletModalOpen, , showDeleteModal, closeDeleteModal, deleteModalProps] = useModal("deleteGreens");

	const handleAddPanel = () => showModal(0);
	const handleEditPanel = (data) => {
		const mode = data ? 1 : 0;
		const initialValues = {
			...data,
			seeds: map(
				data.seeds,
				(o) =>
					({
						label: o.seed_name,
						value: o.slug,
						can_delete: o.can_delete,
					} || [])
			),
		};
		showModal(mode, initialValues);
	};
	const handleDeletePanel = (data) => showDeleteModal(0, data);

	const onDeleteBoard = () => {
		const successHandler = (e) => {
			closeDeleteModal();
			successMessage(e);
			setRefetch(refetch + 1);
		};
		const errorHandler = (e) => {
			closeDeleteModal();
			errorMessage(e);
		};
		deleteGreen(deleteModalProps.slug, successHandler, errorHandler);
	};

	// Filter
	const addToFilter = (e) => {
		let obj = [];
		e.forEach((item) => {
			return (obj =
				item && item.column
					? [
							...obj,
							{
								column_name: item.column.accessor,
								search: item.value,
							},
					  ]
					: obj);
		});
		setFilter(obj);
	};

	const columns = renderColumns({
		handleEditPanel,
		handleDeletePanel,
		addToFilter,
		permissionDetails,
	});
	const addPermission =
		permissionDetails && permissionDetails.greens && permissionDetails.greens.permissions.create_green
			? permissionDetails.greens.permissions.create_green
			: "";
	const listPermission =
		permissionDetails && permissionDetails.greens && permissionDetails.greens.permissions.list_green
			? permissionDetails.greens.permissions.list_green
			: "";

	return (
		<ContentWrap>
			<div className="d-flex align-items-center mb-3">
				<Breadcrumbs keyValue="greens" showLabel={listPermission} label="List" />
				{addPermission ? (
					<div>
						<ButtonPrimary onClick={handleAddPanel}>
							<i className="icon icon-plus mr-2" /> Add Green
						</ButtonPrimary>
					</div>
				) : (
					""
				)}
			</div>
			<div>
				{listPermission ? (
					<Listing
						fetchAction={getGreensList}
						columns={columns}
						refetch={refetch}
						filter={filter}
						sort_name="green_name"
					/>
				) : (
					""
				)}

				<GreensManage
					doRefetch={() => setRefetch(refetch + 1)}
					initialValues={modalProps}
					handleEditPanel={handleEditPanel}
				/>
			</div>
			<ConfirmModal
				show={isDeletModalOpen}
				onNo={closeDeleteModal}
				onYes={onDeleteBoard}
				message="Are you sure want to delete this green?"
			/>
		</ContentWrap>
	);
};
const mapStateToProps = (state) => {
	const { Auth } = state;

	return {
		permissionDetails: get(Auth, "permissionDetails", null),
	};
};

const mapDispatchToProps = {
	getGreensList,
	deleteGreen,
};
export default connect(mapStateToProps, mapDispatchToProps)(GreensListing);
