import { PACK_RATE_GRAPH } from "../types";
import fetchHandler from "../../utils/fetchHandler";

export const fetchPackRateGraphsDetails = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `dashboard/order-pack-rate`,
		method: "POST",
		secure: true,
		actionType: PACK_RATE_GRAPH.FETCH_PACK_RATE_GRAPH_DETAILS,
		body: JSON.stringify(values),
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};
