import React from "react";
import { ButtonSecondary } from "./buttons";
import i18next from "i18next";

export const BackButton = ({ onClick }) => (
	<ButtonSecondary className="back-button" onClick={onClick}>
		<i className="icon icon-angle-left" />
		{i18next.t('label.back_button')}
	</ButtonSecondary>
);
