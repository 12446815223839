import React, { useState } from "react";
import { connect } from "react-redux";
import { BasicDetails, SeedingDetails, GerminationDetails, TaskStatusBar, ProductInformation } from "./viewComponents";
import {
	startSeedingofSGTask,
	toggleViewLoader,
	getSeedingGerminationTaskDetails,
	getAuditLogOfSGTask,
	completeSGTask,
} from "../../../store/actions";
import useModal from "../../../utils/useModal";
import TaskDetailsModal from "../taskDetails";
import TableLoader from "../../../utils/table/tableLoader";
import { errorMessage } from "../../../utils/errorMessage";
import { successMessage } from "../../../utils/successMessage";
import AuditLog from "../../auditLog";
import Util from "utils/Util";

const SeedingGerminationView = ({
	taskDetails = {},
	startSeedingofSGTask,
	getSeedingGerminationTaskDetails,
	getAuditLogOfSGTask,
	completeSGTask,

	acitivityLog,
	viewPageLoader,
	toggleViewLoader,
	permissionDetails,
	germinationActive,
}) => {
	const [, , showModal] = useModal("seeding_details");
	const [startTaskBtn, setstartTaskBtn] = useState("Start Task");

	const [showActivityLog, setShowActivityLog] = useState(true);
	const [fetchingLog, setFetchingLog] = useState(false);

	const seasonDetails = (taskDetails && taskDetails.product_variety && taskDetails.product_variety.season_details) || null;
	const auditLogPermission =
		permissionDetails && permissionDetails.seeding && permissionDetails.seeding.permissions.view_seeding_task_audit_log
			? permissionDetails.seeding.permissions.view_seeding_task_audit_log
			: "";
	const logPermission =
		permissionDetails && permissionDetails.germination && permissionDetails.germination.permissions.germination_log_details
			? permissionDetails.germination.permissions.germination_log_details
			: "";

	// STATUS
	// 1 New | 2 Seeding | 3 Germination
	// MODAL TYPES
	// 1 - Seeding Add | 2 - Seeding Edit | 3 - Germination Add | 4 - Germination Edit
	const getType =
		taskDetails.status === "New"
			? 1
			: taskDetails.status === "Seeding"
			? 2
			: taskDetails.status === "Germination"
			? 3
			: taskDetails.status === "Completed"
			? 4
			: 0;

	// Task Start Time's to show
	const getCorrespondingTaskTime =
		taskDetails.status === "New"
			? null
			: taskDetails.status === "Seeding"
			? taskDetails && Util.utcToLocal(taskDetails.seeding_start_date)
			: taskDetails.status === "Germination"
			? taskDetails && Util.utcToLocal(taskDetails.germination_start_date)
			: taskDetails.status === "Completed"
			? null
			: 0;

	// Common error handler
	const errorHandler = (err) => {
		errorMessage(err);
		toggleViewLoader(false);
		setstartTaskBtn("Start Task");
	};

	// Action : Footer button clicks
	const onClickStatusAction = (currentStatus) => {
		if (currentStatus === 1) {
			doStartTask();
		}
		// Add seeding Details
		if (currentStatus === 2) {
			showModal(1);
		}
		// Complete task
		if (currentStatus === 3) {
			doCompleteTask();
		}
		// Show activity log
		if (currentStatus === 5) {
			showActivityLog && fetchAuditLog();
			setShowActivityLog(!showActivityLog);
		}
	};

	const isAssigneeSelected = taskDetails && taskDetails.assignee && taskDetails.assignee.id ? true : false;

	// Start Task
	const doStartTask = () => {
		if (isAssigneeSelected) {
			toggleViewLoader(true);
			setstartTaskBtn("Starting...");
			const successHandler = (data) => {
				successMessage(data);
				doFetchTaskDetails();
			};
			startSeedingofSGTask(taskDetails.slug, successHandler, errorHandler);
		} else {
			errorMessage("NO_ASSIGNEE_SELECTED");
		}
	};

	// Complete task
	const doCompleteTask = () => {
		if (isAssigneeSelected) {
			// Actual germination start and end time should be less than current time and date
			if (Util.checkDateIsPast(taskDetails.germination_start_date)) {
				toggleViewLoader(true);
				const successHandler = (data) => {
					successMessage(data);
					doFetchTaskDetails();
				};
				completeSGTask(taskDetails.slug, successHandler, errorHandler);
			} else {
				errorMessage("GERMINATION_END_DATE_CURRENT_DATE");
			}
		} else {
			errorMessage("NO_ASSIGNEE_SELECTED");
		}
	};

	// Action :  Edit button clicks
	const onClickEditAction = (editType) => {
		if (editType === 2) {
			showModal(2);
		}
		if (editType === 4) {
			showModal(4);
		}
	};

	// View
	const doFetchTaskDetails = () => {
		fetchAuditLog();
		toggleViewLoader(true);
		const disableLoader = () => toggleViewLoader(false);
		getSeedingGerminationTaskDetails(taskDetails.slug, disableLoader, disableLoader);
	};

	// Fetch Log
	const fetchAuditLog = () => {
		setFetchingLog(true);
		const onAuditSuccess = () => {
			setFetchingLog(false);
		};
		const onAuditError = (err) => {
			errorMessage(err);
			setFetchingLog(false);
		};
		getAuditLogOfSGTask(taskDetails.slug, onAuditSuccess, onAuditError);
	};
	return (
		<div className="sg-view-wrapper">
			<div className="position-relative">
				{/* Basic Details */}
				<BasicDetails taskDetails={taskDetails} />

				{/* Seeding Details */}
				{getType > 2 && (
					<SeedingDetails
						logPermission={logPermission}
						taskDetails={taskDetails}
						onClickEditAction={onClickEditAction}
						germinationActive={germinationActive}
					/>
				)}

				{/* Germination Details */}
				{getType > 2 && (
					<GerminationDetails
						logPermission={logPermission}
						taskDetails={taskDetails}
						onClickEditAction={onClickEditAction}
						germinationActive={germinationActive}
					/>
				)}

				{/* Product Information */}
				<ProductInformation taskDetails={taskDetails} seasonDetails={seasonDetails} />

				{/* Status Buttons */}
				<TaskStatusBar
					logPermission={logPermission}
					onClickStatusAction={onClickStatusAction}
					startTaskBtn={startTaskBtn}
					tastStartTime={getCorrespondingTaskTime}
					type={getType}
					germinationActive={germinationActive}
				/>

				{/* Loader */}
				{viewPageLoader && (
					<div className="table-loader-wrapper">
						<TableLoader />
					</div>
				)}
			</div>
			{/* Activity Log */}
			{auditLogPermission && (
				<AuditLog log={acitivityLog} loading={fetchingLog} onClickStatusAction={onClickStatusAction} />
			)}

			<TaskDetailsModal
				showCompleteButton={getType === 3}
				taskDetails={taskDetails}
				doFetchTaskDetails={doFetchTaskDetails}
			/>
		</div>
	);
};
const mapStateToProps = (state) => {
	return {
		acitivityLog: state.SeedingGermination.acitivityLog,
		permissionDetails: state.Auth.permissionDetails,
		germinationActive: state?.SeedingGermination?.sgTaskDetails?.product_variety?.active,
	};
};
const mapDispatchToProps = {
	startSeedingofSGTask,
	toggleViewLoader,
	getSeedingGerminationTaskDetails,
	getAuditLogOfSGTask,
	completeSGTask,
};

export default connect(mapStateToProps, mapDispatchToProps)(SeedingGerminationView);
