import React, { useState } from "react";
import { connect } from "react-redux";
import { Container, Button, Row, Col, Form } from "react-bootstrap";
import { ButtonLink } from "../../../elements";
import { reduxForm, Field, reset, change, untouch, getFormValues } from "redux-form";
import { useDispatch } from "react-redux";
import FormGroups from "../../../utils/formGroup";
import { formCreationFieldsMiddle, getValidations } from "./formHelper";
import { createTransplantAdditionalPonds, toggleMainLoader } from "../../../store/actions";
import useModal from "../../../utils/useModal";
import { errorMessage } from "../../../utils/errorMessage";
import { successMessage } from "../../../utils/successMessage";
import { findKey, isEmpty } from "lodash";
import TableLoader from "utils/table/tableLoader";
import Util from "../../../utils/Util";
import { useTranslation } from 'react-i18next';

const TaskPondAddEdit = ({
	handleSubmit,
	createTransplantAdditionalPonds,
	toggleMainLoader,
	reset,
	form,
	taskDetails,
	doFetchTaskDetails,
	initialValues,
	locationList,
	metricsList,
	formValues,
	pondsAdded,
}) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const [, , , closeModal] = useModal("transplant_details");
	const [isLoading, setIsLoading] = useState(false);
	const [btnTitle, setbtnTitle] = useState(t("label.save"));
	const [locationMetricsList, setlocationMetricsList] = useState([{ key: 0, flag: "add" }]);
	const locationPondLaneMappedArray = metricsList[taskDetails.location.value];

	const submitTransplantDetails = (values) => {
		if (!isLoading) {
			setIsLoading(true);
			setbtnTitle(t("label.saving"));
			toggleMainLoader(true);

			const metrics = locationMetricsList.map(function (metric) {
				return {
					...values["lm_" + metric.key],
					pond_number: values["lm_" + metric.key].pond_number.value,
					lane_number: values["lm_" + metric.key].lane_number.value,
				};
			});

			const submissionData = {
				transplant_details: metrics,
			};

			const successHandler = (event) => {
				reset(form);
				successMessage(event);
				setIsLoading(false);
				setbtnTitle(t("label.save"));
				closeModal();
				doFetchTaskDetails();
			};
			const errorHandler = (event) => {
				errorMessage(event);
				setIsLoading(false);
				setbtnTitle(t("label.save"));
			};
			const payload = taskDetails.task_id ? taskDetails.task_id : "";
			createTransplantAdditionalPonds(submissionData, "POST", payload, successHandler, errorHandler);
		}
	};

	const getLaneList = (pond, id) => {
		const laneCount = locationPondLaneMappedArray["lanes_per_pond"];

		const lanesSelected = [];

		locationMetricsList.forEach((listItem, idx) => {
			if (
				// idx !== id &&
				listItem.key !== id &&
				formValues &&
				formValues[`lm_${listItem.key}`] &&
				formValues[`lm_${listItem.key}`]["pond_number"] &&
				formValues[`lm_${listItem.key}`]["pond_number"]["value"] &&
				formValues[`lm_${listItem.key}`]["pond_number"]["value"].toString() === pond.toString() &&
				formValues[`lm_${listItem.key}`]["lane_number"] &&
				formValues[`lm_${listItem.key}`]["lane_number"]["value"]
				// formValues[`lm_${idx}`]["lane_number"]["value"] !== formValues[`lm_${id}`]["lane_number"]["value"]
			) {
				lanesSelected.push(formValues[`lm_${listItem.key}`]["lane_number"]["value"]);
			}
		});

		pondsAdded.forEach((item) => {
			if (item.pond_number.id.toString() === pond.toString()) {
				lanesSelected.push(item.lane_number.toString());
			}
		});

		const arr = Array(laneCount)
			.fill()
			.map((_, i) => ({
				label: i + 1,
				value: (i + 1).toString(),
				...(!isEmpty(lanesSelected)
					? { isdisabled: lanesSelected.includes((i + 1).toString()) ? true : false }
					: { isdisabled: false }),
			}));

		return arr;
	};

	const getOptions = (field, id) => {
		switch (field) {
			case "pond_number":
				return Util.mapOptions(locationPondLaneMappedArray["pond"], "value", "id") || [];
			case "lane_number":
				// return enumerateOptions(locationPondLaneMappedArray, "lanes_per_pond");
				return (
					(formValues &&
						formValues[`lm_${id}`] &&
						formValues[`lm_${id}`].pond_number &&
						formValues[`lm_${id}`].pond_number.value &&
						getLaneList(formValues[`lm_${id}`].pond_number.value, id)) ||
					[]
				);

			default:
				return [];
		}
	};

	const onPondChange = (id) => {
		formValues[`lm_${id}`] &&
			formValues[`lm_${id}`]["lane_number"] &&
			formValues[`lm_${id}`]["lane_number"]["value"] &&
			dispatch(change("transplantForm", `lm_${id}.lane_number`, null));
	};

	const metricsFormFields = (count, flag = "add", i) => {
		let columns = [];

		columns = formCreationFieldsMiddle.map((field, idx) => {
			return (
				<Col className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12" key={idx}>
					<Form.Group controlId={`formControl ${idx}`}>
						{field.type === "select-react" ? (
							<Field
								name={`lm_${count}.${field.name}`}
								type={field.type}
								label={t(field.label)}
								placeholder={t(field.placeholder)}
								component={FormGroups}
								validate={getValidations(field.name)}
								options={getOptions(field.name, count)}
								onChange={() => (field.name === "pond_number" ? onPondChange(count) : null)}
							/>
						) : (
							<Field
								name={`lm_${count}.${field.name}`}
								type={field.type}
								label={t(field.label)}
								placeholder={t(field.placeholder)}
								component={FormGroups}
								validate={getValidations(field.name)}
							/>
						)}
					</Form.Group>
				</Col>
			);
		});

		return (
			<React.Fragment key={i}>
				<div className="outer-box">
					<Row>{columns}</Row>
					<Row>
						{locationMetricsList.length - 1 === i ? (
							<Col
								className={`d-flex justify-content-center text-center ${
									locationMetricsList.length === 1 ? "col-xl-12 col-lg-12  " : "col-xl-6 col-lg-6"
								} col-md-12 col-sm-12 col-12`}>
								<ButtonLink onClick={() => setMetricsArray(count + 1)}>
									<i className="icon icon-plus mr-2" />
									{t("label.Add")}
								</ButtonLink>
							</Col>
						) : (
							""
						)}
						{locationMetricsList.length !== 1 ? (
							<Col
								className={`d-flex justify-content-center text-center ${
									locationMetricsList.length - 1 !== i ? "col-xl-12 col-lg-12  " : "col-xl-6 col-lg-6"
								} col-md-12 col-sm-12 col-12`}>
								<ButtonLink className="remove-link-btn" onClick={() => removeMetricsArray(count)}>
									<i className="icon icon-minus mr-2" />
									{t("label.remove")}
								</ButtonLink>
							</Col>
						) : (
							""
						)}
					</Row>
				</div>
			</React.Fragment>
		);
	};
	const setMetricsArray = (count) => {
		dispatch(change("transplantForm", "lm_" + count + ".pond_number", []));
		dispatch(untouch("transplantForm", "lm_" + count + ".pond_number", []));
		dispatch(change("transplantForm", "lm_" + count + ".lane_number", []));
		dispatch(untouch("transplantForm", "lm_" + count + ".lane_number", []));
		dispatch(change("transplantForm", "lm_" + count + ".number_of_boards", []));
		dispatch(untouch("transplantForm", "lm_" + count + ".number_of_boards", []));
		dispatch(change("transplantForm", "lm_" + count + ".germination_rate", ""));
		dispatch(untouch("transplantForm", "lm_" + count + ".germination_rate", ""));
		setlocationMetricsList([
			...locationMetricsList,
			{
				key: count,
				flag: "add",
			},
		]);
	};
	const removeMetricsArray = (index) => {
		const locationMetricsListKeyToRemove = findKey(locationMetricsList, function (o) {
			return o.key === index;
		});
		const list = [...locationMetricsList];
		list.splice(locationMetricsListKeyToRemove, 1);
		setlocationMetricsList(list);
	};
	return (
		<>
			<Form onSubmit={handleSubmit(submitTransplantDetails)} className="transplantForm">
				<Container className="position-relative">
					{setlocationMetricsList &&
						locationMetricsList &&
						locationMetricsList.map((x, i) => {
							return metricsFormFields(x.key, x.flag, i);
						})}
					{isLoading && (
						<div className="table-loader-wrapper">
							<TableLoader />
						</div>
					)}
				</Container>
				<div className="modal-footer save-footer">
					<Button variant="secondary" onClick={closeModal}>
						{t("label.cancel")}
					</Button>
					<Button variant="primary" type="submit" disabled={isLoading}>
						{btnTitle}
					</Button>
				</div>
			</Form>
		</>
	);
};

const mapStateToProps = (state) => {
	const { Transplant, Auth } = state;

	return {
		locationList: Auth.userLocations,
		metricsList: Transplant.locationMapping,
		initialValues: {},
		formValues: getFormValues("transplantForm")(state),
	};
};

const mapDispatchToProps = {
	toggleMainLoader,
	createTransplantAdditionalPonds,
	reset,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(
	reduxForm({
		form: "transplantForm",
		enableReinitialize: true,
	})(TaskPondAddEdit)
);
