import fetchHandler from "../../utils/fetchHandler";
import { SKU } from "../types";

export const fetchAllSkus = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `skus`,
		method: "POST",
		actionType: SKU.FETCH_SKU,
		body: JSON.stringify(values),
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const fetchSkuDetails = (slug, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `sku/${slug}`,
		method: "GET",
		actionType: SKU.FETCH_SKU_DETAILS,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const createSku = (values, method, payload, successHandler, errorHandler) => {
	const fetchOptions = {
		url: payload ? `sku/${payload}` : `sku`,
		method: method,
		secure: true,
		actionType: SKU.CREATE_SKU,
		body: JSON.stringify(values),
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const deleteSku = (slug, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `sku/${slug}`,
		method: "DELETE",
		actionType: SKU.DELETE_SKU,
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};
export const fetcheSku = (id, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `sku/${id}`,
		method: "DELETE",
		actionType: SKU.DELETE_LOCATION,
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};
export const fetchSkuLocations = () => {
	const fetchOptions = {
		url: `locations`,
		method: "GET",
		actionType: SKU.FETCH_SKU_LOCATION_LIST,
	};

	return fetchHandler(fetchOptions);
};
export const fetchSkuProducts = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `location/product_varieties`,
		method: "POST",
		actionType: SKU.FETCH_SKU_PRODUCT_LIST,
		body: JSON.stringify(values),
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};
export const fetchSkuChangeStatus = (body, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `sku/change_status`,
		method: "POST",
		actionType: SKU.FETCH_SKU_CHANGE_STATUS,
		body: JSON.stringify(body),
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};
