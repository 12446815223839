import React from "react";
import { Col, Row } from "react-bootstrap";

export const getCustomTotal = (data, type) => {
	return (
		<Row className="forecast-table-body-row">
			{data.map((item, ind) => (
				<Col key={ind} className="forecast-table-col">
					{type === 0 ? (
						<>
							<span className="production-value">
								{item.production || item.production === 0
									? Number.isInteger(item.production)
										? item.production.toLocaleString("en-IN")
										: item.production.toLocaleString("en-IN")
									: "-"}
							</span>
							{" / "}
							<span className="sales-value">
								{item.sales || item.sales === 0
									? Number.isInteger(item.sales)
										? item.sales.toLocaleString("en-IN")
										: item.sales.toLocaleString("en-IN")
									: "-"}
							</span>
						</>
					) : type === 1 ? (
						<span
							className={
								item.production || item.sales
									? item.production - item.sales >= 0
										? "txt-green"
										: "txt-red"
									: null
							}>
							{item.production || item.sales ? (item.production - item.sales).toLocaleString("en-IN") : "-"}
						</span>
					) : type === 3 ? (
						<span
							className={
								item.production || item.sales
									? item.production - item.sales >= 0
										? "txt-green"
										: "txt-red"
									: null
							}>
							{item.production || item.sales ? (item.production - item.sales).toLocaleString("en-IN") : "-"}
						</span>
					) : type === 2 ? (
						<>
							<span className="production-value">
								{item.production || item.production === 0 ? item.production.toLocaleString("en-IN") : "-"}
							</span>
							{" / "}
							<span className="sales-value">
								{item.sales || item.sales === 0 ? item.sales.toLocaleString("en-IN") : "-"}
							</span>
						</>
					) : null}
					{/* <span className="production-value">{item.production &&item.production===0 ? item.production.toLocaleString("en-IN") : "-"}</span>
				{" / "}
				<span className="sales-value">{item.sales &&item.sales===0 ? item.sales.toLocaleString("en-IN") : "-"}</span> */}
				</Col>
			))}
		</Row>
	);
};
