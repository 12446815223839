import fetchHandler from "../../utils/fetchHandler";
import { PRODUCT } from "../types";

export const fetchMiniProductsList = (successHandler, errorHandler) => {
	const fetchOptions = {
		url: `product_varieties`,
		method: "GET",
		actionType: PRODUCT.FETCH_PRODUCTS_MINI_LIST,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const getProductVarietyList = (body, successHandler, errorHandler) => {
	const fetchOptions = {
		url: "product_varieties",
		method: "POST",
		body: JSON.stringify(body),
		actionType: PRODUCT.GET_PRODUCT_VARIETIES,
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const getProductVarietyDetails = (slug, filterSlug, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `product_variety/${filterSlug}/${slug}`,
		method: "GET",
		actionType: PRODUCT.GET_PRODUCT_VARIETY_DETAILS,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const createProductVariety = (body, successHandler, errorHandler) => {
	const fetchOptions = {
		url: "product_variety",
		method: "POST",
		actionType: PRODUCT.CREATE_PRODUCT_VARIETY,
		body: JSON.stringify(body),
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const updateProductVariety = (slug, body, filterSlug, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `product_variety/${filterSlug}/${slug}`,
		method: "PUT",
		actionType: PRODUCT.UPDATE_PRODUCT_VARIETY,
		body: JSON.stringify(body),
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const deleteProductVariety = (slug, filterSlug, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `product_variety/${filterSlug}/${slug}`,
		method: "DELETE",
		actionType: PRODUCT.DELETE_PRODUCT_VARIETY,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const getSeedsListForGreens = (slug, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `product_variety/green/${slug}/seeds`,
		method: "GET",
		actionType: PRODUCT.GET_SEEDS_FOR_GREENS,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const clearProductDetails = () => {
	return (dispatch) => {
		dispatch({
			type: PRODUCT.CLEAR_PRODUCT_DETAILS,
		});
	};
};

export const productFormSubmitFailed = (value) => ({
	type: PRODUCT.PRODUCT_FORM_SUBMIT_FAILED,
	payload: value,
});

export const accessProductState = (key, value) => ({
	type: PRODUCT.PRODUCT_FORM_ACCESS_STATE,
	payload: { key, value },
});
export const setProductVarietyStatus = (body, successHandler, errorHandler) => {
	const fetchOptions = {
		url: "product_varieties/change_status",
		method: "POST",
		body: JSON.stringify(body),
		actionType: PRODUCT.CHANGE_STATUS,
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const getListOfLocations = (slug, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `product-variety-green-locations/${slug}`,
		method: "GET",
		actionType: PRODUCT.GET_LIST_LOCATION_PV,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};
