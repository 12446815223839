import { TASKS } from "store/types";
import fetchHandler from "../../utils/fetchHandler";

// Bulk assign tasks [COMMON]
export const bulkAssignTasks = (body, successHandler, errorHandler) => {
	const fetchOptions = {
		url: "bulk_assigning",
		method: "POST",
		body: JSON.stringify(body),
		actionType: TASKS.BULK_ASSIGN_TASKS,
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const repeatAllTasks = (body, successHandler, errorHandler) => {
	const fetchOptions = {
		url: "repeat-task",
		method: "POST",
		body: JSON.stringify(body),
		actionType: TASKS.REPEAT_ALL_TASKS,
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};
