import { number, required, minValue, requiredDateObj, isInt } from "../../../constant/formValidators";

export const OPPackageDetailsFormFields = [
	{
		label: "label.packaging_start_date_and_time_req",
		name: "packaging_start_date",
		type: "date-time-picker",
		placeholder: "label.select_date_and_time",
		validate: [requiredDateObj],
	},
	{
		label: "label.actual_cases_packed_req",
		name: "actual_cases_packed",
		type: "text",
		placeholder: "label.enter_cases",
		validate: [required, number, minValue(0), isInt],
	},
	{
		label: "label.packaging_end_date_and_time_req",
		name: "packaging_end_date",
		type: "date-time-picker",
		placeholder: "label.select_date_and_time",
		validate: [requiredDateObj],
	},
	{
		label: "label.best_buy_days",
		name: "best_buy_days",
		type: "select-react",
		placeholder: "label.select_best_buy_days",
	},
	{
		label: "label.best_buy_date_req",
		name: "best_buy_date",
		type: "date-picker",
		placeholder: "label.enter_date",
		validate: [requiredDateObj],
	},
	{
		label: "label.change_over_time_req",
		name: "change_over_time",
		type: "text",
		placeholder: "label.enter_changeover_time",
		validate: [required, number, minValue(0), isInt],
	},
	{
		label: "label.downtime_req",
		name: "down_time",
		type: "text",
		placeholder: "label.enter_downtime",
		validate: [required, number, minValue(0), isInt],
	},
	{
		label: "label.lot_no_req",
		name: "lot_no",
		type: "text",
		placeholder: "label.enter_lot_number",
		validate: [required, number, minValue(0), isInt],
	},
	{
		label: "label.actual_no_of_employees",
		name: "no_of_employees",
		type: "text",
		placeholder: "label.enter_employees_count",
		validate: [number, minValue(0), isInt],
	},
];

export const getValidations = (field) => {
	switch (field) {
		case "packaging_start_date":
			return required;
		case "pond_number":
			return required;
		// case "number_of_boards":
		// 	return [required, number, minValueBoard];
		case "location":
			return [required];
		case "product_variety":
			return [required];
		case "flag_color":
			return [required];
		// case "expected_date":
		// 	return [requiredDateObj];
		default:
			return [];
	}
};

export const bestBuyDays = [
	{
		label: "12 Days",
		value: "1",
	},
	{
		label: "15 Days",
		value: "2",
	},
	{
		label: "16 Days",
		value: "3",
	},
];
