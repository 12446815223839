import React, { useState } from "react";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Surface, Symbols, ResponsiveContainer } from "recharts";
import { includes, map, uniq, toPairs } from "lodash";
import { colorArray, fullChartColorsList } from "../../../utils/helper";
import { cartesianGridProps, yAxisProps, xAxisProps, toolTipProps, legendProps } from "./viewComponents";

const Chart = ({ data, timeFrame }) => {
	const [disabled, setDisabled] = useState([]);
	const series = uniq(map(data, "unit"));
	const legendKeys = Object.keys(data);
	const chartColors = [];
	legendKeys.map((column, i) => (chartColors[column] = colorArray[i] ? colorArray[i] : fullChartColorsList[i % 20]));

	const handleClick = (dataKey) => {
		if (includes(disabled, dataKey)) {
			setDisabled(disabled.filter((obj) => obj !== dataKey));
		} else {
			setDisabled(disabled.concat(dataKey));
		}
	};

	const renderCusomizedLegend = ({ payload }) => {
		return (
			<div className="customized-legend custom-scroll">
				{payload.map((entry, i) => {
					const { dataKey, color } = entry;
					const active = includes(disabled, dataKey);
					const style = {
						color: active ? "#AAA" : "#000",
						marginLeft: "10px",
					};

					return (
						<span key={i} onClick={() => handleClick(dataKey)} style={style}>
							<Surface width={10} height={10}>
								<Symbols cx={5} cy={5} type="circle" size={50} fill={color} />
								{active && <Symbols cx={5} cy={5} type="circle" size={25} fill={"#FFF"} />}
							</Surface>
							<span>{dataKey}</span>
						</span>
					);
				})}
			</div>
		);
	};
	return (
		<>
			{data ? (
				<ResponsiveContainer aspect={3}>
					<LineChart>
						<CartesianGrid {...cartesianGridProps} />
						<XAxis dataKey="date" type="category" allowDuplicatedCategory={false} {...xAxisProps} />
						{series.map((s, i) => (
							<YAxis
								{...yAxisProps}
								type="number"
								yAxisId={s}
								orientation={i === 0 ? "left" : "right"}
								dataKey="value"
								key={i}
							/>
						))}
						<Tooltip {...toolTipProps} wrapperStyle={{ pointerEvents: "auto" }} />
						<Legend
							{...legendProps}
							content={renderCusomizedLegend}
							payload={toPairs(chartColors).map((pair) => ({
								dataKey: pair[0],
								color: pair[1],
							}))}
						/>
						{toPairs(chartColors)
							.filter((pair) => !includes(disabled, pair[0]))
							.map((pair, i) => (
								<Line
									yAxisId={data[pair[0]]["unit"]}
									type="monotone"
									stroke={pair[1]}
									dataKey="value"
									data={data[pair[0]]["data"]}
									name={data[pair[0]]["name"]}
									key={pair[0]}
									dot={false}
								/>
							))}
					</LineChart>
				</ResponsiveContainer>
			) : (
				""
			)}
		</>
	);
};

export default Chart;
