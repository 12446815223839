import React from "react";
import { Col, Row } from "react-bootstrap";
import { TaskDetailsView } from "./viewHelper";

export const labelledValue = (item, details = {}) => (
	<Col className={`${item.classes} mb-3`} key={item.label}>
		<p className="d-flex align-items-center view-labels mb-1">
			<span className="light-badge-pill"></span>
			{item.label}
		</p>
		<p className="ml-4 view-values">
			{item.render ? item.render(details) : details ? details[details.transfer_request_data] || "-" : "-"}
		</p>
	</Col>
);
const EditButton = ({ onClick }) => (
	<button className="btn btn-cell btn-light btn-sm ml-2 float-right" onClick={() => onClick()}>
		<i className="icon icon-edit" />
	</button>
);
export const BasicDetails = ({ taskDetails, onClickEditDetails, requestDetails, onClickFullFillmentDetails }) => (
	<div>
		<EditButton onClick={() => onClickEditDetails(2)} />
		<h2 className="form-headers mt-4 mb-4 pl-0">Task Details</h2>
		{taskDetails && <Row>{TaskDetailsView.map((item) => labelledValue(item, taskDetails))}</Row>}
	</div>
);

