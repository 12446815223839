import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { getAllPrivilegesList, updatePrivileges } from "../../store/actions/privilegesAction";
import { ContentWrap, Breadcrumbs } from "../../elements";
import Table, { AutoResizer } from "react-base-table";
//import "react-base-table/styles.css";
import "./index.scss";
import { getFixedColumns, getColumnKeys, updateRequestArray, updateRenderArray } from "./helpers";
import { forEach, isEmpty, uniq, pull, map } from "lodash";
import { errorMessage } from "../../utils/errorMessage";
import { successMessage } from "../../utils/successMessage";
import TableLoader from "../../utils/table/tableLoader";

const AcessTable = ({ getAllPrivilegesList, privilegesList = {}, updatePrivileges, permissionDetails }) => {
	const [isEditable, setIsEditable] = useState(false);
	const [permissionsList, setPermissionsList] = useState([]);
	const [privileges, setPrivileges] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const { roles } = privilegesList;
	const updatePermission =
		permissionDetails && permissionDetails.access && permissionDetails.access.permissions.update_privilages
			? permissionDetails.access.permissions.update_privilages
			: "";
	const listPermission =
		permissionDetails && permissionDetails.access && permissionDetails.access.permissions.view_privilages
			? permissionDetails.access.permissions.view_privilages
			: "";

	// Check/Uncheck roles array
	const handleRolesArray = (doAddToArr, existingRoleArr = [], clickedRoleID) =>
		doAddToArr ? uniq([...existingRoleArr, ...[clickedRoleID]]) : pull(existingRoleArr, clickedRoleID);

	// On change checkboxes
	const onChangePermission = (e, roleId, permissionDetails) => {
		if (permissionDetails) {
			const updatedRolesInPermission = handleRolesArray(e.target.checked, permissionDetails.permission_roles, roleId);
			// Array to pass into API
			setPrivileges(updateRequestArray(privileges, permissionDetails.id, updatedRolesInPermission));
			// Array to render main table
			setPermissionsList(updateRenderArray(permissionsList, permissionDetails.id, updatedRolesInPermission));
		}
	};

	// Handle Summary Checkbox
	const onChangeSummary = (e, role, permission) => {
		const roleId = role.id;
		let prState = privileges;
		let permState = permissionsList;

		forEach(permission, (clickedPermission) => {
			const updatedRolesInPermission = handleRolesArray(e.target.checked, clickedPermission.permission_roles, roleId);
			const clickedPermissionId = clickedPermission.id;
			prState = updateRequestArray(prState, clickedPermissionId, updatedRolesInPermission);
			permState = updateRenderArray(permState, clickedPermissionId, updatedRolesInPermission);
		});
		setPrivileges(prState);
		setPermissionsList(permState);
	};

	const hideLoader = () => {
		setIsLoading(false);
	};

	// Submit update
	const onUpdatePrivileges = () => {
		setIsLoading(true);
		const successHandler = (e) => {
			hideLoader();
			setIsEditable(false);
			successMessage(e);
		};
		const errorHandler = (e) => {
			hideLoader();
			setIsEditable(false);
			errorMessage(e);
		};
		updatePrivileges({ privilages: privileges }, successHandler, errorHandler);
	};

	useEffect(() => {
		if (!isEmpty(privilegesList && privilegesList.permissions)) {
			setPermissionsList(privilegesList.permissions);
		}
	}, [privilegesList]);

	useEffect(() => {
		setIsLoading(true);
		getAllPrivilegesList(hideLoader, hideLoader);
	}, [getAllPrivilegesList]);

	const columns = getFixedColumns(roles);
	const expandColumnKey = "column-0";
	const columnKeys = getColumnKeys(columns);

	const enumerateColumns = (permissionData) => {
		const newList = [];
		columnKeys &&
			columnKeys.forEach(function (columnKey, i) {
				newList[columnKey] = {
					flag: "parent",
					key: roles[i]["id"],
					role: roles[i],
					disabled: !isEditable,
					permission: permissionData.permissions,
					roles: roles,
					changeFunction: onChangeSummary,
				};
			});

		return newList;
	};

	const enumerateColumnsChildren = (permissionData, permissionRoles, permissionId, details) => {
		const newList = [];
		columnKeys &&
			columnKeys.forEach(function (columnKey, i) {
				newList[columnKey] = {
					flag: "child",
					key: roles[i]["id"],
					role: roles[i],
					disabled: !isEditable,
					permissionRoles: permissionRoles,
					permissionId: permissionId,
					details: details,
					permission: permissionData.permissions,
					roles: roles,
					changeFunction: onChangePermission,
				};
			});
		return newList;
	};

	const makeChildrenArray = (permissionData, key) => {
		const getChildrenFixedColumns = permissionData.permissions && map(permissionData.permissions, "display_name");
		const getChildrenFixedColumnsPermissionRoles =
			permissionData.permissions && map(permissionData.permissions, "permission_roles");
		const getChildrenFixedColumnsPermissionIds = permissionData.permissions && map(permissionData.permissions, "id");
		const childrenArray = permissionData.permissions.map((permission, i) => {
			return {
				children: [],
				id: key + "-sub-" + i,
				parentId: key,
				permissionRoles: getChildrenFixedColumnsPermissionRoles[i],
				permissionId: getChildrenFixedColumnsPermissionIds[i],
				details: permission,
				"column-0": getChildrenFixedColumns[i],
				...enumerateColumnsChildren(
					permissionData,
					getChildrenFixedColumnsPermissionRoles[i],
					getChildrenFixedColumnsPermissionIds[i],
					permission
				),
			};
		});
		return childrenArray;
	};
	const data = [];
	permissionsList.forEach(function (permissionData, key) {
		data[key] = {
			children: makeChildrenArray(permissionData, key === 0 ? "frozen-row-0" : "row-" + key),
			id: key === 0 ? "frozen-row-0" : "row-" + key,
			permission: permissionData.permissions ? permissionData.permissions : "",
			role: permissionData.name,
			roleKey: permissionData.name,
			disabled: !isEditable,
			onChangePermission: onChangePermission,
			onChangeSummary: onChangeSummary,
			parentId: null,
			"column-0": permissionData.name ? permissionData.name : "",
			...enumerateColumns(permissionData),
		};
	});

	return (
		<ContentWrap>
			<div className="d-flex align-items-center mb-4">
				<Breadcrumbs keyValue="accessPrivilege" showLabel={listPermission} label={isEditable ? "Edit" : "View"} />
				{listPermission && updatePermission ? (
					<button
						className={`btn btn-cell btn-md ml-2 ${isEditable ? "btn-primary" : "btn-light"}`}
						onClick={() => setIsEditable(!isEditable)}>
						<i className="icon icon-edit" />
					</button>
				) : (
					""
				)}
			</div>
			<div className="accordion-table position-relative">
				{listPermission ? (
					<AutoResizer>
						{({ width, height }) => (
							<Table
								classPrefix="AdvanceTable"
								width={width}
								height={height}
								fixed
								headerHeight={60}
								columns={columns}
								data={data}
								expandColumnKey={expandColumnKey}
							/>
						)}
					</AutoResizer>
				) : (
					""
				)}
				{isLoading}
				{/* Loader */}
				{isLoading && !permissionDetails && (
					<div className="table-loader-wrapper">
						<TableLoader />
					</div>
				)}
			</div>
			{isEditable && !isEmpty(privilegesList) && (
				<div className="d-flex justify-content-end mt-5">
					<button
						className="btn btn-primary"
						type="submit"
						disabled={isEmpty(privileges)}
						onClick={onUpdatePrivileges}>
						Apply
					</button>
				</div>
			)}
		</ContentWrap>
	);
};

//export default AcessTable;
const mapStateToProps = (state) => {
	const { Privileges } = state;
	const { privilegesList } = Privileges;
	return {
		privilegesList,
		permissionDetails: state.Auth.permissionDetails,
	};
};

const mapDispatchToProps = {
	getAllPrivilegesList,
	updatePrivileges,
};
export default connect(mapStateToProps, mapDispatchToProps)(AcessTable);
