import React from "react";
import { Button, Row, Col, Form } from "react-bootstrap";
import { reduxForm, Field, change } from "redux-form";
import { forecastFormFields } from "./formHelper";
import { connect, useDispatch } from "react-redux";
import { createStructuredSelector } from "reselect";
import {
	fetchLaneCalculatorData,
	setTableViewColumns,
	setTableViewDropDown,
	fetchSkusPvList,
	resetFieldLaneUsage,
	setProductVarieties,
	resetLaneUsageLC
} from "store/actions";
import { getLocationList, laneToCaseSkuList, getProductVarietyList } from "../../../../store/selectors/laneCalculatorSelectors";
import FormGroups from "utils/formGroup";
const CaseToLaneForm = ({
	locationList,
	fetchSkusPvList,
	skuList,
	submitting,
	handleSubmit,
	reset,
	resetFieldLaneUsage,
	setProductVarieties,
	productVariety,
	resetLaneUsageLC
}) => {
	const dispatch = useDispatch();
	const onSubmit = () => {
		setProductVarieties(productVariety);
		resetLaneUsageLC();
	};
	const getOptions = (name) => {
		switch (name) {
			case "location_slug":
				return locationList;
			case "skus":
				return skuList;
			default:
				return [];
		}
	};
	const onSelect = (event, newValue, previousValue, field) => {
		if (field === "location_slug") {
			fetchSkusPvList(event.value);
			dispatch(change("laneCalculatorForm", "skus", []));
		}
	};
	const getFormFieldHtml = (formfields) => {
		let formFieldHtml = [];
		formFieldHtml = formfields.map((field, idx) => {
			return (
				<React.Fragment key={idx}>
					<Col className="forecast-form-column">
						<Form.Group
							className={field.name === "exclude_wastage" && "mt-5"}
							controlId={`formControl ${field.name}`}>
							<Field
								name={field.name}
								type={field.type}
								label={field.label}
								placeholder={field.placeholder}
								component={FormGroups}
								validate={field.validate}
								onBlur={(event) => event.preventDefault()}
								options={getOptions(field.name)}
								onChange={(event, newValue, previousValue, name) =>
									onSelect(event, newValue, previousValue, name)
								}
								selectMenuHeight={200}
							/>
						</Form.Group>
					</Col>
				</React.Fragment>
			);
		});
		return (
			<Row className="forecast-form-row">
				{formFieldHtml}
				<Col className="form-submit-col">
					<Button type="submit" disabled={submitting} className="apply-btn">
						Apply
					</Button>
					<Button
						className="reset-btn"
						disabled={submitting}
						onClick={() => {
							reset();
							resetFieldLaneUsage();
						}}>
						<i className="icon icon-reset mr-2" />
					</Button>
				</Col>
			</Row>
		);
	};

	return (
		<div className="forecast-form-wrapper">
			<Form onSubmit={handleSubmit(onSubmit)} className="laneCalculatorForm">
				{getFormFieldHtml(forecastFormFields)}
			</Form>
		</div>
	);
};

const mapStateToProps = createStructuredSelector({
	locationList: getLocationList,
	skuList: laneToCaseSkuList,
	productVariety: getProductVarietyList,
});

export default connect(mapStateToProps, {
	fetchLaneCalculatorData,
	setTableViewColumns,
	setTableViewDropDown,
	fetchSkusPvList,
	resetFieldLaneUsage,
	setProductVarieties,
	resetLaneUsageLC
})(reduxForm({ form: "laneCalculatorForm", enableReinitialize: true })(CaseToLaneForm));
