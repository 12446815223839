import React from "react";
import { connect } from "react-redux";
import { find } from "lodash";
import Listing from "../../../partials/listings";
import { renderColumns } from "./helper";
import { getGerminationTasks } from "../../../store/actions";
import TableLoader from "../../../utils/table/tableLoader";

const SeedingGerminationListing = ({
	filter,
	setFilter,
	refetch,
	assigneeList,
	onChangeCheckbox,
	isLocationSelected,
	handleEditPanel,
	handleViewPanel,
	listingPageLoader,
	getGerminationTasks,
	permissionDetails,
	exportFlag,
	exportHandlerFlag
}) => {
	const listPermission =
		permissionDetails && permissionDetails.germination && permissionDetails.germination.permissions.list_germination_task
			? permissionDetails.germination.permissions.list_germination_task
			: "";

	// Filter
	const addToFilter = (e) => {
		let obj = [];
		e.forEach((item) => {
			return (obj =
				item && item.column
					? [
							...obj,
							{
								column_name: item.column.accessor,
								search: item.value,
							},
					  ]
					: obj);
		});
		const locationFilter = find(filter, ["column_name", "location_slug"]);
		locationFilter ? setFilter([...obj, locationFilter]) : setFilter(obj);
	};

	const columns = renderColumns({
		handleEditPanel,
		handleViewPanel,
		addToFilter,
		isLocationSelected,
		onChangeCheckbox,
		checkedAssignees: assigneeList,
		permissionDetails,
	});

	return (
		<div className="position-relative">
			{listPermission ? (
				<Listing
					fetchAction={getGerminationTasks}
					columns={columns}
					refetch={refetch}
					filter={filter}
					sort_name="task_id"
					exportFlag={exportFlag}
					exportHandlerFlag={()=>{exportHandlerFlag()}}
				/>
			) : (
				""
			)}

			{/* Loader */}
			{listingPageLoader && (
				<div className="table-loader-wrapper">
					<TableLoader />
				</div>
			)}
		</div>
	);
};

const mapStateToProps = (state) => ({
	permissionDetails: state.Auth.permissionDetails,
});
const mapDispatchToProps = {
	getGerminationTasks,
};

export default connect(mapStateToProps, mapDispatchToProps)(SeedingGerminationListing);
