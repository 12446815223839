import React, { useState } from "react";
import { Button, Row, Col, Form } from "react-bootstrap";
import { connect } from "react-redux";
import { reduxForm, Field, change } from "redux-form";
import { useDispatch } from "react-redux";
import { createStructuredSelector } from "reselect";
import {
	getFormToDate,
	getLocationList,
	getFormFromDate,
	getThirtyWeeks,
	getPondList,
	getRenderType,
	// getNutrientList,
	// getRenderType,
	// getNutrientList,
	// getProductVarietyList,
	// getRenderType,
	// getSeasonList,
} from "store/selectors/toAddListSelectors";
// import { fetchDataWSR, setColumnsWSR, setDataWSR, setLoaderWSR } from "store/actions";

import FormGroups from "utils/formGroup";
import { toAddListFormFields } from "./formHelper";

import moment from "moment";
import { errorMessage } from "utils/errorMessage";
import Util from "utils/Util";
import { setLoaderToAddList, fetchDataToAddList, fetchToAddListNutrientList } from "store/actions";

const ToAddListForm = ({
	handleSubmit,
	reset,
	submitting,
	pristine,
	locationList,
	fromDate,
	toDate,
	maxDate,
	pondList,
	setLoaderToAddList,
	fetchDataToAddList,
	renderType,
	fetchToAddListNutrientList,
}) => {
	const [startDate, setStartDate] = useState(null);
	const [endDate, setEndDate] = useState(null);
	const dispatch = useDispatch();

	const onSubmit = (values) => {
		if (renderType === 0) {
			setLoaderToAddList();
			const submitData = {
				location_slug: values.location.value,
				pond: values.pond.value,
				start_date: Util.getMDYFormatUtcDate(startDate),
				end_date: Util.getMDYFormatUtcDate(endDate),
			};
			const successHandler = () => {
				setLoaderToAddList();
			};
			const errorHandler = (e) => {
				errorMessage(e);
				setLoaderToAddList();
			};
			fetchDataToAddList(submitData, successHandler, errorHandler);
		} else {
			setLoaderToAddList();
			const submitData = {
				location_slug: values.location.value,
				pond: values.pond.value,
				start_date: Util.getMDYFormatUtcDate(values.week[0]),
				end_date: Util.getMDYFormatUtcDate(values.week[1]),
			};
			const successHandler = () => {
				setLoaderToAddList();
			};
			const errorHandler = (e) => {
				errorMessage(e);
				setLoaderToAddList();
			};
			fetchToAddListNutrientList(submitData, successHandler, errorHandler);
		}
	};

	const getOptions = (name) => {
		switch (name) {
			case "location":
				return locationList;
			case "pond":
				return pondList || [];

			default:
				return [];
		}
	};

	const onDateChange = (dates) => {
		const [start, end] = dates;

		if (dates && dates[0]) {
			const first = new Date(moment(start).startOf("week"));
			const last = end ? new Date(moment(end).endOf("week")) : null;
			if (startDate && endDate) {
				setStartDate(first);
				setEndDate(null);
			} else {
				setStartDate(first);
				last && setEndDate(last);
			}
		} else {
			setStartDate(null);
			setEndDate(null);
		}
	};

	const onChangeForm = (name, event) => {
		if (name === "location") {
			dispatch(change("toAddListForm", "pond", ""));
		}
	};

	const getFormFieldHtml = (formfields) => {
		let formFieldHtml = [];

		formFieldHtml = formfields.map((field, idx) => {
			return (
				<React.Fragment key={idx}>
					<Col className="croptimals-form-column">
						<Form.Group controlId={`formControl ${field.name}`}>
							{field.name === "week" ? (
								<Field
									name={field.name}
									type={field.type}
									label={field.label}
									placeholder={field.placeholder}
									component={FormGroups}
									startDate={startDate}
									endDate={endDate}
									onChange={onDateChange}
									validate={field.validate}
									dateFormat={"MM-DD-YYYY"}
									pickerTypeSelector={0}
									maxDate={fromDate && !toDate && maxDate}
									onBlur={(event) => event.preventDefault()}
								/>
							) : (
								<Field
									name={field.name}
									type={field.type}
									label={field.label}
									placeholder={field.placeholder}
									component={FormGroups}
									validate={field.validate}
									options={getOptions(field.name)}
									startDate={startDate}
									endDate={endDate}
									onChange={(event) => onChangeForm(field.name, event)}
									selectMenuHeight={200}
								/>
							)}
						</Form.Group>
					</Col>
				</React.Fragment>
			);
		});

		return (
			<Row className="wsr-form-row">
				{formFieldHtml}
				<Col className="form-submit-col-wsr">
					<Button type="submit" disabled={submitting} className="apply-btn">
						Apply
					</Button>
					<Button
						className="reset-btn"
						disabled={pristine || submitting}
						onClick={() => {
							setStartDate(null);
							setEndDate(null);
							reset();
						}}>
						<i className="icon icon-reset mr-2" />
					</Button>
				</Col>
			</Row>
		);
	};

	return (
		<div className="to-add-list-form-wrapper">
			<Form onSubmit={handleSubmit(onSubmit)} className="forecastForm">
				{getFormFieldHtml(toAddListFormFields)}
			</Form>
		</div>
	);
};

const mapStateToProps = createStructuredSelector({
	locationList: getLocationList,
	fromDate: getFormFromDate,
	toDate: getFormToDate,
	maxDate: getThirtyWeeks,
	pondList: getPondList,
	renderType: getRenderType,
});

export default connect(mapStateToProps, {
	setLoaderToAddList,
	fetchDataToAddList,
	fetchToAddListNutrientList,
})(reduxForm({ form: "toAddListForm", enableReinitialize: true })(ToAddListForm));
