import { keys, values } from "lodash";
import React, { useEffect, useState } from "react";
import TableForm from "./tableForm";

const LabResultTable = (props) => {
	const { initialData, slugKey, renderKey } = props;
	const [initialValues, setinitialValues] = useState(null);

	useEffect(() => {
		if (initialData) {
			const dataObj = {};
			// eslint-disable-next-line array-callback-return
			values(initialData).map((item, i) => {
				dataObj[keys(initialData)[i]] = {};
				// eslint-disable-next-line array-callback-return
				item.croptimals.map((item_i, j) => {
					dataObj[keys(initialData)[i]][slugKey ? item_i[slugKey] : item_i.slug] = renderKey
						? item_i[renderKey]
						: item_i.value;
				});
			});
			setinitialValues(dataObj);
		}
	}, [initialData, renderKey, slugKey]);

	return initialValues ? <TableForm initialValues={initialValues} {...props} /> : null;
};

export default LabResultTable;
