import { ListingBtn } from "elements";
import React from "react";
import { Col, Row } from "react-bootstrap";
import ActionForm from "./actionForm";
import Util from "../Util";

export const getActionColumns = (
	data,
	actionColumnWidth,
	posThreeRef,
	editableRows,
	setEditableRows,
	onDelete,
	dispatch,
	submit,
	reset,
	cancelEdit,
	customEdit,
	editPermission,
	deletePermission,
	keyMapping,
	onClickOrderShip
) => {

	return (
		<div className="action-fixed-column right-fixed" style={{ width: actionColumnWidth, position: "relative" }}>
			<div ref={posThreeRef} className="sticky-head sticky-head-right">
				<Row className={"right-head action-table-head "} style={{ width: `${actionColumnWidth}px` }}>
					<div className="show-shadow-row-right shadow-head" />

					<Col className="action-table-col">
						<span>Actions</span>
					</Col>
				</Row>
			</div>
			<div className="action-table-body">
				{data.map((dataItem, d_ind) => (
					<Row key={d_ind} className={"action-table-body-row right-row position-relative"}>
						<div className="show-shadow-row-right" />
						<Col className="action-table-col">
							{dataItem.status === null ? (
								<>
									{dataItem.is_edittable === true && (
										<>
											{editableRows.indexOf(d_ind) === -1 ? (
												<>
													<ListingBtn
														tooltipText="Ship"
														iconClass="icon-shipping-icon"
														onClick={() => {
															onClickOrderShip(dataItem);
														}}
														btnClass="btn btn-cell btn-light btn-sm"
													/>
													{editPermission && (
														<ListingBtn
															tooltipFontSize={11}
															tooltipText="Edit"
															type="span"
															iconClass="icon icon-edit"
															onClick={() =>
																customEdit
																	? customEdit(dataItem)
																	: setEditableRows([...editableRows, d_ind])
															}
															btnClass="btn btn-cell btn-light btn-sm"
														/>
													)}
													{deletePermission && (
														<ListingBtn
															tooltipFontSize={11}
															tooltipText="Delete"
															iconClass="icon icon-trash"
															type="span"
															onClick={() => onDelete(dataItem)}
															btnClass="btn btn-cell btn-light btn-sm"
														/>
													)}
												</>
											) : (
												<>
													<ListingBtn
														tooltipFontSize={11}
														tooltipText="Save"
														type="span"
														iconClass={"icon icon-check modal-table-icon"}
														onClick={(e) => dispatch(submit(`actionForm${d_ind}`))}
														btnClass="btn btn-cell btn-light btn-sm"
													/>

													<ListingBtn
														tooltipFontSize={11}
														tooltipText="Cancel"
														type="span"
														iconClass={"icon icon-close modal-table-icon"}
														onClick={(e) => {
															dispatch(reset(`actionForm${d_ind}`));
															cancelEdit(d_ind);
														}}
														btnClass="btn btn-cell btn-light btn-sm"
													/>
												</>
											)}
										</>
									)}
								</>
							) : (
								<ListingBtn
									disabled={true}
									tooltipFontSize={11}
									tooltipText="Shipped"
									type="btn"
									iconClass="icon-shipped"
									btnClass="btn btn-cell btn-light btn-sm"
								/>
							)}
						</Col>
					</Row>
				))}
			</div>
		</div>
	);
};
export const getData = (
	data,
	initialData,
	posTwoRef,
	columns,
	slugKey,
	renderKey,
	render,
	editableRows,
	onSave,
	cancelEdit,
	validation,
	dateValidation,
	keyMapping,
	option
) => {

	return (
		<>
			<div ref={posTwoRef} className="sticky-head">
				<Row className="action-table-head position-relative">
					{columns.map((item, idx) => (
						<Col key={idx} className="action-table-col">
							<span>{item.label && item.label}</span>
						</Col>
					))}
				</Row>
			</div>
			<div className="action-table-body">
				{data.map((a_item, a_ind) => {
					const initialValues = {};
					let options = [];
					a_item &&
						columns &&
						columns.forEach((columnItem) => {
							if (columnItem.type === "date-picker") {
								initialValues[columnItem.key] = Util.getDateObjectFormat(a_item[columnItem.key]);
							} else if (columnItem.type === "select-react") {
								options = Util.mapOptions(option, "location_name", "slug");
								const optionValue = options.filter((item) => item.label === a_item[columnItem.key]);
								initialValues[columnItem.key] = optionValue && optionValue[0] && optionValue[0];
							} else {
								initialValues[columnItem.key] = a_item[columnItem.key];
							}
						});
					return (
						data && (
							<ActionForm
								key={a_ind.toString()}
								form={`actionForm${a_ind}`}
								columns={columns}
								renderKey={renderKey}
								render={render}
								a_ind={a_ind}
								editable={editableRows.indexOf(a_ind) !== -1 ? true : false}
								a_item={data}
								slugKey={slugKey}
								initialValues={initialValues}
								slug={a_item}
								onSave={onSave}
								validation={validation}
								dateValidation={dateValidation}
								cancelEdit={cancelEdit}
								option={options}
							/>
						)
					);
				})}
			</div>
		</>
	);
};

export const fixedColumnTable = (
	data,
	fixedColumnWidth,
	posOneRef,
	fixedColumnHeading,
	renderKeyItem,
	keyMapping,
	initialData
) => {
	return (
		<div className="action-fixed-column" style={{ width: fixedColumnWidth, position: "relative" }}>
			<div ref={posOneRef} className="sticky-head">
				<Row className={"action-table-head "} style={{ width: `${fixedColumnWidth}px` }}>
					<div className="show-shadow-row shadow-head" />

					<Col className="action-table-col columnrow">
						<span>{fixedColumnHeading}</span>
					</Col>
				</Row>
			</div>
			<div className="action-table-body">
				{initialData.map((dataItem, d_ind) => (
					<Row key={d_ind} className={"action-table-body-row position-relative"}>
						<div className="show-shadow-row " />
						<Col className="action-table-col columnrow">{dataItem.sku_name}</Col>

						{/* <Col className="action-table-col">{renderKeyItem(dataItem)}</Col> */}
					</Row>
				))}
			</div>
		</div>
	);
};
