import { PRODUCT } from "../types";
import { map, isEmpty, find, findKey, reduce, replace, capitalize, trimEnd } from "lodash";
import Util from "../../utils/Util";

const initialState = {
	productsMiniList: [],
	productVarietiesList: [],
	seedsListForGreens: [],
	productVarietyDetails: {},
	editDetailsFormInitialValues: {},
	isProductFormError: 0,
	formErrObj: null,
	seedsErrorTab: 0,
	seasonsErrorTab: "0_0",
	activeLocation: "",
	locationList: {},
};

export default function (state = initialState, { type, payload }) {
	switch (type) {
		case PRODUCT.FETCH_PRODUCTS_MINI_LIST:
			return {
				...state,
				productsMiniList: payload.data || [],
			};
		case PRODUCT.GET_PRODUCT_VARIETIES:
			state = {
				...state,
				productVarietiesList: payload.data || [],
			};
			return state;
		case PRODUCT.GET_SEEDS_FOR_GREENS:
			return {
				...state,
				seedsListForGreens: payload.data || [],
			};
		case PRODUCT.GET_PRODUCT_VARIETY_DETAILS:
			return {
				...state,
				formErrObj: null,
				seedsErrorTab: 0,
				seasonsErrorTab: "0_0",
				seedsListForGreens: [],
				isProductFormError: 0,
				productVarietyDetails: payload.data || {},
				editDetailsFormInitialValues: constructProductFormArr(payload.data) || {},
			};
		case PRODUCT.CLEAR_PRODUCT_DETAILS:
			return {
				...state,
				formErrObj: null,
				seedsErrorTab: 0,
				seasonsErrorTab: "0_0",
				isProductFormError: 0,
				seedsListForGreens: [],
				productVarietyDetails: {},
				editDetailsFormInitialValues: {},
			};
		case PRODUCT.PRODUCT_FORM_SUBMIT_FAILED:
			const tabKeys = {
				seedTab: 0,
				seasonTab: "0_0",
				formErrObj: null,
			};
			if (!isEmpty(payload.seed_details)) {
				// For keeping tabs active
				const errTab = find(payload.seed_details, (o) => !isEmpty(o));
				const errTabKey = findKey(payload.seed_details, (o) => !isEmpty(o)) || 0;
				if (errTab && !isEmpty(errTab.seasons)) {
					const errSTabKey = findKey(errTab.seasons, (o) => !isEmpty(o)) || 0;
					const errSKey = `${errTabKey}_${errSTabKey}`;
					tabKeys.seasonTab = errSKey;
				} else if (errTab) {
					tabKeys.seasonTab = `${errTabKey}_0`;
				}
				tabKeys.seedTab = errTabKey;

				// For showing corresponding messages
				if (payload && payload.green) {
					tabKeys.formErrObj = `Greens ${payload.green}`;
				} else if (payload && payload.seed) {
					tabKeys.formErrObj = `Seeds ${payload.seed}`;
				} else if (errTab && errTab.location) {
					tabKeys.formErrObj = `Location ${errTab.location}`;
				} else if (errTab && !isEmpty(errTab.seasons)) {
					const seasonError = find(errTab.seasons, (o) => !isEmpty(o)) || 0;
					if (seasonError) {
						const errorMsg = reduce(
							seasonError,
							(result, value, key) => result + `${capitalize(replace(key, "_", " "))} ${value}, `,
							""
						);
						tabKeys.formErrObj = errorMsg ? trimEnd(errorMsg, ", ") || errorMsg : errorMsg;
					}
				}
			} else if (payload) {
				tabKeys.formErrObj = `${payload.green ? `Greens ${payload.green}` : ""} ${
					payload.green && payload.seeds ? `, ` : ""
				} ${payload.seeds ? `Seeds ${payload.seeds}` : ""}`;
			}

			return {
				...state,
				isProductFormError: state.isProductFormError + 1,
				seedsErrorTab: tabKeys.seedTab,
				seasonsErrorTab: tabKeys.seasonTab,
				formErrObj: tabKeys.formErrObj,
			};
		case PRODUCT.PRODUCT_FORM_ACCESS_STATE:
			return {
				...state,
				[payload.key]: payload.value,
			};
		case PRODUCT.GET_LIST_LOCATION_PV:
			return {
				...state,
				locationList: payload.data,
			};
		default:
			return state;
	}
}

const constructProductFormArr = (data) => ({
	prSlug: data.slug,
	green: Util.mapObject(data.green.green_name, data.green.slug),
	seeds: map(data.product_variety_list, (s) => Util.mapObject(s.seed.seed_name, s.seed.slug)),
	seed_details: map(data.product_variety_list, (p) => constructSeedDetailsFormArr(p)),
});
const constructSeedDetailsFormArr = (data) => ({
	seed_name: data.seed.seed_name,
	seed: data.seed.slug,
	slug: data.seed.slug,
	luna_crop_id: data.luna_crop_id || null,
	first_image: data.first_image || null,
	second_image: data.second_image || null,
	third_image: data.third_image || null,
	location: map(data.locations, (l) => Util.mapObject(l.location_name, l.slug)),
	seasons: map(data.seasons, (s) => constructSeasonsFormArr(s)),
});
const constructSeasonsFormArr = (data) => ({
	...data,
	germination_period: data.germination_period || "",
	grow_cycle: data.grow_cycle || "",
	harvesting_period: data.harvesting_period || "",
	shelflife_period: data.shelflife_period || "",
	seed_density: data.seed_density || "",
	board: (data.board && Util.mapObject(data.board.board_name, data.board.slug)) || null,
	germination_temperature: data.germination_temperature || "",
	germination_humidity: data.germination_humidity || "",
	germination_time: data.germination_time || "",
	growing_temperature: data.growing_temperature || "",
	growing_humidity: data.growing_humidity || "",
	growing_light: data.growing_light || "",
	ph: data.ph || "",
	ec: data.ec || "",
	do: data.do || "",
	pond_temperature: data.pond_temperature || "",
	price_per_pound: data.price_per_pound || "",
});
