import React from "react";
import { BreadcrumbHomeRoute, BreadcrumbSubRoutes } from "./breadcrumbConstants";
import { useTranslation } from 'react-i18next';

/**
 * Page Title & Breadcrumbs
 * @param {String} keyValue Key to read breadcrumbs from constants page
 * @param {Boolean} showLabel Permission to show sub labels [List/View/Edit]
 * @param {String} label Sub Labels (Appear to the end of breadcrumbs)
 * @param {node} children
 *
 * @todo Read "keyValue" from route instead of passing keys from parent
 */
export const Breadcrumbs = ({ keyValue = "", showLabel = false, label = "", children = null, className }) => {
	const {t} = useTranslation();
	const {
		pageTitle,
		menuTitle,
		breadCrumbTittle,
		isLinkClickable = false,
		linkRoute = "/",
	} = keyValue ? BreadcrumbSubRoutes[keyValue] : {};
	const { homeRoute, homeLabel } = BreadcrumbHomeRoute;
	const renderLinkRoute = () => (
		<>
			<span>/ </span>
			<a href={linkRoute}>{pageTitle} </a>
		</>
	);
	return (
		<div className="flex-fill">
			{children}
			<h1>{t(pageTitle)}</h1>
			<div className={className ? `${className} page-bread-crumbs` : "page-bread-crumbs"}>
				{/* Home */}
				<a href={homeRoute}>{t(homeLabel)}</a>

				{/* Menu */}
				{menuTitle ? ` / ${t(menuTitle)} ` : null}

				{/* Page Title */}
				{breadCrumbTittle
					? isLinkClickable
						? renderLinkRoute()
						: ` / ${t(breadCrumbTittle)} `
					: pageTitle
					? isLinkClickable
						? renderLinkRoute()
						: ` / ${t(pageTitle)} `
					: null}

				{/* Mode */}
				{showLabel ? (
					<>
						/ <label>{t(label)}</label>
					</>
				) : null}
			</div>
		</div>
	);
};
