import React, { useEffect } from "react";
import { Breadcrumbs, ContentWrap } from "elements";
import Listing from "./listing";
import "../../index.scss";
import TableLoader from "utils/table/tableLoader";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { getForecastRenderType, getLoading } from "store/selectors/forecastSelectors";
import { fetchLocationSkusPF, setForecastRenderType, setLoader } from "store/actions";
import { errorMessage } from "utils/errorMessage";

const ProductionForecastCases = ({ loading, fetchLocationSkusPF, setLoader, setForecastRenderType, renderType }) => {
	useEffect(() => {
		setLoader();
		const successHandler = () => {
			setLoader();
		};
		const errorHandler = (e) => {
			errorMessage(e);
		};
		fetchLocationSkusPF(successHandler, errorHandler);
	}, [fetchLocationSkusPF, setLoader]);

	return (
		<ContentWrap>
			<div className="d-flex align-items-center mb-3">
				<Breadcrumbs keyValue="productionForecast" showLabel={true} label={"Production Forecast Cases"} />
			</div>

			<div className="position-relative">
				<Listing />
				{loading && (
					<div className="table-loader-wrapper">
						<TableLoader />
					</div>
				)}
			</div>
		</ContentWrap>
	);
};

const mapStateToProps = createStructuredSelector({
	loading: getLoading,
	renderType: getForecastRenderType,
});

export default connect(mapStateToProps, { fetchLocationSkusPF, setLoader, setForecastRenderType })(ProductionForecastCases);
