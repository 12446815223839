import React, { useEffect, useReducer, useRef } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Table, Pagination } from "../../utils/table/table";
import TableLoader from "../../utils/table/tableLoader";
import { get } from "lodash";
import { errorMessage } from "../../utils/errorMessage";
import { useTranslation } from "react-i18next";
import Util from "../../utils/Util";

const initialState = {
	data: null,
	total: 0,
	currentPage: 1,
	per_page: 10,
	sort_by: "id",
	sort_type: "asc",
};

const reducer = (state, payload) => {
	return { ...state, ...payload };
};

const Listing = ({
	fetchAction,
	columns,
	search = "",
	refetch = 0,
	filter,
	exportFlag,
	sort_name = "id",
	sort_dir = "",
	defaultDate,
	exportHandlerFlag,
}) => {
	const setArray = () => {
		return sort_dir
			? { ...initialState, sort_by: sort_name, sort_type: sort_dir }
			: { ...initialState, sort_by: sort_name };
	};
	const { t } = useTranslation();
	const [state, dispatch] = useReducer(reducer, setArray());
	const { data, total, currentPage, per_page, sort_by, sort_type } = state;

	function usePrevious(value) {
		const ref = useRef();
		useEffect(() => {
			ref.current = value;
		});
		return ref.current;
	}

	const prevPage = usePrevious(currentPage);

	useEffect(() => {
		const successHandler = (event) => {
			exportHandlerFlag();
			if (event && event.data && event.data.export_url) {
				window.open(event.data.export_url);
			}
		};
		const errorHandler = (event) => {
			errorMessage(event);
		};
		if (exportFlag === true) {
			fetchAction(
				{ search, sort_by, sort_type, per_page, page: currentPage, filter, export: 1 ,timezone: Util.getLocalTimeZone(),},
				successHandler,
				errorHandler
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [exportFlag]);

	useEffect(() => {
		const successHandler = (e) => {
			// current_page > last_page => Goes to last page
			// B: Not going to first page, when filter change happens
			// currentPage === prevPage => Goes to first page
			// B:  even if last page has entries - control goes to page first
			if (e && e.meta && e.meta.current_page > e.meta.last_page) {
				if (currentPage === prevPage) {
					dispatch({
						data: e.data,
						total: e.meta.total,
						currentPage: e.meta.last_page,
					});
				} else {
					dispatch({
						data: e.data,
						total: e.meta.total,
						currentPage: e.meta.last_page,
					});
				}
			} else {
				e &&
					dispatch({
						data: e.data,
						total: e.meta.total,
						currentPage: e.meta.current_page,
					});
			}
		};
		const errorHandler = (e) => {
			errorMessage(e);
		};
		dispatch({ data: null });
		fetchAction({ search, sort_by, sort_type, per_page, page: currentPage, filter }, successHandler, errorHandler);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [fetchAction, search, sort_by, sort_type, per_page, refetch, currentPage, filter]);

	const onSort = (e) => {
		(e.by !== sort_by || e.order !== sort_type) &&
			dispatch({
				sort_by: e.by,
				sort_type: e.order,
			});
	};

	return (
		<div>
			<Table
				filterArr={filter}
				columns={columns}
				data={data}
				onSort={onSort}
				sortBy={sort_by}
				sortDir={sort_type}
				defaultDate={defaultDate}
			/>
			{data ? (
				<Pagination
					total={total}
					count={per_page}
					page={currentPage}
					onChange={(currentPage) => dispatch({ currentPage })}
				/>
			) : (
				<TableLoader />
			)}
			{data && data.length < 1 && <div className="no-results-found text-center">{t("no_result_found")}</div>}
		</div>
	);
};

const mapStateToProps = (state) => {
	const { UI, Auth } = state;
	return {
		accessToken: get(Auth, "accessToken", null),
		userDetails: get(Auth, "userDetails", null),
		mainPreloader: get(UI, "mainPreloader", null),
	};
};

export default connect(mapStateToProps)(withRouter(Listing));
