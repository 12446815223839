/* eslint-disable array-callback-return */
import { keys, values } from "lodash";
import React, { createRef, useEffect, useRef, useCallback } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { connect } from "react-redux";
import "./index.scss";
import { FormSection, reduxForm } from "redux-form";
import { createStructuredSelector } from "reselect";
import { getForecastTableErrors, getForecastTableValues } from "store/selectors/forecastTableSelectors";
import ScrollContainer from "react-indiana-drag-scroll";
import { renderRow } from "./table";
import { localeStringNumber, number, isInt, required } from "constant/formValidators";

const TableForm = ({
	columns,
	initialData: data,
	fixedColumnHeading,
	fixedColumnWidth = 200,
	editable,
	onSave,
	onCancel,
	keyMapping,
	dateFormat,
	tableErrors,
	render,
	renderKey,
	slugKey,
	errorStringsArray,
	handleSubmit,
	initialValues,
	localeString = false,
	isOzPerBoard = false,
	validation = [!isOzPerBoard ? isInt : localeString ? localeStringNumber : number, required],
	pristine,
	total = false,
	tableValues,
	customTotal,
	headers,
	type,
	laneUtilization = false,
}) => {
	const inputRef = useRef(null);
	const draggableRef = createRef(null);
	const scrollRef = createRef(null);
	const fixedRef = createRef(null);
	const posOneRef = createRef(null);
	const posTwoRef = createRef(null);

	useEffect(() => {
		window.addEventListener("scroll", bodyScroll, true);
		return () => {
			window.removeEventListener("scroll", bodyScroll);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	});

	const bodyScroll = () => {
		const elem = fixedRef.current;
		if (elem) {
			const { top, height } = elem.getBoundingClientRect();
			if (height > 0) {
				if (top <= 0) {
					if (height + top <= 120) {
						posOneRef.current.style.top = height - 120 + "px";
						posTwoRef.current.style.top = height - 120 + "px";
					} else {
						posOneRef.current.style.top = Math.abs(top) + "px";
						posTwoRef.current.style.top = Math.abs(top) + "px";
					}
				} else if (top > 0) {
					posOneRef.current.style.top = 0;
					posTwoRef.current.style.top = 0;
				}
			}
		}
	};

	const fixedColumnTable = () => {
		return (
			<div className="forecast-fixed-column" style={{ width: fixedColumnWidth, position: "relative" }}>
				<div ref={posOneRef} className="sticky-head">
					<Row className={"forecast-table-head "} style={{ width: `${fixedColumnWidth + 15}px` }}>
						<div className="show-shadow-row shadow-head" />
						<Col className="forecast-table-col">
							<span>{fixedColumnHeading}</span>
						</Col>
					</Row>
				</div>
				<div className="forecast-table-body">
					{headers &&
						headers.map((dataItem, d_ind) => (
							<Row key={d_ind} className={"forecast-table-body-row position-relative"}>
								<div className="show-shadow-row" />
								{render ? (
									<Col className="forecast-table-col">{dataItem.name}</Col>
								) : (
									<Col className="forecast-table-col">{dataItem}</Col>
								)}
								{/* <Col className="forecast-table-col">{dataItem}</Col> */}
							</Row>
						))}
					{(total || customTotal) && (
						<Row className={"forecast-table-body-row position-relative"}>
							<div className="show-shadow-row" />
							<Col className="forecast-table-col">
								<span className="total-txt">Total</span>
							</Col>
						</Row>
					)}
				</div>
			</div>
		);
	};

	const getData = () => {
		return (
			<>
				<div ref={posTwoRef} className="sticky-head">
					<Row className="forecast-table-head position-relative">
						{columns.map((item, idx) => (
							<Col key={idx} className="forecast-table-col">
								<span>{item}</span>
							</Col>
						))}
					</Row>
				</div>
				<div className="forecast-table-body">
					{headers &&
						headers.map((a_item, a_ind) => (
							<Row key={a_ind} className="forecast-table-body-row">
								<FormSection
									name={`${a_item}`}
									component={renderRow}
									nameKey={render ? `${a_item.slug}` : `${a_item}`}
									columns={columns}
									a_item={data}
									a_ind={a_ind}
									editable={editable}
									renderKey={renderKey}
									render={render}
									slugKey={slugKey}
									validation={validation}
									localeString={localeString}
									type={type}
									laneUtilization={laneUtilization}
								/>
							</Row>
						))}
					{!customTotal && total && (
						<Row className="forecast-table-body-row">
							{columns.map((col_item, ind) => {
								let total = 0;
								headers &&
									headers.map((item_i) => {
										total = total + initialValues[col_item][item_i];
									});
								return (
									<Col key={ind} className="forecast-table-col">
										<span className="total-txt">{!total ? "0" : total.toLocaleString("en-IN")}</span>
									</Col>
								);
							})}
						</Row>
					)}
					{customTotal && customTotal()}
				</div>
			</>
		);
	};

	const onScrollTrack = (e) => {
		const fixedPos = fixedRef.current.getBoundingClientRect().left;
		const scrollingPos = scrollRef.current.getBoundingClientRect().left;

		const shadowElem = [].slice.call(document.getElementsByClassName("show-shadow-row"));

		if (fixedPos + fixedColumnWidth > scrollingPos) {
			// eslint-disable-next-line array-callback-return
			shadowElem.map((node) => {
				node.style.display = "block";

				node.parentNode.classList.add("show-shadow");
			});
		} else {
			// eslint-disable-next-line array-callback-return
			shadowElem.map((node) => {
				node.style.display = "none";
				node.parentNode.classList.remove("show-shadow");
			});
		}
	};

	const onClick = useCallback((event) => {
		if (event.target.tagName === "INPUT") {
			inputRef.current = event.target;
			event.target.focus();
		} else {
			if (inputRef.current) {
				inputRef.current.blur();
				inputRef.current = null;
			}
			event.target.click();
		}
	}, []);

	return columns && data ? (
		<>
			<Form onSubmit={handleSubmit} className="forecastTable">
				<div className="forecast-table-wrapper">
					<div ref={fixedRef} className="forecast-fixed-column-wrapper" style={{ width: fixedColumnWidth }}>
						{fixedColumnTable()}
					</div>
					<ScrollContainer
						ref={draggableRef}
						hideScrollbars={false}
						className="forecast-data-column-wrapper"
						style={{ width: `calc(100% - ${fixedColumnWidth}px)` }}
						onScroll={(e) => onScrollTrack(e)}
						onClick={onClick}>
						<div ref={scrollRef} className="forecast-data-column">
							{getData()}
						</div>
					</ScrollContainer>
				</div>
			</Form>
		</>
	) : null;
};

const mapStateToProps = createStructuredSelector({
	tableErrors: getForecastTableErrors,
	tableValues: getForecastTableValues,
});

export function scrollToFirstError(fieldList, props) {
	const { columns, slugKey } = props;

	// eslint-disable-next-line no-unused-vars
	let elem = "";
	// eslint-disable-next-line array-callback-return
	columns.some((item) => {
		if (item.slug in values(fieldList)[0]) {
			// const elem = document.querySelector(`#${slugKey ? item[slugKey] : item.slug}_${keys(fieldList)[0]}`);

			if (slugKey) {
				elem = document.querySelector(`#${item[slugKey]}_${keys(fieldList)[0]}`);
			} else {
				elem = document.querySelector(`a${item.slug}_${keys(fieldList)[0]}`);
			}

			if (elem) {
				setTimeout(() => {
					elem.scrollIntoView({ behavior: "smooth", block: "center" });
				}, 800);
				return elem;
			}
		}
	});
}

const onSubmit = (val, dispatch, props) => {
	const { slugKey, renderKey, localeString } = props;

	const data = {};
	// eslint-disable-next-line array-callback-return
	values(val).map((item, i) => {
		let count = 0;
		for (const key in item) {
			if (item[key] && item[key] !== "") {
				count += 1;
			}
		}

		if (count > 0) {
			data[keys(val)[i]] = [];
			for (const key in item) {
				if (item[key] && item[key] !== "") {
					const value = localeString ? item[key].replace(/[^0-9-.]/g, "") : item[key];
					if (slugKey) {
						if (renderKey) {
							data[keys(val)[i]].push({
								[slugKey]: key,
								[renderKey]: value,
							});
						} else {
							data[keys(val)[i]].push({
								[slugKey]: key,
								value: value,
							});
						}
					} else {
						if (renderKey) {
							data[keys(val)[i]].push({
								slug: key,
								[renderKey]: value,
							});
						} else {
							data[keys(val)[i]].push({
								slug: key,
								value: value,
							});
						}
					}
				}
			}
		}
	});

	props.onSave(data);
};

export default connect(mapStateToProps)(
	reduxForm({
		form: "LaneCalculator",
		enableReinitialize: true,
		onSubmitFail: (errors, dispatch, submitError, props) => {
			scrollToFirstError(errors, props);
		},
		onSubmit: onSubmit,
	})(TableForm)
);
