import React from "react";
import { useSelector } from "react-redux";
import { Row, Col } from "react-bootstrap";
import ToggleButton from "../../../utils/toggleButton";
import { errorMessage } from "../../../utils/errorMessage";
import { successMessage } from "../../../utils/successMessage";

const SkuView = ({ details = {}, doRefetch, handleEditPanel, updatePermission, changeStatus, fetchSkuDetails }) => {
	const { sku_name, clamshells_per_case, oz_per_clamshell, recipe, location_list, slug } = useSelector(
		(state) => state.Sku.skuDetails
	);
	const labelledValue = (label, value, unit = null) => (
		<Col className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mb-3" key={label}>
			<div key={label}>
				<p className="d-flex align-items-center text-muted mb-2">
					<span className="light-badge-pill"></span>
					<span className="view-labels">{label}</span>
				</p>
				<h6 className="ml-4 view-values">{value ? `${value} ${unit ? unit : ""}` : "-"}</h6>
			</div>
		</Col>
	);
	const successHandler = (e) => {
		doRefetch();
		successMessage(e);
	};
	const errorHandler = (e) => {
		errorMessage(e);
	};
	const refetchSkuDetails = (slug) => {
		fetchSkuDetails(slug, successHandler, errorHandler);
	};
	const locationStatusToggles = (locations, sku_slug) =>
		locations.length > 0 && (
			<Col className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-3">
				<div>
					<p className="d-flex align-items-center text-muted mb-4">
						<span className="light-badge-pill"></span>
						<span className="view-labels">Locations</span>
					</p>
					{locations.map((location, index) => (
						<div className="sku-location-status" key={index}>
							<h6 className="ml-4 mb-3 view-values">{location.name}</h6>
							<ToggleButton
								value={location.active}
								onChange={(e) =>
									changeStatus(
										{ location_slug: location.slug, sku_slug, active: !location.active },
										() => refetchSkuDetails(sku_slug),
										(e) => errorHandler(e)
									)
								}
							/>
						</div>
					))}
				</div>
			</Col>
		);
	return (
		<>
			<div className="px-4 pt-3">
				<div className="d-flex align-items-center justify-content-between mb-3">
					<h2>{sku_name}</h2>
					{updatePermission ? (
						<button className="btn btn-cell btn-light btn-sm ml-2" onClick={() => handleEditPanel(slug)}>
							<span className="edit-icon-background">
								<i className="icon icon-edit" />
							</span>
						</button>
					) : (
						""
					)}
				</div>
				<Row>
					{labelledValue("Oz per clamshell", oz_per_clamshell, "Oz")}
					{labelledValue("Clamshells Per Case", clamshells_per_case)}
					{locationStatusToggles(location_list, slug)}
				</Row>
			</div>
			<div className="px-4 pt-3">
				<div className="d-flex align-items-center justify-content-between mb-3">
					<h2>Recipe</h2>
				</div>
				<Row>
					{recipe
						? recipe.map(function (value, index) {
								return labelledValue(value["product_variety"], value["percentage"], "%");
						  })
						: ""}
				</Row>
			</div>
		</>
	);
};

export default SkuView;
