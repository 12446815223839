import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Row, Col, Form } from "react-bootstrap";
import { reduxForm, Field, change, untouch, getFormValues } from "redux-form";
import { get, isEmpty, map, findKey, uniq, includes, forEach, find, compact, join, uniqBy, filter, pick } from "lodash";
import {
	formCreationFields,
	daysArray,
	getValidations,
	mapOptions,
	daysMap,
	skuMap,
	createSKUPercentageObj,
} from "./formHelper";
import FormGroups from "../../../utils/formGroup";
import { createCustomer, setCustomerViewRender } from "../../../store/actions";
import { errorMessage } from "../../../utils/errorMessage";
import { successMessage } from "../../../utils/successMessage";
import { ButtonPrimary, ButtonLink, ButtonSecondary } from "../../../elements";
import { required, names } from "../../../constant/formValidators";
import TableLoader from "utils/table/tableLoader";
import { CustomerSKUPricingTableManage, checkingIfSumIs100 } from "./customerManageComponents";
import "../customer.scss";
import { NotificationManager } from "react-notifications";

// sku all feature
// view page to edit page - laoder
const CustomerForm = ({
	handleSubmit,
	setCustomerViewRender,
	createCustomer,
	renderType,
	locationList,
	initialValues,
	skuList,
	formValues,
}) => {
	const [isLoading, setisLoading] = useState(false);
	const [dcList, setDcList] = useState(
		initialValues && initialValues.deliveryCentersMapArray
			? initialValues.deliveryCentersMapArray
			: [{ key: 0, skuOptions: [], skufields: [], sku_percentages: {}, flag: "add" }]
	);

	const dispatch = useDispatch();

	// Form Select Field Options
	const getOptions = (field, locationSkuArray = []) => {
		switch (field) {
			case "sku":
				return locationSkuArray ? mapOptions(locationSkuArray, "name", "value") : [];
			case "location":
				return mapOptions(locationList, "location_name", "slug");
			case "delivery_days":
				return [
					{
						value: "All",
						label: "All",
					},
					...daysArray,
				];
			default:
				return [];
		}
	};

	// Format SKU Percentage array before submitting
	const formattedSkuPercentages = (skuArr = []) => {
		const skuPercentageMapped = map(skuArr, (value, key) => ({
			day: key,
			skus: map(value, (skuValue, skuKey) => ({
				sku_slug: skuKey,
				percentage: skuValue && skuValue.percentage,
			})),
		}));
		return filter(skuPercentageMapped, (o) => o.day !== "All");
	};

	// Format Delivery Days value before submitting
	const formattedDeliveryDays = (daysArr = []) => {
		const selectedDays = uniq(map(daysArr, "value"));
		if (includes(selectedDays, "All")) {
			return map(daysArray, "value");
		} else {
			const arr = [];
			forEach(selectedDays, (o) => {
				const weekDayObj = find(daysArray, ["value", o]) || null;
				const weekDayIndex = weekDayObj && weekDayObj.index;
				arr[weekDayIndex] = o;
			});
			return arr && !isEmpty(arr) ? compact(arr) : !isEmpty(daysArr) ? map(daysArr, "value") : [];
		}
	};

	// Filter out percentage array based on days
	const filterOutDaysFromSKUPerc = (skuPer = null, days = null, sku = null) => {
		if (!isEmpty(skuPer) && !isEmpty(days) && !isEmpty(sku)) {
			return pick(skuPer, days);
		}
	};

	// Checking if sum exceeds 100 for Week Percentage
	const isNoPercentageError = () => {
		// Find all SKU Percentages and its sum (Join all DC SKU's to one)
		let allSKUPercentageJoinedArr = [];
		forEach(dcList, (value) => {
			const dcObj = formValues["dc_" + value.key];
			allSKUPercentageJoinedArr = [
				...allSKUPercentageJoinedArr,
				...checkingIfSumIs100(
					mapOptions(dcObj.sku, "label", "value"),
					filterOutDaysFromSKUPerc(
						dcObj && dcObj.sku_percentages,
						formattedDeliveryDays(dcObj && dcObj.delivery_days),
						map(dcObj.sku, "value").map((field) => ({
							slug: field,
							price: dcObj[field],
						}))
					),
					dcObj.name
				),
			];
		});

		// Filter out only those SKU who exceeded 100%
		const allSumExceededObjArr = map(allSKUPercentageJoinedArr, (o) => (o.sum !== 100 ? o : null));

		// Take only the labels of SKU / Remove empty values / Remove duplicate values
		const allSKULabelsOnlyArray = uniqBy(compact(map(allSumExceededObjArr, "label")));

		// Draft error message to show by joining labels
		const errMessage =
			allSKULabelsOnlyArray && allSKULabelsOnlyArray.length < 12
				? `Total SKU Percentage Allocation should be 100%. Please check the Percentage sum of ${join(
						allSKULabelsOnlyArray,
						", "
				  )}`
				: "Total SKU Percentage Allocation should be 100%. Please check the Percentage sum of the SKUs added";

		// If no SKU has exceeded 100, proceed to submit / Else show notification
		return isEmpty(allSKULabelsOnlyArray) ? true : NotificationManager.error(errMessage);
	};

	const submitCustomerDetails = (values) => {
		if (!isLoading) {
			if (isNoPercentageError()) {
				setisLoading(true);
				const dcCenters = dcList.map(function (value, index) {
					const dcObj = values["dc_" + value.key];

					const handleSKUPercentageValue = formattedSkuPercentages(
						filterOutDaysFromSKUPerc(
							dcObj && dcObj.sku_percentages,
							formattedDeliveryDays(dcObj && dcObj.delivery_days),
							map(dcObj.sku, "value").map((field) => ({
								slug: field,
								price: dcObj[field],
							}))
						)
					);
					return {
						slug: dcObj.slug,
						location: dcObj.location.value,
						city: dcObj.city,
						state: dcObj.state,
						name: dcObj.name,
						address: dcObj.address,
						zip_code: dcObj.zip_code,
						delivery_days: formattedDeliveryDays(dcObj && dcObj.delivery_days),
						sku: map(dcObj.sku, "value").map((field) => ({
							slug: field,
							price: dcObj[field],
						})),
						address_internal_id: map(dcObj.address_internal_id, "value").map((field) => parseFloat(field)) || [],
						sku_percentages: handleSKUPercentageValue,
						status: dcObj.status,
					};
				});

				const method = renderType === 1 ? null : initialValues.slug;
				const submissionData = {
					dc: dcCenters,
					customer_name: values.customer_name,
				};
				const successHandler = (event) => {
					setisLoading(false);
					successMessage(event);
					setCustomerViewRender(0);
				};
				const errorHandler = (event) => {
					setisLoading(false);
					errorMessage(event);
				};

				createCustomer(submissionData, method, successHandler, errorHandler);
			}
		}
	};

	// SKU Change
	const handleChange = (e, i) => {
		dcList[i].skufields = e ? e : [];
		if (!isEmpty(e)) {
			const currentPercArrObj = formValues[`dc_${i}`].sku_percentages || {};
			const selectedDaysArr = formValues[`dc_${i}`].delivery_days;

			let newPercArrObj = { ...currentPercArrObj };
			selectedDaysArr &&
				map(selectedDaysArr, (o) => {
					// For each day in delivery days
					if (currentPercArrObj && currentPercArrObj[o.value]) {
						let skuList = {};
						const skuObjListOfThisDay = currentPercArrObj[o.value];
						map(e, (s) => {
							if (skuObjListOfThisDay[s.value]) {
								skuList = {
									...skuList,
									[s.value]: skuObjListOfThisDay[s.value] || { percentage: "" },
								};
							} else {
								skuList = {
									...skuList,
									[s.value]: { percentage: "" },
								};
							}
						});
						newPercArrObj = {
							...newPercArrObj,
							[o.value]: skuList,
						};
					} else {
						// SKU objects list for each day
						let skuList = {};
						map(e, (s) => {
							skuList = {
								...skuList,
								[s.value]: { percentage: "" },
							};
						});
						newPercArrObj = {
							...newPercArrObj,
							[o.value]: skuList,
						};
					}
				});
			dispatch(change("customerManagementForm", "dc_" + i + ".sku_percentages", newPercArrObj));
		} else {
			dispatch(change("customerManagementForm", "dc_" + i + ".sku_percentages", {}));
		}
	};

	// Location Change
	const handleLocationChange = (e, i) => {
		const currentLocation = e ? e.value : "";
		dispatch(change("customerManagementForm", "dc_" + dcList[i].key + ".sku", []));
		dispatch(change("customerManagementForm", "dc_" + dcList[i].key + ".sku_percentages", {}));
		dcList[i].skuOptions = skuList[currentLocation];
		dcList[i].skufields = [];
	};

	// Delivery Days Change
	const onChangeDaysField = (values, count) => {
		if (!isEmpty(values)) {
			const currentPercArrObj = formValues[`dc_${count}`].sku_percentages || {};
			const selectedSKUs = formValues[`dc_${count}`].sku;
			let newPercArrObj = {};
			values &&
				map(values, (o) => {
					// For each day in delivery days
					if (currentPercArrObj && currentPercArrObj[o.value]) {
						let skuList = currentPercArrObj[o.value];
						map(selectedSKUs, (s) => {
							if (skuList[s.value]) {
								return;
							} else {
								skuList = {
									...skuList,
									[s.value]: { percentage: "" },
								};
							}
						});
						newPercArrObj = {
							...newPercArrObj,
							[o.value]: skuList,
						};
					} else {
						// SKU objects list for each day
						let skuList = {};
						map(selectedSKUs, (s) => {
							skuList = {
								...skuList,
								[s.value]: { percentage: "" },
							};
						});

						newPercArrObj = {
							...newPercArrObj,
							[o.value]: skuList,
						};
					}
				});
			dispatch(change("customerManagementForm", "dc_" + count + ".sku_percentages", newPercArrObj));
		} else {
			dispatch(change("customerManagementForm", "dc_" + count + ".sku_percentages", {}));
		}
	};

	const customerFormFields = (count, skuFields = [], skuOptions = [], flag = "add", i) => {
		let customerFormTopPortion = [];
		customerFormTopPortion = formCreationFields.map((field, idx) => (
			<Col className="col-lg-3 col-md-4 col-12" key={idx}>
				{field.name === "delivery_days" ? (
					<Field
						name={`dc_${count}.${field.name}`}
						type={field.type}
						label={field.label}
						placeholder={field.placeholder}
						component={FormGroups}
						validate={getValidations(field.name)}
						multiple={true}
						options={getOptions(field.name)}
						onChange={(e) => onChangeDaysField(e, i)}
						selectMenuHeight={150}
					/>
				) : field.name === "location" ? (
					<Field
						name={`dc_${count}.${field.name}`}
						type={field.type}
						label={field.label}
						placeholder={field.placeholder}
						component={FormGroups}
						validate={getValidations(field.name)}
						options={getOptions(field.name)}
						onChange={(e) => handleLocationChange(e, i)}
						selectMenuHeight={250}
					/>
				) : field.name === "sku" ? (
					<Field
						name={`dc_${count}.${field.name}`}
						type="select-react-customer-sku"
						label={field.label}
						placeholder={field.placeholder}
						component={FormGroups}
						validate={getValidations(field.name)}
						multiple={true}
						options={getOptions(field.name, skuOptions)}
						onChange={(e) => handleChange(e, i)}
						selectMenuHeight={150}
					/>
				) : (
					<Field
						name={`dc_${count}.${field.name}`}
						type={field.type}
						label={field.label}
						placeholder={field.placeholder}
						component={FormGroups}
						validate={getValidations(field.name)}
					/>
				)}
			</Col>
		));

		let customerFormPricingPortion = [];
		customerFormPricingPortion = skuFields && !isEmpty(skuFields) && (
			<CustomerSKUPricingTableManage formValues={formValues} skuFields={skuFields} count={count} />
		);

		return (
			<React.Fragment key={i}>
				<div className="outer-box">
					{/* DC Details */}
					<Row className="dc-edit-headers mr-2">
						<div className="form-headers">DC Details</div>
						{renderType !== 1 && <Field name={`dc_${count}.status`} type="boolean" component={FormGroups} />}
					</Row>
					<Row>{customerFormTopPortion}</Row>

					{/* Pricing */}
					{skuFields && !isEmpty(skuFields) && (
						<>
							<Row>
								<div className="form-headers mt-3">Pricing & SKU Percentage Allocation</div>
							</Row>
							<Row>
								<Col>{skuFields && customerFormPricingPortion}</Col>
								{flag && flag === "edit" ? <input type="hidden" name="slug" value=""></input> : ""}
							</Row>
						</>
					)}
				</div>

				{/* Add/Remove Button */}
				<Row>
					{dcList.length - 1 === i ? (
						<Col
							className={`d-flex justify-content-center text-center ${
								dcList.length === 1 ? "col-xl-12 col-lg-12  " : "col-xl-6 col-lg-6"
							} col-md-12 col-sm-12 col-12`}>
							<ButtonLink className="view-table-btn" onClick={() => setDcArray(count + 1)}>
								<i className="icon icon-plus mr-2" />
								Add New DC
							</ButtonLink>
						</Col>
					) : null}
					{dcList.length !== 1 ? (
						<Col
							className={`d-flex justify-content-center text-center ${
								dcList.length - 1 !== i ? "col-xl-12 col-lg-12  " : "col-xl-6 col-lg-6"
							} col-md-12 col-sm-12 col-12`}>
							<ButtonLink className="remove-link-btn view-table-btn" onClick={() => removeDcArray(count)}>
								<i className="icon icon-minus mr-2" />
								Remove DC
							</ButtonLink>
						</Col>
					) : null}
				</Row>
			</React.Fragment>
		);
	};

	// Add New DC to List
	const setDcArray = (count) => {
		dispatch(change("customerManagementForm", "dc_" + count + ".name", []));
		dispatch(change("customerManagementForm", "dc_" + count + ".location", []));
		dispatch(change("customerManagementForm", "dc_" + count + ".city", []));
		dispatch(change("customerManagementForm", "dc_" + count + ".state", []));
		dispatch(change("customerManagementForm", "dc_" + count + ".address", []));
		dispatch(change("customerManagementForm", "dc_" + count + ".zip_code", []));
		dispatch(change("customerManagementForm", "dc_" + count + ".sku", []));
		dispatch(change("customerManagementForm", "dc_" + count + ".sku_percentages", {}));
		dispatch(change("customerManagementForm", "dc_" + count + ".delivery_days", []));

		dispatch(untouch("customerManagementForm", "dc_" + count + ".name", []));
		dispatch(untouch("customerManagementForm", "dc_" + count + ".location", []));
		dispatch(untouch("customerManagementForm", "dc_" + count + ".city", []));
		dispatch(untouch("customerManagementForm", "dc_" + count + ".state", []));
		dispatch(untouch("customerManagementForm", "dc_" + count + ".address", []));
		dispatch(untouch("customerManagementForm", "dc_" + count + ".zip_code", []));
		dispatch(untouch("customerManagementForm", "dc_" + count + ".sku", []));
		dispatch(untouch("customerManagementForm", "dc_" + count + ".sku_percentages", {}));
		dispatch(untouch("customerManagementForm", "dc_" + count + ".delivery_days", []));

		setDcList([
			...dcList,
			{
				key: count,
				skuOptions: [],
				skufields: [],
				flag: "add",
			},
		]);
	};

	// Remove DC from List
	const removeDcArray = (index) => {
		const dcListKeyToRemove = findKey(dcList, function (o) {
			return o.key === index;
		});

		const list = [...dcList];
		list.splice(dcListKeyToRemove, 1);
		setDcList(list);
	};

	return (
		<Form autoComplete="off" onSubmit={handleSubmit(submitCustomerDetails)} className="customer-management-form">
			<div className="position-relative">
				<Row>
					<Col className="col-lg-3 col-md-4 col-12">
						<Field
							name="customer_name"
							type="text"
							label="Customer Name *"
							autoComplete="####@"
							validate={[required, names]}
							placeholder="Enter Customer Name"
							component={FormGroups}
						/>
					</Col>
				</Row>

				{/* DC List Mapping */}
				{dcList && dcList.map((x, i) => customerFormFields(x.key, x.skufields, x.skuOptions, x.flag, i))}

				{isLoading && (
					<div className="table-loader-wrapper">
						<TableLoader />
					</div>
				)}
			</div>

			<div className="modal-footer d-flex justify-content-end save-footer">
				<ButtonSecondary onClick={() => setCustomerViewRender(0)}>Cancel</ButtonSecondary>
				<ButtonPrimary type="submit" disabled={isLoading}>
					{isLoading ? "Saving..." : "Save"}
				</ButtonPrimary>
			</div>
		</Form>
	);
};
const mapStateToProps = (state) => {
	const { UI, Auth, Customers } = state;

	const initialArray = () => {
		const customerDataArray =
			state.Customers && state.Customers.customerDetails ? state.Customers && state.Customers.customerDetails : [];
		const customerName = customerDataArray && customerDataArray.customer_name ? customerDataArray.customer_name : "";
		const slug = customerDataArray && customerDataArray.slug ? customerDataArray.slug : "";
		const deliveryCenters =
			customerDataArray && customerDataArray.delivery_centers ? customerDataArray.delivery_centers : [];
		const deliveryCentersArray = [];
		deliveryCenters.forEach(function (data, key) {
			deliveryCentersArray["dc_" + key] = {
				...data,
				location: {
					label: data.location.location_name ? data.location.location_name : "",
					value: data.location.slug ? data.location.slug : "",
				},
				address_internal_id:
					map(data.address_internal_id, (o) => ({
						label: o,
						value: parseFloat(o),
						can_delete: true,
					})) || [],
				delivery_days: daysMap(data.delivery_days),
				sku: mapOptions(data.skus, "sku", "slug"),
				sku_percentages: createSKUPercentageObj(data.sku_percentages, data.delivery_days, data.skus) || {},
				skuOptionsDetailsArray: Customers.allSkuList[data.location.slug],
				skuFieldsDetailArray: mapOptions(data.skus, "sku", "slug"),
				...skuMap(data.skus),
			};
		});

		const deliveryCentersMapArray = [];
		deliveryCenters.forEach(function (data, key) {
			deliveryCentersMapArray[key] = [];
			deliveryCentersMapArray[key]["key"] = key;
			deliveryCentersMapArray[key]["skuOptions"] = deliveryCentersArray["dc_" + key]["skuOptionsDetailsArray"];
			deliveryCentersMapArray[key]["skufields"] = deliveryCentersArray["dc_" + key]["skuFieldsDetailArray"];
			deliveryCentersMapArray[key]["flag"] = "edit";
		});
		const formatedInitialValueArray = {
			slug,
			customer_name: customerName,
			...deliveryCentersArray,
			deliveryCentersMapArray,
		};

		return formatedInitialValueArray;
	};

	return {
		accessToken: get(Auth, "accessToken", null),
		mainPreloader: get(UI, "mainPreloader", null),
		renderType: get(Customers, "renderDisplay", null),
		initialValues: state.Customers.renderDisplay === 1 ? {} : initialArray(),
		locationList: get(Customers, "allLocationsList", []),
		skuList: get(Customers, "allSkuList", []),
		formValues: getFormValues("customerManagementForm")(state),
	};
};

const mapDispatchToProps = { createCustomer, setCustomerViewRender };

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(
	reduxForm({
		form: "customerManagementForm",
		enableReinitialize: true,
	})(CustomerForm)
);
