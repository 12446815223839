import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Container, Button, Row, Col, Form } from "react-bootstrap";
import { reduxForm, Field, reset, change, getFormValues, FormSection } from "redux-form";
import { useDispatch } from "react-redux";
import FormGroups from "../../../utils/formGroup";
import Util from "../../../utils/Util";
import { formCreationFieldsTop, formCreationFieldsMiddle, getValidations } from "./formHelperPond";
import useModal from "../../../utils/useModal";
import { toggleMainLoader, createTransplantPond, getPondParamentersToday, updatePondParameters } from "../../../store/actions";
import { errorMessage } from "../../../utils/errorMessage";
import { successMessage } from "../../../utils/successMessage";
import TableLoader from "../../../utils/table/tableLoader";
import { find, map } from "lodash";
import moment from "moment";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
const componentGroup = ({ defaultValue, id, input, label, type, options = [], meta: { touched, error, warning } }) => {
	switch (type) {
		case "hidden":
			// return <input type="hidden" name="sample" value="1" onChange={()=>{input.onChange("12")}}></input>;
			defaultValue && input.onChange(defaultValue);
			return <input value={input.value} type="hidden" {...input} />;
		default:
			return;
	}
};
const getPondForm = (pondSlug) => {
	const formFieldHtml = formCreationFieldsMiddle.map((pondField, idx) => {
		return (
			<React.Fragment key={idx}>
				<FormSection name={String(pondSlug)}>
					<Col className={pondField.name === "orp" ? "col-12" : "col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12"}>
						<Form.Group controlId={`formControl ${pondField.name}`}>
							{pondField.type === "hidden" ? (
								<Field
									name={`${pondField.name}`}
									type={pondField.type}
									defaultValue={10}
									label={i18next.t(pondField.label)}
									placeholder={i18next.t(pondField.placeholder)}
									component={componentGroup}
								/>
							) : (
								<Field
									name={`${pondField.name}`}
									type={pondField.type}
									label={i18next.t(pondField.label)}
									placeholder={i18next.t(pondField.placeholder)}
									component={FormGroups}
									validate={getValidations(pondField.name === "orp" ? "number" : "ponds")}
								/>
							)}
						</Form.Group>
					</Col>
				</FormSection>
			</React.Fragment>
		);
	});
	return formFieldHtml;
};

const getPondLayout = (pondArray) => {
	const result = pondArray.map((item, i) => {
		return (
			<div key={i} className="outer-box">
				<>
					<Row>
						<div className="form-headers">{i18next.t(item.label)}</div>
					</Row>
					<Row>{getPondForm(item.value)}</Row>
				</>
			</div>
		);
	});

	return result;
};

const TransplantPondAddEdit = ({
	handleSubmit,
	toggleMainLoader,
	reset,
	form,
	formValues,
	doFetchTaskDetails,
	initialValues,
	locationList,
	metricsList,
	createTransplantPond,
	getPondParamentersToday,
	onAddOrEdit,
	selectedLocation,
	initialize,
	mode,
	updatePondParameters,
}) => {
	const { t } = useTranslation();
	const [, , , closeModal] = useModal("transplant");
	const dispatch = useDispatch();
	const [isLoading, setIsLoading] = useState(false);
	const [btnTitle, setbtnTitle] = useState(t("label.save"));
	const [isFetchingLog, setIsFetchingLog] = useState(false);
	const [shouldFetchLog, setShouldFetchLog] = useState(false);
	const [locationPondLaneMappedArray, setLocationPondLaneMappedArray] = useState(
		// initialValues && initialValues.location ? metricsList[initialValues.location.value] : []
		selectedLocation && selectedLocation.value ? metricsList[selectedLocation.value] : []
	);
	const [pondArray, setPondArray] = useState(initialValues ? initialValues.product_varieties : []);
	const errorHandler = (err) => {
		errorMessage(err);
		setIsLoading(false);
		setIsFetchingLog(false);
		setShouldFetchLog(false);
	};
	// Fetch details regarding previous entries
	useEffect(() => {
		if (shouldFetchLog) {
			if (formValues && formValues.date && formValues.location) {
				setIsFetchingLog(true);
				const onSuccess = (resp) => {
					setIsFetchingLog(false);
					setShouldFetchLog(false);
					if (resp.data && resp.data.parameter_values) {
						const pondLaneCount = map(resp.data.parameter_values, "pond_value");
						const pondParamsArray = resp.data.parameter_values;

						const pondInitials = {};
						pondParamsArray.forEach((item) => {
							pondInitials[item.pond_value.id] = {
								do: item.do,
								ec: item.ec,
								orp: item.orp ? item.orp : "",
								ph: item.ph,
								pond_temperature: item.pond_temperature,
								edit: 1,
							};
						});

						const existingPondValues = pondLaneCount.map((number, i) => ({
							label: number.value,
							value: number.id,
						}));
						dispatch(change("transplantPondForm", "pond_number", existingPondValues));
						dispatch(change("transplantPondForm", "pond_details", pondInitials));
						setPondArray(existingPondValues);

						// pondParamsArray.forEach(function (data, key) {
						// 	formCreationFieldsMiddle.forEach(function (prondField, pondkey) {
						// 		dispatch(
						// 			change(
						// 				"transplantPondForm",
						// 				`pond_details.${data.pond_value.id}.${prondField.name}`,
						// 				data[prondField.name]
						// 			)
						// 		);
						// 	});
						// });
					} else {
						dispatch(change("transplantPondForm", "pond_number", []));
						dispatch(change("transplantPondForm", "pond_details", []));
						setPondArray([]);
					}
				};
				const body = {
					date: Util.DateFormat(formValues.date),
					location_slug: formValues.location && formValues.location.value,
				};
				getPondParamentersToday(body, onSuccess, errorHandler);
			}
		}
	}, [formValues, getPondParamentersToday, shouldFetchLog, dispatch, initialize]);

	const submitTransplantDetails = (values) => {
		if (!isLoading) {
			setIsLoading(true);
			setbtnTitle(t("label.saving"));
			const details =
				values.pond_number && values.pond_number
					? values.pond_number.map(function (value, index) {
							if (values && values.pond_details[value["value"]] && values.pond_details[value["value"]]["edit"]) {
								return {
									pond_number: value["value"],
									ph: values.pond_details[value["value"]]["ph"],
									ec: values.pond_details[value["value"]]["ec"],
									do: values.pond_details[value["value"]]["do"],
									pond_temperature: values.pond_details[value["value"]]["pond_temperature"],
									orp: values.pond_details[value["value"]]["orp"]
										? values.pond_details[value["value"]]["orp"]
										: "",
									edit :values.pond_details[value["value"]]["edit"]
								};
							} else {
								return {
									pond_number: value["value"],
									ph: values.pond_details[value["value"]]["ph"],
									ec: values.pond_details[value["value"]]["ec"],
									do: values.pond_details[value["value"]]["do"],
									pond_temperature: values.pond_details[value["value"]]["pond_temperature"],
									orp: values.pond_details[value["value"]]["orp"]
										? values.pond_details[value["value"]]["orp"]
										: "",
								};
							}
					  })
					: [];

			const submissionData =
				mode === 5
					? {
							ph: details && details[0].ph,
							ec: details && details[0].ec,
							do: details && details[0].do,
							pond_temperature: details && details[0].pond_temperature,
							orp: details && details[0].orp ? details[0].orp : "",
							transplant_pond_parameter_values_id: initialValues.transplant_pond_parameter_values_id,
					  }
					: {
							location_slug: values && values.location ? values.location.value : "",
							date: values && values.date ? Util.DateFormat(values.date) : "",
							pond_details: { ...details },
					  };
			const successHandler = (event) => {
				onAddOrEdit && onAddOrEdit(event);
				reset(form);
				successMessage(event);
				closeModal();
				setIsLoading(false);
				setbtnTitle(t("label.save"));
			};
			const errorHandler = (event) => {
				setIsLoading(false);
				setbtnTitle(t("label.save"));
				errorMessage(event);
			};
			if (mode === 5) {
				updatePondParameters(submissionData, "POST", successHandler, errorHandler);
			} else {
				createTransplantPond(submissionData, "POST", successHandler, errorHandler);
			}
		}
	};

	const getOptions = (field) => {
		switch (field) {
			case "pond_number":
				return Util.mapOptions(locationPondLaneMappedArray["pond"], "value", "id") || [];

			case "location":
				return Util.mapOptions(locationList, "name", "slug");

			default:
				return [];
		}
	};

	const remapLocations = (value) => {
		value && setLocationPondLaneMappedArray(metricsList[value]);
		setPondArray([]);
		dispatch(change("transplantPondForm", "pond_number", []));
	};

	// Fetch if entries already added in a particular date
	const onChangeFormFields = (e, doGetCall = false) => {
		if (doGetCall) {
			setShouldFetchLog(true);
		} else {
			setShouldFetchLog(false);
		}
	};

	const getFormFieldHtml = (fields) => {
		let formFieldHtml = [];
		formFieldHtml = fields.map((field, idx) => {
			return (
				<React.Fragment key={idx}>
					<Col
						className={
							field.name === "pond_number"
								? "col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
								: "col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12"
						}>
						<Form.Group controlId={`formControl ${field.name}`}>
							{field.type === "select-react" || field.type === "select-react-expand-all" ? (
								<Field
									name={field.name}
									type={field.type}
									label={i18next.t(field.label)}
									placeholder={i18next.t(field.placeholder)}
									component={FormGroups}
									multiple={field.name === "location" ? false : true}
									validate={getValidations(field.name)}
									options={getOptions(field.name)}
									onChange={(event) => {
										field.name === "location" ? remapLocations(event.value) : setPondArray(event);
										field.name === "location" && onChangeFormFields(event, field.doGetCall);
									}}
									checked={true}
								/>
							) : (
								<Field
									name={field.name}
									type={field.type}
									label={i18next.t(field.label)}
									placeholder={i18next.t(field.placeholder)}
									component={FormGroups}
									validate={getValidations(field.name)}
									selected={new Date()}
									onChange={(e) => onChangeFormFields(e, field.doGetCall)}
								/>
							)}
						</Form.Group>
					</Col>
				</React.Fragment>
			);
		});
		return <>{formFieldHtml}</>;
	};

	// const getPondHtml = (x, label) => {
	// 	let formFieldHtml = [];
	// 	formFieldHtml = formCreationFieldsMiddle.map((prondField, idx) => {
	// 		return (
	// 			<React.Fragment key={idx}>
	// 				<Col className={prondField.name === "orp" ? "col-12" : "col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12"}>
	// 					<Form.Group controlId={`formControl ${prondField.name}`}>
	// 						<Field
	// 							name={`pond_details.${x}.${prondField.name}`}
	// 							type="text"
	// 							label={prondField.label}
	// 							placeholder={prondField.placeholder}
	// 							component={FormGroups}
	// 							validate={getValidations(prondField.name === "orp" ? "number" : "ponds")}
	// 						/>
	// 					</Form.Group>
	// 				</Col>
	// 			</React.Fragment>
	// 		);
	// 	});

	// 	return (
	// 		<React.Fragment key={x}>
	// 			<div className="outer-box">
	// 				<Row>
	// 					<div className="form-headers">{label}</div>
	// 				</Row>
	// 				<Row>{formFieldHtml}</Row>
	// 			</div>
	// 		</React.Fragment>
	// 	);
	// };
	const labelledValue = (label, value, unit = null, colSize = 6, colLGSize = 6) => (
		<Col className={`col-lg-${colLGSize} col-md-${colSize} col-12 mb-3`} key={label}>
			{(label || value) && (
				<div key={label}>
					<p className="d-flex align-items-center text-muted mb-2">
						{/* <span className="light-badge-pill"></span> */}
						<span className="view-labels ">{t(label)}</span>
					</p>
					<h6 className="ml-4 view-values pond-edit-values">{value ? `${value} ${unit ? unit : ""}` : "-"}</h6>
				</div>
			)}
		</Col>
	);
	return (
		<>
			<Form onSubmit={handleSubmit(submitTransplantDetails)} className="transplantPondForm">
				<Container className="position-relative">
					<Row>
						{mode !== 5 ? (
							getFormFieldHtml(formCreationFieldsTop)
						) : (
							<>
								{labelledValue(t("label.location"), initialValues.location.label)}
								{labelledValue(t("label.date"), moment(initialValues.date).format("MM/DD/YYYY LT"))}
							</>
						)}
					</Row>

					{/* {pondArray &&
						pondArray.map((x, i) => {
							return getPondHtml(x.value, x.label);
						})} */}
					{mode === 3 && pondArray && <FormSection name="pond_details">{getPondLayout(pondArray)}</FormSection>}
					{mode === 5 && <FormSection name="pond_details">{getPondLayout(initialValues.pond_number)}</FormSection>}
					{/* Loader */}
					{(isLoading || isFetchingLog) && (
						<div className="table-loader-wrapper">
							<TableLoader />
						</div>
					)}
				</Container>
				<div className="modal-footer save-footer">
					<Button variant="secondary" onClick={closeModal}>
						{t("label.cancel")}
					</Button>
					<Button variant="primary" type="submit" disabled={isLoading}>
						{btnTitle}
					</Button>
				</div>
			</Form>
		</>
	);
};

const mapStateToProps = (state) => {
	const { Transplant, Auth, Modals } = state;
	const initialArray = () => {
		const initData = Modals.modalProps;
		const location = find(Transplant.locationMapping, { location_name: initData.location });
		return {
			date: new Date(initData.date),
			transplant_pond_parameter_values_id: initData.transplant_pond_parameter_values_id,
			location: { label: location && location.location_name, value: location && location.slug },
			pond_number: [{ label: initData.data[0].pond_name, value: initData.data[0].pond_number }],
			pond_details: {
				[initData.data[0].pond_number]: {
					ph: initData.data[0].ph,
					ec: initData.data[0].ec,
					do: initData.data[0].do,
					pond_temperature: initData.data[0].pond_temperature,
					orp: initData.data[0].orp,
				},
			},
		};
	};
	return {
		locationList: Auth.userLocations,
		metricsList: Transplant.locationMapping,
		selectedLocation: Transplant.selectedLocation,
		formValues: getFormValues("transplantPondForm")(state),
		initialValues:
			Modals.modalType === 5
				? initialArray()
				: { location: Transplant.selectedLocation ? Transplant.selectedLocation : "" },
	};
};

const mapDispatchToProps = {
	toggleMainLoader,
	createTransplantPond,
	updatePondParameters,
	getPondParamentersToday,
	reset,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(
	reduxForm({
		form: "transplantPondForm",
		enableReinitialize: true,
	})(TransplantPondAddEdit)
);
