import { ListingBtn } from "elements";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { Field, FormSection } from "redux-form";

export const fixedColumnTable = (headings, fixedColumnWidth, fixedColumnHeading, renderKeyItem, tableId) => {
	const headingsHead = headings[0];
	const headingsBody = headings.filter((item, ind) => ind !== 0);
	// headingsBody.shift();

	return (
		<div className="dosing-fixed-column" style={{ width: fixedColumnWidth, position: "relative" }}>
			<div className="sticky-head">
				<Row className={"dosing-table-head "} style={{ width: `${fixedColumnWidth}px` }}>
					<div className={`show-shadow-row shadow-table-${tableId} shadow-head`} />

					<Col className="dosing-table-col">
						<span>{headingsHead.name}</span>
					</Col>
				</Row>
			</div>
			<div className="dosing-table-body">
				{headingsBody.map((dataItem, d_ind) => (
					<Row key={d_ind} className={"dosing-table-body-row position-relative"}>
						<div className={`show-shadow-row shadow-table-${tableId}`} />

						<Col className="dosing-table-col">{dataItem.name}</Col>
					</Row>
				))}
			</div>
		</div>
	);
};

export const getData = (data, headings, slugKey, renderKey, render, editableRows, onSave, cancelEdit) => {
	// const arr = keys(data).sort();
	const headingsHead = headings[0];
	const headingsBody = headings.filter((item, ind) => ind !== 0);

	// headingsBody.shift();

	return (
		<>
			{data.map((item, ind) => {
				return (
					<div key={ind.toString()} className="dosing-column-wrapper">
						<FormSection
							key={ind}
							name={item.slug}
							component={dataRender}
							item={item}
							headingsBody={headingsBody}
							headingsHead={headingsHead}
						/>
					</div>
				);
			})}
		</>
	);
};

const dataRender = ({ item, headingsBody, headingsHead }) => {
	return (
		<>
			<div className="sticky-head">
				<Row className="dosing-table-head position-relative">
					<Col className="dosing-table-col">
						{/* <span>{item[headingsHead.slug]}</span> */}
						<Field
							id={`${item.slug}_${headingsHead.slug}`}
							name={headingsHead.slug}
							type={"div"}
							component={componentGroup}
							// validate={[number]}
						/>
					</Col>
				</Row>
			</div>
			<div className="dosing-table-body">
				{headingsBody.map((dataItem, a_ind) => (
					<Row key={a_ind} className="dosing-table-body-row">
						<Col className="dosing-table-col">
							{/* <span>{item[headingsHead.slug]}</span> */}
							<Field
								id={`${item.slug}_${dataItem.slug}`}
								name={dataItem.slug}
								type={dataItem.editable ? "input" : "div"}
								component={componentGroup}
								{...(dataItem.validate && { validate: dataItem.validate })}
							/>
						</Col>
						{/* <FormSection
                            name={`${a_item}`}
                            component={renderRow}
                            nameKey={`${a_item}`}
                            columns={columns}
                            a_item={data[a_item]}
                            a_ind={a_ind}
                            editable={editable}
                            renderKey={renderKey}
                            render={render}
                            slugKey={slugKey}
                        /> */}
					</Row>
				))}
			</div>
		</>
	);
};

const componentGroup = ({ id, input, label, type, meta: { touched, error, warning } }) => {
	switch (type) {
		case "div":
			return <div>{input.value || input.value === 0 ? input.value : "-"}</div>;
		case "input":
			return (
				<div className="position-relative">
					<input
						{...input}
						id={id}
						className={`form-control action-table-input ${
							touched ? (error ? "input-error" : warning ? "warning" : "") : ""
						}`}
						placeholder={label}
						type={type}
					/>
					{touched ? (
						error ? (
							<div className="error-table-sec">
								<ListingBtn
									tooltipText={error}
									btnClass="btn btn-cell btn-light error-table-btn"
									iconClass="icon icon-info table-error-icon"
									errorBtn={true}
								/>
							</div>
						) : null
					) : null}
				</div>
			);
		default:
			return;
	}
};
