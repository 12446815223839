import React, { useState } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { Link } from "react-router-dom";
import "./session.scss";

import {
	sendLoginCredentials,
	toggleMainLoader,
	resetField,
	getProfileDetails,
	getPermissionDetails,
} from "../../store/actions";

import FormGroup from "../../utils/formGroup";
import { errorMessage } from "../../utils/errorMessage";
import { successMessage } from "../../utils/successMessage";
import { required, email } from "../../constant/formValidators";
import Util from "../../utils/Util";

const LSVariable = process.env.REACT_APP_LS_VAR;

export const SignIn = ({
	handleSubmit,
	sendLoginCredentials,
	getProfileDetails,
	toggleMainLoader,
	resetField,
	history,
	getPermissionDetails,
}) => {
	const [error, setError] = useState(null);

	const submitLogin = (values) => {
		toggleMainLoader(true);
		const successHandler = (event) => {
			event && resetField("Auth.accessToken", event);
			localStorage.setItem(LSVariable, JSON.stringify(event));
			successMessage(event);
			toggleMainLoader(false);
			getProfileDetails((e) => {
				resetField("Auth.userDetails", e.data);
				const successHandler = (event) => {
					resetField("Auth.permissionDetails", Util.formatPermissionDataArray(event.data));
				};
				getPermissionDetails(e.data.role_id, successHandler);
				history.push("/");
			});
		};
		const logoutApp = () => {
			localStorage.removeItem(LSVariable);
			history.push("/signin");
		};
		const errorHandler = (event) => {
			errorMessage(event);
			logoutApp();
			toggleMainLoader(false);
		};
		sendLoginCredentials({ ...values, remember_me: true }, successHandler, errorHandler);
	};

	return (
		<div className="sign-in-wrap">
			<div className="sign-in-container">
				<form className="sign-in-form" onSubmit={handleSubmit(submitLogin)}>
					<h1 className="mb-1">Sign In</h1>
					<div className="mb-3">Please sign in to your account.</div>
					<Field
						name="email"
						label="Email"
						type="text"
						onChange={() => setError(null)}
						component={FormGroup}
						validate={[required, email]}
					/>
					<Field
						name="password"
						label="Password"
						type="password"
						onChange={() => setError(null)}
						component={FormGroup}
						validate={[required]}
					/>
					<div className="d-block mt-4 text-right forgot-password">
						<Link to="/forgot-password">Forgot password?</Link>
					</div>
					<div className="d-block mt-4 text-right">
						<button className="btn btn-primary" type="submit">
							Submit
						</button>
					</div>
					{error && <div className="error small text-danger text-center mt-3">{error}</div>}
				</form>
			</div>
		</div>
	);
};

const mapDispatchToProps = {
	sendLoginCredentials,
	toggleMainLoader,
	resetField,
	getProfileDetails,
	getPermissionDetails,
};

export default connect(
	null,
	mapDispatchToProps
)(
	reduxForm({
		form: "SignInForm",
	})(SignIn)
);
