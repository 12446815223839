import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Row, Col } from "react-bootstrap";
import { createStructuredSelector } from "reselect";
import { isEmpty } from "lodash";
import moment from "moment";
import { ContentWrap } from "elements";
import { OrdersPackagingBreadcrumb, CaseSummary, PackagingSummary } from "../ordersPackagingComponents";
import { SKULocationDropdown, SKUListDropdown, SKUDateDropdown } from "./skuCompositionComponents";
import {
	changeOrdersRenderType,
	selectLocation,
	loadLocationSkus,
	getSKURecipeDetails,
	changeSKUComposition,
	resetListing,
} from "store/actions";
import { getLocationList, getLocationSelected, getLocationSkus } from "store/selectors/ordersPackagingSelectors";
import Util from "utils/Util";
import TableLoader from "utils/table/tableLoader";
import SKURecipeForm from "./skuRecipeForm";
import { useTranslation } from 'react-i18next';

const SKUComposition = ({
	loadLocationSkus,
	getSKURecipeDetails,
	changeOrdersRenderType,
	selectLocation,
	changeSKUComposition,

	locationList,
	skuList,
	resetListing,
	globalLocation,
}) => {
	const { t } = useTranslation();
	const [loading, setLoading] = useState(false);
	const [addLoader, setAddLoader] = useState(false);
	const [isRecipeEditing, setIsRecipeEditing] = useState(false);
	const [selectedDate, setSelectedDate] = useState(new Date());
	const [selectedLocation, setSelectedLocation] = useState(null);
	const [selectedSKU, setSelectedSKU] = useState(null);
	const [selectedRecipeDetails, setSelectedRecipeDetails] = useState({});

	// On change date/location/sku
	const onHandleSelect = (type, e) => {
		if (type === "DATE") {
			setSelectedDate(e);
			fetchRecipeDetails(e, selectedLocation, selectedSKU);
		} else if (type === "LOCATION") {
			selectLocation(e);
			setSelectedSKU(null);
			setSelectedRecipeDetails(null);
			setSelectedLocation(e);
		} else if (type === "SKU") {
			setSelectedSKU(e);
			fetchRecipeDetails(selectedDate, selectedLocation, e);
		}
	};

	// Add SKU - After call -
	const handleAddResponse = (type) => {
		setAddLoader(false);
		if (type === "SUCCESS") {
			fetchRecipeDetails(selectedDate, selectedLocation, selectedSKU);
		}
	};

	// Fetch Recipe Details
	const fetchRecipeDetails = (date, location, sku) => {
		if (date && !isEmpty(location) && !isEmpty(sku)) {
			setLoading(true);
			const successHandler = (e) => {
				setLoading(false);
				setIsRecipeEditing(false);
				setSelectedRecipeDetails((e && e.data) || {});
			};
			const errorHandler = () => {
				setLoading(false);
				setIsRecipeEditing(false);
				setSelectedRecipeDetails({});
			};
			const body = {
				date: Util.getYMDFormat(date),
				location_slug: location && location.value,
				sku_slug: sku && sku.value,
			};
			getSKURecipeDetails(body, successHandler, errorHandler);
		}
	};

	// Action : Edit Recipe Click
	const onClickEdit = (type) => {
		if (type === "SKU_RECIPE") {
			setIsRecipeEditing(true);
		}
	};

	// Close recipe
	const onCloseEditRecipe = () => setIsRecipeEditing(false);

	// Page Load : Load all locations and its skus
	useEffect(() => {
		loadLocationSkus();
		return () => {
			resetListing();
		};
	}, [loadLocationSkus, resetListing]);

	useEffect(() => {
		if (globalLocation) {
			selectLocation(globalLocation);
			setSelectedLocation(globalLocation);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [globalLocation]);

	const { required_cases = 0, possible_cases = 0, actual_harvest_lbs = {}, available_harvest_lbs = {}, actual_recipe = [] } =
		selectedRecipeDetails || {};

	const recipeInitialValue = {
		date: Util.getYMDFormat(selectedDate),
		location_slug: (selectedLocation && selectedLocation.value) || "",
		sku_slug: (selectedSKU && selectedSKU.value) || "selectedSKU",
		product_variety: actual_recipe,
	};

	const isShowEdit = moment(selectedDate).isSameOrAfter(moment(), "day");

	return (
		<ContentWrap>
			<div className="d-flex align-items-center mb-3">
				<OrdersPackagingBreadcrumb view={2} goBackToListing={() => changeOrdersRenderType(0)} />
			</div>
			<div className="position-relative sku-composition-wrapper">
				{/* Dropdowns */}
				<Row className="mt-3">
					<Col className="op-mb" sm="12" md="4">
						<SKULocationDropdown value={selectedLocation} options={locationList} onSelect={onHandleSelect} />
					</Col>
					<Col className="op-mb" sm="12" md="4">
						<SKUDateDropdown value={selectedDate} onSelect={onHandleSelect} />
					</Col>
					<Col className="op-mb" sm="12" md="4">
						<SKUListDropdown value={selectedSKU} options={skuList} onSelect={onHandleSelect} />
					</Col>
				</Row>

				{/* Summary Boxes */}
				{!isEmpty(selectedRecipeDetails) && <CaseSummary required={required_cases} possible={possible_cases} />}

				{/* Harvest LBS */}
				{(!isEmpty(actual_harvest_lbs) || !isEmpty(available_harvest_lbs)) && (
					<div className="op-view-content">
						<PackagingSummary
							title={t("label.actual_harvest_lbs")}
							data={Util.mapOptions(actual_harvest_lbs, "product_variety_name", "pounds") || []}
							unit=" Lbs"
						/>
						<PackagingSummary
							title={t("label.available_harvest_lbs")}
							data={Util.mapOptions(available_harvest_lbs, "product_variety_name", "pounds") || []}
							unit=" Lbs"
						/>
					</div>
				)}

				{!isEmpty(actual_recipe) && (
					<div className="op-view-content">
						{isRecipeEditing ? (
							<SKURecipeForm
								initialValues={recipeInitialValue || []}
								changeSKUComposition={changeSKUComposition}
								onCloseEditRecipe={onCloseEditRecipe}
								isLoading={addLoader}
								setPageLoading={() => setAddLoader(true)}
								handleAddResponse={handleAddResponse}
							/>
						) : (
							<PackagingSummary
								title={t("label.recipe")}
								data={Util.mapOptions(actual_recipe, "product_variety_name", "percentage") || []}
								unit=" %"
								showEdit={isShowEdit}
								editIdentifier="SKU_RECIPE"
								onClickEdit={onClickEdit}
							/>
						)}
					</div>
				)}
				{/* Loading */}
				{(loading || addLoader) && (
					<div className="table-loader-wrapper">
						<TableLoader />
					</div>
				)}
			</div>
		</ContentWrap>
	);
};

const mapStateToProps = createStructuredSelector({
	locationList: getLocationList,
	skuList: getLocationSkus,
	globalLocation: getLocationSelected,
});

export default connect(mapStateToProps, {
	loadLocationSkus,
	changeOrdersRenderType,
	selectLocation,
	getSKURecipeDetails,
	changeSKUComposition,
	resetListing,
})(SKUComposition);
