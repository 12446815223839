import React from "react";

import "./index.scss";

import RenderColors from "./colors";
import RenderHeadings from "./headings";
import RenderParagraph from "./paragraph";
import RenderButtons from "./buttons";
import RenderInputs from "./inputs";
import RenderCards from "./cards";
import RenderPrimayTable from "./primaryTable";
import RenderIcons from "./icons";

const UIDocumentation = () => {
	return (
		<div className="ui-doc-wrap">
			<div className="ui-doc-container">
				<h1 className="mt-4">UI Guidelines - Bright Farms</h1>
				<RenderColors />
				<hr />
				<RenderHeadings />
				<RenderParagraph />
				<hr />
				<RenderButtons />
				<hr />
				<RenderInputs />
				<hr />
				<RenderCards />
				<hr />
				<RenderPrimayTable />
				<hr />
				<RenderIcons />
				<div className="pt-5" />
			</div>
		</div>
	);
};

export default UIDocumentation;
