import React, { useState, useEffect } from "react";
import { Container, Button, Row, Col, Form } from "react-bootstrap";
import FormGroups from "utils/formGroup";
import { connect, useDispatch } from "react-redux";
import { reduxForm, Field, reset, change } from "redux-form";
import { cleaningTimeForm, setUpTimeForm } from "./formHelper";
import useModal from "utils/useModal";
import {
	insertCleaningTime,
	getCleaningTime,
	getSetUpTime,
	insertSetUpTime,
	resetAddEdit,
	fetchTaskUsers,
	resetHarvestLogTime,
} from "store/actions";
import { errorMessage } from "utils/errorMessage";
import { successMessage } from "utils/successMessage";
import moment from "moment";
import { get, isEmpty } from "lodash";
import TableLoader from "utils/table/tableLoader";
import { useTranslation } from "react-i18next";

const LogTime = ({
	handleSubmit,
	form,
	insertCleaningTime,
	initialValues,
	cleaningTime,
	cleaningTimeUser,
	getCleaningTime,
	getSetUpTime,
	insertSetUpTime,
	setUpTimeUser,
	setUpTime,
	resetAddEdit,
	fetchTaskUsers,
	locationList,
	editData = null,
	doRefetch,
	resetHarvestLogTime,
}) => {
	const { t } = useTranslation();
	const [, modalType, , closeModal] = useModal("harvest");
	const [loading, setloading] = useState(initialValues.location_slug ? true : false);
	const [btnTitle, setbtnTitle] = useState(t("label.save"));
	const [date, setdate] = useState(moment().format("YYYY-MM-DD"));
	const [location, setlocation] = useState(initialValues.location_slug ? initialValues.location_slug.value : "");
	const dispatch = useDispatch();
	const harvestTimeOptions = [
		{ value: "Mid", label: "Mid" },
		{ value: "End", label: "End" },
	];

	const submitLogTime = (values) => {
		if (!loading) {
			setloading(true);
			setbtnTitle(t("label.saving"));
			const submitData =
				modalType === 3
					? {
							...values,
							cleaning_date: moment(values.cleaning_date).format("YYYY-MM-DD"),
							cleaning_type: "harvesting",
							room: "",
							location_slug: values.location_slug.value,
							log_instance: values.log_instance.value,
					  }
					: {
							...values,
							set_up_date: moment(values.set_up_date).format("YYYY-MM-DD"),
							room: "",
							set_up_type: "harvesting",
							location_slug: values.location_slug.value,
					  };
			const successHandler = (event) => {
				reset(form);
				setloading(false);
				setbtnTitle(t("label.save"));
				editData && doRefetch && doRefetch();
				successMessage(event);
				closeModal();
			};
			const errorHandler = (event) => {
				setloading(false);
				setbtnTitle(t("label.save"));
				errorMessage(event);
			};
			modalType === 3
				? insertCleaningTime(submitData, successHandler, errorHandler)
				: insertSetUpTime(submitData, successHandler, errorHandler);
		}
	};

	useEffect(() => {
		const successHandler = () => {
			setloading(false);
		};
		const errorHandler = (event) => {
			setloading(false);
			errorMessage(event);
		};
		fetchTaskUsers(successHandler, errorHandler);
	}, [fetchTaskUsers]);

	useEffect(() => {
		return () => {
			(setUpTime || cleaningTime) && resetHarvestLogTime();
		};
	}, [resetHarvestLogTime, setUpTime, cleaningTime]);

	useEffect(() => {
		if (!editData && location && date) {
			setloading(true);
			checkTime();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location, date]);

	const checkTime = () => {
		const obj =
			modalType === 3
				? {
						cleaning_date: date,
						cleaning_type: "harvesting",
						room: "",
						location_slug: location,
				  }
				: {
						set_up_date: date,
						room: "",
						set_up_type: "harvesting",
						location_slug: location,
				  };

		const tempKey = modalType === 3 ? "cleaning_time_in_minutes" : "set_up_time_in_minutes";
		const successHandler = (e) => {
			setloading(false);
			if (e.data && !isEmpty(e.data) && e.data[tempKey]) {
				if (modalType === 3) {
					dispatch(change("harvestLogForm", tempKey, e.data[tempKey]));
					dispatch(
						change("harvestLogForm", "log_instance", {
							value: e.data["log_instance"],
							label: e.data["log_instance"],
						})
					);
				} else {
					dispatch(change("harvestLogForm", tempKey, e.data[tempKey]));
				}
			} else {
				dispatch(change("harvestLogForm", tempKey, null));
			}
		};
		const errorHandler = (event) => {
			setloading(false);
			errorMessage(event);
		};
		modalType === 3 ? getCleaningTime(obj, successHandler, errorHandler) : getSetUpTime(obj, successHandler, errorHandler);
	};

	const getFormFieldHtml = (formfields) => {
		let formFieldHtml = [];

		formFieldHtml = formfields.map((field, idx) => {
			return (
				<React.Fragment key={idx}>
					<Col className="col-12">
						<Form.Group
							controlId={`formControl ${field.name} `}
							className={
								editData &&
								field.name !== "set_up_time_in_minutes" &&
								field.name !== "cleaning_time_in_minutes" &&
								field.name !== "log_instance"
									? "view-form-input-disabled"
									: "view-form-input"
							}>
							{field.name === "cleaning_date" || field.name === "set_up_date" ? (
								<Field
									name={field.name}
									type={field.type}
									label={t(field.label)}
									placeholder={t(field.placeholder)}
									component={FormGroups}
									minDate={field.minDate}
									validate={field.validate}
									disable={editData && true}
									onChange={(value) => setdate(moment(value).format("YYYY-MM-DD"))}
								/>
							) : (
								<Field
									name={field.name}
									type={field.type}
									label={t(field.label)}
									placeholder={t(field.placeholder)}
									component={FormGroups}
									validate={field.validate}
									minDate={field.minDate}
									multiple={false}
									options={field.name === "log_instance" ? harvestTimeOptions : locationList}
									disable={editData && field.name === "location_slug" && true}
									onChange={(event) => field.name === "location_slug" && setlocation(event.value)}
								/>
							)}
						</Form.Group>
					</Col>
				</React.Fragment>
			);
		});
		return <Row>{formFieldHtml}</Row>;
	};

	return (
		<>
			<Form onSubmit={handleSubmit(submitLogTime)} className="harvestLogForm">
				<Container style={{ position: "relative" }}>
					{modalType === 3 ? getFormFieldHtml(cleaningTimeForm) : getFormFieldHtml(setUpTimeForm)}
					{modalType === 3 && cleaningTimeUser && (
						<div
							role="alert"
							style={{ position: "absolute" }}
							className="fade daily-tasks-alert alert alert-light show">
							{t("label.cleaning_time_user", {
								cleaning_time_user: `${cleaningTimeUser}`,
								cleaning_time: `${cleaningTime}`,
							})}
						</div>
					)}
					{modalType === 4 && setUpTimeUser && (
						<div
							role="alert"
							style={{ position: "absolute" }}
							className="fade daily-tasks-alert alert alert-light show">
							{t("label.cleaning_time_user", {
								cleaning_time_user: `${setUpTimeUser}`,
								cleaning_time: `${setUpTime}`,
							})}
						</div>
					)}

					{loading && (
						<div className="table-loader-wrapper">
							<TableLoader />
						</div>
					)}
				</Container>

				<div className="modal-footer save-footer" style={{ marginTop: 40 }}>
					<Button variant="secondary" onClick={closeModal}>
						{t("label.cancel")}
					</Button>
					<Button variant="primary" type="submit" disabled={loading}>
						{btnTitle}
					</Button>
				</div>
			</Form>
		</>
	);
};

const mapStateToProps = (state, props) => {
	const { Harvest } = state;
	const { editData } = props;
	return {
		locationList: get(Harvest, "locationList", []),
		cleaningTime: editData
			? editData.cleaning_time_in_minutes
			: get(Harvest, "cleaningTime.cleaning_time_in_minutes", null),
		cleaningTimeUser: editData ? editData.user : get(Harvest, "cleaningTime.user", null),
		setUpTime: editData ? editData.set_up_time_in_minutes : get(Harvest, "setUpTime.set_up_time_in_minutes", null),
		setUpTimeUser: editData ? editData.user : get(Harvest, "setUpTime.user", null),
		initialValues: editData
			? editData
			: {
					location_slug: !isEmpty(Harvest.selectedLocation) ? Harvest.selectedLocation : "",
					set_up_date: new Date(),
					cleaning_date: new Date(),
			  },
	};
};

const areEqual = (prevProps, nextProps) => {
	return (
		prevProps.cleaningTime === nextProps.cleaningTime &&
		prevProps.setUpTime === nextProps.setUpTime &&
		prevProps.locationList === nextProps.locationList
	);
};

export default connect(mapStateToProps, {
	insertCleaningTime,
	getCleaningTime,
	getSetUpTime,
	insertSetUpTime,
	resetAddEdit,
	fetchTaskUsers,
	resetHarvestLogTime,
})(
	reduxForm({
		form: "harvestLogForm",
		enableReinitialize: false,
	})(React.memo(LogTime, areEqual))
);
