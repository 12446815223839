import React, { useEffect, useRef, useState } from "react";
import { Breadcrumbs, ButtonPrimary, ContentWrap } from "elements";
import Listing from "./listing";
import "../../index.scss";
import TableLoader from "utils/table/tableLoader";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { getForecastRenderType, getLoading, getWeeklyAddAccess } from "store/selectors/forecastSelectors";
import { getLocationCustomersPF, setForecastRenderType, setLoader, uploadExcelFile } from "store/actions";
import { errorMessage } from "utils/errorMessage";
import AddForecast from "./addForecast";
import { successMessage } from "utils/successMessage";
import { ProgressBar } from "react-bootstrap";

const WeeklyForecast = ({
	loading,
	getLocationCustomersPF,
	setLoader,
	renderType,
	setForecastRenderType,
	uploadExcelFile,
	addAccess,
}) => {
	const inpRef = useRef(null);
	const [percentage, setPercentage] = useState("");
	const [uploadBtnText, setuploadBtnText] = useState("Upload Weekly Forecast");
	useEffect(() => {
		setLoader();
		const successHandler = () => {
			setLoader();
		};
		const errorHandler = (e) => {
			errorMessage(e);
		};
		getLocationCustomersPF(successHandler, errorHandler);
	}, [getLocationCustomersPF, setLoader]);

	const onChangeFile = async (event) => {
		inpRef.current = event.target;
		setuploadBtnText("Uploading...");

		const successHandler = (e) => {
			setuploadBtnText("Upload Weekly Forecast");
			successMessage(e);

			inpRef.current.value = "";
		};

		const errorHandler = (e) => {
			setuploadBtnText("Upload Weekly Forecast");

			errorMessage(e);
			inpRef.current.value = "";
		};

		const formD = new FormData();

		formD.append("forecast_file", event.target.files[0]);

		const options = {
			onUploadProgress: (progressEvent) => {
				const { loaded, total } = progressEvent;
				const percent = Math.floor((loaded * 100) / total);
				// console.log(`${loaded}kb of ${total}kb | ${percent}%`);

				setPercentage(percent);
			},
		};

		uploadExcelFile(formD, successHandler, errorHandler, options);
	};

	return (
		<ContentWrap>
			<div className="d-flex align-items-center mb-3">
				<Breadcrumbs
					className="forecast-breadcrumb"
					keyValue={renderType === 0 ? "salesForecast" : "salesForecastAdd"}
					showLabel={renderType === 0 ? true : false}
					label={renderType === 0 ? "Weekly Forecast" : ""}
					// label={
					// 	renderType === 0 ? (
					// 		"Weekly Forecast"
					// 	) : (
					// 		<>
					// 			<span onClick={() => setForecastRenderType(0)}>Weekly Forecast</span> / Add
					// 		</>
					// 	)
					// }
				/>

				{renderType === 0 && (
					<div className="d-md-flex position-relative">
						{addAccess && (
							<>
								<label htmlFor="csv-upload" className="csv-file-upload">
									<i className="icon icon-upload-forecast " />
									{uploadBtnText}
								</label>
								<input
									id="csv-upload"
									accept=".csv"
									fileTypes
									name="forecast_file"
									type="file"
									onChange={onChangeFile}
								/>
								{percentage && percentage < 100 && (
									<ProgressBar className="progress-bar" animated now={percentage} />
								)}

								<ButtonPrimary onClick={() => setForecastRenderType(1)}>
									<i className="icon icon-plus mr-2" />
									Add Weekly Forecast
								</ButtonPrimary>
							</>
						)}
					</div>
				)}
			</div>

			<div className="position-relative">
				{renderType === 0 ? <Listing /> : renderType === 1 ? <AddForecast /> : null}
				{loading && (
					<div className="table-loader-wrapper">
						<TableLoader />
					</div>
				)}
			</div>
		</ContentWrap>
	);
};

const mapStateToProps = createStructuredSelector({
	loading: getLoading,
	renderType: getForecastRenderType,
	addAccess: getWeeklyAddAccess,
});

export default connect(mapStateToProps, { getLocationCustomersPF, setLoader, setForecastRenderType, uploadExcelFile })(
	WeeklyForecast
);
