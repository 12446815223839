import { HARVEST } from "../types";
import { isEmpty } from "lodash";

const initialState = {
	renderType: 0,
	harvestDetails: [],
	selectedLocation: {},
	taskUser: null,
	locationList: [],
	productList: [],
	assigneeList: [],
	pondList: [],
	laneList: [],
	lanesPerPond: null,
	leaningTime: null,
	setUpTime: null,
	fetchHarvestList: [],
	activityLog: null,
	harvestTaskOrderList: [],
};

const getList = (data) => {
	// eslint-disable-next-line prefer-const
	let arr = [];
	for (let i = 1; i <= parseInt(data); i++) {
		arr.push({ value: i, label: i });
	}
	return arr;
};

export default (state = initialState, action) => {
	switch (action.type) {
		case HARVEST.FETCH_HARVEST_LIST:
			return {
				...state,
				fetchHarvestList: (action.payload && action.payload.data) || [],
			};
		case HARVEST.FETCH_HARVEST_TASK_ORDER_LIST:
			return {
				...state,
				harvestTaskOrderList: (action.payload && action.payload.data) || [],
			};
		case HARVEST.FETCH_HARVEST_DETAILS:
			const selLoc = {
				value: action.payload.data.location.slug,
				label: action.payload.data.location.location_name,
			};

			return {
				...state,
				harvestDetails: action.payload ? action.payload.data : {},
				selectedLocation: selLoc,
			};
		case HARVEST.FETCH_TASK_USERS:
			const { data } = action.payload;
			const loc = Object.values(data).map((item) => {
				return {
					value: item.slug,
					label: item.location_name,
				};
			});
			const prodList = isEmpty(state.selectedLocation) ? [] : data[state.selectedLocation.value].product_varieties;
			const userList = isEmpty(state.selectedLocation) ? [] : data[state.selectedLocation.value].users;
			const pondList = isEmpty(state.selectedLocation) ? [] : data[state.selectedLocation.value].pond;
			const lanesPerPond = isEmpty(state.selectedLocation) ? null : data[state.selectedLocation.value].lanes_per_pond;
			const laneList = isEmpty(state.selectedLocation) ? [] : getList(data[state.selectedLocation.value].lanes_per_pond);
			return {
				...state,
				taskUser: data,
				locationList: loc,
				productList: prodList,
				assigneeList: userList,
				pondList,
				laneList,
				lanesPerPond,
			};

		case HARVEST.CHANGE_LOCATION:
			const selLoc_2 = action.payload;
			const prodList_2 = state.taskUser && selLoc_2 ? state.taskUser[selLoc_2.value].product_varieties : [];
			const userList_2 = state.taskUser && selLoc_2 ? state.taskUser[selLoc_2.value].users : [];
			const pondList_2 = state.taskUser && selLoc_2 ? state.taskUser[selLoc_2.value].pond : [];
			const lanesPerPond_2 = state.taskUser && selLoc_2 ? state.taskUser[selLoc_2.value].lanes_per_pond : null;

			const laneList_2 = state.taskUser && selLoc_2 ? getList(state.taskUser[selLoc_2.value].lanes_per_pond) : [];
			return {
				...state,
				selectedLocation: selLoc_2,
				productList: prodList_2,
				assigneeList: userList_2,
				pondList: pondList_2,
				laneList: laneList_2,
				lanesPerPond: lanesPerPond_2,
			};

		case HARVEST.CHANGE_RENDER_TYPE:
			return {
				...state,
				renderType: action.payload.value,
				slug: action.payload.value === 1 ? action.payload.slug : "",
			};
		case HARVEST.UPDATE_HARVESTED_DETAILS:
			const obj = {
				...state.harvestDetails,
				...action.payload,
				harvesting_task_description: action.payload.harvested_comments,
				harvested_board_details: action.payload.board_details,
			};
			return {
				...state,
				harvestDetails: obj,
			};
		case HARVEST.UPDATE_HARVESTED_BOARD_DETAILS:
			return {
				...state,
				harvestDetails: {
					...state.harvestDetails,
					harvested_board_details: [...state.harvestDetails.harvested_board_details, ...action.payload],
				},
			};
		case HARVEST.UPDATE_STATUS:
			return {
				...state,
				harvestDetails: { ...state.harvestDetails, status: action.payload },
			};

		case HARVEST.SET_STATUS_LIST:
			const list = action.payload.data.harvesting_task_status.map((item) => {
				return {
					label: item === "Inprogress" ? item = "In progress" : item,
					value: item,
				};
			});
			return {
				...state,
				statusList: list,
			};
		case HARVEST.GET_CLEANING_TIME:
			return {
				...state,
				cleaningTime: action.payload.data ? action.payload.data : null,
			};
		case HARVEST.GET_SET_UP_TIME:
			return {
				...state,
				setUpTime: action.payload.data ? action.payload.data : null,
			};
		case HARVEST.GET_HARVEST_AUDIT_LOG:
			return {
				...state,
				activityLog: action.payload.data ? action.payload.data : null,
			};

		case HARVEST.RESET_ADD_EDIT:
			const list_2 = state.statusList;
			return {
				...initialState,
				fetchHarvestList:state.fetchHarvestList,
				statusList: list_2,
			};
		case HARVEST.RESET_HARVEST_LOG_TIME:
			return {
				...state,
				setUpTime: null,
				cleaningTime: null,
			};

		default:
			return state;
	}
};
