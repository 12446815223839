import React from "react";
import { Button } from "react-bootstrap";
import Select from "react-select";
import DatePicker from "react-datepicker";
import moment from "moment";
import i18next from "i18next";

export const LocationDropdown = ({ label, value, options, onSelect, isClearable = true, placeholder }) => (
	<>
		<div className={label ? "form-group" : ""}>
			<div className={label ? "form-group" : ""}>
				<Select
					value={value}
					onChange={onSelect}
					options={options}
					isClearable={isClearable}
					classNamePrefix="header-react-select"
					className="header-location-dropdown"
					placeholder={placeholder ? placeholder : i18next.t("label.select_location")}
				/>
				{label && <span className="form-label">{label}</span>}
			</div>
		</div>
	</>
);

export const AssigneeButton = ({ onClick }) => (
	<Button className="header-assignee-btn position-assignee-btn" variant="" onClick={onClick}>
		<i className="icon icon-select-assignee" />
		{i18next.t("label.select_assignee")}
	</Button>
);

export const CommonSelectBox = ({ value, options, placeholder, onSelect, selectMenuHeight, isClearable = true }) => (
	<Select
		value={value}
		onChange={onSelect}
		options={options}
		isClearable={false}
		classNamePrefix="header-react-select-common"
		className="header-location-dropdown"
		placeholder={placeholder ? placeholder : i18next.t("label.select")}
		maxMenuHeight={selectMenuHeight}
		//styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }), menu: (styles) => ({ ...styles, zIndex: 999 }) }}
	/>
);

export const CommonDatePicker = ({ selectedDate = "", onSelect, minDate, placeholder, label = "" }) => (
	<>
		<div className="form-group">
			<div className="form-group">
				<DatePicker
					classNamePrefix="header-react-select"
					className="form-control form-control-date height-order-class header-location-dropdown"
					selected={selectedDate}
					onChange={onSelect}
					//disabled={disable}
					placeholderText={placeholder}
					dateFormat="MM/dd/yyyy"
					calendarClassName="bright-farms-calendar"
					autoComplete="off"
					label={label}
					//minDate={minDate}
				/>
				<span className="form-label">{label}</span>
			</div>
		</div>
	</>
);
export const DateRangePicker = ({ onChange, startDate, endDate, minDate, placeholder, label = "" }) => (
	<>
		<div className="form-group">
			<div className="form-group">
				<DatePicker
					classNamePrefix="header-react-select"
					className="form-control form-control-date height-order-class header-location-dropdown"
					selected={startDate}
					onChange={onChange}
					startDate={startDate}
					endDate={endDate}
					selectsRange
					placeholderText={placeholder}
					value={
						!endDate
							? new Date()
							: moment(`${startDate}`).format("MM/DD/YYYY") + " - " + moment(`${endDate}`).format("MM/DD/YYYY")
					}
					shouldCloseOnSelect={false}
					openToDate={startDate ? startDate : new Date()}
					showYearDropdown={false}
					showYearPicker={false}
					showMonthYearPicker={false}
					minDate={minDate}
					autoComplete="off"
					dateFormat="MM/dd/yyyy"
				/>

				<span className="form-label">{label}</span>
			</div>
		</div>
	</>
);
export const LocationOrderDropdown = ({ value, options, onSelect, isClearable = true, label = "" }) => (
	<>
		<div className="form-group">
			<div className="form-group">
				<Select
					value={value}
					onChange={onSelect}
					options={options}
					isClearable={isClearable}
					classNamePrefix="header-react-select"
					className="header-location-dropdown"
					placeholder={i18next.t("label.select_location")}
				/>
				<span className="form-label">{label}</span>
			</div>
		</div>
	</>
);

export const PondDropdown = ({ label, value, options, onSelect, isClearable = true, placeholder }) => (
	<>
		<div className={label ? "form-group" : ""}>
			<div className={label ? "form-group" : ""}>
				<Select
					value={value}
					onChange={onSelect}
					options={options}
					isClearable={isClearable}
					classNamePrefix="header-react-select"
					className="header-location-dropdown"
					placeholder={placeholder ? placeholder : i18next.t("label.select_pond")}
				/>
				{label && <span className="form-label">{label}</span>}
			</div>
		</div>
	</>
);