import React, { useState } from "react";
import { ButtonSecondary } from "../elements";
import "./uploadFile.scss";

const ImageUpload = ({
	input,
	multiple = true,
	resizeImage = [150, 150],
	onChange,
	defaultValue = null,
	placeholder = null,
	className,
}) => {
	const [defaultImage, setDefaultImage] = useState(defaultValue);
	const [thumbnails, setThumbnails] = useState([]);

	const renderDefault = () => {
		return defaultImage ? (
			<div className="image-upload-thumbnails">
				<img src={defaultImage} alt="DroppedImage" />
				<ButtonSecondary className="remove-btn" onClick={removeDefault}>
					<i className="icon icon-close mr-2" />
				</ButtonSecondary>
			</div>
		) : null;
	};

	const renderThumbnails = () => {
		return thumbnails && thumbnails.length
			? thumbnails.map((item, index) => {
					return (
						<div className="image-upload-thumbnails" key={index}>
							<img src={item.base64} alt="Uploaded" />
							<ButtonSecondary className="remove-btn" onClick={() => removeImage(item)}>
								<i className="icon icon-close mr-2" />
							</ButtonSecondary>
						</div>
					);
			  })
			: null;
	};

	const onFileChange = (files) => {
		const newList = files.map((item) => {
			return new Promise((resolve) => {
				const reader = new FileReader();
				reader.onload = (event) => {
					const image = new Image();
					image.onload = (imageEvent) => {
						const targetImage = imageEvent.target;
						const ratio = targetImage.width / targetImage.height;
						let nWidth = 0;
						let nHeight = 0;
						if (ratio > 1) {
							nWidth = resizeImage[0];
							nHeight = resizeImage[1] / ratio;
						} else {
							nWidth = resizeImage[0] * ratio;
							nHeight = resizeImage[1];
						}
						const canvas = document.createElement("canvas");
						canvas.width = nWidth;
						canvas.height = nHeight;
						const ctx = canvas.getContext("2d");
						ctx.fillStyle = "#fff";
						ctx.fillRect(0, 0, nWidth, nHeight);
						ctx.drawImage(image, 0, 0, nWidth, nHeight);
						resolve({
							file: item,
							base64: canvas.toDataURL("image/jpeg"),
						});
					};
					image.src = event.target.result;
				};
				reader.readAsDataURL(item);
			});
		});

		Promise.all(newList).then(function (values) {
			const list = multiple ? [...thumbnails, ...values] : values;
			onChange && onChange(list);
			setThumbnails(list);
		});
	};

	const onDragFileOver = (e) => e.preventDefault();

	const onDragLeaveFile = (e) => e.preventDefault();

	const onDropFile = (e) => {
		e.preventDefault();
		onFileChange([...e.nativeEvent.dataTransfer.files]);
	};

	const removeImage = (image) => {
		const list = thumbnails.filter((i) => i !== image);
		onChange && onChange(list);
		setThumbnails(list);
	};

	const removeDefault = () => {
		onChange && onChange(null);
		setDefaultImage(null);
	};

	const showUploadInput = thumbnails.length === 0 && !defaultImage;

	return (
		<div className={`image-upload-wrapper ${className}`}>
			{/* Click to upload */}
			{showUploadInput ? (
				<div
					className="image-upload-input"
					onDragOver={onDragFileOver}
					onDragLeave={onDragLeaveFile}
					onDrop={onDropFile}>
					<input {...input} type="file" multiple={multiple} onChange={(e) => onFileChange([...e.target.files])} />
					<span className="image-upload-placeholder">
						{placeholder ? placeholder : `Upload Image${multiple ? "s" : ""}`}
					</span>
				</div>
			) : null}

			{/* Default */}
			{!thumbnails.length ? renderDefault() : null}

			{/* Uploaded Images */}
			{renderThumbnails()}
		</div>
	);
};

export default ImageUpload;
