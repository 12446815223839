import React, { useState } from "react";
import { connect } from "react-redux";
import { Container, Button, Row, Col, Form } from "react-bootstrap";
import { reduxForm, Field, reset } from "redux-form";
import FormGroups from "../../../utils/formGroup";
import { required, number } from "../../../constant/formValidators";
import { formCreationFields } from "./formHelper";
import { createBoard } from "../../../store/actions";
import useModal from "../../../utils/useModal";
import { toggleMainLoader } from "../../../store/actions";
import { errorMessage } from "../../../utils/errorMessage";
import { successMessage } from "../../../utils/successMessage";
import TableLoader from "utils/table/tableLoader";

const BoardAddEdit = ({
	handleSubmit,
	createBoard,
	handleEditPanel,
	editingBoardDetails,
	toggleMainLoader,
	reset,
	form,
	onAddOrEdit,
	initialValues,
}) => {
	const [, modalType, , closeModal] = useModal("boards");
	const [btnTitle, setbtnTitle] = useState("Save");
	const [isLoading, setisLoading] = useState(false);
	const submitBoardDetails = (values) => {
		if (!isLoading) {
			setisLoading(true);
			setbtnTitle("Saving...");
			toggleMainLoader(true);
			const successHandler = (event) => {
				onAddOrEdit && onAddOrEdit(event);
				setisLoading(false);
				setbtnTitle("Save");
				reset(form);
				successMessage(event);
				closeModal();
			};
			const errorHandler = (event) => {
				setisLoading(false);
				setbtnTitle("Save");
				errorMessage(event);
			};
			const method = modalType ? "PUT" : "POST";
			const payload = modalType ? initialValues.slug : "";
			const submissionData = {
				...values,
			};

			createBoard(submissionData, method, payload, successHandler, errorHandler);
		}
	};
	const getValidations = (field) => {
		switch (field) {
			case "board_name":
				return required;
			default:
				return [required, number];
		}
	};

	const styroFormFields = () => {
		let columns = [];
		columns = formCreationFields.map((field, idx) => {
			return (
				<Col xs={6} md={6} key={idx}>
					<Form.Group controlId={`formControl ${idx}`}>
						<Field
							name={field.name}
							type={field.type}
							label={field.label}
							placeholder={field.placeholder}
							component={FormGroups}
							validate={getValidations(field.name)}
						/>
					</Form.Group>
				</Col>
			);
		});

		return <Row>{columns}</Row>;
	};

	return (
		<>
			<Form onSubmit={handleSubmit(submitBoardDetails)} className="board-details-form">
				<Container className="position-relative">
					{styroFormFields()}
					{isLoading && (
						<div className="table-loader-wrapper">
							<TableLoader />
						</div>
					)}
				</Container>
				<div className="modal-footer save-footer">
					<Button variant="secondary" onClick={closeModal}>
						Cancel
					</Button>
					<Button variant="primary" type="submit" disabled={isLoading}>
						{btnTitle}
					</Button>
				</div>
			</Form>
		</>
	);
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
	toggleMainLoader,
	createBoard,
	reset,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(
	reduxForm({
		form: "board-details-form",
		enableReinitialize: true,
	})(BoardAddEdit)
);
