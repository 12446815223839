import { PRODUCT } from "../types";

export const showView = (modalName, modalType, modalProps) => {
	return {
		type: PRODUCT.SHOW_PRODUCT_VIEW,
		payload: {
			modalType,
			modalName,
			modalProps,
		},
	};
};

export const hideView = (modalName) => {
	return {
		type: PRODUCT.HIDE_PRODUCT_VIEW,
		payload: {
			modalName,
		},
	};
};
