import React, { useState } from "react";
import { Field, reduxForm, reset } from "redux-form";
import { Form, Container, Row, Col, Modal, Button } from "react-bootstrap";
import useModal from "../../utils/useModal";
import useGetUser from "../../utils/useGetUser";
import { required, names, USPhoneNumber, normalizePhone, email } from "../../constant/formValidators";
import FormGroups from "../../utils/formGroup";
import { successMessage } from "../../utils/successMessage";
import { errorMessage } from "../../utils/errorMessage";
import { connect } from "react-redux";
import { updateProfile } from "../../store/actions/userActions";

const ProfileEdit = ({ initialValues = {}, handleSubmit, form, reset, updateProfile }) => {
	const [getUser] = useGetUser();
	const [isOpen, , , closeModal] = useModal("profile");
	const [isLoading, setIsLoading] = useState(false);

	const getUpdatedBody = (body, img) => {
		if (img === undefined) {
			return body;
		} else {
			return {
				...body,
				photo: img && img[0] ? img[0]["base64"] : null,
			};
		}
	};

	const updateMyProfile = (values) => {
		setIsLoading(true);
		const body = {
			first_name: values.first_name,
			last_name: values.last_name,
			phone: values.phone ? values.phone : "",
			email: values.email ? values.email : "",
		};
		const bodyEdit = getUpdatedBody(body, values.photo);
		const successHandler = (event) => {
			setIsLoading(false);
			successMessage(event);
			reset(form);
			closeModal();
			getUser();
		};
		const errorHandler = (event) => {
			setIsLoading(false);
			errorMessage(event);
		};
		updateProfile(bodyEdit, successHandler, errorHandler);
	};

	return (
		<Modal size="lg" show={isOpen} onHide={closeModal} className="modal-sidepanel">
			<Modal.Header closeButton>
				<Modal.Title className="modal-title">Edit Profile</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form onSubmit={handleSubmit(updateMyProfile)} className="user-details-form">
					<Container>
						<Row>
							<Col xs={6} md={6}>
								<Form.Group controlId="userFirstName">
									<Field
										name="first_name"
										type="text"
										label="First Name"
										placeholder="Enter First Name"
										component={FormGroups}
										validate={[required, names]}
									/>
								</Form.Group>
							</Col>
							<Col xs={6} md={6}>
								<Form.Group controlId="userLastName">
									<Field
										name="last_name"
										type="text"
										label="Last Name"
										placeholder="Enter Last Name"
										component={FormGroups}
										validate={[required, names]}
									/>
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Col xs={6} md={6}>
								<Form.Group controlId="userEmail">
									<Field
										name="email"
										type="text"
										label="Email"
										placeholder="Enter Email"
										component={FormGroups}
										validate={[required, email]}
									/>
								</Form.Group>
							</Col>
							<Col xs={6} md={6}>
								<Form.Group controlId="userPhone">
									<Field
										label="Phone Number"
										type="text"
										name="phone"
										placeholder="Enter Phone Number"
										component={FormGroups}
										validate={[USPhoneNumber]}
										normalize={normalizePhone}
									/>
								</Form.Group>
							</Col>
						</Row>
					</Container>
					<Container>
						<div className="d-flex justify-content-center profile-edit-image">
							<Field
								className="profile-round-upload"
								name="photo"
								type="image-upload"
								defaultValue={initialValues.avatar || null}
								placeholder="Drop Profile Image here"
								component={FormGroups}
								multiple={false}
							/>
						</div>
					</Container>
					<Modal.Footer>
						<Button variant="secondary" onClick={closeModal}>
							Cancel
						</Button>
						<Button variant="primary" type="submit" disabled={isLoading}>
							{isLoading ? "Saving..." : "Save"}
						</Button>
					</Modal.Footer>
				</Form>
			</Modal.Body>
		</Modal>
	);
};

const mapDispatchToProps = {
	reset,
	updateProfile,
};

export default connect(
	null,
	mapDispatchToProps
)(
	reduxForm({
		form: "user-profile-form",
		enableReinitialize: true,
	})(ProfileEdit)
);
