export const sortFields = (key) => {
	const currentArray = {
		sku: {
			title: "Drag and Drop the rows to change sku priority",
			headOne: "SKU",
			headTwo: "Priority",
			fieldKey: "sku_slug",
			fieldValue: "sku_name",
			url: "location-sku-priority",
			payloadKey: "skus",
		},
		customer: {
			title: "Drag and Drop the rows to change customer priority",
			headOne: "Customer Name",
			headTwo: "Priority",
			fieldKey: "customer_slug",
			fieldValue: "customer_name",
			url: "location-customer-priority",
			payloadKey: "customers",
		},
	};
	return currentArray[key];
};
