import React from "react";
import { Modal } from "react-bootstrap";
import useModal from "../../../utils/useModal";
import PackageDetailsForm from "./packageDetailsForm";
import { useTranslation } from 'react-i18next';
const { Header, Title, Body } = Modal;

const PackageDetailsModal = ({ doFetchTaskDetails }) => {
	const { t } = useTranslation();
	const [isOpen, modalType, , closeModal, modalProps] = useModal("op_package_details");

	const isEdit = modalType === 2;
	return (
		<Modal size="lg" show={isOpen} onHide={closeModal} className="modal-sidepanel">
			<Header closeButton>
				<Title className="modal-title">{t("label.packaging_details")}</Title>
			</Header>
			<Body>
				<PackageDetailsForm isEdit={isEdit} initialValues={modalProps} doFetchTaskDetails={doFetchTaskDetails} />
			</Body>
		</Modal>
	);
};

export default PackageDetailsModal;
