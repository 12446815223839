import { SESSION } from "../types";
import Util from "../../utils/Util";

const initialState = { profileDetails: {}, userLocations: [], permissionDetails: [] };

export default (state = initialState, action) => {
	switch (action.type) {
		case SESSION.GET_PROFILE_DETAILS:
			return {
				...state,
				profileDetails: action.payload ? action.payload.data : {},
				userLocations: action.payload ? action.payload.data.location_slugs : [],
				reportLocations: action.payload ? action.payload.data.other_location_slugs : [],
				climateLocations: action.payload ? action.payload.data.climate_location_slugs : [],
			};
		case SESSION.GET_PERMISSION_DETAILS:
			return {
				...state,
				permissionDetails: action.payload ? Util.formatPermissionDataArray(action.payload.data) : {},
			};
		case SESSION.UPDATE_LOGIN_CREDENTIALS:
			return {
				userDetails: action.payload,
				userLocations: action.payload ? action.payload.data.location_slugs : [],
				reportLocations: action.payload ? action.payload.data.other_location_slugs : [],
				climateLocations: action.payload ? action.payload.data.climate_location_slugs : [],
				//permissionDetails: action.payload ? Util.formatPermissionDataArray(action.payload.data) : {},
			};
		default:
			return state;
	}
};
