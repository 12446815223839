import React, { useEffect, useRef, useState } from "react";

export const ListingBtn = ({
	onClick,
	iconClass,
	tooltipText,
	parentClass = "",
	btnClass = "btn btn-cell btn-light btn-sm ml-2",
	tooltipWidth = "max-content",
	id,
	type = "btn",
	tooltipFontSize = 12,
	tooltipLeft = false,
	errorBtn = false,
	disabled = false,
}) => {
	const btnRef = useRef(null);
	useEffect(() => {
		btnRef.current.parentNode.classList.add("overflow-unset");
		btnRef.current.parentNode.classList.add("d-flex");
		btnRef.current.parentNode.classList.add("align-items-center");
		parentClass && btnRef.current.parentNode.classList.add(parentClass);
	}, [parentClass]);

	const [tooltip, settooltip] = useState(false);

	return (
		<div ref={btnRef}>
			{type === "btn" ? (
				<button
					id={id}
					type="button"
					disabled={disabled}
					onMouseEnter={() => settooltip(true)}
					onMouseLeave={() => settooltip(false)}
					className={`${btnClass} btn-tooltip btn-listing`}
					onClick={onClick}>
					{tooltipText && tooltip && (
						<span
							className={tooltipLeft ? "tooltip-btn tooltip-left" : "tooltip-btn"}
							style={{
								width: tooltipWidth,
								...(errorBtn && {
									backgroundColor: "#edffb6",
									fontWeight: "500",
									color: "red",
								}),
							}}>
							{tooltipText}
						</span>
					)}
					<i className={iconClass} />
				</button>
			) : (
				<div
					id={id}
					onMouseEnter={() => settooltip(true)}
					onMouseLeave={() => settooltip(false)}
					className={`${btnClass} btn-tooltip btn-listing`}
					onClick={onClick}>
					{tooltipText && tooltip && (
						<div
							className={tooltipLeft ? "tooltip-btn tooltip-left" : "tooltip-btn"}
							style={{ width: tooltipWidth, fontSize: tooltipFontSize }}>
							{tooltipText}
						</div>
					)}
					<i className={iconClass} />
				</div>
			)}
		</div>
	);
};
