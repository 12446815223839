import { createSelectorCreator, defaultMemoize } from "reselect";
import { isEmpty, isEqual, values } from "lodash";
import Util from "utils/Util";

const getCroptimals = (state) => state.Croptimals;

const getForm = (state) => state.form;

const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);

const getDropdownData = createDeepEqualSelector([getCroptimals], (crop) => crop.dropdownData);

export const croptimalsForm = createDeepEqualSelector([getForm], (form) => form.croptimalsForm);

export const getLoading = createDeepEqualSelector([getCroptimals], (crop) => crop && crop.loading);

export const getLocationList = createDeepEqualSelector([getDropdownData], (data) =>
	data && data.location && !isEmpty(data.location)
		? Util.mapOptions(values(data.location), "location_name", "location_slug")
		: []
);

export const getSelectedLocation = createDeepEqualSelector([croptimalsForm], (form) =>
	form && form.values && form.values.location_slug ? form.values.location_slug : null
);
export const getSelectedSeason = createDeepEqualSelector([croptimalsForm], (form) =>
	form && form.values && form.values.season ? form.values.season : null
);

export const getProductVarietyList = createDeepEqualSelector([getDropdownData, getSelectedLocation], (crop, selLocation) =>
	crop && selLocation
		? Util.mapOptions(crop.location[selLocation.value].pv, "product_variety_name", "product_variety_slug")
		: []
);

export const getSeasonList = createDeepEqualSelector([getDropdownData], (data) =>
	data && data.seasonData && !isEmpty(data.seasonData) ? Util.mapOptions(data.seasonData, "season_name", "season_name") : []
);

export const getRenderType = createDeepEqualSelector([getCroptimals], (crop) => crop.renderType);

const columnFormat = (values) => {
	const data = values.map((item) => ({
		name: item.nutrient,
		slug: item.slug,
	}));
	return data;
};

export const getNutrientList = createDeepEqualSelector([getDropdownData], (data) =>
	data && data.nutrientMaster && !isEmpty(data.nutrientMaster) ? columnFormat(data.nutrientMaster) : []
);
export const getCroptimalsData = createDeepEqualSelector([getCroptimals], (crop) => crop.data);

export const getCroptimalsTableColumn = createDeepEqualSelector([getCroptimals], (crop) => crop.columns);

export const getProductKeyMapping = createDeepEqualSelector([getCroptimals], (crop) => crop.productKeyMapping);

const croptimalsPermit = (state) => state.Auth.permissionDetails.croptimals;

export const getAddPermission = createDeepEqualSelector(
	[croptimalsPermit],
	(access) => access && access.permissions.add_croptimals
);

export const getEditPermission = createDeepEqualSelector(
	[croptimalsPermit],
	(access) => access && access.permissions.edit_croptimals
);

export const getDeletePermission = createDeepEqualSelector(
	[croptimalsPermit],
	(access) => access && access.permissions.delete_croptimals
);

export const getListPermission = createDeepEqualSelector(
	[croptimalsPermit],
	(access) => access && access.permissions.list_croptimals
);
