import React, { useState } from "react";
import "./uploadFile.scss";

const FileUpload = ({
	input,
	multiple = true,
	resizeImage = [150, 150],
	onChange,
	fileType = "file",
	defaultValue = null,
	placeholder = null,
}) => {
	const [over, setOver] = useState(false);
	const [thumbnails, setThumbnails] = useState([]);

	const renderDefault = () => {
		return defaultValue ? (
			<div className="thumbnail">
				<img src={defaultValue} alt="DropedImage" />
			</div>
		) : null;
	};

	const renderThumbnails = () => {
		return thumbnails && thumbnails.length
			? thumbnails.map((item, index) => {
					return (
						<div className="thumbnail" key={index}>
							<img src={item.base64} alt="Uploaded" />
						</div>
					);
			  })
			: null;
	};

	const onFileChange = (files) => {
		const newList = files.map((item) => {
			return new Promise((resolve) => {
				let reader = new FileReader();
				reader.onload = (event) => {
					const image = new Image();
					image.onload = (imageEvent) => {
						const targetImage = imageEvent.target;
						const ratio = targetImage.width / targetImage.height;
						let nWidth = 0;
						let nHeight = 0;
						if (ratio > 1) {
							nWidth = resizeImage[0];
							nHeight = resizeImage[1] / ratio;
						} else {
							nWidth = resizeImage[0] * ratio;
							nHeight = resizeImage[1];
						}
						const canvas = document.createElement("canvas");
						canvas.width = nWidth;
						canvas.height = nHeight;
						const ctx = canvas.getContext("2d");
						ctx.fillStyle = "#fff";
						ctx.fillRect(0, 0, nWidth, nHeight);
						ctx.drawImage(image, 0, 0, nWidth, nHeight);
						resolve({
							file: item,
							base64: canvas.toDataURL("image/jpeg"),
						});
					};
					image.src = event.target.result;
				};
				reader.readAsDataURL(item);
			});
		});

		Promise.all(newList).then(function (values) {
			const list = multiple ? [...thumbnails, ...values] : values;
			onChange && onChange(list);
			setThumbnails(list);
		});
	};

	const onDragFileOver = (e) => {
		e.preventDefault();
		setOver(true);
	};

	const onDragLeaveFile = (e) => {
		e.preventDefault();
		setOver(false);
	};

	const onDropFile = (e) => {
		e.preventDefault();
		onFileChange([...e.nativeEvent.dataTransfer.files]);
		setOver(false);
	};

	return (
		<label
			className={`droparea ${over ? "over" : ""}`}
			onDragOver={onDragFileOver}
			onDragLeave={onDragLeaveFile}
			onDrop={onDropFile}
		>
			{!thumbnails.length ? (
				<div className="droparea-thumbnails">{renderDefault()}</div>
			) : null}
			<div className="droparea-thumbnails">{renderThumbnails()}</div>
			<input
				{...input}
				type="file"
				multiple={multiple}
				onChange={(e) => onFileChange([...e.target.files])}
			/>
			<span className="droparea-placeholder">
				{placeholder
					? placeholder
					: `Drop ${fileType} ${multiple ? "s" : ""} here!`}
			</span>
		</label>
	);
};

export default FileUpload;
