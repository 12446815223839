/* eslint-disable array-callback-return */
import React, { useState, useEffect } from "react";
import WatchWireForm from "../manage/index";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import { Tab, TabContent, Tabs } from "react-bootstrap";
import ForecastTable from "utils/forecastTable";
import Util from "../../../utils/Util";
// import { columns } from "../manage/formHelper";
import Chart from "./chart";
import TableLoader from "../../../utils/table/tableLoader";
import { setActiveTabCommodity, setActiveTabAccountId } from "../../../store/actions/watchWireActions";
import {
	getTabsCommodity,
	getActiveTabCommodity,
	getActiveTabAccountIds,
	getTabsDC,
	getTableView,
	CommodityKeyLabel,
	getChartData,
	getStartDate,
	getEndDate,
	getShowChartObj,
	getSubmittedObj,
	getColumns,
} from "../../../store/selectors/watchWireSelectors";
const Listing = ({
	accountsIdTabs,
	activeCommodityTab,
	activeTabAccountsIds,
	dcTabs,
	tableViewData,
	CommodityKeyLabel,
	setActiveTabCommodity,
	setActiveTabAccountId,
	chartData,
	startDate,
	endDate,
	showChart,
	submittedObj,
	columns,
}) => {
	const [isLoading] = useState(false);
	const [data, setData] = useState({});
	useEffect(() => {
		const dataChart = chartData;
		const sDate = Util.getYMDFormat(startDate).split("-");
		const eDate = Util.getYMDFormat(endDate).split("-");
		if (JSON.stringify(sDate) === JSON.stringify(eDate)) {
			for (const key in dataChart) {
				dataChart[key].data = fillMonths(
					dataChart[key].data,
					Number(sDate[1]),
					Number(eDate[1]),
					Number(sDate[0].substr(-4)),
					Number(eDate[0].substr(-4))
				);
			}
		}
		setData(dataChart);
	}, [chartData, endDate, startDate]);
	const fillMonths = (chartData, startMonth, endMonth, startYear, endYear) => {
		const allMonths = [
			"January",
			"February",
			"March",
			"April",
			"May",
			"June",
			"July",
			"August",
			"September",
			"October",
			"November",
			"December",
		];
		const result = [];
		let months = [];
		if (startYear === endYear) {
			// fillmonths in a year
			months = allMonths.slice(startMonth - 1, endMonth);
			months.forEach((month, index) => (months[index] = endYear + "-" + month));
		} else if (endYear - startYear > 1) {
			months = allMonths.slice(startMonth - 1);
			months.forEach((month, index) => {
				months[index] = startYear + "-" + month;
			});
			for (let index = 1; index < endYear - startYear; index++) {
				const interMonths = allMonths.map((month) => Number(startYear + index) + "-" + month);
				months.push(...interMonths);
			}
			const lastMonths = allMonths.slice(0, endMonth).map((month) => endYear + "-" + month);
			months.push(...lastMonths);
		} else {
			//for adjacent start and endyear
			months = allMonths.slice(startMonth - 1).map((month) => startYear + "-" + month);
			months.push(...allMonths.slice(0, endMonth).map((month) => endYear + "-" + month));
		}
		months.forEach((month) => {
			const item = chartData.find((dataItem) => dataItem.date === month);
			if (item) {
				result.push(item);
			} else {
				result.push({ date: month, value: 0 });
			}
		});
		return result;
	};

	return (
		<div className="listing-wrapper">
			<WatchWireForm />

			{
				isLoading ? <TableLoader /> : !isEmpty(data) && showChart ? <Chart data={data} /> : null
				// <p className="card-text-empty text-center">{!isLoading && chartData  ? "No Data Available" : ""}</p>
			}

			{accountsIdTabs && (
				<div className="tab-wrapper">
					<Tabs
						activeKey={activeCommodityTab}
						defaultActiveKey={activeCommodityTab}
						onSelect={(k) => {
							setActiveTabCommodity(CommodityKeyLabel[k]);
						}}
						mountOnEnter={true}
						className="customer-tab"
						id="uncontrolled-tab-outer">
						{accountsIdTabs.map((tab, index) => (
							<Tab eventKey={tab.key} key={index} title={tab.label}>
								{dcTabs && (
									<TabContent bsPrefix="customer-tab-content">
										<Tabs
											activeKey={activeTabAccountsIds}
											defaultActiveKey={activeTabAccountsIds}
											onSelect={(k) => {
												setActiveTabAccountId(k);
											}}
											className="dc-tab"
											mountOnEnter={true}
											id="uncontrolled-tab-inner">
											{dcTabs.map((tab, index) => (
												<Tab
													className="dc-tab-cell"
													eventKey={tab.key}
													key={index}
													title={
														<span>
															<i className="icon icon-market mr-3" />
															{tab.label}
														</span>
													}>
													{activeTabAccountsIds === tab.key && tableViewData ? (
														!isEmpty(tableViewData) ? (
															<>
																<ForecastTable
																	columns={columns}
																	fixedColumnHeading="Month"
																	initialData={tableViewData}
																	render={(item) => (
																		<>
																			<span className="txt-green">
																				{item.name === "Usage"
																					? item.usage &&
																					  item.usage.toLocaleString("en-IN")
																					: item.name === "Cost"
																					? item.cost &&
																					  "$" + item.cost.toLocaleString("en-IN")
																					: null}
																			</span>
																		</>
																	)}
																	watchWire={true}
																/>
															</>
														) : (
															<div className="no-results-found text-center">No Results Found</div>
														)
													) : null}
												</Tab>
											))}
										</Tabs>
									</TabContent>
								)}
							</Tab>
						))}
					</Tabs>
				</div>
			)}

			{submittedObj && isEmpty(tableViewData) ? (
				<div className="no-results-found text-center">No Results Found</div>
			) : null}
		</div>
	);
};

const mapStateToProps = createStructuredSelector({
	accountsIdTabs: getTabsCommodity,
	activeCommodityTab: getActiveTabCommodity,
	activeTabAccountsIds: getActiveTabAccountIds,
	dcTabs: getTabsDC,
	tableViewData: getTableView,
	CommodityKeyLabel: CommodityKeyLabel,
	chartData: getChartData,
	startDate: getStartDate,
	endDate: getEndDate,
	showChart: getShowChartObj,
	submittedObj: getSubmittedObj,
	columns: getColumns,
});
export default connect(mapStateToProps, {
	setActiveTabCommodity,
	setActiveTabAccountId,
})(Listing);
