import React, { useEffect } from "react";
import { Breadcrumbs, ButtonPrimary, ContentWrap } from "elements";
import Listing from "./listing";
import "./index.scss";
import TableLoader from "utils/table/tableLoader";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { getListPermission, getAddPermission, getLoading } from "store/selectors/croptimalsSelectors";
import { fetchDropdownData, setLoaderCroptimals, setRenderTypeCroptimals } from "store/actions";
import { errorMessage } from "utils/errorMessage";
import { getRenderType } from "store/selectors/croptimalsSelectors";
import AddCroptimals from "./add";

const Croptimals = ({
	loading,
	fetchDropdownData,
	setLoaderCroptimals,
	setRenderTypeCroptimals,
	renderType,
	listPermission,
	addPermission,
}) => {
	useEffect(() => {
		setLoaderCroptimals();
		const successHandler = () => {
			setLoaderCroptimals();
		};
		const errorHandler = (e) => {
			errorMessage(e);
			setLoaderCroptimals();
		};
		fetchDropdownData(successHandler, errorHandler);
	}, [fetchDropdownData, setLoaderCroptimals]);

	return (
		<ContentWrap>
			<div className="d-flex align-items-center mb-3">
			<Breadcrumbs
					className="forecast-breadcrumb"
					keyValue={renderType === 0 ? "nutrientTracking" : "nutrientTrackingCroptimalsAdd"}
					showLabel={renderType === 0 ? true : false}
					label={renderType === 0 ? "Croptimals" : ""}
				/>
				{renderType === 0 && addPermission && (
					<ButtonPrimary onClick={() => setRenderTypeCroptimals(1)}>
						<i className="icon icon-plus mr-2" />
						Add Croptimals
					</ButtonPrimary>
				)}
			</div>

			<div className="position-relative" style={{ paddingBottom: !listPermission ? 20 : 0 }}>
				{renderType === 0 ? listPermission && <Listing /> : addPermission && <AddCroptimals />}
				{loading && (
					<div className="table-loader-wrapper">
						<TableLoader />
					</div>
				)}
			</div>
		</ContentWrap>
	);
};

const mapStateToProps = createStructuredSelector({
	loading: getLoading,
	renderType: getRenderType,
	listPermission: getListPermission,
	addPermission: getAddPermission,
});

export default connect(mapStateToProps, { fetchDropdownData, setLoaderCroptimals, setRenderTypeCroptimals })(Croptimals);
