import { NotificationManager } from "react-notifications";
import i18next from "i18next";
import { errorCodes } from "../constant/errorCodes";

export const errorMessage = (event, customMessage) => {
	let code = null;
	let messageList = null;

	switch (typeof event) {
		case "number":
		case "string":
			code = event;
			break;
		case "object":
			event && event.result && !event.result.responseValue && (messageList = { ...event });

			code = event && event.error && event.code ? event.code : null;
			break;
		default:
			const error = event.error || event.result;
			code = error && error.code;
	}

	const delay = 5000;
	if (messageList && !code) {
		return NotificationManager.error(customMessage ? customMessage(event.result) : i18next.t("error.something_went_wrong"), "", delay);
	} else if (code || code === 0) {
		if (code === 116) {
			let errorMessages = "";
			errorMessages += Object.keys(event.error)
				.map(function (key) {
					if (key === "cleaning_date" || key === "date" || key === "set_up_date") {
						return i18next.t("error.date_must_be_after_equal");
					} 
					else if(key === "expected_harvesting_date"){
						return i18next.t("error.expected_harvesting_date_must_after_or_eqaul");
					}else if(key === "date_of_delivery"){
						return i18next.t("error.delivery_must_be_after_or_equal_request_date");
					}else if(key === "media_usage_date"){
						return i18next.t("error.only_current_or_future_date_can_be_edited");
					}else if(key === "assignee_id"){
						return i18next.t("error.the_selected_assignee_id_invalid");
					}else if(key === "expected_transplant_date"){
						return i18next.t("error.expected_transplant_date_should_not_past_date");
					}else if(key === "log_instance"){
						return i18next.t("error.the_log_instance_field_is_required");
					}else if(key === "inventory.*.required_cases"){
						return i18next.t("error.required_cases_higher_than_available");
					}
					else {
						let singleErrorMessage = "";
						singleErrorMessage += event.error[key]
							.map(function (value, index) {
								return value;
							})
							.join("");
						return singleErrorMessage;
					}
				})
				.join("\n");
			// return NotificationManager.error(errorMessages ? errorMessages : "Something went wrong!", "", 40000);
			return NotificationManager.error(errorMessages ? errorMessages : i18next.t("error.something_went_wrong"), "", 4000);
		} else if (code === 191 || code === 237) {
			const errorMessages = event.message;
			return NotificationManager.error(errorMessages ? errorMessages : i18next.t("error.something_went_wrong"), "", delay);
		}
		const message = errorCodes.filter((i) => i.code === code);
		return NotificationManager.error(message[0] ? i18next.t(message[0].message) : i18next.t("error.something_went_wrong"), "", delay);
	}
	NotificationManager.error(i18next.t("error.something_went_wrong"), "", delay);
};
