import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";
import { reduxForm, reset } from "redux-form";
import { map } from "lodash";
import LocationView from "./locationView";
import LocationManage from "./locationManage";
import LocationSort from "./locationSort";
import { errorMessage } from "../../../utils/errorMessage";
import { successMessage } from "../../../utils/successMessage";
import useModal from "../../../utils/useModal";
import { fetchUserRoles, toggleMainLoader, fetchUsers } from "../../../store/actions";
import { createLocation, updateLocation } from "../../../store/actions/locationActions";
import { fetchMiniProductsList } from "../../../store/actions/productActions";
import ManageStatusTable from "./manageStatusTable";

export const LocationForm = ({
	locationDetails = {},
	fetchMiniProductsList,
	productsMiniList,
	userRoles,
	fetchUserRoles,
	userList,
	fetchUsers,
	createLocation,
	updateLocation,
	handleSubmit,
	toggleMainLoader,
	handleEditPanel,
	form,
	reset,
	doRefetch,
	skuPriorityList,
	customerPriorityList,
	updatePermission,
}) => {
	const classPrefix = "bright-farms";
	const [isOpen, modalType, , closeModal, modalProp] = useModal("locations");
	const [fetchingUsers, setFetchingUsers] = useState(false);
	const [isLoading, setisLoading] = useState(false);
	const [btnTitle, setbtnTitle] = useState("Save");
	const [searchUserQuery, setSearchUserQuery] = useState({
		total: 0,
		filter: [],
		page: 1,
		per_page: 10,
		search: "",
		sort_by: "id",
		sort_type: "asc",
	});
	const [userIDList, setUserIDList] = useState([]);

	const submitLocation = (values) => {
		if (!isLoading) {
			setisLoading(true);
			setbtnTitle("Saving...");
			toggleMainLoader(true);
			const successHandler = (event) => {
				setisLoading(false);
				setbtnTitle("Save");
				doRefetch();
				successMessage(event);
				reset(form);
				closeModal();
			};
			const errorHandler = (event) => {
				setisLoading(false);
				setbtnTitle("Save");
				errorMessage(event);
			};

			const body = {
				...values,
				user_ids: userIDList,
				drum_size: map(values.drum_size, "value") || [],
				germination_chambers: map(values.germination_chambers, "value") || [],
				pond: map(values.pond, "value") || [],
				ponds: 10,
				//pond: [],
				//product_varieties: map(values.product_varieties, "value") || [],
			};
			modalType === 1 && modalProp
				? updateLocation(modalProp, body, successHandler, errorHandler)
				: createLocation(body, successHandler, errorHandler);
		}
	};

	const doSetTotalValue = () => {
		setSearchUserQuery({
			...searchUserQuery,
			total: (userList && userList.meta && userList.meta.total) || 0,
		});
	};

	// For Pagination
	useEffect(doSetTotalValue, [userList]);

	// EDIT LOCATION : To check those users who are already selected
	useEffect(() => {
		if (locationDetails) {
			setUserIDList(map(locationDetails.user_list, "id"));
		}
	}, [locationDetails]);

	// GET LOCATION USERS
	const doSearch = (q) => {
		setSearchUserQuery(q);
		doFetchUsers(q);
	};
	const doFetchUsers = (query = searchUserQuery) => {
		setFetchingUsers(true);
		const handleSuccess = () => setFetchingUsers(false);
		const handleError = () => setFetchingUsers(false);
		fetchUsers(query, handleSuccess, handleError);
	};

	// Initial Load [ADD|EDIT]
	// useEffect(() => {
	// 	if (modalType === 1 || modalType === 0) {
	// 		doFetchUsers();
	// 		fetchMiniProductsList();
	// 		fetchUserRoles();
	// 	}
	// }, []);
	useEffect(doFetchUsers, []);
	useEffect(() => {
		fetchMiniProductsList();
		fetchUserRoles();
	}, [fetchMiniProductsList, fetchUserRoles]);

	return (
		<Modal size="lg" show={isOpen} onHide={closeModal} className="modal-sidepanel">
			<Modal.Header closeButton>
				<Modal.Title className="modal-title">
					{modalType === 1
						? "Edit Location"
						: modalType === 2
						? "View Location"
						: modalType === 3
						? "Manage SKU Priority"
						: modalType === 4
						? "Manage Customer Priority"
						: modalType === 5
						? "Manage SKU Status"
						: modalType === 6
						? "Manage Product Variety Status"
						: "Add Location"}
				</Modal.Title>
				{modalType === 2 && (
					<button type="button" className={`${classPrefix}-modal-close-button`} onClick={closeModal}>
						<i className="icon icon-close" />
					</button>
				)}
			</Modal.Header>
			<Modal.Body>
				<>
					{modalType === 1 || modalType === 0 ? (
						<LocationManage
							fetchingUsers={fetchingUsers}
							searchUserQuery={searchUserQuery}
							doSearch={doSearch}
							productDropdownList={productsMiniList}
							userRoles={userRoles}
							userIDList={userIDList}
							setUserIDList={setUserIDList}
							usersList={userList}
							handleSubmit={handleSubmit}
							submitLocation={submitLocation}
							closeModal={closeModal}
							btnTitle={btnTitle}
							isLoading={isLoading}
						/>
					) : modalType === 3 || modalType === 4 ? (
						<LocationSort
							details={modalType === 3 ? skuPriorityList : customerPriorityList}
							closeModal={closeModal}
							locationSlug={modalProp}
							doRefetch={doRefetch}
							modalType={modalType}
						/>
					) : modalType === 5 || modalType === 6 ? (
						<>
							<ManageStatusTable modalType={modalType} modalProp={modalProp} />
						</>
					) : (
						<LocationView
							updatePermission={updatePermission}
							details={locationDetails}
							closeModal={closeModal}
							handleEditPanel={handleEditPanel}
						/>
					)}
				</>
			</Modal.Body>
		</Modal>
	);
};

const mapStateToProps = (state) => ({
	locationDetails: state.Location.locationDetails,
	skuPriorityList: state.Location.skuPriority ? state.Location.skuPriority : [],
	customerPriorityList: state.Location.customerPriority ? state.Location.customerPriority : [],
	initialValues: {
		...state.Location.locationDetails,
		drum_size:
			map(state.Location.locationDetails.drum_size, (o) => ({
				label: o.value,
				value: o.value,
				can_delete: o.can_delete,
			})) || [],
		germination_chambers:
			map(state.Location.locationDetails.germination_chambers, (o) => ({
				label: o.value,
				value: o.value,
				can_delete: o.can_delete,
			})) || [],
		pond:
			map(state.Location.locationDetails.pond, (o) => ({
				label: o.value,
				value: o.value,
				can_delete: o.can_delete,
			})) || [],
		product_varieties:
			map(state.Location.locationDetails.product_varieties, (o) => ({
				label: o.product_variety,
				value: o.slug,
			})) || [],
		user_ids: map(state.Location.locationDetails.user_list, "id") || [],
	},
	userRoles: state.User.userRoles,
	userList: state.User.userList,
	productsMiniList: state.Product.productsMiniList,
});
const mapDispatchToProps = {
	toggleMainLoader,
	reset,
	fetchMiniProductsList,
	fetchUserRoles,
	fetchUsers,
	createLocation,
	updateLocation,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(
	reduxForm({
		form: "location-details-form",
		enableReinitialize: true,
	})(LocationForm)
);
