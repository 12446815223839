import React, { useState } from "react";
import { Button, Row, Col, Form } from "react-bootstrap";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";
import { createStructuredSelector } from "reselect";
import {
	getFormToDate,
	getLocationList,
	getFormFromDate,
	getThirtyWeeks,
	getSelectedWeekDates,
	getNutrientList,
	getRenderType,
	// getNutrientList,
	// getProductVarietyList,
	// getRenderType,
	// getSeasonList,
} from "store/selectors/waterSourceSelectors";
import { fetchDataWSR, setColumnsWSR, setDataWSR, setLoaderWSR } from "store/actions";

import FormGroups from "utils/formGroup";
import { wsrFormFields } from "./formHelper";

import moment from "moment";
import { errorMessage } from "utils/errorMessage";
import Util from "utils/Util";

const WaterSourceForm = ({
	handleSubmit,
	reset,
	submitting,
	pristine,
	locationList,
	fromDate,
	toDate,
	maxDate,
	selectedWeeks,
	renderType,
	nutrientList,
	setColumnsWSR,
	setDataWSR,
	setLoaderWSR,
	fetchDataWSR,
}) => {
	const [startDate, setStartDate] = useState(null);

	const [endDate, setEndDate] = useState(null);
	const onSubmit = (values) => {
		if (renderType === 0) {
			setLoaderWSR();
			const submitData = {
				location_slug: values.location_slug.value,
				start_date: Util.getMDYFormatUtcDate(startDate),
				end_date: Util.getMDYFormatUtcDate(endDate),
			};
			const successHandler = () => {
				setLoaderWSR();
				setColumnsWSR(nutrientList);
			};
			const errorHandler = (e) => {
				errorMessage(e);
				setLoaderWSR();
			};
			fetchDataWSR(submitData, successHandler, errorHandler);
		} else {
			const data = {};
			// eslint-disable-next-line array-callback-return
			selectedWeeks.map((item) => {
				data[item] = nutrientList.map((p_item) => ({
					name: p_item.nutrient,
					slug: p_item.slug,
					value: "",
				}));
			});

			// setProductVarietyMapping(productKeyMapping);
			setColumnsWSR(nutrientList);
			setDataWSR(data);
		}
	};

	const getOptions = (name) => {
		switch (name) {
			case "location_slug":
				return locationList;

			default:
				return [];
		}
	};

	const onDateChange = (dates) => {
		const [start, end] = dates;

		if (dates && dates[0]) {
			const first = new Date(moment(start).startOf("week"));
			const last = end ? new Date(moment(end).endOf("week")) : null;

			if (startDate && endDate) {
				setStartDate(first);
				setEndDate(null);
			} else {
				setStartDate(first);
				last && setEndDate(last);
			}
		} else {
			setStartDate(null);
			setEndDate(null);
		}
	};

	const getFormFieldHtml = (formfields) => {
		let formFieldHtml = [];

		formFieldHtml = formfields.map((field, idx) => {
			return (
				<React.Fragment key={idx}>
					<Col className="croptimals-form-column">
						<Form.Group
							// className={field.name === "exclude_wastage" && "mt-5"}
							controlId={`formControl ${field.name}`}>
							{field.name === "week" ? (
								<Field
									name={field.name}
									type={field.type}
									label={field.label}
									placeholder={field.placeholder}
									component={FormGroups}
									startDate={startDate}
									endDate={endDate}
									onChange={onDateChange}
									validate={field.validate}
									dateFormat={"MM-DD-YYYY"}
									pickerTypeSelector={0}
									maxDate={fromDate && !toDate && maxDate}
									onBlur={(event) => event.preventDefault()}
								/>
							) : (
								<Field
									name={field.name}
									type={field.type}
									label={field.label}
									placeholder={field.placeholder}
									component={FormGroups}
									validate={field.validate}
									options={getOptions(field.name)}
									startDate={startDate}
									endDate={endDate}
									selectMenuHeight={200}
								/>
							)}
						</Form.Group>
					</Col>
				</React.Fragment>
			);
		});

		return (
			<Row className="wsr-form-row">
				{formFieldHtml}
				<Col className="form-submit-col-wsr">
					<Button type="submit" disabled={submitting} className="apply-btn">
						Apply
					</Button>
					<Button
						className="reset-btn"
						disabled={pristine || submitting}
						onClick={() => {
							setStartDate(null);
							setEndDate(null);
							reset();
						}}>
						<i className="icon icon-reset mr-2" />
					</Button>
				</Col>
			</Row>
		);
	};

	return (
		<div className="wsr-form-wrapper">
			<Form onSubmit={handleSubmit(onSubmit)} className="forecastForm">
				{getFormFieldHtml(wsrFormFields)}
			</Form>
		</div>
	);
};

const mapStateToProps = createStructuredSelector({
	locationList: getLocationList,
	fromDate: getFormFromDate,
	toDate: getFormToDate,
	maxDate: getThirtyWeeks,
	selectedWeeks: getSelectedWeekDates,
	nutrientList: getNutrientList,
	// pvList: getProductVarietyList,
	// seasonList: getSeasonList,
	renderType: getRenderType,
});

export default connect(mapStateToProps, {
	setLoaderWSR,
	setColumnsWSR,
	setDataWSR,
	fetchDataWSR,
})(reduxForm({ form: "waterSourceForm", enableReinitialize: true })(WaterSourceForm));
