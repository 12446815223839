export const menuItems = [
	{
		label: "label.dashboard",
		url: "/dashboard",
	},
	{
		label: "label.operations",
		url: "/operations",
		submenu: [
			{
				label: "label.seeding",
				url: "/seeding-and-germination",
				permission_key: "seeding",
			},

			{
				label: "label.germination",
				url: "/germination",
				permission_key: "germination",
			},
			{
				label: "label.transplant_and_growing",
				url: "/transplant",
				permission_key: "transplant",
			},
			{
				label: "label.harvesting",
				url: "/harvesting",
				permission_key: "harvesting",
			},
			{
				label: "label.orders_and_packaging",
				url: "/orders-and-packaging",
				permission_key: "orders_and_packaging",
			},

			{
				label: "Nutrient Tracking",

				submenu: [
					{
						label: "Lab Results",
						url: "/lab-results",
						permission_key: "lab_results",
					},
					{
						label: "Croptimals",
						url: "/croptimals",
						permission_key: "croptimals",
					},
					{
						label: "Water Source Results",
						url: "/water-source-results",
						permission_key: "water_source_results",
					},
					{
						label: "Fertilizers",
						url: "/fertilizers",
						permission_key: "fertilizers",
					},
					{
						label: "Dosing Calculator",
						url: "/dosing-calculator",
						permission_key: "dosing_calculator",
					},
					{
						label: "To Add List",
						url: "/to-add-list",
						permission_key: "to_add_list",
					},
				],
			},
			{
				label: "Cross Supply",

				submenu: [
					{
						label: "Transfers",
						url: "/cross-supply",
						permission_key: "cross_supply",
					},
					{
						label: "Shipping Details",
						url: "/cross-supply-shipping",
						permission_key: "cross_supply",
					},
				],
			},
		],
	},
	{
		label: "Forecast",
		url: "/forecast",
		submenu: [
			{
				label: "Sales Forecast",
				permission_key: "production_forecast",
				submenu: [
					{
						label: "Daily Forecast",
						permission_key: "daily_forecast",
						url: "/daily-forecast",
					},
					{
						label: "Weekly Forecast",
						permission_key: "weekly_forecast",
						url: "/weekly-forecast",
					},
				],
			},
			{
				label: "Production Forecast",
				permission_key: "production_forecast",
				submenu: [
					{
						label: "Forecast",
						permission_key: "production_forecast",

						url: "/forecast",
					},
					{
						label: "Harvest Forecast Pounds",
						permission_key: "harvest_forecast_pounds",
						url: "/harvest-forecast-pounds",
					},
					{
						label: "Production Forecast Cases",
						permission_key: "production_forecast_cases",
						url: "/production-forecast-cases",
					},
				],
			},
			{
				label: "Production Vs Sales",
				submenu: [
					{
						label: "By Sku",
						permission_key: "production_vs_sales_sku",

						url: "/production-vs-sales-by-sku",
					},
					{
						label: "By Product Variety",
						permission_key: "production_vs_sales_variety",
						url: "/production-vs-sales-by-variety",
					},
				],
			},
			{
				label: "Production Vs Actual Sales",
				submenu: [
					{
						label: "By Sku",
						permission_key: "production_vs_sales_sku",

						url: "/production-vs-actual-sales-by-sku",
					},
					{
						label: "By Product Variety",
						permission_key: "production_vs_sales_variety",
						url: "/production-vs-actual-sales-by-variety",
					},
				],
			},
			{
				label: "Lane Calculator",
				submenu: [
					{
						label: "Actual Production Vs Sales",
						permission_key: "lane_calculator",
						url: "/lane-calculator",
					},
					{
						label: "Case to lane",
						permission_key: "lane_calculator",
						url: "/case-to-lane",
					},
					{
						label: "Lane to Case",
						permission_key: "lane_calculator",
						url: "/lane-to-case",
					},
				],
			},
		],
	},

	{
		label: "Climate",
		url: "/climate",
		submenu: [
			{
				label: "Climate Data Table",
				url: "/climate-table",
				permission_key: "climate-table",
			},
			{
				label: "Climate Reports",
				url: "/climate",
				permission_key: "climate-reports",
			},
			{
				label: "Watchwire",
				url: "/watch_wire",
				permission_key: "watchwire",
			},
		],
	},

	{
		label: "Reports",
		url: "/reports",
		permission_key: "reports",
		// submenu: [
		// 	{
		// 		label: "Timesheet",
		// 		url: "/dashboard",
		// 	},
		// ],
	},
	{
		label: "Settings & Configurations",
		url: "/settings",
		submenu: [
			{
				label: "User Management",
				url: "/users",
				permission_key: "user",
			},
			{
				label: "Locations",
				url: "/locations",
				permission_key: "locations",
			},
			{
				label: "Styrofoams",
				url: "/styrofoams",
				permission_key: "styrofoams",
			},
			{
				label: "Seeds",
				url: "/seeds",
				permission_key: "seeds",
			},
			{
				label: "Greens",
				url: "/greens",
				permission_key: "greens",
			},
			{
				label: "Product Variety",
				url: "/product-varieties",
				permission_key: "product_variety",
			},
			{
				label: "Stock Keeping Unit",
				url: "/sku",
				permission_key: "stock",
			},
			{
				label: "Customer Management",
				url: "/customers",
				permission_key: "customer",
			},
			{
				label: "KPI Target",
				url: "/kpi",
				permission_key: "kpi",
			},
			{
				label: "Access Privileges",
				url: "/access-privileges",
				permission_key: "access",
			},
		],
	},
];
