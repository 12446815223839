import React from "react";
import { Row, Col } from "react-bootstrap";
import { ListingBtn } from "elements";
import Util from "../../utils/Util";
import i18next from "i18next";

export const getBestBuyDays =(value)=>{
	if(value === 1){
		return "12 Days";
	}else if(value === 2){
		return "15 Days";
	}else if(value === 3){
		return "16 Days";
	}
}
const RenderViewTable = ({ form, data, flagsArray, addItem, number, onClickEditHandler, onClickDeleteHandle, status }) => {
	const tableHead = () => {
		return (
			<Row className="view-table-head-row">
				{form.map((column, index) => {
					return (
						<Col key={index} className="view-table-body-col">
							<span style={{ color: "#000000" }}>{i18next.t(column.label)}</span>
						</Col>
					);
				})}
			</Row>
		);
	};

	const tableBody = () => {
		return (
			<div className="view-table-body">
				{data.map((dataItem, d_ind) => (
					<Row key={d_ind} className="view-table-body-row">
						{form.map((formItem, f_ind) => (
							<Col key={f_ind} className="view-table-body-col">
								{getColumnItem(formItem, dataItem)}
							</Col>
						))}
					</Row>
				))}
			</div>
		);
	};

	const getColumnItem = (formItem, dataItem) => {
		return (
			<span>
				{formItem.name === "action" ? (
					<>
						<ListingBtn
							tooltipFontSize={11}
							tooltipText={i18next.t("tooltip.edit")}
							type="span"
							iconClass="icon icon-edit"
							onClick={() => onClickEditHandler(dataItem)}
							btnClass="btn btn-cell btn-light btn-sm "
						/>
						{status !== "Completed" && (
							<ListingBtn
								tooltipText={i18next.t("tooltip.delete")}
								btnClass="btn btn-cell btn-light btn-sm ml-2"
								iconClass="icon icon-trash"
								onClick={() => onClickDeleteHandle(dataItem)}
							/>
						)}
					</>
				) : formItem.name === "packaging_start_date" || formItem.name === "packaging_end_date" ? (
					<span>{Util.getFormattedDateAndTime(dataItem[formItem.name])}</span>
				) : formItem.name === "best_buy_days" ? (
					<span>{dataItem[formItem.name] ? getBestBuyDays(dataItem[formItem.name]) : "-"}</span>
				) : formItem.name === "best_buy_date" ? (
					<span>{Util.checkValidDateString(dataItem[formItem.name])}</span>
				) : dataItem[formItem.name] || dataItem[formItem.name] === 0 ? (
					dataItem[formItem.name]
				) : (
					"-"
				)}
			</span>
		);
	};

	const addButton = () => (
		<div className="view-table-btn-row">
			<button className="view-table-btn" type="button" onClick={addItem}>
				+ {i18next.t("label.Add")}
			</button>
		</div>
	);
	return (
		<div className="view-table">
			{tableHead()}
			{tableBody()}
			{addItem && addButton()}
		</div>
	);
};
export default RenderViewTable;
