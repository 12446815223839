import { required, requiredDateRange } from "constant/formValidators";

export const casesByCustomerForm = [
	{
		label: "label.location_required",
		name: "location_slug",
		type: "select-react",
		placeholder: "label.select_location",
		validate: [required],
	},
	{
		label: "label.date_from_to_req",
		name: "date",
		type: "date-range-picker",
		placeholder: "label.enter_date",
		// validate: [requiredDateObj],
		validate: [requiredDateRange],

		// minDate: new Date(),
	},
	{
		label: "label.sku_req",
		name: "sku_slug",
		type: "select-react",
		placeholder: "label.select_sku",
		validate: [required],
	},
];
