import React, { useEffect, useState, useRef, useContext, createContext, useMemo } from "react";
import { useParams } from "react-router-dom";
import { fetchLocationDetails, fetchHarvestForecastV2, updateHarvestForecast } from "store/actions";
import { errorMessage } from "utils/errorMessage";
import { connect } from "react-redux";
import { get } from "lodash";
import Sticky from "react-sticky-el";

import "./index.scss";
import { ContentWrap } from "elements";
import moment from "moment";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { Button, Col, Container, Row } from "react-bootstrap";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import DayCell from "./dayCell";
import { successMessage } from "../../utils/successMessage";
import DataGrid from "react-data-grid";
import { TextEditor } from "react-data-grid";
import growerVarieties from "../harvestForecastV2/farmVarieties";

const defaultParsePaste = (str) => str.split(/\r\n|\n|\r/).map((row) => row.split("\t"));

const FilterContext = createContext(undefined);
function FilterRenderer({ column, children }) {
	const filters = useContext(FilterContext);
	return (
		<>
			<div className="flex flex-col">
				<div style={{ height: 30, lineHeight: "30px" }}>{column.name}</div>
				<div style={{ height: 100 }}>{children(filters)}</div>
			</div>
		</>
	);
}

const textEditor = ({ row, column, onRowChange, onClose }) => {
	// function autoFocusAndSelect(input) {
	// 	input.focus();
	// 	input.select();
	// }
	return (
		<input
			className={`rdg-text-editor`}
			// ref={autoFocusAndSelect}
			value={row[column.key]}
			onChange={(event) => onRowChange({ ...row, [column.key]: event.target.value })}
			onBlur={() => onClose(true, false)}
		/>
	);
};

const CustomTextField = styled(TextField)({
	"label + &": { borderRadius: 8 },
	"& .MuiInputBase-input": {
		borderRadius: 8,
	},
	"& label": {
		color: "black",
	},
	"& label.Mui-focused": {
		color: "#006241",
	},
	"& .MuiInput-underline:after": {
		borderBottomColor: "#006241",
		borderRadius: 8,
	},
	"& .MuiOutlinedInput-root": {
		borderRadius: 8,
		width: "100%",
		"& fieldset": {
			borderColor: "#cdcdcd",
			borderRadius: 8,
			fontWeight: "bold",
		},
		"&:hover fieldset": {
			borderColor: "#000",
			borderRadius: 14,
			fontWeight: "bold",
		},
		"&.Mui-focused fieldset": {
			borderColor: "#006241",
			borderRadius: 8,
		},
	},
});

// Names from PHP
// $greenNamingMap =[
//     'Newton Basil'=>'Newton-Basil', #basil
//     'Thai Basil'=>'Thai-Basil',      #basil
//     'Lemon Basil'=>'Lemon-Basil',    #basil
//     'Basil'=>'Basil', #basil
//     'Kale'=>'Kale',  #Retired Kale
//     'Arugula'=>'Arugula', #Arugula -> Astro
//     'Wild Arugula' => 'Wild Arugula',#Wild Arugula -> Bellezia -> Esmee
//     'Bellezia' => 'Wild Arugula',
//     'Paris Island'=>'Romaine', #Romaine -> Paris Choi
//     'Romaine'=>'Romaine',
//     'Pac Choi'=>'Pac Choi', #Pac Choi -> Pac Choi
//     'Spinach'=>'Spinach', #spinach -> Bellizia is an arugula
//     'Lola' => 'Red Leaf',
//     'Yukina' => 'Yukina',
//     'Crunch' => 'Crunch',
//     'Cristabel' => 'Crunch',
//     'Cristobel'=>'Crunch', #Crunch -> Cristobel, Finstar
//     'Finstar' => 'Crunch',
//     'Sunny' => 'Crunch',
//     'Annapolis' => 'Red Leaf',
//     'Blend'=> 'Blend', #Blend product -> Five Star
//     'Lettuce Blend' => 'Blend',
//     'Azyrka' => 'Red Leaf',
//     'Azirka'=>'Red Leaf', #Red Leaf -> Azirka, Litzka
//     'Red Giant'=>'Mustard', # Mustard -> Red Giant
//     'Mustard' => 'Mustard',
//     'Beet'=>'Beet', #Beet -> Multiple
//     'Bellezia Arugula'=>'Wild Arugula', #Wild Arugula -> Bellezia -> Esmee
//     'Butter'=>'Butter', #Butter -> budgee, esmari
//     'Butter '=>'Butter', #Butter -> budgee, esmari
//     'Butter Crunch' => 'Butter', #Butter -> budgee, esmari
//     'Green Reef'=>'Reef', #Reef -> Coastline, Green Reef
//     'Rutgers'=>'Nutrigreens', #Nutrigreens -> Rutgers- custom KF Plus -Custom Nar
//     'Coastline'=>'Reef-Coastline', #Reef -> Coastline, Green Reef
//     'Chard'=>'Chard', #Chard -> Peppermint Chard, Charbell
//     'Red Crunch '=>'Red Leaf', #Red Leaf -> Azirka, Litza
//     'Red Crunch'=>'Red Leaf', #Red Leaf -> Azirka, Litza,
//     'Litska' => 'Red Leaf',
//     "Scarlet Frill / Red Giant" => "Mustard",
//     "SFrill" => "Mustard",
//     "Oak Leaf" => "Oak Leaf",
//     "Red Leaf" => "Red Leaf",
//     "Mizuna" => "Mizuna",
//     "Spice" => "Spice",
//     "Blank" => "Blank",
//     "Mixed" => "Mixed"
// ];

function handleCopy({ sourceRow, sourceColumnKey }) {
	console.log(sourceRow);
	if (window.isSecureContext) {
		navigator.clipboard.writeText(sourceRow[sourceColumnKey]);
	}
}

function handlePaste({ sourceColumnKey, sourceRow, targetColumnKey, targetRow }) {
	return { ...targetRow, [targetColumnKey]: sourceRow[sourceColumnKey] };
}

const GrowerToolsTable = ({ fetchLocationDetails, fetchHarvestForecastV2, updateHarvestForecast }) => {
	let location = useLocation();
	const urlParams = new URLSearchParams(location.search);
	const [greenhouse, setGreenhouse] = useState([]);
	const [currentYear, setCurrentYear] = useState(urlParams.get("year") ? urlParams.get("year") : moment().year());
	const [currentMonth, setCurrentMonth] = useState(urlParams.get("month") ? moment(urlParams.get("month")) : moment());
	const [currentWeek, setCurrentWeek] = useState(urlParams.get("week") ? moment(urlParams.get("week")) : moment().isoWeek());
	const [shouldRefresh, setShouldRefresh] = useState(true);
	const [forecastData, setForecastData] = useState(null);
	const [save, setSave] = useState([]);
	const [luna, setLuna] = useState([]);
	const [loading, setLoading] = useState(false);
	const [rows, setRows] = useState([]);
	const [selectedRows, setSelectedRows] = useState(() => new Set());

	const [selectedCell, setSelectedCell] = useState();
	const [selectedDataset, setSelectedDataset] = useState();
	const gridRef = useRef([]);

	const [filters, setFilters] = useState({
		date: "",
	});

	function scrollToRow() {
		const today = new Date().toISOString().split("T")[0];

		const index = rows[Object.keys(rows)[0]].findIndex((obj) => obj.date === today);
		gridRef.current.forEach((e) => {
			e.scrollToRow(index);
		});
	}

	const columns = [
		// {
		// 	key: "id",
		// 	name: "ID",
		// 	width: 80,
		// 	resizable: true,
		// 	frozen: true,
		// },
		{
			key: "date",
			name: "Date",
			width: 200,
			resizable: true,
			frozen: true,
			formatter: ({ row, column }) => {
				// Custom logic to render the cell's content
				return <span>{moment(row[column["key"]], "YYYY-MM-DD").format("ddd MMM DD, YYYY")}</span>;
			},
			// headerRenderer: (p) => (
			// 	<FilterRenderer {...p}>
			// 		{(filters) => (
			// 			<input
			// 				style={{ height: 40 }}
			// 				value={filters?.date}
			// 				onChange={(e) => {
			// 					console.log(e.target.value);
			// 					setFilters({
			// 						...filters,
			// 						date: e.target.value,
			// 					});
			// 				}}
			// 			/>
			// 		)}
			// 	</FilterRenderer>
			// ),
		},
		{
			key: "oz_per_board",
			name: "Ounces per Board / Pounds per Gutter",
			width: 200,
			resizable: true,

			editable: (row) => {
				// if (moment(row["date"], "YYYY-MM-DD").format("d") === "0") {
				// 	return false;
				// }
				return true;
			},
			editor: TextEditor,
		},
		{
			key: "number_of_boards",
			name: "Number of Boards / Number of Gutters",
			width: 200,
			resizable: true,
			editable: (row) => {
				return true;
			},
			editor: TextEditor,
		},
	];

	const handleGridRowsUpdated = ({ fromRow, toRow, updated }) => {
		const updatedRows = [...rows];
		for (let i = fromRow; i <= toRow; i++) {
			updatedRows[i] = { ...updatedRows[i], ...updated };
		}
		setRows(updatedRows);
	};

	const handleDragHandleDoubleClick = (e, { idx }) => {
		const selectedCell = rows[idx];
		const newValue = selectedCell[columns[idx].key];
		const updatedRows = rows.map((row, index) => (index > idx ? { ...row, [columns[idx].key]: newValue } : row));
		setRows(updatedRows);
	};

	function handleFill({ columnKey, sourceRow, targetRows }) {
		return targetRows.map((row) => {
			if (moment(row["date"], "YYYY-MM-DD").format("d") === "0") {
				return { ...row };
			}
			return {
				...row,
				[columnKey]: sourceRow[columnKey],
			};
		});
	}

	let params = useParams();

	useEffect(() => {
		console.log("forecastUpdated");
	}, [forecastData]);

	// useEffect(() => {
	// 	scrollToRow();
	// }, [rows]);

	useEffect(() => {
		const externalPaste = (e) => {
			e.preventDefault();
			console.log(selectedCell);
			if (!selectedCell) {
				return;
			}

			let newRows = [];
			const pasteData = defaultParsePaste(e.clipboardData.getData("text/plain"));

			console.log(pasteData);
			newRows = rows[selectedDataset.varietyCode];
			var savable = [...save];

			pasteData.forEach((row, index) => {
				if (selectedCell.idx == 1) {
					newRows[index + selectedCell.rowIdx]["oz_per_board"] = row[0];
				} else if (selectedCell.idx == 2) {
					newRows[index + selectedCell.rowIdx]["number_of_boards"] = row[0];
				}

				savable.push({
					date: moment(rows[selectedDataset.varietyCode][index + selectedCell.rowIdx].date, "YYYY-MM-DD").format(
						"MMMM DD, YYYY"
					),
					variety_code_from_grower: selectedDataset.varietyCode,
					forecast: selectedCell.idx == 1 ? "oz_per_board" : "number_of_boards",
					forecast_unit: selectedCell.idx == 1 ? "oz_per_board" : "number_of_boards",
					forecast_value: row[0],
					variety_name_from_grower: selectedDataset.growerName,
					variety_name_from_perf: selectedDataset.perfName,
					location_slug: greenhouse.slug,
					forecast_type: "daily",
				});
			});

			setSave(savable);

			const finalRows = { ...rows };
			finalRows[selectedDataset] = newRows;
			setRows(finalRows);
			// this.updateRows(topLeft.rowIdx, newRows);
		};

		document.addEventListener("paste", externalPaste);
		return () => document.removeEventListener("paste", externalPaste);
	}, [selectedCell, selectedDataset]);

	useEffect(() => {
		const handleBeforeUnload = (e) => {
			// You can check if the user has taken some action here
			// For example, check if a form is dirty or if specific data has been modified
			const isDirty = save.length > 0; // Replace this with your condition

			if (isDirty) {
				e.preventDefault();
				e.returnValue = "Are you sure you want to leave without saving?"; // This is the message that will be displayed in the confirmation dialog
			}
		};

		window.addEventListener("beforeunload", handleBeforeUnload);

		return () => {
			// Cleanup the event listener when the component unmounts
			window.removeEventListener("beforeunload", handleBeforeUnload);
		};
	}, [save]);

	useEffect(() => {
		const pullForecastData = async () => {
			setLoading(true);
			fetchHarvestForecastV2(
				{
					forecast_type: "daily",
					current_year: currentYear,
					current_month: currentMonth,
					current_week: currentWeek,
					location_id: greenhouse.slug,
					yearOnly: 1,
				},
				(e) => {
					const mappingTable = e.harvest.reduce((acc, obj) => {
						const varietyCode = obj.variety_code_from_grower;

						if (varietyCode && !acc[varietyCode]) {
							acc[varietyCode] = [];
						}

						if (varietyCode) {
							acc[varietyCode].push({
								location_id: obj.location_id,
								forecast: obj.forecast,
								date: obj.date,
								product_variety_id: obj.product_variety_id,
								forecast_value: obj.forecast_value,
								created_at: obj.created_at,
								updated_at: obj.updated_at,
								forecast_version: obj.forecast_version,
								most_current: obj.most_current,
								growing_lane_number: obj.growing_lane_number,
								forecast_unit: obj.forecast_unit,
								forecast_type: obj.forecast_type,
								change_reason: obj.change_reason,
								change_notes: obj.change_notes,
								growing_location_id: obj.growing_location_id,
								created_by_user_id: obj.created_by_user_id,
								variety_name_from_perf: obj.variety_name_from_perf,
								variety_name_from_grower: obj.variety_name_from_grower,
								variety_code_from_grower: obj.variety_code_from_grower,
							});
						}

						return acc;
					}, {});

					console.log("1", Object.keys(mappingTable));
					growerVarieties[greenhouse?.slug].forEach((a) => {
						console.log(a);
						if (!Object.keys(mappingTable).includes(a.varietyCode)) {
							mappingTable[a.varietyCode] = [{ variety_code_from_grower: a.varietyCode, date: "2023-01-01" }];
						}
					});

					const resultArray = {};
					const dataMap = {};

					const firstDayOfWeek = moment().year(currentYear).startOf("year").startOf("week");
					const lastDayOfWeek = moment(firstDayOfWeek).add(1, "year").endOf("week");

					const datesArray = {};

					// Start from firstDayOfWeek and increment by one day until we reach lastDayOfWeek
					let currentDay = moment(firstDayOfWeek);
					while (currentDay <= lastDayOfWeek) {
						const theDate = currentDay.clone().format("YYYY-MM-DD");
						datesArray[theDate] = { date: theDate }; // Clone the moment object to avoid modifying the original
						currentDay.add(1, "day"); // Increment by one day
					}

					// Now, datesArray contains all the dates from firstDayOfWeek to lastDayOfWeek
					console.log("2", datesArray);

					// Iterate through the input data
					Object.keys(mappingTable).forEach((variety) => {
						mappingTable[variety].forEach((item) => {
							const datesArrayCopy = JSON.parse(JSON.stringify(datesArray));
							if (!item.variety_code_from_grower) {
								return;
							}
							// console.log(item.variety_code_from_grower);
							// console.log(variety);
							const date = item.date.split(" ")[0]; // Extract the date portion
							if (date == "2023-01-01") {
								console.log(item);
							}
							const forecastType = item.forecast;

							// Create a unique key based on date
							const key = date;
							if (!dataMap[variety]) {
								dataMap[variety] = datesArrayCopy;
							}

							if (!dataMap[variety][key]) {
								// Initialize an object for this date if it doesn't exist
								// dataMap[variety][key] = {
								// 	date: date,
								// };
								return;
							}

							// Assign the appropriate value based on the forecast type
							if (forecastType === "oz_per_board") {
								dataMap[variety][key].oz_per_board = item.forecast_value;
							} else if (forecastType === "number_of_boards") {
								dataMap[variety][key].number_of_boards = item.forecast_value;
							}
							// console.log(dataMap[variety]);
						});

						for (const key in dataMap[variety]) {
							if (!resultArray[variety]) {
								resultArray[variety] = [];
							}
							resultArray[variety].push(dataMap[variety][key]);
						}
					});
					console.log(dataMap);
					console.log(resultArray);

					// Convert the map values into an array
					// for (const key in dataMap) {
					// 	resultArray.push(dataMap[key]);
					// }
					// console.log(resultArray);

					setRows(resultArray);
					setForecastData(mappingTable);
					setLuna(e.luna);
					setShouldRefresh(false);
					setLoading(false);
				},
				errorHandler
			);
		};

		if (greenhouse?.slug && currentYear && shouldRefresh) {
			pullForecastData();
		}
	}, [shouldRefresh, greenhouse, currentYear, currentMonth, currentWeek, fetchHarvestForecastV2]);

	useEffect(() => {
		fetchLocationDetails(params.slug, successHandler, errorHandler);
	}, [fetchLocationDetails, params]);

	const forecastChange = (object) => {
		console.log("Changing Data:", object);
		var savable = [...save];
		savable.push(object);
		setSave(savable);
	};

	const successHandler = (e) => {
		console.log(e.data);
		setGreenhouse(e.data);
	};
	function formatDate(date) {
		return date.format("ddd, MMM Do YYYY");
	}

	const errorHandler = (e) => {
		errorMessage(e);
	};

	const saveForecast = (e) => {
		const target = e.currentTarget;
		target.disabled = true;
		updateHarvestForecast(
			save,
			(e2) => {
				target.disabled = false;
				successMessage(e2);
				setShouldRefresh(true);
				setSave([]);
			},
			(e3) => {
				target.disabled = false;
				errorHandler(e3);
			}
		);
	};

	return (
		<ContentWrap>
			<div className="d-flex">
				<div style={{ flex: 1 }}>
					<div style={{ fontSize: 32, fontWeight: "bold" }}>{greenhouse.location_name}</div>
					<div style={{ fontSize: 16 }}>
						{greenhouse.city}, {greenhouse.state}
					</div>
				</div>
			</div>
			<div>
				<b>Date Filter</b>
				<input
					style={{ height: 40 }}
					value={filters?.date}
					onChange={(e) => {
						console.log(e.target.value);
						setFilters({
							...filters,
							date: e.target.value,
						});
					}}
				/>
			</div>
			<div onClick={scrollToRow}>GOTO TODAY</div>

			{greenhouse?.slug &&
				growerVarieties[greenhouse?.slug].map((v, ix) => {
					return (
						<div
							key={`${v.perfName} (${v.growerName} - ${v.varietyCode})`}
							style={{ paddingBottom: 24, marginBottom: 24 }}>
							<Row style={{ paddingTop: 24, paddingBottom: 12 }}>
								<div
									className="d-flex"
									style={{
										padding: 12,
										fwidth: "100%",
										borderTop: "1px solid #00624155",
										borderBottom: "none",
										textAlign: "center",
										flex: 1,
										flexDirection: "row",
										fontSize: 24,
									}}>
									<div style={{ width: "25%", fontSize: 32, fontWeight: "bold" }}>
										<div
											style={{
												border: "2px solid #006241",
												paddingLeft: 24,
												paddingRight: 24,
												borderRadius: 24,
												color: "#006241",
												cursor: "pointer",
											}}
											onClick={() => {
												setForecastData(null);
												setCurrentWeek(moment().year(currentYear).subtract(1, "year").isoWeek());
												setCurrentMonth(moment().year(currentYear).subtract(1, "year"));
												setCurrentYear(moment().year(currentYear).subtract(1, "year").year());
												setShouldRefresh(true);
											}}>
											{"<"}
										</div>
									</div>
									<div style={{ width: "50%" }}>
										<div style={{ fontSize: 32, fontWeight: "bold" }}>{`${currentYear}`}</div>
									</div>
									<div style={{ width: "25%", fontSize: 32, fontWeight: "bold" }}>
										<div
											style={{
												border: "2px solid #006241",
												paddingLeft: 24,
												paddingRight: 24,
												borderRadius: 24,
												color: "#006241",
												cursor: "pointer",
											}}
											onClick={() => {
												setForecastData(null);
												setCurrentWeek(moment().year(currentYear).add(1, "year").isoWeek());
												setCurrentYear(moment().year(currentYear).add(1, "year"));
												setCurrentYear(moment().year(currentYear).add(1, "year").year());

												setShouldRefresh(true);
											}}>
											{">"}
										</div>
									</div>
								</div>
							</Row>
							<Row
								style={{
									width: "100%",
									justifyContent: "space-evenly",
									alignItems: "center",
									paddingBottom: 24,
								}}>
								<Col>
									<h1 style={{ fontSize: 32, fontWeight: "bold", paddingTop: 0 }}>
										{v.perfName} ({v.growerName} - {v.varietyCode})
									</h1>
								</Col>
								<Col style={{ textAlign: "right" }}>
									<Button
										disabled={save.length == 0 ? true : false}
										onClick={(e) => {
											saveForecast(e);
										}}>
										Save
									</Button>
								</Col>
							</Row>
							{rows && rows[v.varietyCode] && (
								<FilterContext.Provider value={filters}>
									<DataGrid
										style={{ height: 1000, width: "620px" }}
										ref={(el) => (gridRef.current[ix] = el)}
										headerRowHeight={100}
										columns={columns}
										rows={rows[v.varietyCode].filter((r) => {
											return filters.date ? r.date.includes(filters.date) : true;
										})}
										rowKeyGetter={(row) => rows.id}
										className="fill-grid"
										enableCellSelect={true}
										onFill={handleFill}
										onRowsChange={(allRows, data) => {
											console.log(data);
											var type = data.column.key;
											var savable = [...save];

											data.indexes.map((idx) => {
												savable.push({
													date: moment(rows[v.varietyCode][idx].date, "YYYY-MM-DD").format(
														"MMMM DD, YYYY"
													),
													variety_code_from_grower: v.varietyCode,
													forecast: type,
													forecast_unit: type,
													forecast_value: allRows[idx][type] ? allRows[idx][type] : 0,
													variety_name_from_grower: v.growerName,
													variety_name_from_perf: v.perfName,
													location_slug: greenhouse.slug,
													forecast_type: "daily",
												});
											});
											setSave(savable);
											// console.log(allRows[index]);
											// console.log(type, index);
											// console.log(rows[v.varietyCode][index]);

											const newRows = { ...allRows };
											newRows[v.varietyCode] = allRows;
											setRows(newRows);
											console.log(selectedRows);
										}}
										// rowsCount={rows.length}
										onPaste={handlePaste}
										rowHeight={30}
										selectedRows={selectedRows}
										onSelectedRowsChange={setSelectedRows}
										onSelectedCellChange={(args) => {
											console.log(args);
											console.log(v.varietyCode);
											setSelectedCell(args);
											setSelectedDataset(v);
										}}
										onCellClick={(args, event) => {
											args.selectCell(true);
											if (args.column.key === "title") {
												event.preventGridDefault();
												args.selectCell(true);
											}
										}}
									/>
								</FilterContext.Provider>
							)}
						</div>
					);
				})}
		</ContentWrap>
	);
};

const mapStateToProps = (state) => {
	const { UI, Auth } = state;
	return {
		accessToken: get(Auth, "accessToken", null),
		mainPreloader: get(UI, "mainPreloader", null),
		permissionDetails: get(Auth, "permissionDetails", null),
	};
};

const mapDispatchToProps = {
	fetchLocationDetails,
	fetchHarvestForecastV2,
	updateHarvestForecast,
};
export default connect(mapStateToProps, mapDispatchToProps)(GrowerToolsTable);
