import React, { useState } from "react";
import { get, isEmpty, isBoolean } from "lodash";
import "./auditLog.scss";
import Util from "../../utils/Util";
import { Accordion, Card } from "react-bootstrap";
import { useTranslation } from 'react-i18next';


const AuditLog = ({ log, loading = false, onClickStatusAction }) => {
	const { t } = useTranslation();
	const [selectedLog, setSelectedLog] = useState(null);
	const [toggler, setToggler] = useState(true);

	const convertDisplay = (field, key) => {
		const value = get(field, key, "-");

		// Boolean to string
		if (field.is_boolean) {
			const boolVal = isBoolean(value) ? (value ? "Yes" : "No") : "-";
			return boolVal.toString();
		}
		// UTC date to Local formatted
		else if (field.to_local) {
			const convertedDate = value ? Util.getMDYLTFormat(value) : null;
			return convertedDate ? convertedDate : value;
		}
		// Date to formatted date
		else if (field.is_date) {
			const formattedDate = value ? Util.getDateFormat(value) : null;
			return formattedDate ? formattedDate : value;
		}
		// All other values
		else {
			return value;
		}
	};

	const isSelectedItem = (item) => {
		if (item && selectedLog && item.id === selectedLog.id) {
			return "selected";
		} else {
			return "";
		}
	};

	return (
		<Accordion>
			<Card>
				<Card.Header className="d-flex flex-row justify-content-between">
					<div className="mb-2 ">
						<span className="text-md font-weight-bold mb-5">{t('label.activity_log')}</span>
					</div>
					<div className="">
						{/* <ContextAwareToggle as="div" className="accordion-table-body-toggler" eventKey="0">
							<i onClick={() => onClickStatusAction(5)} className="icon-angle-down text-sm"></i>
						</ContextAwareToggle> */}
						<Accordion.Toggle
							as="div"
							onClick={() => {
								setToggler(!toggler);
								onClickStatusAction(5);
							}}
							className="accordion-table-body-toggler"
							eventKey="0">
							<i className={toggler ? "icon-angle-down text-sm" : "icon-angle-up text-sm"}></i>
						</Accordion.Toggle>
					</div>
				</Card.Header>
				<Accordion.Collapse eventKey="0">
					<Card.Body>
						{loading ? (
							<div>{t('label.fetching_log')}</div>
						) : (
							<div className="audit-log-body">
								{!isEmpty(log) ? (
									<div className="audit-log-body-list scroll-invisible">
										{log.map((item, i) => (
											<div
												className={`audit-log-body-list-item ${isSelectedItem(item)}`}
												key={i}
												onClick={() => setSelectedLog(item)}>
												<div className="d-flex flex-1">{item.user}</div>
												<div className="d-flex flex-3 px-3">{item.audited_detail}</div>
												<div className="d-flex flex-1">{Util.utcToLocal(item.audited_on)}</div>
											</div>
										))}
									</div>
								) : (
									"No results"
								)}

								{selectedLog && (
									<div className="audit-log-body-view custom-y-scroll">
										{!isEmpty(selectedLog.audited_fields) &&
											selectedLog.audited_fields.map((field, i) => (
												<div key={i} className="audit-log-body-view-item">
													<div className="d-flex flex-5">{field.name}</div>
													<div className="d-flex flex-3 pl-1">
														{convertDisplay(field, "new") || "-"}
													</div>
													<div className="d-flex flex-3 pl-1 text-strike">
														{convertDisplay(field, "old") ? (
															<del>{convertDisplay(field, "old")}</del>
														) : (
															"-"
														)}
													</div>
												</div>
											))}
									</div>
								)}
							</div>
						)}
					</Card.Body>
				</Accordion.Collapse>
			</Card>
		</Accordion>
		// <div className="audit-log-wrapper">
		// 	<div className="mb-4">
		// 		<span className="text-md font-weight-bold mb-5">Activity Log</span>
		// 		<i className="icon icon-close float-right custom-close pointer" onClick={closeLog} />
		// 	</div>

		// </div>
	);
};

export default AuditLog;
