import React, { useEffect } from "react";
import { Breadcrumbs, ContentWrap } from "elements";
import TableLoader from "utils/table/tableLoader";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { getDropDownOptions, showChartHandler } from "store/actions/watchWireActions";
import { getLoading } from "store/selectors/watchWireSelectors";
import { setLoaderWW } from "store/actions";
import { errorMessage } from "utils/errorMessage";
import Listing from "./listing";
import { Button } from "react-bootstrap";
import { getTableView } from "../../store/selectors/watchWireSelectors";
const WatchWire = ({ loading, getDropDownOptions, setLoaderWW, tableViewData, showChartHandler }) => {
	useEffect(() => {
		setLoaderWW();
		const successHandler = () => {
			setLoaderWW();
		};
		const errorHandler = (e) => {
			errorMessage(e);
			setLoaderWW();
		};
		getDropDownOptions(successHandler, errorHandler);
	}, [getDropDownOptions, setLoaderWW]);

	return (
		<ContentWrap>
			<div className="d-flex align-items-center mb-3">
				<Breadcrumbs className="forecast-breadcrumb" keyValue="watchWire" />
				<Button
					className="header-assignee-btn"
					onClick={() => {
						showChartHandler();
					}}
					variant="secondary"
					disabled={tableViewData ? false : true}>
					View Line Chart
				</Button>
			</div>
			<div className="position-relative">
				<Listing />
				{loading && (
					<div className="table-loader-wrapper">
						<TableLoader />
					</div>
				)}
			</div>
		</ContentWrap>
	);
};
const mapStateToProps = createStructuredSelector({
	loading: getLoading,
	tableViewData: getTableView,
});

export default connect(mapStateToProps, {
	setLoaderWW,
	getDropDownOptions,
	showChartHandler,
})(WatchWire);
