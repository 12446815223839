import React from "react";
import {
	ButtonPrimary,
	ButtonSecondary,
	ButtonDefault,
	ButtonLight,
	ButtonDark,
} from "../../elements";

const RenderButtons = () => {
	return (
		<div className="ui-buttons">
			<h2 className="mb-4">Buttons</h2>
			<div className="row">
				<div className="col-4 pb-3">
					<ButtonPrimary>
						<i className="icon icon-check mr-2" /> Primary Button
					</ButtonPrimary>
				</div>
				<div className="col-4 pb-3">
					<ButtonSecondary>
						<i className="icon icon-close mr-2" /> Secondary Button
					</ButtonSecondary>
				</div>
				<div className="col-4 pb-3">
					<ButtonDefault>
						<i className="icon icon-plus mr-2" /> Default Button
					</ButtonDefault>
				</div>
				<div className="col-4 pb-3">
					<ButtonLight>
						<i className="icon icon-edit mr-2" /> Light Button
					</ButtonLight>
				</div>
				<div className="col-4 pb-3">
					<ButtonDark>
						<i className="icon icon-trash mr-2" /> Dark Button
					</ButtonDark>
				</div>
			</div>
		</div>
	);
};

export default RenderButtons;
