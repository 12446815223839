import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import useModal from "utils/useModal";
import FormGroups from "utils/formGroup";
import { change, Field, reduxForm } from "redux-form";
import { copyForecastFormFields } from "./formHelper";
import { connect, useDispatch } from "react-redux";
import { createStructuredSelector } from "reselect";
import {
	copyForecastInitials,
	getCopyForecastFromDate,
	getCopyForecastToDate,
	getForecastFormData,
	numberOfDaysInTable,
} from "store/selectors/forecastSelectors";
import moment from "moment";
import { successMessage } from "utils/successMessage";
import { errorMessage } from "utils/errorMessage";
import TableLoader from "utils/table/tableLoader";
import { copyForecast } from "store/actions";

const CopyForecastForm = ({
	handleSubmit,
	initialValues,
	toDate,
	fromDate,
	forecastFormData,
	copyForecast,
	numberOfDaysInTable,
}) => {
	const [loading, setloading] = useState(false);
	const dispatch = useDispatch();
	const [, , , closeModal] = useModal("task");

	const submitForm = (values) => {
		setloading(true);
		const pv_slugs = forecastFormData.productVarieties.map((item) => item.value);

		const submitData = {
			from_location_slug: forecastFormData.location.value,
			to_location_slug: values.location_slug.value,
			from_date_from: moment(forecastFormData.fromDate).format("YYYY-MM-DD"),
			from_date_to: moment(forecastFormData.toDate).format("YYYY-MM-DD"),
			to_date_from: moment(values.date_from).format("YYYY-MM-DD"),
			to_date_to: moment(values.date_to).format("YYYY-MM-DD"),
			forecast: values.forecast_slug.value,
			pv_slugs: pv_slugs,
		};
		const successHandler = (e) => {
			successMessage(e);
			closeModal();
			setloading(false);
		};
		const errorHandler = (e) => {
			errorMessage(e);
			setloading(false);
		};

		copyForecast(submitData, successHandler, errorHandler);
	};

	useEffect(() => {
		if (fromDate && toDate) {
			dispatch(change("copyForecastForm", "date_to", new Date(toDate)));
		}
	}, [dispatch, fromDate, toDate]);

	const getFormFieldHtml = (formfields) => {
		let formFieldHtml = [];

		formFieldHtml = formfields.map((field, idx) => {
			return (
				<React.Fragment key={idx}>
					<Col className={field.name === "date_from" || field.name === "date_to" ? "col-md-6" : "col-12"}>
						<Form.Group controlId={`formControl ${field.name}`}>
							<Field
								name={field.name}
								type={field.type}
								label={field.label}
								placeholder={field.placeholder}
								component={FormGroups}
								minDate={field.minDate}
								validate={field.validate}
								filterDate={(date) => {
									const startDate = moment(forecastFormData.fromDate).subtract(
										numberOfDaysInTable - 1,
										"days"
									);
									if (date >= startDate && date <= forecastFormData.toDate) {
										return false;
									} else {
										return true;
									}
								}}
								disable={field.name === "date_from" || field.name === "date_to" ? false : true}
							/>
						</Form.Group>
					</Col>
				</React.Fragment>
			);
		});
		return <Row>{formFieldHtml}</Row>;
	};

	return (
		<Form onSubmit={handleSubmit(submitForm)} className="copyForecastForm">
			<Container className="position-relative">{getFormFieldHtml(copyForecastFormFields)}</Container>
			<div className="modal-footer save-footer" style={{ marginTop: 40 }}>
				<Button variant="secondary" onClick={closeModal}>
					Cancel
				</Button>
				<Button variant="primary" type="submit" disabled={loading}>
					{loading ? "Saving" : "Save"}
				</Button>
				{loading && (
					<div className="table-loader-wrapper">
						<TableLoader />
					</div>
				)}
			</div>
		</Form>
	);
};

const mapStateToProps = createStructuredSelector({
	initialValues: copyForecastInitials,
	toDate: getCopyForecastToDate,
	fromDate: getCopyForecastFromDate,
	forecastFormData: getForecastFormData,
	numberOfDaysInTable: numberOfDaysInTable,
});

export default connect(mapStateToProps, { copyForecast })(
	reduxForm({ form: "copyForecastForm", enableReinitialize: false })(CopyForecastForm)
);
