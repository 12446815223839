import { CROSS_SUPPLY } from "../types";

const initialState = {
	listingPageLoader: false,
	viewPageLoader: false,
	crossSupplyList: [],
	crossSupplyShippingList: [],
	csListingLocations: [],
	csUserLocations: [],
	csTransferDetails: {},
	csLocationSkus: [],
};

export default function (state = initialState, { type, payload }) {
	switch (type) {
		case CROSS_SUPPLY.GET_CROSS_SUPPLY_LIST:
			return {
				...state,
				crossSupplyList: payload.data || [],
			};
		case CROSS_SUPPLY.GET_CROSS_SUPPLY_SHIPPING_LIST:
			return {
				...state,
				crossSupplyShippingList: payload.data || [],
			};
		case CROSS_SUPPLY.GET_CROSS_SUPPLY_TRANSFER_DETAILS:
			return {
				...state,
				csTransferDetails: payload.data || {},
			};
		case CROSS_SUPPLY.GET_CROSS_SUPPLY_LISTING_LOCATIONS:
			return {
				...state,
				csListingLocations: payload.data || [],
			};
		case CROSS_SUPPLY.GET_CROSS_SUPPLY_USER_LOCATIONS:
			return {
				...state,
				csUserLocations: payload || [],
			};

		case CROSS_SUPPLY.TOGGLE_CROSS_SUPPLY_LIST_LOADER:
			return {
				...state,
				listingPageLoader: payload,
			};
		case CROSS_SUPPLY.TOGGLE_CROSS_SUPPLY__VIEW_LOADER:
			return {
				...state,
				viewPageLoader: payload,
			};
		case CROSS_SUPPLY.FETCH_LOCATION_SKUS:
			return {
				...state,
				csLocationSkus: payload.data || [],
			};

		default:
			return state;
	}
}
