import React from "react";
import { ListingBtn } from "../../../../elements";
// import Util from "../../../utils/Util";

export const renderColumns = ({ handleDeletePanel }) => {
	return [
		{
			name: "SKU",
			accessor: "skus",
			className: "cs-board-col-sku",
			headerClassName: "cs-board-col-sku-header",
		},
		{
			name: "Cases Required",
			accessor: "cases_required",
			className: "cs-board-col-number",
			headerClassName: "cs-board-col-number-header",
		},

		{
			name: "Delivery date",
			accessor: "delivery_date",
			className: "cs-board-col-date",
			headerClassName: "cs-board-col-date-header",
		},
		{
			name: "",
			className: "cs-board-col-actions justify-content-end",
			headerClassName: "cs-board-col-actions-header justify-content-end",
			render: (index) => {
				return (
					<>
						{
							<ListingBtn
								tooltipText="Delete"
								btnClass="btn btn-cell btn-light btn-sm ml-2"
								iconClass="icon icon-trash"
								onClick={() => handleDeletePanel(index)}
							/>
						}
					</>
				);
			},
			sortable: false,
		},
	];
};
