import { DAILY_DATA_GRAPH } from "../types";
import fetchHandler from "../../utils/fetchHandler";

export const fetchDailyGraphsDetails = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `dashboard/pie-charts`,
		method: "POST",
		secure: true,
		actionType: DAILY_DATA_GRAPH.FETCH_DAILY_DATA_GRAPH,
		body: JSON.stringify(values),
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};
