import React from "react";

const RenderCards = () => {
	return (
		<div className="ui-cards">
			<h2 className="mb-4">Cards</h2>
			<div className="d-flex">
				<div className="flex-fill mr-3">
					<div className="card card-primary p-4">Card Primary</div>
				</div>
				<div className="flex-fill mr-3">
					<div className="card card-secondary p-4">
						Card Secondary
					</div>
				</div>
				<div className="flex-fill mr-3">
					<div className="card card-info p-4">Card Info</div>
				</div>
			</div>
		</div>
	);
};

export default RenderCards;
