export const BreadcrumbHomeRoute = {
	homeLabel: "label.home",
	homeRoute: "/dashboard",
};

const menuTitle = {
	operations: "label.operations",
	settings: "Settings & Configurations",
	forecast: "Forecast",
	watchwire: "Reports",
};

export const BreadcrumbSubRoutes = {
	dashboard: {
		menuTitle: null,
		pageTitle: "Dashboard",
	},
	productionForecast: {
		menuTitle: menuTitle.forecast,
		pageTitle: "Production Forecast",
	},
	productionForecastAdd: {
		menuTitle: menuTitle.forecast,
		pageTitle: "Production Forecast",
		breadCrumbTittle: "Production Forecast / Forecast / Add",
	},
	salesForecast: {
		menuTitle: menuTitle.forecast,
		pageTitle: "Sales Forecast",
	},
	salesForecastAdd: {
		menuTitle: menuTitle.forecast,
		pageTitle: "Sales Forecast",
		breadCrumbTittle: "Sales Forecast / Weekly Forecast / Add",
	},
	productionVsSales: {
		menuTitle: menuTitle.forecast,
		pageTitle: "Production Vs Sales ",
	},
	productionVsActualSales: {
		menuTitle: menuTitle.forecast,
		pageTitle: "Production Vs Actual Sales",
	},
	laneCalculator: {
		menuTitle: menuTitle.forecast,
		pageTitle: "Lane Calculator",
	},
	watchWire: {
		menuTitle: menuTitle.watchwire,
		pageTitle: "Watchwire",
	},
	reports: {
		menuTitle: null,
		pageTitle: "Reports",
	},

	seedingGermination: {
		menuTitle: menuTitle.operations,
		pageTitle: "label.seeding",
	},
	crossSupply: {
		menuTitle: menuTitle.operations,
		pageTitle: "Cross-Supply",
	},
	crossSupplyShipping: {
		menuTitle: menuTitle.operations,
		pageTitle: "Shipping Details",
		breadCrumbTittle: " Cross-Supply / Shipping Details",
	},

	sGMediaUsage: {
		menuTitle: menuTitle.operations,
		pageTitle: "Media Usage",
	},
	sGCleaningTime: {
		menuTitle: menuTitle.operations,
		pageTitle: "Cleaning Time",
	},
	sGSetUpTime: {
		menuTitle: menuTitle.operations,
		pageTitle: "Set Up Time",
	},
	germination: {
		menuTitle: menuTitle.operations,
		pageTitle: "label.germination",
	},
	transplantGrowing: {
		menuTitle: menuTitle.operations,
		pageTitle: "label.transplant_and_growing",
	},
	harvesting: {
		menuTitle: menuTitle.operations,
		pageTitle: "label.harvesting",
	},
	ordersPackaging: {
		menuTitle: menuTitle.operations,
		pageTitle: "label.orders_and_packaging",
	},
	users: {
		menuTitle: menuTitle.settings,
		pageTitle: "User Management",
	},
	locations: {
		menuTitle: menuTitle.settings,
		pageTitle: "Location Management",
	},
	boards: {
		menuTitle: menuTitle.settings,
		pageTitle: "Styrofoam Board",
	},
	seeds: {
		menuTitle: menuTitle.settings,
		pageTitle: "Seeds",
		isLinkClickable: true,
		linkRoute: "/seeds",
	},
	greens: {
		menuTitle: menuTitle.settings,
		pageTitle: "Greens",
	},
	productVariety: {
		menuTitle: menuTitle.settings,
		pageTitle: "Product Variety",
		isLinkClickable: true,
		linkRoute: "/product-varieties",
	},
	sku: {
		menuTitle: menuTitle.settings,
		pageTitle: "Stock Keeping Unit",
	},
	customer: {
		menuTitle: menuTitle.settings,
		pageTitle: "Customer Management",
		isLinkClickable: true,
		linkRoute: "/customers",
	},
	accessPrivilege: {
		menuTitle: menuTitle.settings,
		pageTitle: "Access Privileges",
	},
	nutrientTracking: {
		menuTitle: menuTitle.nutrientTracking,
		pageTitle: "Nutrient Tracking",
	},
	nutrientTrackingLabAdd: {
		menuTitle: menuTitle.nutrientTracking,
		pageTitle: "Nutrient Tracking",
		breadCrumbTittle: "Nutrient Tracking / Lab Results / Add",
	},
	nutrientTrackingCroptimalsAdd: {
		menuTitle: menuTitle.nutrientTracking,
		pageTitle: "Nutrient Tracking",
		breadCrumbTittle: "Nutrient Tracking / Croptimals / Add",
	},
	nutrientTrackingWaterAdd: {
		menuTitle: menuTitle.nutrientTracking,
		pageTitle: "Nutrient Tracking",
		breadCrumbTittle: "Nutrient Tracking / Water Source Results / Add",
	},
	nutrientTrackingFertilizersAdd: {
		menuTitle: menuTitle.nutrientTracking,
		pageTitle: "Nutrient Tracking",
		breadCrumbTittle: "Nutrient Tracking / Fertilizers / Add",
	},
	nutrientTrackingToAdd: {
		menuTitle: menuTitle.nutrientTracking,
		pageTitle: "Nutrient Tracking",
		breadCrumbTittle: "Nutrient Tracking / To Add List / View Nutrients Added",
	},
	transferRequest: {
		menuTitle: menuTitle.operations,
		pageTitle: "Transfer Request",
	},
};
