import { LANE_CALCULATOR } from "../types";
import { isEmpty, keys, values } from "lodash";
const initialState = {
	loading: false,
	laneCalculatorLocations: {},
	customerLaneUsage: {},
	laneUsage: {},
	laneUtilization: {},
	tableView: {},
	activeTabCustomer: {},
	activeTabDC: {},
	setLaneUsageDropDown: "sales",
	setTableViewDropDown: "sales",
	skusAndPvList:{},
	laneUsageData:{},
	submitLane:false,
	productVarietiesCL:null,
	growLanesData: null,
	productVarietiesData: null,
	laneToCaseFormData: null,
};
export default function (state = initialState, { type, payload }) {
	switch (type) {
		case LANE_CALCULATOR.LOADER_LC:
			return {
				...state,
				loading: !state.loading,
			};
		case LANE_CALCULATOR.LOCATION_LIST:
			return {
				...state,
				laneCalculatorLocations: payload.data || {},
			};
		case LANE_CALCULATOR.SET_DATA:
			return {
				...state,
				laneUsage: payload.data.lane_usage,
				laneUtilization: payload.data.lane_utilization,
			};
		case LANE_CALCULATOR.SET_DATA_FORCAST:
			return {
				...state,
				tableView: payload.data,
				activeTabCustomer: !isEmpty(payload.data) ? keys(payload.data)[0].replace(/\s/g, "") : null,
				activeTabDC: !isEmpty(payload.data) ? keys(values(payload.data)[0])[0].replace(/\s/g, "") : null,
			};
		case LANE_CALCULATOR.SET_PRODUCTION_DATA_FORCAST:
			return {
				...state,
				tableView: payload.data,
				activeTabCustomer: null,
				activeTabDC: null,
			};
		case LANE_CALCULATOR.SET_ACTIVE_TAB_CUSTOMER_LC:
			return {
				...state,
				activeTabCustomer: payload.key,
				activeTabDC: keys(state.tableView[payload.label])[0].replace(/\s/g, ""),
			};
		case LANE_CALCULATOR.SET_ACTIVE_TAB_DC_LC:
			return {
				...state,
				activeTabDC: payload,
			};
		case LANE_CALCULATOR.SET_TABLE_VIEW_COLUMNS:
			const tempData = payload.map((item) => ({
				name: item.label,
				slug: item.value,
			}));
			return {
				...state,
				laneCalculatorTableColumns: tempData,
			};
		case LANE_CALCULATOR.TYPE_LU:
			return {
				...state,
				setLaneUsageDropDown: payload,
			};

		case LANE_CALCULATOR.TYPE_TV:
			return {
				...state,
				setTableViewDropDown: payload,
			};
		case LANE_CALCULATOR.RESET_REFRESH_LC:
			return {
				...state,
				customerLaneUsage: {},
				laneUsage: {},
				laneUtilization: {},
				tableView: {},
				activeTabCustomer: {},
				activeTabDC: {},
				setLaneUsageDropDown: "sales",
				setTableViewDropDown: "sales",
			};

		case LANE_CALCULATOR.LOCATION_PRODUCT_VARIETIES:
			return {
				...state,
				productVarieties: payload.data,
			};
		case LANE_CALCULATOR.SET_GROW_LANES:
			return {
				...state,
				growLanesData: payload.data,
			};

		case LANE_CALCULATOR.SET_PRODUCT_VARIETIES:
			return {
				...state,
				productVarietiesData: payload.data,
			};

		case LANE_CALCULATOR.LANE_TO_CASE_FORM_DATA:
			return {
				...state,
				laneToCaseFormData: payload,
			};
		case LANE_CALCULATOR.SKU_AND_PV:
			return {
				...state,
				skusAndPvList: payload.data,
				// productVarietiesCL:null
			};
		case LANE_CALCULATOR.LANE_USAGE_DATA:
			return {
				...state,
				laneUsageData: payload.data,
			};

		case LANE_CALCULATOR.SET_SUBMIT_TRUE:
			return {
				...state,
				submitLane: payload,
			};
		case LANE_CALCULATOR.RESET_FIELD_LU:
			return {
				...state,
				laneUsageData: {},
				productVarietiesCL:null
			};

		case LANE_CALCULATOR.RESET_PRODUCT_VARIETIES:
			return {
				...state,
				productVarietiesData: null,
			};
		case LANE_CALCULATOR.RESET_LANE_TO_CASE:
			return {
				...state,
				productVarietiesData: null,
				growLanesData: null,
				laneToCaseFormData: null,
			};
		case LANE_CALCULATOR.SET_PRODUCT_VARIETIES_CL:
			return {
				...state,
				productVarietiesCL:payload
			}

		case LANE_CALCULATOR.RESET_LANE_USAGE_LC:
			return {
				...state,
				laneUsageData:{}
			}

		case LANE_CALCULATOR.RESET_GROW_LANES:
			return {
				...state,
				growLanesData: null,
			};
		default:
			return state;
	}
}
