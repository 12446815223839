import { useDispatch, useSelector } from "react-redux";
import { hideModal, showModal as showModalAction } from "../store/actions/modalActions";

/**
 * Modal handling Hook
 * @param {string} modalName
 * @returns {boolean} isOpen
 * @returns {number} modalType
 * @returns {function} showModal
 * @returns {function} closeModal
 * @returns {any} modalProps
 */
export default function useModal(modalName) {
    const dispatch = useDispatch();

	const isOpen = useSelector((state) => state.Modals.modalName === modalName);
	const modalProps = useSelector((state) => state.Modals.modalProps);
	const modalType = useSelector((state) => state.Modals.modalType);
	const closeModal = () => dispatch(hideModal());
	const showModal = (modalType, modalProps) =>
		dispatch(showModalAction(modalName, modalType, modalProps));

	return [isOpen, modalType, showModal, closeModal, modalProps];
}
