import React, { useState } from "react";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import { Field, reduxForm, reset } from "redux-form";
import moment from "moment";
import FormGroups from "../../../utils/formGroup";
import { connect } from "react-redux";
import useModal from "../../../utils/useModal";
import { updateSeedingDetailsOfSGTask, updateGerminationDetailsOfSGTask } from "../../../store/actions";
import { successMessage } from "../../../utils/successMessage";
import { errorMessage } from "../../../utils/errorMessage";
import TableLoader from "../../../utils/table/tableLoader";
import Util from "../../../utils/Util";
import { useTranslation } from 'react-i18next';

const TaskDetailsForm = ({
	form,
	formFields,
	isSeeding,
	isGermination,
	showCompleteButton,
	slug,
	isAssigneeSelected,
	updateSeedingDetailsOfSGTask,
	updateGerminationDetailsOfSGTask,
	handleSubmit,
	doFetchTaskDetails,
}) => {
	const { t } = useTranslation();
	const [, modalType, , closeModal] = useModal("seeding_details");

	const [isLoading, setIsLoading] = useState(false);
	const [isCompleteButtonLoading, setIsCompleteButtonLoading] = useState(false);

	const isEditing = modalType === 2 || modalType === 4;

	// Button Labels
	const addLabels = isSeeding ? t("label.move_to_germination") : t("label.save");
	const addLoadingLabels = isSeeding ? t("label.moving") : t("label.saving");
	const editLabels = isLoading ? t("label.updating") : t("label.update");

	// Submit Form
	const submitDetails = (values) => {
		setIsLoading(true);
		const successHandler = (data) => {
			successMessage(data);
			setIsLoading(false);
			closeModal();
			reset(form);
			doFetchTaskDetails();
		};
		const errorHandler = (err) => {
			errorMessage(err);
			setIsLoading(false);
		};
		// SEEDING
		if (isSeeding) {
			const body = {
				seeded_board_count: values.seeded_board_count,
				seeding_flag_color: values.seeding_flag_color && values.seeding_flag_color.value,
				seeding_seed_density: values.seeding_seed_density,
				seeding_container_filled: values.seeding_container_filled && values.seeding_container_filled.value,
				seeding_accuracy_in_furrows: values.seeding_accuracy_in_furrows,
				comments: values.comments || null,
			};
			updateSeedingDetailsOfSGTask(slug, body, successHandler, errorHandler);
		}
		// GERMINATION
		else if (isGermination) {
			const body = {
				germination_start_date: moment(values.germination_start_date).utc().format(),
				expected_germination_date: Util.dateObjToString(values.expected_germination_date),
			};
			updateGerminationDetailsOfSGTask(slug, body, successHandler, errorHandler);
		}
	};

	// Update and Submit - Germination Details Modal
	const submitGerminationAndComplete = (values) => {
		if (isAssigneeSelected) {
			// Actual germination start and end time should be less than current time and date
			if (Util.checkDateIsPast(values.germination_start_date)) {
				setIsCompleteButtonLoading(true);
				const successHandler = (data) => {
					successMessage(data);
					setIsCompleteButtonLoading(false);
					closeModal();
					reset(form);
					doFetchTaskDetails();
				};
				const errorHandler = (err) => {
					errorMessage(err);
					setIsCompleteButtonLoading(false);
				};
				const body = {
					germination_start_date: moment(values.germination_start_date).utc().format(),
					expected_germination_date: Util.dateObjToString(values.expected_germination_date),
					is_complete: true,
				};
				updateGerminationDetailsOfSGTask(slug, body, successHandler, errorHandler);
			} else {
				errorMessage("GERMINATION_END_DATE_CURRENT_DATE");
			}
		} else {
			errorMessage("NO_ASSIGNEE_SELECTED");
		}
	};

	return (
		<Form onSubmit={handleSubmit(submitDetails)} className="board-details-form">
			<Container className="position-relative">
				<Row>
					{formFields.map((field, idx) => (
						<Col xs={field.view === "full" ? 12 : 6} key={idx}>
							<Form.Group controlId={`formControl ${idx}`}>
								<Field
									name={field.name}
									type={field.type}
									label={field.label}
									placeholder={field.placeholder}
									component={FormGroups}
									validate={field.validate}
									options={field.options || []}
									disable={field.disabled || false}
									multiple={false}
									// Date
									minDate={field.minDate}
								/>
							</Form.Group>
						</Col>
					))}
				</Row>
				{/* Loader */}
				{(isLoading || isCompleteButtonLoading) && (
					<div className="table-loader-wrapper">
						<TableLoader />
					</div>
				)}
			</Container>
			<div className="modal-footer">
				<Button variant="secondary" onClick={closeModal}>
					{t("label.cancel")}
				</Button>
				<Button variant="primary" type="submit" disabled={isLoading}>
					{isEditing ? editLabels : isLoading ? addLoadingLabels : addLabels}
				</Button>
				{isGermination && showCompleteButton && (
					<Button
						variant="primary"
						disabled={isCompleteButtonLoading}
						onClick={handleSubmit(submitGerminationAndComplete)}>
						{isCompleteButtonLoading ? t("label.updating_and_completing") : t("label.update_and_completed")}
					</Button>
				)}
			</div>
		</Form>
	);
};

const mapDispatchToProps = {
	reset,
	updateSeedingDetailsOfSGTask,
	updateGerminationDetailsOfSGTask,
};

export default connect(
	null,
	mapDispatchToProps
)(
	reduxForm({
		form: "task-details-form",
		enableReinitialize: true,
	})(TaskDetailsForm)
);
