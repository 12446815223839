import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Row, Col, Form, Container } from "react-bootstrap";
import FormGroups from "utils/formGroup";
import { reduxForm, Field } from "redux-form";

import { createStructuredSelector } from "reselect";
import {
	getCasesPerSku,
	getCasesDate,
	getCasesLocation,
	getLocationInitial,
	getLocationList,
} from "store/selectors/ordersPackagingSelectors";
import { casesPerSkuForm } from "./formHelper";
import { fetchCasesPerSku, resetListing } from "store/actions";
import moment from "moment";
import { errorMessage } from "utils/errorMessage";
import CasesPerSkuTable from "./casesPerSkuTable";
import TableLoader from "utils/table/tableLoader";
import { useTranslation } from 'react-i18next';

const CasesPerSku = ({ locationList, initialValues, date, location, fetchCasesPerSku, casesPerSku, resetListing }) => {
	const [loading, setloading] = useState(false);
	const { t } = useTranslation();

	useEffect(() => {
		if (date && location) {
			setloading(true);
			const submitData = {
				location_slug: location,
				date: moment(date).format("YYYY-MM-DD"),
				search: "",
			};
			const successHandler = (event) => {
				setloading(false);
			};
			const errorHandler = (event) => {
				setloading(false);

				errorMessage(event);
			};
			fetchCasesPerSku(submitData, successHandler, errorHandler);
		}
	}, [date, location, fetchCasesPerSku]);

	useEffect(() => {
		return () => {
			resetListing();
		};
	}, [resetListing]);

	const getFormFieldHtml = (formfields) => {
		let formFieldHtml = [];

		formFieldHtml = formfields.map((field, idx) => {
			return (
				<React.Fragment key={idx}>
					<Col className="col-12">
						<Form.Group controlId={`formControl ${field.name}`}>
							{field.name === "date" ? (
								<Field
									name={field.name}
									type={field.type}
									label={t(field.label)}
									placeholder={t(field.placeholder)}
									component={FormGroups}
									minDate={field.minDate}
									validate={field.validate}
								/>
							) : (
								<Field
									name={field.name}
									type={field.type}
									label={t(field.label)}
									placeholder={t(field.placeholder)}
									component={FormGroups}
									validate={field.validate}
									multiple={field.name === "product_variety" || field.name === "sku" ? true : false}
									options={locationList}
								/>
							)}
						</Form.Group>
					</Col>
				</React.Fragment>
			);
		});
		return <Row>{formFieldHtml}</Row>;
	};

	return (
		<Form className="casesPerSku">
			<Container className="postion-relative">
				{getFormFieldHtml(casesPerSkuForm)}

				{loading && (
					<div className="table-loader-wrapper">
						<TableLoader />
					</div>
				)}
				{!loading && date && location && <CasesPerSkuTable />}
			</Container>
		</Form>
	);
};

const mapStateToProps = createStructuredSelector({
	locationList: getLocationList,
	initialValues: getLocationInitial,
	date: getCasesDate,
	location: getCasesLocation,
	casesPerSku: getCasesPerSku,
});

export default connect(mapStateToProps, { fetchCasesPerSku, resetListing })(
	reduxForm({
		form: "casesPerSku",
		enableReinitialize: false,
	})(CasesPerSku)
);
