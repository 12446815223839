import React from "react";
import { map, join } from "lodash";
import { ButtonLink, ListingBtn } from "../../../elements";
import { Dropdown } from "react-bootstrap";
import { errorMessage } from "utils/errorMessage";


export const renderColumns = ({
	handleEditPanel,
	handleViewPanel,
	handleDeletePanel,
	addToFilter,
	permissionDetails,
	ActivateLocation,
}) => {
	const updatePermission =
		permissionDetails &&
		permissionDetails.product_variety &&
		permissionDetails.product_variety.permissions.update_product_variety
			? permissionDetails.product_variety.permissions.update_product_variety
			: "";
	const viewPermission =
		permissionDetails &&
		permissionDetails.product_variety &&
		permissionDetails.product_variety.permissions.show_product_variety
			? permissionDetails.product_variety.permissions.show_product_variety
			: "";
	const deletePermission =
		permissionDetails &&
		permissionDetails.product_variety &&
		permissionDetails.product_variety.permissions.delete_product_variety
			? permissionDetails.product_variety.permissions.delete_product_variety
			: "";
	return [
		{
			name: "Greens Name",
			accessor: "green_name",
			className: "greens-listing-col-name",
			headerClassName: "greens-listing-col-name-header",
			filter: { type: "search", onChange: addToFilter },
			render: (row) => {
				return viewPermission ? (
					<ButtonLink className="p-0" onClick={() => handleViewPanel(row.slug)}>
						{row.green.green_name || "Green"}
					</ButtonLink>
				) : (
					row.green.green_name || "Green"
				);
			},
		},
		{
			name: "Seeds",
			accessor: "seed_name",
			className: "greens-listing-col-seeds",
			headerClassName: "greens-listing-col-seeds-header",
			render: (row) => join(map(row.seeds, "seed_name"), ", "),
			sortable: false,
			filter: {
				type: "search",
				onChange: addToFilter,
			},
		},
		{
			name: "Actions",
			className: "location-listing-col-actions",
			headerClassName: "location-listing-col-actions-header",
			hide: updatePermission || deletePermission ? false : true,
			render: (row) => {
				return (
					<>
						<Dropdown drop={"down"}>
							<Dropdown.Toggle as="div" id="dropdown-basic">
								<ListingBtn
									parentClass={
										updatePermission && deletePermission ? "justify-content-end" : "justify-content-around"
									}
									btnClass="btn btn-cell btn-light btn-sm ml-2"
									iconClass="icon icon-menu"
								/>
							</Dropdown.Toggle>

							<Dropdown.Menu>
								{updatePermission ? (
									<Dropdown.Item
										onClick={() =>
											updatePermission ? ActivateLocation(row) : errorMessage("INSUFFICIENT_PERMISSION")
										}>
										Manage Product Variety Status
									</Dropdown.Item>
								) : (
									""
								)}
							</Dropdown.Menu>
						</Dropdown>
						{updatePermission ? (
							<ListingBtn
								parentClass={
									updatePermission && deletePermission ? "justify-content-end" : "justify-content-around"
								}
								tooltipText="Edit"
								btnClass="btn btn-cell btn-light btn-sm ml-2"
								iconClass="icon icon-edit"
								onClick={() => handleEditPanel(row.slug)}
							/>
						) : (
							""
						)}
						{deletePermission ? (
							<ListingBtn
								parentClass={
									updatePermission && deletePermission ? "justify-content-end" : "justify-content-around"
								}
								tooltipText="Delete"
								btnClass="btn btn-cell btn-light btn-sm ml-2"
								iconClass="icon icon-trash"
								onClick={() => handleDeletePanel(row)}
							/>
						) : (
							""
						)}
					</>
				);
			},
			sortable: false,
		},
	];
};
