import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import { findIndex, find, map, sum } from "lodash";
import { ButtonSecondary, ButtonPrimary, ButtonLink } from "../../../elements";
import { errorMessage } from "../../../utils/errorMessage";
import { successMessage } from "../../../utils/successMessage";
import { required, number, max100p, min0p } from "constant/formValidators";
import { useTranslation } from 'react-i18next';

const SKURecipeForm = ({
	isLoading,
	initialValues,
	setPageLoading,
	handleAddResponse,
	onCloseEditRecipe,
	changeSKUComposition,
}) => {
	const { t } = useTranslation();
	const [formValues, setFormValues] = useState(initialValues);

	const validateRecipe = (value) => required(value) || number(value) || max100p(value) || min0p(value) || null;

	// Handle input change
	const onHandleInput = (e, value) => {
		const index = findIndex(formValues && formValues.product_variety, ["pv_slug", value.pv_slug]);
		const updatedRecipeArr = [...formValues.product_variety];
		updatedRecipeArr[index] = {
			...updatedRecipeArr[index],
			percentage: e.target.value,
			error: validateRecipe(e.target.value),
		};
		setFormValues({
			...formValues,
			product_variety: updatedRecipeArr,
		});
	};

	// On Reset
	const onReset = () => {
		const arr = map(formValues && formValues.product_variety, (o) => ({
			...o,
			percentage: o.actual_percentage,
			error: validateRecipe(o.actual_percentage),
		}));
		setFormValues({
			...formValues,
			product_variety: arr,
		});
	};

	// Disable button if any errors
	const ifAnyErrors = find(formValues && formValues.product_variety, (o) => o.error) || null;

	// If sum of recipes are below 100
	const isPercentageSumValid = () => {
		const numbArr = map(formValues && formValues.product_variety, (o) => o && +o.percentage);
		const sumOfRecipe = sum(numbArr);
		return sumOfRecipe === 100 ? true : false;
	};

	// Submit Form
	const submitUpdatedRecipe = () => {
		if (isPercentageSumValid()) {
			setPageLoading(true);
			const onSuccess = (e) => {
				successMessage(e);
				handleAddResponse("SUCCESS");
			};
			const onError = (e) => {
				errorMessage(e);
				handleAddResponse("ERROR");
			};
			changeSKUComposition(formValues, onSuccess, onError);
		} else {
			errorMessage("RECIPE_SUM_MAX_100");
		}
	};

	return (
		<Row>
			{/* Title */}
			<Col xs={12}>
				<h2 className="form-headers mt-3 mb-4 pl-0">{t("label.recipe")}</h2>
			</Col>

			{/* Form */}
			{formValues &&
				formValues.product_variety &&
				formValues.product_variety.map((item, i) => (
					<Col md={4} sm={6} xs={12} key={i} className={`form-group text ${item.error ? "error" : ""}`}>
						{item.error && <span className="span-error">{item.error}</span>}
						<input
							className="form-control"
							key={i}
							type="text"
							value={item.percentage}
							onChange={(e) => onHandleInput(e, item)}
						/>
						<span className="form-label">{item.product_variety_name}</span>
					</Col>
				))}

			{/* Footer */}
			<Col xs={12} className="d-flex justify-content-end mt-3 mb-3">
				<ButtonLink className="btn-link-grey" onClick={onCloseEditRecipe}>
					{t("label.cancel")}
				</ButtonLink>
				<ButtonSecondary className="ml-3" onClick={onReset}>
					{t("label.reset_to_actual")}
				</ButtonSecondary>
				<ButtonPrimary type="submit" className="ml-3" disabled={ifAnyErrors} onClick={submitUpdatedRecipe}>
					{isLoading ? t("label.applying_recipe") : t("label.save_Apply_recipe")}
				</ButtonPrimary>
			</Col>
		</Row>
	);
};

export default SKURecipeForm;
