import { REPORTS } from "../types";
const initialState = {
	graphDetails: [],
	locationSkus: [],
	locationCustomers: [],
	locationProductVariety: [],
};
export default (state = initialState, action) => {
	switch (action.type) {
		case REPORTS.FETCH_GRAPH_DETAILS:
			return {
				...state,
				graphDetails: action.payload ? action.payload.data : [],
			};
		case REPORTS.FETCH_LOCATION_SKUS:
			return {
				...state,
				locationSkus: action.payload ? action.payload.data : [],
			};
		case REPORTS.LOCATION_CUSTOMERS:
			return {
				...state,
				locationCustomers: action.payload ? action.payload.data : [],
			};
		case REPORTS.FETCH_LOCATION_PRODUCT_VARITIES_REPORT:
			return {
				...state,
				locationProductVariety: action.payload ? action.payload.data : [],
			};
		default:
			return state;
	}
};
