export const SalesVsProductionDropdownType = [
	{
		label: "Production Vs Sales",
		value: 0,
		key: "production_vs_sales",
	},
	{
		label: "Production Sales Difference",
		value: 1,
		key: "production_sales_difference",
	},
	{
		label: "Production Vs Sales Combined",
		value: 2,
		key: "production_vs_sales_combined",
	},
	{
		label: "Production Vs Sales Combined Difference",
		value: 3,
		key: "production_vs_sales_combined_difference",
	},
];

export const ActualSalesVsProductionDropdownType = [
	{
		label: "Production Vs Actual Sales",
		value: 0,
		key: "production_vs_actual_sales",
	},
	{
		label: "Production Vs Actual Sales Difference",
		value: 1,
		key: "production_actual_sales_difference",
	},
	{
		label: "Production Vs Actual Sales Combined",
		value: 2,
		key: "production_vs_actual_sales_combined",
	},
	{
		label: "Production Vs Actual Sales Combined Difference",
		value: 3,
		key: "production_vs_actual_sales_combined_difference",
	},
];
