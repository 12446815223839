import { createSelectorCreator, defaultMemoize } from "reselect";
import Util from "utils/Util";
import { isEqual } from "lodash";

const getCountDetaiils = (state) => state.Count;
const getAuth = (state) => state.Auth;
const getSeedHarvestRateDetails = (state) => state.SeedHarvestGraph;
const getDailyGraphDetails = (state) => state.DailyGraphs;
const getPackRateGraphDetails = (state) => state.packRateGraph;

const getDashboardDetails = (state)=>state.DashBoard;

const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);

export const getUserLocation = createDeepEqualSelector([getAuth], (auth) => auth.userLocations[0] || []);

export const getCountDetailsArray = createDeepEqualSelector([getCountDetaiils], (data) => data.countDetails || []);
export const getSeedRateArray = createDeepEqualSelector(
	[getSeedHarvestRateDetails],
	(data) => data.seedHarvestDetails.seedRateChartData || []
);
export const getHarvestRateArray = createDeepEqualSelector(
	[getSeedHarvestRateDetails],
	(data) => data.seedHarvestDetails.harvestRateChartData || []
);
export const getCasesPackedArray = createDeepEqualSelector(
	[getDailyGraphDetails],
	(data) => data.dailyGraphDetails.casesPackedPie || []
);
export const getLbsHarvestedPiArray = createDeepEqualSelector(
	[getDailyGraphDetails],
	(data) => data.dailyGraphDetails.lbsHarvestedPie || []
);
export const getOzPerBoardPiArray = createDeepEqualSelector(
	[getDailyGraphDetails],
	(data) => data.dailyGraphDetails.ozPerBoardPie || []
);
export const getLocationList = createDeepEqualSelector(
	[getAuth],
	(auth) => Util.mapOptions(auth.reportLocations, "name", "slug") || []
);
export const getPackRateGraphArray = createDeepEqualSelector(
	[getPackRateGraphDetails],
	(data) => data.packRateGraphDetails.chartData || []
);

export const getPackRateKpi = createDeepEqualSelector(
	[getPackRateGraphDetails],
	(data) => data.packRateGraphDetails.packKpiTarget || 0
);

const getReportsPermission = (state) => state.Auth.permissionDetails.reports;

export const getSeedingAccess = createDeepEqualSelector(
	[getReportsPermission],
	(reports) => reports && reports.permissions.seeding_report
);
export const getHarvestAccess = createDeepEqualSelector(
	[getReportsPermission],
	(reports) => reports && reports.permissions.harvesting_report
);
export const getOrdersAccess = createDeepEqualSelector(
	[getReportsPermission],
	(reports) => reports && reports.permissions.orders_report
);
export const getPondVisualizationAccess = createDeepEqualSelector(
	[getReportsPermission],
	(reports) => reports && reports.permissions.pond_visualisation
);


export const getHarvestKpiData = createDeepEqualSelector(
	[getSeedHarvestRateDetails],
	(data) => data.seedHarvestDetails.harvestKpiTarget || 0
);

export const getSeedKpiData = createDeepEqualSelector(
	[getSeedHarvestRateDetails],
	(data) => data.seedHarvestDetails.seedKpiTarget || 0
);

export const getPondList = createDeepEqualSelector(
	[getDashboardDetails],
	(data) => data && data.pondDropdownList && Util.mapOptions(data.pondDropdownList, "name", "id") || []
);