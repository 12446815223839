import { required, requiredDateObj } from "../../constant/formValidators";
import Util from "../../utils/Util";

export const formCreationFields = [
	{
		label: "Location *",
		name: "location",
		type: "select-react",
		placeholder: "Select Location",
	},
	{
		label: "Report *",
		name: "report",
		type: "select-react",
		placeholder: "Select Report",
	},
	{
		label: "Type *",
		name: "type",
		type: "select-react",
		placeholder: "Select Type",
	},
	{
		label: "Timeframe *",
		name: "timeframe",
		type: "select-react",
		placeholder: "Select Timeframe",
	},
	{
		label: "Date *",
		name: "date",
		type: "date-range-picker",
		placeholder: "Enter date range",
	},
	{
		label: "Sku",
		name: "sku",
		type: "select-react",
		placeholder: "Select Sku",
	},
	{
		label: "Customer",
		name: "customer_slug",
		type: "select-react",
		placeholder: "Select Customer",
	},
	{
		label: "DC",
		name: "dc_slug",
		type: "select-react",
		placeholder: "Select DC",
	},
	{
		label: "Product Variety",
		name: "pv_slug",
		type: "select-react",
		placeholder: "Select Product Variety",
	},
];

export const getValidations = (field) => {
	switch (field) {
		case "to":
			return [requiredDateObj];
		case "from":
			return [requiredDateObj];
		case "sku":
			return null;
		case "customer_slug":
			return null;
		case "dc_slug":
			return null;
		case "pv_slug":
			return null;
		default:
			return [required];
	}
};

export const timeFrameArray = [
	{
		value: "daily",
		label: "Daily",
	},
	{
		value: "monthly",
		label: "Monthly",
	},
	{
		value: "yearly",
		label: "Yearly",
	},
];

export const reportTypeArray = [
	{
		value: "seeding",
		label: "Seeding",
		permission_key: "seeding_report",
	},
	{
		value: "harvesting",
		label: "Harvesting",
		permission_key: "harvesting_report",
	},
	{
		value: "orders_and_packaging",
		label: "Orders & Packaging",
		permission_key: "orders_report",
	},
	{
		value: "forecast",
		label: "Forecast",
		permission_key: "forecast_report",
	},
	{
		value: "forecast_revenue",
		label: "Forecast Revenue",
		permission_key: "forecast_report",
	},
];

export const calculationTypeArray = {
	seeding: [
		{
			value: "board",
			label: "Boards Seeded",
		},
		{
			value: "seedrate",
			label: "Seed Rate",
		},
		{
			value: "germination_hours",
			label: "Germination hours",
		},
	],
	harvesting: [
		// {
		// 	value: "board",
		// 	label: "Board",
		// },
		{
			value: "harvest_rate",
			label: "Harvest Rate",
		},
		{
			value: "lbs_harvested_wi",
			label: "Lbs Harvested (Including Wastage)",
		},
		{
			value: "lbs_harvested_we",
			label: "Lbs Harvested (Excluding Wastage)",
		},
		{
			value: "boards_harvested",
			label: "Boards Harvested",
		},
		{
			value: "total_yield",
			label: "Total Yield",
		},
		{
			value: "yield_by_variety",
			label: "Yields by Variety",
		},
	],
	orders_and_packaging: [
		{
			value: "cases_packed",
			label: "Cases Packed",
		},
		{
			value: "cases_per_lab_hour",
			label: "Cases Per Labour Hour",
		},
		{
			value: "lbs_per_lab_hour",
			label: "Lbs Per Labour Hour",
		},
		{
			value: "orders_created",
			label: "Orders Created",
		},
		{
			value: "shortage_cases",
			label: "Shortage Cases",
		},
		{
			value: "excess_cases",
			label: "Excess Cases",
		},
	],
	forecast: [
		{
			value: "forecast_harvest",
			label: "Forecast Harvest Lbs",
		},
		{
			value: "forecast_accuracy",
			label: "Forecast Accuracy",
		},
		{
			value: "forecast_variance",
			label: "Variance",
		},
	],
	forecast_revenue: [
		{
			value: "production_forecast_revenue_by_variety",
			label: "Production Forecast Revenue by Variety",
		},
		{
			value: "production_forecast_revenue_by_cases",
			label: "Production Forecast Revenue by Cases",
		},
		{
			value: "sales_forecast_revenue_by_variety",
			label: "Sales Forecast Revenue by Variety",
		},
		{
			value: "sales_forecast_revenue_by_cases",
			label: "Sales Forecast Revenue by Cases",
		},
	],
};

export const singleBarChartArrayMapper = { cases_per_lab_hour: "cases_per_lab_hour", lbs_per_lab_hour: "lbs_per_lab_hour" };

export const mapOptions = (list, label, value) => {
	const newList =
		list &&
		list.map((field) => ({
			label: field[label].toString(),
			value: field[value].toString(),
		}));

	return newList;
};
export const skuOptions = (location, list) => {};
const fieldMap = (list, productVariety) => {
	const result =
		productVariety &&
		productVariety.map((el) => {
			const obj = list.filter((item) => item.slug === el.slug);
			return obj[0]
				? {
						name: el.name,
						slug: el.slug,
						value: obj[0].value,
				  }
				: {
						name: el.name,
						slug: el.slug,
						value: 0,
				  };
		});
	const newList = [];
	result &&
		result.map((field) => {
			return (newList[field.name.toString()] = parseFloat(field.value));
		});

	return newList;
};

export const formatChartData = (xAxisData, chartData, type, productVariety) => {
	const newList =
		xAxisData &&
		xAxisData.map((value) => {
			const mappedFields = fieldMap(chartData[value][type], productVariety);
			const total = chartData[value][type].reduce((a, b) => +(+a + +b.value).toFixed(3), 0);
			let obj = {
				name: value.toString(),
				...mappedFields,
				total: total,
			};
			if (chartData[value]["avg"]) {
				obj = {
					...obj,
					Average: chartData[value]["avg"],
				};
			}
			return obj;
		});

	return newList ? newList : [];
};

export const getFormattedXaxisData = (xAxisData) => {
	const newList =
		xAxisData &&
		xAxisData.map((value) => {
			return Util.getDateFormat(value);
		});
	return newList ? newList : [];
};
export const axisStyle = { fontSize: "10px", fontFamily: "Poppins, sans-serif", fontWeight: 500, fontColor: "#43425d" };
export const toolTipStyle = { fontSize: "10px", fontFamily: "Poppins, sans-serif", fontWeight: 500, color: "#43425d" };
export const formatXAxis = (tickItem) => {
	return Util.getDateFormat(tickItem);
};

export const lastDay = (date, type) => {
	return Util.getLastDay(date, type);
};

export const pickerFormatArray = ["MM/dd/yyyy", "MM/yyyy", "yyyy"];
export const colorArray = [
	"#00aeef",
	"#6bcff6",
	"#cedc28",
	"#fff101",
	"#f89b6c",
	"#16c172",
	"#b5e4b2",
	"#9ccc78",
	"#aefca9",
	"#a1e8cc",
	"#16c172",
	"#b5e4b2",
	"#9ccc78",
	"#aefca9",
	"#a1e8cc",
];
