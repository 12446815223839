import React, { useState, useCallback } from "react";
import {
	ResponsiveContainer,
	Label,
	Bar,
	XAxis,
	YAxis,
	Surface,
	Symbols,
	Tooltip,
	BarChart,
	Legend,
	CartesianGrid,
	Cell,
} from "recharts";
import { map, includes, toPairs, omit } from "lodash";
import { formatChartData, formatXAxis } from "./formHelper";
import { colorArray } from "../../utils/helper";
import {
	cartesianGridProps,
	yAxisProps,
	xAxisProps,
	toolTipProps,
	legendProps,
	barChartProps,
	singleToolTipProps,
	xAxisLabelTypeArray,
	yAxisLabelTypeArray,
	yAxisLabelStyle,
	xAxisLabelStyle,
	formatDate,
} from "./viewComponents";
import TableLoader from "../../utils/table/tableLoader";
import FileSaver from "file-saver";
import { ButtonPrimary } from "../../elements";
import { Row } from "react-bootstrap";
import html2canvas from "html2canvas";
const Chart = ({ data, type, timeFrame, restoreGraph, reportName, isLoading }) => {
	if (type === "cases_per_lab_hour" || type === "lbs_per_lab_hour" || type === "total_yield") {
		restoreGraph["restore"] = 1;
	}
	const plotData = data.data;
	const yAxisData =
		plotData && plotData.product_varieties
			? map(plotData.product_varieties, "name")
			: plotData && plotData.skus
			? map(plotData.skus, "name")
			: plotData.parameters;
	const xAxisData =
		plotData && plotData.chartData && plotData.product_varieties
			? Object.keys(plotData.chartData)
			: plotData && plotData.chartData && plotData.skus
			? Object.keys(plotData.chartData)
			: plotData.parameters
			? map(plotData.chartData, "name")
			: [];

	const graphData =
		plotData && plotData.chartData && plotData.product_varieties
			? formatChartData(xAxisData, plotData.chartData, "product_varieties", plotData.product_varieties)
			: plotData && plotData.chartData && plotData.skus
			? formatChartData(xAxisData, plotData.chartData, "skus", plotData.skus)
			: plotData.parameters
			? plotData.chartData
			: [];
	const [disabled, setDisabled] = useState([]);
	const [enabled, setEnabled] = useState("");
	const [singleChartData, setSingleChartData] = useState(
		plotData && plotData.product_varieties ? [] : plotData && plotData.skus ? [] : graphData
	);
	const [date, setDate] = useState("");
	const [total, setTotal] = useState("");
	const chartColors = [];
	yAxisData.map((column, i) => (chartColors[column] = colorArray[i]));
	const handleClick = (dataKey) => {
		if (dataKey === enabled) {
			setDisabled([]);
			setEnabled("");
		} else {
			setDisabled(yAxisData.filter((obj) => obj !== dataKey));
			setEnabled(dataKey);
		}
	};
	const CustomTooltip = ({ active, payload }) => {
		if (active && payload[0] && payload[0]["payload"]) {
			const toolTipHtml = toPairs(omit(payload[0]["payload"], ["name", "total"])).map((pair, i) => (
				<li key={i} className="recharts-tooltip-item">
					<span className="recharts-tooltip-item-name">{pair[0]}</span>
					<span className="recharts-tooltip-item-separator"> : </span>
					<span className="recharts-tooltip-item-value">{pair[1]}</span>
				</li>
			));
			return (
				<div className="custom-tooltip recharts-default-tooltip custom-scroll">
					<p className="recharts-tooltip-label"> {formatDate(payload[0]["payload"]["name"])}</p>
					<ul className="recharts-tooltip-item-list">
						{toolTipHtml}
						<li className="recharts-tooltip-item">
							<span className="recharts-tooltip-item-name">Total</span>
							<span className="recharts-tooltip-item-separator"> : </span>
							<span className="recharts-tooltip-item-value">
								{String(payload[0]["payload"]["total"]) ? payload[0]["payload"]["total"] : "0"}
							</span>
						</li>
					</ul>
				</div>
			);
		}
		return null;
	};

	const renderCusomizedLegend = ({ payload }) => {
		return (
			<div className="customized-legend ">
				{restoreGraph.restore === 0 ? (
					payload.map((entry, i) => {
						const { dataKey, color } = entry;
						const active = includes(disabled, dataKey);
						const style = {
							color: active ? "#AAA" : "#000",
							marginLeft: "10px",
						};

						return color ? (
							<span key={i} onClick={() => handleClick(dataKey)} style={style}>
								<Surface width={10} height={10}>
									<Symbols cx={5} cy={5} type="circle" size={50} fill={color ? color : "#fff"} />
									{active && <Symbols cx={5} cy={5} type="circle" size={25} fill={"#FFF"} />}
								</Surface>
								<span>{dataKey}</span>
							</span>
						) : (
							""
						);
					})
				) : type !== "lbs_per_lab_hour" && type !== "cases_per_lab_hour" && type !== "total_yield" ? (
					<span>
						<div className="legend-single-details-class d-flex justify-content-between">
							<div className=" d-flex justify-content-start">
								<div>Date :{date} </div>&nbsp; <div> Total :{total} </div>{" "}
							</div>

							<button type="button" className="back-button-reports" onClick={() => handleReset()}>
								Back
							</button>
						</div>
					</span>
				) : (
					""
				)}
			</div>
		);
	};
	const setChartDailyArray = (barData) => {
		const singleDayChartData = toPairs(omit(barData.payload, ["name", "total"])).map((pair) => ({
			name: pair[0],
			value: pair[1],
		}));
		setDate(timeFrame === "Daily" ? formatXAxis(barData.payload.name) : barData.payload.name);
		setTotal(barData.payload.total);
		setSingleChartData(singleDayChartData);
		restoreGraph["restore"] = 1;
	};

	const handleReset = () => {
		restoreGraph["restore"] = 0;
		setSingleChartData([]);
		setTotal("");
		setDate("");
	};

	const handleAreaDownload = useCallback(async () => {
		html2canvas(document.querySelector("#capture")).then((canvas) => {
			const dataURL = canvas.toDataURL();
			FileSaver.saveAs(dataURL, "graph.png");
		});
	}, []);

	return plotData && !isLoading && plotData.chartData ? (
		<>
			<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-4">
				<div className="card h-100" style={{ marginLeft: "90.2%", border: "none" }}>
					<Row>
						<ButtonPrimary className="reset-btn" style={{ float: "right" }} onClick={handleAreaDownload}>
							<span>
								Download <i className="icon icon-export text-sm" style={{ fontWeight: "bold" }} />
							</span>
						</ButtonPrimary>
					</Row>
				</div>
			</div>
			<div style={{ padding: "10px" }} id="capture">
				<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-4">
					<div className="card h-100">
						<div className="card-body">
							{restoreGraph.restore === 1 ? (
								<div>
									<ResponsiveContainer aspect={3}>
										<BarChart
											{...barChartProps}
											data={
												type !== "lbs_per_lab_hour" &&
												type !== "cases_per_lab_hour" &&
												type !== "total_yield"
													? singleChartData
													: graphData
											}>
											<CartesianGrid {...cartesianGridProps} />
											<Legend
												{...legendProps}
												wrapperStyle={{ top: 0, left: 25, bottom: 20 }}
												content={renderCusomizedLegend}
											/>
											<XAxis {...xAxisProps}>
												<Label
													value={
														plotData && plotData.product_varieties
															? "Product Variety"
															: plotData && plotData.skus
															? "Skus"
															: xAxisLabelTypeArray[timeFrame]
													}
													{...xAxisLabelStyle}
												/>
											</XAxis>
											<YAxis
												{...yAxisProps}
												label={{ value: yAxisLabelTypeArray[type], ...yAxisLabelStyle }}
											/>
											<Tooltip {...singleToolTipProps} labelFormatter={(value) => formatDate(value)} />
											<Bar dataKey="value">
												{singleChartData.map((entry, index) => (
													<Cell
														key={`cell-${index}`}
														fill={
															plotData && plotData.product_varieties
																? chartColors[entry.name]
																: plotData && plotData.skus
																? chartColors[entry.name]
																: "#f89b6c"
														}
														stroke={
															plotData && plotData.product_varieties
																? chartColors[entry.name]
																: plotData && plotData.skus
																? chartColors[entry.name]
																: "#f89b6c"
														}
													/>
												))}
											</Bar>
										</BarChart>
									</ResponsiveContainer>
								</div>
							) : (
								<div>
									<ResponsiveContainer aspect={3}>
										<BarChart {...barChartProps} data={graphData}>
											<CartesianGrid {...cartesianGridProps} />

											<XAxis {...xAxisProps}>
												<Label
													value={
														xAxisLabelTypeArray[timeFrame]
															? xAxisLabelTypeArray[timeFrame]
															: "Product Variety"
													}
													{...xAxisLabelStyle}
												/>
											</XAxis>
											<YAxis
												{...yAxisProps}
												label={{
													value: yAxisLabelTypeArray[type],

													...yAxisLabelStyle,
												}}
											/>
											<Tooltip
												{...toolTipProps}
												wrapperStyle={{ pointerEvents: "auto" }}
												content={CustomTooltip}
											/>

											<Legend
												{...legendProps}
												payload={toPairs(chartColors).map((pair) => ({
													dataKey: pair[0],
													color: pair[1],
												}))}
												content={renderCusomizedLegend}
											/>
											{toPairs(chartColors)
												.filter((pair) => !includes(disabled, pair[0]))
												.map((pair) => (
													<Bar
														isAnimationActive={false}
														stackId="a"
														key={pair[0]}
														dataKey={pair[0]}
														fill={pair[1]}
														onClick={(data, index) => setChartDailyArray(data, index)}></Bar>
												))}
										</BarChart>
									</ResponsiveContainer>
								</div>
							)}

							{isLoading && (
								<div className="table-loader-wrapper">
									<TableLoader />
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		</>
	) : (
		""
	);
};

export default Chart;
