import { createSelectorCreator, defaultMemoize } from "reselect";
import { isEqual, isEmpty, keys } from "lodash";
import Util from "utils/Util";
import moment from "moment";

const getDosingCalc = (state) => state.DosingCalculator;

const getForm = (state) => state.form;

const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);

const getDropdownData = createDeepEqualSelector([getDosingCalc], (data) => data.dropdownData);

export const dosingCalcForm = createDeepEqualSelector([getForm], (form) => form && form.dosingCalcForm);

export const locationSelected = createDeepEqualSelector([dosingCalcForm], (form) =>
	form && form.values && form.values.location ? form.values.location : null
);

export const getLoading = createDeepEqualSelector([getDosingCalc], (wsr) => wsr && wsr.loading);
export const getRenderType = createDeepEqualSelector([getDosingCalc], (data) => data && data.renderType);

export const getLocationList = createDeepEqualSelector([getDropdownData], (data) =>
	data && !isEmpty(data) ? Util.mapOptions(data, "location", "slug") : []
);

const formatPondList = (data, location) => {
	const ind = data.findIndex((item) => item.slug === location.value);
	if (ind !== -1) {
		const tempData = data[ind].pond_details;
		const pondList = !isEmpty(tempData)
			? tempData.map((item) => ({
					label: item.value,
					value: item.id,
			  }))
			: [];
		return pondList;
	} else {
		return [];
	}
};
export const getPondList = createDeepEqualSelector([getDropdownData, locationSelected], (data, location) =>
	data && !isEmpty(data) && location ? formatPondList(data, location) : []
);

export const getFormFromDate = createDeepEqualSelector([dosingCalcForm], (form) =>
	form && form.values && form.values.week && form.values.week[0] ? form.values.week[0] : null
);
export const getFormToDate = createDeepEqualSelector([dosingCalcForm], (form) =>
	form && form.values && form.values.week && form.values.week[1] ? form.values.week[1] : null
);

const fromDateFormat = createDeepEqualSelector([getFormFromDate], (date) => (date ? new Date(date) : null));

export const getThirtyWeeks = createDeepEqualSelector([fromDateFormat], (date) =>
	date
		? new Date(
				moment(date.setDate(date.getDate() + 7 * 29))
					.endOf("week")
					.format("MM-DD-YYYY")
		  )
		: null
);
export const getData = createDeepEqualSelector([getDosingCalc], (dosing) => dosing.data);
export const getInitialData = createDeepEqualSelector([getDosingCalc], (dosing) => dosing.initialValues);
export const getInitials = createDeepEqualSelector([getDosingCalc], (dosing) => dosing.initialData);


export const getDosingDate = createDeepEqualSelector([getDosingCalc], (dosing) => dosing.data.dose_added_date);

export const getFertilizerTableData = createDeepEqualSelector([getDosingCalc], (data) =>
	data && !isEmpty(data) && data.fertiliserData ? data.fertiliserData : null
);

export const getActivePondTab = createDeepEqualSelector([getDosingCalc], (data) => data.activePond);
export const getPondTabData = createDeepEqualSelector([getDosingCalc], (data) => data.pondTabs);
export const getCurrentPondId = createDeepEqualSelector([getDosingCalc], (data) => data.pondId);



export const getGallonName = (form, gallon) => {
	const item_gallon = `gallons_${gallon}`;
	return form.calculateForm.values && form.calculateForm.values[item_gallon]
};
export const getGallonsValue = createDeepEqualSelector([getForm, getCurrentPondId], (form, gallon) =>
	form && form.calculateForm && form.calculateForm.values
		? getGallonName(form, gallon)
		: null
);

const formatPvList = (data) => {
	const list = data.map((item) => ({
		label: item,
		value: item,
	}));
	return list;
};

export const getPvDropdownList = createDeepEqualSelector([getData], (data) =>
	data && data.resultTable && data.resultTable.result_table ? formatPvList(keys(data.resultTable.result_table)) : null
);

export const getPvSelected = createDeepEqualSelector([getDosingCalc], (dosing) => dosing.pvSelected);

export const getResultTableData = createDeepEqualSelector([getActivePondTab,getDosingCalc, getPvSelected], (pond,data, pv) =>
	pv && data && data[pond].resultTable && data[pond].resultTable.result_table
		? data[pond].resultTable.result_table[pv.value]
			? data[pond].resultTable.result_table[pv.value]
			: []
		: null
);

export const getSeasonName = createDeepEqualSelector([getData], (data) => data && data.season);

export const getSaveEnable = createDeepEqualSelector([getDosingCalc,getActivePondTab], (dosing,pond) => dosing[`saving_${pond}`]);

export const getSelectedPond = createDeepEqualSelector([dosingCalcForm], (form) =>
	form && form.values && form.values.pond ? form.values.pond : null
);

const setGallons=(data,id)=>{
	const initialValues = {};
	const items = keys(data);
	items.forEach(function (val, i) {
			initialValues[`gallons_${data[val].pond_id}`] = data[val].galloons
	});
	return initialValues;
}
//set initial gallon values
export const getGallonsInitial = createDeepEqualSelector([getInitialData,getCurrentPondId], (data,id) => data && id ? setGallons(data,id) : null);

export const getDosingCalcEditMode = createDeepEqualSelector([getDosingCalc], (data) => data.editMode);

export const getDosingCalcEditFormInitials = createDeepEqualSelector([getData, getDosingCalcEditMode], (data, edit) =>
	edit && data && data.formInitials ? data.formInitials : {}
);

export const dosingCalculatorEditData = createDeepEqualSelector([getDosingCalc], (dosing) => dosing.dosingCalculorEditData);

export const getDosingCalcSlug = createDeepEqualSelector([dosingCalculatorEditData], (data) => data && data.dosing_calculator_slug);

const dosingPermit = (state) => state.Auth.permissionDetails.dosing_calculator;

export const getAddPermission = createDeepEqualSelector(
	[dosingPermit],
	(access) => access && access.permissions.add_dosing_calculator
);

const tabKeys = (data) => {
	const key = [];
	data && data.map((item)=>{
		key.push({
			key:item,
			label:item
		})
	})
	return key;
};
export const pondKeyLabels = createDeepEqualSelector([getDosingCalc], (data) =>
	data && !isEmpty(data.ponds) ? tabKeys(data.ponds) : null
);

export const getTabsPonds = createDeepEqualSelector([pondKeyLabels], (data) => (data && !isEmpty(data) ? data : null));
