import React, { useState } from "react";
import { connect } from "react-redux";
import { Container, Button, Row, Col, Form } from "react-bootstrap";
import { ButtonLink } from "../../../elements";
import { reduxForm, Field, reset, change, untouch, getFormValues } from "redux-form";
import { useDispatch } from "react-redux";
import FormGroups from "../../../utils/formGroup";
import Util from "../../../utils/Util";
import { capitalize, isEmpty } from "lodash";
import TableLoader from "../../../utils/table/tableLoader";

import {
	formCreationFieldsTop,
	formCreationFieldsMiddle,
	formCreationFieldsBottom,
	getValidations,
	dropdownArrays,
} from "./formHelper";
import { createTransplantTask } from "../../../store/actions";
import useModal from "../../../utils/useModal";
import { toggleMainLoader } from "../../../store/actions";
import { errorMessage } from "../../../utils/errorMessage";
import { successMessage } from "../../../utils/successMessage";

import { useTranslation } from 'react-i18next';

import { findKey } from "lodash";

const TransplantAddEdit = ({
	handleSubmit,
	createTransplantTask,
	handleEditPanel,
	toggleMainLoader,
	reset,
	form,
	onAddOrEdit,
	initialValues,
	locationList,
	metricsList,
	selectedLocation,
	formValues,
}) => {
	const { t } = useTranslation();
	const [, modalType, , closeModal] = useModal("transplant");
	const [isLoading, setIsLoading] = useState(false);
	const [btnTitle, setbtnTitle] = useState(t("label.save"));
	const [date, setDate] = useState(
		initialValues && initialValues.expected_transplant_date ? initialValues.expected_transplant_date : ""
	);

	const [locationMetricsList, setlocationMetricsList] = useState(
		initialValues && initialValues.transplantMapArray ? initialValues.transplantMapArray : [{ key: 0, flag: "add" }]
	);

	const [locationPondLaneMappedArray, setLocationPondLaneMappedArray] = useState(
		modalType === 1 || modalType === 4
			? initialValues && initialValues.location
				? metricsList[initialValues.location.value]
				: []
			: modalType === 0
			? selectedLocation
				? metricsList[selectedLocation.value]
				: []
			: []
	);

	const dispatch = useDispatch();

	const submitTransplantDetails = (values) => {
		if (!isLoading) {
			setIsLoading(true);
			setbtnTitle(t("label.saving"));
			const metrics = locationMetricsList.map(function (metric) {
				return {
					...values["lm_" + metric.key],
					pond_number: values["lm_" + metric.key].pond_number.value,
					lane_number: values["lm_" + metric.key].lane_number.value,
				};
			});

			const submissionData = {
				location_slug: values.location.value ? values.location.value : "",
				product_variety_slug: values.product_variety.value ? values.product_variety.value : "",
				transplant_details: metrics,
				flag_color: values.flag_color.value ? values.flag_color.value : "",
				expected_transplant_date: values.expected_transplant_date
					? Util.getMDYFormatUtcDate(values.expected_transplant_date)
					: "",
				task_description: values.task_description ? values.task_description : "",
				assignee_id: values.assignee_id ? values.assignee_id.value : "",
			};

			const successHandler = (event) => {
				onAddOrEdit && onAddOrEdit(event);
				reset(form);
				successMessage(event);
				closeModal();
				setIsLoading(false);
				setbtnTitle(t("label.save"));
			};
			const errorHandler = (event) => {
				setIsLoading(false);
				setbtnTitle(t("label.save"));
				errorMessage(event);
			};
			const method = modalType && modalType !== 4 ? "PUT" : "POST";
			const payload = modalType && modalType !== 4 ? initialValues.task_id : "";

			createTransplantTask(submissionData, method, payload, successHandler, errorHandler);
		}
	};

	const getLaneList = (pond, id) => {
		const laneCount = locationPondLaneMappedArray["lanes_per_pond"];

		const lanesSelected = [];

		locationMetricsList.forEach((listItem, idx) => {
			if (
				// idx !== id &&
				listItem.key !== id &&
				formValues &&
				formValues[`lm_${listItem.key}`] &&
				formValues[`lm_${listItem.key}`]["pond_number"] &&
				formValues[`lm_${listItem.key}`]["pond_number"]["value"] &&
				formValues[`lm_${listItem.key}`]["pond_number"]["value"].toString() === pond.toString() &&
				formValues[`lm_${listItem.key}`]["lane_number"] &&
				formValues[`lm_${listItem.key}`]["lane_number"]["value"]
				// formValues[`lm_${idx}`]["lane_number"]["value"] !== formValues[`lm_${id}`]["lane_number"]["value"]
			) {
				lanesSelected.push(formValues[`lm_${listItem.key}`]["lane_number"]["value"]);
			}
		});

		const arr = Array(laneCount)
			.fill()
			.map((_, i) => ({
				label: i + 1,
				value: (i + 1).toString(),
				...(!isEmpty(lanesSelected)
					? { isdisabled: lanesSelected.includes((i + 1).toString()) ? true : false }
					: { isdisabled: false }),
			}));

		return arr;
	};

	const onPondChange = (id) => {
		formValues[`lm_${id}`] &&
			formValues[`lm_${id}`]["lane_number"] &&
			formValues[`lm_${id}`]["lane_number"]["value"] &&
			dispatch(change("transplantForm", `lm_${id}.lane_number`, null));
	};

	const getOptions = (field, id) => {
		switch (field) {
			case "pond_number":
				return Util.mapOptions(locationPondLaneMappedArray["pond"], "value", "id") || [];
			case "lane_number":
				// return enumerateOptions(locationPondLaneMappedArray, "lanes_per_pond");
				return (
					(formValues &&
						formValues[`lm_${id}`] &&
						formValues[`lm_${id}`].pond_number &&
						formValues[`lm_${id}`].pond_number.value &&
						getLaneList(formValues[`lm_${id}`].pond_number.value, id)) ||
					[]
				);
			case "assignee_id":
				return locationPondLaneMappedArray && locationPondLaneMappedArray.users
					? Util.mapOptions(locationPondLaneMappedArray.users, "name", "id")
					: [];
			case "product_variety":
				return locationPondLaneMappedArray && locationPondLaneMappedArray.product_varieties
					? Util.mapOptions(locationPondLaneMappedArray.product_varieties, "product_variety_name", "slug")
					: [];
			case "location":
				return Util.mapOptions(locationList, "name", "slug");
			case "flag_color":
				return dropdownArrays.flagColors;

			default:
				return [];
		}
	};

	const remapLocations = (value) => {
		value && setLocationPondLaneMappedArray(metricsList[value]);
		dispatch(change("transplantForm", "product_variety", []));
		dispatch(change("transplantForm", "assignee_id", []));
		locationMetricsList &&
			locationMetricsList.forEach(function (x) {
				dispatch(change("transplantForm", `lm_${x.key}.pond_number`, []));
				dispatch(change("transplantForm", `lm_${x.key}.lane_number`, []));
			});
	};

	const getFormFieldHtml = (fields) => {
		let formFieldHtml = [];
		formFieldHtml = fields.map((field, idx) => {
			return (
				<React.Fragment key={idx}>
					<Col
						className={
							field.name === "task_description" || field.name === "assignee_id"
								? "col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
								: "col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12"
						}>
						<Form.Group controlId={`formControl ${field.name}`}>
							{field.type === "select-react" ? (
								<Field
									name={field.name}
									type={field.type}
									label={t(field.label)}
									placeholder={t(field.placeholder)}
									component={FormGroups}
									validate={getValidations(field.name)}
									options={getOptions(field.name)}
									selectMenuHeight={field.maxMenuHeight}
									onChange={(event) => field.name === "location" && remapLocations(event.value)}
								/>
							) : field.name === "expected_transplant_date" ? (
								<Field
									name={field.name}
									type={field.type}
									label={t(field.label)}
									placeholder={t(field.placeholder)}
									component={FormGroups}
									minDate={field.minDate}
									validate={getValidations(field.name)}
									selectedDate={date}
									onChange={(value) => setDate(value)}
								/>
							) : (
								<Field
									name={field.name}
									type={field.type}
									label={t(field.label)}
									placeholder={t(field.placeholder)}
									component={FormGroups}
									validate={getValidations(field.name)}
								/>
							)}
						</Form.Group>
					</Col>
				</React.Fragment>
			);
		});
		return <>{formFieldHtml}</>;
	};

	const metricsFormFields = (count, flag = "add", i) => {
		let columns = [];

		columns = formCreationFieldsMiddle.map((field, idx) => {
			return (
				<Col className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12" key={idx}>
					<Form.Group controlId={`formControl ${idx}`}>
						{field.type === "select-react" ? (
							<Field
								name={`lm_${count}.${field.name}`}
								type={field.type}
								label={t(field.label)}
								placeholder={t(field.placeholder)}
								component={FormGroups}
								validate={getValidations(field.name)}
								options={getOptions(field.name, count)}
								onChange={() => (field.name === "pond_number" ? onPondChange(count) : null)}
							/>
						) : (
							<Field
								name={`lm_${count}.${field.name}`}
								type={field.type}
								label={t(field.label)}
								placeholder={t(field.placeholder)}
								component={FormGroups}
								validate={getValidations(field.name)}
							/>
						)}
					</Form.Group>
				</Col>
			);
		});

		return (
			<React.Fragment key={i}>
				<div className="outer-box">
					<Row>{columns}</Row>
					<Row>
						{locationMetricsList.length - 1 === i ? (
							<Col
								className={`d-flex justify-content-center text-center ${
									locationMetricsList.length === 1 ? "col-xl-12 col-lg-12  " : "col-xl-6 col-lg-6"
								} col-md-12 col-sm-12 col-12`}>
								<ButtonLink onClick={() => setMetricsArray(count + 1)}>
									<i className="icon icon-plus mr-2" />
									{t("label.Add")}
								</ButtonLink>
							</Col>
						) : (
							""
						)}
						{locationMetricsList.length !== 1 ? (
							<Col
								className={`d-flex justify-content-center text-center ${
									locationMetricsList.length - 1 !== i ? "col-xl-12 col-lg-12  " : "col-xl-6 col-lg-6"
								} col-md-12 col-sm-12 col-12`}>
								<ButtonLink className="remove-link-btn" onClick={() => removeMetricsArray(count)}>
									<i className="icon icon-minus mr-2" />
									{t("label.remove")}
								</ButtonLink>
							</Col>
						) : (
							""
						)}
					</Row>
				</div>
			</React.Fragment>
		);
	};
	const setMetricsArray = (count) => {
		dispatch(change("transplantForm", "lm_" + count + ".pond_number", []));
		dispatch(untouch("transplantForm", "lm_" + count + ".pond_number", []));
		dispatch(change("transplantForm", "lm_" + count + ".lane_number", []));
		dispatch(untouch("transplantForm", "lm_" + count + ".lane_number", []));
		dispatch(change("transplantForm", "lm_" + count + ".number_of_boards", []));
		dispatch(untouch("transplantForm", "lm_" + count + ".number_of_boards", []));
		setlocationMetricsList([
			...locationMetricsList,
			{
				key: count,
				flag: "add",
			},
		]);
	};
	const removeMetricsArray = (index) => {
		const locationMetricsListKeyToRemove = findKey(locationMetricsList, function (o) {
			return o.key === index;
		});

		const list = [...locationMetricsList];
		list.splice(locationMetricsListKeyToRemove, 1);
		setlocationMetricsList(list);
	};
	return (
		<>
			<Form onSubmit={handleSubmit(submitTransplantDetails)} className="transplantForm">
				<Container className="position-relative">
					<Row>{getFormFieldHtml(formCreationFieldsTop)}</Row>

					{setlocationMetricsList &&
						locationMetricsList &&
						locationMetricsList.map((x, i) => {
							return metricsFormFields(x.key, x.flag, i);
						})}

					<Row>{getFormFieldHtml(formCreationFieldsBottom)}</Row>
					{/* Loader */}
					{isLoading && (
						<div className="table-loader-wrapper">
							<TableLoader />
						</div>
					)}
				</Container>
				<div className="modal-footer save-footer">
					<Button variant="secondary" onClick={closeModal}>
						{t(("label.cancel"))}
					</Button>
					<Button variant="primary" type="submit" disabled={isLoading}>
						{btnTitle}
					</Button>
				</div>
			</Form>
		</>
	);
};

const mapStateToProps = (state) => {
	const { Transplant, Auth, Modals } = state;
	const transplantTaskDetailsArray = Transplant.transplantTaskDetails;
	const initialArray = () => {
		const transplantMetrics =
			transplantTaskDetailsArray && transplantTaskDetailsArray.transplant_and_growing_task_details
				? transplantTaskDetailsArray.transplant_and_growing_task_details
				: [];
		const transplantMetricsArray = [];
		transplantMetrics.forEach(function (data, key) {
			transplantMetricsArray["lm_" + key] = {
				pond_number: { value: data["pond_number"]["id"].toString(), label: data["pond_number"]["value"].toString() },
				lane_number: { value: data["lane_number"].toString(), label: data["lane_number"].toString() },
				number_of_boards: data["number_of_boards"],
			};
		});
		const transplantMapArray = [];
		transplantMetrics.forEach(function (data, key) {
			transplantMapArray[key] = [];
			transplantMapArray[key]["key"] = key;
			transplantMapArray[key]["flag"] = "edit";
		});
		const expected_date = Util.getDateObjectFormat(transplantTaskDetailsArray.expected_transplant_date);
		const formatedInitialValueArray = {
			...transplantMetricsArray,
			...transplantMapArray,
			transplantMapArray: transplantMapArray,
			expected_transplant_date: expected_date,
			task_id: transplantTaskDetailsArray.task_id,
			task_description: transplantTaskDetailsArray.task_description ? transplantTaskDetailsArray.task_description : "",
			location:
				transplantTaskDetailsArray.location && transplantTaskDetailsArray.location.deleted_at === null
					? transplantTaskDetailsArray.location
					: {},
			product_variety:
				transplantTaskDetailsArray.location &&
				transplantTaskDetailsArray.location.deleted_at === null &&
				transplantTaskDetailsArray.product_variety
					? {
							label: transplantTaskDetailsArray.product_variety.name,
							value: transplantTaskDetailsArray.product_variety.slug,
					  }
					: {},
			flag_color: transplantTaskDetailsArray.flag_color
				? {
						label: capitalize(transplantTaskDetailsArray.flag_color),
						value: transplantTaskDetailsArray.flag_color,
				  }
				: {},

			assignee_id:
				transplantTaskDetailsArray.location &&
				transplantTaskDetailsArray.location.deleted_at === null &&
				transplantTaskDetailsArray.assignee
					? transplantTaskDetailsArray.assignee
					: {},
		};
		return formatedInitialValueArray;
	};
	return {
		locationList: Auth.userLocations,
		metricsList: Transplant.locationMapping,
		selectedLocation: Transplant.selectedLocation,
		initialValues: Modals.modalType
			? initialArray()
			: { location: Transplant.selectedLocation ? Transplant.selectedLocation : "" },
		formValues: getFormValues("transplantForm")(state),
	};
};

const mapDispatchToProps = {
	toggleMainLoader,
	createTransplantTask,
	reset,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(
	reduxForm({
		form: "transplantForm",
		enableReinitialize: true,
	})(TransplantAddEdit)
);
