import { KPI } from "../types";
const initialState = {
	locationKpiDetails: [],
};
export default (state = initialState, action) => {
	switch (action.type) {
		case KPI.GET_LOCATION_KPI_DETAILS:
			return {
				...state,
				locationKpiDetails: action.payload ? action.payload : [],
			};

		default:
			return state;
	}
};
