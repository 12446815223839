import React from "react";
import { connect } from "react-redux";
import { PieChart, Pie, Legend, Cell, ResponsiveContainer, Tooltip } from "recharts";
import { singleToolTipProps, legendProps } from "../chartComponentHelper";
import { pieChartColorArray } from "../../../utils/helper";
import { createStructuredSelector } from "reselect";
import { getCasesPackedArray } from "store/selectors/dashboardSelectors";
import ContentLoader from "../../contentLoader/contentLoader";
import { useTranslation } from "react-i18next";

const CasesGraphBlock = ({ getCasesPackedArray, loadingFlag }) => {
	const { t } = useTranslation();
	return (
		<div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6 mb-4 ">
			<div className="card h-100">
				<div className="card-body">
					<div className="graph-headers"> {t("label.orders_cases_packed")}</div>
					{loadingFlag ? (
						<ContentLoader type="pie" />
					) : Object.keys(getCasesPackedArray).length !== 0 ? (
						<ResponsiveContainer aspect={2}>
							<PieChart>
								<Pie
									data={getCasesPackedArray}
									labelLine={false}
									outerRadius={100}
									fill="#8884d8"
									dataKey="value">
									{getCasesPackedArray.map((entry, index) => (
										<Cell
											key={`cell-${index}`}
											fill={
												pieChartColorArray[index]
													? pieChartColorArray[index]
													: pieChartColorArray[index % pieChartColorArray.length]
											}
										/>
									))}
								</Pie>
								<Legend {...legendProps} />
								<Tooltip {...singleToolTipProps} />
							</PieChart>
						</ResponsiveContainer>
					) : (
						<p className="card-text-empty">{t("no_data_available")}</p>
					)}
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = createStructuredSelector({
	getCasesPackedArray,
});
export default connect(mapStateToProps, {})(CasesGraphBlock);
