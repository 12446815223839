import React, { createRef, useState, useEffect, useRef, useCallback } from "react";
import { connect } from "react-redux";
import "./index.scss";
import { reduxForm } from "redux-form";
import Util from "../Util";
import ScrollContainer from "react-indiana-drag-scroll";
import { fixedColumnTable, getData } from "./columns";
import { Form } from "react-bootstrap";
import { isEmpty, keys } from "lodash";

const TableForm = ({
	initialData: data,
	headings,
	fixedColumnHeading,
	fixedColumnWidth = 133,
	editable,
	onSave,
	onDelete,
	keyMapping,
	dateFormat,
	tableErrors,
	errorStrings,
	render,
	renderKey,
	slugKey,
	initialValues,
	actionColumnWidth = 100,
	handleSubmit,
	tableId = "table",
	pristine,
	valueChanged,
	reset,
}) => {
	const inputRef = useRef(null);
	const draggableRef = createRef(null);
	const scrollRef = createRef(null);
	const fixedRef = createRef(null);
	const [editableRows, setEditableRows] = useState([]);

	useEffect(() => {
		onScrollTrack();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		// if (!pristine) {
		valueChanged && valueChanged(!pristine);
		// }
	}, [pristine, valueChanged]);

	const renderKeyItem = (key) => {
		if (keyMapping) {
			const elem = keyMapping.find((item) => item.slug === key);
			if (elem) {
				return <span>{elem.name}</span>;
			}
		} else {
			return <span>{dateFormat ? Util.checkValidDateString(key) : key}</span>;
		}
	};

	const onScrollTrack = (e) => {
		const shadowElem = [].slice.call(document.getElementsByClassName(`shadow-table-${tableId}`));
		// const rightShadowElem = [].slice.call(document.getElementsByClassName("show-shadow-row-right"));
		if (fixedRef.current && scrollRef.current) {
			const fixedPos = fixedRef.current.getBoundingClientRect().left;
			const scrollingPos = scrollRef.current.getBoundingClientRect().left;

			if (fixedPos + fixedColumnWidth > scrollingPos) {
				// eslint-disable-next-line array-callback-return
				shadowElem.map((node) => {
					node.style.display = "block";

					node.parentNode.classList.add(`show-shadow`);
				});
			} else {
				// eslint-disable-next-line array-callback-return
				shadowElem.map((node) => {
					node.style.display = "none";
					node.parentNode.classList.remove(`show-shadow`);
				});
			}
		}
	};

	const onClick = useCallback((event) => {
		if (event.target.tagName === "INPUT") {
			inputRef.current = event.target;
			event.target.focus();
		} else {
			if (inputRef.current) {
				inputRef.current.blur();
				inputRef.current = null;
			}
			event.target.click();
		}
	}, []);

	const cancelEdit = (index) => {
		setEditableRows(() => editableRows.filter((item) => item !== index));
	};

	return headings && data ? (
		<div className="dosing-table-wrapper">
			<div ref={fixedRef} className="dosing-fixed-column-wrapper" style={{ width: fixedColumnWidth }}>
				{fixedColumnTable(headings, fixedColumnWidth, fixedColumnHeading, renderKeyItem, tableId)}
			</div>
			<ScrollContainer
				ref={draggableRef}
				hideScrollbars={false}
				className="dosing-data-column-wrapper"
				style={{ width: `calc(100% - ${fixedColumnWidth}px)` }}
				onScroll={(e) => onScrollTrack(e)}
				onClick={onClick}>
				<div ref={scrollRef} className="dosing-data-column">
					<Form onSubmit={handleSubmit} className="dosingTable">
						{getData(data, headings, slugKey, renderKey, render, editableRows, onSave, cancelEdit)}
					</Form>
				</div>
			</ScrollContainer>
		</div>
	) : null;
};

const scrollToFirstError = (fieldList, props) => {
	const { initialData: data, headings } = props;
	// eslint-disable-next-line array-callback-return
	data.some((item) => {
		if (item.slug in fieldList) {
			// eslint-disable-next-line array-callback-return
			headings.some((item_i) => {
				if (item_i.slug in fieldList[item.slug]) {
					const elem = document.querySelector(`#${item.slug}_${item_i.slug}`);

					if (elem) {
						elem.scrollIntoView({ behavior: "smooth", block: "center" });
						return elem;
					}
				}
			});
		}
	});
};

const onSubmit = (data, dispatch, props) => {
	const { headings } = props;
	const customAccessors = headings.filter((item) => item.custom_accessor);

	const values = keys(data).map((item) => {
		if (!isEmpty(customAccessors)) {
			let obj = {};
			// eslint-disable-next-line array-callback-return
			customAccessors.map((accessor) => {
				obj[accessor.slug] = data[item][accessor.custom_accessor];
				obj[accessor.custom_accessor] = data[item][accessor.slug];
			});

			obj = { ...data[item], ...obj, slug: item };

			return obj;
		} else {
			const obj = { slug: item, ...data[item] };
			return obj;
		}
	});
	props.onSave && props.onSave(values);
};

export default connect(null)(
	reduxForm({
		form: "dosingCalculatorTable",
		// destroyOnUnmount: false,
		enableReinitialize: true,
		onSubmitFail: (errors, dispatch, submitError, props) => {
			scrollToFirstError(errors, props);
		},
		onSubmit: onSubmit,
	})(TableForm)
);
