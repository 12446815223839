import { number, required } from "constant/formValidators";
import React from "react";
import { Col } from "react-bootstrap";
import { connect } from "react-redux";
import { Field } from "redux-form";
import { createStructuredSelector } from "reselect";

import { getForecastTableData } from "store/selectors/forecastTableSelectors";

const componentGroup = ({ id, input, label, type, meta: { touched, error, warning } }) => {
	switch (type) {
		case "div":
			return <div>{input.value || input.value === 0 ? input.value : "-"}</div>;
		case "input":
			return (
				<input
					{...input}
					id={id}
					className={`form-control action-table-input ${
						touched ? (error ? "input-error" : warning ? "warning" : "") : ""
					}`}
					placeholder={label}
					type={type}
				/>
			);
		default:
			return;
	}
};

const RenderForecastTableColumn = ({
	b_ind,
	a_ind,
	editable,
	c_item,
	c_ind,
	// onBlurValidation,
	slugKey,
	nameKey,
}) => {
	return (
		<>
			<>
				<Col key={b_ind} className="lab-table-col">
					{/* {editable ? ( */}
					<Field
						id={slugKey ? `${c_item[slugKey]}_${nameKey}` : `${c_item.slug}_${nameKey}`}
						name={slugKey ? c_item[slugKey] : c_item.slug}
						type={editable ? "input" : "div"}
						component={componentGroup}
						validate={[required, number]}
					/>
					{/* ) : (
					<span>{render ? render(c_item) : c_item.value ? c_item.value : "-"}</span>
				)} */}
				</Col>
			</>
		</>
	);
};

const mapStateToProps = createStructuredSelector({
	data: getForecastTableData,
});

const areEqual = (prevProps, nextProps) => {
	return (
		prevProps.b_ind === nextProps.b_ind &&
		prevProps.a_ind === nextProps.a_ind &&
		prevProps.editable === nextProps.editable &&
		prevProps.c_item === nextProps.c_item &&
		prevProps.c_ind === nextProps.c_ind
	);

	//  a_ind, editable, renderKey, render, c_item, tableErrors, data, c_ind
};

export default connect(mapStateToProps)(React.memo(RenderForecastTableColumn, areEqual));
