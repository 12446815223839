import React, { useEffect, useState } from "react";
import ForecastTable from "utils/forecastTable";
import ForecastForm from "../../../manage";
import { createStructuredSelector } from "reselect";
import {
	getForecastAddData,
	getForecastColumns,
	getForecastFormForecast,
	getForecastFormLocation,
} from "store/selectors/forecastSelectors";
import { connect } from "react-redux";
import { setLoader, addForecast, setForecastRenderType, forecastResetRefresh } from "store/actions";
import { successMessage } from "utils/successMessage";
import { errorMessage } from "utils/errorMessage";

const AddForecast = ({
	data,
	columns,
	location,
	forecast,
	setLoader,
	addForecast,
	setForecastRenderType,
	forecastResetRefresh,
}) => {
	const [isOzPerBoard, setIsOzPerBoard] = useState(false);
	const onSave = (values) => {
		// values contain the edited values
		setLoader();
		const submitData = {
			location_slug: location.value,
			forecast: forecast.value,
			forecast_details: values,
		};

		const successHandler = (e) => {
			successMessage(e);
			setLoader();
			forecastResetRefresh();
			setForecastRenderType(0);
		};
		const errorHandler = (e) => {
			errorMessage(e);
			setLoader();
		};
		addForecast(submitData, successHandler, errorHandler);
	};

	const onCancel = () => {
		setForecastRenderType(0);
	};

	useEffect(() => {
		return () => {
			forecastResetRefresh();
		};
	}, [forecastResetRefresh]);

	useEffect(() => {
		if (forecast && forecast.value === "oz_per_board") {
			setIsOzPerBoard(true);
		} else if (forecast && forecast.value === "no_of_boards") {
			setIsOzPerBoard(false);
		}
	}, [forecast]);

	return (
		<div className="listing-wrapper">
			<ForecastForm type={1} />
			{data && (
				<>
					<ForecastTable
						editable={true}
						columns={columns}
						fixedColumnHeading="Date"
						initialData={data}
						onSave={onSave}
						onCancel={onCancel}
						dateFormat="DD-MM-YYYY"
						localeString={false}
						isOzPerBoard={isOzPerBoard}
					/>
				</>
			)}
		</div>
	);
};

const mapStateToProps = createStructuredSelector({
	data: getForecastAddData,
	columns: getForecastColumns,
	forecast: getForecastFormForecast,
	location: getForecastFormLocation,
});

export default connect(mapStateToProps, { setLoader, addForecast, setForecastRenderType, forecastResetRefresh })(AddForecast);
