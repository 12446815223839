import { LR } from "store/types";
import fetchHandler from "utils/fetchHandler";

export const setLoaderLR = () => {
	return (dispatch) => {
		dispatch({
			type: LR.LOADER_LR,
		});
	};
};

export const fetchDropdownDataLR = (successHandler, errorHandler) => {
	const fetchOptions = {
		url: `set-add-lab-results`,
		method: "GET",
		secure: true,
		actionType: LR.SET_DROPDOWN_DATA_LR,
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const setRenderTypeLR = (value) => {
	return (dispatch) => {
		dispatch({
			type: LR.SET_RENDER_TYPE_LR,
			payload: value,
		});
	};
};

export const setColumnsLR = (values, successHandler, errorHandler) => {
	return (dispatch) => {
		dispatch({
			type: LR.SET_COLUMNS_LR,
			payload: values,
		});
	};
};
export const setFormData = (values, successHandler, errorHandler) => {
	return (dispatch) => {
		dispatch({
			type: LR.SET_FORM_LR,
			payload: values,
		});
	};
};

export const setDataLR = (values, successHandler, errorHandler) => {
	return (dispatch) => {
		dispatch({
			type: LR.SET_DATA_LR,
			payload: values,
		});
	};
};

export const resetRefreshLR = () => {
	return (dispatch) => {
		dispatch({
			type: LR.REFRESH_LISTING_LR,
		});
	};
};

export const addDataLR = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `add-lab-results`,
		method: "POST",
		secure: true,
		body: JSON.stringify(values),
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const fetchDataLR = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `list-lab-result`,
		method: "POST",
		secure: true,
		body: JSON.stringify(values),
		actionType: LR.SET_LIST_DATA_LR,
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const fetchEditDataLR = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `list-lab-result`,
		method: "POST",
		secure: true,
		body: JSON.stringify(values),
		actionType: LR.SET_LIST_EDIT_DATA_LR,
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const editDataLR = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `update-lab-results`,
		method: "PUT",
		secure: true,
		body: JSON.stringify(values),
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const deleteDataLR = (slug, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `delete-lab-results/${slug}`,
		method: "DELETE",
		secure: true,
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const uploadLR = (value, successHandler, errorHandler, options) => {
	const fetchOptions = {
		url: `upload-lab-result`,
		method: "POST",
		secure: true,
		fileUpload: true,
		body: value,
	};
	const type = "axios";
	return fetchHandler(fetchOptions, successHandler, errorHandler, type, options);
};

export const fetchCroptimalValue = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `list-lab-result-croptimal`,
		method: "POST",
		secure: true,
		body: JSON.stringify(values),
		actionType: LR.SET_LIST_DATA_CROPTIMAL_LR,
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const setDateMapping = (values) => {
	return (dispatch) => {
		dispatch({
			type: LR.SET_KEY_MAPPING_DATE,
			payload: values,
		});
	};
};
export const setCroptimalData = (values, successHandler, errorHandler) => {
	return (dispatch) => {
		dispatch({
			type: LR.SET_CROPTIMAL_LR,
			payload: values,
		});
	};
};

export const setCroptimalSelection = (values, successHandler, errorHandler) => {
	return (dispatch) => {
		dispatch({
			type: LR.SET_CROPTIMAL_SELECTION,
			payload: values,
		});
	};
};

export const setActiveTabPondLR = (value, type) => {
	return (dispatch) => {
		dispatch({
			type: LR.SET_ACTIVE_TAB_POND_LR,
			payload: value,
		});
	};
};

export const setActivePonds = (value, type) => {
	return (dispatch) => {
		dispatch({
			type: LR.SET_ACTIVE_PONDS,
			payload: value,
		});
	};
};

export const setActivePond = (value, type) => {
	return (dispatch) => {
		dispatch({
			type: LR.SET_ACTIVE_TAB,
			payload: value,
		});
	};
};