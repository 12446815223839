import React, { useEffect, useState } from "react";

import { errorMessage } from "utils/errorMessage";
import { connect } from "react-redux";
import { debounce, get, round } from "lodash";

import "./index.scss";
import { successMessage } from "../../utils/successMessage";

import { Col, Container, Row } from "react-bootstrap";
import moment from "moment";
import { updateHarvestForecast } from "store/actions";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";

const CustomTextField = styled(TextField)({
	"label + &": { borderRadius: 8 },
	"& .MuiInputBase-input": {
		borderRadius: 8,
		userSelect: "all",
	},
	"& label": {
		color: "black",
	},
	"& label.Mui-focused": {
		color: "#006241",
		userSelect: "all",
	},
	"& .MuiInput-underline:after": {
		borderBottomColor: "#006241",
		borderRadius: 8,
	},
	"& .MuiOutlinedInput-root": {
		borderRadius: 8,
		width: "100%",
		"& fieldset": {
			borderColor: "#cdcdcd",
			borderRadius: 8,
			fontWeight: "bold",
		},
		"&:hover fieldset": {
			borderColor: "#000",
			borderRadius: 14,
			fontWeight: "bold",
		},
		"&.Mui-focused fieldset": {
			borderColor: "#006241",
			borderRadius: 8,
		},
	},
});

const DayCell = ({ dayObject, placeholder, type, label, onChange }) => {
	const [value, setValue] = React.useState(dayObject?.forecast_value ? dayObject.forecast_value : 0);
	// console.log(`${dayObject?.date}-${dayObject?.variety_code_from_grower}-${dayObject?.forecast}`);

	React.useEffect(() => {
		// console.log(`${dayObject?.date}-${dayObject?.variety_code_from_grower}-${dayObject?.forecast}`);
	}, [value, dayObject]);
	return (
		<CustomTextField
			key={`${dayObject?.date}-${dayObject?.variety_code_from_grower}-${dayObject?.forecast}`}
			onChange={(e) => {
				setValue(e.currentTarget.value);
			}}
			onBlur={(e) => {
				var updateDay = { ...dayObject };
				updateDay.forecast_value = e.currentTarget.value;
				onChange(updateDay);
			}}
			value={value}
			onFocus={(event) => {
				event.target.select();
			}}
			placeholder={placeholder}
			type={type}
			label={label}
			disabled={moment(dayObject?.date, "YYYY-MM-DD HH:mm:ss").isBefore(moment.now())}
		/>
	);
};

const mapStateToProps = (state) => {
	const { UI, Auth } = state;
	return {
		accessToken: get(Auth, "accessToken", null),
		mainPreloader: get(UI, "mainPreloader", null),
		permissionDetails: get(Auth, "permissionDetails", null),
	};
};

const mapDispatchToProps = {
	updateHarvestForecast,
};
export default connect(mapStateToProps, mapDispatchToProps)(DayCell);
