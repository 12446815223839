import fetchHandler from "../../utils/fetchHandler";
import { CROSS_SUPPLY } from "../types";

// Get cross supply list
export const getCrossSupply = (body, successHandler, errorHandler) => {
	const fetchOptions = {
		url: "list-cross-supply",
		method: "POST",
		body: JSON.stringify(body),
		actionType: CROSS_SUPPLY.GET_CROSS_SUPPLY_LIST,
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

//  Create new Transfer request
export const createCrossSupplyTransfer = (body, successHandler, errorHandler) => {
	const fetchOptions = {
		url: "create-transfer-request",
		method: "POST",
		body: JSON.stringify(body),
		actionType: CROSS_SUPPLY.CREATE_CROSS_SUPPLY_TRANSFER,
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

// Get cross supply Shipping list
export const getCrossSupplyShippingList = (body, successHandler, errorHandler) => {
	const fetchOptions = {
		url: "list-cross-supply-shipping-data",
		method: "POST",
		body: JSON.stringify(body),
		actionType: CROSS_SUPPLY.GET_CROSS_SUPPLY_SHIPPING_LIST,
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

// Get details of a tranfer request
export const getCrossSupplyTransferDetails = (body, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `get-transfer-request-details`,
		method: "POST",
		body: JSON.stringify(body),
		actionType: CROSS_SUPPLY.GET_CROSS_SUPPLY_TRANSFER_DETAILS,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

// Get All Location Sku data for new tranfer request
export const getCrossSupplyUserLocations = (successHandler, errorHandler) => {
	const fetchOptions = {
		url: `get-loc-sku-dropdown`,
		method: "GET",
		actionType: CROSS_SUPPLY.GET_CROSS_SUPPLY_USER_LOCATIONS,
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

//Get All Locations details for listing pages
export const getCrossSupplyListingLocations = (successHandler, errorHandler) => {
	const fetchOptions = {
		url: `get-cross-supply-location-dropdown`,
		method: "GET",
		actionType: CROSS_SUPPLY.GET_CROSS_SUPPLY_LISTING_LOCATIONS,
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

// Listing Page Loader toggler
export const toggleCrossSupplyListLoader = (payload) => {
	return (dispatch) => {
		dispatch({
			type: CROSS_SUPPLY.TOGGLE_CROSS_SUPPLY_LIST_LOADER,
			payload,
		});
	};
};

// View Page Loader toggler
export const toggleCrossSupplyViewLoader = (payload) => {
	return (dispatch) => {
		dispatch({
			type: CROSS_SUPPLY.TOGGLE_CROSS_SUPPLY__VIEW_LOADER,
			payload,
		});
	};
};

// Delete a particular transfer request

export const deleteCrossSupply = (slug, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `delete-transfer-request/${slug}`,
		method: "DELETE",
		actionType: CROSS_SUPPLY.DELETE_CROSS_SUPPLY_TRANSFER_REQUEST,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

// Get locations skus
export const fetchLocationSkusCS = (successHandler, errorHandler) => {
	const fetchOptions = {
		url: `location-skus`,
		method: "GET",
		secure: true,
		actionType: CROSS_SUPPLY.FETCH_LOCATION_SKUS,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};
