import { useDispatch, useSelector } from "react-redux";
import { get } from "lodash";
import { resetField, getProfileDetails } from "../store/actions";
import { errorMessage } from "./errorMessage";

const LSVariable = process.env.REACT_APP_LS_VAR;

/**
 * User details fetch hook
 * @returns {function} getUser |  API calls without any checks
 * @returns {function} getUserIfEmpty | API calls if data is empty
 */

export default function useGetUser() {
	const dispatch = useDispatch();

	const userDetails = useSelector((state) => get(state.Auth, "userDetails", null));

	const getLSVariable = localStorage.getItem(LSVariable);
	const userData = getLSVariable ? JSON.parse(getLSVariable) : null;
	const successHandler = (e) => {
		dispatch(resetField("Auth.userDetails", e.data));
	};
	const errorHandler = () => {
		errorMessage("PROFILE_FETCH_FAILED");
	};

	// Call Directly
	const getUser = () => {
		dispatch(getProfileDetails(successHandler, errorHandler));
	};

	// Call if user details is empty
	const getUserIfEmpty = () => {
		if (!userDetails && userData) {
			dispatch(getProfileDetails(successHandler, errorHandler));
		}
	};

	return [getUser, getUserIfEmpty];
}
