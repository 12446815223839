import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { getSeedRateArray, getSeedKpiData } from "store/selectors/dashboardSelectors";
import { BarChart, Bar, CartesianGrid, ResponsiveContainer, XAxis, YAxis, Tooltip } from "recharts";
import { cartesianGridProps, xAxisProps, yAxisProps, singleToolTipProps, barChartProps } from "../chartComponentHelper";
import ContentLoader from "../../contentLoader/contentLoader";
import { useTranslation } from "react-i18next";

const SeedRateGraphBlock = ({ getSeedRateArray, loadingFlag, getSeedKpiData }) => {
	const { t } = useTranslation();
	return (
		<div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6 mb-4">
			<div className="card h-100">
				<div className="card-body">
					<div className="d-flex justify-content-between">
						<div className="graph-headers">{t("label.seed_rate")}</div>
						<div className="graph-headers">
							{t("label.kpi_target")} - {getSeedKpiData}{" "}
						</div>
					</div>
					{loadingFlag ? (
						<ContentLoader type="bar" />
					) : Object.keys(getSeedRateArray).length !== 0 ? (
						<ResponsiveContainer aspect={2}>
							<BarChart {...barChartProps} data={getSeedRateArray}>
								<CartesianGrid {...cartesianGridProps} />
								<XAxis {...xAxisProps} />
								<YAxis {...yAxisProps} />
								<Tooltip {...singleToolTipProps} />
								<Bar dataKey="value" fill={"#f89b6c"} stroke={"#f89b6c"}></Bar>
							</BarChart>
						</ResponsiveContainer>
					) : (
						<p className="card-text-empty">{t("no_data_available")}</p>
					)}
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = createStructuredSelector({
	getSeedRateArray,
	getSeedKpiData,
});
export default connect(mapStateToProps, {})(SeedRateGraphBlock);
