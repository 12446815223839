import React from "react";
import { map, join, size } from "lodash";
import { ListingBtn } from "elements";
import { errorMessage } from "../../../utils/errorMessage";

export const renderColumns = ({ handleEditPanel, handleDeletePanel, addToFilter, permissionDetails }) => {
	const updatePermission =
		permissionDetails && permissionDetails.greens && permissionDetails.greens.permissions.update_green
			? permissionDetails.greens.permissions.update_green
			: "";
	const deletePermission =
		permissionDetails && permissionDetails.greens && permissionDetails.greens.permissions.delete_green
			? permissionDetails.greens.permissions.delete_green
			: "";
	return [
		{
			name: "Greens Name",
			accessor: "green_name",
			className: "greens-listing-col-name",
			headerClassName: "greens-listing-col-name-header",
			filter: { type: "search", onChange: addToFilter },
			render: (row) => row.green_name,
		},
		{
			name: "Seeds",
			className: "greens-listing-col-seeds",
			headerClassName: "greens-listing-col-seeds-header",
			render: (row) => join(map(row.seeds, "seed_name"), ", "),
			sortable: false,
		},
		{
			name: "Actions",
			className: "greens-listing-col-actions",
			headerClassName: "greens-listing-col-actions-header",
			hide: updatePermission || deletePermission ? false : true,
			render: (row) => {
				return (
					<>
						{updatePermission ? (
							<ListingBtn
								parentClass={
									updatePermission && deletePermission ? "justify-content-end" : "justify-content-around"
								}
								tooltipText="Edit"
								btnClass="btn btn-cell btn-light btn-sm ml-2"
								iconClass="icon icon-edit"
								onClick={() => handleEditPanel(row)}
							/>
						) : (
							""
						)}
						{deletePermission ? (
							<ListingBtn
								parentClass={
									updatePermission && deletePermission ? "justify-content-end" : "justify-content-around"
								}
								tooltipText="Delete"
								btnClass="btn btn-cell btn-light btn-sm ml-2"
								iconClass="icon icon-trash"
								onClick={() =>
									size(row.product_varieties) > 0
										? errorMessage("GREENS_DELETE_DISABLE")
										: handleDeletePanel(row)
								}
							/>
						) : (
							""
						)}
					</>
				);
			},
			sortable: false,
		},
	];
};
