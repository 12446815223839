import { required } from "../../constant/formValidators";
import Util from "../../utils/Util";

export const formCreationFields = [
	{
		label: "Type *",
		name: "graph_types",
		type: "select-react",
		placeholder: "Select Type",
	},
	{
		label: "Location *",
		name: "location",
		type: "select-react-expand-all",
		placeholder: "Select Location",
	},
	{
		label: "Compartment *",
		name: "compartment",
		type: "select-react-expand-all",
		placeholder: "Select Compartment",
		multiple: true,
	},
	{
		label: "Pond Name *",
		name: "ponds",
		type: "select-react-expand-all",
		placeholder: "Select Ponds",
		multiple: true,
	},

	{
		label: "Parameters *",
		name: "parameters",
		type: "select-react-expand-all",
		placeholder: "Select Parameters",
		multiple: true,
	},
	{
		label: "Timeframe *",
		name: "timeframe",
		type: "select-react",
		placeholder: "Select Timeframe",
	},
	{
		label: "Date *",
		name: "date",
		type: "date-range-picker",
		placeholder: "Enter date range",
	},
];
export const formCreationWithoutTimeFrameFields = [
	{
		label: "Type *",
		name: "graph_types",
		type: "select-react",
		placeholder: "Select Type",
	},
	{
		label: "Location *",
		name: "location",
		type: "select-react-expand-all",
		placeholder: "Select Location",
	},
	{
		label: "Compartment *",
		name: "compartment",
		type: "select-react-expand-all",
		placeholder: "Select Compartment",
		multiple: true,
	},

	{
		label: "Parameters *",
		name: "parameters",
		type: "select-react-expand-all",
		placeholder: "Select Parameters",
		multiple: true,
	},
	{
		label: "Date *",
		name: "date",
		type: "date-range-picker",
		placeholder: "Enter date range",
	},
];

export const getValidations = (field) => {
	switch (field) {
		default:
			return [required];
	}
};

export const timeFrameArray = [
	{
		value: "daily",
		label: "Daily",
	},
	{
		value: "monthly",
		label: "Monthly",
	},
	{
		value: "yearly",
		label: "Yearly",
	},
];

export const reportTypeArray = [
	{
		value: "climate_all",
		label: "Climate All",
		permission_key: "climate_all",
	},
	{
		value: "daily_avg",
		label: "Daily Averages",
		permission_key: "daily_averages",
	},
	{
		value: "linear_plot",
		label: "Linear Plot",
		permission_key: "linear_plots",
	},
];

const fieldMap = (list) => {
	const newList = [];
	list.map((field) => {
		return (newList[field.name] = parseFloat(field.value));
	});

	return newList;
};

export const formatChartData = (xAxisData, chartData, type) => {
	const newList = xAxisData.map((value) => {
		const mappedFields = fieldMap(chartData[value][type]);
		return {
			name: value,
			...mappedFields,
			total: chartData[value][type].reduce((a, b) => +(+a + +b.value).toFixed(3), 0),
		};
	});

	return newList ? newList : [];
};

export const getFormattedXaxisData = (xAxisData) => {
	const newList = xAxisData.map((value) => {
		return Util.getDateFormat(value);
	});
	return newList ? newList : [];
};
export const axisStyle = { fontSize: "10px", fontFamily: "Poppins, sans-serif", fontWeight: 500, fontColor: "#43425d" };
export const toolTipStyle = { fontSize: "10px", fontFamily: "Poppins, sans-serif", fontWeight: 500, color: "#43425d" };
export const formatXAxis = (tickItem) => {
	return Util.getDateFormat(tickItem);
};

export const lastDay = (date, type) => {
	return Util.getLastDay(date, type);
};

export const pickerFormatArray = ["MM/dd/yyyy", "MM/yyyy", "yyyy"];
