import React, { useEffect, useState } from "react";

import { errorMessage } from "utils/errorMessage";
import { successMessage } from "../../utils/successMessage";
import { connect } from "react-redux";
import { get } from "lodash";
import { styled } from "@mui/material/styles";

import "./index.scss";

import { Col, Container, Row } from "react-bootstrap";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";

import moment from "moment";
import { updateHarvestForecast } from "store/actions";

const CustomTextField = styled(TextField)({
	"label + &": { borderRadius: 8 },
	"& .MuiInputBase-input": {
		borderRadius: 8,
	},
	"& label": {
		color: "black",
	},
	"& label.Mui-focused": {
		color: "#006241",
	},
	"& .MuiInput-underline:after": {
		borderBottomColor: "#006241",
		borderRadius: 8,
	},
	"& .MuiOutlinedInput-root": {
		borderRadius: 8,
		width: 150,
		"& fieldset": {
			borderColor: "#cdcdcd",
			borderRadius: 8,
			fontWeight: "bold",
		},
		"&:hover fieldset": {
			borderColor: "#000",
			borderRadius: 14,
			fontWeight: "bold",
		},
		"&.Mui-focused fieldset": {
			borderColor: "#006241",
			borderRadius: 8,
		},
	},
});

const ForecastMonth = ({
	updateHarvestForecast,
	greenhouse,
	variety,
	date,
	year,
	currentLane,
	currentPond,
	currentValue = {},
	currentSalesValue = 0,
}) => {
	const [ozPerBoard, setOzPerBoard] = useState({
		forecast_unit: "oz_per_board",
		forecast_type: "monthly",
		growing_lane_number: currentLane,
		growing_location: currentPond,
		location_id: greenhouse.slug,
		date: date,
		product_variety_id: variety.slug,
		forecast_value: currentValue?.oz_per_board?.forecast_value ? currentValue?.oz_per_board?.forecast_value : 0,
		year: year,
		change_reason: null,
		change_notes: null,
	});

	const [numOfBoards, setNumOfBoards] = useState({
		forecast_unit: "number_of_boards",
		forecast_type: "monthly",
		growing_lane_number: currentLane,
		growing_location: currentPond,
		location_id: greenhouse.slug,
		date: date,
		product_variety_id: variety.slug,
		forecast_value: currentValue?.number_of_boards?.forecast_value ? currentValue?.number_of_boards?.forecast_value : 0,
		year: year,
		change_reason: null,
		change_notes: null,
	});
	const [growCycle, setGrowCycle] = useState({
		forecast_unit: "grow_cycle",
		forecast_type: "monthly",
		growing_lane_number: currentLane,
		growing_location: currentPond,
		location_id: greenhouse.slug,
		date: date,
		product_variety_id: variety.slug,
		forecast_value: currentValue?.grow_cycle?.forecast_value ? currentValue?.grow_cycle?.forecast_value : 0,
		year: year,
		change_reason: null,
		change_notes: null,
	});

	const [shouldSave, setShouldSave] = useState(false);

	useEffect(() => {
		setOzPerBoard({
			forecast_unit: "oz_per_board",
			forecast_type: "monthly",
			growing_lane_number: currentLane,
			growing_location: currentPond,
			location_id: greenhouse.slug,
			date: date,
			product_variety_id: variety.slug,
			forecast_value: currentValue?.oz_per_board?.forecast_value ? currentValue?.oz_per_board?.forecast_value : 0,
			year: year,
			change_reason: null,
			change_notes: null,
		});

		setNumOfBoards({
			forecast_unit: "number_of_boards",
			forecast_type: "monthly",
			growing_lane_number: currentLane,
			growing_location: currentPond,
			location_id: greenhouse.slug,
			date: date,
			product_variety_id: variety.slug,
			forecast_value: currentValue?.number_of_boards?.forecast_value ? currentValue?.number_of_boards?.forecast_value : 0,
			year: year,
			change_reason: null,
			change_notes: null,
		});

		setGrowCycle({
			forecast_unit: "grow_cycle",
			forecast_type: "monthly",
			growing_lane_number: currentLane,
			growing_location: currentPond,
			location_id: greenhouse.slug,
			date: date,
			product_variety_id: variety.slug,
			forecast_value: currentValue?.grow_cycle?.forecast_value ? currentValue?.grow_cycle?.forecast_value : 0,
			year: year,
			change_reason: null,
			change_notes: null,
		});
	}, [currentValue, currentLane, currentPond, date, greenhouse, variety, year, currentSalesValue]);

	const updateOz = () => {
		console.log("Saving OZ");
		saveForecast(ozPerBoard);
	};

	// useAutosave({ data: ozPerBoard, onSave: updateOz });

	const updateGrow = () => {
		console.log("Saving Grow");
		saveForecast(growCycle);
	};

	// useAutosave({ data: growCycle, onSave: updateGrow });

	const updateBoards = () => {
		console.log("Saving Boards");
		saveForecast(numOfBoards);
	};

	// useAutosave({ data: numOfBoards, onSave: updateBoards });

	const saveForecast = (value) => {
		if (shouldSave) {
			setShouldSave(false);
			updateHarvestForecast(
				[value],
				(e) => {
					successMessage(e);
				},
				errorHandler
			);
		}
	};

	const errorHandler = (e) => {
		errorMessage(e);
	};

	return (
		<div key={`aaaaforecast-year-${date}-${year}`} style={{ paddingBottom: 24 }}>
			<div style={{ textAlign: "center" }}>
				<div
					style={{
						fontSize: 12,
						fontWeight: "bold",
						letterSpacing: 1,
						color: "#fff",
						backgroundColor: "#006241",
						padding: 8,
					}}>
					{date} ({moment(date, "MMMM").daysInMonth()}days)
				</div>
				<div className="d-flex" style={{ flex: 1, flexDirection: "row", paddingTop: 8, paddingLeft: 8 }}>
					<div
						style={{
							width: 120,
							borderRadius: 20,
							backgroundColor: "#C9DD74",
							color: "#black",
							margin: "8px 4px",
							paddingLeft: 12,
							paddingRight: 12,
							fontWeight: "bold",
							fontSize: 10,
							letterSpacing: 2,
						}}>
						HARVEST: {moment(date, "MMMM").daysInMonth()}
					</div>
					<div
						style={{
							width: 150,
							borderRadius: 20,
							backgroundColor: "black",
							color: "white",
							margin: "8px 4px",
							paddingLeft: 12,
							paddingRight: 12,
							fontWeight: "bold",
							fontSize: 10,
							letterSpacing: 2,
						}}>
						SALES Lbs: {currentSalesValue / 16}
					</div>
				</div>
				<Container style={{ paddingLeft: 8, paddingTop: 24 }}>
					<Row style={{ textAlign: "left", justifyContent: "center", paddingBottom: 12 }}>
						<Col style={{ width: 100 }}>
							<CustomTextField
								sx={{
									"& .MuiOutlinedInput-root": {
										width: 100,
									},
								}}
								onChange={(e) => {
									setShouldSave(true);
									setOzPerBoard({ ...ozPerBoard, forecast_value: e.currentTarget.value });
								}}
								onBlur={(e) => {
									updateOz();
								}}
								value={ozPerBoard.forecast_value}
								placeholder="oz/board"
								type="number"
								label="Oz per board"
								InputProps={{
									endAdornment: <InputAdornment position="start">Oz</InputAdornment>,
								}}
							/>
						</Col>
						<Col style={{ width: 25 }}>
							<div
								style={{
									flex: 1,
									justifyContent: "center",
									alignItems: "center",
									alignContent: "center",
									height: 50,
									textAlign: "center",
									width: 50,

									lineHeight: "50px",
									fontWeight: "bold",
								}}>
								x
							</div>
						</Col>
						<Col style={{}}>
							<CustomTextField
								onChange={(e) => {
									setShouldSave(true);
									setNumOfBoards({ ...numOfBoards, forecast_value: e.currentTarget.value });
								}}
								onBlur={(e) => {
									updateBoards();
								}}
								value={numOfBoards.forecast_value}
								placeholder="# of boards"
								type="number"
								label="# of boards"
								InputProps={{
									endAdornment: <InputAdornment position="start">Boards</InputAdornment>,
								}}
							/>
						</Col>
					</Row>
					<Row style={{ textAlign: "left", justifyContent: "center", paddingBottom: 12 }}>
						<Col width={90} style={{ width: 90 }}>
							<div className="d-flex">
								<CustomTextField
									sx={{
										"& .MuiOutlinedInput-root": {
											width: 90,
										},
									}}
									value={growCycle.forecast_value}
									onChange={(e) => {
										setShouldSave(true);
										setGrowCycle({ ...growCycle, forecast_value: e.currentTarget.value });
									}}
									onBlur={(e) => {
										updateGrow();
									}}
									placeholder="Grow Cycle"
									label="Grow Cycle"
								/>
								<div
									style={{
										marginTop: 0,
										marginLeft: 54,
										justifyContent: "center",
										alignItems: "center",
										padding: 0,
										fontWeight: "bold",
										color: "white",
										textAlign: "right",
									}}>
									<div
										style={{
											justifyContent: "center",
											alignItems: "flex-end",
											fontWeight: "bold",
											color: "black",
											fontSize: 20,
											textAlign: "right",
										}}>
										Total <br />
										<span style={{ fontSize: 24 }}>
											{(ozPerBoard.forecast_value / 16) * numOfBoards.forecast_value} Lbs
										</span>
									</div>
								</div>
							</div>
						</Col>
					</Row>
				</Container>
				<div style={{ textAlign: "left", paddingLeft: 8, color: "#aaa" }}>
					<div style={{ fontSize: 10, fontWeight: "bold" }}>
						Oz updated: <span>{moment(currentValue?.oz_per_board?.updated_at).fromNow()}</span>
					</div>
					<div style={{ fontSize: 10, fontWeight: "bold" }}>
						# updated: <span>{moment(currentValue?.number_of_boards?.updated_at).fromNow()}</span>
					</div>
					<div style={{ fontSize: 10, fontWeight: "bold" }}>
						cycle updated: <span>{moment(currentValue?.grow_cycle?.updated_at).fromNow()}</span>
					</div>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => {
	const { UI, Auth } = state;
	return {
		accessToken: get(Auth, "accessToken", null),
		mainPreloader: get(UI, "mainPreloader", null),
		permissionDetails: get(Auth, "permissionDetails", null),
	};
};

const mapDispatchToProps = {
	updateHarvestForecast,
};
export default connect(mapStateToProps, mapDispatchToProps)(ForecastMonth);
