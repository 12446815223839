import { createSelectorCreator, defaultMemoize } from "reselect";
import { isEmpty, isEqual, values } from "lodash";
import Util from "utils/Util";
import moment from "moment";

const getWaterSource = (state) => state.WaterSource;

const getForm = (state) => state.form;

const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);

const getDropdownData = createDeepEqualSelector([getWaterSource], (wsr) => wsr.dropdownData);

export const waterSourceForm = createDeepEqualSelector([getForm], (form) => form.waterSourceForm);

export const getLoading = createDeepEqualSelector([getWaterSource], (wsr) => wsr && wsr.loading);

export const getLocationList = createDeepEqualSelector([getDropdownData], (data) =>
	data && data.location && !isEmpty(data.location) ? Util.mapOptions(values(data.location), "location_name", "slug") : []
);

const columnFormat = (values) => {
	const data = values.map((item) => ({
		name: item.nutrient,
		slug: item.slug,
	}));
	return data;
};

export const getNutrientList = createDeepEqualSelector([getDropdownData], (data) =>
	data && data.nutrients && !isEmpty(data.nutrients) ? columnFormat(data.nutrients) : []
);

export const getFormFromDate = createDeepEqualSelector([waterSourceForm], (form) =>
	form && form.values && form.values.week && form.values.week[0] ? form.values.week[0] : null
);
export const getFormToDate = createDeepEqualSelector([waterSourceForm], (form) =>
	form && form.values && form.values.week && form.values.week[1] ? form.values.week[1] : null
);

export const getFormLocation = createDeepEqualSelector([waterSourceForm], (form) =>
	form && form.values && form.values.location_slug ? form.values.location_slug : null
);

const fromDateFormat = createDeepEqualSelector([getFormFromDate], (date) => (date ? new Date(date) : null));

export const getThirtyWeeks = createDeepEqualSelector([fromDateFormat], (date) =>
	date
		? new Date(
				moment(date.setDate(date.getDate() + 7 * 29))
					.endOf("week")
					.format("MM-DD-YYYY")
		  )
		: null
);

const getWeeks = (from, to) => {
	const dateArray = [];
	let curDate = moment(from);
	const stDate = moment(to);
	while (curDate <= stDate) {
		dateArray.push(moment(curDate).format("MM/DD/YYYY"));
		curDate = moment(curDate).add(7, "days");
	}

	return dateArray;
};

export const getSelectedWeekDates = createDeepEqualSelector(
	[getFormFromDate, getFormToDate],
	(from, to) => (from && to && getWeeks(from, to)) || null
);

export const getColumnsWSR = createDeepEqualSelector([getWaterSource], (wsr) => wsr.columns);

export const getDataWSR = createDeepEqualSelector([getWaterSource], (wsr) => wsr.data);

export const getRenderType = createDeepEqualSelector([getWaterSource], (wsr) => wsr.renderType);

export const getDateSlugMapping = createDeepEqualSelector([getWaterSource], (wsr) => wsr.dateSlugMapping);

const WSRPermit = (state) => state.Auth.permissionDetails.water_source_results;

export const getAddPermission = createDeepEqualSelector(
	[WSRPermit],
	(access) => access && access.permissions.add_water_source_results
);

export const getEditPermission = createDeepEqualSelector(
	[WSRPermit],
	(access) => access && access.permissions.edit_water_source_results
);

export const getDeletePermission = createDeepEqualSelector(
	[WSRPermit],
	(access) => access && access.permissions.delete_water_source_results
);

export const getListPermission = createDeepEqualSelector(
	[WSRPermit],
	(access) => access && access.permissions.list_water_source_results
);
