/* eslint-disable array-callback-return */
import React from "react";
import LaneToCaseForm from "../manage/index";
import { isEmpty, keys } from "lodash";
import Util from "utils/Util";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import { errorMessage } from "utils/errorMessage";
import { successMessage } from "utils/successMessage";
import { setLoaderLc, addGrowLanesLc } from "store/actions";
import { laneToCaseColumns, pvColumns } from "./helper";

import {
	getGrowLanesTableData,
	getProductVarietiesTableData,
	getLaneToCaseFormFromDate,
	getLaneToCaseFormToDate,
	getLaneToCaseFormLocation,
	getLaneToCaseFormPV,
} from "store/selectors/laneCalculatorSelectors";
import CaseToLaneTable from "utils/caseToLaneTable";
import ForecastTable from "utils/forecastTable";

const Listing = ({ tableData, setLoaderLc, addGrowLanesLc, location, productVarieties, fromDate, toDate, pvTableData }) => {
	const onSave = (data) => {
		const calculatePV = [];
		keys(data).map((key) => {
			let obj = {};
			let ozPerBoard = "0";
			let noOfLanes = "0";
			let growCycle = "0";
			let pvSlug = "";

			data[key].map((item) => {
				switch (item.slug) {
					case "pv_slug":
						pvSlug = item.value;

						break;
					case "no_of_lanes":
						noOfLanes = item.value;

						break;
					case "oz_per_board":
						ozPerBoard = item.value;

						break;
					case "grow_cycle":
						growCycle = item.value;

						break;

					default:
						break;
				}
			});

			obj = {
				pv_slug: pvSlug,
				no_of_lanes: noOfLanes,
				oz_per_board: ozPerBoard,
				grow_cycle: growCycle,
			};
			calculatePV.push(obj);
		});

		setLoaderLc();
		const submitData = {
			location_slug: location.value,
			product_varieties: productVarieties,
			from: Util.getYMDFormat(fromDate),
			to: Util.getYMDFormat(toDate),
			calculate_pv: calculatePV,
			tz: Util.getLocalTimeZone(),
		};
		const successHandler = (e) => {
			successMessage(e);
			setLoaderLc();
		};
		const errorHandler = (e) => {
			errorMessage(e);
			setLoaderLc();
		};
		addGrowLanesLc(submitData, successHandler, errorHandler);
	};

	return (
		<div className="listing-wrapper">
			<LaneToCaseForm />
			{tableData ? (
				!isEmpty(tableData) ? (
					<>
						<CaseToLaneTable
							editable={true}
							columns={laneToCaseColumns}
							fixedColumnHeading="Product Varieties"
							initialData={tableData}
							onSave={onSave}
						/>
					</>
				) : (
					<div className="no-results-found text-center">No Results Found</div>
				)
			) : null}

			{pvTableData ? (
				!isEmpty(pvTableData) ? (
					<>
						<ForecastTable
							editable={false}
							columns={pvColumns}
							fixedColumnHeading="Product Varieties"
							initialData={pvTableData}
						/>
					</>
				) : (
					<div className="no-results-found text-center">No Results Found</div>
				)
			) : null}
		</div>
	);
};

const mapStateToProps = createStructuredSelector({
	tableData: getGrowLanesTableData,
	fromDate: getLaneToCaseFormFromDate,
	toDate: getLaneToCaseFormToDate,
	location: getLaneToCaseFormLocation,
	productVarieties: getLaneToCaseFormPV,
	pvTableData: getProductVarietiesTableData,
});
export default connect(mapStateToProps, {
	setLoaderLc,
	addGrowLanesLc,
})(Listing);
