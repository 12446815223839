import React, { useState } from "react";
import { connect } from "react-redux";
import { get } from "lodash";
import { ContentWrap, ButtonPrimary, Breadcrumbs } from "../../../elements";
import Listing from "../../../partials/listings";
import useModal from "../../../utils/useModal";
import BoardsManage from "../manage";
import ConfirmModal from "../../../utils/confirmModal";
import { getBoardsList, deleteBoard } from "../../../store/actions/boardsActions";
import { renderColumns } from "./helper";
import { successMessage } from "../../../utils/successMessage";
import { errorMessage } from "../../../utils/errorMessage";

const BoardsListing = ({ getBoardsList, deleteBoard, permissionDetails }) => {

	const [, , showModal] = useModal("boards");
	const [refetch, setRefetch] = useState(0);
	const [filter, setFilter] = useState([]);
	const [isDeletModalOpen, , showDeleteModal, closeDeleteModal, deleteModalProps] = useModal("deleteBoard");

	const handleAddPanel = () => showModal(0);
	const handleEditPanel = (data) => {
		const mode = data ? 1 : 0;
		showModal(mode, data);
	};
	const handleViewPanel = (data) => showModal(2, data);
	const handleDeletePanel = (data) => showDeleteModal(0, data);

	const onDeleteBoard = () => {
		const successHandler = (e) => {
			closeDeleteModal();
			successMessage(e);
			setRefetch(refetch + 1);
		};
		const errorHandler = (e) => {
			closeDeleteModal();
			errorMessage(e);
		};
		deleteBoard(deleteModalProps.slug, successHandler, errorHandler);
	};

	// Filter
	const addToFilter = (e) => {
		let obj = [];
		e.forEach((item) => {
			return (obj =
				item && item.column
					? [
							...obj,
							{
								column_name: item.column.accessor,
								search: item.value,
							},
					  ]
					: obj);
		});
		setFilter(obj);
	};

	const columns = renderColumns({
		handleEditPanel,
		handleViewPanel,
		handleDeletePanel,
		addToFilter,
		permissionDetails,
	});

	const addPermission =
		permissionDetails && permissionDetails.styrofoams && permissionDetails.styrofoams.permissions.edit_boards
			? permissionDetails.styrofoams.permissions.add_boards
			: "";
	const listPermission =
		permissionDetails && permissionDetails.styrofoams && permissionDetails.styrofoams.permissions.list_boards
			? permissionDetails.styrofoams.permissions.list_boards
			: "";
	const updatePermission =
		permissionDetails && permissionDetails.styrofoams && permissionDetails.styrofoams.permissions.edit_boards
			? permissionDetails.styrofoams.permissions.edit_boards
			: "";

	return (
		<ContentWrap>
			<div className="d-flex align-items-center mb-3">
				<Breadcrumbs keyValue="boards" showLabel={listPermission} label="List" />
				<div>
					{addPermission ? (
						<ButtonPrimary onClick={handleAddPanel}>
							<i className="icon icon-plus mr-2" /> Add Styrofoam board
						</ButtonPrimary>
					) : (
						""
					)}
				</div>
			</div>
			<div>
				{listPermission ? (
					<Listing fetchAction={getBoardsList} columns={columns} refetch={refetch} filter={filter} />
				) : (
					""
				)}

				<BoardsManage
					updatePermission={updatePermission}
					onAddOrEdit={() => setRefetch(refetch + 1)}
					handleEditPanel={handleEditPanel}
				/>
			</div>
			<ConfirmModal
				show={isDeletModalOpen}
				onNo={closeDeleteModal}
				onYes={onDeleteBoard}
				message="Are you sure want to delete this board?"
			/>
		</ContentWrap>
	);
};

const mapStateToProps = (state) => {
	const { Board, Auth } = state;
	return {
		fetchBoardDetails: get(Board, "boardDetails", null),
		permissionDetails: get(Auth, "permissionDetails", null),
	};
};

const mapDispatchToProps = {
	getBoardsList,
	deleteBoard,
};
export default connect(mapStateToProps, mapDispatchToProps)(BoardsListing);
