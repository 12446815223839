import { number, required, isDateObj, requiredDateObj, isInt, minValue } from "../../../constant/formValidators";
import { flagColors, containerFilled } from "../../../constant/global";
import moment from "moment";

export const SeedingTaskFormDetails = [
	{
		label: "label.actual_board_selected",
		name: "seeded_board_count",
		type: "text",
		placeholder: "label.enter_number_of_boards",
		validate: [required, number, minValue(0), isInt],
	},
	{
		label: "label.flag_color_required",
		name: "seeding_flag_color",
		type: "select-react",
		placeholder: "label.enter_flag_color",
		options: flagColors,
		validate: [required],
	},
	{
		label: "label.seed_density_required",
		name: "seeding_seed_density",
		type: "text",
		placeholder: "label.seeding_enter_seed_density",
		validate: [required, number, minValue(0)],
	},
	{
		label: "label.seed_container_filled_required",
		name: "seeding_container_filled",
		type: "select-react",
		placeholder: "label.select",
		options: containerFilled,
		validate: [required],
	},
	{
		label: "label.seeding_lot_no",
		name: "seed_lot_no",
		type: "text",
		placeholder: "label.enter_seed_lot_number",
		validate: [],
	},
	{
		label: "label.seed_accuracy_in_furrow_per",
		name: "seeding_accuracy_in_furrows",
		type: "text",
		placeholder: "label.enter_seed_accuracy_in_furrow",
		validate: [],
		// view: "full",
	},
	{
		label: "label.batch_number",
		name: "seed_batch_number",
		type: "text",
		placeholder: "label.enter_batch_number",
		validate: [number],
	},
	{
		label: "label.comments",
		name: "comments",
		type: "textarea",
		placeholder: "label.enter_comment",
		validate: [],
		view: "full",
	},
];

// Germination date validation
const checkGerminationDate = (c, minAct, maxAct) => {
	const cWithoutSeconds = (c && moment(c).seconds(0)) || null;
	if (c && cWithoutSeconds && minAct && minAct > cWithoutSeconds) {
		return "Date invalid";
	}
	// Should be less than Germination end date
	if (c && cWithoutSeconds && maxAct && cWithoutSeconds > maxAct) {
		return "Date invalid";
	}
	// If no germination end date logged, should be less than current time and date
	// else if (c && c > moment()) {
	// 	return "Date invalid";
	// }
	return undefined;
};

export const GerminationTaskFormDetails = (minExp, minAct, maxAct) => [
	{
		label: "label.expected_germination_date_required",
		name: "expected_germination_date",
		type: "date-picker",
		placeholder: "label.enter_germination_date",
		validate: [requiredDateObj, isDateObj],
		view: "full",
		minDate: minExp,
	},
	{
		label: "label.actual_germination_satrt_date_required",
		name: "germination_start_date",
		type: "date-time-picker",
		placeholder: "label.enter_actual_germination_start_date",
		validate: [requiredDateObj, isDateObj, (e) => checkGerminationDate(e, minAct, maxAct)],
		view: "full",
		minDate: minAct,
	},
];
