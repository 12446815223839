import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { reduxForm, FormSection, Field, change, reset } from "redux-form";
import useModal from "utils/useModal";
import { Button, Row, Col, Form, Container } from "react-bootstrap";
import FormGroups from "utils/formGroup";
import { AddEditForm } from "./formHelper";
import TableLoader from "utils/table/tableLoader";
import { createStructuredSelector } from "reselect";
import {
	getLocationList,
	getCustomers,
	getCustomerDC,
	getDcSku,
	editOrderInitials,
	getLocationSelected,
} from "store/selectors/ordersPackagingSelectors";
import { required, number } from "constant/formValidators";
import moment from "moment";

import {
	getLocationCustomers,
	selectLocationCustomer,
	selectLocation,
	resetListing,
	selectLocationCustomerDC,
	addOrder,
	updateOrder,
} from "store/actions";
import { successMessage } from "utils/successMessage";
import { errorMessage } from "utils/errorMessage";
import { useTranslation } from 'react-i18next';

const AddEditOrders = ({
	handleSubmit,
	locationList,
	customerList,
	dcList,
	skuList,
	getLocationCustomers,
	selectLocationCustomer,
	selectLocationCustomerDC,
	selectLocation,
	resetListing,
	addOrder,
	form,
	initialValues,
	onAddOrEdit,
	updateOrder,
	globalLocation,
}) => {
	const { t } = useTranslation();
	const [, modalType, , closeModal] = useModal("orders-add-edit");
	const [loading, setloading] = useState(false);
	const [btnTitle, setbtnTitle] = useState(t("label.save"));
	const [skuSelected, setskuSelected] = useState(modalType === 2 && initialValues ? [initialValues.skus] : []);
	const dispatch = useDispatch();

	const onSuccess = () => {
		setloading(false);
	};
	const onError = (e) => {
		setloading(false);
		errorMessage(e);
	};

	useEffect(() => {
		setloading(true);
		const initial = async () => await getLocationCustomers(onSuccess, onError);
		initial();
		// return () => {
		// 	resetListing();
		// };
	}, [getLocationCustomers, resetListing]);

	useEffect(() => {
		if (globalLocation) {
			selectLocation(globalLocation);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [globalLocation]);

	const submitForm = (values) => {
		setloading(true);
		setbtnTitle(t("label.saving"));

		const skus =
			modalType === 1 &&
			values.skus.map((item) => ({
				sku_slug: item.value,
				cases: values.skusSelected[item.value],
			}));
		const submitData =
			modalType === 1
				? {
						...values,
						location_slug: values.location_slug.value,
						customer_slug: values.customer_slug.value,
						dc_slug: values.dc_slug.value,
						order_request_date: moment(values.order_request_date).format("YYYY-MM-DD"),
						date_of_delivery: moment(values.date_of_delivery).format("YYYY-MM-DD"),
						skus: skus,
				  }
				: {
						// order_id: values.order_id,
						location_slug: values.location_slug.value,
						customer_slug: values.customer_slug.value,
						dc_slug: values.dc_slug.value,
						order_request_date: moment(values.order_request_date).format("YYYY-MM-DD"),
						date_of_delivery: moment(values.date_of_delivery).format("YYYY-MM-DD"),
						cases: values.skusSelected[values.skus.value],
						sku_slug: values.skus.value,
				  };

		const successHandler = (event) => {
			reset(form);
			setloading(false);
			onAddOrEdit();
			setbtnTitle(t("label.save"));
			successMessage(event);
			closeModal();
		};
		const errorHandler = (event) => {
			setloading(false);
			setbtnTitle(t("label.save"));
			errorMessage(event);
		};

		modalType === 1
			? addOrder(submitData, successHandler, errorHandler)
			: updateOrder(initialValues.task_id, submitData, successHandler, errorHandler);
	};

	const getOptions = (name) => {
		switch (name) {
			case "location_slug":
				return locationList;
			case "customer_slug":
				return customerList;
			case "dc_slug":
				return dcList;
			case "skus":
				return skuList;

			default:
				return [];
		}
	};

	const getSkuHtml = () => {
		let formFieldHtml = [];

		formFieldHtml = skuSelected.map((skuField, idx) => {
			return (
				<Row key={idx}>
					<Col className="col-12">
						<Form.Group controlId={`formControl ${skuField.value}`}>
							<Field
								name={skuField.value}
								type="text"
								label={skuField.label}
								placeholder={t("label.enter_cases_required")}
								component={FormGroups}
								validate={[required, number]}
							/>
						</Form.Group>
					</Col>
				</Row>
			);
		});

		return <div className="outer-box">{formFieldHtml}</div>;
	};

	const onSelect = (name, value) => {
		switch (name) {
			case "location_slug":
				selectLocation(value);
				dispatch(change("ordersAddEdit", "customer_slug", ""));
				dispatch(change("ordersAddEdit", "dc_slug", ""));
				dispatch(change("ordersAddEdit", "skus", ""));
				skuSelected.length > 0 && setskuSelected([]);
				break;
			case "customer_slug":
				selectLocationCustomer(value);
				dispatch(change("ordersAddEdit", "dc_slug", ""));
				dispatch(change("ordersAddEdit", "skus", ""));
				skuSelected.length > 0 && setskuSelected([]);
				break;
			case "dc_slug":
				selectLocationCustomerDC(value);
				dispatch(change("ordersAddEdit", "skus", ""));
				skuSelected.length > 0 && setskuSelected([]);
				break;
			case "skus":
				value ? setskuSelected(modalType === 2 ? [value] : value) : setskuSelected([]);
				break;
			default:
				return;
		}
	};

	const getFormFieldHtml = (formfields) => {
		let formFieldHtml = [];

		formFieldHtml = formfields.map((field, idx) => {
			return (
				<React.Fragment key={idx}>
					<Col
						className={
							field.name === "order_request_date" || field.name === "date_of_delivery" ? "col-md-6" : "col-12"
						}>
						<Form.Group controlId={`formControl ${field.name}`}>
							{field.name === "order_request_date" || field.name === "date_of_delivery" ? (
								<Field
									name={field.name}
									type={field.type}
									label={t(field.label)}
									placeholder={t(field.placeholder)}
									component={FormGroups}
									minDate={field.minDate}
									validate={field.validate}
								/>
							) : (
								<Field
									name={field.name}
									type={field.type}
									label={t(field.label)}
									placeholder={t(field.placeholder)}
									component={FormGroups}
									validate={field.validate}
									multiple={field.name === "skus" && modalType === 1 ? true : false}
									options={getOptions(field.name)}
									onChange={(event) => onSelect(field.name, event)}
								/>
							)}
						</Form.Group>
					</Col>
				</React.Fragment>
			);
		});
		return <Row>{formFieldHtml}</Row>;
	};

	return (
		<Form onSubmit={handleSubmit(submitForm)} className="ordersAddEdit">
			<Container style={{ position: "relative" }}>
				{getFormFieldHtml(AddEditForm["details"])}
				{skuSelected.length > 0 && <FormSection name="skusSelected">{getSkuHtml()}</FormSection>}
				{getFormFieldHtml(AddEditForm["dates"])}

				{loading && (
					<div className="table-loader-wrapper">
						<TableLoader />
					</div>
				)}
			</Container>
			<div className="modal-footer save-footer">
				<Button variant="secondary" onClick={closeModal}>
					{t("label.cancel")}
				</Button>
				<Button variant="primary" type="submit" disabled={loading}>
					{btnTitle}
				</Button>
			</div>
		</Form>
	);
};

const mapStateToProps = createStructuredSelector({
	locationList: getLocationList,
	customerList: getCustomers,
	dcList: getCustomerDC,
	skuList: getDcSku,
	initialValues: editOrderInitials,
	globalLocation: getLocationSelected,
});

export default connect(mapStateToProps, {
	getLocationCustomers,
	selectLocationCustomer,
	resetListing,
	selectLocation,
	selectLocationCustomerDC,
	addOrder,
	updateOrder,
})(reduxForm({ form: "ordersAddEdit", enableReinitialize: false })(AddEditOrders));
