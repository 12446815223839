import React, { createRef, useState, useEffect, useRef, useCallback } from "react";
import { connect, useDispatch } from "react-redux";
import "./index.scss";
import { reset, submit } from "redux-form";
import ScrollContainer from "react-indiana-drag-scroll";
import { fixedColumnTable, getActionColumns, getData } from "./columns";

import { createStructuredSelector } from "reselect";
import AddForm from "./addForm";

const FertilizerTable = ({
	initialData: data,
	columns,
	fixedColumnHeading,
	fixedColumnWidth = 200,
	editable,
	onSave,
	onDelete,
	keyMapping,
	dateFormat,
	tableErrors,
	errorStrings,
	render,
	renderKey,
	slugKey,
	actionColumnWidth = 100,
	type = "edit",
	handleSubmit,
	onCancel,
	editPermission = true,
	deletePermission = true,
}) => {
	const dispatch = useDispatch();
	const inputRef = useRef(null);
	const draggableRef = createRef(null);
	const scrollRef = createRef(null);
	const fixedRef = createRef(null);
	const posOneRef = createRef(null);
	const posTwoRef = createRef(null);
	const posThreeRef = createRef(null);
	const actionRef = createRef(null);
	const [editableRows, setEditableRows] = useState([]);

	useEffect(() => {
		onScrollTrack();
		window.addEventListener("scroll", bodyScroll, true);
		return () => {
			window.removeEventListener("scroll", bodyScroll);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	});

	const bodyScroll = () => {
		const elem = fixedRef.current;
		if (elem) {
			const { top, height } = elem.getBoundingClientRect();

			if (height > 0) {
				if (top <= 0) {
					if (height + top <= 120) {
						posOneRef.current.style.top = height - 120 + "px";
						posTwoRef.current.style.top = height - 120 + "px";
						posThreeRef.current.style.top = height - 120 + "px";
					} else {
						posOneRef.current.style.top = Math.abs(top) + "px";
						posTwoRef.current.style.top = Math.abs(top) + "px";
						posThreeRef.current.style.top = Math.abs(top) + "px";
					}
				} else if (top > 0) {
					posOneRef.current.style.top = 0;
					posTwoRef.current.style.top = 0;
					posThreeRef.current.style.top = 0;
				}
			}
		}
	};

	const onScrollTrack = (e) => {
		const shadowElem = [].slice.call(document.getElementsByClassName("show-shadow-row"));
		const rightShadowElem = [].slice.call(document.getElementsByClassName("show-shadow-row-right"));

		if (fixedRef.current && scrollRef.current && actionRef.current) {
			const fixedPos = fixedRef.current.getBoundingClientRect().left;
			const scrollingPos = scrollRef.current.getBoundingClientRect().left;
			const actionPos = actionRef.current.getBoundingClientRect().left;
			const scrollingWidth = scrollRef.current.scrollWidth;

			if (actionPos + Math.abs(scrollingPos) === scrollingWidth) {
				// eslint-disable-next-line array-callback-return
				rightShadowElem.map((node) => {
					node.classList.remove("shadow-block");
					node.parentNode.classList.remove("show-shadow-right");
				});
			} else {
				// eslint-disable-next-line array-callback-return
				rightShadowElem.map((node) => {
					node.classList.add("shadow-block");
					node.parentNode.classList.add("show-shadow-right");
				});
			}

			if (fixedPos + fixedColumnWidth > scrollingPos) {
				// eslint-disable-next-line array-callback-return
				shadowElem.map((node) => {
					node.style.display = "block";

					node.parentNode.classList.add("show-shadow");
				});
			} else {
				// eslint-disable-next-line array-callback-return
				shadowElem.map((node) => {
					node.style.display = "none";
					node.parentNode.classList.remove("show-shadow");
				});
			}
		}
	};

	const onClick = useCallback((event) => {
		if (event.target.tagName === "INPUT") {
			inputRef.current = event.target;
			event.target.focus();
		} else {
			if (inputRef.current) {
				inputRef.current.blur();
				inputRef.current = null;
			}
			event.target.click();
		}
	}, []);

	const cancelEdit = (index) => {
		setEditableRows(() => editableRows.filter((item) => item !== index));
	};

	return columns && data ? (
		type === "add" ? (
			<AddForm
				fixedRef={fixedRef}
				fixedColumnWidth={fixedColumnWidth}
				draggableRef={draggableRef}
				onScrollTrack={onScrollTrack}
				onClick={onClick}
				scrollRef={scrollRef}
				editable={editable}
				tableErrors={tableErrors}
				dispatch={dispatch}
				onCancel={onCancel}
				data={data}
				posOneRef={posOneRef}
				fixedColumnHeading={fixedColumnHeading}
				type={type}
				posTwoRef={posTwoRef}
				posThreeRef={posThreeRef}
				columns={columns}
				slugKey={slugKey}
				renderKey={renderKey}
				render={render}
				editableRows={editableRows}
				onSave={onSave}
				actionColumnWidth={actionColumnWidth}
				actionRef={actionRef}
				// setEditableRows={setEditableRows}
				// onDelete={onDelete}
				// cancelEdit={cancelEdit}
			/>
		) : (
			<>
				<div className="fertilizer-table-wrapper">
					<div ref={fixedRef} className="fertilizer-fixed-column-wrapper" style={{ width: fixedColumnWidth }}>
						{fixedColumnTable(
							data,
							fixedColumnWidth,
							posOneRef,
							fixedColumnHeading,
							type,
							editable,
							editableRows,
							renderKey,
							slugKey,
							dispatch,
							onSave,
							cancelEdit
						)}
					</div>
					<ScrollContainer
						ref={draggableRef}
						hideScrollbars={false}
						className="fertilizer-data-column-wrapper"
						style={{ width: `calc(100% - ${fixedColumnWidth + actionColumnWidth}px)` }}
						onScroll={(e) => onScrollTrack(e)}
						onClick={onClick}>
						<div ref={scrollRef} className="fertilizer-data-column">
							{getData(
								data,
								posTwoRef,
								columns,
								slugKey,
								renderKey,
								render,
								editableRows,
								type,
								onSave,
								cancelEdit
							)}
						</div>
					</ScrollContainer>
					<div style={{ width: actionColumnWidth }} ref={actionRef} className="fertilizer-table-column">
						{getActionColumns(
							data,
							actionColumnWidth,
							posThreeRef,
							editableRows,
							setEditableRows,
							onDelete,
							dispatch,
							submit,
							reset,
							cancelEdit,
							null,
							type,
							editPermission,
							deletePermission
						)}
					</div>
				</div>
			</>
		)
	) : null;
};

const mapStateToProps = createStructuredSelector({});

export default connect(mapStateToProps)(FertilizerTable);
