import Util from "../../../utils/Util";
const axisStyle = { fontSize: "10px", fontFamily: "Poppins, sans-serif", fontWeight: 500, fontColor: "#43425d" };
const axisStroke = "#43425d";
const toolTipStyle = { fontSize: "10px", fontFamily: "Poppins, sans-serif", fontWeight: 500 };
export const formatDate = (tickItem) => {
	const formattedDateValue = Util.checkValidDateTimeString(tickItem);
	return formattedDateValue !== "" ? formattedDateValue : tickItem;
};

export const cartesianGridProps = { vertical: false, stroke: "#fff" };
export const yAxisProps = {
	interval: "preserveStartEnd",
	axisLine: false,
	stroke: axisStroke,
	style: axisStyle,
	tickLine: false,
	padding: { top: 20, bottom: 20, left: 20, right: 20 },
};
export const xAxisProps = {
	interval: "preserveStartEnd",
	stroke: axisStroke,
	style: axisStyle,
	tickLine: false,
	axisLine: false,
	//dataKey: "name",
	//allowDuplicatedCategory: true,
	tickFormatter: function (value) {
		return ` ${formatDate(value)}`;
	},
};
export const toolTipProps = {
	labelStyle: toolTipStyle,
	itemStyle: toolTipStyle,
	labelFormatter: function (value) {
		return ` ${formatDate(value)}`;
	},
	formatter: function (value, name) {
		return `${value}`;
	},
};
export const legendProps = {
	layout: "horizontal",
	verticalAlign: "bottom",
	align: "left",
	height: 150,
	top: 200,
	iconType: "circle",
	fontSize: "12px",
};

export const xAxisPropsSingleChart = {
	interval: "preserveStartEnd",
	stroke: axisStroke,
	style: axisStyle,
	tickLine: false,
	axisLine: false,
	dataKey: "name",
};

export const singleToolTipProps = {
	labelStyle: toolTipStyle,
	itemStyle: toolTipStyle,
	labelFormatter: function (value) {
		return ` ${value}`;
	},
	formatter: function (value, name) {
		return `${value}`;
	},
};
export const xAxisLabelTypeArray = {
	Daily: "Date",
	Monthly: "Month",
	Yearly: "Year",
};
export const yAxisLabelTypeArray = {
	board: "Board",
	seedrate: "Seed Rate",
	Yearly: "Year",
	harvest_rate: "Harvest Rate",
	cases_packed: "Cases Packed",
	cases_per_lab_hour: "Cases Per Labour Hour",
};
export const reportLabelsArray = {
	seeding: "Seeding",
	harvesting: "Harvesting",
	orders_and_packaging: "Orders & Packaging",
};
export const labelListStyle = { fontSize: "7px", fontFamily: "Poppins, sans-serif", fontWeight: 500, color: "#43425d" };
export const yAxisLabelStyle = {
	angle: -90,
	position: "left",
	fontSize: "12px",
	fontFamily: "Poppins, sans-serif",
	fontWeight: 500,
	color: "#43425d",
	style: { textAnchor: "middle", marginLeft: "5px", marginRight: "5px" },
};
export const xAxisLabelStyle = {
	offset: 0,
	position: "insideBottomLeft",
	fontSize: "12px",
	fontFamily: "Poppins, sans-serif",
	fontWeight: 500,
	color: "#43425d",
};
