import { ListingBtn } from "elements";
import React from "react";
import { Col } from "react-bootstrap";
import { connect } from "react-redux";
import { Field } from "redux-form";
import { createStructuredSelector } from "reselect";

const componentGroup = ({ id, input, label, type, meta: { touched, error, warning } }) => {
	switch (type) {
		case "div":
			return <div>{input.value || input.value === 0 ? input.value : "-"} </div>;
		case "input":
			return (
				<div className="position-relative">
					<input
						{...input}
						id={id}
						className={`form-control action-table-input ${
							touched ? (error ? "input-error" : warning ? "warning" : "") : ""
						}`}
						placeholder={label}
						type={type}
					/>
					{touched ? (
						error ? (
							<div className="error-table-sec">
								<ListingBtn
									tooltipText={error}
									btnClass="btn btn-cell btn-light error-table-btn"
									iconClass="icon icon-info table-error-icon"
									errorBtn={true}
								/>
							</div>
						) : null
					) : null}
				</div>
			);
		default:
			return;
	}
};

const RenderActionTableColumn = ({
	b_ind,
	a_ind,
	editable,
	renderKey,
	render,
	c_item,
	data,
	c_ind,
	getClassName,
	slugKey,
	validation,
	toAddList
}) => {
	let slug;
	if(toAddList){
		slug = c_item.slug.replace(".","~_~")
	}else{
		slug = c_item.slug
	}
	return (
		<>
			<Col key={b_ind} className="action-table-col">
				{/* {editable ? ( */}
				<Field
					id={slugKey ? `${c_item[slugKey]}${a_ind}` : `${slug}${a_ind}`}
					name={slugKey ? c_item[slugKey] : slug}
					type={editable ? "input" : "div"}
					component={componentGroup}
					validate={validation}
					selectMenuHeight={200}
					// {...(!editable && {defaultValue: {this.props.editableOpts}} : {})}
				/>
				{/* ) : (
					<span>{render ? render(c_item) : c_item.value ? c_item.value : "-"}</span>
				)} */}
			</Col>
		</>
	);
};

const mapStateToProps = createStructuredSelector({
	// data: getForecastTableData,
});

const areEqual = (prevProps, nextProps) => {
	return (
		prevProps.b_ind === nextProps.b_ind &&
		prevProps.a_ind === nextProps.a_ind &&
		prevProps.editable === nextProps.editable &&
		prevProps.renderKey === nextProps.renderKey &&
		prevProps.render === nextProps.render &&
		prevProps.c_item === nextProps.c_item &&
		prevProps.getClassName === nextProps.getClassName &&
		prevProps.c_ind === nextProps.c_ind &&
		prevProps.slugKey === nextProps.slugKey
	);

	//  a_ind, editable, renderKey, render, c_item, tableErrors, data, c_ind
};

export default connect(mapStateToProps)(React.memo(RenderActionTableColumn, areEqual));
