import React, { useState, useEffect } from "react";
import { get } from "lodash";
import { connect, useSelector } from "react-redux";
import { ContentWrap, ButtonPrimary, Breadcrumbs } from "../../../elements";
import CustomerManage from "../manage/";
import {
	setCustomerViewRender,
	deleteCustomer,
	fetchCustomers,
	fetchCustomerDetails,
	fetchCustomerLocations,
	fetchCustomerSkus,
	fetchCustomerDcPriority,
} from "../../../store/actions";
import Listing from "../../../partials/listings";
import ConfirmModal from "../../../utils/confirmModal";
import { errorMessage } from "../../../utils/errorMessage";
import { successMessage } from "../../../utils/successMessage";
import { renderColumns } from "./helper";
import useModal from "../../../utils/useModal";
import TableLoader from "utils/table/tableLoader";
import ReorderDc from "../manage/reorderDc";
import "../customer.scss";
import { useTranslation } from "react-i18next";
import { isEmpty } from "lodash";

const CustomerListing = ({
	setCustomerViewRender,
	deleteCustomer,
	fetchCustomers,
	doFetchCustomerDetails,
	fetchCustomerLocations,
	fetchCustomerSkus,
	fetchCustomerDcPriority,
	dcPriorityList,
	permissionDetails,
	allCustomerDetails
}) => {
	const { t } = useTranslation();
	const renderType = useSelector((state) => state.Customers.renderDisplay);
	const [refetch, setRefetch] = useState(0);
	const [filter, setFilter] = useState([]);
	const [loading, setloading] = useState(false);
	const [viewPageLoading, setViewPageLoading] = useState(false);
	const [isDeletModalOpen, , showDeleteModal, closeDeleteModal, deleteModalProps] = useModal("deleteSeed");
	const [, , showModal] = useModal("customer");
	const [list, setList] = useState(0);
	const [exportFlag,setExportFlag] = useState(null);

	// Permission Checks
	const addPermission =
		permissionDetails && permissionDetails.customer && permissionDetails.customer.permissions.create_customer
			? permissionDetails.customer.permissions.create_customer
			: "";
	const listPermission =
		permissionDetails && permissionDetails.customer && permissionDetails.customer.permissions.list_customer
			? permissionDetails.customer.permissions.list_customer
			: "";
	const updatePermission =
		permissionDetails && permissionDetails.customer && permissionDetails.customer.permissions.update_customer
			? permissionDetails.customer.permissions.update_customer
			: "";

	const onSuccess = (renderType) => {
		setloading(false);
		setViewPageLoading(false);
		setCustomerViewRender(renderType);
	};
	const onError = (e) => {
		setloading(false);
		setViewPageLoading(false);
		errorMessage(e);
	};
	// Edit Customer DC
	const handleEdit = (slug, isViewPage) => {
		isViewPage ? setViewPageLoading(true) : setloading(true);
		const successHandler = () => onSuccess(2);
		doFetchCustomerDetails(slug, successHandler, onError);
	};

	// View Customer DC
	const handleView = (slug) => {
		setloading(true);
		const successHandler = () => onSuccess(3);
		doFetchCustomerDetails(slug, successHandler, onError);
	};

	const handleDcReorder = (slug) => {
		setList(0);
		setloading(true);
		const successHandler = () => {
			setList(1);
			setloading(false);
			showModal(4, slug);
		};
		fetchCustomerDcPriority(slug, successHandler, onError);
	};

	// Delete Customer DC
	const handleDeletePanel = (data) => showDeleteModal(0, data);
	const onDeleteCustomer = (id) => {
		const successHandler = (e) => {
			closeDeleteModal();
			successMessage(e);
			setRefetch(refetch + 1);
		};
		const errorHandler = (e) => {
			closeDeleteModal();
			errorMessage(e);
		};
		deleteCustomer(deleteModalProps, successHandler, errorHandler);
	};

	const addToFilter = (e) => {
		let obj = [];
		e.forEach((item) => {
			return (obj =
				item && item.column
					? [
							...obj,
							{
								column_name: item.column.accessor,
								search: item.value,
							},
					  ]
					: obj);
		});
		setFilter(obj);
	};

	const columns = renderColumns({
		setCustomerViewRender,
		addToFilter,
		handleEdit,
		handleView,
		handleDeletePanel,
		handleDcReorder,
		permissionDetails,
	});

	useEffect(() => {
		fetchCustomerLocations();
		fetchCustomerSkus();
	}, [fetchCustomerLocations, fetchCustomerSkus]);

	const exportHandler = () =>{
		setExportFlag(true);
	}

	const exportHandlerFlag = () => {
		setExportFlag(false);
	};

	return (
		<>
			{renderType === 0 ? (
				<ContentWrap>
					<div className="d-flex align-items-center mb-3">
						<Breadcrumbs keyValue="customer" showLabel={listPermission} label="List" />
						{!isEmpty(allCustomerDetails) &&
							<ButtonPrimary className="export_btn" onClick={exportHandler}>
								<span>
								{t("label.export")}{" "}
									<i className="icon icon-export text-sm" style={{fontWeight:"bold"}}/>
								</span>
							</ButtonPrimary>
			            }
						{addPermission ? (
							<div>
								<ButtonPrimary onClick={() => setCustomerViewRender(1)}>
									<i className="icon icon-plus mr-2" />
									Add Customer
								</ButtonPrimary>
							</div>
						) : null}
					</div>
					<div className="position-relative">
						{listPermission ? (
							<Listing
								sort_name="customer_name"
								fetchAction={fetchCustomers}
								columns={columns}
								refetch={refetch}
								filter={filter}
								exportFlag={exportFlag} 
						        exportHandlerFlag={()=>{exportHandlerFlag()}}
							/>
						) : null}
						{loading && (
							<div className="table-loader-wrapper">
								<TableLoader />
							</div>
						)}
					</div>

					{list && updatePermission ? (
						<ReorderDc
							onAddOrEdit={() => setRefetch(refetch + 1)}
							details={dcPriorityList}
							doRefetch={() => setRefetch(refetch + 1)}
							handleDcReorder={handleDcReorder}
						/>
					) : null}

					<ConfirmModal
						show={isDeletModalOpen}
						onNo={closeDeleteModal}
						onYes={onDeleteCustomer}
						message="Are you sure want to delete this customer?"
					/>
				</ContentWrap>
			) : (
				<CustomerManage
					updatePermission={updatePermission}
					viewPageLoading={viewPageLoading}
					handleEdit={(slug) => handleEdit(slug, true)}
				/>
			)}
		</>
	);
};
const mapStateToProps = (state) => {
	const { UI, Auth, Customers } = state;
	return {
		accessToken: get(Auth, "accessToken", null),
		mainPreloader: get(UI, "mainPreloader", null),
		renderDisplay: get(Customers, "renderDisplay", null),
		prevRenderDisplay: get(Customers, "prevRenderDisplay", null),
		dcPriorityList: get(Customers, "dcPriority", null),
		permissionDetails: get(Auth, "permissionDetails", null),
		allCustomerDetails: get(Customers, "allCustomerDetails", null),

	};
};

const mapDispatchToProps = {
	setCustomerViewRender,
	deleteCustomer,
	fetchCustomers,
	doFetchCustomerDetails: fetchCustomerDetails,
	fetchCustomerLocations,
	fetchCustomerSkus,
	fetchCustomerDcPriority,
};
export default connect(mapStateToProps, mapDispatchToProps)(CustomerListing);
