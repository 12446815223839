import React from "react";

const RenderParagraph = () => {
	return (
		<div className="ui-paragraph">
			<h4 className="mb-4">Body Heading - h4</h4>
			<p>
				Paragraph - Lorem ipsum dolor sit amet, consectetur adipiscing
				elit, sed do eiusmod tempor incididunt ut labore et dolore magna
				aliqua. Ut enim ad minim veniam, quis nostrud exercitation
				ullamco laboris nisi ut aliquip ex ea commodo consequat.
			</p>
			<p className="lead">
				Paragraph - lead - Duis aute irure dolor in reprehenderit in
				voluptate velit esse cillum dolore eu fugiat nulla pariatur.
				Excepteur sint occaecat cupidatat non proident, sunt in culpa
				qui officia deserunt mollit anim id est laborum.
			</p>
		</div>
	);
};

export default RenderParagraph;
