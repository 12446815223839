import React, { useState } from "react";
import { Container, Button, Row, Col, Form } from "react-bootstrap";
import { reduxForm, Field } from "redux-form";
import { connect } from "react-redux";
import Util from "utils/Util";
import FormGroups from "../../utils/formGroup";
import TableLoader from "../../utils/table/tableLoader";
import { required } from "constant/formValidators";
import { successMessage } from "utils/successMessage";
import { errorMessage } from "utils/errorMessage";
import { bulkAssignTasks } from "store/actions";
import { useTranslation } from "react-i18next";

const BulkAssigneeForm = ({
	reset,
	form,
	handleSubmit,
	locationUsersList,
	initialBody,
	bulkAssignTasks,
	closeModal,
	doSuccessHandling,
}) => {
	const { t } = useTranslation();
	const [isLoading, setIsLoading] = useState(false);
	const submitDetails = (values) => {
		setIsLoading(true);
		const body = {
			...initialBody,
			assignee_id: (values && values.assignee_id && values.assignee_id.value) || "",
		};
		const successHandler = (resp) => {
			setIsLoading(false);
			successMessage(resp);
			reset(form);
			closeModal();
			doSuccessHandling();
		};
		const errorHandler = (err) => {
			setIsLoading(false);
			errorMessage(err);
		};
		bulkAssignTasks(body, successHandler, errorHandler);
	};
	// Formatted location users array
	const locationUsers = Util.mapOptions(locationUsersList, "name", "id") || [];

	return (
		<Form onSubmit={handleSubmit(submitDetails)} className="board-details-form">
			<Container className="position-relative">
				<Row>
					<Col xs={12}>
						<Form.Group controlId="formControl">
							<Field
								name="assignee_id"
								type="select-react"
								label={t("label.assignee_required")}
								placeholder={t("label.select_assignee")}
								component={FormGroups}
								validate={required}
								options={locationUsers || []}
								multiple={false}
							/>
						</Form.Group>
					</Col>
				</Row>
				{/* Loader */}
				{isLoading && (
					<div className="table-loader-wrapper">
						<TableLoader />
					</div>
				)}
			</Container>
			<div className="modal-footer">
				<Button variant="secondary" onClick={closeModal}>
					{t("label.cancel")}
				</Button>
				<Button variant="primary" type="submit" disabled={isLoading}>
					{isLoading ? t("label.submitting") : t("label.submit")}
				</Button>
			</div>
		</Form>
	);
};
const mapStateToProps = (state) => {
	return { locationUsersList: state.Location.locationUsersList };
};
const mapDispatchToProps = { bulkAssignTasks };
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(
	reduxForm({
		form: "bulk-assignee-form",
		enableReinitialize: true,
	})(BulkAssigneeForm)
);
