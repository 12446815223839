import React from "react";
import { Modal } from "react-bootstrap";
import useModal from "../../../utils/useModal";
import CrossSupplyAdd from "./crossSupplyAdd";

const { Header, Title, Body } = Modal;

const CrossSupplyManage = ({ errorHandler, userLocations, doRefetch, doTransferRefetch }) => {
	const [isOpen, closeModal] = useModal("crossSupplyTransfer");

	return (
		<Modal size="lg" show={isOpen} onHide={closeModal} className="modal-sidepanel">
			<Header closeButton>
				<Title className="modal-title"> New Transfer</Title>
			</Header>

			<Body className="position-relative">
				{isOpen ? (
					<CrossSupplyAdd userLocations={userLocations} doRefetch={doRefetch} doTransferRefetch={doTransferRefetch} />
				) : null}
			</Body>
		</Modal>
	);
};

export default CrossSupplyManage;
