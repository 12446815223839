import React from "react";
import { Row, Col, Button } from "react-bootstrap";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { useDispatch } from "react-redux";
import {
	getCasesByCustomerFormData,
	getLoading,
	getShouldUpdateCases,
	getCasesByCustomerFormValidate,
	getCasesByCustomer,
} from "store/selectors/ordersPackagingSelectors";
import CasesByCustomerTableBody from "./casesByCustomerTableBody";
import { setShouldUpdateCases } from "store/actions";
import { change } from "redux-form";
import { useTranslation } from 'react-i18next';

const CasesByCustomerTable = ({ data, loading, validate, setShouldUpdateCases, shouldUpdateCases, casesByCustomer }) => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const tableHead = () => {
		return (
			<Row className="modal-table-head">
				<Col className="modal-table-head-col cont-wrap" style={{ flex: 0.7 }}>
					<span>{t("label.order_id")}</span>
				</Col>
				<Col className="modal-table-head-col cont-wrap" style={{ flex: 0.9 }}>
					<span>{t("label.customer_dc")}</span>
				</Col>
				<Col className="modal-table-head-col cont-wrap" style={{ flex: 1.2 }}>
					<span>{t("label.required_cases")}</span>
				</Col>
				<Col className="modal-table-head-col cont-wrap" style={{ flex: 1 }}>
					<span>{t("label.possible_cases")}</span>
				</Col>
				{shouldUpdateCases && (
					<Col className="modal-table-head-col cont-wrap" style={{ flex: 1.34 }}>
						<span>{t("label.update_cases")}</span>
					</Col>
				)}
			</Row>
		);
	};

	const tableBody = () => {
		return (
			<div className="modal-table-body">
				{data.customer_cases.map((item, index) => {
					return (
						<CasesByCustomerTableBody
							key={index}
							index={index}
							orderId={item.order_id}
							customerDC={item.dc_name}
							requiredCases={item.required_cases}
							possibleCases={item.possible_cases}
							updateCases={item.updated_cases}
						/>
					);
				})}
			</div>
		);
	};

	return (
		<div className="modal-table-wrapper casePerSku">
			<Row className="d-flex justify-content-end mb-4">
				<Button
					className="update-cases-btn"
					onClick={() => {
						setShouldUpdateCases();
						dispatch(change("casesByCustomer", "casesByCustomer", casesByCustomer));
						validate && dispatch(change("casesByCustomer", "validate", ""));
					}}>
					<i className="icon icon-edit" />
					{t("label.update_cases")}
				</Button>
			</Row>
			<div className="position-relative">
				{tableHead()}
				{tableBody()}
				{validate && (
					<Row>
						<Col>
							<span className="error-text">{`Updated cases ${
								validate === "notBoth"
									? t("validation.is_required_is_number")
									: validate === "notNumber"
									? t("validation.should_be_number")
									: validate === "notEqual"
									?t("validation.sum_equal_to_possible_cases")
									: t("validation.is_required")
							}`}</span>
						</Col>
					</Row>
				)}
			</div>

			{shouldUpdateCases && (
				<Row className="modal-footer save-footer" style={{ marginTop: 40, paddingRight: 0 }}>
					<Button
						variant="secondary"
						onClick={() => {
							setShouldUpdateCases();
							dispatch(change("casesByCustomer", "casesByCustomer", casesByCustomer));
							validate && dispatch(change("casesByCustomer", "validate", ""));
						}}>
						{t("label.cancel")}
					</Button>
					<Button variant="primary" type="submit" disabled={loading}>
						{t("label.save")}
					</Button>
				</Row>
			)}
		</div>
	);
};

const mapStateToProps = createStructuredSelector({
	data: getCasesByCustomerFormData,
	casesByCustomer: getCasesByCustomer,
	loading: getLoading,
	shouldUpdateCases: getShouldUpdateCases,
	validate: getCasesByCustomerFormValidate,
});

const areEqual = (prevProps, nextProps) => {
	return (
		prevProps.data === nextProps.data &&
		prevProps.validate === nextProps.validate &&
		prevProps.loading === nextProps.loading &&
		prevProps.shouldUpdateCases === nextProps.shouldUpdateCases &&
		prevProps.casesByCustomer === nextProps.casesByCustomer
	);
};

export default connect(mapStateToProps, { setShouldUpdateCases })(React.memo(CasesByCustomerTable, areEqual));
