import React from "react";
import { round } from "lodash";
import { ButtonLink, ListingBtn } from "../../../elements";
import "../boards.scss";
//import { errorMessage } from "utils/errorMessage";

export const renderColumns = ({ handleViewPanel, handleEditPanel, handleDeletePanel, addToFilter, permissionDetails }) => {
	const updatePermission =
		permissionDetails && permissionDetails.styrofoams && permissionDetails.styrofoams.permissions.edit_boards
			? permissionDetails.styrofoams.permissions.edit_boards
			: "";
	const viewPermission =
		permissionDetails && permissionDetails.styrofoams && permissionDetails.styrofoams.permissions.view_boards
			? permissionDetails.styrofoams.permissions.view_boards
			: "";
	const deletePermission =
		permissionDetails && permissionDetails.styrofoams && permissionDetails.styrofoams.permissions.delete_boards
			? permissionDetails.styrofoams.permissions.delete_boards
			: "";

	return [
		{
			name: "Styrofoam Board",
			accessor: "board_name",
			className: "boards-listing-col-name",
			headerClassName: "boards-listing-col-name-header",
			filter: { type: "search", onChange: addToFilter },
			render: (row) => {
				return viewPermission ? (
					<ButtonLink className="p-0" onClick={() => handleViewPanel(row)}>
						{row.board_name}
					</ButtonLink>
				) : (
					row.location_name
				);
			},
		},
		{
			name: "Dimensions",
			className: "boards-listing-col-dimension",
			headerClassName: "boards-listing-col-dimension-header",
			render: (row) =>
				`${round(row.height, 2) || "- "} x ${round(row.width, 2) || " - "} x ${round(row.length, 2) || " -"}`,
			sortable: false,
		},
		{
			name: "Furrows",
			accessor: "furrow_count",
			className: "boards-listing-col-furrow",
			headerClassName: "boards-listing-col-furrow-header",
			filter: {
				type: "search",
				onChange: addToFilter,
			},
		},
		{
			name: "Actions",
			className: "boards-listing-col-actions justify-content-end",
			headerClassName: "boards-listing-col-actions-header justify-content-end",
			hide: updatePermission || deletePermission ? false : true,
			render: (row) => {
				return (
					<>
						{updatePermission ? (
							<ListingBtn
								parentClass={
									updatePermission && deletePermission ? "justify-content-end" : "justify-content-around"
								}
								tooltipText="Edit"
								btnClass="btn btn-cell btn-light btn-sm ml-2"
								iconClass="icon icon-edit"
								onClick={() => handleEditPanel(row)}
							/>
						) : (
							""
						)}
						{deletePermission ? (
							<ListingBtn
								parentClass={
									updatePermission && deletePermission ? "justify-content-end" : "justify-content-around"
								}
								tooltipText="Delete"
								btnClass="btn btn-cell btn-light btn-sm ml-2"
								iconClass="icon icon-trash"
								onClick={() => handleDeletePanel(row)}
							/>
						) : (
							""
						)}
					</>
				);
			},
			sortable: false,
		},
	];
};
