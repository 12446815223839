import { DateRangePicker, LocationOrderDropdown } from "components/bulkAssignee/bulkAssigneeComponents";
import { BackButton, ContentWrap } from "elements";
import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import {
	setPackagingOrderExpDate,
	changeOrdersRenderType,
	setPackagingOrderLocation,
	fetchPackagingOrders,
	updatePackagingOrders,
} from "store/actions";

import {
	getLocationList,
	getPackagingOrderData,
	getPackagingOrderExpDate,
	getPackagingOrderLocation,
	getPackagingOrderUpdateAccess,
	getPackagingOrderViewAccess,
} from "store/selectors/ordersPackagingSelectors";
import { errorMessage } from "utils/errorMessage";
import TableLoader from "utils/table/tableLoader";
import Util from "utils/Util";
import { OrdersPackagingBreadcrumb } from "../ordersPackagingComponents";
import ViewPackagingOrder from "./viewPackagingOrder";
import { useTranslation } from "react-i18next";

const PackagingOrder = ({
	orderLocation,
	expDate,
	fetchPackagingOrders,
	locationList,
	setPackagingOrderExpDate,
	changeOrdersRenderType,
	updatePackagingOrders,
	setPackagingOrderLocation,
	data,
	viewPackagingOrderPermission,
	updatePackagingOrderPermission,
}) => {
	const { t } = useTranslation();
	const [loading, setloading] = useState(false);
	const [startDate, setStartDate] = useState(null);
	const [endDate, setEndDate] = useState(null);

	useEffect(() => {
		if (startDate && endDate && orderLocation) {
			handleHarvestOrderView();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [startDate, endDate, orderLocation]);

	const handleHarvestOrderView = () => {
		setloading(true);
		const successHandler = (e) => {
			setloading(false);
		};
		const onError = (e) => {
			errorMessage(e);
		};
		const body = {
			location_slug: orderLocation.value,
			date_from: Util.getYMDFormat(startDate),
			date_to: Util.getYMDFormat(endDate),
		};

		fetchPackagingOrders(body, successHandler, onError);
	};
	const onChangeDate = (dates) => {
		const [start, end] = dates;

		if (dates) {
			setStartDate(start);
			setEndDate(end);
		} else {
			return;
		}
	};

	return (
		<ContentWrap>
			<div className="d-flex justify-content-between mb-3">
				<div className="d-flex justify-content-between mb-3">
					<BackButton onClick={() => changeOrdersRenderType(0)} />
					<OrdersPackagingBreadcrumb view={0} />
				</div>
				<div className="d-flex align-items-center">
					{!isEmpty(locationList) && (
						<LocationOrderDropdown
							value={orderLocation}
							options={locationList}
							onSelect={(location) => setPackagingOrderLocation(location)}
							isClearable={false}
							label={t("label.location")}
						/>
					)}
					<DateRangePicker
						label={t("label.exp_delivery_date_from_to")}
						placeholder="Select Date"
						startDate={startDate}
						endDate={endDate}
						onChange={onChangeDate}
					/>

				</div>
			</div>
			<div className="position-relative">
				{data && (
					<>
						<div className="italic-text mb-3">{t("drag_and_drop_task_change_to_packaging_order")}</div>
						<ViewPackagingOrder
							handleHarvestListView={() => changeOrdersRenderType(0)}
							doRefetch={fetchPackagingOrders}
							details={data}
							updateOrderPermission={updatePackagingOrderPermission}
							viewOrderPermission={viewPackagingOrderPermission}
							setloading={setloading}
							loading={loading}
						/>
					</>
				)}

				{loading && (
					<div className="table-loader-wrapper">
						<TableLoader />
					</div>
				)}
			</div>
		</ContentWrap>
	);
};
const mapStateToProps = createStructuredSelector({
	locationList: getLocationList,
	orderLocation: getPackagingOrderLocation,
	expDate: getPackagingOrderExpDate,
	data: getPackagingOrderData,
	viewPackagingOrderPermission: getPackagingOrderViewAccess,
	updatePackagingOrderPermission: getPackagingOrderUpdateAccess,
});

export default connect(mapStateToProps, {
	setPackagingOrderExpDate,
	changeOrdersRenderType,
	fetchPackagingOrders,
	updatePackagingOrders,
	setPackagingOrderLocation,
})(PackagingOrder);
