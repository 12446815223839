export const formCreationFields = [
	{
		heading: "Seed Information",
		fields: [
			{
				label: "Germination Period *",
				name: "germination_period",
				type: "text",
				placeholder: "Enter Germination Period",
			},
			{
				label: "Grow Cycle *",
				name: "grow_cycle",
				type: "text",
				placeholder: "Enter Grow Cycle",
			},
			{
				label: "Harveting Period",
				name: "harvesting_period",
				type: "text",
				placeholder: "Enter Harvesting Period",
			},
			{
				label: "Shelflife Period *",
				name: "shelflife_period",
				type: "text",
				placeholder: "Enter Shelflife Period",
			},
		],
	},
	{
		heading: "Germination Conditions",
		fields: [
			{
				label: "Germination Time *",
				name: "germination_time",
				type: "text",
				placeholder: "Enter Germination Time",
			},
			{
				label: "Temperature (C) *",
				name: "germination_temperature",
				type: "text",
				placeholder: "Enter Temperature",
			},
			{
				label: "Humidity (%) *",
				name: "germination_humidity",
				type: "text",
				placeholder: "Enter Humidity",
			},
		],
	},
	{
		heading: "Growing Conditions",
		fields: [
			{
				label: "Temperature (C) *",
				name: "growing_temperature",
				type: "text",
				placeholder: "Enter Temperature",
			},
			{
				label: "Humidity (%) *",
				name: "growing_humidity",
				type: "text",
				placeholder: "Enter Humidity",
			},
			{
				label: "Light(Avg. Per/day) *",
				name: "growing_light",
				type: "text",
				placeholder: "Enter Avg. per/Day",
			},
		],
	},
	{
		heading: "Pond Metrics",
		fields: [
			{
				label: "pH",
				name: "ph",
				type: "text",
				placeholder: "Enter pH",
			},
			{
				label: "EC",
				name: "ec",
				type: "text",
				placeholder: "Enter EC",
			},
			{
				label: "DO",
				name: "do",
				type: "text",
				placeholder: "Enter DO",
			},
			{
				label: "Pond Temperature (C) *",
				name: "pond_temperature",
				type: "text",
				placeholder: "Enter Pond Temperature",
			},
		],
	},
	{
		heading: "Price & Seed Density",
		fields: [
			{
				label: "Price Per Pound *",
				name: "price_per_pound",
				type: "text",
				placeholder: "Enter Price",
			},
			{
				label: "Average Seed Density *",
				name: "seed_density",
				type: "text",
				placeholder: "Enter Seed Density",
			},
		],
	},
];

export const seasonsArray = [
	{
		value: "1",
		label: "Winter",
	},
	{
		value: "2",
		label: "Spring & Fall",
	},
	{
		value: "3",
		label: "Summer",
	},
];

export const headingsArray = ["Seed Information", "Germination Conditions", "Growing Conditions", "Pond Metrics", "Price"];
export const viewCreationFields = [
	{
		heading: "Seed Information",
		fields: [
			{
				label: "Germination Period *",
				name: "germination_period",
				unit: "Day(s)",
			},
			{
				label: "Grow Cycle",
				name: "grow_cycle",
				unit: "Day(s)",
			},
			{
				label: "Harveting Period",
				name: "harvesting_period",
				unit: "Day(s)",
			},
			{
				label: "Shelflife Period",
				name: "shelflife_period",
				unit: "Day(s)",
			},
		],
	},
	{
		heading: "Germination Conditions",
		fields: [
			{
				label: "Temperature",
				name: "germination_temperature",
				unit: "(C)",
			},
			{
				label: "Germination Time",
				name: "germination_time",
				unit: "Day(s)",
			},

			{
				label: "Humidity",
				name: "germination_humidity",
				unit: "(%)",
			},
		],
	},
	{
		heading: "Growing Conditions",
		fields: [
			{
				label: "Temperature ",
				name: "growing_temperature",
				unit: "(C)",
			},
			{
				label: "Humidity",
				name: "growing_humidity",
				unit: "(%)",
			},
			{
				label: "Light(Avg. Per/day)",
				name: "growing_light",
			},
		],
	},
	{
		heading: "Pond Metrics",
		fields: [
			{
				label: "pH",
				name: "ph",
			},
			{
				label: "EC",
				name: "ec",
			},
			{
				label: "DO",
				name: "do",
			},
			{
				label: "Pond Temperature",
				name: "pond_temperature",
				unit: "(C)",
			},
		],
	},
	{
		heading: "Price & Seed Density",
		fields: [
			{
				label: "Price Per Pound",
				name: "price_per_pound",
				unit: "$",
			},
			{
				label: "Average Seed Density",
				name: "seed_density",
			},
		],
	},
];
