import React, { useEffect, useState } from "react";

import { fetchAllLocations } from "store/actions";
import { errorMessage } from "utils/errorMessage";
import { connect } from "react-redux";
import { get } from "lodash";

import "./index.scss";
import { ContentWrap } from "elements";

const HarvestForecastV2 = ({ fetchAllLocations }) => {
	const [greenhouses, setGreenhouses] = useState([]);

	useEffect(() => {
		fetchAllLocations({}, successHandler, errorHandler);
	}, [fetchAllLocations]);
	const successHandler = (e) => {
		setGreenhouses(e.data);
	};
	const errorHandler = (e) => {
		errorMessage(e);
	};

	return (
		<ContentWrap style={{ textAlign: "center", justifyContent: "center" }}>
			<div style={{ textAlign: "center" }}>
				<h1 style={{ fontSize: 50 }}>Select a Greenhouse</h1>

				<div className="ui-cards" style={{ paddingTop: 24 }}>
					<div className="d-flex" style={{ justifyContent: "center", alignItems: "center", flexWrap: "wrap" }}>
						{greenhouses.map((gh) => {
							return (
								<div
									key={gh.slug}
									className="flex mr-3"
									style={{ boxShadow: "0px 0px 8px rgba(0,0,0,.2)", width: "250px", margin: 16 }}>
									<a href={`/gh/${gh.slug}`}>
										<div className="card card-primary p-4">
											<div style={{ fontWeight: "bold", fontSize: "16px" }}>{gh.location_name}</div>
											<div style={{ fontSize: "14px", paddingTop: 8, paddingBottom: 16 }}>
												{gh.city}, {gh.state}
											</div>
											<div>
												<object
													style={{ pointerEvents: "none" }}
													type="image/svg+xml"
													width={100}
													height={100}
													data={require(`../../assets/images/states/${gh.state.toLowerCase()}.svg`)}
												/>
											</div>
										</div>
									</a>
								</div>
							);
						})}
					</div>
				</div>
			</div>
		</ContentWrap>
	);
};

const mapStateToProps = (state) => {
	const { UI, Auth } = state;
	return {
		accessToken: get(Auth, "accessToken", null),
		mainPreloader: get(UI, "mainPreloader", null),
		permissionDetails: get(Auth, "permissionDetails", null),
	};
};

const mapDispatchToProps = {
	fetchAllLocations,
};
export default connect(mapStateToProps, mapDispatchToProps)(HarvestForecastV2);
