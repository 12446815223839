import React from "react";
import { isEmpty } from "lodash";
import { Col, Row } from "react-bootstrap";
import { Breadcrumbs, BackButton } from "elements";
import { BtnSKUChange } from "./view/viewComponents";
import i18next from "i18next";
import utils from "../../utils/Util";

export const OrdersPackagingBreadcrumb = ({ view = 0, goBackToListing }) => {
	return (
		<>
			{view !== 0 ? <BackButton onClick={goBackToListing} /> : null}
			<Breadcrumbs
				keyValue="ordersPackaging"
				showLabel={true}
				label={
					view === 1
						? "label.view"
						: view === 2
						? "label.change_sku_recipe"
						: view === 3
						? "label.packaging_order"
						: view === 4
						? "label.view_setup_time"
						: view === 5
						? "label.view_cleaning_time"
						: view === 6
						? "label.view_packaging_waste_by_sku"
						: view === 7
						? "label.view_packaging_waste_by_product_variety"
						: view === 8
						? "label.view_packed_cases_by_sku"
						: view === 9
						? "label.view_unpacked_lbs_by_product_variety"
						: view === 10
						? "label.view_packarate_details"
						: view === 11
						? "label.view_precool_details"
						: view === 12
						? "label.view_product_temperature_details"
						: "label.list"
				}
			/>
		</>
	);
};

const onMouseEnterHandler = () => {
	document.getElementById("tooltip_div").style.display = "block";
};
const onMouseLeaveHandler = () => {
	document.getElementById("tooltip_div").style.display = "none";
};

export const CaseSummary = ({
	isThreeBoxes = false,
	required = 0,
	possible = 0,
	updated = 0,
	totalPacked = 0,
	unPackedCases = [],
}) => (
	<>
		<Row>
			<Col className="op-mb" sm="12" md="3">
				<div className="op-view-summary">
					<p className="mb-2 text-label font-weight-bold">{i18next.t("label.required_cases")}</p>
					<p className="mb-0 text-md font-weight-bold">{required || "0"}</p>
				</div>
			</Col>
			<Col className="op-mb" sm="12" md="3">
				<div className="op-view-summary">
					<p className="mb-2 text-label font-weight-bold">
						{i18next.t("label.possible_cases")} {isThreeBoxes ? i18next.t("label.calculated") : ""}
					</p>
					<p className="mb-0 text-md font-weight-bold">{possible || "0"}</p>
				</div>
			</Col>
			{isThreeBoxes && (
				<Col className="op-mb" sm="12" md="3">
					<div className="op-view-summary">
						<p className="mb-2 text-label font-weight-bold">{i18next.t("label.possible_cases_updated")}</p>
						<p className="mb-0 text-md font-weight-bold">{updated || "0"}</p>
					</div>
				</Col>
			)}
			<Col className="op-mb" sm="12" md="3" onMouseEnter={!isEmpty(unPackedCases) ? onMouseEnterHandler : null} onMouseLeave={!isEmpty(unPackedCases) ? onMouseLeaveHandler :null}>
				<div className="op-view-summary">
					<p className="mb-2 text-label font-weight-bold">{i18next.t("label.inventory_packed_cases")}</p>
					<p className="mb-0 text-md font-weight-bold">{totalPacked || "0"}</p>
				</div>
			</Col>
		</Row>
		{!isEmpty(unPackedCases) && (
			<div className="inventory-tooltip" id="tooltip_div" style={{ display: "none" }} onMouseLeave={onMouseLeaveHandler}>
				<Col className="op-mb" sm="12" md="3">
					<div className="op-view-summary" style={{ backgroundColor: "#fff"}}>
						{unPackedCases.map((item,index) => {
							return (
								<span key={index}>
									{item.unsold_cases === "1"
										? `${item.unsold_cases} Case Packed - ${utils.formatMDY(item.date)}`
										: `${item.unsold_cases} Cases Packed - ${utils.formatMDY(item.date)}`}
								</span>
							);
						})}
					</div>
				</Col>
			</div>
		)}
	</>
);

export const PackagingSummary = ({ title, data = [], unit = "", editIdentifier = "", showEdit, onClickEdit }) =>
	!isEmpty(data) && (
		<>
			<h2 className="form-headers mt-3 mb-4 pl-0">{title}</h2>
			<Row>
				{data.map((item) => (
					<Col className="col-lg-3 col-md-4 col-sm-6 col-12" key={`${item.label}_${title}`}>
						<p className="d-flex align-items-center view-labels mb-1">
							<span className="light-badge-pill"></span>
							{item.label}
						</p>
						<p className="view-values ml-4">
							{item.value || 0}
							{unit}
						</p>
					</Col>
				))}
				{showEdit && <BtnSKUChange className="mr-sku-adjust" onClickEdit={() => onClickEdit(editIdentifier)} />}
			</Row>
		</>
	);
