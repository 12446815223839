import React, { useEffect } from "react";
import { Breadcrumbs, ButtonPrimary, ContentWrap } from "elements";

import Listing from "./listing";
import "./index.scss";
import TableLoader from "utils/table/tableLoader";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { getLoading, getRenderType, getAddPermission, getListPermission } from "store/selectors/fertilizerSelectors";
import { fetchDropdownDataFertilizers, setLoaderFertilizers, setRenderTypeFertilizers } from "store/actions";
import { errorMessage } from "utils/errorMessage";

const Fertilizers = ({
	loading,
	fetchDropdownDataFertilizers,
	setLoaderFertilizers,
	setRenderTypeFertilizers,
	renderType,
	addPermission,
	listPermission,
}) => {
	useEffect(() => {
		setLoaderFertilizers();
		const successHandler = () => {
			setLoaderFertilizers();
		};
		const errorHandler = (e) => {
			errorMessage(e);
			setLoaderFertilizers();
		};
		fetchDropdownDataFertilizers(successHandler, errorHandler);
	}, [fetchDropdownDataFertilizers, setLoaderFertilizers]);

	return (
		<ContentWrap>
			<div className="d-flex align-items-center mb-3">
			<Breadcrumbs
					className="forecast-breadcrumb"
					keyValue={renderType === 0 ? "nutrientTracking" : "nutrientTrackingFertilizersAdd"}
					showLabel={renderType === 0 ? true : false}
					label={renderType === 0 ? "Fertilizers" : ""}
				/>
				{renderType === 0 && addPermission && (
					<div className="d-md-flex position-relative croptimals-wrapper">
						<ButtonPrimary onClick={() => setRenderTypeFertilizers(1)}>
							<i className="icon icon-plus mr-2" />
							Add Fertilizers
						</ButtonPrimary>
					</div>
				)}
			</div>

			<div className="position-relative" style={{ paddingBottom: !listPermission ? 20 : 0 }}>
				{listPermission && <Listing />}
				{loading && (
					<div className="table-loader-wrapper">
						<TableLoader />
					</div>
				)}
			</div>
		</ContentWrap>
	);
};

const mapStateToProps = createStructuredSelector({
	loading: getLoading,
	renderType: getRenderType,
	listPermission: getListPermission,
	addPermission: getAddPermission,
});

export default connect(mapStateToProps, {
	fetchDropdownDataFertilizers,
	setLoaderFertilizers,
	setRenderTypeFertilizers,
})(Fertilizers);
