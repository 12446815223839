import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Container, Button, Row, Col, Form } from "react-bootstrap";
import { reduxForm, Field, reset, change, formValueSelector, FieldArray } from "redux-form";
import Util from "../../../utils/Util";
import FormGroups from "../../../utils/formGroup";
import { topFormFields } from "./formHelper";
import { useDispatch } from "react-redux";
import useModal from "../../../utils/useModal";
import { successMessage } from "../../../utils/successMessage";
import { errorMessage } from "../../../utils/errorMessage";
import { createCrossSupplyTransfer, toggleCrossSupplyListLoader } from "../../../store/actions";
import TableLoader from "../../../utils/table/tableLoader";
import CrossSupplyBoard from "./crossSupplyBoard";

const CrossSupplyAdd = ({
	handleSubmit,
	userLocations,
	boardValues,
	reset,
	form,
	doRefetch,
	createCrossSupplyTransfer,
	toggleCrossSupplyListLoader,
}) => {
	const [, , , closeModal] = useModal("crossSupplyTransfer");

	const [loading, setLoading] = useState(false);
	const [skuList, setSkuList] = useState([]);
	const [deliveryDate, setDeliveryDate] = useState("");
	const [requestDate, setRequestDate] = useState("");
	const [skuOptions, setSkuOptions] = useState([]);

	const dispatch = useDispatch();

	// Submit form
	const submitBoardDetails = (values) => {
		if (!loading) {
			setLoading(true);
			toggleCrossSupplyListLoader(true);
			const boardDetails = values.crossSupplyBoard.map((item) => {
				return {
					slug: item.slug.value,
					cases_required: parseInt(item.cases_required),
					delivery_date: item.delivery_date ? Util.getMDYFormatUtcDate(item.delivery_date) : "",
				};
			});
			const body = {
				location_slug: values.location_slug.value,
				date_of_request: Util.getYMDFormat(values.date_of_request),
				SKU: boardDetails,
			};
			const successHandler = (event) => {
				setLoading(false);
				toggleCrossSupplyListLoader(false);
				doRefetch && doRefetch(event);
				reset(form);
				successMessage(event);
				closeModal();
			};
			const errorHandler = (event) => {
				setLoading(false);
				errorMessage(event);
			};

			createCrossSupplyTransfer(body, successHandler, errorHandler);
		}
	};

	const getOptions = (field, id) => {
		switch (field) {
			case "location_slug":
				return (userLocations && Util.mapOptions(userLocations, "location_name", "slug")) || [];
			case "slug":
				return (skuList && Util.mapOptions(skuList, "sku_name", "slug", "isdisabled")) || [];

			default:
				return [];
		}
	};

	const onChangeFormEvents = (name, event) => {
		if (name === "location_slug") {
			userLocations.forEach((item) => {
				if (item.slug === event.value) {
					const skuArr = item.stock_keeping_units.map((arr) => ({ ...arr, isdisabled: false }));
					setSkuList(skuArr);
				}
			});

			dispatch(change("crossSupplyForm", "date_of_request", ""));

			boardValues &&
				boardValues.length > 0 &&
				// eslint-disable-next-line array-callback-return
				boardValues.map((item, index) => {
					dispatch(change("crossSupplyForm", `crossSupplyBoard[${index}].slug`, ""));
					dispatch(change("crossSupplyForm", `crossSupplyBoard[${index}].cases_requiredd`, ""));
					dispatch(change("crossSupplyForm", `crossSupplyBoard[${index}].date_of_request`, ""));
				});
		}

		if (name === "date_of_request") {
			setRequestDate(event);
		}
		if (name === "delivery_date") {
			setDeliveryDate(event);
		}
	};

	//Re set sku dropdown on row delete

	const resetSku = (value) => {
		const newArr = skuList.map((item) => {
			return item.slug === value ? { ...item, isdisabled: false } : item;
		});
		setSkuList(newArr);
	};
	useEffect(() => {
		setSkuOptions(getOptions("slug"));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [skuList]);

	// Conditional Form rendering
	const getFormFieldHtml = (formfields, getOptions) =>
		formfields.fields.map((field, idx) => (
			<React.Fragment key={idx}>
				<Col className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
					<Form.Group controlId={`formControl ${field.name}`}>
						{field.type === "date-picker" ? (
							<Field
								name={field.name}
								type={field.type}
								label={field.label}
								placeholder={field.placeholder}
								component={FormGroups}
								minDate={field.minDate}
								selectedDate={requestDate}
								validate={field.validate}
							/>
						) : field.type === "select-react" ? (
							<Field
								name={field.name}
								type={field.type}
								label={field.label}
								placeholder={field.placeholder}
								component={FormGroups}
								validate={field.validate}
								options={getOptions(field.name)}
								onChange={(event) => onChangeFormEvents(field.name, event)}
							/>
						) : (
							<Field
								name={field.name}
								type={field.type}
								label={field.label}
								placeholder={field.placeholder}
								component={FormGroups}
								validate={field.validate}
							/>
						)}
					</Form.Group>
				</Col>
			</React.Fragment>
		));

	const renderFormFields = (fields) =>
		fields.map((fields, idx) => (
			<React.Fragment key={idx}>
				<Row>{getFormFieldHtml(fields, getOptions)}</Row>
			</React.Fragment>
		));

	return (
		<Form onSubmit={handleSubmit(submitBoardDetails)} className="crossSupplyForm">
			<Container className="position-relative">
				{renderFormFields(topFormFields)}
				<FieldArray
					name="crossSupplyBoard"
					component={CrossSupplyBoard}
					skuOptions={skuOptions}
					onChangeFormEvents={onChangeFormEvents}
					resetSku={resetSku}
					skuList={skuList}
					setSkuList={setSkuList}
					deliveryDate={deliveryDate}
				/>
				{/* Loader */}
				{loading && (
					<div className="table-loader-wrapper">
						<TableLoader />
					</div>
				)}
			</Container>
			<div className="modal-footer save-footer">
				<Button variant="secondary" onClick={closeModal}>
					Cancel
				</Button>
				<Button variant="primary" type="submit" disabled={loading}>
					{loading ? "Saving..." : "Save"}
				</Button>
			</div>
		</Form>
	);
};

const mapStateToProps = (state) => {
	const selector = formValueSelector("crossSupplyForm");
	const addInitial = {
		location_slug: "",
		date_of_request: "",
		crossSupplyBoard: [
			{
				slug: "",
				cases_required: "",
				delivery_date: "",
			},
		],
	};
	return {
		boardValues: selector(state, "crossSupplyBoard"),
		initialValues: addInitial,
	};
};

const mapDispatchToProps = {
	reset,
	change,
	createCrossSupplyTransfer,
	toggleCrossSupplyListLoader,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(
	reduxForm({
		form: "crossSupplyForm",
		enableReinitialize: true,
	})(CrossSupplyAdd)
);
