import React from "react";
import { includes } from "lodash";
import { ButtonLink, ListingBtn } from "../../../elements";
import { flagColors, taskStatus } from "../../../constant/global";
import Util from "../../../utils/Util";
import Checkbox from "../../../utils/checkbox";
import i18next from "i18next";

const getFlagLabel = (flag_color) => {
	const found = flagColors.find(({ value }) => {
		return String(value).match(flag_color);
	});
	return found.label;
};
export const renderColumns = ({
	handleViewPanel,
	handleEditPanel,
	handleDeletePanel,
	addToFilter,
	isLocationSelected,
	onChangeCheckbox,
	checkedAssignees,
	permissionDetails,
}) => {
	const updatePermission =
		permissionDetails && permissionDetails.seeding && permissionDetails.seeding.permissions.update_seeding_task
			? permissionDetails.seeding.permissions.update_seeding_task
			: "";

	const viewPermission =
		permissionDetails && permissionDetails.seeding && permissionDetails.seeding.permissions.view_seeding_task
			? permissionDetails.seeding.permissions.view_seeding_task
			: "";
	const deletePermission =
		permissionDetails && permissionDetails.seeding && permissionDetails.seeding.permissions.delete_seeding_task
			? permissionDetails.seeding.permissions.delete_seeding_task
			: "";
	return [
		{
			name: "",
			className: "sg-listing-col-checkbox",
			headerClassName: "sg-listing-col-checkbox-header",
			render: (row) => (
				<Checkbox checked={includes(checkedAssignees, row.slug)} onChange={(e) => onChangeCheckbox(e, row.slug)} />
			),
			sortable: false,
		},
		{
			name: i18next.t("label.task_id"),
			accessor: "task_id",
			className: "sg-listing-col-taskId",
			headerClassName: "sg-listing-col-taskId-header seedingCentreHeading",
			render: (row) => {
				return viewPermission ? (
					<ButtonLink className="p-0" onClick={() => handleViewPanel(row)}>
						{row.slug}
					</ButtonLink>
				) : (
					row.slug
				);
			},
			filter: { type: "search", onChange: addToFilter },
		},
		{
			name: i18next.t("label.product_variety"),
			accessor: "product_variety",
			className: "sg-listing-col-pv",
			headerClassName: "sg-listing-col-pv-header seedingCentreHeading",
			filter: { type: "search", onChange: addToFilter },
		},
		{
			name: i18next.t("label.expected_seeding"),
			accessor: "expected_seeding_date",
			className: "sg-listing-col-date",
			headerClassName: "sg-listing-col-date-header",
			filter: { type: "calendar", onChange: addToFilter },
			sortable: false,
			render: (row) => Util.formatMDY(row.expected_seeding_date),
		},
		{
			name: i18next.t("label.expected_transplant"),
			accessor: "expected_transplant_date",
			className: "sg-listing-col-date",
			headerClassName: "sg-listing-col-date-header",
			filter: { type: "calendar", onChange: addToFilter },
			sortable: false,
			render: (row) => Util.formatMDY(row.expected_transplant_date),
		},
		{
			name: i18next.t("label.no_of_boards"),
			accessor: "board_count",
			className: "sg-listing-col-number",
			headerClassName: "sg-listing-col-number-header",
			sortable: false,
		},
		{
			name: i18next.t("label.flag"),
			accessor: "flag_color",
			className: "sg-listing-col-flag",
			headerClassName: "sg-listing-col-flag-header",
			options: flagColors,
			filter: {
				type: "select",
				options: flagColors,
				onChange: addToFilter,
			},
			render: (row) => getFlagLabel(row.flag_color),
		},
		{
			name: i18next.t("label.assignee"),
			accessor: "assignee",
			className: "sg-listing-col-assignee",
			headerClassName: "sg-listing-col-assignee-header",
			filter: { type: "search", onChange: addToFilter },
			render: (row) => (row.assignee && row.assignee.name) || "-",
		},
		{
			name: i18next.t("label.status"),
			accessor: "status",
			className: "sg-listing-col-status",
			headerClassName: "sg-listing-col-status-header",
			options: taskStatus,
			filter: {
				type: "select",
				options: taskStatus,
				onChange: addToFilter,
			},
		},
		{
			name: i18next.t("label.actions"),
			className: "sg-listing-col-actions justify-content-end",
			headerClassName: "sg-listing-col-actions-header justify-content-end",
			hide: updatePermission || deletePermission ? false : true,
			render: (row) => {
				return (
					<>
						{updatePermission ? (
							<>
								<ListingBtn
									parentClass={
										updatePermission && deletePermission ? "justify-content-end" : "justify-content-around"
									}
									tooltipText={i18next.t("tooltip.repeat")}
									btnClass="btn btn-cell btn-light btn-sm ml-2 spinner-button"
									iconClass="icon icon-repeat"
									onClick={() => handleEditPanel(row, "REPEAT")}
								/>
								<ListingBtn
									parentClass={
										updatePermission && deletePermission ? "justify-content-end" : "justify-content-around"
									}
									tooltipText={i18next.t("tooltip.edit")}
									btnClass="btn btn-cell btn-light btn-sm ml-2 spinner-button"
									iconClass="icon icon-edit"
									onClick={() => handleEditPanel(row, "EDIT")}
								/>
							</>
						) : (
							""
						)}
						{deletePermission ? (
							<ListingBtn
								tooltipText={i18next.t("tooltip.delete")}
								btnClass="btn btn-cell btn-light btn-sm ml-2"
								iconClass="icon icon-trash"
								onClick={() => handleDeletePanel(row)}
							/>
						) : (
							""
						)}
					</>
				);
			},
			sortable: false,
		},
	];
};
