import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Util from "../../../utils/Util";
import { createStructuredSelector } from "reselect";
import { setLoaderToAddList, setToAddListEdit, deleteToAddList, setDosingEditData, fetchDataToAddList } from "store/actions";
import {
	getPondSelected,
	getToAddListColumns,
	getToAddListData,
	locationSelected,
	getEditPermission,
	getDeletePermission,
	getFormFromDate,
	getFormToDate,
} from "store/selectors/toAddListSelectors";
import ActionTable from "utils/actionTable";
import { errorMessage } from "utils/errorMessage";
import ToAddListForm from "../manage";
import DosingCalculatorListing from "components/dosingCalculator/listing";
import moment from "moment";
import { getDosingCalcEditMode } from "store/selectors/dosingCalcSelectors";
import { isEmpty, keys } from "lodash";
import { successMessage } from "utils/successMessage";
import ConfirmModal from "utils/confirmModal";
import { useDispatch } from "react-redux";
import {initialize} from "redux-form";
const Listing = ({
	addListData,
	columns,
	setLoaderToAddList,
	setToAddListEdit,
	locationSelected,
	pondSelected,
	fetchDataToAddList,
	setDosingEditData,
	dosingCalcultorEditing,
	deleteToAddList,
	editPermission,
	deletePermission,
	fromDate,
	toDate,
}) => {
	const dispatch = useDispatch();
	const [deleteItem, setdeleteItem] = useState(null);
	const [refetch, setRefetch] = useState(false);
	const onEdit = (slug) => {
		setLoaderToAddList();
		const submitData = {
			dosing_calculator_slug: slug,
		};
		const successHandler = (e) => {
			setLoaderToAddList();
			dispatch(initialize(`calculateForm`));
			const editData = {
				...e.data,
				dosing_calculator_slug: slug,
				formInitials: {
					location: locationSelected,
					pond: pondSelected,
					week: [
						new Date(moment(e.data.dose_added_date).startOf("week")),
						new Date(moment(e.data.dose_added_date).endOf("week")),
					],
				},
			};

			setDosingEditData(editData);
		};
		const errorHandler = (e) => {
			errorMessage(e);
			setLoaderToAddList();
		};
		setToAddListEdit(submitData, successHandler, errorHandler);
	};

	const onDelete = () => {
		setLoaderToAddList();

		const successHandler = (e) => {
			successMessage(e);
			setRefetch(true);
			setdeleteItem(null);
			setLoaderToAddList();
		};
		const errorHandler = (e) => {
			errorMessage(e);
			setLoaderToAddList();
			setdeleteItem(null);
		};
		deleteToAddList(deleteItem, successHandler, errorHandler);
	};

	useEffect(() => {
		refetch && fetchToAddList();
		setRefetch(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [refetch]);

	const fetchToAddList = () => {
		setLoaderToAddList();
		const submitData = {
			location_slug: locationSelected.value,
			pond: pondSelected.value,
			start_date: Util.getMDYFormatUtcDate(fromDate),
			end_date: Util.getMDYFormatUtcDate(toDate),
		};
		const successHandler = () => {
			setLoaderToAddList();
		};
		const errorHandler = (e) => {
			errorMessage(e);
			setLoaderToAddList();
		};
		fetchDataToAddList(submitData, successHandler, errorHandler);
	};

	const renderRows = (date, arrayData) => {
		const data = {};
		const keyMapping = [];

		arrayData.forEach((item, index) => {
			const key = item.dosing_calculator_slug ? item.dosing_calculator_slug : "total";
			data[key] = item.row_data.map((item_i) => ({
				slug: item_i.slug,
				value: item_i.value,
			}));
			keyMapping.push({
				name: item.pond_name,
				pondIndex: index,
				slug: key,
			});
		});

		return (
			<div key={date}>
				<h3> Week - {Util.getDateFormat(date)}</h3>
				<ActionTable
					editable={true}
					columns={columns}
					fixedColumnHeading="Pond"
					initialData={data}
					keyMapping={keyMapping}
					customEdit={(slug) => onEdit(slug)}
					dateFormat="MM-DD-YYYY"
					onDelete={(slug) => setdeleteItem(slug)}
					editPermission={editPermission}
					deletePermission={deletePermission}
					formName={date}
					toAddList = {true}
				/>
			</div>
		);
	};

	return (
		<div className="to-add-list-listing-wrapper">
			<ToAddListForm />

			{dosingCalcultorEditing ? (
				<DosingCalculatorListing refetch={refetch} setRefetch={setRefetch} />
			) : !isEmpty(addListData) ? (
				addListData &&
				keys(addListData)
					.sort()
					.map((key) => renderRows(key, addListData[key]))
			) : (
				addListData && <div className="no-results-found text-center">No Results Found</div>
			)}

			<ConfirmModal
				show={deleteItem ? true : false}
				onNo={() => setdeleteItem(null)}
				onYes={onDelete}
				message="Are you sure want to delete this Data ?"
			/>
		</div>
	);
};

const mapStateToProps = createStructuredSelector({
	addListData: getToAddListData,
	columns: getToAddListColumns,
	locationSelected: locationSelected,
	pondSelected: getPondSelected,
	dosingCalcultorEditing: getDosingCalcEditMode,
	editPermission: getEditPermission,
	deletePermission: getDeletePermission,
	fromDate: getFormFromDate,
	toDate: getFormToDate,
});
export default connect(mapStateToProps, {
	setLoaderToAddList,
	setToAddListEdit,
	setDosingEditData,
	deleteToAddList,
	fetchDataToAddList,
})(Listing);
