import { required, requiredDateRange } from "constant/formValidators";

export const toAddListFormFields = [
	{
		label: "Location *",
		name: "location",
		type: "select-react",
		placeholder: "Select Location",
		validate: [required],
	},
	{
		label: "Pond *",
		name: "pond",
		type: "select-react",
		placeholder: "Select Pond",
		validate: [required],
	},
	{
		label: "Week *",
		name: "week",
		type: "date-range-picker",
		placeholder: "Enter Date",
		validate: [requiredDateRange],
	},
];
