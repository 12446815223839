import { keys, values } from "lodash";
import React, { createRef, useEffect, useRef, useCallback } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { connect, useDispatch } from "react-redux";
import "./index.scss";
import { FormSection, reduxForm, reset } from "redux-form";
import { createStructuredSelector } from "reselect";
import { getForecastTableErrors } from "store/selectors/forecastTableSelectors";
//import moment from "moment";
import Util from "../Util";
import { CommonSelectBox } from "components/bulkAssignee/bulkAssigneeComponents";

import ScrollContainer from "react-indiana-drag-scroll";
import { renderRow } from "./table";

const TableForm = ({
	// data,
	columns,
	initialData: data,
	fixedColumnHeading,
	fixedColumnWidth = 200,
	editable,
	onSave,
	onCancel,
	keyMapping,
	dateFormat,
	tableErrors,
	render,
	renderKey,
	slugKey,
	errorStringsArray,
	handleSubmit,
	initialValues,
	getNutrientCroptimalsValues,
	productVarietyData,
	productVarietySelected,
}) => {
	const dispatch = useDispatch();
	const inputRef = useRef(null);
	const draggableRef = createRef(null);
	const scrollRef = createRef(null);
	const fixedRef = createRef(null);
	const posOneRef = createRef(null);
	const posTwoRef = createRef(null);

	useEffect(() => {
		window.addEventListener("scroll", bodyScroll, true);
		return () => {
			window.removeEventListener("scroll", bodyScroll);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	});

	const bodyScroll = () => {
		const elem = fixedRef.current;
		if (elem) {
			const { top, height } = elem.getBoundingClientRect();
			if (height > 0) {
				if (top <= 0) {
					if (height + top <= 120) {
						posOneRef.current.style.top = height - 120 + "px";
						posTwoRef.current.style.top = height - 120 + "px";
					} else {
						posOneRef.current.style.top = Math.abs(top) + "px";
						posTwoRef.current.style.top = Math.abs(top) + "px";
					}
				} else if (top > 0) {
					posOneRef.current.style.top = 0;
					posTwoRef.current.style.top = 0;
				}
			}
		}
	};

	const renderKeyItem = (key) => {
		const seasonStart = (data[key] && Util.checkValidDateString(data[key]["season_start_date"])) || "";
		const seasonEnd = (data[key] && Util.checkValidDateString(data[key]["season_end_date"])) || "";
		return <span>{`${key} (${seasonStart} - ${seasonEnd})`}</span>;
	};

	const fixedColumnTable = () => {
		const arr = keys(data).sort();
		return (
			<div className="lab-fixed-column" style={{ width: fixedColumnWidth, position: "relative" }}>
				<div ref={posOneRef} className="sticky-head">
					<Row className={"lab-table-head "} style={{ width: `${fixedColumnWidth + 15}px` }}>
						<div className="show-shadow-row shadow-head" />
						<Col className="lab-table-col select-col-product-variety">
							<span>
								<CommonSelectBox
									options={productVarietyData}
									placeholder="Select Product Variety"
									onSelect={(event) => {
										getNutrientCroptimalsValues(event);
									}}
									selectMenuHeight="50"
									value={productVarietySelected}
								/>
							</span>
						</Col>
					</Row>
				</div>
				<div className="lab-table-body">
					{arr.map((dataItem, d_ind) => (
						<Row key={d_ind} className={"lab-table-body-row position-relative"}>
							<div className="show-shadow-row" />
							<Col className="lab-table-col">{renderKeyItem(dataItem)}</Col>
						</Row>
					))}
				</div>
			</div>
		);
	};
	const getData = () => {
		const arr = keys(data).sort();

		return (
			<>
				<div ref={posTwoRef} className="sticky-head">
					<Row className="lab-table-head position-relative">
						{columns.map((item, idx) => (
							<Col key={idx} className="lab-table-col">
								<span>{item.name ? item.name : item}</span>
							</Col>
						))}
					</Row>
				</div>
				<div className="lab-table-body">
					{/* {(${Util.checkValidDateString(
									data[a_item]["season_start_date"]
								)} - ${Util.checkValidDateString(data[a_item]["season_end_date"])})} */}
					{arr.map((a_item, a_ind) => (
						<Row key={a_ind} className="lab-table-body-row">
							<FormSection
								name={`${a_item}`}
								component={renderRow}
								nameKey={`${a_item}`}
								columns={columns}
								a_item={data[a_item]["croptimals"]}
								a_ind={a_ind}
								editable={editable}
								renderKey={renderKey}
								render={render}
								slugKey={slugKey}
							/>
						</Row>
					))}
				</div>
			</>
		);
	};

	const onScrollTrack = (e) => {
		const fixedPos = fixedRef.current.getBoundingClientRect().left;
		const scrollingPos = scrollRef.current.getBoundingClientRect().left;

		const shadowElem = [].slice.call(document.getElementsByClassName("show-shadow-row"));

		if (fixedPos + fixedColumnWidth > scrollingPos) {
			// eslint-disable-next-line array-callback-return
			shadowElem.map((node) => {
				node.style.display = "block";

				node.parentNode.classList.add("show-shadow");
			});
		} else {
			// eslint-disable-next-line array-callback-return
			shadowElem.map((node) => {
				node.style.display = "none";
				node.parentNode.classList.remove("show-shadow");
			});
		}
	};

	const onClick = useCallback((event) => {
		if (event.target.tagName === "INPUT") {
			inputRef.current = event.target;
			event.target.focus();
		} else {
			if (inputRef.current) {
				inputRef.current.blur();
				inputRef.current = null;
			}
			event.target.click();
		}
	}, []);

	return columns && data ? (
		<>
			<Form onSubmit={handleSubmit} className="forecastTable">
				<div className="lab-table-wrapper">
					<div ref={fixedRef} className="lab-fixed-column-wrapper" style={{ width: fixedColumnWidth }}>
						{fixedColumnTable()}
					</div>
					<ScrollContainer
						ref={draggableRef}
						hideScrollbars={false}
						className="lab-data-column-wrapper"
						style={{ width: `calc(100% - ${fixedColumnWidth}px)` }}
						onScroll={(e) => onScrollTrack(e)}
						onClick={onClick}>
						<div ref={scrollRef} className="lab-data-column">
							{getData()}
						</div>
						{data && Object.keys(data).length === 0 && <div className="no-results-found text-center">No Data</div>}
					</ScrollContainer>
				</div>
				{editable && (
					<Row className="justify-content-end ml-0 mr-0 mt-4 position-relative lab-table-btn-row">
						{tableErrors && <p className="error-string">{tableErrors}</p>}
						<Button
							variant="secondary"
							className="mr-3"
							onClick={() => {
								dispatch(reset("forecastTable"));
								onCancel && onCancel();
							}}>
							Cancel
						</Button>
						<Button variant="primary" className="save-btn" type="submit">
							Save
						</Button>
					</Row>
				)}
			</Form>
		</>
	) : null;
};

const mapStateToProps = createStructuredSelector({
	tableErrors: getForecastTableErrors,
});

export function scrollToFirstError(fieldList, props) {
	const { columns, slugKey } = props;

	// eslint-disable-next-line no-unused-vars
	const elem = "";
	// eslint-disable-next-line array-callback-return
	columns.some((item) => {
		if (item.slug in values(fieldList)[0]) {
			const elem = document.querySelector(`#${slugKey ? item[slugKey] : item.slug}_${keys(fieldList)[0]}`);

			if (elem) {
				setTimeout(() => {
					elem.scrollIntoView({ behavior: "smooth", block: "center" });
				}, 800);
				return elem;
			}
		}
	});
}

const onSubmit = (val, dispatch, props) => {
	const { slugKey, renderKey } = props;

	const data = {};
	// eslint-disable-next-line array-callback-return
	values(val).map((item, i) => {
		data[keys(val)[i]] = [];
		for (const key in item) {
			if (slugKey) {
				if (renderKey) {
					data[keys(val)[i]].push({
						[slugKey]: key,
						[renderKey]: item[key],
					});
				} else {
					data[keys(val)[i]].push({
						[slugKey]: key,
						value: item[key],
					});
				}
			} else {
				if (renderKey) {
					data[keys(val)[i]].push({
						slug: key,
						[renderKey]: item[key],
					});
				} else {
					data[keys(val)[i]].push({
						slug: key,
						value: item[key],
					});
				}
			}
		}
	});

	props.onSave(data);
};

export default connect(mapStateToProps)(
	reduxForm({
		form: "forecastTable",
		enableReinitialize: true,
		onSubmitFail: (errors, dispatch, submitError, props) => {
			scrollToFirstError(errors, props);
		},
		onSubmit: onSubmit,
	})(TableForm)
);
