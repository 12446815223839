import { USERS } from "../types";
const initialState = {
	userList: [],
	userRoles: [],
	addUser: false,
	isEdit: false,
	editProfile: false,
	userDetails: null,
	userLocations: [],
};

export default function (state = initialState, action) {
	switch (action.type) {
		case USERS.FETCH_USERS:
			state = {
				...state,
				userList: action.payload,
			};
			return state;

		case USERS.FETCH_USER_DETAILS:
			return {
				...state,
				userDetails: action.payload ? action.payload.data : [],
			};
		case USERS.FETCH_LOCATION_LIST:
			return {
				...state,
				userLocations: action.payload.data,
			};
		case USERS.FETCH_USER_ROLES:
			return {
				...state,
				userRoles: action.payload.data,
			};
		case USERS.UPDATE_FIELD:
			return {
				...state,
				[action.field]: action.val,
			};
		default:
			return state;
	}
}
