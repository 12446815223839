import { required, number } from "constant/formValidators";

export const viewBoardForm = [
	{
		label: "label.pond",
		name: "pond",
	},
	{
		label: "label.lane_number",
		name: "lane",
	},
	{
		label: "label.flag_color",
		name: "flag_color",
	},
	{
		label: "label.no_of_boards",
		name: "board_count",
	},
];

export const otherFields = [
	{
		heading: "",
		fields: [
			{
				label: "label.actual_grow_cycle",
				name: "actual_grow_cycle",
				type: "text",
				placeholder: "label.enter_number_of_days",
				validate: [number],
			},
			{
				label: "label.down_time",
				name: "down_time",
				type: "text",
				placeholder: "label.enter_down_time",
			},
			{
				label: "label.wastage_pounds",
				name: "wastage_pound",
				type: "text",
				placeholder: "label.enter_wastage_pounds",
				validation: [number],
			},
			{
				label: "label.blade_height_range",
				name: "harvested_blade_height",
				type: "text",
				placeholder: "label.enter_blade_height",
				valiation: [number],
			},
			{
				label: "label.boards_broken",
				name: "boards_broken",
				type: "text",
				placeholder: "label.enter_boards_broken",
			},
			{
				label: "label.blades_replaced_required",
				name: "blades_replaced",
				type: "select-react",
				placeholder: "label.select_blades_replaced",
				validate: [required],
			},
			{
				label: "label.comments",
				name: "harvested_comments",
				type: "textarea",
				placeholder: "label.enter_comment",
			},
		],
	},
];

export const harvestedTableForm = [
	{
		label: "label.pond",
		name: "pond",
	},
	{
		label: "label.lane_number",
		name: "lane",
	},
	{
		label: "label.flag_color",
		name: "flag_color",
	},
	{
		label: "label.boards",
		name: "board_count",
	},
	{
		label: "label.lbs",
		name: "weight",
		type: "join",
	},
	{
		label: "label.total_lbs_per_batch",
		name: "weight",
		type: "sum",
	},
	{
		label: "label.average_oz_per_board",
		name: "weight",
		type: "avg",
	},
];

export const harvestedBoardForm = {
	pond: {
		label: "label.pond_required",
		name: "pond",
		type: "select-react",
		placeholder: "label.select_pond",
		validate: [required],
	},
	lane: {
		label: "label.lane_number_required",
		name: "lane",
		type: "select-react",
		placeholder: "label.select_lane_number",
		validate: [required],
	},
	flag_color: {
		label: "label.flag_color_required",
		name: "flag_color",
		type: "select-react",
		placeholder: "label.enter_flag_color",
		validate: [required],
	},
	board_count: {
		label: "label.boards_harvested_required",
		name: "board_count",
		type: "text",
		placeholder: "label.enter_board",
		validate: [required,number],
	},
	weights: {
		label: "label.lbs_harvested_required",
		name: "weight",
		type: "selectWithTagsNumbersMultiple",
		placeholder: "label.enter_pounds",
		validate: [required],
	},
};

export const yesOrNo = [
	{
		label: "Yes",
		value: 1,
	},
	{
		label: "No",
		value: 0,
	},
];
