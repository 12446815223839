import React from "react";
import { ButtonLink } from "../../../elements";
import { flagColors, taskStatusGermination } from "../../../constant/global";
import Util from "../../../utils/Util";
import i18next from "i18next";

export const renderColumns = ({ handleViewPanel, addToFilter, permissionDetails }) => {
	const viewPermission =
		permissionDetails && permissionDetails.germination && permissionDetails.germination.permissions.view_germination_task
			? permissionDetails.germination.permissions.view_germination_task
			: "";
	const getFlagLabel = (flag_color) => {
		const found = flagColors.find(({ value }) => {
			return String(value).match(flag_color);
		});
		return found.label;
	};
	return [
		{
			name: i18next.t("label.task_id"),
			accessor: "task_id",
			className: "sg-listing-col-taskId",
			headerClassName: "sg-listing-col-taskId-header seedingCentreHeading",
			render: (row) => {
				return viewPermission ? (
					<ButtonLink className="p-0" onClick={() => handleViewPanel(row)}>
						{row.slug}
					</ButtonLink>
				) : (
					row.slug
				);
			},
			filter: { type: "search", onChange: addToFilter },
		},
		{
			name:  i18next.t("label.product_variety"),
			accessor: "product_variety",
			className: "sg-listing-col-pv",
			headerClassName: "sg-listing-col-pv-header seedingCentreHeading",
			filter: { type: "search", onChange: addToFilter },
		},
		{
			name:  i18next.t("label.expected_seeding"),
			accessor: "expected_seeding_date",
			className: "sg-listing-col-date",
			headerClassName: "sg-listing-col-date-header",
			filter: { type: "calendar", onChange: addToFilter },
			sortable: false,
			render: (row) => Util.formatMDY(row.expected_seeding_date),
		},
		{
			name:  i18next.t("label.expected_transplant"),
			accessor: "expected_transplant_date",
			className: "sg-listing-col-date",
			headerClassName: "sg-listing-col-date-header",
			filter: { type: "calendar", onChange: addToFilter },
			sortable: false,
			render: (row) => Util.formatMDY(row.expected_transplant_date),
		},
		{
			name:  i18next.t("label.no_of_boards"),
			accessor: "board_count",
			className: "sg-listing-col-number",
			headerClassName: "sg-listing-col-number-header",
			sortable: false,
		},
		{
			name:  i18next.t("label.flag"),
			accessor: "flag_color",
			className: "sg-listing-col-flag",
			headerClassName: "sg-listing-col-flag-header",
			options: flagColors,
			filter: {
				type: "select",
				options: flagColors,
				onChange: addToFilter,
			},
			render: (row) => getFlagLabel(row.flag_color),
		},
		{
			name:  i18next.t("label.assignee"),
			accessor: "assignee",
			className: "sg-listing-col-assignee",
			headerClassName: "sg-listing-col-assignee-header",
			filter: { type: "search", onChange: addToFilter },
			render: (row) => (row.assignee && row.assignee.name) || "-",
		},
		{
			name:  i18next.t("label.status"),
			accessor: "status",
			germination: "status",
			className: "sg-listing-col-status",
			headerClassName: "sg-listing-col-status-header",
			options: taskStatusGermination,
			filter: {
				type: "select",
				options: taskStatusGermination,
				onChange: addToFilter,
			},
		},
	];
};
