import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { ContentWrap } from "../../elements";

import ReportForm from "./form";
import { fetchClimateTableDetails, fetchClimateGraphDropDownDetails } from "../../store/actions";
import Util from "../../utils/Util";
import { errorMessage } from "../../utils/errorMessage";
import { isEmpty } from "lodash";
import { Row, Col } from "react-bootstrap";
import ForecastTable from "utils/forecastTable";

const ClimateTable = ({ fetchClimateTableDetails, fetchClimateGraphDropDownDetails }) => {
	const pageTitle = "Climate Data Table";
	const [isLoading, setisLoading] = useState(false);
	const [isSubmitSet, setIsSubmitSet] = useState(false);
	const [btnTitle, setbtnTitle] = useState("Apply");
	const [data, setData] = useState([]);
	const [currentSelectedParameters, setCurrentSelectedParameters] = useState([]);

	useEffect(() => {
		fetchClimateGraphDropDownDetails();
	}, [fetchClimateGraphDropDownDetails]);

	const submitClimateDetails = (values) => {
		setData([]);
		if (!isLoading && Object.keys(values).length !== 0) {
			setIsSubmitSet(true);
			setisLoading(true);
			setbtnTitle("Fetching...");
			const fromDate = Util.getMDYFormatUtcDate(new Date(values.date[0]));
			const toDate = Util.getMDYFormatUtcDate(new Date(values.date[1] ? values.date[1] : values.date[0]));
			const submissionData = {
				location_slug: values.location ? values.location.value : "",
				report_start_date: fromDate ? fromDate : "",
				report_end_date: toDate ? toDate : "",
				parameters: values.parameters.map((item) => item.value),
				compartment: values.compartment ? values.compartment.value : "",
			};

			const successHandler = (event) => {
				setbtnTitle("Apply");
				setisLoading(false);
				setData(event.data);
				setCurrentSelectedParameters(values.parameters.map((item) => item.label));
			};
			const errorHandler = (event) => {
				setbtnTitle("Apply");
				setisLoading(false);
				errorMessage(event);
			};
			fetchClimateTableDetails(submissionData, successHandler, errorHandler);
		}
	};
	return (
		<ContentWrap>
			<div className="reports-container">
				<div className="d-flex align-items-center mb-4">
					<div className="flex-fill">
						<h1>{pageTitle}</h1>
						<span className="page-bread-crumbs">
							<a href="/dashboard">Home</a> /Climate Data Table{" "}
						</span>
					</div>
				</div>
				<ReportForm submitClimateDetails={submitClimateDetails} btnTitle={btnTitle} isLoading={isLoading} />

				{data && !isEmpty(data) ? (
					<Row>
						<Col>
							{isLoading ? (
								""
							) : (
								<ForecastTable
									editable={false}
									columns={currentSelectedParameters}
									fixedColumnHeading="Date"
									initialData={data}
									dateFormat="MM/dd/yyyy"
								/>
							)}
						</Col>
					</Row>
				) : (
					!isLoading && isSubmitSet && <div className="no-results-found text-center">No Results Found</div>
				)}
			</div>
		</ContentWrap>
	);
};

const mapDispatchToProps = { fetchClimateTableDetails, fetchClimateGraphDropDownDetails };
export default connect(null, mapDispatchToProps)(ClimateTable);
