import React, { useState, useEffect } from "react";
import { Form, Button, Container, Row, Col, Alert } from "react-bootstrap";
import { Field, reduxForm, reset, getFormValues, change } from "redux-form";
import FormGroups from "../../../utils/formGroup";
import { connect, useSelector } from "react-redux";
import useModal from "../../../utils/useModal";
import {
	addSGMediaUsage,
	addSGSetupTime,
	addSGCleaningTime,
	getSGMediaUsage,
	getSGSetupTime,
	getSGCleaningTime,
} from "../../../store/actions";
import { successMessage } from "../../../utils/successMessage";
import { errorMessage } from "../../../utils/errorMessage";
import Util from "../../../utils/Util";
import TableLoader from "../../../utils/table/tableLoader";
import { useTranslation } from 'react-i18next';

const DailyTasksForm = ({
	form,
	change,
	formValues,
	handleSubmit,
	formFields,
	setRefetch,
	refetch,
	addSGMediaUsage,
	addSGSetupTime,
	addSGCleaningTime,
	getSGMediaUsage,
	getSGSetupTime,
	getSGCleaningTime,
}) => {
	const { t } = useTranslation();
	const [, modalType, , closeModal, modalProps] = useModal("dailyTasks");
	const selectedLocation = useSelector((state) => state.SeedingGermination.selectedLocation);

	const [isLoading, setIsLoading] = useState(false);
	const [isFetchingLog, setIsFetchingLog] = useState(false);
	const [shouldFetchLog, setShouldFetchLog] = useState(selectedLocation ? true : false);

	const [alreadyEnteredData, setAlreadyEnteredData] = useState((modalProps && modalProps.msg) || null);

	const errorHandler = (err) => {
		errorMessage(err);
		setIsLoading(false);
		setIsFetchingLog(false);
		setShouldFetchLog(false);
	};
	// Submit Form
	const submitDetails = (values) => {
		setIsLoading(true);
		const successHandler = (data) => {
			successMessage(data);
			setIsLoading(false);
			setRefetch && setRefetch(refetch + 1);
			closeModal();
			reset(form);
		};
		// MEDIA USAGE
		if (modalType === 1) {
			const body = {
				media_usage_date: Util.dateObjToString(values.media_usage_date),
				media_usage: values.media_usage,
				location_slug: values.location_slug && values.location_slug.value,
				lot_number: values.lot_number,
				media_usage_type: "seeding_germination",
			};
			addSGMediaUsage(body, successHandler, errorHandler);
		}
		// SET UP TIME
		if (modalType === 2) {
			const body = {
				room: values.room && values.room.value,
				set_up_date: Util.dateObjToString(values.set_up_date),
				set_up_time_in_minutes: values.set_up_time_in_minutes,
				set_up_type: "seeding_germination",
				location_slug: values.location_slug && values.location_slug.value,
			};
			addSGSetupTime(body, successHandler, errorHandler);
		}
		// CLEANING TIME
		if (modalType === 3) {
			const body = {
				room: values.room && values.room.value,
				cleaning_date: Util.dateObjToString(values.cleaning_date),
				cleaning_time_in_minutes: values.cleaning_time_in_minutes,
				cleaning_type: "seeding_germination",
				location_slug: values.location_slug && values.location_slug.value,
			};
			addSGCleaningTime(body, successHandler, errorHandler);
		}
	};

	// Fetch if entries already added in a particular date
	const onChangeFormFields = (e, doGetCall = false) => {
		if (doGetCall && (modalType === 1 || modalType === 2 || modalType === 3)) {
			setShouldFetchLog(true);
		} else {
			setShouldFetchLog(false);
		}
	};

	// Fetch details regarding previous entries
	useEffect(() => {
		if (shouldFetchLog) {
			// MEDIA USAGE
			if (modalType === 1) {
				if (formValues && formValues.media_usage_date && formValues.location_slug) {
					setIsFetchingLog(true);
					const onSuccess = (resp) => {
						const msg =
							(resp &&
								resp.data &&
								resp.data.user &&
								`${resp.data.user} has already logged ${resp.data.media_usage} bag(s).`) ||
							null;
						setAlreadyEnteredData(msg);
						setIsFetchingLog(false);
						setShouldFetchLog(false);
						change("media_usage", resp.data.media_usage);
						change("lot_number", resp.data.lot_number);
					};
					const body = {
						media_usage_date: Util.dateObjToString(formValues.media_usage_date),
						location_slug: formValues.location_slug && formValues.location_slug.value,
					};
					getSGMediaUsage(body, onSuccess, errorHandler);
				}
			}
			// SET UP TIME
			if (modalType === 2) {
				if (formValues && formValues.set_up_date && formValues.room && formValues.location_slug) {
					setIsFetchingLog(true);
					const onSuccess = (resp) => {
						const msg =
							(resp &&
								resp.data &&
								resp.data.user &&
								`${resp.data.user} has already logged ${resp.data.set_up_time_in_minutes} minutes.`) ||
							null;
						setAlreadyEnteredData(msg);
						setIsFetchingLog(false);
						setShouldFetchLog(false);
						change("set_up_time_in_minutes", resp.data.set_up_time_in_minutes);
					};
					const body = {
						set_up_date: Util.dateObjToString(formValues.set_up_date),
						room: formValues.room && formValues.room.value,
						set_up_type: "seeding_germination",
						location_slug: formValues.location_slug && formValues.location_slug.value,
					};
					getSGSetupTime(body, onSuccess, errorHandler);
				}
			}
			// CLEANING TIME
			if (modalType === 3) {
				if (formValues && formValues.cleaning_date && formValues.room && formValues.location_slug) {
					setIsFetchingLog(true);
					const onSuccess = (resp) => {
						const msg =
							(resp &&
								resp.data &&
								resp.data.user &&
								`${resp.data.user} has already logged ${resp.data.cleaning_time_in_minutes} minutes.`) ||
							null;
						setAlreadyEnteredData(msg);
						change("cleaning_time_in_minutes", resp.data.cleaning_time_in_minutes);
						setIsFetchingLog(false);
						setShouldFetchLog(false);
					};
					const body = {
						cleaning_date: Util.dateObjToString(formValues.cleaning_date),
						room: formValues.room && formValues.room.value,
						cleaning_type: "seeding_germination",
						location_slug: formValues.location_slug && formValues.location_slug.value,
					};
					getSGCleaningTime(body, onSuccess, errorHandler);
				}
			}
		}
	}, [change, formValues, getSGCleaningTime, getSGMediaUsage, getSGSetupTime, modalType, shouldFetchLog]);

	const editForm = () => {
		switch (modalType) {
			// MEDIA USAGE
			case 1:
				return (
					<div>
						<Row>
							<Col className="col-lg-4 col-md-6 col-sm-12 col-12 mb-3">
								<p className="d-flex align-items-center text-muted mb-1">
									<span className="light-badge-pill"></span>
									{t("label.location")}
								</p>
								<h6 className="ml-4">{formValues && formValues.location_slug.label}</h6>
							</Col>
						</Row>
						<Row>
							<Col className="col-lg-4 col-md-6 col-sm-12 col-12 mb-3">
								<p className="d-flex align-items-center text-muted mb-1">
									<span className="light-badge-pill"></span>
									{t("label.date")}
								</p>
								<h6 className="ml-4">{formValues && Util.formatMDY(formValues.media_usage_date)}</h6>
							</Col>
						</Row>

						<Row>
							<Col className="col-lg-4 col-md-6 col-sm-12 col-12 mb-3">
								<p className="d-flex align-items-center text-muted mb-1">
									<span className="light-badge-pill"></span>
									{t("label.user")}
								</p>
								<h6 className="ml-4">{formValues && formValues.user}</h6>
							</Col>
						</Row>
					</div>
				);
			// SET UP TIME
			case 2:
				return (
					<div>
						<Row>
							<Col className="col-lg-4 col-md-6 col-sm-12 col-12 mb-3">
								<p className="d-flex align-items-center text-muted mb-1">
									<span className="light-badge-pill"></span>
									{t("label.location")}
								</p>
								<h6 className="ml-4">{formValues && formValues.location_slug.label}</h6>
							</Col>
						</Row>
						<Row>
							<Col className="col-lg-4 col-md-6 col-sm-12 col-12 mb-3">
								<p className="d-flex align-items-center text-muted mb-1">
									<span className="light-badge-pill"></span>
									{t("label.room")}
								</p>

								<h6 className="ml-4">{formValues && formValues.room.value}</h6>
							</Col>
						</Row>
						<Row>
							<Col className="col-lg-4 col-md-6 col-sm-12 col-12 mb-3">
								<p className="d-flex align-items-center text-muted mb-1">
									<span className="light-badge-pill"></span>
									{t("label.set_up_date")}
								</p>
								<h6 className="ml-4">{formValues && Util.formatMDY(formValues.set_up_date)}</h6>
							</Col>
						</Row>
						<Row>
							<Col className="col-lg-4 col-md-6 col-sm-12 col-12 mb-3">
								<p className="d-flex align-items-center text-muted mb-1">
									<span className="light-badge-pill"></span>
									{t("label.user")}
								</p>
								<h6 className="ml-4">{formValues && formValues.user}</h6>
							</Col>
						</Row>
					</div>
				);
			// CLEANING TIME
			case 3:
				return (
					<div>
						<Row>
							<Col className="col-lg-4 col-md-6 col-sm-12 col-12 mb-3">
								<p className="d-flex align-items-center text-muted mb-1">
									<span className="light-badge-pill"></span>
									{t("label.location")}
								</p>
								<h6 className="ml-4">{formValues && formValues.location_slug.label}</h6>
							</Col>
						</Row>
						<Row>
							<Col className="col-lg-4 col-md-6 col-sm-12 col-12 mb-3">
								<p className="d-flex align-items-center text-muted mb-1">
									<span className="light-badge-pill"></span>
									{t("label.room")}
								</p>
								<h6 className="ml-4">{formValues && formValues.room.value}</h6>
							</Col>
						</Row>
						<Row>
							<Col className="col-lg-4 col-md-6 col-sm-12 col-12 mb-3">
								<p className="d-flex align-items-center text-muted mb-1">
									<span className="light-badge-pill"></span>
									{t("label.cleaning_date")}
								</p>
								<h6 className="ml-4">{formValues && Util.formatMDY(formValues.cleaning_date)}</h6>
							</Col>
						</Row>
						<Row>
							<Col className="col-lg-4 col-md-6 col-sm-12 col-12 mb-3">
								<p className="d-flex align-items-center text-muted mb-1">
									<span className="light-badge-pill"></span>
									{t("label.user")}
								</p>
								<h6 className="ml-4">{formValues && formValues.user}</h6>
							</Col>
						</Row>
					</div>
				);
			default:
				return;
		}
	};

	return (
		<Form onSubmit={handleSubmit(submitDetails)} className="board-details-form">
			<Container className="position-relative">
				{setRefetch && editForm()}
				<Row>
					{formFields.map((field, idx) => (
						<Col xs={12} key={idx}>
							<Form.Group controlId={`formControl ${idx}`}>
								<Field
									name={field.name}
									type={field.type}
									label={t(field.label)}
									placeholder={t(field.placeholder)}
									component={FormGroups}
									validate={field.validate}
									options={field.options || []}
									multiple={false}
									onChange={(e) => onChangeFormFields(e, field.doGetCall)}
									// Date
									minDate={field.minDate}
								/>
							</Form.Group>
						</Col>
					))}
				</Row>

				{alreadyEnteredData && (
					<Alert className="daily-tasks-alert" variant="light">
						{alreadyEnteredData}
					</Alert>
				)}
				{/* Loader */}
				{(isLoading || isFetchingLog) && (
					<div className="table-loader-wrapper">
						<TableLoader />
					</div>
				)}
			</Container>
			<div className="modal-footer save-footer">
				<Button variant="secondary" onClick={closeModal}>
					{t("label.cancel")}
				</Button>
				<Button variant="primary" type="submit" disabled={isLoading}>
					{isLoading ? t("label.saving") : t("label.save")}
				</Button>
			</div>
		</Form>
	);
};

const mapStateToProps = (state) => {
	return { formValues: getFormValues("daily-tasks-form")(state) };
};

const mapDispatchToProps = {
	reset,
	change,
	addSGMediaUsage,
	addSGSetupTime,
	addSGCleaningTime,
	getSGMediaUsage,
	getSGSetupTime,
	getSGCleaningTime,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(
	reduxForm({
		form: "daily-tasks-form",
		enableReinitialize: true,
	})(DailyTasksForm)
);
