import React from "react";
import { useSelector } from "react-redux";
import { Row } from "react-bootstrap";
import { isEmpty } from "lodash";
import { viewCreationFields } from "./formHelper";
import { CustomerSKUPricingTableView, customerSKULabelledValue } from "./customerManageComponents";
import TableLoader from "utils/table/tableLoader";

const CustomerView = ({ viewPageLoading }) => {
	const customerDetails = useSelector((state) => state.Customers.customerDetails);
	const customerName = customerDetails && customerDetails.customer_name ? customerDetails.customer_name : "";
	const deliveryCenters = customerDetails && customerDetails.delivery_centers ? customerDetails.delivery_centers : [];
	// DC Details Card - For Each DC
	const renderEachDCData = (dcDetails, i) => (
		<div key={i} className="outer-box-view">
			{/* DC Details */}
			<Row className="dc-header mr-2">
				<div className="form-headers">DC Details</div>
			</Row>
			<Row>{viewCreationFields.map((field) => customerSKULabelledValue(field, dcDetails))}</Row>

			{/* Price Allocation */}
			{dcDetails && !isEmpty(dcDetails.skus) && (
				<>
					<Row>
						<div className="form-headers">Pricing & SKU Percentage Allocation</div>
					</Row>
					<CustomerSKUPricingTableView dcDetails={dcDetails} />
				</>
			)}
		</div>
	);

	return (
		<div className="position-relative">
			{/* Customer Name */}
			<h2 className="customer-view-title">{customerDetails && customerName}</h2>

			{/* DC Details & Price Allocation */}
			{!isEmpty(deliveryCenters) && deliveryCenters.map((eachDC, i) => renderEachDCData(eachDC, i))}

			{viewPageLoading && (
				<div className="table-loader-wrapper">
					<TableLoader />
				</div>
			)}
		</div>
	);
};

export default CustomerView;
