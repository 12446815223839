import { DASHBOARD } from "../types";

const initialState = {
	pondDropdownList:[]
};

export default function (state = initialState, { type, payload }) {
	switch (type) {
		case DASHBOARD.POND_LIST:
			return {
				...state,
				pondDropdownList: payload.data,
			};
		default:
			return state;
	}
}
