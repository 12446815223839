import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { fetchLocationDetails } from "store/actions";
import { errorMessage } from "utils/errorMessage";
import { connect } from "react-redux";
import { get } from "lodash";

import "./index.scss";
import { ContentWrap } from "elements";
import { fetchMiniProductsList, getProductVarietyDetails, getProductVarietyList } from "store/actions/productActions";
import { fetchProductBasedList } from "store/actions/locationActions";

const GreenHarvestForecastV2 = ({ fetchLocationDetails, getProductVarietyDetails }) => {
	const [variety, setVariety] = useState({});
	const [greenhouse, setGreenhouse] = useState([]);
	let params = useParams();
	useEffect(() => {
		fetchLocationDetails(
			params.slug,
			(e) => {
				setGreenhouse(e.data);
			},
			errorHandler
		);
		getProductVarietyDetails(params.green_slug, params.slug, successHandler, errorHandler);
	}, [fetchLocationDetails, getProductVarietyDetails, params]);
	const successHandler = (e) => {
		console.log(e.data);
		setVariety(e.data);
	};
	const errorHandler = (e) => {
		errorMessage(e);
	};

	return (
		<ContentWrap>
			<div className="d-flex">
				<div style={{ flex: 1 }}>
					<div style={{ fontSize: 32, fontWeight: "bold" }}>{greenhouse.location_name}</div>
					<div style={{ fontSize: 16 }}>
						{greenhouse.city}, {greenhouse.state}
					</div>
				</div>
				<div style={{ flex: 1, paddingTop: 12 }}>
					<div style={{ fontSize: 16, fontWeight: "bold" }}>
						Green House Type: <span style={{ paddingLeft: 12 }}>Ponds</span>
					</div>
					<div style={{ fontSize: 16, fontWeight: "bold" }}>
						Ponds: <span style={{ paddingLeft: 12 }}>{greenhouse?.pond?.length}</span>
					</div>
					<div style={{ fontSize: 16, fontWeight: "bold" }}>
						Lanes: <span style={{ paddingLeft: 12 }}>{greenhouse?.lanes_per_pond}</span>
					</div>
				</div>
			</div>
			<div style={{ textAlign: "center", paddingTop: 48 }}>
				<div style={{ fontSize: 24, fontWeight: "bold" }}>{variety?.green?.green_name}</div>
				<h2 style={{ fontSize: 24, paddingTop: 0, textAlign: "center" }}>Select a Variety</h2>
				<div className="ui-cards" style={{ paddingTop: 24 }}>
					<div className="d-flex" style={{ justifyContent: "center", alignItems: "center", flexWrap: "wrap" }}>
						{variety?.product_variety_list?.map((v) => {
							return (
								<div
									key={v.slug}
									className="flex mr-3"
									style={{ boxShadow: "0px 0px 8px rgba(0,0,0,.2)", width: "250px", margin: 16 }}>
									<a href={`/gh/${params.slug}/${params.green_slug}/${v.slug}`}>
										<div
											className="card card-primary p-4"
											style={{
												textAlign: "center",
												justifyContent: "center",
												backgroundSize: "cover",
												minHeight: "200px",
												backgroundColor: "rgba(37,116,48,1)",
												// backgroundImage: `url(${require("../../assets/images/placeholder_leaf.png")}`,
											}}>
											<div
												style={{
													textShadow: "2px 2px rgba(0,0,0,.5)",
													fontWeight: "bold",
													fontSize: "24px",
													color: "white",
												}}>
												{v.product_variety_name}
											</div>
										</div>
									</a>
								</div>
							);
						})}
					</div>
				</div>
			</div>
		</ContentWrap>
	);
};

const mapStateToProps = (state) => {
	const { UI, Auth } = state;
	return {
		accessToken: get(Auth, "accessToken", null),
		mainPreloader: get(UI, "mainPreloader", null),
		permissionDetails: get(Auth, "permissionDetails", null),
	};
};

const mapDispatchToProps = {
	getProductVarietyDetails,
	fetchProductBasedList,
	fetchMiniProductsList,
	getProductVarietyList,
	fetchLocationDetails,
};
export default connect(mapStateToProps, mapDispatchToProps)(GreenHarvestForecastV2);
