import React from "react";
import { Col } from "react-bootstrap";
import { connect } from "react-redux";
import { Field } from "redux-form";
import { createStructuredSelector } from "reselect";

import { getForecastTableData } from "store/selectors/forecastTableSelectors";

const componentGroup = ({ id, input, label, type, meta: { touched, error, warning } }) => {
	switch (type) {
		case "div":
			return <div>{input.value || input.value === 0 ? input.value : "-"}</div>;
		case "input":
			return (
				<input
					{...input}
					id={id}
					className={`form-control action-table-input ${
						touched ? (error ? "input-error" : warning ? "warning" : "") : ""
					}`}
					placeholder={label}
					type={type}
				/>
			);
		default:
			return;
	}
};

const changeLocaleString = (val) => {
	if (val) {
		const valString = val.toString();
		const Int =
			valString.indexOf(".") === -1 ? Number(val.replace(/[^0-9-.]/g, "")) : parseFloat(val.replace(/[^0-9-.]/g, ""));

		const str =
			valString === "."
				? valString
				: valString.indexOf(".") === valString.length - 1
				? `${Int.toLocaleString("en-IN")}.`
				: valString.includes(".") && valString[valString.length - 1] === "0"
				? `${Number(valString.split(".")[0].replace(/[^0-9-.]/g, "")).toLocaleString("en-IN")}.${
						valString.split(".")[1]
				  }`
				: `${Int.toLocaleString("en-IN")}`;

		return str;
	}
	return val;
};

const RenderForecastTableColumn = ({
	b_ind,
	a_ind,
	editable,
	c_item,
	c_ind,
	// onBlurValidation,
	slugKey,
	nameKey,
	validation,
	localeString,
}) => {
	return (
		<>
			<>
				<Col key={b_ind} className="forecast-table-col">
					{/* {editable ? ( */}
					<Field
						id={slugKey ? `${c_item[slugKey]}_${nameKey}` : `a${c_item.slug}_${nameKey}`}
						name={slugKey ? c_item[slugKey] : c_item.slug}
						type={editable ? "input" : "div"}
						component={componentGroup}
						validate={validation}
						{...(localeString ? { parse: (val) => changeLocaleString(val) } : {})}
					/>
					{/* ) : (
					<span>{render ? render(c_item) : c_item.value ? c_item.value : "-"}</span>
				)} */}
				</Col>
			</>
		</>
	);
};

const mapStateToProps = createStructuredSelector({
	data: getForecastTableData,
});

const areEqual = (prevProps, nextProps) => {
	return (
		prevProps.b_ind === nextProps.b_ind &&
		prevProps.a_ind === nextProps.a_ind &&
		prevProps.editable === nextProps.editable &&
		prevProps.c_item === nextProps.c_item &&
		prevProps.c_ind === nextProps.c_ind
	);

	//  a_ind, editable, renderKey, render, c_item, tableErrors, data, c_ind
};

export default connect(mapStateToProps)(React.memo(RenderForecastTableColumn, areEqual));
