import React, { useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { change } from "redux-form";
import { createStructuredSelector } from "reselect";
import { Row, Col } from "react-bootstrap";
import Checkbox from "../../../utils/checkbox";
import {
	getInventoryData,
	checkInventoryDateLessThanCurrent,
	getInventoryType,
	getSkuName,
	getFormValues,
} from "store/selectors/ordersPackagingSelectors";
import InventoryTableBody from "./inventoryTableBody";
import { useTranslation } from "react-i18next";

const InventoryTable = ({ data, skuName ,validate}) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const editableData = useSelector(
		(state) => state.form && state.form.inventoryForm && state.form.inventoryForm.values && state.form.inventoryForm.values
	);
	useEffect(() => {
		data &&
			data.map((item, index) => {
				dispatch(change("inventoryForm", `itemSelected[${index}].editable`, false));
				dispatch(change("inventoryForm", `itemSelected[${index}].cases`, item.cases));
			});
	}, [data, dispatch]);

	const onChangeCheckbox = () => {
		if (editableData && editableData.checkbox_all === true) {
			dispatch(change("inventoryForm", `checkbox_all`, false));
			data &&
				data.map((item, index) => {
					dispatch(change("inventoryForm", `itemSelected[${index}].checkbox`, false));
					dispatch(change("inventoryForm", `itemSelected[${index}].date`, item.date));
				});
		} else {
			dispatch(change("inventoryForm", `checkbox_all`, true));
			data &&
				data.map((item, index) => {
					dispatch(change("inventoryForm", `itemSelected[${index}].checkbox`, true));
					dispatch(change("inventoryForm", `itemSelected[${index}].date`, item.date));
				});
		}
	};
	const tableHead = () => {
		return (
			<Row className="modal-table-head">
				<Col className="modal-table-head-col cont-wrap" style={{ flex: 2.1 }}>
					<Checkbox
						className="sg-listing-col-checkbox"
						inputClass="checkbox-input"
						checked={editableData && editableData.checkbox_all ? true : false}
						onChange={(e) => onChangeCheckbox()}
					/>
					<span className="inventory_margin">{t("label.packed_date")}</span>
				</Col>
				<Col className="modal-table-head-col cont-wrap" style={{ flex: 2.1 }}>
					<span>{t("label.packed_cases")}</span>
				</Col>
				<Col className="modal-table-head-col cont-wrap" style={{ flex: 2.1 }}>
					<span>{t("label.required_cases")}</span>
				</Col>
				<Col className="modal-table-head-col cont-wrap" style={{ flex: 0.7 }}>
					<span>{t("label.action")}</span>
				</Col>
			</Row>
		);
	};

	const tableBody = () => {
		return (
			<div className="modal-table-body">
				{data &&
					data.map((item, index) => {
						return (
							<InventoryTableBody
								key={index}
								data={item}
								date={item.date}
								cases={item.cases}
								value={`itemSelected[${index}]`}
								index={index}
								editable={false}
							/>
						);
					})}
			</div>
		);
	};

	return (
		<div className="modal-table-wrapper">
			<Row>
				<div className="sku-name">{skuName && skuName.sku_name}</div>
			</Row>
			{tableHead()}
			{tableBody()}
			{validate !== null && (
				<span className="error-text">{validate}</span>
			)}
		</div>
	);
};

const mapStateToProps = createStructuredSelector({
	data: getInventoryData,
	skuName: getSkuName,
	checkInventoryDateLessThanCurrent: checkInventoryDateLessThanCurrent,
	inventoryType: getInventoryType,
	formValues: getFormValues,
});

const areEqual = (prevProps, nextProps) => {
	return (
		prevProps.data === nextProps.data &&
		prevProps.checkInventoryDateLessThanCurrent === nextProps.checkInventoryDateLessThanCurrent &&
		prevProps.inventoryType === nextProps.inventoryType &&
		prevProps.validate === nextProps.validate
	);
};

export default connect(mapStateToProps)(React.memo(InventoryTable, areEqual));
