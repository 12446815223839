import fetchHandler from "../../utils/fetchHandler";
import { SESSION, COMMON } from "../types";

export const sendLoginCredentials = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `auth/login`,
		method: "POST",
		secure: false,
		actionType: SESSION.SEND_LOGIN_CREDENTIALS,
		body: JSON.stringify(values),
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export function updateLoginCredentials(credentials, successHandler) {
	successHandler && successHandler();
	return {
		type: SESSION.UPDATE_LOGIN_CREDENTIALS,
		payload: credentials,
	};
}

export const getProfileDetails = (successHandler, errorHandler) => {
	const fetchOptions = {
		url: `user`,
		method: "GET",
		secure: true,
		actionType: SESSION.GET_PROFILE_DETAILS,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export function refreshToken(values, successHandler) {
	const fetchOptions = {
		url: `token-refresh/`,
		method: "POST",
		secure: false,
		actionType: SESSION.SEND_LOGIN_CREDENTIALS,
		body: JSON.stringify(values),
	};
	return fetchHandler(fetchOptions, successHandler);
}

export function verifyToken(values, successHandler, errorHandler) {
	const fetchOptions = {
		url: `token-verify/`,
		method: "POST",
		secure: false,
		actionType: SESSION.SEND_LOGIN_CREDENTIALS,
		body: JSON.stringify(values),
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
}

export const sendForgotPasswordCredentials = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `auth/forgot`,
		method: "POST",
		secure: false,
		actionType: SESSION.SEND_FORGOT_PASSWORD_CREDENTIALS,
		body: JSON.stringify(values),
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const sendResetPasswordCredentials = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `auth/reset`,
		method: "PUT",
		secure: false,
		body: JSON.stringify(values),
		actionType: SESSION.SEND_RESET_PASSWORD_CREDENTIALS,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const checkTokenValidity = (body, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `auth/check-token`,
		method: "POST",
		secure: false,
		body: JSON.stringify(body),
		actionType: SESSION.CHECK_TOKEN_VALIDITY,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const sendRegisterNowCredentials = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `members/`,
		method: "POST",
		secure: false,
		actionType: SESSION.SEND_REGISTER_NOW_CREDENTIALS,
		body: JSON.stringify(values),
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const logoutApp = (successHandler) => {
	successHandler && successHandler();
	return {
		type: COMMON.LOGOUT_APP,
		payload: null,
	};
};

export const getPermissionDetails = (roleId, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `role_privilage/${roleId}`,
		method: "GET",
		secure: true,
		actionType: SESSION.GET_PERMISSION_DETAILS,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const onLogout = (successHandler, errorHandler) => {
	const fetchOptions = {
		url: `auth/logout`,
		method: "GET",
		secure: true,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};
