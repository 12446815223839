import React from "react";
import { Col, Row, Table } from "react-bootstrap";
import {
	TaskDetailsView,
	ProductInfoView,
	GrowingCondView,
	GerminationCondView,
	TransplantDetailsViewBottom,
	tableHeadersBasicDetails,
	tableHeadersTransplantDetails,
	tableHeadersPondMetrics,
	PondMetricsView,
	climateParametersView,
} from "./viewHelper";
import { ButtonPrimary, ButtonLink } from "../../../elements";
import "../transplant.scss";
import i18next from "i18next";
import Util from "../../../utils/Util";

export const labelledValue = (item, details = {}) => (
	<Col className={`${item.classes} mb-3`} key={item.label}>
		<p className="d-flex align-items-center text-muted mb-1">
			<span className="light-badge-pill"></span>
			{i18next.t(item.label)}
		</p>
		<p className="pill-text ml-4">{item.render ? item.render(details) : details ? details[item.accessor] || "-" : "-"}</p>
	</Col>
);

export const BasicDetails = ({ taskDetails, seasonDetails }) => (
	<div className="outer-box-view">
		<Row>
			<div className="form-headers">{i18next.t("label.task_details")}</div>
		</Row>
		{taskDetails && <Row>{TaskDetailsView.map((item) => labelledValue(item, taskDetails))}</Row>}
		{taskDetails && taskDetails.transplant_and_growing_task_details ? (
			<div className="table-scroll custom-scroll">
				<div className="table-wrap mb-3">
					<Table responsive="sm">
						<thead>
							<tr className="table-header-color">
								{tableHeadersBasicDetails.map((heading, index) => (
									<th key={index}>{i18next.t(heading)}</th>
								))}
							</tr>
						</thead>
						<tbody>
							{taskDetails.transplant_and_growing_task_details.map((metrics, index) => (
								<tr key={index}>
									<td> {metrics.pond_number.value || ""}</td>
									<td> {metrics.lane_number}</td>
									<td> {metrics.number_of_boards}</td>
								</tr>
							))}
						</tbody>
					</Table>
				</div>
			</div>
		) : null}
	</div>
);

const EditButton = ({ onClick }) => (
	<button className="btn btn-cell btn-light btn-sm ml-2 float-right light-border" onClick={() => onClick()}>
		<i className="icon icon-edit" />
	</button>
);

export const TransplantDetails = ({ taskDetails, onClickEditAction, logPermission, transplantActive }) => (
	<div className="outer-box-view">
		<Row className="d-flex justify-content-between">
			<div className="form-headers">{i18next.t("label.transplant_details")}</div>
			{logPermission && transplantActive && <EditButton onClick={() => onClickEditAction(2)} />}
		</Row>
		{taskDetails.transplant_and_growing_task_transplant_details &&
		taskDetails.transplant_and_growing_task_transplant_details.transplant_details ? (
			<>
				<div className="table-scroll custom-scroll">
					<div className="table-wrap mb-3">
						<Table responsive="sm">
							<thead>
								<tr className="table-header-color">
									{tableHeadersTransplantDetails.map((heading, index) => (
										<th key={index}>{i18next.t(heading)}</th>
									))}
								</tr>
							</thead>
							<tbody>
								{taskDetails.transplant_and_growing_task_transplant_details.transplant_details.map(
									(metrics, index) => (
										<tr key={index}>
											<td> {metrics.pond_number.value || ""}</td>
											<td> {metrics.lane_number}</td>
											<td> {metrics.number_of_boards}</td>
											<td> {metrics.germination_rate}</td>
										</tr>
									)
								)}
							</tbody>
						</Table>
						<Col
							className={` mb-2 d-flex justify-content-center text-center col-xl-12 col-lg-12 col-md-12 col-sm-12 col-10`}>
							{logPermission && transplantActive && (
								<ButtonLink className="view-table-btn" onClick={() => onClickEditAction(4)}>
									<i className="icon icon-plus mr-2" />
									{i18next.t("label.Add")}
								</ButtonLink>
							)}
						</Col>
					</div>
				</div>
			</>
		) : (
			""
		)}

		<Row>{TransplantDetailsViewBottom.map((item) => labelledValue(item, taskDetails))}</Row>
	</div>
);

export const ClimateDetails = ({ taskDetails, onClickEditAction }) => (
	<div className="sg-view-content">
		<div className="form-headers">{i18next.t("label.climate_parameters")}</div>
		<Row>{climateParametersView.map((item) => labelledValue(item, taskDetails))}</Row>
	</div>
);

export const PondMetrics = ({ taskDetails }) => (
	<div className="outer-box-view">
		<Row>
			<div className="form-headers">{i18next.t("label.pond_metrics")}</div>
		</Row>
		{taskDetails.pond_metrics ? (
			<div className="table-scroll custom-scroll">
				<div className="table-wrap mb-3">
					<Table responsive="sm">
						<thead>
							<tr className="table-header-color">
								{/* <th></th> */}
								{tableHeadersPondMetrics.map((heading, index) => (
									<th style={{width:heading.value === "time" ? "10%":""}} key={index}>{i18next.t(heading.label)}</th>
								))}
							</tr>
						</thead>
						<tbody>
							{taskDetails.pond_metrics.map((metrics, index) => (
								<tr key={index}>
									<td width="10%"> {metrics.pond_number || ""}</td>
									<td width="10%"> {metrics.ph}</td>
									<td width="10%"> {metrics.ec}</td>
									<td width="10%"> {metrics.do}</td>
									<td width="10%"> {metrics.pond_temperature}</td>
									<td width="10%"> {Util.getTime(metrics.time)}</td>
								</tr>
							))}
						</tbody>
					</Table>
				</div>
			</div>
		) : (
			""
		)}
	</div>
);

export const PondDetails = ({ taskDetails }) => (
	<>
		{taskDetails.pond_view && taskDetails.pond_view.pond_details ? (
			<div className="outer-box-view">
				<Row>
					<div className="form-headers">{i18next.t("label.pond_view")}</div>
				</Row>
				<div className="table-scroll scroll-invisible custom-scroll">
					<div className="table-wrap mb-3">
						<div className="table-responsive custom-scroll">
							<table className="table">
								<thead>
									<tr className="table-header-color">
										<th scope="col" width="30%">{i18next.t("label.pond")}</th>
										{Array.from({ length: taskDetails.pond_view.lanes_per_pond }).map((_, index) => (
											<th width="20%" scope="col" key={index}>
												{i18next.t("label.lane")} {index + 1}
											</th>
										))}
									</tr>
								</thead>
								<tbody>
									{taskDetails.pond_view.pond_details &&
										Object.keys(taskDetails.pond_view.pond_details).map((m, index) => (
											<tr key={index}>
												<td width="20%"> {m}</td>
												{taskDetails.pond_view.pond_details[m].map((value, i) => (
													<td key={i}> {value.product_variety}</td>
												))}
											</tr>
										))}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		) : (
			""
		)}
	</>
);

export const TransplantProductInformation = ({ taskDetails, seasonDetails }) =>
	taskDetails &&
	seasonDetails && (
		<div className="outer-box-view">
			<Row>
				<div className="form-headers">{i18next.t("label.product_information")}</div>
			</Row>
			{seasonDetails && <Row>{ProductInfoView.map((item) => labelledValue(item, seasonDetails))}</Row>}
			<Row>
				<div className="form-headers">{i18next.t("label.growing_condition")}</div>
			</Row>
			{seasonDetails && <Row>{GrowingCondView.map((item) => labelledValue(item, seasonDetails))}</Row>}
			<Row>
				<div className="form-headers">{i18next.t("label.germination_condition")}</div>
			</Row>
			{seasonDetails && <Row>{GerminationCondView.map((item) => labelledValue(item, seasonDetails))}</Row>}
			<Row>
				<div className="form-headers">{i18next.t("label.pond_metrics")}</div>
			</Row>
			{seasonDetails && <Row>{PondMetricsView.map((item) => labelledValue(item, seasonDetails))}</Row>}
		</div>
	);

export const TaskStatusBar = ({
	type = 0,
	onClickStatusAction,
	startTaskBtn,
	completeTaskBtn,
	logPermission,
	tastStartTime,
	transplantActive,
}) =>
	type === 1 ? (
		<div className="sg-view-footer">
			{logPermission && transplantActive && (
				<ButtonPrimary className="start-task-btn" onClick={() => onClickStatusAction(type)}>
					{startTaskBtn}
				</ButtonPrimary>
			)}
		</div>
	) : type === 2 && transplantActive ? (
		<div className="sg-view-content status-bar">
			<h2 className="font-weight-bold">
				<span className="view-labels">{i18next.t("label.status")} </span>
				{i18next.t("label.transplant_in_progress")}
				{tastStartTime && (
					<p className="status-bar-subtitle">
						<span className="view-labels">{i18next.t("label.actual_transplant_start_date")} </span>
						<span className="font-weight-bold">{tastStartTime}</span>
					</p>
				)}
			</h2>
			{logPermission && transplantActive && (
				<ButtonPrimary onClick={() => onClickStatusAction(type)}>
					{i18next.t("label.enter_transplant_details")}
				</ButtonPrimary>
			)}
		</div>
	) : type === 3 ? (
		<div className="sg-view-footer">
			{logPermission && transplantActive && (
				<ButtonPrimary className="complete-task-btn" onClick={() => onClickStatusAction(type)}>
					{completeTaskBtn}
				</ButtonPrimary>
			)}
		</div>
	) : null;
