import { TO_ADD_LIST } from "../types";

const initialState = {
	loading: false,
	dropdownData: null,
	renderType: 0,
	nutrientsData: null,
	nutrientsColumn: null,
	addListData: null,
	addListColumn: null,
};

export default function (state = initialState, { type, payload }) {
	switch (type) {
		case TO_ADD_LIST.LOADER_ADD_LIST:
			return {
				...state,
				loading: !state.loading,
			};

		case TO_ADD_LIST.SET_DROPDOWN_ADD_LIST:
			return {
				...state,
				dropdownData: payload.data,
			};
		case TO_ADD_LIST.SET_RENDER_TYPE_ADD_LIST:
			return {
				...state,
				renderType: payload,
			};
		case TO_ADD_LIST.SET_NUTRIENTS_ADD_LIST:
			return {
				...state,
				nutrientsData: payload.data.tableData,
				nutrientsColumn: payload.data.headerData,
			};
		case TO_ADD_LIST.SET_DATA_ADD_LIST:
			return {
				...state,
				addListData: payload.data.tableData,
				addListColumn: payload.data.headerData,
			};

		case TO_ADD_LIST.DELETE_DATA_TO_ADD_LIST:
			const deletedData = state.addListData.filter((item) => item.dosing_calculator_slug !== payload);
			return {
				...state,
				addListData: deletedData,
			};
		default:
			return state;
	}
}
