import React from "react";
import { Modal } from "react-bootstrap";
import useModal from "utils/useModal";
import BulkAssigneeForm from "./bulkAssigneeForm";
import "./bulkAssignee.scss";
import { useTranslation } from 'react-i18next';

const { Header, Title, Body } = Modal;

const BulkAssignee = ({ doSuccessHandling }) => {
	const { t } = useTranslation();
	const [isOpen, , , closeModal, modalProps] = useModal("bulkAssignee");
	return (
		<Modal size="lg" show={isOpen} onHide={closeModal} className="modal-sidepanel">
			<Header closeButton>
				<Title className="modal-title">{t("label.select_assignee")}</Title>
			</Header>
			<Body>
				<BulkAssigneeForm initialBody={modalProps} doSuccessHandling={doSuccessHandling} closeModal={closeModal} />
			</Body>
		</Modal>
	);
};

export default BulkAssignee;
