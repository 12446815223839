import React from "react";
import moment from "moment";
import { ButtonLink, ListingBtn } from "elements";
import { ordersPackagingStatus } from "constant/global";
import i18next from "i18next";

export const renderColumns = ({
	addToFilter,
	handleDeletePanel,
	handleEditPanel,
	handleViewPanel,
	deleteAccess,
	updateAccess,
	viewAccess,
}) => {
	return [
		{
			name: i18next.t("label.order_id"),
			accessor: "order_id",
			className: "orders-listing-col-id",
			headerClassName: "orders-listing-col-id-header seedingCentreHeading",
			filter: {
				type: "search",
				onChange: addToFilter,
			},
			render: (row) => {
				return viewAccess ? (
					<ButtonLink className="p-0" onClick={() => handleViewPanel(row)}>
						{row.order.order_id}
					</ButtonLink>
				) : (
					row.order.order_id
				);
			},
		},
		{
			name: i18next.t("label.customer"),
			accessor: "customer",
			className: "orders-listing-col-name",
			headerClassName: "orders-listing-col-name-header",
			filter: {
				type: "search",
				onChange: addToFilter,
			},
			render: (row) => (
				<ButtonLink className="p-0 buttonCenter" onClick={() => handleViewPanel(row)}>
					{row.customer_name}
				</ButtonLink>
			),
		},
		{
			name: i18next.t("label.dc"),
			accessor: "dc_name",
			className: "orders-listing-col-skus ",
			headerClassName: "orders-listing-col-dc-header",
			render: (row) => (
				<ButtonLink className="p-0 buttonCenter" onClick={() => handleViewPanel(row)}>
					{row.dc_name}
				</ButtonLink>
			),
			sortable: false,
		},
		{
			name: i18next.t("label.sku"),
			accessor: "sku",
			className: "orders-listing-col-sku",
			headerClassName: "orders-listing-col-sku-header",
			filter: {
				type: "search",
				onChange: addToFilter,
			},
			render: (row) => (
				<ButtonLink className="p-0 buttonCenter" onClick={() => handleViewPanel(row)}>
					{row.sku_name}
				</ButtonLink>
			),
		},
		{
			name: i18next.t("label.req_Cases"),
			accessor: "cases",
			className: "orders-listing-col-req",
			headerClassName: "orders-listing-col-req-header",
			render: (row) => row.cases,
			sortable: false,
		},
		{
			name: i18next.t("label.cases_packed"),
			accessor: "cases_packed",
			className: "orders-listing-col-cases-packed",
			headerClassName: "orders-listing-col-cases-packed-header",
			render: (row) => row.cases_packed,
			sortable: false,
		},
		{
			name: i18next.t("label.request"),
			accessor: "order_request_date",
			className: "orders-listing-col-ord",
			headerClassName: "orders-listing-col-ord-header overflow-unset",
			filter: { type: "calendar", onChange: addToFilter },
			render: (row) => moment(row.order_request_date).format("MM/DD/YYYY"),
			sortable: false,
		},
		{
			name: i18next.t("label.delivery"),
			accessor: "date_of_delivery",
			className: "orders-listing-col-dod",
			headerClassName: "orders-listing-col-dod-header overflow-unset",
			filter: { type: "calendar", onChange: addToFilter },
			render: (row) => (row.date_of_delivery ? moment(row.date_of_delivery).format("MM/DD/YYYY") : "-"),
			sortable: false,
		},
		{
			name: i18next.t("label.status_col"),
			accessor: "status",
			className: "orders-listing-col-status",
			headerClassName: "orders-listing-col-status-header",
			options: ordersPackagingStatus,
			filter: {
				type: "select",
				options: ordersPackagingStatus,
				onChange: addToFilter,
			},
			render: (row) => row.status,
		},
		{
			name: i18next.t("label.actions"),
			className: "orders-listing-col-actions",
			headerClassName: "orders-listing-col-actions-header",
			hide: updateAccess || deleteAccess ? false : true,
			render: (row) => {
				return (
					<>
						{updateAccess && (
							<ListingBtn tooltipText={i18next.t("tooltip.edit")} iconClass="icon icon-edit" onClick={() => handleEditPanel(row)} />
						)}
						{deleteAccess && (
							<ListingBtn
								tooltipText={i18next.t("tooltip.delete")}
								iconClass="icon icon-trash"
								onClick={() => handleDeletePanel(`${row.slug}`)}
							/>
						)}
					</>
				);
			},
			sortable: false,
		},
	];
};
