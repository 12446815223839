import React from "react";
import { Modal } from "react-bootstrap";
import { ButtonPrimary, ButtonSecondary } from "../elements";
import { useTranslation } from 'react-i18next';

const ConfirmModal = ({ show, message = "Are you sure?", onYes, onNo }) => {
	const { t } = useTranslation();
	return (
		<Modal size="lg" show={show} onHide={onNo} className="modal-confirm">
			<div>
				<p>{message}</p>
				<div className="d-flex justify-content-end">
					<ButtonSecondary className="mr-3" onClick={onNo}>
						{t("label.no")}
					</ButtonSecondary>
					<ButtonPrimary onClick={onYes}>{t("label.yes")}</ButtonPrimary>
				</div>
			</div>
		</Modal>
	);
};

export default ConfirmModal;
