import React from "react";
import { Col, Row } from "react-bootstrap";

export const getCustomTotal = (data, type, combinedDifferenceSP) => {
	return (
		<Row className="forecast-table-body-row">
			{data.map((item, ind) => (
				<Col key={ind} className="forecast-table-col">
					{type === "production_vs_sales_difference" ? (
						<>
							<span className={item.production - item.sales >= 0 ? "txt-green" : "txt-red"}>
								{(item.production - item.sales).toLocaleString("en-IN")}
							</span>
						</>
					) : type === "production_vs_sales" ? (
						<>
							<span className="production-value">
								{item.production || item.production === 0 ? item.production.toLocaleString("en-IN") : "-"}
							</span>
							{" / "}
							<span className="sales-value">
								{item.sales || item.sales === 0 ? item.sales.toLocaleString("en-IN") : "-"}
							</span>
						</>
					) : type === "production_vs_sales_combined" ? (
						combinedDifferenceSP ? (
							<span className={item.production - item.sales >= 0 ? "txt-green" : "txt-red"}>
								{(item.production - item.sales).toLocaleString("en-IN")}
							</span>
						) : (
							<>
								<span className="production-value">
									{item.production || item.production === 0 ? item.production.toLocaleString("en-IN") : "-"}
								</span>
								{" / "}
								<span className="sales-value">
									{item.sales || item.sales === 0 ? item.sales.toLocaleString("en-IN") : "-"}
								</span>
							</>
						)
					) : null}
					{/* <span className="production-value">{item.production &&item.production===0 ? item.production.toLocaleString("en-IN") : "-"}</span>
				{" / "}
				<span className="sales-value">{item.sales &&item.sales===0 ? item.sales.toLocaleString("en-IN") : "-"}</span> */}
				</Col>
			))}
		</Row>
	);
};
