import React, { useState } from "react";
import { Col, Row, Button } from "react-bootstrap";
import { findIndex, find, map, sum } from "lodash";
import { OrderDetailsView, PackagingDetailsView } from "./viewHelper";
import { ButtonPrimary, ButtonLink, ButtonSecondary } from "elements";
import { required, number, max100p, min0p } from "constant/formValidators";
import { successMessage } from "utils/successMessage";
import { errorMessage } from "utils/errorMessage";
import i18next from "i18next";
import { isEmpty } from "lodash";

export const labelledValue = (item, data = {}, packagingDetails) => (
	<Col className={`${item.classes} mb-3`} key={item.label}>
		<p className="d-flex align-items-center view-labels mb-1">
			<span className="light-badge-pill"></span>
			{i18next.t(item.label)}
		</p>
		<p className="view-values ml-4">
			{item.render
				? item.render(data, packagingDetails)
				: data
				? data[item.accessor]
					? `${data[item.accessor]}${item.unit || ""}`
					: "-"
				: "-"}
		</p>
	</Col>
);

export const OrderDetails = ({ data }) => (
	<div className="op-view-content">
		<h2 className="form-headers mt-3 mb-4 pl-0">{i18next.t("label.order_details")}</h2>
		<Row>{OrderDetailsView.map((item) => labelledValue(item, data))}</Row>
	</div>
);

export const PackagingDetails = ({
	showEdit,
	data,
	packagingDetails,
	onClickEdit,
	updatePackagingTaskAccess,
	packagingActivate,
}) => (
	<div className="op-view-content">
		<h2 className="form-headers mt-3 mb-4 pl-0">
			{showEdit && updatePackagingTaskAccess && packagingActivate && (
				<button className="btn btn-cell btn-light btn-sm ml-2 float-right" onClick={() => onClickEdit("PACKAGE")}>
					<i className="icon icon-edit" />
				</button>
			)}
			{i18next.t("label.packaging_details")}
		</h2>
		<Row>{PackagingDetailsView.map((item) => labelledValue(item, data, packagingDetails))}</Row>
	</div>
);

export const TaskStatusBar = ({
	type = 0,
	onClickStatusAction,
	loading,
	completeTaskAccess,
	startTaskAccess,
	createPackagingTaskAccess,
	tastStartTime,
	packagingActivate,
	addInventory,
}) =>
	type === 1 ? (
		// STATUS : NEW
		<div className="op-view-footer">
			{!isEmpty(addInventory) && (
				<ButtonPrimary className="add-inventory-button" onClick={() => onClickStatusAction(6)}>
					{i18next.t("label.add_inventory")}
				</ButtonPrimary>
			)}
			{startTaskAccess && packagingActivate && (
				<ButtonPrimary className="start-task-btn" onClick={() => onClickStatusAction(type)}>
					{loading ? i18next.t("label.startingButton") : i18next.t("label.start_packaging")}
				</ButtonPrimary>
			)}
		</div>
	) : type === 2 && packagingActivate ? (
		// STATUS : IN PROGRESS & Package details NOT entered
		<div className="op-view-content status-bar">
			<h2 className="status-bar-title mb-0">
				<span className="view-labels">{i18next.t("label.status")} </span>
				{i18next.t("label.packaging_in_progress")}
				{/* {tastStartTime && (
					<p className="status-bar-subtitle">
						<span className="view-labels">Actual Packaging Start Date and Time : </span>
						<span className="font-weight-bold">{tastStartTime}</span>
					</p>
				)} */}
			</h2>
			<div className={!isEmpty(addInventory) ? "add-inventory-display" : ""}>
				{!isEmpty(addInventory) && (
					<ButtonPrimary className="add-inventory-button1" onClick={() => onClickStatusAction(6)}>
						{i18next.t("label.add_inventory")}
					</ButtonPrimary>
				)}
				{createPackagingTaskAccess && (
					<ButtonPrimary onClick={() => onClickStatusAction(type)}>
						{i18next.t("label.enter_packaging_details")}
					</ButtonPrimary>
				)}
			</div>
		</div>
	) : type === 3 ? (
		// STATUS : IN PROGRESS & Package details entered
		<div className="op-view-footer">
			{!isEmpty(addInventory) && (
				<ButtonPrimary className="add-inventory-button" onClick={() => onClickStatusAction(6)}>
					{i18next.t("label.add_inventory")}
				</ButtonPrimary>
			)}
			{completeTaskAccess && packagingActivate && (
				<ButtonPrimary onClick={() => onClickStatusAction(type)}>
					{loading ? i18next.t("label.completing") : i18next.t("label.complete_packaging")}
				</ButtonPrimary>
			)}
		</div>
	) : null;

// Change Recipe Button
export const BtnSKUChange = ({ onClickEdit, className }) => (
	<Col xs={12} className="d-flex justify-content-end pr-0">
		<Button variant="secondary" className={`btn-sku-change ${className}`} onClick={() => onClickEdit("RECIPE")}>
			{i18next.t("label.change_recipe")}
		</Button>
	</Col>
);

// SKU Update for Order
export const EditSKU = ({ slug, initialValues, onCloseEditRecipe, changeSKURecipeOfOrder, callFetchOrder }) => {
	const [formValues, setFormValues] = useState(initialValues);
	const [updatingRecipe, setUpdatingRecipe] = useState(false);

	const validateRecipe = (value) => required(value) || number(value) || max100p(value) || min0p(value) || null;

	// Handle input change
	const onHandleInput = (e, value) => {
		const index = findIndex(formValues, ["product_variety_slug", value.product_variety_slug]);
		const updatedRecipeArr = [...formValues];
		updatedRecipeArr[index] = {
			...updatedRecipeArr[index],
			percentage: e.target.value,
			error: validateRecipe(e.target.value),
		};
		setFormValues(updatedRecipeArr);
	};

	// On Reset
	const onReset = () => {
		const arr = map(formValues, (o) => ({
			...o,
			percentage: o.actual_percentage,
			error: validateRecipe(o.actual_percentage),
		}));
		setFormValues(arr);
	};

	// Disable button if any errors
	const ifAnyErrors = find(formValues, (o) => o.error) || null;

	const isPercentageSumValid = () => {
		const numbArr = map(formValues, (o) => o && +o.percentage);
		const sumOfRecipe = sum(numbArr);
		return sumOfRecipe === 100 ? true : false;
	};

	// Submit Form
	const submitUpdatedRecipe = () => {
		if (isPercentageSumValid()) {
			setUpdatingRecipe(true);
			const onSuccess = (e) => {
				setUpdatingRecipe(false);
				successMessage(e);
				callFetchOrder();
			};
			const onError = (e) => {
				setUpdatingRecipe(false);
				errorMessage(e);
			};
			const body = {
				sku_recipe: formValues,
			};
			changeSKURecipeOfOrder(slug, body, onSuccess, onError);
		} else {
			errorMessage("RECIPE_SUM_MAX_100");
		}
	};

	return (
		<div className="op-view-content">
			<h2 className="form-headers mt-3 mb-4 pl-0">{i18next.t("label.recipe")}</h2>
			<Row>
				{formValues &&
					formValues.map((item, i) => (
						<Col md={4} sm={6} xs={12} key={i} className={`form-group text ${item.error ? "error" : ""}`}>
							{item.error && <span className="span-error">{item.error}</span>}
							<input
								className="form-control"
								key={i}
								type="text"
								value={item.percentage}
								onChange={(e) => onHandleInput(e, item)}
							/>
							<span className="form-label">{item.product_variety_name}</span>
						</Col>
					))}
			</Row>
			{/* Footer */}
			<Col xs={12} className="d-flex justify-content-end mt-3 mb-3">
				<ButtonLink className="btn-link-grey" onClick={() => onCloseEditRecipe("CLOSE_RECIPE")}>
					{i18next.t("label.cancel")}
				</ButtonLink>
				<ButtonSecondary className="ml-3" onClick={onReset}>
					{i18next.t("label.reset_to_actual")}
				</ButtonSecondary>
				<ButtonPrimary
					type="submit"
					className="ml-3"
					disabled={ifAnyErrors || updatingRecipe}
					onClick={submitUpdatedRecipe}>
					{updatingRecipe ? i18next.t("label.applying_recipe") : i18next.t("label.save_Apply_recipe")}
				</ButtonPrimary>
			</Col>
		</div>
	);
};
