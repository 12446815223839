import { LR } from "../types";
import Util from "utils/Util";
import { keys, isEmpty } from "lodash";
const initialState = {
	loading: false,
	dropdownData: null,
	data: null,
	renderType: 0,
	columns: null,
	dateSlugMapping: null,
	croptimalData: [],
	formDataArray: [],
	productVarietySelected: null,
	activePond: null,
	pondTabs: null,
	pondTabsLR: null,
};

export default function (state = initialState, { type, payload }) {
	let value = [];
	let temp = {};
	let tempMap = {};
	switch (type) {
		case LR.LOADER_LR:
			return {
				...state,
				loading: !state.loading,
			};

		case LR.SET_DROPDOWN_DATA_LR:
			return {
				...state,
				dropdownData: payload.data,
			};
		case LR.SET_RENDER_TYPE_LR:
			return {
				...state,
				renderType: payload,
			};
		case LR.SET_CROPTIMAL_SELECTION:
			return {
				...state,
				productVarietySelected: payload,
			};
		case LR.REFRESH_LISTING_LR:
			return {
				...initialState,
				dropdownData: state.dropdownData,
				renderType: state.renderType,
			};
		case LR.SET_DATA_LR:
			return {
				...state,
				data: payload,
			};
		case LR.SET_CROPTIMAL_LR:
			return {
				...state,
				croptimalData: payload,
			};
		case LR.SET_COLUMNS_LR:
			return {
				...state,
				columns: payload,
			};
		case LR.SET_FORM_LR:
			return {
				...state,
				formDataArray: payload,
			};
		case LR.SET_LIST_DATA_CROPTIMAL_LR:
			return {
				...state,
				croptimalData: payload.data,
			};

		case LR.SET_KEY_MAPPING_DATE:
			return {
				...state,
				dateSlugMapping: payload,
			};

		case LR.SET_LIST_DATA_LR:
			value = payload.data && keys(payload.data);
			temp = {};
			tempMap = {};
			// eslint-disable-next-line array-callback-return
			if (!isEmpty(payload.data)) {
				payload.data &&
					payload.data[value[0]].map((item) => {
						temp[item.slug] = item.result_data || [];
					});
				tempMap =
					payload.data &&
					payload.data[value[0]].map((item) => ({
						name: Util.checkValidDateString(item.result_date),
						slug: item.slug,
					}));
			}

			return {
				...state,
				data: temp,
				dateSlugMapping: tempMap,
				activePond: !isEmpty(payload.data) ? keys(payload.data)[0].replace(/\s/g, "") : null,
				pondTabs: !isEmpty(payload.data) ? payload.data : null,
			};

		case LR.SET_LIST_EDIT_DATA_LR:
			value = payload.data && keys(payload.data);
			temp = {};
			tempMap = {};
			// eslint-disable-next-line array-callback-return
			if (!isEmpty(payload.data)) {
				payload.data &&
					payload.data[state.activePond].map((item) => {
						temp[item.slug] = item.result_data || [];
					});
				tempMap =
					payload.data &&
					payload.data[state.activePond].map((item) => ({
						name: Util.checkValidDateString(item.result_date),
						slug: item.slug,
					}));
			}

			return {
				...state,
				data: temp,
				dateSlugMapping: tempMap,
				activePond: state.activePond,
				pondTabs: !isEmpty(payload.data) ? payload.data : null,
			};
		case LR.SET_ACTIVE_TAB_POND_LR:
			const tempData = {};
			// eslint-disable-next-line array-callback-return
			state.pondTabs[payload].map((item) => {
				tempData[item.slug] = item.result_data || [];
			});

			const tempMapData = state.pondTabs[payload].map((item) => ({
				name: Util.checkValidDateString(item.result_date),
				slug: item.slug,
			}));
			return {
				...state,
				activePond: payload,
				data: tempData,
				dateSlugMapping: tempMapData,
			};
		case LR.SET_ACTIVE_PONDS:
			return {
				...state,
				pondTabsLR: payload,
				activePond: payload[0].label,
				activePondId: payload[0].value,
			};
		case LR.SET_ACTIVE_TAB:
			return {
				...state,
				activePond: payload,
			};
		default:
			return state;
	}
}
