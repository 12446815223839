import React, { useState } from "react";
import { connect } from "react-redux";
import { Container, Button, Row, Col, Form } from "react-bootstrap";
import { ButtonLink } from "../../../elements";
import { reduxForm, Field, reset, change, untouch, getFormValues } from "redux-form";
import FormGroups from "../../../utils/formGroup";
import { useDispatch } from "react-redux";
import {
	formCreationFieldsTop,
	formCreationFieldsMiddle,
	formCreationFieldsBottom,
	getValidations,
	dropdownArrays,
} from "./formHelper";
import { createTransplantTaskDetails } from "../../../store/actions";
import useModal from "../../../utils/useModal";
import { toggleMainLoader } from "../../../store/actions";
import { errorMessage } from "../../../utils/errorMessage";
import { successMessage } from "../../../utils/successMessage";
import { capitalize, isEmpty } from "lodash";
import { findKey } from "lodash";
import TableLoader from "utils/table/tableLoader";
import Util from "../../../utils/Util";

import { useTranslation } from 'react-i18next';

const TaskAddEdit = ({
	handleSubmit,
	createTransplantTaskDetails,
	toggleMainLoader,
	reset,
	form,
	taskDetails,
	doFetchTaskDetails,
	initialValues,
	locationList,
	metricsList,
	formValues,
}) => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const [, modalType, , closeModal] = useModal("transplant_details");
	const [isLoading, setIsLoading] = useState(false);
	const [btnTitle, setbtnTitle] = useState(t("label.save"));
	const [locationMetricsList, setlocationMetricsList] = useState(
		initialValues && initialValues.transplantMapArray ? initialValues.transplantMapArray : [{ key: 0, flag: "add" }]
	);
	const locationPondLaneMappedArray = metricsList[taskDetails.location.value];

	const submitTransplantDetails = (values) => {
		if (!isLoading) {
			setIsLoading(true);
			setbtnTitle(t("label.saving"));
			toggleMainLoader(true);

			const metrics = locationMetricsList.map(function (metric) {
				return {
					...values["lm_" + metric.key],
					pond_number: values["lm_" + metric.key].pond_number.value,
					lane_number: values["lm_" + metric.key].lane_number.value,
				};
			});

			const submissionData = {
				transplant_details: metrics,
				flag_color: values.flag_color.value ? values.flag_color.value : "",
				task_description: values.task_description ? values.task_description : "",
			};

			const successHandler = (event) => {
				reset(form);
				successMessage(event);
				setIsLoading(false);
				setbtnTitle(t("label.save"));
				closeModal();
				doFetchTaskDetails();
			};
			const errorHandler = (event) => {
				errorMessage(event);
				setIsLoading(false);
				setbtnTitle(t("label.save"));
			};
			const method = modalType && modalType === 2 ? "PUT" : "POST";

			const payload = taskDetails.task_id ? taskDetails.task_id : "";
			createTransplantTaskDetails(submissionData, method, payload, successHandler, errorHandler);
		}
	};

	const getLaneList = (pond, id) => {
		const laneCount = locationPondLaneMappedArray["lanes_per_pond"];

		const lanesSelected = [];
		locationMetricsList.forEach((listItem, idx) => {
			if (
				// idx !== id &&
				listItem.key !== id &&
				formValues &&
				formValues[`lm_${listItem.key}`] &&
				formValues[`lm_${listItem.key}`]["pond_number"] &&
				formValues[`lm_${listItem.key}`]["pond_number"]["value"] &&
				formValues[`lm_${listItem.key}`]["pond_number"]["value"].toString() === pond.toString() &&
				formValues[`lm_${listItem.key}`]["lane_number"] &&
				formValues[`lm_${listItem.key}`]["lane_number"]["value"]
				// formValues[`lm_${idx}`]["lane_number"]["value"] !== formValues[`lm_${id}`]["lane_number"]["value"]
			) {
				lanesSelected.push(formValues[`lm_${listItem.key}`]["lane_number"]["value"]);
			}
		});

		const arr = Array(laneCount)
			.fill()
			.map((_, i) => ({
				label: i + 1,
				value: (i + 1).toString(),
				...(!isEmpty(lanesSelected)
					? { isdisabled: lanesSelected.includes((i + 1).toString()) ? true : false }
					: { isdisabled: false }),
			}));

		return arr;
	};

	const onPondChange = (id) => {
		formValues[`lm_${id}`] &&
			formValues[`lm_${id}`]["lane_number"] &&
			formValues[`lm_${id}`]["lane_number"]["value"] &&
			dispatch(change("transplantForm", `lm_${id}.lane_number`, null));
	};

	const getOptions = (field, id) => {
		// console.log(field, id, formValues, locationPondLaneMappedArray);
		switch (field) {
			case "pond_number":
				return Util.mapOptions(locationPondLaneMappedArray["pond"], "value", "id") || [];
			case "lane_number":
				// return enumerateOptions(locationPondLaneMappedArray, "lanes_per_pond");

				return (
					(formValues &&
						formValues[`lm_${id}`] &&
						formValues[`lm_${id}`].pond_number &&
						formValues[`lm_${id}`].pond_number.value &&
						getLaneList(formValues[`lm_${id}`].pond_number.value, id)) ||
					[]
				);

			case "flag_color":
				return dropdownArrays.flagColors;

			default:
				return [];
		}
	};

	const getFormFieldHtml = (fields) => {
		let formFieldHtml = [];
		formFieldHtml = fields.map((field, idx) => {
			return (
				<React.Fragment key={idx}>
					<Col
						className={
							field.name === "task_description" || field.name === "flag_color"
								? "col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
								: "col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12"
						}>
						<Form.Group controlId={`formControl ${field.name}`}>
							{field.type === "select-react" ? (
								<Field
									name={field.name}
									type={field.type}
									label={t(field.label)}
									placeholder={t(field.placeholder)}
									component={FormGroups}
									validate={getValidations(field.name)}
									options={getOptions(field.name, idx)}
								/>
							) : (
								<Field
									name={field.name}
									type={field.type}
									label={t(field.label)}
									placeholder={t(field.placeholder)}
									component={FormGroups}
									validate={getValidations(field.name)}
								/>
							)}
						</Form.Group>
					</Col>
				</React.Fragment>
			);
		});
		return <>{formFieldHtml}</>;
	};

	const metricsFormFields = (count, flag = "add", i) => {
		let columns = [];

		columns = formCreationFieldsMiddle.map((field, idx) => {
			return (
				<Col className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12" key={idx}>
					<Form.Group controlId={`formControl ${idx}`}>
						{field.type === "select-react" ? (
							<Field
								name={`lm_${count}.${field.name}`}
								type={field.type}
								label={t(field.label)}
								placeholder={t(field.placeholder)}
								component={FormGroups}
								validate={getValidations(field.name)}
								options={getOptions(field.name, count)}
								onChange={() => (field.name === "pond_number" ? onPondChange(count) : null)}
							/>
						) : (
							<Field
								name={`lm_${count}.${field.name}`}
								type={field.type}
								label={t(field.label)}
								placeholder={t(field.placeholder)}
								component={FormGroups}
								validate={getValidations(field.name)}
							/>
						)}
					</Form.Group>
				</Col>
			);
		});

		return (
			<React.Fragment key={i}>
				<div className="outer-box">
					<Row>{columns}</Row>
					<Row>
						{locationMetricsList.length - 1 === i ? (
							<Col
								className={`d-flex justify-content-center text-center ${
									locationMetricsList.length === 1 ? "col-xl-12 col-lg-12  " : "col-xl-6 col-lg-6"
								} col-md-12 col-sm-12 col-12`}>
								<ButtonLink onClick={() => setMetricsArray(count + 1)}>
									<i className="icon icon-plus mr-2" />
									{t("label.Add")}
								</ButtonLink>
							</Col>
						) : (
							""
						)}
						{locationMetricsList.length !== 1 ? (
							<Col
								className={`d-flex justify-content-center text-center ${
									locationMetricsList.length - 1 !== i ? "col-xl-12 col-lg-12  " : "col-xl-6 col-lg-6"
								} col-md-12 col-sm-12 col-12`}>
								<ButtonLink className="remove-link-btn" onClick={() => removeMetricsArray(count)}>
									<i className="icon icon-minus mr-2" />
									{t("label.remove")}
								</ButtonLink>
							</Col>
						) : (
							""
						)}
					</Row>
				</div>
			</React.Fragment>
		);
	};
	const setMetricsArray = (count) => {
		dispatch(change("transplantForm", "lm_" + count + ".pond_number", []));
		dispatch(untouch("transplantForm", "lm_" + count + ".pond_number", []));
		dispatch(change("transplantForm", "lm_" + count + ".lane_number", []));
		dispatch(untouch("transplantForm", "lm_" + count + ".lane_number", []));
		dispatch(change("transplantForm", "lm_" + count + ".number_of_boards", []));
		dispatch(untouch("transplantForm", "lm_" + count + ".number_of_boards", []));
		dispatch(change("transplantForm", "lm_" + count + ".germination_rate", ""));
		dispatch(untouch("transplantForm", "lm_" + count + ".germination_rate", ""));
		setlocationMetricsList([
			...locationMetricsList,
			{
				key: count,
				flag: "add",
			},
		]);
	};
	const removeMetricsArray = (index) => {
		const locationMetricsListKeyToRemove = findKey(locationMetricsList, function (o) {
			return o.key === index;
		});

		const list = [...locationMetricsList];
		list.splice(locationMetricsListKeyToRemove, 1);
		setlocationMetricsList(list);
	};
	return (
		<>
			<Form onSubmit={handleSubmit(submitTransplantDetails)} className="transplantForm">
				<Container className="position-relative">
					<Row>{getFormFieldHtml(formCreationFieldsTop)}</Row>

					{locationMetricsList &&
						locationMetricsList.map((x, i) => {
							return metricsFormFields(x.key, x.flag, i);
						})}

					<Row>{getFormFieldHtml(formCreationFieldsBottom)}</Row>
					{isLoading && (
						<div className="table-loader-wrapper">
							<TableLoader />
						</div>
					)}
				</Container>
				<div className="modal-footer save-footer">
					<Button variant="secondary" onClick={closeModal}>
						{t("label.cancel")}
					</Button>
					<Button variant="primary" type="submit" disabled={isLoading}>
						{btnTitle}
					</Button>
				</div>
			</Form>
		</>
	);
};

const mapStateToProps = (state) => {
	const { Transplant, Auth, Modals } = state;
	const transplantTaskDetailsArray = Transplant.transplantTaskDetails.transplant_and_growing_task_transplant_details;
	const initialArray = () => {
		const transplantMetrics =
			transplantTaskDetailsArray && transplantTaskDetailsArray.transplant_details
				? transplantTaskDetailsArray.transplant_details
				: [];
		const transplantMetricsArray = [];
		transplantMetrics.forEach(function (data, key) {
			transplantMetricsArray["lm_" + key] = {
				pond_number: { value: data["pond_number"]["id"].toString(), label: data["pond_number"]["value"].toString() },
				lane_number: { value: data["lane_number"].toString(), label: data["lane_number"].toString() },
				number_of_boards: data["number_of_boards"],
				germination_rate: data["germination_rate"],
			};
		});
		const transplantMapArray = [];
		transplantMetrics.forEach(function (data, key) {
			transplantMapArray[key] = [];
			transplantMapArray[key]["key"] = key;
			transplantMapArray[key]["flag"] = "edit";
		});
		const formatedInitialValueArray = {
			...transplantMetricsArray,
			...transplantMapArray,
			transplantMapArray: transplantMapArray,
			task_id: Transplant.transplantTaskDetails.task_id && Transplant.transplantTaskDetails.task_id,
			task_description: transplantTaskDetailsArray.task_description ? transplantTaskDetailsArray.task_description : "",

			flag_color: transplantTaskDetailsArray.flag_color
				? {
						label: capitalize(transplantTaskDetailsArray.flag_color),
						value: transplantTaskDetailsArray.flag_color,
				  }
				: {},
		};
		return transplantTaskDetailsArray ? formatedInitialValueArray : [];
	};
	return {
		formValues: getFormValues("transplantForm")(state),
		locationList: Auth.userLocations,
		metricsList: Transplant.locationMapping,
		initialValues: Modals.modalType === 2 ? initialArray() : {},
	};
};

const mapDispatchToProps = {
	toggleMainLoader,
	createTransplantTaskDetails,
	reset,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(
	reduxForm({
		form: "transplantForm",
		enableReinitialize: true,
	})(TaskAddEdit)
);
