import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { ContentWrap, ButtonPrimary, Breadcrumbs, BackButton } from "../../../elements";
import Listing from "../../../partials/listings";
import useProductViews from "../useProductViews";
import ConfirmModal from "../../../utils/confirmModal";
import { renderColumns } from "./helper";
import { successMessage } from "../../../utils/successMessage";
import { errorMessage } from "../../../utils/errorMessage";
import { find, reject } from "lodash";
import useModal from "../../../utils/useModal";
// import GreensManage from "../manage";
import {
	getProductVarietyList,
	deleteProductVariety,
	getProductVarietyDetails,
	clearProductDetails,
	setProductVarietyStatus,
	getListOfLocations,
} from "../../../store/actions/productActions";
import "../productVariety.scss";
import ProductVarietyManage from "../manage";
import ProductView from "../manage/productView";
import TableLoader from "../../../utils/table/tableLoader";
import { LocationDropdown } from "components/bulkAssignee/bulkAssigneeComponents";
import Util from "utils/Util";
import ActivateLocationModal from "../manage/activateLocation";
import { useTranslation } from "react-i18next";
import { isEmpty } from "lodash";

// 0 LIST
// 1 VIEW
// 2 EDIT
// 3 ADD
// 4 Loader only
const ProductVarietyListing = ({
	getProductVarietyList,
	getProductVarietyDetails,
	deleteProductVariety,
	clearProductDetails,
	setProductVarietyStatus,
	productDetails,
	permissionDetails,
	locationSlugs,
	getListOfLocations,
	productVarietiesList
}) => {
	const { t } = useTranslation();
	const [goBackToView, setGoBackToView] = useState(false);
	const [listFilter, setListFilter] = useState("");
	const [refetch, setRefetch] = useState(0);
	const [loading, setloading] = useState(false);
	const [editPanel, setEditPanel] = useState(false);
	const [, modalType, showModal, closeModal, modalProps] = useProductViews("product_variety");
	const [isDeletModalOpen, , showDeleteModal, closeDeleteModal, deleteModalProps] = useProductViews("deleteProduct");
	const [filterLocation, setFilterLocation] = useState("");
	const [, , showModal1] = useModal("locations");
	const [exportFlag,setExportFlag] = useState(null);

	useEffect(() => {
		// set initial loading filterLocation to locationSlugs[0]
		if (!filterLocation) {
			setFilterLocation(locationSlugs ? locationSlugs[0] : []);
		}

		// set initial loading listFilter to locationSlugs[0] and column_name
		if (locationSlugs[0]?.value && !listFilter) {
			setListFilter([{ column_name: "location_slug", search: locationSlugs[0]?.value }]);
		}
	}, [locationSlugs, filterLocation, listFilter]);

	const handleViewPanel = (slug) => {
		const filterSlug = filterLocation ? filterLocation.value : listFilter[0]?.search;
		getProductVarietyDetails(slug, filterSlug, () => showModal(1));
	};
	const handleAddPanel = () => {
		setEditPanel(false);
		clearProductDetails();
		showModal(3);
	};

	const handleEditPanel = (slug) => {
		const mode = slug ? 2 : 3;
		const filterSlug = filterLocation ? filterLocation.value : listFilter[0]?.search;
		setloading(true);
		const successHandler = () => {
			onSuccess(mode, slug);
			setEditPanel(true);
		};
		getProductVarietyDetails(slug, filterSlug, successHandler, onError);
	};
	const handleDeletePanel = (data) => showDeleteModal(0, data);

	const ActivateLocation = (slug) => {
		setloading(true);
		const successHandler = (e) => {
			setloading(false);
			e.data && e.data ? showModal1(5) : errorMessage("Something Went Wrong!");
		};
		getListOfLocations(slug.product_varieties[0].slug, successHandler, onError);
	};

	// View Page | Edit button
	const handleViewEdit = (slug) => {
		closeModal();
		showModal(4);
		setGoBackToView(true);
		handleEditPanel(slug);
	};

	const onSuccess = (modalType, slug) => {
		setloading(false);
		showModal(modalType, slug);
	};
	const onError = (e) => {
		setloading(false);
		errorMessage(e);
	};

	// View > Edit | Back button
	const conditionalClose = () => {
		if (goBackToView) {
			getProductVarietyDetails(productDetails.slug, () => showModal(1));
			setGoBackToView(false);
		} else {
			closeModal();
		}
	};
	const changeProductVarityStatus = (location_slug, pv_slug, active, product_slug) => {
		const successHandler = (e) => {
			successMessage(e);
			setRefetch(refetch + 1);
		};
		const errorHandler = (e) => {
			errorMessage(e);
		};
		setProductVarietyStatus(
			{ location_slug, pv_slug, active },
			() => getProductVarietyDetails(product_slug, location_slug, successHandler, errorHandler),
			errorHandler
		);
	};
	const onDeleteBoard = () => {
		const filterSlug = filterLocation && filterLocation.value && filterLocation.value;
		const successHandler = (e) => {
			closeDeleteModal();
			successMessage(e);
			setRefetch(refetch + 1);
		};
		const errorHandler = (e) => {
			closeDeleteModal();
			errorMessage(e);
		};
		deleteProductVariety(deleteModalProps.slug, filterSlug, successHandler, errorHandler);
	};

	// Filter
	const addToFilter = (e) => {
		let obj = [];
		e.forEach((item) => {
			return (obj =
				item && item.column
					? [
							...obj,
							{
								column_name: item.column.accessor,
								search: item.value,
							},
					  ]
					: obj);
		});
		const locationFilter = find(listFilter, ["column_name", "location_slug"]);
		locationFilter ? setListFilter([...obj, locationFilter]) : setListFilter(obj);
	};

	const columns = renderColumns({
		handleViewPanel,
		handleEditPanel,
		handleDeletePanel,
		addToFilter,
		permissionDetails,
		ActivateLocation,
	});
	const addPermission =
		permissionDetails &&
		permissionDetails.product_variety &&
		permissionDetails.product_variety.permissions.create_product_variety
			? permissionDetails.product_variety.permissions.create_product_variety
			: "";
	const listPermission =
		permissionDetails &&
		permissionDetails.product_variety &&
		permissionDetails.product_variety.permissions.list_product_variety
			? permissionDetails.product_variety.permissions.list_product_variety
			: "";
	const updatePermission =
		permissionDetails &&
		permissionDetails.product_variety &&
		permissionDetails.product_variety.permissions.update_product_variety
			? permissionDetails.product_variety.permissions.update_product_variety
			: "";

	const PageTitleBreadCrumb = (
		<Breadcrumbs
			keyValue="productVariety"
			showLabel={listPermission}
			label={
				modalType === 0 ? "List" : modalType === 1 ? "View" : modalType === 2 ? "Edit" : modalType === 3 ? "Add" : "..."
			}>
			{modalType === 1 && updatePermission && (
				<button
					className="btn btn-cell btn-light btn-sm ml-2 float-right"
					onClick={() => handleViewEdit(productDetails.slug)}>
					<i className="icon icon-edit" />
				</button>
			)}
		</Breadcrumbs>
	);

	// ----------------- BULK ASSIGNEE ----------------- //
	const onSelectFilterLocation = (location = null) => {
		if (location) {
			const obj = {
				column_name: "location_slug",
				search: location.value || "",
			};
			const rejectArr = reject(listFilter, ["column_name", "location_slug"]);
			setListFilter([...rejectArr, obj]);
		} else {
			const rejectArr = reject(listFilter, ["column_name", "location_slug"]);
			setListFilter(rejectArr);
		}
		// Set location filter
		setFilterLocation(location);
	};

	const exportHandler = () =>{
		setExportFlag(true);
	}

	const exportHandlerFlag = () => {
		setExportFlag(false);
	};


	return (
		<ContentWrap>
			<div className="d-flex mb-5">
				{modalType ? <BackButton onClick={conditionalClose} /> : null}
				{PageTitleBreadCrumb}
				{!modalType && Object.keys(locationSlugs).length > 1 && filterLocation && listPermission && (
					<LocationDropdown
						value={filterLocation}
						options={locationSlugs}
						onSelect={onSelectFilterLocation}
						isClearable={false}
					/>
				)}
				{!isEmpty(productVarietiesList) &&
					<ButtonPrimary className="export_btn" onClick={exportHandler}>
					 <span>
					{t("label.export")}{" "}
						<i className="icon icon-export text-sm" style={{fontWeight:"bold"}}/>
					</span>
				   </ButtonPrimary>
				}
				{!modalType && addPermission ? (
					<ButtonPrimary onClick={handleAddPanel}>
						<i className="icon icon-plus mr-2" /> Add Product Variety
					</ButtonPrimary>
				) : null}
			</div>
			{!modalType ? (
				<div className="position-relative">
					{listPermission && locationSlugs ? (
						<Listing
							fetchAction={getProductVarietyList}
							columns={columns}
							refetch={refetch}
							sort_name="green_name"
							filter={listFilter}
							exportFlag={exportFlag} 
							exportHandlerFlag={()=>{exportHandlerFlag()}}
						/>
					) : (
						""
					)}
					{loading && !permissionDetails && (
						<div className="table-loader-wrapper">
							<TableLoader />
						</div>
					)}
				</div>
			) : modalType === 1 ? (
				<ProductView
					productDetails={productDetails}
					clearProductDetails={clearProductDetails}
					changeProductVarityStatus={changeProductVarityStatus}
					currentLocation={filterLocation}
				/>
			) : modalType === 4 ? (
				<TableLoader />
			) : (
				<ProductVarietyManage
					doRefetch={() => setRefetch(refetch + 1)}
					initialValues={modalProps}
					handleEditPanel={handleEditPanel}
					isEdit={modalType === 2}
					listFilter={listFilter}
					editPanel={editPanel}
				/>
			)}
			<ActivateLocationModal/>
			<ConfirmModal
				show={isDeletModalOpen}
				onNo={closeDeleteModal}
				onYes={onDeleteBoard}
				message="Are you sure want to delete this product variety?"
			/>
		</ContentWrap>
	);
};

const mapStateToProps = (state) => ({
	productDetails: state.Product.productVarietyDetails,
	permissionDetails: state.Auth.permissionDetails,
	locationSlugs: Util.mapOptions(state?.Auth?.userDetails?.location_slugs, "name", "slug"),
	location: state?.Auth?.userLocations[0],
	productVarietiesList: state.Product.productVarietiesList
});

const mapDispatchToProps = {
	getProductVarietyList,
	deleteProductVariety,
	getProductVarietyDetails,
	clearProductDetails,
	setProductVarietyStatus,
	getListOfLocations,
};
export default connect(mapStateToProps, mapDispatchToProps)(ProductVarietyListing);
