import React, { Fragment } from "react";
import { Form, Row, Col, Tabs, Tab } from "react-bootstrap";
import { Field, FieldArray } from "redux-form";
import FormGroups from "../../../utils/formGroup";
import { get } from "lodash";
import { LocationField, LunaField, SeasonFields } from "./formHelper";
import Util from "../../../utils/Util";

// ============================ Normal Form Field ============================ //
export const RenderFormFields = ({ arr = [], nameParam = null, keyStr = "" }) =>
	arr.map((formSection, i) => (
		<Fragment key={`${keyStr}_${arr.heading}_${i}`}>
			{/* value = {props.options.filter(option =>  option.label === 'Some label') */}

			<h2 className="font-weight-bold mt-3">{formSection.heading}</h2>
			<Row>
				{formSection.fields.map((formField, j) => (
					// add value ^^
					<Col key={`PROD_${i}_${j}`} className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
						<Form.Group controlId="formControl">
							<Field
								name={nameParam ? `${nameParam}.${formField.name}` : formField.name}
								type={formField.type}
								label={formField.label}
								placeholder={formField.placeholder}
								component={FormGroups}
								validate={formField.validationsArr}
								multiple={formField.isMultiple || false}
								options={formField.options || []}
								disable={formField.disabled || false}
								defaultValue={
									formField.options ? formField.options.filter((option) => option.label === "New") : null
								}
							/>
						</Form.Group>
					</Col>
				))}
			</Row>
		</Fragment>
	));

// ============================ Controlled Form Field ============================ //
export const RenderControlField = ({ formData, onChange }) => (
	<Col className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
		<Form.Group controlId="formControl">
			<Form.Group controlId="formControl">
				<Field
					name={formData.name}
					type={formData.type}
					label={formData.label}
					placeholder={formData.placeholder}
					component={FormGroups}
					validate={formData.validationsArr}
					multiple={formData.isMultiple || false}
					options={formData.options || []}
					disable={formData.disabled || false}
					onChange={(e) => onChange(e)}
				/>
			</Form.Group>
		</Form.Group>
	</Col>
);

// ============================ Image Upload Field ============================ //
export const RenderImageUpload = ({ name = "", defaultImage = null }) => (
	<Form.Group controlId="formControl">
		<Field name={name} type="image-upload" defaultValue={defaultImage} component={FormGroups} multiple={false} />
	</Form.Group>
);

// ============================ Seeds Tab ============================ //
export const RenderSeedTabs = ({
	isEdit,
	fields,
	formFieldValues = {},
	locationsList = [],
	boardsDropdownList = [],
	getActiveTab,
	onClickSeedsTab,
	seasonActiveTab,
	onClickSeasonsTab,
	editPanel,
}) => {
	const getFormName = (seed) => (get(formFieldValues, seed) && get(formFieldValues, seed).seed_name) || "Seed";
	const getFormKey = (seed, i) => {
		const SEEDS_ID = (get(formFieldValues, seed) && get(formFieldValues, seed).slug) || `PR_SEEDS_${i}`;
		return SEEDS_ID;
	};
	const getLunaCropId = (seed) => (get(formFieldValues, seed) && get(formFieldValues, seed).luna_crop_id) || null;
	const getFirstImage = (seed) => (get(formFieldValues, seed) && get(formFieldValues, seed).first_image) || null;
	const getSecondImage = (seed) => (get(formFieldValues, seed) && get(formFieldValues, seed).second_image) || null;
	const getThirdImage = (seed) => (get(formFieldValues, seed) && get(formFieldValues, seed).third_image) || null;
	const getLocation = (seed) =>
		Util.mapOptions(
			get(formFieldValues, seed) && get(formFieldValues, seed).getActiveLocation,
			"location_name",
			"slug",
			"used"
		);

	return (
		<Tabs
			id="uncontrolled-tab-example"
			onSelect={(k) => onClickSeedsTab(k)}
			activeKey={getActiveTab()}
			defaultActiveKey={getActiveTab}>
			{fields.map((seed, i) => (
				<Tab eventKey={i} title={getFormName(seed)} key={getFormKey(seed, i)}>
					{/* Images */}
					<h2 className="font-weight-bold mt-3 mb-4">Images</h2>
					{/* Show image upload synchrsly after adding one another */}

					<Row>
						{isEdit ? (
							<Col className="d-flex align-items-start">
								<RenderImageUpload name={`${seed}.first_image`} defaultImage={getFirstImage(seed)} />
								<RenderImageUpload name={`${seed}.second_image`} defaultImage={getSecondImage(seed)} />
								<RenderImageUpload name={`${seed}.third_image`} defaultImage={getThirdImage(seed)} />
							</Col>
						) : (
							<Col className="d-flex align-items-start">
								<RenderImageUpload name={`${seed}.first_image`} defaultImage={getFirstImage(seed)} />
								{getFirstImage(seed) && (
									<RenderImageUpload name={`${seed}.second_image`} defaultImage={getSecondImage(seed)} />
								)}
								{getSecondImage(seed) && (
									<RenderImageUpload name={`${seed}.third_image`} defaultImage={getThirdImage(seed)} />
								)}
							</Col>
						)}
					</Row>
					{/* Location */}
					{editPanel !== true && (
						<RenderFormFields nameParam={seed} keyStr="PV_ADD_LOC" arr={LocationField(getLocation(seed))} />
					)}
					<RenderFormFields
						nameParam={`${seed}.luna_crop_id`}
						keyStr="PV_ADD_LUNA"
						arr={LunaField(getLunaCropId(seed))}
					/>
					{/* Seasons */}
					<div className="highlight-tabs-wrapper">
						<FieldArray
							name={`${seed}.seasons`}
							formFieldValues={formFieldValues}
							component={RenderSeedSeasonTabs}
							boardsDropdownList={boardsDropdownList}
							seasonActiveTab={seasonActiveTab}
							seedIndex={i}
							onClickSeasonsTab={onClickSeasonsTab}
						/>
					</div>
				</Tab>
			))}
		</Tabs>
	);
};

// ============================ Seasons Tab ============================ //
export const RenderSeedSeasonTabs = ({
	fields,
	formFieldValues,
	boardsDropdownList = [],
	seasonActiveTab,
	seedIndex,
	onClickSeasonsTab,
}) => {
	const getFormName = (season) => (get(formFieldValues, season) && get(formFieldValues, season).season_name) || "Season";
	const getFormKey = (season, i) => {
		const SEASON_ID = (get(formFieldValues, season) && get(formFieldValues, season).season_id) || `PR_SEEDS_SEASON_${i}`;
		return SEASON_ID;
	};
	return (
		<Tabs
			onSelect={(k) => onClickSeasonsTab(k)}
			activeKey={seasonActiveTab}
			defaultActiveKey={seasonActiveTab}
			className="highlight-tabs">
			{fields.map((season, i) => (
				<Tab
					key={getFormKey(season, i)}
					eventKey={`${seedIndex}_${i}`}
					title={getFormName(season)}
					className={i === 0 ? "first" : null}>
					<RenderFormFields
						nameParam={season}
						keyStr="PV_ADD_SEASON_DETAILS"
						arr={SeasonFields(boardsDropdownList)}
					/>
				</Tab>
			))}
		</Tabs>
	);
};
