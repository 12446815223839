import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { getRenderType } from "store/selectors/ordersPackagingSelectors";
import OrdersPackingListing from "./listing";
import OrdersPackingView from "./view";
import SKUComposition from "./skuComposition";
import "./ordersPackaging.scss";
import PackagingOrder from "./packagingOrder";

const OrdersPackaging = ({ renderType }) => {
	return (
		<div>
			{renderType === 0 ||
			renderType === 4 ||
			renderType === 5 ||
			renderType === 6 ||
			renderType === 7 ||
			renderType === 8 ||
			renderType === 9 ||
			renderType === 10 ||
			renderType === 11 ||
			renderType === 12 ? (
				<OrdersPackingListing />
			) : renderType === 1 ? (
				<OrdersPackingView />
			) : renderType === 2 ? (
				<SKUComposition />
			) : renderType === 3 ? (
				<PackagingOrder />
			) : null}
		</div>
	);
};

const mapStateToProps = createStructuredSelector({
	renderType: getRenderType,
});

const areEqual = (prevProps, nextProps) => {
	return prevProps.renderType === nextProps.renderType;
};

export default connect(mapStateToProps)(React.memo(OrdersPackaging, areEqual));
