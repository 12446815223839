import React, { useEffect } from "react";
import { Breadcrumbs, ContentWrap } from "elements";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import Listing from "./listing";
import { fetchDropdownDataDosingCalc, setLoaderDosingCalc, setRenderTypeDosingCalc } from "store/actions";
import { getLoading, getRenderType, getAddPermission } from "store/selectors/dosingCalcSelectors";
import { errorMessage } from "utils/errorMessage";
import TableLoader from "utils/table/tableLoader";
import "./index.scss";
import { DosingCalcForm } from "./manage";

const DosingCalculator = ({
	loading,
	setLoaderDosingCalc,
	renderType,
	fetchDropdownDataDosingCalc,
	setRenderTypeDosingCalc,
	addPermission,
}) => {
	useEffect(() => {
		setLoaderDosingCalc();
		const successHandler = () => {
			setLoaderDosingCalc();
		};
		const errorHandler = (e) => {
			errorMessage(e);
			setLoaderDosingCalc();
		};
		fetchDropdownDataDosingCalc(successHandler, errorHandler);
	}, [fetchDropdownDataDosingCalc, setLoaderDosingCalc]);

	return (
		<ContentWrap>
			<div className="d-flex align-items-center mb-3">
				<Breadcrumbs
					className="forecast-breadcrumb"
					keyValue="nutrientTracking"
					showLabel={true}
					label={"Dosing Calculator"}
				/>
			</div>

			<div className="position-relative dosing-calculator-wrapper" style={{ paddingBottom: !addPermission ? 20 : 0 }}>
				<DosingCalcForm/>
				{addPermission && <Listing />}
				{loading && (
					<div className="table-loader-wrapper">
						<TableLoader />
					</div>
				)}
			</div>
		</ContentWrap>
	);
};

const mapStateToProps = createStructuredSelector({
	loading: getLoading,
	renderType: getRenderType,
	addPermission: getAddPermission,
});

export default connect(mapStateToProps, { setLoaderDosingCalc, fetchDropdownDataDosingCalc, setRenderTypeDosingCalc })(
	DosingCalculator
);
