import React, { useState, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import ToggleButton from "utils/toggleButton";
import TableLoader from "utils/table/tableLoader";
import { fetchProductChangeStatus } from "store/actions/locationActions";
import { getListOfLocations } from "../../../store/actions/productActions";
import ConfirmModal from "utils/confirmModal";
import { successMessage } from "utils/successMessage";
import { errorMessage } from "utils/errorMessage";
import FormGroups from "../../../utils/formGroup";
import { Row, Col, Form } from "react-bootstrap";
import {  Field } from "redux-form";
import Util from "utils/Util";

const ManageStatusTable = ({ modalType }) => {
	const [loader, setLoader] = useState(false);
	const [confirmModal, setConfirmModal] = useState(false);
	const [confirmData, setConfirmData] = useState(null);
	const [modelStatus, setModelStatus] = useState("");
	const status = useSelector((state) => state?.Product?.locationList);
	const getSeedList = Util.mapOptions(status, "pv_name", "pv_slug");
	const [locationList, setLocationList] = useState([]);
	const selectedPV = useSelector((state) => state?.form?.product_variety_form?.values);

	const dispatch = useDispatch();

	const changeUserStatus = (e, data) => {
		setConfirmData([
			{
				e: e,
				data: data,
			},
		]);
		modalType === 5 &&
			setModelStatus(`Are you sure you want to make the Location status ${e !== true ? "Inactive" : "active"}?`);
		setConfirmModal(true);
	};

	const handleClose = () => setConfirmModal(false);

	const handleSubmit = () => {
		const { e, data } = confirmData[0];
		setLoader(true);
		let isSubmit = true;
		const successHandler = (res) => {
			successMessage(res);
			if (res.status === "success" && isSubmit === true) {
				const successHandler = (res) => {
					res && setLoader(false);
					const locationList = res && res.data.find(function (item) {
						return item.pv_slug === selectedPV?.Seeds?.value;
					});
					setLocationList(locationList.locations);
				};
				if (modalType === 5) {
					dispatch(getListOfLocations(selectedPV?.Seeds?.value, successHandler, onError));
				}
			}
			isSubmit = false;
		};

		const onError = (err) => {
			errorMessage(err);
			setLoader(false);
		};

		const postBody = {
			pv_slug: selectedPV?.Seeds?.value,
			location_slug: data?.slug,
			active: e,
		};
		dispatch(fetchProductChangeStatus(postBody, successHandler, onError));
		setConfirmModal(false);
	};
	const onChangeFormFields = (e, field) => {
		const locationList = status.find(function (item) {
			return item.pv_slug === e.value;
		});
		setLocationList(locationList.locations);
	};

	return (
		<Fragment>
			<ConfirmModal show={confirmModal} onNo={handleClose} onYes={handleSubmit} message={modelStatus} />
			<Row>
				<Col xs={12}>
					<Form.Group controlId={`formControl`}>
						<Field
							name="Seeds"
							type="select-react"
							label="Seed *"
							placeholder="Select Seed"
							component={FormGroups}
							options={getSeedList || []}
							onChange={(e) => onChangeFormFields(e, "seed")}
						/>
					</Form.Group>
				</Col>
			</Row>
			{locationList?.length !== 0 ? (
				<div className="mt-3">
					<div className="modal-table-head d-flex ml-1 mr-1">
						<Col className="modal-table-head-col cont-wrap">
							<span> {modalType === 5 ? "Location" : ""}</span>
						</Col>
						<Col className="modal-table-head-col cont-wrap justify-content-center">
							<span>Status</span>
						</Col>
					</div>
					<div className="table-body-wrapper">
						{!loader &&
							locationList?.map((data, index) => (
								<div className="modal-table-body-row d-flex" key={index}>
									<Col className="modal-table-col cont-wrap">
										<span>{data.name || data.name}</span>
									</Col>
									<Col className="modal-table-col cont-wrap justify-content-center">
										<ToggleButton value={data.active} onChange={(e) => changeUserStatus(e, data)} />
									</Col>
								</div>
							))}
						{locationList?.length === 0 && <p className="d-flex justify-content-center">No Results Found</p>}
						{loader && (
							<div className="table-loader-wrapper">
								<TableLoader />
							</div>
						)}
					</div>
				</div>
			) : null}
		</Fragment>
	);
};

export default ManageStatusTable;
