import React, { useEffect } from "react";
import ForecastTable from "utils/forecastTable";
import ForecastForm from "../../../manage";
import { createStructuredSelector } from "reselect";
import {
	getDcKeyMapping,
	getForecastAddData,
	getForecastColumns,
	getForecastFormCustomerAdd,
	getForecastFormFromDate,
	getForecastFormLocation,
	getForecastFormToDate,
} from "store/selectors/forecastSelectors";
import { connect } from "react-redux";
import { setLoader, addWeeklyForecast, setForecastRenderType, forecastResetRefresh } from "store/actions";
import { successMessage } from "utils/successMessage";
import { errorMessage } from "utils/errorMessage";
import moment from "moment";

const AddForecast = ({
	data,
	columns,
	location,
	setLoader,
	addForecast,
	setForecastRenderType,
	forecastResetRefresh,
	dcKeyMapping,
	fromDate,
	toDate,
	customerSelected,
	addWeeklyForecast,
}) => {
	const onSave = (values) => {
		// values contain the edited values
		setLoader();
		const submitData = {
			location_slug: location.value,
			from: moment(fromDate).format("YYYY-MM-DD"),
			to: moment(toDate).format("YYYY-MM-DD"),
			forecast_details: values,
			customer_slug: customerSelected.value,
			distribution_centers: values,
		};

		const successHandler = (e) => {
			successMessage(e);
			setLoader();
			setForecastRenderType(0);
			forecastResetRefresh();
		};
		const errorHandler = (e) => {
			errorMessage(e);
			setLoader();
		};
		addWeeklyForecast(submitData, successHandler, errorHandler);
	};

	const onCancel = () => {
		setForecastRenderType(0);
	};

	useEffect(() => {
		return () => {
			forecastResetRefresh();
		};
	}, [forecastResetRefresh]);

	return (
		<div className="listing-wrapper">
			<ForecastForm type={6} />

			{data && (
				<>
					<ForecastTable
						editable={true}
						columns={columns}
						fixedColumnHeading="DC Name"
						initialData={data}
						onSave={onSave}
						onCancel={onCancel}
						dateFormat="DD-MM-YYYY"
						keyMapping={dcKeyMapping}
						renderKey="forecast_value"
						localeString={true}
						// render={(item) => item.forecast_value}
					/>
				</>
			)}
		</div>
	);
};

const mapStateToProps = createStructuredSelector({
	data: getForecastAddData,
	columns: getForecastColumns,
	fromDate: getForecastFormFromDate,
	toDate: getForecastFormToDate,
	location: getForecastFormLocation,
	dcKeyMapping: getDcKeyMapping,
	customerSelected: getForecastFormCustomerAdd,
});

export default connect(mapStateToProps, { setLoader, addWeeklyForecast, setForecastRenderType, forecastResetRefresh })(
	AddForecast
);
