import { number, required, isDateObj, requiredDateObj, max, isInt, minValue } from "../../../constant/formValidators";
import { flagColors, containerFilled } from "../../../constant/global";
import moment from "moment";
import i18next from "i18next";

export const SeedingTaskFormDetails = [
	{
		label: i18next.t("label.actual_board_selected"),
		name: "seeded_board_count",
		type: "text",
		placeholder: i18next.t("seeding.enter_number_of_board"),
		validate: [required, number, minValue(0), isInt],
	},
	{
		label: i18next.t("label.flag_color_required"),
		name: "seeding_flag_color",
		type: "select-react",
		placeholder: i18next.t("label.enter_flag_color"),
		options: flagColors,
		validate: [required],
	},
	{
		label: i18next.t("label.seed_density_required"),
		name: "seeding_seed_density",
		type: "text",
		placeholder: i18next.t("label.seeding_enter_seed_density"),
		validate: [required, number, minValue(0)],
	},
	{
		label: i18next.t("label.seed_container_filled_required"),
		name: "seeding_container_filled",
		type: "select-react",
		placeholder: i18next.t("label.select"),
		options: containerFilled,
		validate: [required],
	},
	{
		label: i18next.t("label.seed_accuracy_in_furrow_per"),
		name: "seeding_accuracy_in_furrows",
		type: "text",
		placeholder: i18next.t("label.enter_seed_accuracy_in_furrow"),
		validate: [number, max(100)],
		view: "full",
	},
	{
		label: i18next.t("label.comments"),
		name: "comments",
		type: "textarea",
		placeholder: i18next.t("label.enter_comment"),
		validate: [],
		view: "full",
	},
];

// Germination date validation
const checkGerminationDate = (c, minAct, maxAct) => {
	const cWithoutSeconds = (c && moment(c).seconds(0)) || null;
	if (c && cWithoutSeconds && minAct && minAct > cWithoutSeconds) {
		return "Date invalid";
	}
	// Should be less than Germination end date
	if (c && cWithoutSeconds && maxAct && cWithoutSeconds > maxAct) {
		return "Date invalid";
	}
	// If no germination end date logged, should be less than current time and date
	// else if (c && c > moment()) {
	// 	return "Date invalid";
	// }
	return undefined;
};

export const GerminationTaskFormDetails = (minExp, minAct, maxAct) => [
	{
		label: i18next.t("label.expected_germination_date_required"),
		name: "expected_germination_date",
		type: "date-picker",
		placeholder: i18next.t("label.enter_germination_date"),
		validate: [requiredDateObj, isDateObj],
		view: "full",
		minDate: minExp,
	},
	{
		label: i18next.t("label.actual_germination_satrt_date_required"),
		name: "germination_start_date",
		type: "date-time-picker",
		placeholder: i18next.t("label.enter_actual_germination_start_date"),
		validate: [requiredDateObj, isDateObj, (e) => checkGerminationDate(e, minAct, maxAct)],
		view: "full",
		minDate: minAct,
	},
];
