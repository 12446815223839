import React from "react";
import { Modal } from "react-bootstrap";
import useModal from "../../../utils/useModal";
import ManageStatusTable from "./manageStatusTable";
import { Form } from "react-bootstrap";
import { reduxForm, change } from "redux-form";
import { connect, useDispatch } from "react-redux";

const ActivateLocationModal = () => {
	const dispatch = useDispatch();
	const [isOpen, modalType, , closeModal, modalProp] = useModal("locations");
	const closeHandler = () => {
		closeModal();
		dispatch(change("product_variety_form", "Seeds", null));
	};
	return (
		<Form className="board-details-form">
			<Modal size="lg" show={isOpen} onHide={closeHandler} className="modal-sidepanel">
				<Modal.Header closeButton>
					<Modal.Title className="modal-title">{modalType === 5 ? "Manage Product Variety Status" : ""}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{modalType === 5 ? <ManageStatusTable modalType={modalType} modalProp={modalProp} /> : null}
				</Modal.Body>
			</Modal>
		</Form>
	);
};

export default connect()(
	reduxForm({
		form: "product_variety_form",
		enableReinitialize: true,
	})(ActivateLocationModal)
);
