import React, { useEffect } from "react";
import { connect } from "react-redux";
import { find } from "lodash";
import Util from "../../../utils/Util";
import Listing from "../../../partials/listings";
import useModal from "../../../utils/useModal";
import { successMessage } from "../../../utils/successMessage";
import { errorMessage } from "../../../utils/errorMessage";
import ConfirmModal from "../../../utils/confirmModal";
import { renderColumns, renderShippingColumns } from "./helper";
import {
	getCrossSupply,
	getCrossSupplyShippingList,
	deleteCrossSupply,
	toggleCrossSupplyListLoader,
} from "../../../store/actions";
import TableLoader from "../../../utils/table/tableLoader";

const CrossSupplyListing = ({
	filter,
	setFilter,
	showCrossSupply,
	refetch,
	setRefetch,
	handleViewPanel,
	listingPageLoader,
	getCrossSupply,
	getCrossSupplyShippingList,
	deleteCrossSupply,
	permissionDetails,
}) => {
	const [isDeletModalOpen, , showDeleteModal, closeDeleteModal, deleteModalProps] = useModal("deleteCrossSupply");
	const getTodaysDate = Util.getYMDFormat(new Date());
	useEffect(() => {
		!showCrossSupply && setFilter([{ column_name: "ship_date", search: getTodaysDate }]);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [showCrossSupply]);
	const handleDeletePanel = (data) => showDeleteModal(0, data);

	const onDeleteTask = () => {
		toggleCrossSupplyListLoader(true);
		const successHandler = (e) => {
			closeDeleteModal();
			successMessage(e);
			toggleCrossSupplyListLoader(false);
			setRefetch(refetch + 1);
		};
		const errorHandler = (e) => {
			closeDeleteModal();
			errorMessage(e);
			toggleCrossSupplyListLoader(false);
		};
		deleteCrossSupply(deleteModalProps.slug, successHandler, errorHandler);
	};

	const listPermission =
		permissionDetails && permissionDetails.cross_supply && permissionDetails.cross_supply.permissions.shipment_listing
			? permissionDetails.cross_supply.permissions.shipment_listing
			: "";

	// Filter
	const addToFilter = (e) => {
		let obj = [];
		e.forEach((item) => {
			return (obj =
				item && item.column
					? [
							...obj,
							{
								column_name: item.column.accessor,
								search: item.value,
							},
					  ]
					: obj);
		});
		const locationFilter = find(filter, ["column_name", "location_slug"]);
		locationFilter ? setFilter([...obj, locationFilter]) : setFilter(obj);
	};
	const columns = renderColumns({
		handleViewPanel,
		handleDeletePanel,
		addToFilter,
	});

	const shippingColumns = renderShippingColumns(addToFilter);

	return (
		<div className="position-relative">
			{showCrossSupply ? (
				listPermission ? (
					<Listing fetchAction={getCrossSupply} columns={columns} refetch={refetch} filter={filter} />
				) : (
					""
				)
			) : listPermission ? (
				<Listing fetchAction={getCrossSupplyShippingList} columns={shippingColumns} refetch={refetch} filter={filter} />
			) : (
				""
			)}
			<ConfirmModal
				show={isDeletModalOpen}
				onNo={closeDeleteModal}
				onYes={onDeleteTask}
				message="Are you sure want to delete this transfer request?"
			/>
			{/* Loader */}
			{listingPageLoader && (
				<div className="table-loader-wrapper">
					<TableLoader />
				</div>
			)}
		</div>
	);
};

const mapStateToProps = (state) => ({
	permissionDetails: state.Auth.permissionDetails,
});
const mapDispatchToProps = {
	getCrossSupply,
	getCrossSupplyShippingList,
	deleteCrossSupply,
};

export default connect(mapStateToProps, mapDispatchToProps)(CrossSupplyListing);
