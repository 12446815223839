import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { isEmpty, reject } from "lodash";
import Util from "../../utils/Util";
import useModal from "../../utils/useModal";
import { ContentWrap, ButtonPrimary, Breadcrumbs, BackButton } from "../../elements";

import CrossSupplyListing from "./listing";
import {
	getCrossSupplyTransferDetails,
	getCrossSupplyUserLocations,
	getCrossSupplyListingLocations,
	fetchLocationSkusCS,
	toggleCrossSupplyListLoader,
	toggleCrossSupplyViewLoader,
	getCrossSupply,
} from "../../store/actions";
import "./crossSupply.scss";

import TransferRequest from "./transferRequest";
import CrossSupplyManage from "./manage";
import { errorMessage } from "../../utils/errorMessage";
import { LocationDropdown } from "components/bulkAssignee/bulkAssigneeComponents";

const CrossSupply = ({
	listingLocations,
	userLocations,
	locationSkus,
	getCrossSupplyTransferDetails,
	getCrossSupplyUserLocations,
	getCrossSupplyListingLocations,
	selectedTransferDetails,
	listingPageLoader,
	toggleCrossSupplyListLoader,
	toggleCrossSupplyViewLoader,
	permissionDetails,
	match,
	viewPageLoader,
}) => {
	const showCrossSupply = match.path === "/cross-supply" ? true : false;

	const [, , openTransferModal] = useModal("crossSupplyTransfer");

	const [listFilter, setListFilter] = useState([]);
	const [transferSlug, setTransferSlug] = useState("");
	const [filterLocation, setFilterLocation] = useState(null);
	const [isShowViewPage, setIsShowViewPage] = useState(false);
	const [refetch, setRefetch] = useState(0);

	// Formatted location array
	const locations = Util.mapOptions(listingLocations, "location_name", "location_slug") || [];

	// Common error handler
	const errorHandler = (event) => {
		errorMessage(event);
		toggleCrossSupplyListLoader(false);
		toggleCrossSupplyViewLoader(false);
	};

	useEffect(() => {
		const successHandler = () => {
			toggleCrossSupplyListLoader(false);
		};
		getCrossSupplyUserLocations(successHandler, errorHandler);
		getCrossSupplyListingLocations(successHandler, errorHandler);
		fetchLocationSkusCS(successHandler, errorHandler);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// View
	const handleViewPanel = (slug) => {
		setTransferSlug(slug);
		setIsShowViewPage(true);
	};
	// handle new transfer
	const handleNewTransfer = () => {
		openTransferModal();
	};

	const addPermission =
		permissionDetails &&
		permissionDetails.cross_supply &&
		permissionDetails.cross_supply.permissions.create_transfer_request
			? permissionDetails.cross_supply.permissions.create_transfer_request
			: "";

	const doFetchTransferDetails = (slug) => {
		toggleCrossSupplyViewLoader(true);
		const disableLoader = () => toggleCrossSupplyViewLoader(false);
		getCrossSupply(slug, disableLoader, disableLoader);
	};
	// ----------------- BULK ASSIGNEE ----------------- //
	const onSelectFilterLocation = (location = null) => {
		if (location) {
			const obj = {
				column_name: "location_slug",
				search: location.value || "",
			};
			const rejectArr = reject(listFilter, ["column_name", "location_slug"]);
			setListFilter([...rejectArr, obj]);
		} else {
			setListFilter([]);
		}

		// Set location filter
		setFilterLocation(location);
	};

	return (
		<ContentWrap>
			<div className="d-flex mb-3">
				{/* -------------- HEADER -------------- */}
				{/* For View page */}
				{isShowViewPage ? <BackButton onClick={() => setIsShowViewPage(false)} /> : null}

				<Breadcrumbs
					keyValue={showCrossSupply ? "crossSupply" : "crossSupplyShipping"}
					showLabel={showCrossSupply ? true : false}
					label={isShowViewPage ? "View" : "Transfers"}
				/>

				{/* For listing page */}
				{!isShowViewPage && (
					<div className="d-flex align-items-center cs-listing">
						{!isEmpty(locations) && (
							<LocationDropdown
								value={filterLocation}
								options={locations}
								onSelect={onSelectFilterLocation}
								placeholder={!showCrossSupply ? "Select Sender Location" : null}
							/>
						)}

						{showCrossSupply && addPermission ? (
							<ButtonPrimary className="btn-fixed-height" onClick={handleNewTransfer}>
								<i className="icon icon-plus mr-2" /> New Transfer
							</ButtonPrimary>
						) : (
							""
						)}
					</div>
				)}
			</div>

			{/* -------------- BODY -------------- */}
			{isShowViewPage ? (
				// View

				<TransferRequest transferSlug={transferSlug} viewPageLoader={viewPageLoader} />
			) : (
				// Listing
				<CrossSupplyListing
					filter={listFilter}
					setFilter={setListFilter}
					showCrossSupply={showCrossSupply}
					listingPageLoader={listingPageLoader}
					refetch={refetch}
					setRefetch={setRefetch}
					handleViewPanel={handleViewPanel}
				/>
			)}

			{/* Add Transfer Modal */}
			<CrossSupplyManage
				userLocations={userLocations}
				errorHandler={errorHandler}
				doRefetch={() => setRefetch(refetch + 1)}
				doTransferRefetch={doFetchTransferDetails}
			/>
		</ContentWrap>
	);
};

const mapStateToProps = (state) => ({
	listingPageLoader: state.CrossSupply.listingPageLoader,
	viewPageLoader: state.CrossSupply.viewPageLoader,
	listingLocations: state.CrossSupply.csListingLocations,
	userLocations: state.CrossSupply.csUserLocations,
	locationSkus: state.CrossSupply.csLocationSkus,
	selectedTransferDetails: state.CrossSupply.csTransferDetails,
	permissionDetails: state.Auth.permissionDetails,
});
const mapDispatchToProps = {
	getCrossSupplyTransferDetails,
	getCrossSupplyUserLocations,
	getCrossSupplyListingLocations,
	fetchLocationSkusCS,
	toggleCrossSupplyListLoader,
	toggleCrossSupplyViewLoader,
};

export default connect(mapStateToProps, mapDispatchToProps)(CrossSupply);
