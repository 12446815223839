import React from "react";
import { Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import Select from "react-select";
import { createStructuredSelector } from "reselect";
import { setSalesVsProductionType } from "store/actions";
import { getSalesVsProductionType, salesVsProdTypeSelect } from "store/selectors/forecastSelectors";
import { ActualSalesVsProductionDropdownType, SalesVsProductionDropdownType } from "./helper";

const SalesVsProductionType = ({ selectType, setSalesVsProductionType, actualSales }) => {
	const handleChange = (item) => {
		setSalesVsProductionType(item.value);
	};

	return (
		<Row className="justify-content-end sales-vs-prod-type-row">
			<Col className="col-md-4 col-lg-3 d-md-flex flex-row">
				<div className="d-flex justify-content-md-end align-items-center mr-3">
					<span className="form-label">View By: </span>
				</div>
				<div className="form-group">
					<Select
						placeholder={"Select Type"}
						classNamePrefix="react-select"
						onChange={handleChange}
						options={actualSales ? ActualSalesVsProductionDropdownType : SalesVsProductionDropdownType}
						className={`custom-select-box sales-vs-prod}`}
						isMulti={false}
						name="sales vs production type"
						value={selectType}
					/>
				</div>
			</Col>
		</Row>
	);
};

const mapStateToProps = (state, props) =>
	createStructuredSelector({
		type: getSalesVsProductionType,
		selectType: salesVsProdTypeSelect(
			props.actualSales ? ActualSalesVsProductionDropdownType : SalesVsProductionDropdownType
		),
	});

export default connect(mapStateToProps, { setSalesVsProductionType })(SalesVsProductionType);
