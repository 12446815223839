import { FERTILIZERS } from "../types";
import fetchHandler from "utils/fetchHandler";

export const setLoaderFertilizers = () => {
	return (dispatch) => {
		dispatch({
			type: FERTILIZERS.LOADER_FERTILIZER,
		});
	};
};

export const fetchDropdownDataFertilizers = (successHandler, errorHandler) => {
	const fetchOptions = {
		url: `get-watersource-dropdown`,
		method: "GET",
		secure: true,
		actionType: FERTILIZERS.SET_DROPDOWN_DATA_FERTILIZER,
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const setRenderTypeFertilizers = (value) => {
	return (dispatch) => {
		dispatch({
			type: FERTILIZERS.SET_RENDER_TYPE_FERTILIZER,
			payload: value,
		});
	};
};

export const locationSelectFertilizer = (value) => {
	return (dispatch) => {
		dispatch({
			type: FERTILIZERS.LOCATION_SELECT_FERTILIZER,
			payload: value,
		});
	};
};

export const addFertilizer = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `add-fertilizer`,
		method: "POST",
		secure: true,
		body: JSON.stringify(values),
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const fetchFertilizers = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `list-fertilizer`,
		method: "POST",
		secure: true,
		body: JSON.stringify(values),
		actionType: FERTILIZERS.SET_DATA_FERTILIZER,
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const editFertilizer = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `edit-fertilizer`,
		method: "PUT",
		secure: true,
		body: JSON.stringify(values),
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const updateFertilizerData = (values) => {
	return (dispatch) => {
		dispatch({
			type: FERTILIZERS.UPDATE_DATA_FERTILIZER,
			payload: values,
		});
	};
};

export const deleteFertilizer = (slug, successHandler, errorHandler) => {
	const encodedSlug = encodeURI(slug);
	const fetchOptions = {
		url: `delete-fertilizer/${encodedSlug}`,
		method: "DELETE",
		secure: true,
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const resetRefreshFertilizers = () => {
	return (dispatch) => {
		dispatch({
			type: FERTILIZERS.RESET_REFRESH_FERTILIZER,
		});
	};
};
