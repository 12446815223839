import { number } from "constant/formValidators";
import React from "react";
import { Col } from "react-bootstrap";
import { connect, useDispatch } from "react-redux";
import { Field } from "redux-form";
import { createStructuredSelector } from "reselect";
import { componentGroup } from "./componentGroup";
const { updateSyncErrors } = require("redux-form/lib/actions").default;

const validateCheck = (value, tableIndex, name, dispatch, errors) => {
	if (isNaN(Number(value))) {
		if (errors.data) {
			dispatch(
				updateSyncErrors(`fertilizerTable${tableIndex}`, {
					...errors,
					data: { ...errors.data, [name]: "Must be a number" },
				})
			);
		} else {
			dispatch(
				updateSyncErrors(`fertilizerTable${tableIndex}`, {
					...errors,
					data: { [name]: "Must be a number" },
				})
			);
		}
	} else {
		if (errors.data && errors.data[name]) {
			const tempErr = errors;
			delete tempErr.data[name];
			dispatch(updateSyncErrors(`fertilizerTable${tableIndex}`, tempErr));
		}
	}
};

const RenderActionTableColumn = ({ b_ind, a_ind, editable, c_item, slugKey, type, errors }) => {
	const dispatch = useDispatch();
	return (
		<>
			<Col key={b_ind} className="fertilizer-table-col">
				{/* {editable ? ( */}
				<Field
					id={slugKey ? `${c_item[slugKey]}${a_ind}` : `${c_item.slug}${a_ind}`}
					name={slugKey ? c_item[slugKey] : c_item.slug}
					type={editable ? "input" : "div"}
					component={componentGroup}
					validate={[number]}
					selectMenuHeight={200}
					{...(type !== "add" && {
						onChange: (event, newValue, previousValue, name) => {
							validateCheck(newValue, a_ind, slugKey ? c_item[slugKey] : c_item.slug, dispatch, errors);
							// newValue &&
							// 	validateCheck(newValue, a_ind, slugKey ? c_item[slugKey] : c_item.slug, dispatch, errors);
						},
					})}
					// onChange={(event, newValue, previousValue, name) => {
					// 	newValue && validateCheck(newValue, a_ind, slugKey ? c_item[slugKey] : c_item.slug, dispatch, errors);
					// }}
					// {...(!editable && {defaultValue: {this.props.editableOpts}} : {})}
				/>
				{/* ) : (
					<span>{render ? render(c_item) : c_item.value ? c_item.value : "-"}</span>
				)} */}
			</Col>
		</>
	);
};

const mapStateToProps = createStructuredSelector({
	// data: getForecastTableData,
});

// const areEqual = (prevProps, nextProps) => {
// 	return (
// 		prevProps.b_ind === nextProps.b_ind &&
// 		prevProps.a_ind === nextProps.a_ind &&
// 		prevProps.editable === nextProps.editable &&
// 		prevProps.renderKey === nextProps.renderKey &&
// 		prevProps.render === nextProps.render &&
// 		prevProps.c_item === nextProps.c_item &&
// 		prevProps.getClassName === nextProps.getClassName &&
// 		prevProps.c_ind === nextProps.c_ind &&
// 		prevProps.slugKey === nextProps.slugKey
// 	);

// 	//  a_ind, editable, renderKey, render, c_item, tableErrors, data, c_ind
// };

export default connect(mapStateToProps)(RenderActionTableColumn);
