import { isEmpty } from "lodash";
import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { Field, FormSection, reduxForm } from "redux-form";
import { getUnitOptions } from "./columns";

import { componentGroup } from "./componentGroup";
import RenderActionTableColumn from "./renderActionTableColumn";
const { updateSyncErrors } = require("redux-form/lib/actions").default;

const validateCheckFixed = (value, tableIndex, name, dispatch, errors) => {
	if (name === "name") {
		if (!value || value === "") {
			dispatch(
				updateSyncErrors(`fertilizerTable${tableIndex}`, {
					...errors,
					[name]: "Required",
				})
			);
		} else if (value && typeof value === "string" && value && value !== "") {
			if (errors && errors[name]) {
				const tempErr = errors;
				delete tempErr[name];
				dispatch(updateSyncErrors(`fertilizerTable${tableIndex}`, tempErr));
			}
		}
	} else if (name === "unit") {
		if (!value || value === "" || isEmpty(value)) {
			dispatch(
				updateSyncErrors(`fertilizerTable${tableIndex}`, {
					...errors,
					[name]: "Required",
				})
			);
		} else if (value && typeof value === "string" && value && value !== "") {
			if (errors && errors[name]) {
				const tempErr = errors;
				delete tempErr[name];
				dispatch(updateSyncErrors(`fertilizerTable${tableIndex}`, tempErr));
			}
		}
	} else if (name === "unit_weight") {
		if (!value || value === "" || isEmpty(value)) {
			dispatch(
				updateSyncErrors(`fertilizerTable${tableIndex}`, {
					...errors,
					[name]: "Required",
				})
			);
		} else if (value && typeof value === "string" && value && value !== "") {
			if (errors && errors[name]) {
				const tempErr = errors;
				delete tempErr[name];
				dispatch(updateSyncErrors(`fertilizerTable${tableIndex}`, tempErr));
			}
		}
	}
};

const ActionForm = ({
	a_ind,
	editable,
	columns,
	a_item,
	render,
	renderKey,
	handleSubmit,
	slugKey,
	initialValues,
	columnType,
	type,
	dispatch,
	syncErrors,
	errors,
	onSave,
}) => {
	return (
		<div>
			<Form onSubmit={handleSubmit(onSubmit)} className="fertilizerForm">
				{columnType === "fixed" ? (
					<div className="position-relative">
						<Row key={a_ind} className={"fertilizer-table-body-row position-relative"}>
							<div className="show-shadow-row" />
							<Col className="fertilizer-table-col name-col">
								<Field
									id={`name${a_ind}`}
									name={"name"}
									type={editable ? "input" : "div"}
									component={componentGroup}
									// validate={[required]}
									selectMenuHeight={200}
									onChange={(event, newValue, previousValue, name) => {
										validateCheckFixed(newValue, a_ind, "name", dispatch, errors);
									}}
									// {...(!editable && {defaultValue: {this.props.editableOpts}} : {})}
								/>
							</Col>
							<Col className="fertilizer-table-col unit-col">
								<Field
									id={`unit${a_ind}`}
									name={"unit"}
									type={editable ? "select-react" : "div"}
									component={componentGroup}
									// validate={[required]}
									selectMenuHeight={200}
									options={getUnitOptions}
									onChange={(event, newValue, previousValue, name) => {
										validateCheckFixed(newValue, a_ind, "unit", dispatch, errors);
									}}
									// {...(!editable && {defaultValue: {this.props.editableOpts}} : {})}
								/>
							</Col>
							<Col className="fertilizer-table-col unit-weight-col">
								<Field
									id={`unit_weight${a_ind}`}
									name={"unit_weight"}
									type={editable ? "input" : "div"}
									component={componentGroup}
									// validate={[required]}
									selectMenuHeight={200}
									onChange={(event, newValue, previousValue, name) => {
										validateCheckFixed(newValue, a_ind, "unit_weight", dispatch, errors);
									}}
									// {...(!editable && {defaultValue: {this.props.editableOpts}} : {})}
								/>
							</Col>
						</Row>
					</div>
				) : (
					<Row key={a_ind} className="fertilizer-table-body-row">
						{columns.map((b_item, b_ind) => {
							const c_ind = a_item.data.findIndex((key) =>
								slugKey
									? key[slugKey] === b_item.slug
									: b_item.slug
									? key.slug === b_item.slug
									: key.slug === b_item
							);

							if (c_ind !== -1) {
								const c_item = a_item.data[c_ind];

								return (
									<FormSection
										key={b_ind}
										name={`data`}
										component={RenderActionTableColumn}
										b_ind={b_ind}
										a_ind={a_ind}
										c_ind={c_ind}
										editable={editable}
										renderKey={renderKey}
										render={render}
										c_item={c_item}
										slugKey={slugKey}
										errors={errors}
										type={type}
									/>
									// <RenderActionTableColumn
									// 	key={b_ind}
									// 	b_ind={b_ind}
									// 	a_ind={a_ind}
									// 	c_ind={c_ind}
									// 	editable={editable}
									// 	renderKey={renderKey}
									// 	render={render}
									// 	c_item={c_item}
									// 	slugKey={slugKey}
									// />
								);
							} else {
								return (
									<Col key={b_ind} className="fertilizer-table-col">
										<span>-</span>
									</Col>
								);
							}
						})}
					</Row>
				)}
			</Form>
		</div>
	);
};

export function scrollToFirstError(fieldList, props) {
	const { slugKey, a_ind } = props;

	// const elem = "";

	const item = props.a_item;

	if ("name" in fieldList) {
		const elem = document.querySelector(`#name${a_ind}`);

		if (elem) {
			// setTimeout(() => {
			elem.scrollIntoView({ behavior: "smooth", block: "center" });
			// }, 800);
			return elem;
		}
	} else if ("unit" in fieldList) {
		const elem = document.querySelector(`#unit${a_ind}`);

		if (elem) {
			elem.scrollIntoView({ behavior: "smooth", block: "center" });

			return elem;
		}
	} else if ("unit_weight" in fieldList) {
		const elem = document.querySelector(`#unit_weight${a_ind}`);

		if (elem) {
			// setTimeout(() => {
			elem.scrollIntoView({ behavior: "smooth", block: "center" });
			// }, 800);
			return elem;
		}
	} else {
		// eslint-disable-next-line array-callback-return
		item.data.some((obj) => {
			if (slugKey) {
				if (obj[slugKey] in fieldList.data) {
					const elem = document.querySelector(`#${obj[slugKey]}${a_ind}`);

					if (elem) {
						// setTimeout(() => {
						elem.scrollIntoView({ behavior: "smooth", block: "center" });
						// }, 800);
						return elem;
					}
				}
			} else {
				if (obj.slug in fieldList.data) {
					const elem = document.querySelector(`#${obj.slug}${a_ind}`);

					if (elem) {
						// setTimeout(() => {
						elem.scrollIntoView({ behavior: "smooth", block: "center" });
						// }, 800);
						return elem;
					}
				}
			}
		});
	}
}

const onSubmit = (values, dispatch, props) => {
	const { slugKey, renderKey } = props;
	const editableChange = () => {
		props.cancelEdit(props.a_ind);
	};
	const dataArr = [];
	for (const key in values.data) {
		if (values.data[key] && values.data[key] !== "") {
			if (slugKey) {
				if (renderKey) {
					dataArr.push({
						[slugKey]: key,
						[renderKey]: values.data[key],
					});
				} else {
					dataArr.push({
						[slugKey]: key,
						value: values.data[key],
					});
				}
			} else {
				if (renderKey) {
					dataArr.push({
						slug: key,
						[renderKey]: values.data[key],
					});
				} else {
					dataArr.push({
						slug: key,
						value: values.data[key],
					});
				}
			}
		}
	}
	const data = { ...values, unit: values.unit.value, data: dataArr };

	props.onSave(data, props.a_item.slug, editableChange);
};

const areEqual = (prevProps, nextProps) => {
	return (
		prevProps.editable === nextProps.editable &&
		prevProps.a_item === nextProps.a_item &&
		prevProps.columns === nextProps.columns &&
		prevProps.columnType === nextProps.columnType &&
		prevProps.syncErrors === nextProps.syncErrors &&
		prevProps.errors === nextProps.errors
	);
};

const mapStateToProps = (state, props) => {
	const errors = state.form[`fertilizerTable${props.a_ind}`];
	return {
		errors: errors && errors.syncErrors ? errors.syncErrors : {},
	};
};
export default connect(mapStateToProps)(
	reduxForm({
		enableReinitialize: true,

		onSubmitFail: (errors, dispatch, submitError, props) => {
			scrollToFirstError(errors, props);
		},
		onSubmit: onSubmit,
	})(React.memo(ActionForm, areEqual))
);
