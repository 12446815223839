import React, { useEffect } from "react";
import "./App.scss";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { NotificationContainer } from "react-notifications";
import { Scrollbars } from "react-custom-scrollbars";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { get } from "lodash";

import Util from "./utils/Util";

import HeaderMenu from "./partials/header";

import Preloader from "./utils/preloader";
import { MainRouter, SessionRouter } from "./routers";
import { errorMessage } from "./utils/errorMessage";

import IdleTimerContainer from "./components/IdleTimerContainer/IdleTimerContainer";
import { toggleMainLoader, resetField, logoutApp, getProfileDetails, getPermissionDetails } from "./store/actions";

const LSVariable = process.env.REACT_APP_LS_VAR;

const App = ({
	mainPreloader,
	location,
	userDetails,
	resetField,
	getProfileDetails,
	getPermissionDetails,
	accessToken,
	history,
	permissionDetails,
}) => {
	const currentKey = location.pathname; // .split("/")[1] || "/";
	const currentPathname = location.pathname && location.pathname.split("/").length ? location.pathname.split("/")[1] : "";

	useEffect(() => {
		const getLSVariable = localStorage.getItem(LSVariable);
		const userData = getLSVariable ? JSON.parse(getLSVariable) : null;

		const successHandler = (e) => {
			resetField("Auth.userDetails", e.data);
			const successHandler = (event) => {
				resetField("Auth.permissionDetails", Util.formatPermissionDataArray(event.data));
			};
			getPermissionDetails(e.data.role_id, successHandler);
		};
		const logout = () => {
			localStorage.removeItem(LSVariable);
			history.push("/signin");
		};
		const errorHandler = () => {
			errorMessage("PROFILE_FETCH_FAILED");
			logout();
		};
		if (!userDetails && userData) {
			getProfileDetails(successHandler, errorHandler);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [getProfileDetails, history, location, resetField, accessToken, getPermissionDetails]);

	const desideRouter = () => {
		const getLSVariable = localStorage.getItem(LSVariable);
		const userData = getLSVariable ? JSON.parse(getLSVariable) : null;
		if (userData) {
			return (
				<div className="bright-farms-wrap">
					<div className="bright-farms-container">
						<Scrollbars autoHide={true}>
							<div className="d-flex flex-column mih-100">
								<HeaderMenu userDetails={userDetails} permissionData={permissionDetails} />
								<TransitionGroup className="router-transition-wrap flex-fill">
									<CSSTransition
										key={currentKey}
										timeout={{ enter: 50, exit: 900 }}
										classNames={"transition-wrap"}>
										<div className="router-transition-container">
											<MainRouter
												permissionData={permissionDetails}
												userData={{ id: 1 }}
												location={location}
											/>
										</div>
									</CSSTransition>
								</TransitionGroup>
							</div>
						</Scrollbars>
					</div>
				</div>
			);
		}
		return (
			<TransitionGroup className="router-transition-wrap bright-farms-session-wrap">
				<CSSTransition key={currentKey} timeout={{ enter: 50, exit: 900 }} classNames={"transition-wrap"}>
					<div className="router-transition-container bright-farms-session-container">
						<SessionRouter location={location} />
					</div>
				</CSSTransition>
			</TransitionGroup>
		);
	};

	return (
		<div className={`bright-farms-app page-${currentPathname}`}>
			<Preloader show={mainPreloader} />
			<NotificationContainer />
			{/* <IdleTimerContainer /> */}
			{desideRouter()}
		</div>
	);
};

const mapStateToProps = (state) => {
	const { UI, Auth } = state;
	return {
		accessToken: get(Auth, "accessToken", null),
		userDetails: get(Auth, "userDetails", null),
		permissionDetails: get(Auth, "permissionDetails", null),
		mainPreloader: get(UI, "mainPreloader", null),
	};
};

const mapDispatchToProps = {
	toggleMainLoader,
	resetField,
	logoutApp,
	getProfileDetails,
	getPermissionDetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(App));
