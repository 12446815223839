import { FERTILIZERS } from "../types";

const initialState = {
	loading: false,
	dropdownData: null,
	renderType: 0,
	location: null,
	data: null,
};

export default function (state = initialState, { type, payload }) {
	switch (type) {
		case FERTILIZERS.LOADER_FERTILIZER:
			return {
				...state,
				loading: !state.loading,
			};

		case FERTILIZERS.SET_DROPDOWN_DATA_FERTILIZER:
			return {
				...state,
				dropdownData: payload.data,
			};
		case FERTILIZERS.SET_RENDER_TYPE_FERTILIZER:
			return {
				...state,
				renderType: payload,
			};
		case FERTILIZERS.LOCATION_SELECT_FERTILIZER:
			return {
				...state,
				location: payload,
			};
		case FERTILIZERS.SET_DATA_FERTILIZER:
			const val = payload.fertiliserListing.data.map((item) => {
				const tempData = [];
				// eslint-disable-next-line array-callback-return
				payload.nutrientMaster.map((nut) => {
					const ind = item.data.findIndex((key) => nut.slug === key.nutrient_slug);
					if (ind !== -1) {
						tempData.push(item.data[ind]);
					} else {
						tempData.push({
							nutrient_slug: nut.slug,
							nutrient_value: "",
						});
					}
				});

				return {
					...item,
					data: tempData,
				};
			});
			return {
				...state,
				data: val,
			};
		case FERTILIZERS.UPDATE_DATA_FERTILIZER:
			return {
				...state,
				data: payload,
			};
		case FERTILIZERS.RESET_REFRESH_FERTILIZER:
			return {
				...initialState,
				dropdownData: state.dropdownData,
				renderType: state.renderType,
			};
		default:
			return state;
	}
}
