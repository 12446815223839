import React, { useState } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { Link } from "react-router-dom";

import { sendForgotPasswordCredentials, toggleMainLoader } from "../../store/actions";

import FormGroup from "../../utils/formGroup";
import { errorMessage } from "../../utils/errorMessage";
import { required, email } from "../../constant/formValidators";
import { successMessage } from "../../utils/successMessage";

const ForgotPassword = ({ history, handleSubmit, sendForgotPasswordCredentials, toggleMainLoader }) => {
	const [error, setError] = useState(null);

	const submitForm = (values) => {
		toggleMainLoader(true);
		const successHandler = (e) => {
			toggleMainLoader(false);
			if (e && e.code && e.code === 123) {
				successMessage(e);
				history.push("/signin");
			} else {
				errorMessage(e);
			}
		};
		const errorHandler = (event) => {
			errorMessage(event);
			toggleMainLoader(false);
		};
		sendForgotPasswordCredentials({ ...values }, successHandler, errorHandler);
	};

	return (
		<div className="sign-in-wrap">
			<div className="sign-in-container">
				<form className="forgot-password-form" onSubmit={handleSubmit(submitForm)}>
					<h4 className="mb-3">Forgot Password</h4>
					<Field
						name="email"
						label="Email"
						type="email"
						onChange={() => setError(null)}
						validate={[required, email]}
						component={FormGroup}
					/>
					<div className="d-flex flex-row-reverse align-items-center mt-4">
						<button className="btn btn-primary" type="submit">
							Submit
						</button>
						<Link className="mr-3 forgot-password" to="/signin">
							Back to Login
						</Link>
					</div>
					{error && <div className="error small text-danger text-center mt-3">{error}</div>}
				</form>
			</div>
		</div>
	);
};

const mapDispatchToProps = {
	sendForgotPasswordCredentials,
	toggleMainLoader,
};

export default connect(
	null,
	mapDispatchToProps
)(
	reduxForm({
		form: "ForgotPasswordForm",
	})(ForgotPassword)
);
