import React, { useEffect, useRef, useState } from "react";
import { Breadcrumbs, ButtonPrimary, ContentWrap } from "elements";

import Listing from "./listing";
import "./index.scss";
import TableLoader from "utils/table/tableLoader";
import { connect, useDispatch } from "react-redux";
import { createStructuredSelector } from "reselect";
import { fetchDropdownDataLR, setLoaderLR, setRenderTypeLR, uploadLR } from "store/actions";
import { errorMessage } from "utils/errorMessage";
import { getRenderType, getLoading, getListPermission, getAddPermission } from "store/selectors/labResultSelectors";
import AddCroptimals from "./add";
import { successMessage } from "utils/successMessage";
import { ProgressBar } from "react-bootstrap";
import { destroy } from "redux-form";

const LabResults = ({
	loading,
	listPermission,
	addPermission,
	fetchDropdownDataLR,
	setLoaderLR,
	setRenderTypeLR,
	renderType,
	uploadLR,
}) => {
	const dispatch = useDispatch();
	const inpRef = useRef(null);
	const [percentage, setPercentage] = useState("");
	const [uploadBtnText, setuploadBtnText] = useState("Upload Lab Results");

	useEffect(() => {
		setLoaderLR();
		const successHandler = () => {
			setLoaderLR();
		};
		const errorHandler = (e) => {
			errorMessage(e);
			setLoaderLR();
		};
		fetchDropdownDataLR(successHandler, errorHandler);
	}, [fetchDropdownDataLR, setLoaderLR]);

	const onChangeFile = async (event) => {
		inpRef.current = event.target;
		setuploadBtnText("Uploading...");

		const successHandler = (e) => {
			setuploadBtnText("Upload Lab Results");
			successMessage(e);

			inpRef.current.value = "";
		};

		const errorHandler = (e) => {
			setuploadBtnText("Upload Lab Results");

			errorMessage(e);
			inpRef.current.value = "";
		};

		const formD = new FormData();

		formD.append("lab_result_file", event.target.files[0]);

		const options = {
			onUploadProgress: (progressEvent) => {
				const { loaded, total } = progressEvent;
				const percent = Math.floor((loaded * 100) / total);
				setPercentage(percent);
			},
		};

		uploadLR(formD, successHandler, errorHandler, options);
	};

	const onClickHandler = () => {
		setRenderTypeLR(1);
		dispatch(destroy(`labResultForm`));
	};
	return (
		<ContentWrap>
			<div className="d-flex align-items-center mb-3">
			<Breadcrumbs
					className="forecast-breadcrumb"
					keyValue={renderType === 0 ? "nutrientTracking" : "nutrientTrackingLabAdd"}
					showLabel={renderType === 0 ? true : false}
					label={renderType === 0 ? "Lab Results" : ""}
				/>
				{renderType === 0 && addPermission && (
					<div className="d-md-flex position-relative">
						<label htmlFor="wsr-upload" className="wsr-file-upload">
							<i className="icon icon-upload-forecast " />
							{uploadBtnText}
						</label>
						<input
							id="wsr-upload"
							accept=".xls,.xlsx"
							//filetypes
							name="lab_result_file"
							type="file"
							onChange={onChangeFile}
						/>
						{percentage && percentage < 100 && (
							<ProgressBar className="progress-bar-wsr" animated now={percentage} />
						)}

						<ButtonPrimary onClick={onClickHandler}>
							<i className="icon icon-plus mr-2" />
							Add Lab Results
						</ButtonPrimary>
					</div>
				)}
			</div>

			<div className="position-relative">
				{renderType === 0 ? listPermission && <Listing /> : addPermission && <AddCroptimals />}
				{loading && (
					<div className="table-loader-wrapper">
						<TableLoader />
					</div>
				)}
			</div>
		</ContentWrap>
	);
};

const mapStateToProps = createStructuredSelector({
	loading: getLoading,
	renderType: getRenderType,
	listPermission: getListPermission,
	addPermission: getAddPermission,
});

export default connect(mapStateToProps, {
	fetchDropdownDataLR,
	setLoaderLR,
	setRenderTypeLR,
	uploadLR,
})(LabResults);
