import React from "react";
import { Modal } from "react-bootstrap";
import TransplantPondAddEdit from "./transplantPondAddEdit";
import TransplantEdit from "./transplantAddEdit";
import useModal from "../../../utils/useModal";
import { useTranslation } from 'react-i18next';
const { Header, Title, Body } = Modal;
const classPrefix = "bright-farms";

const TransplantManage = ({ handleEditPanel, onAddOrEdit, locations }) => {
	const { t } = useTranslation();
	const [isOpen, modalType, , closeModal] = useModal("transplant");
	return (
		<Modal size="lg" show={isOpen} onHide={closeModal} className="modal-sidepanel">
			<Header closeButton>
				<Title className="modal-title">
					{modalType === 4
						? t("label.repeat_task")
						: modalType === 3
						? t("label.add_pond_parameters")
						: modalType === 5
						? t("label.edit_pond_parameters")
						: modalType === 1
						? t("label.edit_task")
						: t("label.add_task")}
				</Title>
				{modalType === 2 && (
					<button type="button" className={`${classPrefix}-modal-close-button`} onClick={closeModal}>
						<i className="icon icon-close" />
					</button>
				)}
			</Header>
			<Body>
				{modalType === 3 || modalType === 5 ? (
					<TransplantPondAddEdit onAddOrEdit={onAddOrEdit} mode={modalType} initialValues={{ date: new Date() }} />
				) : (
					<TransplantEdit onAddOrEdit={onAddOrEdit} mode={modalType} />
				)}
			</Body>
		</Modal>
	);
};

export default TransplantManage;
