import { ListingBtn } from "elements";
import React from "react";
import { Col } from "react-bootstrap";
import { connect } from "react-redux";
import { Field } from "redux-form";
import DatePicker from "react-datepicker";
import Util from "../../utils/Util";
import Select from "react-select";
import { createStructuredSelector } from "reselect";

const componentGroup = ({ id, input, label, type, options = [], meta: { touched, error, warning } }) => {
	const handleChange = (selectedOption) => {
		input.onChange(selectedOption);
	};
	let itemValue;
	if (id === "cases") {
		itemValue = input && input.value && input.value;
	} else if (id === "sender_location") {
		itemValue = input.value && input.value && input.value.label;
	} else {
		itemValue = input && input.value && Util.getDateFormat(input.value);
	}
	switch (type) {
		case "div":
			return <div>{itemValue || itemValue === 0 ? itemValue : "-"} </div>;
		case "input":
			return (
				<div className="position-relative">
					<input
						{...input}
						id={id}
						className={`form-control action-table-input ${
							touched ? (error ? "input-error" : warning ? "warning" : "") : ""
						}`}
						placeholder={label}
						type={type}
					/>
					{touched ? (
						error ? (
							<div className="error-table-sec">
								<ListingBtn
									tooltipText={error}
									btnClass="btn btn-cell btn-light error-table-btn"
									iconClass="icon icon-info table-error-icon"
									errorBtn={true}
								/>
							</div>
						) : null
					) : null}
				</div>
			);
		case "date-picker":
			return (
				// Default value format should be "new Date(date)""
				<div className="position-relative">
					<DatePicker
						{...input}
						id={id}
						// className="form-control form-control-date"
						className={`form-control form-control-date ${
							touched ? (error ? "input-error" : warning ? "warning" : "") : ""
						}`}
						type={type}
						placeholder={label}
						selected={input.value}
						onBlur={() => input.onBlur(input.value)}
						onChange={input.onChange}
						// disabled={disable}
						// placeholderText={placeholder}
						dateFormat="MM/dd/yyyy"
						calendarClassName="bright-farms-calendar"
						autoComplete="off"
						// minDate={minDate}
						// maxDate={maxDate}
						// filterDate={filterDate}
					/>
					{touched ? (
						error ? (
							<div className="error-table-sec">
								<ListingBtn
									tooltipText={error}
									btnClass="btn btn-cell btn-light error-table-btn"
									iconClass="icon icon-info table-error-icon"
									errorBtn={true}
								/>
							</div>
						) : null
					) : null}
				</div>
			);
		case "select-react":
			return (
				<Select
					// placeholder={placeholder ? placeholder : "Select"}
					classNamePrefix="react-select"
					// className="custom-select-box"
					onChange={handleChange}
					options={options}
					// className={`custom-select-box ${className}`}
					// isMulti={multiple}
					onBlur={(event) => event.preventDefault()}
					onFocus={() => input.onFocus(input.value)}
					value={input.value}
					name={input.name}
					// isDisabled={disable}
					isOptionDisabled={(option) => option.isdisabled}
					// maxMenuHeight={selectMenuHeight}
				/>
			);
		default:
			return;
	}
};

const RenderActionTableColumn = ({
	b_ind,
	b_item,
	type,
	a_ind,
	editable,
	renderKey,
	render,
	c_item,
	data,
	c_ind,
	getClassName,
	slugKey,
	validation,
	dateValidation,
	name,
	option,
	editFlag,
}) => {
	return (
		<>
			<Col key={b_ind} className="action-table-col">
				{type === "select-react" ? (
					<Field
						id={name}
						options={option}
						name={name}
						type={editable && editFlag ? type : "div"}
						component={componentGroup}
						// validate={validation}
						selectMenuHeight={200}
					/>
				) : type === "date-picker" ? (
					<Field
						id={name}
						options={option}
						name={name}
						type={editable && editFlag ? type : "div"}
						component={componentGroup}
						validate={name === "delivery_date" ? null : dateValidation}
						selectMenuHeight={200}
					/>
				) : (
					<Field
						id={name}
						options={option}
						name={name}
						type={editable && editFlag ? type : "div"}
						component={componentGroup}
						validate={validation}
						selectMenuHeight={200}
					/>
				)}
			</Col>
		</>
	);
};

const mapStateToProps = createStructuredSelector({
	// data: getForecastTableData,
});

const areEqual = (prevProps, nextProps) => {
	return (
		prevProps.b_ind === nextProps.b_ind &&
		prevProps.a_ind === nextProps.a_ind &&
		prevProps.editable === nextProps.editable &&
		prevProps.renderKey === nextProps.renderKey &&
		prevProps.render === nextProps.render &&
		prevProps.c_item === nextProps.c_item &&
		prevProps.getClassName === nextProps.getClassName &&
		prevProps.c_ind === nextProps.c_ind &&
		prevProps.slugKey === nextProps.slugKey
	);

	//  a_ind, editable, renderKey, render, c_item, tableErrors, data, c_ind
};

export default connect(mapStateToProps)(React.memo(RenderActionTableColumn, areEqual));
