import { isEqual, values } from "lodash";
import { createSelectorCreator, defaultMemoize } from "reselect";

const getForm = (state) => state.form;

const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);

export const getForecastTableData = createDeepEqualSelector([getForm], (form) =>
	form.forecastTable && form.forecastTable.values && form.forecastTable.values.data ? form.forecastTable.values.data : null
);

// export const getForecastTableErrors = createDeepEqualSelector([getForm], (form) =>
// 	form.forecastTable && form.forecastTable.values && form.forecastTable.values.errors
// 		? form.forecastTable.values.errors
// 		: null
// );

const formatErrors = (errors) => {
	const errStrings = [];
	// eslint-disable-next-line array-callback-return
	values(errors).map((item) => {
		for (const key in item) {
			if (!errStrings.includes(item[key])) {
				errStrings.push(item[key]);
			}
		}
	});

	return errStrings.join(" , ");
};

export const getForecastTableErrors = createDeepEqualSelector([getForm], (form) =>
	form.forecastTable && form.forecastTable.submitFailed && form.forecastTable.syncErrors
		? formatErrors(form.forecastTable.syncErrors)
		: null
);

export const getForecastTableValues = createDeepEqualSelector([getForm], (form) =>
	form.forecastTable && form.forecastTable.values ? form.forecastTable.values : null
);
