import { keys, values } from "lodash";
import React, { useEffect, useState } from "react";
import TableForm from "./tableForm";

const CaseToLaneProductTable = (props) => {
	const { initialData, slugKey, renderKey } = props;
	const [initialValues, setinitialValues] = useState(null);

	useEffect(() => {
		if (initialData) {
			const dataObj = {};
			// eslint-disable-next-line array-callback-return
			values(initialData).map((item, i) => {
				dataObj[keys(initialData)[i]] = {};
				// eslint-disable-next-line array-callback-return
				item.map((item_i, j) => {
					dataObj[keys(initialData)[i]][slugKey ? item_i[slugKey] : item_i.slug] = renderKey
						? props.localeString && item_i[renderKey]
							? Number(item_i[renderKey]).toLocaleString("en-IN")
							: item_i[renderKey]
						: props.localeString && item_i.value
						? Number(item_i.value).toLocaleString("en-IN")
						: item_i.value;
				});
			});
			setinitialValues(dataObj);
		}
	}, [initialData, props.localeString, renderKey, slugKey]);

	return initialValues ? <TableForm initialValues={initialValues} {...props} /> : null;
};

export default CaseToLaneProductTable;
