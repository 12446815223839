import React from "react";
import { Row, Col } from "react-bootstrap";
import { connect, useDispatch } from "react-redux";
import { change } from "redux-form";
import { createStructuredSelector } from "reselect";
import { getShouldUpdateCases } from "store/selectors/ordersPackagingSelectors";

const CasesByCustomerTableBody = ({
	index,
	orderId,
	customerDC,
	requiredCases,
	possibleCases,
	updateCases,
	shouldUpdateCases,
}) => {
	const dispatch = useDispatch();

	return (
		<Row key={index} className="modal-table-body-row">
			<Col className={"modal-table-col cont-wrap"} style={{ flex: 0.7 }}>
				<span>{orderId}</span>
			</Col>
			<Col className={"modal-table-col cont-wrap"} style={{ flex: 1 }}>
				<span>{customerDC}</span>
			</Col>
			<Col className={"modal-table-col cont-wrap"} style={{ flex: 1 }}>
				<span>{requiredCases}</span>
			</Col>
			<Col className="modal-table-col" style={{ flex: 1 }}>
				<span>{possibleCases}</span>
			</Col>
			{shouldUpdateCases && (
				<Col className="modal-table-col" style={{ flex: 1 }}>
					<input
						className="form-control"
						value={updateCases}
						onChange={(e) =>
							dispatch(
								change(
									"casesByCustomer",
									`casesByCustomer.customer_cases[${index}].updated_cases`,
									e.target.value
								)
							)
						}
					/>
				</Col>
			)}
		</Row>
	);
};

const mapStateToProps = createStructuredSelector({
	shouldUpdateCases: getShouldUpdateCases,
});

const areEqual = (prevProps, nextProps) => {
	return (
		prevProps.customerDC === nextProps.customerDC &&
		prevProps.requiredCases === nextProps.requiredCases &&
		prevProps.possibleCases === nextProps.possibleCases &&
		prevProps.updateCases === nextProps.updateCases &&
		prevProps.shouldUpdateCases === nextProps.shouldUpdateCases
	);
};

export default connect(mapStateToProps)(React.memo(CasesByCustomerTableBody, areEqual));
