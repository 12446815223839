import fetchHandler from "../../utils/fetchHandler";
import {TRANSFER_REQUEST} from '../types';

export const getTransferRequestDetails = (body, successHandler, errorHandler) => {
	const fetchOptions = {
		url: "get-transfer-request-details",
		method: "POST",
		body: JSON.stringify(body),
		actionType: TRANSFER_REQUEST.GET_TASK_DETAILS,
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const getAuditLogOfTransferRequest = (body, successHandler, errorHandler) => {
	const fetchOptions = {
		url: "get-cross-supply-audit-log",
		method: "POST",
		body: JSON.stringify(body),
		actionType: TRANSFER_REQUEST.AUDITLOG_DETAILS,
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const updateTaskDetails = (body, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `update-comments`,
		method: "PUT",
		body: JSON.stringify(body),
		actionType: TRANSFER_REQUEST.TASK_DETAILS_EDIT,
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const getLocationSkuList = (body, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `get-loc-sku-dropdown`,
		method: "GET",
		body: JSON.stringify(body),
		actionType: TRANSFER_REQUEST.LOCATION_SKU_LIST,
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const addFulFillmentDetails = (body, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `add-fulfilment`,
		method: "POST",
		body: JSON.stringify(body),
		actionType: TRANSFER_REQUEST.ADD_FULFILLMENT_DETAILS,
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const setLoaderFulFillmentData = (body, successHandler, errorHandler) => {
	return (dispatch) => {
		dispatch({
			type: TRANSFER_REQUEST.LOADER_FULFILLMENT,
		});
	};
};
export const deleteFulFillmentData = (slug, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `delete-fulfilment/${slug}`,
		method: "DELETE",
		actionType: TRANSFER_REQUEST.DELETE_FULFILLMENT_ITEM,
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const editRequestedDetails = (body, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `update-inline-request-details`,
		method: "PUT",
		body: JSON.stringify(body),
		actionType: TRANSFER_REQUEST.EDIT_REQUESTED_DETAILS,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const editFulFillmentData = (body, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `update-fulfilment`,
		method: "PUT",
		body: JSON.stringify(body),
		actionType: TRANSFER_REQUEST.EDIT_FULFILLMENT_DATA_DETAILS,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const changeFulfillmentStatus = (body, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `change-fulfilment-status`,
		method: "POST",
		body: JSON.stringify(body),
		actionType: TRANSFER_REQUEST.CHANGE_FULFILLMENT_STATUS,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

