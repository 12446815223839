import { number } from "constant/formValidators";

export const fertilizerTableHeadings = [
	{
		name: "Fertilizer",
		slug: "fertiliser_name",
		editable: false,
	},
	{
		name: "Unit",
		slug: "unit",
		editable: false,
		custom_accessor: "display_unit",
	},
	{
		name: "Unit Weight",
		slug: "unit_weight",
		editable: false,
	},
	{
		name: "Input Quantity",
		slug: "input_quantity",
		editable: true,
		validate: [number],
	},
];

export const resultTableHeadings = [
	{
		name: "Nutrients",
		slug: "name",
		editable: false,
	},
	{
		name: "Croptimals",
		slug: "croptimal_value",
		editable: false,
	},
	{
		name: "Lab Results",
		slug: "lab_result_value",
		editable: false,
	},
	{
		name: "To Add (PPM)",
		slug: "to_add_value",
		editable: false,
	},
	{
		name: "Total",
		slug: "total",
		editable: false,
	},
];
