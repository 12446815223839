import React from "react";
import moment from "moment";
import { includes } from "lodash";
import { ButtonLink, ListingBtn } from "elements";
import Checkbox from "utils/checkbox";
import Util from "utils/Util";
import i18next from "i18next";

export const renderColumns = ({
	addToFilter,
	handleDeletePanel,
	handleEditPanel,
	handleViewPanel,
	handleRepeatPanel,
	statusList,
	isLocationSelected,
	onChangeCheckbox,
	checkedAssignees,
	permissionDetails,
}) => {
	const updatePermission =
		permissionDetails && permissionDetails.harvesting && permissionDetails.harvesting.permissions.update_harvesting_task
			? permissionDetails.harvesting.permissions.update_harvesting_task
			: "";
	const viewPermission =
		permissionDetails && permissionDetails.harvesting && permissionDetails.harvesting.permissions.view_harvesting_task
			? permissionDetails.harvesting.permissions.view_harvesting_task
			: "";
	const deletePermission =
		permissionDetails && permissionDetails.harvesting && permissionDetails.harvesting.permissions.delete_harvesting_task
			? permissionDetails.harvesting.permissions.delete_harvesting_task
			: "";
	return [
		{
			name: "",
			className: "harvest-listing-col-checkbox",
			headerClassName: "harvest-listing-col-checkbox-header",
			render: (row) => (
				<Checkbox
					checked={includes(checkedAssignees, row.task_id)}
					onChange={(e) => onChangeCheckbox(e, row.task_id)}
				/>
			),
			sortable: false,
		},
		{
			name: i18next.t("label.task_id"),
			accessor: "task_id",
			className: "harvest-listing-col-name table-col1",
			headerClassName: "harvest-listing-col-name-header table-col1",
			filter: {
				type: "search",
				onChange: addToFilter,
			},
			render: (row) => {
				return viewPermission ? (
					<ButtonLink className="p-0" onClick={() => handleViewPanel(row.task_id)}>
						{row.task_id}
					</ButtonLink>
				) : (
					row.task_id
				);
			},
		},
		{
			name: i18next.t("label.product_variety"),
			accessor: "product_variety",
			className: "harvest-listing-col-name table-col2",
			headerClassName: "harvest-listing-col-name-header table-col2 seedingCentreHeading",
			filter: {
				type: "search",
				onChange: addToFilter,
			},
			render: (row) => row.product_variety,
			sortable: false,
		},
		{
			name: i18next.t("label.exp_harvesting_date"),
			accessor: "expected_harvesting_date",
			className: "harvest-listing-col-dc table-col3",
			headerClassName: "harvest-listing-col-dc-header  table-col3",
			filter: { type: "calendar", onChange: addToFilter },
			render: (row) => moment(row.expected_harvesting_date).format("MM/DD/YYYY"),
			sortable: false,
		},
		{
			name: i18next.t("label.pond"),
			accessor: "pond",
			className: "harvest-listing-col-name table-col2",
			headerClassName: "harvest-listing-col-name-header table-col2",
			render: (row) => row.pond,
			sortable: false,
		},
		{
			name: i18next.t("label.lane"),
			accessor: "lane",
			className: "harvest-listing-col-name table-col2",
			headerClassName: "harvest-listing-col-name-header table-col2",
			render: (row) => row.lane,
			sortable: false,
		},
		{
			name: i18next.t("label.assignee"),
			accessor: "assignee",
			className: "harvest-listing-col-dc",
			headerClassName: "harvest-listing-col-dc-header",
			filter: {
				type: "search",
				onChange: addToFilter,
			},
			render: (row) => (row.assignee ? row.assignee.name : ""),
		},
		{
			name: i18next.t("label.status_col"),
			accessor: "status",
			className: "harvest-listing-col-dc",
			headerClassName: "harvest-listing-col-dc-header",
			options: statusList,
			filter: {
				type: "select",
				options: statusList,
				onChange: addToFilter,
			},
			render: (row) => row.status,
		},
		{
			name: i18next.t("label.actions"),
			className: "harvest-listing-col-actions",
			headerClassName: "harvest-listing-col-actions-header",
			hide: updatePermission || deletePermission ? false : true,
			render: (row) => {
				return (
					<>
						{updatePermission ? (
							<>
								<ListingBtn
									parentClass={
										updatePermission && deletePermission ? "justify-content-end" : "justify-content-around"
									}
									tooltipText={i18next.t("tooltip.repeat")}
									iconClass="icon icon-repeat"
									onClick={() => handleRepeatPanel(`${row.task_id.toLowerCase()}`)}
								/>
								<ListingBtn
									parentClass={
										updatePermission && deletePermission ? "justify-content-end" : "justify-content-around"
									}
									tooltipText={i18next.t("tooltip.edit")}
									iconClass="icon icon-edit"
									onClick={() => handleEditPanel(`${row.task_id.toLowerCase()}`, row.status)}
								/>
							</>
						) : (
							""
						)}
						{deletePermission ? (
							<ListingBtn
								parentClass={
									updatePermission && deletePermission ? "justify-content-end" : "justify-content-around"
								}
								tooltipText={i18next.t("tooltip.delete")}
								iconClass="icon icon-trash"
								onClick={() => handleDeletePanel(`${row.task_id.toLowerCase()}`)}
							/>
						) : (
							""
						)}
					</>
				);
			},
			sortable: false,
		},
	];
};

export const renderSetupTimeColumns = ({ addToFilter, handleDeletePanel, handleEditSetUpTimePanel }) => {
	return [
		{
			name: i18next.t("label.date"),
			accessor: "date",
			className: "harvest-listing-col-dc table-col3",
			headerClassName: "harvest-listing-col-dc-header  table-col3",
			// filter: { type: "calendar", onChange: addToFilter },
			render: (row) => moment(row.set_up_date).format("MM/DD/YYYY"),
			sortable: true,
		},
		{
			name: i18next.t("label.set_up_time"),
			accessor: "set_up_time_in_minutes",
			className: "harvest-listing-col-name table-col2",
			headerClassName: "harvest-listing-col-name-header table-col2",
			// render: (row) => row.set_up_time_in_minutes,
			sortable: false,
		},
		{
			name: i18next.t("label.location"),
			// accessor: "lane",
			className: "harvest-listing-col-name table-col2",
			headerClassName: "harvest-listing-col-name-header table-col2",
			render: (row) => (row.location && row.location.location_name) || "",
			sortable: true,
		},
		{
			name: i18next.t("label.user"),
			accessor: "user",
			className: "harvest-listing-col-dc",
			headerClassName: "harvest-listing-col-dc-header",
			render: (row) => row.user.name,
			sortable: true,
		},
		{
			name: i18next.t("label.actions"),
			className: "harvest-listing-col-actions",
			headerClassName: "harvest-listing-col-actions-header",
			// hide: updatePermission || deletePermission ? false : true,
			render: (row) => {
				return (
					<>
						<ListingBtn
							parentClass={"justify-content-end"}
							tooltipText={i18next.t("tooltip.edit")}
							iconClass="icon icon-edit"
							onClick={() =>
								handleEditSetUpTimePanel({
									set_up_date: new Date(row.set_up_date),
									location_slug: { label: row.location.location_name, value: row.location.slug },
									set_up_time_in_minutes: row.set_up_time_in_minutes,
									user: row.user.name,
								})
							}
						/>

						<ListingBtn
							parentClass={"justify-content-end"}
							tooltipText={i18next.t("tooltip.delete")}
							iconClass="icon icon-trash"
							onClick={() =>
								handleDeletePanel({
									set_up_date: Util.getYMDFormat(row.set_up_date),
									set_up_type: row.set_up_type,
									location_slug: row.location.slug,
								})
							}
						/>
					</>
				);
			},
			sortable: false,
		},
	];
};

export const renderCleaningTimeColumns = ({ addToFilter, handleDeletePanel, handleEditCleaningTimePanel }) => {
	return [
		{
			name: i18next.t("label.date"),
			accessor: "date",
			className: "harvest-listing-col-dc table-col3",
			headerClassName: "harvest-listing-col-dc-header  table-col3",
			// filter: { type: "calendar", onChange: addToFilter },
			render: (row) => moment(row.cleaning_date).format("MM/DD/YYYY"),
			sortable: true,
		},
		{
			name: i18next.t("label.cleaning_time"),
			accessor: "cleaning_time_in_minutes",
			className: "harvest-listing-col-name table-col2",
			headerClassName: "harvest-listing-col-name-header table-col2",
			// render: (row) => row.set_up_time_in_minutes,
			sortable: false,
		},
		{
			name: i18next.t("label.harvest_time"),
			accessor: "log_instance",
			className: "harvest-listing-col-name table-col2",
			headerClassName: "harvest-listing-col-name-header table-col2",
			sortable: false,
		},
		{
			name: i18next.t("label.location"),
			// accessor: "lane",
			className: "harvest-listing-col-name table-col2",
			headerClassName: "harvest-listing-col-name-header table-col2",
			render: (row) => (row.location && row.location.location_name) || "",
			sortable: true,
		},
		{
			name: i18next.t("label.user"),
			accessor: "user",
			className: "harvest-listing-col-dc",
			headerClassName: "harvest-listing-col-dc-header",
			render: (row) => row.user.name,
			sortable: true,
		},
		{
			name: i18next.t("label.actions"),
			className: "harvest-listing-col-actions",
			headerClassName: "harvest-listing-col-actions-header",
			// hide: updatePermission || deletePermission ? false : true,
			render: (row) => {
				return (
					<>
						<ListingBtn
							parentClass={"justify-content-end"}
							tooltipText={i18next.t("tooltip.edit")}
							iconClass="icon icon-edit"
							onClick={() =>
								handleEditCleaningTimePanel({
									cleaning_date: new Date(row.cleaning_date),
									location_slug: { label: row.location.location_name, value: row.location.slug },
									log_instance: { label: row.log_instance, value: row.log_instance },
									cleaning_time_in_minutes: row.cleaning_time_in_minutes,
									user: row.user.name,
								})
							}
						/>

						<ListingBtn
							parentClass={"justify-content-end"}
							tooltipText={i18next.t("tooltip.delete")}
							iconClass="icon icon-trash"
							onClick={() =>
								handleDeletePanel({
									cleaning_date: Util.getYMDFormat(row.cleaning_date),
									cleaning_type: row.cleaning_type,
									location_slug: row.location.slug,
								})
							}
						/>
					</>
				);
			},
			sortable: false,
		},
	];
};
