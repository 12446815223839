import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import useModal from "../../../../utils/useModal";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import FormGroups from "../../../../utils/formGroup";
import { Field, reduxForm, reset } from "redux-form";
import Util from "../../../../utils/Util";
import { errorMessage } from "../../../../utils/errorMessage";
import { successMessage } from "../../../../utils/successMessage";
import { addFulFillmentDetails } from "../../../../store/actions";
import TableLoader from "../../../../utils/table/tableLoader";
import { FullFillmentDetails } from "./fullFillmentDetailsFormHelper";

const { Header, Title, Body } = Modal;

const FullFillmentDetailsModal = ({
	form,
	handleSubmit,
	locationSkuList,
	addFulFillmentDetails,
	doFetchTaskDetails,
	formValues,
	initialValues,
	reset
}) => {
	const [isLoading, setIsLoading] = useState(false);
	const [isOpen, , , closeModal] = useModal("FullFillmentDetails");
	const submitDetails = (values) => {
		setIsLoading(true);
		const successHandler = (e) => {
			setIsLoading(false);
			successMessage(e.code);
			closeModal();
			reset(form);
			doFetchTaskDetails();
		};
		const errorHandler = (e) => {
			errorMessage(e);
			setIsLoading(false);
		};
		const body = {
			transfer_request_detail_slug: values.slug,
			cases: values.numberofcases,
			pack_date: Util.getYMDFormat(values.packdate),
			best_buy_date: Util.getYMDFormat(values.bestbuydate),
			ship_date: Util.getYMDFormat(values.shipdate),
			location_slug: values.sender && values.sender.value,
		};
		addFulFillmentDetails(body, successHandler, errorHandler);
	};
	const getOptions = (field) => {
		switch (field.name) {
			case "sender":
				return locationSkuList ? Util.mapOptions(locationSkuList, "location_name", "slug") : [];
			default:
				return [];
		}
	};
	const getFormFields = (fields) => {
		let formFieldHtml = [];
		formFieldHtml = fields.map((field, idx) => {
			return (
				<Col xs={field.view === "full" ? 12 : 6} key={idx}>
					<Form.Group controlId={`formControl ${idx}`}>
						{field.type === "select-react" ? (
							<Field
								name={field.name}
								type={field.type}
								label={field.label}
								placeholder={field.placeholder}
								component={FormGroups}
								validate={field.validate}
								options={getOptions(field)}
							/>
						) : (
							<Field
								name={field.name}
								type={field.type}
								label={field.label}
								placeholder={field.placeholder}
								component={FormGroups}
								validate={field.validate}
							/>
						)}
					</Form.Group>
				</Col>
			);
		});
		return <>{formFieldHtml}</>;
	};

	const editForm = () => {
		return (
			<div>
				<Row>
					<Col className="col-lg-4 col-md-6 col-sm-12 col-12 mb-3">
						<p className="d-flex align-items-center text-muted mb-1">
							<span className="light-badge-pill"></span>
							Sku
						</p>
						<h6 className="ml-4">{initialValues && initialValues.sku}</h6>
					</Col>
				</Row>
				<Row>
					<Col className="col-lg-4 col-md-6 col-sm-12 col-12 mb-3">
						<p className="d-flex align-items-center text-muted mb-1">
							<span className="light-badge-pill"></span>
							Delivery Date
						</p>
						<h6 className="ml-4">{initialValues && Util.formatMDY(initialValues.deliverydate)}</h6>
					</Col>
				</Row>
			</div>
		);
	};

	const formFiled = FullFillmentDetails(initialValues);

	const closeHandler = () => {
		closeModal();
		reset(form);
	};
	return (
		<Modal size="lg" show={isOpen} onHide={closeModal} className="modal-sidepanel">
			<Header closeButton>
				<Title className="modal-title">Transfer Details</Title>
			</Header>
			<Body>
				<Form onSubmit={handleSubmit(submitDetails)} className="board-details-form">
					<Container className="position-relative">
						{editForm()}
						<Row>{getFormFields(formFiled)}</Row>
					</Container>
					{/* Loader */}
					{isLoading && (
						<div className="table-loader-wrapper">
							<TableLoader />
						</div>
					)}
					<div className="modal-footer">
						<Button
							variant="secondary"
							onClick={() => {
								closeHandler();
							}}>
							Cancel
						</Button>
						<Button variant="primary" type="submit" disabled={isLoading}>
							{isLoading ? "Saving..." : "Save"}
						</Button>
					</div>
				</Form>
			</Body>
		</Modal>
	);
};
const mapDispatchToProps = {
	reset,
	addFulFillmentDetails,
};

export default connect(
	null,
	mapDispatchToProps
)(
	reduxForm({
		form: "fulfillment-details",
		enableReinitialize: true,
	})(FullFillmentDetailsModal)
);
