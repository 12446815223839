import React, { useEffect, useState } from "react";
import { useParams, withRouter, useLocation } from "react-router-dom";

import { errorMessage } from "utils/errorMessage";
import { connect } from "react-redux";
import { debounce, get, round, sum } from "lodash";

import "./index.scss";
import { fetchMiniProductsList, getProductVarietyDetails, getProductVarietyList } from "store/actions/productActions";
import { fetchLocationDetails, fetchHarvestForecastV2, syncLuna } from "store/actions";
import { fetchProductBasedList } from "store/actions/locationActions";
import { successMessage } from "../../utils/successMessage";
import { updateHarvestForecast } from "store/actions";

import { Col, Container, Row } from "react-bootstrap";
import moment from "moment";
import ForecastMonth from "./forecastMonth.js";
import ForecastDay from "./forecastDay.js";
import { ResponsiveContainer, XAxis, YAxis, Tooltip, Legend, LineChart, Line } from "recharts";

const ProductVarietyHarvestForecastV2 = ({
	fetchLocationDetails,
	fetchHarvestForecastV2,
	getProductVarietyDetails,
	getProductVarietyList,
	updateHarvestForecast,
	syncLuna,
	history,
}) => {
	let params = useParams();
	let location = useLocation();
	const urlParams = new URLSearchParams(location.search);

	const [greenhouse, setGreenhouse] = useState(null);
	const [variety, setVariety] = useState(null);
	const [varieties, setVarieties] = useState([]);
	const [currentForecast, setCurrentForecast] = useState(urlParams.get("forecast") ? urlParams.get("forecast") : "monthly");

	const [currentYear, setCurrentYear] = useState(urlParams.get("year") ? urlParams.get("year") : moment().year());
	const [currentMonth, setCurrentMonth] = useState(urlParams.get("month") ? moment(urlParams.get("month")) : moment());
	const [currentPond, setCurrentPond] = useState(urlParams.get("pond") != "null" ? parseInt(urlParams.get("pond")) : null);
	const [currentLane, setCurrentLane] = useState(urlParams.get("lane") != "null" ? parseInt(urlParams.get("lane")) : null);
	const [forecastData, setForecastData] = useState(null);
	const [activeTabs, setActiveTabs] = useState([]);
	const [salesForecast, setSalesForecast] = useState(null);
	const [luna, setLuna] = useState([]);
	const [chartsEnabled, setChartsEnabled] = useState(false);
	const [shouldRefresh, setShouldRefresh] = useState(true);
	const [loading, setLoading] = useState(false);

	const data = [
		{
			name: "DATE",
			y: "yield",
			uv: 4000,
			pv: 100,
			amt: 2400,
		},
		{
			name: "DATE",
			y: "yield",
			uv: 3000,
			pv: 100,
			amt: 2210,
		},
		{
			name: "DATE",
			y: "yield",
			uv: 2000,
			pv: 200,
			amt: 2290,
		},
		{
			name: "DATE",
			y: "yield",
			uv: 2780,
			pv: 200,
			amt: 2000,
		},
		{
			name: "DATE",
			y: "yield",
			uv: 1890,
			pv: 10,
			amt: 2181,
		},
		{
			name: "DATE",
			y: "yield",
			uv: 2390,
			pv: 100,
			amt: 2500,
		},
		{
			name: "DATE",
			y: "yield",
			uv: 3490,
			pv: 100,
			amt: 2100,
		},
	];

	const getCalendar = () => {
		const startWeek = moment(currentMonth).startOf("month").week();

		const endWeek = moment(currentMonth).endOf("month").week();

		var w = startWeek;
		let calendar = [];
		for (w; w <= endWeek; w++) {
			calendar.push({
				week: w,
				days: Array(7)
					.fill(0)
					.map((n, i) =>
						moment()
							.year(currentYear)
							.week(w)
							.startOf("week")
							.clone()
							.add(n + i, "day")
					),
			});
		}

		return calendar;
	};

	const errorHandler = (e) => {
		console.log(e);
		errorMessage(e);
		setLoading(false);
	};

	const buildSalesForecastForMonth = (m) => {
		if (salesForecast) {
			let r = {};
			for (const [key, value] of Object.entries(salesForecast)) {
				if (moment(key).month() === moment(`${m}-${currentYear}`, "MMMM-YYYY").month()) {
					value.forEach((v) => {
						if (v.slug == variety.slug) {
							if (!r[m]) {
								r[m] = 0;
							}
							r[m] = r[m] + v.sales;
						}
					});
				}
			}
			return r[m];
		}
	};

	const buildSalesForecastForWeek = (days) => {
		if (salesForecast) {
			let r = 0;
			for (const [key, value] of Object.entries(salesForecast)) {
				if (days.includes(moment(key).format("DD/MM/YYYY"))) {
					value.forEach((v) => {
						if (v.slug == variety.slug) {
							r = r + v.sales;
						}
					});
				}
			}
			return r;
		}
	};

	const buildForecastForMonth = (m) => {
		if (forecastData) {
			let r = {};
			forecastData
				.map((f) => {
					if (moment(f.date).month() === moment(`${m}-${currentYear}`, "MMMM-YYYY").month()) {
						r[f.forecast_unit] = { forecast_value: f.forecast_value, updated_at: f.updated_at };
						return null;
					}
					return null;
				})
				.filter((p) => p !== undefined);

			return r;
		}
	};

	const buildStatsForDay = (d) => {
		var dates = [];

		var currDate = moment(d).startOf("day");
		var lastDate = moment(currDate).subtract(9, "days").startOf("day");

		while (currDate.subtract(1, "days").diff(lastDate) >= 0) {
			// console.log(currDate.toDate());
			if (currDate.day() !== 0 && dates.length < 7) {
				dates.push(currDate.clone().toDate());
			}
		}

		let formattedDates = dates.map((f) => {
			return moment(f).format("DD/MM/YYYY");
		});
		// console.log(formattedDates);
		//calculate the totals

		let totalBoards = 0;
		let totalOunces = 0;
		let forecasts = [];

		if (forecastData) {
			let r = {};
			forecastData
				.map((f) => {
					if (formattedDates.includes(moment(f.date).format("DD/MM/YYYY"))) {
						if (f.forecast_unit === "oz_per_board" && moment(f.date).day() !== 0) {
							// console.log(f);
							forecasts.push(parseFloat(f.forecast_value));
						}
						if (f.forecast_unit === "num_of_boards" && moment(f.date).day() !== 0) {
							// console.log(f);
							forecasts.push(parseFloat(f.forecast_value));
						}

						return null;
					}
					return null;
				})
				.filter((p) => p !== undefined);
			// console.log(forecasts);
			// console.log({ rolling_7_day_oz_per_board: sum(forecasts) / forecasts.length });
			return { rolling_7_day_oz_per_board: sum(forecasts) / forecasts.length };
		}
	};

	const buildForecastForDay = (d) => {
		if (forecastData) {
			let r = {};
			forecastData
				.map((f) => {
					if (moment(f.date).format("DD/MM/YYYY") === moment(d).format("DD/MM/YYYY")) {
						r[f.forecast_unit] = { forecast_value: f.forecast_value, updated_at: f.updated_at };
						return null;
					}
					return null;
				})
				.filter((p) => p !== undefined);

			return r;
		}
	};

	const buildLunaData = (days) => {
		let formattedDates = days.map((f) => {
			return moment(f).format("DD/MM/YYYY");
		});
		let lunaHarvest = {};
		luna.map((f) => {
			if (formattedDates.includes(moment(f.luna_attributed_on).format("DD/MM/YYYY"))) {
				lunaHarvest[
					f.luna_harvest_group_harvest_queue[0].space.length > 0
						? f.luna_harvest_group_harvest_queue[0].space[0].name
						: "empty"
				] = {
					date: moment(f.luna_attributed_on).format("DD/MM/YYYY"),
					totals: f.luna_harvest_group_totals[0] ? f.luna_harvest_group_totals[0].totalWeight : 0,
				};
			}
		});
		const ponds = Object.keys(lunaHarvest);
		return (
			<div>
				Weekly Luna Harvest Pounds
				{ponds.map((group) => {
					return (
						<div key={group}>
							<b>{group}:</b> {parseFloat(lunaHarvest[group].totals).toFixed(2)} lbs
						</div>
					);
				})}
			</div>
		);
	};

	const copyMonToSat = (days) => {
		if (forecastData) {
			let formattedDates = days.map((f) => {
				return moment(f).format("DD/MM/YYYY");
			});

			let restWeekData = [];
			let mondayData = [];
			forecastData.forEach((f) => {
				if (formattedDates[1] === moment(f.date).format("DD/MM/YYYY")) {
					// console.log(formattedDates[1]);
					mondayData.push(f);
				}
			});

			formattedDates.map((f) => {
				console.log(moment(f, "DD/MM/YYYY").day());
				if (moment(f, "DD/MM/YYYY").day() !== 0) {
					mondayData.forEach((element) => {
						restWeekData.push({
							forecast_unit: element.forecast_unit,
							forecast_type: "daily",
							growing_lane_number: currentLane,
							growing_location: currentPond,
							location_id: greenhouse.slug,
							date: moment(f, "DD/MM/YYYY").toISOString(),
							product_variety_id: variety.slug,
							forecast_value: element.forecast_value,
							year: moment(f, "DD/MM/YYYY").year(),
							change_reason: null,
							change_notes: null,
						});
					});
				}
			});
			console.log(restWeekData);
			updateHarvestForecast(
				restWeekData,
				(e) => {
					successMessage(e);
					setShouldRefresh(true);
				},
				errorHandler
			);
			console.log(restWeekData);
		}
	};

	const copyWeekToWeek = (days) => {
		if (forecastData) {
			let formattedDates = days.map((f) => {
				return moment(f).format("DD/MM/YYYY");
			});
			let nextWeekData = [];
			forecastData.map((f) => {
				if (formattedDates.includes(moment(f.date).format("DD/MM/YYYY"))) {
					nextWeekData.push({
						forecast_unit: f.forecast_unit,
						forecast_type: "daily",
						growing_lane_number: currentLane,
						growing_location: currentPond,
						location_id: greenhouse.slug,
						date: moment(f.date).add(1, "weeks").toISOString(),
						product_variety_id: variety.slug,
						forecast_value: f.forecast_value,
						year: moment(f.date).add(1, "weeks").year(),
						change_reason: null,
						change_notes: null,
					});
				}
				return null;
			});
			updateHarvestForecast(
				nextWeekData,
				(e) => {
					successMessage(e);
					setShouldRefresh(true);
				},
				errorHandler
			);
			console.log(nextWeekData);
		}
	};

	const buildCalcsForWeek = (days) => {
		if (forecastData && salesForecast) {
			let avgOzPerBoard = [];
			let totalBoards = 0;
			let avgGrowCycle = [];
			let totalOz = 0;
			let formattedDates = days.map((f) => {
				return moment(f).format("DD/MM/YYYY");
			});

			forecastData.map((f) => {
				if (formattedDates.includes(moment(f.date).format("DD/MM/YYYY"))) {
					if (f.forecast_unit === "oz_per_board") {
						avgOzPerBoard.push(f.forecast_value);
						totalOz += parseFloat(f.forecast_value);
					}
					if (f.forecast_unit === "number_of_boards") {
						totalBoards += parseFloat(f.forecast_value);
					}

					if (f.forecast_unit === "grow_cycle") {
						avgGrowCycle.push(f.forecast_value);
					}
				}
				return null;
			});
			let salesOzWeek = buildSalesForecastForWeek(formattedDates);

			return (
				<Row
					style={{
						backgroundColor: "wheat",
						padding: 20,
						marginBottom: 40,
					}}>
					<Col>
						<div>
							<button
								onClick={() => {
									copyMonToSat(days);
								}}>
								Copy Mon->Sat
							</button>
						</div>
						<button
							onClick={() => {
								copyWeekToWeek(days);
							}}>
							Copy ⬆ week to ⬇ next week
						</button>
					</Col>
					{/* <Col>
						<div>
							<button
								onClick={async () => {
									await pullLunaData(
										moment(days[0]).format("YYYY-MM-DD"),
										moment(days[days.length - 1]).format("YYYY-MM-DD")
									);
								}}>
								SYNC LUNA HARVEST
							</button>
							{buildLunaData(days)}
						</div>
					</Col> */}
					<Col>
						<div style={{ flex: 1 }}>
							<div
								style={{
									justifyContent: "center",
									alignItems: "flex-end",
									fontWeight: "bold",
									color: "green",
									fontSize: 20,
									textAlign: "right",
								}}>
								Sales lbs Needed for week: {salesOzWeek / 16}
							</div>
							<div
								style={{
									justifyContent: "center",
									alignItems: "flex-end",
									fontWeight: "bold",
									color: "green",
									fontSize: 20,
									textAlign: "right",
								}}>
								Total lbs for Week: {(totalOz / 16) * totalBoards}
							</div>
							<div
								style={{
									justifyContent: "center",
									alignItems: "flex-end",
									fontWeight: "bold",
									color: "black",
									fontSize: 20,
									textAlign: "right",
								}}>
								Total Boards: {totalBoards}
							</div>
							<div
								style={{
									justifyContent: "center",
									alignItems: "flex-end",
									fontWeight: "bold",
									color: "black",
									fontSize: 20,
									textAlign: "right",
								}}>
								Avg Oz per Board: {avgOzPerBoard.reduce((p, c, _, a) => p + c / a.length, 0)}
							</div>
						</div>
					</Col>
				</Row>
			);
		}
	};

	useEffect(() => {}, [loading]);

	useEffect(() => {
		const pullForecastData = async () => {
			setLoading(true);
			fetchHarvestForecastV2(
				{
					forecast_type: currentForecast,
					current_year: currentYear,
					current_month: currentMonth,
					current_pond: currentPond,
					current_lane: currentLane,
					location_id: greenhouse.slug,
					product_variety_id: variety.slug,
				},
				(e) => {
					setForecastData(e.harvest);
					setSalesForecast(e.sales);
					setActiveTabs(e.activePonds);
					setLuna(e.luna);
					setShouldRefresh(false);
					setLoading(false);
				},
				errorHandler
			);
		};
		console.log(currentPond);
		if (currentForecast === "monthly" && greenhouse && variety && currentYear && shouldRefresh) {
			pullForecastData();
		} else if (
			currentForecast === "daily" &&
			greenhouse &&
			variety &&
			currentYear &&
			currentPond != null &&
			shouldRefresh
		) {
			pullForecastData();
		}
	}, [
		shouldRefresh,
		variety,
		greenhouse,
		currentYear,
		currentMonth,
		// currentLane,
		currentPond,
		currentForecast,
		fetchHarvestForecastV2,
	]);

	useEffect(() => {
		fetchLocationDetails(
			params.slug,
			(e) => {
				setGreenhouse(e.data);
			},
			errorHandler
		);
		getProductVarietyDetails(
			params.green_slug,
			params.slug,
			(e) => {
				var newVariety = e.data.product_variety_list.find((e) => {
					return e.slug === params.product_variety_slug;
				});

				setVariety(newVariety);
			},
			errorHandler
		);
		getProductVarietyList(
			{ filter: [{ column_name: "location_slug", search: params.slug }] },
			(e) => {
				setVarieties(e.data);
				console.log(e.data);
			},
			errorHandler
		);

		getCalendar();
	}, [fetchLocationDetails, getProductVarietyDetails, params]);

	const getMonths = () => {
		var arrays = [],
			size = 3,
			a = moment.months();

		while (a.length > 0) arrays.push(a.splice(0, size));

		return arrays;
	};

	const pondLaneDisplay = () => {
		const rows = [];
		for (let index = 0; index < greenhouse?.lanes_per_pond; index++) {
			let active = activeTabs?.find((value) => {
				return value.growing_lane_number === index && value.growing_location_id == currentPond;
			});
			let totalBoards = activeTabs?.find((value) => {
				return (
					value.growing_lane_number === index &&
					value.growing_location_id == currentPond &&
					value.forecast_unit === "number_of_boards"
				);
			});
			let avgOzForecast = activeTabs?.find((value) => {
				return (
					value.growing_lane_number === index &&
					value.growing_location_id == currentPond &&
					value.forecast_unit === "oz_per_board"
				);
			});
			rows.push(
				<Col
					onClick={() => {
						setCurrentLane(index);
						setShouldRefresh(true);
					}}
					key={`pond-lane-display-${index}`}
					style={{
						border: currentLane === index ? "2px solid #006241" : "2px solid #6D6E71",
						borderRadius: active ? 0 : 20,
						padding: 8,
						margin: 12,
						backgroundColor: currentLane === index ? "#006241" : active ? "white" : "gray",
						boxShadow: currentLane === index ? "0px 0px 8px rgba(0,0,0,.5)" : "none",
						cursor: "pointer",
						order: active ? -1 : "initial",
					}}>
					<div>
						<div
							style={{
								textAlign: "center",
								fontSize: 18,
								fontWeight: "bold",
								letterSpacing: 1,
								color: currentLane === index ? "#fff" : active ? "#006241" : "darkgray",
							}}>
							Lane {index + 1}
							<span style={{ fontSize: 10, display: "block" }}>
								Forecast: {avgOzForecast && `${round((avgOzForecast?.avg * totalBoards?.sum) / 16)} LBS`}
							</span>
						</div>
					</div>
				</Col>
			);
		}
		return rows;
	};

	const pullLunaData = async (startDate, endDate) => {
		syncLuna(
			{
				startDate: startDate,
				endDate: endDate,
				facilityId: "1794",
			},
			(e) => {
				successMessage(e);
				setShouldRefresh(true);
				console.log(e);
			},
			errorHandler
		);
	};

	return (
		<section className="content-wrap" style={{ padding: 0, borderRadius: 0 }}>
			<div className="content-container" style={{ borderRadius: 0 }}>
				{greenhouse && (
					<div className="d-flex">
						<div style={{ flex: 1 }}>
							<div style={{ fontSize: 18, fontWeight: "regular" }}>{greenhouse.location_name}</div>
							<select
								onChange={(e) => {
									history.push({
										pathname: e.target.value,
										search: `?year=${currentYear}&forecast=${currentForecast}&lane=${currentLane}&pond=${currentPond}&month=${currentMonth}`,
									});
								}}
								style={{ fontSize: 32, fontWeight: "bold" }}>
								{varieties?.map((vr) => {
									return vr?.product_varieties?.map((v) => {
										return (
											<option
												key={v.slug}
												style={{ fontSize: 32, fontWeight: "bold" }}
												selected={variety?.slug === v.slug}
												value={`/gh/${greenhouse.slug}/${vr.slug}/${v.slug}`}>
												{v.name}
											</option>
										);
									});
								})}
							</select>
						</div>

						<div className="d-flex" style={{ flex: 1, paddingTop: 12, flexDirection: "row" }}>
							<div
								style={{
									width: 250,
									// height: 100,
									border: currentForecast === "monthly" ? "3px solid #006241" : "3px solid #6D6E71",
									borderRadius: 20,
									padding: 24,
									backgroundColor: currentForecast === "monthly" ? "#006241" : "white",
									marginRight: 24,
									boxShadow: currentForecast === "monthly" ? "0px 0px 8px rgba(0,0,0,.5)" : "none",
									cursor: "pointer",
								}}
								onClick={() => {
									setCurrentForecast("monthly");
									setShouldRefresh(true);
									console.log(history);
									history.replace("?currentForecast=monthly");
								}}>
								<div
									style={{
										fontSize: 12,
										fontWeight: "bold",
										letterSpacing: 1,
										color: currentForecast === "monthly" ? "#fff" : "#006241",
									}}>
									MONTHLY
								</div>
								<div
									style={{
										color: currentForecast === "monthly" ? "#fff" : "#000",
										fontSize: 20,
										fontWeight: "bold",
									}}>
									Harvest Forecast
								</div>
								<div
									style={{
										color: currentForecast === "monthly" ? "#fff" : "#000",
										fontSize: 12,
										paddingTop: 10,
									}}>
									Used for budget and future planning.
								</div>
							</div>
							<div
								style={{
									width: 250,
									// height: 100,
									border: currentForecast === "daily" ? "3px solid #006241" : "3px solid #6D6E71",
									borderRadius: 20,
									padding: 24,
									backgroundColor: currentForecast === "daily" ? "#006241" : "white",
									boxShadow: currentForecast === "daily" ? "0px 0px 8px rgba(0,0,0,.5)" : "none",
									cursor: "pointer",
								}}
								onClick={() => {
									setCurrentForecast("daily");
									setShouldRefresh(true);
									history.replace("?currentForecast=daily");
								}}>
								<div
									style={{
										fontSize: 12,
										fontWeight: "bold",
										letterSpacing: 1,
										color: currentForecast === "daily" ? "#fff" : "#006241",
									}}>
									DAILY
								</div>
								<div
									style={{
										fontSize: 20,
										fontWeight: "bold",
										color: currentForecast === "daily" ? "#fff" : "#000",
									}}>
									Harvest Forecast
								</div>
								<div
									style={{
										fontSize: 12,
										paddingTop: 10,
										color: currentForecast === "daily" ? "#fff" : "#000",
									}}>
									Used for production daily planning.
								</div>
							</div>
						</div>
					</div>
				)}
				<div style={{ paddingTop: 48 }}>
					{currentForecast === "monthly" && (
						<Container
							style={{ border: "1px solid #fff", borderRadius: 20, boxShadow: "0px 0px 8px rgba(0,0,0,.2)" }}>
							<Row>
								<div
									className="d-flex"
									style={{
										padding: 12,
										fwidth: "100%",

										borderBottom: "none",
										textAlign: "center",
										flex: 1,
										flexDirection: "row",
										fontSize: 24,
									}}>
									<div style={{ width: "25%", fontSize: 32, fontWeight: "bold" }}>
										<div
											style={{
												border: "2px solid #006241",
												paddingLeft: 24,
												paddingRight: 24,
												borderRadius: 24,
												color: "#006241",
												cursor: "pointer",
											}}
											onClick={() => {
												setCurrentYear(moment(currentYear, "YYYY").subtract(1, "year").year());
												setShouldRefresh(true);
											}}>
											{"<"}
										</div>
									</div>
									<div style={{ width: "50%" }}>
										<div style={{ fontSize: 32, fontWeight: "bold" }}>{currentYear}</div>
									</div>
									<div style={{ width: "25%", fontSize: 32, fontWeight: "bold" }}>
										<div
											style={{
												border: "2px solid #006241",
												paddingLeft: 24,
												paddingRight: 24,
												borderRadius: 24,
												color: "#006241",
												cursor: "pointer",
											}}
											onClick={() => {
												setCurrentYear(moment(currentYear, "YYYY").add(1, "year").year());
												setShouldRefresh(true);
											}}>
											{">"}
										</div>
									</div>
								</div>
							</Row>

							{getMonths().map((group, index) => {
								return (
									<Row key={`group-${index}`}>
										{group.map((m, i) => {
											return (
												<Col
													key={`col-${i}`}
													style={{
														padding: 0,
														borderTop: "1px solid #00624155",
														width: "100%",
														height: "300px",
														borderRight: (i + 1) % 3 <= 0 ? "none" : "1px solid #00624155",
													}}>
													{forecastData && greenhouse && variety && (
														<ForecastMonth
															key={`${currentYear}-${m}`}
															currentLane={currentLane}
															currentPond={currentPond}
															date={m}
															year={currentYear}
															greenhouse={greenhouse}
															variety={variety}
															currentValue={buildForecastForMonth(m)}
															currentSalesValue={buildSalesForecastForMonth(m)}
														/>
													)}
												</Col>
											);
										})}
									</Row>
								);
							})}
						</Container>
					)}
					{currentForecast === "daily" && (
						<div>
							<div className="ui-cards">
								{chartsEnabled && (
									<div className="d-flex">
										<div
											style={{ width: "33%", height: "250px", margin: "0", padding: "2px" }}
											className="flex-fill">
											<ResponsiveContainer width="100%" height="100%">
												<LineChart width={500} height={300} data={data}>
													<XAxis dataKey="name" />
													<YAxis />
													<Line type="monotone" dataKey="pv" stroke="#82ca9d" />
													<Tooltip />
													<Legend />
												</LineChart>
											</ResponsiveContainer>
											Rolling 6 weeks variety yield
										</div>
										<div
											style={{ width: "33%", height: "250px", margin: "0", padding: "2px" }}
											className="flex-fill">
											<ResponsiveContainer width="100%" height="100%">
												<LineChart width={500} height={300} data={data}>
													<XAxis dataKey="name" />
													<YAxis />
													<Line type="monotone" dataKey="pv" stroke="#82ca9d" />
													<Tooltip />
													<Legend />
												</LineChart>
											</ResponsiveContainer>
											Rolling 6 weeks pond yield
										</div>
										<div
											style={{ width: "33%", height: "250px", margin: "0", padding: "2px" }}
											className="flex-fill">
											<ResponsiveContainer width="100%" height="100%">
												<LineChart width={500} height={300} data={data}>
													<XAxis dataKey="name" />
													<YAxis />
													<Line type="monotone" dataKey="pv" stroke="#82ca9d" />
													<Tooltip />
													<Legend />
												</LineChart>
											</ResponsiveContainer>
											Rolling 6 weeks lane yield
										</div>
									</div>
								)}
							</div>
							<Container
								style={{
									marginTop: 48,
									maxWidth: "100%",
									border: "1px solid #fff",
									borderRadius: 20,
									boxShadow: "0px 0px 8px rgba(0,0,0,.2)",
								}}>
								<Row>
									<h2 style={{ paddingTop: 24, paddingLeft: 24, fontWeight: "bold", fontSize: 24 }}>
										Select a Pond
									</h2>
								</Row>
								<Row>
									{greenhouse?.pond.map((p) => {
										let active = activeTabs?.find((value) => {
											return value.growing_location_id === p.id;
										});

										let totalBoards = activeTabs?.find((value) => {
											return (
												value.growing_location_id === p.id && value.forecast_unit === "number_of_boards"
											);
										});
										let avgOzForecast = activeTabs?.find((value) => {
											return value.growing_location_id === p.id && value.forecast_unit === "oz_per_board";
										});

										return (
											<Col
												onClick={() => {
													setCurrentPond(p.id);
													setShouldRefresh(true);
												}}
												key={`pond-${p.id}`}
												style={{
													width: `{100/greenhouse?.pond.length}%`,
													border: currentPond === p.id ? "2px solid #006241" : "2px solid #6D6E71",
													borderRadius: active ? 0 : 20,
													padding: 4,
													margin: 8,
													backgroundColor:
														currentPond === p.id ? "#006241" : active ? "white" : "gray",
													boxShadow: currentPond === p.id ? "0px 0px 8px rgba(0,0,0,.5)" : "none",
													cursor: "pointer",
													order: active ? -1 : "initial",
												}}>
												<div>
													<div
														style={{
															textAlign: "center",
															fontSize: 18,
															fontWeight: "bold",
															letterSpacing: 1,
															color:
																currentPond === p.id ? "#fff" : active ? "#006241" : "darkgray",
														}}>
														{p.value}
														{/* <span style={{ fontSize: 10, display: "block" }}>
															{avgOzForecast &&
																`${round((avgOzForecast?.avg * totalBoards?.sum) / 16)} LBS`}
														</span> */}
													</div>
												</div>
											</Col>
										);
									})}
								</Row>

								{/* {currentPond && (
									<>
										<h2 style={{ paddingTop: 24, paddingLeft: 24, fontWeight: "bold", fontSize: 18 }}>
											Select a Lane
										</h2>
										<Row>{pondLaneDisplay()}</Row>
									</>
								)} */}
								<Row style={{ paddingTop: 24 }}>
									<div
										className="d-flex"
										style={{
											padding: 12,
											fwidth: "100%",
											borderTop: "1px solid #00624155",
											borderBottom: "none",
											textAlign: "center",
											flex: 1,
											flexDirection: "row",
											fontSize: 24,
										}}>
										<div style={{ width: "25%", fontSize: 32, fontWeight: "bold" }}>
											<div
												style={{
													border: "2px solid #006241",
													paddingLeft: 24,
													paddingRight: 24,
													borderRadius: 24,
													color: "#006241",
													cursor: "pointer",
												}}
												onClick={() => {
													setCurrentMonth(moment(currentMonth).subtract(1, "month"));
													setCurrentYear(moment(currentMonth).subtract(1, "month").year());
													setShouldRefresh(true);
												}}>
												{"<"}
											</div>
										</div>
										<div style={{ width: "50%" }}>
											<div style={{ fontSize: 32, fontWeight: "bold" }}>
												{currentMonth.format("MMMM YYYY")}
											</div>
										</div>
										<div style={{ width: "25%", fontSize: 32, fontWeight: "bold" }}>
											<div
												style={{
													border: "2px solid #006241",
													paddingLeft: 24,
													paddingRight: 24,
													borderRadius: 24,
													color: "#006241",
													cursor: "pointer",
												}}
												onClick={() => {
													setCurrentMonth(moment(currentMonth).add(1, "month"));
													setCurrentYear(moment(currentMonth).add(1, "month").year());
													setShouldRefresh(true);
												}}>
												{">"}
											</div>
										</div>
									</div>
								</Row>

								<Row>
									<Col
										style={{
											width: "100%",
											fontSize: 12,
											fontWeight: "bold",
											letterSpacing: 1,
											color: "#fff",
											backgroundColor: "#006241",
											padding: 8,
											textAlign: "center",
											borderRight: "1px solid white",
										}}>
										Sunday
									</Col>
									<Col
										style={{
											width: "100%",
											fontSize: 12,
											fontWeight: "bold",
											letterSpacing: 1,
											color: "#fff",
											backgroundColor: "#006241",
											padding: 8,
											textAlign: "center",
											borderRight: "1px solid white",
										}}>
										Monday
									</Col>
									<Col
										style={{
											width: "100%",
											fontSize: 12,
											fontWeight: "bold",
											letterSpacing: 1,
											color: "#fff",
											backgroundColor: "#006241",
											padding: 8,
											textAlign: "center",
											borderRight: "1px solid white",
										}}>
										Tuesday
									</Col>
									<Col
										style={{
											width: "100%",
											fontSize: 12,
											fontWeight: "bold",
											letterSpacing: 1,
											color: "#fff",
											backgroundColor: "#006241",
											padding: 8,
											textAlign: "center",
											borderRight: "1px solid white",
										}}>
										Wednesday
									</Col>
									<Col
										style={{
											width: "100%",
											fontSize: 12,
											fontWeight: "bold",
											letterSpacing: 1,
											color: "#fff",
											backgroundColor: "#006241",
											padding: 8,
											textAlign: "center",
											borderRight: "1px solid white",
										}}>
										Thursday
									</Col>
									<Col
										style={{
											width: "100%",
											fontSize: 12,
											fontWeight: "bold",
											letterSpacing: 1,
											color: "#fff",
											backgroundColor: "#006241",
											padding: 8,
											textAlign: "center",
											borderRight: "1px solid white",
										}}>
										Friday
									</Col>
									<Col
										style={{
											width: "100%",
											fontSize: 12,
											fontWeight: "bold",
											letterSpacing: 1,
											color: "#fff",
											backgroundColor: "#006241",
											padding: 8,
											textAlign: "center",
										}}>
										Saturday
									</Col>
								</Row>
								{getCalendar().map((week, windex) => {
									return (
										<div key={`week-${windex}`}>
											<Row>
												{week.days.map((d, i) => (
													<Col
														key={`day-col-${i}`}
														style={{
															padding: 0,
															borderTop: "2px solid #00624155",
															width: "100%",

															borderRight: (i + 1) % 7 <= 0 ? "none" : "1px solid #00624155",
														}}>
														{greenhouse &&
															variety &&
															// currentLane !== null &&
															currentPond !== null && (
																<ForecastDay
																	currentLane={currentLane}
																	currentPond={currentPond}
																	date={d}
																	year={currentYear}
																	greenhouse={greenhouse}
																	variety={variety}
																	currentValue={buildForecastForDay(d)}
																	stats={buildStatsForDay(d)}
																	setShouldRefresh={setShouldRefresh}
																	loading={loading}
																/>
															)}
													</Col>
												))}
											</Row>
											<div style={{ flex: 1, width: "100%" }}>
												<div>{buildCalcsForWeek(week.days)}</div>
											</div>
										</div>
									);
								})}
							</Container>
						</div>
					)}
				</div>
			</div>
		</section>
	);
};

const mapStateToProps = (state) => {
	const { UI, Auth } = state;
	return {
		accessToken: get(Auth, "accessToken", null),
		mainPreloader: get(UI, "mainPreloader", null),
		permissionDetails: get(Auth, "permissionDetails", null),
	};
};

const mapDispatchToProps = {
	getProductVarietyDetails,
	fetchProductBasedList,
	fetchMiniProductsList,
	getProductVarietyList,
	fetchLocationDetails,
	fetchHarvestForecastV2,
	syncLuna,
	updateHarvestForecast,
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProductVarietyHarvestForecastV2));
