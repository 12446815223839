import React, { useState, useEffect, createRef, useRef } from "react";
import Select from "react-select";
import DatePicker from "react-datepicker";
import CreatableSelect from "react-select/creatable";
import ToggleButton from "./toggleButton";
import FileUpload from "./uploadFile";
import Tooltip from "./tooltip";
import ImageUpload from "./imageUpload";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { change } from "redux-form";
import { useDispatch } from "react-redux";
import { isNaN } from "lodash";

const selectAllOption = {
	value: "<SELECT_ALL>",
	label: "All",
};

const createOption = (label, length) => ({
	label,
	value: `${label}-${length + 1}`,
});

const FormGroup = ({
	input,
	label,
	type,
	defaultValue,
	isOptionDisabled = false,
	options = [],
	formGroupClass = "",
	showPassword = true,
	matchWith = null,
	hide = false,
	multiple = false,
	resizeImage = [150, 150],
	meta: { touched, error, warning },
	className = "",
	render = null,
	info = null,
	numberOnly = false,
	list = [],
	placeholder = null,
	disable = false,
	viewMode = false,
	formatOptionLabel,
	tooltip,
	radioLabel,
	selectedDate = "",
	imageUploadLength,
	selectMenuHeight,
	minDate,
	autoFocus,
	onKeyDown,
	startDate,
	endDate,
	pickerTypeSelector,
	maxDate,
	popperPlacement,
	filterDate,
	formName,
	selectedTime = "",
}) => {
	const [toggleShowPassword, setToggleShowPassword] = useState(false);
	const [inputType] = useState(type);
	const [matching, setMatching] = useState(false);
	const [selected, setSelected] = useState(null);
	const [expandSelect, setexpandSelect] = useState(false);
	const [inputValue, setInputValue] = useState("");
	const selectRef = createRef(null);
	const posRef = createRef(null);
	const [heightPos, setheightPos] = useState(66);
	const [date,setDate]= useState(new Date());
	const dispatch = useDispatch();
	const components = {
		DropdownIndicator: null,
		ClearIndicator: null,
	};
	const handleTagsInputChange = (inputValue) => {
		setInputValue(inputValue);
	};
	const handleTagsInputChangeNumber = (inputValue) => {
		const isNumeric = /^[+-]?\d*(?:[.,]\d*)?$/.test(inputValue);
		isNumeric && setInputValue(inputValue);
	};

	const handleTagsInputChangeNumber_2 = (inputValue) => {
		const isNumeric = !isNaN(Number(inputValue));
		isNumeric && setInputValue(inputValue);
	};

	//react- select - ALL functions
	// select-react-expand-all
	const valueRef = useRef(input.value);
	valueRef.current = input.value;

	const isSelectAllSelected = () => valueRef.current.length === options.length;

	const isOptionSelected = (option) =>
		(valueRef.current && valueRef.current.some(({ value }) => value === option.value)) || isSelectAllSelected();

	const getOptions = () => options.length > 0 && [selectAllOption, ...options];

	const getValue = () => (options.length > 0 && isSelectAllSelected() ? [selectAllOption] : input.value);

	const onChange = (newValue, actionMeta) => {
		const { action, option, removedValue } = actionMeta;

		if (action === "select-option" && option.value === selectAllOption.value) {
			handleChange(options, actionMeta);
		} else if (
			(action === "deselect-option" && option.value === selectAllOption.value) ||
			(action === "remove-value" && removedValue.value === selectAllOption.value)
		) {
			handleChange([], actionMeta);
		} else if (actionMeta.action === "deselect-option" && isSelectAllSelected()) {
			handleChange(
				options.filter(({ value }) => value !== option.value),
				actionMeta
			);
		} else {
			handleChange(newValue || [], actionMeta);
		}
	};

	const inputDateValue =
		pickerTypeSelector === 0
			? moment(`${startDate}`).format("MM/DD/YYYY") + " - " + moment(`${endDate}`).format("MM/DD/YYYY")
			: pickerTypeSelector === 1
			? moment(`${startDate}`).format("MM/DD/YYYY") + " - " + moment(`${endDate}`).endOf("month").format("MM/DD/YYYY")
			: moment(`${startDate}`).format("MM/DD/YYYY") + " - " + moment(`${endDate}`).endOf("year").format("MM/DD/YYYY");

	useEffect(() => {
		if (type === "select-react-expand" || type === "select-react-expand-all") {
			const height = selectRef.current.getBoundingClientRect().height;
			posRef.current.style.height = height;
			if (height !== heightPos) {
				setheightPos(height > 93 ? 93 : height);
			}
			// posRef.current.style.height = height;
		}
	}, [heightPos, input, posRef, selectRef, type]);

	useEffect(() => {
		if (matchWith && matchWith.formValues && matchWith.formValues[matchWith.field] && input.value) {
			const fieldValue = matchWith.formValues[matchWith.field];
			setMatching(fieldValue === input.value);
		} else {
			setMatching(false);
		}
	}, [matchWith, input]);

	useEffect(() => {
		input && (!input.value || input.value === "") && setSelected([]);
	}, [input]);

	const handleDateChange = (value) => {
		setDate(value);
		input.onChange(value);
	};

	const onSelectChange = (value) => {
		input.onChange(value);
		setSelected(value);
	};

	const onSelectKeydown = (event) => {
		const allowedKeys = ["Delete", "Backspace", "ArrowLeft", "ArrowRight", "ArrowUp", "ArrowDown"];
		if ((numberOnly && !isNaN(event.nativeEvent.key)) || allowedKeys.includes(event.nativeEvent.key)) {
			return;
		}
		event.preventDefault();
	};

	const onFileChange = (value) => {
		input.onChange(value);
	};

	const renderBadges = () =>
		list.map((item, index) => (
			<div className="badge mr-2 mb-2 badge-secondary" key={index}>
				{item.label}
			</div>
		));
	const handleChange = (selectedOption) => {
		input.onChange(selectedOption);
	};
	const orderOptions = (values) => {
		return values && values.filter((v) => v.can_delete).concat(values.filter((v) => !v.can_delete));
	};
	const handleIsValidNewOption = (inputValue) => {
		if (!input.value) {
			return 1;
		}
		// Check for the same value
		const exactValueExists = input.value.find((el) => el.value === inputValue);
		// Check if the value has a valid length.
		// Without this, it will show create option for empty values.
		const valueIsNotEmpty = inputValue.trim().length;
		// If true show create option.
		return !exactValueExists && valueIsNotEmpty;
	};

	const handleIsValidNewOptionNumber = (inputValue) => {
		if (!input.value) {
			return 1;
		}
		// Check for the same value
		const exactValueExists = input.value.find((el) => el.value === inputValue);
		// Check if the value has a valid length.
		// Without this, it will show create option for empty values.
		const valueIsNotEmpty = inputValue.trim().length;

		// check for number
		const isNumeric = /^[+-]?\d*(?:[.,]\d*)?$/.test(inputValue);
		// If true show create option.
		return !exactValueExists && valueIsNotEmpty && isNumeric;
	};
	const handleTagsKeyDown = (event) => {
		if (!inputValue) {
			return true;
		}
		/*eslint-disable */
		switch (event.key) {
			case "Enter":
				input.value && !handleIsValidNewOption(inputValue) && event.preventDefault();
				break;
			case "Tab":
				break;
			/*eslint-enable */
		}
	};
	const handleTagsKeyDownNumbers = (event) => {
		if (!inputValue) {
			return true;
		}

		/*eslint-disable */
		switch (event.key) {
			case "Enter":
				input.value && !handleIsValidNewOptionNumber(inputValue) && event.preventDefault();
				break;
			case "Tab":
				break;
			/*eslint-enable */
		}
	};
	const handleChangeTags = (selectedOption, { action, removedValue }) => {
		let newelyCreated = false;
		/*eslint-disable */
		switch (action) {
			//case "remove-value":
			// selectedOption = selectedOption && selectedOption.filter((v) => (v.can_delete ? !v.can_delete : true));
			// break;
			case "pop-value":
				//if (removedValue.can_delete ? !removedValue.can_delete : true) {
				return removedValue.can_delete ? !removedValue.can_delete : true;
				//}
				break;
			case "clear":
				selectedOption = selectedOption && selectedOption.filter((v) => (v.can_delete ? !v.can_delete : true));
				break;
			case "create-option":
				newelyCreated = true;
				break;
		}
		/*eslint-enable */

		selectedOption = selectedOption && newelyCreated ? selectedOption : orderOptions(selectedOption);
		input.onChange(selectedOption);
	};

	const handleTagsKeyDownNumbersMultiple = (event) => {
		// const { inputValue, value } = this.state;
		if (!inputValue) {
			return;
		}
		switch (event.key) {
			case "Enter":
			case "Tab":
			// case " ":
				setInputValue("");
				dispatch(change(`${formName}`, input.name, [...input.value, createOption(inputValue, input.value.length)]));
				event.preventDefault();
				break;
			default:
				return;
		}
	};

	const styles = {
		// multiValue: (base, state) => {
		// 	return !state.data.can_delete ? { ...base } : base;
		// },
		// multiValueLabel: (base, state) => {
		// 	return !state.data.can_delete ? { ...base } : base;
		// },
		multiValueRemove: (base, state) => {
			return !state.data.__isNew__ && !state.data.can_delete ? { ...base, display: "none" } : base;
		},
	};

	const expandSelectStyles = {
		container: (base) => ({
			...base,
			width: "100%",
			// position: "absolute",
			// top: 0,
		}),
		control: (base) => ({
			...base,
			maxHeight: expandSelect ? 200 : 65,
			overflowY: "auto",
		}),
		option: (styles, { data, isDisabled, isFocused, isSelected }) => {
			return {
				...styles,
				zIndex: 5,
			};
		},
	};

	const renderInput = () => {
		const labelOrRender = label ? label : render ? render() : null;
		switch (type) {
			case "h3":
				return <h3 className={className}>{labelOrRender}</h3>;
			case "div":
				return <div className={className}>{labelOrRender}</div>;
			case "badges":
				return (
					<div className={`form-group ${className}`}>
						<div className="form-value">{renderBadges()}</div>
						{label ? <span className="form-label">{label}</span> : null}
					</div>
				);
			case "hidden":
				defaultValue && input.onChange(defaultValue);
				return <input value={input.value} type="hidden" {...input} />;
			case "select":
				return (
					<Select
						{...input}
						value={selected && (typeof selected.value !== "undefined" || selected.length) ? selected : defaultValue}
						onChange={onSelectChange}
						onBlur={() => input.onBlur(input.value)}
						options={options}
						className="custom-select-box"
						isMulti={multiple}
						isOptionDisabled={isOptionDisabled}
						placeholder={placeholder ? placeholder : "Select"}
						isDisabled={disable}
						formatOptionLabel={formatOptionLabel}
					/>
				);

			case "select-react":
				return (
					<Select
						placeholder={placeholder ? placeholder : "Select"}
						classNamePrefix="react-select"
						onChange={handleChange}
						options={options}
						className={`custom-select-box ${className}`}
						isMulti={multiple}
						onBlur={(event) => event.preventDefault()}
						onFocus={() => input.onFocus(input.value)}
						value={input.value}
						defaultValue={defaultValue}
						name={input.name}
						isDisabled={disable}
						isOptionDisabled={(option) => option.isdisabled}
						maxMenuHeight={selectMenuHeight}
					/>
				);
			case "select-react-expand":
				return (
					<div className="position-relative">
						<Select
							styles={expandSelectStyles}
							classNamePrefix="react-select-expand"
							placeholder={placeholder ? placeholder : "Select"}
							onChange={handleChange}
							options={options}
							className={`custom-select-box ${className}`}
							isMulti={multiple}
							onBlur={(event) => event.preventDefault()}
							//onBlur={() => input.onBlur(input.value)}
							onFocus={() => input.onFocus(input.value)}
							value={input.value}
							name={input.name}
							isDisabled={disable}
							isOptionDisabled={(option) => option.isdisabled}
							maxMenuHeight={selectMenuHeight}
						/>
						<span className="react-select-expand-btn" onClick={() => setexpandSelect(!expandSelect)}>
							<i className={expandSelect ? "icon icon-angle-up" : "icon icon-angle-down"} />
						</span>
					</div>
				);
			case "select-react-expand-all":
				return (
					<div className="position-relative">
						<Select
							isOptionSelected={isOptionSelected}
							hideSelectedOptions={false}
							closeMenuOnSelect={false}
							styles={expandSelectStyles}
							classNamePrefix="react-select-expand"
							placeholder={placeholder ? placeholder : "Select"}
							onChange={onChange}
							options={options.length > 0 ? getOptions() : []}
							className={`custom-select-box ${className}`}
							isMulti={true}
							onBlur={(event) => event.preventDefault()}
							//onBlur={() => input.onBlur(input.value)}
							onFocus={() => input.onFocus(input.value)}
							value={getValue()}
							name={input.name}
							isDisabled={disable}
							isOptionDisabled={(option) => option.isdisabled}
							maxMenuHeight={selectMenuHeight}
						/>
						<span className="react-select-expand-btn" onClick={() => setexpandSelect(!expandSelect)}>
							<i className={expandSelect ? "icon icon-angle-up" : "icon icon-angle-down"} />
						</span>
					</div>
				);
			case "select-react-customer-sku":
				return (
					<Select
						placeholder={placeholder ? placeholder : "Select"}
						onChange={handleChange}
						options={options}
						className="custom-select-box"
						isMulti={multiple}
						//onBlur={() => input.onBlur(input.value)}
						onBlur={(event) => event.preventDefault()}
						onFocus={() => input.onFocus(input)}
						value={input.value}
						name={input.name}
						isDisabled={disable}
						isOptionDisabled={(option) => option.isdisabled}
						maxMenuHeight={selectMenuHeight}
					/>
				);
			case "selectWithAdd":
				return (
					<CreatableSelect
						{...input}
						value={selected || defaultValue}
						onChange={onSelectChange}
						onKeyDown={onSelectKeydown}
						onBlur={() => input.onBlur(input.value)}
						options={options}
						className="custom-select-box"
						isMulti={multiple}
						isOptionDisabled={isOptionDisabled}
						placeholder={placeholder ? placeholder : "Select"}
						isDisabled={disable}
					/>
				);
			case "selectWithTags":
				return (
					<CreatableSelect
						options={options}
						styles={styles}
						placeholder={placeholder ? placeholder : "Select"}
						onChange={handleChangeTags}
						components={components}
						className="custom-select-box"
						isMulti={true}
						inputValue={inputValue}
						onKeyDown={handleTagsKeyDown}
						onInputChange={handleTagsInputChange}
						value={input.value}
						name={input.name}
						isClearable={input.value && input.value.map((v) => !v.can_delete)}
						formatCreateLabel={(userInput) => `${userInput}`}
					/>
				);
			case "selectWithTagsNumbers":
				return (
					<CreatableSelect
						styles={styles}
						placeholder={placeholder ? placeholder : "Select"}
						onChange={handleChangeTags}
						components={components}
						className="custom-select-box"
						isMulti={true}
						inputValue={inputValue}
						onKeyDown={handleTagsKeyDownNumbers}
						onInputChange={handleTagsInputChangeNumber}
						value={input.value}
						name={input.name}
						isClearable={input.value && input.value.map((v) => !v.can_delete)}
						formatCreateLabel={(userInput) => `${userInput}`}
					/>
				);
			case "selectWithTagsNumbersMultiple":
				return (
					<CreatableSelect
						styles={styles}
						placeholder={placeholder ? placeholder : "Select"}
						onChange={handleChangeTags}
						components={components}
						className="custom-select-box"
						isMulti={true}
						inputValue={inputValue}
						onKeyDown={handleTagsKeyDownNumbersMultiple}
						classNamePrefix="multiple-entry-box"
						onInputChange={handleTagsInputChangeNumber_2}
						value={input.value}
						name={input.name}
						menuIsOpen={false}
						isClearable={input.value && input.value.map((v) => !v.can_delete)}
						formatCreateLabel={(userInput) => `${userInput}`}
					/>
				);
			case "boolean":
				return (
					<div className="input-container">
						<ToggleButton {...input} isDisabled={disable} tooltip={tooltip} />
					</div>
				);
			case "radio":
				return (
					<div className="radio">
						<input {...input} className="form-control" type="radio" disabled={disable} />
						<span className="radio-label">{radioLabel}</span>
					</div>
				);
			case "file":
				return (
					<div className="input-file-container">
						<FileUpload
							{...input}
							defaultValue={defaultValue}
							multiple={multiple}
							onChange={onFileChange}
							resizeImage={resizeImage}
							isDisabled={disable}
							placeholder={placeholder}
						/>
					</div>
				);
			case "image-upload":
				return (
					<div className="input-file-container">
						<ImageUpload
							{...input}
							className={className}
							defaultValue={defaultValue}
							multiple={multiple}
							onChange={onFileChange}
							resizeImage={resizeImage}
							isDisabled={disable}
							placeholder={placeholder}
							length={imageUploadLength}
						/>
					</div>
				);
			case "textarea":
				return (
					<div className="input-textarea-container">
						<textarea {...input} placeholder={placeholder} className="form-control" disabled={disable} />
					</div>
				);
			case "date-picker":
				return (
					// Default value format should be "new Date(date)""
					<DatePicker
						{...input}
						className="form-control form-control-date"
						selected={input.value}
						onBlur={() => input.onBlur(input.value)}
						onChange={input.onChange}
						disabled={disable}
						placeholderText={placeholder}
						dateFormat="MM/dd/yyyy"
						calendarClassName="bright-farms-calendar"
						autoComplete="off"
						minDate={minDate}
						maxDate={maxDate}
						popperPlacement={popperPlacement}
						filterDate={filterDate}
					/>
				);
			case "react-date-picker":
				return (
					// Default value format should be "new Date(date)""
					<DatePicker
						className="form-control form-control-date"
						selected={selectedDate}
						onChange={input.onChange}
						disabled={disable}
						placeholderText={placeholder}
						dateFormat="MM/dd/yyyy"
						calendarClassName="bright-farms-calendar"
						autoComplete="off"
						minDate={minDate}
					/>
				);
			case "date-time-picker":
				return (
					// Default value format should be "new Date(date)""
					<DatePicker
						{...input}
						className="form-control form-control-date"
						selected={input.value ? input.value : date}
						onBlur={() => input.onBlur(input.value)}
						onChange={handleDateChange}
						disabled={disable}
						placeholderText={placeholder}
						calendarClassName="bright-farms-calendar"
						autoComplete="off"
						timeInputLabel="Time:"
						dateFormat="MM/dd/yyyy h:mm aa"
						showTimeInput
						minDate={minDate}
					/>
				);
			case "time-picker":
				return (
					<DatePicker
						className="form-control form-control-date"
						calendarClassName="bright-farms-calendar"
						selected={selectedTime}
						onChange={input.onChange}
						showTimeInput
						showTimeSelectOnly
						timeCaption="Time:"
						// timeFormat="HH:mm"
						dateFormat="H:mm "
					/>
				);

			case "date-range-picker":
				return (
					// Default value format should be "new Date(date)""
					<DatePicker
						{...input}
						className="form-control form-control-date"
						selected={startDate}
						onChange={input.onChange}
						startDate={startDate}
						endDate={endDate}
						selectsRange
						placeholderText={placeholder}
						value={!endDate ? new Date() : inputDateValue}
						shouldCloseOnSelect={false}
						openToDate={startDate ? startDate : new Date()}
						showYearDropdown={pickerTypeSelector === 0 ? true : false}
						showYearPicker={pickerTypeSelector === 2 ? true : false}
						showMonthYearPicker={pickerTypeSelector === 1 ? true : false}
						maxDate={maxDate}
						minDate={minDate}
						autoComplete="off"
					/>
				);
			default:
				const field = (
					<input
						{...input}
						autoComplete="off"
						className="form-control"
						placeholder={placeholder ? placeholder : ""}
						type={type === "password" ? (toggleShowPassword ? "text" : "password") : inputType}
						disabled={disable}
						autoFocus={autoFocus}
						onKeyDown={onKeyDown}
					/>
				);
				return type === "checkbox" ? (
					<label className={`m-0 ${disable ? "disabled" : ""}`}>
						{field}
						<span className="form-checkbox" />
					</label>
				) : (
					field
				);
		}
	};

	const renderInfo = () => {
		return info ? (
			<Tooltip
				message={info}
				component={
					<span className="info-icon">
						<i className="icon icon-info" />
					</span>
				}
			/>
		) : null;
	};

	const nonFormElements = ["h1", "h2", "h3", "h4", "div", "badges", "hidden"];

	return hide ? null : nonFormElements.includes(type) ? (
		renderInput()
	) : (
		<div
			className={`form-group ${type} ${formGroupClass} ${touched ? (error ? "error" : warning ? "warning" : "") : ""} ${
				matchWith && matchWith.formValues && matchWith.formValues[matchWith.field] && input.value
					? matching
						? "matching"
						: "not-matching"
					: ""
			} ${viewMode ? "view-mode" : ""}`}>
			{type === "select-react-expand" || type === "select-react-expand-all" ? (
				<div ref={posRef} style={{ height: heightPos }} className="position-relative">
					<div ref={selectRef} className="select-abs">
						{label || info ? (
							<div className="form-label">
								{label} {renderInfo()}
							</div>
						) : null}
						{touched &&
							((error && <span className="span-error">{error}</span>) ||
								(warning && <span className="span-warning">{warning}</span>))}
						{renderInput()}

						{type === "password" && showPassword ? (
							<div className="view-password-btn" onClick={() => setToggleShowPassword(!toggleShowPassword)}>
								<i className={`icon icon-${toggleShowPassword ? "hide" : "show"}`} />
							</div>
						) : null}
					</div>
				</div>
			) : (
				<>
					{touched &&
						((error && <span className="span-error">{error}</span>) ||
							(warning && <span className="span-warning">{warning}</span>))}
					{renderInput()}
					{label || info ? (
						<span className="form-label">
							{label} {renderInfo()}
						</span>
					) : null}
					{type === "password" && showPassword ? (
						<div className="view-password-btn" onClick={() => setToggleShowPassword(!toggleShowPassword)}>
							<i className={`icon icon-${toggleShowPassword ? "hide" : "show"}`} />
						</div>
					) : null}
				</>
			)}
		</div>
	);
};

export default FormGroup;
