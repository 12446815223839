import { PRIVILEGES } from "../types";
const initialState = {
	privilegesList: [],
};

export default function (state = initialState, { type, payload }) {
	switch (type) {
		case PRIVILEGES.GET_ALL_PRIVILEGES_LIST:
			return {
				...state,
				privilegesList: payload.data || [],
			};
		default:
			return state;
	}
}
