export const COMMON = {
	MAIN_PRELOADER: "MAIN_PRELOADER",
	RESET_FIELD: "RESET_FIELD",
	LOGOUT_APP: "LOGOUT_APP",
};

export const SESSION = {
	SEND_LOGIN_CREDENTIALS: "SEND_LOGIN_CREDENTIALS",
	UPDATE_LOGIN_CREDENTIALS: "UPDATE_LOGIN_CREDENTIALS",
	SEND_FORGOT_PASSWORD_CREDENTIALS: "SEND_FORGOT_PASSWORD_CREDENTIALS",
	SEND_REGISTER_NOW_CREDENTIALS: "SEND_REGISTER_NOW_CREDENTIALS",
	SEND_RESET_PASSWORD_CREDENTIALS: "SEND_RESET_PASSWORD_CREDENTIALS",
	EIDT_USER_DETAILS: "EIDT_USER_DETAILS",
	GET_PROFILE_DETAILS: "GET_PROFILE_DETAILS",
	CHECK_TOKEN_VALIDITY: "CHECK_TOKEN_VALIDITY",
	GET_PERMISSION_DETAILS: "GET_PERMISSION_DETAILS",
};

export const USERS = {
	FETCH_USERS: "FETCH_USERS",
	FETCH_LOCATION_LIST: "FETCH_LOCATION_LIST",
	FETCH_USER_ROLES: "FETCH_USER_ROLES",
	CREATE_USER: "CREATE_USER",
	UPDATE_FIELD: "UPDATE_FIELD",
	FETCH_USER_DETAILS: "FETCH_USER_DETAILS",
	DELETE_USER: "DELETE_USER",
	CHANGE_STATUS: "CHANGE_STATUS",
	GET_ROLE: "GET_ROLE",
	UPDATE_PROFILE: "UPDATE_PROFILE",
};

export const PRIVILEGES = {
	GET_ALL_PRIVILEGES_LIST: "GET_ALL_PRIVILEGES_LIST",
	UPDATE_PRIVILEGES_LIST: "UPDATE_PRIVILEGES_LIST",
};

export const BOARDS = {
	GET_BOARDS_MINI_LIST: "GET_BOARDS_MINI_LIST",
	GET_BOARDS_LIST: "GET_BOARDS_LIST",
	CREATE_BOARD: "CREATE_BOARD",
	FETCH_BOARD_DETAILS: "FETCH_BOARD_DETAILS",
	DELETE_BOARD: "DELETE_BOARD",
};

export const MODALS = {
	SHOW_MODAL: "SHOW_MODAL",
	HIDE_MODAL: "HIDE_MODAL",
};

export const LOCATIONS = {
	FETCH_ALL_LOCATIONS: "FETCH_ALL_LOCATIONS",
	FETCH_LOCATIONS: "FETCH_LOCATIONS",
	DELETE_LOCATION: "DELETE_LOCATION",
	FETCH_LOCATION_DETAILS: "FETCH_LOCATION_DETAILS",
	CREATE_LOCATION: "CREATE_LOCATION",
	UPDATE_LOCATION: "UPDATE_LOCATION",
	CLEAR_LOCATION_DETAILS: "CLEAR_LOCATION_DETAILS",
	FETCH_LOCATION_PRODUCTS: "FETCH_LOCATION_PRODUCTS",
	FETCH_LOCATION_USERS: "FETCH_LOCATION_USERS",
	FETCH_LOCATION_SKU_PRIORITY: "FETCH_LOCATION_SKU_PRIORITY",
	UPDATE_PRIORITY: "UPDATE_PRIORITY",
	FETCH_LOCATION_CUSTOMER_PRIORITY: "FETCH_LOCATION_CUSTOMER_PRIORITY",
	FETCH_PRODUCT_BASED_LIST: "FETCH_PRODUCT_BASED_LIST",
	FETCH_SKU_BASED_LIST: "FETCH_SKU_BASED_LIST",
	FETCH_PRODUCT_CHANGE_STATUS: "FETCH_PRODUCT_CHANGE_STATUS",
	FETCH_SKU_CHANGE_STATUS: "FETCH_SKU_CHANGE_STATUS",
};

export const PRODUCT = {
	FETCH_PRODUCTS_MINI_LIST: "FETCH_PRODUCTS_MINI_LIST",
	GET_PRODUCT_VARIETIES: "GET_PRODUCT_VARIETIES",
	GET_PRODUCT_VARIETY_DETAILS: "GET_PRODUCT_VARIETY_DETAILS",
	CREATE_PRODUCT_VARIETY: "CREATE_PRODUCT_VARIETY",
	UPDATE_PRODUCT_VARIETY: "UPDATE_PRODUCT_VARIETY",
	DELETE_PRODUCT_VARIETY: "DELETE_PRODUCT_VARIETY",
	GET_SEEDS_FOR_GREENS: "GET_SEEDS_FOR_GREENS",
	CLEAR_PRODUCT_DETAILS: "CLEAR_PRODUCT_DETAILS",
	PRODUCT_FORM_SUBMIT_FAILED: "PRODUCT_FORM_SUBMIT_FAILED",
	PRODUCT_FORM_ACCESS_STATE: "PRODUCT_FORM_ACCESS_STATE",
	SHOW_PRODUCT_VIEW: "SHOW_PRODUCT_VIEW",
	HIDE_PRODUCT_VIEW: "HIDE_PRODUCT_VIEW",
	GET_LIST_LOCATION_PV: "GET_LIST_LOCATION_PV",
};

export const GREENS = {
	GET_GREENS_MINI_LIST: "GET_GREENS_MINI_LIST",
	GET_GREENS_LIST: "GET_GREENS_LIST",
	CREATE_GREEN: "CREATE_GREEN",
	UPDATE_GREEN: "UPDATE_GREEN",
	DELETE_GREEN: "DELETE_GREEN",
};

export const SEEDS = {
	SET_SEEDS_VIEW_RENDER: "SET_SEEDS_VIEW_RENDER",
	CREATE_SEED: "CREATE_SEED",
	DELETE_SEED: "DELETE_SEED",
	FETCH_SEEDS: "FETCH_SEEDS",
	FETCH_SEED_DETAILS: "FETCH_SEED_DETAILS",
	GET_SEEDS_MINI_LIST: "GET_SEEDS_MINI_LIST",
	FETCH_SEED_CHANGE_STATUS: "FETCH_SEED_CHANGE_STATUS",
};

export const SKU = {
	FETCH_ALL_SKU: "FETCH_ALL_SKU",
	FETCH_SKU: "FETCH_SKU",
	DELETE_SKU: "DELETE_SKU",
	FETCH_SKU_DETAILS: "FETCH_SKU_DETAILS",
	CREATE_SKU: "CREATE_SKU",
	UPDATE_SKU: "UPDATE_SKU",
	FETCH_SKU_PRODUCT_LIST: "FETCH_SKU_PRODUCT_LIST",
	FETCH_SKU_LOCATION_LIST: "FETCH_SKU_LOCATION_LIST",
	FETCH_SKU_CHANGE_STATUS: "FETCH_SKU_CHANGE_STATUS",
};

export const SEEDING_GERMINATION = {
	GET_TASK_DROPDOWN_DETAILS: "GET_TASK_DROPDOWN_DETAILS",
	GET_SEEDING_GERMINATION_TASKS_LIST: "GET_SEEDING_GERMINATION_TASKS_LIST",
	CREATE_SEEDING_GERMINATION_TASK: "CREATE_SEEDING_GERMINATION_TASK",
	GET_SEEDING_GERMINATION_TASK_DETAILS: "GET_SEEDING_GERMINATION_TASK_DETAILS",
	GET_GERMINATION_TASK_DETAILS: "GET_GERMINATION_TASK_DETAILS",
	GET_REPEAT_SEEDING_GERMINATION_TASK_DETAILS: "GET_REPEAT_SEEDING_GERMINATION_TASK_DETAILS",
	UPDATE_SEEDING_GERMINATION_BASIC_DETAILS: "UPDATE_SEEDING_GERMINATION_BASIC_DETAILS",
	DELETE_SEEDING_GERMINATION_TASK: "DELETE_SEEDING_GERMINATION_TASK",
	START_SEEDING_GERMINATION_TASK_SEEDING: "START_SEEDING_GERMINATION_TASK_SEEDING",
	UPDATE_SEEDING_GERMINATION_SEED_DETAILS: "UPDATE_SEEDING_GERMINATION_SEED_DETAILS",
	UPDATE_SEEDING_GERMINATION_GERMINATION_DETAILS: "UPDATE_SEEDING_GERMINATION_GERMINATION_DETAILS",
	COMPLETE_SEEDING_GERMINATION_TASK: "COMPLETE_SEEDING_GERMINATION_TASK",
	GET_SEEDING_GERMINATION_TASK_AUDIT_LOG: "GET_SEEDING_GERMINATION_TASK_AUDIT_LOG",
	ADD_SEEDING_GERMINATION_CLEANING_TIME: "ADD_SEEDING_GERMINATION_CLEANING_TIME",
	GET_SEEDING_GERMINATION_CLEANING_TIME: "GET_SEEDING_GERMINATION_CLEANING_TIME",
	GET_SEEDING_GERMINATION_CLEANING_TIME_LIST: "GET_SEEDING_GERMINATION_CLEANING_TIME_LIST",
	DELETE_SEEDING_GERMINATION_CLEANING_TIME: "DELETE_SEEDING_GERMINATION_CLEANING_TIME",
	ADD_SEEDING_GERMINATION_MEDIA_USAGE: "ADD_SEEDING_GERMINATION_MEDIA_USAGE",
	GET_SEEDING_GERMINATION_MEDIA_USAGE: "GET_SEEDING_GERMINATION_MEDIA_USAGE",
	GET_SEEDING_GERMINATION_MEDIA_USAGE_LIST: "GET_SEEDING_GERMINATION_MEDIA_USAGE_LIST",
	DELETE_SEEDING_GERMINATION_MEDIA_USAGE: "DELETE_SEEDING_GERMINATION_MEDIA_USAGE",
	ADD_SEEDING_GERMINATION_SETUP_TIME: "ADD_SEEDING_GERMINATION_SETUP_TIME",
	GET_SEEDING_GERMINATION_SETUP_TIME: "GET_SEEDING_GERMINATION_SETUP_TIME",
	GET_SEEDING_GERMINATION_SETUP_TIME_LIST: "GET_SEEDING_GERMINATION_SETUP_TIME_LIST",
	DELETE_SEEDING_GERMINATION_SET_UP_TIME: "DELETE_SEEDING_GERMINATION_SET_UP_TIME",
	CLEAR_SEEDING_GERMINATION_STATES: "CLEAR_SEEDING_GERMINATION_STATES",
	TOGGLE_LIST_LOADER: "TOGGLE_LIST_LOADER",
	GET_SEEDING_GERMINATION_PRODUCT_VARIETY_DETAILS: "GET_SEEDING_GERMINATION_PRODUCT_VARIETY_DETAILS",
	TOGGLE_VIEW_LOADER: "TOGGLE_VIEW_LOADER",
	SELECTED_LOCATION: "SELECTED_LOCATION",
};
export const CROSS_SUPPLY = {
	GET_CROSS_SUPPLY_LIST: "GET_CROSS_SUPPLY_LIST",
	CREATE_CROSS_SUPPLY_TRANSFER: "CREATE_CROSS_SUPPLY_TRANSFER",
	GET_CROSS_SUPPLY_SHIPPING_LIST: "GET_CROSS_SUPPLY_SHIPPING_LIST",
	TOGGLE_CROSS_SUPPLY_LIST_LOADER: "TOGGLE_CROSS_SUPPLY_LIST_LOADER",
	TOGGLE_CROSS_SUPPLY__VIEW_LOADER: "TOGGLE_CROSS_SUPPLY__VIEW_LOADER",
	GET_CROSS_SUPPLY_TRANSFER_DETAILS: "GET_CROSS_SUPPLY_TRANSFER_DETAILS",
	GET_CROSS_SUPPLY_LISTING_LOCATIONS: "GET_CROSS_SUPPLY_LISTING_LOCATIONS",
	GET_CROSS_SUPPLY_USER_LOCATIONS: "GET_CROSS_SUPPLY_USER_LOCATIONS",
	DELETE_CROSS_SUPPLY_TRANSFER_REQUEST: "DELETE_CROSS_SUPPLY_TRANSFER_REQUEST",
	FETCH_LOCATION_SKUS: "FETCH_LOCATION_SKUS",
};

export const TASKS = {
	BULK_ASSIGN_TASKS: "BULK_ASSIGN_TASKS",
	REPEAT_ALL_TASKS: "REPEAT_ALL_TASKS",
};

export const CUSTOMERS = {
	SET_CUSTOMER_VIEW_RENDER: "SET_CUSTOMER_VIEW_RENDER",
	CREATE_CUSTOMER: "CREATE_CUSTOMER",
	DELETE_CUSTOMER: "DELETE_CUSTOMER",
	FETCH_CUSTOMER_DETAILS: "FETCH_CUSTOMER_DETAILS",
	FETCH_CUSTOMERS: "FETCH_CUSTOMERS",
	FETCH_CUSTOMERS_LOCATION_LIST: "FETCH_CUSTOMERS_LOCATION_LIST",
	FETCH_CUSTOMERS_SKU_LIST: "FETCH_CUSTOMERS_SKU_LIST",
	FETCH_CUSTOMER_DC_PRIORITY: "FETCH_CUSTOMER_DC_PRIORITY",
};
export const TRANSPLANT = {
	FETCH_ALL_TRANSPLANT: "FETCH_ALL_TRANSPLANT",
	FETCH_TRANSPLANT: "FETCH_TRANSPLANT",
	DELETE_TRANSPLANT: "DELETE_TRANSPLANT",
	FETCH_TRANSPLANT_DETAILS: "FETCH_TRANSPLANT_DETAILS",
	CREATE_TRANSPLANT: "CREATE_TRANSPLANT",
	UPDATE_TRANSPLANT: "UPDATE_TRANSPLANT",
	GET_TRANSPLANT_LOCATION_DETAILS: "GET_TRANSPLANT_LOCATION_DETAILS",
	FETCH_TRANSPLANT_LOCATION_LIST: "FETCH_TRANSPLANT_LOCATION_LIST",
	SET_TRANSPLANT_VIEW_RENDER: "SET_TRANSPLANT_VIEW_RENDER",
	CREATE_TRANSPLANT_POND: "CREATE_TRANSPLANT_POND",
	START_TRANSPLANT_TASK: "START_TRANSPLANT_TASK",
	CREATE_TRANSPLANT_TASK_DETAILS: "CREATE_TRANSPLANT_TASK_DETAILS",
	CREATE_TRANSPLANT_ADDITIONAL_POND: "CREATE_TRANSPLANT_ADDITIONAL_POND",
	COMPLETE_TRANSPLANT_TASK: "COMPLETE_TRANSPLANT_TASK",
	GET_TRANSPLANT_TASK_AUDIT_LOG: "GET_TRANSPLANT_TASK_AUDIT_LOG",
	GET_POND_DETAILS_TODAY: "GET_POND_DETAILS_TODAY",
	SELECTED_LOCATION: "SELECTED_LOCATION",
	FETCH_TRANSPLANT_TASK_ORDER_LIST: "FETCH_TRANSPLANT_TASK_ORDER_LIST",
	FETCH_ALL_POND_PARAMETERS: "FETCH_ALL_POND_PARAMETERS",
	SET_EDIT_TRANSPLANT_POND_PARAMETERS: "SET_EDIT_TRANSPLANT_POND_PARAMETERS",
	UPDATE_POND_PARAMETERS: "UPDATE_POND_PARAMETERS",
	DELETE_POND_PARAMETERS: "DELETE_POND_PARAMETERS",
};

export const HARVEST = {
	FETCH_HARVEST_LIST: "FETCH_HARVEST_LIST",
	FETCH_HARVEST_DETAILS: "FETCH_HARVEST_DETAILS",
	FETCH_HARVEST_FORECAST: "FETCH_HARVEST_FORECAST",
	FETCH_HARVEST_PRODUCT_LIST: "FETCH_HARVEST_PRODUCT_LIST",
	FETCH_ASSIGNEE_LIST: "FETCH_ASSIGNEE_LIST",
	FETCH_TASK_USERS: "FETCH_TASK_USERS",
	CHANGE_LOCATION: "CHANGE_LOCATION",
	RESET_ADD_EDIT: "RESET_ADD_EDIT",
	CHANGE_RENDER_TYPE: "CHANGE_RENDER_TYPE",
	UPDATE_HARVESTED_DETAILS: "UPDATE_HARVESTED_DETAILS",
	UPDATE_HARVESTED_BOARD_DETAILS: "UPDATE_HARVESTED_BOARD_DETAILS",
	UPDATE_STATUS: "UPDATE_STATUS",
	SET_STATUS_LIST: "SET_STATUS_LIST",
	GET_CLEANING_TIME: "GET_CLEANING_TIME",
	GET_SET_UP_TIME: "GET_SET_UP_TIME",
	GET_HARVEST_AUDIT_LOG: "GET_HARVEST_AUDIT_LOG",
	FETCH_HARVEST_TASK_ORDER_LIST: "FETCH_HARVEST_TASK_ORDER_LIST",
	RESET_HARVEST_LOG_TIME: "RESET_HARVEST_LOG_TIME",
};

export const ORDERS_PACKAGING = {
	CHANGE_RENDER_TYPE: "CHANGE_RENDER_TYPE",
	TOGGLE_OP_VIEW_LOADER: "TOGGLE_OP_VIEW_LOADER",
	GET_PACKAGE_DETAILS: "GET_PACKAGE_DETAILS",
	GET_CLEANING_TIME: "GET_CLEANING_TIME",
	GET_SET_UP_TIME: "GET_SET_UP_TIME",
	SELECT_LOCATION: "SELECT_LOCATION",
	LOAD_LOCATION_SKUS: "LOAD_LOCATION_SKUS",
	LOCATION_CUSTOMERS: "LOCATION_CUSTOMERS",
	SELECTED_LOCATION_CUSTOMERS: "SELECTED_LOCATION_CUSTOMERS",
	RESET_LISTING: "RESET_LISTING",
	SELECTED_LOCATION_CUSTOMERS_DC: "SELECTED_LOCATION_CUSTOMERS_DC",
	SELECTED_ORDER: "SELECTED_ORDER",
	FETCH_LOCATION_PRODUCT_VARITIES: "FETCH_LOCATION_PRODUCT_VARITIES",
	FETCH_INVENTORY_DETAILS: "FETCH_INVENTORY_DETAILS",
	EMPLOYEE_COUNT: "EMPLOYEE_COUNT",
	PACKROOM_PRECOOL: "PACKROOM_PRECOOL",
	PACKAGE_WASTE: "PACKAGE_WASTE",
	CASES_PER_SKU: "CASES_PER_SKU",
	GET_ORDERS_PACKAGING_TASK_AUDIT_LOG: "GET_ORDERS_PACKAGING_TASK_AUDIT_LOG",
	CASES_BY_CUSTOMER: "CASES_BY_CUSTOMER",
	LOADING: "LOADING",
	SHOULD_UPDATE_CASES: "SHOULD_UPDATE_CASES",
	UPDATE_CASES_BY_CUSTOMER: "UPDATE_CASES_BY_CUSTOMER",
	SET_INVENTORY_NULL: "SET_INVENTORY_NULL",
	LOCATION_SELECT: "LOCATION_SELECT",
	PACKAGING_ORDER_EXP_DATE: "PACKAGING_ORDER_EXP_DATE",
	PACKAGING_ORDER_LOCATION: "PACKAGING_ORDER_LOCATION",
	PACKAGING_ORDER_DATA: "PACKAGING_ORDER_DATA",
	CURRENT_ORDER_PACKAGE_SLUG: "CURRENT_ORDER_PACKAGE_SLUG",
	FETCH_LOCATION_PRODUCTS_OP: "FETCH_LOCATION_PRODUCTS_OP",
	ADD_STATUS:"ADD_STATUS",
	GET_INVENTORY_DETAILS:"GET_INVENTORY_DETAILS",
	GET_ORDERS_PACKAGING_TASKS_LIST:"GET_ORDERS_PACKAGING_TASKS_LIST"
};
export const REPORTS = {
	FETCH_GRAPH_DETAILS: "FETCH_GRAPH_DETAILS",
	FETCH_LOCATION_SKUS: "FETCH_LOCATION_SKUS",
	LOCATION_CUSTOMERS: "LOCATION_CUSTOMERS",
	FETCH_LOCATION_PRODUCT_VARITIES_REPORT: "FETCH_LOCATION_PRODUCT_VARITIES_REPORT",
	EXPORT_TABLE: "EXPORT_TABLE",
};
export const CLIMATE = {
	FETCH_CLIMATE_GRAPH_DROPDOWN_DETAILS: "FETCH_CLIMATE_GRAPH_DROPDOWN_DETAILS",
	FETCH_CLIMATE_GRAPH_DETAILS: "FETCH_CLIMATE_GRAPH_DETAILS",
	FETCH_CLIMATE_TABLE_DETAILS: "FETCH_CLIMATE_TABLE_DETAILS",
	FETCH_CLIMATE_PARAMETERS: "FETCH_CLIMATE_PARAMETERS",
	FETCH_POND_PARAMETERS: "FETCH_POND_PARAMETERS",
};
export const COUNT = {
	FETCH_COUNT_DETAILS: "FETCH_COUNT_DETAILS",
};
export const SEED_HARVEST_GRAPH = {
	FETCH_SEED_HARVEST_GRAPH_DETAILS: "FETCH_SEED_HARVEST_GRAPH_DETAILS",
};
export const DAILY_DATA_GRAPH = {
	FETCH_DAILY_DATA_GRAPH: "FETCH_DAILY_DATA_GRAPH",
};

export const PACK_RATE_GRAPH = {
	FETCH_PACK_RATE_GRAPH_DETAILS: "FETCH_PACK_RATE_GRAPH_DETAILS",
};
export const DASHBOARD={
	POND_LIST:"POND_LIST"
}
export const PRODUCTION_FORECAST = {
	LOADER: "LOADER",
	LOCATION_PRODUCT_VARIETIES: "LOCATION_PRODUCT_VARIETIES",
	FORECAST_COLUMNS: "FORECAST_COLUMNS",
	SET_DATA: "SET_DATA",
	FORECAST_EDITABLE: "FORECAST_EDITABLE",
	FORECAST_RENDER_TYPE: "FORECAST_RENDER_TYPE",
	FORECAST_ADD_DATA: "FORECAST_ADD_DATA",
	RESET_REFRESH: "RESET_REFRESH",
	FORECAST_FORM_DATA: "FORECAST_FORM_DATA",
	LOCATION_SKUS: "LOCATION_SKUS",
	LOCATION_CUSTOMERS: "LOCATION_CUSTOMERS",
	SET_DATA_SP: "SET_DATA_SP",
	SET_ACTIVE_TAB_CUSTOMER: "SET_ACTIVE_TAB_CUSTOMER",
	SET_ACTIVE_TAB_CUSTOMER_SF: "SET_ACTIVE_TAB_CUSTOMER_SF",
	SET_ACTIVE_TAB_DC: "SET_ACTIVE_TAB_DC",
	SET_SALES_FORECAST_DATA_SF: "SET_SALES_FORECAST_DATA_SF",
	SET_DC_KEY_MAPPING: "SET_DC_KEY_MAPPING",
	UPDATE_SALES_FORECAST_DATA: "UPDATE_SALES_FORECAST_DATA",
	TYPE_SP: "TYPE_SP",
	COMBINED_DIFFERENCE_SP: "COMBINED_DIFFERENCE_SP",
	SET_DATA_SP_VARIETY: "SET_DATA_SP_VARIETY",
	SET_DATA_SP_VARIETY_COMBINED: "SET_DATA_SP_VARIETY_COMBINED",
	SET_VARIETY_TYPE_SP: "SET_VARIETY_TYPE_SP",
};

export const CROPTIMALS = {
	LOADER_CROPTIMALS: "LOADER_CROPTIMALS",
	SET_DROPDOWN_DATA_CROPTIMALS: "SET_DROPDOWN_DATA_CROPTIMALS",
	SET_DATA_CROPTIMALS: "SET_DATA_CROPTIMALS",
	SET_DATA_LIST_CROPTIMALS: "SET_DATA_LIST_CROPTIMALS",
	SET_RENDER_TYPE_CROPTIMALS: "SET_RENDER_TYPE_CROPTIMALS",
	CROPTIMALS_COLUMNS_CROPTIMALS: "CROPTIMALS_COLUMNS_CROPTIMALS",
	SET_KEY_MAPPING_CROPTIMALS: "SET_KEY_MAPPING_CROPTIMALS",
	REFRESH_LISTING_CROPTIMALS: "REFRESH_LISTING_CROPTIMALS",
};

// water source results
export const WSR = {
	LOADER_WSR: "LOADER_WSR",
	SET_DROPDOWN_DATA_WSR: "SET_DROPDOWN_DATA_WSR",
	SET_RENDER_TYPE_WSR: "SET_RENDER_TYPE_WSR",
	SET_COLUMNS_WSR: "SET_COLUMNS_WSR",
	SET_DATA_WSR: "SET_DATA_WSR",
	REFRESH_LISTING_WSR: "REFRESH_LISTING_WSR",
	SET_LIST_DATA_WSR: "SET_LIST_DATA_WSR",
};

export const FERTILIZERS = {
	LOADER_FERTILIZER: "LOADER_FERTILIZER",
	SET_DROPDOWN_DATA_FERTILIZER: "SET_DROPDOWN_DATA_FERTILIZER",
	SET_RENDER_TYPE_FERTILIZER: "SET_RENDER_TYPE_FERTILIZER",
	LOCATION_SELECT_FERTILIZER: "LOCATION_SELECT_FERTILIZER",
	SET_DATA_FERTILIZER: "SET_DATA_FERTILIZER",
	UPDATE_DATA_FERTILIZER: "UPDATE_DATA_FERTILIZER",
	RESET_REFRESH_FERTILIZER: "RESET_REFRESH_FERTILIZER",
};

export const TO_ADD_LIST = {
	LOADER_ADD_LIST: "LOADER_ADD_LIST",
	SET_DROPDOWN_ADD_LIST: "SET_DROPDOWN_ADD_LIST",
	SET_RENDER_TYPE_ADD_LIST: "SET_RENDER_ADD_LIST",
	SET_DATA_ADD_LIST: "SET_DATA_ADD_LIST",
	SET_NUTRIENTS_ADD_LIST: "SET_NUTRIENTS_ADD_LIST",
	SET_TO_ADD_LIST_EDIT: "SET_TO_ADD_LIST_EDIT",
	UPDATE_DATA_ADD_LIST: "UPDATE_DATA_ADD_LIST",
	DELETE_DATA_TO_ADD_LIST: "DELETE_DATA_TO_ADD_LIST",
};

export const DOSING_CALC = {
	LOADER_DOSING: "LOADER_DOSING",
	SET_DROPDOWN_DOSING: "SET_DROPDOWN_DOSING",
	SET_RENDER_TYPE_DOSING: "SET_RENDER_DOSING",
	SET_DATA_DOSING: "SET_DATA_DOSING",
	PPM_UPDATE: "PPM_UPDATE",
	CHANGE_PV: "CHANGE_PV",
	RESET_DATA_DOSING: "RESET_DATA_DOSING",
	SAVE_DATA_DOSING: "SAVE_DATA_DOSING",
	EDIT_DATA_DOSING: "EDIT_DATA_DOSING",
	DONE_EDIT_DATA_DOSING: "DONE_EDIT_DATA_DOSING",
	SET_ACTIVE_TAB_POND: "SET_ACTIVE_TAB_POND",
	RESET_ALL: "RESET_ALL",
};
export const LR = {
	LOADER_LR: "LOADER_LR",
	SET_DROPDOWN_DATA_LR: "SET_DROPDOWN_DATA_LR",
	SET_RENDER_TYPE_LR: "SET_RENDER_TYPE_LR",
	SET_COLUMNS_LR: "SET_COLUMNS_LR",
	SET_DATA_LR: "SET_DATA_LR",
	REFRESH_LISTING_LR: "REFRESH_LISTING_LR",
	SET_LIST_DATA_LR: "SET_LIST_DATA_LR",
	SET_LIST_DATA_CROPTIMAL_LR: "SET_LIST_DATA_CROPTIMAL_LR",
	SET_FORM_LR: "SET_FORM_LR",
	SET_KEY_MAPPING_DATE: "SET_KEY_MAPPING_DATE",
	SET_CROPTIMAL_LR: "SET_CROPTIMAL_LR",
	SET_CROPTIMAL_SELECTION: "SET_CROPTIMAL_SELECTION",
	SET_ACTIVE_TAB_POND_LR: "SET_ACTIVE_TAB_POND_LR",
	SET_ACTIVE_PONDS: "SET_ACTIVE_PONDS",
	SET_ACTIVE_TAB: "SET_ACTIVE_TAB",
	SET_LIST_EDIT_DATA_LR:"SET_LIST_EDIT_DATA_LR"
};

export const KPI = { GET_LOCATION_KPI_DETAILS: "GET_LOCATION_KPI_DETAILS", UPDATE_KPI_DETAILS: "UPDATE_KPI_DETAILS" };

export const TRANSFER_REQUEST = {
	GET_TASK_DETAILS: "GET_TASK_DETAILS",
	AUDITLOG_DETAILS: "AUDITLOG_DETAILS",
	TASK_DETAILS_EDIT: "TASK_DETAILS_EDIT",
	LOCATION_SKU_LIST: "LOCATION_SKU_LIST",
	LOADER_FULFILLMENT: "LOADER_FULFILLMENT",
	DELETE_FULFILLMENT_ITEM: "DELETE_FULFILLMENT_ITEM",
	EDIT_REQUESTED_DETAILS: "EDIT_REQUESTED_DETAILS",
	EDIT_FULFILLMENT_DATA_DETAILS: "EDIT_FULFILLMENT_DATA_DETAILS",
	CHANGE_FULFILLMENT_STATUS: "CHANGE_FULFILLMENT_STATUS",
};

export const LANE_CALCULATOR = {
	LOADER_LC: "LOADER_LC",
	LOCATION_LIST: "LOCATION_LIST",
	LOCATION_PRODUCT_VARIETIES: "LOCATION_PRODUCT_VARIETIES",
	SET_DATA: "SET_DATA",
	SET_ACTIVE_TAB_CUSTOMER_LC: "SET_ACTIVE_TAB_CUSTOMER_LC",
	SET_TABLE_VIEW_COLUMNS: "SET_TABLE_VIEW_COLUMNS",
	TYPE_LU: "TYPE_LU",
	TYPE_TV: "TYPE_TV",
	SET_PRODUCTION_DATA: "SET_PRODUCTION_DATA",
	SET_SALES_VS_PRODUCTION_DATA: "SET_SALES_VS_PRODUCTION_DATA",
	SET_DATA_FORCAST: "SET_DATA_FORCAST",
	SET_PRODUCTION_DATA_FORCAST: "SET_PRODUCTION_DATA_FORCAST",
	SET_SALES_VS_PRODUCTION_DATA_FORCAST: "SET_SALES_VS_PRODUCTION_DATA_FORCAST",
	RESET_REFRESH_LC: "RESET_REFRESH_LC",
	SET_GROW_LANES: "SET_GROW_LANES",
	GROW_LANES_COLUMNS: "GROW_LANES_COLUMNS",
	SET_PRODUCT_VARIETIES: "SET_PRODUCT_VARIETIES",
	LANE_TO_CASE_FORM_DATA: "LANE_TO_CASE_FORM_DATA",
	SET_ACTIVE_TAB_DC_LC: "SET_ACTIVE_TAB_DC_LC",
	SKU_AND_PV: "SKU_AND_PV",
	LANE_USAGE_DATA: "LANE_USAGE_DATA",
	SET_SUBMIT_TRUE: "SET_SUBMIT_TRUE",
	RESET_FIELD_LU: "RESET_FIELD_LU",
	RESET_LANE_TO_CASE: "RESET_LANE_TO_CASE",
	RESET_PRODUCT_VARIETIES: "RESET_PRODUCT_VARIETIES",
	SET_PRODUCT_VARIETIES_CL: "SET_PRODUCT_VARIETIES_CL",
	RESET_LANE_USAGE_LC: "RESET_LANE_USAGE_LC",
	RESET_GROW_LANES: "RESET_GROW_LANES",
};

export const WATCH_WIRE = {
	LOADER_WW: "LOADER_WW",
	DROPDOWN_LIST: "DROPDOWN_LIST",
	FETCH_WATCH_WIRE_INVOICE: "FETCH_WATCH_WIRE_INVOICE",
	SET_ACTIVE_TAB_COMMODITY: "SET_ACTIVE_TAB_COMMODITY",
	SET_ACTIVE_TAB_ACCOUNT_ID: "SET_ACTIVE_TAB_ACCOUNT_ID",
	RESET_WATCH_WIRE_FIELD: "RESET_WATCH_WIRE_FIELD",
	EXPORT_FILE: "EXPORT_FILE",
	SHOW_CHART: "SHOW_CHART",
	SET_COLUMNS: "SET_COLUMNS",
};
