import fetchHandler from "../../utils/fetchHandler";
import { TRANSPLANT } from "../types";

export const fetchAllTransplantTasks = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `transplant-and-growing-tasks`,
		method: "POST",
		actionType: TRANSPLANT.FETCH_TRANSPLANT,
		body: JSON.stringify(values),
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const fetchTransplantTaskDetails = (slug, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `transplant-and-growing-task/${slug}`,
		method: "GET",
		actionType: TRANSPLANT.FETCH_TRANSPLANT_DETAILS,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const createTransplantTaskDetails = (values, method, payload, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `transplant-task/transplant-details/${payload}`,
		method: method,
		secure: true,
		actionType: TRANSPLANT.CREATE_TRANSPLANT_TASK_DETAILS,
		body: JSON.stringify(values),
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};
export const createTransplantAdditionalPonds = (values, method, payload, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `transplant-task/transplant-details/add-details/${payload}`,
		method: method,
		secure: true,
		actionType: TRANSPLANT.CREATE_TRANSPLANT_ADDITIONAL_POND,
		body: JSON.stringify(values),
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};
export const createTransplantTask = (values, method, payload, successHandler, errorHandler) => {
	const fetchOptions = {
		url: payload ? `transplant-and-growing-task/${payload}` : `transplant-and-growing-task`,
		method: method,
		secure: true,
		actionType: TRANSPLANT.CREATE_TRANSPLANT,
		body: JSON.stringify(values),
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};
export const createTransplantPond = (values, method, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `transplant-and-growing-task/pond-parameters`,
		method: method,
		secure: true,
		actionType: TRANSPLANT.CREATE_TRANSPLANT_POND,
		body: JSON.stringify(values),
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};
export const updatePondParameters = (values, method, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `update-transplant-pond-parameters`,
		method: method,
		secure: true,
		actionType: TRANSPLANT.UPDATE_POND_PARAMETERS,
		body: JSON.stringify(values),
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};
export const fetchAllPondParameters = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `list-transplant-pond-parameters`,
		method: "POST",
		actionType: TRANSPLANT.FETCH_ALL_POND_PARAMETERS,
		body: JSON.stringify(values),
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const deletePondParameters = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `delete-transplant-pond-parameters`,
		method: "DELETE",
		actionType: TRANSPLANT.DELETE_POND_PARAMETERS,
		body: JSON.stringify(values),
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};
export const fetchEditPondParameters = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `set-edit-transplant-pond-parameters`,
		method: "POST",
		actionType: TRANSPLANT.SET_EDIT_TRANSPLANT_POND_PARAMETERS,
		body: JSON.stringify(values),
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const deleteTransplantTask = (slug, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `transplant-and-growing-task/${slug}`,
		method: "DELETE",
		actionType: TRANSPLANT.DELETE_TRANSPLANT,
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const fetchTransplantLocationsMapping = (successHandler, errorHandler) => {
	const fetchOptions = {
		url: "task-users/transplanting",
		method: "GET",
		actionType: TRANSPLANT.GET_TRANSPLANT_LOCATION_DETAILS,
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const setTransplantViewRender = (value) => {
	return {
		type: TRANSPLANT.SET_TRANSPLANT_VIEW_RENDER,
		payload: value,
	};
};

export const startTransplantTask = (slug, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `start-transplant-task/${slug}`,
		method: "GET",
		actionType: TRANSPLANT.START_TRANSPLANT_TASK,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const completeTransplantTask = (slug, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `complete-transplant-task/${slug}`,
		method: "GET",
		actionType: TRANSPLANT.COMPLETE_TRANSPLANT_TASK,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

// Get Audit log of particular task
export const getAuditLogOfTransplantTask = (slug, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `transplant-task/audit-log/${slug}`,
		method: "GET",
		actionType: TRANSPLANT.GET_TRANSPLANT_TASK_AUDIT_LOG,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const getPondParamentersToday = (body, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `transplant-task/pond-parameters?location_slug=${body.location_slug}&date=${body.date}`,
		method: "GET",
		//body: JSON.stringify(body),
		actionType: TRANSPLANT.GET_POND_DETAILS_TODAY,
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const changeTransplantLocation = (location) => {
	return (dispatch) => {
		dispatch({
			type: TRANSPLANT.SELECTED_LOCATION,
			payload: location,
		});
	};
};

export const fetchTransplantTaskOrder = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `transplant-and-growing-tasks-order`,
		method: "POST",
		secure: true,
		body: JSON.stringify(values),
		actionType: TRANSPLANT.FETCH_TRANSPLANT_TASK_ORDER_LIST,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const updatePriorityTransplant = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `update-transplant-and-growing-tasks-order`,
		method: "POST",
		secure: true,
		body: JSON.stringify(values),
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};
