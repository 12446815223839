import { required, number, minValueBoard } from "../../../constant/formValidators";
import { find } from "lodash";
import { flagColors } from "../../../constant/global";
export const dropdownArrays = {
	flagColors,
};

export const formCreationFieldsTop = [
	{
		label: "label.flag_color_required",
		name: "flag_color",
		type: "select-react",
		placeholder: "label.enter_flag_color",
	},
];
export const formCreationFieldsMiddle = [
	{
		label: "label.pond_required",
		name: "pond_number",
		type: "select-react",
		placeholder: "label.select_pond",
	},
	{
		label: "label.lane_required",
		name: "lane_number",
		type: "select-react",
		placeholder: "label.select_lane_number",
	},
	{
		label: "label.no_of_boards_required",
		name: "number_of_boards",
		type: "text",
		placeholder: "label.enter_boards",
	},
	{
		label: "label.germination_date",
		name: "germination_rate",
		type: "text",
		placeholder: "label.enter_germination_date",
	},
];

export const formCreationFieldsBottom = [
	{
		label: "label.task_description",
		name: "task_description",
		type: "textarea",
		placeholder: "label.enter_description",
	},
];

export const getValidations = (field) => {
	switch (field) {
		case "lane_number":
			return required;
		case "pond_number":
			return required;
		case "number_of_boards":
			return [required, number, minValueBoard];
		case "germination_rate":
			return [number,minValueBoard];
		case "flag_color":
			return [required];
		default:
			return [];
	}
};

export const enumerateOptions = (locationPondLaneMappedArray, field) => {
	const newList =
		locationPondLaneMappedArray &&
		locationPondLaneMappedArray[field] > 0 &&
		Array(locationPondLaneMappedArray[field])
			.fill()
			.map((_, i) => ({
				label: i + 1,
				value: (i + 1).toString(),
			}));

	return newList ? newList : [];
};

export const getDropdownObj = (arrKey, value) => find(dropdownArrays[arrKey], (o) => o.value === value);
