import React from "react";
import { ContentWrap } from "../../../elements";

const ViewUser = () => {
	const pageTitle = "User Details";

	return (
		<ContentWrap>
			<div className="d-flex align-items-center mb-3">
				<div className="flex-fill">
					<h1>{pageTitle}</h1>
					{/* <Container>
						<Row>
							<Col xs={6} md={6}>
								<Form.Group controlId="userFirstName">
									<Form.Label>First Name</Form.Label>
									<Form.Control
										type="text"
										placeholder="Enter First Name"
									/>
								</Form.Group>
							</Col>
							<Col xs={6} md={6}>
								<Form.Group controlId="userLastName">
									<Form.Label>Last Name</Form.Label>
									<Form.Control
										type="text"
										placeholder="Enter Last Name"
									/>
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Col xs={6} md={6}>
								<Form.Group controlId="userEmail">
									<Form.Label>Email</Form.Label>
									<Form.Control
										type="text"
										placeholder="Enter Email"
									/>
								</Form.Group>
							</Col>
							<Col xs={6} md={6}>
								<Form.Group controlId="userPhone">
									<Form.Label>Phone Number</Form.Label>
									<Form.Control
										type="text"
										placeholder="Enter Phone Number"
									/>
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Col xs={6} md={6}>
								<Form.Group controlId="userLocation">
									<Form.Label>Location</Form.Label>
									<Form.Control as="select">
										<option>Select Location</option>
										<option>2</option>
										<option>3</option>
										<option>4</option>
										<option>5</option>
									</Form.Control>
								</Form.Group>
							</Col>
							<Col xs={6} md={6}>
								<Form.Group controlId="userRole">
									<Form.Label>Role</Form.Label>
									<Form.Control as="select">
										<option>Select Role</option>
										<option>2</option>
										<option>3</option>
										<option>4</option>
										<option>5</option>
									</Form.Control>
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Col xs={6} md={6}>
								<Form.Group controlId="userId">
									<Form.Label>User ID</Form.Label>
									<Form.Control
										type="text"
										placeholder="Enter User ID"
									/>
								</Form.Group>
							</Col>
							<Col xs={6} md={6}>
								<Form.Label></Form.Label>
								<Form.Check
									type="switch"
									id="custom-switch"
									label="Active"
								/>
							</Col>
						</Row>
					</Container>
					<Container>
						<Col xs={12} md={12}>
							<Form.File id="formcheck-api-regular">
								<Form.File.Label></Form.File.Label>
								<Form.File.Input />
							</Form.File>
						</Col>
					</Container> */}
				</div>
			</div>
		</ContentWrap>
	);
};

export default ViewUser;
