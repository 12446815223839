import { createSelectorCreator, defaultMemoize } from "reselect";
import Util from "utils/Util";
import { isEqual, get, isEmpty } from "lodash";
import moment from "moment";

const getOrders = (state) => state.OrdersPackaging;

const getAuth = (state) => state.Auth;

const getForm = (state) => state.form;

const getModal = (state) => state.Modals;

const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);

export const getRenderType = createDeepEqualSelector([getOrders], (order) => order.renderType);

export const getLocationSkus = createDeepEqualSelector([getOrders], (order) =>
	order.selectedLocation && order.locationSkus
		? !isEmpty(order.locationSkus[order.selectedLocation.value])
			? Util.mapOptions(order.locationSkus[order.selectedLocation.value], "name", "value")
			: []
		: []
);

export const getProductVariety = createDeepEqualSelector([getForm], (form) =>
	form.dailyTaskOrders && form.dailyTaskOrders.values && form.dailyTaskOrders.values
		? form.dailyTaskOrders
		: []
);

export const getLocationProductVarieties = createDeepEqualSelector(
	[getOrders],
	(order) =>
		order.productVarieties &&
		order.productVarieties.data &&
		Util.mapOptions(order.productVarieties.data, "product_variety", "slug")
);

export const getLocationProducts = createDeepEqualSelector([getOrders], (order) =>
	order.selectedLocation && order.locationProductVarieties
		? !isEmpty(order.locationProductVarieties[order.selectedLocation.value])
			? Util.mapOptions(
					order.locationProductVarieties[order.selectedLocation.value],
					"product_variety_name",
					"product_variety_slug"
			  )
			: []
		: []
);

export const getLocationList = createDeepEqualSelector(
	[getAuth],
	(auth) => Util.mapOptions(auth.userLocations, "name", "slug") || []
);

export const getCleaningTimeValue = createDeepEqualSelector([getOrders], (order) =>
	get(order.cleaningTime, "cleaning_time_in_minutes", null)
);
export const getCleaningTimeUser = createDeepEqualSelector([getOrders], (order) => get(order.cleaningTime, "user", null));

export const getSetUpTimeValue = createDeepEqualSelector([getOrders], (order) =>
	get(order.setUpTime, "set_up_time_in_minutes", null)
);

export const getSetUpTimeUser = createDeepEqualSelector([getOrders], (order) => get(order.setUpTime, "user", null));

export const getCustomers = createDeepEqualSelector([getOrders], (order) =>
	order.locationCustomers && order.selectedLocation
		? Util.mapOptions(order.locationCustomers[order.selectedLocation.value], "customer_name", "customer_slug")
		: []
);

const getCustomerIndex = createDeepEqualSelector([getOrders], (order) =>
	order.locationCustomers &&
	order.selectedLocationCustomer &&
	order.locationCustomers[order.selectedLocation.value].length > 0
		? order.locationCustomers[order.selectedLocation.value].find(
				(item) => item.customer_slug === order.selectedLocationCustomer.value
		  )
		: {}
);

export const getCustomerDC = createDeepEqualSelector([getCustomerIndex], (customer) =>
	!isEmpty(customer) && customer.dc_list.length > 0 ? Util.mapOptions(customer.dc_list, "dc_name", "dc_slug") : []
);

const getDcIndex = createDeepEqualSelector([getCustomerIndex, getOrders], (customer, order) =>
	order.selectedCustomerDC && !isEmpty(customer) && customer.dc_list.length > 0
		? customer.dc_list.find((item) => item.dc_slug === order.selectedCustomerDC.value)
		: {}
);

export const getDcSku = createDeepEqualSelector([getDcIndex], (dc) =>
	!isEmpty(dc) && dc.dc_skus.length > 0 ? Util.mapOptions(dc.dc_skus, "sku_name", "sku_slug") : []
);

export const getLocationSelected = createDeepEqualSelector([getOrders], (order) => order.locationSelected);

export const editOrderInitials = createDeepEqualSelector([getOrders, getModal, getLocationSelected], (order, modal, location) =>
	modal && order.selectedOrder && modal.modalType === 2
		? {
				task_id: order.selectedOrder.slug,
				order_id: order.selectedOrder.order.order_id,
				location_slug: order.selectedLocation,
				customer_slug: order.selectedLocationCustomer,
				dc_slug: order.selectedCustomerDC,
				skus: order.selectedCustomerDC
					? { label: order.selectedOrder.sku_name, value: order.selectedOrder.sku_slug }
					: "",
				skusSelected: { [order.selectedOrder.sku_slug]: order.selectedOrder.cases },
				order_request_date: moment(order.selectedOrder.order_request_date).toDate(),
				date_of_delivery: order.selectedOrder.date_of_delivery
					? moment(order.selectedOrder.date_of_delivery).toDate()
					: "",
		  }
		: location
		? { location_slug: location }
		: {}
);

//Inventory

export const getInventoryItems = createDeepEqualSelector([getForm], (form) =>
	form.inventoryForm && form.inventoryForm.values && form.inventoryForm.values.itemSelected
		? form.inventoryForm.values.itemSelected
		: []
);

export const getInventoryType = createDeepEqualSelector([getForm], (form) =>
	form.inventoryForm &&
	form.inventoryForm.values &&
	form.inventoryForm.values.inventory &&
	form.inventoryForm.values.inventory.value
		? form.inventoryForm.values.inventory.value
		: ""
);

const checkDateDifference = (date) => {
	const date1 = new Date(date);
	const date2 = new Date();

	// To calculate the time difference of two dates
	const Difference_In_Time = date2.getTime() - date1.getTime();
	// To calculate the no. of days between two dates
	const Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
	return parseInt(Difference_In_Days);
};

const daysDifference = createDeepEqualSelector(
	[getForm],
	(form) =>
		form.inventoryForm &&
		form.inventoryForm.values &&
		form.inventoryForm.values.date &&
		checkDateDifference(form.inventoryForm.values.date)
);

export const checkInventoryDateLessThanCurrent = createDeepEqualSelector([daysDifference], (days) =>
	days <= 3 && days > 0 ? true : false
);

export const getInventoryDate = createDeepEqualSelector([getForm], (form) =>
	form.inventoryForm && form.inventoryForm.values && form.inventoryForm.values.date ? form.inventoryForm.values.date : ""
);

export const getInventoryLocation = createDeepEqualSelector([getForm], (form) =>
	form.inventoryForm && form.inventoryForm.values && form.inventoryForm.values.location_slug
		? form.inventoryForm.values.location_slug.value
		: ""
);

export const getInventoryDetails = createDeepEqualSelector([getOrders], (order) => order.inventoryDetails);

export const getSkuFields = createDeepEqualSelector([getForm], (form) =>
	form.inventoryForm && form.inventoryForm.values && form.inventoryForm.values.sku ? form.inventoryForm.values.sku : null
);

export const getProductVarietyFields = createDeepEqualSelector([getForm], (form) =>
	form.inventoryForm && form.inventoryForm.values && form.inventoryForm.values.product_variety
		? form.inventoryForm.values.product_variety
		: null
);

//Daily Tasks

const getDailyTaskForm = createDeepEqualSelector([getForm], (form) => (form.dailyTaskOrders ? form.dailyTaskOrders : null));

export const getDailyTaskFormDate = createDeepEqualSelector([getDailyTaskForm], (form) => (form ? form.values.date : null));

export const getDailyTaskFormLocation = createDeepEqualSelector([getDailyTaskForm], (form) =>
	form ? (form.values.location_slug ? form.values.location_slug.value : null) : null
);

export const getDailyTaskFormRoom = createDeepEqualSelector([getDailyTaskForm], (form) =>
	form ? (form.values.room ? form.values.room.value : null) : null
);

export const getDailyTaskFormPackagingType = createDeepEqualSelector([getDailyTaskForm], (form) =>
	form ? (form.values.type ? form.values.type.value : null) : null
);

export const getDailyTaskFormUser = createDeepEqualSelector([getDailyTaskForm], (form) =>
	form ? (form.values.user ? form.values.user : null) : null
);

export const getEmployeeCount = createDeepEqualSelector([getOrders], (form) => form.employeeCount);

export const getPackroomPrecool = createDeepEqualSelector([getOrders], (form) => form.packroomPrecool);

export const getPackageWaste = createDeepEqualSelector([getOrders], (form) => form.packageWaste);

//Cases Per Sku

export const getDateInitial = createDeepEqualSelector([], () => ({ date: new Date() }));

const getcasesPerSkuForm = createDeepEqualSelector([getForm], (form) => (form.casesPerSku ? form.casesPerSku : null));

export const getCasesLocation = createDeepEqualSelector([getcasesPerSkuForm], (form) =>
	form ? (form.values.location_slug ? form.values.location_slug.value : null) : null
);

export const getCasesDate = createDeepEqualSelector([getcasesPerSkuForm], (form) =>
	form ? (form.values.date ? form.values.date : null) : null
);

export const getCasesPerSku = createDeepEqualSelector([getOrders], (order) => order.casesPerSku);

//Cases Per Customer

const getcasesByCustomerForm = createDeepEqualSelector([getForm], (form) =>
	form.casesByCustomer ? form.casesByCustomer : null
);

export const getCasesByCustomerLocation = createDeepEqualSelector([getcasesByCustomerForm], (form) =>
	form ? (form.values.location_slug ? form.values.location_slug.value : null) : null
);

export const getCasesByCustomerDate = createDeepEqualSelector([getcasesByCustomerForm], (form) =>
	form ? (form.values.date ? form.values.date : null) : null
);

export const getCasesByCustomerSku = createDeepEqualSelector([getcasesByCustomerForm], (form) =>
	form ? (form.values.sku_slug ? form.values.sku_slug : null) : null
);

export const getCasesByCustomerFormData = createDeepEqualSelector([getcasesByCustomerForm], (form) =>
	form ? (form.values.casesByCustomer ? form.values.casesByCustomer : null) : null
);

export const getCasesByCustomerFormValidate = createDeepEqualSelector([getcasesByCustomerForm], (form) =>
	form ? (form.values.validate ? form.values.validate : null) : null
);

export const getCasesByCustomer = createDeepEqualSelector([getOrders], (order) => order.casesByCustomer);

export const getLoading = createDeepEqualSelector([getOrders], (order) => order.loading);

export const getShouldUpdateCases = createDeepEqualSelector([getOrders], (order) => order.shouldUpdateCases);

// access privileges

const getOrderPackagingPermit = (state) => state.Auth.permissionDetails.orders_and_packaging;

export const getAddAccess = createDeepEqualSelector(
	[getOrderPackagingPermit],
	(access) => access && access.permissions.create_order
);

export const getListAccess = createDeepEqualSelector(
	[getOrderPackagingPermit],
	(access) => access && access.permissions.list_order
);
export const getViewAccess = createDeepEqualSelector(
	[getOrderPackagingPermit],
	(access) => access && access.permissions.view_order
);
export const getUpdateAccess = createDeepEqualSelector(
	[getOrderPackagingPermit],
	(access) => access && access.permissions.update_order
);
export const getDeleteAccess = createDeepEqualSelector(
	[getOrderPackagingPermit],
	(access) => access && access.permissions.delete_order
);

export const getCreateInventoryAccess = createDeepEqualSelector(
	[getOrderPackagingPermit],
	(access) => access && access.permissions.order_inventory
);

export const getCompleteTaskAccess = createDeepEqualSelector(
	[getOrderPackagingPermit],
	(access) => access && access.permissions.log_order_details
);
export const getStartTaskAccess = createDeepEqualSelector(
	[getOrderPackagingPermit],
	(access) => access && access.permissions.log_order_details
);
export const getAuditLogAccess = createDeepEqualSelector(
	[getOrderPackagingPermit],
	(access) => access.permissions.view_orders_audit_log
);
export const getCasesPerSkuAccess = createDeepEqualSelector(
	[getOrderPackagingPermit],
	(access) => access && access.permissions.cases_per_sku
);

export const getCreatePackagingTaskAccess = createDeepEqualSelector(
	[getOrderPackagingPermit],
	(access) => access && access.permissions.log_order_details
);

export const getUpdatePackagingTaskAccess = createDeepEqualSelector(
	[getOrderPackagingPermit],
	(access) => access && access.permissions.log_order_details
);

export const getUpdateTaskSkuRecipeAccess = createDeepEqualSelector(
	[getOrderPackagingPermit],
	(access) => access && access.permissions.log_order_details
);
export const getSkuRecipeAccess = createDeepEqualSelector(
	[getOrderPackagingPermit],
	(access) => access && access.permissions.change_sku_recipe
);

export const getCasesByCustomerAccess = createDeepEqualSelector(
	[getOrderPackagingPermit],
	(access) => access && access.permissions.cases_by_customer
);
export const getDailyTaskAccess = createDeepEqualSelector(
	[getOrderPackagingPermit],
	(access) => access && access.permissions.order_daily_task_details
);

export const getPackagingOrderViewAccess = createDeepEqualSelector(
	[getOrderPackagingPermit],
	(access) => access && access.permissions.view_packaging_order
);

export const getPackagingOrderUpdateAccess = createDeepEqualSelector(
	[getOrderPackagingPermit],
	(access) => access && access.permissions.update_packaging_order
);

export const getDailyTaskInitials = (data) =>
	createDeepEqualSelector([getLocationSelected], (location) =>
		// SET UP TIME EDIT
		data
			? data
			: {
					location_slug: location,
					date: new Date(),
					cleaning_date: new Date(),
					set_up_date: new Date(),
			  }
	);

export const getLocationInitial = createDeepEqualSelector([getLocationSelected], (location) => ({
	location_slug: location,
	date: new Date(),
}));

export const getInventoryIntials = (data) =>
	createDeepEqualSelector([getLocationSelected], (location) =>
		data
			? data
			: {
					location_slug: location,
					date: new Date(),
			  }
	);

export const getPackagingOrderLocation = createDeepEqualSelector([getOrders], (orders) => orders.packagingOrderLocation);

export const getPackagingOrderExpDate = createDeepEqualSelector([getOrders], (orders) => orders.packagingOrderExpDate);

export const getPackagingOrderData = createDeepEqualSelector([getOrders], (orders) => orders.packagingOrderData);

export const getInventoryData = createDeepEqualSelector([getOrders], (order) => order.inventoryTaskDetails.inventory);
export const getSkuName = createDeepEqualSelector([getOrders], (order) => order.inventoryTaskDetails.sku);
export const getFormValues = createDeepEqualSelector([getForm], (data) => data);

export const getPackageList = createDeepEqualSelector([getOrders], (orders) => orders.ordersAndPackagingList);

