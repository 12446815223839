import { isEmpty, keys } from "lodash";
import React from "react";
import { connect } from "react-redux";
import Util from "../../../utils/Util";
import { createStructuredSelector } from "reselect";
import { getNutrientsTableColumns, getNutrientsTableData } from "store/selectors/toAddListSelectors";
import ActionTable from "utils/actionTable";
import ToAddListForm from "../manage";

const NutrientListing = ({ tableData, columns }) => {
	const renderRows = (date, arrayData) => {
		const data = {};
		const keyMapping = [];

		arrayData.forEach((item, index) => {
			const key = item.pond_name === "Total" ? "total" : item.pond_name;
			data[key] = item.row_data.map((item_i) => ({
				slug: item_i.slug,
				value: item_i.value,
			}));
			keyMapping.push({
				name: item.pond_name,
				pondIndex: index,
				slug: key,
			});
		});
		return (
			<div key={date}>
				<h3> Week - {Util.getDateFormat(date)}</h3>

				<ActionTable
					columns={columns}
					fixedColumnHeading="Pond"
					initialData={data}
					keyMapping={keyMapping}
					showActions={false}
					formName={date}
				/>
			</div>
		);
	};
	return (
		<div className="to-add-list-listing-wrapper">
			<ToAddListForm />
			{tableData &&
				columns &&
				(!isEmpty(tableData) ? (
					tableData &&
					keys(tableData)
						.sort()
						.map((key) => renderRows(key, tableData[key]))
				) : (
					<div className="no-results-found text-center">No Results Found</div>
				))}
		</div>
	);
};

const mapStateToProps = createStructuredSelector({
	tableData: getNutrientsTableData,
	columns: getNutrientsTableColumns,
});

export default connect(mapStateToProps)(NutrientListing);
