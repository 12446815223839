/* eslint-disable array-callback-return */
import { createSelectorCreator, defaultMemoize } from "reselect";
import { isEmpty, isEqual, keys, uniqBy, values } from "lodash";
import Util from "utils/Util";

const getLaneCalculator = (state) => state.LaneCalculator;

const getForm = (state) => state.form;

const getAuth = (state) => state.Auth;

const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);

//get the list of locations
export const getLocationList = createDeepEqualSelector(
	[getAuth],
	(auth) => Util.mapOptions(auth.userLocations, "name", "slug") || []
);

export const getLaneCalculatorForm = createDeepEqualSelector([getForm], (form) =>
	form.laneCalculatorForm ? form.laneCalculatorForm : null
);

export const getLoading = createDeepEqualSelector(
	[getLaneCalculator],
	(LaneCalculator) => LaneCalculator && LaneCalculator.loading
);

export const getLaneCalculatorFormLocation = createDeepEqualSelector([getLaneCalculatorForm], (form) =>
	form && form.values && form.values.location_slug ? form.values.location_slug : null
);

export const getSkus = createDeepEqualSelector([getLaneCalculatorForm], (form) =>
	form && form.values && form.values.skus ? form.values.skus : null
);

export const getLaneCalculatorFormDcName = createDeepEqualSelector([getLaneCalculatorForm], (form) =>
	form && form.values && form.values.dc_name ? form.values.dc_name : null
);

export const getSubmitData = createDeepEqualSelector([getLaneCalculator], (LaneCalculator) => LaneCalculator.submitLane);

export const getLaneUsageData = createDeepEqualSelector([getLaneCalculator], (LaneCalculator) => LaneCalculator.laneUsageData);

export const getTableViewData = createDeepEqualSelector([getLaneCalculator], (LaneCalculator) => LaneCalculator.tableView);

export const getProduct = createDeepEqualSelector([getLaneCalculator], (LaneCalculator) => LaneCalculator.productVarietiesCL);

const getLocationCustomers = createDeepEqualSelector(
	[getLaneCalculator],
	(laneCalculator) => laneCalculator && laneCalculator.laneCalculatorLocations && laneCalculator.laneCalculatorLocations
);

export const getLaneCalculatorFormCustomerAdd = createDeepEqualSelector([getLaneCalculatorForm], (form) =>
	form && form.values && form.values.customer ? form.values.customer : null
);

//get the list of customers
export const getCustomers = createDeepEqualSelector(
	[getLaneCalculator, getLaneCalculatorFormLocation],
	(LaneCalculator, location) =>
		LaneCalculator.laneCalculatorLocations && location
			? Util.mapOptions(LaneCalculator.laneCalculatorLocations[location.value], "customer_name", "customer_slug")
			: []
);

//get the customer dc names list
export const getLaneCalculatorCustomer = createDeepEqualSelector([getLaneCalculatorForm], (form) =>
	form && form.values && form.values.customer ? form.values.customer : null
);

export const getDcNames = (LaneCalculator, customer, location) => {
	const loc = location && location.value;
	const customerArray = LaneCalculator && LaneCalculator.laneCalculatorLocations[loc];
	return dcArrayFormatting(customerArray, customer, "dc");
};

const dcArrayFormatting = (customerArray, customer, type) => {
	const obj = customerArray && customerArray.find((item) => item.customer_slug === customer.value);
	return obj && obj.dc_list ? Util.mapOptions(obj.dc_list, "dc_name", "dc_slug") : [];
};
export const getCustomerDC = createDeepEqualSelector(
	[getLaneCalculator, getLaneCalculatorCustomer, getLaneCalculatorFormLocation],
	(LaneCalculator, customer, location) => getDcNames(LaneCalculator, customer, location)
);

const skusArrayFormatting = (customerArray, dc, cus) => {
	const customer = customerArray && customerArray.find((item) => item.customer_slug === cus);
	const dcName = customer && customer.dc_list.find((item) => item.dc_slug === dc);
	return dcName && dcName.dc_skus ? Util.mapOptions(dcName.dc_skus, "sku_name", "sku_slug") : [];
};

export const getSkuNames = (LaneCalculator, customer, location, dcName) => {
	const loc = location && location.value;
	const customerArray = LaneCalculator && LaneCalculator.laneCalculatorLocations[loc];
	const dc = dcName && dcName.value;
	const cus = customer && customer.value;
	return skusArrayFormatting(customerArray, dc, cus);
};

export const getSkusList = createDeepEqualSelector(
	[getLaneCalculator, getLaneCalculatorCustomer, getLaneCalculatorFormLocation, getLaneCalculatorFormDcName],
	(LaneCalculator, customer, location, dcName) => getSkuNames(LaneCalculator, customer, location, dcName)
);

const getFixedHeaderFunction = (data) => {
	let key = {};
	key = data && keys(data);
	const keyItem = key && key[0];
	return key && data && data[keyItem] && keys(data[keyItem]);
};

export const getCustomerLaneUsageData = createDeepEqualSelector(
	[getLaneCalculator],
	(laneCalculator) => laneCalculator.laneUsage
);
export const getLaneCalculatorColumns = createDeepEqualSelector([getLaneCalculator], (laneCalculator) =>
	keys(laneCalculator.laneUsage)
);
export const customerFixedHeader = createDeepEqualSelector([getLaneCalculator], (laneCalculator) =>
	getFixedHeaderFunction(laneCalculator.laneUsage)
);

export const getTableData = createDeepEqualSelector([getLaneCalculator], (laneCalculator) => keys(laneCalculator.tableView));

const arrayFormating = (formItem, selItem, type) => {
	const data = [];

	// eslint-disable-next-line array-callback-return
	formItem.map((item_1) => {
		const obj = selItem.find((item_2) =>
			type === "dc" ? item_1.value === item_2.customer_slug : item_1.value === item_2.dc_slug
		);
		if (obj) {
			if (type === "dc") {
				data.push(...obj.dc_list);
			} else {
				data.push(...obj.dc_skus);
			}
		}
	});

	if (type === "dc") {
		return data;
	} else {
		return uniqBy(data, "sku_slug");
	}
};

const selectedCustomers = createDeepEqualSelector(
	[getLocationCustomers, getLaneCalculatorFormLocation, getLaneCalculatorCustomer],
	(laneUsage, location, customers) =>
		laneUsage && location && customers && !isEmpty(laneUsage[location.value])
			? arrayFormating(customers, laneUsage[location.value], "dc")
			: null
);

export const getCustomerDCSample = createDeepEqualSelector([selectedCustomers], (customer) =>
	customer ? Util.mapOptions(customer, "dc_name", "dc_slug") : []
);

export const getForecastFormDC = createDeepEqualSelector([getLaneCalculatorForm], (form) =>
	form && form.values && form.values.dc_name ? form.values.dc_name : null
);

const selectedDCs = createDeepEqualSelector([selectedCustomers, getForecastFormDC], (customers, dc) =>
	customers && dc ? arrayFormating(dc, customers, "sku") : null
);

export const getDcSku = createDeepEqualSelector([selectedDCs], (dc) => (dc ? Util.mapOptions(dc, "sku_name", "sku_slug") : []));

const tabKeys = (data) => {
	const keys = {};
	// eslint-disable-next-line array-callback-return
	data.map((item) => {
		keys[item.replace(/\s/g, "")] = {
			key: item.replace(/\s/g, ""),
			label: item,
		};
	});

	return keys;
};

export const customerKeyLabel = createDeepEqualSelector([getTableViewData], (data) =>
	data && !isEmpty(data) ? tabKeys(keys(data)) : null
);

export const getTabsCustomer = createDeepEqualSelector([customerKeyLabel], (data) =>
	data && !isEmpty(data) ? values(data) : null
);

export const getActiveTabCustomer = createDeepEqualSelector(
	[getLaneCalculator],
	(laneCalculator) => laneCalculator.activeTabCustomer
);
export const getActiveTabDC = createDeepEqualSelector([getLaneCalculator], (laneCalculator) => laneCalculator.activeTabDC);

export const dcKeyLabel = createDeepEqualSelector(
	[getTableViewData, getActiveTabCustomer, customerKeyLabel],
	(data, customerKey, customerObj) =>
		data && !isEmpty(customerKey) && !isEmpty(customerObj) && !isEmpty(data)
			? tabKeys(keys(data)) &&
			  tabKeys(keys(data[customerObj])) &&
			  tabKeys(keys(data[customerObj[customerKey]])) &&
			  tabKeys(keys(data[customerObj[customerKey].label])) &&
			  tabKeys(keys(data[customerObj[customerKey].label]))
			: null
);

export const getTabsDC = createDeepEqualSelector([dcKeyLabel], (data) => (data && !isEmpty(data) ? values(data) : null));

export const getTableDataLC = createDeepEqualSelector(
	[getTableViewData, getActiveTabCustomer, getActiveTabDC, customerKeyLabel, dcKeyLabel],
	(data, customerKey, dcKey, customerObj, dcObj) =>
		data && customerKey && dcKey && customerObj && dcObj
			? data[customerObj[customerKey].label] &&
			  data[customerObj[customerKey].label][dcObj[dcKey].label] &&
			  data[customerObj[customerKey].label][dcObj[dcKey].label]
			: null
);

export const getLaneUtilizedColumns = createDeepEqualSelector([getLaneCalculator], (laneCalculator) =>
	keys(laneCalculator.laneUtilization)
);

const ULdata = (data, key) => {
	const UlData = {};
	key &&
		key.map((item_i) => {
			const obj = {};
			const lanes = data && data[item_i] && data[item_i].gh_utilized_lanes;
			lanes &&
				lanes.map((item_lane) => {
					obj[item_lane.pv_name] = item_lane;
				});
			UlData[item_i] = obj;
		});
	return UlData;
};

export const LUkeyLabel = createDeepEqualSelector([getLaneCalculator], (data) =>
	data && data.laneUtilization && !isEmpty(data.laneUtilization)
		? ULdata(data.laneUtilization, keys(data.laneUtilization))
		: null
);

export const getLaneUtilizedData = createDeepEqualSelector([LUkeyLabel], (data) => (data && !isEmpty(data) ? data : null));

export const getSalesVsProductionType = createDeepEqualSelector([getLaneCalculator], (data) => data.setTableViewDropDown);

const TableViewdata = (data, key, type) => {
	const UlData = {};
	key &&
		key.map((item_i) => {
			const obj = {};
			const lanes = data && data[item_i] && data[item_i];
			lanes &&
				lanes.map((item_lane) => {
					if (type === "sales") {
						obj[item_lane.slug] = {
							pv_name: item_lane.name,
							pv_slug: item_lane.slug,
							lane_usage: item_lane.forecast_value,
						};
					} else if (type === "production") {
						obj[item_lane.slug] = {
							pv_name: item_lane.name,
							pv_slug: item_lane.slug,
							lane_usage: item_lane.value,
							lbs_per_case: item_lane.lbs_per_case,
						};
					} else if (type === "production_vs_sales") {
						obj[item_lane.slug] = {
							pv_name: item_lane.name,
							pv_slug: item_lane.slug,
							lane_usage: item_lane.forecast_value,
							lbs_per_case: item_lane.lbs_per_case,
							sales: item_lane.sales,
							production: item_lane.production,
						};
					} else if (type === "production_vs_sales_difference") {
						obj[item_lane.slug] = {
							pv_name: item_lane.name,
							pv_slug: item_lane.slug,
							lane_usage: item_lane.difference,
						};
					}
				});
			UlData[item_i] = obj;
		});
	return UlData;
};

export const TableViewkeyLabel = createDeepEqualSelector([getTableDataLC, getSalesVsProductionType], (data, type) =>
	data && data && !isEmpty(data) ? TableViewdata(data, keys(data), type) : null
);
export const getTableView = createDeepEqualSelector([TableViewkeyLabel], (data) => (data && !isEmpty(data) ? data : null));
export const getTableViewHeader = createDeepEqualSelector([getLaneCalculator], (data) =>
	data && data.laneCalculatorTableColumns && !isEmpty(data.laneCalculatorTableColumns)
		? data.laneCalculatorTableColumns
		: null
);

export const getSalesVsProductionTypeLu = createDeepEqualSelector([getLaneCalculator], (data) => data.setLaneUsageDropDown);

const getTypeObjSP = (array, type) => {
	let obj = {};
	// eslint-disable-next-line array-callback-return
	array.map((item) => {
		if (item.value === type) {
			obj = item;
		}
	});
	return obj;
};

export const salesVsProdTypeSelect = (array) =>
	createDeepEqualSelector([getSalesVsProductionType], (data) => getTypeObjSP(array, data));

export const forcastDropdownSelect = (array) =>
	createDeepEqualSelector([getSalesVsProductionTypeLu], (data) => getTypeObjSP(array, data));

export const getFormValues = createDeepEqualSelector([getForm], (form) =>
	form.laneCalculatorForm ? form.laneCalculatorForm.values : null
);

export const getTableViewDataProduction = createDeepEqualSelector(
	[getLaneCalculator],
	(LaneCalculator) => LaneCalculator.tableView
);

export const getLaneUtilized = createDeepEqualSelector([getLaneCalculator], (LaneCalculator) => LaneCalculator.laneUtilization);

//CaseToLane
export const laneToCaseSkuList = createDeepEqualSelector([getLaneCalculator], (LaneCalculator) =>
	LaneCalculator && LaneCalculator.skusAndPvList && LaneCalculator.skusAndPvList
		? Util.mapOptions(LaneCalculator.skusAndPvList, "sku_name", "sku_slug")
		: []
);
const getProductVariety = (data, skus) => {
	let product = [];
	data &&
		data.length > 0 &&
		skus &&
		data.map((item) => {
			if (item.sku_slug === skus.value) {
				product = item.product_varieties ? item.product_varieties : null;
			}
		});
	return product;
};
export const getProductVarietyList = createDeepEqualSelector([getLaneCalculator, getSkus], (LaneCalculator, skus) =>
	LaneCalculator && LaneCalculator.skusAndPvList && LaneCalculator.skusAndPvList && skus
		? getProductVariety(LaneCalculator.skusAndPvList, skus)
		: null
);

const getData = (data, variety) => {
	const array = [
		{ name: "Oz/Board", slug: "oz_board" },
		{ name: "Grow Cycle", slug: "grow_cycle" },
	];
	const CLData = {};
	variety &&
		variety.map((item_v) => {
			const temp = [];
			array &&
				array.map((item_a) => {
					const obj = {
						name: item_a.name,
						value: "",
						slug: item_a.slug,
						product_slug: item_v.pv_slug,
					};
					temp.push(obj);
				});
			CLData[item_v.pv_name] = temp;
		});
	return CLData;
};
export const getCaseToLaneData = createDeepEqualSelector([getLaneCalculator, getProduct], (LaneCalculator, productVariety) =>
	LaneCalculator && LaneCalculator.skusAndPvList && productVariety
		? getData(LaneCalculator.skusAndPvList, productVariety)
		: []
);

const getLaneTableData = (laneUsage) => {
	const LUData = {};
	laneUsage &&
		laneUsage.length > 0 &&
		laneUsage.map((item) => {
			let temp = {};
			temp = {
				value: item.lanes_required,
				slug: item.pv_slug,
				name: item.pv_name,
			};
			LUData[item.pv_name] = [temp];
		});
	return LUData;
};

export const getLaneData = createDeepEqualSelector([getLaneUsageData], (laneUsage) =>
	laneUsage && laneUsage && !isEmpty(getLaneTableData(laneUsage)) ? getLaneTableData(laneUsage) : null
);
const formatErrors = (errors) => {
	const errStrings = [];
	// eslint-disable-next-line array-callback-return
	values(errors).map((item) => {
		for (const key in item) {
			if (!errStrings.includes(item[key])) {
				errStrings.push(item[key]);
			}
		}
	});

	return errStrings.join(" , ");
};
export const getCaseToLaneErrors = createDeepEqualSelector([getForm], (form) =>
	form.caseToLaneTable && form.caseToLaneTable.submitFailed && form.caseToLaneTable.syncErrors
		? formatErrors(form.caseToLaneTable.syncErrors)
		: null
);

export const getCaseToLaneValues = createDeepEqualSelector([getForm], (form) =>
	form.caseToLaneTable && form.caseToLaneTable.values ? form.caseToLaneTable.values : null
);

//case to lane ends

// LaneToCase

export const getGrowLanes = createDeepEqualSelector([getLaneCalculator], (item) => item.growLanesData);

export const getProductVarieties = createDeepEqualSelector([getLaneCalculator], (item) => item.productVarietiesData);
const formatTableData = (data) => {
	const tableData = {};

	keys(data).map((key) => {
		const rowData = [];

		keys(data[key]).map((dataKey) => {
			const obj = {
				slug: dataKey,
				value: data[key][dataKey],
			};
			rowData.push(obj);
		});
		tableData[key] = rowData;
	});
	return tableData;
};

export const getGrowLanesTableData = createDeepEqualSelector([getGrowLanes], (data) =>
	data && !isEmpty(data) ? formatTableData(data) : null
);

export const getProductVarietiesTableData = createDeepEqualSelector([getProductVarieties], (data) =>
	data && !isEmpty(data) ? formatTableData(data) : null
);

// LaneToCase form
export const getLaneToCaseForm = createDeepEqualSelector([getForm], (form) =>
	form.laneToCaseForm ? form.laneToCaseForm : null
);

export const getLaneToCaseFormFromDate = createDeepEqualSelector([getLaneToCaseForm], (form) =>
	form && form.values && form.values.date && form.values.date[0] ? form.values.date[0] : null
);
export const getLaneToCaseFormToDate = createDeepEqualSelector([getLaneToCaseForm], (form) =>
	form && form.values && form.values.date && form.values.date[1] ? form.values.date[1] : null
);

export const getLaneToCaseFormLocation = createDeepEqualSelector([getLaneToCaseForm], (form) =>
	form && form.values && form.values.location_slug ? form.values.location_slug : null
);
export const getLaneToCaseFormPV = createDeepEqualSelector([getLaneToCaseForm], (form) =>
	form && form.values && form.values.product_variety ? form.values.product_variety.map((item) => item.value) : null
);

export const getLaneToCaseLocationProducts = createDeepEqualSelector(
	[getLaneToCaseFormLocation, getLaneCalculator],
	(form, laneCalculator) =>
		form && laneCalculator.productVarieties
			? !isEmpty(laneCalculator.productVarieties[form.value])
				? Util.mapOptions(laneCalculator.productVarieties[form.value], "product_variety_name", "product_variety_slug")
				: []
			: []
);

// getTableDataUSTotal

const formatSPTotal = (data, columns, headers) => {
	const arr = columns.map((key, index) => {
		let total = 0;
		headers &&
			headers.map((item, index) => {
				total = total + data[key][item].lane_usage;
			});
		return { total: total };
	});
	return arr;
};

export const getTableDataUSTotal = createDeepEqualSelector(
	[getCustomerLaneUsageData, getLaneCalculatorColumns, customerFixedHeader],
	(data, columns, header) => data && columns && formatSPTotal(data, columns, header)
);

//lane to case ends
