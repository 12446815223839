import { isEmpty, keys, values } from "lodash";
import { PRODUCTION_FORECAST } from "../types";

const initialState = {
	forecastRender: 0,
	loading: false,
	productVarieties: null,
	forecastColumns: null,
	forecastData: null,
	forecastEditable: false,
	forecastAddData: null,
	forecastFormData: null,
	skus: null,
	locationCustomers: null,
	salesVsProductionData: null,
	activeTabCustomer: null,
	activeTabDC: null,
	salesForecastData: null,
	dcKeyMapping: null,
	salesVsProductionType: 0,
	salesVsProductionCombinedData: null,
	combinedDifferenceSP: false,
	salesVsProductionDataVariety: null,
	varietyTypeSP: null,
};

export default function (state = initialState, { type, payload }) {
	switch (type) {
		case PRODUCTION_FORECAST.LOADER:
			return {
				...state,
				loading: !state.loading,
			};
		case PRODUCTION_FORECAST.LOCATION_PRODUCT_VARIETIES:
			return {
				...state,
				productVarieties: payload.data,
			};
		case PRODUCTION_FORECAST.FORECAST_COLUMNS:
			const tempData = payload.map((item) => ({
				name: item.label,
				slug: item.value,
			}));
			return {
				...state,
				forecastColumns: tempData,
			};
		case PRODUCTION_FORECAST.SET_DATA:
			return {
				...state,
				forecastEditable: false,
				forecastData: payload.data,
			};
		case PRODUCTION_FORECAST.FORECAST_EDITABLE:
			return {
				...state,
				forecastEditable: payload,
			};
		case PRODUCTION_FORECAST.FORECAST_RENDER_TYPE:
			return {
				...state,
				forecastRender: payload,
			};

		case PRODUCTION_FORECAST.FORECAST_ADD_DATA:
			return {
				...state,
				forecastAddData: payload,
			};
		case PRODUCTION_FORECAST.RESET_REFRESH:
			return {
				...initialState,
				forecastRender: state.forecastRender,
				productVarieties: state.productVarieties,
				skus: state.skus,
				locationCustomers: state.locationCustomers,
			};
		case PRODUCTION_FORECAST.FORECAST_FORM_DATA:
			return {
				...state,
				forecastFormData: payload,
			};
		case PRODUCTION_FORECAST.LOCATION_SKUS:
			return {
				...state,
				skus: payload.data,
			};
		case PRODUCTION_FORECAST.LOCATION_CUSTOMERS:
			return {
				...state,
				locationCustomers: payload.data,
			};
		case PRODUCTION_FORECAST.SET_DATA_SP:
			return {
				...state,
				salesVsProductionData: payload.data.collection,
				activeTabCustomer: !isEmpty(payload.data.collection)
					? keys(payload.data.collection)[0].replace(/\s/g, "")
					: null,
				activeTabDC: !isEmpty(payload.data.collection)
					? keys(values(payload.data.collection)[0])[0].replace(/\s/g, "")
					: null,
				salesVsProductionCombinedData: payload.data.combined,
			};
		case PRODUCTION_FORECAST.SET_ACTIVE_TAB_CUSTOMER:
			return {
				...state,
				activeTabCustomer: payload.key,
				activeTabDC: keys(state.salesVsProductionData[payload.label])[0].replace(/\s/g, ""),
			};
		case PRODUCTION_FORECAST.SET_ACTIVE_TAB_CUSTOMER_SF:
			return {
				...state,
				activeTabCustomer: payload,
				activeTabDC: keys(state.salesForecastData[payload].customer_dcs)[0].replace(/\s/g, ""),
			};
		case PRODUCTION_FORECAST.SET_ACTIVE_TAB_DC:
			return {
				...state,
				activeTabDC: payload,
			};
		case PRODUCTION_FORECAST.SET_SALES_FORECAST_DATA_SF:
			return {
				...state,
				forecastEditable: false,
				salesForecastData: payload.data,
				activeTabCustomer: !isEmpty(payload.data) ? keys(payload.data)[0] : null,
				activeTabDC: !isEmpty(payload.data) ? keys(values(payload.data)[0].customer_dcs)[0] : null,
			};
		case PRODUCTION_FORECAST.UPDATE_SALES_FORECAST_DATA:
			return {
				...state,
				salesForecastData: payload,
			};

		case PRODUCTION_FORECAST.SET_DC_KEY_MAPPING:
			return {
				...state,
				dcKeyMapping: payload,
			};

		case PRODUCTION_FORECAST.TYPE_SP:
			return {
				...state,
				salesVsProductionType: payload,
			};

		case PRODUCTION_FORECAST.COMBINED_DIFFERENCE_SP:
			return {
				...state,
				combinedDifferenceSP: !state.combinedDifferenceSP,
			};

		case PRODUCTION_FORECAST.SET_DATA_SP_VARIETY:
			return {
				...state,
				forecastEditable: false,
				salesVsProductionData: payload.data,
				activeTabCustomer: !isEmpty(payload.data) ? keys(payload.data)[0].replace(/\s/g, "") : null,
				activeTabDC: !isEmpty(payload.data) ? keys(values(payload.data)[0])[0].replace(/\s/g, "") : null,
			};
		case PRODUCTION_FORECAST.SET_DATA_SP_VARIETY_COMBINED:
			return {
				...state,
				forecastEditable: false,
				salesVsProductionCombinedData: payload.data,
			};
		case PRODUCTION_FORECAST.SET_VARIETY_TYPE_SP:
			return {
				...state,
				varietyTypeSP: payload,
			};
		default:
			return state;
	}
}
