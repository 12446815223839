import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import ForecastForm from "../../../manage";
import {
	checkDaysCountEqualSF,
	getTabsCustomerSF,
	getActiveTabCustomer,
	getTabsDCSF,
	getActiveTabDC,
	getTableDataSF,
	getForecastEditable,
	getForecastFormLocation,
	getForecastFormFromDate,
	getForecastFormToDate,
	getSalesForecastData,
	getColumnsSF,
	getDailyViewAccess,
	getDailyEditAccess,
	getForecastForm,
	getCustomers
} from "store/selectors/forecastSelectors";
import ForecastTable from "utils/forecastTable";
import {
	setForecastEditable,
	setLoader,
	setActiveTabCustomer,
	setActiveTabDC,
	updateDailyForecast,
	updateSalesForecastData,
	fetchDailyForecastSFExport,
} from "store/actions";
import { isEmpty } from "lodash";
import { Tab, TabContent, Tabs } from "react-bootstrap";
import { ListingBtn, ButtonPrimary } from "elements";
import moment from "moment";
import { successMessage } from "utils/successMessage";
import { errorMessage } from "utils/errorMessage";

const Listing = ({
	setLoader,
	checkDaysCountEqualSF,
	customerTabs,
	activeTabCustomer,
	setActiveTabCustomer,
	activeTabDC,
	dcTabs,
	setActiveTabDC,
	tableData,
	setForecastEditable,
	forecastEditable,
	locationSelected,
	fromDate,
	toDate,
	updateDailyForecast,
	updateSalesForecastData,
	responseData,
	columnsSF,
	viewAccess,
	editAccess,
	formValues,
	fetchDailyForecastSFExport,
	submitting,
	customerList
}) => {
	const onSave = (values) => {
		setLoader();
		const submitData = {
			location_slug: locationSelected.value,
			from: moment(fromDate).format("YYYY-MM-DD"),
			to: moment(toDate).format("YYYY-MM-DD"),
			customer_slug: activeTabCustomer,
			distribution_centers: {
				[activeTabDC]: values,
			},
		};

		const successHandler = (e) => {
			successMessage(e);
			setLoader();

			const temp = {
				...responseData,
				[activeTabCustomer]: {
					...responseData[activeTabCustomer],
					customer_dcs: {
						...responseData[activeTabCustomer].customer_dcs,
						[activeTabDC]: {
							...responseData[activeTabCustomer].customer_dcs[activeTabDC],
							forecast_values: values,
						},
					},
				},
			};

			updateSalesForecastData(temp);
			setForecastEditable(false);
		};
		const errorHandler = (e) => {
			errorMessage(e);
			setLoader();
		};
		updateDailyForecast(submitData, successHandler, errorHandler);
	};

	const exportHandler = () => {
		const values = formValues && formValues.values;
		const submitData = {
			location_slug: values && values.location_slug.value,
			customer_slug: values && values.customer.map((item) => item.value),
			dc_slugs: values && values.dc.map((item) => item.value),
			from: values && moment(values.date[0]).format("YYYY-MM-DD"),
			to: values && moment(values.date[1]).format("YYYY-MM-DD"),
			skus: values && values.sku_sp.map((item) => item.value),
			export_customer_slug: activeTabCustomer,
			export: 1,
		};
		const successHandler = (e) => {
			e.data.export_url && window.open(e.data.export_url);
		};
		const errorHandler = (e) => {
			errorMessage(e);
		};

		fetchDailyForecastSFExport(submitData, successHandler, errorHandler);
	};

	return (
		<div className="listing-wrapper" style={{ paddingBottom: !viewAccess && 50 }}>
			{viewAccess && (
				<>
					<ForecastForm type={5} />
					{customerTabs && (
						<div className="tab-wrapper">
							<Tabs
								activeKey={activeTabCustomer}
								defaultActiveKey={activeTabCustomer}
								onSelect={(k) => {
									forecastEditable && setForecastEditable(false);
									setActiveTabCustomer(k, "sf");
								}}
								mountOnEnter={true}
								className="customer-tab"
								id="uncontrolled-tab-outer">
								{customerTabs.map((tab, index) => (
									<Tab eventKey={tab.key} key={index} title={tab.label}>
										{/* {seedsFormFields(tab.value)} */}
										{!isEmpty(tableData) && (
											<div className="forecast-export-btn">
												<ButtonPrimary type="submit" disabled={submitting} onClick={exportHandler}>
													<span>
														Export{" "}
														<i
															className="icon icon-export text-sm"
															style={{ fontWeight: "bold" }}
														/>
													</span>
												</ButtonPrimary>
											</div>
										)}
										{dcTabs && (
											<TabContent bsPrefix="customer-tab-content">
												<Tabs
													activeKey={activeTabDC}
													defaultActiveKey={activeTabDC}
													onSelect={(k) => {
														forecastEditable && setForecastEditable(false);
														setActiveTabDC(k);
													}}
													className="dc-tab"
													mountOnEnter={true}
													id="uncontrolled-tab-inner">
													{dcTabs.map((tab, index) => (
														<Tab
															className="dc-tab-cell"
															eventKey={tab.key}
															key={index}
															title={
																<span>
																	<i className="icon icon-market mr-3" />
																	{tab.label}
																</span>
															}>
															{activeTabDC === tab.key && tableData ? (
																!isEmpty(tableData) && !isEmpty(columnsSF) ? (
																	<>
																		{editAccess && (
																			<ListingBtn
																				tooltipText="Edit"
																				btnClass="btn btn-cell btn-light forecast-sm-btn"
																				iconClass="icon icon-edit"
																				onClick={() => setForecastEditable(true)}
																			/>
																		)}
																		{!checkDaysCountEqualSF && (
																			<div className="d-flex justify-content-end">
																				<p className="dates-missing-text">
																					Data is not available for a few dates
																				</p>
																			</div>
																		)}

																		<ForecastTable
																			editable={forecastEditable}
																			columns={columnsSF}
																			fixedColumnHeading="Date"
																			initialData={tableData}
																			onSave={onSave}
																			dateFormat="DD-MM-YYYY"
																			onCancel={() => setForecastEditable(false)}
																			renderKey="forecast_value"
																			localeString={true}
																			total
																			// render={(item) =>
																			// 	item.forecast_value ? item.forecast_value : "-"
																			// }
																		/>
																	</>
																) : (
																	<div className="no-results-found text-center">
																		No Results Found
																	</div>
																)
															) : null}
														</Tab>
													))}
												</Tabs>
											</TabContent>
										)}
									</Tab>
								))}
							</Tabs>
						</div>
					)}
					{responseData && isEmpty(responseData) && (
						<div className="no-results-found text-center">No Results Found</div>
					)}
				</>
			)}
		</div>
	);
};

const mapStateToProps = createStructuredSelector({
	// columns: getForecastColumns,
	// data: getForecastData,
	checkDaysCountEqualSF: checkDaysCountEqualSF,
	activeTabCustomer: getActiveTabCustomer,
	customerTabs: getTabsCustomerSF,
	activeTabDC: getActiveTabDC,
	dcTabs: getTabsDCSF,
	tableData: getTableDataSF,
	forecastEditable: getForecastEditable,
	locationSelected: getForecastFormLocation,
	fromDate: getForecastFormFromDate,
	toDate: getForecastFormToDate,
	responseData: getSalesForecastData,
	columnsSF: getColumnsSF,
	viewAccess: getDailyViewAccess,
	editAccess: getDailyEditAccess,
	formValues: getForecastForm,
	customerList: getCustomers,
});
export default connect(mapStateToProps, {
	setForecastEditable,
	setLoader,
	setActiveTabCustomer,
	setActiveTabDC,
	updateDailyForecast,
	updateSalesForecastData,
	fetchDailyForecastSFExport,
})(Listing);
