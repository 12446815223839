import React from "react";
import { connect } from "react-redux";
import { BarChart, Bar, CartesianGrid, ResponsiveContainer, XAxis, YAxis, Tooltip } from "recharts";
import { createStructuredSelector } from "reselect";
import { getHarvestRateArray, getHarvestKpiData } from "store/selectors/dashboardSelectors";
import { cartesianGridProps, xAxisProps, yAxisProps, singleToolTipProps, barChartProps } from "../chartComponentHelper";
import ContentLoader from "../../contentLoader/contentLoader";
import { useTranslation } from "react-i18next";

const HarvestRateGraphBlock = ({ getHarvestRateArray, loadingFlag, getHarvestKpiData }) => {
	const { t } = useTranslation();
	return (
		<div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6 mb-4">
			<div className="card h-100">
				<div className="card-body">
					<div className="d-flex justify-content-between">
						<div className="graph-headers">{t("label.harvest_rate")}</div>
						<div className="graph-headers">
							{t("label.kpi_target")} - {getHarvestKpiData}{" "}
						</div>
					</div>

					{loadingFlag ? (
						<ContentLoader type="bar" />
					) : Object.keys(getHarvestRateArray).length !== 0 ? (
						<ResponsiveContainer aspect={2}>
							<BarChart data={getHarvestRateArray} {...barChartProps}>
								<CartesianGrid {...cartesianGridProps} />
								<XAxis {...xAxisProps} />
								<YAxis {...yAxisProps} />
								<Tooltip {...singleToolTipProps} />
								<Bar dataKey="value" fill={"#cedc2f"} stroke={"#cedc2f"}></Bar>
							</BarChart>
						</ResponsiveContainer>
					) : (
						<p className="card-text-empty">{t("no_data_available")}</p>
					)}
				</div>
			</div>
		</div>
		// </div>
	);
};

const mapStateToProps = createStructuredSelector({
	getHarvestRateArray,
	getHarvestKpiData,
});
export default connect(mapStateToProps, null)(HarvestRateGraphBlock);
