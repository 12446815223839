import React, { useState } from "react";
import { connect, useSelector } from "react-redux";
import { ContentWrap } from "../../elements";
import Chart from "./chart";
import ChartTable from "./table/chartTable";
import ReportForm from "./form";
import { fetchGraphDetails, downloadExcel } from "../../store/actions";
import Util from "../../utils/Util";
import { errorMessage } from "../../utils/errorMessage";
//import { successMessage } from "../../utils/successMessage";
import { isEmpty, capitalize, includes } from "lodash";
//import { reportTypeArray } from "./formHelper";
import { Row, Col } from "react-bootstrap";
import "./reports.scss";
//import ContentLoader from "../contentLoader/contentLoader";
import TableLoader from "../../utils/table/tableLoader";
import { ButtonPrimary } from "../../elements";

const Reports = ({ getAllPrivilegesList, privilegesList = {}, updatePrivileges, fetchGraphDetails, downloadExcel }) => {
	const pageTitle = "Reports";
	const [isLoading, setisLoading] = useState(false);
	const [btnTitle, setbtnTitle] = useState("Apply");
	const [type, setType] = useState("");
	const [timeFrame, setTimeFrame] = useState("");
	const [data, setData] = useState([]);
	const [restoreGraph, setRestoreGraph] = useState("");
	const [reportName, setReportName] = useState("");
	const formValues = useSelector((state) => state.form.customerManagementForm);

	const setSubmitValues = (values, flag) => {
		const fromDate =
			values.date && values.timeframe.value === "monthly"
				? Util.getFirstDay(new Date(values.date[0]), "month")
				: values.date && values.timeframe.value === "yearly"
				? Util.getFirstDay(new Date(values.date[0]), "year")
				: Util.getMDYFormatUtcDate(new Date(values.date[0]));

		const toDate =
			values.date && values.timeframe.value === "monthly"
				? Util.getLastDay(new Date(values.date[1] ? values.date[1] : values.date[0]), "month")
				: values.date && values.timeframe.value === "yearly"
				? Util.getLastDay(new Date(values.date[1] ? values.date[1] : values.date[0]), "year")
				: Util.getMDYFormatUtcDate(new Date(values.date[1] ? values.date[1] : values.date[0]));

		let submissionData = {
			report: values.report ? values.report.value : "",
			location_slug: values.location ? values.location.value : "",
			type: values.type ? values.type.value : "",
			from: fromDate ? fromDate : "",
			to: toDate ? toDate : "",
			timeframe: values.timeframe ? values.timeframe.value : "",
			timezone: Util.getLocalTimeZone(),
		};
		if (values.report && values.report.value === "orders_and_packaging" && values.sku) {
			submissionData = { ...submissionData, sku_slug: values.sku && values.sku.value ? values.sku.value : null };
		}
		if (
			values.report &&
			includes(["sales_forecast_revenue_by_cases", "production_forecast_revenue_by_cases"], submissionData.type)
		) {
			submissionData = {
				...submissionData,
				customer_slug: values.customer_slug && values.customer_slug.value ? values.customer_slug.value : "",
				dc_slug: values.dc_slug && values.dc_slug.value ? values.dc_slug.value : "",
			};
		}

		if (
			values.report &&
			includes(["production_forecast_revenue_by_variety", "sales_forecast_revenue_by_variety"], submissionData.type)
		) {
			submissionData = {
				...submissionData,
				pv_slug: values.pv_slug && values.pv_slug.value ? values.pv_slug.value : "",
				exclude_wastage: values.exclude_wastage ? true : false,
			};
		}

		if (flag) {
			submissionData = {
				...submissionData,
				export: 1,
			};
		}
		return submissionData;
	};
	const submitCustomerDetails = (values) => {
		if (!isLoading) {
			setisLoading(true);
			setbtnTitle("Fetching...");
			setData([]);
			const exportFlag = false;
			const submissionData = setSubmitValues(values, exportFlag);
			const successHandler = (event) => {
				setbtnTitle("Apply");
				setisLoading(false);
				setData(event);
				setType(submissionData.type);
				setTimeFrame(submissionData.timeframe);
				setRestoreGraph(0);
				setReportName(submissionData.report);
			};
			const errorHandler = (event) => {
				setbtnTitle("Apply");
				setisLoading(false);
				errorMessage(event);
			};
			fetchGraphDetails(submissionData, successHandler, errorHandler);
		}
	};
	const DownloadHandler = () => {
		const exportFlag = true;
		const submissionData = setSubmitValues(formValues.values, exportFlag);
		const downloadSuccessHandler = (event) => {
			if (event && event.data && event.data.download_url) {
				window.open(event.data.download_url);
			}
		};
		const downloadErrorHandler = (event) => {
			errorMessage(event);
		};
		downloadExcel(submissionData, downloadSuccessHandler, downloadErrorHandler);
	};

	return (
		<ContentWrap>
			<div className="reports-container">
				<div className="d-flex align-items-center mb-4">
					<div className="flex-fill">
						<h1>{pageTitle}</h1>
						<span className="page-bread-crumbs">
							<a href="/dashboard">Home</a> / Reports{" "}
						</span>
					</div>
				</div>
				<ReportForm submitCustomerDetails={submitCustomerDetails} btnTitle={btnTitle} isLoading={isLoading} />

				{data && data.data && data.data.chartData && !isEmpty(data.data.chartData) ? (
					isLoading ? (
						<TableLoader />
					) : Object.keys(data.data.chartData).length !== 0 ? (
						<Chart
							data={data}
							isLoading={isLoading}
							restoreGraph={{ restore: restoreGraph }}
							type={type}
							timeFrame={capitalize(timeFrame)}
							reportName={reportName}
						/>
					) : (
						!isLoading && <p className="card-text-empty">No Data Available</p>
					)
				) : (
					timeFrame && <div className="card-text-empty align-text-report-center">No Data Available</div>
				)}
				{data && data.data && data.data.tableData && !isEmpty(data.data.tableData) ? (
					<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-4">
						<div className="card h-100" style={{ marginLeft: "93.3%", border: "none" }}>
							<Row>
								<ButtonPrimary
									className="reset-btn"
									style={{ float: "right" }}
									onClick={DownloadHandler}
									// onClick={() => {
									// 	submitCustomerDetails(formValues.values, "Export");
									// }}
								>
									<span>
										Export <i className="icon icon-export text-sm" style={{ fontWeight: "bold" }} />
									</span>
								</ButtonPrimary>
							</Row>
						</div>
					</div>
				) : (
					""
				)}
				{data && data.data && data.data.tableData && !isEmpty(data.data.tableData) ? (
					<Row>
						<Col>{isLoading ? "" : <ChartTable type={type} data={data} timeFrame={capitalize(timeFrame)} />}</Col>
					</Row>
				) : (
					""
				)}
			</div>
		</ContentWrap>
	);
};

const mapDispatchToProps = { fetchGraphDetails, downloadExcel };
export default connect(null, mapDispatchToProps)(Reports);
