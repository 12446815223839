import React, { useState, useEffect } from "react";
import { createStructuredSelector } from "reselect";
import { reduxForm, Field, reset, change } from "redux-form";
import { Button, Row, Col, Form, Container } from "react-bootstrap";
import { connect, useDispatch } from "react-redux";
import moment from "moment";
import TableLoader from "utils/table/tableLoader";
import useModal from "utils/useModal";
import FormGroups from "utils/formGroup";
import {
	getInventoryDate,
	getInventoryItems,
	getInventoryLocation,
	getInventoryType,
	getLocationList,
	getLocationProducts,
	getLocationSkus,
	getInventoryDetails,
	getLocationSelected,
	getInventoryIntials,
} from "store/selectors/ordersPackagingSelectors";
import { inventoryForm, inventoryList } from "./formHelper";
import { isEqual } from "lodash";
import {
	loadLocationSkus,
	fetchLocationProductVarities,
	selectLocation,
	addInventoryDetails,
	fetchInventoryDetails,
	resetListing,
	setInventoryNull,
	editInventory,
} from "store/actions";
import { errorMessage } from "utils/errorMessage";
import InventoryTable from "./inventoryTable";
import { successMessage } from "utils/successMessage";
import Util from "utils/Util";
import { useTranslation } from 'react-i18next';

const InventoryForm = ({
	handleSubmit,
	locationList,
	productVarietyList,
	skuList,
	loadLocationSkus,
	fetchLocationProductVarities,
	selectLocation,
	selectedItems,
	addInventoryDetails,
	form,
	selectedLocation,
	selectedDate,
	selectedInventory,
	fetchInventoryDetails,
	getInventoryDetails,
	resetListing,
	setInventoryNull,
	initialValues,
	globalLocation,
	editData,
	editInventory,
	setRefetch,
}) => {
	const { t } = useTranslation();
	const [loading, setloading] = useState(false);
	const [btnTitle, setbtnTitle] = useState(t("label.save"));
	const dispatch = useDispatch();
	const [, , , closeModal] = useModal("task");

	const [inventoryType, setinventoryType] = useState(editData ? editData.inventory : null);
	const [tableValidation, settableValidation] = useState("");

	const onSuccess = () => {
		setloading(false);
	};
	const onError = (e) => {
		setloading(false);
		errorMessage(e);
	};

	useEffect(() => {
		if (globalLocation) {
			selectLocation(globalLocation);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [globalLocation]);

	useEffect(() => {
		if (selectedLocation && selectedDate && selectedInventory) {
			setloading(true);
			const inventoryData = {
				date: moment(selectedDate).format("YYYY-MM-DD HH:mm:ss"),
				inventory: selectedInventory,
				location_slug: selectedLocation,
			};

			fetchInventoryDetails(inventoryData, onSuccess, onError);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedLocation, selectedDate, selectedInventory]);

	useEffect(() => {
		if (getInventoryDetails) {
			dispatch(
				change(
					"inventoryForm",
					selectedInventory === "UnSoldCases" ? "sku" : "product_variety",
					getInventoryDetails.selectedFields
				)
			);
			dispatch(change("inventoryForm", "itemSelected", getInventoryDetails.selectedItems));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [getInventoryDetails]);

	useEffect(() => {
		setloading(true);

		const loadFn = () => {
			loadLocationSkus(() => fetchLocationProductVarities(onSuccess, onError));
		};
		loadFn();
	}, [fetchLocationProductVarities, loadLocationSkus]);

	useEffect(() => {
		return () => {
			// resetListing();
			dispatch(reset("inventoryForm"));
			setInventoryNull();
			selectLocation(null);
		};
	}, [dispatch, selectLocation, setInventoryNull]);

	const submitForm = (values) => {
		let checkValidation = "";
		// eslint-disable-next-line array-callback-return
		values.itemSelected.map((item) => {
			if (item.count && isNaN(Number(item.count)) && item.count === "") {
				checkValidation = "notBoth";
				return true;
			} else if (item.count && isNaN(Number(item.count))) {
				if (checkValidation === "required") {
					checkValidation = "notBoth";
					return true;
				} else {
					checkValidation = "notNumber";
				}
			} else if (!item.count) {
				if (checkValidation === "notNumber") {
					checkValidation = "notBoth";
					return true;
				} else {
					checkValidation = "required";
				}
			}
		});

		if (checkValidation) {
			settableValidation(checkValidation);
		} else {
			setloading(true);
			setbtnTitle(t("label.saving"));
			const selItems =
				values.inventory.value === "UnSoldCases"
					? values.itemSelected.map((item) => ({
							sku_slug: item.value,
							unsold_cases: item.count,
							added_to_package: item.addToPackage ? 1 : 0,
					  }))
					: values.itemSelected.map((item) => ({
							pv_slug: item.value,
							unpacked_lbs: item.count,
							added_to_package: item.addToPackage ? 1 : 0,
					  }));

			const submitData = editData
				? {
						inventory_id: editData.inventory_id,
						...(values.inventory.value === "UnSoldCases"
							? { unsold_cases: values.itemSelected[0].count }
							: { unpacked_lbs: values.itemSelected[0].count }),
						added_to_package: values.itemSelected[0].addToPackage,
						type: values.inventory.value,
						timezone: Util.getLocalTimeZone(),
						date: moment(values.date).format("YYYY-MM-DD"),
				  }
				: {
						date: moment(values.date).format("YYYY-MM-DD HH:mm:ss"),
						inventory: values.inventory.value,
						location_slug: values.location_slug.value,
						inventory_details: selItems,
				  };

			const successHandler = (event) => {
				reset(form);
				setloading(false);
				setbtnTitle(t("label.save"));
				setRefetch && setRefetch((refetch) => refetch + 1);
				successMessage(event);
				closeModal();
			};
			const errorHandler = (event) => {
				setloading(false);
				setbtnTitle(t("label.save"));
				errorMessage(event);
			};
			editData
				? editInventory(submitData, successHandler, errorHandler)
				: addInventoryDetails(submitData, successHandler, errorHandler);
		}
	};

	const getOptions = (name,id) => {
		switch (name) {
			case "location_slug":
				return locationList;
			case "inventory":
				return inventoryList;
			case "sku":
				return skuList;
			case "product_variety":
				return productVarietyList;
			default:
				return [];
		}
	};

	const onSelect = (field, event) => {
		switch (field) {
			case "date":
				if (getInventoryDetails) {
					setInventoryNull();
					dispatch(change("inventoryForm", "itemSelected", []));
					dispatch(change("inventoryForm", "product_variety", ""));
					dispatch(change("inventoryForm", "sku", ""));
				}
				break;
			case "location_slug":
				selectLocation({ value: event.value, label: event.label });
				dispatch(change("inventoryForm", "itemSelected", []));
				dispatch(change("inventoryForm", "product_variety", ""));
				dispatch(change("inventoryForm", "sku", ""));

				break;
			case "inventory":
				setinventoryType((prevState) => {
					if (!isEqual(prevState, event)) {
						dispatch(change("inventoryForm", "itemSelected", []));
						dispatch(change("inventoryForm", event.value === "UnSoldCases" ? "product_variety" : "sku", ""));
						return {
							...event,
						};
					} else {
						return {
							...event,
						};
					}
				});
				break;
			case "sku":
			case "product_variety":
				setInventoryNull();
				const arr = [];
				if (!event) {
					dispatch(change("inventoryForm", "itemSelected", []));
				} else if (selectedItems.length > event.length) {
					// eslint-disable-next-line array-callback-return
					selectedItems.map((item_o) => {
						const checkValue = event.find((item_i) => item_o.value === item_i.value);
						if (checkValue) {
							arr.push(item_o);
						}
					});
				} else {
					// eslint-disable-next-line array-callback-return
					event.map((item_o) => {
						const checkValue = selectedItems.find((item_i) => item_o.value === item_i.value);
						if (!checkValue) {
							arr.push(...selectedItems, { ...item_o, count: "", addToPackage: false, editable: true });
						}
					});
				}


				dispatch(change("inventoryForm", "itemSelected", arr));

				break;
			default:
				return;
		}
	};

	const getFormFieldHtml = (formfields) => {
		let formFieldHtml = [];

		formFieldHtml = formfields.map((field, idx) => {
			return (
				<React.Fragment key={idx}>
					<Col className={field.name === "date" || field.name === "location_slug" ? "col-md-6" : "col-12"}>
						<Form.Group controlId={`formControl ${field.name}`}>
							{field.name === "date" ? (
								<Field
									name={field.name}
									type={field.type}
									label={t(field.label)}
									placeholder={t(field.placeholder)}
									component={FormGroups}
									minDate={field.minDate}
									validate={field.validate}
									disable={editData ? true : false}
									onChange={(event) => onSelect(field.name, event)}
								/>
							) : (
								<Field
									name={field.name}
									type={field.type}
									label={t(field.label)}
									placeholder={t(field.placeholder)}
									component={FormGroups}
									validate={field.validate}
									disable={editData ? true : false}
									multiple={field.name === "product_variety" || field.name === "sku" ? true : false}
									options={getOptions(field.name,idx)}
									onChange={(event) => onSelect(field.name, event)}
								/>
							)}
						</Form.Group>
					</Col>
				</React.Fragment>
			);
		});
		return <Row>{formFieldHtml}</Row>;
	};

	return (
		<Form onSubmit={handleSubmit(submitForm)} className="dailyTaskOrders">
			<Container style={{ position: "relative" }}>
				{getFormFieldHtml(inventoryForm.details)}
				{inventoryType &&
					(inventoryType.value === "UnSoldCases"
						? getFormFieldHtml(inventoryForm.bySKU)
						: inventoryType.value === "UnPackedLbs"
						? getFormFieldHtml(inventoryForm.byProductVariety)
						: null)}
				{selectedItems.length > 0 && <InventoryTable validate={tableValidation} editMode={editData ? true : false} />}
				{loading && (
					<div className="table-loader-wrapper">
						<TableLoader />
					</div>
				)}
			</Container>
			<div className="modal-footer save-footer" style={{ marginTop: 40 }}>
				<Button variant="secondary" onClick={closeModal}>
					{t("label.cancel")}
				</Button>
				<Button variant="primary" type="submit" disabled={loading}>
					{btnTitle}
				</Button>
			</div>
		</Form>
	);
};

const mapStateToProps = (state, { editData }) =>
	createStructuredSelector({
		locationList: getLocationList,
		skuList: getLocationSkus,
		productVarietyList: getLocationProducts,
		selectedItems: getInventoryItems,
		selectedDate: getInventoryDate,
		selectedLocation: getInventoryLocation,
		selectedInventory: getInventoryType,
		getInventoryDetails: getInventoryDetails,
		initialValues: getInventoryIntials(editData),
		globalLocation: getLocationSelected,
	});

export default connect(mapStateToProps, {
	loadLocationSkus,
	fetchLocationProductVarities,
	selectLocation,
	addInventoryDetails,
	fetchInventoryDetails,
	resetListing,
	setInventoryNull,
	editInventory,
})(reduxForm({ form: "inventoryForm", enableReinitialize: false })(InventoryForm));
