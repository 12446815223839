import React from "react";
import { Col } from "react-bootstrap";
import RenderForecastTableColumn from "./renderForecastTableColumn";

export const renderRow = ({ nameKey, columns, a_item, a_ind, editable, renderKey, render, slugKey }) => {
	return (
		<>
			{columns.map((b_item, b_ind) => {
				const c_ind = a_item.findIndex((key) => (b_item.slug ? key.slug === b_item.slug : key.slug === b_item));
				if (c_ind !== -1) {
					const c_item = a_item[c_ind];
					if (render) {
						return (
							<Col key={b_ind} className="lab-table-col">
								<span>{render(c_item)}</span>
							</Col>
						);
					}
					return (
						<RenderForecastTableColumn
							key={b_ind}
							b_ind={b_ind}
							a_ind={a_ind}
							c_ind={c_ind}
							editable={editable}
							renderKey={renderKey}
							render={render}
							c_item={c_item}
							slugKey={slugKey}
							nameKey={nameKey}
						/>
					);
				} else {
					return (
						<Col key={b_ind} className="lab-table-col">
							<span>-</span>
						</Col>
					);
				}
			})}
		</>
	);
};
