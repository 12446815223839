import React from "react";
import Checkbox from "../../utils/checkbox";
import { includes, every, isEmpty, some } from "lodash";
import "../../utils/checkbox/checkboxStyles.scss";

// Summary row (Not collapsed)
export const AccordionTableSummary = ({ onChangeSummary, role, disabled, permission }) => {
	const isAllChecked = (!isEmpty(permission) && every(permission, (o) => includes(o.permission_roles, role.id))) || false;

	const isAnyChecked = (!isEmpty(permission) && some(permission, (o) => includes(o.permission_roles, role.id))) || false;

	return (
		<div className="accordion-table-body-row-item summary-row">
			<Checkbox
				type={isAllChecked ? "normal" : isAnyChecked ? "single" : "normal"}
				disabled={disabled}
				checked={isAllChecked || isAnyChecked}
				onChange={(e) => onChangeSummary(e, role, permission)}
			/>
		</div>
	);
};

// Permissions row (each CRUD Row) (Collapsible row)
export const AccordionTablePermissionRow = ({ roles, disabled, onChangeCheckbox, permissionRoles, details }) => {
	return (
		<Checkbox
			checked={includes(permissionRoles, roles.id)}
			disabled={disabled}
			onChange={(e) => onChangeCheckbox(e, roles.id, details)}
		/>
	);
};

export const AccordionTableSummaryFunction = (onChangeSummary, role, disabled, permission) => {
	const isAllChecked = (!isEmpty(permission) && every(permission, (o) => includes(o.permission_roles, role.id))) || false;
	const isAnyChecked = (!isEmpty(permission) && some(permission, (o) => includes(o.permission_roles, role.id))) || false;
	return (
		<div className="accordion-table-body-row-item summary-row">
			<Checkbox
				type={isAllChecked ? "normal" : isAnyChecked ? "single" : "normal"}
				disabled={disabled}
				checked={isAllChecked || isAnyChecked}
				onChange={(e) => onChangeSummary(e, role, permission)}
			/>
		</div>
	);
};
