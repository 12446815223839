import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import common_en from "./translations/english.json";
import common_sp from "./translations/spanish.json";

const resources ={
    en:{
        translation:common_en
    },
    sp:{
        translation:common_sp
    }
}

i18n.use(initReactI18next).init({
    resources,
    lng:"en",
    fallbackLng:"en",
    keySeparator:false,
    interpolation:{
        escapeValue:false
    }
})
export default i18n;