import { requiredDateObj, required, minValue, number } from "constant/formValidators";

export const topFormFields = [
	{
		heading: "",
		fields: [
			{
				label: "label.location_required",
				name: "locations",
				type: "select-react",
				placeholder: "label.select_location",
				validate: [required],
			},
			{
				label: "label.expected_harvest_date_required",
				name: "expected_harvesting_date",
				type: "date-picker",
				placeholder: "label.enter_harvest_date",
				validate: [requiredDateObj],
				minDate: new Date(),
			},
			{
				label: "label.product_variety_required",
				name: "product_varieties",
				type: "select-react",
				placeholder: "label.select_product_variety",
				validate: [required],
			},
		],
	},
];

export const harvestingBoardForm = {
	pond: {
		label: "label.pond_required",
		name: "pond",
		type: "select-react",
		placeholder: "label.select_pond",
		validate: [required],
	},
	lane: {
		label: "label.lane_number_required",
		name: "lane",
		type: "select-react",
		placeholder: "label.select_lane_number",
		validate: [required],
	},
	flag_color: {
		label: "label.flag_color_required",
		name: "flag_color",
		type: "select-react",
		placeholder: "label.enter_flag_color",
		validate: [required],
	},
	board_count: {
		label: "label.no_of_boards_required",
		name: "board_count",
		type: "text",
		placeholder: "label.enter_no_of_boards",
		validate: [required, minValue(1), number],
	},
};

export const bottomFormFields = [
	{
		heading: "",
		fields: [
			{
				label: "label.blade_height_range_required",
				name: "harvesting_blade_height",
				type: "text",
				placeholder: "label.enter_blade_height",
				validate: [number, required],
			},
			{
				label: "label.root_puller_height_required",
				name: "root_puller_height",
				type: "text",
				placeholder: "label.enter_root_puller_height",
				validate: [required, number],
			},
			{
				label: "label.forcast_oz_per_board_required",
				name: "forecast_oz_per_board",
				type: "text",
				placeholder: "label.enter_forcast_oz_per_board",
				validate: [required, number],
			},
			{
				label: "label.forcast_no_of_boards_required",
				name: "forecast_no_of_boards",
				type: "text",
				placeholder: "label.enter_forcast_no_of_boards",
				validate: [required, number],
			},
			{
				label: "label.forcast_harvest_lbs_required",
				name: "forecast_harvest_weight",
				type: "text",
				placeholder: "",
				validate: [required, number],
			},
			{
				label: "label.assignee",
				name: "assignee_id",
				type: "select-react",
				placeholder: "label.select_assignee",
			},
			{
				label: "label.task_description",
				name: "harvesting_task_description",
				type: "textarea",
				placeholder: "label.enter_description",
			},
		],
	},
];

export const viewBoardForm = [
	{
		label: "label.pond",
		name: "pond",
	},
	{
		label: "label.lane_number",
		name: "lane",
	},
	{
		label: "label.flag_color",
		name: "flag_color",
	},
	{
		label: "label.no_of_boards",
		name: "board_count",
	},
];

export const cleaningTimeForm = [
	{
		label: "label.location_required",
		name: "location_slug",
		type: "select-react",
		placeholder: "label.select_location",
		validate: [required],
	},
	{
		label: "label.date_required",
		name: "cleaning_date",
		type: "date-picker",
		placeholder: "label.enter_date",
		validate: [requiredDateObj],
		minDate: new Date(),
	},
	{
		label: "label.harvest_time",
		name: "log_instance",
		type: "select-react",
		placeholder: "label.select_harvest_time",
		validate: [required],
	},
	{
		label: "label.cleaning_time_required",
		name: "cleaning_time_in_minutes",
		type: "text",
		placeholder: "label.enter_cleaning_time_in_minutes",
		validate: [required, number, minValue(0)],
	},
];

export const setUpTimeForm = [
	{
		label: "label.location_required",
		name: "location_slug",
		type: "select-react",
		placeholder: "label.select_location",
		validate: [required],
	},
	{
		label: "label.date_required",
		name: "set_up_date",
		type: "date-picker",
		placeholder: "label.enter_date",
		validate: [requiredDateObj],
		minDate: new Date(),
	},
	{
		label: "label.set_up_time_required",
		name: "set_up_time_in_minutes",
		type: "text",
		placeholder: "label.enter_setup_time_in_minutes",
		validate: [required, number, minValue(0)],
	},
];
