import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Field, reduxForm, getFormValues } from "redux-form";
import { Link } from "react-router-dom";

import { sendResetPasswordCredentials, updateLoginCredentials, toggleMainLoader } from "../../store/actions";

import FormGroup from "../../utils/formGroup";
import { required, passwordStrength } from "../../constant/formValidators";
import { errorMessage } from "../../utils/errorMessage";
import { successMessage } from "../../utils/successMessage";
import { checkTokenValidity } from "../../store/actions/authActions";

const ResetPassword = ({
	set,
	handleSubmit,
	formValues,
	sendResetPasswordCredentials,
	match,
	history,
	toggleMainLoader,
	checkTokenValidity,
}) => {
	const [isTokenValid, setIsTokenValid] = useState(false);
	const [pageLoad, setPageLoad] = useState(false);
	const [error, setError] = useState(null);

	const token = match && match.params ? match.params.token : null;

	const doCheckTokenValidity = () => {
		const successHandler = (e) => {
			setPageLoad(false);
			setIsTokenValid(true);
		};
		const errorHandler = (event) => {
			setPageLoad(false);
			errorMessage(event);
			setIsTokenValid(false);
		};
		setPageLoad(true);
		checkTokenValidity({ token }, successHandler, errorHandler);
	};

	const submitForm = (values) => {
		toggleMainLoader(true);
		const { password, password_confirmation } = values;
		const successHandler = (e) => {
			successMessage(e);
			history.push("/signin");
			toggleMainLoader(false);
		};
		const errorHandler = (event) => {
			errorMessage(event);
			toggleMainLoader(false);
		};
		const notMatching = () => {
			errorMessage("PASSWORD_NOT_MATCH");
			toggleMainLoader(false);
		};
		password && password_confirmation && password === password_confirmation && token
			? sendResetPasswordCredentials({ ...values, token }, successHandler, errorHandler)
			: notMatching();
	};

	useEffect(doCheckTokenValidity, []);

	return (
		<div className="sign-in-wrap">
			<div className="sign-in-container">
				{isTokenValid ? (
					<form className="reset-password-form" onSubmit={handleSubmit(submitForm)}>
						<h4 className="mb-3">{set ? "Set" : "Reset"} Password</h4>
						<Field
							name="password"
							label="Password"
							type="password"
							component={FormGroup}
							onChange={() => setError(null)}
							validate={[required, passwordStrength]}
							info="Password should contain 1 number, 1 capital letter and 1 special character and should be 8 or more characters."
						/>
						<Field
							name="password_confirmation"
							showPassword={false}
							matchWith={{ formValues, field: "password" }}
							onChange={() => setError(null)}
							label="Confirm Password"
							type="password"
							component={FormGroup}
							validate={[required]}
						/>
						<div className="d-flex flex-row-reverse align-items-center mt-4">
							<button className="btn btn-primary" type="submit">
								Submit
							</button>
							<Link className="mr-3" to="/signin">
								Login
							</Link>
						</div>
						{error && <div className="error small text-danger text-center mt-3">{error}</div>}
					</form>
				) : pageLoad ? (
					<h2>Please wait...</h2>
				) : (
					<h2>
						Link expired. Go to <Link to="/signin">Sign in</Link>
					</h2>
				)}
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	formValues: getFormValues("ResetPasswordForm")(state),
});

const mapDispatchToProps = {
	sendResetPasswordCredentials,
	updateLoginCredentials,
	toggleMainLoader,
	checkTokenValidity,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(
	reduxForm({
		form: "ResetPasswordForm",
	})(ResetPassword)
);
