import React, { useRef, useState, useEffect } from "react";
import IdleTimer from "react-idle-timer";
import { errorMessage } from "../../utils/errorMessage";
import { successMessage } from "../../utils/successMessage";
import { withRouter } from "react-router-dom";
import { onLogout } from "store/actions";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";

const LSVariable = process.env.REACT_APP_LS_VAR;

const IdleTimerContainer = ({ history, onLogout }) => {
	const intervalRef = useRef();
	const idleTimerRef = useRef(null);
	const sessionTimeOutRef = useRef(false);

	const getLSVariable = localStorage.getItem(LSVariable);
	const userData = getLSVariable ? JSON.parse(getLSVariable) : null;

	const timer = 60;
	const [show, setShow] = useState(false);
	const [count, setCount] = useState(timer);

	const onIdleHandler = () => {
		setShow(true);
		setCount(timer);
		sessionTimeOutRef.current = setTimeout(logout, timer * 1000);
	};
	const onActionHandler = (e) => {
		clearTimeout(sessionTimeOutRef.current);
		setShow(false);
	};

	const decreaseNum = () => setCount((prev) => prev - 1);

	const logout = () => {
		setShow(false);
		clearTimeout(sessionTimeOutRef.current);
		const successHandler = (e) => {
			localStorage.removeItem(LSVariable);
			history.push("/signin");
			successMessage(e);
		};
		const errorHandler = (e) => {
			errorMessage(e);
		};
		onLogout(successHandler, errorHandler);
	};

	useEffect(() => {
		intervalRef.current = setInterval(decreaseNum, 1000);
		return () => clearInterval(intervalRef.current);
	}, []);

	return (
		<div>
			{userData && (
				<>
					<IdleTimer ref={idleTimerRef} timeout={30 * 60 * 1000} onAction={onActionHandler} onIdle={onIdleHandler} />
					<Modal size="lg" centered={true} show={show} className="modal-confirm">
						<div>
							<center>
								<strong>Hey!</strong> you will be logged off in <strong className="time"> {count} </strong> seconds
								due to inactivity.
							</center>
						</div>
					</Modal>
				</>
			)}
		</div>
	);
};
export default withRouter(connect(null, { onLogout })(IdleTimerContainer));
