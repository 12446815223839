import React from "react";
import { Col, Row } from "react-bootstrap";

export const getCustomTotal = (data, type) => {
	return (
		<Row className="forecast-table-body-row">
			{data.map(
				(item, ind) => (
					(
						<Col key={ind} className="forecast-table-col">
							<span className="production-value">
								{item.total || item.total === 0
									? Number.isInteger(item.total)
										? item.total.toLocaleString("en-IN")
										: item.total.toLocaleString("en-IN")
									: "-"}
							</span>
						</Col>
					)
				)
			)}
		</Row>
	);
};
