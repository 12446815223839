import React from "react";
import { Button, Row, Col, Form } from "react-bootstrap";
import { connect, useDispatch } from "react-redux";
import { reduxForm, Field, change } from "redux-form";
import { createStructuredSelector } from "reselect";
import {
	getLocationList,
	getNutrientList,
	getProductVarietyList,
	getRenderType,
	getSeasonList,
} from "store/selectors/croptimalsSelectors";
import { errorMessage } from "utils/errorMessage";

import FormGroups from "utils/formGroup";
import { croptimalsFormFields } from "./formHelper";

import {
	setLoaderCroptimals,
	fetchCroptimalsData,
	setCroptimalsColumns,
	setCroptimalsData,
	setProductVarietyMapping,
} from "store/actions";

const CroptimalsForm = ({
	handleSubmit,
	reset,
	submitting,
	pristine,
	locationList,
	pvList,
	seasonList,
	setLoaderCroptimals,
	fetchCroptimalsData,
	renderType,
	nutrientList,
	setCroptimalsColumns,
	setCroptimalsData,
	setProductVarietyMapping,
}) => {
	const dispatch = useDispatch();

	const onSubmit = (values) => {
		if (renderType === 0) {
			setLoaderCroptimals();
			const submitData = {
				location_slug: values.location_slug.value,
				season_name: values.season.value,
				product_variety_slug: values.product_variety.map((item) => item.value),
			};

			const successHandler = () => {
				setLoaderCroptimals();
				setCroptimalsColumns(nutrientList);

				// setForecastColumns(values.product_variety);
			};
			const errorHandler = (e) => {
				errorMessage(e);
				setLoaderCroptimals();
			};
			fetchCroptimalsData(submitData, successHandler, errorHandler);
		} else {
			const data = {};
			// eslint-disable-next-line array-callback-return
			values.product_variety.map((item) => {
				data[item.value] = nutrientList.map((p_item) => ({
					name: p_item.nutrient,
					slug: p_item.slug,
					value: "",
				}));
			});
			const productKeyMapping = values.product_variety.map((item) => ({
				name: item.label,
				slug: item.value,
			}));
			setProductVarietyMapping(productKeyMapping);
			setCroptimalsColumns(nutrientList);
			setCroptimalsData(data);
		}
	};

	const getOptions = (name) => {
		switch (name) {
			case "location_slug":
				return locationList;

			case "product_variety":
				return pvList;

			case "season":
				return seasonList;

			default:
				return [];
		}
	};

	const onSelect = (event, newValue, previousValue, name) => {
		if (name === "location_slug") {
			if (previousValue !== undefined) {
				dispatch(change("croptimalsForm", "product_variety", []));
			}
		}
	};

	const getFormFieldHtml = (formfields) => {
		let formFieldHtml = [];

		formFieldHtml = formfields.map((field, idx) => {
			return (
				<React.Fragment key={idx}>
					<Col className="croptimals-form-column">
						<Form.Group
							// className={field.name === "exclude_wastage" && "mt-5"}
							controlId={`formControl ${field.name}`}>
							<Field
								name={field.name}
								type={field.type}
								label={field.label}
								placeholder={field.placeholder}
								component={FormGroups}
								validate={field.validate}
								multiple={field.name === "product_variety" ? true : false}
								options={getOptions(field.name)}
								onChange={(event, newValue, previousValue, name) =>
									onSelect(event, newValue, previousValue, name)
								}
								selectMenuHeight={200}
							/>
						</Form.Group>
					</Col>
				</React.Fragment>
			);
		});

		return (
			<Row className="croptimals-form-row">
				{formFieldHtml}
				<Col className="form-submit-col-croptimals">
					<Button type="submit" disabled={submitting} className="apply-btn">
						Apply
					</Button>
					<Button
						className="reset-btn"
						disabled={pristine || submitting}
						onClick={() => {
							reset();
						}}>
						<i className="icon icon-reset mr-2" />
					</Button>
				</Col>
			</Row>
		);
	};

	return (
		<div className="croptimals-form-wrapper">
			<Form onSubmit={handleSubmit(onSubmit)} className="forecastForm">
				{getFormFieldHtml(croptimalsFormFields)}
			</Form>
		</div>
	);
};

const mapStateToProps = createStructuredSelector({
	locationList: getLocationList,
	pvList: getProductVarietyList,
	seasonList: getSeasonList,
	renderType: getRenderType,
	nutrientList: getNutrientList,
});

export default connect(mapStateToProps, {
	setLoaderCroptimals,
	fetchCroptimalsData,
	setCroptimalsColumns,
	setCroptimalsData,
	setProductVarietyMapping,
})(reduxForm({ form: "croptimalsForm", enableReinitialize: true })(CroptimalsForm));
