import React, { useState } from "react";
import { Button, Row, Col, Form } from "react-bootstrap";
import { reduxForm, Field, change } from "redux-form";
import { forecastFormFields, metricDropDown } from "./formHelper";
import { connect, useDispatch } from "react-redux";
import { createStructuredSelector } from "reselect";
import { errorMessage } from "../../../utils/errorMessage";
import { successMessage } from "../../../utils/successMessage";
import { ButtonPrimary } from "../../../elements";
import {
	getLocationList,
	getCommodityList,
	getAccountsIds,
	getWatchWireForm,
	getDownload,
	getSubmittedObj,
} from "../../../store/selectors/watchWireSelectors";
import { setLoaderWW, fetchWatchWireInvoiceData, resetFieldsWatchWire, fetchExportFunction,setColumns } from "store/actions";
import FormGroups from "utils/formGroup";
import Util from "utils/Util";
const WatchWireForm = ({
	locationList,
	commodityList,
	accountsIds,
	submitting,
	handleSubmit,
	reset,
	resetFieldsWatchWire,
	fetchWatchWireInvoiceData,
	setLoaderWW,
	fetchExportFunction,
	watchFireForm,
	downloadUrl,
	submittedObj,
	setColumns
}) => {
	const dispatch = useDispatch();
	const [startDate, setStartDate] = useState(null);
	const [endDate, setEndDate] = useState(null);
	const onSubmit = (values) => {
		setLoaderWW();
		const submitData = {
			location_slug: values.location_slug.value,
			start_date: Util.getYMDFormat(startDate),
			end_date: Util.getYMDFormat(endDate),
			commodity: values.commodity.map((item) => item.value),
			account_code: values.accounts_ids.map((item) => item.value),
			metric: values.metric.map((item) => item.value),
		};
		const successHandler = (e) => {
			successMessage(e);
			setLoaderWW();
		};
		const errorHandler = (e) => {
			setLoaderWW();
			errorMessage(e);
		};
		setColumns(values.metric);
		fetchWatchWireInvoiceData(submitData, successHandler, errorHandler);
	};

	const getOptions = (name) => {
		switch (name) {
			case "location_slug":
				return locationList;
			case "commodity":
				return commodityList;
			case "accounts_ids":
				return accountsIds;
			case "metric":
				return metricDropDown;
			default:
				return [];
		}
	};
	const onSelect = (event, newValue, previousValue, field) => {
		if (field === "location_slug") {
			dispatch(change("watchWireForm", "commodity", []));
			dispatch(change("watchWireForm", "accounts_ids", []));
		}else if(field === "commodity"){
			dispatch(change("watchWireForm", "accounts_ids", []));
		}
	};

	const onChange = (dates) => {
		const [start, end] = dates;
		setStartDate(start);
		setEndDate(end);
	};

	const exportHandler = () => {
		setLoaderWW();
		const values = watchFireForm.values;
		const submitData = {
			location_slug: values.location_slug.value,
			start_date: Util.getYMDFormat(startDate),
			end_date: Util.getYMDFormat(endDate),
			commodity: values.commodity.map((item) => item.value),
			account_code: values.accounts_ids.map((item) => item.value),
			metric: values.metric.map((item) => item.value),
		};

		const successHandler = (e) => {
			e.data.export_url && window.open(e.data.export_url);
			// successMessage(e);
			setLoaderWW();
		};
		const errorHandler = (e) => {
			setLoaderWW();
			errorMessage(e);
		};
		fetchExportFunction(submitData, successHandler, errorHandler);
	};
	const getFormFieldHtml = (formfields) => {
		let formFieldHtml = [];
		formFieldHtml = formfields.map((field, idx) => {
			return (
				<Col
					key={idx}
					className={
						field.type === "date-range-picker"
							? "forecast-form-column"
							: field.name === "metric"
							? "forecast-form-column"
							: "col-xl-2"
					}>
					<Form.Group
						className="watchDatepicker"
						controlId={`formControl ${field.name}`}>
						{field.type === "date-range-picker" ? (
							<Field
								name={field.name}
								type={field.type}
								label={field.label}
								placeholder={field.placeholder}
								component={FormGroups}
								startDate={startDate}
								endDate={endDate}
								onChange={onChange}
								validate={field.validate}
								pickerTypeSelector={1}
								//maxDate={fromDate && !toDate && maxDate}
								onBlur={(event) => event.preventDefault()}
							/>
						) : (
							<Field
								name={field.name}
								type={field.type}
								label={field.label}
								placeholder={field.placeholder}
								component={FormGroups}
								validate={field.validate}
								onBlur={(event) => event.preventDefault()}
								options={getOptions(field.name)}
								onChange={(event, newValue, previousValue, name) =>
									onSelect(event, newValue, previousValue, name)
								}
								multiple={field.name === "location_slug" ? false : true}
								selectMenuHeight={200}
							/>
						)}
					</Form.Group>
				</Col>
			);
		});
		return (
			<React.Fragment>
				<Row className="forecast-form-row">
					{formFieldHtml}
					<Col className="form-submit-col watchWireWidth">
						<Button type="submit" disabled={submitting} className="apply-btn">
							Apply
						</Button>
						<ButtonPrimary type="submit" disabled={submittedObj ? false : true} onClick={exportHandler}>
							<span>
								Export{" "}
								<i className="icon icon-export text-sm" style={{fontWeight:"bold"}}/>
							</span>
						</ButtonPrimary>
						<Button
							className="reset-btn"
							disabled={submitting}
							onClick={() => {
								setStartDate(null);
								setEndDate(null);
								reset();
								resetFieldsWatchWire();
							}}>
							<i className="icon icon-reset mr-2" />
						</Button>
					</Col>
				</Row>
			</React.Fragment>
		);
	};

	return (
		<div className="forecast-form-wrapper">
			<Form onSubmit={handleSubmit(onSubmit)} className="watchwireForm">
				{getFormFieldHtml(forecastFormFields)}
			</Form>
		</div>
	);
};

const mapStateToProps = createStructuredSelector({
	locationList: getLocationList,
	commodityList: getCommodityList,
	accountsIds: getAccountsIds,
	watchFireForm: getWatchWireForm,
	downloadUrl: getDownload,
	submittedObj: getSubmittedObj,
});

export default connect(mapStateToProps, {
	setLoaderWW,
	fetchWatchWireInvoiceData,
	resetFieldsWatchWire,
	fetchExportFunction,
	setColumns
})(reduxForm({ form: "watchWireForm", enableReinitialize: true })(WatchWireForm));
