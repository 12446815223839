import React, { useState } from "react";
import { connect, useSelector } from "react-redux";
import {
	BasicDetails,
	TransplantDetails,
	TaskStatusBar,
	PondMetrics,
	PondDetails,
	TransplantProductInformation,
} from "./viewComponents";
import {
	startTransplantTask,
	toggleViewLoader,
	fetchTransplantTaskDetails,
	completeTransplantTask,
	setTransplantViewRender,
	getAuditLogOfTransplantTask,
} from "../../../store/actions";
import useModal from "../../../utils/useModal";
import TaskManage from "../taskDetails";
import TableLoader from "../../../utils/table/tableLoader";
import { errorMessage } from "../../../utils/errorMessage";
import { successMessage } from "../../../utils/successMessage";
import { Breadcrumbs, BackButton } from "../../../elements";
import AuditLog from "../../auditLog";
import Util from "utils/Util";
import { useTranslation } from 'react-i18next';


const TransplantView = ({
	startTransplantTask,
	fetchTransplantTaskDetails,
	completeTransplantTask,
	setTransplantViewRender,
	toggleViewLoader,
	getAuditLogOfTransplantTask,
	acitivityLog,
	permissionDetails,
	transplantActive,
}) => {
	const { t } = useTranslation();
	const [, , showModal] = useModal("transplant_details");
	const [fetchingLog, setFetchingLog] = useState(false);
	const [isLoading, setisLoading] = useState(false);
	const [startTaskBtn, setstartTaskBtn] = useState(t("label.startButton"));
	const [completeTaskBtn, setcompleteTaskBtn] = useState(t("label.complete_task"));
	const [showActivityLog, setShowActivityLog] = useState(true);
	const transplantDetails = useSelector((state) => state.Transplant.transplantTaskDetails);
	const auditLogPermission =
		permissionDetails && permissionDetails.transplant && permissionDetails.transplant.permissions.view_transplant_audit_log
			? permissionDetails.transplant.permissions.view_transplant_audit_log
			: "";
	const logPermission =
		permissionDetails && permissionDetails.transplant && permissionDetails.transplant.permissions.transplanting_log_details
			? permissionDetails.transplant.permissions.transplanting_log_details
			: "";

	const seasonDetails =
		(transplantDetails && transplantDetails.product_information && transplantDetails.product_information) || null;

	const getType =
		transplantDetails.status === "New"
			? 1
			: transplantDetails.status === "In Progress" && transplantDetails.transplant_and_growing_task_transplant_details
			? 3
			: transplantDetails.status === "Completed"
			? 4
			: 2;
	// Task Start Time's to show
	const getCorrespondingTaskTime =
		transplantDetails.status === "New"
			? null
			: transplantDetails.status === "In Progress" && transplantDetails.transplant_and_growing_task_transplant_details
			? transplantDetails && Util.utcToLocal(transplantDetails.transplant_start_date)
			: transplantDetails.status === "Completed"
			? null
			: transplantDetails && Util.utcToLocal(transplantDetails.transplant_start_date);

	// Common error handler
	const errorHandler = (err) => {
		errorMessage(err);
		toggleViewLoader(false);
		setisLoading(false);
		setstartTaskBtn(t("label.startButton"));
		setcompleteTaskBtn(t("label.complete_task"));
	};

	// Action : Footer button clicks
	const onClickStatusAction = (currentStatus) => {
		if (currentStatus === 1) {
			if (!isLoading) {
				setisLoading(true);
				setstartTaskBtn(t("label.startingButton"));
				toggleViewLoader(true);
				const successHandler = (data) => {
					setisLoading(false);
					successMessage(data);
					doFetchTaskDetails();
					fetchAuditLog();
				};
				startTransplantTask(transplantDetails.task_id, successHandler, errorHandler);
			}
		}

		if (currentStatus === 2) {
			showModal(1);
		}

		if (currentStatus === 3) {
			if (!isLoading) {
				setisLoading(true);
				setcompleteTaskBtn(t("label.completing"));
				toggleViewLoader(true);
				const successHandler = (data) => {
					setisLoading(false);
					successMessage(data);
					doFetchTaskDetails();
				};
				completeTransplantTask(transplantDetails.task_id, successHandler, errorHandler);
			}
		}

		// Show activity log
		if (currentStatus === 5) {
			showActivityLog && fetchAuditLog();
			setShowActivityLog(!showActivityLog);
		}
	};

	// Action :  Edit button clicks
	const onClickEditAction = (editType) => {
		if (editType === 2) {
			showModal(2);
		}
		if (editType === 4) {
			const pondDetails = transplantDetails.transplant_and_growing_task_transplant_details.transplant_details.map(
				(item) => ({
					pond_number: item.pond_number,
					lane_number: item.lane_number,
				})
			);
			showModal(4, pondDetails);
		}
	};

	// View
	const doFetchTaskDetails = () => {
		toggleViewLoader(true);
		const disableLoader = () => toggleViewLoader(false);
		fetchTransplantTaskDetails(transplantDetails.task_id, disableLoader, disableLoader);
	};
	// Fetch Log
	const fetchAuditLog = () => {
		setFetchingLog(true);
		const onAuditSuccess = () => {
			setFetchingLog(false);
		};
		const onAuditError = (err) => {
			errorMessage(err);
			setFetchingLog(false);
		};
		getAuditLogOfTransplantTask(transplantDetails.task_id, onAuditSuccess, onAuditError);
	};
	return (
		<>
			<div className="d-flex justify-content-between">
				<div className="d-flex justify-content-between mb-3">
					<BackButton onClick={() => setTransplantViewRender(0)} />
					<Breadcrumbs keyValue="transplantGrowing" showLabel={true} label={t("label.view")} />
				</div>
			</div>
			<div className="sg-view-wrapper position-relative transplant-view-wrapper">
				<div className="position-relative">
					<BasicDetails logPermission={logPermission} taskDetails={transplantDetails} seasonDetails={seasonDetails} />

					{/* {transplantDetails.pond_metrics ? <ClimateDetails taskDetails={transplantDetails} /> : ""} */}
					{transplantDetails.pond_metrics ? <PondMetrics taskDetails={transplantDetails} /> : ""}
					{transplantDetails.pond_view ? <PondDetails taskDetails={transplantDetails} /> : ""}
					{/* Transplant Details */}
					{getType > 1 && transplantDetails.transplant_and_growing_task_transplant_details && (
						<TransplantDetails
							logPermission={logPermission}
							taskDetails={transplantDetails}
							onClickEditAction={onClickEditAction}
							transplantActive={transplantActive}
						/>
					)}

					{/* Product Information */}
					<TransplantProductInformation taskDetails={transplantDetails} seasonDetails={seasonDetails} />

					{/* Loader */}
					{isLoading && (
						<div className="table-loader-wrapper">
							<TableLoader />
						</div>
					)}
				</div>
				{/* Status Buttons */}
				<TaskStatusBar
					onClickStatusAction={onClickStatusAction}
					startTaskBtn={startTaskBtn}
					completeTaskBtn={completeTaskBtn}
					type={getType}
					tastStartTime={getCorrespondingTaskTime}
					logPermission={logPermission}
					transplantActive={transplantActive}
				/>
				<TaskManage
					logPermission={logPermission}
					taskDetails={transplantDetails}
					doFetchTaskDetails={doFetchTaskDetails}
				/>
				{/* Activity Log */}
				{auditLogPermission && (
					<AuditLog log={acitivityLog} loading={fetchingLog} onClickStatusAction={onClickStatusAction} />
				)}
			</div>
		</>
	);
};
const mapStateToProps = (state) => {
	return {
		acitivityLog: state.Transplant.acitivityLog,
		permissionDetails: state.Auth.permissionDetails,
		transplantActive: state?.Transplant?.transplantTaskDetails?.product_variety?.active,
	};
};
const mapDispatchToProps = {
	startTransplantTask,
	toggleViewLoader,
	fetchTransplantTaskDetails,
	completeTransplantTask,
	setTransplantViewRender,
	getAuditLogOfTransplantTask,
};

export default connect(mapStateToProps, mapDispatchToProps)(TransplantView);
