import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { isEmpty, reject, filter, map, includes, find } from "lodash";
import Util from "../../utils/Util";
import { Button } from "react-bootstrap";
import { ContentWrap, ButtonPrimary, Breadcrumbs, BackButton } from "../../elements";
import useModal from "../../utils/useModal";
import SeedingGerminationListing from "./listing";
import SGDailyTaskListing from "./listDailyTask";
import SeedingGerminationManage from "./manage";
import {
	getSeedingGerminationTaskDetails,
	getRepeatSeedingGerminationTaskDetails,
	toggleListLoader,
	toggleViewLoader,
	fetchLocationUsers,
	changeSeedingGerminationLocation,
	getSGMediaUsage,
} from "../../store/actions";
import "./seedingGermination.scss";
import SeedingGerminationView from "./view";
import DailyTasks from "./dailyTasks";
import { errorMessage } from "../../utils/errorMessage";
import { DailyUsageDropdown } from "./seedingGerminationHelper";
import BulkAssignee from "components/bulkAssignee";
import RepeatAllTasks from "components/repeatAllTasks";
import { AssigneeButton, LocationDropdown } from "components/bulkAssignee/bulkAssigneeComponents";
import { useTranslation } from "react-i18next";


// Seed Germination Task Modal
//     0 - Add
//     1 - Edit
//     2 - Repeat

// Daily Tasks Modal
//     1 - Media
//     2 - Setup
//     3 - Cleaning

const SeedingGermination = ({
	getSeedingGerminationTaskDetails,
	getSGMediaUsage,
	getRepeatSeedingGerminationTaskDetails,
	fetchLocationUsers,
	locationBasedDetails,
	selectedTaskDetails,
	userDetails,
	seedingGerminationList,
	viewPageLoader,
	toggleViewLoader,
	listingPageLoader,
	toggleListLoader,
	permissionDetails,
	changeSeedingGerminationLocation
}) => {
	const { t } = useTranslation();
	const [, , showModal] = useModal("seedingGerminationTask");
	const [, , openBulkModal] = useModal("bulkAssignee");
	const [, , openTasksModal] = useModal("dailyTasks");
	const [, , openRepeatAllModal] = useModal("repeatAllTasks");

	const getTodaysDate = Util.getYMDFormat(new Date());
	const [listFilter, setListFilter] = useState([{ column_name: "expected_seeding_date", search: getTodaysDate }]);
	const [mediaUsageFilter, setMediaUsageFilter] = useState([]);
	const [bulkAssigneeList, setBulkAssigneeList] = useState([]);
	const [filterLocation, setFilterLocation] = useState(null);
	const [isShowViewPage, setIsShowViewPage] = useState(false);
	const [isShowListDailyTask, setIsShowListDailyTask] = useState(false);
	const [dropdownValuesObject, setDropdownValuesObject] = useState({});
	const [refetch, setRefetch] = useState(0);
	const [task, setTask] = useState("");
	const [disableRepeatTasks, setDisableRepeatTasks] = useState(true);
	const selectedLocation = useSelector((state) => state.SeedingGermination.selectedLocation);
	const [exportFlag,setExportFlag] = useState(null);

	// Formatted location array
	const locations = Util.mapOptions(userDetails && userDetails.location_slugs, "name", "slug") || [];

	useEffect(() => {
		if (isEmpty(bulkAssigneeList)) {
			setDisableRepeatTasks(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [bulkAssigneeList]);
	// Common error handler
	const errorHandler = (event) => {
		errorMessage(event);
		toggleListLoader(false);
		toggleViewLoader(false);
	};

	// Add
	const handleAddPanel = () => {
		selectedLocation && fetchDropdownArray(selectedLocation.value);
		showModal(0);
	};

	// Edit/Repeat
	const handleEditPanel = (data, action) => {
		const mode = data ? (action === "REPEAT" ? 2 : 1) : 0;
		const openModal = (response) => {
			const resp = (response && response.data) || {};
			fetchDropdownArray(resp && resp.location && resp.location.slug);
			showModal(mode, resp);
			toggleListLoader(false);
			toggleViewLoader(false);
		};
		if (mode === 1 && data.status === "Completed") {
			errorMessage("SG_NOT_ALLOWED_TO_EDIT");
		} else {
			toggleListLoader(true);
			toggleViewLoader(true);
			mode === 2
				? getRepeatSeedingGerminationTaskDetails(data.slug, openModal, errorHandler)
				: getSeedingGerminationTaskDetails(data.slug, openModal, errorHandler);
		}
	};
	// Edit Media Usage

	const handleEditPanelDailyTask = (data) => {
		if (task === "media_usage") {
			openTasksModal(1, data);
		} else if (task === "set_up_time") {
			openTasksModal(2, data);
		} else if (task === "cleaning_time") {
			openTasksModal(3, data);
		}
	};

	// View
	const handleViewPanel = (data) => {
		toggleListLoader(true);
		const openModal = () => {
			toggleListLoader(false);
			setIsShowViewPage(true);
		};
		getSeedingGerminationTaskDetails(data.slug, openModal, errorHandler);
	};

	// ADD/EDIT/REPEAT : Update the dropdown array - corresponding to location selected
	const fetchDropdownArray = (location) => {
		const locObj = locationBasedDetails[location];
		const { product_varieties, users, drum_size, germination_chambers } = locObj || {};
		const products = Util.mapOptions(product_varieties, "product_variety_name", "slug") || [];
		const usersList = Util.mapOptions(users, "name", "id") || [];
		const drumSizeList = Util.mapOptions(drum_size, "value", "id") || [];
		const chamberList = Util.mapOptions(germination_chambers, "value", "id") || [];
		setDropdownValuesObject({ products, usersList, drumSizeList, chamberList });
	};
	const addPermission =
		permissionDetails && permissionDetails.seeding && permissionDetails.seeding.permissions.create_seeding_task
			? permissionDetails.seeding.permissions.create_seeding_task
			: "";
	const assignPermission =
		permissionDetails && permissionDetails.seeding && permissionDetails.seeding.permissions.seeding_bulk_assign_tasks
			? permissionDetails.seeding.permissions.seeding_bulk_assign_tasks
			: "";
	const dailyTaskPermission =
		permissionDetails && permissionDetails.seeding && permissionDetails.seeding.permissions.seeding_daily_task_details
			? permissionDetails.seeding.permissions.seeding_daily_task_details
			: "";

	// ----------------- DAILY TASKS ----------------- //
	const handleDailyTask = (type) => {
		// Media
		if (type === 1) {
			const intialValue = {
				location_slug: selectedLocation ? selectedLocation : "",
				media_usage_date: new Date(),
			};
			openTasksModal(type, intialValue);
		}
		// Setup
		else if (type === 2) {
			const intialValue = {
				location_slug: selectedLocation ? selectedLocation : "",
				set_up_date: new Date(),
			};
			openTasksModal(type, intialValue);
		}
		// Cleaning
		else if (type === 3) {
			const intialValue = {
				location_slug: selectedLocation ? selectedLocation : "",
				cleaning_date: new Date(),
			};
			openTasksModal(type, intialValue);
		}

		//List Media usage
		else if (type === 4) {
			setIsShowListDailyTask(true);
			setTask("media_usage");
		}
		//List Set Up time
		else if (type === 5) {
			setIsShowListDailyTask(true);
			setTask("set_up_time");
		}
		//List Cleaning time
		else if (type === 6) {
			setIsShowListDailyTask(true);
			setTask("cleaning_time");
		}
	};

	// View
	const doFetchTaskDetails = (slug) => {
		toggleViewLoader(true);
		const disableLoader = () => toggleViewLoader(false);
		getSeedingGerminationTaskDetails(slug, disableLoader, disableLoader);
	};

	// ----------------- BULK ASSIGNEE ----------------- //
	const onSelectFilterLocation = (location = null) => {
		if (location) {
			const obj = {
				column_name: "location_slug",
				search: location.value || "",
			};
			const rejectArr = reject(listFilter, ["column_name", "location_slug"]);
			setListFilter([...rejectArr, obj]);
			setMediaUsageFilter([obj]);
		} else {
			const rejectArr = reject(listFilter, ["column_name", "location_slug"]);
			setListFilter(rejectArr);
			setMediaUsageFilter([]);
		}
		// Clear assignee list, since choosing assignee from a single location is permitted
		setBulkAssigneeList([]);

		// Set location filter
		setFilterLocation(location);
		changeSeedingGerminationLocation(location);
	};

	// Handle bulk assignee checkbox
	const onChangeCheckbox = (e, slug) => {
		if (e.target.checked) {
			setBulkAssigneeList([...bulkAssigneeList, slug]);
			setDisableRepeatTasks(false);
		} else {
			const filteredArr = filter(bulkAssigneeList, (o) => o !== slug);
			setBulkAssigneeList(filteredArr);
			if (isEmpty(filteredArr)) {
				setDisableRepeatTasks(true);
			}
		}
	};

	// Click - Each checkbox
	const onClickAssignee = () => {
		if (filterLocation && filterLocation.value) {
			toggleListLoader(true);
			const handleSuccess = () => {
				const body = {
					location_slug: filterLocation.value,
					task_key: "seeding_germination",
					slugs: bulkAssigneeList,
				};
				toggleListLoader(false);
				openBulkModal(0, body);
			};
			fetchLocationUsers(filterLocation.value, handleSuccess, errorHandler);
		}
	};

	// Click - Select All checkbox
	const onChangeAllCheckbox = (e) => {
		if (e.target.checked) {
			const valSlugs = map(seedingGerminationList, (o) => o.slug);
			setBulkAssigneeList(valSlugs);
			setDisableRepeatTasks(false);
		} else {
			setBulkAssigneeList([]);
			setDisableRepeatTasks(true);
		}
	};

	const isAllCheckboxSelected = find(seedingGerminationList, (o) => !includes(bulkAssigneeList, o.slug)) ? false : true;

	// Success handling after assignee adding
	const doSuccessHandling = () => {
		setRefetch(refetch + 1);
		setBulkAssigneeList([]);
	};

	const isLocationSelected = filterLocation && filterLocation.value ? true : false;

	const clearDailyTaskListing = () => {
		setIsShowListDailyTask(false);
		setTask("");
	};
	const handleRepeatTask = () => {
		const body = {
			task_type: "seeding",
			task_ids: bulkAssigneeList,
		};
		openRepeatAllModal(0, body);
	};
	const exportHandler = () => {
		setExportFlag(true);
	};
	const exportHandlerFlag = () => {
		setExportFlag(false);
	};


	return (
		<ContentWrap>
			<div className="d-flex mb-3">
				{/* -------------- HEADER -------------- */}
				{/* For View page */}
				{isShowViewPage ? <BackButton onClick={() => setIsShowViewPage(false)} /> : null}
				{/* For Listing Daily Task's page */}
				{isShowListDailyTask ? <BackButton onClick={() => clearDailyTaskListing()} /> : null}
				{isShowListDailyTask ? (
					<Breadcrumbs
						keyValue="seedingGermination"
						showLabel={true}
						label={task === "media_usage" ? "label.view_media_usage" : task === "set_up_time" ? "label.view_setup_time" : "label.view_cleaning_time"}
					/>
				) : (
					<Breadcrumbs
						keyValue="seedingGermination"
						showLabel={true}
						label={isShowViewPage ? "label.view" : "label.list"}
					/>
				)}

				{/* For listing page */}
				{!isShowViewPage && (
					<div className="d-flex align-items-center">
					 {task !== "media_usage" && task !== "set_up_time" && task !== "cleaning_time" &&
						<Button
							className="header-order-btn"
							variant=""
							onClick={handleRepeatTask}
							disabled={disableRepeatTasks}>
							{t("label.repeat_all_task")}
						</Button>
                      }
						{assignPermission && !isEmpty(locations) && isEmpty(bulkAssigneeList) && (
							<LocationDropdown value={filterLocation} options={locations} onSelect={onSelectFilterLocation} />
						)}
						{assignPermission && filterLocation && filterLocation.value && !isEmpty(bulkAssigneeList) && (
							<AssigneeButton onClick={onClickAssignee} />
						)}

						{dailyTaskPermission && !isShowListDailyTask && (
							<DailyUsageDropdown handleDailyTask={handleDailyTask} />
						)}
						{!isEmpty(seedingGerminationList) && task !== "media_usage" && task !== "set_up_time" && task !== "cleaning_time" &&
						<ButtonPrimary className="export_btn btn-fixed-height" onClick={exportHandler}>
							<span>
							{t("label.export")}{" "}
								<i className="icon icon-export text-sm" style={{fontWeight:"bold"}}/>
							</span>
						</ButtonPrimary>}
						{!isShowListDailyTask && addPermission ? (
							<ButtonPrimary className="btn-fixed-height" onClick={handleAddPanel}>
								<i className="icon icon-plus mr-2" /> {t("label.add_task")}
							</ButtonPrimary>
						) : (
							""
						)}
					</div>
				)}
			</div>

			{/* -------------- BODY -------------- */}
			{isShowListDailyTask ? (
				// Listing Media Usage
				<SGDailyTaskListing
					filter={mediaUsageFilter}
					task={task}
					listingPageLoader={listingPageLoader}
					refetch={refetch}
					setRefetch={setRefetch}
					handleEditPanel={handleEditPanelDailyTask}
				/>
			) : isShowViewPage ? (
				// View
				<SeedingGerminationView taskDetails={selectedTaskDetails} viewPageLoader={viewPageLoader} />
			) : (
				// Listing
				<SeedingGerminationListing
					filter={listFilter}
					setFilter={setListFilter}
					assigneeList={bulkAssigneeList}
					onChangeCheckbox={onChangeCheckbox}
					showAllCheckbox={!isEmpty(seedingGerminationList)}
					isLocationSelected={isLocationSelected}
					isAllCheckboxSelected={isAllCheckboxSelected}
					onChangeAllCheckbox={onChangeAllCheckbox}
					listingPageLoader={listingPageLoader}
					refetch={refetch}
					setRefetch={setRefetch}
					handleEditPanel={handleEditPanel}
					handleViewPanel={handleViewPanel}
					exportFlag={exportFlag}
					exportHandlerFlag={()=>{exportHandlerFlag()}}
				/>
			)}

			{/* Add/Edit/Repeat Tasks Modal */}
			<SeedingGerminationManage
				dropdownValuesObject={dropdownValuesObject}
				fetchDropdownArray={fetchDropdownArray}
				doRefetch={() => setRefetch(refetch + 1)}
				doTaskRefetch={doFetchTaskDetails}
			/>

			{/* Daily Tasks Modal */}
			<DailyTasks refetch={isShowListDailyTask && refetch} setRefetch={isShowListDailyTask && setRefetch} />

			{/* Bulk Assignee Modal */}
			<BulkAssignee doSuccessHandling={doSuccessHandling} />
			<RepeatAllTasks doSuccessHandling={doSuccessHandling} />
		</ContentWrap>
	);
};

const mapStateToProps = (state) => ({
	userDetails: state.Auth.profileDetails,
	listingPageLoader: state.SeedingGermination.listingPageLoader,
	viewPageLoader: state.SeedingGermination.viewPageLoader,
	locationBasedDetails: state.SeedingGermination.locationBasedDetails,
	selectedTaskDetails: state.SeedingGermination.sgTaskDetails,
	seedingGerminationList: state.SeedingGermination.seedingGerminationList,
	permissionDetails: state.Auth.permissionDetails,
});
const mapDispatchToProps = {
	getSeedingGerminationTaskDetails,
	getSGMediaUsage,
	getRepeatSeedingGerminationTaskDetails,
	toggleListLoader,
	toggleViewLoader,
	fetchLocationUsers,
	changeSeedingGerminationLocation
};

export default connect(mapStateToProps, mapDispatchToProps)(SeedingGermination);
