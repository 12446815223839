import { without, map } from "lodash";

const fixedLeftColProps = { key: "column-0", dataKey: "column-0", resizable: true, maxWidth: 200, width: 170, frozen: "left" };
const fixedRightColumProps = { title: "Total", width: 100, frozen: "right", resizable: true, maxWidth: 100 };
export const singleTableArray = { cases_per_lab_hour: "parameters" };
export const columnMapArray = {
	Daily: "Date",
	Monthly: "Month",
	Yearly: "Year",
};
export const getFixedColumns = (data, timeFrame, type) => {
	const fixedColumns = [
		{
			title: columnMapArray[timeFrame],
			...fixedLeftColProps,
		},
	];

	data.forEach(function (data, key) {
		fixedColumns[key + 1] = {
			key: data.slug,
			dataKey: data.slug,
			title: data.name,
			width: 200,
			align: "center",
			frozen: false,
		};
	});

	if (type === "seedrate" || type === "harvest_rate" || type === "yield_by_variety") {
		fixedRightColumProps.title = "Average";
		fixedColumns[data.length + 1] = {
			key: "average",
			dataKey: "average",
			...fixedRightColumProps,
		};
	} else {
		fixedRightColumProps.title = "Total";
		fixedColumns[data.length + 1] = {
			key: "total",
			dataKey: "total",
			...fixedRightColumProps,
		};
	}
	return fixedColumns;
};

export const getFixedColumnsSinglePlot = (data, timeFrame) => {
	const fixedColumns = [
		{
			title: columnMapArray[timeFrame],
			...fixedLeftColProps,
		},
	];

	data.forEach(function (data, key) {
		fixedColumns[key + 1] = {
			key: data,
			dataKey: data,
			title: tableParameterMapper[data],
			width: 200,
			align: "center",
			frozen: false,
		};
	});

	return fixedColumns;
};

export const getColumnKeys = (fixedColumns) => {
	return fixedColumns ? without(map(fixedColumns, "key"), "column-0", "total") : [];
};

export const tableParameterMapper = {
	cases_packed: "Cases Packed",
	lbs_packed: "Lbs Packed",
	pack_time: "Pack Time",
	employee_count: "Employee Count",
	lbs_per_lab_hour: "Lbs Per Hour",
	case_per_lab_hour: " Cases Per Labour Hour",
	total_yield: "Total Yield",
};
