import fetchHandler from "../../utils/fetchHandler";
import { BOARDS } from "../types";

export const fetchMiniBoardsList = (successHandler, errorHandler) => {
	const fetchOptions = {
		url: "board_list",
		method: "GET",
		actionType: BOARDS.GET_BOARDS_MINI_LIST,
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const getBoardsList = (body, successHandler, errorHandler) => {
	const fetchOptions = {
		url: "board_list",
		method: "POST",
		body: JSON.stringify(body),
		actionType: BOARDS.GET_BOARDS_LIST,
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const fetchBoardDetails = (id, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `boards/${id}`,
		method: "GET",
		actionType: BOARDS.FETCH_BOARD_DETAILS,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const createBoard = (
	values,
	method,
	payload,
	successHandler,
	errorHandler
) => {
	const fetchOptions = {
		url: payload ? `boards/${payload}` : `boards`,
		method: method,
		secure: true,
		actionType: BOARDS.FETCH_BOARD_DETAILS,
		body: JSON.stringify(values),
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const deleteBoard = (slug, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `boards/${slug}`,
		method: "DELETE",
		actionType: BOARDS.DELETE_BOARD,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};
