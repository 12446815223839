import React from "react";
import { ButtonLink, ListingBtn } from "../../../elements";
import { crossSupplyTaskStatus } from "constant/global";
import Util from "../../../utils/Util";

export const renderColumns = ({ handleViewPanel, handleDeletePanel, addToFilter }) => {
	return [
		{
			name: "Transfer ID",
			accessor: "transfer_id",
			className: "cs-listing-col-transferId",
			headerClassName: "cs-listing-col-transferId-header",
			sortable: false,
			render: (row) => {
				return (
					<ButtonLink className="p-0" onClick={() => handleViewPanel(row.slug)}>
						{row.transfer_id}
					</ButtonLink>
				);
			},
		},
		{
			name: "Sender",
			accessor: "sender_location",
			className: "cs-listing-col-sender",
			headerClassName: "cs-listing-col-sender-header",
			render: (row) => (row.sender_location ? row.sender_location : "-"),
			sortable: false,
		},
		{
			name: "Recipient",
			accessor: "recipient_location",
			className: "cs-listing-col-recipient",
			headerClassName: "cs-listing-col-recipient-header",
			sortable: false,
		},
		{
			name: "SKU",
			accessor: "skus",
			className: "cs-listing-col-sku",
			headerClassName: "cs-listing-col-sku-header",
			sortable: false,
		},
		{
			name: "Cases Required",
			accessor: "cases_required",
			className: "cs-listing-col-number",
			headerClassName: "cs-listing-col-number-header",
			sortable: false,
		},

		{
			name: "Status",
			accessor: "status",
			className: "cs-listing-col-status",
			headerClassName: "cs-listing-col-status-header",
			sortable: false,
			options: crossSupplyTaskStatus,
			filter: {
				type: "select",
				options: crossSupplyTaskStatus,
				onChange: addToFilter,
			},
		},
		{
			name: "Actions",
			className: "cs-listing-col-actions justify-content-end",
			headerClassName: "cs-listing-col-actions-header justify-content-end",
			render: (row) => {
				return (
					<>
						{row.delete_show && (
							<ListingBtn
								tooltipText="Delete"
								btnClass="btn btn-cell btn-light btn-sm ml-2"
								iconClass="icon icon-trash"
								onClick={() => handleDeletePanel(row)}
							/>
						)}
					</>
				);
			},
			sortable: false,
		},
	];
};

export const renderShippingColumns = (addToFilter) => {
	return [
		{
			name: "Sender",
			accessor: "sender_location",
			className: "cs-listing-col-sender",
			headerClassName: "cs-listing-col-sender-header",
			sortable: false,
		},
		{
			name: "Recipient",
			accessor: "recipient_location",
			className: "cs-listing-col-recipient",
			headerClassName: "cs-listing-col-recipient-header",
			sortable: false,
		},
		{
			name: "SKU",
			accessor: "skus",
			className: "cs-listing-col-sku",
			headerClassName: "cs-listing-col-sku-header",
			sortable: false,
		},
		{
			name: "Cases Fulfilled",
			accessor: "cases_fulfilled",
			className: "cs-listing-col-number",
			headerClassName: "cs-listing-col-number-header",
			sortable: false,
		},
		{
			name: "Ship date",
			accessor: "ship_date",
			className: "cs-listing-col-date",
			headerClassName: "cs-listing-col-date-header",
			filter: { type: "calendar", onChange: addToFilter },
			sortable: false,
			render: (row) => {
				return Util.formatMDY(row.ship_date);
			},
		},
		{
			name: "Delivery date",
			accessor: "delivery_date",
			className: "cs-listing-col-date",
			headerClassName: "cs-listing-col-date-header",
			sortable: false,
			render: (row) => {
				return Util.formatMDY(row.delivery_date);
			},
		},
	];
};
