import React, { useState } from "react";
import { connect } from "react-redux";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import arrayMove from "array-move";
import { Modal, Button, Row, Col } from "react-bootstrap";
import { errorMessage } from "../../../utils/errorMessage";
import { successMessage } from "../../../utils/successMessage";
import { updatePriority, toggleMainLoader } from "../../../store/actions";
import { sortFields } from "./helperFile";

const LocationSort = ({ details, closeModal, locationSlug, doRefetch, toggleMainLoader, updatePriority, modalType }) => {
	const [priorityList, setPriorityList] = useState(details);
	const labelNames = sortFields(modalType === 3 ? "sku" : "customer");
	const [isLoading, setisLoading] = useState(false);
	const [btnTitle, setbtnTitle] = useState("Save");

	const submitSort = (priorityList) => {
		if (!isLoading) {
			setisLoading(true);
			setbtnTitle("Saving...");
			toggleMainLoader(true);
			const successHandler = (event) => {
				setisLoading(false);
				setbtnTitle("Save");
				doRefetch();
				successMessage(event);
				closeModal();
			};
			const errorHandler = (event) => {
				setisLoading(false);
				setbtnTitle("Save");
				errorMessage(event);
			};

			const body = priorityList.map((value, index) => {
				return {
					[labelNames.fieldKey]: value[labelNames.fieldKey],
					priority: index + 1,
				};
			});

			updatePriority(
				{ [labelNames.payloadKey]: body },
				`${labelNames.url}/${locationSlug}`,
				successHandler,
				errorHandler
			);
		}
	};

	const SortableItem = SortableElement(({ value, priority }) => (
		<Row>
			<Col className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 sort-priority-container-child d-flex justify-content-between text-center">
				<p>{value}</p>
				<p>
					{labelNames.headTwo} {priority}
				</p>
			</Col>
		</Row>
	));

	const SortableList = SortableContainer(() => {
		return (
			<>
				<div className="sort-priority-container">
					{priorityList.map((value, index) => (
						<SortableItem
							key={value[labelNames.fieldKey]}
							index={index}
							priority={index + 1}
							value={value[labelNames.fieldValue]}
						/>
					))}
				</div>
			</>
		);
	});

	const onSortEnd = ({ oldIndex, newIndex }) => {
		setPriorityList(arrayMove(priorityList, oldIndex, newIndex));
	};

	return (
		<div className="px-4 pt-3 pb-3">
			<Row className="mb-4">
				<Col className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12  d-flex justify-content-start ">
					<div className="italic-text">{labelNames.title}</div>
				</Col>
			</Row>
			<Row className="mb-4">
				<Col className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 sort-priority-container-child d-flex justify-content-between text-center">
					<h6>{labelNames.headOne}</h6>
					<h6>{labelNames.headTwo} </h6>
				</Col>
			</Row>
			<SortableList onSortEnd={onSortEnd} />
			<Modal.Footer className="save-footer">
				<Button variant="secondary" onClick={closeModal}>
					Cancel
				</Button>
				<Button variant="primary" type="button" onClick={() => submitSort(priorityList)}>
					{btnTitle}
				</Button>
			</Modal.Footer>
		</div>
	);
};

const mapDispatchToProps = { updatePriority, toggleMainLoader };

export default connect(null, mapDispatchToProps)(LocationSort);
