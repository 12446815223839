import React, { useState } from "react";
import { Form, Button, Container } from "react-bootstrap";
import { reduxForm, reset } from "redux-form";
import { connect, useSelector } from "react-redux";
import useModal from "../../../utils/useModal";
import { successMessage } from "../../../utils/successMessage";
import { errorMessage } from "../../../utils/errorMessage";
import TableLoader from "../../../utils/table/tableLoader";
import { addPackagingDetails, updatePackagingDetails, updateInventoryPackedCases } from "store/actions";
import { useTranslation } from "react-i18next";
import InventoryTable from "./inventoryTable";
import { createStructuredSelector } from "reselect";

const InventoryDetailsForm = ({ handleSubmit, updateInventoryPackedCases, doFetchTaskDetails, fetchTaskDetails }) => {
	const { t } = useTranslation();
	const [, , , closeModal] = useModal("inventory_show_modal");
	const [isLoading, setIsLoading] = useState(false);
	const packageDetails = useSelector((state) => state?.OrdersPackaging?.packageDetails);
	const [validate, setValidate] = useState(null);

	const checkInventoryValidation = (values) => {
		let isValid = false;
		values &&
			values.itemSelected &&
			values.itemSelected.map((item) => {
				if (item.checkbox === true) {
					isValid = true;
				}
			});
		return isValid;
	};
	const checkRequiredCasesValidation = (values) => {
		let errorMessages = "";
		values &&
			values.itemSelected &&
			values.itemSelected.map(function (item) {
				if (item.checkbox === true && !item.required_cases) {
					if (errorMessages === "") {
						errorMessages += t("label.enter_required_cases");
					}
				}
			});
		return errorMessages;
	};
	// Submit Form
	const submitDetails = (values) => {
		const flag = checkInventoryValidation(values);
		const flag1 = checkRequiredCasesValidation(values);
		if (flag) {
			if (!flag1) {
				setValidate(null);
				setIsLoading(true);
				const inventory_data = [];
				values &&
					values.itemSelected.map((item) => {
						if (item.checkbox === true) {
							inventory_data.push({
								date: item.date,
								required_cases: item.required_cases,
							});
						}
					});
				const submitData = {
					inventory: inventory_data,
				};
				const successHandler = (e) => {
					doFetchTaskDetails();
					fetchTaskDetails();
					setIsLoading(false);
					closeModal();
					successMessage(e);
				};
				const errorHandler = (e) => {
					setIsLoading(false);
					errorMessage(e);
				};
				updateInventoryPackedCases(packageDetails.slug, submitData, successHandler, errorHandler);
			} else {
				setValidate(t("label.enter_required_cases"));
			}
		} else {
			setValidate(t("label.select_inventories"));
		}
	};

	return (
		<Form onSubmit={handleSubmit(submitDetails)} className="board-details-form">
			<Container className="position-relative">
				<InventoryTable validate={validate} />
				{/* Loader */}
				{isLoading && (
					<div className="table-loader-wrapper">
						<TableLoader />
					</div>
				)}
			</Container>
			<div className="modal-footer" style={{ marginTop: "20px" }}>
				<Button variant="secondary" onClick={closeModal}>
					{t("label.cancel")}
				</Button>
				<Button variant="primary" type="submit" disabled={isLoading}>
					{isLoading ? t("label.saving") : t("label.save")}
				</Button>
			</div>
		</Form>
	);
};

// const mapStateToProps = (state) => {
// };
const mapStateToProps = createStructuredSelector({});

const mapDispatchToProps = {
	reset,
	addPackagingDetails,
	updatePackagingDetails,
	updateInventoryPackedCases,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(
	reduxForm({
		form: "inventoryForm",
		enableReinitialize: true,
	})(InventoryDetailsForm)
);
