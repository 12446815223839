import React, { useEffect } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import WaterSourceForm from "../manage";
import ForecastTable from "utils/forecastTable";
import { isEmpty, keys, values } from "lodash";
import { successMessage } from "utils/successMessage";
import { errorMessage } from "utils/errorMessage";
import { addDataWSR, setLoaderWSR, resetRefreshWSR, setRenderTypeWSR } from "store/actions";
import { getColumnsWSR, getDataWSR, getFormLocation } from "store/selectors/waterSourceSelectors";
import Util from "utils/Util";
import { number } from "constant/formValidators";

const AddCroptimals = ({
	data,
	columns,
	location,

	setLoaderWSR,
	addDataWSR,
	resetRefreshWSR,
	setRenderTypeWSR,
}) => {
	const onSave = (tableData) => {
		setLoaderWSR();
		const submitData = {
			location_slug: location.value,

			water_source_results: values(tableData).map((item, i) => ({
				date: Util.getYMDFormat(keys(tableData)[i]),
				nutrient_data: item.map((item_o, j) => ({
					nutrient_slug: item_o.slug,
					value: item_o.value,
				})),
			})),
		};
		const successHandler = (e) => {
			successMessage(e);
			setLoaderWSR();
			resetRefreshWSR();
			setRenderTypeWSR(0);
		};

		const errorHandler = (e) => {
			errorMessage(e);
			setLoaderWSR();
		};

		addDataWSR(submitData, successHandler, errorHandler);
	};

	useEffect(() => {
		return () => {
			resetRefreshWSR();
		};
	}, [resetRefreshWSR]);

	return (
		<div className="listing-wrapper-croptimals">
			<WaterSourceForm />
			{data &&
				(!isEmpty(columns) ? (
					<>
						<ForecastTable
							editable={true}
							columns={columns}
							fixedColumnHeading="Week"
							initialData={data}
							onSave={onSave}
							onCancel={() => resetRefreshWSR()}
							validation={[number]}
						/>
					</>
				) : (
					<div className="no-results-found text-center">No Nutrients Added</div>
				))}
		</div>
	);
};

const mapStateToProps = createStructuredSelector({
	data: getDataWSR,
	columns: getColumnsWSR,
	location: getFormLocation,
});

export default connect(mapStateToProps, {
	addDataWSR,
	setLoaderWSR,
	resetRefreshWSR,
	setRenderTypeWSR,
})(AddCroptimals);
