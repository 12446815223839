import { CROPTIMALS } from "store/types";
import fetchHandler from "utils/fetchHandler";

export const setLoaderCroptimals = () => {
	return (dispatch) => {
		dispatch({
			type: CROPTIMALS.LOADER_CROPTIMALS,
		});
	};
};

export const fetchDropdownData = (successHandler, errorHandler) => {
	const fetchOptions = {
		url: `croptimal-filter`,
		method: "GET",
		secure: true,
		actionType: CROPTIMALS.SET_DROPDOWN_DATA_CROPTIMALS,
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const fetchCroptimalsData = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `list-croptimals`,
		method: "POST",
		secure: true,
		actionType: CROPTIMALS.SET_DATA_LIST_CROPTIMALS,
		body: JSON.stringify(values),
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const setRenderTypeCroptimals = (value) => {
	return (dispatch) => {
		dispatch({
			type: CROPTIMALS.SET_RENDER_TYPE_CROPTIMALS,
			payload: value,
		});
	};
};

export const setCroptimalsColumns = (values, successHandler, errorHandler) => {
	return (dispatch) => {
		dispatch({
			type: CROPTIMALS.CROPTIMALS_COLUMNS_CROPTIMALS,
			payload: values,
		});
	};
};

export const setCroptimalsData = (values, successHandler, errorHandler) => {
	return (dispatch) => {
		dispatch({
			type: CROPTIMALS.SET_DATA_CROPTIMALS,
			payload: { data: values },
		});
	};
};

export const addCroptimals = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `add-croptimal`,
		method: "POST",
		secure: true,
		body: JSON.stringify(values),
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const setProductVarietyMapping = (values) => {
	return (dispatch) => {
		dispatch({
			type: CROPTIMALS.SET_KEY_MAPPING_CROPTIMALS,
			payload: values,
		});
	};
};

export const resetRefreshCroptimals = () => {
	return (dispatch) => {
		dispatch({
			type: CROPTIMALS.REFRESH_LISTING_CROPTIMALS,
		});
	};
};

export const editCroptimals = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `edit-croptimals`,
		method: "PUT",
		secure: true,
		body: JSON.stringify(values),
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const deleteCroptimal = (slug, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `delete-croptimal/${slug}`,
		method: "DELETE",
		secure: true,
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};
