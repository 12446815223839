import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-notifications/lib/notifications.css";
import "./assets/style.css";
import "./index.scss";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

import { store } from "./store";

import "./i18n";
import i18next from "i18next";
import axios from "axios";

import * as Sentry from "@sentry/react";

Sentry.init({
	dsn: "https://457b0bfeb9efec1c5e7d38c985642d1b@o4507704061263872.ingest.us.sentry.io/4507704063295488",
	integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
	// Performance Monitoring
	tracesSampleRate: 1.0, //  Capture 100% of the transactions
	// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
	tracePropagationTargets: ["localhost"],
	// Session Replay
	replaysSessionSampleRate: 0.2, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
	replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const lang = localStorage.getItem("language") || "English";
axios.defaults.baseURL = "http://localhost:3000/";
axios.defaults.headers.common["Accept-Language"] = lang;
i18next.changeLanguage(lang);

ReactDOM.render(
	// <React.StrictMode>
	<Provider store={store}>
		<BrowserRouter basename={"/"}>
			<App />
		</BrowserRouter>
	</Provider>,
	// </React.StrictMode>,
	document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
