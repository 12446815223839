import React, { useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import useModal from "../../../utils/useModal";
import SeedingGerminationTaskForm from "./seedingGerminationTaskForm";
import { getTaskDropdownDetails } from "../../../store/actions";
import Util from "../../../utils/Util";
import { getDropdownObj } from "./formHelper";
import { useTranslation } from 'react-i18next';

const { Header, Title, Body } = Modal;

// 0 Add
// 1 Edit
// 2 Repeat
const SeedingGerminationManage = ({
	getTaskDropdownDetails,
	dropdownValuesObject,
	fetchDropdownArray,
	doRefetch,
	doTaskRefetch,
}) => {
	const { t } = useTranslation();
	const [isOpen, modalType, , closeModal, modalProps] = useModal("seedingGerminationTask");
	const selectedLocation = useSelector((state) => state.SeedingGermination.selectedLocation);

	const updatedPropValues = (props) => {
		const {
			slug,
			task_id,
			assignee = {},
			location = {},
			product_variety = {},
			expected_germination_date,
			expected_seeding_date,
			expected_transplant_date,
			drum_size = {},
			germination_chamber = {},
			task_type,
			top_coat,
			flag_color,
			board_count = "",
			description = "",
			seed_density,
		} = props || {};

		const initialObj = {
			slug,
			task_id,
			board_count,
			flag_color: getDropdownObj("flagColors", flag_color),
			top_coat: getDropdownObj("topCoatOptions", top_coat),
			//drum_size: getDropdownObj("drumSizes", drum_size),
			seed_density,
			expected_germination_date: Util.getDateObjectFormat(expected_germination_date),
			expected_seeding_date: Util.getDateObjectFormat(expected_seeding_date),
			expected_transplant_date: Util.getDateObjectFormat(expected_transplant_date),
			task_type: getDropdownObj("batchTypes", task_type),
			description,
		};
		if (location && location.deleted_at) {
			return {
				...initialObj,
				location_slug: null,
				product_variety_slug: null,
				assignee_id: null,
			};
		} else {
			return {
				...initialObj,
				location_slug: Util.mapObject(location.location_name, location.slug),
				product_variety_slug:
					product_variety && product_variety.deleted_at
						? null
						: Util.mapObject(product_variety.name, product_variety.slug),
				germination_chamber:
					germination_chamber && germination_chamber.value
						? Util.mapObject(germination_chamber.value, germination_chamber.id)
						: null,
				drum_size: drum_size && drum_size.value ? Util.mapObject(drum_size.value, drum_size.id) : null,
				assignee_id: assignee && Util.mapObject(assignee.name, assignee.id),
			};
		}
	};

	useEffect(() => {
		getTaskDropdownDetails();
	}, [getTaskDropdownDetails]);

	return (
		<Modal size="lg" show={isOpen} onHide={closeModal} className="modal-sidepanel">
			<Header closeButton>
				<Title className="modal-title">{modalType === 2 ? t("label.repeat_task") : modalType === 1 ? t("label.edit_task") : t("label.add_task")}</Title>
			</Header>

			<Body className="position-relative">
				{isOpen ? (
					<SeedingGerminationTaskForm
						dropdownValuesObject={dropdownValuesObject}
						fetchDropdownArray={fetchDropdownArray}
						initialValues={
							modalType
								? modalProps
									? updatedPropValues(modalProps)
									: null
								: {
										location_slug: selectedLocation ? selectedLocation : null,
										task_type: { value: "production", label: "Production" },
								  }
						}
						doRefetch={doRefetch}
						doTaskRefetch={doTaskRefetch}
						details={modalProps}
					/>
				) : null}
			</Body>
		</Modal>
	);
};

const mapDispatchToProps = {
	getTaskDropdownDetails,
};

export default connect(null, mapDispatchToProps)(SeedingGerminationManage);
