export const formCreationFields = [
	{
		heading: "",
		fields: [
			{
				label: "SKU  Name *",
				name: "sku_name",
				type: "text",
				placeholder: "Enter SKU Name",
			},
			{
				label: "Oz per clamshell *",
				name: "oz_per_clamshell",
				type: "text",
				placeholder: "Enter Oz Per Clamshell",
			},
			{
				label: "Clamshells per Case *",
				name: "clamshells_per_case",
				type: "text",
				placeholder: "Enter  Clamshells per Case",
			},
			{
				label: "Locations *",
				name: "locations",
				type: "select-react",
				placeholder: "Select Locations",
			},
		],
	},
	{
		heading: "Recipe",
		fields: [
			{
				label: "Product Variety *",
				name: "product_varieties",
				type: "select-react",
				placeholder: "Select Product Varieties",
			},
		],
	},
];
