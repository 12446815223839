import fetchHandler from "../../utils/fetchHandler";
import { SEEDING_GERMINATION } from "../types";

// Get location based task dropdown details
export const getTaskDropdownDetails = (successHandler, errorHandler) => {
	const fetchOptions = {
		url: "task-users/seeding_germination",
		method: "GET",
		actionType: SEEDING_GERMINATION.GET_TASK_DROPDOWN_DETAILS,
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

// Get list of seeding and germination task
export const getSeedingGerminationTasks = (body, successHandler, errorHandler) => {
	const fetchOptions = {
		url: "seed_germination_tasks",
		method: "POST",
		body: JSON.stringify(body),
		actionType: SEEDING_GERMINATION.GET_SEEDING_GERMINATION_TASKS_LIST,
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

// Insert basic details of seeding and germination task
export const createSeedingGerminationTask = (body, successHandler, errorHandler) => {
	const fetchOptions = {
		url: "seed_germination_task",
		method: "POST",
		body: JSON.stringify(body),
		actionType: SEEDING_GERMINATION.CREATE_SEEDING_GERMINATION_TASK,
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

// Get details of a particular task
export const getSeedingGerminationTaskDetails = (slug, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `seed_germination_task/${slug}`,
		method: "GET",
		actionType: SEEDING_GERMINATION.GET_SEEDING_GERMINATION_TASK_DETAILS,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};
// Get details of a particular task for REPEAT
export const getRepeatSeedingGerminationTaskDetails = (slug, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `seed_germination_task/repeat/${slug}`,
		method: "GET",
		actionType: SEEDING_GERMINATION.GET_REPEAT_SEEDING_GERMINATION_TASK_DETAILS,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

// Update basic details of seeding and germination task
export const updateBasicDetailsOfSGTask = (slug, body, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `seed_germination_task/${slug}`,
		method: "PUT",
		body: JSON.stringify(body),
		actionType: SEEDING_GERMINATION.UPDATE_SEEDING_GERMINATION_BASIC_DETAILS,
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

// Delete a particular seeding and germination task
export const deleteSeedingGerminationTask = (slug, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `seed_germination_task/${slug}`,
		method: "DELETE",
		actionType: SEEDING_GERMINATION.DELETE_SEEDING_GERMINATION_TASK,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

// Start task trigger action
export const startSeedingofSGTask = (slug, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `start_seeding_task/${slug}`,
		method: "GET",
		actionType: SEEDING_GERMINATION.START_SEEDING_GERMINATION_TASK_SEEDING,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

// Update Seeding details of seeding and germination task
export const updateSeedingDetailsOfSGTask = (slug, body, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `seeding_task/seeding_details/${slug}`,
		method: "PUT",
		body: JSON.stringify(body),
		actionType: SEEDING_GERMINATION.UPDATE_SEEDING_GERMINATION_SEED_DETAILS,
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

// Update basic details of seeding and germination task
export const updateGerminationDetailsOfSGTask = (slug, body, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `seeding_task/germination_details/${slug}`,
		method: "PUT",
		body: JSON.stringify(body),
		actionType: SEEDING_GERMINATION.UPDATE_SEEDING_GERMINATION_GERMINATION_DETAILS,
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

// Complete task trigger action
export const completeSGTask = (slug, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `complete_task/${slug}`,
		method: "GET",
		actionType: SEEDING_GERMINATION.COMPLETE_SEEDING_GERMINATION_TASK,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

// Get Audit log of particular task
export const getAuditLogOfSGTask = (slug, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `seeding_task/audit_log/${slug}`,
		method: "GET",
		actionType: SEEDING_GERMINATION.GET_SEEDING_GERMINATION_TASK_AUDIT_LOG,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

// Insert cleaning time for the day
export const addSGCleaningTime = (body, successHandler, errorHandler) => {
	const fetchOptions = {
		url: "cleaning_time",
		method: "POST",
		body: JSON.stringify(body),
		actionType: SEEDING_GERMINATION.ADD_SEEDING_GERMINATION_CLEANING_TIME,
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

// Get cleaning time for today
export const getSGCleaningTime = (body, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `get_cleaning_time`,
		method: "POST",
		body: JSON.stringify(body),
		actionType: SEEDING_GERMINATION.GET_SEEDING_GERMINATION_CLEANING_TIME,
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};
// Get list of Cleaning Up time
export const getSGCleaningTimeList = (body, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `list_seeding_cleaning_time`,
		method: "POST",
		body: JSON.stringify(body),
		actionType: SEEDING_GERMINATION.GET_SEEDING_GERMINATION_CLEANING_TIME_LIST,
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};
// Delete a particular Cleaning time
export const deleteSGCleaningTime = (body, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `delete_seeding_cleaning_time`,
		method: "DELETE",
		body: JSON.stringify(body),
		actionType: SEEDING_GERMINATION.DELETE_SEEDING_GERMINATION_CLEANING_TIME,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

// Insert media usage for the day
export const addSGMediaUsage = (body, successHandler, errorHandler) => {
	const fetchOptions = {
		url: "media_usage",
		method: "POST",
		body: JSON.stringify(body),
		actionType: SEEDING_GERMINATION.ADD_SEEDING_GERMINATION_MEDIA_USAGE,
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

// Get media usage for today
export const getSGMediaUsage = (body, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `get_media_usage`,
		method: "POST",
		body: JSON.stringify(body),
		actionType: SEEDING_GERMINATION.GET_SEEDING_GERMINATION_MEDIA_USAGE,
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};
// Get list of media usage
export const getSGMediaUsageList = (body, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `list_seeding_media_usage`,
		method: "POST",
		body: JSON.stringify(body),
		actionType: SEEDING_GERMINATION.GET_SEEDING_GERMINATION_MEDIA_USAGE_LIST,
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};
// Delete a particular media usage
export const deleteSGMediaUsage = (body, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `delete_media_usage`,
		method: "DELETE",
		body: JSON.stringify(body),
		actionType: SEEDING_GERMINATION.DELETE_SEEDING_GERMINATION_MEDIA_USAGE,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

// Insert setup time for the day
export const addSGSetupTime = (body, successHandler, errorHandler) => {
	const fetchOptions = {
		url: "set_up_time",
		method: "POST",
		body: JSON.stringify(body),
		actionType: SEEDING_GERMINATION.ADD_SEEDING_GERMINATION_SETUP_TIME,
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

// Get set up time for today
export const getSGSetupTime = (body, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `get_set_up_time`,
		method: "POST",
		body: JSON.stringify(body),
		actionType: SEEDING_GERMINATION.GET_SEEDING_GERMINATION_SETUP_TIME,
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};
// Get list of Set Up time
export const getSGSetUpTimeList = (body, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `list_seeding_set_up_time`,
		method: "POST",
		body: JSON.stringify(body),
		actionType: SEEDING_GERMINATION.GET_SEEDING_GERMINATION_SET_UP_TIME_LIST,
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};
// Delete a particular Set Up time
export const deleteSGSetUpTime = (body, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `delete_seeding_set_up_time`,
		method: "DELETE",
		body: JSON.stringify(body),
		actionType: SEEDING_GERMINATION.DELETE_SEEDING_GERMINATION_SET_UP_TIME,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};
// Clear reducer states
export const clearSeedingGerminationStates = () => {
	return (dispatch) => {
		dispatch({
			type: SEEDING_GERMINATION.CLEAR_SEEDING_GERMINATION_STATES,
		});
	};
};

// Listing Page Loader toggler
export const toggleListLoader = (payload) => {
	return (dispatch) => {
		dispatch({
			type: SEEDING_GERMINATION.TOGGLE_LIST_LOADER,
			payload,
		});
	};
};

// View Page Loader toggler
export const toggleViewLoader = (payload) => {
	return (dispatch) => {
		dispatch({
			type: SEEDING_GERMINATION.TOGGLE_VIEW_LOADER,
			payload,
		});
	};
};

export const changeSeedingGerminationLocation = (location) => {
	return (dispatch) => {
		dispatch({
			type: SEEDING_GERMINATION.SELECTED_LOCATION,
			payload: location,
		});
	};
};

// Get list of  germination task
export const getGerminationTasks = (body, successHandler, errorHandler) => {
	const fetchOptions = {
		url: "germination_tasks",
		method: "POST",
		body: JSON.stringify(body),
		actionType: SEEDING_GERMINATION.GET_SEEDING_GERMINATION_TASKS_LIST,
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};
// Get details of a particular task
export const getGerminationTaskDetails = (slug, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `germination_details/${slug}`,
		method: "GET",
		actionType: SEEDING_GERMINATION.GET_GERMINATION_TASK_DETAILS,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const getProductVarietyDetails = (body, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `seed_density_germination`,
		method: "POST",
		body: JSON.stringify(body),
		actionType: SEEDING_GERMINATION.GET_SEEDING_GERMINATION_PRODUCT_VARIETY_DETAILS,
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};
