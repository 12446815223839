import { GREENS } from "../types";

const initialState = {
	greensList: [],
	greensDropdownList: [],
};

export default function (state = initialState, { type, payload }) {
	switch (type) {
		case GREENS.GET_GREENS_LIST:
			return {
				...state,
				greensList: payload.data,
			};
		case GREENS.GET_GREENS_MINI_LIST:
			return {
				...state,
				greensDropdownList: payload.data || [],
			};

		default:
			return state;
	}
}
