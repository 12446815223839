import { required, requiredDateObj, isDateObj, number, minValue } from "../../../constant/formValidators";
import { rooms } from "../../../constant/global";
import i18next from "i18next";

export const setUpTimeForm = (locations = []) => [
	{
		label: i18next.t("label.location_required"),
		name: "location_slug",
		type: "select-react",
		placeholder: i18next.t("label.select_location"),
		options: locations,
		validate: [required],
		doGetCall: true,
	},
	{
		label: i18next.t("label.room_required"),
		name: "room",
		type: "select-react",
		placeholder: i18next.t("label.select_room"),
		options: rooms,
		validate: [required],
		doGetCall: true,
	},
	{
		label: i18next.t("label.date_required"),
		name: "set_up_date",
		type: "date-picker",
		placeholder: i18next.t("label.enter_date"),
		validate: [requiredDateObj, isDateObj],
		doGetCall: true,
		minDate: new Date(),
	},
	{
		label: i18next.t("label.set_up_time_required"),
		name: "set_up_time_in_minutes",
		type: "text",
		placeholder: i18next.t("label.enter_seup_time"),
		validate: [required, number, minValue(0)],
	},
];

export const setUpTimeEditForm = () => [
	{
		label: i18next.t("label.set_up_time_required"),
		name: "set_up_time_in_minutes",
		type: "text",
		placeholder: i18next.t("label.enter_seup_time"),
		validate: [required, number, minValue(0)],
	},
];

export const cleaningTimeForm = (locations = []) => [
	{
		label: i18next.t("label.location_required"),
		name: "location_slug",
		type: "select-react",
		placeholder: i18next.t("label.select_location"),
		options: locations,
		validate: [required],
		doGetCall: true,
	},
	{
		label: i18next.t("label.room_required"),
		name: "room",
		type: "select-react",
		placeholder: i18next.t("label.select_room"),
		options: rooms,
		validate: [required],
		doGetCall: true,
	},
	{
		label: i18next.t("label.date_required"),
		name: "cleaning_date",
		type: "date-picker",
		placeholder: i18next.t("label.enter_date"),
		validate: [requiredDateObj, isDateObj],
		doGetCall: true,
		minDate: new Date(),
	},
	{
		label: i18next.t("label.cleaning_time_required"),
		name: "cleaning_time_in_minutes",
		type: "text",
		placeholder: i18next.t("label.enter_cleaning_time"),
		validate: [required, number, minValue(0)],
	},
];
export const cleaningTimeEditForm = () => [
	{
		label: i18next.t("label.cleaning_time_required"),
		name: "cleaning_time_in_minutes",
		type: "text",
		placeholder: i18next.t("label.enter_cleaning_time"),
		validate: [required, number, minValue(0)],
	},
];

export const mediaUsageForm = (locations = []) => [
	{
		label: i18next.t("label.location_required"),
		name: "location_slug",
		type: "select-react",
		placeholder: i18next.t("label.select_location"),
		options: locations,
		validate: [required],
		doGetCall: true,
	},
	{
		label: i18next.t("label.date_required"),
		name: "media_usage_date",
		type: "date-picker",
		placeholder: i18next.t("label.enter_date"),
		validate: [requiredDateObj, isDateObj],
		doGetCall: true,
		minDate: new Date(),
	},
	{
		label: i18next.t("label.media_usage_required"),
		name: "media_usage",
		type: "text",
		placeholder: i18next.t("label.enter_media_usage_in_bags"),
		validate: [required, number, minValue(0)],
	},
	{
		label: i18next.t("label.lot_number"),
		name: "lot_number",
		type: "text",
		placeholder: i18next.t("label.enter_lot_no"),
	},
];
export const mediaUsageEditForm = (locations = []) => [
	{
		label: i18next.t("label.media_usage_required"),
		name: "media_usage",
		type: "text",
		placeholder: i18next.t("label.enter_media_usage_in_bags"),
		validate: [required, number, minValue(0)],
	},
	{
		label: i18next.t("label.lot_number"),
		name: "lot_number",
		type: "text",
		placeholder: i18next.t("label.enter_lot_no"),
	},
];
