import React from "react";
import { size, round } from "lodash";
import { ButtonLink, ListingBtn } from "../../../elements";
import { Dropdown } from "react-bootstrap";
import { errorMessage } from "utils/errorMessage";

export const renderColumns = ({
	handleViewPanel,
	handleEditPanel,
	confirmDelete,
	addToFilter,
	handleSkuReorder,
	handleCustomerReorder,
	handelSkuStatus,
	permissionDetails,
	handelProductVarietyStatus,
}) => {
	const updatePermission =
		permissionDetails && permissionDetails.locations && permissionDetails.locations.permissions.update_location
			? permissionDetails.locations.permissions.update_location
			: "";
	const viewPermission =
		permissionDetails && permissionDetails.locations && permissionDetails.locations.permissions.view_location
			? permissionDetails.locations.permissions.view_location
			: "";
	const deletePermission =
		permissionDetails && permissionDetails.locations && permissionDetails.locations.permissions.delete_location
			? permissionDetails.locations.permissions.delete_location
			: "";
	return [
		{
			name: "Location Name",
			accessor: "location_name",
			className: "location-listing-col-name",
			headerClassName: "location-listing-col-name-header",
			filter: {
				type: "search",
				onChange: addToFilter,
			},
			render: (row) => {
				return viewPermission ? (
					<ButtonLink className="px-0" onClick={() => handleViewPanel(row.slug)}>
						{row.location_name}
					</ButtonLink>
				) : (
					row.location_name
				);
			},
		},
		{
			name: "City",
			accessor: "city",
			className: "location-listing-col-city",
			headerClassName: "location-listing-col-city-header",
			filter: {
				type: "search",
				onChange: addToFilter,
			},
		},
		{
			name: "State",
			accessor: "state",
			className: "location-listing-col-state",
			headerClassName: "location-listing-col-state-header",
			filter: {
				type: "search",
				onChange: addToFilter,
			},
		},
		{
			name: "Ponds",
			accessor: "ponds",
			className: "location-listing-col-ponds",
			headerClassName: "location-listing-col-ponds-header",
			sortable: false,
			render: (row) => size(row.pond),
		},
		{
			name: "Products",
			accessor: "product_varieties",
			className: "location-listing-col-products",
			headerClassName: "location-listing-col-products-header",
			sortable: false,
			render: (row) => size(row.product_varieties),
		},
		{
			name: "Users",
			accessor: "users",
			className: "location-listing-col-users",
			headerClassName: "location-listing-col-users-header",
			sortable: false,
		},
		{
			name: "Wastage",
			accessor: "wastage_percentage",
			className: "location-listing-col-wastage",
			headerClassName: "location-listing-col-wastage-header",
			sortable: false,
			render: (row) => `${round(row.wastage_percentage, 2)}%`,
		},
		{
			name: "Actions",
			className: "location-listing-col-actions",
			headerClassName: "location-listing-col-actions-header",
			hide: permissionDetails && permissionDetails.locations && (updatePermission || deletePermission) ? false : true,
			render: (row) => {
				return (
					<>
						<Dropdown drop={"down"}>
							<Dropdown.Toggle as="div" id="dropdown-basic">
								<ListingBtn
									parentClass={
										updatePermission && deletePermission ? "justify-content-end" : "justify-content-around"
									}
									btnClass="btn btn-cell btn-light btn-sm ml-2"
									iconClass="icon icon-menu"
								/>
							</Dropdown.Toggle>

							<Dropdown.Menu>
								{updatePermission ? (
									<Dropdown.Item
										onClick={() =>
											updatePermission
												? handleSkuReorder(`${row.slug}`)
												: errorMessage("INSUFFICIENT_PERMISSION")
										}>
										Manage SKU Priority
									</Dropdown.Item>
								) : (
									""
								)}
								{updatePermission ? (
									<Dropdown.Item
										onClick={() =>
											updatePermission
												? handleCustomerReorder(`${row.slug}`)
												: errorMessage("INSUFFICIENT_PERMISSION")
										}>
										Manage Customer Priority
									</Dropdown.Item>
								) : (
									""
								)}
								{updatePermission ? (
									<Dropdown.Item
										onClick={() =>
											updatePermission
												? handelSkuStatus(`${row.slug}`)
												: errorMessage("INSUFFICIENT_PERMISSION")
										}>
										Manage SKU Status
									</Dropdown.Item>
								) : (
									""
								)}
								{updatePermission ? (
									<Dropdown.Item
										onClick={() =>
											updatePermission
												? handelProductVarietyStatus(`${row.slug}`)
												: errorMessage("INSUFFICIENT_PERMISSION")
										}>
										Manage Product Variety Status
									</Dropdown.Item>
								) : (
									""
								)}
							</Dropdown.Menu>
						</Dropdown>
						{updatePermission ? (
							<ListingBtn
								parentClass={
									updatePermission && deletePermission ? "justify-content-end" : "justify-content-around"
								}
								tooltipText="Edit"
								btnClass="btn btn-cell btn-light btn-sm ml-2"
								iconClass="icon icon-edit"
								onClick={() =>
									updatePermission ? handleEditPanel(`${row.slug}`) : errorMessage("INSUFFICIENT_PERMISSION")
								}
							/>
						) : (
							""
						)}
						{deletePermission ? (
							<ListingBtn
								parentClass={
									updatePermission && deletePermission ? "justify-content-end" : "justify-content-around"
								}
								tooltipText="Delete"
								btnClass="btn btn-cell btn-light btn-sm ml-2"
								iconClass="icon icon-trash"
								onClick={() =>
									deletePermission ? confirmDelete(row.slug) : errorMessage("INSUFFICIENT_PERMISSION")
								}
							/>
						) : (
							""
						)}
					</>
				);
			},
			sortable: false,
		},
	];
};
