import fetchHandler from "../../utils/fetchHandler";
import { GREENS } from "../types";

export const fetchMiniGreensList = (successHandler, errorHandler) => {
	const fetchOptions = {
		url: "greens",
		method: "GET",
		actionType: GREENS.GET_GREENS_MINI_LIST,
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const getGreensList = (body, successHandler, errorHandler) => {
	const fetchOptions = {
		url: "greens",
		method: "POST",
		body: JSON.stringify(body),
		actionType: GREENS.GET_GREENS_LIST,
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const createGreen = (body, successHandler, errorHandler) => {
	const fetchOptions = {
		url: "green",
		method: "POST",
		actionType: GREENS.CREATE_GREEN,
		body: JSON.stringify(body),
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const updateGreen = (slug, body, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `green/${slug}`,
		method: "PUT",
		actionType: GREENS.UPDATE_GREEN,
		body: JSON.stringify(body),
	};

	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const deleteGreen = (slug, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `green/${slug}`,
		method: "DELETE",
		actionType: GREENS.DELETE_GREEN,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};
