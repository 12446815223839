/* eslint-disable array-callback-return */
import React from "react";
import { Col } from "react-bootstrap";

export const renderRow = ({
	nameKey,
	columns,
	a_item,
	a_ind,
	editable,
	renderKey,
	render,
	slugKey,
	validation,
	localeString,
	type,
	laneUtilization,
}) => {
	return (
		<>
			{columns.map((b_item, b_ind) => {
				const value =
					a_item && a_item[b_item] && a_item[b_item] && a_item[b_item][nameKey] && a_item[b_item][nameKey].lane_usage
						? a_item[b_item][nameKey].lane_usage
						: 0;
				if (laneUtilization) {
					let utilizationVal = 0;
					if (nameKey === "Lane Utilized") {
						utilizationVal = a_item && a_item[b_item] && a_item[b_item].gh_utilized_lanes;
					} else if (nameKey === "GH Max") {
						utilizationVal = a_item && a_item[b_item] && a_item[b_item].gh_max_lanes;
					}
					return (
						<Col key={b_ind} className="forecast-table-col">
							<span>{!utilizationVal ? "0" : utilizationVal.toLocaleString("en-IN")}</span>
						</Col>
					);
				}
				if (render) {
					if (type === "production") {
						const value = a_item && a_item[b_item] && a_item[b_item];
						if (value) {
							let lbsValue = "";
							value.map((item) => {
								if (nameKey === item.slug) {
									lbsValue = item;
								}
							});
							return (
								<Col key={b_ind} className="forecast-table-col">
									<span>{lbsValue ? render(lbsValue) : render(0)}</span>
								</Col>
							);
						} else {
							return (
								<Col key={b_ind} className="forecast-table-col">
									<span>{render(0)}</span>
								</Col>
							);
						}
					} else {
						return (
							<Col key={b_ind} className="forecast-table-col">
								<span>{render(a_item[b_item][nameKey])}</span>
							</Col>
						);
					}
				} else {
					return (
						<Col key={b_ind} className="forecast-table-col">
							<span>{!value ? "0" : value.toLocaleString("en-IN")}</span>
						</Col>
					);
				}
			})}
		</>
	);
};
