import { ListingBtn } from "elements";
import React from "react";
import Select from "react-select";

export const componentGroup = ({
	id,
	input,
	label,
	type,
	placeholder,
	options,
	className,
	selectMenuHeight,
	multiple = false,
	disable,
	meta: { touched, error, warning },
}) => {
	const handleChange = (selectedOption) => {
		input.onChange(selectedOption);
	};
	// console.log(input, touched, error, warning);
	switch (type) {
		case "div":
			return <div>{input.value || input.value === 0 ? input.value : "-"}</div>;
		case "input":
			return (
				<div className="position-relative">
					<input
						{...input}
						id={id}
						className={`form-control fertilizer-table-input ${
							touched ? (error ? "input-error" : warning ? "warning" : "") : ""
						}`}
						placeholder={label}
						autoComplete="nope"
						autoCorrect="off"
						type={type}
					/>
					{touched ? (
						error ? (
							<div className="error-table-sec">
								<ListingBtn
									tooltipText={error}
									btnClass="btn btn-cell btn-light error-table-btn"
									iconClass="icon icon-info table-error-icon"
									errorBtn={true}
								/>
							</div>
						) : null
					) : null}
				</div>
			);
		case "select-react":
			return (
				<div className="position-relative">
					<Select
						placeholder={placeholder ? placeholder : ""}
						classNamePrefix="fertlizer-table-select"
						onChange={handleChange}
						options={options}
						className={`custom-select-box ${className}`}
						isMulti={multiple}
						onBlur={(event) => event.preventDefault()}
						onFocus={() => input.onFocus(input.value)}
						value={input.value}
						name={input.name}
						isDisabled={disable}
						isOptionDisabled={(option) => option.isdisabled}
						maxMenuHeight={selectMenuHeight}
					/>
					{touched ? (
						error ? (
							<div className="error-table-sec">
								<ListingBtn
									tooltipText={error}
									btnClass="btn btn-cell btn-light error-table-btn"
									iconClass="icon icon-info table-error-icon"
									errorBtn={true}
								/>
							</div>
						) : null
					) : null}
				</div>
			);
		default:
			return;
	}
};
