import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import ForecastForm from "../../../manage";
import {
	checkDaysCountEqual,
	getForecastColumns,
	getForecastData,
	getForecastEditable,
	getForecastFormForecast,
	getForecastFormLocation,
	getProductionForecastCopyAccess,
	getProductionForecastUpdateAccess,
	getProductionForecastViewAccess,
	getForecastForm
} from "store/selectors/forecastSelectors";
import ForecastTable from "utils/forecastTable";
import { ListingBtn, ButtonPrimary } from "elements";
import { addForecast, setForecastData, setForecastEditable, setLoader, forecastResetRefresh,fetchForecastDataExport } from "store/actions";
import { successMessage } from "utils/successMessage";
import { errorMessage } from "utils/errorMessage";
import useModal from "utils/useModal";
import CopyForecast from "../copyForecast";
import moment from "moment";
import { isEmpty } from "lodash";

const Listing = ({
	data,
	fixedColumnData,
	columns,
	forecastEditable,
	setForecastEditable,
	location,
	forecast,
	setLoader,
	addForecast,
	setForecastData,
	checkDaysCountEqual,
	forecastResetRefresh,
	viewAccess,
	editAccess,
	copyAccess,
	formValues,
	fetchForecastDataExport
}) => {
	const [setCopyForecast, , showCopyForecast] = useModal("task");
	const [isOzPerBoard, setIsOzPerBoard] = useState(false);

	const onSave = (values) => {
		setLoader();
		const submitData = {
			location_slug: location.value,
			forecast: forecast.value,
			forecast_details: values,
		};
		const successHandler = (e) => {
			successMessage(e);
			setForecastData(values);
			setForecastEditable(false);
			setLoader();
		};
		const errorHandler = (e) => {
			errorMessage(e);
			setLoader();
		};
		addForecast(submitData, successHandler, errorHandler, "edit");
	};

	useEffect(() => {
		return () => {
			forecastResetRefresh();
		};
	}, [forecastResetRefresh]);

	useEffect(() => {
		if (forecast && forecast.value === "oz_per_board") {
			setIsOzPerBoard(true);
		} else if (forecast && forecast.value === "no_of_boards") {
			setIsOzPerBoard(false);
		}
	}, [forecast]);

	const exportHandler = () => {
		const values = formValues && formValues.values;
		const submitData = {
			location_slug: values.location_slug.value,
			forecast: values.forecast_slug.value,
			date_from: moment(values.date[0]).format("YYYY-MM-DD"),
			date_to: moment(values.date[1]).format("YYYY-MM-DD"),
			pv_slugs: values.product_variety.map((item) => item.value),
			export:1
		};
		const successHandler = (e) => {
			e.data.export_url && window.open(e.data.export_url);
		};
		const errorHandler = (e) => {
			errorMessage(e);
		};
		fetchForecastDataExport(submitData, successHandler, errorHandler);
	};

	return (
		<div className="listing-wrapper" style={{ paddingBottom: !viewAccess && 50 }}>
			{viewAccess && (
				<>
					<ForecastForm type={1} />

					{data ? (
						!isEmpty(data) ? (
							<>
								<div className="d-flex justify-content-between">
									<h1 className="form-headers pl-0">{forecast.label}</h1>
									<div>
										<div className="export-btn">
											<ButtonPrimary type="submit" onClick={exportHandler}>
												<span>
													Export{" "}
													<i className="icon icon-export text-sm" style={{ fontWeight: "bold" }} />
												</span>
											</ButtonPrimary>
										</div>
										{copyAccess && (
											<ListingBtn
												tooltipText="Copy Selected Forecast"
												btnClass="btn btn-cell btn-light forecast-sm-btn mr-4"
												iconClass="icon icon-copy"
												tooltipWidth={180}
												onClick={() => showCopyForecast(1)}
											/>
										)}
										{editAccess && (
											<ListingBtn
												tooltipText="Edit"
												btnClass="btn btn-cell btn-light forecast-sm-btn"
												iconClass="icon icon-edit"
												onClick={() => setForecastEditable(true)}
											/>
										)}
									</div>
								</div>
								{!checkDaysCountEqual && (
									<div className="d-flex justify-content-end">
										<p className="dates-missing-text">Data is not available for a few dates</p>
									</div>
								)}
								<ForecastTable
									editable={forecastEditable}
									columns={columns}
									fixedColumnHeading="Date"
									initialData={data}
									onSave={onSave}
									onCancel={() => setForecastEditable(false)}
									dateFormat={"MM-DD-YYYY"}
									isOzPerBoard={isOzPerBoard}
									localeString={false}
								/>
								{setCopyForecast && <CopyForecast />}
							</>
						) : (
							<div className="no-results-found text-center">No Results Found</div>
						)
					) : null}
				</>
			)}
		</div>
	);
};

const mapStateToProps = createStructuredSelector({
	columns: getForecastColumns,
	data: getForecastData,
	forecastEditable: getForecastEditable,
	forecast: getForecastFormForecast,
	location: getForecastFormLocation,
	checkDaysCountEqual: checkDaysCountEqual,
	viewAccess: getProductionForecastViewAccess,
	editAccess: getProductionForecastUpdateAccess,
	copyAccess: getProductionForecastCopyAccess,
	formValues: getForecastForm,
});
export default connect(mapStateToProps, { setForecastEditable, setLoader, addForecast, setForecastData, forecastResetRefresh,fetchForecastDataExport })(
	Listing
);
