import React from "react";
import { connect } from "react-redux";
import { Modal, Button, Container, Row, Col, Form } from "react-bootstrap";
import { Field, reduxForm } from "redux-form";
import { filter, map, includes } from "lodash";
import FormGroups from "../../../utils/formGroup";
import { required, number } from "../../../constant/formValidators";
import LocationUsers from "./locationUsers";
import TableLoader from "utils/table/tableLoader";

const LocationManage = ({
	handleSubmit,
	fetchingUsers,
	searchUserQuery,
	doSearch,
	submitLocation,
	productDropdownList,
	userRoles,
	userIDList,
	setUserIDList,
	usersList,
	closeModal,
	btnTitle,
	isLoading,
}) => {
	// Fetch users based on query | Role select/User search
	const onChangeQuery = (key, value, isFilter = false) => {
		let queryCopy = searchUserQuery;
		if (isFilter) {
			const q = filter(queryCopy.filter, (o) => o.column_name !== key);
			queryCopy = {
				...queryCopy,
				filter: [...q, { column_name: key, search: value }],
			};
		} else {
			queryCopy = {
				...queryCopy,
				[key]: value,
			};
		}
		doSearch(queryCopy);
	};

	// Remove filter
	const onRemoveFilter = (key) => {
		const q = {
			...searchUserQuery,
			filter: filter(searchUserQuery.filter, (o) => o.column_name !== key),
		};
		doSearch(q);
	};

	// Select users
	const onChangeCheckbox = (e, data) => {
		if (e.target.checked) {
			setUserIDList([...userIDList, data]);
		} else {
			setUserIDList(filter(userIDList, (o) => o !== data));
		}
	};

	const onChangeAllCheckbox = (e, data) => {
		const dataArr = map(data, "id");
		if (e.target.checked) {
			setUserIDList([...userIDList, ...dataArr]);
		} else {
			setUserIDList(filter(userIDList, (o) => !includes(dataArr, o)));
		}
	};

	return (
		<Form onSubmit={handleSubmit(submitLocation)} name="location-details-form" className="location-details-form">
			<Container className="position-relative">
				<Row>
					<Col xs={6} md={6}>
						<h2 className="my-3">Location Information</h2>
					</Col>
				</Row>
				<Row>
					<Col xs={6} md={6}>
						<Form.Group controlId="locationName">
							<Field
								name="location_name"
								type="text"
								label="Location Name *"
								placeholder="Enter Location Name"
								component={FormGroups}
								validate={[required]}
							/>
						</Form.Group>
					</Col>
					<Col xs={6} md={6}>
						<Form.Group controlId="locationCity">
							<Field
								name="city"
								type="text"
								label="City *"
								placeholder="Enter City"
								component={FormGroups}
								validate={[required]}
							/>
						</Form.Group>
					</Col>
				</Row>
				<Row>
					<Col xs={6} md={6}>
						<Form.Group controlId="locationState">
							<Field
								name="state"
								type="text"
								label="State *"
								placeholder="Enter State"
								component={FormGroups}
								validate={[required]}
							/>
						</Form.Group>
					</Col>
					<Col xs={6} md={6}>
						<Form.Group controlId="locationWastage">
							<Field
								name="wastage_percentage"
								type="text"
								label="Wastage (%)*"
								placeholder="Enter Wastage"
								component={FormGroups}
								validate={[required, number]}
							/>
						</Form.Group>
					</Col>
				</Row>
				<Row>
					<Col xs={12} md={12}>
						<Form.Group controlId="locationPonds">
							<Field
								name="pond"
								type="selectWithTags"
								label="Pond Naming *"
								placeholder="Enter Pond Naming"
								component={FormGroups}
								validate={[required]}
							/>
						</Form.Group>
					</Col>
				</Row>
				<Row>
					<Col xs={6} md={6}>
						<Form.Group controlId="locationLanesPerPond">
							<Field
								name="lanes_per_pond"
								type="text"
								label="Lanes per Pond *"
								placeholder="Enter Lanes per Pond"
								component={FormGroups}
								validate={[required, number]}
							/>
						</Form.Group>
					</Col>
					<Col xs={6} md={6}>
						<Form.Group controlId="locationColumnsPerLane">
							<Field
								name="column_per_lane"
								type="text"
								label="Columns per Lane *"
								placeholder="Enter Columns per Lane"
								component={FormGroups}
								validate={[required, number]}
							/>
						</Form.Group>
					</Col>
				</Row>
				<Row>
					<Col xs={6} md={6}>
						<Form.Group controlId="locationStyrofoamBoardsPerColumn">
							<Field
								name="column_board_count"
								type="text"
								label="Styrofoam Boards per Column *"
								placeholder="Enter Styrofoam Boards per Column"
								component={FormGroups}
								validate={[required, number]}
							/>
						</Form.Group>
					</Col>
					<Col xs={6} md={6}>
						<Form.Group controlId="gallonsInPond">
							<Field
								name="gallons_in_pond"
								type="text"
								label="Gallons In Pond *"
								placeholder="Enter Gallons In Pond"
								component={FormGroups}
								validate={[required, number]}
							/>
						</Form.Group>
					</Col>
				</Row>
				<Row>
					<Col xs={6} md={6}>
						<Form.Group controlId="locationLunaFacilityId">
							<Field
								name="luna_facility_id"
								type="text"
								label="Luna Facility ID"
								placeholder="Luna Facility ID"
								component={FormGroups}
								validate={[number]}
							/>
						</Form.Group>
					</Col>
				</Row>
				<Row>
					<Col xs={12} md={12}>
						<Form.Group controlId="drumSize">
							<Field
								name="drum_size"
								type="selectWithTags"
								label="Drum Size *"
								placeholder="Enter Drum Size"
								component={FormGroups}
								validate={[required]}
							/>
						</Form.Group>
					</Col>
				</Row>
				<Row>
					<Col xs={12} md={12}>
						<Form.Group controlId="germinationChambers">
							<Field
								name="germination_chambers"
								type="selectWithTags"
								label="Germination Chambers *"
								placeholder="Enter Germination Chambers"
								component={FormGroups}
								validate={[required]}
							/>
						</Form.Group>
					</Col>
				</Row>

				{/* <Col xs={6} md={6}>
						<Form.Group controlId="locationProductVarieties">
							<Field
								label="Products"
								name="product_varieties"
								type="select-react"
								multiple={true}
								component={FormGroups}
								options={
									map(productDropdownList, (o) => ({
										label: o.product_variety,
										value: o.slug,
									})) || []
								}
							/>
						</Form.Group>
					</Col> */}

				<Row>
					<Col xs={12} md={12}>
						<p className="px-0 pt-3 text-green">Map Users</p>
					</Col>
				</Row>

				<Row>
					<Col xs={12} md={12}>
						<LocationUsers
							isEditable
							data={(usersList && usersList.data) || []}
							query={searchUserQuery}
							roleKey="role_name"
							rolesArr={userRoles}
							onChangeQuery={onChangeQuery}
							onRemoveFilter={onRemoveFilter}
							onChangeCheckbox={onChangeCheckbox}
							checkedArr={userIDList}
							onChangeAllCheckbox={onChangeAllCheckbox}
							loadingUsers={fetchingUsers}
						/>
					</Col>
				</Row>
				{isLoading && (
					<div className="table-loader-wrapper">
						<TableLoader />
					</div>
				)}
			</Container>
			<Modal.Footer className="save-footer">
				<Button variant="secondary" onClick={closeModal}>
					Cancel
				</Button>
				<Button variant="primary" type="submit" disabled={isLoading}>
					{btnTitle}
				</Button>
			</Modal.Footer>
		</Form>
	);
};

//export default LocationManage;

const mapStateToProps = (state) => ({
	locationDetails: state.Location.locationDetails,
	skuPriorityList: state.Location.skuPriority ? state.Location.skuPriority : [],
	customerPriorityList: state.Location.customerPriority ? state.Location.customerPriority : [],
	initialValues: {
		...state.Location.locationDetails,
		drum_size:
			map(state.Location.locationDetails.drum_size, (o) => ({
				label: o.value,
				value: o.value,
				can_delete: o.can_delete,
			})) || [],
		germination_chambers:
			map(state.Location.locationDetails.germination_chambers, (o) => ({
				label: o.value,
				value: o.value,
				can_delete: o.can_delete,
			})) || [],
		pond:
			map(state.Location.locationDetails.pond, (o) => ({
				label: o.value,
				value: o.value,
				can_delete: o.can_delete,
			})) || [],
		product_varieties:
			map(state.Location.locationDetails.product_varieties, (o) => ({
				label: o.product_variety,
				value: o.slug,
			})) || [],
		user_ids: map(state.Location.locationDetails.user_list, "id") || [],
	},
	userRoles: state.User.userRoles,
	userList: state.User.userList,
	productsMiniList: state.Product.productsMiniList,
});
const mapDispatchToProps = {};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(
	reduxForm({
		form: "location-details-form",
		enableReinitialize: true,
	})(LocationManage)
);
