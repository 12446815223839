import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import ForecastForm from "../../../manage";
import {
	checkDaysCountEqualSP,
	getForecastColumns,
	getForecastData,
	getTabsCustomer,
	getActiveTabCustomer,
	getTabsDC,
	getActiveTabDC,
	getTableDataSP,
	customerKeyLabel,
	getSalesVsProductionCombinedData,
	getSalesVsProductionType,
	getSalesVsProductionData,
	checkDaysCountEqualSP_combined,
	getProdVsSalesSKUViewAccess,
	getTableDataSPTotal,
	getSPCombinedDataTotal,
	getForecastForm,
} from "store/selectors/forecastSelectors";
import ForecastTable from "utils/forecastTable";
import {
	setForecastEditable,
	setLoader,
	setActiveTabCustomer,
	setActiveTabDC,
	fetchSalesVsProductionDataExport,
	fetchProductionVsActualSalesBySkuExport,
} from "store/actions";
import { isEmpty } from "lodash";
import { Tab, TabContent, Tabs } from "react-bootstrap";
import SalesVsProductionType from "./salesVsProductionType";
import { getCustomTotal } from "./getCustomTotal";
import { ButtonPrimary } from "elements";
import moment from "moment";
import { errorMessage } from "utils/errorMessage";
import { ActualSalesVsProductionDropdownType, SalesVsProductionDropdownType } from "./helper";

const Listing = ({
	data,
	fixedColumnData,
	columns,
	setLoader,
	checkDaysCountEqualSP,
	customerTabs,
	activeTabCustomer,
	setActiveTabCustomer,
	activeTabDC,
	dcTabs,
	setActiveTabDC,
	tableData,
	customerKeyLabel,
	combinedData,
	type,
	responseData,
	checkDaysCountEqualSP_combined,
	viewAccess,
	tableDataTotal,
	combinedDataTotal,
	actualSales,
	fetchSalesVsProductionDataExport,
	formValues,
	fetchProductionVsActualSalesBySkuExport,
}) => {
	const getViewByData = (item) => {
		let obj;
		item &&
			item.map((data) => {
				if (data.value === type) {
					obj = data.key;
				}
			});
		return obj;
	};
	const exportHandler = () => {
		const values = formValues && formValues.values;
		const viewBy = getViewByData(actualSales ? ActualSalesVsProductionDropdownType : SalesVsProductionDropdownType);
		let exportSlug;
		values &&
			values.customer.map((item) => {
				if (customerKeyLabel[activeTabCustomer].label === item.label) {
					exportSlug = item.value;
				}
			});
		let submitData = {
			location_slug: values.location_slug.value,
			customer_slug: values.customer.map((item) => item.value),
			dc_slug: values.dc.map((item) => item.value),
			date_from: moment(values.date[0]).format("YYYY-MM-DD"),
			date_to: moment(values.date[1]).format("YYYY-MM-DD"),
			sku_slugs: values.sku_sp.map((item) => item.value),
			view_by: viewBy,
			export: 1,
		};
		if (type === 0 || type === 1) {
			submitData = {
				...submitData,
				export_customer_slug: exportSlug,
			};
		}

		const successHandler = (e) => {
			e.data.export_url && window.open(e.data.export_url);
		};
		const errorHandler = (e) => {
			errorMessage(e);
		};

		if (!actualSales) {
			fetchSalesVsProductionDataExport(submitData, successHandler, errorHandler);
		} else {
			fetchProductionVsActualSalesBySkuExport(submitData, successHandler, errorHandler);
		}
	};
	return (
		<div className="listing-wrapper" style={{ paddingBottom: !viewAccess && 50 }}>
			{viewAccess && (
				<>
					<ForecastForm type={4} actualSales={actualSales} />

					{(responseData && !isEmpty(responseData)) || (combinedData && !isEmpty(combinedData)) ? (
						<SalesVsProductionType actualSales={actualSales} />
					) : null}

					{type === 0 || type === 1
						? customerTabs && (
								<div className="tab-wrapper">
									<Tabs
										activeKey={activeTabCustomer}
										defaultActiveKey={activeTabCustomer}
										mountOnEnter={true}
										onSelect={(k) => {
											setActiveTabCustomer(customerKeyLabel[k]);
										}}
										className="customer-tab"
										id="uncontrolled-tab-outer">
										{customerTabs.map((tab, index) => (
											<Tab eventKey={tab.key} key={index} title={tab.label}>
												{/* {seedsFormFields(tab.value)} */}
												{!isEmpty(tableData) && (
													<div className="forecast-export-btn">
														<ButtonPrimary type="submit" onClick={exportHandler}>
															<span>
																Export{" "}
																<i
																	className="icon icon-export text-sm"
																	style={{ fontWeight: "bold" }}
																/>
															</span>
														</ButtonPrimary>
													</div>
												)}
												<TabContent bsPrefix="customer-tab-content">
													<Tabs
														activeKey={activeTabDC}
														defaultActiveKey={activeTabDC}
														onSelect={(k) => setActiveTabDC(k)}
														className="dc-tab"
														mountOnEnter={true}
														id="uncontrolled-tab-inner">
														{dcTabs.map((tab, index) => (
															<Tab
																className="dc-tab-cell"
																eventKey={tab.key}
																key={index}
																title={
																	<span>
																		<i className="icon icon-market mr-3" />
																		{tab.label}
																	</span>
																}>
																{activeTabDC === tab.key && tableData ? (
																	!isEmpty(tableData) ? (
																		<>
																			{!checkDaysCountEqualSP && (
																				<div className="d-flex justify-content-end">
																					<p className="dates-missing-text">
																						Data is not available for a few dates
																					</p>
																				</div>
																			)}
																			<ForecastTable
																				columns={columns}
																				fixedColumnHeading="Date"
																				initialData={tableData}
																				dateFormat="DD-MM-YYYY"
																				customTotal={() =>
																					tableDataTotal &&
																					getCustomTotal(tableDataTotal, type)
																				}
																				render={(item) =>
																					type === 0 ? (
																						<>
																							<span className="production-value">
																								{item.production ||
																								item.production === 0
																									? Number.isInteger(
																											item.production
																									  )
																										? item.production.toLocaleString(
																												"en-IN"
																										  )
																										: item.production.toLocaleString(
																												"en-IN"
																										  )
																									: "-"}
																							</span>
																							{" / "}
																							<span className="sales-value">
																								{item.sales || item.sales === 0
																									? Number.isInteger(
																											item.sales
																									  )
																										? item.sales.toLocaleString(
																												"en-IN"
																										  )
																										: item.sales.toLocaleString(
																												"en-IN"
																										  )
																									: "-"}
																							</span>
																						</>
																					) : (
																						<>
																							<span
																								className={
																									item.production ||
																									item.sales
																										? item.production -
																												item.sales >=
																										  0
																											? "txt-green"
																											: "txt-red"
																										: null
																								}>
																								{item.production || item.sales
																									? (
																											item.production -
																											item.sales
																									  ).toLocaleString("en-IN")
																									: "-"}
																							</span>
																						</>
																					)
																				}
																			/>
																		</>
																	) : (
																		<div className="no-results-found text-center">
																			No Results Found
																		</div>
																	)
																) : null}
															</Tab>
														))}
													</Tabs>
												</TabContent>
											</Tab>
										))}
									</Tabs>
								</div>
						  )
						: !isEmpty(combinedData) && (
								<>
									{!isEmpty(combinedData) && (
										<div className="d-flex justify-content-end mb-3">
											<div className="forecast-export-btn-left">
												<ButtonPrimary type="submit" onClick={exportHandler}>
													<span>
														Export{" "}
														<i
															className="icon icon-export text-sm"
															style={{ fontWeight: "bold" }}
														/>
													</span>
												</ButtonPrimary>
											</div>
										</div>
									)}
									{!checkDaysCountEqualSP_combined && (
										<div className="d-flex justify-content-end">
											<p className="dates-missing-text">Data is not available for a few dates</p>
										</div>
									)}

									<ForecastTable
										columns={columns}
										fixedColumnHeading="Date"
										initialData={combinedData}
										dateFormat="DD-MM-YYYY"
										customTotal={() => combinedDataTotal && getCustomTotal(combinedDataTotal, type)}
										render={(item) =>
											type === 3 ? (
												<span
													className={
														item.production || item.sales
															? item.production - item.sales >= 0
																? "txt-green"
																: "txt-red"
															: null
													}>
													{item.production || item.sales
														? (item.production - item.sales).toLocaleString("en-IN")
														: "-"}
												</span>
											) : (
												<>
													<span className="production-value">
														{item.production || item.production === 0
															? item.production.toLocaleString("en-IN")
															: "-"}
													</span>
													{" / "}
													<span className="sales-value">
														{item.sales || item.sales === 0
															? item.sales.toLocaleString("en-IN")
															: "-"}
													</span>
												</>
											)
										}
									/>
								</>
						  )}
					{type === 0 || type === 1
						? ((responseData && isEmpty(responseData)) || responseData === undefined) && (
								<div className="no-results-found text-center">No Results Found</div>
						  )
						: combinedData &&
						  isEmpty(combinedData) && <div className="no-results-found text-center">No Results Found</div>}
				</>
			)}
		</div>
	);
};

const mapStateToProps = createStructuredSelector({
	columns: getForecastColumns,
	data: getForecastData,

	checkDaysCountEqualSP: checkDaysCountEqualSP,
	checkDaysCountEqualSP_combined: checkDaysCountEqualSP_combined,
	activeTabCustomer: getActiveTabCustomer,
	customerTabs: getTabsCustomer,
	activeTabDC: getActiveTabDC,
	dcTabs: getTabsDC,
	tableData: getTableDataSP,
	customerKeyLabel: customerKeyLabel,
	combinedData: getSalesVsProductionCombinedData,
	type: getSalesVsProductionType,
	responseData: getSalesVsProductionData,
	viewAccess: getProdVsSalesSKUViewAccess,
	tableDataTotal: getTableDataSPTotal,
	combinedDataTotal: getSPCombinedDataTotal,
	formValues: getForecastForm,
});
export default connect(mapStateToProps, {
	setForecastEditable,
	setLoader,
	setActiveTabCustomer,
	setActiveTabDC,
	fetchSalesVsProductionDataExport,
	fetchProductionVsActualSalesBySkuExport,
})(Listing);
