import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { get, isEmpty, find, reject, map, includes, filter } from "lodash";
import { ContentWrap, ButtonPrimary, Breadcrumbs, BackButton } from "../../../elements";
import { Button } from "react-bootstrap";
import Listing from "../../../partials/listings";
import { PondParametersDropdown, renderColumns } from "./helper";
import ViewPondParameters from "./viewPondParameters";
import {
	fetchAllTransplantTasks,
	fetchTransplantTaskDetails,
	deleteTransplantTask,
	fetchTransplantLocationsMapping,
	setTransplantViewRender,
	fetchLocationUsers,
	changeTransplantLocation,
	fetchTransplantTaskOrder,
	fetchEditPondParameters,
} from "../../../store/actions";
import Checkbox from "utils/checkbox";

import ConfirmModal from "../../../utils/confirmModal";
import { errorMessage } from "../../../utils/errorMessage";
import { successMessage } from "../../../utils/successMessage";
import TransplantManage from "../manage";
import useModal from "../../../utils/useModal";
import "../transplant.scss";
import TransplantView from "../view";
import Util from "utils/Util";
import {
	LocationDropdown,
	LocationOrderDropdown,
	AssigneeButton,
	CommonDatePicker,
} from "components/bulkAssignee/bulkAssigneeComponents";
import BulkAssignee from "components/bulkAssignee";
import RepeatAllTasks from "components/repeatAllTasks";
import TableLoader from "utils/table/tableLoader";
import ViewTransplantOrderListing from "../listing/viewTransplantOrderListing";
import { useTranslation } from "react-i18next";

const Transplant = ({
	userDetails,
	fetchLocationUsers,
	fetchTransplantList,
	fetchAllTransplantTasks,
	fetchTransplantTaskDetails,
	fetchEditPondParameters,
	deleteTransplantTask,
	setTransplantViewRender,
	fetchTransplantLocationsMapping,
	permissionDetails,
	changeTransplantLocation,
	fetchTransplantTaskOrder,
	transplantTaskOrderList,
	locationOrder,
}) => {
	const { t } = useTranslation();
	const [refetch, setRefetch] = useState(0);
	const getTodaysDate = Util.getYMDFormat(new Date());
	const [listFilter, setListFilter] = useState([{ column_name: "expected_transplant_date", search: getTodaysDate }]);
	const [transplantTaskToDelete, setTransplantTaskToDelete] = useState(null);
	const [bulkAssigneeList, setBulkAssigneeList] = useState([]);
	const [filterLocation, setFilterLocation] = useState(null);

	const [, , showModal] = useModal("transplant");
	const [, , openBulkModal] = useModal("bulkAssignee");
	const [, , openRepeatAllModal] = useModal("repeatAllTasks");
	const [loading, setloading] = useState(false);
	const [disableRepeatTasks, setDisableRepeatTasks] = useState(true);

	// Formatted location array
	const locations = Util.mapOptions(userDetails && userDetails.location_slugs, "name", "slug") || [];
	const [showTransplantView, setShowTransplantView] = useState(false);
	const [date, setDate] = useState(null);
	const [filterTransplantOrderLocation, setFilterTransplantOrderLocation] = useState(null);
	const renderType = useSelector((state) => state.Transplant.renderDisplay);
	const [exportFlag, setExportFlag] = useState(null);

	// useEffect(() => {
	// 	// set initial loading filterLocation to locationSlugs[0]
	// 	if (!filterTransplantOrderLocation && locationOrder) {
	// 		setFilterTransplantOrderLocation({ label: locationOrder?.name || null, value: locationOrder?.slug || null });
	// 	}
	// }, [filterTransplantOrderLocation, locationOrder]);

	const handleTransplantOrderView = () => {
		setShowTransplantView(true);
		setloading(true);
		const successHandler = (e) => {
			setloading(false);
		};
		const onError = (e) => {
			errorMessage(e);
		};
		const body = {
			location_slug: filterTransplantOrderLocation ? filterTransplantOrderLocation.value : locationOrder.slug,
			date: date ? Util.getYMDFormat(date) : Util.getYMDFormat(new Date()),
		};

		fetchTransplantTaskOrder(body, successHandler, onError);
	};
	useEffect(() => {
		fetchTransplantLocationsMapping();
	}, [fetchTransplantLocationsMapping]);

	useEffect(() => {
		if (isEmpty(bulkAssigneeList)) {
			setDisableRepeatTasks(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [bulkAssigneeList]);
	useEffect(() => {
		if (filterTransplantOrderLocation && date) {
			setloading(true);
			handleTransplantOrderView();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filterTransplantOrderLocation, date]);

	const handleAddPanel = () => {
		showModal(0);
	};
	const handlePondPanel = (e) => {
		if (e && e.value === "view-pond-parameters") {
			setTransplantViewRender(2);
		} else if (e && e.value === "add-pond-parameters") {
			showModal(3);
		}
	};
	const handleRepeatPanel = (slug) => {
		setloading(true);
		const successHandler = () => onSuccess(slug ? 4 : 0, slug);
		fetchTransplantTaskDetails(slug, successHandler, onError);
	};
	const handlePondEditPanel = (props) => {
		const successHandler = (res) => onSuccess(res ? 5 : 0, { ...res, transplant_pond_parameter_values_id: props });
		fetchEditPondParameters({ transplant_pond_parameter_values_id: props }, successHandler, onError);
	};
	const handleEditPanel = (slug) => {
		setloading(true);
		const successHandler = () => onSuccess(slug ? 1 : 0, slug);
		fetchTransplantTaskDetails(slug, successHandler, onError);
	};
	const handleViewPanel = (slug) => {
		setloading(true);
		const successHandler = () => {
			setloading(false);
			setTransplantViewRender(1);
		};
		fetchTransplantTaskDetails(slug, successHandler, onError);
	};

	const confirmDelete = (slug) => setTransplantTaskToDelete(slug);

	const onSuccess = (modalType, slug) => {
		setloading(false);
		showModal(modalType, slug);
	};
	const onError = (e) => {
		setloading(false);
		errorMessage(e);
	};

	// Delete User
	const onDeleteSku = (id) => {
		const successHandler = (e) => {
			setTransplantTaskToDelete(null);
			setRefetch(refetch + 1);
			successMessage(e);
		};
		const errorHandler = (e) => {
			setTransplantTaskToDelete(null);
			errorMessage(e);
		};
		deleteTransplantTask(id, successHandler, errorHandler);
	};

	// Filter
	const addToFilter = (e) => {
		let obj = [];
		e.forEach((item) => {
			return (obj =
				item && item.column
					? [
							...obj,
							{
								column_name: item.column.accessor,
								search: item.value,
							},
					  ]
					: obj);
		});
		const locationFilter = find(listFilter, ["column_name", "location_slug"]);
		locationFilter ? setListFilter([...obj, locationFilter]) : setListFilter(obj);
	};

	// ----------------- BULK ASSIGNEE ----------------- //
	const onSelectFilterLocation = (location = null) => {
		if (location) {
			const obj = {
				column_name: "location_slug",
				search: location.value || "",
			};
			const rejectArr = reject(listFilter, ["column_name", "location_slug"]);
			setListFilter([...rejectArr, obj]);
		} else {
			const rejectArr = reject(listFilter, ["column_name", "location_slug"]);
			setListFilter(rejectArr);
		}
		// Clear assignee list, since choosing assignee from a single location is permitted
		setBulkAssigneeList([]);

		// Set location filter
		setFilterLocation(location);

		changeTransplantLocation(location);
	};

	// Click - Each checkbox
	const onChangeCheckbox = (e, task_id) => {
		if (e.target.checked) {
			setBulkAssigneeList([...bulkAssigneeList, task_id]);
			setDisableRepeatTasks(false);
		} else {
			const filteredArr = filter(bulkAssigneeList, (o) => o !== task_id);
			setBulkAssigneeList(filteredArr);
			if (isEmpty(filteredArr)) {
				setDisableRepeatTasks(true);
			}
		}
	};

	// Handle bulk assignee button
	const onClickAssignee = () => {
		if (filterLocation && filterLocation.value) {
			const handleSuccess = () => {
				const body = {
					location_slug: filterLocation.value,
					task_key: "transplanting",
					slugs: bulkAssigneeList,
				};
				openBulkModal(0, body);
			};
			fetchLocationUsers(filterLocation.value, handleSuccess, errorHandler);
		}
	};

	// Click - Select All checkbox
	const onChangeAllCheckbox = (e) => {
		if (e.target.checked) {
			const valSlugs = map(fetchTransplantList, (o) => o.task_id);
			setBulkAssigneeList(valSlugs);
			setDisableRepeatTasks(false);
		} else {
			setBulkAssigneeList([]);
			setDisableRepeatTasks(true);
		}
	};

	const isLocationSelected = filterLocation && filterLocation.value ? true : false;
	const showAllCheckbox = !isEmpty(fetchTransplantList);
	const isAllCheckboxSelected = find(fetchTransplantList, (o) => !includes(bulkAssigneeList, o.task_id)) ? false : true;

	// Success handling after assignee adding
	const doSuccessHandling = () => {
		setRefetch(refetch + 1);
		setBulkAssigneeList([]);
	};
	const errorHandler = (e) => {
		errorMessage(e);
	};

	const onSelectOrderLocation = (location = null) => {
		setFilterTransplantOrderLocation(location);
	};
	const onSelectOrderDate = (event) => {
		setDate(event);
	};
	const handleTransplantListView = () => {
		setShowTransplantView(false);
	};
	const handleTransplantView = () => {
		if (!filterTransplantOrderLocation && locationOrder) {
			setFilterTransplantOrderLocation({ label: locationOrder?.name || null, value: locationOrder?.slug || null });
		}
		if (!date) {
			setDate(new Date());
		}
		handleTransplantOrderView();
	};
	const handleRepeatTask = () => {
		const body = {
			task_type: "transplanting",
			task_ids: bulkAssigneeList,
		};
		openRepeatAllModal(0, body);
	};

	const columns = renderColumns({
		handleEditPanel,
		handleViewPanel,
		handleRepeatPanel,
		confirmDelete,
		addToFilter,
		isLocationSelected,
		onChangeCheckbox,
		checkedAssignees: bulkAssigneeList,
		permissionDetails,
	});
	const addPermission =
		permissionDetails &&
		permissionDetails.transplant &&
		permissionDetails.transplant.permissions.create_transpant_and_growing_task
			? permissionDetails.transplant.permissions.create_transpant_and_growing_task
			: "";
	const listPermission =
		permissionDetails &&
		permissionDetails.transplant &&
		permissionDetails.transplant.permissions.list_transpant_and_growing_task
			? permissionDetails.transplant.permissions.list_transpant_and_growing_task
			: "";
	const pondPermission =
		permissionDetails &&
		permissionDetails.transplant &&
		permissionDetails.transplant.permissions.transpant_log_pond_parameter
			? permissionDetails.transplant.permissions.transpant_log_pond_parameter
			: "";
	const assigneePermission =
		permissionDetails &&
		permissionDetails.transplant &&
		permissionDetails.transplant.permissions.transplanting_bulk_assign_tasks
			? permissionDetails.transplant.permissions.transplanting_bulk_assign_tasks
			: "";
	const viewOrderPermission =
		permissionDetails && permissionDetails.transplant && permissionDetails.transplant.permissions.view_transplant_order
			? permissionDetails.transplant.permissions.view_transplant_order
			: "";
	const updateOrderPermission =
		permissionDetails && permissionDetails.transplant && permissionDetails.transplant.permissions.update_transplant_order
			? permissionDetails.transplant.permissions.update_transplant_order
			: "";
	const exportHandler = () => {
		setExportFlag(true);
	};
	const exportHandlerFlag = () => {
		setExportFlag(false);
	};
	return (
		<>
			{!showTransplantView ? (
				<ContentWrap>
					{renderType === 0 ? (
						<>
							<div className="d-flex align-items-center mb-3">
								<Breadcrumbs keyValue="transplantGrowing" showLabel={listPermission} label={t("label.list")} />
								{viewOrderPermission && (
									<Button className="header-order-btn transplantbtn" variant="" onClick={handleTransplantView}>
										{t("label.view_transplant_order")}
									</Button>
								)}
								{viewOrderPermission && (
									<Button
										className="header-order-btn transplantbtn"
										variant=""
										onClick={handleRepeatTask}
										disabled={disableRepeatTasks}>
										{t("label.repeat_all_task")}
									</Button>
								)}
								{assigneePermission && !isEmpty(locations) && isEmpty(bulkAssigneeList) && (
									<LocationDropdown
										value={filterLocation}
										options={locations}
										onSelect={onSelectFilterLocation}
									/>
								)}
								{assigneePermission && filterLocation && filterLocation.value && !isEmpty(bulkAssigneeList) && (
									<AssigneeButton onClick={onClickAssignee} />
								)}

								{renderType === 0 ? (
									<div className="d-flex align-items-center">
										{pondPermission ? <PondParametersDropdown onSelect={handlePondPanel} /> : ""}
										{!isEmpty(fetchTransplantList) && (
											<ButtonPrimary className="export_btn btn-fixed-height" onClick={exportHandler}>
												<span>
												{t("label.export")}{" "}
													<i className="icon icon-export text-sm" style={{ fontWeight: "bold" }} />
												</span>
											</ButtonPrimary>
										)}
										{addPermission ? (
											<div className="add-button">
												<ButtonPrimary className="btn-fixed-height" onClick={handleAddPanel}>
													<i className="icon icon-plus mr-2" /> {t("label.add_task")}
												</ButtonPrimary>
											</div>
										) : (
											""
										)}
									</div>
								) : (
									""
								)}
							</div>
							<div className="position-relative">
								{showAllCheckbox && (
									<Checkbox
										wrapperClass="listing-checkbox top-transplant-checkbox"
										checked={isAllCheckboxSelected}
										onChange={onChangeAllCheckbox}
									/>
								)}
								{listPermission ? (
									<Listing
										fetchAction={fetchAllTransplantTasks}
										sort_name={"task_id"}
										columns={columns}
										refetch={refetch}
										filter={listFilter}
										exportFlag={exportFlag}
										exportHandlerFlag={exportHandlerFlag}
									/>
								) : (
									""
								)}
								{loading && !permissionDetails && (
									<div className="table-loader-wrapper">
										<TableLoader />
									</div>
								)}
							</div>
							<TransplantManage
								onAddOrEdit={() => setRefetch(refetch + 1)}
								doRefetch={() => setRefetch(refetch + 1)}
								handleEditPanel={handleEditPanel}
								locations={locations}
							/>
							<ConfirmModal
								show={transplantTaskToDelete ? true : false}
								onNo={() => setTransplantTaskToDelete(null)}
								onYes={() => onDeleteSku(transplantTaskToDelete)}
								message={t("seeding.confirm.delete_task")}
							/>
							{/* Bulk Assignee Modal */}
							<BulkAssignee doSuccessHandling={doSuccessHandling} />
							<RepeatAllTasks doSuccessHandling={doSuccessHandling} />
						</>
					) : renderType === 2 ? (
						<>
							<ViewPondParameters
								onAddOrEdit={refetch}
								handleAddPondPanel={() => showModal(3)}
								locations={locations}
								handlePondEditPanel={handlePondEditPanel}
							/>
							<TransplantManage
								onAddOrEdit={() => setRefetch(refetch + 1)}
								doRefetch={() => setRefetch(refetch + 1)}
								handlePondEditPanel={handlePondEditPanel}
								locations={locations}
							/>
						</>
					) : (
						<TransplantView handleEditPanel={handleEditPanel} />
					)}
				</ContentWrap>
			) : (
				<ContentWrap>
					<div className="d-flex justify-content-between mb-3">
						<div className="d-flex justify-content-between mb-3">
							<BackButton onClick={handleTransplantListView} />
							<Breadcrumbs
								keyValue="transplantGrowing"
								showLabel={listPermission}
								label={t("label.transplant_order")}
							/>
						</div>

						<div className="d-flex align-items-center">
							{/* <Button className="header-order-btn" variant="" onClick={handleTransplantListView}>
								View Transplant List
							</Button> */}
							{!isEmpty(locations) && (
								<LocationOrderDropdown
									value={filterTransplantOrderLocation}
									options={locations}
									onSelect={onSelectOrderLocation}
									isClearable={false}
									label={t("label.location")}
								/>
							)}
							<CommonDatePicker
								label={t("label.exp_transplant_date")}
								placeholder={t("label.select_date")}
								selectedDate={date ? date : new Date()}
								onSelect={onSelectOrderDate}
							/>
						</div>
					</div>
					<div className="position-relative">
						<div className="italic-text mb-3">{t("transplant.drag_and_drop_task")}</div>
						{transplantTaskOrderList && (
							<ViewTransplantOrderListing
								handleTransplantListView={handleTransplantListView}
								doRefetch={fetchTransplantTaskOrder}
								details={transplantTaskOrderList}
								updateOrderPermission={updateOrderPermission}
								viewOrderPermission={viewOrderPermission}
							/>
						)}

						{loading && !permissionDetails && (
							<div className="table-loader-wrapper">
								<TableLoader />
							</div>
						)}
					</div>
				</ContentWrap>
			)}
		</>
	);
};

const mapStateToProps = (state) => {
	const { UI, Auth, Transplant } = state;
	return {
		userDetails: state.Auth.profileDetails,
		accessToken: get(Auth, "accessToken", null),
		mainPreloader: get(UI, "mainPreloader", null),
		fetchTransplantList: state.Transplant.fetchTransplantList,
		permissionDetails: state.Auth.permissionDetails,
		transplantTaskOrderList: get(Transplant, "transplantTaskOrderList", []),
		locationOrder: state?.Auth?.userLocations[0],
	};
};

const mapDispatchToProps = {
	fetchAllTransplantTasks,
	fetchTransplantTaskDetails,
	deleteTransplantTask,
	fetchTransplantLocationsMapping,
	setTransplantViewRender,
	fetchLocationUsers,
	changeTransplantLocation,
	fetchTransplantTaskOrder,
	fetchEditPondParameters,
};
export default connect(mapStateToProps, mapDispatchToProps)(Transplant);
