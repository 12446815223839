import { MODALS } from "../types";

export const showModal = (modalName, modalType, modalProps) => {
	return {
		type: MODALS.SHOW_MODAL,
		payload: {
			modalType,
			modalName,
			modalProps,
		},
	};
};

export const hideModal = (modalName) => {
	return {
		type: MODALS.HIDE_MODAL,
		payload: {
			modalName,
		},
	};
};
