export const successCodes = [
	{
		code: 100,
		reason: "User was created successfully!",
		message: "User was created successfully!",
	},
	{
		code: 102,
		reason: "User was updated successfully!",
		message: "User was updated successfully!",
	},
	{
		code: 104,
		reason: "User list retrieved successfully",
		message: "User list retrieved successfully",
	},
	{
		code: 105,
		reason: "User details retrieved successfully",
		message: "User details retrieved successfully",
	},
	{
		code: 107,
		reason: "User was deleted successfully",
		message: "User was deleted successfully",
	},
	{
		code: 109,
		reason: "User authenticated successfully",
		message: "User authenticated successfully",
	},
	{
		code: 111,
		reason: "Logged out successfully",
		message: "Logged out successfully",
	},
	{
		code: 112,
		reason: "Profile was updated successfully",
		message: "Profile was updated successfully",
	},
	{
		code: 114,
		reason: "Profile details retrieved successfully",
		message: "Profile details retrieved successfully",
	},
	{
		code: 123,
		reason: "Password reset link has been sent to the email",
		message: "Password reset link has been sent to the email",
	},
	{
		code: 124,
		reason: "Your password has been changed succesfully",
		message: "Your password has been changed succesfully",
	},
	{
		code: 126,
		reason: "Password has been set successfully",
		message: "Password has been set successfully",
	},
	{
		code: 127,
		reason: "Location created successfully",
		message: "Location created successfully",
	},
	{
		code: 128,
		reason: "Location updated successfully",
		message: "Location updated successfully",
	},
	{
		code: 129,
		reason: "Location deleted successfully",
		message: "Location deleted successfully",
	},
	{
		code: 135,
		reason: "Stock keeping unit created successfully",
		message: "Stock keeping unit created successfully",
	},
	{
		code: 136,
		reason: "Stock keeping unit updated successfully",
		message: "Stock keeping unit updated successfully",
	},
	{
		code: 137,
		reason: "Stock keeping unit deleted successfully",
		message: "Stock keeping unit deleted successfully",
	},
	{
		code: 139,
		reason: "Customer created sucessfully",
		message: "Customer created sucessfully",
	},
	{
		code: 140,
		reason: "Customer updated sucessfully",
		message: "Customer updated sucessfully",
	},
	{
		code: 141,
		reason: "Customer deleted sucessfully",
		message: "Customer deleted sucessfully",
	},
	{
		code: 143,
		reason: "Transplant and Growing task created successfully",
		message: "success.transplant_and_growing_task_created_successfully",
	},
	{
		code: 144,
		reason: "Transplant and Growing task updated successfully",
		message: "success.transplant_and_growing_task_updated_successfully",
	},
	{
		code: 145,
		reason: "Transplant and Growing task deleted successfully",
		message: "success.transplant_and_growing_task_deleted_successfully",
	},
	{
		code: 147,
		reason: "Transplant plant task started successfully",
		message: "success.transplant_task_started_successfully",
	},
	{
		code: 150,
		reason: "Transplant and Growing task has been completed successfully",
		message: "success.transplant_and_growing_completed_successfully",
	},
	{
		code: 151,
		reason: "Boards created successfully!",
		message: "Boards created successfully!",
	},
	{
		code: 153,
		reason: "Board deleted successfully",
		message: "Board deleted successfully",
	},
	{
		code: 154,
		reason: "Boards updated successfully!",
		message: "Boards updated successfully!",
	},
	{
		code: 155,
		reason: "Privilage created successfully",
		message: "Privilage created successfully",
	},
	{
		code: 156,
		reason: "Privilage updated successfully",
		message: "Privilage updated successfully",
	},
	{
		code: 157,
		reason: "Seed created successfully!",
		message: "Seed created successfully!",
	},
	{
		code: 159,
		reason: "Seed deleted successfully",
		message: "Seed deleted successfully",
	},
	{
		code: 160,
		reason: "Seed updated successfully",
		message: "Seed updated successfully",
	},
	{
		code: 162,
		reason: "Green created successfully",
		message: "Green created successfully",
	},
	{
		code: 164,
		reason: "Green deleted successfully",
		message: "Green deleted successfully",
	},
	{
		code: 165,
		reason: "Green updated successfully",
		message: "Green updated successfully",
	},
	{
		code: 166,
		reason: "Product variety created successfully",
		message: "Product variety created successfully",
	},
	{
		code: 169,
		reason: "Product variety updated successfully",
		message: "Product variety updated successfully",
	},
	{
		code: 170,
		reason: "Product variety deleted successfully",
		message: "Product variety deleted successfully",
	},
	{
		code: 171,
		reason: "Set up time updated successfully",
		message: "success.setup_time_updated_successfully",
	},
	{
		code: 172,
		reason: "Cleaning time updated successfully",
		message: "success.cleaning_time_updated_successfully",
	},
	{
		code: 173,
		reason: "Media usage updated successfully",
		message: "success.media_usage_updated_successfully",
	},
	{
		code: 178,
		reason: "Seeding and germination task updated successfully",
		message: "success.seeding_and_germination_task_updated",
	},
	{
		code: 179,
		reason: "Seeding and germination task created successfully",
		message: "success.seeding_and_germination_task_created_successfully",
	},
	{
		code: 181,
		reason: "Task started successfully",
		message: "success.task_started_successfully",
	},
	{
		code: 184,
		reason: "Seeding Details Logged Successfully",
		message: "success.seeding_details_logged_successfully",
	},
	{
		code: 185,
		reason: "Seeding and germination task deleted successfully",
		message: "success.seeding_and_germination_task_deleted_successfully",
	},
	{
		code: 187,
		reason: "Task assigned successfully",
		message: "Task assigned successfully",
	},
	{
		code: 189,
		reason: "Germination Details Logged Successfully",
		message: "success.germination_details_logged_successfully",
	},
	{
		code: 194,
		reason: "Task completed successfully",
		message: "success.task_completed_successfully",
	},
	{
		code: 201,
		reason: "Transplant details logged successfully",
		message: "success.transplant_details_logged_successfully",
	},
	{
		code: 202,
		reason: "Transplant details updated successfully",
		message: "success.transplant_details_updated_successfully",
	},
	{
		code: 205,
		reason: "Pond parameters added successfully",
		message: "success.pond_parameters_added_successfully",
	},
	{
		code: 209,
		reason: "Order created successfully",
		message: "success.order_created_successfully",
	},
	{
		code: 210,
		reason: "Order updated successfully",
		message: "Order updated successfully",
	},
	{
		code: 211,
		reason: "Order deleted successfully",
		message: "Order deleted successfully",
	},
	{
		code: 214,
		reason: "Inventory details saved succesfully",
		message: "success.inventory_details_saved_successfully",
	},

	{
		code: 220,
		reason: "Packaging task completed successfully",
		message: "success.packaging_task_completed_successfully",
	},
	{
		code: 221,
		reason: "Stock keeping unit recipe changed successfully",
		message: "Stock keeping unit recipe changed successfully",
	},
	{
		code: 222,
		reason: "Stock Keeping Unit priority updated successfully",
		message: "Stock Keeping Unit priority updated successfully",
	},
	{
		code: 223,
		reason: "Customer priority updated successfully",
		message: "Customer priority updated successfully",
	},
	{
		code: 224,
		reason: "Distribution center priorities updated successfully",
		message: "Distribution center priorities updated successfully",
	},
	{
		code: 242,
		reason: "Product Temperature deleted successfully",
		message: "success.product_temperature_deleted_successfully",
	},
	{
		code: 250,
		reason: "Harvesting task created successfully",
		message: "sucess.harvesting_task_created_successfully",
	},
	{
		code: 252,
		reason: "Harvesting task updated successfully",
		message: "success.harvesting_task_updated_successfully",
	},
	{
		code: 255,
		reason: "Harvesting task started successfully",
		message: "success.harvesting_task_started_successfully",
	},
	{
		code: 257,
		reason: "Harvesting details logged successfully",
		message: "Harvesting details logged successfully",
	},
	{
		code: 260,
		reason: "Harvesting task deleted successfullyy",
		message: "Harvesting task deleted successfully",
	},
	{
		code: 262,
		reason: "Harvesting task completed successfully",
		message: "success.harvesting_task_completed_successfully",
	},
	{
		code: 209,
		reason: "Order created successfully",
		message: "Order created successfully",
	},
	{
		code: 210,
		reason: "Order updated successfully",
		message: "Order updated successfully",
	},
	{
		code: 211,
		reason: "Order deleted successfully",
		message: "Order deleted successfully",
	},
	{
		code: 214,
		reason: "Inventory details saved succesfully",
		message: "success.inventory_details_saved_successfully",
	},
	{
		code: 215,
		reason: "Packaging details saved succesfully",
		message: "success.packaging_details_saved_successfully",
	},
	{
		code: 219,
		reason: "Packaging task started successfully",
		message: "success.packaging_started_successfully",
	},
	{
		code: 220,
		reason: "Packaging task completed successfully",
		message: "Packaging task completed successfully",
	},
	{
		code: 221,
		reason: "Stock keeping unit recipe changed successfully",
		message: "Stock keeping unit recipe changed successfully",
	},
	{
		code: 222,
		reason: "Stock Keeping Unit priority updated successfully",
		message: "Stock Keeping Unit priority updated successfully",
	},
	{
		code: 224,
		reason: "Distribution center priorities updated successfully",
		message: "Distribution center priorities updated successfully",
	},
	{
		code: 225,
		reason: "Order & Packaging task updated successfully",
		message: "success.orders_and_packaging_task_updated_successfully",
	},
	{
		code: 226,
		reason: "Order & Packaging task deleted successfully",
		message: "success.orders_and_packaging_task_deleted_successfully",
	},
	{
		code: 227,
		reason: "Customer cases updated successfully",
		message: "success.customer_cases_updated_successfully",
	},
	{
		code: 276,
		reason: "Status updated successfully",
		message: "Status updated successfully",
	},
	{
		code: 235,
		reason: "Forecast file uploaded & processed successfully",
		message: "Forecast file uploaded & processed successfully",
	},
	{
		code: 228,
		reason: "Sales Forecast created successfully",
		message: "Sales Forecast created successfully",
	},
	{
		code: 229,
		reason: "Sales Forecast updated successfully",
		message: "Sales Forecast updated successfully",
	},

	{
		code: 238,
		reason: "Product Temperature added successfully",
		message: "success.product_temperature_added_successfully",
	},
	{
		code: 239,
		reason: "Product Temperature updated successfully",
		message: "success.product_temperature_updated_successfully",
	},
	{
		code: 241,
		reason: "Packaging details deleted successfully",
		message: "success.packaging_details_deleted_successfully",
	},

	{
		code: 271,
		reason: "Package waste Composition details updated successfully",
		message: "success.package_waste_composition_details_updated_successfully",
	},
	{
		code: 273,
		reason: "Forecast Saved Successfully",
		message: "Forecast Saved Successfully",
	},
	{
		code: 275,
		reason: "Forecast Updated Successfully",
		message: "Forecast Updated Successfully",
	},
	{
		code: 276,
		reason: "Status updated successfully",
		message: "Status updated successfully",
	},
	{
		code: 294,
		reason: "KPI Target values updated successfully",
		message: "KPI Target values updated successfully",
	},
	{
		code: 295,
		reason: "Harvesting Tasks order updated successfully",
		message: "success.harvesting_task_order_updated_successfully",
	},
	{
		code: 285,
		reason: "Croptimal values added successfully",
		message: "Croptimal values added successfully",
	},
	{
		code: 286,
		reason: "Fertilizer added successfully",
		message: "Fertilizer added successfully",
	},
	{
		code: 288,
		reason: "Lab Result added successfully",
		message: "Lab Result added successfully",
	},
	{
		code: 290,
		reason: "Water source result added successfully",
		message: "Water source result added successfully",
	},
	{
		code: 292,
		reason: "Dosing Calculator added successfully",
		message: "Dosing Calculator added successfully",
	},
	{
		code: 296,
		reason: "Transplant and Growing Tasks order updated successfully",
		message: "success.transplant_and_growing_tasks_order_updated_successfully",
	},
	{
		code: 297,
		reason: "Lab Result Updated Successfully",
		message: "Lab Result Updated Successfully",
	},
	{
		code: 298,
		reason: "Lab Result Deleted Successfully",
		message: "Lab Result Deleted Successfully",
	},
	{
		code: 304,
		reason: "Fertilizer updated successfully",
		message: "Fertilizer updated successfully",
	},
	{
		code: 305,
		reason: "Fertilizer deleted successfully",
		message: "Fertilizer deleted successfully",
	},
	{
		code: 306,
		reason: "Croptimals updated successfully",
		message: "Croptimals updated successfully",
	},
	{
		code: 307,
		reason: "Croptimals deleted successfully",
		message: "Croptimals deleted successfully",
	},
	{
		code: 308,
		reason: "Water source result deleted successfully",
		message: "Water source result deleted successfully",
	},
	{
		code: 309,
		reason: "Water source result updated successfully",
		message: "Water source result updated successfully",
	},
	{
		code: 310,
		reason: "To add list data deleted successfully",
		message: "To add list data deleted successfully",
	},
	{
		code: 311,
		reason: "To add list data updated successfully",
		message: "To add list data updated successfully",
	},
	{
		code: 312,
		reason: "Cleaning time deleted successfully",
		message: "success.cleaning_time_deleted_successfully",
	},
	{
		code: 313,
		reason: "SetUp time deleted successfully",
		message: "success.setup_time_deleted_successfully",
	},
	{
		code: 314,
		reason: "Media Usage deleted successfully",
		message: "success.media_usage_deleted_successfully",
	},
	{
		code: 315,
		reason: "Pond Parameters deleted Successfully",
		message: "success.pond_parameters_deleted_successfully",
	},
	{
		code: 316,
		reason: "Packaging waste deleted Successfully",
		message: "success.packaging_waste_deleted_successfully",
	},
	{
		code: 317,
		reason: "Deleted Successfully",
		message: "success.deleted_successfully",
	},
	{
		code: 318,
		reason: "Data updated Successfully",
		message: "success.data_updated_successfully",
	},
	{
		code: 320,
		reason: "Packaging waste updated successfully",
		message: "success.packaging_waste_updated_successfully",
	},
	{
		code: 321,
		reason: "Inventory data updated successfully",
		message: "Inventory data updated successfully",
	},
	{
		code: 326,
		reason: "Transfer request placed successfully",
		message: "Transfer request placed successfully",
	},
	{
		code: 327,
		reason: "Transfer request deleted successfully",
		message: "Transfer request deleted successfully",
	},
	{ code: 328, message: "Fulfilment details added successfully", status: "Fulfilment details added successfully" },
	{
		code: 330,
		message: "Fulfilment details deleted successfully",
		status: "Fulfilment details deleted successfully",
	},
	{
		code: 331,
		message: "Fulfilment status updated successfully",
		status: "Fulfilment status updated successfully",
	},
	{
		code: 332,
		message: "Request details updated successfully",
		status: "Request details updated successfully",
	},
	{
		code: 334,
		message: "Data updated successfully",
		status: "Data updated successfully",
	},
	{
		code: 329,
		message: "Fulfilment details updated successfully",
		status: "Fulfilment details updated successfully",
	},
	{
		code: 337,
		status: "Pack Rate data added successfully",
		message: "success.pack_rate_data_added_successfully",
	},
	{
		code: 356,
		status: "Inventory successfully applied to packaging",
		message: "success.inventory_successfully_applied_to_packaging",
	},
	{
		code: 357,
		status: "Tasks repeated successfully",
		message: "success.task_repeated_successfully",
	},
	{
		code: 358,
		status: "Succesfully Printed",
		message: "success.zebra_print",
	},
	{
		code: 359,
		status: "Succesfully Deleted",
		message: "success.harvest_deleted",
	},
];
