import React from "react";
import CustomModal from "utils/modal";
import useModal from "utils/useModal";
import CasesByCustomer from "./casesByCustomer";
import CasesPerSku from "./casesPerSku";
import DailyTasksForm from "./dailyTasks";
import InventoryForm from "./inventory";
import { useTranslation } from 'react-i18next';

const DailyTasks = () => {
	const { t } = useTranslation();
	const [isOpen, modalType, , closeModal, modalProps] = useModal("task");

	return (
		<CustomModal
			show={isOpen}
			onHide={closeModal}
			className={modalType === 8 && "cases-by-customer-modal"}
			title={
				modalType === 1
					? t("label.no_of_employees")
					: modalType === 2
					? t("label.pack_room_or_pre_cool_details")
					: modalType === 3
					?t("label.log_set_up_time")
					: modalType === 4
					? t("label.log_cleaning_time")
					: modalType === 5
					? t("label.packaging_waste")
					: modalType === 6
					? t("tooltip.inventory")
					: modalType === 7
					? t("tooltip.cases_per_sku")
					: modalType === 8
					? t("tooltip.case_by_customer")
					: modalType === 9
					? t("label.pack_rate_details")
					: modalType === 10 
					? t("label.product_temperature_details")
					: ""
			}>
			{modalType === 6 ? (
				<InventoryForm editData={modalProps && modalProps.editData} setRefetch={modalProps && modalProps.setRefetch} />
			) : modalType === 7 ? (
				<CasesPerSku />
			) : modalType === 8 ? (
				<CasesByCustomer />
			) : (
				<DailyTasksForm editData={modalProps && modalProps.editData} setRefetch={modalProps && modalProps.setRefetch} />
			)}
		</CustomModal>
	);
};

export default DailyTasks;
