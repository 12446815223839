import React from "react";

const RenderButton = (props) => {
	const { varient, className = "", children, type = "button" } = props;
	return (
		<button {...props} type={type} className={`btn ${varient} ${className}`}>
			{children}
		</button>
	);
};

export const ButtonPrimary = (props) => {
	return <RenderButton {...props} varient="btn-primary" />;
};

export const ButtonSecondary = (props) => {
	return <RenderButton {...props} varient="btn-secondary" />;
};

export const ButtonDefault = (props) => {
	return <RenderButton {...props} varient="btn-default" />;
};

export const ButtonLight = (props) => {
	return <RenderButton {...props} varient="btn-light" />;
};

export const ButtonDark = (props) => {
	return <RenderButton {...props} varient="btn-dark" />;
};

export const ButtonLink = (props) => {
	return <RenderButton {...props} varient="btn-link" />;
};
export const ButtonOutline = (props) => {
	return <RenderButton {...props} varient="btn-dropdown" />;
};
