import { required,requiredDateRange} from "constant/formValidators";

export const forecastFormFields = [
	{
		label: "Location *",
		name: "location_slug",
		type: "select-react",
		placeholder: "Select Location",
		validate: [required],
	},
	{
		label: "Commodity *",
		name: "commodity",
		type: "select-react-expand-all",
		placeholder: "Select Commodity",
		validate: [required],
	},
	{
		label: "Accounts Ids *",
		name: "accounts_ids",
		type: "select-react-expand-all",
		placeholder: "Select Account Ids",
		validate: [required],
	},
    {
		label: "Metric *",
		name: "metric",
		type: "select-react-expand-all",
		placeholder: "Select Metric",
		validate: [required],
	},
    {
		label: "Date *",
		name: "date",
		type: "date-range-picker",
		placeholder: "Enter date range",
		validate: [requiredDateRange]
	},
];

export const metricDropDown =[
	{
		label:"Usage",
		value:"usage"
	},{
		label:"Cost",
		value:"cost"
	}
]

export const columns = [
	{
		name:"Usage",
		slug:"1"
	},
	{
		name:"Cost",
		slug:"2"
	}
]