import React, { useState } from "react";
import { Row } from "react-bootstrap";
import {  tableHeadersRequestedDetails } from "../view/viewHelper";
import ConfirmModal from "utils/confirmModal";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import RequestedDetailsTable from "../../../../utils/requestedDetailsTable";
import { getfulFillementTableColumns } from "../../../../store/selectors/fulFillmentTableSelectors";
import { errorMessage } from "utils/errorMessage";
import { successMessage } from "utils/successMessage";
import { setLoaderFulFillmentData, deleteFulFillmentData, editRequestedDetails } from "../../../../store/actions";
import Util from "utils/Util";

const RequestedDetailsModel = ({
	taskDetails,
	columns,
	locationSkuList,
	setLoaderFulFillmentData,
	doFetchTaskDetails,
	deleteFulFillmentData,
	onClickFullFillmentDetails,
	editRequestedDetails,
	permissionDetails
}) => {
	const [deleteItem, setdeleteItem] = useState(null);
	const onSave = (values, slug, editableChange) => {
		setLoaderFulFillmentData();
		const submitData = {
			transfer_request_details_slug: slug.transfer_request_details_slug,
			delivery_date: Util.getYMDFormat(values.delivery_date),
			cases_required: values.cases_required,
		};

		const successHandler = (e) => {
			successMessage(e.code);
			doFetchTaskDetails();
			editableChange();
		};
		const errorHandler = (e) => {
			errorMessage(e);
			doFetchTaskDetails();
		};

		editRequestedDetails(submitData, successHandler, errorHandler);
	};
	const onDelete = () => {
		const successHandler = (e) => {
			successMessage(e.code);
			doFetchTaskDetails();
			setdeleteItem(null);
		};
		const errorHandler = (e) => {
			errorMessage(e.code);
			doFetchTaskDetails();
			setdeleteItem(null);
		};
		deleteFulFillmentData(deleteItem, successHandler, errorHandler);
	};

	const editPermission =
	permissionDetails && permissionDetails.cross_supply && permissionDetails.cross_supply.permissions.edit_request_details
		? permissionDetails.cross_supply.permissions.edit_request_details
		: "";
		const addPermission =
		permissionDetails && permissionDetails.cross_supply && permissionDetails.cross_supply.permissions.add_fulfilment
			? permissionDetails.cross_supply.permissions.add_fulfilment
			: "";


	return (
		<>
			<Row>
				<div className="form-headers">Requested Details</div>
			</Row>
			<RequestedDetailsTable
				editable={true}
				columns={tableHeadersRequestedDetails}
				fixedColumnHeading="SKU"
				initialData={taskDetails}
				slugKey="sku_slug"
				renderKey="sku_name"
				option={locationSkuList}
				onSave={onSave}
				render={(item) => (item.sku_name ? item.sku_name : "-")}
				keyMapping={taskDetails}
				onDelete={(slug) => setdeleteItem(slug)}
				onClickFullFillmentDetails={onClickFullFillmentDetails}
				editPermission={editPermission}
				addPermission={addPermission}
			/>
			<ConfirmModal
				show={deleteItem ? true : false}
				onNo={() => setdeleteItem(null)}
				onYes={onDelete}
				message="Are you sure want to delete this data ?"
			/>
		</>
	);
};

const mapStateToProps = createStructuredSelector({
	columns: getfulFillementTableColumns,
});

export default connect(mapStateToProps, { setLoaderFulFillmentData, deleteFulFillmentData, editRequestedDetails })(
	RequestedDetailsModel
);
