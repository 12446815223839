import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Field, change } from "redux-form";
import { Row, Col } from "react-bootstrap";
import { ListingBtn } from "elements";
import Checkbox from "../../../utils/checkbox";
import { number,zeroValidation } from "../../../constant/formValidators";
import utils from "../../../utils/Util";


const componentGroup = ({ defaultValue, id, input, label, type, meta: { touched, error, warning } }) => {
	switch (type) {
		case "text":
			return <div>{defaultValue ? defaultValue : "-"}</div>;
		case "div":
			return <div>{input.value || input.value === 0 ? input.value : "-"}</div>;
		case "input":
			return (
				<div className="position-relative">
					<input
						{...input}
						id={id}
						className={`form-control action-table-input ${
							touched ? (error ? "input-error" : warning ? "warning" : "") : ""
						}`}
						placeholder={label}
						type={type}
					/>
					{touched ? (
						error ? (
							<div className="error-table-sec">
								<ListingBtn
									tooltipText={error}
									btnClass="btn btn-cell btn-light error-table-btn"
									iconClass="icon icon-info table-error-icon"
									errorBtn={true}
								/>
							</div>
						) : null
					) : null}
				</div>
			);
		default:
			return;
	}
};

const InventoryTableBody = ({
	value,
	index,
	date,
	cases,
}) => {
	const dispatch = useDispatch();
	const editableData = useSelector(
		(state) =>
			state.form &&
			state.form.inventoryForm &&
			state.form.inventoryForm.values &&
			state.form.inventoryForm.values.itemSelected &&
			state.form.inventoryForm.values.itemSelected[index] &&
			state.form.inventoryForm.values.itemSelected[index]
	);
	const syncErrors = useSelector(
		(state) =>
			state.form &&
			state.form.inventoryForm &&
			state.form.inventoryForm.syncErrors &&
			state.form.inventoryForm.syncErrors.itemSelected &&
			state.form.inventoryForm.syncErrors.itemSelected[index] &&
			state.form.inventoryForm.syncErrors.itemSelected[index]
	);
	const onChangeCheckbox = (index, date) => {
		if (editableData && editableData.checkbox) {
            dispatch(change("inventoryForm", `checkbox_all`, false));
			dispatch(change("inventoryForm", `itemSelected[${index}].checkbox`, false));
		} else {
			dispatch(change("inventoryForm", `itemSelected[${index}].checkbox`, true));
		}
		dispatch(change("inventoryForm", `itemSelected[${index}].date`, date));
	};
	return (
		<Row key={index} className="modal-table-body-row">
			<Col className="modal-table-head-col cont-wrap" style={{ flex: 2.1 }}>
				<Checkbox
					className="sg-listing-col-checkbox"
					inputClass="checkbox-input"
					checked={editableData && editableData.checkbox ? true : false}
					onChange={(e) => onChangeCheckbox(index, date)}
				/>
                <div className="inventory_margin">
				<Field name={`${value}-item`} type={"text"} component={componentGroup} value="qwe" defaultValue={utils.formatMDY(date)} />
                </div>
			</Col>

			<Col className={"modal-table-col cont-wrap"} style={{ flex: 2.1 }}>
				<Field name={`${value}-item`} type={"text"} component={componentGroup} value="qwe" defaultValue={cases} />
			</Col>
			<Col className={"modal-table-col cont-wrap"} style={{ flex: 2.1, paddingRight: 0, overflow: "initial" }}>
				<Field
					id={`${value}.required_cases`}
					name={`${value}.required_cases`}
					type={editableData && editableData.editable ? "input" : "div"}
					component={componentGroup}
					validate={[number,zeroValidation]}
				/>
			</Col>

			<Col className="modal-table-col" style={{ width: 70, flex: "inherit" }}>
				<ListingBtn
					id={`${value}-edit-id`}
					tooltipFontSize={11}
					tooltipText={editableData && editableData.editable ? "Save" : "Edit"}
					type="span"
					iconClass={
						editableData && editableData.editable
							? "icon icon-check modal-table-icon "
							: "icon icon-edit modal-table-icon "
					}
					onClick={(e) => {
						if (editableData && editableData.editable) {
							if (syncErrors === undefined) {
								dispatch(change("inventoryForm", `itemSelected[${index}].editable`, false));
							}
						} else {
							dispatch(change("inventoryForm", `itemSelected[${index}].editable`, true));
						}
					}}
				/>
			</Col>
		</Row>
	);
};

export default InventoryTableBody;