import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { get, find, reject } from "lodash";
import Listing from "../../../partials/listings";
import useModal from "../../../utils/useModal";
import ConfirmModal from "../../../utils/confirmModal";
import { errorMessage } from "../../../utils/errorMessage";
import { successMessage } from "../../../utils/successMessage";
import { renderPondParameterColumns } from "./helper";
import { ButtonPrimary, Breadcrumbs, BackButton } from "../../../elements";
import { LocationDropdown, DateRangePicker } from "components/bulkAssignee/bulkAssigneeComponents";
import { fetchAllPondParameters, setTransplantViewRender, deletePondParameters } from "../../../store/actions";
import "../transplant.scss";
import TableLoader from "utils/table/tableLoader";
import Util from "../../../utils/Util";
import { useTranslation } from "react-i18next";

const ViewPondParameters = ({
	handleAddPondPanel,
	locations,
	handlePondEditPanel,
	fetchAllPondParameters,
	deletePondParameters,
	permissionDetails,
	setTransplantViewRender,
	onAddOrEdit,
}) => {
	const { t } = useTranslation();
	const [filterLocation, setFilterLocation] = useState(null);
	const [loading, setloading] = useState(false);
	const [isDeletModalOpen, , showDeleteModal, closeDeleteModal, deleteModalProps] = useModal("deletePond");
	const pondPermission =
		permissionDetails &&
		permissionDetails.transplant &&
		permissionDetails.transplant.permissions.transpant_log_pond_parameter
			? permissionDetails.transplant.permissions.transpant_log_pond_parameter
			: "";
	const [refetch, setRefetch] = useState(0);
	const [filter, setFilter] = useState([]);
	const [startDate, setStartDate] = useState(null);
	const [endDate, setEndDate] = useState(null);
	const [, setPondFilter] = useState(null);
	const addToFilter = (e) => {
		let obj = [];
		e.forEach((item) => {
			return (obj =
				item && item.column
					? [
							...obj,
							{
								column_name: item.column.accessor,
								search: item.value,
							},
					  ]
					: obj);
		});
		setPondFilter(obj);
		// console.log("filterLocation",filterLocation)
		// const locationFilter = find(filter, ["column_name", "location_slug"]);
		if (filterLocation) {
			obj = [...obj, { column_name: "location_slug", search: filterLocation.value || "" }];
		}
		if (startDate && endDate) {
			obj = [
				...obj,
				{ column_name: "date", start_date: Util.getYMDFormat(startDate), end_date: Util.getYMDFormat(endDate) },
			];
		}
		obj ? setFilter(obj) : setFilter([]);
	};
	const onChangeDate = (dates) => {
		const [start, end] = dates;
		let obj = [];
		if (dates) {
			setStartDate(start);
			setEndDate(end);
		} else {
			return;
		}
		if (start && end) {
			if (filterLocation) {
				obj = [...obj, { column_name: "location_slug", search: filterLocation.value || "" }];
			}
			const pondFilter = find(filter, ["column_name", "pond"]);

			if(pondFilter){
				obj = [...obj,pondFilter]
			}
			obj = [...obj, { column_name: "date", start_date: Util.getYMDFormat(start), end_date: Util.getYMDFormat(end) }];

			obj ? setFilter(obj) : setFilter([]);
		}
	};

	useEffect(() => {
		setRefetch(onAddOrEdit);
	}, [onAddOrEdit]);

	const handleDeletePanel = (data) => showDeleteModal(0, data);

	const onDeletePond = () => {
		setloading(true);
		const successHandler = (e) => {
			closeDeleteModal();
			successMessage(e);
			setRefetch(refetch + 1);
			setloading(false);
		};
		const errorHandler = (e) => {
			closeDeleteModal();
			errorMessage(e);
			setloading(false);
		};
		deletePondParameters(deleteModalProps, successHandler, errorHandler);
	};

	const onSelectFilterLocation = (location = null) => {
		let obj = [];
		if (location) {
			obj = {
				column_name: "location_slug",
				search: location.value || "",
			};
			const rejectArr = reject(filter, ["column_name", "location_slug"]);
			setFilter([...rejectArr, obj]);
		} else {
			const rejectArr = reject(filter, ["column_name", "location_slug"]);
			setFilter([...rejectArr]);
		}
		// Set location filter
		setFilterLocation(location);
	};

	const columns = renderPondParameterColumns({
		handleDeletePanel,
		addToFilter,
		handlePondEditPanel,
		permissionDetails,
		locationMappings: locations,
	});

	return (
		<>
			<div className="d-flex align-items-center mb-3">
				<BackButton onClick={() => setTransplantViewRender(0)} />
				<Breadcrumbs keyValue="transplantGrowing" showLabel={pondPermission} label={t("label.view_pond_parameter")} />
				<LocationDropdown
					label={t("label.location")}
					value={filterLocation}
					options={locations}
					onSelect={onSelectFilterLocation}
				/>
				<DateRangePicker
					label={t("label.date_from_to")}
					placeholder={t("label.select_date")}
					startDate={startDate}
					endDate={endDate}
					onChange={onChangeDate}
				/>
				<ButtonPrimary onClick={handleAddPondPanel}>
					<i className="icon icon-plus mr-2" /> {t("label.add_pond_parameter")}
				</ButtonPrimary>
			</div>
			<Listing
				sort_name={"date"}
				fetchAction={fetchAllPondParameters}
				columns={columns}
				refetch={refetch}
				filter={filter}
			/>
			{pondPermission && loading && (
				<div className="table-loader-wrapper">
					<TableLoader />
				</div>
			)}
			<ConfirmModal
				show={isDeletModalOpen}
				onNo={closeDeleteModal}
				onYes={onDeletePond}
				message={t("transplant.confirm.delete_pond")}
			/>
		</>
	);
};
const mapStateToProps = (state) => {
	const { UI, Auth } = state;
	return {
		userDetails: state.Auth.profileDetails,
		accessToken: get(Auth, "accessToken", null),
		mainPreloader: get(UI, "mainPreloader", null),
		fetchAllPondParameters: state.Transplant.fetchAllPondParameters,
		permissionDetails: state.Auth.permissionDetails,
		locationOrder: state?.Auth?.userLocations[0],
	};
};
const mapDispatchToProps = {
	fetchAllPondParameters,
	setTransplantViewRender,
	deletePondParameters,
};
export default connect(mapStateToProps, mapDispatchToProps)(ViewPondParameters);
