import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { getHarvestRateArray, getHarvestKpiData, getPondList } from "store/selectors/dashboardSelectors";
import { PondDropdown } from "components/bulkAssignee/bulkAssigneeComponents";
import { useTranslation } from "react-i18next";
import { isEmpty } from "lodash";
import { colorArray } from "../chartComponentHelper";
import Util from "../../../utils/Util";
import { errorMessage } from "utils/errorMessage";
import { fetchPondVisualizationData } from "../../../store/actions";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
const _ = require("lodash");

const PondVisualization = ({ pond, locationSlug, fetchPondVisualizationData, pondVisualizationFlag }) => {
	const [pondValue, setPondValue] = useState();
	const [pondVisualization, setPondVisualization] = useState([]);
	const [lanes, setLanes] = useState([]);
	const { t } = useTranslation();

	useEffect(() => {
		setPondValue([]);
		setPondVisualization([]);
		setLanes([]);
	}, [locationSlug]);
	const onChangePond = (pond) => {
		setPondValue(pond);
		const successHandler = (e) => {
			e && e.data && setPondVisualization(e.data);
			const lanesArray = [];
			e.data &&
				e.data.lanesData &&
				e.data.lanesData[0] &&
				e.data.lanesData[0].lanes_per_pond &&
				_.times(e.data.lanesData[0].lanes_per_pond, (i) => {
					const obj = {
						no: i + 1,
						label: t("label.lane") + (i + 1),
					};
					lanesArray.push(obj);
				});
			setLanes(lanesArray);
		};
		const errorHandler = (e) => {
			errorMessage(e);
		};
		pond &&
			fetchPondVisualizationData(
				{
					location_slug: locationSlug,
					timezone: Util.getLocalTimeZone(),
					date: Util.getYMDFormat(new Date()),
					pond_id: pond.value,
				},
				successHandler,
				errorHandler
			);
	};

	const sliceAndCapitalizeFirstLetter = (flagLabel) => {
		const afterDot = flagLabel.substr(flagLabel.indexOf(".") + 1);
		const label = afterDot.charAt(0).toUpperCase() + afterDot.slice(1);
		return label;
	};

	return (
		<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-4">
			<div className="card h-100">
				<div className="card-body">
					<div className="d-flex justify-content-between p-3">
						<div className="graph-headers">{t("label.pond_view")}</div>
						<div className="pond-lengend">
							{colorArray.map((item, index) => {
								return (
									<div className="legend-inner-div" key={index}>
										<div className={`oval-box ${item.value}`}></div>
										<span className="legend-label">{sliceAndCapitalizeFirstLetter(item.label)}</span>
									</div>
								);
							})}
						</div>
						<div className="graph-headers">
							<PondDropdown value={pondValue} options={pond} onSelect={onChangePond} />
						</div>
					</div>

					{!isEmpty(lanes) ? (
						<div className="pond-visualization">
							<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
								<div className="pond-header">
									<div className="pond-header-flex">{t("label.harvesting")}</div>
									<div className="pond-header-flex">{t("label.transplant_and_growing")}</div>
								</div>
							</div>
							<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 ">
								{lanes &&
									lanes.map((item_lane, index_lane) => {
										return (
											<div key={index_lane}>
												<div className="rotate">{item_lane.label}</div>
												<div className="pond-outer-div custom-y-scroll">
													<div className="pond-inner-div">
														{pondVisualization &&
															pondVisualization.harvestingPondData &&
															pondVisualization.harvestingPondData.map((item_t, indx_t) => {
																if (item_lane.no === item_t.lane) {
																	return (
																		<div key={indx_t}>
																			{_.times(item_t.board_count, (index) => (
																				<div key={index}>
																					<OverlayTrigger
																						// key={index}
																						placement="bottom"
																						overlay={
																							<Popover
																								rootclose="true"
																								className="popover-over-modal"
																								title="">
																								<div className="legend-box-flex">
																									<div
																										className={`legend-box ${item_t.flag_color}`}></div>
																									<div className="lengend-content">
																										<h4>
																											{item_t.pv_name}
																										</h4>
																										<>
																											{t(
																												"label.no_of_boards"
																											)}{" "}
																											:{" "}
																											{item_t.board_count}
																										</>
																									</div>
																								</div>
																							</Popover>
																						}>
																						<div
																							className={`box ${item_t.flag_color}`}></div>
																					</OverlayTrigger>
																				</div>
																			))}
																		</div>
																	);
																}
																return;
															})}
													</div>
													<div className="pond-inner-div">
														{pondVisualization &&
															pondVisualization.transplantData &&
															pondVisualization.transplantData.map((item_t, indx_t) => {
																if (item_lane.no === item_t.lane_number) {
																	return (
																		<div key={indx_t}>
																			{_.times(item_t.number_of_boards, (index) => (
																				<div key={index}>
																					<OverlayTrigger
																						// key={index}
																						placement="bottom"
																						overlay={
																							<Popover
																								rootclose="true"
																								className="popover-over-modal"
																								title="">
																								<div className="legend-box-flex">
																									<div
																										className={`legend-box ${item_t.flag_color}`}></div>
																									<div className="lengend-content">
																										<h4>
																											{item_t.pv_name}
																										</h4>
																										<>
																											{t(
																												"label.no_of_boards"
																											)}{" "}
																											:{" "}
																											{
																												item_t.number_of_boards
																											}
																										</>
																									</div>
																								</div>
																							</Popover>
																						}>
																						<div
																							className={`box ${item_t.flag_color}`}></div>
																					</OverlayTrigger>
																				</div>
																			))}
																		</div>
																	);
																}
																return;
															})}
													</div>
												</div>
											</div>
										);
									})}
							</div>
						</div>
					) : (
						<p className="card-text-empty">{t("no_data_available")}</p>
					)}
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = createStructuredSelector({
	getHarvestRateArray,
	getHarvestKpiData,
	pond: getPondList,
});
export default connect(mapStateToProps, { fetchPondVisualizationData })(PondVisualization);
