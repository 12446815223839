import { requiredDateRange, required } from "constant/formValidators";

export const forecastFormFields = [
	{
		label: "Location *",
		name: "location_slug",
		type: "select-react",
		placeholder: "Select Location",
		validate: [required],
	},
	{
		label: "Forecast *",
		name: "forecast_slug",
		type: "select-react",
		placeholder: "Select Forecast",
		validate: [required],
	},
	{
		label: "Date (From - To) *",
		name: "date",
		// type: "date-picker",
		type: "date-range-picker",
		placeholder: "Enter Date",
		validate: [requiredDateRange],
	},

	{
		label: "Product Variety *",
		name: "product_variety",
		type: "select-react-expand-all",
		placeholder: "Select Product Variety",
		validate: [required],
	},
];

export const forecastList = [
	{
		label: "Oz per Board",
		value: "oz_per_board",
	},
	{
		label: "No. of Boards",
		value: "no_of_boards",
	},
];

export const harvestForecastFormFields = [
	{
		label: "Location *",
		name: "location_slug",
		type: "select-react",
		placeholder: "Select Location",
		validate: [required],
	},
	{
		label: "Date (From - To) *",
		name: "date",
		type: "date-range-picker",
		placeholder: "Enter Date",
		validate: [requiredDateRange],
	},

	{
		label: "Product Variety *",
		name: "product_variety",
		type: "select-react-expand-all",
		placeholder: "Select Product Variety",
		validate: [required],
	},
	{
		label: "Exclude wastage% *",
		name: "exclude_wastage",
		type: "checkbox",
		placeholder: "Select Product Variety",
	},
];

export const forecastCasesFormFields = [
	{
		label: "Location *",
		name: "location_slug",
		type: "select-react",
		placeholder: "Select Location",
		validate: [required],
	},

	{
		label: "Date (From - To) *",
		name: "date",
		type: "date-range-picker",
		placeholder: "Enter Date",
		validate: [requiredDateRange],
	},
	{
		label: "SKU *",
		name: "sku",
		type: "select-react-expand-all",
		placeholder: "Select SKU",
		validate: [required],
	},
];

export const salesVsProdFormFields = [
	{
		label: "Location *",
		name: "location_slug",
		type: "select-react",
		placeholder: "Select Location",
		validate: [required],
	},

	{
		label: "Date (From - To) *",
		name: "date",
		type: "date-range-picker",
		placeholder: "Enter Date",
		validate: [requiredDateRange],
	},
	{
		label: "Customer *",
		name: "customer",
		type: "select-react-expand-all",
		placeholder: "Select Customer",
		validate: [required],
	},
	{
		label: "DC *",
		name: "dc",
		type: "select-react-expand-all",
		placeholder: "Select DC",
		validate: [required],
	},
	{
		label: "SKU *",
		name: "sku_sp",
		type: "select-react-expand-all",
		placeholder: "Select SKU",
		validate: [required],
	},
];

export const weeklyAddFields = [
	{
		label: "Location *",
		name: "location_slug",
		type: "select-react",
		placeholder: "Select Location",
		validate: [required],
	},

	{
		label: "Date (From - To) *",
		name: "date",
		type: "date-range-picker",
		placeholder: "Enter Date",
		validate: [requiredDateRange],
	},
	{
		label: "Customer *",
		name: "customer_add",
		type: "select-react",
		placeholder: "Select Customer",
		validate: [required],
	},
	{
		label: "DC *",
		name: "dc_add",
		type: "select-react-expand-all",
		placeholder: "Select DC",
		validate: [required],
	},
	{
		label: "SKU *",
		name: "sku_add",
		type: "select-react-expand-all",
		placeholder: "Select SKU",
		validate: [required],
	},
];

export const salesVsProdByVarietyFormFields = [
	{
		label: "View By *",
		name: "view_by",
		type: "select-react",
		placeholder: "View By",
		validate: [required],
	},
	{
		label: "Location *",
		name: "location_variety",
		// type: (type = 1) => (type === 3 ? "select-react-expand-all" : "select-react"),
		type: "select-react",
		placeholder: "Select Location",
		validate: [required],
	},

	{
		label: "Date (From - To) *",
		name: "date",
		type: "date-range-picker",
		placeholder: "Enter Date",
		validate: [requiredDateRange],
	},
	{
		label: "Customer *",
		name: "customer_variety",
		type: "select-react-expand-all",
		placeholder: "Select Customer",
		validate: [required],
	},
	{
		label: "DC *",
		name: "dc_variety",
		type: "select-react-expand-all",
		placeholder: "Select DC",
		validate: [required],
	},
	{
		label: "Product Variety *",
		name: "product_variety_sp",
		type: "select-react-expand-all",
		placeholder: "Select Product Variety",
		validate: [required],
	},
];

export const viewByOptions = [
	{
		label: "Production Vs Sales",
		value: "production_vs_sales",
		key:"production_vs_sales"
	},
	{
		label: "Production Vs Sales Combined",
		value: "production_vs_sales_combined",
		key:"production_vs_sales_combined"
	},
	{
		label: "Production Vs Sales Difference",
		value: "production_vs_sales_difference",
		key:"production_sales_difference"
	},
	// production_vs_sales_combined_difference
];

export const viewByOptionsActualSales = [
	{
		label: "Production Vs Actual Sales",
		value: "production_vs_sales",
		key:"production_vs_actual_sales"
	},
	{
		label: "Production Vs Actual Sales Combined",
		value: "production_vs_sales_combined",
		key:"production_vs_actual_sales_combined"
	},
	{
		label: "Production Vs Actual Sales Difference",
		value: "production_vs_sales_difference",
		key:"production_actual_sales_difference"
	},
	// production_vs_actual_sales_combined_difference
];
