import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import arrayMove from "array-move";
import { errorMessage } from "utils/errorMessage";
import { successMessage } from "utils/successMessage";
import { toggleMainLoader, updatePackagingOrders } from "store/actions";
import { isEmpty } from "lodash";
import { useTranslation } from "react-i18next";
// import "../harvesting.scss";

const ViewPackagingOrder = ({
	details,
	updateOrderPermission,
	viewOrderPermission,
	handleHarvestListView,
	doRefetch,
	toggleMainLoader,
	updatePackagingOrders,
	setloading,
	loading,
}) => {
	const { t } = useTranslation();
	const [priorityList, setPriorityList] = useState(details);
	useEffect(() => {
		setPriorityList(details);
	}, [details]);

	const sortFields = {
		title: "Drag and Drop the rows to change Packaging order",
		hearders: [
			t("label.packaging_order"),
			t("label.task_id"),
			t("label.customer_name"),
			t("label.dc_name"),
			t("label.sku"),
			t("label.cases"),
			t("label.exp_delivery_date"),
		],
		keys: ["id", "task_id", "customer_name", "dc_name", "sku", "cases", "date_of_delivery"],
		url: "location-sku-priority",
		payloadKey: "skus",
	};

	// const [isLoading, setisLoading] = useState(false);
	const [btnTitle, setbtnTitle] = useState(t("label.save"));

	const submitSort = (priorityList) => {
		if (!loading) {
			setloading(true);

			setbtnTitle(t("label.saving"));
			toggleMainLoader(true);
			const successHandler = (event) => {
				setloading(false);
				setbtnTitle(t("label.save"));
				successMessage(event);
			};
			const errorHandler = (event) => {
				setloading(false);
				setbtnTitle(t("label.save"));
				errorMessage(event);
			};

			const body = priorityList.map((value, index) => {
				return {
					id: value.id,
					task_order: index + 1,
				};
			});

			updatePackagingOrders({ values: body }, successHandler, errorHandler);
		}
	};

	const SortableItem = SortableElement(({ value, priority, index }) => {
		return (
			<>
				<div className="bright-farms-table-tr-order">
					{sortFields.keys.map((val, i) => (
						<div key={i} className="bright-farms-table-td-order seeds-listing-col-name">
							{i === 0 ? priority : value[val]}
						</div>
					))}
				</div>
			</>
		);
	});

	const SortableList = SortableContainer(() => (
		<>
			<div className="sort-priority-container">
				{priorityList.map((value, index) => (
					<SortableItem key={`item-${index}`} index={index} priority={index + 1} value={value} />
				))}
			</div>
		</>
	));

	const onSortEnd = ({ oldIndex, newIndex }) => {
		setPriorityList(arrayMove(priorityList, oldIndex, newIndex));
	};

	return (
		<div>
			<div className="bright-farms-table-order">
				<div className="bright-farms-table-col-group-order">
					{sortFields.hearders.map((value, index) => (
						<div key={index} className="bright-farms-table-col-order seeds-listing-col-name"></div>
					))}
				</div>
				<div className="bright-farms-table-thead-order">
					<div className="bright-farms-table-tr-order">
						{sortFields.hearders.map((value, index) => (
							<div
								key={index}
								className="bright-farms-table-th-order seeds-listing-col-name seeds-listing-col-name-header">
								<div>
									<span>{value}</span>
								</div>
							</div>
						))}
					</div>
				</div>
				{viewOrderPermission && !isEmpty(priorityList) ? (
					<div className="bright-farms-table-tbody-order">
						{!isEmpty(priorityList) && <SortableList onSortEnd={onSortEnd} />}
					</div>
				) : (
					<div className="no-results-found text-center">{t("no_result_found")}</div>
				)}
			</div>
			{updateOrderPermission && !isEmpty(priorityList) && (
				<div className="justify-content-end ml-0 mr-0 mt-4 position-relative forecast-table-btn-row row">
					<button type="button" onClick={handleHarvestListView} className="mr-3 btn btn-secondary">
						{t("label.cancel")}
					</button>
					<button type="submit" onClick={() => submitSort(priorityList)} className="save-btn btn btn-primary">
						{btnTitle}
					</button>
				</div>
			)}
		</div>
	);
};

const mapDispatchToProps = { toggleMainLoader, updatePackagingOrders };

export default connect(null, mapDispatchToProps)(ViewPackagingOrder);
