import React from "react";
import "./headermenu.scss";
import { useLocation, withRouter } from "react-router-dom";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import MainLogo from "../../assets/logo.svg";
import Person from "../../assets/images/profile-image.png";
import { menuItems } from "./helper";
import useModal from "../../utils/useModal";
import Profile from "../../components/profile";

import { successMessage } from "../../utils/successMessage";
import { map } from "lodash";
import Kpi from "../../components/kpi";
import { onLogout } from "store/actions";
import { errorMessage } from "utils/errorMessage";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

//import TableLoader from "../../utils/table/tableLoader";
//import ContentLoader from "../../components/contentLoader/contentLoader";

const HeaderMenu = ({ history, userDetails, permissionData, onLogout }) => {
	let location = useLocation();

	const { t } = useTranslation();
	const [, , showModal] = useModal("profile");
	const [, , showKPIModal] = useModal("kpi");
	const exceptionArray = ["/dashboard", "/forecast", "/climate"];
	const lang = localStorage.getItem("language") || "en";

	// Logout
	const logoutApp = () => {
		const successHandler = (e) => {
			localStorage.removeItem("BRIGHT_FARMS_USER_DATA");
			history.push("/signin");
			successMessage(e);
		};
		const errorHandler = (e) => {
			errorMessage(e);
		};
		onLogout(successHandler, errorHandler);
	};

	// Menu Permission
	const checkSubMenuPermission = (submenu) => {
		let subMenuArray = map(submenu, "permission_key");
		subMenuArray = subMenuArray.filter((item) => item !== undefined);
		const subMenuInner = [];
		submenu.forEach((item) => {
			if (item.submenu) {
				subMenuInner.push(...item.submenu);
			}
		});
		const subMenuInnerPermission = map(subMenuInner, "permission_key");

		const combinedArray = [...subMenuArray, ...subMenuInnerPermission];
		const subMenuFlag = combinedArray.every((submenu, i) => {
			const checkPermission =
				permissionData && permissionData[submenu] && permissionData[submenu]["full_permission"]
					? permissionData[submenu]["full_permission"]
					: false;
			if (checkPermission) {
				return false;
			} else {
				return true;
			}
		});

		return !subMenuFlag;
	};

	// Navbar Menu Item with Dropdown
	const renderDropdownMenuItem = (menuItem) => (
		<span className="navbar-menu-link">
			{t(menuItem.label)}
			<span className="navbar-menu-down-arrow icon-angle-down"></span>
		</span>
	);

	const changeLanguageHandler = (item) => {
		localStorage.setItem("language", item);
		window.location.reload();
	};
	return (
		<>
			{/* {Object.keys(permissionData).length === 0 && (
				<div className="table-loader-wrapper">{<ContentLoader type="line" />}</div>
			)} */}

			{location.pathname.includes("/gh/") && (
				<div className="header-common-container" style={{ height: 66 }}>
					<div className="header-logo-container" style={{ height: 66, borderBottomLeftRadius: 0 }}>
						<a href="/gh/all">
							<img className="logo-dimension" src={MainLogo} alt="Bright Farms" />
						</a>
					</div>
					<div
						className="header-menu-container"
						style={{ alignItems: "center", height: 66, borderBottomRightRadius: 0 }}>
						<Navbar
							className="brightfarms-navbar"
							expand="lg"
							style={{ alignItems: "center", justifyContent: "flex-end", padding: 0 }}>
							<Nav className="secondry-nav-class">
								{/* My Profile */}
								<NavDropdown
									title={
										<>
											<img
												className="thumbnail-image"
												src={userDetails && userDetails.avatar ? userDetails.avatar : Person}
												alt="user pic"
											/>
											<span className="navbar-menu-down-arrow ml-3 icon-angle-down"></span>
										</>
									}
									className="profile-nav-dropdown"
									id="profile-nav-dropdown">
									<NavDropdown.Item onClick={() => showModal(0)}>Profile</NavDropdown.Item>
									<NavDropdown.Divider />
									<NavDropdown.Item type="button" onClick={logoutApp}>
										Logout
									</NavDropdown.Item>
								</NavDropdown>
							</Nav>
						</Navbar>
					</div>
				</div>
			)}
			{!location.pathname.includes("/gh/") && (
				<div className="header-common-container">
					{/* Navbar Logo */}
					<div className="header-logo-container">
						<a href="/dashboard">
							<img className="logo-dimension" src={MainLogo} alt="Bright Farms" />
						</a>
					</div>
					<div className="header-menu-container">
						<Navbar className="brightfarms-navbar" expand="lg">
							<Navbar.Toggle aria-controls="basic-navbar-nav">
								<img
									className="custom-navbar-toggler"
									src={require("assets/images/menu.svg")}
									alt="MenuToggle"
								/>
							</Navbar.Toggle>

							{/* Navbar Items - Center Menu */}
							<Navbar.Collapse id="basic-navbar-nav">
								<Nav className="navbar-menu-center mr-auto">
									{Object.keys(permissionData).length !== 0 &&
										menuItems &&
										menuItems.map((menuItem, i) =>
											menuItem.submenu ? (
												checkSubMenuPermission(menuItem.submenu) && (
													<NavDropdown
														key={i}
														title={
															checkSubMenuPermission(menuItem.submenu)
																? renderDropdownMenuItem(menuItem)
																: ""
														}>
														{menuItem.submenu.map((submenu, index) => {
															const checkPermission =
																permissionData &&
																permissionData[submenu.permission_key] &&
																permissionData[submenu.permission_key]["full_permission"]
																	? permissionData[submenu.permission_key]["full_permission"]
																	: "";

															return (
																<React.Fragment key={submenu.permission_key + index.toString()}>
																	{submenu.submenu
																		? checkSubMenuPermission(submenu.submenu) && (
																				<>
																					{/* {index !== 0 ? <NavDropdown.Divider /> : ""} */}
																					<NavDropdown
																						className="inner-submenu menu-border"
																						key={index}
																						title={
																							<div className="d-flex justify-content-between">
																								<span>{t(submenu.label)}</span>
																								<span className="navbar-menu-down-arrow icon-angle-right d-flex align-items-center justify-content-center"></span>
																							</div>
																						}>
																						{submenu.submenu.map(
																							(submenu_item, j) => {
																								const checkSubPermission =
																									permissionData &&
																									permissionData[
																										submenu_item
																											.permission_key
																									] &&
																									permissionData[
																										submenu_item
																											.permission_key
																									]["full_permission"]
																										? permissionData[
																												submenu_item
																													.permission_key
																										  ]["full_permission"]
																										: "";
																								return (
																									checkSubPermission && (
																										<React.Fragment key={j}>
																											{/* {j !== 0 ? (
																										<NavDropdown.Divider />
																									) : (
																										""
																									)} */}
																											<NavDropdown.Item
																												href={
																													submenu_item.url
																												}>
																												{t(
																													submenu_item.label
																												)}
																											</NavDropdown.Item>
																										</React.Fragment>
																									)
																								);
																							}
																						)}
																					</NavDropdown>
																				</>
																		  )
																		: checkPermission && (
																				<React.Fragment key={index}>
																					{/* {index !== 0 ? <NavDropdown.Divider /> : ""} */}
																					<div className="dropdown-item-wrapper">
																						{submenu.url === "/kpi" ? (
																							<NavDropdown.Item
																								onClick={() => showKPIModal(0)}>
																								{t(submenu.label)}
																							</NavDropdown.Item>
																						) : (
																							<NavDropdown.Item
																								href={submenu.url}>
																								{t(submenu.label)}
																							</NavDropdown.Item>
																						)}
																					</div>
																				</React.Fragment>
																		  )}
																</React.Fragment>
															);
														})}
													</NavDropdown>
												)
											) : menuItem.url === "/reports" ? (
												permissionData &&
												permissionData["reports"] &&
												permissionData["reports"]["full_permission"] && (
													<Nav.Link key={i} href={menuItem.url}>
														{t(menuItem.label)}
													</Nav.Link>
												)
											) : (
												<Nav.Link key={i} href={menuItem.url}>
													{exceptionArray.find((k) => k === menuItem.url) ? t(menuItem.label) : ""}
													{/* {menuItem.url === "/reports" &&
												permissionData &&
												permissionData["reports"] &&
												permissionData["reports"]["full_permission"]
													? permissionData["reports"]["full_permission"]
													: menuItem.label}
												{exceptionArray.find((k) => k === menuItem.url) ? menuItem.label : ""} */}
												</Nav.Link>
											)
										)}
								</Nav>
							</Navbar.Collapse>

							{/* Navbar Items - Right End */}
							<Nav className="secondry-nav-class">
								{/* select language */}

								<NavDropdown
									title={
										<>
											<span>{lang === "en" ? "English" : "Spanish"}</span>
											<span className="navbar-menu-down-arrow ml-3 icon-angle-down"></span>
										</>
									}
									className="profile-nav-dropdown"
									id="profile-nav-dropdown">
									<NavDropdown.Item
										onClick={() => {
											changeLanguageHandler("en");
										}}>
										English
									</NavDropdown.Item>
									<NavDropdown.Item
										onClick={() => {
											changeLanguageHandler("sp");
										}}>
										Spanish
									</NavDropdown.Item>
								</NavDropdown>
								{/* Notification */}
								<Nav.Link className="no-underline" href="#notification">
									<span className="notification-count">1</span>
									<i className="icon-notification" />
								</Nav.Link>
								{/* My Profile */}
								<NavDropdown
									title={
										<>
											<img
												className="thumbnail-image"
												src={userDetails && userDetails.avatar ? userDetails.avatar : Person}
												alt="user pic"
											/>
											<span className="navbar-menu-down-arrow ml-3 icon-angle-down"></span>
										</>
									}
									className="profile-nav-dropdown"
									id="profile-nav-dropdown">
									<NavDropdown.Item onClick={() => showModal(0)}>Profile</NavDropdown.Item>
									<NavDropdown.Divider />
									<NavDropdown.Item type="button" onClick={logoutApp}>
										Logout
									</NavDropdown.Item>
								</NavDropdown>
							</Nav>
						</Navbar>
					</div>
				</div>
			)}
			<Profile />
			<Kpi />
		</>
	);
};

export default withRouter(connect(null, { onLogout })(HeaderMenu));
