import React from "react";
import { Modal } from "react-bootstrap";
import BoardsView from "./boardsView";
import BoardsEdit from "./boardAddEdit";
import useModal from "../../../utils/useModal";

const { Header, Title, Body } = Modal;
const classPrefix = "bright-farms";

const BoardsManage = ({ handleEditPanel, onAddOrEdit, editingBoardDetails, updatePermission }) => {
	const [isOpen, modalType, , closeModal, modalProps] = useModal("boards");
	return (
		<Modal size="lg" show={isOpen} onHide={closeModal} className="modal-sidepanel">
			<Header closeButton>
				<Title className="modal-title">
					{modalType === 2 ? "View" : modalType === 1 ? "Edit" : "Add"} Styrofoam board
				</Title>
				{modalType === 2 && (
					<button type="button" className={`${classPrefix}-modal-close-button`} onClick={closeModal}>
						<i className="icon icon-close" />
					</button>
				)}
			</Header>
			<Body>
				{modalType === 2 ? (
					<BoardsView updatePermission={updatePermission} details={modalProps} handleEditPanel={handleEditPanel} />
				) : (
					<BoardsEdit
						initialValues={modalType ? (modalProps ? modalProps : null) : null}
						onAddOrEdit={onAddOrEdit}
						details={modalProps}
						mode={modalType}
					/>
				)}
			</Body>
		</Modal>
	);
};

export default BoardsManage;
