import { keys, isEmpty } from "lodash";
import { DOSING_CALC } from "../types";

const initialState = {
	loading: false,
	dropdownData: null,
	renderType: 0,
	data: null,
	initialData: null,
	pvSelected: null,
	saveEnable: false,
	editMode: false,
	activePond: null,
	pondTabs: {},
	pondId: null,
	initialValues: null,
	dosingCalculorEditData: null,
	ponds:null
};

export default function (state = initialState, { type, payload, statusSaving }) {
	let saveValue = null;
	let value = null;
	let pond = null;
	let tempItem =[];
	switch (type) {
		case DOSING_CALC.LOADER_DOSING:
			return {
				...state,
				loading: !state.loading,
			};

		case DOSING_CALC.SET_DROPDOWN_DOSING:
			return {
				...state,
				dropdownData: payload.data,
			};
		case DOSING_CALC.SET_RENDER_TYPE_DOSING:
			return {
				...state,
				renderType: payload,
			};
		case DOSING_CALC.SET_DATA_DOSING:
			const temp = payload.data.pond_list;
			value = keys(payload.data.pond_list);
			tempItem =[];
			value.map((item, index) => {
				value.map((item_i, index_i) => {
					if (temp[item_i].order === index) {
						tempItem.push(item_i);
					}
				});
			});
			pond = !isEmpty(tempItem) ? tempItem[0].replace(/\s/g, "") : null;
			return {
				...state,
				initialValues: payload.data.pond_list,
				data: tempItem && payload.data.pond_list[tempItem[0]],
				initialData: tempItem && payload.data.pond_list[tempItem[0]],
				pvSelected: tempItem && {
					label: keys(payload.data.pond_list[tempItem[0]].resultTable.result_table)[0],
					value: keys(payload.data.pond_list[tempItem[0]].resultTable.result_table)[0],
				},
				fertiliserData: tempItem && payload.data.pond_list[tempItem[0]].fertiliser_data,
				activePond: !isEmpty(tempItem) ? tempItem[0].replace(/\s/g, "") : null,
				[pond]: tempItem && payload.data.pond_list[tempItem[0]],
				pondTabs: payload.data.pond_list,
				pondId: tempItem && payload.data.pond_list[tempItem[0]].pond_id,
				ponds:tempItem
			};
		case DOSING_CALC.PPM_UPDATE:
			saveValue = `saving_${state.activePond}`;
			return {
				...state,
				[state.activePond]: payload,
				[saveValue]: true,
				[statusSaving]: true,
			};
		case DOSING_CALC.CHANGE_PV:
			return {
				...state,
				pvSelected: payload,
			};

		case DOSING_CALC.RESET_DATA_DOSING:
			saveValue = `saving_${state.activePond}`;
			return {
				...state,
				data: state.initialData,
				[saveValue]: false,
				[state.activePond]: state.initialValues[state.activePond],
			};
		case DOSING_CALC.SAVE_DATA_DOSING:
			saveValue = `saving_${state.activePond}`;
			return {
				...state,
				initialData: state.data,
				[saveValue]: false,
			};
		case DOSING_CALC.EDIT_DATA_DOSING:
			value = keys(payload.pond_list);
			tempItem =[];
			value.map((item, index) => {
				value.map((item_i, index_i) => {
					if (payload.pond_list[item_i].order === index) {
						tempItem.push(item_i);
					}
				});
			});
			pond = !isEmpty(tempItem) ? tempItem[0].replace(/\s/g, "") : null;
			// pond = !isEmpty(payload.pond_list) ? keys(payload.pond_list)[0].replace(/\s/g, "") : null;
			return {
				...state,
				initialValues: payload.pond_list,
				data: tempItem && payload.pond_list[tempItem[0]],
				initialData: tempItem && payload.pond_list[tempItem[0]],
				pvSelected: tempItem && {
					label: keys(payload.pond_list[tempItem[0]].resultTable.result_table)[0],
					value: keys(payload.pond_list[tempItem[0]].resultTable.result_table)[0],
				},
				fertiliserData: tempItem && payload.pond_list[tempItem[0]].fertiliser_data,
				activePond: !isEmpty(payload.pond_list) ? keys(payload.pond_list)[0].replace(/\s/g, "") : null,
				[pond]: tempItem && payload.pond_list[tempItem[0]],
				pondTabs: payload.pond_list,
				pondId: tempItem && payload.pond_list[tempItem[0]].pond_id,
				editMode: true,
				dosingCalculorEditData: payload,
				ponds:tempItem
			};
		case DOSING_CALC.DONE_EDIT_DATA_DOSING:
			return {
				...initialState,
				dropdownData: state.dropdownData,
			};
		case DOSING_CALC.SET_ACTIVE_TAB_POND:
			return {
				...state,
				[payload]: state[payload] ? state[payload] : state.pondTabs[payload],
				// initialData: state.pondTabs[payload],

				pvSelected: payload && {
					label: state.pvSelected.label,
					value: state.pvSelected.value,
				},
				
				activePond: payload,
				pondId: state.pondTabs[payload].pond_id,
				fertiliserData: state.pondTabs[payload].fertiliser_data,
			};

		case DOSING_CALC.RESET_ALL:
			return {
				...state,
				loading: false,
				renderType: 0,
				data: null,
				initialData: null,
				pvSelected: null,
				saveEnable: false,
				editMode: false,
				activePond: null,
				pondTabs: {},
				pondId: null,
				initialValues: null,
				ponds:null
			};

		default:
			return state;
	}
}
