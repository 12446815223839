import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import ForecastForm from "../../../manage";
import {
	checkDaysCountEqualSP,
	getForecastColumns,
	getSalesVsProductionDataVariety,
	getTabsCustomer,
	getActiveTabCustomer,
	getTabsDC,
	getActiveTabDC,
	getTableDataSP,
	customerKeyLabel,
	getSalesVsProductionCombinedData,
	getVarietyTypeSP,
	getSalesVsProductionData,
	getCombinedDifferenceSP,
	checkDaysCountEqualSP_combined,
	getProdVsSalesVarietyViewAccess,
	getTableDataSPTotal,
	getSPCombinedDataTotal,
	getForecastForm,
} from "store/selectors/forecastSelectors";
import ForecastTable from "utils/forecastTable";
import {
	setForecastEditable,
	setLoader,
	setActiveTabCustomer,
	setActiveTabDC,
	setCombinedDifferenceSP,
	fetchSalesVsProductionDataVarietyExport,
	fetchProductionVsActualSalesByVarietyExport,
} from "store/actions";
import { isEmpty } from "lodash";
import { Tab, TabContent, Tabs } from "react-bootstrap";
import Switch from "react-switch";
import { getCustomTotal } from "./getCustomTotal";
import { ButtonPrimary } from "elements";
import { errorMessage } from "utils/errorMessage";
import moment from "moment";

const Listing = ({
	data,
	fixedColumnData,
	columns,
	setLoader,
	checkDaysCountEqualSP,
	customerTabs,
	activeTabCustomer,
	setActiveTabCustomer,
	activeTabDC,
	dcTabs,
	setActiveTabDC,
	tableData,
	customerKeyLabel,
	combinedData,
	type,
	responseData,
	setCombinedDifferenceSP,
	combinedDifferenceSP,
	checkDaysCountEqualSP_combined,
	viewAccess,
	tableDataTotal,
	combinedDataTotal,
	actualSales,
	formValues,
	fetchSalesVsProductionDataVarietyExport,
	fetchProductionVsActualSalesByVarietyExport,
}) => {
	const exportHandler = () => {
		const values = formValues && formValues.values;
		let exportSlug;
		values &&
			customerKeyLabel &&
			activeTabCustomer &&
			values.customer_variety.map((item) => {
				if (customerKeyLabel[activeTabCustomer].label === item.label) {
					exportSlug = item.value;
				}
			});
		let submitData = {
			location_slug:
				values.view_by.value === "production_vs_sales_combined"
					? values.location_variety.map((item) => item.value)
					: values.location_variety.value,
			customer_slug: values.customer_variety.map((item) => item.value),
			dc_slug: values.dc_variety.map((item) => item.value),
			date_from: moment(values.date[0]).format("YYYY-MM-DD"),
			date_to: moment(values.date[1]).format("YYYY-MM-DD"),
			pv_slugs: values.product_variety_sp.map((item) => item.value),
			export: 1,
			export_customer_slug: exportSlug,
		};

		const successHandler = (e) => {
			e.data.export_url && window.open(e.data.export_url);
		};
		const errorHandler = (e) => {
			errorMessage(e);
		};

		if (!actualSales) {
			submitData = {
				...submitData,
				view_by:
					values.view_by.key === "production_vs_sales_combined"
						? combinedDifferenceSP
							? "production_vs_sales_combined_difference"
							: values && values.view_by.key
						: values && values.view_by.key,
			};
			fetchSalesVsProductionDataVarietyExport(submitData, successHandler, errorHandler, values.view_by.value);
		} else {
			submitData = {
				...submitData,
				view_by:
					values.view_by.key === "production_vs_actual_sales_combined"
						? combinedDifferenceSP
							? "production_vs_actual_sales_combined_difference"
							: values && values.view_by.key
						: values && values.view_by.key,
			};
			fetchProductionVsActualSalesByVarietyExport(submitData, successHandler, errorHandler, values.view_by.value);
		}
	};

	return (
		<div className="listing-wrapper" style={{ paddingBottom: !viewAccess && 50 }}>
			{viewAccess && (
				<>
					<ForecastForm type={7} actualSales={actualSales} />

					{type && (type === "production_vs_sales" || type === "production_vs_sales_difference") ? (
						customerTabs && (
							<div className="tab-wrapper">
								<Tabs
									activeKey={activeTabCustomer}
									defaultActiveKey={activeTabCustomer}
									mountOnEnter={true}
									onSelect={(k) => {
										setActiveTabCustomer(customerKeyLabel[k]);
									}}
									className="customer-tab"
									id="uncontrolled-tab-outer">
									{customerTabs.map((tab, index) => (
										<Tab eventKey={tab.key} key={index} title={tab.label}>
											{/* {seedsFormFields(tab.value)} */}
											{!isEmpty(tableData) && (
												<div className="forecast-export-btn">
													<ButtonPrimary type="submit" onClick={exportHandler}>
														<span>
															Export{" "}
															<i
																className="icon icon-export text-sm"
																style={{ fontWeight: "bold" }}
															/>
														</span>
													</ButtonPrimary>
												</div>
											)}
											<TabContent bsPrefix="customer-tab-content">
												<Tabs
													activeKey={activeTabDC}
													defaultActiveKey={activeTabDC}
													onSelect={(k) => setActiveTabDC(k)}
													className="dc-tab"
													mountOnEnter={true}
													id="uncontrolled-tab-inner">
													{dcTabs.map((tab, index) => (
														<Tab
															className="dc-tab-cell"
															eventKey={tab.key}
															key={index}
															title={
																<span>
																	<i className="icon icon-market mr-3" />
																	{tab.label}
																</span>
															}>
															{activeTabDC === tab.key && tableData ? (
																!isEmpty(tableData) ? (
																	<>
																		{!checkDaysCountEqualSP && (
																			<div className="d-flex justify-content-end">
																				<p className="dates-missing-text">
																					Data is not available for a few dates
																				</p>
																			</div>
																		)}
																		<ForecastTable
																			columns={columns}
																			fixedColumnHeading="Date"
																			initialData={tableData}
																			dateFormat="DD-MM-YYYY"
																			customTotal={() =>
																				tableDataTotal &&
																				getCustomTotal(tableDataTotal, type)
																			}
																			render={(item) =>
																				type === "production_vs_sales_difference" ? (
																					<>
																						<span
																							className={
																								item.production - item.sales >=
																								0
																									? "txt-green"
																									: "txt-red"
																							}>
																							{(
																								item.production - item.sales
																							).toLocaleString("en-IN")}
																						</span>
																					</>
																				) : (
																					<>
																						<span className="production-value">
																							{item.production ||
																							item.production === 0
																								? item.production.toLocaleString(
																										"en-IN"
																								  )
																								: "-"}
																						</span>
																						{" / "}
																						<span className="sales-value">
																							{item.sales || item.sales === 0
																								? item.sales.toLocaleString(
																										"en-IN"
																								  )
																								: "-"}
																						</span>
																					</>
																				)
																			}
																		/>
																	</>
																) : (
																	<div className="no-results-found text-center">
																		No Results Found
																	</div>
																)
															) : null}
														</Tab>
													))}
												</Tabs>
											</TabContent>
										</Tab>
									))}
								</Tabs>
							</div>
						)
					) : type && type === "production_vs_sales_combined" ? (
						<>
							{combinedData && !isEmpty(combinedData) && (
								<>
									<div className=" d-flex w-100 justify-content-end mb-3 float-right combined-switch-sec">
										<span className="combined-switch-left mr-2">Combined</span>
										<Switch
											onChange={setCombinedDifferenceSP}
											checked={combinedDifferenceSP}
											uncheckedIcon={false}
											checkedIcon={false}
											offColor="#006341"
											onColor="#006f49"
											height={18}
											width={35}
										/>
										<span className="combined-switch-left ml-2">Difference</span>
									</div>
									{!isEmpty(combinedData) && (
										<div className="d-flex justify-content-end w-100">
											<div className="forecast-export-left">
												<ButtonPrimary type="submit" onClick={exportHandler}>
													<span>
														Export{" "}
														<i
															className="icon icon-export text-sm"
															style={{ fontWeight: "bold" }}
														/>
													</span>
												</ButtonPrimary>
											</div>
										</div>
									)}

									{!checkDaysCountEqualSP_combined && (
										<div className="d-flex justify-content-end w-100">
											<p className="dates-missing-text">Data is not available for a few dates</p>
										</div>
									)}
									<ForecastTable
										columns={columns}
										fixedColumnHeading="Date"
										initialData={combinedData}
										dateFormat="DD-MM-YYYY"
										customTotal={() =>
											combinedDataTotal && getCustomTotal(combinedDataTotal, type, combinedDifferenceSP)
										}
										render={(item) =>
											combinedDifferenceSP ? (
												<span className={item.production - item.sales >= 0 ? "txt-green" : "txt-red"}>
													{(item.production - item.sales).toLocaleString("en-IN")}
												</span>
											) : (
												<>
													<span className="production-value">
														{item.production || item.production === 0
															? item.production.toLocaleString("en-IN")
															: "-"}
													</span>
													{" / "}
													<span className="sales-value">
														{item.sales || item.sales === 0
															? item.sales.toLocaleString("en-IN")
															: "-"}
													</span>
												</>
											)
										}
									/>
								</>
							)}
						</>
					) : null}
					{type !== "production_vs_sales_combined"
						? responseData &&
						  isEmpty(responseData) && <div className="no-results-found text-center">No Results Found</div>
						: combinedData &&
						  isEmpty(combinedData) && <div className="no-results-found text-center">No Results Found</div>}
				</>
			)}
		</div>
	);
};

const mapStateToProps = createStructuredSelector({
	columns: getForecastColumns,
	data: getSalesVsProductionDataVariety,
	checkDaysCountEqualSP: checkDaysCountEqualSP,
	checkDaysCountEqualSP_combined: checkDaysCountEqualSP_combined,
	activeTabCustomer: getActiveTabCustomer,
	customerTabs: getTabsCustomer,
	activeTabDC: getActiveTabDC,
	dcTabs: getTabsDC,
	tableData: getTableDataSP,
	customerKeyLabel: customerKeyLabel,
	combinedData: getSalesVsProductionCombinedData,
	type: getVarietyTypeSP,
	responseData: getSalesVsProductionData,
	combinedDifferenceSP: getCombinedDifferenceSP,
	viewAccess: getProdVsSalesVarietyViewAccess,
	tableDataTotal: getTableDataSPTotal,
	combinedDataTotal: getSPCombinedDataTotal,
	formValues: getForecastForm,
});
export default connect(mapStateToProps, {
	setForecastEditable,
	setLoader,
	setActiveTabCustomer,
	setActiveTabDC,
	setCombinedDifferenceSP,
	fetchSalesVsProductionDataVarietyExport,
	fetchProductionVsActualSalesByVarietyExport,
})(Listing);
