import { WATCH_WIRE } from "../types";
import { isEmpty, keys, values } from "lodash";
const initialState = {
	loading: false,
	dropDownList: {},
	invoiceDataChartData: {},
	invoiceDataTableData: null,
	activeTabCommodity: null,
	activeTabAccountsIds: null,
	showChart:false,
	downloadUrl:null,
	submitted:false,
	columns:null
};
export default function (state = initialState, { type, payload }) {
	switch (type) {
		case WATCH_WIRE.LOADER_WW:
			return {
				...state,
				loading: !state.loading,
			};
		case WATCH_WIRE.DROPDOWN_LIST:
			return {
				...state,
				dropDownList: payload.data || {},
			};
		case WATCH_WIRE.FETCH_WATCH_WIRE_INVOICE:
			return {
				...state,
				invoiceDataChartData: payload.data.chart_data || {},
				invoiceDataTableData: payload.data.table_data || {},
				activeTabCommodity: !isEmpty(payload.data.table_data)
					? keys(payload.data.table_data)[0].replace(/\s/g, "")
					: null,
				activeTabAccountsIds: !isEmpty(payload.data.table_data)
					? keys(values(payload.data.table_data)[0])[0].replace(/\s/g, "")
					: null,
				submitted:true
			};
		case WATCH_WIRE.SET_ACTIVE_TAB_COMMODITY:
			return {
				...state,
				activeTabCommodity: payload.key,
				activeTabAccountsIds: keys(state.invoiceDataTableData[payload.label])[0].replace(/\s/g, ""),
			};

		case WATCH_WIRE.SET_ACTIVE_TAB_ACCOUNT_ID:
			return {
				...state,
				activeTabAccountsIds: payload,
			};

		case WATCH_WIRE.RESET_WATCH_WIRE_FIELD:
			return {
				...state,
				invoiceDataChartData: {},
				invoiceDataTableData: null,
				activeTabCommodity: null,
				activeTabAccountsIds: null,
				showChart:false,
				submitted:false
			};

		case WATCH_WIRE.EXPORT_FILE:
			return{
				...state,
				downloadUrl:payload.data.export_url
			}
		case WATCH_WIRE.SHOW_CHART:
			return{
				...state,
				showChart:true
			}
		case WATCH_WIRE.SET_COLUMNS:
			return{
				...state,
				columns:payload
			}

		default:
			return state;
	}
}
