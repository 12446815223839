import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { get } from "lodash";
import { ContentWrap, ButtonPrimary, Breadcrumbs } from "../../../elements";
import Listing from "../../../partials/listings";
import { renderColumns } from "./helper";
import { fetchSkuLocations, deleteSku, fetchSkuDetails, fetchAllSkus, fetchSkuChangeStatus } from "../../../store/actions";
import ConfirmModal from "../../../utils/confirmModal";
import { errorMessage } from "../../../utils/errorMessage";
import { successMessage } from "../../../utils/successMessage";
import SkuManage from "../manage/";
import useModal from "../../../utils/useModal";
import "../sku.scss";
import TableLoader from "utils/table/tableLoader";
import { useTranslation } from "react-i18next";
import { isEmpty } from "lodash";

const StockKeeping = ({
	deleteSku,
	fetchSkuDetails,
	fetchAllSkus,
	fetchSkuLocations,
	allLocationsList,
	permissionDetails,
	fetchSkuChangeStatus,
	allSkuDetails
}) => {
	const { t } = useTranslation();
	const [refetch, setRefetch] = useState(0);
	const [filter, setFilter] = useState([]);
	const [skuToDelete, setSkuToDelete] = useState(null);
	const [, , showModal] = useModal("sku");
	const [loading, setloading] = useState(false);
	const [exportFlag,setExportFlag] = useState(null);
	
	const addPermission =
		permissionDetails && permissionDetails.stock && permissionDetails.stock.permissions.create_stock_keeping_unit
			? permissionDetails.stock.permissions.create_stock_keeping_unit
			: "";
	const updatePermission =
		permissionDetails && permissionDetails.stock && permissionDetails.stock.permissions.update_stock_keeping_unit
			? permissionDetails.stock.permissions.update_stock_keeping_unit
			: "";
	const listPermission =
		permissionDetails && permissionDetails.stock && permissionDetails.stock.permissions.list_stock_keeping_unit
			? permissionDetails.stock.permissions.list_stock_keeping_unit
			: "";

	useEffect(() => {
		fetchSkuLocations();
	}, [fetchSkuLocations]);
	const handleAddPanel = () => {
		showModal(0);
	};
	const handleEditPanel = (slug) => {
		setloading(true);
		const successHandler = () => onSuccess(slug ? 1 : 0, slug);
		fetchSkuDetails(slug, successHandler, onError);
	};
	const handleViewPanel = (slug) => {
		setloading(true);
		const successHandler = () => onSuccess(2, slug);
		fetchSkuDetails(slug, successHandler, onError);
	};
	const confirmDelete = (slug) => setSkuToDelete(slug);

	const onSuccess = (modalType, slug) => {
		setloading(false);
		showModal(modalType, slug);
	};
	const onError = (e) => {
		setloading(false);
		errorMessage(e);
	};

	// Delete User
	const onDeleteSku = (id) => {
		const successHandler = (e) => {
			setSkuToDelete(null);
			setRefetch(refetch + 1);
			successMessage(e);
		};
		const errorHandler = (e) => {
			setSkuToDelete(null);
			errorMessage(e);
		};
		deleteSku(id, successHandler, errorHandler);
	};

	// Filter
	const addToFilter = (e) => {
		let obj = [];
		e.forEach((item) => {
			return (obj =
				item && item.column
					? [
							...obj,
							{
								column_name: item.column.accessor,
								search: item.value,
							},
					  ]
					: obj);
		});
		setFilter(obj);
	};

	const columns = renderColumns({
		handleEditPanel,
		handleViewPanel,
		allLocationsList,
		confirmDelete,
		addToFilter,
		permissionDetails,
	});

	const exportHandler = () =>{
		setExportFlag(true);
	}

	const exportHandlerFlag = () => {
		setExportFlag(false);
	};

	

	return (
		<ContentWrap>
			<div className="d-flex align-items-center mb-3">
				<Breadcrumbs keyValue="sku" showLabel={listPermission} label="List" />
				{!isEmpty(allSkuDetails) &&
					<ButtonPrimary className="export_btn" onClick={exportHandler}>
						<span>
						{t("label.export")}{" "}
							<i className="icon icon-export text-sm" style={{fontWeight:"bold"}}/>
						</span>
					</ButtonPrimary>
			    }
				{addPermission ? (
					<div>
						<ButtonPrimary onClick={handleAddPanel}>
							<i className="icon icon-plus mr-2"></i> Add SKU
						</ButtonPrimary>
					</div>
				) : (
					""
				)}
			</div>
			<div className="position-relative">
				{listPermission ? (
					<Listing
						fetchAction={fetchAllSkus}
						sort_name={"sku_name"}
						columns={columns}
						refetch={refetch}
						filter={filter}
						exportFlag={exportFlag} 
						exportHandlerFlag={()=>{exportHandlerFlag()}}
						
					/>
				) : (
					""
				)}
				{loading && !permissionDetails && (
					<div className="table-loader-wrapper">
						<TableLoader />
					</div>
				)}
			</div>
			<SkuManage
				onAddOrEdit={() => setRefetch(refetch + 1)}
				doRefetch={() => setRefetch(refetch + 1)}
				handleEditPanel={handleEditPanel}
				updatePermission={updatePermission}
				changeStatus={fetchSkuChangeStatus}
				fetchSkuDetails={fetchSkuDetails}
			/>
			<ConfirmModal
				show={skuToDelete ? true : false}
				onNo={() => setSkuToDelete(null)}
				onYes={() => onDeleteSku(skuToDelete)}
				message="Are you sure want to delete this stock keeping unit?"
			/>
		</ContentWrap>
	);
};

const mapStateToProps = (state) => {
	const { UI, Auth, Sku } = state;
	return {
		accessToken: get(Auth, "accessToken", null),
		mainPreloader: get(UI, "mainPreloader", null),
		allLocationsList: get(Sku, "allLocationsList", null),
		productList: get(Sku, "productList", null),
		permissionDetails: get(Auth, "permissionDetails", null),
		allSkuDetails: get(Sku, "allSkuDetails", null),
	};
};

const mapDispatchToProps = {
	fetchSkuChangeStatus,
	fetchSkuLocations,
	fetchSkuDetails,
	deleteSku,
	fetchAllSkus,
};
export default connect(mapStateToProps, mapDispatchToProps)(StockKeeping);
